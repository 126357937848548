import React, { Component } from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import Utility from '../../utils/Utility';
import OrderItem from './OrderItem';

// eslint-disable-next-line react/prefer-stateless-function
export default class OrderItems extends Component {
  constructor(props) {
    super(props);
    this.onlyOne = this.checkIfOneShipmentOrOrder();
  }

  checkIfOneShipmentOrOrder = () => {
    const { orderHistoryData = [] } = this.props;

    return (
      orderHistoryData.length === 1 &&
      (orderHistoryData[0].shipments.length === 1 ||
        orderHistoryData[0].shipments.length === 0)
    );
  };

  renderItem = ({ item }) => {
    const { navigation } = this.props;
    const { orderHistoryData = [], paginateOrderHistory = () => {} } =
      this.props;

    return (
      <OrderItem
        navigation={navigation}
        orderHistoryItem={item}
        key={item.id}
        onlyOneOrder={this.onlyOne}
      />
    );
  };

  footer = () => {
    const { showLoader } = this.props;
    if (showLoader) {
      return (
        <View style={styles.footer}>
          <ActivityIndicator size='large' color='green' />
        </View>
      );
    }

    return <View style={styles.verticalMargin} />;
  };

  keyExtractor = (item) => item.id;

  render() {
    const { orderHistoryData = [], paginateOrderHistory = () => {} } =
      this.props;
    return (
      <FlatList
        data={orderHistoryData}
        renderItem={this.renderItem}
        keyExtractor={this.keyExtractor}
        extraData={this.props}
        ListFooterComponent={this.footer}
        onEndReached={paginateOrderHistory}
        showsVerticalScrollIndicator={false}
        onEndReachedThreshold={0.3}
        contentContainerStyle={styles.flatListContent}
      />
    );
  }
}

const styles = StyleSheet.create({
  footer: {
    height: 100,
    width: Utility.getScreenWidth(),
    marginTop: 12,
  },
  verticalMargin: {
    height: 100,
    width: Utility.getScreenWidth(),
  },
  flatListContent: {
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  }
});
