let getListComponent = () => {};
let getListComponentHeight = () => {};
let getVerticalComponent = () => {};
let getVerticalListOffsets = () => {};

export const setListRefHelper = ({
  getListComponentRef,
  getListComponentHeightRef,
  getVerticalComponentRef,
  getVerticalListOffsetsRef,
}) => {
  getListComponent = getListComponentRef;
  getListComponentHeight = getListComponentHeightRef;
  getVerticalComponent = getVerticalComponentRef;
  getVerticalListOffsets = getVerticalListOffsetsRef;
};

export const getListRefHelper = () => ({
  getListComponent,
  getListComponentHeight,
  getVerticalComponent,
  getVerticalListOffsets,
});
