import React from 'react';
import { Text, View } from 'react-native';
import RoutinesUtility from '../../components/Routines/RoutinesUtility';
import Utility from '../../utils/Utility';
import CountDown from '../../helpers/Counter';
import moment from 'moment';

const OfferCounter = (props) => {
  const {
    endsAt,
    digitStyle,
    digitTxtStyle,
    timeLabelStyle,
    separatorStyle,
    countdownText,
    timerText,
  } = props;
  const currentDate = new Date();
  const endDate = new Date(moment(endsAt).local());
  if (Utility.isBlank(endsAt) || currentDate > endDate) {
    return null;
  }

  if (Utility.isPresent(RoutinesUtility.getEndsAtString(new Date(endsAt)))) {
    return (
      <Text style={timerText}>
        {RoutinesUtility.getEndsAtString(new Date(endsAt))}
      </Text>
    );
  }


  const timeDiff = endDate.getTime() - currentDate.getTime();

  return (
    <View>
      <Text style={countdownText}>Expiring in</Text>
      <CountDown
        size={8}
        until={timeDiff / 1000}
        digitStyle={digitStyle}
        digitTxtStyle={digitTxtStyle}
        timeLabelStyle={timeLabelStyle}
        separatorStyle={separatorStyle}
        timeToShow={['H', 'M', 'S']}
        timeLabels={{ h: '', m: '', s: '' }}
        showSeparator
      />
    </View>
  );
};

export default OfferCounter;
