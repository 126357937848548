import NetInfo from '@react-native-community/netinfo';
import { NativeModule, NativeModules } from 'react-native';
import _ from 'lodash';
import AnalyticsManager from './AnalyticsManager';
import EventType from './AnalyticsEventType';
import EventParameterKey from './EventParameterKey';
import EventParameterValue from './EventParameterValue';
import Utility from '../../../utils/Utility';
import { CURRENCY } from '../../../config/Constants';
import {
  getCurrentAddress,
  getCurrentAddressPinCode,
} from '../../../utils/AddressUtility';
import { APP_LOAD_SOURCE } from '../config/Constants';
import { isPresent } from '../../../utils/BooleanUtility';

class AnalyticsUtility {
  static fireImageErrorAnalytics(id, imageUrl, name, type, screenName) {
    NetInfo.fetch().then((state) => {
      if (state.isConnected) {
        AnalyticsManager.logEvent(EventType.errorEvents.IMAGE_ERROR, {
          [EventParameterKey.SCREEN_NAME]: screenName,
          [EventParameterKey.IMAGE_URL]: imageUrl,
          [EventParameterKey.ITEM_ID]: id,
          [EventParameterKey.ITEM_NAME]: name,
          [EventParameterKey.ITEM_TYPE]: isPresent(type)
            ? EventParameterValue.ITEM_TYPE[type?.toUpperCase()]
            : '',
        });
      }
    });
  }

  static fireContentErrorAnalytics(id, name, type) {
    NetInfo.fetch().then((state) => {
      if (state.isConnected) {
        AnalyticsManager.logEvent(EventType.errorEvents.CONTENT_ERROR, {
          [EventParameterKey.ITEM_ID]: id,
          [EventParameterKey.ITEM_NAME]: name,
          [EventParameterKey.ITEM_TYPE]: isPresent(type)
            ? EventParameterValue.ITEM_TYPE[type?.toUpperCase()]
            : '',
        });
      }
    });
  }

  static fireVariantErrorAnalytics(id, name) {
    NetInfo.fetch().then((state) => {
      if (state.isConnected) {
        AnalyticsManager.logEvent(EventType.errorEvents.VARIANT_ERROR, {
          [EventParameterKey.ITEM_ID]: id,
          [EventParameterKey.ITEM_NAME]: name,
        });
      }
    });
  }

  static fireDescriptionErrorAnalytics(id, name) {
    NetInfo.fetch().then((state) => {
      if (state.isConnected) {
        AnalyticsManager.logEvent(EventType.errorEvents.DESCRIPTION_ERROR, {
          [EventParameterKey.ITEM_ID]: id,
          [EventParameterKey.ITEM_NAME]: name,
          [EventParameterKey.ITEM_TYPE]: EventParameterValue.ITEM_TYPE.PRODUCT,
        });
      }
    });
  }

  static firePageLoadDelayEvent(meta) {
    AnalyticsManager.logEvent(EventType.errorEvents.PAGE_LOAD_DELAY, meta);
  }

  static recordTimeWithFilterCounter(
    analyticsMeta,
    functionCallback,
    actionFunction,
    ...args
  ) {
    const timer = setTimeout(() => {
      AnalyticsUtility.firePageLoadDelayEvent(analyticsMeta);
      clearTimeout(timer);
    }, 5000);
    if (actionFunction) {
      actionFunction(
        ...args,
        (success, response, pageNo = undefined, filtersCounter = -1) => {
          clearTimeout(timer);
          functionCallback(success, response, pageNo, filtersCounter);
        },
      );
    }
  }

  static fireItemClickEvent(
    screen_name = '',
    item_id = '',
    item_type = '',
    item_name = '',
    item_position = '',
    list_id = '',
    list_type = '',
    list_name = '',
    list_position = '1',
    list_tag = '',
    in_stock = '',
    list_content = '',
    product_id = '',
  ) {
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: screen_name,
      [EventParameterKey.ITEM_ID]: item_id,
      [EventParameterKey.ITEM_TYPE]: item_type,
      [EventParameterKey.ITEM_NAME]: item_name,
      [EventParameterKey.ITEM_POSITION]: item_position,
      [EventParameterKey.LIST_ID]: list_id,
      [EventParameterKey.LIST_DISPLAY]: list_type,
      [EventParameterKey.LIST_NAME]: list_name,
      [EventParameterKey.LIST_POSITION]: list_position,
      [EventParameterKey.LIST_CONTENT]: list_content,
      [EventParameterKey.LIST_TAGS]: list_tag,
      [EventParameterKey.IN_STOCK]: in_stock,
      [EventParameterKey.PRODUCT_ID]: product_id,
      [EventParameterKey.GENDER]: Utility.genderFromSelfie
        ? Utility.genderFromSelfie[0]
        : -1,
    });
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.SELECT_CONTENT,
      {
        [EventParameterKey.CONTENT_TYPE]: item_type,
        [EventParameterKey.ID]: item_id,
      },
    );
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.SELECT_ITEM,
      {
        [EventParameterKey.ITEM_LIST_NAME]: list_name,
        [EventParameterKey.ITEM_LIST_ID]: list_id,
        [EventParameterKey.ITEMS]: [
          {
            [EventParameterKey.ID]: item_id,
          },
        ],
      },
    );
  }

  static fireListViewEvent(viewedIds, viewableItems, screenName) {
    try {
      viewableItems.forEach((i) => {
        if (!viewedIds.includes(i.item.id)) {
          const { id, content, name } = i.item;

          AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_VIEW, {
            [EventParameterKey.SCREEN_NAME]: screenName,
            [EventParameterKey.LIST_ID]: id,
            [EventParameterKey.LIST_TYPE]: content,
            [EventParameterKey.LIST_NAME]: name,
            [EventParameterKey.LIST_POSITION]: i.index,
            [EventParameterKey.LIST_TAGS]: '',
          });
          viewedIds = [...viewedIds, i.item.id];
        }
      });

      return viewedIds;
    } catch (error) {}
  }

  static triggerListItemViewEvent = (
    screenName,
    listName,
    listIndex,
    listId,
    item,
  ) => {
    const { type, metadata = {}, id, display = '' } = item;
    let item_name = item.name;
    let extraProductParams = {};
    if (type === 'video') {
      item_name = metadata.title;
    } else if (type === 'banner') {
      item_name = 'banner';
    } else if (type === 'product') {
      extraProductParams = {
        [EventParameterKey.IN_STOCK]: !item.outOfStock,
      };
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_VIEW, {
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ITEM_ID]: id,
      [EventParameterKey.ITEM_TYPE]: type,
      [EventParameterKey.ITEM_NAME]: item_name,
      [EventParameterKey.ITEM_POSITION]: -1,
      [EventParameterKey.LIST_ID]: listId,
      [EventParameterKey.LIST_TYPE]: display,
      [EventParameterKey.LIST_NAME]: listName,
      [EventParameterKey.LIST_POSITION]: listIndex,
      ...extraProductParams,
    });
  };

  static fireAppLoadEvent(source, url) {
    if (Utility.isAppLoadedForFirstTime) {
      if (isPresent(Utility.appLastLoadedAtTimeDifference)) {
        if (isPresent(url)) {
          AnalyticsManager.logEvent(EventType.miscAppEvents.APP_LOAD, {
            [EventParameterKey.APP_LAST_OPEN_AT]:
              Utility.appLastLoadedAtTimeDifference,
            [EventParameterKey.SOURCE]: source,
            [EventParameterKey.URL]: url,
            [EventParameterKey.LOAD_TIME]: Utility.getTimeDiff(
              new Date(),
              Utility.appFirstLoaded,
            ),
          });
        } else {
          AnalyticsManager.logEvent(EventType.miscAppEvents.APP_LOAD, {
            [EventParameterKey.APP_LAST_OPEN_AT]:
              Utility.appLastLoadedAtTimeDifference,
            [EventParameterKey.SOURCE]: source,
            [EventParameterKey.LOAD_TIME]: Utility.getTimeDiff(
              new Date(),
              Utility.appFirstLoaded,
            ),
          });
        }
      } else {
        AnalyticsManager.logEvent(EventType.miscAppEvents.APP_LOAD, {
          [EventParameterKey.SOURCE]: source,
          [EventParameterKey.LOAD_TIME]: Utility.getTimeDiff(
            new Date(),
            Utility.appFirstLoaded,
          ),
        });
      }

      Utility.isAppLoadedForFirstTime = false;
    }
  }

  static logScreenLoadTime() {
    AnalyticsManager.logEvent(EventType.onboardingEvent.SCREEN_LOAD, {
      [EventParameterKey.LOAD_TIME]: Utility.getTimeDiff(
        new Date(),
        Utility.appFirstLoaded,
        true,
      ),
    });
  }

  static logSelfieState(state) {
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_ANALYSIS_STATE, {
      [EventParameterKey.STATE]: state,
    });
  }

  static fireCheckoutInitiate(meta = {}) {
    const {
      isContactLess,
      authToken,
      address,
      lastPaymentMethod,
      cartItems = [],
      optForPlasticFreePackaging,
      couponCodes = [],
      pricingData: { total = '' },
      networkInfo = {},
    } = meta;

    const couponCodesArray = _.map(couponCodes, (coupon) => {
      return coupon.coupon_code;
    });

    const networkData = Utility.getNetworkInfo(networkInfo);

    AnalyticsManager.logEvent(EventType.payment.INITIATE_CHECKOUT, {
      ...networkData,
      [EventParameterKey.ITEM_COUNT]: cartItems.length,
      [EventParameterKey.ADDRESS_PRESENT]: isPresent(address),
      [EventParameterKey.ADDRESS_ID]: getCurrentAddress(address),
      [EventParameterKey.IS_GUEST_USER]: Utility.isBlank(authToken),
      [EventParameterKey.COUPON_CODE_APPLIED]:
        isPresent(couponCodes) && couponCodes.length !== 0,
      [EventParameterKey.COUPON_CODE]: couponCodesArray.toString(),
      [EventParameterKey.PAYMENT_METHOD_SAVED_STATUS]:
        !Utility.isBlank(lastPaymentMethod) &&
        !Utility.isBlank(lastPaymentMethod.method),
      [EventParameterKey.PAYMENT_METHOD_SAVED_NAME]:
        !Utility.isBlank(lastPaymentMethod) &&
        !Utility.isBlank(lastPaymentMethod.method)
          ? lastPaymentMethod.method
          : '',
      [EventParameterKey.OPTED_FOR_PLASTIC_FREE_PACKAGING]:
        optForPlasticFreePackaging,
      [EventParameterKey.LAST_PAYMENT_METHOD]:
        !Utility.isBlank(lastPaymentMethod) &&
        !Utility.isBlank(lastPaymentMethod.method),
      [EventParameterKey.LAST_PAYMENT_METHOD_NAME]:
        !Utility.isBlank(lastPaymentMethod) &&
        !Utility.isBlank(lastPaymentMethod.method)
          ? lastPaymentMethod.method
          : '',
    });

    let items = [];

    items = Utility.getItems(cartItems);

    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.BEGIN_CHECKOUT,
      {
        [EventParameterKey.CURRENCY]: CURRENCY.INR,
        [EventParameterKey.VALUE]: total,
        coupon: couponCodesArray.toString(),
        items,
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_INITIATED_CHECKOUT,
      total,
      {
        [EventParameterKey.EVENT_PARAM_NUM_ITEMS]: items.length,
        [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: CURRENCY.INR,
        [EventParameterKey.FB.EVENT_PARAM_PAYMENT_INFO_AVAILABLE]:
          isPresent(lastPaymentMethod) ? 1 : 0,
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: _.map(
          items,
          (item) => item.id,
        ),
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
        // TODO: Get quantity and id from cart_items
        // [EventParameterKey.FB.EVENT_PARAM_CONTENT]: [],
        // content_category
      },
    );
  }

  static fireLoginInitiate({ source }) {
    AnalyticsManager.logEvent(EventType.onboardingEvent.LOGIN_INITIATE, {
      [EventParameterKey.SOURCE]: source,
    });
  }

  static fireContactLessCheckboxEvent({ isContactLess }) {
    if (isContactLess) {
      AnalyticsManager.logEvent(EventType.payment.CONTACTLESS_CHECKED);
    } else {
      AnalyticsManager.logEvent(EventType.payment.CONTACTLESS_UNCHECKED);
    }
  }

  static firePlasticFreeOptingEvent({ opted }) {
    if (opted) {
      AnalyticsManager.logEvent(
        EventType.payment.OPT_FOR_PLASTIC_FREE_PACKAGING_CHECKED,
      );
    } else {
      AnalyticsManager.logEvent(
        EventType.payment.OPT_FOR_PLASTIC_FREE_PACKAGING_UNCHECKED,
      );
    }
  }

  static fireCartOfferClick() {
    AnalyticsManager.logEvent(EventType.offer.CART_OFFER_CLICK);
  }

  static fireOfferRemoveEvent({ coupon_code }) {
    AnalyticsManager.logEvent('offer_remove', {
      coupon_code,
    });
  }

  static firePaymentMethodCollapseEvent({
    paymentOption,
    isUpgradeToPrepaid,
    total,
  }) {
    AnalyticsManager.logEvent('payment_method_collapse', {
      [EventParameterKey.AMOUNT]: total,
      [EventParameterKey.PAYMENT_TYPE]:
        paymentOption === 'Cash On Delivery' ? 'cod' : 'prepaid',
      [EventParameterKey.PAYMENT_METHOD]: paymentOption,
      [EventParameterKey.INITIAL_BLOCKED]: isUpgradeToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
    });
  }

  static firePaymentMethodExpandEvent({
    paymentOption,
    isUpgradeToPrepaid,
    total,
  }) {
    AnalyticsManager.logEvent('payment_method_expand', {
      [EventParameterKey.AMOUNT]: total,
      [EventParameterKey.PAYMENT_TYPE]:
        paymentOption === 'Cash On Delivery' ? 'cod' : 'prepaid',
      [EventParameterKey.PAYMENT_METHOD]: paymentOption,
      [EventParameterKey.INITIAL_BLOCKED]: isUpgradeToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
    });
  }

  static firePaymentMethodClickEvent({ orderPayload, extraData, total }) {
    console.tron.log(orderPayload, extraData, total);

    const { paymentMethod = '', upgradableToPrepaid = false } = orderPayload;

    const { method = '', name = '', package_name = '' } = extraData;

    const analyticsMeta = {
      [EventParameterKey.AMOUNT]: total,
      [EventParameterKey.PAYMENT_TYPE]: method !== 'cod' ? 'prepaid' : 'cod',
      [EventParameterKey.PAYMENT_METHOD]: paymentMethod,
      [EventParameterKey.INITIAL_BLOCKED]: upgradableToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
    };

    if (method === 'intent') {
      analyticsMeta.app_name = name;
      analyticsMeta.package_name = package_name;
    }

    AnalyticsManager.logEvent(EventType.payment.PAYMENT_METHOD_CLICK, {
      [EventParameterKey.AMOUNT]: total,
      [EventParameterKey.PAYMENT_TYPE]: method !== 'cod' ? 'prepaid' : 'cod',
      [EventParameterKey.PAYMENT_METHOD]: paymentMethod,
      [EventParameterKey.INITIAL_BLOCKED]: upgradableToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
    });
  }

  static fireOrderCreateEvent({
    payuParams,
    codData,
    extraData,
    cartPricing,
    isContactLess,
    optForPlasticFreePackaging,
    cartItems,
  }) {
    const { txnid, order_id } = payuParams;
    const { method } = extraData;
    const {
      delivery_fee = '',
      discount = '',
      cod_charges = '',
      items_total = '',
      coupon_codes = [],
      total = '',
      cod_availability,
    } = cartPricing;
    const couponCodesArray = _.map(coupon_codes, (coupon) => {
      return coupon.coupon_code;
    });
    let orderMeta = {
      [EventParameterKey.TXN_ID]: txnid,
      [EventParameterKey.ORDER_ID]:
        method === 'cod' ? `${codData.order_id}` : `${order_id}`,
      [EventParameterKey.PRODUCT_COUNT]: cartItems.length,
      [EventParameterKey.ITEM_TOTAL]: items_total,
      [EventParameterKey.DELIVERY_FEE]: delivery_fee,
      [EventParameterKey.DISCOUNT]: discount,
      [EventParameterKey.COD_FEE]: cod_charges,
      [EventParameterKey.TOTAL_VALUE]: total,
      [EventParameterKey.COD_ELIGIBLE]: cod_availability,
      [EventParameterKey.PROMO_CODE_APPLIED]: JSON.stringify(couponCodesArray),
      [EventParameterKey.CONTACTLESS]: isContactLess,
      [EventParameterKey.OPTED_FOR_PLASTIC_FREE_PACKAGING]:
        optForPlasticFreePackaging,
    };

    AnalyticsManager.logEvent(EventType.order.ORDER_CREATE, orderMeta);
  }

  static fireOrderCreateEventUpi({
    upiParams,
    extraData,
    cartPricing,
    isContactLess,
    optForPlasticFreePackaging,
    cartItems,
  }) {
    const { txn_id, order_id } = upiParams;
    const { method } = extraData;
    const {
      delivery_fee = '',
      discount = '',
      cod_charges = '',
      items_total = '',
      coupon_codes = [],
      total = '',
      cod_availability,
    } = cartPricing;
    const couponCodesArray = _.map(coupon_codes, (coupon) => {
      return coupon.coupon_code;
    });
    let orderMeta = {
      [EventParameterKey.TXN_ID]: txn_id,
      [EventParameterKey.ORDER_ID]: `${order_id}`,
      [EventParameterKey.PRODUCT_COUNT]: cartItems.length,
      [EventParameterKey.ITEM_TOTAL]: items_total,
      [EventParameterKey.DELIVERY_FEE]: delivery_fee,
      [EventParameterKey.DISCOUNT]: discount,
      [EventParameterKey.COD_FEE]: cod_charges,
      [EventParameterKey.TOTAL_VALUE]: total,
      [EventParameterKey.COD_ELIGIBLE]: cod_availability,
      [EventParameterKey.PROMO_CODE_APPLIED]: JSON.stringify(couponCodesArray),
      [EventParameterKey.CONTACTLESS]: isContactLess,
      [EventParameterKey.OPTED_FOR_PLASTIC_FREE_PACKAGING]:
        optForPlasticFreePackaging,
    };

    AnalyticsManager.logEvent(EventType.order.ORDER_CREATE, orderMeta);
  }

  static fireSwitchTo({ totalPrice }) {
    AnalyticsManager.logEvent('payment_prepaid_switch', {
      [EventParameterKey.AMOUNT]: totalPrice,
    });
  }

  static fireOrderConfirm({
    orderResponse,
    cartPricing,
    isContactLess,
    optForPlasticFreePackaging,
    cartItems,
    authToken = '',
    payuParams = {},
  }) {
    const {
      delivery_fee = '',
      discount = '',
      cod_charges = '',
      items_total = '',
      coupon_codes = [],
      total = '',
    } = cartPricing;
    const { txnId = '' } = payuParams;
    const couponCodesArray = _.map(coupon_codes, (coupon) => {
      return coupon.coupon_code;
    });
    const { order_id = '', skus = [], targeting_events = [] } = orderResponse;
    const orderMeta = {
      [EventParameterKey.TXN_ID]: isPresent(txnId) ? txnId : '',
      [EventParameterKey.ORDER_ID]: `${order_id}`,
      [EventParameterKey.PRODUCT_COUNT]: cartItems.length,
      [EventParameterKey.ITEM_TOTAL]: items_total,
      [EventParameterKey.DELIVERY_FEE]: delivery_fee,
      [EventParameterKey.DISCOUNT]: discount,
      [EventParameterKey.COD_FEE]: cod_charges,
      [EventParameterKey.TOTAL_VALUE]: total,
      [EventParameterKey.PROMO_CODE_APPLIED]: couponCodesArray,
      [EventParameterKey.CONTACTLESS]: isContactLess,
      [EventParameterKey.OPTED_FOR_PLASTIC_FREE_PACKAGING]:
        optForPlasticFreePackaging,
      [EventParameterKey.IS_GUEST_USER]: Utility.isBlank(authToken),
      [EventParameterKey.CURRENCY]: CURRENCY.INR,
      [EventParameterKey.VALUE]: parseFloat(total),
    };
    AnalyticsManager.logEvent(EventType.order.ORDER_CONFIRM, orderMeta);
    const items = [];

    _.forEach(skus, (e) => {
      items.push({ id: e?.id });
    });

    AnalyticsUtility.fireTargetingEvents(targeting_events, order_id);

    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.PURCHASE,
      {
        [EventParameterKey.TRANSACTION_ID]: `${order_id}`,
        [EventParameterKey.VALUE]: parseFloat(total),
        [EventParameterKey.CURRENCY]: CURRENCY.INR,
        [EventParameterKey.ITEMS]: items,
        shipping: delivery_fee,
        tax: 0,
        affiliation: '',
        coupon: couponCodesArray,
      },
    );
    AnalyticsManager.logPurchaseEvent(parseFloat(total), CURRENCY.INR, {
      [EventParameterKey.FB.EVENT_PARAM_NUM_ITEMS]: items.length,
      [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: JSON.stringify(
        _.map(skus, (sku) => sku.id),
      ),
      [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
      // [EventParameterKey.FB.EVENT_PARAM_CONTENT]: [ TODO: Get quantity of each sku and then send this parameter]
    });

    AnalyticsManager.logEvent('ecommerce_purchase', {
      items,
      transaction_id: `${order_id}`,
      value: parseFloat(total),
      shipping: delivery_fee,
      currency: CURRENCY.INR,
      coupon: couponCodesArray,
    });
  }

  static fireOrderConfirmUpi({
    orderResponse,
    cartPricing,
    isContactLess,
    optForPlasticFreePackaging,
    cartItems,
    authToken = '',
    payuParams = {},
  }) {
    const {
      delivery_fee = '',
      discount = '',
      cod_charges = '',
      items_total = '',
      coupon_codes = [],
      total = '',
    } = cartPricing;
    const { txn_id = '' } = payuParams;
    const couponCodesArray = _.map(coupon_codes, (coupon) => {
      return coupon.coupon_code;
    });
    const { order_id = '', skus = [], targeting_events = [] } = orderResponse;
    const orderMeta = {
      [EventParameterKey.TXN_ID]: isPresent(txn_id) ? txn_id : '',
      [EventParameterKey.ORDER_ID]: `${order_id}`,
      [EventParameterKey.PRODUCT_COUNT]: cartItems.length,
      [EventParameterKey.ITEM_TOTAL]: items_total,
      [EventParameterKey.DELIVERY_FEE]: delivery_fee,
      [EventParameterKey.DISCOUNT]: discount,
      [EventParameterKey.COD_FEE]: cod_charges,
      [EventParameterKey.TOTAL_VALUE]: total,
      [EventParameterKey.PROMO_CODE_APPLIED]: couponCodesArray,
      [EventParameterKey.CONTACTLESS]: isContactLess,
      [EventParameterKey.OPTED_FOR_PLASTIC_FREE_PACKAGING]:
        optForPlasticFreePackaging,
      [EventParameterKey.IS_GUEST_USER]: Utility.isBlank(authToken),
      [EventParameterKey.CURRENCY]: CURRENCY.INR,
      [EventParameterKey.VALUE]: parseFloat(total),
    };
    AnalyticsManager.logEvent(EventType.order.ORDER_CONFIRM, orderMeta);
    const items = [];

    _.forEach(skus, (e) => {
      items.push({ id: e?.id });
    });

    AnalyticsUtility.fireTargetingEvents(targeting_events, order_id);

    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.PURCHASE,
      {
        [EventParameterKey.TRANSACTION_ID]: `${order_id}`,
        [EventParameterKey.VALUE]: parseFloat(total),
        [EventParameterKey.CURRENCY]: CURRENCY.INR,
        [EventParameterKey.ITEMS]: items,
        shipping: delivery_fee,
        tax: 0,
        affiliation: '',
        coupon: couponCodesArray,
      },
    );
    AnalyticsManager.logPurchaseEvent(parseFloat(total), CURRENCY.INR, {
      [EventParameterKey.FB.EVENT_PARAM_NUM_ITEMS]: items.length,
      [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: JSON.stringify(
        _.map(skus, (sku) => sku.id),
      ),
      [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
      // [EventParameterKey.FB.EVENT_PARAM_CONTENT]: [ TODO: Get quantity of each sku and then send this parameter]
    });

    AnalyticsManager.logEvent('ecommerce_purchase', {
      items,
      transaction_id: `${order_id}`,
      value: parseFloat(total),
      shipping: delivery_fee,
      currency: CURRENCY.INR,
      coupon: couponCodesArray,
    });
  }

  static fireTargetingEvents(targeting_events, order_id) {
    _.forEach(targeting_events, (e = {}) => {
      if (isPresent(e.name)) {
        AnalyticsManager.logEvent(e.name, {
          order_id,
          ...e.params,
        });
      }
    });
  }

  static firePaymentInitiate({
    extraData,
    cartItems,
    UtoP,
    payuParams,
    cartPricing,
    networkInfo,
  }) {
    const { coupon_codes } = cartPricing;
    const { isUpgradeToPrepaid } = UtoP;
    const { method, name, package_name } = extraData;
    const networkData = Utility.getNetworkInfo(networkInfo);

    const analyticsMeta = {
      ...networkData,
      [EventParameterKey.ORDER_ID]: `${payuParams.order_id}`,
      [EventParameterKey.TOTAL_VALUE]: payuParams.amount,
      [EventParameterKey.PAYMENT_TYPE]: method,
      [EventParameterKey.INITIAL_BLOCKED]: isUpgradeToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
    };

    if (method === 'intent') {
      analyticsMeta.app_name = name;
      analyticsMeta.package_name = package_name;
    }

    AnalyticsManager.logEvent(
      EventType.payment.PAYMENT_INITIATE,
      analyticsMeta,
    );

    let items = [];
    const coupons = [];

    items = Utility.getItems(cartItems);
    _.forEach(coupon_codes, (e) => {
      coupons.push(e.coupon_code);
    });
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.ADD_PAYMENT_INFO,
      {
        payment_type: method,
        currency: CURRENCY.INR,
        coupon: coupons,
        value: payuParams.amount,
        items,
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_ADDED_PAYMENT_INFO,
      payuParams.amount,
      {
        [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: CURRENCY.INR,
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: _.map(
          items,
          (item) => item.id,
        ),
      },
    );
  }

  static firePaymentInitiateCod({
    codData = {},
    cartItems = [],
    cartPricing = {},
  }) {
    const { coupon_codes, total = '' } = cartPricing;

    const analyticsMeta = {
      [EventParameterKey.ORDER_ID]: `${codData?.order_id}`,
      [EventParameterKey.TOTAL_VALUE]: total,
      [EventParameterKey.PAYMENT_TYPE]: 'cod',
    };

    AnalyticsManager.logEvent(
      EventType.payment.PAYMENT_INITIATE,
      analyticsMeta,
    );

    let items = [];
    const coupons = [];

    items = Utility.getItems(cartItems);
    _.forEach(coupon_codes, (e) => {
      coupons.push(e.coupon_code);
    });
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.ADD_PAYMENT_INFO,
      {
        payment_type: 'cod',
        currency: CURRENCY.INR,
        coupon: coupons,
        value: total,
        items,
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_ADDED_PAYMENT_INFO,
      total,
      {
        [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: CURRENCY.INR,
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: _.map(
          items,
          (item) => item?.id,
        ),
      },
    );
  }

  static firePaymentInitiateUpi({
    extraData = {},
    cartItems = {},
    UtoP = {},
    payuParams = {},
    cartPricing = {},
    networkInfo = {},
  }) {
    const { coupon_codes } = cartPricing;
    const { isUpgradeToPrepaid } = UtoP;
    const { method, name, package_name } = extraData;
    const networkData = Utility.getNetworkInfo(networkInfo);

    const analyticsMeta = {
      ...networkData,
      [EventParameterKey.ORDER_ID]: `${payuParams.order_id}`,
      [EventParameterKey.TOTAL_VALUE]: payuParams?.txn_amount || '',
      [EventParameterKey.PAYMENT_TYPE]: 'upi',
      [EventParameterKey.INITIAL_BLOCKED]: isUpgradeToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
    };

    AnalyticsManager.logEvent(
      EventType.payment.PAYMENT_INITIATE,
      analyticsMeta,
    );

    let items = [];
    const coupons = [];

    items = Utility.getItems(cartItems);
    _.forEach(coupon_codes, (e) => {
      coupons.push(e.coupon_code);
    });
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.ADD_PAYMENT_INFO,
      {
        payment_type: method,
        currency: CURRENCY.INR,
        coupon: coupons,
        value: payuParams?.txn_amount || '',
        items,
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_ADDED_PAYMENT_INFO,
      payuParams?.txn_amount || '',
      {
        [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: CURRENCY.INR,
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: _.map(
          items,
          (item) => item.id,
        ),
      },
    );
  }

  static firePaymentMethodUpgrade(meta) {
    console.tron.log(meta);
  }

  static firePayTmAddMoneyInitiate(meta) {
    console.tron.log(meta);
  }

  static firePaymentCancelEvent(meta) {
    console.tron.log(meta);
  }

  static firePaymentStatus({
    orderResponse,
    orderPayload,
    payuParams = {},
    UtoP,
    networkInfo = {},
  }) {
    const { extraData: { method = '', name = '', package_name = '' } = {} } =
      orderPayload;

    let { txnId = '', order_id = '', amount = '' } = payuParams;
    if (method === 'cod') {
      txnId = '';
      order_id = orderResponse.order_id;
      amount = orderResponse.total;
    }
    const { isUpgradeToPrepaid } = UtoP;

    const orderStatus = orderResponse.status;

    const networkData = Utility.getNetworkInfo(networkInfo);

    const analyticsMeta = {
      ...networkData,
      [EventParameterKey.TXN_ID]: txnId,
      [EventParameterKey.ORDER_ID]: order_id,
      [EventParameterKey.TOTAL_VALUE]: amount,
      [EventParameterKey.PAYMENT_METHOD]: method,
      [EventParameterKey.PAYMENT_TYPE]: method === 'cod' ? 'cod' : 'prepaid',
      [EventParameterKey.INITIAL_BLOCKED]: isUpgradeToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
    };

    if (method === 'intent') {
      analyticsMeta.app_name = name;
      analyticsMeta.package_name = package_name;
    }

    if (orderStatus === 'success') {
      AnalyticsManager.logEvent(
        EventType.payment.PAYMENT_SUCCESS,
        analyticsMeta,
      );
    }

    if (Utility.isBlank(orderStatus) && orderResponse.code === 'cancelled') {
      analyticsMeta.reason = 'canceled_by_user';
      AnalyticsManager.logEvent(
        EventType.payment.PAYMENT_CANCEL,
        analyticsMeta,
      );
      return;
    }

    if (Utility.isBlank(orderStatus) || orderStatus === 'failure') {
      AnalyticsManager.logEvent(
        EventType.payment.PAYMENT_FAILURE,
        analyticsMeta,
      );
    }
  }

  static firePaymentFailure({
    orderResponse,
    orderPayload,
    payuParams = {},
    UtoP,
    networkInfo = {},
  }) {
    let { txn_id = '', order_id = '', txn_amount = '' } = payuParams;
    const { isUpgradeToPrepaid } = UtoP;
    const networkData = Utility.getNetworkInfo(networkInfo);
    const analyticsMeta = {
      ...networkData,
      [EventParameterKey.TXN_ID]: txn_id,
      [EventParameterKey.ORDER_ID]: order_id,
      [EventParameterKey.TOTAL_VALUE]: txn_amount,
      [EventParameterKey.PAYMENT_METHOD]: 'upi',
      [EventParameterKey.PAYMENT_TYPE]: 'prepaid',
      [EventParameterKey.INITIAL_BLOCKED]: isUpgradeToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
    };

    AnalyticsManager.logEvent(EventType.payment.PAYMENT_FAILURE, analyticsMeta);
  }

  static firePaymentSuccess({
    orderResponse,
    orderPayload,
    payuParams = {},
    UtoP,
    networkInfo = {},
  }) {
    let { txn_id = '', order_id = '', txn_amount = '' } = payuParams;
    const { isUpgradeToPrepaid } = UtoP;
    const networkData = Utility.getNetworkInfo(networkInfo);
    const analyticsMeta = {
      ...networkData,
      [EventParameterKey.TXN_ID]: txn_id,
      [EventParameterKey.ORDER_ID]: order_id,
      [EventParameterKey.TOTAL_VALUE]: txn_amount,
      [EventParameterKey.PAYMENT_METHOD]: 'upi',
      [EventParameterKey.PAYMENT_TYPE]: 'prepaid',
      [EventParameterKey.INITIAL_BLOCKED]: isUpgradeToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
    };

    AnalyticsManager.logEvent(EventType.payment.PAYMENT_SUCCESS, analyticsMeta);
  }

  static fireCartPromptVisible(cartPrompts) {
    const promptMeta = Utility.getPromptMeta(cartPrompts);
    AnalyticsManager.logEvent('prompt_visible', {
      ...promptMeta,
      location: 'cart',
    });
  }

  static firePageLoadEvent(screen) {
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
      [EventParameterKey.SCREEN_NAME]: screen,
    });
  }

  static fireRetryPayment(paymentMethodPayload) {
    AnalyticsManager.logEvent('retry_payment', paymentMethodPayload);
  }

  static fireCartViewEvent({ cartItems, cartPricing, address, authToken }) {
    const {
      total = '',
      items_total: itemsTotal = '',
      last_payment_method: lastPaymentMethod,
    } = cartPricing;

    const addressPincode = getCurrentAddressPinCode(address);

    const cartMeta = {
      [EventParameterKey.ITEM_COUNT]: cartItems.length,
      [EventParameterKey.CART_TOTAL]: total,
      [EventParameterKey.TOTAL_VALUE]: itemsTotal,
      [EventParameterKey.ADDRESS_PRESENT]: isPresent(address),
      [EventParameterKey.ADDRESS_ID]: getCurrentAddress(address),
      [EventParameterKey.IS_GUEST_USER]: Utility.isBlank(authToken),
      [EventParameterKey.LAST_PAYMENT_METHOD]:
        !!lastPaymentMethod && isPresent(lastPaymentMethod.method),
      [EventParameterKey.LAST_PAYMENT_METHOD_NAME]:
        !!lastPaymentMethod && isPresent(lastPaymentMethod.method)
          ? lastPaymentMethod.method
          : '',
      [EventParameterKey.PIN_CODE]: addressPincode,
    };
    AnalyticsManager.logEvent(EventType.discoveryEvents.CART_VIEW, cartMeta);

    let items = [];
    items = Utility.getItems(cartItems);
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.VIEW_CART,
      {
        [EventParameterKey.VALUE]: total,
        [EventParameterKey.CURRENCY]: CURRENCY.INR,
        [EventParameterKey.ITEMS]: items,
      },
    );
  }

  static firePaymentMethodView({
    savedCards = [],
    cartPricing,
    UtoP,
    networkInfo = {},
    authToken = '',
  }) {
    const {
      total = '',
      last_payment_method: lastPaymentMethod,
      cod_availability,
      source = '',
      cod_error_message = '',
      cod_error_code = '',
    } = cartPricing;
    const { isUpgradeToPrepaid } = UtoP;
    const networkData = Utility.getNetworkInfo(networkInfo);

    AnalyticsManager.logEvent(EventType.payment.PAYMENT_METHOD_VIEW, {
      ...networkData,
      [EventParameterKey.TOTAL_VALUE]: total,
      [EventParameterKey.SAVED_CARD_COUNT]: savedCards.length,
      [EventParameterKey.COD_ELIGIBLE]: cod_availability.toString(),
      [EventParameterKey.SOURCE]: source,
      [EventParameterKey.INITIAL_BLOCKED]: isUpgradeToPrepaid
        ? EventParameterValue.FLOW.UPGRADE
        : EventParameterValue.FLOW.INITIAL,
      [EventParameterKey.LAST_PAYMENT_METHOD]:
        !Utility.isBlank(lastPaymentMethod) &&
        !Utility.isBlank(lastPaymentMethod.method),
      [EventParameterKey.LAST_PAYMENT_METHOD_NAME]:
        !Utility.isBlank(lastPaymentMethod) &&
        !Utility.isBlank(lastPaymentMethod.method)
          ? lastPaymentMethod.method
          : '',
      [EventParameterKey.COD_ERROR_CODE]: cod_error_code || '',
      [EventParameterKey.COD_ERROR_MESSAGE]: cod_error_message || '',
      [EventParameterKey.IS_GUEST_USER]: Utility.isBlank(authToken),
    });
  }

  static setGuestTokenAsProperties(guestToken) {
    AnalyticsManager.setUserProperty('guest_token', `${guestToken}`);
    if (Utility.isAndroid()) {
      NativeModules.UserPreferences.saveGuestToken(`${guestToken}`);
    }
  }

  static logDeepLinkStatusEvent = (state, ...extraParams) => {
    AnalyticsManager.logEvent(EventType.deeplink.DEEP_LINK_OPEN, {
      [EventParameterKey.LINK_TYPE]:
        EventParameterValue.LINKS.LINK_TYPE.dynamicLink,
      [EventParameterKey.STATUS]: state,
      ...extraParams,
    });
  };

  static fireAppLoadEventWithSource(appFirstOpened) {
    let eventFired = false;
    if (Utility.isAndroid()) {
      NativeModules.DeviceDetails.getNotificationStatus((err, value) => {
        if (value === true && eventFired === false) {
          AnalyticsUtility.fireAppLoadEvent(
            APP_LOAD_SOURCE.NOTIFICATION,
            appFirstOpened,
          );
          eventFired = true;
        }
      });
      if (!eventFired) {
        NativeModules.DeviceDetails.getLaunchSource((err, value) => {
          if (value === true && eventFired === false) {
            AnalyticsUtility.fireAppLoadEvent(
              APP_LOAD_SOURCE.PERSONALIZED_SHORTCUT,
            );
          } else {
            AnalyticsUtility.fireAppLoadEvent(
              APP_LOAD_SOURCE.ORGANIC,
              appFirstOpened,
            );
          }
        });
      }
    } else {
      AnalyticsUtility.fireAppLoadEvent(
        APP_LOAD_SOURCE.ORGANIC,
        appFirstOpened,
      );
    }
  }

  static recordTime(analyticsMeta, functionCallback, actionFunction, ...args) {
    const timer = setTimeout(() => {
      AnalyticsUtility.firePageLoadDelayEvent(analyticsMeta);
      clearTimeout(timer);
    }, 5000);
    if (actionFunction) {
      actionFunction(...args, (success, response, pageNo = undefined) => {
        clearTimeout(timer);
        functionCallback(success, response, pageNo);
      });
    }
  }
}

export default AnalyticsUtility;
