import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
  Keyboard,
} from 'react-native';
import React, { useState } from 'react';
import { TextField } from 'react-native-material-textfield';
import { useDispatch } from 'react-redux';
import colors from '../../theme/Colors';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import Utility from '../../utils/Utility';
import { postShopifyOrderEmail } from '../../actions/ActionTypes';
import { useEmail } from '../../lib/hooksUtil';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';

export default function Email({ emailVerificationError }) {
  const { email, setEmail } = useEmail();
  const [sendingVerificationLink, setSendingVerificationLink] = useState(false);
  const [emailError, setEmailError] = useState(emailVerificationError);
  const [emailSent, setEmailSent] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const dispatch = useDispatch();

  const showEmailInput = RemoteConfig.getBooleanValue(
    REMOTE_CONFIG_KEYS.show_email_input_on_past_orders,
  );

  if (!showEmailInput) return null;

  const handleChangeText = (text) => setEmail(text);
  const submitEmail = () => {
    Keyboard.dismiss();
    if (!email) {
      return setEmailError('Please enter an email id.');
    }
    const validate = Utility.validateEmail(email);
    if (!validate) {
      return setEmailError('Please enter a valid email.');
    }
    setSendingVerificationLink(true);
    dispatch(
      postShopifyOrderEmail(email, (success, response) => {
        setSendingVerificationLink(false);
        if (success) {
          setRetryCount((count) => count + 1);
          setEmailSent(true);
        } else {
          const { error } = response;
          if (error) {
            setEmailError(error);
          }
        }
      }),
    );
  };

  if (emailSent) {
    return (
      <View style={styles.container}>
        <Text style={styles.notice}>
          We have sent verification link to your email
        </Text>
        <Text style={styles.email}>{email}</Text>
        <View style={styles.resendContainer}>
          <Text style={styles.email}>Didn't receive verification link?</Text>
          <TouchableOpacity
            disabled={retryCount > 1 || sendingVerificationLink}
            onPress={submitEmail}
          >
            <Text
              style={
                retryCount > 1
                  ? styles.disabledResendButton
                  : styles.resendButton
              }
            >
              Resend
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
  return (
    <KeyboardAvoidingView
      behavior="position"
      keyboardVerticalOffset={Utility.isAndroid() ? 0 : 100}
    >
      <View style={styles.container}>
        <Text style={styles.title}>Not able to find your Order?</Text>
        <Text style={styles.subtitle}>
          Verify email id to check for past orders
        </Text>
        <TextField
          label="Email"
          lineWidth={1}
          activeLineWidth={1}
          blurOnSubmit
          value={email}
          error={emailError}
          hideLabel={false}
          inputContainerStyle={styles.inputContainerStyle}
          onChangeText={handleChangeText}
          returnKeyType="done"
          keyboardType="email-address"
          enablesReturnKeyAutomatically
          style={styles.inputBoxContainer}
          textColor={colors.black}
          autoCompleteType="email"
          autoCapitalize="none"
          tintColor={colors.silver}
        />
        <FoxyShadowButton
          borderRadius={16}
          width={Utility.getScreenWidth() - 32}
          onPress={submitEmail}
          style={styles.button}
          title="Verify Email"
          disabled={sendingVerificationLink}
        />
      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    width: Utility.screenWidth,
  },
  title: {
    marginTop: 10,
    fontWeight: '700',
    fontSize: 16,
    color: colors.black,
    fontFamily: 'Roboto-Medium',
  },
  inputContainerStyle: {
    borderBottomColor: colors.black,
  },
  button: {
    marginVertical: 20,
  },
  resendContainer: {
    flexDirection: 'row',
    marginVertical: 10,
    marginBottom: 25,
  },
  resendButton: {
    marginLeft: 10,
    color: colors.cta.lightBlue,
  },
  disabledResendButton: {
    marginLeft: 10,
    color: colors.disabled,
  },
  notice: {
    marginTop: 15,
    paddingTop: 10,
    color: colors.black,
    fontFamily: 'Roboto-Regular',
  },
  email: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
  },
  subtitle: {
    color: colors.subtitle,
    fontFamily: 'Roboto-Regular',
  },
  inputBoxContainer: {
    fontFamily: 'Roboto-Regular',
  },
});
