export const CREATE_INVITE_REQUEST = 'CREATE_INVITE_REQUEST';
export const BULK_CREATE_INVITE = 'BULK_CREATE_INVITE';
export const ACCEPT_INVITE_REQUEST = 'ACCEPT_INVITE_REQUEST';
export const REJECT_INVITE_REQUEST = 'REJECT_INVITE_REQUEST';
export const GET_LOYALTY_PLAN_INVITATIONS = 'GET_LOYALTY_PLAN_INVITATIONS';
export const GET_REGISTRATION_INVITATIONS = 'GET_REGISTRATION_INVITATIONS';

export const createInviteRequest = (data, callback) => {
  return {
    type: CREATE_INVITE_REQUEST,
    data,
    callback,
  };
};

export const bulkCreateInvite = (invitations, callback) => {
  return {
    type: BULK_CREATE_INVITE,
    invitations,
    callback,
  };
};

export const acceptInviteRequest = (inviteId, callback) => {
  return {
    type: ACCEPT_INVITE_REQUEST,
    inviteId,
    callback,
  };
};

export const rejectInviteRequest = (inviteId, callback) => {
  return {
    type: REJECT_INVITE_REQUEST,
    inviteId,
    callback,
  };
};

export const getLoyaltyPlanInvitations = (callback) => {
  return {
    type: GET_LOYALTY_PLAN_INVITATIONS,
    callback,
  };
};

export const getRegistrationInvitations = (callback) => {
  return {
    type: GET_REGISTRATION_INVITATIONS,
    callback,
  };
};
