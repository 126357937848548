import Utility from '../../../../utils/Utility';
import colors from '../../../../theme/Colors';

const PRODUCT_CONST = {
  NUM_OF_CARDS: {
    LIST: 1,
    GRID: 2,
  },
  SPACING: {
    LIST: 0,
    GRID: 16,
  },
  DEFAULT_DESCRIPTION_HEIGHT: 110,
  HORIZONTAL_SPACING: 32,
  VERTICAL_SPACING: 24,
};

export const PRODUCT = {
  ATTRIBUTE_TYPE: {
    INTEGER: 'integer',
    IMAGE: 'image',
  },
  RATING: {
    color: {
      1: '#ff4243',
      2: '#ffb145',
      3: '#01b460',
      4: '#01b460',
      5: '#01b460',
      0: '#d8d8d8',
    },
  },
  BAG_TEXT: {
    true: 'Added to bag',
    false: 'Add to bag',
  },
  BAG_TEXT_COLOR: {
    true: colors.foxyPink,
    false: colors.foxyBlack,
  },
  ADD_TO_CART_LAYOUT: {
    PRODUCT_CARD: 'productCard',
    PRODUCT_DETAIL: 'productDetail',
    CART: 'cart',
  },
  MAX_LINES: {
    BRAND_NAME: {
      rail: {
        defaultSize: 1,
      },
      matchedProduct: {
        small: 1,
        large: 2,
        defaultSize: 1,
      },
      grid: {
        defaultSize: 1,
      },
      list: {
        small: 1,
        large: 2,
        defaultSize: 1,
      },
      gridRail: {
        defaultSize: 2,
      },
      verticalRail: {
        defaultSize: 1,
      },
      horizontalRail: {
        defaultSize: 1,
      },
      personalisedRail: {
        defaultSize: 1,
      },
      cart: {
        small: 1,
        large: 2,
        defaultSize: 1,
      },
    },
    PRODUCT_NAME: {
      rail: {
        defaultSize: 1,
      },
      grid: {
        defaultSize: 2,
      },
      list: {
        small: 2,
        large: 2,
        defaultSize: 2,
      },
      gridRail: {
        defaultSize: 2,
      },
      verticalRail: {
        defaultSize: 2,
      },
      personalisedRail: {
        defaultSize: 2,
      },
      cart: {
        small: 1,
        large: 2,
        defaultSize: 1,
      },
    },
  },
  SPACING: {
    brandImage: 20,
    brandName: 30,
  },
  CARD: {
    list: {
      small: {
        viewwidth: Utility.getScreenWidth() - 24,
        viewheight: 116,
        imagewidth: 116,
        imageheight: 116,
      },
      large: {
        viewwidth: Utility.getScreenWidth() - 24,
        viewheight: 116,
        imagewidth: 116,
        imageheight: 116,
      },
      defaultSize: {
        viewwidth: Utility.getScreenWidth() - 24,
        viewheight: 116,
        imagewidth: 116,
        imageheight: 116,
      },
    },
    rail: {
      defaultSize: {
        viewwidth: 146,
        viewheight: 209,
        imagewidth: 146,
        imageheight: 146,
      },
      small: {
        viewwidth: 146,
        viewheight: 209,
        imagewidth: 146,
        imageheight: 146,
      },
      large: {
        viewwidth: 146,
        viewheight: 209,
        imagewidth: 146,
        imageheight: 146,
      },
    },
    verticalRail: {
      defaultSize: {
        viewwidth: 332,
        viewheight: 136,
        imagewidth: 120,
        imageheight: 120,
      },
      small: {
        viewwidth: 332,
        viewheight: 136,
        imagewidth: 120,
        imageheight: 120,
      },
      large: {
        viewwidth: 332,
        viewheight: 136,
        imagewidth: 120,
        imageheight: 120,
      },
    },
    personalisedRail: {
      defaultSize: {
        viewwidth: Utility.getScreenWidth() - 24,
        viewheight: 170,
        imagewidth: 140,
        imageheight: 140,
      },
      small: {
        viewwidth: Utility.getScreenWidth() - 24,
        viewheight: 170,
        imagewidth: 140,
        imageheight: 140,
      },
      large: {
        viewwidth: Utility.getScreenWidth() - 24,
        viewheight: 170,
        imagewidth: 140,
        imageheight: 140,
      },
    },

    matchedProduct: {
      defaultSize: {
        viewwidth: Utility.getScreenWidth() - 32,
        viewheight: Utility.getScreenWidth() - 32,
        imagewidth: Utility.getScreenWidth() - 64,
        imageheight: Utility.getScreenWidth() - 64,
      },
    },

    wishlistBuilder: {
      defaultSize: {
        viewwidth: 327,
        viewheight: 327,
        imagewidth: 327,
        imageheight: 327,
      },
    },

    personalizedHorizontal: {
      defaultSize: {
        viewwidth: 223,
        viewheight: 223,
        imagewidth: 223,
        imageheight: 223,
      },
    },

    grid: {
      large: {
        viewwidth: Utility.getDynamicWidthForGrid(
          PRODUCT_CONST.NUM_OF_CARDS.GRID,
          PRODUCT_CONST.SPACING.GRID,
        ),
        viewheight:
          Utility.getDynamicWidthForGrid(
            PRODUCT_CONST.NUM_OF_CARDS.GRID,
            PRODUCT_CONST.SPACING.GRID,
          ) +
          PRODUCT_CONST.DEFAULT_DESCRIPTION_HEIGHT +
          PRODUCT_CONST.VERTICAL_SPACING,
        imagewidth:
          Utility.getDynamicWidthForGrid(
            PRODUCT_CONST.NUM_OF_CARDS.GRID,
            PRODUCT_CONST.SPACING.GRID,
          ) + PRODUCT_CONST.HORIZONTAL_SPACING,
        imageheight:
          Utility.getDynamicWidthForGrid(
            PRODUCT_CONST.NUM_OF_CARDS.GRID,
            PRODUCT_CONST.SPACING.GRID,
          ) + PRODUCT_CONST.VERTICAL_SPACING,
      },
      small: {
        viewwidth: Utility.getDynamicWidthForGrid(
          PRODUCT_CONST.NUM_OF_CARDS.GRID,
          PRODUCT_CONST.SPACING.GRID,
        ),
        viewheight:
          Utility.getDynamicWidthForGrid(
            PRODUCT_CONST.NUM_OF_CARDS.GRID,
            PRODUCT_CONST.SPACING.GRID,
          ) + PRODUCT_CONST.DEFAULT_DESCRIPTION_HEIGHT,
        imagewidth:
          Utility.getDynamicWidthForGrid(
            PRODUCT_CONST.NUM_OF_CARDS.GRID,
            PRODUCT_CONST.SPACING.GRID,
          ) + PRODUCT_CONST.HORIZONTAL_SPACING,
        imageheight:
          Utility.getDynamicWidthForGrid(
            PRODUCT_CONST.NUM_OF_CARDS.GRID,
            PRODUCT_CONST.SPACING.GRID,
          ) + PRODUCT_CONST.VERTICAL_SPACING,
      },
      defaultSize: {
        viewwidth: Utility.getDynamicWidthForGrid(
          PRODUCT_CONST.NUM_OF_CARDS.GRID,
          PRODUCT_CONST.SPACING.GRID,
        ),
        viewheight:
          Utility.getDynamicWidthForGrid(
            PRODUCT_CONST.NUM_OF_CARDS.GRID,
            PRODUCT_CONST.SPACING.GRID,
          ) + PRODUCT_CONST.DEFAULT_DESCRIPTION_HEIGHT,
        imagewidth:
          Utility.getDynamicWidthForGrid(
            PRODUCT_CONST.NUM_OF_CARDS.GRID,
            PRODUCT_CONST.SPACING.GRID,
          ) + PRODUCT_CONST.HORIZONTAL_SPACING,
        imageheight:
          Utility.getDynamicWidthForGrid(
            PRODUCT_CONST.NUM_OF_CARDS.GRID,
            PRODUCT_CONST.SPACING.GRID,
          ) + PRODUCT_CONST.VERTICAL_SPACING,
      },
    },
    cart: {
      defaultSize: {
        viewwidth: Utility.getScreenWidth(),
        viewheight: 77,
        imagewidth: 61,
        imageheight: 61,
      },
      small: {
        viewwidth: Utility.getScreenWidth(),
        viewheight: 64,
        imagewidth: 48,
        imageheight: 48,
      },
      large: {
        viewwidth: Utility.getScreenWidth(),
        viewheight: 77,
        imagewidth: 61,
        imageheight: 61,
      },
    },
  },
  SCREEN: {},
};

export default PRODUCT;
