import React, { PureComponent } from 'react';
import { View, BackHandler } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LAYOUT, TABBAR_CONSTANT } from '../../config/Constants';
import { withMaybe, withEither } from '../../lib/Monads';
import Utility from '../../utils/Utility';
import withNavigation from '../../utils/WithNavigation';
import { TagCard } from '../../components/tag';
import { fetchTagList } from '../../actions/ActionTypes';
import DetailPage from '../../components/shared/DetailPage';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  NavigationBackHeader,
  TabNavigationRightHeader,
} from '../../components/header';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import FilterUtils from '../../components/sort-filter-options/FilterUtils';
import { compose } from 'recompose';
import ColouredCollectionCard from '../../components/Collection/ColouredCollectionCard';
import { getFirebasePerformanceTrace } from '../../utils/PerformanceUtility';

const TagCardHeightConfig = {
  small: 108,
  large: 124,
  defaultSize: 88,
};
class Tag extends PureComponent {
  static getComponentHeight(item) {
    const { size } = item;
    return TagCardHeightConfig[size];
  }
  constructor(props) {
    super(props);
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.TAG_DETAIL);
    this.state = {
      tagData: {},
      paginateLastItem: false,
      stickyPosition: -1,
      selectedFilters: {},
      filters: [],
      quickFilters: [],
      isPageLoading: true,
      serverError: false,
    };
    this.page = 0;
    this.shouldLoadMore = true;
    this.listSlug = '';
    this.prevAppliedFiltersFromModal = [];
    this.selectedQuickFilters = [];
    this.filtersEnabled = false;
    this.fnAutoListPagination = null;
    this.paginateLastItem = false;
  }

  itemDataNullFn = (props) => !props.itemData;

  isLayoutScreen = this.layout === LAYOUT.SCREEN;

  TagCardWithCondition = withMaybe(this.itemDataNullFn)(TagCard);

  transparentImageNullFn = (props) => {
    return (
      Utility.isPresent(props.itemData) &&
      Utility.isBlank(props.itemData.image_url) &&
      Utility.isPresent(props.itemData.transparent_image_url)
    );
  };

  CardsWithCondition = compose(
    withEither(this.transparentImageNullFn, ColouredCollectionCard),
  )(this.TagCardWithCondition);

  componentDidMount = () => {
    this.loadData();
    if (Utility.isAndroid() && this.isLayoutScreen) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.handleBackPress,
      );
    }
  };

  componentWillUnmount() {
    if (Utility.isAndroid() && this.isLayoutScreen) {
      this.backHandler.remove();
    }
  }

  onEndReached = () => {
    if (!this.shouldLoadMore) {
      // dont call api unnecessarily if end is reached
      return;
    }
    if (this.paginateLastItem) {
      return;
    }

    this.page += 1;
    setTimeout(() => {
      this.loadData();
    }, 10);
  };

  loadData = () => {
    const { navigation, route, layout, fetchTagList } = this.props;
    const { tagData } = this.state;
    const slugString = Utility.isBlank(this.listSlug)
      ? route.params?.slug
      : this.listSlug;
    if (layout === undefined && !Utility.isBlank(slugString)) {
      const currentPage = this.page;
      this.trace.start();
      fetchTagList(slugString, currentPage, (success, data, status) => {
        if (success) {
          Utility.setPageLoadEnd('tag_detail', currentPage);
          if (currentPage === 0) {
            if (Utility.isBlank(route.params?.name)) {
              const { entity_details: { name = '' } = {} } = data;
              setTimeout(() => {
                //todo_vishwender
                this.props.navigation.setParams({
                  name: name,
                });
              }, 10);
            }

            if (!this.filtersEnabled) {
              const { paginate_last_item = false } = data;
              this.paginateLastItem = paginate_last_item;
              this.addFiltersObjectIntoListData(data);
            } else {
              const listData =
                FilterUtils.appendFilteredListToMasterListObjects(
                  this.listSlug,
                  data,
                  this.state.tagData,
                );
              this.setState({ tagData: listData });
            }
            // this.setState(
            //   {
            //     tagData: data,
            //   },
            //   () => {
            //     setTimeout(this.fireListRenderedEvent, 1000);
            //   },
            // );
          } else if (
            Utility.isPresent(data) &&
            Utility.isPresent(data.objects) &&
            data.objects.length > 0 &&
            Utility.isPresent(tagData.objects)
          ) {
            const newObjects = tagData.objects.contact(data.objects); // [...tagData.objects, ...data.objects];
            // const newTagData = Object.assign({}, tagData);
            tagData.objects = newObjects;
            tagData.display_count = newObjects.length;
            this.setState({
              tagData,
            });
          }
          if (Utility.isBlank(data) || Utility.isBlank(data.objects)) {
            this.shouldLoadMore = false;
          }
          this.trace.stop();
          AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
            [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.TAG_DETAIL,
          });
        } else {
          Utility.clearPageLoadTimer();
          this.setState({
            isPageLoading: false,
            serverError: !success && status !== 404,
          });
        }
      });
    }
  };

  loadFilteredData = (slug) => {
    const { fetchTagList } = this.props;
    this.page = 0;
    // this.loadData(true);
    fetchTagList(this.listSlug, this.page, (success, data) => {
      const { tagData } = this.state;
      // const listObjects = Utility.removeShimmerInListObject(tagData);
      // const indexOfProductList = listObjects.length;
      // tagData.objects = listObjects;
      // tagData.objects[indexOfProductList] = data;
      // tagData.objects[indexOfProductList]['filtersEnabled'] = this.listSlug.includes('&');
      const listData = FilterUtils.appendFilteredListToMasterListObjects(
        this.listSlug,
        data,
        this.state.tagData,
      );
      const paginateLastItem = listData.paginate_last_item;

      console.tron.log('List Data ', listData);
      this.setState(
        {
          tagData: listData,
        },
        () => {
          if (paginateLastItem) {
            this.fnAutoListPagination(this.listSlug);
          }
        },
      );
    });
  };

  fireListRenderedEvent = () => {
    const { tagData } = this.state;
    Utility.fireListRenderedEvent(tagData, SCREEN_CONSTANTS.TAG_DETAIL);
  };

  addFiltersObjectIntoListData = (apiResponse) => {
    const response = apiResponse;
    if (apiResponse.display !== 'vertical') {
      this.setState(
        {
          tagData: response,
        },
        () => {
          // if (!this.paginateLastItem) {
          //   this.onEndReached();
          // }
          setTimeout(this.fireListRenderedEvent, 1000);
        },
      );
      return;
    }
    const {
      list: objects,
      position: stickyPosition,
      slug: listSlug,
    } = Utility.createFilterObjectIfExists(response.objects, true, false, true);
    response.objects = objects;
    const { filters, quickFilters } = FilterUtils.getFiltersFromList(response);

    this.listSlug = listSlug;
    this.setState(
      {
        tagData: response,
        filters,
        quickFilters,
        stickyPosition,
      },
      () => {
        // if (!this.paginateLastItem) {
        //   this.onEndReached();
        // }
        setTimeout(this.fireListRenderedEvent, 1000);
      },
    );
  };

  getQuickFiltersListRef = (ref) => {
    this.quickFiltersRef = ref;
  };

  onFiltersPress = (item, added, index) => {
    const { navigation } = this.props;
    const {
      tagData,
      tagData: { id = '', name = '' },
      selectedFilters,
      filters,
    } = this.state;
    const { list } = FilterUtils.getList(tagData);

    if (
      item.criteria_name === 'quick_filter_sort_by' ||
      item.criteria_name === 'quick_filter_foxy_match'
    ) {
      navigation.navigate('SortingFilteringModal', {
        selectedFilters,
        filterModel: filters,
        onFilterApplyPress: this.onFiltersPress,
        listName: name,
        listId: id,
        listSlug: slug,
        screenName: SCREEN_CONSTANTS.TAG,
        entityName: tagData?.name,
        listData: tagData,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
      });
      return;
    }

    const {
      slug,
      pageNo,
      allowNextPageLoad,
      filtersEnabled,
      groupedSelectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
    } = FilterUtils.onFilterApplied(
      item,
      added,
      index,
      list,
      selectedFilters,
      this.quickFiltersRef,
      this.listSlug,
      this.prevAppliedFiltersFromModal,
      this.selectedQuickFilters,
      SCREEN_CONSTANTS.TAG,
      tagData?.name,
    );
    // let trimmerSlug = slug;
    // if (slug.indexOf('Nan') !== -1) {
    //   trimmerSlug = slug.substr(slug.indexOf('Nan') + 7, slug.length);
    // }

    this.listSlug = slug;
    this.page = pageNo;

    this.allowNextPageLoad = allowNextPageLoad;
    this.selectedQuickFilters = selectedQuickFilters;
    this.filtersEnabled = filtersEnabled;
    this.prevAppliedFiltersFromModal = prevAppliedFiltersFromModal;
    tagData.objects = Utility.addShimmerInListObject(this.state.tagData);
    this.setState({
      selectedFilters: groupedSelectedFilters,
      tagData,
    });

    this.loadFilteredData(this.listSlug);
  };

  resetFilters = () => {
    const { navigation } = this.props;
    this.page = 0;
    this.filtersEnabled = false;
    this.listSlug = this.listSlug.substr(0, this.listSlug.indexOf('&'));
    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
    });
    this.selectedQuickFilters = [];

    this.loadFilteredData(this.listSlug);
  };

  setListPaginatedCallApiRef = (fnAutoListPagination) => {
    console.tron.log('Final Settlement');
    this.fnAutoListPagination = fnAutoListPagination;
  };

  handleBackPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  render() {
    let { navigation, route, layout, itemData, previousScreen } = this.props;
    const {
      stickyPosition,
      selectedFilters,
      filters,
      quickFilters,
      isPageLoading,
    } = this.state;

    const { tagData, paginateLastItem = false, serverError } = this.state;
    layout = layout || (route.params?.display ?? LAYOUT.SCREEN);
    if (Utility.isBlank(itemData)) {
      if (layout === LAYOUT.SCREEN) {
        return (
          <>
            <DetailPage
              itemData={this.state.tagData}
              layout={layout}
              // onEndReached={this.onEndReached}
              search // FIXME: hack to remove header from list having quick filters
              getQuickFiltersListRef={this.getQuickFiltersListRef}
              stickyPosition={stickyPosition}
              selectedFilters={selectedFilters}
              onFiltersPress={this.onFiltersPress}
              onPressResetButton={this.resetFilters}
              setListPaginatedCallApiRef={this.setListPaginatedCallApiRef}
              isPageLoading={isPageLoading}
              serverError={serverError}
              previousScreen={previousScreen}
            />
          </>
        );
      }
      return null;
    }
    if (layout === LAYOUT.SCREEN) {
      return <DetailPage itemData={tagData} id={itemData.id} layout={layout} />;
    }

    return (
      <View onLayout={this.onLayout}>
        <this.CardsWithCondition
          {...this.props}
          itemData={itemData}
          id={itemData.id}
          layout={layout}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchTagList }, dispatch),
});

export default withNavigation(connect(null, mapDispatchToProps)(Tag));

export const TagHeaderOptions = ({ route }) => ({
  title: route.params?.name ?? '',
  headerTitleAlign: 'center',
  headerLeft: () => <NavigationBackHeader />,
  headerRight: () => (
    <TabNavigationRightHeader tabBar={TABBAR_CONSTANT.category} />
  ),
  headerShadowVisible: false,
  animation: Utility.isIOS() ? 'default' : 'slide_from_right',
});

export const TagHeaderOptionsWithNavigationHeader = ({ route }) => ({
  title: route.params?.name ?? '',
  headerShown: true,
  headerTitleAlign: 'center',
  headerLeft: () => <NavigationBackHeader />,
  headerRight: () => (
    <TabNavigationRightHeader tabBar={TABBAR_CONSTANT.category} />
  ),
  headerShadowVisible: false,
  animation: Utility.isIOS() ? 'default' : 'slide_from_right',
});
