import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Clipboard,
  TextInput,
  TouchableWithoutFeedback,
} from 'react-native';
import { ids, styles } from './styles';
import LinearGradient from 'react-native-linear-gradient';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import { SocialMediaOptions, getProAccountObjectForId } from './OneLinkConfig';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { updateInfluencerData } from '../../actions/InfluencerActions';

export default function DashboardSocialMediaHandlesCard(props) {
  const {
    onEditPress = () => {},
    pro_accounts = [],
    refresh = () => {},
  } = props;
  const [editableTextField, setEditableTextField] = useState('');
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const filteredMediaHandles = SocialMediaOptions.filter((socialMediaOption) =>
    pro_accounts.some(
      ({ social_media_platform_id }) =>
        socialMediaOption.social_media_platform_id === social_media_platform_id,
    ),
  );

  const updateInfluencer = (item, value) => {
    const proAccountId =
      pro_accounts.find(
        (proAccount) =>
          proAccount.social_media_platform_id === item.social_media_platform_id,
      )?.id || '';
    const postData = {
      user: {
        pro_accounts_attributes: [
          {
            id: proAccountId,
            button_text: value,
          },
        ],
      },
    };
    dispatch(
      updateInfluencerData(postData, (success, response) => {
        if (success) {
          setEditableTextField('');
          refresh();
        }
      }),
    );
  };

  const SocialMediaOptionTile = ({ item }) => {
    const {
      image = '',
      title = '',
      backgroundColor = '',
      social_media_platform_id = '',
    } = item;

    const tileTitle =
      getProAccountObjectForId(pro_accounts, social_media_platform_id)
        ?.button_text || title;
    return (
      <TouchableWithoutFeedback
        onLongPress={() => setEditableTextField(item.social_media_platform_id)}
      >
        <View
          style={[
            styles.influencerPreviewTileContainer,
            { backgroundColor, padding: 8, marginVertical: 6 },
          ]}
        >
          <FastImageView
            source={image}
            style={styles.socialMediaHandleTileImage}
          />
          <TextInput
            numberOfLines={1}
            ellipseMode='tail'
            defaultValue={tileTitle}
            style={styles.socialMediaHandleTileText}
            editable={editableTextField === item.social_media_platform_id}
            onSubmitEditing={({ target }) =>
              updateInfluencer(item, target.value)
            }
          />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  return (
    <LinearGradient
      colors={['#e3e3e3', '#e6f2fb']}
      style={[styles.dashboardInfoCardContainer, { alignItems: 'flex-start' }]}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 0 }}
    >
      <Text style={[styles.registrationCompletedHeading, { marginTop: 12 }]}>
        Your Social Handles
      </Text>
      <Text
        style={[
          styles.registrationCompletedSubheading,
          { marginTop: 6, marginBottom: 12 },
        ]}
      >
        Press and hold to edit message
      </Text>

      {filteredMediaHandles.map((item) => {
        return <SocialMediaOptionTile item={item} />;
      })}

      <TouchableOpacity
        style={styles.editButtonContainer}
        onPress={onEditPress}
      >
        <FastImageView source={images.edit_icon} style={styles.editButton} />
      </TouchableOpacity>
    </LinearGradient>
  );
}
