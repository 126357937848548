import { StyleSheet, View } from 'react-native';
import React from 'react';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

const OfferWalletCard = () => {
  return (
    <View style={{ marginLeft: 12 + 28 }}>
      <ShimmerPlaceHolder autoRun style={styles.couponContainer} colorShimmer={['#fafafa', '#eeeeee', '#fafafa']} />
      <ShimmerPlaceHolder autoRun style={styles.circle} colorShimmer={['#fafafa', '#eeeeee', '#fafafa']} />
    </View>
  );
};

const WalletShimmer = () => {
  return (
    <View style={{ backgroundColor: '#fff', paddingTop: 12 }}>
      <ShimmerPlaceHolder autoRun style={styles.name} colorShimmer={['#fafafa', '#eeeeee', '#fafafa']} />
      <View style={styles.contentContainer}>
        <OfferWalletCard />
        <OfferWalletCard />
      </View>
    </View>
  );
};

export default WalletShimmer;

const styles = StyleSheet.create({
  couponContainer: {
    backgroundColor: '#FFE4F4',
    width: 241,
    height: 100,
    borderRadius: 16,
    paddingVertical: 8,
    justifyContent: 'space-between',
  },
  container: {},
  contentContainer: {
    backgroundColor: colors.white,
    flexDirection: 'row',
  },
  circle: {
    height: 56,
    width: 56,
    borderRadius: 28,
    position: 'absolute',
    top: 22,
    left: -28,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
  },
  maxDiscount: { fontSize: 16, fontFamily: 'Roboto-Bold', color: '#173143' },
  headingStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  timerText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginVertical: 4,
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  digitTxtStyle: {
    color: colors.white,
    fontSize: size.h5,
    fontFamily: 'Roboto-Regular',
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    marginTop: 0,
    marginLeft: 10,
  },
  separatorStyle: {
    color: colors.black,
  },
  countdownText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h6,
  },
  name: {
    width: Utility.getDynamicWidthForGrid(2, 32) - 20,
    height: 12,
    borderRadius: 4,
    marginLeft: 16,
    marginBottom: 8,
  },
});
