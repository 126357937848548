import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';

export const { ids, styles } = StyleSheet.create({
  pagerContainer: {
    borderRadius: 10,
  },
  pagerImage: {
    height: '100%',
    borderRadius: 10,
    overflow: 'hidden',
  },
  bannerContainer: {
    height: (2 / 3) * Utility.getScreenWidth() - 24,
    width: Utility.getScreenWidth() - 24,
    justifyContent: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 10,
    overflow: 'hidden',
    '@media (min-width: 768px)': {
      height: (1 / 3) * Utility.getScreenWidth() - 24,
      width: Utility.getScreenWidth() / 2 - 24,
    },
  },
  bannerImage: {
    height: (2 / 3) * Utility.getScreenWidth() - 24,
    width: Utility.getScreenWidth() - 35,
    borderRadius: 10,
    overflow: 'hidden',
    '@media (min-width: 768px)': {
      height: (1 / 3) * Utility.getScreenWidth() - 24,
      width: Utility.getScreenWidth() / 2 - 35,
    },
  },

  countdownTimerContainer: {
    position: 'absolute',
    bottom: 10,
    right: 20,
  },

  pagerCountdownTimerContainer: {
    position: 'absolute',
    bottom: 10,
    right: 20,
  },
  counterDigitStyle: {
    backgroundColor: colors.black,
  },

  counterTextStyle: {
    color: colors.white,
    fontSize: 14,

    marginBottom: 0,
    fontFamily: 'Roboto-Bold',
  },
});
