import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Linking } from 'react-native';
import { styles } from './styles';
import InfluencerTag from './InfluencerTag';
import LinearGradient from 'react-native-linear-gradient';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import { isPresent } from '../../utils/BooleanUtility';

export default function DashboardInfoCard(props) {
  const {
    name = '',
    bio = '',
    influencer_tags = [],
    influencer_tag_colors = [],
    onEditPress = () => {},
    image_url = '',
  } = props;
  return (
    <LinearGradient
      colors={['#e3e3e3', '#e6f2fb']}
      style={styles.dashboardInfoCardContainer}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 0 }}
    >
      {isPresent(image_url) && <FastImageView source={image_url} style={styles.profileImage} />}
      <Text style={styles.registrationCompletedHeading}>{name}</Text>
      <View style={styles.chipContainer}>
        {influencer_tags.map((chipObject, index) => (
          <InfluencerTag
            label={chipObject.name}
            image={chipObject.image_url}
            disabled
            backgroundColor={influencer_tag_colors[index]}
          />
        ))}
      </View>
      <Text style={styles.registrationCompletedSubheading}>{bio}</Text>
      <TouchableOpacity
        style={styles.editButtonContainer}
        onPress={onEditPress}
      >
        <FastImageView source={images.edit_icon} style={styles.editButton} />
      </TouchableOpacity>
    </LinearGradient>
  );
}
