import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  Text,
  Keyboard,
  ActivityIndicator,
  FlatList,
} from 'react-native';
import Modal from 'react-native-modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { PopUpStyles } from '../Product/styles';
import { updateCallbackRequestedAt } from '../../actions/OrderActions';
import { AnalyticsManager } from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import RadioButton from 'react-native-radio-button';
import size from '../../theme/Fonts';
import _ from 'lodash';

class SelectSlotModal extends Component {
  constructor(props) {
    super(props);
    let currentTime = new Date().getHours();
    const currentHrs = new Date().getHours();
    if (currentTime <= 9) {
      currentTime = 9;
    }
    if (currentTime >= 19) {
      currentTime = 19;
    }
    const suffix = currentTime >= 12 ? 'PM' : 'AM';
    currentTime = ((currentTime + 11) % 12) + 1;
    this.state = {
      selectedPill: 0,
      currentTime,
      suffix,
      showLoader: false,
      currentHrs,
      firstIndex: 0,
      selectedReason: '',
      showTimeSlot: false,
    };
    const { status } = this.props;
    this.reasons = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.callback_reasons),
    )[`${status}`];
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;
    if (!prevProps.isVisible && isVisible) {
      if (!this.validateReduceTime() && !this.validateIncrementTime()) {
        this.setState({
          firstIndex: 1,
          selectedPill: 1,
          currentTime: 9,
          suffix: 'AM',
        });
      }
    }
  }

  cancelpressed = () => {
    const { hideEmailModal } = this.props;
    hideEmailModal();
  };

  convertTime12To24 = (hours, AMPM) => {
    let hr = hours;
    if (AMPM === 'PM' && hours < 12) hr += 12;
    if (AMPM === 'AM' && hours === 12) hr -= 12;
    return hr.toString();
  };

  continuePressed = () => {
    const { currentTime, selectedPill, suffix } = this.state;
    const {
      updateCallbackRequestedAt,
      onConfirm,
      id,
      skillName,
      status,
      orderId,
    } = this.props;

    const { selectedReason = '' } = this.state;
    this.setState({
      showLoader: true,
    });
    AnalyticsManager.logEvent('modal_action', {
      modal_name: skillName,
      state: status,
      entity_type: id?.split('-')[0],
      entity_id: id?.split('-')[1],
    });
    const currentData = new Date();
    currentData.setDate(currentData.getDate() + parseInt(selectedPill));
    const hours = this.convertTime12To24(currentTime, suffix);
    currentData.setHours(hours);
    currentData.setMinutes(0);
    currentData.setSeconds(0);

    this.setState({
      showLoader: false,
    });
    onConfirm(
      currentData,
      `${currentTime + 1 === 13 ? 1 : currentTime + 1} ${suffix}`,
    );

    const body = {
      order: {
        callback_requested_at: currentData,
        skill_name: skillName,
        callback_requested_at_reason: selectedReason,
      },
    };

    updateCallbackRequestedAt(orderId, body, () => {
      this.setState({
        showLoader: false,
      });
      onConfirm(
        currentData,
        `${currentTime + 1 === 13 ? 1 : currentTime + 1} ${suffix}`,
      );
    });
  };

  selectSlot = (value) => {
    let currentTime = new Date().getHours();
    const currentHrs = new Date().getHours();
    if (currentTime <= 9) {
      currentTime = 9;
    }
    if (currentTime >= 19) {
      currentTime = 19;
    }
    const suffixs = currentTime >= 12 ? 'PM' : 'AM';
    currentTime = ((currentTime + 11) % 12) + 1;
    this.setState({
      selectedPill: value,
      currentTime: value > 0 ? 9 : currentTime,
      suffix: value > 0 ? 'AM' : suffixs,
      currentHrs,
    });
  };

  slots = () => {
    const { selectedPill, firstIndex } = this.state;

    return (
      <View style={PopUpStyles.selectSlotsContainer}>
        {[firstIndex, firstIndex + 1].map((value) => {
          const slotsStyle = [
            PopUpStyles.slotsContainer,
            {
              borderWidth: selectedPill === value ? 1 : 0,
              borderColor: selectedPill === value ? colors.black : colors.white,
            },
          ];
          const currentData = new Date();
          currentData.setDate(currentData.getDate() + value);

          return (
            <TouchableOpacity
              style={slotsStyle}
              onPress={() => this.selectSlot(value)}
            >
              <Text style={PopUpStyles.slotsText}>
                {currentData.toDateString()}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  checkIfNoSlotAvailableToday = () => {
    const { selectedPill, currentHrs } = this.state;
    const timeRightNow = new Date().getHours();

    return currentHrs <= timeRightNow && selectedPill === 0;
  };

  validateReduceTime = () => {
    const { currentTime, suffix, selectedPill, currentHrs } = this.state;
    const timeRightNow = new Date().getHours();

    if (currentHrs <= timeRightNow && selectedPill === 0) {
      return false;
    }

    return !(currentTime <= 9 && suffix === 'AM');
  };

  reduceTime = () => {
    const { currentTime, suffix, selectedPill, currentHrs } = this.state;
    let updatedTime = currentTime - 1;
    if (currentTime === 12 && suffix === 'PM') {
      updatedTime = 11;
      this.setState({
        suffix: suffix === 'AM',
      });
    }
    if (currentTime === 12) {
      this.setState({
        suffix: suffix === 'AM' ? 'PM' : 'AM',
      });
    }
    if (currentTime === 1) {
      updatedTime = 12;
    }
    if (!this.validateReduceTime()) {
      return;
    }

    if (selectedPill === 0) {
      this.setState({
        currentHrs: currentHrs - 1,
      });
    }
    this.setState({
      currentTime: updatedTime,
    });
  };

  validateIncrementTime = () => {
    const { currentTime, suffix } = this.state;
    return !(currentTime >= 7 && currentTime !== 12 && suffix === 'PM');
  };

  increaseTime = () => {
    const { currentTime, suffix, currentHrs, selectedPill } = this.state;
    let updatedTime = currentTime + 1;
    if (!this.validateIncrementTime()) {
      return;
    }
    if (currentTime === 12) {
      updatedTime = 1;
    }
    if (currentTime === 11) {
      this.setState({
        suffix: suffix === 'AM' ? 'PM' : 'AM',
      });
    }
    if (selectedPill === 0) {
      this.setState({
        currentHrs: currentHrs + 1,
      });
    }
    this.setState({
      currentTime: updatedTime,
    });
  };

  changeSlot = () => {
    const { currentTime, suffix } = this.state;
    const leftSlotIcon = [
      PopUpStyles.timeSlotIncrementDecrementImage,
      { tintColor: this.validateReduceTime() ? colors.black : '#979BAA' },
    ];
    const rightSlotIcon = [
      PopUpStyles.timeSlotIncrementDecrementImage,
      {
        tintColor: this.validateIncrementTime()
          ? colors.black
          : colors.ultraSilverLite,
      },
    ];
    return (
      <View style={PopUpStyles.changeSlotContainer}>
        <TouchableOpacity
          style={PopUpStyles.decrementButtonContainer}
          onPress={this.reduceTime}
          activeOpacity={0.6}
          disabled={!this.validateReduceTime()}
          hitSlop={Utility.getHitSlop('likeButton')}
        >
          <Image
            source={images.chevronLeft}
            style={leftSlotIcon}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <View>
          <Text style={PopUpStyles.timeSlotText}>
            <Text style={PopUpStyles.timeSlotHourText}>{currentTime}</Text>
            {` : 00 ${suffix}`}
          </Text>
        </View>
        <TouchableOpacity
          style={PopUpStyles.confirmTimeSlogAction}
          onPress={this.increaseTime}
          activeOpacity={0.6}
          disabled={!this.validateIncrementTime()}
          hitSlop={Utility.getHitSlop('likeButton')}
        >
          <Image
            source={images.chevronRight}
            style={rightSlotIcon}
            resizeMode="contain"
          />
        </TouchableOpacity>
      </View>
    );
  };

  onReasonSelected = (index, item) => {
    console.tron.log(index, item, 'selectedReason');
    this.setState({
      selectedReason: item,
    });
  };

  renderRadioBoxes = ({ item, index }) => {
    const { selectedReason } = this.state;
    console.tron.log(selectedReason, item, '      encodeURIComponent(');
    return (
      <TouchableOpacity
        style={PopUpStyles.radioButtonContainer}
        onPress={_.memoize(
          () => this.onReasonSelected(index, item),
          () => [index],
        )}
      >
        <RadioButton
          outerColor={colors.subtitle}
          innerColor={colors.foxyBlack}
          isSelected={selectedReason === item}
          size={12}
          onPress={() => this.onReasonSelected(index, item)}
        />
        <Text style={PopUpStyles.radioButtonText}>{`${item}`}</Text>
      </TouchableOpacity>
    );
  };

  keyExtractor = (item, index) => `${index}`;

  selectReason = () => {
    return (
      <FlatList
        data={this.reasons}
        style={PopUpStyles.selectSlotFlatlistContainer}
        showsHorizontalScrollIndicator={false}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderRadioBoxes}
        scrollEnabled={false}
        extraData={this.state}
      />
    );
  };

  onContinueClick = () => {
    this.setState({ showTimeSlot: true });
  };

  render() {
    const styles = PopUpStyles;
    const { isVisible } = this.props;
    const { showLoader, showTimeSlot } = this.state;
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.8}
        backdropTransitionOutTiming={0}
        style={styles.changePincodeModal}
        onBackdropPress={this.cancelpressed}
        swipeDirection="down"
        onSwipeComplete={this.cancelpressed}
      >
        <SafeAreaView style={PopUpStyles.selectSlotSafearea}>
          {!showTimeSlot ? (
            <View style={PopUpStyles.container}>
              <View style={PopUpStyles.addReviewHeader}>
                <Text style={PopUpStyles.reviewHeaderText}>
                  Select your reason for a callback
                </Text>
                <TouchableOpacity
                  style={PopUpStyles.cancelButton}
                  onPress={this.cancelpressed}
                >
                  <Image
                    source={images.cross}
                    style={PopUpStyles.cancelStyle}
                  />
                </TouchableOpacity>
              </View>
              <this.selectReason />
              <TouchableOpacity
                style={PopUpStyles.submitButton}
                onPress={this.onContinueClick}
              >
                <ActivityIndicator
                  style={styles.activityIndicatorInActionButton}
                  animating={showLoader}
                  color={colors.white}
                />
                <Text style={PopUpStyles.submitText}>Continue</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={PopUpStyles.container}>
              <View style={PopUpStyles.addReviewHeader}>
                <Text style={PopUpStyles.reviewHeaderText}>
                  Select your slot for a callback
                </Text>
                <TouchableOpacity
                  style={PopUpStyles.cancelButton}
                  onPress={this.cancelpressed}
                >
                  <Image
                    source={images.cross}
                    style={PopUpStyles.cancelStyle}
                  />
                </TouchableOpacity>
              </View>
              <this.slots />
              <this.changeSlot />
              <TouchableOpacity
                style={PopUpStyles.submitButton}
                onPress={this.continuePressed}
              >
                <ActivityIndicator
                  style={styles.activityIndicatorInActionButton}
                  animating={showLoader}
                  color={colors.white}
                />
                <Text style={PopUpStyles.submitText}>Continue</Text>
              </TouchableOpacity>
            </View>
          )}
        </SafeAreaView>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ updateCallbackRequestedAt }, dispatch),
});

export default connect(null, mapDispatchToProps)(SelectSlotModal);
