import { StyleSheet } from 'react-native';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';

const PagerStyles = StyleSheet.create({
  dotStyle: {
    width: 6,
    height: 6,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#4E4E4E',
    opacity: 0.6,
  },

  dotStyleBottom: {
    width: 32,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#F19E54',
    opacity: 0.6,
  },

  dotStyleFeature: {
    width: 6,
    height: 6,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#4E4E4E',
    opacity: 0.6,
    marginTop: 8,
  },
  paginationStyle: {
    position: 'absolute',
    left: null,
    right: 20,
    top: 8,
    bottom: null,
  },

  bottomPaginationStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },

  pageContainer: {
    paddingHorizontal: Utility.padding,
    marginVertical: 8,
    // // width: 196,
    height: 320,
    flexDirection: 'row',
    paddingRight: 20,
  },
  pageImageStyle: {
    flex: 1,
    height: 100,
    resizeMode: 'stretch',
  },
  pageContainerFeature: {
    marginVertical: 8,
    width: Utility.getScreenWidth(),
    height: (Utility.getScreenWidth() - 24) / 1.5,
  },

  dotStyleBottomPager: {
    width: 6,
    height: 6,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#E8ECFD',
    opacity: 0.6,
  },
  activeDot: {
    backgroundColor: 'white',
    opacity: 1,
    borderWidth: 0.5,
    borderColor: '#4E4E4E',
  },
});

export default PagerStyles;
