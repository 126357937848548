import StyleSheet from 'react-native-media-query';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

export const { ids, styles } = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  storyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
  },
  storyContainerWithGradient: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  artistname: {
    fontSize: size.h4,
    color: colors.foxyBlack,

    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 8,
    '@media (min-width: 768px)': {
      fontSize: size.h3_5,
    },
  },
  listArtistName: {
    fontSize: size.h4,
    color: colors.foxyBlack,
    fontWeight: 'normal',
    alignSelf: 'flex-start',
    marginTop: 14,
    marginLeft: 10,
    maxWidth: (3 / 5) * Utility.getScreenWidth(),
  },
  circularImageContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 20,
    backgroundColor: colors.havelockBlue,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: colors.white,
  },

  followButton: {
    bottom: 30,
    right: 6,
  },
  followBrandButton: {
    bottom: 0,
    right: 0,
  },
  followButtonForList: {
    right: 16,
    bottom: 0,
    right: 0,
  },
  followButtonForList: {
    right: 16,
  },
  cardStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    margin: 4,
  },
  listCardStyle: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    margin: 0,
    marginTop: 1,
    marginBottom: 1,
    alignSelf: 'center',
    borderRadius: 4,
    height: 112,
  },
  plusImageStyle: {
    height: 15,
    width: 15,
  },
  updateCardStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 4,
    marginRight: 12,
  },
  listImageStyle: { marginLeft: 12, borderRadius: 4, overflow: 'hidden' },
  artistDescription: {
    flexDirection: 'column',
    marginLeft: 14,
    marginRight: 50,
    flex: 2.5,
    justifyContent: 'space-between',
    height: '100%',
  },
  artistNameForList: {
    position: 'absolute',
    top: 16,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    color: colors.foxyBlack,
    fontStyle: 'normal',
    lineHeight: 14,
  },
  artistDesignation: {
    position: 'absolute',
    bottom: 16,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,

    fontStyle: 'normal',
    color: colors.silver,
    lineHeight: 14,
  },
  navigationFollowButton: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: Utility.isIOS() ? 8 : 12,
    right: 16,
  },
  navbarFollowIconStyle: {
    width: 22,
    height: 20,
    resizeMode: 'contain',
  },
  textualFollowButton: {
    backgroundColor: colors.followButtonBlueColor,
    alignSelf: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '45%',
    right: 12,
    paddingHorizontal: 8,
    borderRadius: 2,
    height: 20,
  },
  storyGradientStyle: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
});
