import React, { Component } from 'react';
import {
  View,
  ActivityIndicator,
  BackHandler,
  Text,
  StyleSheet,
} from 'react-native';
import { debounce } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchData, applyCartCoupon } from '../../actions/ActionTypes';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import size from '../../theme/Fonts';
import { setCongratsModalForEdge } from '../../actions/FoxyEdgeAction';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import withNavigation from '../../utils/WithNavigation';

export class CallApi extends Component {
  constructor(props) {
    super(props);
    this.params = props.route.params?.params;
    this.slug = this.params?.slug;
    this.loadingMessage = this.params?.loading_message;
    this.surl = this.params?.surl;
    this.method = this.params?.method || 'GET';
    this.sku_id = this.params?.sku_id || '';
    this.force_apply = this.params?.force_apply || '';
    this.plan_type = this.parmas?.plan_type || '';
    this.source = this.params?.source || '';
    this.previousScreen = this.params?.previousScreen || '';
    this.purpose = this.params?.purpose || '';
  }

  componentDidMount() {
    setTimeout(() => {
      this.callApi();
    }, 0);
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  applyOffer = (code) => {
    const { applyCartCoupon, navigation } = this.props;
    applyCartCoupon(
      code,
      (success, callbackData) => {
        const appliedCoupon = callbackData?.prompts?.find(
          (coupon) => coupon.coupon_code === code,
        );
        if (success) {
          this.setSuccessfullyAppliedCoupon(appliedCoupon);
        }
      },
      false,
      true,
    );
  };

  callApi = () => {
    const { fetchData, cartItems, membership_cohort } = this.props;
    let data = {
      slug: `/${this.slug}`,
      method: this.method,
    };
    if (Utility.isPresent(this.sku_id)) {
      data = {
        ...data,
        body: {
          cart_item: {
            sku_id: parseInt(this.sku_id),
            quantity: 1,
            is_free: false,
            force_apply: this.force_apply,
          },
        },
      };
    }
    const doesSkuExist = cartItems.filter(
      (e) => parseInt(e?.sku_id) === parseInt(this.sku_id),
    );
    if (Utility.isPresent(doesSkuExist)) {
      this.applyOffer(this.force_apply);
      setTimeout(() => {
        DynamicLinkManager.handleDynamicLink(
          `${this.surl}`,
          this.navigateToScreen,
        );
      }, 100);

      return;
    }
    if (this.slug.includes('/activate')) {
      AnalyticsManager.logEvent(EventType.EDGE.MEMBERSHIP_ACTIVATED, {
        slug: this.slug,
        previousScreen: this.previousScreen,
        status: 'started',
        membership_cohort,
      });
    } else if (this.purpose === 'loyalty_plan_activation') {
      AnalyticsManager.logEvent(EventType.EDGE.MEMBERSHIP_ACTIVATED, {
        sku_id: this.sku_id,
        previousScreen: this.previousScreen,
        status: 'started',
        membership_cohort,
      });
    }

    fetchData(data, (success, response) => {
      if (!success) {
        this.goBack();
        return;
      }
      if (Utility.isPresent(this.sku_id)) {
        const {
          sku_id = '',
          quantity = 1,
          final_sp = '',
          final_mrp = '',
          offer: { name = '' } = {},
        } = response;
        const { membership_cohort, membership_id } = this.props;
        const productMeta = {
          [EventParameterKey.SKU_ID]: sku_id,
          [EventParameterKey.QUANTITY]: quantity,
          [EventParameterKey.PRODUCT_LISTING_PRICE]: final_mrp,
          [EventParameterKey.PRODUCT_SELLING_PRICE]: final_sp,
          [EventParameterKey.MEMBERSHIP_TYPE]: name,
          [EventParameterKey.MEMBERSHIP_STATUS]: membership_cohort,
          membership_id,
        };
        AnalyticsManager.logEvent(
          EventType.discoveryEvents.PRODUCT_ADDED_TO_CART,
          productMeta,
        );
      }
      DynamicLinkManager.handleDynamicLink(
        `${this.surl}`,
        this.navigateToScreen,
      );
    });
  };

  navigateToScreen = (route, slug, path, extra = {}, params, url) => {
    const { navigation, setCongratsModalForEdge, membership_cohort } =
      this.props;

    if (route === 'TheEdge' || this.slug.includes('/activate')) {
      Utility.foxy_edge_source = this.source;
      AnalyticsManager.logEvent(EventType.EDGE.MEMBERSHIP_ACTIVATED, {
        slug: this.slug,
        previousScreen: this.previousScreen,
        status: 'final',
        membership_cohort: 'member',
      });
      AnalyticsManager.logEvent(EventType.EDGE.EDGE_ACTIVE, {
        slug: this.slug,
      });
      setCongratsModalForEdge(true);
    }
    const updatedParams = {
      ...params,
      source: this.source,
      previousScreen: this.previousScreen,
    };
    if (route === 'TheEdge') {
      navigation.navigate(route, { slug, extra, params: updatedParams, url });
      return;
    }
    navigation.replace(route, { slug, extra, params: updatedParams, url });
  };

  render() {
    return (
      <View style={styles.container}>
        <ActivityIndicator animating color={colors.white} size='large' />
        <Text style={styles.text}>
          {decodeURIComponent(this.loadingMessage)}
        </Text>
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchData,
      setCongratsModalForEdge,
      applyCartCoupon,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
  membership_cohort: state.todayDeals.membership_cohort,
  membership_id: state.todayDeals?.membership_id,
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CallApi),
);

const styles = StyleSheet.create({
  container: {
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: colors.translucent,
  },
  text: {
    marginTop: 8,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },
});
