import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

export const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  frameLessTagContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 6,
    marginLeft: 0,
  },
  frameLessTagStyles: {
    height: 124,
    width: Utility.getScreenWidth() / 3.8,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 3.8 / 2,
    },
  },
  tagsText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.black,
    marginTop: 4,
  },
  smallTagContainer: {
    display: 'flex',
    width: Utility.getScreenWidth() / 4.1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  smallTagImage: {
    width: Utility.getScreenWidth() / 5,
    height: 100,
  },
  defaultSizeContainer: {
    height: 72,
    width: 76,
    borderRadius: 4,
    backgroundColor: colors.white,
    margin: 4,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  defaultSizeCardImageStyle: {
    height: 28,
    width: 32,
  },
  defaultSizeCardText: {
    marginLeft: 2,
    marginRight: 2,
    alignSelf: 'center',
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.foxyBlack,
    fontStyle: 'normal',
    textAlign: 'center',
    lineHeight: 12,
  },
});
