import React from 'react';
import { View, Text, Image } from 'react-native';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import styles from './styles';
import {
  AnalyticsEvent,
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';

const SocialValidation = (props) => {
  const {
    item: { rankings = {} },
    myAttributesValues,
    sku_id,
    id,
  } = props;
  const rank = Utility.findRanking(rankings, myAttributesValues);
  const cta = `#${rank.rank} rated`;
  const afterCta = ` product by users with ${rank.name}`;
  // if (Utility.isBlank(rank)) {
  //   AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_VIEW, {
  //     [EventParameterKey.SKU_ID]: sku_id,
  //     [EventParameterKey.PRODUCT_ID]: id,
  //     [EventParameterKey.HAS_TRUST]: false,
  //   });
  //   return null;
  // }
  // AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_VIEW, {
  //   [EventParameterKey.SKU_ID]: sku_id,
  //   [EventParameterKey.PRODUCT_ID]: id,
  //   [EventParameterKey.HAS_TRUST]: true,
  // });
  return (
    <View style={styles.container}>
      <Image source={images.foxyMatch.social} style={styles.icon} />
      <Text
        style={styles.foxyMatchTextCta}
        allowFontScaling={false}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {cta}
      </Text>
      <Text
        style={styles.foxyMatchText}
        allowFontScaling={false}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {afterCta}
      </Text>
    </View>
  );
};

export default SocialValidation;
