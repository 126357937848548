import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

export default class ConsumerProfilePlaceholderShimmer extends PureComponent {
  headerPlaceholder = () => {
    return (
      <View style={styles.profileContainer}>
        <ShimmerPlaceHolder
          //   colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.headerTextShimmerStyle}
        />
      </View>
    );
  };

  headerPlaceholderSmall = () => {
    return (
      <ShimmerPlaceHolder
        // colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
        autoRun
        style={styles.headerTextShimmerSmallStyle}
      />
    );
  };

  headerPlaceholderTwo = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          // backgroundColor: colors.white,
        }}
      >
        <ShimmerPlaceHolder
          //   colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.headerTextShimmerExtraSmallTwoStyle}
        />
        <ShimmerPlaceHolder
          //   colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.headerTextShimmerExtraSmallTwoStyle}
        />
      </View>
    );
  };

  headerPlaceholderArray = () => {
    const { isSelfieAttributesPresent } = this.props;
    if (isSelfieAttributesPresent) return null;
    return (
      <View
        style={{
          flexDirection: 'row',
          overflow: 'hidden',
          flexWrap: 'wrap',
          marginTop: 64,
          justifyContent: 'space-around',
        }}
      >
        {_.map([1, 2, 3, 4], (e) => {
          return (
            <ShimmerPlaceHolder
              //   colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
              autoRun
              style={styles.headerTextShimmerArrayStyle}
            />
          );
        })}
      </View>
    );
  };

  profilePicPlaceholder = () => {
    return <ShimmerPlaceHolder autoRun style={styles.profilePicShimmer} />;
  };

  pillsAndImageName = () => {
    return (
      <View style={styles.pillsContainer}>
        <this.headerPlaceholder />
        <this.profilePicPlaceholder />
        <this.headerPlaceholderSmall />
        <this.headerPlaceholderTwo />
        <this.headerPlaceholderArray />
      </View>
    );
  };

  render() {
    return <this.pillsAndImageName />;
  }
}

const styles = StyleSheet.create({
  profileContainer: { width: '50%', marginTop: 24 },
  pillsContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
    alignSelf: 'center',
    paddingHorizontal: 12,
    paddingBottom: 12,
  },
  headerTextShimmerStyle: {
    height: 18,
    width: Utility.getScreenWidth() / 2,
    borderRadius: 4,
  },
  headerTextShimmerSmallStyle: {
    height: 18,
    width: Utility.getScreenWidth() / 3,
    borderRadius: 4,
    marginTop: 8,
  },

  headerTextShimmerExtraSmallTwoStyle: {
    height: 20,
    width: 88,
    borderRadius: 4,
    marginTop: 30,
    marginRight: 12,
  },
  headerTextShimmerArrayStyle: {
    height: 48,
    width: 48,
    borderRadius: 12,

    marginRight: 8,
    marginTop: 30,
  },

  profilePicShimmer: {
    height: 56,
    width: 56,
    borderRadius: 64,
    position: 'absolute',
    right: 12,
    top: 24,
  },
});
