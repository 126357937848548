import { StyleSheet, Dimensions } from 'react-native';
import size from '../../../theme/Fonts';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

const screenWidth = Dimensions.get('window').width;

const ArtistDetailStyles = StyleSheet.create({
  scrollview: {
    backgroundColor: colors.background,
  },
  bannerImage: {
    zIndex: 1,
    width: Utility.getScreenWidth(),
    height: 232,
    justifyContent: 'flex-start',
    elevation: 2,
    shadowRadius: 4,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowOffset: { width: 1, height: 1 },
    overflow: 'visible',
    backgroundColor: 'white',
  },
  imageOverlay: {
    flex: 1,
    opacity: 0.5,
    backgroundColor: colors.black,
  },
  infoContainer: {
    position: 'absolute',
    width: '100%',
    height: 120,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  artistInsightContainer: {
    width: '100%',
    height: 44,
    paddingLeft: 16,
    paddingRight: 16,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 16,
  },
  artistDescription: {
    alignSelf: 'center',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  artistAvatar: {
    marginLeft: 16,
    height: 72,
    width: 72,
    borderRadius: 36,
    elevation: 1,
    shadowRadius: 2,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: colors.background,
  },
  artistNameAndAddress: {
    height: 72,
    marginLeft: 16,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  artistName: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  artistDesignationContainer: {
    flexDirection: 'row',
  },
  smallLogo: {
    width: 12,
    height: 14,
    resizeMode: 'contain',
  },
  emoji: {
    fontSize: 10,
  },
  artistAddressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  descriptionText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 8,
  },
  statsComponentContainer: {
    marginRight: 12,
    height: 36,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: 68,
  },
  statsCount: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  followButton: {
    position: 'absolute',
    height: 24,
    width: 106,
    right: 16,
    bottom: 8,
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
  },
  unfollowedStateButton: {
    position: 'absolute',
    left: 0,
    height: 24,
    width: 106,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bluePlusContainer: {
    position: 'absolute',
    left: 0,
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.havelockBlue,
  },
  grayTickContainer: {
    position: 'absolute',
    left: -24,
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.silverDark,
  },
  whitePlus: {
    height: 8,
    width: 8,
  },
  whiteTick: {
    height: 8,
    width: 12,
  },
  followButtonImage: {
    height: 8,
    width: 8,
    marginRight: 4,
  },
  followtextContainer: {
    marginLeft: 12,
    backgroundColor: colors.havelockBlue,
    height: 20,
    width: 82,
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  followedTextContainer: {
    marginLeft: 28,
    backgroundColor: colors.silverDark,
    height: 24,
    width: 82,
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  socialMediaIconContainer: {
    width: '100%',
    height: 60,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: Utility.padding,
    paddingRight: Utility.padding,
    marginTop: 16,
    marginBottom: 24,
  },
  socialMediaButton: {
    height: 40,
    width: Utility.getDynamicWidth(2, 6),
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.foxyBlack,
  },
  socialMediaIconImage: {
    position: 'absolute',
    left: 6,
    height: 30,
    width: 30,
  },
  socialMediaIdContainer: {
    flexDirection: 'row',
    width: Utility.getScreenWidth() / 2 - 48 - Utility.largePadding,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 40,
    overflow: 'hidden',
  },
  socialMediaIdStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  bookNowButton: {
    position: 'absolute',
    height: 32,
    backgroundColor: colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    // marginRight: 12,
    // marginLeft: 12,
    width: Utility.getScreenWidth() - 2 * Utility.padding,
    borderRadius: 4,
    // marginBottom: 12,
    bottom: 12,
    left: 12,
    right: 12,
  },
  bookNowText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
  },
  yellowDot: {
    position: 'absolute',
    right: 2,
    top: 2,
    height: 6,
    width: 6,
    borderRadius: 3,
    backgroundColor: 'yellow',
  },
  socialMediaLogo: {
    height: 12,
    width: 16,
    resizeMode: 'contain',
  },
  artistBio: {
    margin: 16,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
  },
  editButton: {
    position: 'absolute',
    right: 20,
    top: -2,
  },
  editButtonImage: {
    tintColor: colors.foxyBlack,
    height: 16,
    width: 16,
  },
  icon: {
    width: 16,
    marginTop: 2,
    height: 16,
    marginRight: 4,
  },
  textFieldContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
  },
  textInputText: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },
  buttonLayout: {
    marginTop: 12,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  termAndConditionRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 12,
  },
  checkboxContainer: {
    height: 18,
    borderColor: colors.silver,
    borderWidth: 0.9,
    borderRadius: 3,
    width: 18,
    marginLeft: 20,
  },
  artistContectTerms: {
    color: colors.foxyBlack,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',

    marginLeft: 8,
    marginTop: 2,
  },
});

export default ArtistDetailStyles;
