const getSize = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const { width, height } = img;
      resolve({ width, height });
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
};

const ImageSize = { getSize };
export default ImageSize;
