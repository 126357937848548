import { analytics } from '../../../libraries/Firebase';
import AnalyticsEvent from './AnalyticsEvent';

class FirebaseAnalytics {
  static trackEvent = (event) => {
    // console.tron.log('in firebase tracker');
    if (event instanceof AnalyticsEvent) {
      const { meta, eventType } = event;
      let params = meta;
      if (Object.keys(meta).length > 24) {
        params = Object.fromEntries(Object.entries(meta).slice(0, 24));
        params = { ...params, params_gt_24: true };
      }
      analytics().logEvent(eventType, params);
    }
  };

  static currentScreen = (screenName) => {
    analytics().logScreenView({ screen_name: screenName });
  };

  static setUserProperty = (key, value) => {
    analytics().setUserProperty(key, value);
  };

  static setUserId = (id) => {
    analytics().setUserId(id);
  };
}

export default FirebaseAnalytics;
