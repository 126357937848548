// Dependencies
import React from 'react';
import { StyleSheet } from 'react-native';
import ShimmerPlaceHolder from '../libraries/ReactNativeShimmerPlaceholder';
const styles = StyleSheet.create({
  container: {
    opacity: 0.4,
  },
});

function FullWidthShimmer(props) {
  const {
    style,
    speed = 600,
    colors = ['#ffffff1A', '#fff', '#ffffff1A'],
  } = props;
  return (
    <ShimmerPlaceHolder
      autoRun
      style={[styles.container, style]}
      colorShimmer={colors}
      duration={speed}
      isInteraction
    />
  );
}

export default FullWidthShimmer;
