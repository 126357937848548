import { isDesktop } from '../../utils/BooleanUtility';
import Utility from '../../utils/Utility';

export const salonConstants = {
  listViewHeight: 64,
  smallRailViewHeight: 92,
  largeRailViewHeight: 120,
  defaultImageWidth: 48,
  NUM_OF_CARDS: {
    LIST: isDesktop() ? 2 : 1,
    GRID: isDesktop() ? 4 : 2,
  },
  SPACING: {
    LIST: 0,
    GRID: 16,
  },
};
export const SALON = {
  CARD: {
    list: {
      defaultSize: {
        viewwidth: Utility.getScreenWidth(),
        viewheight: Utility.listDefaultViewHeight,
        imagewidth: salonConstants.defaultImageWidth,
        imageheight: salonConstants.defaultImageWidth,
      },
    },
    rail: {
      small: {
        viewwidth: salonConstants.smallRailViewHeight,
        viewheight: salonConstants.smallRailViewHeight,
        imagewidth: salonConstants.smallRailViewHeight,
        imageheight: salonConstants.smallRailViewHeight,
      },
      large: {
        viewwidth: salonConstants.largeRailViewHeight,
        viewheight: salonConstants.largeRailViewHeight,
        imagewidth: salonConstants.largeRailViewHeight,
        imageheight: salonConstants.largeRailViewHeight,
      },
      defaultSize: {
        viewwidth: salonConstants.largeRailViewHeight,
        viewheight: salonConstants.largeRailViewHeight,
        imagewidth: salonConstants.largeRailViewHeight,
        imageheight: salonConstants.largeRailViewHeight,
      },
    },
    grid: {
      defaultSize: {
        viewwidth: Utility.getDynamicWidthForGrid(
          salonConstants.NUM_OF_CARDS.GRID,
          salonConstants.SPACING.GRID,
        ),
        viewheight: Utility.getDynamicWidthForGrid(
          salonConstants.NUM_OF_CARDS.GRID,
          salonConstants.SPACING.GRID,
        ),
        imagewidth: Utility.getDynamicWidthForGrid(
          salonConstants.NUM_OF_CARDS.GRID,
          salonConstants.SPACING.GRID,
        ),
        imageheight: Utility.getDynamicWidthForGrid(
          salonConstants.NUM_OF_CARDS.GRID,
          salonConstants.SPACING.GRID,
        ),
      },
    },
    SCREEN: {},
  },
};
