import React, { Component } from 'react';
import { ProductInfoStyles } from '../Product/styles';
import { View, FlatList } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
class Bullets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.containerStyle =
      ProductInfoStyles[
        `dotsContainer${Config.PRODUCT_PAGE_IMAGE_CAROUSEL_DOTS_POSITION}`
      ];
  }

  componentDidMount() {
    if (this.props.onRef != null) {
      this.props.onRef(this);
    }
  }

  setActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  };

  bullet = ({ item, index }) => {
    const { activeIndex } = this.state;
    const currentStyle =
      activeIndex === index
        ? ProductInfoStyles.activeDotStyle
        : ProductInfoStyles.dotStyle;

    return <View style={currentStyle}></View>;
  };

  bulletLayout(data, index) {
    return {
      length: ProductInfoStyles.dotStyle.width + 2,
      offset: (ProductInfoStyles.dotStyle.width + 2) * index,
      index,
    };
  }

  bulletSelectorRailKeyExtractor = (item, index) => `${index}_${item}`;

  render() {
    const { items } = this.props;
    return (
      <View style={this.containerStyle}>
        <FlatList
          data={items}
          contentContainerStyle={ProductInfoStyles.dotsContentContainer}
          horizontal
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
          getItemLayout={this.bulletLayout}
          renderItem={this.bullet}
          keyExtractor={this.bulletSelectorRailKeyExtractor}
          extraData={this.state.activeIndex}
        />
      </View>
    );
  }
}

export default Bullets;
