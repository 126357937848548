import React from 'react';
import { View, Text, Image } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import styles from './styles';
import {
  AnalyticsEvent,
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';

const Rating = (props) => {
  const {
    rating,
    item: { rating_grade = '' },
    scrollToRatings,
    sku_id,
    id,
    matchedProduct = false,
  } = props;
  const beforeCta = ` Rating of ${rating}`;
  const cta = `${rating_grade}`;
  return (
    <TouchableOpacity
      onPress={() => {
        AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
          [EventParameterKey.SKU_ID]: sku_id,
          [EventParameterKey.PRODUCT_ID]: id,
          [EventParameterKey.ELEMENT_NAME]: 'foxy_match',
          [EventParameterKey.ELEMENT_TYPE]: 'rating',
        });
        scrollToRatings();
      }}
      disabled={matchedProduct}
      hitSlop={Utility.getHitSlop()}
    >
      <View style={styles.container}>
        <Image source={images.foxyMatch.star} style={styles.icon} />
        <Text
          style={styles.foxyMatchTextCta}
          allowFontScaling={false}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {cta}
        </Text>
        <Text
          style={styles.foxyMatchText}
          allowFontScaling={false}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {beforeCta}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default Rating;
