import { take } from 'lodash';
import { takeLatest } from 'redux-saga/effects';
import {
  GET_ROUTINES,
  GET_ROUTINES_DISCOVERY,
  GET_ROUTINE_DETAIL,
} from '../actions/ActionTypes';
import Utility from '../utils/Utility';
import { convertJsonFromResponse, getApiHeaders } from './GeneratorUtil';
import { API_DOMAIN } from '../config/Constants';

function* fetchRoutinesDiscovery(action) {
  const { callback, api = '' } = action;
  try {
    const response = yield fetch(api, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

function* fetchRoutines(action) {
  const { slug, callback } = action;
  const url = `${API_DOMAIN}${slug}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

function* fetchRoutineDetail(action) {
  const { callback, id } = action;
  const url = `${API_DOMAIN}/routines/${id}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export default function* watchRoutinesSaga() {
  yield takeLatest(GET_ROUTINES_DISCOVERY, fetchRoutinesDiscovery);
  yield takeLatest(GET_ROUTINES, fetchRoutines);
  yield takeLatest(GET_ROUTINE_DETAIL, fetchRoutineDetail);
}
