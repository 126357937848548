import { camelCase } from 'lodash';
import LinearTabCard from './LinearTabCard';
import CircularTabCard from './CircularTabCard';
import PillsTabCard from './PillsTabCard';

const TabGroupCardComponent = {
  linearTab: LinearTabCard,
  circularRail: CircularTabCard,
  pills: PillsTabCard,
};

export const getTabGroupCardComponent = (display) => {
  return TabGroupCardComponent[camelCase(display)];
};
