import Config from '../libraries/ReactNativeConfig';
import AppConfig from '../config/AppConfig';
import { CURRENCY_SYMBOL } from '../config/Constants';

export const formatNumberInIndianStyleAndRemoveFractions = (number) =>
  (+number).toLocaleString('en-IN', {
    useGrouping: true,
    maximumFractionDigits: 0,
  });

export const getPriceText = (number) => {
  const shouldFormatPrice = AppConfig.getBooleanValue(Config.FORMAT_PRICE_INDIAN_STYLE);
  const formattedNumber = shouldFormatPrice ? formatNumberInIndianStyleAndRemoveFractions(number) : +number;
  return `${CURRENCY_SYMBOL} ${formattedNumber}`;
}
