// Dependencies
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { salonSchema } from '../../config/Schema';

// Components
import * as ActionTypes from '../../actions/ActionTypes';
import { SalonCard } from '../../components/Salon';
import { LAYOUT } from '../../config/Constants';
import { withMaybe } from '../../lib/Monads';
import Utility from '../../utils/Utility';
import OnboardingSalonCard from '../../components/Artist/OnboardingSalonCard';

class Salon extends Component {
  itemDataNullFn = props => !props.itemData;

  SalonCardWithCondition = withMaybe(this.itemDataNullFn)(SalonCard);

  render() {
    const {
      layout, itemData, onboarding, onCardClick,
    } = this.props;
    const showDescription = layout === LAYOUT.LIST;
    if (onboarding) {
      return <OnboardingSalonCard itemData={itemData} cardClick={onCardClick} />;
    }
    return (
      <this.SalonCardWithCondition
        {...this.props}
        itemData={itemData}
        showDescription={showDescription}
      />
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  if (ownProps.search || ownProps.onboarding) {
    return { itemData: ownProps.itemData, baseProps: {} };
  }
  const itemData = denormalize(
    store.data.entities.salons[ownProps.id],
    salonSchema,
    store.data.entities,
  );
  if (Utility.isBlank(itemData)) {
    return { itemData, baseProps: {} };
  }
  return {
    itemData: denormalize(
      store.data.entities.salons[ownProps.id],
      salonSchema,
      store.data.entities,
    ),
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionTypes, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Salon);
