import { takeEvery, call, select, put } from 'redux-saga/effects';
import _ from 'lodash';
import { PUBLISH_POST, storePostServerID } from '../actions/UploadPostActions';
import {
  logoutUser,
  updateCurrentPostID,
  uploadMediaToS3,
} from '../actions/LoginActions';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';

import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import { updateLastVideoUpload } from '../actions/ActionTypes';

const uploadPost = (
  authToken,
  postData,
  cardImageName,
  mediaFiles,
  headers,
) => {
  const products = [];
  postData.items[0].products.forEach((item, index) => {
    const data = { product_id: item };
    products.push(data);
  });
  const { entityId = '', entityType = '' } = postData;
  const medium = {
    type: postData?.uploadType,
    title: postData?.items[0]?.caption,
    description: postData?.postDescription,
    width: postData?.items[0]?.width,
    height: postData?.items[0]?.height,
    duration: postData?.items[0]?.videoLength,
    edits_allowed: false,
    card_image: cardImageName,
    media_products_attributes: products,
    media_category_id: postData?.postCategory,
    entity_type: entityType,
    entity_id: entityId,
  };
  const rating = {};
  rating.sku_id = postData.skuId ? postData.skuId : 0;
  if (postData.uploadType === 'FoxyVideo') {
    medium.video = mediaFiles[0];
  } else {
    medium.images = mediaFiles;
  }
  if (Utility.isPresent(postData.campaignId)) {
    medium.campaign_id = postData.campaignId;
  }
  if (Utility.isPresent(postData.reviewProductId)) {
    rating.product_id = postData.reviewProductId;
  }
  if (Utility.isPresent(postData.forGift)) {
    medium.for_gift = postData.forGift;
  }
  const response = fetch(URL.PUBLISH_POST, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      auth_token: authToken,
      medium,
      rating,
    }),
  });
  return response;
};

function* publishPostOnServer(action) {
  try {
    // console.tron.log('Publish Post Saga Called', action);
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const postId = action.postID;
    const posts = yield select((state) => state.Posts);
    const currentPost = posts[postId];
    let index = 0;
    let cardImageName = null;
    if (Utility.isPresent(currentPost?.postThumbnail)) {
      index = currentPost?.postThumbnail?.lastIndexOf('/') + 1;
      cardImageName = currentPost?.postThumbnail?.substr(index);
    }
    const mediaFiles = [];
    if (Utility.isPresent(currentPost.items)) {
      for (let i = 0; i < currentPost.items.length; i += 1) {
        yield put(
          updateLastVideoUpload({ id: postId, postData: currentPost.items[i] }),
        );
        yield put(updateCurrentPostID(postId));
        const mediaIndex = currentPost.items[i].uri.lastIndexOf('/') + 1;
        const mediaFileName = currentPost.items[i].uri.substr(mediaIndex);
        mediaFiles.push(mediaFileName);
      }
    }
    const headers = yield getApiHeaders();
    const response = yield call(
      uploadPost,
      authToken,
      currentPost,
      cardImageName,
      mediaFiles,
      headers,
    );
    if (response.status === 401) {
      yield put(logoutUser());
    } else if (response.status >= 200 && response.status <= 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(storePostServerID(action.postID, json.id));
      for (let i = 0; i < json.assets.length; i += 1) {
        yield put(
          uploadMediaToS3(
            json.assets[i].form_data,
            currentPost.items[i].uri,
            json.assets[i].url,
            action?.callback,
            true,
          ),
        );
      }
    }
  } catch (error) {}
}

export default function* watchPublishPostSaga() {
  yield takeEvery(PUBLISH_POST, publishPostOnServer);
}

// FIXME:
// 1. Remove HardCoded Products entry
// 2. Remove hardcoded categories entry
// 3. Concat Multiple video fragments into single
