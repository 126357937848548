import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { PaymentStyles } from '../cart/styles';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import FoxyShadowButton from '../../lib/FoxyShadowButton';

export default function SavedCardLayout({
  createOrder,
  card,
  savedCardCvv,
  setCvvForSavedCard,
}) {
  const [cvvError, setCvvError] = useState(false);
  const cardParams = {
    cvv: savedCardCvv,
    cardToken: card.cardToken,
    cardBin: card.cardBin,
    cardName: card.cardName,
    expiryMonth: card.expiryMonth,
    expiryYear: card.expiryYear,
    nameOnCard: card.nameOnCard,
    cardBrand: card.cardBrand,
  };
  const paymentMethodPayload = {
    paymentGateway: 'payu',
    paymentMethod: 'credit_card',
    isSavedPaymentMethod: true,
    paymentMethodPayload: {
      cvv: savedCardCvv,
      card_token: card.cardToken,
    },
    payuPayload: cardParams,
    extraData: {
      method: 'saved_card',
    },
  };
  return (
    <View style={PaymentStyles.savedCardExpandedContainer}>
      {cvvError && (
        <Text style={PaymentStyles.savedCardCvvErrorText}>
          Please Enter CVV
        </Text>
      )}

      <FoxyShadowButton
        width={Utility.getScreenWidth() - 34}
        title={'Pay'}
        onPress={() => {
          if (Utility.isBlank(savedCardCvv)) {
            setCvvError(true);
            return;
          }
          setCvvError(false);
          createOrder(paymentMethodPayload);
          setCvvForSavedCard('');
        }}
        backgroundColor={colors.primaryActionBackgroundColor}
        style={styles.payButton}
        firstColor={colors.linearGradientGreenFirst}
        secondColor={colors.linearGradientGreenSecond}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  payButton: {
    width: '90%',
    alignSelf: 'center',
  },
});
