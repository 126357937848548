import React, { Component } from 'react';
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cartIds, CartStyles } from '../../components/cart/styles';
import {
  optedForPlasticFreePackaging,
  getCartPricing,
  setContactLess,
  updatePlasticFreeData,
} from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { AnalyticsUtilty, CartAndPaymentAnalytics } from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';

class CartAdditionalItems extends Component {
  constructor(props) {
    super(props);
    this.state = { pricingData: {} };
    this.index = 0;
  }

  componentDidMount() {
    const { optedForPlasticFreePackaging } = this.props;

    this.showPlasticFreeCheckbox = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.show_plastic_free_packaging_checkbox,
    );
    const isPlasticFreePackagingCheckedByDefault = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.plastic_free_checked_by_default,
    );
    if (this.showPlasticFreeCheckbox === 'yes') {
      if (isPlasticFreePackagingCheckedByDefault === 'yes') {
        optedForPlasticFreePackaging(true);
      } else {
        optedForPlasticFreePackaging(false);
      }
    } else {
      optedForPlasticFreePackaging(false);
    }
  }

  optForPlasticFreePackaging = () => {
    const {
      optedForPlasticFreePackaging,
      optForPlasticFreePackaging,
      getCartPricing,
      showToast = () => {},
      updatePlasticFreeData,
    } = this.props;

    const plasticFreePackingCharges = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.plastic_free_packaging_charge,
    );

    if (optForPlasticFreePackaging) {
      updatePlasticFreeData(-plasticFreePackingCharges);
      optedForPlasticFreePackaging(false, (success) => {
        if (success) {
          getCartPricing();
        } else {
          showToast('Something went wrong');
        }
      });

      CartAndPaymentAnalytics.firePlasticFreeOptingEvent({ opted: false });
    } else {
      updatePlasticFreeData(+plasticFreePackingCharges);
      optedForPlasticFreePackaging(true, (success) => {
        if (success) {
          getCartPricing();
        } else {
          showToast('Something went wrong');
        }
      });
      CartAndPaymentAnalytics.firePlasticFreeOptingEvent({ opted: true });
    }
  };

  handleContactLess = () => {
    const { setContactLess, isContactLess } = this.props;

    if (isContactLess) {
      setContactLess({ isContactLess: false });
      CartAndPaymentAnalytics.fireContactLessCheckboxEvent({
        isContactLess: false,
      });
    } else {
      setContactLess({ isContactLess: true });
      CartAndPaymentAnalytics.fireContactLessCheckboxEvent({
        isContactLess: true,
      });
    }
  };

  goGreenCheckbox = () => {
    const { optForPlasticFreePackaging } = this.props;

    const goGreenHeading = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.go_green_heading,
    );

    const goGreenSubHeading = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.go_green_subheading,
    );
    const goGreenSubHeadingSelected = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.go_green_subheading_selected,
    );
    const showPlasticFreeCheckbox = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.show_plastic_free_packaging_checkbox,
    );
    if (showPlasticFreeCheckbox === 'no') {
      return null;
    }
    const styles = CartStyles;
    return (
      <TouchableWithoutFeedback onPress={this.optForPlasticFreePackaging}>
        <View style={styles.goGreenContainer}>
          <Text
            style={styles.goGreenHeading}
            dataSet={{ media: cartIds.goGreenHeading }}
            allowFontScaling={false}
          >
            {goGreenHeading}
          </Text>

          <View style={styles.alignCenterRow}>
            <View style={styles.additionalItemImage}>
              {optForPlasticFreePackaging && (
                <Image source={images.greenCheck} style={styles.checkBox} />
              )}
            </View>

            <Text
              style={styles.additionalItemText}
              dataSet={{ media: cartIds.additionalItemText }}
              allowFontScaling={false}
            >
              {optForPlasticFreePackaging
                ? goGreenSubHeadingSelected
                : goGreenSubHeading}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  contactLessDeliveryCheckbox = () => {
    const { isContactLess } = this.props;

    const checkBoxHeading = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.contactless_checkbox_heading,
    );
    const checkBoxSubHeading = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.contactless_checkbox_subheading,
    );

    const showCheckBox = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.show_contactless_checkbox,
    );

    const styles = CartStyles;

    if (showCheckBox === 'no') {
      return null;
    }

    return (
      <TouchableWithoutFeedback onPress={this.handleContactLess}>
        <View style={styles.contactLessContainer}>
          <Text
            style={styles.contactLessHeading}
            dataSet={{ media: cartIds.contactLessHeading }}
            allowFontScaling={false}
          >
            {checkBoxHeading}
          </Text>

          <View style={styles.alignCenterRow}>
            <View style={styles.additionalItemImage}>
              {isContactLess && (
                <Image source={images.greenCheck} style={styles.checkBox} />
              )}
            </View>

            <Text
              style={styles.additionalItemText}
              dataSet={{ media: cartIds.additionalItemText }}
              allowFontScaling={false}
            >
              {checkBoxSubHeading}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  render() {
    return (
      <>
        <this.contactLessDeliveryCheckbox />
        <this.goGreenCheckbox />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  isContactLess: state.bag.isContactLess,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      optedForPlasticFreePackaging,
      getCartPricing,
      setContactLess,
      updatePlasticFreeData,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CartAdditionalItems),
);
