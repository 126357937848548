import React, { Component } from 'react';
import { View, Text } from 'react-native';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListDetails } from '../../../actions/ActionTypes';
import { getRoutineSectionLists } from '../../../actions/RoutineActions';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { List } from '../../../containers/List';
import { Header } from '../../header';

class RoutineActivityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
    };
    const { getListDetails, slug, listId } = this.props;
    getListDetails(listId, (success, response) => {
      this.setState({
        itemData: response,
      });
    });
  }

  render() {
    const { itemData = {} } = this.state;
    const {
      listId = '',
      onActionPress = () => {},
      itemData: { id: routineActivityId },
      navigation,
    } = this.props;
    return (
      <View style={{ marginLeft: 4 }}>
        <Header
          item={itemData}
          navigation={navigation}
          layout={itemData.display}
          previousScreen={'previousScreen'}
          hideTopMargin
          // isVisible = {item.type !== 'quick_filters'}
        />
        <List
          itemData={itemData}
          id={listId}
          previousScreen={SCREEN_CONSTANTS.DURING_ROUTINE}
          shimmerDisplay={'mediaRail'}
          onItemPress={onActionPress}
          routineActivityId={routineActivityId}
          useInViewPort={true}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getRoutineSectionLists,
      getListDetails,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(RoutineActivityList);
