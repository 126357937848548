import React from 'react';
import { Touchable } from 'react-native';
import { View, Text, Image } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import styles from './styles';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';

const Ranking = (props) => {
  const {
    item: { rank = '', category_path = '', slug },
    productCategoryName = '',
    openList,
    sku_id,
    id,
    matchedProduct = false,
  } = props;
  const cta = `#${rank} product`;
  const afterCta = ` in category of ${productCategoryName}`;
  // AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_VIEW, {
  //   [EventParameterKey.SKU_ID]: sku_id,
  //   [EventParameterKey.PRODUCT_ID]: id,
  //   [EventParameterKey.HAS_TRUST]: true,
  // });
  return (
    <TouchableOpacity
      onPress={() => {
        AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
          [EventParameterKey.SKU_ID]: sku_id,
          [EventParameterKey.PRODUCT_ID]: id,
          [EventParameterKey.ELEMENT_NAME]: 'foxy_match',
          [EventParameterKey.ELEMENT_TYPE]: 'ranking',
        });
        openList(slug);
      }}
      hitSlop={Utility.getHitSlop()}
      disabled={matchedProduct}
    >
      <View style={styles.container}>
        <Image source={images.foxyMatch.rank} style={styles.playIcon} />

        <Text
          style={styles.foxyMatchTextCta}
          allowFontScaling={false}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {cta}
        </Text>

        <Text
          style={styles.foxyMatchText}
          allowFontScaling={false}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {afterCta}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default Ranking;
