import { StyleSheet } from 'react-native';
import MediaQueryStyleSheet from 'react-native-media-query';
import Config from '../../../libraries/ReactNativeConfig';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import { isWeb } from '../../../utils/BooleanUtility';

export const styles = StyleSheet.create({
  image: {
    width: 156,
    height: 156,
  },
  stopWatch: {
    width: 18,
    height: 18,
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const paymentVerifyingStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
  },
  iconWithCtaContainer: {
    // borderWidth: 1,
    // borderColor: colors.cta.lightBlue,
    backgroundColor: colors.green,
    width: '100%',
    marginBottom: 0,
    height: 46,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  iconWithoutCtaContainer: {
    borderWidth: 0,
    borderColor: colors.cta.lightBlue,
    width: '100%',
    marginBottom: 28,
    height: 84,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  iconWithCta: {
    height: 28,
    width: 28,
  },
  iconWithoutCta: {
    height: 80,
    width: 80,
  },
  saperatorContainer: {
    flexDirection: 'column',
    marginBottom: 16,
    marginTop: 4,
  },
  saperatorContainerBottom: {
    width: '100%',
    marginTop: 16,
  },
  saperatorText: {
    color: '#c4c4c4',
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    textAlign: 'center',
  },
  ctaIconContainer: {
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ctaText: {
    color: 'white',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
  },
  iconContainer: {
    height: 80,
    width: 80,
    marginBottom: 16,
  },
  saperatorOr: {
    color: '#000',
    fontFamily: 'Roboto-Regular',
    fontSize: 11,
    textAlign: 'center',
    marginTop: 16,
  },
  paymentStepsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: Utility.getScreenWidth(),
    marginVertical: 9,
    paddingHorizontal: 16,
  },
  stepperContainer: {
    width: 18,
    height: 18,
    backgroundColor: '#fff',
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#4F83F3',
    marginRight: 8,
  },
  stepper: {
    width: 12,
    height: 12,
    backgroundColor: '#4F83F3',
    borderRadius: 9,
  },
  verticalLineForStepper: {
    position: 'absolute',
    width: 2,
    backgroundColor: '#4F83F3',
    left: 24,
    top: 32,
    bottom: 24,
    zIndex: -1,
  },
  paymentStep: {
    marginRight: 16,
  },
  paymentStepText: {
    color: '#000',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: isWeb() ? Utility.getScreenWidth() - 44 : Utility.getScreenWidth(),
  },
  paymentDetailsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  UpiText: { color: '#a6a6a6', fontFamily: 'Roboto-Regular', fontSize: 12 },
  orderIdContainer: {
    borderWidth: 1,
    borderColor: '#EFEFEF',
    borderRadius: 16,
    paddingVertical: 2,
    paddingHorizontal: 6,
    marginTop: 8,
    maxWidth: 150,
    flexWrap: 'wrap',
    alignSelf: 'center',
  },

  orderId: {
    color: '#979797',
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    flexWrap: 'wrap',
  },
  paymentExpireText: {
    color: '#979797',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 16,
  },
  timerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  timerIcon: { height: 20, width: 20 },
  digitStyle: {
    backgroundColor: '#FFF',
    marginLeft: 0,
  },
  counterContainer: { marginLeft: 4 },
  counterDigitText: {
    color: '#FE8B4B',
    fontSize: 18,
    marginLeft: 4,
    marginBottom: 0,
    fontFamily: 'Roboto-Bold',
  },
  counterSaperator: { color: '#FE8B4B', marginBottom: 2, marginLeft: 4 },
  backInstruction: {
    color: '#5F6368',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    textAlign: 'center',
    marginVertical: 15,
  },
});

export const { ids: addressIds, styles: addressStyles } =
  MediaQueryStyleSheet.create({
    container: {
      flexDirection: 'row',
      width: Utility.getScreenWidth(),
      alignItems: 'center',
      paddingVertical: 16,
      backgroundColor: colors.white,
    },
    paymentStatusAddressContainer: {
      flexDirection: 'row',
      width: Utility.getScreenWidth(),
      alignItems: 'center',
      paddingVertical: 16,
      backgroundColor: colors.white,
      paddingTop: 4,
      alignContent: 'center',
      '@media (min-width: 768px)': {
        width: '100%',
        marginTop: 10,
      },
    },
    addressImageContainer: { alignItems: 'center', marginTop: 12 },
  });

export const paymentValidateStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
  },
  animationStyle: {
    height: 140,
    width: 140,
    resizeMode: 'cover',
    alignSelf: 'center',
  },
});

export const validationActions = StyleSheet.create({
  primaryButton: {
    marginTop: 12,
    marginHorizontal: 16,
  },
  secondaryButton: {
    marginBottom: 8,
    marginTop: 12,
    borderColor: Utility.isPresent(Config.THEME_COLOR)
      ? `${Config.THEME_COLOR}60`
      : colors.green,
    borderWidth: 1,
  },
  secondaryButtonText: {
    color: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.green,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },
  changePaymentMethod: { borderColor: colors.white, borderWidth: 1 },
  changePaymentMethodText: {
    color: '#4F83F3',
    fontSize: 12,
  },
  buttonTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },
});

export const contactUsStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  watsappImage: { width: 14, height: 14, marginRight: 4 },
  contactUs: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.cta.lightBlue,
  },
});

export const failureActions = StyleSheet.create({
  secondaryButton: {
    marginBottom: 8,
    borderColor: '#E7e7e7',
    borderWidth: 1,
    margin: 16,
  },
  secondaryButtonText: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },
});

export const cartItemsStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingVertical: 8,
    paddingRight: 16,
  },
  imageContainer: { flex: 1 },
  imageContainerDesktop: { width: 90 },
  image: { height: 60, width: 70 },
  itemDetailsConntainer: {
    flexDirection: 'column',
    flex: 4,
    justifyContent: 'space-between',
    marginLeft: 22,
  },
  brandName: {
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  productName: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  variantContainer: { flexDirection: 'row', alignItems: 'center' },
  variantImage: {
    height: 12,
    width: 12,
    borderRadius: 6,
    marginRight: 4,
    resizeMode: 'contain',
  },
  variantDisplayName: {
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  quantityWithVariant: {
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  quantity: {
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  spContainer: { flex: 1 },
  sp: {
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    alignSelf: 'flex-end',
  },
  flatlist: { backgroundColor: colors.background },
});

export const deliveryStatus = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: Utility.getScreenWidth() - 2 * Utility.largePadding,
    alignItems: 'center',
    paddingVertical: 16,
  },
  trackOrder: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
  },
  ctaContainer: { position: 'absolute', bottom: 16, right: 0 },
  cta: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.cta.lightBlue,
  },
  arivingText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
    maxWidth: Utility.getScreenWidth() - 30,
    textAlign: 'center',
  },
});

export const failureStatus = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 29,
  },
  animationStyle: {
    height: 140,
    width: 140,
    resizeMode: 'cover',
    alignSelf: 'center',
  },
});

export const successStatus = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
  },
  continueShopping: {
    marginBottom: 16,
    borderColor: '#E7e7e7',
    borderWidth: 1,
    marginHorizontal: 16,
    zIndex: 2,
  },
  animationStyle: {
    height: 140,
    width: 140,
    resizeMode: 'cover',
    alignSelf: 'center',
  },
});

export const paymentStatusMessages = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  digitStyle: {
    backgroundColor: '#FFF',
    marginLeft: 0,
  },
  counterContainer: { marginLeft: 4 },
  counterDigitText: {
    color: '#FE8B4B',
    fontSize: 14,
    marginLeft: 4,
    marginBottom: 0,
    fontFamily: 'Roboto-Bold',
  },
  counterSaperator: { color: '#FE8B4B', marginBottom: 2, marginLeft: 4 },
  headingSubheadingContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 6,
  },
  heading: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
  },
  subHeading: {
    color: '#979797',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    textAlign: 'center',
    marginHorizontal: 24,
  },
  orderIdContainer: {
    borderWidth: 1,
    borderColor: '#EFEFEF',
    borderRadius: 16,
    paddingVertical: 2,
    paddingHorizontal: 6,
    marginTop: 24,
    maxWidth: 150,
    flexWrap: 'wrap',
    marginBottom: 16,
  },
  orderId: {
    color: '#979797',
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    flexWrap: 'wrap',
  },
  animation: {
    height: 140,
    width: 140,
    resizeMode: 'cover',
    alignSelf: 'center',
    // marginTop: 12,
    marginBottom: 34,
  },
});
