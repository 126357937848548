import React, { Component } from 'react';
import {
  Text,
  View,
  TouchableWithoutFeedback,
  Image,
  FlatList,
  BackHandler,
  Linking,
  StyleSheet,
} from 'react-native';
import { memoize } from 'lodash';
import { StaticNavigationHeader } from '../../components/shared';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { jsonParser } from '../../utils/JsonUtility';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import Config from '../../libraries/ReactNativeConfig';

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.aboutUsDetails =
      jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.about_us_urls)) || {};
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      Utility.setStatusBarColor(colors.white);
    });
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
    this.unsubscribe();
  }

  navigateToScreen = (item) => {
    const { navigation } = this.props;
    const { type = '', title = '', url = '' } = item;

    switch (type) {
      case 'app_permissions':
        Linking.openSettings();
        break;
      default:
        navigation.navigate('WebUrlView', {
          destination: url,
          title,
          showBottomButton: true,
          fromOnboarding: true,
        });
        break;
    }
  };

  keyExtractor = (item, index) => `${item?.type}_${index}`;

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  renderSettingOption = ({ item = {} }) => {
    const { title = '' } = item;
    return (
      <View style={styles.borderLayout}>
        <TouchableWithoutFeedback
          onPress={memoize(
            () => this.navigateToScreen(item),
            () => [item.type],
          )}
        >
          <View style={styles.row}>
            <View style={styles.titleContainer}>
              <Text style={styles.menuOptionText}>{title}</Text>
            </View>
            <Image source={images.chevronRight} style={styles.chevronRight} />
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  };

  render() {
    const { setting_options = [] } = this.aboutUsDetails;
    return (
      <View style={styles.container}>
        <StaticNavigationHeader title={Config.ABOUT_US_SECTION_LABEL} />
        <FlatList
          data={setting_options}
          extraData={this.state}
          style={styles.flatlist}
          showsHorizontalScrollIndicator={false}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderSettingOption}
        />
      </View>
    );
  }
}

export default withNavigation(AboutUs);

const styles = StyleSheet.create({
  container: {
    height: Utility.getScreenHeight(),
    backgroundColor: colors.background,
  },
  flatlist: { paddingTop: 10, paddingBottom: 10 },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: { flexDirection: 'column', marginLeft: 24 },
  menuOptionText: {
    fontFamily: 'Roboto',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },
  borderLayout: {
    height: 60,
    marginBottom: 4,
    paddingBottom: 4,
    backgroundColor: colors.white,
    justifyContent: 'center',
  },
  buttonTitle: {
    fontFamily: 'Roboto',
    color: colors.white,
    fontSize: size.h4,
  },
  chevronRight: {
    height: 16,
    width: 16,
    tintColor: colors.darkBorder,
    marginRight: 24,
  },
});
