import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BannerCard } from '../../components/banner';
import { withMaybe } from '../../lib/Monads';
import Utility from '../../utils/Utility';

class Banner extends PureComponent {
  static getComponentHeight({ display = '' }) {
    return (1 / 3) * (Utility.getScreenWidth() - 24);
  }

  itemDataNullFn = (props) => !props.itemData;

  BannerCardWithCondition = withMaybe(this.itemDataNullFn)(BannerCard);

  render() {
    const {
      itemData,
      allVideosId,
      authToken,
      extraEventParameters = {},
    } = this.props;
    return (
      <this.BannerCardWithCondition
        {...this.props}
        itemData={itemData}
        authToken={authToken}
        allVideosId={allVideosId}
        extraEventParameters={extraEventParameters}
      />
    );
  }
}

const mapStateToProps = (store) => ({
  authToken: store.UserAccountInfo.authToken,
});

export default connect(mapStateToProps, null)(Banner);
