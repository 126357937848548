import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { getScreenWidth } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';
import FastImageView from '../../components/FastImageView';
import RoundedButton from '../../components/layout/buttons/RoundedButton';
import images from '../../theme/Images';

const whatsappImage = () => {
  return (
    <FastImageView
      source={images.routines.whatsApp_white}
      style={styles.groupCardButtonIcon}
      resizeMode='contain'
    />
  );
};

const WhatsAppCard = ({ imageUrl, onClickJoinButton, show }) => {
  if (!show) return null;

  return (
    <View style={styles.whatsAppCard}>
      <View style={styles.whatsAppCardImageContainer}>
        <FastImageView source={imageUrl} style={styles.image} />
      </View>
      <View style={styles.whatsAppCardContentContainer}>
        <Text style={styles.headingText}>Join our whatsapp community</Text>
        <Text style={styles.subHeadingText}>
          to get your questions answered directly by experts
        </Text>
      </View>

      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonText={'Join now'}
          buttonTextColor={colors.white}
          buttonColor={colors.black}
          buttonAction={onClickJoinButton}
          style={styles.buttonStyle}
          buttonTextStyle={styles.buttonText}
          renderProp={whatsappImage}
        />
      </View>
    </View>
  );
};

export default WhatsAppCard;

const styles = StyleSheet.create({
  whatsAppCard: {
    width: getScreenWidth() - 30,
    height: 120,
    backgroundColor: colors.white,
    borderRadius: 8,
    flexDirection: 'row',
  },

  whatsAppCardImageContainer: {
    width: 170,
    height: 120,

    borderRadius: 8,
    flexDirection: 'row',
    padding: 15,
  },

  whatsAppCardContentContainer: {
    flex: 1,
    height: 120,

    paddingLeft: 20,
    paddingRight: 20,
    // paddingTop: 20,
    justifyContent: 'center',
    flexDirection: 'column',
  },

  headingText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 20,
    color: colors.foxyBlack,
  },
  subHeadingText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.subtitle,
    marginTop: 10,
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: 10,
    resizeMode: 'contain',
    margin: 'auto',
  },

  buttonContainer: {
    flex: 0.6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    width: 84 * 2,
    marginRight: 20,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  groupCardButtonIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
});
