import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import CountDownWithoutText from './CounterWithoutText';
import TimeUtility from '../utils/TimeUtility';
import colors from '../theme/Colors';
import size from '../theme/Fonts';
import Utility from '../utils/Utility';

const styles = StyleSheet.create({
  container: {},
  parallelogram: {
    paddingLeft: 12,
    paddingRight: 12,
    height: 22,
  },
  parallelogramInner: {
    position: 'absolute',
    left: 8,
    right: 8,
    top: 0,
    backgroundColor: '#E6EFFF',
    height: '100%',
  },
  parallelogramInnerRed: {
    position: 'absolute',
    left: 8,
    right: 8,
    top: 0,
    backgroundColor: '#FFECE5',
    height: '100%',
  },
  parallelogramRight: {
    top: 0,
    right: 0,
    position: 'absolute',
  },
  parallelogramLeft: {
    top: 0,
    left: 0,
    position: 'absolute',
  },
  triangleCorner: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 8,
    borderTopWidth: 22,
    borderRightColor: 'transparent',
    borderTopColor: '#E6EFFF',
  },
  triangleCornerRed: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 8,
    borderTopWidth: 22,
    borderRightColor: 'transparent',
    borderTopColor: '#FFECE5',
  },
  triangleCornerBottomRight: {
    transform: [{ rotate: '180deg' }],
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h6,
    alignSelf: 'center',
    marginTop: 0,
  },
  expiryDetailsContainer: {
    flexDirection: 'row',
  },
  date: {
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
    marginTop: 4,
    color: colors.black,
  },
  dateRed: {
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
    marginTop: 4,
    color: '#ED5239',
  },
});

export default class NumberCounter extends PureComponent {
  TriangleRight = ({ showRed }) => {
    const triangleCornerStyle = showRed
      ? styles.triangleCornerRed
      : styles.triangleCorner;
    return <View style={[triangleCornerStyle, styles.parallelogramRight]} />;
  };

  TriangleLeft = ({ showRed }) => {
    const triangleCornerStyle = showRed
      ? styles.triangleCornerRed
      : styles.triangleCorner;
    return (
      <View
        style={[
          triangleCornerStyle,
          styles.triangleCornerBottomRight,
          styles.parallelogramLeft,
        ]}
      />
    );
  };

  renderCounter = ({ showRed }) => {
    const {
      counter_max_value,
      counter_current_value,
      counter_suffix = '',
    } = this.props;
    const dateStyle = showRed ? styles.dateRed : styles.date;
    return (
      <View style={styles.expiryDetailsContainer}>
        <Text style={dateStyle}>
          {`${counter_current_value} / ${counter_max_value} ${counter_suffix}`}
        </Text>
      </View>
    );
  };

  render() {
    const {
      counter_max_value,
      counter_current_value,
      showRedColor = false,
    } = this.props;
    if (
      Utility.isBlank(counter_max_value) ||
      Utility.isBlank(counter_current_value)
    ) {
      return null;
    }

    const showRed = showRedColor && counter_max_value < counter_current_value;

    const parallelogramInnerStyle = showRed
      ? styles.parallelogramInnerRed
      : styles.parallelogramInner;
    return (
      <View style={styles.parallelogram}>
        <this.TriangleLeft showRed={showRed} />
        <View style={parallelogramInnerStyle} />
        <this.renderCounter showRed={showRed} />
        <this.TriangleRight showRed={showRed} />
      </View>
    );
  }
}
