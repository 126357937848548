import React from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import FoxyMatchItemProductCard from './FoxyMatchItemProductCard';

import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import size from '../../theme/Fonts';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

const FoxyMatchForProductCard = (props) => {
  const {
    facialAnalysis: { my_attributes_values = [] } = {},
    itemData: {
      foxy_match: { title = '', subtitle = '', objects = {} } = {},
      star_ingredients = [],
      rating = '',
      id = '',
      product_category_name = '',
      product_category: {
        category_user_attributes: { goal = [] } = {},
        slug: productCategorySlug,
      } = {},
      trusted,
      sku_id = '',
    } = {},
    productPositiveIngredients,
    productNegativeIngredients,
    navigation,
    scrollToRatings,

    matchedProduct,
  } = props;

  if (Utility.isBlank(objects)) {
    return null;
  }

  const openList = (slug) => {
    AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
      [EventParameterKey.SKU_ID]: sku_id,
      [EventParameterKey.PRODUCT_ID]: id,
      [EventParameterKey.ELEMENT_NAME]: 'foxy_match',
      [EventParameterKey.ELEMENT_TYPE]: 'ranking',
    });
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'MoreItems',
      params: {
        slug: `/${slug}`,
      },
    });
  };

  const RenderItem = ({ item, index }) => {
    return (
      <FoxyMatchItemProductCard
        item={item}
        myAttributesValues={my_attributes_values}
        heroIngredients={star_ingredients}
        rating={rating}
        productCategoryName={product_category_name}
        productPositiveIngredients={productPositiveIngredients}
        productNegativeIngredients={productNegativeIngredients}
        openList={openList}
        scrollToRatings={scrollToRatings}
        goal={goal}
        navigation={navigation}
        productCategorySlug={productCategorySlug}
        trusted={trusted}
        sku_id={sku_id}
        id={id}
        matchedProduct={matchedProduct}
      />
    );
  };

  const keyExtractor = (item, index) => `${sku_id}_${index}_${id}`;

  return (
    <View>
      <View style={styles.foxyMatch}>
        <FlatList
          data={objects.slice(0, 1)}
          keyExtractor={keyExtractor}
          listKey={`${sku_id}_${id}`}
          scrollEnabled={false}
          renderItem={RenderItem}
          extraData={props}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerText: {
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  foxyMatch: {
    width: isDesktop() ? '100%' : Utility.getScreenWidth() - 24,
    paddingHorizontal: 12,
    backgroundColor: colors.white,
    borderRadius: 8,
    paddingBottom: 8,
    // height: 70,
  },
});

export default FoxyMatchForProductCard;
