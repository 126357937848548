import React, { Component } from 'react';
import {
  View,
  ActivityIndicator,
  SafeAreaView,
  BackHandler,
  ScrollView,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import colors from '../../theme/Colors';
import {
  getAllVideos,
  paginatedMoreSearchResult,
} from '../../actions/ActionTypes';
import { StaticNavigationHeader } from '../../components/shared';
import { Vertical, Listing } from '../../components/layout';
import { ORIENTATION } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';

class PaginatedList extends Component {
  constructor(props) {
    super(props);
    this.title = 'All Videos';
    const { route } = props;
    this.allVideosId = route.params?.id ?? '';
    this.dynamicDestinationSlug = route.params?.dynamicDestinationSlug ?? '';
    this.slug = route.params?.slug;
    this.previousScreen = route.params?.previousScreen;
    this.debouncepaginatedMoreSearchResult = _.debounce(
      props.paginatedMoreSearchResult,
      100,
    );
    this.state = {
      data: [],
      isLoading: false,
      page: 0,
      isCartVisible: false,
    };
  }

  componentDidMount() {
    this.getData();
    if (this.previousScreen === SCREEN_CONSTANTS.SEARCH) {
      const { route } = this.props;
      const searchQuery = route.params?.searchQuery;
      let searchContent = route.params?.content;
      if (searchContent === 'media') {
        searchContent = 'Video';
      }
      this.title = `${_.capitalize(searchContent)}s - '${searchQuery}'`;
    }

    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler.remove();
    }
  }

  toggleCartVisibility = () => {
    this.props.navigation.push('Cart', {
      source: 'paginated_list',
    });
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  createList = () => {
    const { data } = this.state;
    return { id: this.allVideosId, slug: this.allVideosId, objects: data };
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  getData = () => {
    const { getAllVideos, navigation } = this.props;
    const { data, page } = this.state;

    if (this.previousScreen === SCREEN_CONSTANTS.SEARCH) {
      this.setState({ isLoading: true });
      this.debouncepaginatedMoreSearchResult(this.slug, page, (result) => {
        if (Utility.isBlank(result)) {
          this.setState({ isLoading: false });
        } else {
          this.setState({
            data: [...data, ...result],
            isLoading: false,
          });
        }
      });
    } else {
      getAllVideos(
        this.allVideosId,
        page,
        this.dynamicDestinationSlug,
        (success, callbackData) => {
          if (success) {
            this.setState({
              data: [...data, ...callbackData],
            });
          }
        },
      );
    }
  };

  endReached = () => {
    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
      }),
      () => {
        this.getData();
      },
    );
  };

  render() {
    const { data, isLoading } = this.state;
    return (
      <View style={{ height: Utility.getScreenHeight() }}>
        <StaticNavigationHeader
          title={this.title}
          previousScreen={this.previousScreen}
        />
        <ScrollView
          style={{
            height: Utility.getScreenHeight(),
            backgroundColor: colors.background,
          }}
        >
          <Listing
            id='paginated-list'
            // orientation={ORIENTATION.HORIZONTAL}
            display='grid'
            displayCount={data.length}
            item={data}
            size='defaultSize'
            endReached={this.endReached}
            previousScreen='paginatedList'
            toggleCartVisibility={this.toggleCartVisibility}
            listData={this.createList()}
          />

          {isLoading && (
            <ActivityIndicator
              size='large'
              color={colors.silver}
              style={{ marginTop: 5, marginBottom: 5 }}
            />
          )}
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  artistGift: state.UserAccountInfo.artistGift,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getAllVideos,
      paginatedMoreSearchResult,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PaginatedList),
);
