import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fetchProduct,
  updateProductData,
  fetchProductReviews,
} from '../../actions/ActionTypes';
import { LAYOUT } from '../../config/Constants';
import Utility from '../../utils/Utility';
import WithNavigation from '../../utils/WithNavigation';
import { addProductIdInProductViews } from '../../actions/UserInteractionsActions';
import CartItemCardForOffer from '../../components/Product/CartItemCardForOffer';

class OfferCartProduct extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = props;
    this.layout = props.layout || (route.params?.display ?? LAYOUT.SCREEN);
  }

  render() {
    const {
      skuId,
      quantity,
      previousScreen,
      cartItem,
      cartProduct,
      error,
      showToastInCart,
      showToast,
      offer,
      hideAddToCart,
      isDigitalSku,
      toTop,
      onPress,
      list_id,
      force_apply_coupon,
      scrollY,
    } = this.props;
    const { itemData: { product } = {} } = this.props;

    if (Utility.isBlank(product)) {
      return null;
    }

    return (
      <CartItemCardForOffer
        layout={this.layout}
        skuId={skuId}
        quantity={quantity}
        cartItem={cartItem}
        cartProduct={cartProduct}
        onPress={onPress}
        error={error}
        showToastInCart={showToastInCart}
        previousScreen={previousScreen}
        showToast={showToast}
        offer={offer}
        hideAddToCart={hideAddToCart}
        isDigitalSku={isDigitalSku}
        toTop={toTop}
        list_id={list_id}
        force_apply_coupon={force_apply_coupon}
        scrollY={scrollY}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  todayDeals: state.todayDeals,
  boostedOffers: state.boostedOffers,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchProduct,
      updateProductData,
      fetchProductReviews,
      addProductIdInProductViews,
    },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps)(OfferCartProduct),
);
