import React, { Component } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import colors from '../../theme/Colors';
import images from '../../theme/Images';

export default class Accordion extends Component {
  toggleExpand = () => {
    const { updateExpandableComponent, item = {} } = this.props;
    updateExpandableComponent(item.title);
  };

  render() {
    const {
      title,
      expandedStatus = '',
      item,
      expandableComponent,
      hideArrow,
    } = this.props;
    return (
      <View>
        <TouchableWithoutFeedback
          ref={this.Accordion}
          onPress={this.toggleExpand}
        >
          <View>
            {title()}
            {!hideArrow && (
              <Image
                source={
                  expandedStatus.includes(item.title) &&
                    expandedStatus.includes('expanded')
                    ? images.chevronUp
                    : images.chevronDown
                }
                style={styles.chevron}
              />
            )}
          </View>
        </TouchableWithoutFeedback>
        {expandedStatus.includes(item.title) &&
          expandedStatus.includes('expanded') &&
          expandableComponent()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  row: {
    height: 52,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
    marginRight: 16,
  },
  parentHr: {
    height: 1,
    color: colors.white,
    width: '100%',
  },
  child: {
    backgroundColor: colors.white,
    padding: 16,
  },

  chevron: {
    position: 'absolute',
    right: 20,
    bottom: 20,
    tintColor: colors.foxyBlack,
  },
});
