import React from 'react';
import { View, TouchableOpacity, Image, Animated, Share } from 'react-native';
import Toast from 'react-native-easy-toast';
import { Dropdown } from 'react-native-material-dropdown';
import { AVAILABLE_BUILD_TYPES } from '../../config/Constants';
import { ProductDetailStyles } from './styles';
import Images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import ShareModal from './ShareModal';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

import { BagIcon } from '../header';

import CustomNavigationHeader from './CustomNavigationHeader';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import Config from '../../libraries/ReactNativeConfig';
import FastImageView from '../FastImageView';
import AppConfig from '../../config/AppConfig';
import headerStyles from '../header/styles';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';

class TransparentHeader extends CustomNavigationHeader {
  constructor(props) {
    super(props);
    this.bagIconContainerStyle = [
      ProductDetailStyles.navigationShareButtonContainer,
      { right: 62 },
    ];
  }

  onSearchTap = () => {
    const { navigation } = this.props;

    navigation.push('Search', {});
  };

  navigateToInviteCentre = () => {
    const { navigation } = this.props;
    navigation.push('InviteCenter');
  };

  renderInviteCentre = () => {
    const { renderInviteCentreIcon } = this.props;
    if (!renderInviteCentreIcon) return null;
    if (!AppConfig.getBooleanValue(Config.SHOW_INVITE_CENTER_ON_WISHLIST))
      return null;

    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={ProductDetailStyles.inviteCentreIcon}
        onPress={this.navigateToInviteCentre}
      >
        <Image
          source={Images.addUser}
          style={ProductDetailStyles.addUsersIconStyle}
        />
      </DebouncedTouchableOpacity>
    );
  };

  onSocialShareOptionTapped = (medium) => {
    const {
      shareUrl,
      isProduct = false,
      name,
      image,
      brand,
      previousScreen,
      id,
      screenName,
      content,
      skuId,
      fromPersonalizedPage,
      utmSource: utm_source = 'share',
      utmMedium: utm_medium = 'share',
      utmCampaign: utm_campaign = '',
      onShareTapped,
    } = this.props;
    if (onShareTapped) {
      onShareTapped();
      return;
    }
    // screenName={SCREEN_CONSTANTS.CATEGORY}
    let sharedUrl = shareUrl; // to change url when triggered from offer detail page
    let title = name;
    let message = '';

    if (fromPersonalizedPage) {
      sharedUrl = `${sharedUrl}?fromPersonalizedPage=true`;
    }

    if (isProduct) {
      title = `${name} ${brand.name}`;
    }
    if (screenName === SCREEN_CONSTANTS.ARTIST_DETAILS) {
      message = `Follow ${name} to catch their latest videos on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    } else if (screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL) {
      message = `Check out ${name} on ${Config.APP_NAME}`;
    } else if (screenName === SCREEN_CONSTANTS.BRAND_DETAILS) {
      message = `Check out top product and offers of ${name} on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    } else if (screenName === SCREEN_CONSTANTS.MORE_PAGE) {
      message = `Check out this collection on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    } else if (screenName === SCREEN_CONSTANTS.OFFER_DETAIL) {
      message = `Check out this offer of ${name} on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    } else if (screenName === SCREEN_CONSTANTS.CATEGORY) {
      message = `Check out this category of ${name} on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;
    }

    if (Utility.isBlank(sharedUrl)) {
      this.toast.show('An unexpected error occurred');
      return;
    }

    const linkOptions = {
      title,
      imageUrl: Utility.getMinifiedImage(image, 200, 200),
      description: '',
      trackingParams: { utm_source, utm_medium, utm_campaign },
    };

    try {
      const getDynamicLink = DynamicLinkManager.getShortenUrl(
        sharedUrl,
        linkOptions,
      );
      getDynamicLink
        .then((link) => {
          const options = {
            title: Config.APP_NAME,
            message,
            url: link,
            // social: Share.Social[_.upperCase(medium)],
          };
          if (Utility.isPresent(id) && Utility.isPresent(content)) {
            let contentType = content;
            let screen_name = screenName;
            this.logAnalyticsEvent(
              screen_name,
              id,
              name,
              contentType,
              medium,
              skuId,
            );
          }
          Share.open(options)
            .then((res) => {})
            .catch((err) => {});
        })
        .catch((error) => {
          console.tron.log('Error', error);
        });
    } catch (e) {
      console.tron.log('Error', e);
    }
  };

  shareIcon = () => {
    const { screenName, onShareTapped = () => {} } = this.props;
    if (isWeb()) return null;
    const showShareIcon =
      screenName === SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE ||
      screenName === SCREEN_CONSTANTS.PRE_ROUTINE ||
      screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL;

    if (!showShareIcon) return null;

    return (
      <TouchableOpacity
        style={
          ProductDetailStyles.navigationShareButtonContainerTransparentHeader
        }
        onPress={this.onSocialShareOptionTapped}
      >
        <FastImageView
          source={Images.routines.share_icon}
          style={ProductDetailStyles.addUsersIconStyle}
        />
      </TouchableOpacity>
    );
  };

  chatIcon = () => {
    const {
      screenName,
      onChatTapped = () => {},
      hideChatButton = false,
    } = this.props;
    const showChatIcon =
      screenName === SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE ||
      screenName === SCREEN_CONSTANTS.PRE_ROUTINE;

    if (!showChatIcon || hideChatButton) return null;

    return (
      <TouchableOpacity
        style={
          ProductDetailStyles.navigationChatButtonContainerTransparentHeader
        }
        onPress={onChatTapped}
      >
        <FastImageView
          source={Images.routines.whatsApp_white}
          style={ProductDetailStyles.addUsersIconStyle}
          tintColor={colors.foxyBlack}
          resizeMode='contain'
        />
        <View style={headerStyles.notificationIndication} />
      </TouchableOpacity>
    );
  };

  render() {
    const {
      content,
      image,
      isProduct,
      name,
      sp,
      mrp,
      discount,
      brand,
      subHeading,
      showMenuButton,
      previousScreen,
      morePage = false,
      morePageDisplay,
      onPressCart,
      showSearchButton,
      navigationHeaderHeight = 24,
      hideBackButton = false,
      screenName,
      hideAnimation,
    } = this.props;

    const { isShareModalVisible } = this.state;
    let shareButtonRightSpace = 90;

    if (content === 'artist') {
      shareButtonRightSpace = 52; // for artist page there are three icons in navigation header
    }
    if (
      previousScreen === 'dashboard' ||
      Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only ||
      previousScreen === SCREEN_CONSTANTS.COLLECTION ||
      (previousScreen === SCREEN_CONSTANTS.ARTIST && morePage) ||
      previousScreen === SCREEN_CONSTANTS.PERSONALISED_PAGE ||
      morePageDisplay === 'full-page-banner'
    ) {
      showShareButton = false;
    }
    let headerImageInitialOutput = 0;
    let navigationHeaderInitialColor = 'transparent';
    if (!Utility.isBlank(previousScreen)) {
      if (previousScreen === SCREEN_CONSTANTS.COLLECTION || hideAnimation) {
        navigationHeaderInitialColor = 'rgba(255,255,255,1)';
        headerImageInitialOutput = 1;
      }
    }

    const showDropdown = previousScreen === SCREEN_CONSTANTS.ARTIST;

    const hideIconsForRoutinesFlow =
      screenName === SCREEN_CONSTANTS.PRE_ROUTINE ||
      screenName === SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;

    const heightInset = hideIconsForRoutinesFlow ? 32 : 24;

    return (
      <>
        <View
          style={{
            position: 'absolute',
            overflow: 'visible',
            zIndex: 3,
            flexDirection: 'row',
            backgroundColor: navigationHeaderInitialColor,
            top: 0,
            left: 0,
            right: 0,
            height: Utility.isAndroid()
              ? this.navigationHeaderHeight + navigationHeaderHeight
              : this.navigationHeaderHeight,
          }}
        >
          <Animated.View
            style={[
              ProductDetailStyles.headerImageBackground,
              {
                opacity: this.props.scrollY.interpolate({
                  inputRange: [
                    0.4 * getScreenWidth(),
                    0.4 * getScreenWidth() + 10,
                  ],
                  outputRange: [headerImageInitialOutput, 1],
                }),
                backgroundColor: this.props.scrollY.interpolate({
                  inputRange: [
                    0.4 * getScreenWidth(),
                    0.4 * getScreenWidth() + 10,
                  ],
                  outputRange: [
                    'rgba(255, 255, 255,0.0)',
                    'rgba(255,255,255,1)',
                  ],
                }),
                useNativeDriver: true,
              },
            ]}
          />
          <Animated.View
            style={[
              ProductDetailStyles.navigationHeaderChildView,
              {
                opacity: this.props.scrollY.interpolate({
                  inputRange: [
                    0.4 * Utility.getScreenWidth(),
                    0.4 * Utility.getScreenWidth() + 10,
                  ],
                  outputRange: [headerImageInitialOutput, 1],
                }),

                useNativeDriver: true,
              },
            ]}
          >
            {this.props.children}
          </Animated.View>

          {!hideBackButton && (
            <TouchableOpacity
              onPress={this.goBack}
              style={ProductDetailStyles.navigationBackButtonContainer}
              hitSlop={Utility.getHitSlop('backButton')}
            >
              <Image
                source={Images.ic_back_product}
                style={ProductDetailStyles.navbarIconDefaultStyle}
              />
            </TouchableOpacity>
          )}

          {!hideIconsForRoutinesFlow && (
            <View style={this.bagIconContainerStyle}>
              <BagIcon handleTap={onPressCart} showCart />
            </View>
          )}

          {showMenuButton && (
            <TouchableOpacity
              // onPress={onClickMenu}
              onPress={this.menuButtonPressed}
              style={[
                ProductDetailStyles.navigationShareButtonContainer,
                { right: shareButtonRightSpace },
              ]}
              hitSlop={Utility.getHitSlop()}
            >
              <Image
                source={Images.overflow_menu}
                style={{ height: 35, width: 35 }}
              />
            </TouchableOpacity>
          )}
          {showDropdown ? (
            <Dropdown
              ref={this.dropdownRef}
              label={null}
              data={this.dropDownOption}
              dropdownPosition={-2}
              pickerStyle={{
                position: 'absolute',
                width: 110,
              }}
              containerStyle={{
                right: 110,
                position: 'absolute',
                width: 0,
                height: 0,
                top: this.navigationHeaderHeight,
              }}
              renderAccessory={null}
              textColor={colors.foxyBlack}
              hitSlop={{ top: 20, right: 20, left: 20 }}
              onChangeText={(type) => this.dropDownOptionSelected(type)}
              fontSize={12}
              itemColor={colors.foxyBlack}
              selectedItemColor={colors.foxyBlack}
            />
          ) : null}

          {!hideIconsForRoutinesFlow && !isDesktop() && (
            <DebouncedTouchableOpacity
              {...this.props}
              style={
                ProductDetailStyles.navigationShareButtonContainerTransparentHeader
              }
              onPress={this.onSearchTap}
            >
              <Image
                source={Images.ic_search_product}
                style={ProductDetailStyles.navbarShareIconStyle}
              />
            </DebouncedTouchableOpacity>
          )}
          <this.renderInviteCentre />
          <this.shareIcon />
          <this.chatIcon />
        </View>

        <ShareModal
          isShareModalVisible={isShareModalVisible}
          hideShareModal={this.hideShareModal}
          onSocialShareOptionTapped={this.onSocialShareOptionTapped}
          image={image}
          isProduct={isProduct}
          name={name}
          sp={sp}
          mrp={mrp}
          discount={discount}
          brand={brand}
          subHeading={subHeading}
        />
        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </>
    );
  }
}

export default withNavigation(React.memo(TransparentHeader));
