import React from 'react';
import { StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
import { SIZE, LAYOUT, THEME } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import OfferCartProduct from '../../containers/product/OfferCartProduct';
import images from '../../theme/Images';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import LinearGradient from 'react-native-linear-gradient';

const OfferCartItemExpanded = ({
  cartItem,
  navigation,
  setCurrentIndex,
  currentIndex,
  index,
  setCurrentList,
  coupon_code,
  itemData,
  force_apply_coupon,
  scrollY,
  comboOfferCardPrompt,
}) => {
  let newCart = cartItem;

  const x = { x: 0, y: 0 };
  const y = { x: 0, y: 1 };

  if (Utility.isPresent(cartItem?.offer)) {
    newCart = { ...cartItem, product: cartItem.offer };
  }

  if (cartItem?.type === 'empty') {
    const onEmptyCartItemLowerPress = () => {
      setCurrentList(cartItem.list_id);

      setTimeout(setCurrentIndex, 0);

      AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
        [EventParameterKey.ACTION]: 'add_item_large',
        [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.COMBO_OFFER_DETAIL,
        [EventParameterKey.COUPON_CODE]: coupon_code,
        [EventParameterKey.ENTITY]: 'combo_offer',
      });
    };
    const emptyCartItemLower =
      currentIndex === index
        ? styles.emptyCartItemPlaceholderLowerSelected
        : styles.emptyCartItemPlaceholderLowerUnselected;

    const gradientArray = [
      Utility.addAlpha(cartItem.bgColor, 0.15),
      Utility.addAlpha(cartItem.bgColor, 0.25),
    ];

    return (
      <TouchableOpacity
        onPress={onEmptyCartItemLowerPress}
        style={emptyCartItemLower}
      >
        <LinearGradient
          colors={gradientArray}
          style={styles.gradientContainer}
          start={x}
          end={y}
        />
        <Image source={images.plus_thin} style={styles.plusImageLarge} />
      </TouchableOpacity>
    );
  }

  if (cartItem?.quantity === 0) {
    return null;
  }

  const onCartItemCardPressLower = () => {
    setCurrentList(cartItem.possible_offer_list_ids[0]);

    setTimeout(setCurrentIndex, 0);
  };
  const cartItemLowerStyle =
    currentIndex === index
      ? styles.cartItemLowerSelected
      : styles.cartItemLowerUnselected;

  return (
    <TouchableOpacity
      onPress={onCartItemCardPressLower}
      style={cartItemLowerStyle}
    >
      <OfferCartProduct
        size={SIZE.large}
        layout={LAYOUT.CART}
        cart
        id={newCart?.productId}
        itemData={newCart}
        navigation={navigation}
        theme={THEME.LIGHT}
        previousScreen={SCREEN_CONSTANTS.CART}
        skuId={newCart?.sku_id}
        quantity={newCart?.quantity}
        cartItem={newCart}
        cartProduct={newCart?.product}
        list_id={itemData.list_id}
        force_apply_coupon={force_apply_coupon}
        scrollY={scrollY}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  emptyCartItemPlaceholderLowerSelected: {
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    marginRight: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.foxyBlack,
  },
  emptyCartItemPlaceholderLowerUnselected: {
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    marginRight: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    borderWidth: 0,
    borderColor: colors.white,
  },
  gradientContainer: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
    position: 'absolute',
  },
  plusImageLarge: {
    width: 22,
    height: 22,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
  },
  boxText: {
    fontSize: 14,
    color: '#979BAA',
    fontFamily: 'Roboto-Regular',
    marginTop: 8,
    textAlign: 'center',
    marginHorizontal: 8,
  },
  cartItemLowerSelected: {
    // zIndex: 1000,
    // marginRight: 8,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.foxyBlack,
    flex: 1,
  },
  cartItemLowerUnselected: {
    // zIndex: 1000,
    // marginRight: 8,
    borderRadius: 6,
    borderWidth: 0,
    flex: 1,
  },
});

export default OfferCartItemExpanded;
