import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  TouchableWithoutFeedback,
  Image,
  ActivityIndicator,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../../theme/Colors';
import ScaleAnimate from './ScaleAnimate';
import Utility from '../../utils/Utility';
import DebouncedTouchableOpacity from './DebouncedTouchableOpacity';

class FoxyButton extends PureComponent {
  constructor(props) {
    super(props);
    const { textStyle } = this.props;
    this.titleStyle = [styles.text, textStyle];
  }

  bottomTextBar = (props) => (
    <TouchableWithoutFeedback
      onPress={props.onBottomTextClick}
      hitSlop={Utility.getHitSlop('button')}
    >
      <View style={styles.bottomBarLayout}>
        <Text style={styles.bottomTextStyle}>{props.title}</Text>
      </View>
    </TouchableWithoutFeedback>
  );

  render() {
    const {
      title = '',
      onActionPress,
      layoutStyle,
      disabled = false,
      showLoader = false,
      show_button_icon,
      icon_type,
      actionButtonColor,
      bottomTextVisible,
      bottomText,
      onBottomTextClick,
      textStyle,
      iconStyle = {},
    } = this.props;
    const btnColor = actionButtonColor;
    return (
      <>
        <DebouncedTouchableOpacity
          {...this.props}
          disabled={disabled}
          onPress={onActionPress}
          style={[styles.button, { backgroundColor: btnColor }, layoutStyle]}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {show_button_icon && !showLoader && (
              <Image source={icon_type} style={iconStyle} />
            )}
            {show_button_icon && showLoader && (
              <ActivityIndicator
                color='#ffffff'
                size='small'
                style={{ marginLeft: 5 }}
              />
            )}
            <Text style={this.titleStyle}>{title}</Text>
          </View>
        </DebouncedTouchableOpacity>
        {bottomTextVisible && (
          <this.bottomTextBar
            title={bottomText}
            onBottomTextClick={onBottomTextClick}
          />
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  bottomBarLayout: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    marginBottom: 15,
  },
  bottomTextStyle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
  },
  button: {
    position: 'absolute',
    height: 46,
    backgroundColor: colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    left: 24,
    right: 24,
    borderRadius: 4,
    zIndex: 1,
  },
  text: {
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontSize: 18,
    marginLeft: 5,
  },
});

FoxyButton.propTypes = {
  onActionPress: PropTypes.func,
  bottomTextVisible: PropTypes.bool,
  bottomText: PropTypes.string,
  onBottomTextClick: PropTypes.func,
};
export default FoxyButton;
