import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Image,
  Text,
  ActivityIndicator,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import { MAX_LINES } from '../../config/Constants';

export default class LoginFeatureCard extends Component {
  constructor() {
    super();
  }

  _showProgressIndicator = (props) => (
    <>
      {props.showProgressIndicator && (
        <View style={styles.progressIndicatorContainer}>
          <ActivityIndicator size='small' color={colors.white} />
        </View>
      )}
      {!props.showProgressIndicator && null}
    </>
  );

  render() {
    const {
      itemData: { title, body, background_color },
      onCardPress,
      onCancelPress,
      image_url,

      isDismissable,
      isLocalImage,
      width,
      showProgressIndicator,
      height,
      textColor = colors.foxyBlack,
      webpImageUrl,
    } = this.props;

    const gradientColor = ['#D5DCE5', '#D7DEE7'];

    return (
      <TouchableOpacity onPress={onCardPress}>
        <View
          style={[
            styles.selfieFeatureCardContainer,
            { backgroundColor: background_color },
          ]}
        >
          <Image
            source={images.loginCardIcon}
            style={styles.cardLeftImageStyle}
          />

          <View style={styles.descriptionContainer}>
            <View style={styles.centerAlignedRow}>
              <Text style={styles.featureHeading}>{title}</Text>
              <View style={styles.chevronContainer}>
                <Image
                  source={images.chevronRight}
                  style={styles.chevronStyle}
                />
              </View>
            </View>
            <Text
              numberOfLines={MAX_LINES.three}
              style={[
                styles.featureSubHeading,
                { marginTop: 0, width: '90%', color: textColor },
              ]}
            >
              {body}
            </Text>
          </View>
          {isDismissable && (
            <TouchableOpacity
              onPress={onCancelPress}
              onPressIn={onCancelPress}
              hitSlop={Utility.getHitSlop()}
              style={styles.featureCancelContainer}
            >
              <View>
                <Image source={images.cross} style={styles.featureCross} />
              </View>
            </TouchableOpacity>
          )}

          <this._showProgressIndicator
            showProgressIndicator={showProgressIndicator}
          />
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  selfieFeatureCardContainer: {
    flexDirection: 'row',
    marginLeft: Utility.padding,
    justifyContent: 'center',
    marginRight: Utility.padding,
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 4,
    overflow: 'hidden',
    alignItems: 'center',
    paddingLeft: 25,
    paddingRight: 15,
    paddingTop: 18,
    borderWidth: 1,
    borderColor: colors.border,
    backgroundColor: colors.white,
  },
  cardLeftImageStyle: {
    height: 84,
    width: 107,
    marginLeft: 20,
  },

  selfieCardColumn: {
    flexDirection: 'column',
  },
  centerAlignedRow: { flexDirection: 'row', alignItems: 'center' },
  chevronContainer: {
    borderRadius: 30,
    backgroundColor: colors.foxyBlack,
    justifyContent: 'center',
    padding: 5,
    height: 15,
    width: 15,
    right: 5,
    alignItems: 'center',
  },

  chevronStyle: { height: 12, width: 12, tintColor: colors.white },
  descriptionContainer: {
    flexDirection: 'column',
    // justifyContent: 'space-between',
    marginRight: 0,
    height: 60,
    width: '80%',
    marginLeft: 6,
  },
  featureHeading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginRight: 16,
  },
  featureSubHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginRight: 0,
    width: '80%',
  },
  featureCancelContainer: {
    position: 'absolute',
    top: 12,
    right: 12,
    width: 15,
    height: 15,
  },
  featureCross: { tintColor: '#9D9D9D', height: 15, width: 15 },
  progressIndicatorContainer: {
    position: 'absolute',
    top: 20,
    bottom: 0,
    right: 15,
  },
});
