import { Linking } from 'react-native';
import { AnalyticsManager, EventType } from '../analytics';
import { REMOTE_CONFIG_KEYS } from '../config/Constants';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';
import NavigationService from '../navigator/NavigationService';
import colors from '../theme/Colors';
import RemoteConfig from './RemoteConfig';
import Utility from './Utility';

export default class ContactsUsUtility {
  static onContactUsModalOptionPress = (option = {}) => {
    const { body, subject, value } = option;
    switch (option.type) {
      case 'dialer':
        Linking.openURL(`tel:${value}`);
        break;
      case 'whatsapp':
        Utility.openWhatsApp(
          option.body,
          () => ContactsUsUtility.openWhatsappAlert(option),
          option.value,
        ); 
        break;
      case 'email':
        Linking.openURL(`mailto:${value}?subject=${subject}&body=${body}`);
        break;
      default:
        break;
    }
  };

  static onContactUsOptionPress = () => {
    const contactUsModalData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.contact_us_modal_data),
    );
    const { share_options = [] } = contactUsModalData;
    AnalyticsManager.logEvent(EventType.profileEvents.OTHER_ACTIONS_CLICK, {
      type: 'contact_us',
    });
    if (share_options.length < 2) {
      ContactsUsUtility.onContactUsModalOptionPress(share_options[0]);
      return;
    }
    NavigationService.push(SCREEN_CONSTANTS.CONTACT_US_MODAL, {
      contactUsModalData,
    });
  };

  static openWhatsappAlert = (option) => {
    NavigationService.push(SCREEN_CONSTANTS.POPUP_ALERT, {
      isVisible: true,
      alertBoxTitle: 'Contact Us',
      alertMessage: `Whatsapp us on ${option.value} to connect with us`,
      firstButtonTitle: 'Ok',
      firstButtonTextColor: colors.dodgerBluePressed,
      firstButtonOnPress: ContactsUsUtility.dismissAlert,
      hideSecondButton: true,
      height: 150,
    });
  };

  static dismissAlert = () => {
    NavigationService.goBack();
  };
}
