import Utility from '../../utils/Utility';

const artistHorizontalSpacing = 24;
const numberOfCards = 3;
export const artistConstants = {
  railViewHeight: 89,
  descriptionSpacing: 35,
  storyRailViewHeight: 86,
  paddingForCircularRing: 4,
  artistNameSpacing: 40,
  defaultImageWidth: 48,
};
export const ARTIST = {
  CARD: {
    rail: {
      defaultSize: {
        viewwidth: artistConstants.railViewHeight,
        viewheight:
          artistConstants.railViewHeight + artistConstants.descriptionSpacing,
        imagewidth: artistConstants.railViewHeight,
        imageheight: artistConstants.railViewHeight,
      },
    },
    storyRail: {
      defaultSize: {
        viewwidth:
          artistConstants.storyRailViewHeight +
          artistConstants.paddingForCircularRing,
        viewheight:
          artistConstants.storyRailViewHeight +
          artistConstants.artistNameSpacing +
          artistConstants.paddingForCircularRing,
        imagewidth: artistConstants.storyRailViewHeight,
        imageheight: artistConstants.storyRailViewHeight,
      },
    },
    grid: {
      defaultSize: {
        viewwidth: Utility.getDynamicWidthForGrid(3, artistHorizontalSpacing),
        viewheight:
          Utility.getDynamicWidthForGrid(
            numberOfCards,
            artistHorizontalSpacing,
          ) + Utility.artistNameContainerHeight,
        imagewidth: Utility.getDynamicWidthForGrid(
          numberOfCards,
          artistHorizontalSpacing,
        ),
        imageheight: Utility.getDynamicWidthForGrid(
          numberOfCards,
          artistHorizontalSpacing,
        ),
      },
    },
    list: {
      defaultSize: {
        viewwidth: Utility.getScreenWidth() - 24,
        viewheight: Utility.listDefaultViewHeight,
        imagewidth: 96,
        imageheight: 96,
      },
    },
  },
  SCREEN: {},
};
