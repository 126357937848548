import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { startFacialAnalysis, addProfilePic } from '../../actions/LoginActions';
import styles from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import FastImageView from '../../components/FastImageView';
import AppConfig from '../../config/AppConfig';
import { isNative } from '../../utils/BooleanUtility';

class ProfilePic extends Component {
  constructor(props) {
    super(props);
    // this.state = { showProfilePicLoader: false };
  }

  goToCamera = () => {
    const { navigation } = this.props;
    this.props.showModalSelfie();
  };

  _activityIndicator = () => (
    <View
      style={[
        styles.profileImage,
        {
          justifyContent: 'center',
          alignItem: 'center',
          position: 'absolute',
        },
      ]}
    >
      <ActivityIndicator size='small' color={colors.green} />
    </View>
  );

  onPressMyPassButton = () => {
    const { navigation } = this.props;
    console.tron.log('Navigation', navigation);
    navigation.navigate('UpdateCart', { showIndependentPass: true });
  };

  foxyPassContainer = () => {
    const { authToken } = this.props;
    if (Utility.isBlank(authToken)) return null;
    return (
      <TouchableOpacity
        style={styles.foxyPassContainer}
        onPress={this.onPressMyPassButton}
      >
        <FastImageView
          source={images.retail.profileScanningIcon}
          height={15}
          width={15}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const {
      imageUrl,
      instagram_profile,
      selfieImageUrl,
      showProfilePicLoader,
      height = 76,
      width = 76,
      emptyStateImage,
    } = this.props;

    // const { showProfilePicLoader } = this.state;
    const imageSource = Utility.currentProfilePicToShow(
      selfieImageUrl,
      instagram_profile,
      imageUrl,
      emptyStateImage,
    );

    const disabled = Utility.isBlank(imageUrl);
    return (
      <TouchableOpacity
        disabled={disabled}
        style={styles.profileImage}
        onPress={this.goToCamera}
      >
        <FastImageView
          style={{ height, width, borderRadius: 60 }}
          source={imageSource}
          resizeMode='cover'
        />
        {Utility.isBlank(imageSource) ? (
          <Image
            source={images.myProfile.addImage}
            style={styles.addImageButton}
          />
        ) : null}
        {showProfilePicLoader && <this._activityIndicator />}

        {isNative() &&
          !AppConfig.getBooleanValue(Config.DISABLE_PROFILE_SCAN_BUTTON) && (
            <this.foxyPassContainer />
          )}
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  authToken: state.UserAccountInfo.authToken,
  imageUrl: state.UserAccountInfo.profile.image_url,
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
  instagram_profile: state.UserAccountInfo.instagram_profile,
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      startFacialAnalysis,
      addProfilePic,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(withNavigation(ProfilePic)),
);
