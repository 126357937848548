import _ from 'lodash';
import * as types from '../actions/ActionTypes';
import initialState from './initialState';
import Utility from '../utils/Utility';

export default function order(state = initialState.order, action) {
  if (Utility.isBlank(action.data)) {
    return state;
  }
  const cartItems = action.data;
  switch (action.type) {
    case types.ORDER_SUCCESS:
      return {
        ...state,
        ...cartItems,
      };
    default:
      return state;
  }
}
