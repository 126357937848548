import React, { Component } from 'react';
import { ScrollView, BackHandler } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from '../List';
import { fetchList } from '../../actions/ActionTypes';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import WithNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';

class ListTabPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
      listPage: 0,
    };
    this.fetchList();
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.stopPagination = false;
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  fetchList = () => {
    const { fetchList, listSlug } = this.props;
    const { listPage, itemData } = this.state;
    fetchList({ slug: listSlug || '' }, listPage, (success, data) => {
      if (!success || Utility.isBlank(data)) {
        return;
      }
      if (Utility.isBlank(data.objects)) {
        this.stopPagination = true;
        return;
      }
      const dataToBeSet = Utility.isBlank(itemData)
        ? data
        : { ...itemData, objects: [...itemData.objects, ...data.objects] };
      this.setState({
        itemData: dataToBeSet,
        listPage: listPage + 1,
      });
    });
  };

  paginate = () => {
    if (this.stopPagination) {
      return;
    }
    this.fetchList();
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.navigate('Feed');
    return true;
  };

  isCloseToBottom = async ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingToBottom = 120;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  onMomentumScrollEnd = ({ nativeEvent }) => {
    if (this.isCloseToBottom(nativeEvent)) {
      this.paginate();
    }
  };

  render() {
    const { itemData = {} } = this.state;
    const { navigation } = this.props;
    return (
      <ScrollView onMomentumScrollEnd={this.onMomentumScrollEnd}>
        <List
          itemData={itemData}
          navigation={navigation}
          previousScreen={SCREEN_CONSTANTS.LIST_TAB}
        />
      </ScrollView>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchList }, dispatch),
  };
}

export default WithNavigation(connect(null, mapDispatchToProps)(ListTabPage));
