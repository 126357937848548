import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

const iconsBottomSpace = Utility.isIOS() ? 8 : 12;
const BrandCollabStyles = StyleSheet.create({
  scrollView: {
    backgroundColor: colors.background,
  },
  brandCollabCard: {
    width: Utility.getScreenWidth() - 2 * Utility.padding,
    height: (9 / 16) * (Utility.getScreenWidth() - 2 * Utility.padding) + 72, // 72 for description section
    marginLeft: 12,
    marginRight: 12,
    marginTop: 6,
    marginBottom: 6,
  },
  brandCollabCardImage: {
    width: Utility.getScreenWidth() - 2 * Utility.padding,
    height: (9 / 16) * (Utility.getScreenWidth() - 2 * Utility.padding),
    borderRadius: 4,
  },
  brandCollabCardDescription: {
    padding: 8,
    justifyContent: 'space-evenly',
    // justifyContent: 'flex-start',
    height: 72,
  },
  collabCardDesTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  collabCardDesSubTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    maxWidth: Utility.getScreenWidth() - 2 * Utility.padding - 108,
  },
  brandCollabScrollView: {
    // marginBottom: 60,
  },
  brandCollabDetailImage: {
    height: (9 / 16) * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },
  bannerImageStyle: {
    position: 'absolute',
    bottom: 0,
    width: Utility.getScreenWidth(),
    height: (9 / 16) * Utility.getScreenWidth(),
    alignSelf: 'center',
    resizeMode: 'cover',
  },
  brandLogoSection: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    height: 50,
    alignItems: 'center',
  },
  brandLogo: {
    position: 'absolute',
    height: 90,
    width: 90,
    borderRadius: 8,
    alignSelf: 'center',
    backgroundColor: 'white',
    alignSelf: 'center',
    borderWidth: 0.5,
    borderColor: 'rgba(150,150,150,0.2)',
  },
  brandLogoImage: {
    borderRadius: 8,
    height: 90,
    width: 90,
    backgroundColor: colors.white, // TODO: remove this
  },
  brandCollabDetailTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    margin: 16,
  },
  eventDetailContainer: {
    width: '100%',
    justifyContent: 'space-evenly',
    paddingLeft: 16,
    paddingRight: 16,
  },
  eventDetailSubview: {
    flexDirection: 'row',
    height: 32,
    alignItems: 'center',
  },
  eventDetailIcon: {
    height: 18,
    width: 18,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
  },
  eventDetailSubtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 8,
  },
  actionBottonContainer: {
    position: 'absolute',
    height: 70,
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  brandCollabDetailDescription: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    margin: 16,
  },
  emptySpace: {
    height: 70,
    width: '100%',
  },
  flatlistContainer: {
    marginBottom: 4,
    marginTop: 4,
  },
  railView: {
    marginLeft: Utility.padding - Utility.artistStoryMargin,
  },
  headingContainer: {
    flexDirection: 'row',
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 8,
    marginTop: 12,
  },
  subHeading: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    flex: 1,
    alignContent: 'flex-start',
  },
  campaignCardRegisterButton: {
    width: 80,
    height: 30,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 12,
    top: 22,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 4,
  },
  campaignCardRegisterText: {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    fontSize: size.h4,
  },
  freeProductTag: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 108,
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
  },
  freeProductIcon: {
    marginLeft: 4,
  },
  freeProductText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
    marginLeft: 4,
  },
  morebuttonstyle: {
    fontSize: 14,
    color: '#fd5c63',
    alignContent: 'flex-end',
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
  },
  pendingApprovalText: {
    fontSize: 18,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  navbarIconDefaultStyle: {
    width: 20,
    height: 17.5,
    resizeMode: 'contain',
  },
  editButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    right: 12,
    bottom: iconsBottomSpace,
  },
  rejectionReason: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    marginRight: 20,
    marginLeft: 20,
    marginBottom: 8,
  },
  brandStackContainer: {
    position: 'absolute',
    top: -45,
    height: 90,
    width: 90,
    overflow: 'visible',
    flexDirection: 'row-reverse',
  },
  chatWithUsContainer: {
    height: 50,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  chatWithUsText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginRight: 20,
  },
  videoUploadViaFormContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 56,
    flex: 1,
  },
  videoUploadViaFormText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    color: colors.foxyBlack,
  },
});

export default BrandCollabStyles;
