import React, { useState } from 'react';
import { View, Text, Image, TextInput, Keyboard } from 'react-native';
import _ from 'lodash';
import images from '../../theme/Images';
import { PaymentStyles } from '../cart/styles';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';

export default function SavedCardLayout({
  cardNumber = {},
  card,
  borderColor,
  setCvvForSavedCard,
  index,
  setRef,
  updateExpandableComponent,
  expandedStatus,
  savedCardCvv,
}) {
  const modifiedCardNumber = `${cardNumber.substring(
    0,
    4,
  )}-${cardNumber.substring(4, 8)}-${cardNumber.substring(
    8,
    12,
  )}-${cardNumber.substring(12)}`;

  return (
    <View style={PaymentStyles.paymentOption}>
      <Image
        source={images.cardBrand[card.cardBrand.toLowerCase()]}
        style={PaymentStyles.paymentTypeImage}
      />
      <Text style={PaymentStyles.paymentTypeText}>{modifiedCardNumber}</Text>
      <TextInput
        placeholder='CVV'
        placeholderTextColor={colors.silver}
        lineWidth={1}
        blurOnSubmit={false}
        onChangeText={(value) => {
          setCvvForSavedCard(value);
        }}
        ref={(ref) => setRef(ref, index)}
        maxLength={4}
        containerStyle={PaymentStyles.cvvContainer}
        onSubmitEditing={() => {
          Keyboard.dismiss();
        }}
        keyboardType='numeric'
        returnKeyType='done'
        labelHeight={6}
        style={[
          PaymentStyles.savedCardCvvInputBox,
          { borderColor },
        ]}
        textAlignVertical='center'
        secureTextEntry
        // textAlign='center'
        hideLabel={false}
        onFocus={() => {
          if (
            expandedStatus.includes('expanded') &&
            expandedStatus.includes(cardNumber)
          ) {
            return null;
          }
          updateExpandableComponent(cardNumber);
        }}
      />
    </View>
  );
}
