import React, { PureComponent } from 'react';
import {
  TouchableWithoutFeedback,
  StyleSheet,
  Image,
  View,
  SafeAreaView,
} from 'react-native';
import images from '../../theme/Images';

export default class CrossButton extends PureComponent {
  render() {
    const { onPress, leftSide = false } = this.props;
    const leftSideCss = leftSide ? { left: 15, right: 0, top: 70 } : {};
    return (
      <TouchableWithoutFeedback
        style={[style.container, leftSideCss]}
        onPress={onPress}
      >
        <View style={[style.container, leftSideCss]}>
          <Image style={style.image} source={images.roundedCross} />
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const style = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 20,
    right: 5,
    height: 40,
    width: 40,
  },

  image: {
    height: 18,
    width: 18,
    tintColor: '#000000',
  },
});
