import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import _ from 'lodash';
import { SIZE, LAYOUT, THEME } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import Product from '../../containers/product';
import FullWidthDivider from '../../utils/FullWidthDivider';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import CartItemsComboHeader from '../../containers/cart/CartItemsComboHeader';

// Since Offers only have offer_id as unique identifier, offer_id is used. For products sku_id is used
const keyExtractor = (item, index) =>
  `${item?.offer_id?.toString()}_${item?.sku_id?.toString()}_${index}}`;

const CartItem = ({ cartItem, navigation, showToast, hideAddToCart }) => {
  let newCart = cartItem;
  if (Utility.isPresent(cartItem.offer)) {
    newCart = { ...cartItem, product: cartItem.offer };
  }

  if (cartItem.quantity === 0) {
    return null;
  }
  if (Utility.isBlank(cartItem.sku_id)) {
    return <CartItemsComboHeader item={cartItem} />;
  }
  return (
    <>
      <Product
        size={SIZE.large}
        layout={LAYOUT.CART}
        cart
        id={newCart?.productId}
        itemData={newCart}
        navigation={navigation}
        theme={THEME.LIGHT}
        previousScreen={SCREEN_CONSTANTS.CART}
        skuId={newCart?.sku_id}
        quantity={newCart?.quantity}
        cartItem={newCart}
        campaignId={newCart?.campaign_id}
        cartProduct={newCart?.product}
        error={newCart?.error}
        showToast={showToast}
        hideAddToCart={hideAddToCart}
        isDigitalSku={!!cartItem?.offer}
      />
      {!hideAddToCart && <FullWidthDivider />}
    </>
  );
};

const CartItems = ({
  cartItems = [],
  navigation,
  showToast,
  hideAddToCart,
}) => {
  if (Utility.isBlank(cartItems)) {
    return null;
  }

  return (
    <FlatList
      data={cartItems}
      renderItem={({ item }) => (
        <CartItem
          cartItem={item}
          navigation={navigation}
          showToast={showToast}
          hideAddToCart={hideAddToCart}
        />
      )}
      keyExtractor={keyExtractor}
      style={styles.container}
      extraData={cartItems}
    />
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: colors.white },
});

export default CartItems;
