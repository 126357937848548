// Dependencies
import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  LayoutAnimation,
  UIManager,
} from 'react-native';
import { camelCase } from 'lodash';
import { isAndroid, isBlank, isPresent } from '../../../utils/BooleanUtility';
import { getWindowWidth } from '../../../utils/LayoutUtility';
import colors from '../../../theme/Colors';
import images from '../../../theme/Images';
import FastImageView from '../../FastImageView';
import ImageCarousel from '../ImageCarousel';
import withNavigation from '../../../utils/WithNavigation';
import RichContentHTMLView from '../RichContent/RichContentHTMLView';
import EmbeddedRichContent from '../RichContent/EmbeddedRichContent';

class AccordionTab extends Component {
  AccordionTabContentLayoutComponent = {
    images: ImageCarousel,
    singleLineText: RichContentHTMLView,
    multiLineText: RichContentHTMLView,
  };

  constructor(props) {
    super(props);
    if (isAndroid() && UIManager.setLayoutAnimationEnabledExperimental) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
    const { itemData: { default: isExpanded = false } = {} } = this.props;
    this.state = {
      isExpanded: isExpanded || false,
    };
  }

  accordianTabContentKeyExtractor = (item, index) =>
    `accordian_tab_content_${index}`;

  changeListOrientation = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setTimeout(() => {
      this.setState({ isExpanded: !this.state.isExpanded });
    }, 0); // added timeout to reduce lag while collapsing accordian. Setting state inteferes with the layout animation on L-116.
  };

  accordianTabContent = ({ item = {}, index }) => {
    const { display_type = '' } = item;
    const { isExpanded } = this.state;
    const LayoutComponent =
      this.AccordionTabContentLayoutComponent[camelCase(display_type)];
    if (LayoutComponent === undefined) return null;
    return (
      <LayoutComponent itemData={item} index={index} isExpanded={isExpanded} />
    );
  };

  expandButton = () => {
    const { isExpanded } = this.state;
    const icon = isExpanded ? images.minus_collapse : images.plus_expand;
    return (
      <TouchableOpacity
        style={styles.expandButton}
        onPress={this.changeListOrientation}
      >
        <FastImageView
          resizeMethod='contain'
          source={icon}
          style={styles.expandIcon}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const {
      contents = [],
      title = '',
      subtitle = '',
      itemData = {},
    } = this.props;
    if (isBlank(contents) || isBlank(title)) return null;
    const { isExpanded } = this.state;
    return (
      <View style={styles.container}>
        <TouchableOpacity onPress={this.changeListOrientation}>
          <View style={styles.headerContainer}>
            <View style={styles.textContainer}>
              {isPresent(title) && <Text style={styles.title}>{title}</Text>}
              {isPresent(subtitle) && (
                <Text style={styles.subtitle}>{subtitle}</Text>
              )}
            </View>
            <this.expandButton />
          </View>
        </TouchableOpacity>
        <EmbeddedRichContent itemData={itemData} isExpanded={isExpanded} />
        <View style={styles.separator} />
      </View>
    );
  }
}

export default withNavigation(AccordionTab);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: getWindowWidth(),
  },
  headerContainer: {
    flexDirection: 'row',
    width: getWindowWidth(),
    alignItems: 'center',
  },
  textContainer: {
    flex: 9,
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  expandIcon: { height: 22, width: 22, tintColor: colors.black },
  expandButton: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.grey,
  },
  separator: {
    backgroundColor: colors.background,
    marginHorizontal: 12,
    height: 2,
  },
});
