import React from 'react';
import { View } from 'react-native';
import { Svg, Circle, Text as SVGText } from 'react-native-svg';
import colors from '../../../theme/Colors';

const CircularProgress = (props) => {
  const { size = 120, strokeWidth = 8, progress = 100 } = props;
  const radius = (size - strokeWidth) / 2;
  const circum = radius * 2 * Math.PI;
  const svgProgress = 105 - progress;
  const text = `${progress}%`;
  // const progressColor = progress > 70 ? colors.green : colors.dark_pink;

  return (
    <View style={{ margin: 10 }}>
      <Svg width={size} height={size}>
        <Circle
          stroke={colors.white}
          fill='none'
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeLinecap='round'
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (5 / 100)}
          transform={`rotate(-82, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />

        <Circle
          stroke={colors.dark_pink}
          fill='none'
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (-svgProgress / 100)}
          strokeLinecap='round'
          transform={`rotate(-100, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />

        <SVGText
          fontSize={'26'}
          fontFamily={'Roboto'}
          fontWeight={'700'}
          x={size / 2}
          y={size / 2 + 10}
          textAnchor='middle'
          fill={colors.dark_pink}
        >
          {text}
        </SVGText>
      </Svg>
    </View>
  );
};

export default CircularProgress;
