import React from 'react';
import { useSwiper } from 'swiper/react';
import ContentPage from './ContentPage';

const ContentPageWrapper = (props) => {
  const swiper = useSwiper();

  const goToNextPage = () => {
    swiper.slideNext();
  };

  const goToPrevPage = () => {
    swiper.slidePrev();
  };

  const goToNumberedPage = (pageNumber) => {
    swiper.slideTo(pageNumber);
  };
  return (
    <ContentPage
      {...props}
      goToNextPage={goToNextPage}
      goToPrevPage={goToPrevPage}
      goToNumberedPage={goToNumberedPage}
    />
  );
};

export default ContentPageWrapper;
