import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  LayoutAnimation,
  Platform,
  UIManager,
} from 'react-native';
import HTMLView from 'react-native-htmlview';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import CouponCodePill from '../cart/CouponCodePill';
import { AnalyticsManager, EventType } from '../../analytics';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
  },
  offerDescriptionContainer: {
    flexDirection: 'row',
    width: Utility.getScreenWidth() - 24,
    marginHorizontal: 12,
    alignSelf: 'center',
    paddingTop: 12,
    paddingBottom: 6,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  shortDescriptionContainer: {
    flex: 1,
    paddingLeft: 8,
  },
  shortDescriptionText: {
    fontSize: 12,
    color: colors.foxyBlack,
  },
  couponAndTnCContainer: {
    paddingRight: 8,
    marginLeft: 8,
    flexDirection: 'column',
  },
  couponCodePill: {
    paddingHorizontal: 6,
    paddingVertical: 2,
  },
  TnC: {
    fontSize: 10,
    color: colors.foxyRed,
    alignSelf: 'center',
    paddingTop: 6,
  },
  TnCHeading: {
    fontSize: 12,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    marginLeft: 16,
  },
  htmlContainer: {
    borderRadius: 4,
    padding: 16,
  },
  closeText: {
    fontSize: 10,
    color: colors.foxyRed,
    alignSelf: 'center',
    padding: 6,
    position: 'absolute',
    bottom: 0,
    right: 12,
  },
});

const webViewStyle = StyleSheet.create({
  a: {
    color: colors.foxyPink,
    fontSize: 11,
  },
  p: { color: colors.foxyBlack, fontSize: 11 },
  li: { color: colors.foxyBlack, fontSize: 11 },
  ol: { color: colors.foxyBlack, fontSize: 11 },
  ul: { color: colors.foxyBlack, fontSize: 11 },
  div: { color: colors.foxyBlack, fontSize: 11 },
});

class OfferPagePrompts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTnC: false,
    };
    if (
      Platform.OS === 'android' &&
      UIManager.setLayoutAnimationEnabledExperimental
    ) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
  }

  animatedHtmlView = (props) => {
    const { showTnC } = this.state;
    return (
      <>
        <Text style={styles.TnCHeading}>Terms and conditions</Text>
        <HTMLView
          style={styles.htmlContainer}
          value={`<div>${props.html}</div>`}
          stylesheet={webViewStyle}
        />
        {showTnC && (
          <TouchableOpacity
            onPress={this.hideTnC}
            hitSlop={Utility.getHitSlop('checkBoxes')}
          >
            <Text style={styles.closeText}>Close</Text>
          </TouchableOpacity>
        )}
      </>
    );
  };

  showTnC = () => {
    const { offersData } = this.props;
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    AnalyticsManager.logEvent(EventType.offer.VIEW_TNC_CLICK, {
      coupon_code: offersData.coupon_code,
      offer_id: offersData.id,
    });
    this.setState({
      showTnC: true,
    });
  };

  hideTnC = () => {
    const { offersData } = this.props;
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    AnalyticsManager.logEvent(EventType.offer.CLOSE_TNC_CLICK, {
      coupon_code: offersData.coupon_code,
      offer_id: offersData.id,
    });
    this.setState({
      showTnC: false,
    });
  };

  render() {
    const { offersData } = this.props;
    const { showTnC } = this.state;

    if (
      Utility.isBlank(offersData) ||
      Utility.isBlank(offersData.short_description) ||
      Utility.isBlank(offersData.coupon_code)
    ) {
      return null;
    }

    return (
      <View style={styles.container}>
        <View style={styles.offerDescriptionContainer}>
          <View style={styles.shortDescriptionContainer}>
            <Text style={styles.shortDescriptionText}>
              {offersData.short_description}
            </Text>
          </View>
          <View style={styles.couponAndTnCContainer}>
            <CouponCodePill
              couponCode={offersData.coupon_code}
              style={styles.couponCodePill}
            />
            {!showTnC && (
              <TouchableOpacity
                onPress={this.showTnC}
                hitSlop={Utility.getHitSlop('checkBoxes')}
              >
                <Text style={styles.TnC}>View T&C</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
        {showTnC && <this.animatedHtmlView html={offersData.tnc} />}
      </View>
    );
  }
}

export default OfferPagePrompts;
