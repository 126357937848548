// Dependencies
import React from 'react';
import { View, TouchableOpacity, Image, Text } from 'react-native';
import images from '../../theme/Images';
import styles from './styles/styles';

const ReviewProduct = (props) => {
  const { listName, seeProductReviews } = props;
  return (
    <View style={styles.seeReviewCardContainer}>
      <View style={styles.seeReviewContainer}>
        <View style={{ position: 'relative', width: '70%' }}>
          <Text numberOfLines={1} style={styles.seeReviewHeader}>
            {listName}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ReviewProduct;
