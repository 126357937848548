import { takeEvery, call } from 'redux-saga/effects';
import { SEARCH_HASHTAG } from '../actions/UploadPostActions';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { convertJsonFromResponse } from './GeneratorUtil';

const fetchSuggestions = (searchText) => {
  const url = `${URL.SEARCH_HASHTAG}${searchText}`;
  const response = fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response;
};

function* getHashTagSuggestions(action) {
  try {
    const response = yield call(fetchSuggestions, action.searchString);
    if (response.status === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json);
      }
    } else {
      // console.tron.log(`Error Message: ${JSON.stringify(json)}`);
    }
  } catch (error) {
    // console.tron.log(`Error in getting response ${error}`);
  }
}

export default function* watchGetHashTagsSuggestionSaga() {
  yield takeEvery(SEARCH_HASHTAG, getHashTagSuggestions);
}
