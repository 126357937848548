import _ from 'lodash';
import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Carousel from 'react-native-snap-carousel';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';
import FastImageView from '../../FastImageView';

class RoutineDailyUploadProgress extends PureComponent {
  constructor(props) {
    super(props);
    this.gradientArray = [colors.purple, colors.dark_purple];
    const { dailyPhotoes } = props;
    const firsItem = dailyPhotoes.length > 1 ? 1 : 0;
    this.state = {
      currentIndex: firsItem,
    };
  }

  carouselItem = ({ item, index }) => {
    const { currentIndex } = this.state;

    const textStyle = styles.selectedTextStyle;

    return (
      <View style={styles.carouselItemContainer}>
        <FastImageView
          source={Utility.getMinifiedImage(item.photo, 135, 178)}
          style={styles.carouselItem}
        />
        <Text style={textStyle}>Day {item.day}</Text>
      </View>
    );
  };

  headerFooterComponent = () => {
    return <View style={styles.headerFooterStyle} />;
  };

  onSnapToItem = (index) => {
    this.setState({
      currentIndex: index,
    });
  };

  render() {
    const { currentIndex } = this.state;
    const { dailyPhotoes = [], routineLength = '' } = this.props;
    if (Utility.isBlank(dailyPhotoes)) return null;
    const myDailyPhotos = [];
    Object.entries(dailyPhotoes).map(([key, value]) => {
      myDailyPhotos.push({ day: key, photo: value });
    });
    const firsItem = dailyPhotoes.length > 1 ? 1 : 0;
    return (
      <View style={styles.container}>
        <View style={styles.carousel}>
          <Carousel
            ref={(c) => {
              this._carousel = c;
            }}
            data={myDailyPhotos}
            renderItem={this.carouselItem}
            sliderWidth={Utility.getScreenWidth()}
            itemWidth={132}
            currentIndex={currentIndex}
            firstItem={firsItem}
            onSnapToItem={this.onSnapToItem}
            ListHeaderComponent={this.headerFooterComponent}
            ListFooterComponent={this.headerFooterComponent}
            inactiveSlideOpacity={1}
            showsHorizontalScrollIndicator={false}
            inactiveSlideScale={0.85}
            snapToInterval={132}
          />
        </View>

        <LinearGradient
          colors={this.gradientArray}
          style={styles.backgroundContainer}
          start={{ x: 1, y: 0 }}
          end={{ x: 0, y: 0 }}
        >
          <Text style={styles.description}>
            The past {routineLength} days are just the first step towards your
            journey towards achieving your goals. Actual results will be seen
            after dedication, patience, and practice ✨
          </Text>
          <Text style={styles.highlightedDescription}>So keep at it ✨</Text>
        </LinearGradient>
      </View>
    );
  }
}

export default RoutineDailyUploadProgress;

const styles = StyleSheet.create({
  container: {
    marginTop: 88,
    marginBottom: 10,
  },
  backgroundContainer: {
    height: 244,
    width: Utility.getScreenWidth(),
    borderRadius: 8,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingHorizontal: 12,
    paddingVertical: 20,
  },
  description: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Italic',
    marginBottom: 8,
    flexWrap: 'wrap',
  },
  highlightedDescription: {
    color: colors.mustard,
    fontSize: size.h3,
    fontFamily: 'Roboto-Italic',
    fontWeight: 'bold',
  },
  carouselItem: {
    borderRadius: 16,
    height: 178,
    width: 135,
    borderColor: colors.background,
    borderWidth: 4,
  },
  carousel: {
    position: 'absolute',
    top: -60,
    flex: 1,
    zIndex: 1,
  },
  headerFooterStyle: {
    width: 118,
  },
  carouselItemContainer: {
    alignItems: 'center',
  },
  selectedTextStyle: {
    color: colors.white,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    marginTop: 6,
  },
  unselectedTextStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    marginTop: 6,
  },
});
