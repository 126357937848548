import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Utility from '../../utils/Utility';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import FastImageView from '../FastImageView';
import Modal from 'react-native-modal';
import Session from '../../utils/Sessions';

const UserName = ({ name }) => {
  if (Utility.isBlank(name)) return null;
  return <Text style={styles.userNameFont}>{name}</Text>;
};

const AutoLoginModal = ({ showModal, onPressCancel, onPressLogin }) => {
  const {
    country_code = '',
    phone_number = '',
    profile: { name = '', image_url, selfie_image_url } = {},
  } = Session.previousAccountData;
  const ButtonContainer = () => {
    return (
      <TouchableOpacity style={styles.buttonContainer} onPress={onPressLogin}>
        <Text style={styles.buttonText}>Log me in to this account</Text>
      </TouchableOpacity>
    );
  };

  const profilePic = Utility.isPresent(image_url)
    ? image_url
    : selfie_image_url;

  return (
    <Modal
      isVisible={showModal}
      onBackdropPress={onPressCancel}
      style={styles.modalContainer}
      onSwipeEnd={onPressCancel}
      onSwipeComplete={onPressCancel}
      swipeDirection="down"
      swipeThreshold={200}
      propagateSwipe
      onBackButtonPress={onPressCancel}
      backdropTransitionOutTiming={0}
    >
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={styles.modalHeaderText}>Login</Text>
          <TouchableOpacity onPress={onPressCancel}>
            <Image style={styles.popupCloseIcon} source={images.popupClose} />
          </TouchableOpacity>
        </View>

        <Text style={styles.subtitle}>
          We detected that you have previously used this device for{' '}
          {Config.APP_NAME}. Would you like us to restore your data?
        </Text>

        <View style={styles.userAccountRow}>
          <FastImageView
            style={styles.userProfilePic}
            source={profilePic}
            enableProfilePicPlaceholder={Utility.isBlank(profilePic)}
          />
          <View style={styles.userInfo}>
            <UserName name={name} />
            <Text
              style={styles.mobileNumberFont}
            >{`+${country_code}-${phone_number}`}</Text>
          </View>
        </View>

        <ButtonContainer />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth(),
    borderRadius: 6,
    backgroundColor: 'white',
    marginBottom: -20,
    paddingBottom: 24,
    paddingTop: 20,
    paddingHorizontal: 12,
  },

  modalContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  modalHeaderText: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    color: colors.black,
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.black,
    marginTop: 14,
  },
  popupCloseIcon: {
    height: 22,
    width: 22,
  },

  userAccountRow: {
    flexDirection: 'row',
    marginTop: 20,
    alignItems: 'center',
  },

  userInfo: {
    flexDirection: 'column',
    marginLeft: 12,
  },

  userProfilePic: {
    height: 32,
    width: 32,
    borderRadius: 40,
  },

  userNameFont: {
    fontFamily: 'Roboto-Medium',
    color: colors.black,
    fontSize: size.h3,
  },

  mobileNumberFont: {
    fontFamily: 'Roboto-Regular',
    color: colors.subText,
    fontSize: size.h4,
  },

  buttonContainer: {
    height: 40,
    backgroundColor: colors.black,
    marginTop: 20,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.white,
  },
});

export default AutoLoginModal;
