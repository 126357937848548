import React, { Component } from 'react';
import { View, StyleSheet, FlatList, Alert } from 'react-native';
import Offer from './Offer';
import Utility from '../../utils/Utility';
import { LAYOUT, ORIENTATION } from '../../config/Constants';
import ErrorBoundaryComponent from '../../components/shared/ErrorBoundaryComponent';

export default class OfferTimerBannerWithRail extends Component {
  static LayoutStyle = {
    offer: Offer,
  };

  getComponent = (item, index) => {
    const {
      navigation,
      size,
      id,
      listIndex,
      elementItemCounts,
      previousScreen,
      showToast,
      toggleCartVisibility,
      listData,
    } = this.props;

    if (Utility.isBlank(item)) {
      return null;
    }

    const CardContainer = OfferTimerBannerWithRail.LayoutStyle[item.type];
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <CardContainer
          id={item.id}
          listName={listData.name}
          itemData={item}
          data={item}
          layout={LAYOUT.TIMER_BANNER_WITH_RAIL}
          navigation={navigation}
          size={size}
          type={item.type}
          orientation={ORIENTATION.VERTICAL}
          listId={id}
          index={index}
          listIndex={listIndex}
          elementItemCounts={elementItemCounts}
          previousScreen={previousScreen}
          invokedFromOfferBannerWithRail={true}
          showToast={showToast}
          toggleCartVisibility={toggleCartVisibility}
          listData={listData}
          // onPress={onPress}
          offerBannerWithTimer
        ></CardContainer>
      </ErrorBoundaryComponent>
    );
  };

  render() {
    const { item, listData } = this.props;
    this.itemData = item;
    if (Utility.isBlank(this.itemData)) {
      return null;
    }

    // Flat list here and it will render the component based upon object type.
    return (
      <View style={{ marginVertical: 8 }}>
        <FlatList
          data={this.itemData}
          style={styles.flatlistStyle}
          keyExtractor={(item, index) =>
            `${index}_${item.id}_offerBannerWithRail`
          }
          renderItem={({ item, index }) => this.getComponent(item, index)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  flatlistStyle: {
    overflow: 'visible',
  },
});
