import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';

const ShippingPolicy = () => (
  <ScrollView style={styles.container}>

    <Text style={styles.headingBold}>Shipping Policy</Text>

    <Text style={styles.heading}>What is Foxy’s Shipping Policy?</Text>
    <Text style={styles.paragraph}>We strive to deliver products purchased from Foxy in an excellent condition and in the fastest time possible. For all prepaid purchases above Rs.299, we will deliver the order to your doorstep free of cost. A shipping charge of Rs.70 will be applicable to prepaid orders under Rs. 299. Cash on Delivery charge of Rs. 49 will be applicable on COD orders with a value less than Rs. 700.</Text>
    <Text style={styles.paragraph}>*Order value is calculated after applying discounts/VAT/GST or any other applicable charges.</Text>

    <Text style={styles.heading}>How can I track orders placed on Foxy?</Text>
    <Text style={styles.paragraph}>You can track the status of your order on the My Orders section on the app. You will also receive an email with the details of the tracking number and the courier company that is processing your order. </Text>

    <Text style={styles.headingBold}>Cancellation Policy</Text>

    <Text style={styles.heading}>Can I cancel my order?</Text>
    <Text style={styles.paragraph}>You can cancel an order till it has been shipped from our Warehouse. All cancellation requests should be made from the My Orders section on the app. You can choose to cancel the whole or a part of your order. </Text>
    <Text style={styles.paragraph}> After your order has been packed, unfortunately, we cannot cancel the order from our end. However, you may deny accepting the delivery of the order.</Text>

    <Text style={styles.heading}>How will I get refunded for the cancelled orders?</Text>
    <Text style={styles.paragraph}>In case of cancellation before shipment, we process the refund within 2 business days after receiving the cancellation request.</Text>
    <Text style={styles.paragraph}>In case of cancellation once the shipment has already been dispatched or if it is being returned, we process the refund once the products have been received and verified at our warehouse.</Text>
    <Text style={styles.paragraph}>{'        \u25CF For payments done online, the refund will be processed to the original source of payment within 2 business days of us receiving the products back. It may take 4-10 additional business days for the amount to reflect in your account.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF For cash on delivery transactions, we will initiate a bank transfer against the refund amount in the bank details shared by you. This process will be completed within 2  business days of us receiving the products back and your bank details on email. It may take an additional 4-10 business days for the amount to reflect in your account. '}</Text>

    <Text style={styles.headingBold}>Return Policy</Text>

    <Text style={styles.heading}>How can I return an item purchased on Foxy?</Text>
    <Text style={styles.paragraph}>You can raise a return/exchange request of a product within 7 days of its delivery. We also accept partial returns wherein you can raise a return request for one or all products in your order.</Text>
    <Text style={styles.paragraph}>Step 1: Raise a return request from the Order detail section within 7 days of receiving the order.</Text>
    <Text style={styles.paragraph}>Step 2: We will pick up the products within 2-4 business days. We will inspect the products for the original packaging with their seals, labels and barcodes intact after it has been received at our warehouse.</Text>
    <Text style={styles.paragraph}>Step 3:  We will initiate the refund once the items pass our inspection which may take an additional 4-10 business days for the amount to reflect in your account. </Text>

    <Text style={styles.heading}>What are the cases in which I can return an item?</Text>
    <Text style={styles.paragraph}>You can return an item for the following reasons: </Text>
    <Text style={styles.paragraph}>{'        \u25CF Received a damaged product'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Received an expired product'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Received a wrong product/ wrong shade'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Received a wrong size/ quantity'}</Text>
    

    <Text style={styles.heading}>Which are the items that cannot be returned/replaced? </Text>
    <Text style={styles.paragraph}>Returns will not be accepted under the following conditions:</Text>
    <Text style={styles.paragraph}>{'        \u25CF If a request is initiated after 7 days from the date of delivery of an order'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Returned without original packaging including price tags, labels, original packing, freebies and other accessories or if original packaging is damaged.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Serial Number has been defaced.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Product is covered under warranty.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Product has been opened or used.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Return or replacement request for any free or complimentary products.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Items ordered in bulk.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Accepted delivery of a tampered package.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Any delivery disputes raised to customer support after 48 hours from the time of delivery.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Any other reasons which are not covered under the cases in the previous section. For example, "I placed an order for the wrong product", "I don not like the product", "Product is no longer required", "I want to exchange the delivered product for a different one", etc.'}</Text>




    <Text style={styles.heading}>What are the cases in which I can get a replacement for an item?</Text>
    <Text style={styles.paragraph}>You can replace an item in case you have:</Text>
    <Text style={styles.paragraph}>{'        \u25CF Received a damaged product'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Received an expired product'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Received a wrong product/ wrong shade'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF Received a wrong size/ quantity '}</Text>
    <Text style={styles.paragraph}>Replacements are subject to the availability of the products and the refunds shall be initiated in the absence of replacement. The replacement product(s) shall be dispatched once the out - of - order product(s) reaches the warehouse. </Text>
    <Text style={styles.paragraph}>What happens to free or complementary products in case of a return?</Text>
    <Text style={styles.paragraph}>Complimentary items like gifts, sample products provided by Foxy or by the brands, have to be returned despite a part or whole return of an order.</Text>

    <Text style={styles.heading}>How will I get refunded for the returned items?</Text>
    <Text style={styles.paragraph}>In case of a return, we process the refund once the products have been received and verified at our warehouse.</Text>
    <Text style={styles.paragraph}>{'        \u25CF For payments done online, the refund will be processed to the original source of payment was made within 2 business days of us receiving the products back. It may take 4-10 additional business days for the amount to reflect in your account'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF For cash on delivery transactions, we will initiate a bank transfer against the refund amount in the bank details shared by you. This process will be completed within 2 business days of us receiving the products back and your bank details on email. It may take an additional 4-10 business days for the amount to reflect in your account.'}</Text>



  </ScrollView>
);

const styles = StyleSheet.create({
  container: { flex: 1, height: '100%', width: '100%', padding: 12 },
  headingBold: {
    fontFamily: 'Roboto-Bold',
    fontSize: 28,
    color: colors.black,
    marginBottom: 20,
    marginTop: 20,
  },
  heading: {
    fontFamily: 'Roboto',
    fontSize: size.h24,
    color: colors.black,
    marginBottom: 10,
    marginTop: 20,
  },
  bold: {
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    fontSize: size.h2,
  },
  boldH0: {
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    fontSize: size.h0,
    marginBottom: 20,
  },
  separator: {
    marginBottom: 20,
  },
  paragraph: { marginBottom: 20, fontFamily: 'Roboto', size: size.h2 },

});

export default ShippingPolicy;
