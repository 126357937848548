import { StyleSheet, Dimensions } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import size from '../../../theme/Fonts';

const screenWidth = Dimensions.get('window').width;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  crousalContainer: {
    flex: 1.9,
    backgroundColor: '#ffffff',
  },

  crousalItemBackground: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  crousalHeadingText: {
    fontSize: size.h1,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
  },
  crousalDescText: {
    fontSize: size.h3,
    color: colors.silverLit,
    marginTop: 10,
  },

  crousalInternalBox: {
    flex: 0.6,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
  },
  bottomButtonContainer: {
    flex: 0.4,
    justifyContent: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 12,
    backgroundColor: '#F4F6F8',
  },
  bottomContainerText: {
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  buttonStyle: {
    height: 46,
    marginTop: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    width: screenWidth * 0.9,
    backgroundColor: '#5996ff',
  },
  text: {
    color: '#ffffff',
    fontSize: size.h1,
    marginLeft: 7,
    fontFamily: 'Roboto-Medium',
  },
  row: {
    flex: 0.2,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 24,
    paddingRight: 24,
  },
  checkboxContainer: {
    marginRight: 0,
    height: 20,
    top: 1,
    width: 20,
  },
  checkboxLabel: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    top: 1,
  },
  termsText: {
    color: colors.foxyBlack,
    right: 10,
    bottom: 1,
    fontSize: size.h3,
  },
  dotStyle: {
    backgroundColor: 'rgba(0,0,0,.2)',
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  activeDotStyle: {
    borderWidth: 1,
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
});

export default styles;
