import React, { useEffect, useState } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Image,
  Linking,
  Clipboard,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { ids, styles } from './styles';
import { isBlank, isDesktop, isPresent } from '../../utils/BooleanUtility';
import images from '../../theme/Images';
import FastImageView from '../FastImageView';
import animations from '../../theme/Animations';
import {
  getInfluencerDetails,
  verifyOneLink,
} from '../../actions/InfluencerActions';
import { SocialMediaOptions, getProAccountObjectForId } from './OneLinkConfig';
import { AnalyticsManager, EventType } from '../../analytics';

export default function RegistrationCompleted() {
  const { id: currentUserId = '', profile = {} } = useSelector((state) => state.UserAccountInfo);
  const { handle = '' } = profile;
  const [showAnimation, setShowAnimation] = useState(true);
  const [influencerData, setInfluencerData] = useState({});
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [openInstagramLink, setOpenInstagramLink] = useState(true);

  useEffect(() => {
    fetchInfluencerDetails();
    setTimeout(() => {
      setShowAnimation(false);
    }, 4000);
    AnalyticsManager.logEvent(EventType.INFLUENCER.INSTAGRAM_VERIFICATION_PAGE_VIEW, {
      current_user_id: currentUserId,
    });
  }, []);

  const RegistrationCompletionGif = () => {
    if (!showAnimation) return null;
    return (
      <FastImageView
        style={styles.registrationCompletionGif}
        source={animations.oneLinkRegistrationCompleted}
      />
    );
  };

  const fetchInfluencerDetails = () => {
    dispatch(
      getInfluencerDetails(handle, (success, response) => {
        setInfluencerData(response);
      }),
    );
  };

  const InfluencerPreviewComponent = () => {
    const { bio = '', influencer_tags = [] } = influencerData;
    return (
      <View style={{ width: '100%', height: 360 }}>
        <View style={styles.influencerPreviewContainer}>
          <FastImageView
            source={images.share_black}
            style={styles.influencerPreviewShareIcon}
          />
          <View style={styles.influencerPreviewHeaderContainer}>
            <View style={styles.influencerPreviewFollowerReachContainer}>
              <FastImageView
                source={images.follower_reach_star}
                style={styles.influencerPreviewFollowerReachImage}
              />
              <Text style={styles.influencerPreviewFollowerReachText}>
                {influencer_tags[0]?.name}
              </Text>
            </View>
            <Text
              numberOfLines={3}
              ellipseMode='tail'
              style={styles.influencerPreviewBioText}
            >
              {bio}
            </Text>
          </View>
          <View style={styles.influencerPreviewBodyContainer}>
            {SocialMediaOptions.map((item) => {
              return <InfluencerPreviewSocialMediaOptionTile item={item} />;
            })}
          </View>
          <FastImageView
            source={images.one_link_footer}
            style={styles.influencerPreviewFooter}
          />
        </View>
        <RegistrationCompletionGif />
      </View>
    );
  };

  const onVerifyPress = () => {
    const { pro_accounts = [] } = influencerData;
    if (openInstagramLink && isPresent(handle) && Linking.canOpenURL(handle)) {
      Linking.openURL(`https://instagram.com/${handle}`);
      setOpenInstagramLink(false);
      AnalyticsManager.logEvent(EventType.INFLUENCER.GO_TO_INSTAGRAM_CLICK, {
        current_user_id: currentUserId,
      });
      return;
    }
    AnalyticsManager.logEvent(EventType.INFLUENCER.VERIFY_INSTAGRAM_CLICK, {
      current_user_id: currentUserId,
    });
    dispatch(
      verifyOneLink((success) => {
        if (success) {
          AnalyticsManager.logEvent(EventType.INFLUENCER.INSTAGRAM_VERIFICATION_SUCCESS, {
            status: 'success',
            current_user_id: currentUserId,
          });
          navigation.navigate('VerificationModal', {
            instagramLink: handle,
          });
        } else {
          AnalyticsManager.logEvent(EventType.INFLUENCER.INSTAGRAM_VERIFICATION_FAILED, {
            status: 'failed',
            current_user_id: currentUserId,
          });
        }
      }),
    );
  };

  const InfluencerPreviewSocialMediaOptionTile = ({ item }) => {
    const {
      image = '',
      title = '',
      backgroundColor = '',
      social_media_platform_id = '',
    } = item;
    const { pro_accounts = [] } = influencerData;
    const tileTitle =
      getProAccountObjectForId(pro_accounts, social_media_platform_id)
        ?.button_text || title;
    return (
      <View
        style={[styles.influencerPreviewTileContainer, { backgroundColor }]}
      >
        <FastImageView
          source={image}
          style={styles.influencerPreviewTileImage}
        />
        <Text
          numberOfLines={1}
          ellipseMode='tail'
          style={styles.influencerPreviewTileText}
        >
          {tileTitle}
        </Text>
      </View>
    );
  };

  const copyOneLinkHandle = () => {
    Clipboard.setString(`foxy.in/@${handle}`);
  };

  if (isBlank(influencerData)) return null;

  const { name = '' } = influencerData;

  return (
    <View style={styles.container}>
      {isDesktop() && (
        <View style={styles.logoContainer}>
          <Image source={{ uri: images.appHeaderLogo }} style={styles.logo} />
        </View>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        <ScrollView stickyHeaderIndices={[0]} style={styles.scrollView}>
          <View
            dataSet={{ media: ids.formContainer }}
            style={{
              paddingTop: 24,
              paddingHorizontal: 24,
            }}
          >
            <InfluencerPreviewComponent />
            <Text
              dataSet={{ media: ids.addIntroVideoHeading }}
              style={styles.addIntroVideoHeading}
            >
              Your page is ready {name}
            </Text>
            <Text
              style={styles.addIntroVideoSubheading}
              dataSet={{ media: ids.addIntroVideoSubheading }}
            >
              Just verify your instagram
            </Text>
            <Text
              style={[styles.addIntroVideoSubheading, { paddingHorizontal: 0 }]}
              dataSet={{ media: ids.addIntroVideoSubheading }}
            >
              OneLink : foxy.in/@{handle}
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  bottom: -2,
                }}
                onPress={copyOneLinkHandle}
              >
                <FastImageView
                  source={images.copy_blue}
                  style={styles.copyImage}
                />
                <Text style={styles.copyText}>Copy</Text>
              </TouchableOpacity>
            </Text>
            <View
              style={{
                width: '100%',
                alignItems: 'flex-start',
                marginBottom: 48,
              }}
            >
              <Text style={styles.registrationCompletedHeading}>
                How to verify your onelink?
              </Text>
              <Text style={styles.registrationCompletedSubheading}>
                1. Copy your onelink and paste it on your instagram profile's
                links section.
              </Text>
              <Text style={styles.registrationCompletedSubheading}>
                2. Come back to this page and initiate verification.
              </Text>
              <Text style={styles.registrationCompletedHeading}>
                Why should I verify my instagram?
              </Text>
              <Text style={styles.registrationCompletedSubheading}>
                - Verify your instagram to unlock exclusive brand collabs and
                rewards.
              </Text>
            </View>
          </View>
        </ScrollView>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.buttonContainer}
          dataSet={{ media: ids.buttonContainer }}
        >
          <TouchableOpacity style={styles.button} onPress={onVerifyPress}>
            <Text style={styles.buttonText}>
              {openInstagramLink
                ? 'Got to your Instagram profile'
                : 'Verify instagram link'}
            </Text>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </View>
    </View>
  );
}
