import React, { useEffect, useState } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Image,
} from 'react-native';
import { ids, styles } from './styles';
import images from '../../theme/Images';
import { isDesktop } from '../../utils/BooleanUtility';
import { useNavigation } from '@react-navigation/native';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import { ScaleAnimate } from '../shared';
import { useDispatch } from 'react-redux';
import { getProAccountLinks } from '../../actions/InfluencerActions';
import { FlatList } from 'react-native';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import AddLinkCard from './AddLinkCard';

export default function AddLinks() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [proAccountLinks, setProAccountLinks] = useState({});

  useEffect(() => {
    fetchProAccountLinks();
  }, []);

  const fetchProAccountLinks = () => {
    dispatch(
      getProAccountLinks((success, response) => {
        if (success) {
          setProAccountLinks(response);
        }
      }),
    );
  };

  const goBack = () => {
    navigation.goBack();
  };

  const openAddLinkModal = (link_type) => {
    navigation.navigate('AddLinksModal', {
      link_type,
      refresh: fetchProAccountLinks,
    });
  };

  const handleRouteFromLink = (route, slug, path, extra) => {
    navigation.navigate(route, { slug, extra });
  };

  const handleDeeplink = (url) => {
    DynamicLinkManager.handleDynamicLink(url, handleRouteFromLink);
  };

  const onContinuePress = () => {
    navigation.navigate('AddIntroVideo');
  };

  const {
    content_links_list: { objects: contentLink } = {},
    product_links_list: { objects: productLinks } = {},
  } = proAccountLinks;

  return (
    <View style={styles.container}>
      {isDesktop() && (
        <View style={styles.logoContainer}>
          <Image source={{ uri: images.appHeaderLogo }} style={styles.logo} />
        </View>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        <ScrollView stickyHeaderIndices={[0]} style={styles.scrollView}>
          <View style={styles.header}>
            <TouchableOpacity onPress={goBack}>
              <Image source={images.ic_back_product} style={styles.back} />
            </TouchableOpacity>
            <View dataSet={{ media: ids.headingTextContainer }}>
              <Text
                style={styles.headerTitle}
                dataSet={{ media: ids.headerTitle }}
              >
                Add your links
              </Text>
            </View>
          </View>
          <View style={{ width: '100%', backgroundColor: colors.background }}>
            <View
              dataSet={{ media: ids.formContainer }}
              style={{
                paddingTop: 24,
                paddingHorizontal: 24,
              }}
            >
              <Text style={styles.contentHeading}>
                Add Links to your favourite content
              </Text>
              <Text style={styles.contentSubheading}>
                It can be your latest post, videos or content by your favourite
                artists.
              </Text>
              <FlatList
                data={contentLink}
                style={{ width: '100%' }}
                renderItem={({ item }) => (
                  <AddLinkCard
                    image={item.image}
                    title={item.label}
                    url_domain={item.url_domain}
                    onPress={() => handleDeeplink(item.url)}
                  />
                )}
                showsHorizontalScrollIndicator={false}
                horizontal
                ListFooterComponent={() => (
                  <AddLinkCard
                    title={'Your Content Link'}
                    onPress={() => openAddLinkModal('content')}
                  />
                )}
              />

              <Text style={styles.contentHeading}>
                Add Links to your favourite products
              </Text>
              <Text style={styles.contentSubheading}>
                so that your audience can check out and buy what you love.
              </Text>
              <FlatList
                data={productLinks}
                style={{ width: '100%' }}
                renderItem={({ item }) => (
                  <AddLinkCard
                    image={item.image}
                    title={item.label}
                    url_domain={item.url_domain}
                    onPress={() => handleDeeplink(item.url)}
                  />
                )}
                horizontal
                ListFooterComponent={() => (
                  <AddLinkCard
                    title={'Your Product Link'}
                    onPress={() => openAddLinkModal('product')}
                  />
                )}
              />
            </View>
          </View>
        </ScrollView>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.buttonContainer}
          dataSet={{ media: ids.buttonContainer }}
        >
          <TouchableOpacity onPress={onContinuePress} style={styles.button}>
            <Text style={styles.buttonText}>Continue</Text>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </View>
    </View>
  );
}
