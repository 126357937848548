import { TouchableOpacity, Image, StyleSheet } from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import Config from '../../libraries/ReactNativeConfig';

export default function WebHeaderLogo() {
  const navigation = useNavigation();
  const handlePress = () => {
    navigation.replace('DetailStackNavigator');
  };

  return (
    <TouchableOpacity onPress={handlePress} style={styles.container}>
      <Image
        source={{ uri: Config.WEB_HEADER_LOGO }}
        style={styles.logo}
      />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 48,
    bottom: 15,
    right: 40,
    overflow: 'hidden',
  },
  logo: {
    width: 30,
    height: 30,
  },
});
