import { View, Text } from 'react-native';
import StyleSheet from 'react-native-media-query';
import React from 'react';
import { getScreenWidth } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { isDesktop } from '../../utils/BooleanUtility';

export default function WebListTitle({ title, background, hideForMobile = true }) {
  if (hideForMobile && !isDesktop()) return null;
  return (
    <View style={[styles.container, !!background && { backgroundColor: background }]}>
      <Text style={styles.title} dataSet={{ media: ids.title }}>{title}</Text>
    </View>
  );
}

const { ids, styles } = StyleSheet.create({
  container: {
    width: Utility.screenWidth,
    backgroundColor: colors.ultraLightGray,
    paddingTop: 25,
    paddingBottom: 10,
  },
  title: {
    width: getScreenWidth(),
    alignSelf: 'center',
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 21,
    paddingLeft: 10,
    '@media (min-width: 768px)': {
      paddingLeft: 0,
    },
  },
});
