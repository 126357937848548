import initialState from './initialState';
import createReducer from '../helpers/CreateReducer';

const actionHandlers = {
  GET_INTERESTS_LIST: (state, action) => action.interestsList,
};

const InterestsList = createReducer(initialState.interests, actionHandlers);

export default InterestsList;
