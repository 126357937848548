import { analytics } from '../libraries/Firebase';
import { setUserId, setUserProperties, logEvent } from 'firebase/analytics';
import AnalyticsEvent from './AnalyticsEvent';
import EventType from './AnalyticsEventType';

class FirebaseAnalytics {
  static trackEvent = (event) => {
    if (event instanceof AnalyticsEvent) {
      const { meta, eventType } = event;
      let params = meta;
      if (Object.keys(meta).length > 24) {
        params = Object.fromEntries(Object.entries(meta).slice(0, 24));
        params = { ...params, params_gt_24: true };
      }
      logEvent(analytics, eventType, params);
    }
  };

  static currentScreen = (screenName) => {
    logEvent(analytics, EventType.firebaseWeb.SCREEN_VIEW, { screen_name: screenName });
  };

  static setUserProperty = (key, value) => {
    setUserProperties(analytics, { key: value });
  };

  static setUserId = (id) => {
    setUserId(analytics, id);
  };
}

export default FirebaseAnalytics;
