import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

const BrandCollabStyles = StyleSheet.create({
  draftContainer: {
    backgroundColor: colors.white,
    height: 148,
    width: '100%',
  },
  railView: {
    marginLeft: Utility.padding - 4,
  },
  draftCard: {
    height: 84,
    width: 150,
    margin: 4,
    borderRadius: 4,
    backgroundColor: 'white',
  },
  draftImage: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    backgroundColor: colors.silverLit,
    resizeMode: 'cover',
  },
  draftFlatlistStyle: {
    overflow: 'visible',
  },
  draftHeader: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 16,
    marginTop: 12,
    marginBottom: 8,
  },
  userPostCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    overflow: 'hidden',
    height: (9 / 16) * Utility.getDynamicWidthForGrid(2, 16) + 44, // 44 for description
    width: Utility.getDynamicWidthForGrid(2, 16),
  },
  mediaImageStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    height: (9 / 16) * Utility.getDynamicWidthForGrid(2, 16),
    width: Utility.getDynamicWidthForGrid(2, 16),
  },
  contentTypeIndicatorStyle: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 20,
    height: 10,
  },
  mediaDescriptionContainer: {
    flexDirection: 'row',
  },
  cardTextStyle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    marginLeft: 8,
    marginRight: 8,
    marginTop: 4,
  },
});

export default BrandCollabStyles;
