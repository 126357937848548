import React, { Component } from 'react';
import { StyleSheet, Text } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _, { debounce } from 'lodash';
import { removeOffer, renderOfferShimmers } from '../../actions/ActionTypes';
import Offers from '../../components/cart/CartOffers';
import Config from '../../libraries/ReactNativeConfig';
import CartAndPaymentsAnalytics from '../../analytics/CartAndPaymentsAnalytics';
import { AnalyticsManager, EventType } from '../../analytics';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import applyOffer from '../../hoc/applyOffer';
import Wallet from '../offer/Wallet';
import colors from '../../theme/Colors';
import { pushInitiateCheckoutToGtm } from '../../analytics/GTMAnalytics';

export class CartOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notClubbableCouponCode: '',
      notClubbableOfferId: '',
      notClubbableWith: {},
      clubbableModalVisible: false,
      errorMessage: '',
      unfulfilledCoupon: {},
      successfullyAppliedCoupon: {},
    };
    this.debounceNavigateToOffers = debounce(this.navigateToOffers, 1000, {
      leading: true,
      trailing: false,
    });
  }

  navigateToOffers = () => {
    const { navigation } = this.props;
    CartAndPaymentsAnalytics.fireCartOfferClick();
    pushInitiateCheckoutToGtm(3, 'Offer Section View');
    navigation.navigate('CartOffers');
  };

  walletHeader = () => {
    return (
      <Text style={styles.wallerHeaderContainer}>Use your wallet cash</Text>
    );
  };

  removeCartOffer = (coupon) => {
    const { removeOffer, showToast } = this.props;

    removeOffer(coupon, (response) => {
      if (response) {
        showToast(`${coupon} removed successfully`);

        this.getOfferPrompt();
        CartAndPaymentsAnalytics.fireOfferRemoveEvent({
          coupon_code: coupon,
        });
      }
    });
  };

  applyOffer = (code) => {
    const { applyCartCoupon } = this.props;
    applyCartCoupon(code);
  };

  render() {
    const {
      navigation,
      cartPrompts,
      authToken,
      address,
      date_of_birth,
      todayDeals = {},

      isLoading,
    } = this.props;

    return (
      <>
        <Offers
          promptData={cartPrompts}
          navigateToOffers={this.debounceNavigateToOffers}
          removeCartOffer={this.removeCartOffer}
          getOfferPrompt={this.getOfferPrompt}
          navigation={navigation}
          isOfferShimmerVisible={isLoading}
          applyOffer={this.applyOffer}
          authToken={authToken}
          address={address}
          date_of_birth={date_of_birth}
          todayDeals={todayDeals}
        />
        <Wallet
          walletHeader={this.walletHeader}
          source='cart'
          buttonAction='apply_offer'
          previousScreen={'cart'}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  wallerHeaderContainer: {
    marginLeft: 12,
    marginBottom: 4,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    marginHorizontal: 12,
  },
});

const mapStateToProps = (state) => ({
  isOfferShimmerVisible: state.bag.isOfferShimmerVisible,
  date_of_birth: state.UserAccountInfo.profile.date_of_birth,
  authToken: state.UserAccountInfo.authToken,
  address: state.UserAccountInfo.addresses,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      removeOffer,

      renderOfferShimmers,
    },
    dispatch,
  ),
});

export default applyOffer(
  withNavigation(connect(mapStateToProps, mapDispatchToProps)(CartOffers)),
);
