import React, { Component } from 'react';
import { View, Text, Image, TouchableWithoutFeedback } from 'react-native';
import styles from './MarkerStyle';
import images from '../../../theme/Images';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

class Marker extends Component {
  constructor() {
    super();
  }

  componentDidMount() {}

  UNSAFE_componentWillMount() {}

  setText = (title, otherItemsCount) => {
    const items = otherItemsCount - 1;
    const text = items > 0 ? `${title} & ${items} +` : `${title}`;
    return text;
  };

  _text = (props) => {
    const { textWidth = 100 } = this.props;
    return (
      <Text
        numberOfLines={1}
        ellipsizeMode='tail'
        style={[styles.text, { width: textWidth }]}
      >
        {props.text}
      </Text>
    );
  };

  _titleOnly = (props) => (
    <View style={[styles.row, { paddingLeft: 0 }]}>
      <this._text text={this.setText(props.title, props.total_values)} />
    </View>
  );

  _titleWithIcon = (props) => (
    <View style={styles.row}>
      <Image
        style={styles.icon}
        source={{ uri: Utility.getMinifiedImage(props.icon) }}
      />
      <this._text text={this.setText(props.title, props.total_values)} />
    </View>
  );

  _titleWithColor = (props) => (
    <View style={styles.row}>
      <View
        style={[styles.colorCircle, { backgroundColor: props.colorCode }]}
      />
      <this._text text={this.setText(props.title, props.total_values)} />
    </View>
  );

  _selectAttribute = () => (
    <View style={styles.row}>
      <Text style={styles.selectText}>Select</Text>
      <View style={styles.cheveronContainer}>
        <Image
          source={images.chevronRight}
          style={{ tintColor: colors.white, height: 8, width: 8 }}
        />
      </View>
    </View>
  );

  _attributeValue = (props) => <View />;

  iconImage = () => {
    const { values = [] } = this.props;
    let image = '';
    if (values.length > 0 && values[0] !== undefined && values[0] !== null) {
      image =
        Utility.isPresent(values[0]) && values[0].image !== null
          ? values[0].image
          : values[0].icon;
    }
    return image;
  };

  render() {
    const { title, onPressMarker, values, shadowElevation, shadowOpacity } =
      this.props;
    const valuesPresent =
      Utility.isPresent(values) && Utility.isPresent(values[0]);
    const iconPresent =
      !!(valuesPresent && Utility.isPresent(values[0].icon)) ||
      !!(valuesPresent && Utility.isPresent(values[0].image));
    const colorCodePresent = !!(
      valuesPresent && Utility.isPresent(values[0].color_code)
    );
    const isTitleOnly = valuesPresent && !iconPresent && !colorCodePresent;
    const iconImage = this.iconImage();

    return (
      <TouchableWithoutFeedback onPress={onPressMarker}>
        <View
          style={[
            styles.container,
            { elevation: shadowElevation, shadowOpacity },
          ]}
        >
          <Text style={styles.titleText}>{title}</Text>
          {!valuesPresent && <this._selectAttribute />}
          {iconPresent && (
            <this._titleWithIcon
              title={values[0].name}
              icon={iconImage}
              total_values={values.length}
            />
          )}
          {colorCodePresent && (
            <this._titleWithColor
              title={values[0].name}
              colorCode={values[0].color_code}
              total_values={values.length}
            />
          )}
          {isTitleOnly && (
            <this._titleOnly
              title={values[0].name}
              total_values={values.length}
            />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

export default Marker;
