import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import {
  claimMissedDelightOffer,
  getOrderOffers,
} from '../../../actions/OrderActions';
import withNavigation from '../../../utils/WithNavigation';

import CouponCodePill from '../../cart/CouponCodePill';
import Clipboard from '@react-native-clipboard/clipboard';
import Toast from 'react-native-easy-toast';

class CouponCodeCopyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offersData: {},
      offersLoading: false,
      showSuccessModal: false,
    };
  }

  onOfferPress = () => {
    const { itemData: { couponCode = '' } = {}, showToast = () => {} } =
      this.props;
    Clipboard.setString(couponCode);
    console.tron.log('sddsdsds', this.props);
    showToast(`${couponCode} Copied`);
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  render() {
    const { showSuccessModal } = this.state;
    const {
      itemData: {
        shortDescription = '',
        couponCode = '',
        offerId = '',
        name = '',
      } = {},
    } = this.props;

    return (
      <>
        <TouchableOpacity style={styles.container} onPress={this.onOfferPress}>
          <Text style={styles.header}>{name}</Text>
          <Text style={styles.subheading}>{shortDescription}</Text>
          <CouponCodePill
            couponCode={couponCode}
            style={styles.couponCodeContainer}
          />
          <View style={styles.couponCodeRightCutClipper} />
          <View style={styles.couponCodeLeftCutClipper} />
        </TouchableOpacity>
      </>
    );
  }
}
const styles = StyleSheet.create({
  toast: {
    bottom: 0,
  },
  couponCodeRightCutClipper: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.background,
    bottom: 45,
    right: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
    borderLeftColor: '#ECF3FE',
    borderTopColor: '#ECF3FE',
    borderBottomColor: '#ECF3FE',
    borderRightColor: colors.background,
  },
  couponCodeLeftCutClipper: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.background,
    bottom: 45,
    left: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
    borderRightColor: '#ECF3FE',
    borderTopColor: '#ECF3FE',
    borderBottomColor: '#ECF3FE',
    borderLeftColor: colors.background,
  },
  container: {
    width: Utility.getScreenWidth() - 24,
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: colors.white,
    justifyContent: 'flex-start',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
    borderWidth: 4,
    borderColor: colors.prompt.ultraLightBlue,
  },
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    color: colors.foxyBlack,
    textAlign: 'left',
  },
  subheading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#173143CC',
    textAlign: 'left',
  },
  roundedButton: { width: 96, alignSelf: 'flex-end', height: 24 },
  couponCodeContainer: {
    marginRight: 4,
    // flex: 1,
    paddingHorizontal: 4,
    padding: 2,
    borderWidth: 1,
    backgroundColor: '#F6F9FF',
    borderColor: colors.prompt.borderBlue,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    width: 90,
    alignSelf: 'flex-end',
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      claimMissedDelightOffer,
      getOrderOffers,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(CouponCodeCopyCard),
);
