import { capitalize, upperFirst } from 'lodash';
import { DefaultSizeProductVerticalRail } from '../../Product';
import { DefaultSizeVariantVerticalRail } from '../../variants';
import {
  SmallProductVerticalRail,
  LargeProductVerticalRail,
} from '../../Product/OtherProducts';
import {
  SmallVariantVerticalRail,
  LargeVariantVerticalRail,
} from '../../variants/OtherSkus';

const Components = {
  DefaultSizeProductVerticalRail,
  DefaultSizeVariantVerticalRail,
  LargeProductVerticalRail,
  SmallProductVerticalRail,
  LargeVariantVerticalRail,
  SmallVariantVerticalRail,
};

export const getVerticalRailComponent = (cardType, content, size) => {
  let CardContainer = Components[content];
  if (content === 'product' || content === 'sku') {
    CardContainer =
      Components[`${upperFirst(size)}${capitalize(cardType)}VerticalRail`];
  }
  return CardContainer;
};

export const getVerticalRailHeight = (item) => {
  const { objects = [], content, size } = item;
  const ContainerComponent = getVerticalRailComponent(
    objects[0].type,
    content,
    size,
  );
  try {
    return ContainerComponent.getComponentHeight(item);
  } catch (error) {
    return 0;
  }
};
