import React from 'react';
import { View, Text } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import _ from 'lodash';
import CouponCodePill from '../cart/CouponCodePill';
import { PaymentStyles } from './styles';

const CodExpandedMessage = (props) => {
  const {
    isSpecialOfferApplied = false,
    codErrorMessage = '',
    disabled = false,
    style = {},
    extraChargesMessage = '',
  } = props;

  const codDisableErrorMessage = Utility.isPresent(codErrorMessage)
    ? codErrorMessage
    : 'COD not available';
  const styles = PaymentStyles;
  if (disabled) {
    return (
      <Text
        style={[styles.cashActionButton, styles.codNotAvailableTextStyle]}>
        {codDisableErrorMessage}
      </Text>
    );
  }

  if (isSpecialOfferApplied) {
    return null;
  }

  if (Utility.isBlank(extraChargesMessage)) {
    return null;
  }

  return (
    <View
      style={{
        backgroundColor: colors.white,
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      <Text
        style={[
          styles.cashActionButton,
          {
            color: colors.foxyBlack,
            marginBottom: 8,
            marginLeft: 18,
            marginRight: 12,
            fontSize: 12,
            alignSelf: 'flex-start',
            textAlign: 'left',
          },
          style,
        ]}
      >
        {extraChargesMessage}
      </Text>
    </View>
  );
};

export default CodExpandedMessage;
