import React, { Component } from 'react';
import { View, TouchableOpacity, Image, Text, ScrollView } from 'react-native';
import { PaymentStyles } from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { POPULAR_BANKS } from '../../config/Constants';
import _ from 'lodash';

class NetBankingExpanded extends Component {
  constructor(props) {
    super(props);
    this.debounceCreateOrderAndPay = _.debounce(this.createOrderAndPay, 1500, {
      leading: true,
      trailing: false,
    });
    this.memoizedCreateOrderCache = {};
  }

  netbankingConstants = {
    headerText: 'Netbanking',
    popular: 'Popular Banks',
    allBanks: 'All Banks',
  };

  goBack = () => {
    const { hidePaymentDetailsScreen } = this.props;
    hidePaymentDetailsScreen();
  };

  createOrderAndPay = (bank) => {
    const { createOrder } = this.props;

    const param = {
      bankcode: bank.bankCode,
      name: bank.title,
    };

    const netBankingParams = {
      bankCode: bank.bankCode,
      bankTitle: bank.title,
    };

    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: 'netbanking',
      isSavedPaymentMethod: true,
      paymentMethodPayload: param,
      payuPayload: netBankingParams,
      extraData: {
        method: 'netbanking',
      },
    };

    createOrder(paymentPayload);
  };

  popularBank = (props) => {
    const styles = PaymentStyles;
    const { bank, netbankingOptions } = props;
    let disabled = false;
    let containerStyle = styles.popularBank;
    let imageStyle = styles.popularBankImageWithoutTint;
    if (
      Utility.isPresent(netbankingOptions) &&
      Utility.isPresent(netbankingOptions[bank.bankCode])
    ) {
      //remote config
      disabled = !!netbankingOptions[bank.bankCode].disabled;
      if (disabled) {
        containerStyle = styles.popularBankWithOpacity;
        imageStyle = styles.popularBankImageWithTint;
      }
    }
    return (
      <TouchableOpacity
        onPress={this.memoizedCreateOrder()(bank)}
        style={containerStyle}
        disabled={disabled}
      >
        <Image
          source={images.bankImage[bank.title.toLowerCase()]}
          style={imageStyle}
        />
        <Text style={styles.popularBankTitle}>{bank.title}</Text>
      </TouchableOpacity>
    );
  };

  memoizedCreateOrder = () => {
    return (bank) => {
      if (!this.memoizedCreateOrderCache[bank.bankCode]) {
        this.memoizedCreateOrderCache[bank.bankCode] = () =>
          this.debounceCreateOrderAndPay(bank);
      }
      return this.memoizedCreateOrderCache[bank.bankCode];
    };
  };

  bank = (props) => {
    const styles = PaymentStyles;
    const { bank, netbankingOptions } = props;
    let disabled = false;
    let containerStyle = styles.paymentOptionContainer;
    if (
      Utility.isPresent(netbankingOptions) &&
      Utility.isPresent(netbankingOptions[bank.bankCode])
    ) {
      //remote config
      disabled = !!netbankingOptions[bank.bankCode].disabled;
      if (disabled) {
        containerStyle = styles.paymentOptionContainerWithOpacity;
      }
    }
    return (
      <>
        <TouchableOpacity
          onPress={this.memoizedCreateOrder()(bank)}
          style={containerStyle}
          disabled={disabled}
        >
          <Text style={styles.allBankText}>{bank.title}</Text>
          <Image source={images.chevronRight} style={styles.rightArrow} />
        </TouchableOpacity>
        <View style={styles.fullWidthDivider} />
      </>
    );
  };

  onMoreBankPress = () => {
    const { availableNetBanking, navigation } = this.props;
    navigation.navigate('MoreBanks', {
      availableNetBanking,
      createOrder: this.debounceCreateOrderAndPay,
    });
  };

  render() {
    const styles = PaymentStyles;
    const {
      availableNetBanking,
      payWithNetBanking,
      enabledPaymentOptions,
      hideMoreBanksNb,
    } = this.props;
    const popularBanks = POPULAR_BANKS;

    return (
      <View style={styles.netBankingContainer}>
        <View style={styles.netBankingExpanderContainer}>
          <ScrollView style={styles.scrollViewWithBottomMargin}>
            <View>
              <View style={styles.popularBanksContainer}>
                {popularBanks.map((bank) => (
                  <this.popularBank
                    key={`${bank.bankCode}_${bank.title}`}
                    bank={bank}
                    payWithNetBanking={payWithNetBanking}
                    netbankingOptions={
                      Utility.isPresent(enabledPaymentOptions)
                        ? enabledPaymentOptions.netbanking
                        : {}
                    }
                  />
                ))}
              </View>

              {!hideMoreBanksNb && (
                <TouchableOpacity onPress={this.onMoreBankPress}>
                  <View style={styles.moreBanksTextContainer}>
                    <Text style={styles.moreBanksText}>More Banks</Text>
                    <Image
                      source={images.chevronDown}
                      style={styles.rightArrow}
                    />
                  </View>
                </TouchableOpacity>
              )}
              <View>
                <Text style={styles.redirectingText}>
                  We will redirect you to your bank's website to authorize the
                  payment.
                </Text>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

export default withNavigation(NetBankingExpanded);
