import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  Image,
  ActivityIndicator,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../../theme/Colors';

class ActionButton extends PureComponent {
  render() {
    const {
      title = '',
      onActionPress,
      layoutStyle,
      disabled = false,
      showLoader,
      show_button_icon,
      icon_type,
      actionButtonColor,
      textColor,
      isLoaderVisible = false,
    } = this.props;
    const btnColor = disabled ? colors.silver : actionButtonColor;
    const text_color = textColor == null ? colors.white : textColor
    return (
      <TouchableOpacity
        disabled={disabled || isLoaderVisible}
        onPress={onActionPress}
        style={[styles.button, { backgroundColor: btnColor }, layoutStyle]}
      >
        <View style={styles.container}>
          {show_button_icon && !showLoader && <Image source={icon_type} />}
          {show_button_icon && showLoader && (
            <ActivityIndicator
              color='#ffffff'
              size='small'
              style={{ marginLeft: 5 }}
            />
          )}

          {!isLoaderVisible ? (
            <Text style={[styles.text, { color: text_color }]}>{title}</Text>
          ) : (
            <ActivityIndicator color='#ffffff' size='small' />
          )}
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    position: 'absolute',
    height: 46,
    backgroundColor: colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    left: 24,
    right: 24,
    borderRadius: 4,
    zIndex: 1,
  },
  text: {
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontSize: 16,
    marginLeft: 5,
  },
});

ActionButton.propTypes = {
  onActionPress: PropTypes.func,
};
export default ActionButton;
