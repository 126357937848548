import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';

const TallCardStyles = StyleSheet.create({
  tallCardStyle: {
    margin: 6,
    width: 260,
    borderRadius: 10,
    overflow: 'hidden',
  },
  tallCardImage: {
    height: 260,
    width: 260,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  tallCardInfo: {
    height: 86,
    width: 260,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: colors.white,
    borderWidth: 0.5,
    borderColor: colors.grey,
    paddingHorizontal: 18,
    justifyContent: 'space-evenly',
  },
  tallCardCtaText: {
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },
  tallCardTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
  },
  tallCardCta: {
    width: '100%',
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
});

export default TallCardStyles;
