import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';

class LeaderBoardCard extends PureComponent {
  render() {
    const {
      data: {
        name = 'Guest',
        rank = 5,
        totalPoints = '',
        imageUrl = '',
        score = '',
      } = {},
    } = this.props;
    const image = Utility.isBlank(imageUrl)
      ? images.for_you_placeholder.uri
      : imageUrl;
    const userName = Utility.isBlank(name) ? 'Guest' : name;
    return (
      <View style={styles.container}>
        <View style={styles.leftContainer}>
          <Text style={styles.rank}>{rank}</Text>
          <FastImageView style={styles.profileImage} source={image} />
          <Text style={styles.name} numberOfLines={2}>
            {userName}
          </Text>
        </View>
        <View style={styles.rightContainer}>
          <FastImageView style={styles.badgeIcon} source={images.coins} />
          <Text style={styles.score}>{score}</Text>
        </View>
      </View>
    );
  }
}

export default LeaderBoardCard;

const styles = StyleSheet.create({
  container: {
    height: 50,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // backgroundColor: colors.white,
  },
  rank: {
    color: colors.purple,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    marginLeft: 14,
  },
  profileImage: {
    height: 32,
    width: 32,
    borderRadius: 16,
    marginLeft: 14,
  },
  name: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto',
    marginLeft: 14,
    flexShrink: 1,
    position: 'relative',
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    flexShrink: 1,
    right: 12,
    left: 0,
  },
  rightContainer: {
    right: 12,
    width: 88,
    marginLeft: 8,
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  badgeIcon: {
    height: 36,
    width: 36,
  },
  score: {
    color: colors.subText,
    fontSize: size.h4,
    height: 16,
    flexBasis: 48,
    fontFamily: 'Roboto-Medium',
    marginLeft: 8,
    textAlign: 'center',
  },
});
