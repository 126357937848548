import {
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
} from 'react-native';
import React, { useState } from 'react';
import AccountModal from './AccountModal';
import { useAuthToken } from '../../lib/hooksUtil';
import { isPresent } from '../../utils/BooleanUtility';
import images from '../../theme/Images';

export default function Account({ navigation }) {
  const authToken = useAuthToken();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const accountTitle = isPresent(authToken) ? 'Account' : 'Login';
  return (
    <>
      <TouchableOpacity style={styles.container} onPress={toggleModal}>
        <Image
          source={{ uri: images.desktop.accountIcon }}
          style={styles.image}
        />
        <Text style={styles.text}>{accountTitle}</Text>
      </TouchableOpacity>
      <AccountModal
        showModal={showModal}
        toggleModal={toggleModal}
        navigation={navigation}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
  },
  image: {
    marginTop: 3,
    width: 22,
    height: 22,
    lineHeight: 1.5,
    alignSelf: 'center',
  },
  text: {
    fontSize: 12,
    color: '#173143',
    lineHeight: 1.5,
    alignSelf: 'center',
    marginTop: 12,
  },
});
