import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ActivityIndicator,
  View,
  FlatList,
  Text,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from 'react-native';
import { style } from './RecommendationStyle';
import colors from '../../theme/Colors';
import { StaticNavigationHeader } from '../shared';
import Marker from '../onboarding/ImageMarkers/Marker';
import {
  getArtistStoryRecommendation,
  followRecommendations,
  getListDetails,
  unfollowStories,
} from '../../actions/ActionTypes';

import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import Utility from '../../utils/Utility';
import { List } from '../../containers/List';
import { AnalyticsManager } from '../../analytics';
class FollowRecommendations extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };

    const { route } = props;
    this.hideStoriesIntro = route.params?.hideStoriesIntro;
    this.itemData = route.params?.itemData;
    this.item = route.params?.item;
    this.goBack = route.params?.goBack;
    this.sortStories = route.params?.sortStories;
  }

  componentDidMount = () => {
    const { getArtistStoryRecommendation } = this.props;

    getArtistStoryRecommendation(false, (success, response) => {
      if (success) {
        this.setState({
          data: response,
        });
      } else {
        AnalyticsManager.logEvent('auto_follow_recommendation_fail');
      }
    });
  };

  _facialMarkerTags = (props) => {
    const { marker } = props;
    const { navigation } = this.props;

    return (
      <View style={PersonalisedPageStyle.attributesMarkerStyle}>
        <Marker
          shadowOpacity={Utility.isAndroid() ? 1.0 : 0.3}
          shadowElevation={1}
          title={marker.name}
          values={marker.values}
          onPressMarker={() => {
            navigation.navigate('ImageReview');
          }}
        />
      </View>
    );
  };

  onNextPress = () => {
    const { navigation, route, followedStories } = this.props;
    const fromStories = route.params?.fromStories;

    AnalyticsManager.logEvent('recommendations_screen_done');

    if (fromStories) {
      navigation.goBack();
    } else {
      this.hideStoriesIntro();
      navigation.replace('StoryDetails', {
        item: this.item,
        itemData: this.itemData.concat(followedStories),
        goBack: this.goBack,
        sortStories: this.sortStories,
      });
    }
  };

  backPress = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent('recommendations_screen_back');
    navigation.goBack();
  };

  render() {
    const { data } = this.state;
    const {
      facialAnalysis: { facialProperties },
      profile,
      navigation,
    } = this.props;

    return (
      <View style={style.wrapper}>
        <StaticNavigationHeader
          title={'Following'}
          onBackPress={this.backPress}
        />

        <ScrollView
          style={{
            backgroundColor: colors.background,
          }}
        >
          {Utility.isPresent(profile.selfie_image_url) && (
            <View style={PersonalisedPageStyle.attributesCardContainer}>
              <Text style={PersonalisedPageStyle.myAttributesHeading}>
                From your selfie
              </Text>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('ImageReview');
                }}
              >
                <Text style={PersonalisedPageStyle.reviewAttributeText}>
                  Update
                </Text>
              </TouchableOpacity>
            </View>
          )}

          <FlatList
            style={{ marginLeft: 15 }}
            data={facialProperties}
            horizontal
            showsHorizontalScrollIndicator={false}
            renderItem={({ item, index }) => {
              return (
                Utility.isPresent(item.values) && (
                  <this._facialMarkerTags marker={item} />
                )
              );
            }}
          />

          {Utility.isBlank(data) ? (
            <View style={style.progressLoaderContainer}>
              <ActivityIndicator size='large' color={colors.green} />
            </View>
          ) : (
            <List
              id={data.id}
              itemData={data}
              previousScreen='recommendation'
              navigation={navigation}
            />
          )}

          <View style={PersonalisedPageStyle.dividerStyle} />
        </ScrollView>
        <View style={PersonalisedPageStyle.buttonBackground}>
          <TouchableWithoutFeedback
            onPress={this.onNextPress}
            hitSlop={Utility.getHitSlop()}
          >
            <View style={PersonalisedPageStyle.doneButtonContainer}>
              <Text style={PersonalisedPageStyle.doneButtonText}>Done</Text>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    );
  }
}
const PersonalisedPageStyle = StyleSheet.create({
  attributesCardContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  myAttributesHeading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    color: '#999999',
    marginBottom: 10,
  },
  reviewAttributeText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: colors.foxyPink,
    marginBottom: 10,
  },
  verticalSpacing: {
    width: '100%',
    height: 20,
    backgroundColor: colors.background,
  },
  attributesMarkerStyle: {
    marginRight: 8,
    marginTop: 5,
    marginBottom: 5,
  },
  bannerText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 36,
    fontStyle: 'normal',
    color: colors.white,
  },
  filterOverLay: {
    position: 'absolute',
    opacity: 0.4,
    width: '100%',
    height: '100%',
    zIndex: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerContainer: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  doneButtonContainer: {
    backgroundColor: colors.green,
    borderRadius: 4,
    height: Utility.getButtonHeight(),
    width: '90%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    alignSelf: 'center',
    bottom: 20,
  },
  doneButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    padding: 8,
    color: colors.white,
  },
  buttonBackground: {
    backgroundColor: colors.background,
    width: '100%',
    height: 70,
  },
  dividerStyle: {
    width: '100%',
    height: 150,
    backgroundColor: colors.background,
  },
});

const mapStateToProps = (store) => {
  return {
    facialAnalysis: store.UserAccountInfo.facialAnalysis,
    profile: store.UserAccountInfo.profile,
    followedStories: store.UserAccountInfo.followedStories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getArtistStoryRecommendation,
      followRecommendations,
      getListDetails,
      unfollowStories,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FollowRecommendations);
