import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from '../Product/ProductImageAndRating';

import ProductDescription from '../Product/ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AddToCart from '../Product/AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT, MORE_ITEMS } from '../../config/Constants';
import colors from '../../theme/Colors';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { applyFlashDeal } from '../../actions/ActionTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SkuSizeVariant from './SkuSizeVariant';
import SkuColorVariant from './SkuColorVariant';
import FastImageView from '../FastImageView';
import Images from '../../theme/Images';
import size from '../../theme/Fonts';
import LinearGradient from 'react-native-linear-gradient';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

// import SkuListItem from './SkuListItem';    //  //have to add this

class DefaultSizeVariantRail extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.x = { x: 0, y: 0 };
    this.y = { x: 0, y: 1 };
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData: { product_slug: slug, sku_id, id },
      toggleCartVisibility,
      applyFlashDeal,
      previousScreen,
    } = this.props;

    // if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
    applyFlashDeal({
      type: 'Variant',
      id,
    });
    // }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        slug: `/api/v2/products/${slug}.json`,
        display: LAYOUT.SCREEN,
        toggleCartVisibility,
        sku_id,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData = {},
      listId,
      layout,
      previousScreen,
      listName,
      listContent,
      toggleUnavailableProductsModal,
      listData: {
        options: listOptions = {},
        display_count = '',
        slug: listSlug = '',
      } = {},
      listData = {},
      navigation,
      extraEventParameters = {},
    } = this.props;

    const options = Utility.isPresent(listOptions) ? listOptions : {};
   // FIXME: This is an ugly hack. Use better code design to achieve this.
    const { show_timer_in_subtitle = false } = options;

    if (index === display_count - 1 && show_timer_in_subtitle === true) {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: MORE_ITEMS,
        params: {
          displayCount: itemData?.objects?.length,
          heading: itemData.name,
          navigation,
          title: itemData.name,
          slug: listData.slug,
        },
      });
      return;
    }

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      itemData.id,
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      itemData.id,
      itemData.slug,
      listSlug,
      extraEventParameters,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      layout,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData = {},
      listData: { additional_data = {} } = {},
    } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.RAIL}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        singleStockedVariantSku={singleStockedVariantSku}
        additional_data={additional_data}
        listData={listData}
      />
    );
  };

  skuSizeCondition = ({ variantName }) => Utility.isPresent(variantName);

  conditionalVariants = withEither(
    this.skuSizeCondition,
    SkuSizeVariant,
  )(SkuColorVariant);

  skuVariants = (props) => {
    const { variantName, imageUrl, packSize, containerStyle } = props;

    return (
      <this.conditionalVariants
        variantShade={variantName}
        variantImage={imageUrl}
        packSize={packSize}
        containerStyle={containerStyle}
      />
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hasVariants,
      hideAddToCart,
      itemData: {
        offer_value,
        final_sp,
        additional_discount,
        type,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        sku_id: skuId,
        pack_size,
        product_name,
        display_name,
        image_url,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
        transparent_image_url = '',
        transparent_image_vibrant_colors: {
          vibrant_color: backgroundColor = '#B57CD6',
        } = {},
      },
      listData: {
        options: listOptions = {},
        display = '',
        display_count = '',
        subTitle = '',
      } = {},
      index,
    } = this.props;

    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const display_price = final_sp;

    const imageContainerStyle = Utility.isPresent(transparent_image_url)
      ? [styles.imageContainer, { backgroundColor: `${backgroundColor}66` }]
      : styles.imageContainer;

    const options = Utility.isPresent(listOptions) ? listOptions : {};

    const {
      disable_card_background_color = '0',
      show_borders_for_list_items = 0,
      show_timer_in_subtitle = false,
    } = options;

    const containerStyle =
      Utility.isPresent(transparent_image_url) || show_borders_for_list_items
        ? [
            styles.railCardContainer,
            {
              borderColor: colors.border,
              borderWidth: 1,
            },
          ]
        : styles.railCardContainer;

    const finalBackgroundColor =
      disable_card_background_color === '1'
        ? colors.white
        : backgroundColor || colors.white;

    const gradientArray = [
      Utility.addAlpha(finalBackgroundColor, 0.15),
      Utility.addAlpha(finalBackgroundColor, 0.25),
    ];

    return (
      <View>
        <ScaleAnimate
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
        >
          <Card style={containerStyle}>
            <View style={styles.railImageContainer}>
              <ProductImageAndRating
                layout={LAYOUT.RAIL}
                rating={rating}
                images={images}
                webp_images={webp_images}
                image_webp_url={image_webp_url}
                imageUrl={imageUrl}
                id={id}
                name={name}
                previousScreen={previousScreen}
                itemData={itemData}
                isVariant
              />
              <LinearGradient
                colors={gradientArray}
                style={styles.gradient}
                start={this.x}
                end={this.y}
              />
            </View>
            <View style={styles.fullCardDivider} />
            <View style={styles.productDescriptionContainer}>
              <ProductDescription
                itemData={itemData}
                name={product_name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={variantBrandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.RAIL}
                id={id}
                previousScreen={previousScreen}
              />
            </View>
            <View style={styles.cartImageContainer}>
              <this.addToCart
                skuId={skuId}
                orientation={orientation}
                toggleCartState={this.toggleCartState}
                layout={LAYOUT.LIST}
                addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
                hideAddToCart={hideAddToCart}
                id={id}
                itemData={itemData}
                hasVariants={hasVariants}
                showCart={this.showCart}
                priority={priority}
                outOfStock={outOfStock}
                previousScreen={previousScreen}
                singleStockedVariantSku={singleStockedVariantSku}
              />
            </View>
          </Card>
          <this.skuVariants
            variantName={display_name}
            imageUrl={image_url}
            packSize={pack_size}
            layout={LAYOUT.GRID}
            containerStyle={styles.variantContainerStyle}
          />
          {index === display_count - 1 && show_timer_in_subtitle === true && (
            <View style={styles.viewMoreIconContainer}>
              <FastImageView
                source={Images.view_more_icon}
                style={styles.viewMoreIcon}
                resizeMode="contain"
              />
              <Text style={styles.viewMoreText}>View More</Text>
            </View>
          )}
        </ScaleAnimate>
      </View>
    );
  }
}

const numOfItems = isDesktop() ? 6 : 2;

const styles = StyleSheet.create({
  railCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 10,
    width: Utility.getDynamicWidthForGrid(numOfItems, 16) * 1.3,
    height: Utility.getDynamicWidthForGrid(numOfItems, 16) * 1.3 + 116,
    alignSelf: 'center',
  },
  railImageContainer: {
    height: Utility.getDynamicWidthForGrid(numOfItems, 16) * 1.3,
    resizeMode: 'contain',
    overflow: 'hidden',
  },
  gradient: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
    top: 0,
    position: 'absolute',
    zIndex: -1,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 80,
    backgroundColor: '#ffffff',
  },
  imageContainer: {
    height: Utility.getDynamicWidthForGrid(numOfItems, 16) * 1.3,
    overflow: 'hidden',
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 96,
    right: 7,
  },
  viewMoreIconContainer: {
    position: 'absolute',
    borderRadius: 8,
    width: Utility.getDynamicWidthForGrid(numOfItems, 16) * 1.3,
    height: Utility.getDynamicWidthForGrid(numOfItems, 16) * 1.3 + 116,
    backgroundColor: colors.white,
    opacity: 0.9,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewMoreIcon: {
    height: 80,
    width: 80,
  },
  viewMoreText: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    marginTop: 4,
    color: colors.cta.lightBlue,
  },
  productDescriptionContainer: { flex: 1, marginRight: 60 },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      applyFlashDeal,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(DefaultSizeVariantRail),
);
