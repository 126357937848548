import { View, StyleSheet } from 'react-native';
import React from 'react';
import { useRoute } from '@react-navigation/native';
import MoreItemsList from '../feed/MoreItemsList';
import { isDesktop } from '../../utils/BooleanUtility';

export default function ArtistLists() {
  const { params = {} } = useRoute();
  const { handle, listType } = params;
  return (
    <View style={styles.container}>
      <MoreItemsList slug={`/api/v6/lists/artists/${handle}/${listType}.json?`} hideHeader={isDesktop()} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
