import {
  DefaultSizeArtistList,
  DefaultSizeArtistRail,
  DefaultSizeArtistGrid,
} from '.';

export const SmallArtistList = DefaultSizeArtistList;
export const LargeArtistList = DefaultSizeArtistList;
export const SmallArtistRail = DefaultSizeArtistRail;
export const LargeArtistRail = DefaultSizeArtistRail;
export const SmallArtistGrid = DefaultSizeArtistGrid;
export const LargeArtistGrid = DefaultSizeArtistGrid;
