import React, { PureComponent } from 'react';
import { Image, Text, View, SafeAreaView } from 'react-native';
import PropTypes from 'prop-types';
import CrossButton from '../../components/progressBarView/CrossButton';
import images from '../../theme/Images';
import { withMaybe } from '../../lib/Monads';
import stringConstants from '../../strings/Strings';
import FoxyShadowButton from '../../lib/FoxyShadowButton';

class ErrorPage extends PureComponent {
  ErrorView = () => {
    const { errorMessage, errorIconImage, onRetryTap } = this.props;
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {/* <Image source={errorIconImage} /> */}
        <Text style={{ textAlign: 'center', margin: 24.0 }}>
          {errorMessage}
        </Text>
        <FoxyShadowButton
          title={stringConstants.retryButton}
          onPress={onRetryTap}
        />
      </View>
    );
  };

  // CrossButton = () => <CrossButton crossButtonWidth={44.0} onCancelTap={this.props.onCancelTap} />;

  // shouldShowCrossButton = () => this.props.showCross;

  // withCrossButton = () => withMaybe(this.shouldShowCrossButton, CrossButton);

  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        {/* <this.withCrossButton /> */}
        <this.ErrorView />
      </SafeAreaView>
    );
  }
}

ErrorPage.propTypes = {
  errorIconImage: PropTypes.string,
  showCross: PropTypes.bool,
  errorMessage: PropTypes.string,
  onCancelTap: PropTypes.func,
  onRetryTap: PropTypes.func,
};

ErrorPage.defaultProps = {
  errorIconImage: images.errorIconImage,
  showCross: true,
  errorMessage: stringConstants.defaultErrorMessage,
};

export default ErrorPage;
