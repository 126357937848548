import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import Card from '../../lib/Card';
import ScaleAnimate from '../../components/shared/ScaleAnimate';
import { connect } from 'react-redux';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import FastImageView from '../FastImageView';
// import images from '../../theme/Images';

const UnavailableProductVideoCard = (props) => {
  const { onClick, inVariant } = props;
  const imageUrl = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.video_card_image_url,
  );

  const videoCardHeading = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.video_card_heading,
  );

  const videoCardSubHeading = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.video_card_subheading,
  );

  const styleCard =
    imageUrl !== ''
      ? { width: '64%', height: 70 }
      : { width: '95%', height: 70 };

  const marginBetween = imageUrl !== '' ? 2 : 8;
  const notReviewedVariantVideoCard = inVariant
    ? { marginBottom: 0, width: '100%' }
    : { marginBottom: 20, width: '90%' };

  return (
    <ScaleAnimate>
      <View style={[styles.cardStyle, notReviewedVariantVideoCard]}>
        <View style={styles.container}>
          <TouchableOpacity style={styles.crossIconContainer} onPress={onClick}>
            <Image source={images.popupClose} style={styles.crossIcon} />
          </TouchableOpacity>

          {imageUrl !== '' && (
            <FastImageView
              style={styles.videoCardImage}
              source={{
                uri: Utility.getMinifiedImage(imageUrl, 48, 48),
              }}
              resizeMode="cover"
            />
          )}
          <View style={styleCard}>
            <Text style={[styles.videoCardHeadingStyle, { marginBottom: marginBetween }]}>
              {videoCardHeading}
            </Text>
            <Text style={styles.videoCardSubHeadingStyle}>
              {videoCardSubHeading}
            </Text>
          </View>
        </View>
      </View>
    </ScaleAnimate>
  );
};

const styles = StyleSheet.create({
  cardStyle: {
    shadowOffset: { width: 0, height: 0.5 },
    shadowColor: '#dddddd',
    shadowOpacity: 1,
    shadowRadius: 5,
    borderRadius: 2,
    elevation: 3,
    backgroundColor: colors.white,
    height: 80,
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  videoCardHeadingStyle: {
    fontSize: 14,

    color: colors.foxyBlack,
  },
  crossIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 99,
  },
  crossIcon: {
    height: 18,
    width: 18,
  },
  videoCardImage: {
    width: '31%',
    height: 50,
    marginRight: '4%',
  },
  videoCardSubHeadingStyle: {
    fontSize: 12,
    color: colors.silver,
  },
});

export default UnavailableProductVideoCard;
