import React, { PureComponent } from 'react';
import { View, Text, Image } from 'react-native';
import { ProductInfoStyles, ProductDetailStyles } from './styles';
import ScaleAnimate from '../shared/ScaleAnimate';
import CardView from '../../libraries/ReactNativeCardView'; 
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';

class ComparePriceBanner extends PureComponent {
  showComparePrice = () => {
    const { onPress } = this.props;
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  render() {
    const styles = ProductInfoStyles;
    const { itemData } = this.props;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    let cardImage = undefined;
    if (Utility.isPresent(itemData.image_url)) {
      cardImage = {
        uri: Utility.getMinifiedImage(
          itemData.image_url,
          Utility.getScreenWidth(),
          0.3 * Utility.getScreenWidth(),
        ),
      };
    }
    return (
      <View style={styles.cardContainer}>
        <ScaleAnimate {...this.props} onPress={this.showComparePrice}>
          <CardView
            cardElevation={1}
            cardMaxElevation={1}
            cornerRadius={4}
            style={styles.comparePriceCardContent}
          >
            <FastImageView
              source={cardImage}
              style={ProductDetailStyles.comparePriceContainer}
              resizeMode='stretch'
            >
              <View style={styles.comparePriceTextContainer}>
                <View style={styles.comparePriceHeadingContainer}>
                  <Text
                    style={ProductDetailStyles.comparePriceText}
                    allowFontScaling={false}
                  >
                    {itemData.title}
                  </Text>
                  <Image
                    source={images.chevronRight}
                    style={ProductDetailStyles.rightArow}
                  />
                </View>
                <Text style={styles.comparePriceSubheading}>
                  {itemData.body}
                </Text>
              </View>
            </FastImageView>
          </CardView>
        </ScaleAnimate>
      </View>
    );
  }
}

export default ComparePriceBanner;
