import React from 'react';
import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';

export const style = StyleSheet.create({
  //Prompt Style
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
  overlayContainer: {
    height: '100%',
  },
  leftContainer: {
    flex: 0.7,
    backgroundColor: 'rgba(255,255,255,0.1)',
  },
  rightContainer: {
    flex: 1,
    backgroundColor: '#f00',
    backgroundColor: 'rgba(255,255,255,0.1)',
  },
  //Prompt Style end

  //Follow Recommendation
  wrapper: {
    flex: 1,
  },
  progressLoaderContainer: {
    marginTop: 40,
  },

  rightSideButton: {
    position: 'absolute',
    right: 20,
    top: 50,

    zIndex: 1,
  },

  nextButton: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyRed,
    fontSize: size.h2,
  },
  //Follow Recomendation End
});
