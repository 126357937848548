import React, { Component } from 'react';
import { Keyboard, BackHandler } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Orientation from 'react-native-orientation';
import Config from '../../libraries/ReactNativeConfig';

import * as LoginActions from '../../actions/LoginActions';
import {
  ANDROID,
  COUNTRY_CODE,
  CURRENT_GUEST_STATE,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import {
  applyCartCoupon,
  uacSelfieEventTriggered,
} from '../../actions/ActionTypes';

import AnalyticsManager from '../../analytics/AnalyticsManager';
import EventType from '../../analytics/AnalyticsEventType';
import {
  LoginInfo,
  OTPVerification,
  SelectYourInterest,
  SelfiePrompt,
} from '../../components/onboarding';
import WithNavigation from '../../utils/WithNavigation';
import AppConfig from '../../config/AppConfig';
import {
  isAndroid,
  isBlank,
  isPresent,
  isNative,
} from '../../utils/BooleanUtility';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { isPhoneNumberValid } from '../../utils/AddressUtility';
import { goToHomeIfNoPreviousScreen } from '../../utils/NavigationUtility';
import Utility from '../../utils/Utility';

const enableInternationalChoice = AppConfig.getBooleanValue(
  Config.ENABLE_INTERNATIONAL_CHOICE,
);

class Onboarding extends Component {
  constructor(props) {
    super(props);
    const {
      userAccountInfo: { mobileNumber },
    } = props;
    this.mobileNumberRegex = /^[+\d{12,15}$]/;
    this.state = {
      isSmsReceiverRegistered: false,
      otp: '',
      profileManuallyFilled: false,
      showInitialLoginScreenAsOTP: false,
      changePhoneNumberTapped: false,
      otpRequestedManually: isBlank(mobileNumber),
    };
  }

  componentDidMount() {
    goToHomeIfNoPreviousScreen(this.props.navigation);
    if (isNative()) {
      Orientation.lockToPortrait();
    }
    if (isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.hardwareGoBack,
      );
    }
  }

  componentWillUnmount() {
    if (isAndroid()) {
      this.backHandler.remove();
    }
  }

  hardwareGoBack = () => {
    if (AppConfig.getBooleanValue(Config.ENABLE_MANDATORY_LOGIN)) {
      return true;
    }
    this.goBack();
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.pop();
  };

  registerSmsListener = async () => {
    // try {
    //   const registered = await SmsRetriever.startSmsRetriever();
    //   console.tron.log(`SMS Listener Status: ${registered}`);
    //   this.setState({
    //     isSmsReceiverRegistered: registered,
    //   });
    // } catch (error) {
    //   console.tron.log(`SMS Listener Error: ${JSON.stringify(error)}`);
    // }
  };

  setInitialLoginScreenAsOTP = (value, otpRequestedManually = false) => {
    this.setState({
      showInitialLoginScreenAsOTP: value,
      otpRequestedManually,
    });
  };

  onTapChangePhoneNumber = () => {
    this.setState({
      changePhoneNumberTapped: true,
      showInitialLoginScreenAsOTP: false,
    });
  };

  onSkip = (status) => {
    const { loginActions, navigation, route } = this.props;
    loginActions.updateOnboardingStatus(status);
    navigation.pop();
    setTimeout(() => {
      route.params?.parentSkipAction?.();
    }, 100);
  };

  furtherAction = () => {
    const { route: { params: { furtherAction = () => {} } = {} } = {} } =
      this.props;
    furtherAction();
  };

  initialLoginScreen = () => {
    const {
      userAccountInfo,
      userAccountInfo: { requestedFromHintPrompt, callingCode, countryCode },
    } = this.props;
    const { changePhoneNumberTapped, showInitialLoginScreenAsOTP } = this.state;
    let mobileNumber = userAccountInfo?.mobileNumber;
    if (
      isBlank(mobileNumber) ||
      (changePhoneNumberTapped && !showInitialLoginScreenAsOTP)
    ) {
      return LoginInfo;
    }

    let isNumberValid = false;
    if (enableInternationalChoice) {
      isNumberValid =
        isPhoneNumberValid(mobileNumber, callingCode, countryCode) ||
        mobileNumber?.startsWith('369900');
    } else {
      if (mobileNumber.startsWith('+91-') && mobileNumber.indexOf('0') === 4) {
        mobileNumber = mobileNumber.replace('0', '');
      } else if (
        mobileNumber.startsWith('+91') &&
        mobileNumber.indexOf('0') === 3
      ) {
        mobileNumber = mobileNumber.replace('0', '');
      }

      mobileNumber = mobileNumber.replace('-', '');
      if (this.mobileNumberRegex.test(mobileNumber)) {
        const valid = Utility.phoneNumberFormatter(mobileNumber);

        isNumberValid = !valid.error && !requestedFromHintPrompt;
      }
    }

    return isNumberValid ? OTPVerification : LoginInfo;
  };

  selectStartingNavigator = () => {
    const { userAccountInfo } = this.props;
    let OnboardingScreen;

    if (
      userAccountInfo.registered === false ||
      userAccountInfo.authenticated === false
    ) {
      OnboardingScreen = this.initialLoginScreen();
    } else if (isBlank(userAccountInfo.status)) {
      OnboardingScreen = SelfiePrompt;
    } else {
      OnboardingScreen = SelectYourInterest;
    }

    return OnboardingScreen;
  };

  isProfileManuallyFilled = () => {
    this.setState({
      profileManuallyFilled: true,
    });
  };

  onPressOutside = () => {
    const {
      userAccountInfo: { mobileNumber, phoneNumberModalInfo },
      orderStatus = false,
      route,
    } = this.props;
    try {
      Keyboard.dismiss();
    } catch (error) {}

    const loginModalInfo = jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.login_modal_info),
    )['DEFAULT'];
    // If mobile number is entered then dont allow to dismiss popup,assuming that you are on otp screen
    if (
      isBlank(mobileNumber) &&
      (isBlank(phoneNumberModalInfo) ||
        phoneNumberModalInfo.type === loginModalInfo?.type)
    ) {
      // renderOnboarding(false);

      if (!orderStatus) {
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.LOGIN_MODAL_SKIP_ATTEMPT,
        );
      }
    } else {
      if (!orderStatus) {
        AnalyticsManager.logEvent(EventType.onboardingEvent.LOGIN_MODAL_SKIP);
      }
    }
    const {
      params: {
        goBackOnBackgroundTap = true,
        onPressOutsideAction = () => {},
      } = {},
    } = route;
    if (goBackOnBackgroundTap) {
      this.goBack();
    }

    setTimeout(() => {
      onPressOutsideAction();
    }, 0);
  };

  navigateToRegisterWebView = () => {
    const { navigation } = this.props;
    navigation.navigate('WebUrlView', {
      destination: `${Config.CONTACT_INFO_TYPEFORM_URL}`,
      title: 'Register',
      replaceScreen: 'ConsumerProfile',
    });
  };

  navigateToInviteCenter = () => {
    const { navigation } = this.props;
    navigation.navigate('InviteCenter', {
      replaceScreen: 'ConsumerProfile',
    });
  };

  render() {
    const {
      userAccountInfo,
      interestsList,
      loginActions,
      otp,
      uacSelfieEventTriggered,
      orderStatus = false,
      applyCartCoupon,
    } = this.props;
    const {
      isSmsReceiverRegistered,
      profileManuallyFilled,
      changePhoneNumberTapped,
      otpRequestedManually,
    } = this.state;
    const isAuthTokenPresent = isPresent(userAccountInfo.authToken);

    if (userAccountInfo.authenticated) {
      return null;
    }
    const {
      guestProfile: { current_state },
      currentOtp = '',
    } = userAccountInfo;
    let backgroundColor = 'rgba(0, 0, 0, 1)';
    if (
      current_state === CURRENT_GUEST_STATE.FULLY_REGISTERED ||
      current_state === CURRENT_GUEST_STATE.SKIPPED ||
      current_state === CURRENT_GUEST_STATE.SELFIE_CLICKED
    ) {
      backgroundColor = 'rgba(0,0,0,0.6)';
    }

    if (orderStatus) {
      backgroundColor = '#fff';
    }

    const OnboardingScreen = this.selectStartingNavigator();

    return (
      <>
        {/* onPressOutside={this.onPressOutside}> */}
        <OnboardingScreen
          screenProps={{
            userAccountInfo,
            interestsList,
            onChangeCountryCode: loginActions.changeCountryCode,
            onAddMobileNumber: loginActions.addMobileNumber,
            onInsertMobileNumber: loginActions.insertMobileNumber,
            onRequestOtp: loginActions.requestOTP,
            onTogglingAutofill: loginActions.toggleAutofill,
            onAddProfilePic: loginActions.addProfilePic,
            onSubmitUserInfo: loginActions.addProfileDetails,
            onResendOtp: loginActions.sendOtp,
            onVerifyOtp: loginActions.verifyOtp,
            onVerifyHandle: loginActions.verifyHandle,
            onVerifyHandleFailure: loginActions.verifyHandleFailure,
            onClearHandleStates: loginActions.clearHandleStates,
            clearOtpError: loginActions.clearOTPError,
            getLocation: loginActions.getLocation,
            addLatLong: loginActions.addLatLong,
            onAutoCompleteLocality: loginActions.autoCompleteLocality,
            onToggleInterest: loginActions.toggleInterest,
            onPostSelectedInterests: loginActions.postSelectedInterest,
            getUserInstagramProfile: loginActions.getUserInstagramProfile,
            getProfilePicUrl: loginActions.getProfilePicUploadUrl,
            saveInstagramProfile: loginActions.saveInstagramProfile,
            onPressOutside: this.onPressOutside,
            onSkip: this.onSkip,
            goBack: this.goBack,
            registerSmsReceiver: this.registerSmsListener,
            unregisterSmsReceiver: this.unregisterSmsRegister,
            onFacailLandmarkDetection: loginActions.analyseFace,
            saveProfilePic: loginActions.saveCDNlinktoRedux,
            isSmsReceiverRegistered,
            getFaceLandmarksById: loginActions.getFaceLandmarksById,
            isOtpRequestedFromHint: loginActions.isRequestedFromHintPrompt,
            profileManuallyFilled: this.isProfileManuallyFilled,
            getCurrentOtp: currentOtp,
            loginModalState: loginActions.changeLoginModalState,
            sendOtpOverCall: loginActions.sendOtpOverCall,
            sendOtpOverWhatsapp: loginActions.sendOtpOverWhatsapp,
            postTruecallerData: loginActions.verifyTrueCaller,
            storeCurrentOtp: loginActions.storeCurrentOtp,
            setInitialLoginScreenAsOTP: this.setInitialLoginScreenAsOTP,
            onTapChangePhoneNumber: this.onTapChangePhoneNumber,
            changePhoneNumberTapped,
            // setForcedLogoutFlow: loginActions.setForcedLogoutFlow,
            uacSelfieEventTriggered,
            orderStatus,
            applyCartCoupon,
            furtherAction: this.furtherAction,
            otpRequestedManually,
            navigateToRegisterWebView: this.navigateToRegisterWebView,
            navigateToInviteCenter: this.navigateToInviteCenter,
          }}
        />
      </>
    );
  }
}

Onboarding.propTypes = {
  userAccountInfo: PropTypes.object,
  loginActions: PropTypes.any,
  interestsList: PropTypes.any,
};

export default WithNavigation(
  connect(
    (state) => ({
      userAccountInfo: state.UserAccountInfo,
      interestsList: state.InterestsList,
    }),
    (dispatch) => ({
      loginActions: bindActionCreators(LoginActions, dispatch),
      applyCartCoupon: bindActionCreators(applyCartCoupon, dispatch),
      uacSelfieEventTriggered: bindActionCreators(
        uacSelfieEventTriggered,
        dispatch,
      ),
    }),
  )(Onboarding),
);
