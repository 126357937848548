export const SET_NEW_PAYMENT_FLOW = 'SET_NEW_PAYMENT_FLOW';
export const GET_PAYMENT_CURRENT_STATUS = 'GET_PAYMENT_CURRENT_STATUS';
export const SEND_PAYMENT_REQUEST = 'SEND_PAYMENT_REQUEST';
export function setCurrentPaymentFlow(isNewPaymentFlow) {
  return {
    type: SET_NEW_PAYMENT_FLOW,
    data: {
      isNewPaymentFlow,
    },
  };
}

export function getPaymentCurrentStatus(txnId, user_action, callback) {
  return {
    type: GET_PAYMENT_CURRENT_STATUS,
    txnId,
    user_action,
    callback,
  };
}

export function sendPaymentRequest(payload, callback) {
  return {
    type: SEND_PAYMENT_REQUEST,
    payload,
    callback,
  };
}
