import {
  DefaultSizeProductCard,
  DefaultSizeProductList,
  DefaultSizeProductRail,
  DefaultSizeProductVerticalRail,
} from '.';
import DefaultSizeProductGrid from './DefaultSizeProductGrid';

export const SmallProductCard = DefaultSizeProductCard;
export const SmallProductGrid = DefaultSizeProductGrid;
export const SmallProductRail = DefaultSizeProductRail;
export const SmallProductList = DefaultSizeProductList;
export const SmallProductVerticalRail = DefaultSizeProductVerticalRail;
export const LargeProductCard = DefaultSizeProductCard;
export const LargeProductGrid = DefaultSizeProductGrid;
export const LargeProductList = DefaultSizeProductList;
export const LargeProductRail = DefaultSizeProductRail;
export const LargeProductVerticalRail = DefaultSizeProductVerticalRail;
