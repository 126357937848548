// Dependencies
import React, { Component } from 'react';
import { View, FlatList, NativeModules } from 'react-native';
import PropTypes, { element } from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LAYOUT, ORIENTATION, URL } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
import CacheMedia from '../../../utils/CacheMedia';
// Components
import Artist from '../../../containers/artist';
import styles from './styles';
import StoriesPlaceholder from './StoriesPlaceholder';
import PublisherStoryCard from '../../Artist/PublisherStoryCard';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../../../src/analytics';
import { viewTopic } from '../../../actions/StoriesAction';
import { fetchStories } from '../../../actions/ActionTypes';
import RemoteConfig from '../../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../../config/Constants';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';

class StoryRail extends Component {
  static Components = {
    artist: Artist,
    story: PublisherStoryCard,
  };

  static getComponentHeight(item) {
    return 128.4;
  }

  constructor(props) {
    super(props);
    const { listData } = props;
    const { UIManager } = NativeModules;
    UIManager.setLayoutAnimationEnabledExperimental &&
      UIManager.setLayoutAnimationEnabledExperimental(true);
    this.slug = listData?.slug;
    // this.slug = `/api/v3/lists/stories`;
    this.item = [];
    this.topics = [];
    this.state = {
      isContentProcessing: true,
      videoViewedTriggered: false,
    };
    this.videosData = {};
    this.videosData.id = 'stories';
  }

  componentDidMount() {
    const { item, fetchStories } = this.props;
    fetchStories(this.slug, this.fetchStoriesCallback);
  }

  componentDidUpdate(prevProps) {
    const { viewedTopics } = this.props;
    if (prevProps.viewedTopics !== viewedTopics) {
      this.updateRail();
    }
  }

  fetchStoriesCallback = (success, data) => {
    if (success) {
      this.item = data.objects;
      this.setTopicDetails();
    }
    const { isContentProcessing } = this.state;
    if (isContentProcessing) {
      this.setState({ isContentProcessing: false });
    }
  };

  setTopicDetails = () => {
    for (let i = 0; i < this.item.length; i += 1) {
      if (!Utility.isBlank(this.item[i])) {
        if (this.item[i].content === 'topic') {
          this.topics = this.item[i].objects;
        } else if (this.item[i].content === 'media') {
          this.videosData.objects = this.item[i].objects;
        }
      }
    }

    this.sortTopics();
    if (!Utility.isBlank(this.videosData.objects)) {
      this.prefetchVideos();
    }
    this.triggerEventStoryListLoad();
  };

  prefetchVideos = () => {
    const videoUrls = [];
    const androidMaxCacheLimit = RemoteConfig.getIntValue(
      REMOTE_CONFIG_KEYS.android_max_cache_limit,
    );
    const iosMaxCacheLimit = RemoteConfig.getIntValue(
      REMOTE_CONFIG_KEYS.maxVideoDownloadLimit,
    );
    this.videosData.objects.every((video) => {
      const url = video?.metadata?.videoUrl;
      if (Utility.isAndroid()) {
        if (
          videoUrls.length ===
          androidMaxCacheLimit - ~~(androidMaxCacheLimit / 4)
        ) {
          return false;
        }
      } else if (Utility.isIOS()) {
        if (videoUrls.length === iosMaxCacheLimit - ~~(iosMaxCacheLimit / 4)) {
          return false;
        }
      }
      const extension = url.split('.').pop();
      if (extension === 'm3u8') {
        videoUrls.push(url);
      }
      return true;
    });
    CacheMedia.saveVideoUrlsForCaching(videoUrls);
  };

  updateRail = () => {
    // const { followedStories, hideStoriesIntros } = this.props;
    // const storiesArray = [];
    // this.topics.map((story) => {
    //   storiesArray.push(story.id);
    // });
    // const followRecommendationsStories = followedStories.filter(
    //   (followRecommendationsStory) => {
    //     if (!storiesArray.includes(followRecommendationsStory.id)) {
    //       return followRecommendationsStory;
    //     }
    //   },
    // );
    // this.topics = topics.concat(followRecommendationsStories);
  };

  sortTopics = () => {
    const { viewedTopics } = this.props;
    if (Utility.isBlank(this.topics) || Utility.isBlank(viewedTopics)) {
      return null;
    }

    const fullyViewedTopics = this.topics.filter((element) => {
      if (
        !Utility.isBlank(element) &&
        !Utility.isBlank(element.id) &&
        !Utility.isBlank(viewedTopics[element.id])
      ) {
        const allVideosWatched = element.media_ids.every((r) =>
          viewedTopics[element.id].includes(r),
        );
        return allVideosWatched ? element : null;
      }
      return null;
    });

    const nonViewedTopics = this.topics.filter((element) => {
      if (!Utility.isBlank(element) && !Utility.isBlank(element.id)) {
        if (Utility.isBlank(viewedTopics[element.id])) {
          return element;
        } else {
          const allVideosWatched = element.media_ids.every((r) =>
            viewedTopics[element.id].includes(r),
          );
          return allVideosWatched ? null : element;
        }
      }
      return null;
    });

    if (
      !Utility.isBlank(nonViewedTopics) &&
      !Utility.isBlank(fullyViewedTopics)
    ) {
      var newVideoIds = [];
      for (let index in nonViewedTopics) {
        newVideoIds.push(...nonViewedTopics[index].media_ids);
      }
      for (let index in fullyViewedTopics) {
        newVideoIds.push(...fullyViewedTopics[index].media_ids);
      }
      var newVideos = [];
      for (let videoIdIndex in newVideoIds) {
        for (let videoIndex in this.videosData.objects) {
          if (
            newVideoIds[videoIdIndex] === this.videosData.objects[videoIndex].id
          ) {
            newVideos.push(this.videosData.objects[videoIndex]);
          }
        }
      }
      this.topics = nonViewedTopics.concat(fullyViewedTopics);
      this.videosData.objects = newVideos;
    }
  };

  videoViewed = (videoId) => {
    const { item, viewTopic } = this.props;
    var requiredTopic;
    for (let topicIndex in this.topics) {
      const topic = this.topics[topicIndex];
      if (topic.media_ids.includes(videoId)) {
        requiredTopic = topic;
      }
    }

    if (!Utility.isBlank(requiredTopic)) {
      viewTopic(requiredTopic.id, videoId);
    } else {
      console.tron.log('topic not found');
    }
    this.setState({ videoViewedTriggered: true });
  };

  getComponent = ({ item, index }) => {
    const {
      showToast,
      navigation,
      listData,
      size,
      id,
      viewedTopics,
      listIndex,
      previousScreen,
    } = this.props;

    if (Utility.isBlank(item)) {
      return null;
    }

    let isItemViewed = false;

    const mediaIds = item?.media_ids;
    let nonViewedId = mediaIds[0];
    let startIndex = 0;
    if (viewedTopics != null && !Utility.isBlank(viewedTopics[item.id])) {
      const viewedIds = viewedTopics[item.id];
      isItemViewed = item.media_ids.every((ele) => viewedIds.includes(ele));
      if (!isItemViewed && item.media_indices.length > 0) {
        for (let mediaId in mediaIds) {
          if (!viewedIds.includes(mediaIds[mediaId])) {
            nonViewedId = mediaIds[mediaId];
            break;
          }
        }
      }
    }

    const videos = this.videosData.objects;

    for (let videoIndex in videos) {
      if (videos[videoIndex].id === nonViewedId) {
        startIndex = parseInt(videoIndex);
        break;
      }
    }
    const image = item?.icon ? item.icon : item.image_url;
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <PublisherStoryCard
          image_url={image}
          name={item?.name}
          displayName={item?.display_name}
          layout={LAYOUT.STORYRAIL}
          orientation={ORIENTATION.VERTICAL}
          listId={id}
          size={size}
          navigation={navigation}
          index={index}
          listIndex={listIndex}
          item={item}
          itemData={this.topics}
          // hideStoriesIntro={hideStoriesIntro}
          // sortStories={this.sortTopics}
          // getArtistStoryRecommendation={getArtistStoryRecommendation}
          showToast={showToast}
          startIndex={startIndex}
          videosData={this.videosData}
          isTopicViewed={isItemViewed}
          videoViewed={this.videoViewed}
          listData={listData}
          previousScreen={previousScreen}
        />
      </ErrorBoundaryComponent>
    );
  };

  flatListComponent = (props) => {
    const { data } = props;
    return (
      <FlatList
        extraData={this.state}
        data={data}
        horizontal
        style={styles.flatlistStyle}
        // extraData={{ ...this.props, ...this.state, ...props }}
        showsHorizontalScrollIndicator={false}
        renderItem={this.getComponent}
        keyExtractor={this.keyExtractor}
      />
    );
  };

  keyExtractor = (item) => `${item.schema}_${item.id}`;

  triggerEventStoryListLoad() {
    const topicIds = [];
    this.topics.forEach((element) => {
      topicIds.push(element.id);
    });
    AnalyticsManager.logEvent(EventType.storyEvents.STORY_LIST_LOAD, {
      [EventParameterKey.TOPICS_COUNT]: this.topics.length,
      [EventParameterKey.VIDEOS_COUNT]: this.videosData.objects.length,
      [EventParameterKey.TOPIC_ID]: topicIds.toString(),
    });
  }

  render() {
    const { isContentProcessing } = this.state;

    if (isContentProcessing) {
      return <StoriesPlaceholder />;
    }

    if (Utility.isBlank(this.topics)) {
      return null;
    }

    return (
      <View style={styles.storyRailView}>
        <this.flatListComponent
          data={this.topics}
          // viewedTopics={viewedTopics}
          // videosData={this.videosData}
        />
      </View>
    );
  }
}

// PropTypes
StoryRail.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
  }),
  item: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      // getArtistStory,
      // hideStoriesIntro,
      // getArtistStoryRecommendation,
      viewTopic,
      fetchStories,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  // followedStories: state.UserAccountInfo.followedStories,
  // hideStoriesIntros: state.stories.hideStoriesIntro,
  // viewedStories: state.stories.viewedStories,
  stories: state.stories,
  viewedTopics: state.stories.viewedTopics,
});

export default connect(mapStateToProps, mapDispatchToProps)(StoryRail);
