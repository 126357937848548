import React, { useEffect, useState } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Image,
} from 'react-native';
import { Picker } from '@react-native-picker/picker';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { OutlinedTextField } from 'react-native-material-textfield';
import InfluencerTag from './InfluencerTag';
import ImageUploader from './UploadProfileImage';
import { isBlank, isDesktop, isPresent } from '../../utils/BooleanUtility';
import { ids, styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkHandleAvailability,
  getInfluencerDetails,
  getInfluencerTags,
  setArtistHandle,
  updateInfluencerData,
} from '../../actions/InfluencerActions';
import { useRef } from 'react';
import { REGEX } from '../../config/Constants';
import { useNavigation, useRoute } from '@react-navigation/native';
import GrayScaledImage from '../../containers/inviteCenter/GrayscaleImage';
import { AnalyticsManager, EventType } from '../../analytics';

export default function HandleSelection() {
  const { id: currentUserId = '', profile = {} } = useSelector((state) => state.UserAccountInfo);
  const { id: profileId = '', handle: instagramHandle = '' } = profile;
  const [influencerTagIds, setInfluencerTagIds] = useState([]);
  const [influencerTags, setInfluencerTags] = useState([]);
  const [image, setImage] = useState('');
  const [handleErrorMessage, setHandleErrorMessage] = useState('');
  const [bio, setBio] = useState('');
  const [handle, setHandle] = useState(instagramHandle);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [followerReach, setFollowerReach] = useState(dropdownData[0]);
  const [bioError, setBioError] = useState('');
  const dispatch = useDispatch();
  const scrollViewRef = useRef(null);
  const navigation = useNavigation();

  const [focusedTextField, setFocusedTextField] = useState('');
  const [influencerData, setInfluencerData] = useState({});
  const { params = {} } = useRoute();
  const { source = '' } = params;
  const fromDashboard = source === 'dashboard';

  useEffect(() => {
    fetchInfluencerTags();
    fetchInfluencerDetails();
  }, []);

  const fetchInfluencerDetails = () => {
    if (isPresent(handle)) {
      dispatch(
        getInfluencerDetails(handle, (success, response) => {
          const {
            bio: responseBio = '',
            name: responseName = '',
            influencer_tags: responseInfluencerTags,
            pro_accounts = [],
            image_url = '',
          } = response;
          setBio(responseBio);
          setName(responseName);
          setDefaultEmail(pro_accounts);
          const filteredInfluencerTagsIds = responseInfluencerTags.reduce(
            (a, currentValue) => [...a, currentValue.id],
            [],
          );
          defaultFollowerReach(filteredInfluencerTagsIds);
          setInfluencerTagIds(filteredInfluencerTagsIds);
          setInfluencerData(response);
          setImage(image_url);
          AnalyticsManager.logEvent(EventType.INFLUENCER.UPDATE_PROFILE_INFORMATION_PAGE_VIEW, {
            current_user_id: currentUserId,
            edit: responseName && responseBio,
          });
        }),
      );
    }
  };

  const defaultFollowerReach = (influencerTagsIds) => {
    const defaultFollowerReach = dropdownData.filter((obj) =>
      influencerTagsIds.includes(parseInt(obj.value)),
    );
    if (isPresent(defaultFollowerReach)) {
      setFollowerReach(defaultFollowerReach[0]);
    }
  };

  const fetchInfluencerTags = () => {
    dispatch(
      getInfluencerTags((success, response) => {
        if (success) {
          setInfluencerTags(response);
        }
      }),
    );
  };

  const setDefaultEmail = (proAccounts) => {
    const defaultEmailAddress =
      proAccounts.find(
        (proAccount) => proAccount.social_media_platform_id === 4,
      )?.user_input || '';
    setEmail(defaultEmailAddress);
  };

  const handleChipPress = (value) => {
    if (influencerTagIds.includes(value)) {
      setInfluencerTagIds(influencerTagIds.filter((id) => id !== value));
    } else if (influencerTagIds.length <= 4) {
      setInfluencerTagIds([...influencerTagIds, value]);
    }
  };

  const PrefixIcons = ({ image_url, isFocused }) => {
    return (
      <GrayScaledImage
        source={{ uri: image_url }}
        style={{ height: 25, width: 40, resizeMode: 'contain' }}
        enableGrayscale={!isFocused}
      />
    );
  };

  const onChangeHandle = (value = '') => {
    setHandle(value);
    if (isBlank(value)) {
      setHandleErrorMessage('');
      return;
    }
    dispatch(
      checkHandleAvailability(value, (success, response = {}) => {
        let errorMessage = '';
        if (!success) {
          const { error_message = '' } = response;
          errorMessage = error_message;
        }
        setHandleErrorMessage(errorMessage);
      }),
    );
  };

  const scrollToIndex = (value) => {
    if (scrollViewRef && scrollViewRef.current) {
      scrollViewRef.current.scrollTo(value);
    }
  };

  const onContinuePress = () => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.SUBMIT_PROFILE_INFORMATION, {
      current_user_id: currentUserId,
    });
    if (isBlank(handle) || isPresent(handleErrorMessage)) {
      setHandleErrorMessage(handleErrorMessage || 'Required.');
      scrollToIndex(170);
      return;
    }
    if (bio.length < 20) {
      setBioError('Minimum 20 characters required.');
      scrollViewRef.current?.scrollToEnd({ animated: true });
      return;
    }
    if (isBlank(email) || !validateEmail(email)) {
      setEmailError('Enter a valid email.');
      scrollViewRef.current?.scrollToEnd({ animated: true });
      return;
    }
    updateInfluencer();
  };

  const updateInfluencer = () => {
    const { pro_accounts = [] } = influencerData;
    const proAccountId =
      pro_accounts.find(
        (proAccount) => proAccount.social_media_platform_id === 4,
      )?.id || '';
    const postData = {
      user: {
        pro_accounts_attributes: [
          {
            id: proAccountId,
            social_media_platform_id: 4,
            user_input: email,
          },
        ],
        profile_attributes: {
          bio,
          handle,
          id: profileId,
          influencer_tag_follower_range_id: followerReach.value,
          influencer_tag_ids: influencerTagIds,
          image,
          name,
        },
      },
    };
    dispatch(setArtistHandle(handle));
    dispatch(
      updateInfluencerData(postData, (success, response) => {
        if (success) {
          if (fromDashboard) {
            navigation.navigate('Dashboard')
          } else {
            navigation.navigate('AddSocialProfiles', {
              handle,
              ...response,
            });
          }
        }
      }),
    );
  };

  const validateEmail = () => {
    try {
      return REGEX.EMAIL.test(email);
    } catch (err) {
      return false;
    }
  };

  const onChangeBio = (value = '') => {
    setBio(value);
    setBioError('');
  };

  const onChangeEmail = (value = '') => {
    setEmail(value);
    setEmailError('');
  };

  const goBack = () => {
    navigation.goBack();
  };

  const handlePress = () => {
    navigation.push('TabNavigator');
  };

  const handlePostInsertImage = () => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.PROFILE_PIC_UPLOAD, {
      current_user_id: currentUserId,
    });
  };

  return (
    <View style={styles.container}>
      {isDesktop() && (
        <TouchableOpacity style={styles.logoContainer} onPress={handlePress}>
          <Image source={{ uri: images.appHeaderLogo }} style={styles.logo} />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        <ScrollView
          stickyHeaderIndices={[0]}
          style={styles.scrollView}
          ref={scrollViewRef}
        >
          <View style={styles.header}>
            <TouchableOpacity onPress={goBack}>
              <Image source={images.ic_back_product} style={styles.back} />
            </TouchableOpacity>

            <View dataSet={{ media: ids.headingTextContainer }}>
              <Text
                style={styles.headerTitle}
                dataSet={{ media: ids.headerTitle }}
              >
                Build your one link profile
              </Text>
              <Text
                style={styles.headerSubtitle}
                dataSet={{ media: ids.headerSubtitle }}
              >
                By adding your details
              </Text>
            </View>
          </View>
          <View dataSet={{ media: ids.formContainer }}>
            <ImageUploader image={image} setImage={setImage} postInsertImage={handlePostInsertImage} />
            <View style={{ padding: 16 }}>
              <OutlinedTextField
                renderLeftAccessory={AppHandlePrefix}
                label='Claim Your One Link URL'
                tintColor={colors.subtitle}
                error={handleErrorMessage}
                onChangeText={onChangeHandle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                labelOffset={{ x1: -126, y1: -20, y0: -8 }}
                contentInset={{ input: 8, left: 0 }}
                value={handle}
              />
            </View>
            <View style={{ padding: 16 }}>
              <OutlinedTextField
                label='Name'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                labelOffset={{ x1: -17, y1: -20, y0: -8 }}
                contentInset={{ input: 8 }}
                value={name}
                onChangeText={setName}
              />
            </View>
            <View style={{ padding: 16 }}>
              <Text style={styles.label}>No. of followers</Text>
              <Picker
                label='No. of followers'
                style={styles.picker}
                selectedValue={followerReach}
                onValueChange={(itemValue, itemIndex) =>
                  setFollowerReach(itemValue)
                }
              >
                {dropdownData.map(({ label, value }) => (
                  <Picker.Item label={label} value={value} />
                ))}
              </Picker>
            </View>
            <View style={{ padding: 16 }}>
              <Text style={styles.label}>About you (select upto 4)</Text>
              <View style={styles.chipContainer}>
                {influencerTags.map((chipObject) => (
                  <InfluencerTag
                    {...chipObject}
                    label={chipObject.name}
                    image={chipObject.image_url}
                    handleChipPress={handleChipPress}
                    influencerTagIds={influencerTagIds}
                    value={chipObject.id}
                  />
                ))}
              </View>
            </View>
            <View style={{ padding: 16 }}>
              <OutlinedTextField
                height={150}
                label='Bio'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.multilineTextInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                onChangeText={onChangeBio}
                labelOffset={{ x1: -17, y1: -20 }}
                multiline
                numberOfLines={15}
                maxLength={600}
                style={{ outline: 'none' }}
                value={bio}
                error={bioError}
              />
            </View>
            <View style={{ padding: 16 }}>
              <OutlinedTextField
                label='Email address'
                renderLeftAccessory={() => (
                  <PrefixIcons
                    image_url={images.email}
                    isFocused={focusedTextField === 'email' || isPresent(email)}
                  />
                )}
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                labelOffset={{ x1: -54, y1: -20, y0: -8 }}
                contentInset={{ input: 8, left: 0 }}
                value={email}
                onFocus={() => setFocusedTextField('email')}
                onBlur={() => setFocusedTextField('')}
                error={emailError}
                onChangeText={onChangeEmail}
              />
            </View>
            <View style={styles.verticalSpace} />
          </View>
        </ScrollView>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.buttonContainer}
          dataSet={{ media: ids.buttonContainer }}
        >
          <TouchableOpacity style={styles.button} onPress={onContinuePress}>
            <Text style={styles.buttonText}>{fromDashboard ? 'Save' : 'Continue'}</Text>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </View>
    </View>
  );
}

const AppHandlePrefix = () => (
  <Text style={styles.appHandlePrefix}>foxy.in/@</Text>
);

const dropdownData = [
  { value: '1', label: 'Reaching 5k' },
  { value: '2', label: '5K+ Followers' },
  { value: '3', label: '10K+ Followers' },
  { value: '4', label: '20K+ Followers' },
  { value: '5', label: '30K+ Followers' },
  { value: '6', label: '50K+ Followers' },
  { value: '7', label: '75K+ Followers' },
  { value: '8', label: '100K+ Followers' },
  { value: '9', label: '125K+ Followers' },
  { value: '10', label: '150K+ Followers' },
  { value: '11', label: '200K+ Followers' },
  { value: '12', label: '500K+ Followers' },
  { value: '13', label: '1 Mn+ Followers' },
  { value: '14', label: '5 Mn+ Followers' },
];
