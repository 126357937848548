import initialState from './initialState';
import * as types from '../actions/ActionTypes';

export default function BoostedOffers(
  state = initialState.boostedOffers,
  action,
) {
  switch (action.type) {
    case types.SAVE_BOOSTED_OFFERS:
      return {
        ...action.offers,
      };

    default:
      return state;
  }
}
