import React, { PureComponent } from 'react';
import { TouchableOpacity } from 'react-native';
import _ from 'lodash';
import { connect } from 'react-redux';

class DebouncedTouchableOpacity extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedOnPress = _.debounce(this.customOnPress, 500, {
      leading: true,
      trailing: false,
    });
  }

  customOnPress = () => {
    const { onPress } = this.props;
    onPress();
  };

  handleOnPress = () => {
    const {
      listData: { options: { is_tappable = false } = {} } = {},
      previousScreen,
      membership_cohort,
      navigation,
    } = this.props;

    //TODO: This is not job of DebouncedTouchableOpacity. please write another wrapper for this
    if (
      membership_cohort !== 'member' &&
      ['foxy_edge_non_member', 'foxy_edge_expired'].includes(previousScreen) &&
      !is_tappable
    ) {
      navigation.push('BecomeMemberModal', {
        previousScreen,
      });
      return;
    }
    this.debouncedOnPress();
  };

  render() {
    const { children, delayPressOut = 500 } = this.props;
    return (
      <TouchableOpacity
        {...this.props}
        onPress={this.handleOnPress}
        delayPressOut={delayPressOut}
      >
        {children}
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => ({
  membership_cohort: state.todayDeals.membership_cohort,
});

export default connect(mapStateToProps, null)(DebouncedTouchableOpacity);
