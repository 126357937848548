import _ from 'lodash';
import AnalyticsEvent from './AnalyticsEvent';
import { ReactMoE, MoEProperties } from '../../../libraries/ReactMoe';
import Utility from '../../../utils/Utility';

class MoengageAnalytics {
  // TODO: Added this implementation to break the dependency cycle between
  // AnalyticsManager, MoengageAnalytics and Utility. Should be refactored
  // and removed.
  static isBlank(value) {
    return (
      ((_.isEmpty(value) ||
        _.isNull(value) ||
        _.isUndefined(value) ||
        value === 'null' ||
        value === 'undefined') &&
        !_.isNumber(value)) ||
      _.isNaN(value)
    );
  }

  static trackEvent = (event) => {
    if (Utility.isWeb()) return;
    if (event instanceof AnalyticsEvent) {
      let { meta, eventType } = event;

      const properties = new MoEProperties();

      if (MoengageAnalytics.isBlank(meta)) {
        meta = null;
      }

      for (var key in meta) {
        if (meta.hasOwnProperty(key)) {
          properties.addAttribute(key, meta[key]);
        }
      }

      ReactMoE.trackEvent(eventType, properties);
    }
  };

  static setUserId = (userId) => {
    if (Utility.isWeb()) return;
    ReactMoE.setUserUniqueID(userId);
  };

  static setUserAlias = (userId) => {
    if (Utility.isWeb()) return;
    ReactMoE.setAlias(userId);
  };

  static setUserProperty = (key, value) => {
    if (Utility.isWeb()) return;
    ReactMoE.setUserAttribute(key, value);
  };

  static setGeneralUserAttributes = (object) => {
    if (Utility.isWeb()) return;
    const { name, email, contactNumber } = object;
    if (Utility.isPresent(name)) {
      ReactMoE.setUserName(name);
    }
    if (Utility.isPresent(email)) {
      ReactMoE.setUserEmailID(email);
    }
    if (Utility.isPresent(contactNumber)) {
      ReactMoE.setUserContactNumber(contactNumber);
    }
  };
}

export default MoengageAnalytics;
