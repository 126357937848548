import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import StyleSheet from 'react-native-media-query';
import _ from 'lodash';
import { ArtistCardStyles } from './styles';
import Card from '../../lib/Card';
import { ARTIST } from '../../config/LayoutConstants/ArtistConfig';
import images from '../../theme/Images';
import { LAYOUT, ARTIST_CONSTANTS, MORE_ITEMS } from '../../config/Constants';
import FollowArtistButton from './FollowArtistButton';
import ScaleAnimate from '../shared/ScaleAnimate';
import { withEither } from '../../lib/Monads';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
  AnalyticsUtilty,
} from '../../analytics';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FastImageView from '../FastImageView';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

class DefaultSizeArtistGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceArtistCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
  }

  navigate = () => {
    const {
      previousScreen,
      listData: { id: listId = '', slug: listSlug = '' },
      navigation,
      itemData: {
        layout,
        id,
        name,
        slug,
        search,
        listIndex,
        index,
        searchQuery,
        elementItemCounts,
        listName,
        listContent,
        bio_handle = '',
        image_url = '',
      },
    } = this.props;

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;

      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery || '',
        [EventParameterKey.ITEM_TYPE]: 'artist',
        [EventParameterKey.ITEM_NAME]: name,
        [EventParameterKey.ITEM_ID]: id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 0,
      };
      this.debounceArtistCardClick(meta);
    }

    AnalyticsUtilty.fireItemClickEvent(
      previousScreen,
      id,
      name,
      'artist',
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
    );

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Artist',
      params: {
        bioHandle: bio_handle,
        display: LAYOUT.SCREEN,
        profilePlaceholderUrl: image_url,
      },
    });
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  ArtistAvatar = (props) => {
    const styles = ArtistCardStyles;
    const { imageUrl, imageStyle } = props;
    return (
      <View style={styles.container}>
        <FastImageView
          source={Utility.getMinifiedImage(imageUrl, 480, 480)}
          style={imageStyle}
          onError={this.imageError}
        />
      </View>
    );
  };

  artistDescription = (props) => {
    let { name, artistType } = props;
    const { artistTypes } = this.props;
    if (Utility.isBlank(artistType) && Utility.isBlank(artistTypes)) {
      return null;
    }
    const styles = ArtistCardStyles;
    let artistTypeText = 'Artist';
    artistType = _.sortBy(artistType, [
      (artistTypeId) => artistTypes[artistTypeId].priority,
    ]);
    if (
      !Utility.isBlank(artistTypes) &&
      !Utility.isBlank(artistType) &&
      artistType.length !== 0
    ) {
      artistTypeText = artistTypes[artistType[0]].name || 'Artist';
    }
    return (
      <View style={styles.artistDescription}>
        <Text style={styles.artistNameForList}>{name}</Text>
        <Text style={styles.artistDesignation}>{artistTypeText}</Text>
      </View>
    );
  };

  artistName = (props) => {
    const { name } = props;
    return <Text style={ArtistCardStyles.artistname}>{name}</Text>;
  };

  listConditionFn = (props) => props.layout === LAYOUT.LIST;

  conditionalDescription = withEither(
    this.listConditionFn,
    this.artistDescription,
  )(this.artistName);

  imageError = () => {
    const { image_url, id, name, navigation, onScreen = '' } = this.props;
    AnalyticsUtilty.fireImageErrorAnalytics(
      id,
      image_url,
      name,
      'artist',
      onScreen,
    );
  };

  openArtistMorePage = () => {
    navigateToScreen({
      navigation: this.props.navigation,
      type: 'push',
      screen: MORE_ITEMS,
      params: {
        slug: ARTIST_CONSTANTS.artistList,
      },
    });
  };

  artistCardEmptyState = (props) => {
    const { cardStyle, imageStyle } = props;
    return (
      <ScaleAnimate {...this.props} onPress={this.openArtistMorePage}>
        <View
          style={cardStyle}
        >
          <FastImageView
            source={images.artistCardPlaceholder}
            style={[
              imageStyle,
              {
                margin: 4,
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
              },
            ]}
            resizeMode="cover"
          />
          <Text style={ArtistCardStyles.artistname}>Start Following</Text>
          <FollowArtistButton disabled />
        </View>
      </ScaleAnimate>
    );
  };

  render() {
    const {
      itemData,
      listData,
      previousScreen,
      itemData: {
        layout,
        id,
        image_url,
        name,
        slug,
        artist_type,
        onPressFollowButton,
        onPressUnfollowButton,
      },
    } = this.props;

    let noOfColumns = 3;
    if (Utility.isPresent(listData) && previousScreen !== 'more_page') {
      noOfColumns = listData.columns || 3;
    }
    if (isDesktop()) {
      noOfColumns *= 2;
    }

    let viewWidth = Utility.getDynamicWidthForGrid(noOfColumns, 24);
    let viewHeight = Utility.getDynamicWidthForGrid(noOfColumns, 24) + 35;
    let imageWidth = Utility.getDynamicWidthForGrid(noOfColumns, 24);
    let imageHeight = Utility.getDynamicWidthForGrid(noOfColumns, 24);

    if (Utility.isBlank(image_url) || Utility.isBlank(name)) {
      return null;
    }

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <Card
          style={[styles.cardStyle, { height: viewHeight, width: viewWidth }]}
        >
          <this.ArtistAvatar
            imageUrl={image_url}
            imageStyle={{
              height: imageHeight,
              width: imageWidth,
              borderRadius: imageWidth / 2,
              backgroundColor: 'white',
            }}
          />
          <this.conditionalDescription
            layout={layout}
            name={name}
            artistType={artist_type}
            style={styles.artistName}
          >
            {name}
          </this.conditionalDescription>
          <FollowArtistButton
            id={id}
            layout={layout}
            slug={slug}
            name={name}
            onPressFollowButton={onPressFollowButton}
            onPressUnfollowButton={onPressUnfollowButton}
            previousScreen={previousScreen}
            itemData={itemData}
          />
        </Card>
      </ScaleAnimate>
    );
  }
}

export default withNavigation(DefaultSizeArtistGrid);

const { ids, styles } = StyleSheet.create({
  cardStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    margin: 4,
  },
  artistName: {
    fontSize: size.h4,
    color: colors.foxyBlack,
    fontWeight: 'normal',
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 8,
  },
});
