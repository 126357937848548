import React, { Component } from 'react';
import { View, TouchableOpacity, Image, Text, ScrollView } from 'react-native';
import { PaymentStyles } from './styles';
import images from '../../theme/Images';
import FullWidthDivider from '../../utils/FullWidthDivider';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { POPULAR_BANKS } from '../../config/Constants';
import _ from 'lodash';

class Netbanking extends Component {
  netbankingConstants = {
    headerText: 'Netbanking',
    popular: 'Popular Banks',
    allBanks: 'All Banks',
  };

  constructor(props) {
    super(props);
    this.memoizedCreateOrderCache = {};
  }

  goBack = () => {
    const { hidePaymentDetailsScreen } = this.props;
    hidePaymentDetailsScreen();
  };

  popularBank = (props) => {
    const styles = PaymentStyles;
    const { bank, netbankingOptions } = props;
    let disabled = false;
    let imageStyle = styles.popularBankImageWithoutTint;
    let containerStyle = styles.popularBank;
    if (
      Utility.isPresent(netbankingOptions) &&
      Utility.isPresent(netbankingOptions[bank.bankCode])
    ) {
      //remote config
      disabled = !!netbankingOptions[bank.bankCode].disabled;
      if (disabled) {
        containerStyle = styles.popularBankWithOpacity;
        imageStyle = styles.popularBankImageWithTint;
      }
    }
    return (
      <TouchableOpacity
        onPress={this.memoizedCreateOrder()(bank)}
        style={containerStyle}
        disabled={disabled}
      >
        <Image
          source={images.bankImage[bank.title.toLowerCase()]}
          style={imageStyle}
        />
        <Text style={styles.popularBankTitle}>{bank.title}</Text>
      </TouchableOpacity>
    );
  };

  bank = (props) => {
    const styles = PaymentStyles;
    const { bank, netbankingOptions } = props;
    let disabled = false;
    let containerStyle = styles.paymentOptionContainer;
    if (
      Utility.isPresent(netbankingOptions) &&
      Utility.isPresent(netbankingOptions[bank.bankCode])
    ) {
      //remote config
      disabled = !!netbankingOptions[bank.bankCode].disabled;
      if (disabled) {
        containerStyle = styles.paymentOptionContainerWithOpacity;
      }
    }
    return (
      <>
        <TouchableOpacity
          onPress={this.memoizedCreateOrder()(bank)}
          style={containerStyle}
          disabled={disabled}
        >
          <Text style={styles.allBankText}>{bank.title}</Text>
          <Image source={images.chevronRight} style={styles.rightArrow} />
        </TouchableOpacity>
        <View style={styles.fullWidthDivider} />
      </>
    );
  };

  memoizedCreateOrder = () => {
    const { createOrder } = this.props;
    return (bank) => {
      if (!this.memoizedCreateOrderCache[bank.bankCode]) {
        this.memoizedCreateOrderCache[bank.bankCode] = () => createOrder(bank);
      }
      return this.memoizedCreateOrderCache[bank.bankCode];
    };
  };

  render() {
    const styles = PaymentStyles;
    const { availableNetBanking, payWithNetBanking, enabledPaymentOptions } =
      this.props;
    const popularBanks = POPULAR_BANKS;
    return (
      <View style={styles.netBankingContainer}>
        <View style={styles.header}>
          <TouchableOpacity
            hitSlop={Utility.getHitSlop()}
            onPress={this.goBack}
          >
            <Image
              source={images.navigationWhiteBackIcon}
              style={styles.backArrow}
            />
          </TouchableOpacity>
          <Text style={styles.headerText}>
            {this.netbankingConstants.headerText}
          </Text>
        </View>
        <View style={styles.containerWithHorizontalMargin}>
          <FullWidthDivider margin={16} setDividerMargin={false} />
          <ScrollView style={styles.scrollViewWithBottomInset}>
            <View>
              <Text style={styles.netbankingHeader}>
                {this.netbankingConstants.popular}
              </Text>
              <View style={styles.popularBanksContainer}>
                {popularBanks.map((bank) => (
                  <this.popularBank
                    key={`${bank.bankCode}_${bank.title}`}
                    bank={bank}
                    payWithNetBanking={payWithNetBanking}
                    netbankingOptions={
                      Utility.isPresent(enabledPaymentOptions)
                        ? enabledPaymentOptions.netbanking
                        : {}
                    }
                  />
                ))}
              </View>
              <Text style={styles.netbankingHeader}>
                {this.netbankingConstants.allBanks}
              </Text>
              <View>
                {availableNetBanking.map((bank) => (
                  <this.bank
                    key={`${bank.bankCode}_${bank.title}`}
                    bank={bank}
                    payWithNetBanking={payWithNetBanking}
                    netbankingOptions={
                      Utility.isPresent(enabledPaymentOptions)
                        ? enabledPaymentOptions.netbanking
                        : {}
                    }
                  />
                ))}
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

export default withNavigation(Netbanking);
