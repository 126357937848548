import { put, takeLatest, select } from 'redux-saga/effects';
import {
  GET_MY_COLLECTION,
  SEND_SCAN_PRODUCT_DATA,
  SAVE_MY_KIT_DATA,
} from '../actions/ActionTypes';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

export function* getMyCollectionAsync(action) {
  const { callback } = action;
  const url = URL.MY_COLLECTION;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
      yield put({ type: SAVE_MY_KIT_DATA, data: json });
    } else {
      console.tron.log('error in fetching collection list');
      callback(false, {});
    }
  } catch (error) {
    console.tron.log('error in fetching collection', error);
    callback(false, {});
  }
}

function* sendScanProductDataAsync(action) {
  const { id, callback } = action;
  const url = `${URL.PRODUCT}${id}/scan.json`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken }),
    });
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

// Our watcher Saga:
export default function* watchFetchCollection() {
  yield takeLatest(GET_MY_COLLECTION, getMyCollectionAsync);
  yield takeLatest(SEND_SCAN_PRODUCT_DATA, sendScanProductDataAsync);
}
