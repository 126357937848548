import { NativeModules } from 'react-native';
import Utility from '../../utils/Utility';

const { PayuPayment, PayUIntentPayment } = NativeModules;

export default class RNPayuPayment {
  static setPaymentParams = (payParams) =>
    PayuPayment.setPaymentParams(payParams);

  static setPaymentParamsForIOSIntent = (payParams) =>
    PayUIntentPayment.setPaymentIntentParams(payParams);

  static setPayuParamsForIntent = (payParams) =>
    PayuPayment.setPayuParamsForIntent(payParams);

  static payWithCard = (cardParams, saveCard) =>
    PayuPayment.payWithCard(cardParams, saveCard);

  static payWithNetBanking = (netBankingParams) =>
    PayuPayment.payWithNetBanking(netBankingParams);

  static payWithSodexo = () => PayuPayment.payWithSodexo();

  static payWithPayUMoney = () => PayuPayment.payWithPayUMoney();

  static payWithSavedCard = (cardParams) =>
    PayuPayment.payWithSavedCard(cardParams);

  static deleteSavedCard = (cardParams) =>
    PayuPayment.deleteSavedCard(cardParams);

  static payWithUPI = (upiParams) => PayuPayment.payWithUPI(upiParams);

  static payWithIntent = (appPackage) => PayuPayment.payWithIntent(appPackage);
  
  static payWithIntentIOS = (appPackage) => PayUIntentPayment.payWithIntent(appPackage);

}
