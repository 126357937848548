import React, { Component } from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  Animated,
  Easing,
} from 'react-native';
import PropTypes from 'prop-types';
import { styles } from './styles';
import {
  DEFAULT_ANIMATION_TIME as animationTime,
  MEDIA_DETAIL_HEADER_BUTTON_WIDTH,
  MEDIA_DETAIL_INITIAL_OFFSET_X,
} from '../../config/Constants';
import images from '../../theme/Images';
import {
  EventType,
  AnalyticsManager,
  EventParameterKey,
} from '../../analytics';

class MediaDetailListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedListIndex: 0,
    };
    this.listObjects = [];

    this.animations = {
      selectedIndicator: {
        leftOffset: new Animated.Value(10),
      },
    };
  }

  ListButton = (props) => {
    const { listName, index } = props;
    return (
      <TouchableOpacity
        style={styles.contentListHeaderButtons}
        onPress={this.onListButtonTap(index)}
      >
        <Text style={styles.buttonTextStyle}>{listName}</Text>
      </TouchableOpacity>
    );
  };

  navigateToNextVideo = () => {
    const {
      videoMetadata,
      onNextButtonTapped,
      onNextButtonClick,
      navigation,
      route,
    } = this.props;
    const isHomePageAttributes = route.params?.homepageAttributes
      ? true
      : false;
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_CHANGE, {
      ...videoMetadata,
      [EventParameterKey.METHOD]: 'Next Button',
    });
    if (isHomePageAttributes) {
      onNextButtonClick();
    } else onNextButtonTapped();
  };

  upNext = () => {
    return (
      <TouchableOpacity
        style={styles.showMoreButton}
        onPress={this.navigateToNextVideo}
      ></TouchableOpacity>
    );
  };

  Separator = () => <View style={styles.separatorView} />;

  SelectedIndicator = () => (
    <Animated.View
      style={[
        styles.selectedIndicator,
        { left: this.animations.selectedIndicator.leftOffset },
      ]}
    />
  );

  onListButtonTap = (index) => () => {
    this.setState(
      { selectedListIndex: index },
      this.performIndicatorAnimationAndNotifyParent,
    );
  };

  onScrollEnd = (index) => {
    this.setState({ selectedListIndex: index }, this.animateSelectedIndicator);
  };

  performIndicatorAnimationAndNotifyParent = () => {
    const { selectedListIndex } = this.state;
    const { onListButtonTap } = this.props;
    this.animateSelectedIndicator();
    onListButtonTap(selectedListIndex)();
  };

  animateSelectedIndicator = () => {
    Animated.timing(this.animations.selectedIndicator.leftOffset, {
      toValue: this.getSelectedIndicatorLeftOffset(),
      duration: animationTime,
      easing: Easing.ease,
    }).start();
  };

  getSelectedIndicatorLeftOffset = () => {
    const { selectedListIndex: index } = this.state;
    const offset =
      index * MEDIA_DETAIL_HEADER_BUTTON_WIDTH + MEDIA_DETAIL_INITIAL_OFFSET_X;
    return offset;
  };

  renderListNames = () => {
    const { listNames } = this.props;
    this.listObjects = listNames.map((name, index) => (
      <this.ListButton listName={name} index={index} key={name} />
    ));
    return this.listObjects;
  };

  render() {
    return (
      <View style={styles.contentListHeaderContainer}>
        <this.renderListNames />
        <this.upNext />
        <this.Separator />
        <this.SelectedIndicator />
      </View>
    );
  }
}

MediaDetailListHeader.propTypes = {
  listNames: PropTypes.array,
  videoMetadata: PropTypes.shape({
    [EventParameterKey.VIDEO_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_TITLE]: PropTypes.string,
    [EventParameterKey.ARTIST_NAME]: PropTypes.string,
    [EventParameterKey.ARTIST_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_DURATION]: PropTypes.number,
    [EventParameterKey.VIDEO_TYPE]: PropTypes.string,
  }).isRequired,
  onListButtonTap: PropTypes.func,
  onNextButtonTapped: PropTypes.func,
};

MediaDetailListHeader.defaultProps = {
  listNames: [],
  onListButtonTap: () => {},
  onNextButtonTapped: () => {},
};

export default MediaDetailListHeader;
