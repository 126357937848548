import { ScrollView, View, Image, StyleSheet, Text } from 'react-native';
import React from 'react';
import colors from '../../theme/Colors';
import NavigationStacksUtility from '../../utils/NavigationStacksUtility';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import DownloadAppButtons from '../../components/webFooter/DownloadAppButtons';
import WebFooter from '../../components/webFooter/WebFooter';
import images from '../../theme/Images';
import { FOXY_URLS } from '../../config/Constants';

export default function Reviews() {
  return (
    <ScrollView style={styles.container}>
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <Image
            source={{ uri: images.reviews }}
            style={styles.image}
            resizeMode='cover'
          />
          <View style={styles.headingContainer}>
            <Text style={styles.heading}>Honest Reviews</Text>
            <Text style={styles.subHeading}>on products you liked.</Text>
          </View>
        </View>
        <Text style={styles.text}>
          Get the best advice, reviews, styles, looks and regimes from a large
          network of beauty experts & influencers
        </Text>
        <View style={styles.buttons}>
          <DownloadAppButtons width={137.7} height={37.4} link={FOXY_URLS.dynamicAppLink} />
        </View>
      </View>
      <WebFooter />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    // height: 'calc(100vh - 120px)',
    backgroundColor: '#f7f7f7',
  },
  image: {
    width: getScreenWidth(),
    height: getScreenHeight() * 0.62,
  },
  imageContainer: {
    position: 'relative',
  },
  headingContainer: {
    position: 'absolute',
    bottom: 0,
    right: 20,
    alignItems: 'flex-end',
  },
  heading: {
    fontSize: 24,
    fontFamily: 'Roboto-Regular',
    color: '#EB6768',
  },
  subHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#97A9BC',
  },
  text: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    textAlign: 'right',
    marginTop: 20,
    paddingHorizontal: 28,
  },
  buttons: {
    marginTop: 30,
    alignItems: 'center',
  },
});

export const ReviewsHeaderOption =
  NavigationStacksUtility.getListTabHeaderOptions({
    title: 'Reviews',
  });
