import { takeEvery, call } from 'redux-saga/effects';
import { URL } from '../config/Constants';
import { DELETE_SELFIE } from '../actions/ActionTypes';
import { getApiHeaders } from './GeneratorUtil';

const deleteRequest = (headers) => {
  const url = `${URL.DELETE_SELFIE}`;
  const postData = {
    delete_all_attributes: headers['x-auth-token'],
  };
  console.tron.log('DELETE_USER_BODY', postData);
  const response = fetch(url, {
    method: 'DELETE',
    headers,
    body: JSON.stringify(postData),
  });
  return response;
};

function* deleteSelfie(action) {
  // console.tron.log('DELETE_USER_ACTION'), action;
  const { callback, deleteAttributes } = action;
  try {
    const headers = yield getApiHeaders();

    const url = `${URL.DELETE_SELFIE}`;
    const postData = {
      delete_all_attributes: deleteAttributes,
    };
    const response = yield fetch(url, {
      method: 'DELETE',
      headers,
      body: JSON.stringify(postData),
    });
    console.tron.log('DELETE_SELFIE_RESPONSE', response.status);
    if (response.status >= 200 && response.status < 300) {
      callback(true, {});
    } else {
      callback(false, {});
      // console.tron.log(`Error Message: ${JSON.stringify(responseJson)}`);
    }
  } catch (error) {
    callback(false, error);
    console.tron.log(`Error in getting response ${error}`);
  }
}

export default function* watchDeleteSelfieSaga() {
  yield takeEvery(DELETE_SELFIE, deleteSelfie);
}
