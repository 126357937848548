import React, { PureComponent } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { camelCase } from 'lodash';
import { isBlank, isPresent } from '../../../utils/BooleanUtility';
import ImageCarousel from '../ImageCarousel';
import RichContentHTMLView from './RichContentHTMLView';
import withNavigation from '../../../utils/WithNavigation';
import colors from '../../../theme/Colors';

class EmbeddedRichContent extends PureComponent {
  EmbeddedContentLayoutComponent = {
    images: ImageCarousel,
    singleLineText: RichContentHTMLView,
    multiLineText: RichContentHTMLView,
  };

  headerComponent = ({ item }) => {
    const { title = '', subtitle = '' } = item;
    if (isBlank(title) && isBlank(subtitle)) return null;
    return (
      <View style={styles.header}>
        {isPresent(title) && <Text style={styles.title}>{title}</Text>}
        {isPresent(subtitle) && <Text style={styles.subtitle}>{subtitle}</Text>}
      </View>
    );
  };

  renderEmbeddedRichContent = (item = {}, index) => {
    const {
      display_type = '',
      contents = [],
      title = '',
      subtitle = '',
    } = item;
    const LayoutComponent =
      this.EmbeddedContentLayoutComponent[`${camelCase(display_type)}`];
    const { isExpanded = true } = this.props;
    if (LayoutComponent === undefined || !isExpanded) return null;
    return (
      <View style={styles.container}>
        <this.headerComponent item={item} />
        <LayoutComponent
          contents={contents}
          title={title}
          itemData={item}
          isExpanded={isExpanded}
        />
      </View>
    );
  };

  render() {
    const { itemData: { contents = [] } = {} } = this.props;
    if (isBlank(contents)) return null;
    return contents.map(this.renderEmbeddedRichContent);
  }
}

export default withNavigation(EmbeddedRichContent);

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    paddingHorizontal: 12,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.grey,
    paddingHorizontal: 12,
  },
  header: {
    marginBottom: 12,
  },
  container: {
    marginTop: 12,
  },
});
