import { View, StyleSheet } from 'react-native';
import React from 'react';
import { getScreenWidth } from '../../utils/LayoutUtility';
import Feature from '../../containers/feature/Feature';

export default function OfferPair(props) {
  const { itemData = {}, ...restProps } = props;
  const { data = [] } = itemData;
  const [firstItem, secondItem] = data;
  return (
    <View style={styles.container}>
      <Feature {...restProps} itemData={firstItem} isPair />
      <Feature {...restProps} itemData={secondItem} isPair />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row', width: getScreenWidth(),
  },
});
