import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

const styles = StyleSheet.create({
  placeholderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
  },
  placeholderHeader: {
    fontSize: 18,
    marginTop: 8,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
  },
  placeholderImage: {
    width: 300,
    height: 120,
    resizeMode: 'contain',
  },
  placeholderText: {
    fontSize: 14,
    marginTop: 12,
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    maxWidth: '90%',
    textAlign: 'center',
    marginBottom: 8,
  },
  placeholderButton: {
    height: 40,
    width: 180,
    backgroundColor: colors.green,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
  },
  placeholderButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.white,
  },
  placeholderImageContainer: {
    margin: 12,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
  },
  scanBanner: {
    height: 72,
    width: Utility.getScreenWidth() - 24,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFCFD1',
    margin: 12,
    marginTop: 12,
    borderRadius: 4,
    marginBottom: 6,
  },
  scanBannerTextContainer: {
    width: '70%',
  },
  scanBannerTitle: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h3,
    marginLeft: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  rightArrow: {
    height: 10,
    width: 10,
    marginTop: 2,
    marginLeft: 4,
    tintColor: colors.foxyPink,
    resizeMode: 'cover',
  },
  scanBannerSubTitle: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,

    marginLeft: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginTop: 2,
  },
  scanBannerImage: {
    position: 'absolute',
    right: 0,
    bottom: 6,
    width: 70,
    height: 60,
    resizeMode: 'contain',
  },
  dynamicListShimmerForGrid: {
    paddingTop: 12,
  },

});

export default styles;
