export const FETCH_MEDIA_DETAIL = 'FETCH_MEDIA_DETAIL';
export const MEDIA_DETAIL_RECEIVED = 'MEDIA_DETAIL_RECEIVED';
export const FETCH_MEDIA_DETAIL_FAILED = 'FETCH_MEDIA_DETAIL_FAILED';
export const UPDATE_MEDIA_DATA = 'UPDATE_MEDIA_DATA';
export const UPDATE_MEDIA_ID = 'UPDATE_MEDIA_ID';
export const TOGGLE_FOLLOW_ARTIST = 'TOGGLE_FOLLOW_ARTIST';
export const TOGGLE_FAVORITE_CONTENT = 'TOGGLE_FAVORITE_CONTENT';
export const FAVORITE_CONTENT_FAILED = 'FAVORITE_CONTENT_FAILED';

export function updateMediaData(data) {
  return {
    type: UPDATE_MEDIA_DATA,
    data,
  };
}

export function updateMediaId(data) {
  return {
    type: UPDATE_MEDIA_ID,
    ...data,
  };
}

export function toggleFollowArtist(data) {
  return {
    type: TOGGLE_FOLLOW_ARTIST,
    data,
  };
}

export function toggleFavoriteForContent(data) {
  return {
    type: TOGGLE_FAVORITE_CONTENT,
    data,
  };
}

export function favoriteContentFailed(data) {
  return {
    type: FAVORITE_CONTENT_FAILED,
    data,
  };
}
