import { takeLatest } from 'redux-saga/effects';
import { API_DOMAIN } from '../config/Constants';
import { PAGINATED_LIST_CALLBACK } from '../actions/ActionTypes';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import Utility from '../utils/Utility';

function* getPaginatedApiCallback(action) {
  const {
    slug,
    pageNumber,
    callback,
    appliedFilterCounter,
    use_modified_product_list,
    hideOosProduct,
  } = action;
  let url = `${API_DOMAIN}${slug}`;

  if (url.indexOf('?') === -1) {
    url = `${url}?page=${pageNumber}`;
  } else {
    url = `${url}&page=${pageNumber}`;
  }
  if (use_modified_product_list) {
    url = `${url}&use_modified_product_list=${use_modified_product_list}`;
  }
  if (hideOosProduct) {
    url = `${url}&hide_oos=${hideOosProduct}`;
  }

  try {
    let sendCallbackData = true;
    const timer = setTimeout(() => {
      sendCallbackData = false;
      callback(false, { errorType: 'api_failure' });
    }, parseInt(Utility.getTimeOutValue()));

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 400 && sendCallbackData) {
      clearTimeout(timer);
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      if (json.objects.length === 0) {
        callback(false, {}, appliedFilterCounter);
      }
      callback(true, json, appliedFilterCounter);
    } else {
      callback(false, {}, appliedFilterCounter);
    }
  } catch (error) {
    callback(false, { errorType: '' });
  }
}

export default function* watchPaginatedCallbackSaga() {
  yield takeLatest(PAGINATED_LIST_CALLBACK, getPaginatedApiCallback);
}
