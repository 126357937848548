import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { CURRENCY_SYMBOL } from '../../../config/Constants';
import colors from '../../../theme/Colors';

export default function GrandTotal({ totalAmount }) {
  return (
    <View style={styles.grandTotalContainer}>
      <Text style={styles.grandtotalText} numberOfLines={1}>
        Grand Total
      </Text>

      <Text style={styles.totalAmount}>{`${CURRENCY_SYMBOL}${totalAmount}`}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  grandTotalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grandtotalText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    width: '80%',
  },
  totalAmount: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
});
