// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { bindActionCreators } from 'redux';
import { featureSchema } from '../../config/Schema';
import { dismissFeatureCard } from '../../actions/ActionTypes';

// Components
import FeatureCard from '../../components/feature/FeatureCard';
import { withMaybe } from '../../lib/Monads';
import Utility from '../../utils/Utility';

class Feature extends Component {
  static getComponentHeight(item) {
    return FeatureCard.getComponentHeight(item);
  }

  itemDataNullFn = (props) => !props.itemData;

  FeatureCardWithCondition = withMaybe(this.itemDataNullFn)(FeatureCard);

  onDismiss = (id) => {
    this.props.dismissFeatureCard();
  };

  render() {
    return (
      <this.FeatureCardWithCondition
        {...this.props}
        onDismiss={this.onDismiss}
      />
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    itemData: Utility.isBlank(ownProps.itemData)
      ? denormalize(
          store.data.entities.features[ownProps.id],
          featureSchema,
          store.data.entities,
        )
      : ownProps.itemData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ dismissFeatureCard }, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Feature);
