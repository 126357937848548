import { View } from 'react-native';
import React from 'react';
import StyleSheet from 'react-native-media-query';
import { isDesktop, isWebMobile } from '../../utils/BooleanUtility';
import { StaticNavigationHeader } from '../../components/shared';
import colors from '../../theme/Colors';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { getScreenWidth } from '../../utils/LayoutUtility';

export default function ShopOnlineListPlaceholder() {
  const arrayViews = new Array(4).fill(1);
  return (
    <View style={styles.container}>
      {isWebMobile() && <StaticNavigationHeader showCart showSearchButton showLogo />}
      <View style={styles.title} dataSet={{ media: ids.title }} />
      {arrayViews.map((element, index) => (
        <View style={styles.row} key={index}>
          <ProductContainer />
          {isDesktop() && <ProductContainer />}
        </View>
      ))}
    </View>
  );
}

const ProductContainer = () => (
  <View style={styles.cardContainer} dataSet={{ media: ids.cardContainer }}>
    <ShimmerPlaceHolder
      autoRun
      style={styles.cardImageContainer}
      colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
    />
    <ShimmerPlaceHolder
      autoRun
      style={styles.containerBrandName}
      colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
    />
    <ShimmerPlaceHolder
      autoRun
      style={styles.containerProductName}
      colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
    />
    <ShimmerPlaceHolder
      autoRun
      style={styles.containerProductNameSecondLine}
      colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
    />
  </View>
);

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: getScreenWidth() - 24,
    height: 143,
    alignSelf: 'center',
    '@media (min-width: 768px)': {
      width: 480,
    },
  },

  cardImageContainer: {
    position: 'absolute',
    height: 90,
    top: 20,
    left: 15,
    width: 90,
    borderRadius: 8,
  },

  containerBrandName: {
    position: 'absolute',
    left: 115,
    top: 30,
    width: 80,
    height: 12,
    borderRadius: 4,
  },
  containerProductName: {
    position: 'absolute',
    left: 115,
    top: 50,
    width: 200,
    height: 12,
    borderRadius: 4,
  },
  containerProductNameSecondLine: {
    position: 'absolute',
    left: 115,
    top: 70,
    width: 200,
    height: 12,
    borderRadius: 4,
  },
  containerPrice: {
    position: 'absolute',
    left: 15,
    bottom: 90,
    width: 60,
    height: 10,
    borderRadius: 4,
  },

  row: {
    flexDirection: 'row',
    paddingLeft: 8,
    paddingRight: 8,
    justifyContent: 'center',
  },
  title: {
    width: getScreenWidth(),
    height: 40,
    marginBottom: 10,
    backgroundColor: colors.white,
    '@media (min-width: 768px)': {
      marginTop: 10,
      marginHorizontal: 10,
      width: getScreenWidth() - 20,
    },
  },
});
