import React, { Component } from 'react';
import { Text, View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import FastImageView from '../../FastImageView';
import { isDesktop } from '../../../utils/BooleanUtility';

// eslint-disable-next-line react/prefer-stateless-function
export default class RateProductActionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      showLoader: false,
      showRatingDescription: false,
      ratingDescription: '',
    };
    const { backgroundColor = '#fff', style } = this.props;

    this.containerStyle = [
      styles.container,
      style,
      { backgroundColor },
    ];
  }

  render() {
    const {
      backgroundColor = '#fff',
      imageUrl,
      heading,
      subheading,
      firstActionButtonText,
      style,
      buttonWidth = Utility.getScreenWidth() / (isDesktop() ? 2 : 1) - 52,
      secondaryActionButtonText,
      firstActionIcon,
      secondActionIcon,
      primaryButtonAction,
      secondaryButtonAction,
      slug,
      noOfLines = 2,
    } = this.props;

    return (
      <View style={this.containerStyle} dataSet={{ media: ids.container }}>
        <FastImageView
          resizeMode='contain'
          source={imageUrl}
          style={styles.image}
        />
        {Utility.isPresent(heading) && (
          <Text style={styles.heading_2} numberOfLines={2} ellipsizeMode='tail'>
            {heading}
          </Text>
        )}
        <Text
          style={styles.subheading_2}
          numberOfLines={noOfLines}
          ellipsizeMode='tail'
        >
          {subheading}
        </Text>
        {Utility.isPresent(firstActionButtonText) && (
          <FoxyShadowButton
            width={buttonWidth}
            title={firstActionButtonText}
            onPress={() => primaryButtonAction(slug)}
            backgroundColor={colors.primaryActionBackgroundColor}
            style={styles.primaryButtonContainer}
            textStyle={styles.primaryButtonText}
            firstColor={colors.black}
            secondColor={colors.black}
            underlayColor={colors.black}
            actionIcon={firstActionIcon}
          />
        )}
        {Utility.isPresent(secondaryActionButtonText) && (
          <FoxyShadowButton
            width={buttonWidth}
            title={secondaryActionButtonText}
            onPress={secondaryButtonAction}
            backgroundColor={colors.primaryActionBackgroundColor}
            style={styles.primaryButtonContainer}
            firstColor='transparent'
            secondColor='transparent'
            underlayColor='transparent'
            textStyle={styles.secondaryButtonText}
            actionIcon={secondActionIcon}
            border={styles.border}
          />
        )}
      </View>
    );
  }
}

const { ids, styles } = StyleSheet.create({
  largeActionCard: {
    height: 100,
    width: 100,
    marginRight: 8,
  },
  smallActionCard: {
    height: 62,
    width: 68,
    marginRight: 8,
  },
  headingSubheadingContainer: { flexDirection: 'column', flexShrink: 1 },
  heading: { fontSize: 18, fontFamily: 'Roboto-Bold', color: '#404E9F' },
  subheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
  },
  image: {
    height: 72,
    width: 72,
    marginBottom: 12,
    borderRadius: 36,
    backgroundColor: colors.white,
  },
  heading_2: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: '#404E9F',
    lineHeight: 21.09,
    marginBottom: 4,
    textAlign: 'center',
  },
  subheading_2: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    lineHeight: 16.5,
    marginBottom: 4,
    textAlign: 'center',
  },
  primaryButtonContainer: { marginTop: 12 },
  primaryButtonText: { marginLeft: 4 },
  secondaryButtonContainer: {
    marginTop: 12,
    alignItems: 'center',
    width: Utility.getScreenWidth() - 24 - 12,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2 - 24 - 12,
    },
  },
  secondaryButtonText: { color: colors.secondActionBorderColor, marginLeft: 4 },
  border: { borderColor: colors.secondActionBorderColor, borderWidth: 1 },
  endStateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: Utility.getScreenWidth() - 24,
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginHorizontal: 12,
    borderRadius: 8,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2 - 24,
    },
  },
  container: {
    width: Utility.getScreenWidth() - 24,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
    alignSelf: 'center',
    borderRadius: 16,
    marginTop: 20,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2 - 24,
    },
  },
});
