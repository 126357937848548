import { View, StyleSheet } from 'react-native';
import React from 'react';
import { useRoute } from '@react-navigation/native';
import { isDesktop } from '../../utils/BooleanUtility';
import MoreItemsList from '../../containers/feed/MoreItemsList';

export default function ProductLists() {
  const { params = {} } = useRoute();
  const { name, listType } = params;
  return (
    <View style={styles.container}>
      <MoreItemsList slug={`/api/v2/products/${name}/${listType}.json?`} hideHeader={isDesktop()} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
