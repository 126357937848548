// Dependencies
import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';

class OrderDetailShimmer extends Component {
  constructor(props) {
    super(props);
    this.shimmerColors = ['#f7F7f7', '#eeeeee', '#f7F7f7'];
  }

  offerPlaceholder = () => {
    return (
      <View style={styles.offerPlaceholderContainer}>
        <View style={styles.firstInnerContainer}>
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_5}
          />
          <View style={styles.innerOfferContainer}>
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.shimmer_6}
            />
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.shimmer_7}
            />
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.shimmer_8}
            />
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.shimmer_9}
            />
          </View>

          <this.divider />
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_10}
          />
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_11}
          />
          <View style={styles.arrayContainer}>
            {_.map([1, 2, 3], (e) => {
              return (
                <ShimmerPlaceHolder
                  colorShimmer={this.shimmerColors}
                  autoRun
                  style={styles.shimmer_12}
                />
              );
            })}
          </View>
          <this.divider />
          <View style={styles.secondInnerContainer}>
            <View style={styles.headingSubheading}>
              <ShimmerPlaceHolder
                colorShimmer={this.shimmerColors}
                autoRun
                style={styles.shimmer_13}
              />
              <ShimmerPlaceHolder
                colorShimmer={this.shimmerColors}
                autoRun
                style={styles.shimmer_14}
              />
            </View>

            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.shimmer_15}
            />
          </View>
        </View>
      </View>
    );
  };

  divider = () => {
    return <View style={styles.divider} />;
  };

  paymentDetails = () => {
    return (
      <View style={styles.paymentDetailsContainer}>
        <View style={styles.paymentDetailsInnerContainer}>
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_17}
          />
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_18}
          />
        </View>
        <View style={styles.paymentDetailsInnerSecondContainer}>
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_19}
          />
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_20}
          />
        </View>
      </View>
    );
  };

  largeActionCard = () => {
    return (
      <View style={styles.largeActionCardContainer}>
        <View style={styles.innerContainer}>
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_1}
          />
        </View>
        <ShimmerPlaceHolder
          colorShimmer={this.shimmerColors}
          autoRun
          style={styles.shimmer_2}
        />
        <ShimmerPlaceHolder
          colorShimmer={this.shimmerColors}
          autoRun
          style={styles.shimmer_3}
        />
        <ShimmerPlaceHolder
          colorShimmer={this.shimmerColors}
          autoRun
          style={styles.shimmer_4}
        />
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.shimmerContainer}>
          <this.paymentDetails />
          <this.largeActionCard />
          <this.offerPlaceholder />
          <this.largeActionCard />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  shimmerContainer: {
    backgroundColor: colors.background,
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },
  largeActionCardContainer: {
    backgroundColor: '#F8FBFF',
    height: 275,
    width: Utility.getScreenWidth() - 24,
    borderRadius: 8,
    alignItems: 'center',
    paddingHorizontal: 12,
    alignSelf: 'center',
  },
  innerContainer: { marginTop: 20, marginBottom: 12 },
  shimmer_1: {
    height: 120,
    width: 227,
    borderRadius: 4,
    marginBottom: 12,
    marginLeft: 12,
  },
  shimmer_2: {
    height: 16,
    width: Utility.getScreenWidth() / 4.5,
    borderRadius: 4,
    marginBottom: 12,
    marginLeft: 12,
  },
  shimmer_3: {
    height: 16,
    width: Utility.getScreenWidth() / 2,
    borderRadius: 4,
    marginBottom: 12,
    marginLeft: 12,
  },
  shimmer_4: {
    height: 40,
    width: Utility.getScreenWidth() - 48,
    borderRadius: 4,
    marginBottom: 12,
    marginLeft: 12,
  },
  offerPlaceholderContainer: {
    flexDirection: 'row',
    marginVertical: 12,
    marginHorizontal: 12,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
    backgroundColor: colors.white,
  },
  firstInnerContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    paddingBottom: 12,
  },
  shimmer_5: {
    height: 4,
    width: Utility.getScreenWidth() / 1.4,
    borderRadius: 4,
    marginVertical: 12,
    marginLeft: 12,
    alignSelf: 'center',
    marginTop: 22,
  },
  shimmer_6: {
    height: 16,
    width: 45,
    borderRadius: 4,
    marginBottom: 12,
    marginLeft: 12,
    alignSelf: 'center',
    marginRight: 12,
  },
  shimmer_7: {
    height: 16,
    width: 52,
    borderRadius: 4,
    marginBottom: 12,
    marginLeft: 12,
    alignSelf: 'center',
    marginRight: 12,
  },
  shimmer_8: {
    height: 16,
    width: 39,
    borderRadius: 4,
    marginBottom: 12,
    marginLeft: 12,
    alignSelf: 'center',
    marginRight: 12,
  },
  shimmer_9: {
    height: 16,
    width: 65,
    borderRadius: 4,
    marginBottom: 12,
    marginLeft: 12,
    alignSelf: 'center',
    marginRight: 12,
  },
  shimmer_10: {
    height: 16,
    width: Utility.getScreenWidth() / 4,
    borderRadius: 4,
    marginTop: 22,
    marginLeft: 12,
  },
  shimmer_11: {
    height: 16,
    width: Utility.getScreenWidth() / 2,
    borderRadius: 4,
    marginTop: 4,
    marginLeft: 12,
  },
  shimmer_12: {
    height: 36,
    width: 36,
    borderRadius: 24,
    marginRight: 4,
    marginVertical: 12,
    marginLeft: 12,
  },
  shimmer_13: {
    height: 36,
    width: 36,
    borderRadius: 24,
    marginRight: 4,
    marginVertical: 12,
    marginLeft: 12,
  },
  shimmer_14: {
    height: 36,
    width: 36,
    borderRadius: 24,
    marginRight: 4,
    marginVertical: 12,
    marginLeft: 12,
  },
  shimmer_15: {
    height: 36,
    width: 36,
    borderRadius: 24,
    marginRight: 4,
    marginVertical: 12,
    marginLeft: 12,
  },
  shimmer_16: {
    height: 36,
    width: 36,
    borderRadius: 24,
    marginRight: 4,
    marginVertical: 12,
    marginLeft: 12,
  },
  innerOfferContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: 12,
    marginBottom: 16,
  },
  arrayContainer: { flexDirection: 'row', alignItems: 'center' },
  secondInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 12,
  },
  headingSubheading: { flexDirection: 'row', alignItems: 'center' },
  divider: {
    height: 1,
    backgroundColor: colors.background,
    width: Utility.getScreenWidth(),
  },
  paymentDetailsContainer: {
    flexDirection: 'column',
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    marginTop: 12,
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  paymentDetailsInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  shimmer_17: {
    height: 16,
    width: Utility.getScreenWidth() / 3,
    borderRadius: 4,
    marginTop: 12,
  },
  shimmer_18: {
    height: 16,
    width: Utility.getScreenWidth() / 4,
    borderRadius: 4,
    marginTop: 12,
  },
  shimmer_19: {
    height: 16,
    width: Utility.getScreenWidth() / 5,
    borderRadius: 4,
    marginBottom: 12,
  },
  shimmer_20: {
    height: 16,
    width: Utility.getScreenWidth() / 4.5,
    borderRadius: 4,
    marginBottom: 12,
  },
  paymentDetailsInnerSecondContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
  },
});

export default OrderDetailShimmer;
