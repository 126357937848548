import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isPresent } from '../../utils/BooleanUtility';
import Utility from '../../utils/Utility';

const useActivatedOfferId = (data, currentSku = {}) => {
  const [offerId, setOfferId] = useState('');
  const [promptMessages, setPromptMessages] = useState({});
  const couponCodes =
    useSelector((state) => state?.bag?.cartPricing?.couponCodes) || [];

  useEffect(() => {
    const { prompts } = currentSku;

    if (isPresent(data || prompts)) {
      const { offer_id } = Utility.getEsp(
        isPresent(data) ? data.prompts : prompts,
        couponCodes,
      );

      setOfferId(offer_id);
      setPromptMessages(isPresent(data) ? data.prompts : prompts);
    }
  }, [data, currentSku]);

  return {
    offer_id: offerId,
    promptMessages,
    couponCodes,
  };
};

export default useActivatedOfferId;
