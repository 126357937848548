// Dependencies
import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';

import _ from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import Utility from '../../utils/Utility';

import colors from '../../theme/Colors';

class OfferShimmer extends PureComponent {
  shimmerColors = ['#eeeeee', '#ddddddff', '#eeeeee'];

  innerElementsShimmerColors = ['#f7F7f7', '#eeeeee', '#f7F7f7'];

  offerPlaceholderLarge = () => {
    const { large } = this.props;
    return (
      <View style={{ flexDirection: 'row' }}>
        {_.map([1, 2], (e) => {
          return (
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.offerPlaceHolderLarge}
            />
          );
        })}
      </View>
    );
  };

  offerPlaceholder = () => {
    const { large, horizontal } = this.props;
    const shimmerElements = horizontal ? [1, 2] : [1];
    const firstElementWidth = horizontal ? 280 : '100%';
    return (
      <View style={{ flexDirection: 'row' }}>
        {_.map(shimmerElements, (e) => {
          return (
            <View
              style={[
                styles.smallOfferPlaceholderContainer,
                { width: firstElementWidth },
              ]}
            >
              <ShimmerPlaceHolder
                colorShimmer={this.innerElementsShimmerColors}
                autoRun
                style={styles.offerTitleShimmer}
              />
              <ShimmerPlaceHolder
                colorShimmer={this.innerElementsShimmerColors}
                autoRun
                style={styles.offerSubtitle}
              />
              <ShimmerPlaceHolder
                colorShimmer={this.innerElementsShimmerColors}
                autoRun
                style={styles.offerSubtitleLine2}
              />
              <View style={styles.offerCouponCodeRow}>
                <ShimmerPlaceHolder
                  colorShimmer={this.innerElementsShimmerColors}
                  autoRun
                  style={styles.offerCouponCodeContainer}
                />
                <ShimmerPlaceHolder
                  colorShimmer={this.innerElementsShimmerColors}
                  autoRun
                  style={styles.offerCouponCta}
                />
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  titleShimmer = () => (
    <View>
      <ShimmerPlaceHolder
        colorShimmer={this.shimmerColors}
        autoRun
        style={styles.titleShimmer}
      />
      <ShimmerPlaceHolder
        colorShimmer={this.shimmerColors}
        autoRun
        style={styles.subtitleShimmer}
      />
    </View>
  );

  render() {
    const { large, horizontal } = this.props;
    return (
      <View style={styles.shimmerContainer}>
        {horizontal && <this.titleShimmer />}
        {!large ? <this.offerPlaceholder /> : <this.offerPlaceholderLarge />}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  offerTitleShimmer: {
    height: 18,
    width: 74,
    borderRadius: 4,
    marginTop: 12,
  },

  offerSubtitle: {
    height: 12,
    width: 248,
    borderRadius: 4,
    marginTop: 12,
  },

  offerSubtitleLine2: {
    height: 12,
    width: 124,
    borderRadius: 4,
    marginTop: 4,
  },

  offerCouponCodeRow: {
    marginTop: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  offerCouponCodeContainer: {
    height: 20,
    width: 72,
    borderRadius: 4,
    marginTop: 4,
  },
  offerCouponCta: {
    height: 24,
    width: 92,
    borderRadius: 8,
    marginTop: 4,
  },
  titleShimmer: {
    height: 20,
    width: 80,
    borderRadius: 8,
    marginTop: 32,
  },
  subtitleShimmer: {
    height: 20,
    width: 140,
    borderRadius: 8,
    marginTop: 4,
    marginBottom: 8,
  },
  shimmerContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // flex: 1
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.background,
    marginBottom: 16,
  },

  offerPlaceHolderLarge: {
    height: (1 / 3) * (Utility.getScreenWidth() - 24) + 42,
    width: 280,
    borderRadius: 8,
    marginTop: 12,
    marginRight: 16,
  },

  smallOfferPlaceholderContainer: {
    height: 128,

    backgroundColor: colors.white,
    borderRadius: 3,
    marginRight: 12,
    paddingHorizontal: 16,
  },
});

export default OfferShimmer;
