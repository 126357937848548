import React from 'react';
import { Text, StyleSheet, Image, ActivityIndicator } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import images from '../../../theme/Images';
import DebouncedTouchableOpacity from '../../shared/DebouncedTouchableOpacity';
import { isDesktop } from '../../../utils/BooleanUtility';

export default function RoundedButton(props) {
  const {
    buttonColor,
    buttonTextColor,
    buttonText,
    buttonAction,
    style = {},
    disabled = false,
    buttonTextStyle,
    renderProp,
    showChevron = false,
    chevronDown = false,
    chevronStyle = {},
    visible = true,
    loading,
    minWidth = 80,
    disabledButtonColor = colors.disabled,
    loadingIndicatorColor = colors.white,
    dataSet,
  } = props;

  const buttonStyle = [
    styles.buttonText,
    buttonTextStyle,
    { color: buttonTextColor },
  ];

  const containerStyle = [
    styles.buttonDefaultStyle,
    style,
    {
      minWidth,
      backgroundColor: disabled ? disabledButtonColor : buttonColor,
    }
  ];

  const chevronStyles = [styles.chevron, chevronStyle ];

  if (!visible) return null;

  return (
    <DebouncedTouchableOpacity
      {...props}
      onPress={buttonAction}
      style={containerStyle}
      hitSlop={Utility.getHitSlop('cross')}
      disabled={disabled}
      dataSet={dataSet}
    >
      {renderProp && !loading && renderProp()}

      {loading ? (
        <ActivityIndicator size='small' color={loadingIndicatorColor} />
      ) : (
        <Text style={buttonStyle} allowFontScaling={false}>
          {buttonText}
        </Text>
      )}
      {showChevron && (
        <Image source={images.chevronRight} style={chevronStyles} />
      )}

      {chevronDown && (
        <Image source={images.chevronDown} style={styles.chevron} />
      )}
    </DebouncedTouchableOpacity>
  );
}

//RoundedButton.propTypes = {
//  buttonColor: PropTypes.string,
//  buttonText: PropTypes.string,
//  buttonAction: PropTypes.func,
//  buttonTextColor: PropTypes.string,
//};

RoundedButton.defaultProps = {
  buttonColor: colors.green,
  buttonText: '',
  buttonTextColor: '',
  buttonAction: () => {},
  style: {},
};

const styles = StyleSheet.create({
  buttonDefaultStyle: {
    backgroundColor: colors.green,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    flexDirection: 'row',
  },
  buttonText: {
    fontSize: isDesktop() ? 12 : 10,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },
  chevron: { width: 5, height: 12, tintColor: colors.white, marginLeft: 6 },
});
