// Dependencies
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { listSchema } from '../../config/Schema';
import { withMaybe, withEither } from '../../lib/Monads';

// Components
import * as ActionTypes from '../../actions/ActionTypes';
import CollectionCard from '../../components/Collection';
import ColouredCollectionCard from '../../components/Collection/ColouredCollectionCard';
import PersonalisedCollectionCard from '../../components/Collection/PersonalisedCollectionCard';
import Utility from '../../utils/Utility';
import { compose } from 'recompose';

class Collection extends Component {
  static getComponentHeight(item) {
    return Utility.getDynamicWidthForGrid(2, 16);
  }

  itemDataNullFn = (props) => !props.itemData;

  transparentImageNullFn = (props) =>
    props.itemData &&
    !props.itemData.image_url &&
    props.itemData.transparent_image_url;

  CollectionCardWithCondition = withMaybe(this.itemDataNullFn)(CollectionCard);

  PersonalisedCollectionCardWithCondition = withMaybe(this.itemDataNullFn)(
    PersonalisedCollectionCard,
  );

  ColouredCardsWithCondition = compose(
    withEither(this.transparentImageNullFn, ColouredCollectionCard),
  )(this.CollectionCardWithCondition);

  render() {
    const { itemData, personalisedGrid, fromPersonalisedCard } = this.props;

    return personalisedGrid ? (
      <this.PersonalisedCollectionCardWithCondition
        {...this.props}
        itemData={itemData}
        fromPersonalisedCard={fromPersonalisedCard ? true : false}
      />
    ) : (
      <this.ColouredCardsWithCondition {...this.props} itemData={itemData} />
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  let itemData = denormalize(
    store.data.entities.lists[ownProps.id],
    listSchema,
    store.data.entities,
  );
  if (Utility.isBlank(itemData)) {
    itemData = ownProps.itemData;
  }
  return {
    itemData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionTypes, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
