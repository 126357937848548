import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import ProductGroups from './ProductGroups';
import ProductRatingAndMoreInfo from './ProductRatingAndMoreInfo';
import ProductPrice from './ProductPrice';
import { ProductDetailStyles, productDetailIds } from './styles';
import ProductImageWithDescriptionCard from './ProductImageWithDescriptionCard';
import ProductHeroDescription from './ProductHeroDescription';
import RichContent from './RichContent/RichContent';
import { isDesktop } from '../../utils/BooleanUtility';
import FoxyPromises from './FoxyPromises';
import Config from '../../libraries/ReactNativeConfig';

export default function ProductDetailTopSectionSummary(props) {
  const navigation = useNavigation();

  const { data, isLoading, scrollToRatingSection, productPage } = props;

  const {
    name = '',
    id = '',
    hero_description: heroDescription = '',
    mrp = '',
    stocked_status: stockedStatus,
    rating,
    sku_id: skuId,
    brand,
    rich_content = {},
  } = data;

  if (stockedStatus !== 'in_stock') {
    return (
      <ProductImageWithDescriptionCard
        itemData={data}
        navigation={navigation}
      />
    );
  }

  const pattern = `${brand?.name} ` || '';
  const rep = new RegExp(pattern, 'gi');
  const productName = name?.replace(rep, '');

  return (
    <View style={styles.container}>
      <View style={ProductDetailStyles.productDescriptionContainerStyle} dataSet={{ media: productDetailIds.productDescriptionContainerStyle }}>
        <Text style={ProductDetailStyles.productNameStyle}>{productName}</Text>
      </View>
      <ProductHeroDescription
        hero_description={heroDescription}
        isLoading={isLoading}
      />
      <ProductPrice mrp={mrp} sp={mrp} discount={0} />

      <ProductRatingAndMoreInfo
        onRatingTap={scrollToRatingSection}
        rating={rating}
        id={id}
        sku_id={skuId}
        data={data}
        show
      />
      <RichContent itemData={rich_content?.post_ratings} />

      {isDesktop() && Config.APP_NAME === 'Foxy' && (
        <FoxyPromises
          productPage={productPage}
          navigation={navigation}
          product_id={id}
          brand={brand}
        />
      )}
      <ProductGroups itemData={data} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: { width: '100%' },
});
