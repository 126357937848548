import Config from '../libraries/ReactNativeConfig';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_PUBLIC_ID_FOR_GUEST,
  REGISTER_GUEST_USER,
  savePublicId,
} from '../actions/LoginActions';
import {
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventType,
} from '../analytics';
import AppConfig from '../config/AppConfig';
import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
// import CleverTapAnalytics from '../analytics/CleverTapAnalytics';
function* registerGuestUser(action) {
  const { source } = action;
  try {
    const headers = {
      method: 'POST',
      headers: yield getApiHeaders(),
    };
    const response = yield fetch(URL.REGISTER_GUEST, headers);
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      const userAccountInfo = yield select((state) => state.UserAccountInfo);
      const { publicId } = userAccountInfo;
      if (Utility.isBlank(publicId)) {
        yield put(savePublicId(json.user.public_id));
        // AnalyticsManager.setPublicIdAsIdentifierInClevertap(
        //   json.user.public_id,
        // );
        AnalyticsManager.setUserProperty('public_id', json.user.public_id);
      } else if (Utility.isPresent(publicId) && source === 'deffered_link') {
        yield put(savePublicId(json.user.public_id));
        // AnalyticsManager.setPublicIdAsIdentifierInClevertap(
        //   json.user.public_id,
        // );
        if (AppConfig.getBooleanValue(Config.SEND_USER_ID_FOR_GUEST_USER)) {
          AnalyticsManager.setUserAlias(userAccountInfo);
        }
      }
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: `registerGuestUser: ${error}`,
    });
  }
}

export default function* watchGuestUserSaga() {
  yield takeLatest(REGISTER_GUEST_USER, registerGuestUser);
}
