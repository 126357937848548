import React from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Utility from '../../utils/Utility';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../theme/Colors';

const SlimRailShimmer = () => {
  const shimmerColors = [
    colors.shimmer.darkGrey,
    colors.shimmer.lightGrey,
    colors.shimmer.darkGrey,
  ];
  const imageShimmerColors = [
    colors.shimmer.lightGrey,
    colors.shimmer.extraLightGrey,
    colors.shimmer.lightGrey,
  ];

  const DescriptionPlaceholder = () => {
    return (
      <View style={sharedStyles.description}>
        {_.map([1, 2, 3], (e) => (
          <View style={sharedStyles.descriptionContainer} key={e} dataSet={{ media: ids.descriptionContainer }}>
            <ShimmerPlaceHolder
              colorShimmer={imageShimmerColors}
              autoRun
              style={sharedStyles.imageShimmer}
            />
            <View style={sharedStyles.bottomDescriptionContainer}>
              <ShimmerPlaceHolder
                colorShimmer={shimmerColors}
                autoRun
                style={sharedStyles.brand}
              />
              <ShimmerPlaceHolder
                colorShimmer={shimmerColors}
                autoRun
                style={sharedStyles.name}
              />
              <ShimmerPlaceHolder
                colorShimmer={shimmerColors}
                autoRun
                style={sharedStyles.price}
              />
            </View>

            <View style={sharedStyles.addToCartContainer}>
              <ShimmerPlaceHolder
                colorShimmer={shimmerColors}
                autoRun
                style={sharedStyles.addToCart}
                dataSet={{ media: ids.addToCart }}
              />
            </View>
          </View>
        ))}
      </View>
    );
  };

  return (
    <View style={sharedStyles.shimmerContainer}>
      <DescriptionPlaceholder />
    </View>
  );
};

const { ids, styles: sharedStyles } = StyleSheet.create({
  imageShimmer: {
    height: 170,
    width: 147,
  },
  descriptionContainer: {
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: Utility.getScreenWidth() / 2.4,
    height: Utility.getScreenWidth() / 2.4 + 140,
    alignSelf: 'center',
    padding: 12,
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    alignContent: 'center',
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 4.8,
      height: Utility.getScreenWidth() / 4.8 + 140,
    },
  },
  brand: { height: 18, width: 74, borderRadius: 4, marginTop: 12 },
  name: { height: 12, width: 150, borderRadius: 4, marginTop: 12 },
  price: { height: 12, width: 124, borderRadius: 4, marginTop: 4 },
  addToCartContainer: {
    marginTop: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  addToCart: {
    height: 34,
    width: Utility.getScreenWidth() / 2.4 - 32,
    marginLeft: 10,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 4.8 - 32,
    },
  },
  description: { flexDirection: 'row' },
  shimmerContainer: {
    padding: 12,
  },
  bottomDescriptionContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
});

export default SlimRailShimmer;
