import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import ShimmerPlaceHolder from '../../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../../theme/Colors';

class DescriptionThinCardPlaceholder extends PureComponent {
  render() {
    return (
      <View style={styles.container}>
        <ShimmerPlaceHolder
          colorShimmer={this.shimmerColors}
          autoRun
          style={styles.image}
        />
        <View style={styles.contentContainer}>
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.title}
          />
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.description}
          />
          <View style={styles.flexDirectionRow}>
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.artistImage}
            />
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.artistText}
            />
          </View>
        </View>
      </View>
    );
  }
}

export default DescriptionThinCardPlaceholder;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    paddingHorizontal: 12,
    flexDirection: 'row',
    borderBottomColor: colors.ultraLightGray,
    borderBottomWidth: 1,
    marginVertical: 4,
    backgroundColor: colors.white,
    borderRadius: 8,
  },
  image: {
    width: 84,
    height: 84,
    borderRadius: 16,
  },
  contentContainer: {
    paddingLeft: 12,
    flex: 1,
  },
  flexDirectionRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    width: 130,
    height: 16,
    marginTop: 4,
  },
  description: {
    width: '95%',
    height: 26,
    marginTop: 6,
  },
  artistImage: {
    height: 20,
    width: 20,
    borderRadius: 10,
    marginTop: 8,
  },
  artistText: {
    height: 14,
    marginTop: 8,
    marginLeft: 8,
    width: '40%',
  },
});
