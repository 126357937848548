import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';

const SalonCardStyles = StyleSheet.create({
  verticalCardStyle: {
    backgroundColor: 'white',
    borderColor: '#efefef',
    margin: 4,
    elevation: 3,
    shadowRadius: 1,
    shadowOpacity: 0.24,
    shadowColor: colors.black,
    shadowOffset: { width: 1, height: 2 },
    overflow: 'visible',
    borderRadius: 4,
  },
  horizontalCardStyle: {
    borderRadius: 4,
    backgroundColor: '#fff',
    borderWidth: 0,
    borderColor: '#efefef',
    flexDirection: 'row',
  },
  salonAddress: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,

    fontStyle: 'normal',
    color: colors.silver,
    lineHeight: 14,
    marginBottom: 12,
  },
  salonDescriptionContainer: {
    flex: 2,
  },
  likeButtonStyle: {
    marginRight: 22,
  },
  salonName: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    color: colors.foxyBlack,
    fontStyle: 'normal',
    lineHeight: 14,
    marginTop: 12,
  },
  salonImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  salonDescStyle: {
    flexDirection: 'column',
    marginLeft: 14,
    marginRight: 50,
    flex: 2.5,
    justifyContent: 'space-around',
  },
  listImageBackgroundStyle: {
    marginLeft: 8,
    borderRadius: 4,
    overflow: 'hidden',
  },
  gridImageBackgroundStyle: {
    borderRadius: 4,
    overflow: 'hidden',
  },
  railImageBackgroundStyle: {
    borderRadius: 4,
    overflow: 'hidden',
  },
  listSeparatorStyle: {
    height: 1,
    backgroundColor: '#eaeaea',
  },
});

export default SalonCardStyles;
