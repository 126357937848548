import React from 'react';
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {
  Menu,
  MenuTrigger,
  MenuOptions,
  MenuOption,
} from 'react-native-popup-menu';
import colors from '../../theme/Colors';
import images from '../../theme/Images';

const sort = {
  most_recent: 'Most Recent',
  most_helpful: 'Most Helpful',
};

const { ids, styles } = StyleSheet.create({
  outerContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 16,
  },
  innerContainer: {
    paddingHorizontal: 12,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  verticalSeparator: {
    marginHorizontal: 16,
    height: 24,
    width: 2,
    backgroundColor: '#EFEFEF',
  },
  filterContainer: { flexDirection: 'row' },
  verifiedOnlyContainerSelected: {
    paddingVertical: 5,
    paddingHorizontal: 9,
    borderColor: colors.foxyPink,
    backgroundColor: '#fd5c631a',
    borderWidth: 1,
    borderRadius: 16,
    marginRight: 12,
    cursor: 'pointer',
  },
  verifiedOnlyContainerUnSelected: {
    paddingVertical: 5,
    paddingHorizontal: 9,
    borderColor: '#EFEFEF',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderRadius: 16,
    marginRight: 12,
    cursor: 'pointer',
  },
  verifiedOnlyText: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 0,
    color: '#979797',
  },
  ratingsFilterContainer: {
    flexDirection: 'row',
  },
  ratingFilterContainerSelected: {
    paddingVertical: 5,
    paddingHorizontal: 9,
    borderColor: colors.foxyPink,
    backgroundColor: '#fd5c631a',
    borderWidth: 1,
    borderRadius: 12,
    marginRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  ratingFilterContainerUnSelected: {
    paddingVertical: 5,
    paddingHorizontal: 9,
    borderColor: '#EFEFEF',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderRadius: 12,
    marginRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  ratingText: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 0,
    color: '#979797',
  },
  ratingStarImage: {
    width: 10,
    height: 10,
    tintColor: '#979797',
    marginLeft: 4,
  },
  sortContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sortIcon: { width: 14, height: 12, marginRight: 10 },
  sortByText: {
    fontSize: 12,
    fontWeight: '500',
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
  },
  chevronDown: {
    width: 10,
    height: 10,
    tintColor: colors.foxyPink,
    marginLeft: 6,
  },
  menuOptionsContainer: {
    width: 120,
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuOption: {
    paddingVertical: 8,
    borderTopWidth: 1,
    borderTopColor: colors.border,
  },
  menuOptionText: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
  },
  scrollView: {
    ...Platform.select({
      web: { width: '100%' },
      default: {},
    }),
    '@media (min-width: 768px)': {
      width: 'auto',
    },
  },
});

const ratings = [5, 4, 3, 2, 1];

const SortAndFilterRatings = ({
  showVerifiedOnly,
  onRatingClick,
  filterRatings = [],
  toggleVerifiedOnly,
  sortRating,
  sort_by = 'most_helpful',
}) => {
  const SortRating = () => {
    return (
      <Menu>
        <MenuTrigger>
          <View style={styles.sortContainer}>
            <Image source={images.sorting} style={styles.sortIcon} />
            <Text style={styles.sortByText}>{sort[`${sort_by}`]}</Text>
            <Image source={images.chevronDown} style={styles.chevronDown} />
          </View>
        </MenuTrigger>
        <MenuOptions optionsContainerStyle={styles.menuOptionsContainer}>
          <MenuOption onSelect={sortRating} style={styles.menuOption} value='most_helpful'>
            <Text style={styles.menuOptionText}>Most Helpful</Text>
          </MenuOption>
          <MenuOption onSelect={sortRating} style={styles.menuOption} value='most_recent'>
            <Text style={styles.menuOptionText}>Most Recent</Text>
          </MenuOption>
        </MenuOptions>
      </Menu>
    );
  };

  const Ratings = () => {
    return (
      <View style={styles.ratingsFilterContainer}>
        {ratings.map((rating) => {
          const ratingsFilterStyle = filterRatings.includes(rating)
            ? styles.ratingFilterContainerSelected
            : styles.ratingFilterContainerUnSelected;
          return (
            <TouchableWithoutFeedback
              onPress={() => {
                onRatingClick(rating);
              }}
            >
              <View style={ratingsFilterStyle}>
                <Text style={styles.ratingText}>{rating}</Text>
                <Image
                  source={images.ratingStar}
                  style={styles.ratingStarImage}
                />
              </View>
            </TouchableWithoutFeedback>
          );
        })}
      </View>
    );
  };

  const VerifiedOnly = () => {
    const verifiedOnlyStyles = showVerifiedOnly
      ? styles.verifiedOnlyContainerSelected
      : styles.verifiedOnlyContainerUnSelected;
    return (
      <TouchableWithoutFeedback onPress={toggleVerifiedOnly}>
        <View style={verifiedOnlyStyles} onPress={toggleVerifiedOnly}>
          <Text style={styles.verifiedOnlyText}>Verified Only</Text>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  const FilterRating = () => {
    return (
      <View style={styles.filterContainer}>
        <VerifiedOnly />
        <Ratings />
      </View>
    );
  };

  const VerticalSeparator = () => {
    return <View style={styles.verticalSeparator} />;
  };

  return (
    <View style={styles.outerContainer}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.scrollView} dataSet={{ media: ids.scrollView }}>
        <View style={styles.innerContainer}>
          <SortRating />
          <VerticalSeparator />
          <FilterRating />
        </View>
      </ScrollView>
    </View>
  );
};

export default SortAndFilterRatings;
