import { View, StyleSheet, ActivityIndicator } from 'react-native';
import React from 'react';
import Utility from '../../utils/Utility';
import Email from './Email';

export default function ShopifyOrderHistoryFooter({
  showLoader = false,
  isListEnd = false,
  emailVerificationError,
}) {
  if (showLoader) {
    return (
      <View style={styles.footer}>
        <ActivityIndicator size="large" color="green" />
      </View>
    );
  }
  if (isListEnd) {
    return (
      <View style={styles.bottomEmailContainer}>
        <Email emailVerificationError={emailVerificationError} />
      </View>
    );
  }
  return <View style={styles.verticalMargin} />;
}

const styles = StyleSheet.create({
  footer: {
    height: 100,
    width: Utility.getScreenWidth(),
    marginTop: 12,
  },
  bottomEmailContainer: {
    marginBottom: Utility.isAndroid() ? 100 : 350,
  },
  verticalMargin: {
    height: 100,
  },
});
