import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
} from 'react-native';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';

const VariantShades = (props) => {
  const { variantShadesImages } = props;
  return (
    <View
      style={{
        flexDirection: 'row-reverse',
        marginRight: 3,
      }}
    >
      {variantShadesImages.map((attribute, index) => {
        return (
          <FastImageView
            source={{
              uri: attribute,
            }}
            key={index}
            style={styles.variantShade}
          />
        );
      })}
    </View>
  );
};
const VariantCount = (props) => {
  const { variantsCount } = props;

  return (
    <Text
      style={styles.variantCountText}
      numberOfLines={1}
      ellipsizeMode='tail'
    >
      {variantsCount}
    </Text>
  );
};
export default function ProductColorVariants(props) {
  const {
    variantShadesImages,
    variantsCount,
    containerStyle,
    onVariantPillPress,
  } = props;
  if (variantsCount === 0) return null;
  return (
    <View style={[styles.variantShadeContainer, containerStyle]}>
      <VariantShades variantShadesImages={variantShadesImages} />
      <VariantCount variantsCount={variantsCount} />
    </View>
  );
}

const styles = StyleSheet.create({
  variantShade: {
    height: 12,
    width: 12,
    marginLeft: -7,
    borderWidth: 1,
    borderColor: '#ffffff',
    ...Platform.select({
      web: {
        borderRadius: '50%',
        overflow: 'hidden',
      },
      default: {
        borderRadius: 6,
      },
    }),
  },
  variantCountText: {
    fontSize: 10,
    marginLeft: 2,
    fontFamily: 'Roboto-Regular',
    color: colors.silver,
  },
  variantShadeContainer: {
    flexDirection: 'row',
    padding: 1,
    paddingHorizontal: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
