export const SEARCH_SALON_NEARBY = 'SEARCH_SALON_NEARBY';
export const SEARCH_SALON = 'SEARCH_SALON';
export const CREATE_SALON_UPI_ORDER = 'CREATE_SALON_UPI_ORDER';
export const GET_SETU_PAYMENT_STATUS = 'CREATE_SETU_PAYMENT_STATUS';
export const GET_WHEEL_OFFERS = 'GET_WHEEL_OFFERS';
export const GET_ELIGIBILITY_FOR_KIT = 'GET_ELIGIBILITY_FOR_KIT';
export const LIKE_SALON = 'LIKE_SALON';
export const UNLIKE_SALON = 'UNLIKE_SALON';
export const UPDATE_SALON_SEARCH_HISTORY = 'UPDATE_SALON_SEARCH_HISTORY';
export const CLEAR_SALON_SEARCH_HISTORY = 'CLEAR_SALON_SEARCH_HISTORY';
export const NOTIFY_USER_AFTER_WHEEL_SPIN = 'NOTIFY_USER_AFTER_WHEEL_SPIN';

export function getSaloonsNearBy(lat, longitude, searchQuery, callback) {
  return {
    type: SEARCH_SALON_NEARBY,
    lat,
    longitude,
    searchQuery,
    callback,
  };
}

export function getSalon(searchQuery, callback) {
  return {
    type: SEARCH_SALON,
    searchQuery,
    callback,
  };
}

export function createSalonUpiOrder(entityType, salonId, amount, packageName, callback) {
  return {
    type: CREATE_SALON_UPI_ORDER,
    entityType,
    salonId,
    amount,
    packageName,
    callback,
  };
}

export function getSetuPaymentStatus(setuPaymentId, callback) {
  return {
    type: GET_SETU_PAYMENT_STATUS,
    setuPaymentId,
    callback,
  };
}

export function getWheelOffers(callback) {
  return {
    type: GET_WHEEL_OFFERS,
    callback,
  };
}

export function getEligibilityForKit(callback) {
  return {
    type: GET_ELIGIBILITY_FOR_KIT,
    callback,
  };
}

export function likeSalon(salonId) {
  return {
    type: LIKE_SALON,
    salonId,
  };
}

export function unlikeSalon(salonId) {
  return {
    type: UNLIKE_SALON,
    salonId,
  };
}

export function notifyUserAfterWheelSpin(setuId) {
  return {
    type: NOTIFY_USER_AFTER_WHEEL_SPIN,
    setuId,
  };
}

export function clearSalonSearchHistory() {
  return {
    type: CLEAR_SALON_SEARCH_HISTORY,
  };
}

export function updateSalonSearchHistory(payload) {
  return {
    type: UPDATE_SALON_SEARCH_HISTORY,
    payload,
  };
}
