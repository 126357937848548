import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import styles from './styles';
import {
  AnalyticsEvent,
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';

const navigateToMedia = (slug, navigation, uaavsArray) => {
  navigation.push('MediaPlaylist', {
    slug: `/api/v6/lists/${slug}`,
  });
};

const HonestReviews = (props) => {
  const {
    item: { slug, count, adjective, rank },
    myAttributesValues,
    navigation,
    scrollToHonestReviews,
    sku_id,
    id,
    matchedProduct = false,
  } = props;
  const cta = `${count} honest reviews`;
  const afterCta = ` with ${adjective} comments`;
  if (Utility.isBlank(cta) && Utility.isBlank(adjective)) {
    return null;
  }
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
          [EventParameterKey.SKU_ID]: sku_id,
          [EventParameterKey.PRODUCT_ID]: id,
          [EventParameterKey.ELEMENT_NAME]: 'foxy_match',
          [EventParameterKey.ELEMENT_TYPE]: 'honest_reviews',
        });
        navigateToMedia(slug, navigation);
      }}
      disabled={matchedProduct}
    >
      <Image source={images.foxyMatch.play} style={styles.icon} />
      <Text
        style={styles.foxyMatchTextCta}
        allowFontScaling={false}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {cta}
      </Text>
      <Text
        style={styles.foxyMatchText}
        allowFontScaling={false}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {afterCta}
      </Text>
    </TouchableOpacity>
  );
};

export default HonestReviews;
