import { put, takeLatest } from 'redux-saga/effects';
import {
  GET_SHOPIFY_ORDERS_DETAILS,
  GET_SHOPIFY_ORDERS_HISTORY,
  POST_SHOPIFY_ORDER_EMAIL,
  VERIFY_EMAIL,
  addEmailToLocalStore,
} from '../actions/ActionTypes';
import {
  SHOPIFY_ORDER_DETAILS_URL,
  SHOPIFY_ORDER_URL,
  URL,
} from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

function* sendVerificationEmail(action) {
  const { email, callback } = action;

  const url = `${URL.SEND_VERIFICATION_EMAIL}`;
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ profile: { email } }),
    });
    if (
      (response.status >= 200 && response.status < 300) ||
      response.status === 401
    ) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      const { errors = [] } = json;
      if (errors.length) {
        callback(false, { error: errors[0]?.message });
        return;
      }
      yield put(addEmailToLocalStore(email, false));
      callback(true, json);
      return;
    }
    callback(false, {});
  } catch (error) {
    console.tron.log('error in sending email', error);
    callback(false, {});
  }
}

function* verifyEmail(action) {
  const { email, emailToken, callback } = action;

  const url = `${URL.VERIFY_EMAIL}`;
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ profile: { email, email_token: emailToken } }),
    });
    if (
      (response.status >= 200 && response.status < 300) ||
      response.status === 401
    ) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      const { errors = [] } = json;
      if (errors.length) {
        callback(false, { error: errors[0]?.message });
        return;
      }
      yield put(addEmailToLocalStore(email, true));
      callback(true, json);
      return;
    }
    callback(false, {});
  } catch (error) {
    console.tron.log('Error in verifying email', error);
    callback(false, {});
  }
}

function* getShopifyOrdersHistory(action) {
  const { page = 0, callback } = action;
  const url = `${SHOPIFY_ORDER_URL}?page=${page}`;
  try {
    let response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield response.json();
      callback(true, json);
    } else if (response.status === 401) {
      const json = yield response.json();
      callback(true, json);
    } else {
      response = yield response.json();
      callback(false, {});
    }
  } catch (error) {}
}

function* getShopifyOrdersDetails(action) {
  const { id, callback } = action;
  const url = `${SHOPIFY_ORDER_DETAILS_URL}${id}`;
  try {
    let response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield response.json();
      callback(true, json);
    } else if (response.status === 401) {
      const json = yield response.json();
      callback(true, json);
    } else {
      response = yield response.json();
      callback(false, {});
    }
  } catch (error) {}
}

export default function* watchShopifyOrder() {
  yield takeLatest(POST_SHOPIFY_ORDER_EMAIL, sendVerificationEmail);
  yield takeLatest(VERIFY_EMAIL, verifyEmail);
  yield takeLatest(GET_SHOPIFY_ORDERS_HISTORY, getShopifyOrdersHistory);
  yield takeLatest(GET_SHOPIFY_ORDERS_DETAILS, getShopifyOrdersDetails);
}
