import React, { PureComponent } from 'react';
import { ImageBackground } from 'react-native';
import PropTypes from 'prop-types';
import CardView from '../../libraries/ReactNativeCardView';
import AdStyles from './styles';
import ScaleAnimate from '../shared/ScaleAnimate';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import Utility from '../../utils/Utility';

class AdCard extends PureComponent {
  navigateToScreen = (path, destination) => {
    const { navigation } = this.props;
    navigation.navigate(path, {
      destination,
      slug: destination,
    });
  };

  onActionPressed = () => {
    const { navigation, itemData: { destination } = {} } = this.props;

    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      destination,
      this.navigateToScreen,
    );
  };

  render() {
    const { itemData } = this.props;
    if (itemData === undefined) {
      return null;
    }
    return (
      <ScaleAnimate {...this.props} onPress={this.onActionPressed}>
        <CardView
          cardElevation={2}
          cornerRadius={4}
          cardMaxElevation={4}
          style={AdStyles.cardStyle}
        >
          <ImageBackground
            source={{ uri: itemData.image_url }}
            style={AdStyles.adStyle}
          />
        </CardView>
      </ScaleAnimate>
    );
  }
}

AdCard.propTypes = {
  itemData: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    image_url: PropTypes.string,
  }),
};

export default AdCard;
