import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import _ from 'lodash';
import { SIZE, LAYOUT, THEME } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FullWidthDivider from '../../utils/FullWidthDivider';
import Utility from '../../../../utils/Utility';
import Product from '../../../product';
import colors from '../../../../theme/Colors';

const keyExtractor = (item) => item.sku_id.toString();

const CartItem = ({ cartItem, navigation, showToast, hideAddToCart }) => {
  let newCart = cartItem;
  if (Utility.isPresent(cartItem.offer)) {
    newCart = { ...cartItem, product: cartItem.offer };
  }

  if (cartItem.quantity === 0) {
    return null;
  }
  return (
    <>
      <Product
        size={SIZE.large}
        layout={LAYOUT.CART}
        cart
        id={newCart?.productId}
        itemData={newCart}
        navigation={navigation}
        theme={THEME.LIGHT}
        previousScreen={SCREEN_CONSTANTS.CART}
        skuId={newCart?.sku_id}
        quantity={newCart?.quantity}
        cartItem={newCart}
        campaignId={newCart?.campaign_id}
        cartProduct={newCart?.product}
        error={newCart?.error}
        showToast={showToast}
        hideAddToCart={hideAddToCart}
        isDigitalSku={!!cartItem?.offer}
        retail
      />
      {!hideAddToCart && <FullWidthDivider />}
    </>
  );
};

const CartItems = ({
  cartItems = [],
  navigation,
  showToast,
  hideAddToCart,
}) => {
  if (Utility.isBlank(cartItems)) {
    return null;
  }

  cartItems.sort((first, second) => {
    const { product: { brand: { id: firstId = '' } = {} } = {} } = first;
    const { product: { brand: { id: secondId = '' } = {} } = {} } = second;
    if (firstId > secondId) {
      return -1;
    }
    if (firstId < secondId) {
      return 1;
    }
    return 0;
  });

  return (
    <FlatList
      data={cartItems}
      renderItem={({ item }) => (
        <CartItem
          cartItem={item}
          navigation={navigation}
          showToast={showToast}
          hideAddToCart={hideAddToCart}
        />
      )}
      keyExtractor={keyExtractor}
      style={styles.container}
      extraData={cartItems}
    />
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: colors.white },
});

export default CartItems;
