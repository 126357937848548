export const ENTER_STORE_MODE = 'ENTER_STORE_MODE';
export const EXIT_STORE_MODE = 'EXIT_STORE_MODE';
export const GET_STORE_INFORMATION = 'GET_STORE_INFORMATION';
export const MAKE_STORE_ADDRESS_DEFAULT = 'MAKE_STORE_ADDRESS_DEFAULT';
export const REMOVE_STORE_ADDRESS_AS_DEFAULT = 'GET_STORE_INFORMATION';

export const enterStoreMode = (storeData) => {
  return {
    type: ENTER_STORE_MODE,
    storeData,
  };
};
export const exitStoreMode = () => {
  return {
    type: EXIT_STORE_MODE,
  };
};

export const getStoreInformation = (slug, callback) => {
  return {
    type: GET_STORE_INFORMATION,
    slug,
    callback,
  };
};

export const makeStoreAddressDefault = () => {
  return {
    type: MAKE_STORE_ADDRESS_DEFAULT,
  };
};

export const removeStoreAddressAsDefault = () => {
  return {
    type: REMOVE_STORE_ADDRESS_AS_DEFAULT,
  };
};
