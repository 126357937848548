import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';

export const { ids, styles } = StyleSheet.create({
  container: {},
  headerContainer: {
    alignItems: 'center',
  },
  headerTabs: {
    width: Utility.getScreenWidth(),
    height: 48,
    backgroundColor: colors.white,
  },
  headerTabsContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    alignContent: 'center',
  },
  tabTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    alignSelf: 'center',
    alignContent: 'center',
    right: 10,
  },

  labelContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  tabBarShadow: {
    elevation: 0,
    shadowOpacity: 0,
  },
  tabStyle: {
    width: Utility.getScreenWidth() / 2,
    alignContent: 'center',
  },

  tabStyleForThree: {
    width: Utility.getScreenWidth() / 3,
    alignContent: 'center',
  },

  tabBarStyle: {
    backgroundColor: colors.white,
    paddingBottom: 0,
    height: 48,
    shadowOpacity: 0,
    elevation: 0,
  },
  indicatorStyle: {
    backgroundColor: colors.foxyBlack,
    width: Utility.getScreenWidth() / 2,
    height: 2,
    top: 14,
    bottom: 0,
    left: -12,
  },
  indicatorStyleForThree: {
    backgroundColor: colors.foxyBlack,
    width: Utility.getScreenWidth() / 3,
    height: 2,
    top: 14,
    bottom: 0,
    left: -12,
  },
  image: {
    width: Utility.getScreenWidth() / 4.2,
    height: Utility.getScreenWidth() / 4.2,
  },
  desktopImage: {
    width: Utility.getScreenWidth() / (4.2 * 2),
    height: Utility.getScreenWidth() / (4.2 * 2),
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 20,
    paddingVertical: 12,
  },

  circularTabTextStyle: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    paddingTop: 8,
  },
  contentContainerStyle: {
    paddingLeft: 12,
    paddingTop: 12,
  },
  circularTabContainer: {
    backgroundColor: colors.background,
  },
  selectedCircularTabStyle: {
    position: 'absolute',
    width: Utility.getScreenWidth() / 4.2 + 8,
    height: Utility.getScreenWidth() / 4.2 + 8,
    borderColor: colors.foxyBlack,
    borderWidth: 2,
    top: -4,
    alignSelf: 'center',
    ...Platform.select({
      web: {
        borderRadius: '50%',
      },
      default: {
        borderRadius: 80,
      },
    }),
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / (4.2 * 2) + 8,
      height: Utility.getScreenWidth() / (4.2 * 2) + 8,
    },
  },
  selectedPillsTabStyle: {
    height: 26,
    backgroundColor: colors.foxyBlack,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    minWidth: 40,
    paddingHorizontal: 12,
    marginRight: 12,
    marginTop: 6,
  },
  unselectedPillsTabStyle: {
    height: 26,
    backgroundColor: colors.border,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    minWidth: 40,
    paddingHorizontal: 12,
    marginRight: 12,
    marginTop: 6,
    marginBottom: 6,
  },
  selectedPillsTabTextStyle: {
    fontSize: 12,
    color: colors.white,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'center',
  },
  unselectedPillsTabTextStyle: {
    fontSize: 12,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'center',
  },
  flatListContainerStyle: {
    paddingTop: 12,
  },
  shimmer: {
    paddingTop: 14,
  },
  linearTabsStyle: {
    width: Utility.getScreenWidth() / 2,
    backgroundColor: colors.white,
    height: 48,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  multiLevelTabsContainer: {
    paddingTop: 12,
  },
  pillsTab: {
    backgroundColor: colors.background,
    paddingBottom: 12,
  },
  multiLevelTabsContainerForPage: {},
});
