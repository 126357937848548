import { isDesktop } from '../../utils/BooleanUtility';
import Utility from '../../utils/Utility';

const collectionConstants = {
  NUM_OF_CARDS: {
    LIST: isDesktop() ? 2 : 1,
    GRID: isDesktop() ? 4 : 2,
  },
  SPACING: {
    LIST: 0,
    GRID: 16,
  },
  defaultImageWidth: 48,
};
export const COLLECTION = {
  CARD: {
    rail: {
      defaultSize: {
        viewwidth: isDesktop() ? 136 * 2 : 136,
        viewheight: isDesktop() ? 136 * 2 : 136,
        imagewidth: isDesktop() ? 136 * 2 : 136,
        imageheight: isDesktop() ? 136 * 2 : 136,
      },
    },
    grid: {
      defaultSize: {
        viewwidth: Utility.getDynamicWidthForGrid(
          collectionConstants.NUM_OF_CARDS.GRID,
          collectionConstants.SPACING.GRID,
        ),
        viewheight: Utility.getDynamicWidthForGrid(
          collectionConstants.NUM_OF_CARDS.GRID,
          collectionConstants.SPACING.GRID,
        ),
        imagewidth: Utility.getDynamicWidthForGrid(
          collectionConstants.NUM_OF_CARDS.GRID,
          collectionConstants.SPACING.GRID,
        ),
        imageheight: Utility.getDynamicWidthForGrid(
          collectionConstants.NUM_OF_CARDS.GRID,
          collectionConstants.SPACING.GRID,
        ),
      },
      small: {
        viewwidth: Utility.getDynamicWidthForSmallGrid(
          collectionConstants.NUM_OF_CARDS.GRID,
          collectionConstants.SPACING.GRID,
        ),
        viewheight: Utility.getDynamicWidthForSmallGrid(
          collectionConstants.NUM_OF_CARDS.GRID,
          collectionConstants.SPACING.GRID,
        ),
        imagewidth: Utility.getDynamicWidthForSmallGrid(
          collectionConstants.NUM_OF_CARDS.GRID,
          collectionConstants.SPACING.GRID,
        ),
        imageheight: Utility.getDynamicWidthForSmallGrid(
          collectionConstants.NUM_OF_CARDS.GRID,
          collectionConstants.SPACING.GRID,
        ),
      },
    },
    colouredRectangleRail: {
      defaultSize: {
        viewwidth: isDesktop() ? 170 * 1.5 : 170,
        viewheight: isDesktop() ? 85 * 1.5 : 85,
        imagewidth: isDesktop() ? 80 * 1.5 : 80,
        imageheight: isDesktop() ? 80 * 1.5 : 80,
      },
    },
    rectangularGrid: {
      defaultSize: {
        viewwidth: Utility.getDynamicWidthForGrid(
          collectionConstants.NUM_OF_CARDS.GRID,
          collectionConstants.SPACING.GRID,
        ),
        viewheight:
          Utility.getDynamicWidthForGrid(
            collectionConstants.NUM_OF_CARDS.GRID,
            collectionConstants.SPACING.GRID,
          ) *
          (85 / 170),
        imagewidth: 80,
        imageheight: 80,
      },
    },
    gridSquareRail: {
      defaultSize: {
        viewwidth: isDesktop() ? 136 * 2 : 136,
        viewheight: isDesktop() ? 136 * 2 : 136,
        imagewidth: isDesktop() ? 80 * 2 : 80,
        imageheight: isDesktop() ? 80 * 2 : 80,
      },
    },
    list: {
      defaultSize: {
        viewwidth: Utility.getScreenWidth(),
        viewheight: Utility.listDefaultViewHeight,
        imagewidth: collectionConstants.defaultImageWidth,
        imageheight: collectionConstants.defaultImageWidth,
      },
    },
    gridRail: {
      defaultSize: {
        viewwidth: isDesktop() ? Utility.getScreenWidth() / 4.4 : 120,
        viewheight: isDesktop() ? Utility.getScreenWidth() / 4.4 : 120,
        imagewidth: isDesktop() ? Utility.getScreenWidth() / 4.4 : 120,
        imageheight: isDesktop() ? Utility.getScreenWidth() / 4.4 : 120,
      },
    },
  },
  SCREEN: {},
};
