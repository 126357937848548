import React, { useEffect } from 'react';
import { View, Text, Image } from 'react-native';
import _ from 'lodash';
import images from '../../theme/Images';

import { cartIds, CartStyles } from './styles';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { AnalyticsManager } from '../../analytics';
import TimeUtility from '../../utils/TimeUtility';

const AddAddressButton = () => {
  return (
    <View style={CartStyles.emptyAddressContainer}>
      <Image
        source={images.foxyPromise.fastDelivery}
        style={CartStyles.truckImage}
        dataSet={{ media: cartIds.truckImage }}
      />
      <Text
        style={CartStyles.emptyAddressText}
        allowFontScaling={false}
        dataSet={{ media: cartIds.emptyAddressText }}
      >
        Add address to see delivery timelines
      </Text>
    </View>
  );
};

export const DeliveryInfo = ({
  defaultAddress,
  fromAddress,
  fromOrder,
  orderCampaign: { expected_instock_date = '' } = {},
  getShipmentText = '',
}) => {
  const {
    delivery_message: {
      promise_delivery = false,
      heading = '',
      promise_delivery_title = '',
      promise_delivery_sub_title = '',
    } = {},
  } = defaultAddress;

  if (Utility.isBlank(heading) && Utility.isBlank(expected_instock_date)) {
    return null;
  }

  const headingToDisplay = getShipmentText || heading;

  const preOrderString = `Item is expected to ship on or before `;
  const preOrderDate = expected_instock_date ?? '';

  const tintColor = promise_delivery ? colors.foxyPink : colors.black;

  const style = promise_delivery
    ? [
        CartStyles.promiseDeliveryBox,
        {
          marginHorizontal: fromAddress ? 0 : 12,
          marginTop: fromAddress ? 8 : 0,
        },
      ]
    : [
        CartStyles.verticalPadding,
        { paddingHorizontal: fromAddress ? 0 : 12 },
      ];
  const fontFamily = promise_delivery ? 'Roboto-Medium' : 'Roboto-Regular';

  if (!promise_delivery && fromOrder) {
    return null;
  }

  return (
    <View style={style}>
      <View style={CartStyles.addressDeliveryContainer}>
        <Image
          source={images.foxyPromise.fastDelivery}
          style={[
            CartStyles.truckImage,
            { tintColor },
          ]}
          dataSet={{ media: cartIds.truckImage }}
        />
        {Utility.isPresent(preOrderDate) ? (
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={CartStyles.preOrderText}
            >
              {preOrderString}
            </Text>
            <Text
              style={CartStyles.preOrderDate}
            >
              {preOrderDate}
            </Text>
          </View>
        ) : (
          <Text
            style={[
              CartStyles.addressDeliveryHeading,
              {
                fontSize: fromAddress ? 14 : 12,
                fontFamily,
              },
            ]}
            dataSet={{ media: cartIds.addressDeliveryHeading }}
            allowFontScaling={false}
          >
            {headingToDisplay}
          </Text>
        )}
      </View>
      {promise_delivery && (
        <View style={CartStyles.promiseDeliveryContainer}>
          <Text
            style={CartStyles.promiseDeliveryTitle}
            allowFontScaling={false}
          >
            {promise_delivery_title}
          </Text>
          {!fromOrder && (
            <Text
              style={CartStyles.promiseDeliverySubText}
              allowFontScaling={false}
            >
              {promise_delivery_sub_title}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};

const DelayedDelivery = ({ defaultAddress, fromAddress }) => {
  const { delayed_delivery: { is_delayed = false, message = '' } = {} } =
    defaultAddress;

  if (!is_delayed || Utility.isBlank(message)) {
    return null;
  }
  return (
    <Text
      style={[
        CartStyles.delayedDeliveryText,
        { paddingHorizontal: fromAddress ? 0 : 16 },
      ]}
      allowFontScaling={false}
    >
      {message}
    </Text>
  );
};

const DeliveryInformation = ({
  address = [],
  fromAddress = false,
  orderCampaign = {},
  getShipmentText = '',
}) => {
  let addressArray = [];

  if (!Utility.isBlank(address)) {
    addressArray = Object.keys(address).map((key) => ({
      id: key,
      name: address[key].name,
      pincode: address[key].pincode,
      line1: address[key].line1,
      line2: address[key].line2,
      contact_name: address[key].contact_name,
      default: address[key].default,
      delayed_delivery: address[key].delayed_delivery,
      delivery_message: address[key].delivery_message,
    }));
  }

  let defaultAddress = addressArray.filter((item) => item.default)[0];
  if (Utility.isBlank(defaultAddress)) {
    defaultAddress = addressArray[0];
  }

  if (Utility.isBlank(defaultAddress)) {
    return <AddAddressButton fromAddress={fromAddress} />;
  }
  const {
    delayed_delivery = {},
    delivery_message = {},
    pincode = '',
    id = '',
  } = defaultAddress;
  const { is_delayed, message = '' } = delayed_delivery;
  const {
    promise_delivery,
    heading,
    promise_delivery_title,
    promise_delivery_sub_title,
  } = delivery_message;

  useEffect(() => {
    if (Utility.isBlank(heading)) {
      return;
    }
    AnalyticsManager.logEvent('delivery_info_visible', {
      is_delayed,
      message,
      promise_delivery,
      heading,
      promise_delivery_title,
      promise_delivery_sub_title,
      pincode,
      address_id: id,
    });
  }, [JSON.stringify(delivery_message), JSON.stringify(delayed_delivery)]);
  return (
    <>
      <DeliveryInfo
        defaultAddress={defaultAddress}
        fromAddress={fromAddress}
        orderCampaign={orderCampaign}
        getShipmentText={getShipmentText}
      />

      <DelayedDelivery
        defaultAddress={defaultAddress}
        fromAddress={fromAddress}
      />
    </>
  );
};

export default DeliveryInformation;
