import { Text, TouchableOpacity, View, Image, Linking } from 'react-native';
import { Alert } from '../../../libraries/NativeLibraries';
import React, { Component } from 'react';
import styles from './styles';
import FastImageView from '../../../components/FastImageView';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';
import Video from '../../../libraries/ReactNativeVideo';
import DebouncedTouchableOpacity from '../../../components/shared/DebouncedTouchableOpacity';
import ImagePicker from 'react-native-image-crop-picker';
import Permissions from '../../../libraries/ReactNativePermissions';
import UploadMediaContent from './UploadMediaContent';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import colors from '../../../theme/Colors';
import SeeHowModal from './SeeHowModal';
import { AnalyticsManager, EventType } from '../../../analytics';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import withNavigation from '../../../utils/WithNavigation';
import UploadMedia from '../../../components/productRatings/UploadMedia';
import { isWeb } from '../../../utils/BooleanUtility';

class UploadingImageAndVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      media: [],
      readExternalStoragePermission: false,
      isSeeHowModalVisible: false,
    };
  }

  openAppSettings = () => {
    Linking.openSettings();
  };

  openSettings = () => {
    const { navigation } = this.props;
    Alert.alert(
      'Permission Required',
      'Change Access permission inside Settings to allow photo access',
      [
        {
          text: 'Open Settings',
          onPress: this.openAppSettings,
        },
        {
          text: 'Cancel',
          onPress: () => navigation.goBack(),
          style: 'cancel',
        },
      ],
    );
  };

  RequestExternalStoragePermission = () => {
    const mediaLibrary = Utility.isAndroid() ? 'storage' : 'photo';
    Permissions.request(mediaLibrary).then((response) => {
      const isPermissionAllowed = response === 'authorized';
      const metaData = { type: 'media_library' };
      if (response === 'authorized') {
        this.setState({ readExternalStoragePermission: true }, () => {
          this.onUploadMediaPress();
        });
        return;
      }
      if (Utility.isIOS() && response === 'denied') {
        this.openSettings();
      }
      if (response === 'restricted') {
        this.openSettings();
      }
      Utility.logPermissionEvent(isPermissionAllowed, metaData);
    });
  };

  onUploadMediaPressAppend = (files = []) => {
    const { readExternalStoragePermission, media = [] } = this.state;
    const { setMediaContent, headerTitle } = this.props;

    if (isWeb()) {
      const newMedia = this.createMediaArray(files);
      const newImages = [...media, ...newMedia];
      const appendedImages = newImages.slice(0, 7);
      this.setState({ media: appendedImages });
      return;
    }
    if (readExternalStoragePermission) {
      ImagePicker.openPicker({
        mediaType: 'any',
        multiple: true,
      }).then((images) => {
        const newMedia = this.createMediaArray(images);
        const newImages = [...media, ...newMedia];
        const appendedImages = newImages.slice(0, 7);
        this.setState({ media: appendedImages });
        setMediaContent(appendedImages);
      });
      AnalyticsManager.logFirebaseEvent(EventType.review_upload.MEDIA_SELECT, {
        media_type: headerTitle,
      });
    } else {
      this.RequestExternalStoragePermission();
    }
  };

  deleteImage = (index) => {
    const { media } = this.state;
    const { setMediaContent } = this.props;
    if (media[index].mime === 'video/mp4') {
      this.mediaContainsOneVideo = false;
    }
    if (index > -1) {
      media.splice(index, 1);
    }
    setMediaContent(media);
    this.setState({
      media,
    });
  };

  createMediaArray = (mediaArray) => {
    const filteredArray = [];
    mediaArray.forEach((media) => {
      if (filteredArray.length > 7) {
        this.showToast('Only 7 media objects can be added');
        return filteredArray;
      }
      if (media.mime === 'video/mp4') {
        if (!this.mediaContainsOneVideo) {
          this.mediaContainsOneVideo = true;
          filteredArray.push(media);
        } else {
          this.showToast('Only one video can be added per request');
        }
      } else {
        filteredArray.push(media);
      }
    });
    return filteredArray;
  };

  onUploadMediaPress = (files = []) => {
    const { readExternalStoragePermission } = this.state;
    const { setMediaContent, headerTitle } = this.props;
    if (isWeb()) {
      const imageArray = this.createMediaArray(files.slice(0, 7));
      this.setState({ media: imageArray });
      return;
    }
    if (readExternalStoragePermission) {
      ImagePicker.openPicker({
        mediaType: 'any',
        multiple: true,
      }).then((images) => {
        const imageArray = this.createMediaArray(images.slice(0, 7));
        this.setState({ media: imageArray });
        setMediaContent(imageArray);
      });
      AnalyticsManager.logFirebaseEvent(EventType.review_upload.MEDIA_SELECT, {
        media_type: headerTitle,
      });
    } else {
      this.RequestExternalStoragePermission();
    }
  };

  hideSeeHowModalVisible = () => {
    this.setState({
      isSeeHowModalVisible: false,
    });
  };

  onPressSeeHowButton = () => {
    this.setState({
      isSeeHowModalVisible: true,
    });
  };

  render() {
    const { isSeeHowModalVisible } = this.state;
    const { appendImageCardStyle } = this.props;
    return (
      <View style={styles.uploadContentContainer}>
        <Text style={styles.uploadImageText}>Add image/video</Text>
        <Text style={styles.subHeading}>
          Please upload a photo/video (if available) for us to understand the
          issue better.
        </Text>
        <TouchableOpacity
          onPress={this.onPressSeeHowButton}
          style={styles.seeHowTextContainer}
        >
          <Text style={styles.seeHowTextStyle}>See How</Text>
          <FastImageView
            source={images.blue_chevron}
            style={styles.blueChevronStyle}
          />
        </TouchableOpacity>
        <SeeHowModal
          isVisible={isSeeHowModalVisible}
          onDismiss={this.hideSeeHowModalVisible}
        />
        <UploadMedia
          onUploadMediaPress={this.onUploadMediaPress}
          onUploadMediaPressAppend={this.onUploadMediaPressAppend}
          deleteImage={this.deleteImage}
          navigation={this.props.navigation}
          media={this.state.media}
          appendImageCardStyle={appendImageCardStyle}
          showSquareCameraButton
          hideCommunityGuidelines={isWeb()}
        />
      </View>
    );
  }
}

export default withNavigation(UploadingImageAndVideo);
