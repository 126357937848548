import _ from 'lodash';
import * as types from '../actions/ActionTypes';
import initialState from './initialState';

export default function zoomAnimationVisibility(
  state = initialState.zoomAnimationVisibility,
  action,
) {
  switch (action.type) {
    case types.SET_LAST_ZOOM_ANIMATION_VISIBLE_AT:
      return {
        ...state,
        last_zoom_animation_visible_at: action.lastZoomAnimationVisibleAt,
      };
    case types.CURRENT_ZOOM_ANIMATION_VISIBILITY_COUNTER:
      return {
        ...state,
        current_zoom_animation_visiblity_counter:
          action.currentZoomAnimationVisiblityCounter,
      };
    default:
      return state;
  }
}
