import { GENERIC_APP_ASSET_URL } from '../config/Constants';

const animations = {
  successTick: require('../assets/animations/check_mark_success.json'),

  pendingPayment: require('../assets/animations/wait.json'),
  like: require('../assets/animations/likeAnimate.json'),
  swipeUp: { uri: 'https://cdn1.foxy.in/media/app_asset/image/98/swipeup.gif' },
  paymentValidationFail: require('../assets/animations/warning-animation.json'),
  paymentSuccess: require('../assets/animations/success-animation.json'),
  paymentFail: require('../assets/animations/failure-error-icon.json'),
  holdOn: require('../assets/animations/processing-hold-on.json'),
  takeSelfie: require('../assets/animations/take-selfie.json'),
  paymentProcessing: require('../assets/animations/payment_processing.json'),
  deletionAnimation: require('../assets/animations/deletion-animation.json'),
  confettiAnimation: require('../assets/animations/confetti_gif.json'),
  scanAnimation: require('../assets/animations/scan_animation.json'),
  itemLike: require('../assets/animations/item_like.json'),
  itemLikeNoBg: require('../assets/animations/like_2_nobg.json'),
  zoom_placeholder: `${GENERIC_APP_ASSET_URL}/714/zoom_placeholder.json`,
  tick_animation: require('../assets/animations/tick_animation.json'),
  contacts_animation: require('../assets/animations/contacts_animation.json'),
  notificationPrompt: {
    wishlist_page: `${GENERIC_APP_ASSET_URL}/415/wishlist.json`,
    me_page: `${GENERIC_APP_ASSET_URL}/generic_file/414/payoff.json`,
    order_success: `${GENERIC_APP_ASSET_URL}/413/order_success.json`,
    order_history: `${GENERIC_APP_ASSET_URL}/412/order_history.json`,
    product_out_of_stock: `${GENERIC_APP_ASSET_URL}/416/out_of_stock.json`,
    feed_page: `${GENERIC_APP_ASSET_URL}/417/feed.json`,
    foxy_edge: `${GENERIC_APP_ASSET_URL}/541/46245_notifications.json`,
  },
  alertSuccess: require('../assets/animations/success-animation.json'),
  oneLinkRegistrationCompleted: 'https://www.foxy.in/assets/gifs/page_ready-52d5e04be0a5558afd306b7294b18cd7d9e0c01eb21720f28665deb75d4c02f9.gif',
  oneLinkVerifying: 'https://www.foxy.in/assets/gifs/verifying-999d5f3d17aceae19b4b5da5e0dcb13601c75e17d609d3dab376ab6e917d9ff0.gif',
  oneLinkVerificationFailed: 'https://www.foxy.in/assets/gifs/verification_failed-735061d9954d7211546590b6e94d5db0bf0f2ff3734116dd9fcb98f09ed020b9.gif',
  oneLinkVerificationSuccess: 'https://www.foxy.in/assets/gifs/verification_done-82fb63c76208709f87183f908cf5bfc11a7c472bb6faf922c36a45305f4aa75b.gif',
};

export default animations;
