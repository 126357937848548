import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { View, Text, StyleSheet, Image, Linking } from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../../components/FastImageView';
import { Grayscale } from '../../libraries/ReactNativeColorMatrixImageFilters';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import Contacts from 'react-native-contacts';
import { PERMISSION_STORE } from '../../config/Constants';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import animations from '../../theme/Animations';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import withNavigation from '../../utils/WithNavigation';
import NavigationService from '../../navigator/NavigationService';
import _ from 'lodash';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

const cardWidthChange = 24;
const cardHorizontalPadding = 12;

class EdgeFeatureCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  onPress = () => {
    const {
      cardDetails: {
        destination = '',
        type = '',
        display = '',
        title = '',
        id,
      } = {},
      previousScreen = '',
      index = 0,
      listId = '',
      listContent = '',
      listName = '',
      listDisplay = '',
      listSlug = '',
      listIndex,
    } = this.props;
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      id,
      type,
      title,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      '',
      listSlug,
    );
    DynamicLinkManager.handleDynamicLink(
      `${destination}`,
      this.navigateToScreen,
    );
  };

  openSettings = () => {
    const { navigation } = this.props;
    Alert.alert(
      'Permission Required',
      'Change Access permission inside Settings to allow contact access',
      [
        {
          text: 'Open Settings',
          onPress: Linking.openSettings,
        },
        {
          text: 'Cancel',
          onPress: () => navigation.goBack(),
          style: 'cancel',
        },
      ],
    );
  };

  navigateToScreen = (route, slug, path, extra = {}, params, url) => {
    const { navigation, previousScreen = '' } = this.props;
    if (path === 'save_contact') {
      Utility.checkPermission(
        PERMISSION_STORE.read_contact,
        true,
        (isAllowed) => {
          if (isAllowed) {
            this.addContact(params);
            return;
          }
          this.openSettings();
        },
      );
      return;
    }
    const updatedParams = { ...params, previousScreen };
    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      params: {
        slug,
        extra,
        params: updatedParams,
        url,
      },
    });
  };

  checkForExistingUserAndAdd = async (params) => {
    const allContacts = await Contacts.getAll();
    const doesUserExist = allContacts.filter((user) => {
      const isUserAlreadySaved = Utility.isPresent(
        user?.phoneNumbers.filter(
          (phoneNumber) => phoneNumber?.number === params?.phone_number,
        ),
      );
      return isUserAlreadySaved;
    });
    return Utility.isPresent(doesUserExist);
  };

  addContact = async (params) => {
    const isUserAleadySaved = await this.checkForExistingUserAndAdd(params);
    if (isUserAleadySaved) {
      this.setState({
        isModalVisible: true,
      });
      return;
    }
    let newPerson = {
      emailAddresses: [
        {
          label: 'work',
          email: params?.email,
        },
      ],
      phoneNumbers: [
        {
          label: 'mobile',
          number: `${params?.phone_number}`,
        },
      ],
    };
    newPerson = Utility.isIOS()
      ? { ...newPerson, givenName: `${params?.name}` }
      : { ...newPerson, displayName: `${params?.name}` };
    Contacts.openContactForm(newPerson)
      .then((e) => {})
      .catch((e) => {});
  };

  actionButton = () => {
    const {
      cardDetails: {
        background_color = '',
        arguments: args = {},
        destination = '',
        cta_color = '',
        cta_text = '',
      } = {},
      listData: { background_color: listBg = colors.white } = {},
    } = this.props;

    let disabled = false;
    let cta_url = '';
    if (Utility.isPresent(args)) {
      const { showCTA, showCta, isDisabled = false } = args;
      if (showCTA || showCta) {
        cta_url = destination;
        disabled = isDisabled;
      }
    }

    if (Utility.isBlank(cta_url)) return null;

    const backgroundColor = disabled ? colors.subtitle : cta_color;

    const addToCartStyle = [
      styles.addToCartIconContainer,
      { backgroundColor },
    ];

    const buttonPositionStyle = [
      styles.buttonPosition,
      { backgroundColor: listBg },
    ];

    if (Utility.isPresent(cta_text)) {
      return null;
    }

    return (
      <View style={buttonPositionStyle}>
        <DebouncedTouchableOpacity
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
          style={addToCartStyle}
        >
          <Image style={styles.addToCartIcon} source={images.chevronRight} />
        </DebouncedTouchableOpacity>
      </View>
    );
  };

  closeModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    const {
      cardDetails: {
        title = '',
        body = '',
        image_url = '',
        background_color = '',
        arguments: args = {},
        destination = '',
        cta_text = '',
      } = {},
      dateOfBirth,
      listData: { objects = [] } = {},
    } = this.props;
    const { isModalVisible } = this.state;

    let disabled = false;
    let isTouchable = false;
    if (Utility.isPresent(args)) {
      const {
        isDisabled = false,
        showCta,
        showCTA,
        hide_if_dob_is_present = false,
      } = args;
      if (hide_if_dob_is_present && Utility.isDobPresent(dateOfBirth)) {
        return null;
      }
      disabled = isDisabled;
      if ((showCTA || showCta) && Utility.isPresent(destination)) {
        isTouchable = true;
      }
    }

    const backgroundColor =
      disabled === 'true' ? colors.subtitle : background_color;

    let titleStyle = styles.title;
    let subtitleStyle = styles.subtitle;

    let LayoutComponent = View;
    if (disabled === 'true') {
      titleStyle = [styles.title, { color: colors.white }];
      subtitleStyle = [styles.subtitle, { color: colors.white }];
      LayoutComponent = Grayscale;
    }

    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.onPress}
        disabled={!isTouchable}
        style={_.memoize(
          () => ([{
              width:
                objects.length > 1
                  ? Utility.getScreenWidth() -
                    cardHorizontalPadding -
                    cardWidthChange
                  : Utility.getScreenWidth() - 24,
              marginRight: objects.length > 1 ? 8 : 0,
              marginBottom:
                isTouchable && Utility.isBlank(cta_text) ? 16 + 8 : 8 + 8,
              backgroundColor,
            },
            styles.container,
          ]),
          () => [backgroundColor, objects.length > 1, cta_text, isTouchable],
        )()}
      >
        <View style={styles.contentContainer}>
          <Text style={titleStyle} numberOfLines={1} ellipsizeMode='tail'>
            {title}
          </Text>
          <Text
            style={subtitleStyle}
            numberOfLines={Utility.isPresent(cta_text) ? 1 : 2}
            ellipsizeMode='tail'
          >
            {body}
          </Text>
          {Utility.isPresent(cta_text) && (
            <DebouncedTouchableOpacity
              style={styles.ctaText}
              onPress={this.onPress}
            >
              <Text style={styles.cta} numberOfLines={2} ellipsizeMode='tail'>
                {cta_text}
              </Text>
            </DebouncedTouchableOpacity>
          )}
        </View>

        <View style={styles.featureCardImageContainer}>
          <LayoutComponent>
            <FastImageView
              source={image_url}
              style={styles.featureCardImage}
              resizeMode='contain'
            />
          </LayoutComponent>
        </View>

        <FastImageView
          resizeMode={'stretch'}
          source={images.edge.mesh}
          style={styles.meshImage}
        />
        <this.actionButton />
        <FoxyAlert
          isVisible={isModalVisible}
          hideSecondButton
          alertBoxTitle={'Contact already saved!'}
          alertMessage={
            'Hey it looks like you have already saved our contact details. 😉'
          }
          firstButtonTitle={'Ok'}
          firstButtonOnPress={this.closeModal}
          onTapOutside={this.closeModal}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          showJson
          animation={animations.contacts_animation}
          animationStyle={styles.animationStyle}
        />
      </DebouncedTouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.UserAccountInfo.authToken,
  dateOfBirth: state.UserAccountInfo.profile.date_of_birth,
});

export default withNavigation(connect(mapStateToProps, null)(EdgeFeatureCard));

const styles = StyleSheet.create({
  featureCard: {
    width: Utility.getScreenWidth() - cardWidthChange,
    height: 130,
    borderRadius: 16,
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'center',
    justifyContent: 'center',
    overflow: 'visible',
  },
  contentContainer: {
    flex: 1,
    marginLeft: 16,
    marginTop: 12,
  },
  title: {
    color: colors.foxyBlack,
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    lineHeight: 26,
  },
  subtitle: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
    lineHeight: 14,
  },
  buttonText: {
    color: colors.white,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
  },
  featureCardImage: {
    width: 70,
    height: 70,
    marginTop: -18,
    right: 12,
  },
  featureCardImageContainer: {
    overflow: 'visible',
    zIndex: 3,
  },
  addToCartIconContainer: {
    borderRadius: 21,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonPosition: {
    backgroundColor: colors.white,
    padding: 5,
    height: 40,
    borderRadius: 28,
    width: 40,
    position: 'absolute',
    bottom: -20,
    zIndex: 1,
  },
  addToCartIcon: {
    height: 24,
    width: 24,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  meshImage: {
    width: 140,
    height: 130,
    bottom: 0,
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
  animationStyle: {
    height: 100,
    width: 100,
    alignSelf: 'center',
    marginBottom: 12,
  },
  cta: {
    color: colors.foxyBlack,
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
  },
  container: {
    height: 94,
    borderRadius: 16,
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 24,
    overflow: 'visible',
  },
  ctaText: {
    backgroundColor: 'white',
    padding: 2,
    maxWidth: 80,
    marginTop: 6,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
