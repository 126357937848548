import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import FastImageView from '../FastImageView';

class SelfieAnalysisCard extends PureComponent {
  render() {
    const { onCardPress = () => {} } = this.props;
    return (
      <TouchableOpacity onPress={onCardPress} style={styles.container}>
        <Text style={styles.title}>Set your skincare & haircare goals</Text>
        <View style={styles.flexDirection}>
          <View style={styles.contentContainer}>
            <Text style={styles.subtitle}>
              Do a facial analysis and get routine recommendations for your
              goals!
            </Text>
            <TouchableOpacity onPress={onCardPress} style={styles.buttonContainer}>
              <Text style={styles.buttonText}>Start Analysis</Text>
            </TouchableOpacity>
          </View>
          <FastImageView source={images.routines.camera} style={styles.image} />
        </View>
      </TouchableOpacity>
    );
  }
}

export default SelfieAnalysisCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 12,
    paddingBottom: 4,
    margin: 12,
    borderRadius: 4,
    backgroundColor: colors.yellow,
  },
  contentContainer: {
    flex: 1,
  },
  title: {
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    marginBottom: 8,
  },
  subtitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    marginBottom: 12,
    alignSelf: 'flex-start',
  },
  flexDirection: {
    flexDirection: 'row',
  },
  image: {
    width: 88,
    height: 88,
    resizeMode: 'contain',
    marginLeft: 4,
  },
  buttonContainer: {
    backgroundColor: colors.black,
    borderRadius: 18,
    paddingHorizontal: 16,
    paddingVertical: 8,
    alignSelf: 'flex-start',
  },
  buttonText: {
    color: colors.white,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
  },
});
