import * as types from '../actions/ActionTypes';
import initialState from './initialState';

const feedDetails = (state = initialState.feedDetails, action) => {
  switch (action.type) {
    case types.SHOULD_RENDER_REFERRAL_MODAL:
      return {
        ...state,
        isReferralModalVisible: action.isReferralModalVisible,
        referralModalType: action.referralModalType,
      };
    case types.FETCHING_LIST:
      return {
        ...state,
        refreshing: action.refreshing,
      };
    case types.LIST_DETAIL_RECEIVED:
      return {
        ...state,
        id: action.data.id,
        slug: action.data.slug,
        refreshing: false,
      };
    default:
      return state;
  }
};

export default feedDetails;
