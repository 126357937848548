import React, { PureComponent } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Image,
  TouchableOpacity,
} from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import RoutineDescriptionThinCard from './RoutineDescriptionThinCard';
import DescriptionThinCardPlaceholder from './ShimmerPlaceholderCards/DescriptionThinCardPlaceholder';

class RoutineDiscoveryCard extends PureComponent {
  renderRoutine = ({ item }) => {
    const { navigation } = this.props;
    return (
      <RoutineDescriptionThinCard itemData={item} navigation={navigation} />
    );
  };

  keyExtractor = (item) => `${item?.id}`;

  navigateToAllRoutines = () => {
    const { navigation } = this.props;
    navigation.navigate('Routines', {});

    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.TYPE]: 'see_all_routines',
    });
  };

  descriptionThinCardPlaceholder = () => {
    return (
      <View>
        <DescriptionThinCardPlaceholder />
        <DescriptionThinCardPlaceholder />
      </View>
    );
  };

  routineList = () => {
    const {
      itemData: { objects = [] },
    } = this.props;
    if (Utility.isBlank(objects)) {
      return <this.descriptionThinCardPlaceholder />;
    }
    return (
      <FlatList
        data={objects.slice(0, 2)}
        renderItem={this.renderRoutine}
        keyExtractor={this.keyExtractor}
      />
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Routines for you</Text>
        <Text style={styles.subheading}>Upcoming routines</Text>
        <this.routineList />
        <TouchableOpacity
          onPress={this.navigateToAllRoutines}
          style={styles.allRoutinesContainer}
        >
          <Text style={styles.allRoutinesText}>See all routines</Text>
          <Image source={images.chevronRight} style={styles.arrow} />
        </TouchableOpacity>
      </View>
    );
  }
}

export default RoutineDiscoveryCard;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#E8F4FF',
    paddingHorizontal: 12,
  },
  title: {
    color: colors.black,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    marginTop: 16,
  },
  subheading: {
    color: colors.subtitle,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    marginTop: 4,
    marginBottom: 8,
  },
  allRoutinesContainer: {
    borderTopWidth: 1,
    borderColor: colors.white,
    paddingVertical: 12,
    marginTop: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  allRoutinesText: {
    color: colors.cta.lightBlue,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
  },
  arrow: {
    height: 16,
    width: 16,
    resizeMode: 'contain',
    tintColor: colors.cta.lightBlue,
  },
});
