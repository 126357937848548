import {
  Text,
  View,
  TouchableOpacity,
  Image,
  Keyboard,
  SafeAreaView,
  KeyboardAvoidingView,
} from 'react-native';
import React, { PureComponent } from 'react';
import Modal from 'react-native-modal';
import styles from './styles';
import images from '../../../theme/Images';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import colors from '../../../theme/Colors';
import { editProfile } from '../../../actions/ActionTypes';
import Utility from '../../../utils/Utility';
import { TextField } from 'react-native-material-textfield';
import { REGEX } from '../../../config/Constants';
import { withEither } from '../../../lib/Monads';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class AddEmailModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      emailId: '',
      isNotValidEmail: false,
      showLoader: false,
    };
    this.ref = [];
    this.emailRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isVisible && this.props.isVisible) {
      this.setFocusToEmail();
    }
  }

  setFocusToEmail = () => {
    setTimeout(() => {
      try {
        this.emailRef?.current?.focus();
      } catch (error) {}
    }, 100);
  };

  validate = (email) => {
    try {
      return REGEX.EMAIL.test(email);
    } catch (err) {
      return false;
    }
  };

  addEmail = (value) => {
    this.setState({ emailId: value, isNotValidEmail: false });

    if (value?.length > 0 && !this.validate(value)) {
      this.setState({
        isNotValidEmail: true,
      });
    }
  };

  cancelpressed = () => {
    const { onDismiss } = this.props;
    onDismiss();
    Keyboard.dismiss();
  };

  onPressAddEmailAddress = () => {
    const { emailId } = this.state;
    const { editProfile, onPressAddEmail } = this.props;
    if (!Utility.validateEmail(emailId)) {
      this.setState({
        isNotValidEmail: true,
      });
      return;
    }
    this.setState({
      isNotValidEmail: false,
    });
    const user = {};
    user.email = emailId;
    Keyboard.dismiss();
    this.setState({
      showLoader: true,
    });
    editProfile(user, {}, (callbackData, success) => {
      if (success) {
        setTimeout(() => {
          if (onPressAddEmail) {
            onPressAddEmail(emailId);
          }
          this.setState({
            showLoader: false,
          });
        }, 1000);
        this.cancelpressed();
      } else {
        this.cancelpressed();
      }
    });
  };

  isIosFn = () => Utility.isIOS();

  customKeyboardAvoid = withEither(this.isIosFn, KeyboardAvoidingView)(View);

  render() {
    const { isVisible } = this.props;
    const { isNotValidEmail, emailId } = this.state;
    const email = Utility.isPresent(emailId) ? emailId : '';
    return (
      <>
        <Modal
          isVisible={isVisible}
          style={styles.bottomModal}
          onBackButtonPress={this.cancelpressed}
          onBackdropPress={this.cancelpressed}
        >
          <this.customKeyboardAvoid behavior="position">
            <View style={styles.addEmailContentContainer}>
              <View style={styles.modalHeadingTextContainer}>
                <Text style={styles.modalHeadingText}>Add your email</Text>
                <Text style={styles.modalSubHeadingText}>
                  in case we are not able to reach you via call
                </Text>
                <TouchableOpacity
                  style={styles.crossTouchableOpacity}
                  onPress={this.cancelpressed}
                >
                  <Image source={images.cross} style={styles.modalCrossIcon} />
                </TouchableOpacity>
              </View>
              <View style={styles.emailTextFieldStyle}>
                <TextField
                  label="Email"
                  lineWidth={1.5}
                  activeLineWidth={1}
                  blurOnSubmit={false}
                  value={email}
                  onChangeText={this.addEmail}
                  ref={this.emailRef}
                  hideLabel={false}
                  error={isNotValidEmail ? 'Enter a valid email' : null}
                  inputContainerStyle={{
                    borderBottomColor: colors.lightGrey,
                  }}
                  textContentType="emailAddress"
                  returnKeyType="next"
                  autoCompleteType="email"
                  keyboardType="email-address"
                  autoCapitalize={false}
                  enablesReturnKeyAutomatically
                  tintColor={colors.silver}
                />
              </View>
              <FoxyShadowButton
                width={Utility.getScreenWidth() - 36}
                style={styles.foxyShadowButton}
                title={'Add my email'}
                backgroundColor={colors.codGray}
                firstColor={colors.black}
                secondColor={colors.black}
                underlayColor={colors.black}
                borderRadius={4}
                onPress={this.onPressAddEmailAddress}
                Loading={this.state.showLoader}
              />
            </View>
          </this.customKeyboardAvoid>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ editProfile }, dispatch),
});

export default connect(null, mapDispatchToProps)(AddEmailModal);
