export const ADD_PRODUCT_ID_IN_PRODUCT_VIEWS =
  'ADD_PRODUCT_ID_IN_PRODUCT_VIEWS';

export const ADD_MY_BROWSING = 'ADD_MY_BROWSING';

export const GET_RECENTLY_VIEWED_PRODUCTS = 'GET_RECENTLY_VIEWED_PRODUCTS';

export const LOGOUT_USER = 'LOGOUT_USER';

export function addProductIdInProductViews(id) {
  return {
    type: ADD_PRODUCT_ID_IN_PRODUCT_VIEWS,
    data: { id },
  };
}

export function addMyBrowsing(data) {
  return {
    type: ADD_MY_BROWSING,
    data,
  };
}
