import React, { Component } from 'react';
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Image,
} from 'react-native';
import Modal from 'react-native-modal';
import RadioButton from 'react-native-radio-button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RemoteConfig from '../../utils/RemoteConfig';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import { updateCustomerStatus } from '../../actions/OrderActions';
import { AnalyticsManager } from '../../analytics';

class OptionsModal extends Component {
  constructor(props) {
    super(props);
    this.reasons = this.getReasonsFromRemoteConfig();

    this.state = {
      selectedValue: -1,
      selectedReason: '',
      showLoader: false,
    };
  }

  getReasonsFromRemoteConfig = () => {
    const remoteValues = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.update_shipment_status),
    );
    const reasonsArr = [];
    remoteValues?.forEach((element) => {
      reasonsArr?.push(element?.reason);
    });
    return reasonsArr;
  };

  onReasonSelected = (index, reason) => {
    this.setState({ selectedValue: index, selectedReason: reason });
  };

  renderRadioBoxes = ({ item, index }) => {
    const { selectedValue } = this.state;
    return (
      <TouchableOpacity
        style={styles.radioRow}
        onPress={() => this.onReasonSelected(index, item)}
      >
        <RadioButton
          outerColor={colors.subtitle}
          innerColor={colors.foxyPink}
          isSelected={selectedValue === index}
          size={12}
          onPress={() => this.onReasonSelected(index, item)}
        />
        <Text style={styles.radioText}>{`${item}`}</Text>
      </TouchableOpacity>
    );
  };

  keyExtractor = (item) => item.id;

  addShipmentLostCustomerStatus = () => {
    const { onDismiss, updateCustomerStatus, id, addOrderActionCard, status } =
      this.props;
    const { selectedReason } = this.state;
    this.setState({
      showLoader: true,
    });

    AnalyticsManager.logEvent('modal_action', {
      modal_name: 'customer_status',
      customer_status: selectedReason,
      state: status,
      entity_type: id?.split('-')[0],
      entity_id: id?.split('-')[1],
    });

    const shipmentId = id.split('-')[1];
    if (id.split('-')[0] !== 'shipment') {
      return;
    }

    const body = {
      shipment: {
        customer_status: selectedReason,
      },
    };

    updateCustomerStatus(shipmentId, body, () => {
      this.setState({
        showLoader: false,
      });

      addOrderActionCard();
      onDismiss();
    });
  };

  render() {
    const { isVisible, onDismiss } = this.props;
    const { showLoader, selectedValue } = this.state;
    return (
      <Modal
        isVisible={isVisible}
        style={styles.bottomModal}
        onBackButtonPress={onDismiss}
        onBackdropPress={onDismiss}
      >
        <View style={styles.modalHeadingTextContainer}>
          <Text style={styles.modalHeadingText}>Update Shipment Status </Text>
          <View style={styles.crossButton}>
            <TouchableOpacity
              style={styles.crossTouchableOpacity}
              onPress={onDismiss}
            >
              <Image source={images.cross} style={styles.crossIcon} />
            </TouchableOpacity>
          </View>
        </View>
        <View>
          <Text style={styles.feedbackText}>
            In case you haven’t received your shipment, we’ll reship products in
            no time
          </Text>
        </View>
        <FlatList
          data={this.reasons}
          style={styles.reasonsFlatlistStyle}
          showsHorizontalScrollIndicator={false}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderRadioBoxes}
          extraData={selectedValue}
          scrollEnabled={false}
        />

        <FoxyShadowButton
          width={Utility.getScreenWidth() - 36}
          style={styles.foxyShadowButton}
          title={'Update Shipment Status'}
          backgroundColor={colors.codGray}
          firstColor={colors.black}
          secondColor={colors.black}
          underlayColor={colors.black}
          borderRadius={4}
          onPress={this.addShipmentLostCustomerStatus}
          Loading={showLoader}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateCustomerStatus,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(OptionsModal);

const styles = StyleSheet.create({
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  modalHeadingTextContainer: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    flexDirection: 'row',
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingHorizontal: 12,
    display: 'flex',
    marginTop: 24,
  },
  modalHeadingText: {
    flex: 1,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    fontSize: 18,
    justifyContent: 'center',
  },
  feedbackText: {
    marginVertical: 12,
    color: colors.subtitle,
    marginHorizontal: 12,
  },
  reasonsFlatlistStyle: {
    paddingVertical: 10,
    marginBottom: 12,
    width: '100%',
    paddingHorizontal: 12,
  },
  foxyShadowButton: {
    marginBottom: Utility.isAndroid ? 22 : 42,
    marginHorizontal: 16,
  },
  radioRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20,
  },
  radioText: {
    marginLeft: 16,
    marginTop: 0,
    fontFamily: 'Roboto',
    fontSize: size.h3,
    color: colors.black,
    fontWeight: '400',
  },
  animationStyle: {
    height: 200,
    width: 200,
  },
  crossTouchableOpacity: {
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  crossButton: {
    // flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  crossIcon: {
    tintColor: colors.foxyBlack,
    height: 12,
    width: 12,
  },
});
