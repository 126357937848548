import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import TimeUtility from '../../utils/TimeUtility';
import WithNavigation from '../../utils/WithNavigation';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { interpolateString } from '../../utils/StringUtility';

class ProductDelayTray extends PureComponent {
  render() {
    const { orderCampaign: { expected_instock_date = '' } = {} } = this.props;
    const preOrderDate = expected_instock_date ?? '';
    if (Utility.isBlank(preOrderDate)) {
      return null;
    }
    const advanceOrderCampaignProductText = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.advance_order_campaign_product_text,
    );
    return (
      <View style={styles.container}>
        <View style={styles.textContainer}>
          {advanceOrderCampaignProductText ? (
            <Text style={styles.textNormal}>
              {interpolateString(advanceOrderCampaignProductText, {
                date: preOrderDate,
              })}
            </Text>
          ) : (
            <>
              <Text style={styles.textNormal}>
                Item is expected to ship on or before
              </Text>
              <Text style={styles.textBold}> {preOrderDate}</Text>
            </>
          )}
        </View>
      </View>
    );
  }
}

export default WithNavigation(ProductDelayTray);

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    backgroundColor: '#E8F4FF',
    position: 'absolute',
    zIndex: 1,
    bottom: Utility.isAndroid() ? 68 : 78,
    alignItems: 'center',
    height: 28,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12,
  },
  textNormal: {
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  textBold: {
    fontSize: size.h4,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginTop: Utility.isIOS() ? 1 : 0,
    flex: 1,
  },
});
