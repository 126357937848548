// Dependencies
import React, { Component } from 'react';
import { View, TouchableOpacity, Image, StyleSheet, Text } from 'react-native';
import {
  ProgressBarAndroid,
  ProgressViewIOS,
} from '../../libraries/NativeLibraries';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Video from '../../libraries/ReactNativeVideo';
import Images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import Card from '../../lib/Card';
import ProgressBarAnimated from '../../lib/ProgressBarAnimated';
import { publishPost } from '../../actions/UploadPostActions';
import {
  updateLastVideoUpload,
  refreshMyProfile,
  cancelUpload,
  setVideoUploadProgress,
} from '../../actions/ActionTypes';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import RNFS from '../../libraries/ReactNativeFs';
import images from '../../theme/Images';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import _ from 'lodash';
class UploadVideoBanner extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   progress: 0,
    //   status: 'inProgress',
    // };

    this.debounceUploadingProgress = _.throttle(
      this.props.setVideoUploadProgress,
      1000,
    );
  }

  uploadVideoBannerConstant = {
    heading: {
      in_progress: 'Uploading your Video',
      default: 'Uploading your Video',
      failed: 'Video upload failed, please retry',
    },
    subHeading: 'We’ll review your video once it gets uploaded',
  };

  onRetryTapped = () => {
    const { currentPostId } = this.props;

    this.setState({ status: 'inProgress' });
    if (!Utility.isBlank(currentPostId)) {
      console.tron.log('retry tapped1', currentPostId, this.props.postData);
      this.uploadVideo();
    } else {
      const {
        publishPost,
        lastVideoUpload: { id, postData },
      } = this.props;
      console.tron.log('retry tapped2', currentPostId, this.props.postData);
      publishPost(id, postData, (value) => {
        this.uploadingProgress(value);
      });
    }
  };

  uploadingProgress = (value) => {
    /**
     * Remove local state. and adding progress to redux so that we can control uploading progress in various cases
     *  1 - Retry button click on notification for video upload
     *  2 - Auto start uploading on app start again, if last time it was killed by user.
     *  With local state its impossible to handle progress from various sources.
     */
    const { setVideoUploadProgress } = this.props;

    if (value < 100) {
      console.tron.log('Current Value os =', value);
      setVideoUploadProgress(value);
      // this.debounceUploadingProgress(value);
    }
  };

  fireVideoUploadAnalytics = (postData) => {
    const { previousScreen } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.MY_PROFILE) {
      RNFS.stat(postData.fileUrl)
        .then((stat) => {
          AnalyticsManager.logEvent(EventType.artistEvents.VIDEO_UPLOAD_START, {
            [EventParameterKey.VIDEO_DURATION]: postData.duration,
            [EventParameterKey.FILE_SIZE]: stat.size / 1000,
          });
        })
        .catch((err) => {
          AnalyticsManager.logEvent(EventType.artistEvents.VIDEO_UPLOAD_START, {
            [EventParameterKey.VIDEO_DURATION]: postData.duration,
          });
        });
    }
  };

  uploadVideo = () => {
    const { publishPost, currentPostId, postData, updateLastVideoUpload } =
      this.props;
    this.fireVideoUploadAnalytics(postData);

    publishPost(currentPostId, postData, (value) => {
      this.uploadingProgress(value);
    });
    const data = { id: currentPostId, postData };
    updateLastVideoUpload(data);
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      lastVideoUpload: { status = '' },
    } = this.props;
    if (this.props.isUploading) {
      if (
        Utility.isBlank(status) &&
        !Utility.isBlank(this.props.currentPostId)
      ) {
        this.uploadVideo();
      }
    } else {
      if (prevProps.isUploading) {
        this.fireVideoUploadSuccessAnalytics(
          prevProps.lastVideoUpload.postData,
        );
        this.props.refreshMyProfile();
      }
    }
  }

  fireVideoUploadSuccessAnalytics = (postData) => {
    const { forGift = false } = this.props;
    if (!Utility.isBlank(postData)) {
      RNFS.stat(postData.fileUrl)
        .then((stat) => {
          AnalyticsManager.logEvent(
            EventType.artistEvents.VIDEO_UPLOAD_FINISH,
            {
              [EventParameterKey.VIDEO_DURATION]: postData.duration,
              [EventParameterKey.FILE_SIZE]: stat.size / 1000,
              [EventParameterKey.IS_WELCOME_VIDEO]: forGift,
            },
          );
        })
        .catch((err) => {
          AnalyticsManager.logEvent(
            EventType.artistEvents.VIDEO_UPLOAD_FINISH,
            {
              [EventParameterKey.VIDEO_DURATION]: postData.duration,
              [EventParameterKey.IS_WELCOME_VIDEO]: forGift,
            },
          );
        });
    }
  };

  cancelUpload = () => {
    const { cancelUpload } = this.props;
    cancelUpload();
  };

  retry = () => {
    // const { status } = this.state;
    const {
      lastVideoUpload: { status = '' },
    } = this.props;

    if (status !== 'failed') {
      return null;
    }

    return (
      <>
        <TouchableOpacity
          onPress={this.onRetryTapped}
          style={styles.retryButton}
        >
          <Image
            source={Images.myProfile.videoUploadRetry}
            style={styles.retryImage}
          />
          <Text allowFontScaling={false} style={styles.retryText}>
            RETRY
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.crossStyle} onPress={this.cancelUpload}>
          <Image
            source={images.cross}
            style={{ tintColor: colors.foxyBlack }}
          />
        </TouchableOpacity>
      </>
    );
  };

  androidProgressBar = (props) => {
    const { progressColor, progress } = props;
    return (
      <ProgressBarAndroid
        styleAttr="Horizontal"
        color={progressColor}
        progress={progress}
        indeterminate={false}
        style={{
          width: Utility.getScreenWidth() - 124,
          height: 6,
          borderColor: colors.border,
          borderRadius: 6,
        }}
      />
    );
  };

  iosProgressBar = (props) => {
    const { progressColor, progress } = props;
    return (
      <ProgressViewIOS
        styleAttr="Horizontal"
        color={progressColor}
        progress={progress}
        indeterminate={false}
        style={{
          width: Utility.getScreenWidth() - 124,
          height: 6,
          borderColor: colors.border,
          borderRadius: 6,
        }}
      />
    );
  };

  progressBar = (props) => {
    const { progressColor, progress } = props;
    if (Utility.isAndroid()) {
      return (
        <this.androidProgressBar
          progressColor={progressColor}
          progress={progress}
        />
      );
    }
    return (
      <this.iosProgressBar progressColor={progressColor} progress={progress} />
    );
  };

  render() {
    // const { status } = this.state;
    const {
      currentPostId,
      postData,
      isUploading,
      lastVideoUpload: { progress = 0, status = 'default' },
    } = this.props;

    const progressColor = status === 'failed' ? '#ff0000' : colors.dodgerBlue;
    if (Utility.isBlank(currentPostId)) {
      return null;
    }
    // status blank means either progress is not started or its success
    if (status === '') {
      return null;
    }

    const fileUrl = postData.fileUrl;
    return (
      <Card borderWidth={1} style={styles.bannerContainer}>
        <Video
          style={styles.thumbnail}
          controls={false}
          source={{ uri: fileUrl }}
          paused
        />
        <View style={styles.infoContainer}>
          <View style={styles.headingContainer}>
            <Text style={styles.heading}>
              {this.uploadVideoBannerConstant.heading[status]}
            </Text>
            <this.retry />
          </View>
          <View style={styles.subheadingContainer}>
            <Text style={styles.subHeading}>
              {this.uploadVideoBannerConstant.subHeading}
            </Text>
          </View>
          <View style={styles.progressContainer}>
            <Text allowFontScaling={false} style={styles.progressText}>
              {`${Math.ceil(progress * 100)} %`}
            </Text>
            <View style={styles.prpgressBarContainer}>
              <this.progressBar
                progressColor={progressColor}
                progress={progress}
              />
              {/* <ProgressBarAndroid
                styleAttr='Horizontal'
                color={progressColor}
                progress={progress}
                indeterminate={false}
                style={{
                  width: Utility.getScreenWidth() - 124,
                  height: 6,
                  borderColor: colors.border,
                  borderRadius: 6,
                }}
              /> */}
              {/* <ProgressBarAnimated
                width={Utility.getScreenWidth() - 124}
                value={progress * 100}
                maxValue={100}
                height={6}
                backgroundColor={progressColor}
                borderColor={colors.border}
                underlyingColor={colors.border}
              /> */}
            </View>
          </View>
        </View>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  // showCongratulationsModal: state.UserAccountInfo.artistOnboarding.showCongratulationsModal,
  showCongratulationsModal: false,
  isUploading: state.UserAccountInfo.isUploading,
  lastVideoUpload: state.UserAccountInfo.lastVideoUpload || {},
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      publishPost,
      updateLastVideoUpload,
      refreshMyProfile,
      cancelUpload,
      setVideoUploadProgress,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(UploadVideoBanner),
);

const styles = StyleSheet.create({
  bannerContainer: {
    height: 72,
    width: Utility.getScreenWidth() - 24,
    flexDirection: 'row',
    alignItems: 'center',
    margin: 12,
    marginTop: 6,
    borderRadius: 4,
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  thumbnail: {
    height: 56,
    width: 40,
    margin: 8,
    backgroundColor: colors.background,
    borderRadius: 3,
  },
  infoContainer: {
    height: '100%',
    width: Utility.getScreenWidth() - 24 - 40 - 16,
    justifyContent: 'space-evenly',
  },
  headingContainer: {
    flexDirection: 'row',
    width: '100%',
    flex: 0.35,
    alignSelf: 'center',
  },
  subHeadingContainer: {
    width: '100%',
    flex: 0.35,
  },
  progressContainer: {
    flexDirection: 'row',
    width: '100%',
    flex: 0.3,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  heading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  subHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  retryText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#ff0000',
    marginLeft: 4,
  },
  progressText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 10,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    width: 32,
  },
  prpgressBarContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 0,
    marginRight: 16,
  },
  retryButton: {
    height: '100%',
    flexDirection: 'row',
    position: 'absolute',
    right: 32,
    alignSelf: 'center',
  },
  retryImage: {
    height: 10,
    width: 10,
    marginTop: 2,
  },
  crossStyle: {
    position: 'absolute',
    top: -4,
    right: 4,
    height: 24,
    width: 24,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
