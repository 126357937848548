import React from 'react';
import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';

export const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.black,
  },

  webviewLoadContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
