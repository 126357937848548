import React, { Component } from 'react';
import { debounce } from 'lodash';
import { ActivityIndicator, View, BackHandler, StyleSheet } from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProductRatings } from '../../actions/RatingAndReviewsActions';
import ProductAllReviews from '../../components/productRatings/AllReviews';
import StaticNavigationHeader from '../../components/shared/StaticNavigationHeader';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import SortAndFilter from '../../components/productRatings/SortAndFilterRatings';
import colors from '../../theme/Colors';
import ServerError from '../../components/shared/ServerError';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop } from '../../utils/BooleanUtility';
import { getFullSlugFromName } from '../../utils/StringUtility';

export class AllReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingData: [],
      rating: '',
      ratings_count: 0,
      reviews_count: 0,
      ratingSelected: [],
      filterRatings: [],
      showVerifiedOnly: false,
      sort_by: 'most_helpful',
      serverError: false,
      retryLoading: false,
      isLoaderVisible: false,
    };
    const { route } = this.props;
    this.productId = route.params?.productId;
    this.productSlug = route.params?.productSlug || getFullSlugFromName(route.params?.shortSlug, 'product');
    this.itemData = route.params?.itemData;
    this.variants = route.params?.variants;
    this.paginationEnded = false;
    this.debounceNavigateToAddress = debounce(this.navigateToAddress, 1000, {
      leading: true,
      trailing: false,
    });
    this.pageNo = 0;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    setTimeout(this.getRatings, 0);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  isLoaderVisible = (isVisible) => {
    this.setState({
      isLoaderVisible: isVisible,
    });
  };

  navigateToAddress = () => {
    const { navigation } = this.props;
    navigation.navigate('Address');
  };

  addSelectedRating = (rating) => {
    const { ratingSelected } = this.state;
    const newSelectedRating = ratingSelected.push(rating);
    this.setState({
      ratingSelected: newSelectedRating,
    });
  };

  getRatings = () => {
    const { getProductRatings } = this.props;
    const { showVerifiedOnly, filterRatings, sort_by } = this.state;
    const metaData = {
      productId: this.productId,
      productSlug: this.productSlug,
      page: this.pageNo,
      showVerifiedOnly,
      filterRatings,
      sort_by,
    };
    this.isLoaderVisible(true);
    this.setState({
      retryLoading: true,
    });
    getProductRatings(metaData, (success, response) => {
      this.isLoaderVisible(false);
      this.setState({
        serverError: !success,
        retryLoading: false,
      });
      if (success) {
        this.setState({
          rating: response.rating,
          ratings_count: response.ratings_count,
          reviews_count: response.reviews_count,
          ratingData: response.reviews,
        });
      }
    });
  };

  getPaginatedRatings = () => {
    const { getProductRatings, showToast } = this.props;
    const { ratingData, filterRatings } = this.state;
    if (this.paginationEnded) {
      return;
    }
    const metaData = {
      productId: this.productId,
      productSlug: this.productSlug,
      page: ++this.pageNo,
      filterRatings,
    };
    getProductRatings(metaData, (success, response) => {
      const { reviews = [] } = response;
      if (reviews.length < 5) {
        this.paginationEnded = true;
      }
      this.setState({
        serverError: !success,
      });
      this.isLoaderVisible(false);
      if (success) {
        this.setState({
          ratingData: [...ratingData, ...reviews],
        });
      }
    });
  };

  onRatingClick = (rating) => {
    const { filterRatings } = this.state;

    const ratings = filterRatings;

    if (ratings.includes(rating)) {
      ratings.splice(filterRatings.indexOf(rating), 1);
    } else {
      ratings.push(rating);
    }
    this.setState(
      {
        filterRatings: ratings,
      },
      () => {
        this.pageNo = 0;
        this.paginationEnded = false;
        this.getRatings();
        this.setState({ ratingData: [] });
      },
    );
  };

  toggleVerifiedOnly = () => {
    this.setState(
      (prevState) => ({
        showVerifiedOnly: !prevState.showVerifiedOnly,
      }),
      () => {
        this.pageNo = 0;
        this.paginationEnded = false;
        this.getRatings();
        this.setState({ ratingData: [] });
      },
    );
  };

  onEndReached = () => {
    this.getPaginatedRatings();
  };

  sortRating = (value) => {
    this.setState(
      {
        sort_by: value,
      },
      () => {
        this.pageNo = 0;
        this.paginationEnded = false;
        this.getRatings();
        this.setState({ ratingData: [] });
      },
    );
  };

  activityIndicator = () => {
    const { isLoaderVisible } = this.state;
    if (!isLoaderVisible) {
      return null;
    }
    return (
      <View style={styles.activityIndicator}>
        <ActivityIndicator animating color={colors.black} size="large" />
      </View>
    );
  };

  render() {
    const {
      ratingData,
      rating,
      ratings_count,
      reviews_count,
      showVerifiedOnly,
      filterRatings,
      sort_by,
      serverError,
      retryLoading,
    } = this.state;
    const { navigation } = this.props;
    return (
      <MenuProvider>
        <View style={{ backgroundColor: colors.background, flex: 1 }}>
          {!isDesktop() && <StaticNavigationHeader title="All reviews" />}
          <ErrorBoundary
            showServerError={serverError}
            onPressReload={this.getRatings}
            loading={retryLoading}
            hideHeader
          >
            <SortAndFilter
              onRatingClick={this.onRatingClick}
              toggleVerifiedOnly={this.toggleVerifiedOnly}
              filterRatings={filterRatings}
              showVerifiedOnly={showVerifiedOnly}
              sort_by={sort_by}
              sortRating={this.sortRating}
            />
            <ProductAllReviews
              ratingData={ratingData}
              rating={rating}
              ratings_count={ratings_count}
              reviews_count={reviews_count}
              onEndReached={this.onEndReached}
              productId={this.productId}
              productSlug={this.productSlug}
              navigation={navigation}
              variants={this.variants}
              itemData={this.itemData}
            />
            <this.activityIndicator />
          </ErrorBoundary>
        </View>
      </MenuProvider>
    );
  }
}

const styles = StyleSheet.create({
  activityIndicator: {
    flex: 1,
    position: 'absolute',
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    zIndex: 1,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getProductRatings,
    },
    dispatch,
  ),
});

export default withNavigation(connect(null, mapDispatchToProps)(AllReviews));
