import Config from '../../libraries/ReactNativeConfig';
import MoeEngageHandler from './MoeEngageHandler';
import WebEngageHandler from './WebEngageHandler';
import Utility from '../Utility';

const Handler = {
  'app.ekanek.notifications.WebEngageHandler': WebEngageHandler,
  'app.ekanek.notifications.MoeEngageHandler': MoeEngageHandler,
};

export default class NotificationsHandler {
  static setEventListeners = onNotificationClick => {
    try {
      Handler[Config.NOTIFICATIONS_HANDLER].setEventListeners(
        onNotificationClick,
      );
      this.initializeAlternativeSdkEventListener(onNotificationClick);
    } catch (e) {
      console.tron.log(
        `Notification handler setEventListeners error: ${e}`,
        Config.NOTIFICATIONS_HANDLER,
      );
    }
  };

  static initialize = handleUrl => {
    try {
      Handler[Config.NOTIFICATIONS_HANDLER].initialize(handleUrl);
    } catch (e) {
      console.tron.log(
        `Notification handler initialize error: ${e}`,
        MoeEngageHandler,
        Handler,
      );
    }
  };

  static removeEventListeners = () => {
    try {
      Handler[Config.NOTIFICATIONS_HANDLER].removeEventListeners();
    } catch (e) {
      console.tron.log(
        `Notification handler removeEventListeners error: ${e}`,
        Config.NOTIFICATIONS_HANDLER,
      );
    }
  };

  static initializeAlternativeSdkEventListener = onNotificationClick => {
    if (Utility.isPresent(Config.ALTERNATIVE_NOTIFICATIONS_HANDLER)) {
      Handler[Config.ALTERNATIVE_NOTIFICATIONS_HANDLER].setEventListeners(
        onNotificationClick,
      );
    }
  };
}
