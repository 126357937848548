// Dependencies
import { StyleSheet, Dimensions } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';
import StylesConstants from '../../../utils/StylesConstants';

const style = StyleSheet.create({
  container: { flex: 1 },
  wrapper: {
    flex: 1,
    flexDirection: 'column',
  },
  headerTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h2,
    color: colors.foxyBlack,
    marginLeft: 15,
  },
  backButtonStyle: {
    tintColor: colors.foxyBlack,
  },
  retakeSelfieButton: {
    fontFamily: 'Roboto-Medium',
    marginLeft: 7,
    fontSize: size.h4,
    color: colors.foxyBlack,
  },

  headerBar: {
    height: StylesConstants.getHeaderHeight(),
    flexDirection: 'row',

    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  bottomButtonContainer: {
    flex: 0.2,
    height: Utility.getButtonHeight(),
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 15,
  },

  bottomButton: {
    borderRadius: 4,
    height: Utility.getButtonHeight(),
    width: Utility.getButtonWidth(),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.green,
  },
  imageSection: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyle: { aspectRatio: 9 / 15 },
  overlay: {
    height: '100%',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'absolute',
  },

  retakeCameraContainer: {
    width: 110,
    height: 35,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.border,
  },

  completionCount: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.silver,
    padding: 5,
  },
  imageViewContainer: {
    height: 24,
    width: 24,
    padding: 20,
    position: 'absolute',
    right: 8,
    top: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageView: { height: 12, width: 12 },
  textContainer: {
    zIndex: 1,
    height: 32,
    width: '100%',
    alignSelf: 'center',
    position: 'absolute',
    bottom: '28%',
  },
  text: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  foxyButton: {
    marginBottom: 20,
    overflow: 'hidden',
    marginLeft: 16,
    marginRight: 16,
  },
});

export default style;
