import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
  Linking,
} from 'react-native';
import { styles as FeatureStyles } from './styles';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import {
  AnalyticsUtilty,
  EventType,
  EventParameterKey,
  EventParameterValue,
  AnalyticsManager,
} from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';

export default class FollowDynamicCard extends PureComponent {
  componentDidMount() {
    Utility.firstFollowCardShowing = true;
  }
  text = (props) => {
    const { text, style } = props;
    if (Utility.isBlank(text)) {
      return null;
    }
    return <Text style={style}>{text}</Text>;
  };

  header = (props) => {
    const { iconSource, title, isDismissable } = props;
    return (
      <View style={FeatureStyles.row}>
        <Image
          source={{
            uri: Utility.getSmallImageUrl(iconSource, 30, 30),
          }}
          style={{ height: 24, width: 24 }}
        ></Image>
        <this.text text={title} style={FeatureStyles.headerText} />
        <this.crossButton />
      </View>
    );
  };

  buttonContainer = (props) => {
    return (
      <TouchableWithoutFeedback
        style={FeatureStyles.buttonContainer}
        onPress={this.onPressFollowButton}
      >
        <View style={FeatureStyles.buttonContainer}>
          <this.text style={FeatureStyles.followButtonText} text='Follow' />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  onPressFollowButton = () => {
    const { itemData, listData } = this.props;
    if (itemData.destination.includes('youtube')) {
      AnalyticsUtilty.fireItemClickEvent(
        'feed',
        itemData.id,
        itemData.type,
        itemData.title,
        0,
        listData.id,
        listData.display,
        listData.name,
        listData.display_order,
        '',
        '',
      );
    } else if (itemData.destination.includes('instagram')) {
      AnalyticsUtilty.fireItemClickEvent(
        'feed',
        itemData.id,
        itemData.type,
        itemData.title,
        0,
        listData.id,
        listData.display,
        listData.name,
        listData.display_order,
        '',
        '',
      );
    }

    if (itemData.destination.includes('contacts')) {
      this.removeCard();
    } else {
      Linking.openURL(itemData.destination)
        .then((response) => {
          this.removeCard();
        })
        .catch((error) => {});
    }
  };

  removeCard = () => {
    const { removeFollowCard, itemData } = this.props;
    if (itemData.destination.includes('youtube')) {
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.DYNAMIC_CARD_DISMISS,
        {
          [EventParameterKey.TYPE]: EventParameterValue.MEDIUM.YOUTUBE,
        },
      );
    } else if (itemData.destination.includes('instagram')) {
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.DYNAMIC_CARD_DISMISS,
        {
          [EventParameterKey.TYPE]: EventParameterValue.MEDIUM.INSTAGRAM,
        },
      );
    }
    removeFollowCard(itemData.id);
    Utility.currentActiveFollowCard.id = undefined;
  };

  crossButton = () => {
    const { itemData } = this.props;
    if (!itemData.dismissable) {
      return null;
    }
    return (
      <TouchableWithoutFeedback
        onPress={this.removeCard}
        onPressIn={this.removeCard}
        hitSlop={Utility.getHitSlop()}
        style={{
          position: 'absolute',
          top: 10,
          right: 12,
          width: 22,
          height: 22,
        }}
      >
        <View
          style={{
            position: 'absolute',

            right: 3,
            width: 22,
            height: 22,
          }}
        >
          <Image
            source={images.cameraScreenIcons.popupClose}
            style={{ height: 22, width: 22 }}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  render() {
    const { itemData } = this.props;

    if (itemData.id !== Utility.currentActiveFollowCard.id) {
      return null;
    }

    return (
      <TouchableWithoutFeedback onPress={this.onPressFollowButton}>
        <View
          style={[
            FeatureStyles.followCardContainer,
            {
              backgroundColor: itemData.background_color || '#ffffff',
              width: Utility.getDynamicWidth(1, 0),
            },
          ]}
        >
          <this.header title={itemData.title} iconSource={itemData.image_url} />
          <this.text
            style={FeatureStyles.followCardDescriptionText}
            text={itemData.body}
          />
          <View style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <this.buttonContainer />
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
