import React, { PureComponent } from 'react';
import { View, Text, Image, Animated, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Slider from 'react-native-slider';
import TimeUtility from '../../utils/TimeUtility';
import PlayerMiniControls from './PlayerMiniControls';
// Style import
import { playerBottomControlsStyles as styles } from './Style';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import { ContentPlatforms } from '../../config/Constants';

class PlayerBottomControls extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: 0,
    };
    this.hitSlop = Utility.getHitSlop('slider');
  }

  // View Methods
  SliderView = () => {
    // return null;
    const { onSlidingStart, onSlidingComplete, duration } = this.props;
    const maxValue = duration > 0 ? duration : 100;
    return (
      <Slider
        step={1}
        maximumValue={maxValue}
        minimumValue={0}
        value={this.state.currentTime}
        maximumTrackTintColor={colors.maximumTrackTintColor}
        minimumTrackTintColor={colors.minimumTrackTintColor}
        style={styles.slider}
        thumbImage={images.mediaDetailPage.thumbIcon}
        thumbTintColor={colors.foxyPink}
        thumbStyle={styles.sliderThumbStyle}
        onSlidingStart={onSlidingStart}
        onSlidingComplete={onSlidingComplete}
        hitSlop={this.hitSlop}
      />
    );
  };

  ContentDurationLabel = () => {
    const { duration } = this.props;
    const contentDuration = TimeUtility.getTime(duration);
    return (
      <View style={styles.contentDurationLabel}>
        <Text style={styles.timeLabelStyle}>{contentDuration}</Text>
      </View>
    );
  };

  PlayButton = () => {
    const { paused, togglePlayPause } = this.props;
    const icon = paused
      ? images.mediaDetailPage.playVideoIcon
      : images.mediaDetailPage.pauseVideoIcon;
    return (
      <View style={styles.playPause}>
        <TouchableOpacity
          onPress={togglePlayPause}
          hitSlop={Utility.getHitSlop('playPause')}
        >
          <Image source={icon} style={styles.playPauseTouchable} />
        </TouchableOpacity>
      </View>
    );
  };

  playPauseButton = () => {
    return (
      <Animated.View>
        <this.PlayButton />
      </Animated.View>
    );
  };

  setCurrentTime = (currentTime) => {
    this.setState({ currentTime });
  };

  CurrentPlayedTimeLabel = () => {
    const { currentTime } = this.state;
    const currentPlayedTime = TimeUtility.getTime(currentTime);

    return (
      <View style={styles.currentPlayedTimeLabel}>
        <Text style={styles.timeLabelStyle}>
          {Utility.isBlank(currentPlayedTime) ? '00:00' : currentPlayedTime}
        </Text>
      </View>
    );
  };

  TimeDividerView = () => (
    <View style={styles.timeDividerView}>
      <Text style={styles.timeDividerText}>/</Text>
    </View>
  );

  render() {
    const { style, contentPlatform } = this.props;
    return (
      <View style={style}>
        {/* <View
          style={{
            width: '100%',
            height: 18,
            bottom: 22,
            flexDirection: 'row',
          }}
        > */}
        {/* <View style={styles.timeLabelContainer}>
            <this.CurrentPlayedTimeLabel />
            <this.TimeDividerView />
            <this.ContentDurationLabel />
          </View> */}
        <this.playPauseButton />
        <View style={styles.sliderContainer}>
          <this.SliderView />
        </View>
        <PlayerMiniControls {...this.props} />
        {/* </View> */}
      </View>
    );
  }
}

PlayerBottomControls.propTypes = {
  duration: PropTypes.number.isRequired,
  onSlidingStart: PropTypes.func.isRequired,
  onSlidingComplete: PropTypes.func.isRequired,
  togglePlayPause: PropTypes.func,
  paused: PropTypes.bool,
};

export default PlayerBottomControls;
