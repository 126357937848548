import _ from 'lodash';
import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import FastImageView from '../../components/FastImageView';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';
import ModalContainer from '../../components/modals/ModalContainer';

const styles = StyleSheet.create({
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingHorizontal: 12,
    paddingBottom: 24,
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  heading: {},
  title: {
    color: colors.black,
    fontSize: size.h1,
    fontFamily: 'Roboto-Regular',
    fontWeight: '700',
  },
  subtitle: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    marginTop: 4,
  },
  image: {
    height: (Utility.getScreenWidth() - 40) * (310 / 335),
    width: Utility.getScreenWidth() - 40,
    marginTop: 12,
  },
  bottomText: {
    color: colors.subtitle,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    textAlign: 'center',
    marginBottom: 12,
  },
  buttonContainer: {
    backgroundColor: colors.black,
    borderRadius: 4,
    height: 40,
    width: Utility.getScreenWidth() - 24,
    marginBottom: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },
  topIcon: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginTop: -20,
    marginBottom: 8,
  },
  modal_close_container: {
    position: 'absolute',
    zIndex: 1,
    top: 12,
    right: 12,
  },
  modal_close: {
    height: 23,
    width: 23,
    resizeMode: 'contain',
  },
  topImage: {
    position: 'absolute',
    top: -20,
    width: 40,
    height: 40,
    alignSelf: 'center',
  },
  dropShadow: {
    position: 'absolute',
    top: 0,
  },
});

class JoinProModal extends PureComponent {
  constructor(props) {
    super(props);
    this.image = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.join_pro_modal_image);
  }

  joinButton = () => {
    const { route } = this.props;
    const { modalAction = () => {} } = route.params;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={styles.buttonContainer}
        onPress={modalAction}
      >
        <Text style={styles.buttonText}>Join Annual Membership</Text>
      </DebouncedTouchableOpacity>
    );
  };

  closeModal = () => {
    const { navigation } = this.props;
    navigation.pop();
  };

  renderView = () => {
    const { route } = this.props;
    const { itemData: { name = '', product_name = '' } = {}, modalType = '' } =
      route.params;

    const typeString = _.startCase(modalType);
    return (
      <View style={styles.bottomModal}>
        <FastImageView style={styles.topIcon} source={images.clubStar} />
        <View style={styles.heading}>
          <Text style={styles.title}>Become an annual member</Text>
          <Text style={styles.subtitle}>
            Oops! This amazing {typeString} is available for FREE only to annual
            members. Join Annual Membership today
          </Text>
        </View>
        <FastImageView style={styles.image} source={this.image} />
        <Text style={styles.bottomText}>{name || product_name}</Text>
        <this.joinButton />
        <TouchableOpacity
          style={styles.modal_close_container}
          onPress={this.closeModal}
        >
          <FastImageView
            style={styles.modal_close}
            source={images.edge.modal_close}
          />
        </TouchableOpacity>
        <View style={styles.dropShadow}>
          <FastImageView source={images.edge.offer} style={styles.topImage} />
        </View>
      </View>
    );
  };

  render() {
    return (
      <ModalContainer>
        <this.renderView />
      </ModalContainer>
    );
  }
}

export default JoinProModal;
