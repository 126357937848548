import { takeEvery, put, select } from 'redux-saga/effects';

import {
  GET_PERSONALISED_LISTS,
  GET_USER_LOCATION,
} from '../actions/PersonalisedActions';
import { saveUserLocationInfo } from '../actions/ActionTypes';
import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

function* getPersonalisedLists(action) {
  const { slug = '', callback = () => {}, pageNo = 0 } = action;
  let url = `${URL.MY_LISTS}/${slug}`;
  if (pageNo > 0) {
    url = `${url}?page=${pageNo}`;
  }

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response?.status;
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* getUserLocation(action) {
  const { callback = () => {} } = action;
  try {
    const response = yield fetch(URL.LOCATION, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response?.status;
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      yield put(saveUserLocationInfo(json, new Date()));
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export default function* watchPersonalizedSaga() {
  yield takeEvery(GET_PERSONALISED_LISTS, getPersonalisedLists);
  yield takeEvery(GET_USER_LOCATION, getUserLocation);
}
