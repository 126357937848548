import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  stripContainer: {
    backgroundColor: '#E4F2FF',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingVertical: 8,
    paddingHorizontal: 16,
    paddingBottom: Utility.isIOS() ? 20 : 8,
  },
  stripContainerRedeemed: {
    backgroundColor: '#E4F2FF',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingVertical: 8,
    paddingHorizontal: 16,
    paddingBottom: Utility.isIOS() ? 88 : 70,
  },
  moreContainer: {
    flex: 1,
    paddingBottom: 8,
    backgroundColor: colors.background,
    overflow: 'visible',
  },
  listMoreContainer: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  cartTrayContentContainer: { flexDirection: 'row' },
  cartTrayCarouselContainer: {
    height: 42,
    width: Utility.getScreenWidth(),
    position: 'absolute',
    bottom: 0,
  },
  stripText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  stripTextCta: {
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.cta.lightBlue,
  },
  cartTraySp: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  cartTrayMrp: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    color: colors.silver,
    textDecorationLine: 'line-through',
    marginLeft: 6,
  },
  cartTrayOff: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyPink,
    marginLeft: 6,
  },
  viewBagButton: {
    width: 172,
    height: 48,
    borderRadius: 8,
    backgroundColor: '#52CB8C',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewBagButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
    color: '#fff',
  },
  navbarIconDefaultStyle: {
    width: 17.5,
    height: 17.5,
  },
  navbarIconStyle: {
    width: 20,
    height: 17.5,
    marginTop: 12,
    resizeMode: 'contain',
  },
  rightNavbarHeaderStyle: {
    flexDirection: 'row',
    marginRight: 16,
    width: 20,
    justifyContent: 'space-between',
  },
  navigationHeader: {
    position: 'absolute',
    height: 70,
    width: Utility.getScreenWidth(),
    backgroundColor: 'transparent',
    zIndex: 1,
    flexDirection: 'row',
  },
  navigationBackButtonContainer: { justifyContent: 'center', marginLeft: 16 },
  likeButtonContainer: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    justifyContent: 'center',
  },
  bannerStyle: {
    width: Utility.getScreenWidth(),
    height: 0.67 * Utility.getScreenWidth(),
    alignSelf: 'center',
    marginBottom: 12,
    resizeMode: 'cover',
    overflow: 'visible',
  },
  bannerImageStyle: {
    position: 'absolute',
    bottom: 0,
    width: Utility.getScreenWidth(),
    height: 0.75 * Utility.getScreenWidth(),
    alignSelf: 'center',
    resizeMode: 'cover',
  },
  imageOverlay: {
    position: 'absolute',
    bottom: 0,
    width: Utility.getScreenWidth(),
    height: 0.75 * Utility.getScreenWidth(),
    alignSelf: 'center',
    resizeMode: 'cover',
    // opacity: 0.3,
    // backgroundColor: colors.black,
  },
  infoContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  listHeading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    marginLeft: 16,
    marginRight: 64,
    letterSpacing: 0,
    color: colors.white,
    marginBottom: 16,
  },
  listSubHeading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    marginLeft: 16,
    marginRight: 16,
    letterSpacing: 0,
    marginTop: 8,
    color: colors.white,
    marginBottom: 16,
  },
  playAllButton: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    height: 48,
    width: 46,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  playAllStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
  },
  playIconStyle: {
    width: 20,
    height: 24,
    resizeMode: 'cover',
    marginRight: 8,
  },
  cartTrayCarouselContainer: {
    height: 48,
    width: Utility.screenWidth,
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
  },
  cartTrayContainer: {
    height: '100%',
    width: Utility.screenWidth,
    bottom: 0,
    backgroundColor: '#FEF7EFEF',
    zIndex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'visible',
  },
  cartTrayGradientContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  cartTrayContainerOffer: {
    width: '100%',
    // position: 'absolute',
    bottom: 0,
    backgroundColor: colors.white,
    zIndex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'visible',
    paddingHorizontal: 16,
    paddingTop: 12,
  },
  cartTrayContainerOfferContainer: {
    height: Utility.isIOS() ? 82 : 62,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    paddingBottom: Utility.isIOS() ? 20 : 0,
    backgroundColor: colors.white,
    zIndex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'visible',
  },
  cartTrayContainerOfferContainerWithStrip: {
    height: Utility.isIOS() ? 102 : 62,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    paddingBottom: Utility.isIOS() ? 20 : 0,
    backgroundColor: colors.white,
    zIndex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'visible',
  },
  cartTrayIcon: {
    marginLeft: 16,
    height: 20,
    width: 20,
  },
  cartText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 12,
    color: colors.foxyBlack,
  },
  viewCartButton: {
    position: 'absolute',
    right: 20,
    alignSelf: 'center',
  },
  viewButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.cta.lightBlue,
  },
  uploadingPostContainer: {
    height: 45,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.white,
    opacity: 0.95,
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    elevation: 5,
    shadowRadius: 2,
    shadowColor: colors.black,
    shadowOpacity: 0.5,
    shadowOffset: { width: 1, height: 2 },
    overflow: 'visible',
    paddingHorizontal: 16,
    justifyContent: 'space-between',
  },

  postThumbnail: {
    height: 30,
    width: 30,
    borderRadius: 4,
    elevation: 2,
    shadowRadius: 2,
    shadowColor: colors.black,
    shadowOpacity: 0.5,
    shadowOffset: { width: 1, height: 2 },
  },

  uploadingStatus: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 12,
  },

  containerEmptyState: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  bannerEmptyView: { height: Utility.isIOS() ? 44 + Utility.topInset : 70 },
  listBackground: { backgroundColor: colors.background },

  feedFooterContainer: {
    width: Utility.getScreenWidth(),
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerButtonView: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 16,
    borderColor: colors.foxyPink,
    borderWidth: 1,
  },
  footerTextHeading: {
    textAlign: 'center',
    color: '#979BAA',
    fontSize: 12,
    fontWeight: '400',
  },
  footerButtonText: {
    textAlign: 'center',
    color: colors.cta.lightBlue,
    fontSize: 14,
    marginTop: 8,
    fontWeight: '500',
    marginRight: 4,
  },
  footerChevronImage: {
    tintColor: '#4285F4',
    marginTop: 6,
  },
  footerSearchImage: {
    tintColor: '#4285F4',
    marginTop: 4,
  },
  footerTextAndImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  activityIndicatorContainer: {
    width: Utility.getScreenWidth(),
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadingTrayContainer: {
    height: 48,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.white,
    opacity: 0.95,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'visible',
    flexDirection: 'column',
  },
  uploadText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.black,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 16,
  },
  uploadStatusButton: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.cta.lightBlue,
  },
  toast: { position: 'absolute', bottom: 100 },
  uploadTrayIcon: { height: 20, width: 20, marginLeft: 12 },
  uploadTrayHeader: {
    flexDirection: 'row',
    width: Utility.getScreenWidth(),
    flex: 1.2,
  },
  uploadTrayButton: {
    position: 'absolute',
    right: 16,
    alignSelf: 'center',
  },
  searchInputContainer: {
    paddingVertical: 12,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    elevation: 2,
    zIndex: 10,
    marginBottom: 12,
  },
  searchInput: {
    height: 32,
    width: '85%',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    borderColor: '#B5B5B5',
    borderWidth: 1,
    marginLeft: 12,
    alignItems: 'center',
    flexDirection: 'row',
  },
  searchHeaderInput: {
    height: 32,
    width: '85%',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    borderColor: '#B5B5B5',
    borderWidth: 1,
    marginLeft: 12,
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 10,
  },
  searchHeaderPlaceholderText: {
    marginLeft: 36,
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.subtitle,
  },
  searchHeaderImage: { width: 16, height: 16, marginLeft: 10 },
  uploadSubHeadText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: size.h4,
    color: colors.subtitle,
    fontStyle: 'normal',
    letterSpacing: 0,
    // alignSelf: 'flex-start',
    // marginRight: 16,
  },
  uploadTrayProgressBarContainer: { marginLeft: 12, marginVertical: 8 },
  headerContainer: { flex: 2, flexDirection: 'row', alignItems: 'center' },
  descriptionContainer: {
    flex: 0.8,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: 4,
    // backgroundColor: 'blue',
    marginRight: 16,
  },
  descriptionContainerLarge: {
    flex: 1.4,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: 4,
    // backgroundColor: 'blue',
    marginRight: 16,
  },
  moreItemListContainer: {
    flex: 1,
    backgroundColor: colors.background,
  },
});

export default styles;
