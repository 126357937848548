import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import CouponCodePill from '../../cart/CouponCodePill';
import colors from '../../../theme/Colors';

export default function Coupons({ coupons = [] }) {
  if (!coupons.length) return null;
  return (
    <View style={styles.couponsContainer}>
      <Text style={styles.couponAppliedText}>Coupon(s) applied: </Text>
      {coupons.map((couponCode) => (
        <CouponCodePill
          couponCode={couponCode}
          style={styles.couponCodePill}
          key={couponCode}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  couponCodePill: {
    fontFamily: 'Roboto-Medium',
    fontSize: 10,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.ultraDarkBlue,
    marginHorizontal: 2,
    marginTop: 6,
    borderColor: colors.green,
    backgroundColor: '#F2FBF7',
    paddingHorizontal: 6,
    paddingVertical: 2,
    borderRadius: 2,
  },
  couponAppliedText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  couponsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 12,
  },
});
