export const FETCH_POINTS_HISTORY = 'FETCH_POINTS_HISTORY';
export const FETCH_POINTS_USAGE = 'FETCH_POINTS_USAGE';
export const POST_POINTS_USAGE = 'POST_POINTS_USAGE';

export const fetchPointsHistory = (callback, pageNo) => {
  return {
    type: FETCH_POINTS_HISTORY,
    callback,
    pageNo,
  };
};

export const fetchPointsUsage = (callback) => {
  return {
    type: FETCH_POINTS_USAGE,
    callback,
  };
};

export const postPointsUsage = (id, callback) => {
  return {
    type: POST_POINTS_USAGE,
    id,
    callback,
  };
};
