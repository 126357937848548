import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  Image,
  Text,
  TouchableOpacity,
} from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';
import { isWeb } from '../../../utils/BooleanUtility';

export default class AddressCard extends PureComponent {
  render() {
    const {
      locationIcon,
      locationType,
      pincode,
      isDefault,
      onAddressSetDefault,
      onEditAddress,
      address,
      showDeleteAlert,
      onAddressCardTap,
      isSelected,
      source,
    } = this.props;
    let { addressLine1 = '', addressLine2 = '' } = this.props;

    const cardTap =
      onAddressCardTap !== undefined ? onAddressCardTap : onEditAddress;

    const selectedColor = '#4285F41A';

    const backgroundColor = isSelected ? selectedColor : colors.white;

    if (addressLine1) {
      addressLine1 += ', ';
    }
    if (addressLine2) {
      addressLine2 += ' - ';
      addressLine2 = addressLine2?.replace(', India', '');
    }

    let subtitleStyle = isWeb()
      ? styles.addressTypeSubtitleWeb
      : styles.addressTypeSubtitle;

    if (isWeb() && source === 'consumerProfile') {
      subtitleStyle = styles.addressTypeSubtitleWebConsumerProfile;
    }

    return (
      <>
        <View
          style={[styles.addressContainer, { backgroundColor, width: '100%' }]}
        >
          <TouchableWithoutFeedback onPress={cardTap}>
            <View style={styles.row}>
              <View style={styles.addressCardInnerContainer}>
                <Image style={styles.iconContainer} source={locationIcon} />
                <View style={styles.addressLineContainer}>
                  <Text style={styles.addressTypeHead}>{locationType}</Text>
                  <Text style={subtitleStyle} numberOfLines={2}>
                    {`${addressLine1}${addressLine2}${pincode}`}
                  </Text>
                </View>
              </View>
              <TouchableOpacity
                onPress={onEditAddress}
                hitSlop={Utility.getHitSlop()}
                style={styles.editButtonContainer}
              >
                <Image style={styles.editIcon} source={images.edit} />
              </TouchableOpacity>
            </View>
          </TouchableWithoutFeedback>
        </View>
        <View style={styles.separator} />
      </>
    );
  }
}

const styles = StyleSheet.create({
  addressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: colors.borderDark,
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 12,
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    width: '100%',
  },
  addressTypeHead: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.cta.lightBlue,
  },
  addressTypeSubtitleWeb: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
    paddingEnd: isWeb() ? 30 : 0,
    width: Utility.getScreenWidth() - 50,
    flexShrink: 1,
  },
  addressTypeSubtitleWebConsumerProfile: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
    paddingEnd: isWeb() ? 30 : 0,
    width: Utility.getScreenWidth() - 80,
    flexShrink: 1,
  },
  addressTypeSubtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },
  separator: {
    height: 1,
    backgroundColor: colors.border,
    marginHorizontal: 12,
  },
  addressCardInnerContainer: {
    flexDirection: 'row',
    flex: 1,
    overflow: 'hidden',
  },
  iconContainer: { marginTop: 2, height: 18, width: 18, resizeMode: 'contain' },
  addressLineContainer: { paddingRight: 10, marginLeft: 8 },
  editButtonContainer: {
    marginRight: isWeb() ? 0 : 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  editIcon: { height: 18, width: 18, resizeMode: 'contain' },
});
