import React, { Component } from 'react';
import { View } from 'react-native';
import { ProgressBarAndroid, ProgressViewIOS } from '../libraries/NativeLibraries';
import _ from 'lodash';
import Utility from '../utils/Utility';
import colors from '../theme/Colors';

class MultipleProgressView extends Component {
  progressBar = (props) => {
    if (Utility.isAndroid()) {
      return (
        <ProgressBarAndroid
          {...props}
          color='#ffffff'
          styleAttr='Horizontal'
          indeterminate={false}
        />
      );
    }
    return (
      <ProgressViewIOS
        {...props}
        color='#ffffff'
        trackTintColor={colors.background}
      />
    );
  };

  progressView = (props) => {
    const { progress, total, currentIndex, index, containerWidth } = props;
    let barProgress = index < currentIndex ? 1 : progress;
    if (index > currentIndex) {
      barProgress = 0;
    }
    let progressBarWidth = Utility.getDynamicWidth(total, total * 4 + 12);
    if (!Utility.isBlank(containerWidth)) {
      progressBarWidth =
        (containerWidth - Utility.padding * 2 - total * 4 + 12) / total;
    }
    return (
      <this.progressBar
        progress={barProgress}
        style={{ width: progressBarWidth, marginLeft: 2, marginRight: 2 }}
      />
    );
  };

  renderProgress(progressBarArray) {
    return _.map(progressBarArray, (progressView, index) => progressView);
  }

  render() {
    const { currentIndex, total, progress, containerWidth } = this.props;
    const progressBarArray = [];
    _.times(total, (index) => {
      progressBarArray.push(
        <this.progressView
          progress={progress}
          currentIndex={currentIndex}
          index={index}
          total={total}
          containerWidth={containerWidth}
          color={colors.white}
          key={`multiple_progress_view_${index}`}
        />,
      );
    });
    return (
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        {this.renderProgress(progressBarArray)}
      </View>
    );
  }
}

export default MultipleProgressView;
