// Dependencies
import React, { Component } from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withMaybe } from '../../lib/Monads';
import Card from '../../lib/Card';
import {
  LAYOUT,
  THEME,
  MAX_LINES,
  SIZE,
  AVAILABLE_BUILD_TYPES,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import fontSize from '../../theme/Fonts';
import colors from '../../theme/Colors';
// Components
import { MEDIA } from '../../config/LayoutConstants/ContentMediaConfig';
import MediaCardStyles from './styles';
import images from '../../theme/Images';
import LikeButton from '../shared/LikeButton';
import ScaleAnimate from '../shared/ScaleAnimate';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
  AnalyticsUtilty,
} from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import FastImageView from '../FastImageView';
import { isDesktop } from '../../utils/BooleanUtility';

class DefaultSizeVideoRail extends Component {
  static cardLayoutStyle = {
    vertical: {
      cardElevation: 2,
      cardMaxElevation: 2,
      cornerRadius: 4,
      borderWidth: 1,
    },
    horizontal: {
      cardElevation: 0,
      cardMaxElevation: 0,
      cornerRadius: 0,
      overFlow: 'visible',
    },
  };

  constructor(props) {
    super(props);
    this.itemCapturedInViewport = { currentState: '' };
    this.debounceMediaCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
  }

  forRailOrGridLayout = (props) =>
    props.layout == LAYOUT.RAIL || props.layout == LAYOUT.GRID;

  listOrCardCondition = (props) =>
    !(props.layout === LAYOUT.CARD || props.layout === LAYOUT.LIST);

  artistVerifiedCondition = (props) => !props.verified;

  viewsWithCondition = withMaybe(this.listOrCardCondition)(Text);

  LikeButtonWithCondition = withMaybe(this.forRailOrGridLayout)(LikeButton);

  verifiedTickWithCondition = withMaybe(this.artistVerifiedCondition)(Image);

  navigateToMedia = () => {
    const {
      navigation,
      itemData,
      itemData: { slug = '' } = {},
      listId,
      index,
      listIndex,
      searchQuery,
      search,
      elementItemCounts,
      listName,
      listContent,
      layout,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' } = {},
      onPress,
      cardSource,
      toast,
    } = this.props;

    const toastMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.non_dimensional_video,
    );

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;
      const list_index = index + listIndex + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.metadata.title,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };
      this.debounceMediaCardClick(meta);
    }

    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.id,
        itemData.type,
        itemData.metadata.title,
        index,
        listId,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
      );
    }

    const {
      metadata: { width },
    } = itemData;

    if (onPress !== null && onPress !== undefined) {
      onPress(index);
    } else if (
      cardSource === SCREEN_CONSTANTS.BRAND_COLLAB_DETAIL &&
      Utility.isBlank(width)
    ) {
      console.tron.log(
        'Will not navigate to media page, Height and width is null for media',
      );
      toast.show(toastMessage, 1000);
    } else {
      navigation.push('ContentModal', {
        itemData,
        listId,
        index,
        listData,
      });
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  imageError = () => {
    const { itemData, navigation } = this.props;
    const screen = '';
    AnalyticsUtilty.fireImageErrorAnalytics(
      itemData.id,
      itemData.image_url,
      itemData.name,
      'media',
      screen,
    );
  };

  MediaImage = (props) => {
    const { type, imageUrl, imageBackgroundStyle, layout } = props;
    const imageIcon =
      layout === LAYOUT.LIST
        ? images[`list${type}Icon`]
        : images[`${type}Icon`];
    let newImageUrl = imageUrl;
    if (!Utility.isBlank(imageUrl)) {
      newImageUrl = Utility.getMinifiedImage(
        imageUrl,
        Utility.screenWidth,
        Utility.screenWidth,
      );
    }
    return (
      <FastImageView
        source={newImageUrl}
        style={imageBackgroundStyle}
        onError={this.imageError}
      >
        <Image
          source={imageIcon}
          resizeMode="cover"
          style={MediaCardStyles.railContentTypeIndicatorStyle}
        />
      </FastImageView>
    );
  };

  MediaViews = (props) => {
    const { layout, views, size, viewCountColor } = props;
    if (layout === LAYOUT.LIST || views === '0' || views === 0) {
      return null;
    }
    return (
      <View style={MediaCardStyles.viewsContainer}>
        <View style={MediaCardStyles[`${layout}DotStyle`]} />
        <Text
          style={[MediaCardStyles.railViewCount, { color: viewCountColor }]}
          layout={layout}
          size={size}
        >
          {`${views}`}
        </Text>
        <this.viewsWithCondition
          layout={layout}
          style={[
            MediaCardStyles.railViewCount,
            { color: viewCountColor, marginLeft: 2 },
          ]}
        >
          views
        </this.viewsWithCondition>
      </View>
    );
  };

  MediaDescription = (props) => {
    const {
      layout = LAYOUT.RAIL,
      views,
      description,
      theme = THEME.LIGHT,
      size,
      artistName,
      artistImage,
      verified,
    } = props;
    const styles = MediaCardStyles;
    let textStyle = MediaCardStyles.railTextStyle;
    let descriptionStyle = MediaCardStyles.railMediaDescriptionContainer;
    let gradientArray = ['transparent', 'transparent'];
    let gradientHeight = MEDIA.CONSTANTS.gradientHeight.rail;
    let textSize = fontSize.h5;
    let textColor = colors.foxyBlack;
    let viewCountColor = colors.disabled;
    if (layout === LAYOUT.CARD) {
      textColor = colors.white;
      viewCountColor = colors.white;
      descriptionStyle = styles.cardMediaDescriptionContainer;
      gradientArray = ['rgba(0, 0, 0, 0)', 'black'];
      gradientHeight = MEDIA.CONSTANTS.gradientHeight.card;
      textSize = fontSize.h4;
    }
    if (
      theme === THEME.DARK ||
      ((size === SIZE.large || size === SIZE.larger) && layout !== LAYOUT.LIST)
    ) {
      textColor = colors.white;
      viewCountColor = colors.white;
      textStyle = [textStyle, { color: textColor }];
      gradientArray = ['rgba(0, 0, 0, 0)', 'black'];
    }
    const maxLines = MEDIA.MAX_LINES.TITLE[layout];
    const gradientStyle = [
      MediaCardStyles.gradientStyle,
      { height: gradientHeight },
    ];
    const artistNameWidth =
      Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only &&
      layout === LAYOUT.LIST
        ? null
        : MEDIA.MAX_WIDTH.ARTIST_NAME[layout];
    return (
      <View style={descriptionStyle}>
        <LinearGradient colors={gradientArray} style={gradientStyle}>
          <Text numberOfLines={maxLines} style={textStyle}>
            {description}
          </Text>
          <View style={styles.railArtistContainer}>
            <FastImageView
              borderRadius={9}
              resizeMode="cover"
              source={Utility.getMinifiedImage(artistImage, 120, 120)}
              style={MediaCardStyles.artistMiniAvatar}
            />
            <Text
              numberOfLines={MAX_LINES.single}
              style={[
                MediaCardStyles.artistName,
                {
                  fontSize: textSize,
                  color: textColor,
                  maxWidth: artistNameWidth,
                },
              ]}
            >
              {artistName}
            </Text>
            <this.verifiedTickWithCondition
              verified={verified}
              source={images.verifiedTick}
              style={MediaCardStyles.verifiedTick}
            />

            <this.MediaViews
              layout={layout}
              views={views}
              size={size}
              textColor={textColor}
              viewCountColor={viewCountColor}
            />
          </View>
        </LinearGradient>
      </View>
    );
  };

  conditionalRenderFn = (x) => x === LAYOUT.RAIL || x === LAYOUT.GRID;

  render() {
    const { layout, itemData, size = 'defaultSize', orientation } = this.props;

    const { metadata: { title = ' ', viewCount = ' ' } = {} } = itemData;
    const likeButtonStyle =
      layout === LAYOUT.CARD
        ? MediaCardStyles.likeButtonStyleForCard
        : MediaCardStyles.likeButtonStyle;
    const artistName = (itemData.artist && itemData.artist.name) || '';
    // TODO: Use a default image for an artist
    const artistImage =
      (itemData.artist &&
        itemData.artist.images &&
        itemData.artist.images.thumbnail) ||
      (itemData.artist && itemData.artist.image_url) ||
      'https://apan.net/people/avatar.png';
    const verified = itemData.artist && itemData.artist.verified;

    return (
      <ScaleAnimate {...this.props} onPress={this.navigateToMedia}>
        <Card
          {...DefaultSizeVideoRail.cardLayoutStyle[orientation]}
          style={style.railLayoutCardStyleLight}
        >
          <View style={{ justifyContent: 'center' }}>
            <this.MediaImage
              {...this.props}
              imageBackgroundStyle={style.imageBackgroundStyleForContent}
              imageUrl={itemData.image_url}
              layout={layout}
              imageheight={Utility.getDynamicWidthForGrid(noOfItems, 16)}
              imagewidth={(9 / 16) * Utility.getDynamicWidthForGrid(noOfItems, 16)}
            />
          </View>
          <View style={MediaCardStyles.railDescriptionContainerStyle}>
            <this.MediaDescription
              {...this.props}
              layout={layout}
              size={size}
              description={title || ''}
              views={viewCount || ''}
              artistName={artistName}
              artistImage={artistImage}
              verified={verified}
            />
          </View>
          <View style={likeButtonStyle}>
            {Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.foxy ? (
              <this.LikeButtonWithCondition
                id={itemData.id}
                type={itemData.type}
                slug={itemData.slug}
                layout={layout}
                conditionalRenderFn={this.conditionalRenderFn}
              />
            ) : null}
          </View>
        </Card>
      </ScaleAnimate>
    );
  }
}

// PropTypes
DefaultSizeVideoRail.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string,
  size: PropTypes.string,
  itemData: PropTypes.shape({
    image_url: PropTypes.string,
    id: PropTypes.string,
  }),
  theme: PropTypes.string,
  listId: PropTypes.string,
  onPress: PropTypes.func,
};

DefaultSizeVideoRail.defaultProps = {
  theme: THEME.LIGHT,
  listId: null,
  onPress: null,
};

export default withNavigation(DefaultSizeVideoRail);

const noOfItems = isDesktop() ? 6 : 2;

const style = StyleSheet.create({
  railLayoutCardStyleLight: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    overflow: 'hidden',
    width: Utility.getDynamicWidthForGrid(noOfItems, 16),
    height: (9 / 16) * Utility.getDynamicWidthForGrid(noOfItems, 16) + 52,
  },
  imageBackgroundStyleForContent: {
    alignItems: 'center',
    justifyContent: 'center',
    width: Utility.getDynamicWidthForGrid(noOfItems, 16),
    height: (9 / 16) * Utility.getDynamicWidthForGrid(noOfItems, 16),
  },
});
