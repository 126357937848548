import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { isDesktop } from '../../utils/BooleanUtility';

export const { ids, styles } = StyleSheet.create({
  alertBoxBehindArea: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },

  alertBoxContainer: {
    position: 'absolute',
    width: 300,
    left: isDesktop()
      ? (Utility.getScreenWidth() - 170) / 2
      : (Utility.getScreenWidth() - 300) / 2,
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 12,
    justifyContent: 'space-between',
    '@media (min-width: 768px)': {
      top: '50%',
      transform: 'translate(50%,-50%)',
    },
  },

  alertMessageContainer: {
    borderBottomColor: colors.borderLight,
    paddingBottom: 16,
    paddingTop: 4,
  },

  alertBoxTitle: {
    fontSize: 18,
    color: colors.foxyBlack,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
  },

  alertBoxTitleLargeMargin: {
    fontSize: 18,
    color: colors.foxyBlack,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    marginTop: 38,
  },

  alertBoxTitleSmallMargin: {
    fontSize: 18,
    color: colors.foxyBlack,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    marginTop: 18,
  },

  justifyAlertMessage: {
    fontSize: 14,
    color: colors.silver,
    paddingHorizontal: 20,
    marginTop: 12,
    fontFamily: 'Roboto',
    textAlign: 'justify',
  },

  alertMessage: {
    fontSize: 14,
    color: colors.silver,
    paddingHorizontal: 20,
    marginTop: 12,
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
  },

  centerAlertMessage: {
    fontSize: 14,
    color: colors.silver,
    paddingHorizontal: 20,
    marginTop: 12,
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
  },

  leftAlertMessage: {
    fontSize: 14,
    color: colors.silver,
    paddingHorizontal: 20,
    marginTop: 12,
    fontFamily: 'Roboto-Regular',
  },

  actionButtonsContainer: {
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
  },

  actionButtonText: {
    fontSize: 16,
    width: 256,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },

  hyperLink: {
    fontSize: 14,
    color: colors.green,
    textAlign: 'justify',
    paddingHorizontal: 12,
    marginTop: 12,
  },
  crossStyle: {
    position: 'absolute',
    top: -8,
    right: -8,
    height: 30,
    width: 30,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: 'white',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertBoxAnimation: { height: 120, width: 120, alignSelf: 'center' },
  crossButtonContainer: {
    position: 'absolute',
    height: 24,
    width: 24,
    borderRadius: 40,
    backgroundColor: '#E4E4E4',
    right: 16,
    justifyContent: 'center',
    alignItems: 'center',
    top: 16,
  },

  crossIconStyle: {
    height: 12,
    width: 12,
  },
});
