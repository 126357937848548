import React from 'react';
import { StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../theme/Colors';

const CircularGradient = ({
  circleRadius,
  colorsArray,
  padding = 0,
  children,
}) => {
  return (
    <LinearGradient
      colors={colorsArray}
      start={{ x: 0.0, y: 1.0 }}
      end={{ x: 1.0, y: 1.0 }}
      style={[
        styles.gradientStyle,
        {
          padding, // This should be the border width you want to have
          width: circleRadius * 2,
          height: circleRadius * 2,
          borderRadius: circleRadius,
        },
      ]}
    >
      <View style={[styles.childContainer, { borderRadius: circleRadius }]}>
        {children}
      </View>
    </LinearGradient>
  );
};

export default CircularGradient;

const styles = StyleSheet.create({
  gradientStyle: {
    width: 64,
    height: 64,
    borderRadius: 32,
    padding: 2,
    overflow: 'hidden',
    transform: [{ rotate: '90deg' }],
  },
  childContainer: {
    flex: 1,
    borderRadius: 32,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    transform: [{ rotate: '-90deg' }],
  },
});
