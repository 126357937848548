import React, { Component } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import ShimmerPlaceHolder from '../../../libraries/ReactNativeShimmerPlaceholder';
import SlimRailShimmer from '../SlimRailShimmer';
import SlimGridShimmer from './SlimGridShimmer';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { getScreenWidth } from '../../../utils/LayoutUtility';
import { isDesktop } from '../../../utils/BooleanUtility';

class CategoryBrowsingPageShimmer extends Component {
  constructor(props) {
    super(props);
    this.colorShimmer = [colors.white, colors.shimmer.lightGrey, colors.white];
    this.tabShimmerColor = [
      colors.shimmer.darkGrey,
      colors.shimmer.lightGrey,
      colors.shimmer.darkGrey,
    ];
  }

  gridRowsPair = () => (
    <>
      <ShimmerPlaceHolder
        autoRun
        colorShimmer={this.colorShimmer}
        style={styles.circularCardStyle}
        dataSet={{ media: ids.circularCardStyle }}
      />
      <ShimmerPlaceHolder
        autoRun
        colorShimmer={this.colorShimmer}
        style={styles.circularCardStyle}
        dataSet={{ media: ids.circularCardStyle }}
      />
    </>
  )

  listGridPlaceHolder = () => {
    return (
      <View style={styles.listGridContainerStyle}>
        <View style={styles.gridRowStyle}>
          <this.gridRowsPair />
          {isDesktop() && <this.gridRowsPair />}
        </View>
        <View style={styles.gridRowStyle}>
          <this.gridRowsPair />
          {isDesktop() && <this.gridRowsPair />}
        </View>
        {!isDesktop() && (
          <View style={styles.gridRowStyle}>
            <this.gridRowsPair />
          </View>
        )}
      </View>
    );
  };

  productGridPlaceHolder = () => {
    return (
      <View style={styles.productGridContainerStyle}>
        <ShimmerPlaceHolder
          autoRun
          colorShimmer={this.colorShimmer}
          style={styles.productCardStyle}
        />
        <ShimmerPlaceHolder
          autoRun
          colorShimmer={this.colorShimmer}
          style={styles.productCardStyle}
        />
      </View>
    );
  };

  verticalTabListShimmer = () => {
    const numPlaceholders = 9; // Number of Shimmer placeholders you want

    return (
      <View style={styles.verticalTabPlaceHolder}>
        {Array.from({ length: numPlaceholders }, (_, index) => (
          <ShimmerPlaceHolder
            key={index}
            autoRun
            colorShimmer={this.tabShimmerColor}
            style={styles.verticalTabContentPlaceHolder}
            dataSet={{ media: ids.verticalTabContentPlaceHolder }}
          />
        ))}
      </View>
    );
  };

  listPlaceHolder = () => {
    return (
      <View style={styles.listContainerStyle} dataSet={{ media: ids.listContainerStyle }}>
        <this.listGridPlaceHolder />
        <View style={styles.productGridContainerStyle}>
          <SlimGridShimmer />
        </View>
      </View>
    );
  };

  render() {
    const { previousScreen } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.VERTICAL_TABBED) {
      return <this.listPlaceHolder />;
    }
    return (
      <View style={styles.container} dataSet={{ media: ids.container }}>
        <this.verticalTabListShimmer />
        <this.listPlaceHolder />
      </View>
    );
  }
}

export default CategoryBrowsingPageShimmer;

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: 'row',
    '@media (min-width: 768px)': {
      width: getScreenWidth(),
      alignSelf: 'center',
    },
  },
  verticalTabPlaceHolder: {
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth() * 0.25,
    alignSelf: 'flex-start',
    left: 0,
    backgroundColor: colors.white,
  },
  verticalTabContentPlaceHolder: {
    width: 85,
    alignSelf: 'center',
    height: 55,
    marginTop: 10,
    borderRadius: 5,
    '@media (min-width: 768px)': {
      width: 170,
    },
  },
  listGridContainerStyle: {},
  productGridContainerStyle: {
    paddingTop: 40,
  },
  circularCardStyle: {
    width: Utility.getDynamicWidthForSmallGrid(2, 16) * 0.75,
    height: Utility.getDynamicWidthForSmallGrid(2, 16) * 0.75,
    borderRadius: 80,
    marginHorizontal: 20,
    '@media (min-width: 768px)': {
      width: Utility.getDynamicWidthForSmallGrid(3, 16) * 0.75,
      height: Utility.getDynamicWidthForSmallGrid(3, 16) * 0.75,
      borderRadius: 40,
    },
  },
  gridRowStyle: {
    flexDirection: 'row',
    padding: 12,
  },
  productCardStyle: {},
  listContainerStyle: {
    alignItems: 'center',
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth() * 0.75,
    paddingTop: 18,
    '@media (min-width: 768px)': {
      height: Utility.getScreenHeight() * 0.5,
    },
  },
});
