import React from 'react';

export default function PayuForm() {
  return (
    <form action="https://secure.payu.in/_payment" class="hidden" id="payu-form" method="post">
      <input name="key" value="" id="key" />
      <input name="firstname" value="" id="firstname" />
      <input name="lastname" value="" id="lastname" />
      <input name="email" value="" id="email" />
      <input name="phone" value="" id="phone" />
      <input name="hash" value="" id="hash" />
      <input name="surl" value="" id="surl" />
      <input name="curl" value="" id="curl" />
      <input name="furl" value="" id="furl" />
      <input name="txnid" value="" id="txnid" />
      <input name="udf1" value="" id="udf1" />
      <input name="productinfo" value="" id="productinfo" />
      <input name="amount" value="" id="amount" />
      <input name="user_credentials" value="" id="user_credentials" />
      <input name="pg" value="CC" id="pg" />
      <input name="ccnum" value="" id="ccnum" />
      <input name="ccname" value="" id="ccname" />
      <input name="ccvv" value="" id="ccvv" />
      <input name="ccexpmon" value="" id="ccexpmon" />
      <input name="ccexpyr" value="" id="ccexpyr" />
      <input type="submit" value="submit" />
    </form>
  );
}
