import { isDesktop } from './BooleanUtility';
import { getDynamicWidthForGrid } from './LayoutUtility';

const artistNameContainerHeight = 35;

export const getCardDimentions = (columns, content, size = '') => {
  let spacing = 16;
  let additionalHeight = 0;
  let cardImageRatio = 1;
  let horizontalMargin = 0;
  let windowRatio = 1;
  if (size === 'small') {
    windowRatio = isDesktop() ? 0.75 / 1.3 : 0.75;
    spacing = 17;
    cardImageRatio = isDesktop() ? 0.75 / 1.3 : 0.75;
    additionalHeight = 40;
  }
  if (content === 'artistCard') {
    spacing = 24;
    additionalHeight = artistNameContainerHeight;
  }
  if (content === 'mediaCard') {
    additionalHeight = 52;
    cardImageRatio = 9 / 16;
  }
  if (columns !== 2) {
    horizontalMargin = 3;
  }
  const viewwidth =
    getDynamicWidthForGrid(columns, spacing, windowRatio) - horizontalMargin;
  const viewheight =
    cardImageRatio * getDynamicWidthForGrid(columns, spacing, windowRatio) +
    additionalHeight;
  const imagewidth =
    cardImageRatio * getDynamicWidthForGrid(columns, spacing, windowRatio);
  const imageheight =
    cardImageRatio * getDynamicWidthForGrid(columns, spacing, windowRatio);
  return [viewwidth, viewheight, imagewidth, imageheight];
};

export const CARDSTYLE = {
  CARD: {
    rail: {
      defaultSizeTextContainer: {
        position: 'absolute',
        marginLeft: 8,
        marginRight: 8,
        bottom: 8,
        height: 34,
        justifyContent: 'center',
      },
      defaultSizeCardTextStyle: {
        fontFamily: 'Roboto-Bold',
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
      },
    },
    gridRail: {
      defaultSizeTextContainer: {
        position: 'absolute',
        marginLeft: 8,
        top: 8,
        bottom: 4,
        width: 86,
      },
      defaultSizeCardTextStyle: {
        fontFamily: 'Roboto-Bold',
        fontSize: 14,
        position: 'absolute',
        top: 0,
        textAlign: 'left',
      },
    },
    grid: {
      smallTextContainer: {
        position: 'absolute',
        bottom: 30,
        width: 140,
        alignSelf: 'center',
      },
      smallCardTextStyle: {
        fontFamily: 'Roboto-Regular',
        fontSize: 14,
        position: 'absolute',
        top: 0,
        textAlign: 'center',
        alignSelf: 'center',
      },
      rectangularGridTextContainer: {
        position: 'absolute',
        marginLeft: 12,
        top: 12,
        bottom: 12,
        width: 86,
      },
      rectangularGridCardTextStyle: {
        fontFamily: 'Roboto-Bold',
        fontSize: 14,
        position: 'absolute',
        top: 0,
      },
    },
  },
};

export const getNumberOfLines = (layout, display) => {
  switch (layout) {
    case 'gridRail':
      return 3;
    case 'grid':
      return display === 'rectangularGrid' ? 3 : 1;
    default:
      return 2;
  }
};
