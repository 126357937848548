import {
  View,
  Text,
  Image,
  StyleSheet,
} from 'react-native';
import React from 'react';
import { useSelector } from 'react-redux';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';

export default function UserTitleAccountOption() {
  const {
    name,
    image_url: imageUrl,
    selfie_image_url: selfieImageUrl,
  } = useSelector(state => state.UserAccountInfo.profile);
  const instagramProfile = useSelector(state => state.UserAccountInfo.instagram_profile);
  const phone = useSelector(state => state.UserAccountInfo.mobileNumber);
  const emptyStateImage = images.desktop.placeholderProfileImage;
  const profileImage = Utility.currentProfilePicToShow(
    selfieImageUrl,
    instagramProfile,
    imageUrl,
    emptyStateImage,
  );
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          source={profileImage}
          style={styles.image}
        />
      </View>
      <Text style={styles.title}>{name || phone}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 14,
    paddingHorizontal: 12,
    alignItems: 'center',
    minWidth: 150,
  },
  imageContainer: {
    height: 30,
    width: 30,
    marginRight: 12,
  },
  image: {
    borderRadius: '100%',
    width: 30,
    height: 30,
  },
  title: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    lineHeight: 1.5,
  },
});
