import { takeEvery, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import {
  FETCH_ROUTINE_DETAILS,
  FETCH_ROUTINE_SECTION_LISTS,
  ROUTINE_ACTIVITY_DONE,
  FETCH_POLLS_DATA,
  SELECT_POLL_OPTION,
  FETCH_ROUTINE_LIST_FOR_URL,
} from '../actions/RoutineActions';

import { convertJsonFromResponse, getApiHeaders } from './GeneratorUtil';
import { CREATE_ROUTINE_PARTICIPATION } from '../actions/ActionTypes';

export function* getRoutineDetails(action) {
  const { callback, slug } = action;
  try {
    const response = yield fetch(`${URL.ROUTINE_URL}/${slug}`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export function* getRoutineLists(action) {
  const { callback, slug } = action;
  try {
    const response = yield fetch(`${URL.ROUTINE_LIST}/${slug}/routine_lists`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export function* routineActivityMarkAsDone(action) {
  const { callback, routineActivityId = '', routineSlug = '' } = action;
  try {
    const response = yield fetch(
      `${URL.ROUTINE_URL}/${routineSlug}/users_routine_activities`,
      {
        method: 'POST',
        headers: yield getApiHeaders(),
        body: JSON.stringify({
          routine_activity_id: routineActivityId,
        }),
      },
    );
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export function* fetchPollActionCardData(action) {
  const { callback, id = '' } = action;
  try {
    const response = yield fetch(`${URL.POLL_LIST}/${id}`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export function* selectPollOption(action) {
  const { callback, questionId = '', optionIds = [], pollId = '' } = action;
  try {
    const response = yield fetch(
      `${URL.POLL_LIST}/${pollId}/poll_questions/${questionId}/poll_answers`,
      {
        method: 'POST',
        headers: yield getApiHeaders(),
        body: JSON.stringify({
          poll_question_option_ids: optionIds,
        }),
      },
    );
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export function* createRoutineParticipationAsync(action) {
  const { callback = () => {}, data = '' } = action;
  try {
    const response = yield fetch(`${URL.CREATE_ROUTINE_PARTICIPATION}`, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(data),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export function* fetchRoutineListForURL(action) {
  const { callback = () => {}, url = '' } = action;
  try {
    const response = yield fetch(`${url.trim()}`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export default function* watchRoutineSaga() {
  yield takeLatest(FETCH_ROUTINE_DETAILS, getRoutineDetails);
  yield takeLatest(FETCH_ROUTINE_SECTION_LISTS, getRoutineLists);
  yield takeLatest(ROUTINE_ACTIVITY_DONE, routineActivityMarkAsDone);
  yield takeEvery(FETCH_POLLS_DATA, fetchPollActionCardData);
  yield takeLatest(SELECT_POLL_OPTION, selectPollOption);
  yield takeLatest(
    CREATE_ROUTINE_PARTICIPATION,
    createRoutineParticipationAsync,
  );
  yield takeLatest(FETCH_ROUTINE_LIST_FOR_URL, fetchRoutineListForURL);
}
