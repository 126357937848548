import { Text, View, Clipboard, StyleSheet } from 'react-native';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import Toast from 'react-native-easy-toast';
import { connect } from 'react-redux';
import {
  getShipmentOffers,
  claimShipmentOffer,
} from '../../../actions/OrderActions';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import colors from '../../../theme/Colors';
import SlimOfferCoupon from '../../prompts/SlimOfferCoupon';
import { OfferTermsModal } from '../../Product';
import { CartStyles } from '../../cart/styles';
import images from '../../../theme/Images';
import ShipmentDAO from '../../../utils/ShipmentDAO';
import FastImageView from '../../FastImageView';

class DelayOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offersData: {},
      openClaimModal: false,
    };
  }

  componentDidMount() {
    const { getShipmentOffers, shipment_id } = this.props;
    getShipmentOffers(shipment_id, (success, response) => {
      if (success) {
        this.setState({ offersData: response[0] });
      }
    });
  }

  setOpenClaimModal = (open) => {
    this.setState({
      openClaimModal: open,
    });
  };

  onClaimOffer = () => {
    const { claimShipmentOffer, shipment_id, delay = {} } = this.props;
    const data = {
      shipment_id,
      delay: ShipmentDAO.shipmentDelayType(delay),
    };
    claimShipmentOffer(data, (success, response) => {
      if (success) {
        this.setState({ offersData: response[0] });
      }
    });
    this.setState({
      openClaimModal: false,
    });
  };

  claimOffer = () => {
    const { openClaimModal } = this.state;
    return (
      <View style={styles.claimOfferContainer}>
        <FastImageView
          resizeMode="contain"
          source={images.delayOffers.giftBox}
          style={styles.claimOfferImage}
        />
        {Utility.isPresent('heading') && (
          <Text style={styles.claimOfferText}>
            {'We want to make up for the delay! 🎁'}
          </Text>
        )}
        <Text style={styles.claimOfferSubheading}>
          {'Here’s a gift for you!'}
        </Text>
        <FoxyShadowButton
          width={Utility.getScreenWidth() - 24 - 12}
          title={'Claim My Gift'}
          onPress={() => this.setOpenClaimModal(true)}
          backgroundColor={colors.primaryActionBackgroundColor}
          style={styles.claimButtonContainer}
          firstColor={colors.black}
          secondColor={colors.black}
          underlayColor={colors.black}
          showActionIcon
          actionIcon={images.delayOffers.giftBoxLineIcon}
          borderRadius={8}
        />

        <OfferTermsModal
          isOfferModalVisible={openClaimModal}
          onClaimOffer={this.onClaimOffer}
          cancelOffer={() => this.setOpenClaimModal(false)}
        />
      </View>
    );
  };

  copyCoupon = () => {
    const { offersData: { coupon_code = '' } = {} } = this.state;
    Clipboard.setString(coupon_code);
    this.showToast('Coupon copied successfully!', 2000);
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  showToast = (message, duration = 1000) => {
    this.toast.show(message, duration);
  };

  render() {
    const { offersData } = this.state;
    const { delay = {} } = this.props;

    if (Utility.isDelayed(delay) && Utility.isBlank(offersData)) {
      return <this.claimOffer />;
    }

    if (Utility.isBlank(offersData)) {
      return null;
    }
    return (
      <>
        <View style={styles.container}>
          <FastImageView
            resizeMode="contain"
            source={images.delayOffers.giftBox}
            style={styles.containerImage}
          />

          {Utility.isPresent('heading') && (
            <Text style={styles.claimOfferText}>
              {'We want to make up for the delay! 🎁'}
            </Text>
          )}
          <Text style={styles.claimOfferSubheading}>
            {'Here’s a gift for you!'}
          </Text>
          <View style={styles.couponContainer}>
            <SlimOfferCoupon
              coupon={offersData.coupon_code}
              description={offersData.message}
              couponCode={offersData}
              navigation={undefined}
              promptLoading={false}
              clipperColorLeft={colors.delayOffers.clipperColorLeft}
              clipperColorRight={colors.delayOffers.clipperColorRight}
              showCopyButton
              onCtaPress={this.copyCoupon}
            />
          </View>
        </View>
        <Toast style={CartStyles.toast} ref={this.toastRef} />
      </>
    );
  }
}

const styles = StyleSheet.create({
  claimOfferContainer: {
    width: Utility.getScreenWidth() - 24,
    padding: 12,
    backgroundColor: '#FFF1D8',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
  },
  claimOfferImage: { height: 140, width: 227, marginTop: 20, marginBottom: 12 },
  claimOfferText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: '#404E9F',
    lineHeight: 21.09,
    marginBottom: 4,
  },
  claimOfferSubheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    lineHeight: 14.09,
    marginBottom: 4,
    textAlign: 'center',
    marginTop: 6,
  },
  claimButtonContainer: { marginTop: 12 },
  container: {
    width: Utility.getScreenWidth() - 24,
    padding: 12,
    backgroundColor: '#FFF1D8',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
  },
  containerImage: { height: 140, width: 227, marginTop: 20, marginBottom: 12 },
  couponContainer: { width: Utility.getScreenWidth() - 24, marginTop: 8 },
});

const mapStateToProps = (state) => ({
  orderCardAction: state.UserAccountInfo.orderActionsCards,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getShipmentOffers,
      claimShipmentOffer,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(DelayOffers),
);
