import {
  View,
  TouchableOpacity,
  Image,
  Linking,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import React from 'react';
import images from '../../theme/Images';
import { EXTERNAL_LINKS } from '../../config/Constants';
import Config from '../../libraries/ReactNativeConfig';

export default function ConnectWithUs() {
  return (
    <View style={styles.container}>
      {Config.CONNECT_WITH_US.split(',').map(social => <Icon key={social} social={social} />)}
    </View>
  );
}

const Icon = ({ social }) => (
  <TouchableOpacity onPress={() => Linking.openURL(EXTERNAL_LINKS[social])}>
    <Image
      source={{ uri: images.socialIcons[social] }}
      style={styles.image}
      dataSet={{ media: ids.image }}
    />
  </TouchableOpacity>
);

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  image: {
    width: 23,
    height: 23,
    marginRight: 10.13,
    ':hover': {
      opacity: 0.7,
    },
    '@media (min-width: 768px)': {
      width: 44,
      height: 44,
    },
  },
});
