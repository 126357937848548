import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import HTML from 'react-native-render-html';
import WithNavigation from '../../utils/WithNavigation';
import { StaticNavigationHeader } from '../shared';

class SizeChart extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.htmlData = route.params?.sizeChartContent || '';
    this.ignoredStyles = [
      'font-family',
      'line-height',
      'height',
      'width',
      'max-width',
    ];
  }

  render() {
    return (
      <>
        <StaticNavigationHeader title={'Size Chart'} />
        <ScrollView bounces={false}>
          <HTML
            html={`<div>${this.htmlData}</div>`}
            ignoredStyles={this.ignoredStyles}
          />
        </ScrollView>
      </>
    );
  }
}

export default WithNavigation(SizeChart);
