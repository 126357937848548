import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, Linking, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../../components/FastImageView';

const styles = StyleSheet.create({
  container: {
    height: 42,
    marginHorizontal: 12,
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.background,
    marginBottom: 8,
  },
  calendar: {
    height: 20,
    width: 20,
    marginLeft: 16,
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: size.h3,
    fontWeight: '400',
    color: colors.foxyBlack,
    marginLeft: 8,
  },
  vectorCircle: {
    height: 48,
    width: 48,
    borderRadius: 24,
    borderWidth: 4,
    borderColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
  gradient: {
    height: 48,
    width: 48,
    borderRadius: 24,
    borderWidth: 4,
    borderColor: colors.white,
    position: 'absolute',
  },
  vector: {
    height: 24,
    width: 16,
    tintColor: colors.white,
  },
});

export default class SubscribeToCalendarBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.gradientColors = ['#E9E9E9', '#C1CAD2'];
    this.gradientStart = { x: 0, y: 1 };
    this.gradientEnd = { x: 1, y: 0 };
  }

  onTapBanner = () => {
    const { item: { ios_destination = '', android_destination = '' } = {} } =
      this.props;
    const destination = Utility.isAndroid()
      ? android_destination
      : ios_destination;
    if (Linking.canOpenURL(destination)) {
      Linking.openURL(destination);
    }
  };

  render() {
    const { item: { title = '' } = {} } = this.props;
    return (
      <>
        <DebouncedTouchableOpacity {...this.props} onPress={this.onTapBanner}>
          <View style={styles.container}>
            <FastImageView
              style={styles.calendar}
              source={images.calendar_coloured}
            />
            <Text style={styles.text}>{title}</Text>
            <View style={styles.vectorCircle}>
              <LinearGradient
                colors={this.gradientColors}
                start={this.gradientStart}
                end={this.gradientEnd}
                style={styles.gradient}
              />
              <Image style={styles.vector} source={images.chevronRight} />
            </View>
          </View>
        </DebouncedTouchableOpacity>
      </>
    );
  }
}
