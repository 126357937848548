import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  container: {
    flex: 1,

    flexDirection: 'column',
  },
  topContainer: {
    backgroundColor: colors.white,
  },
  bottomContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  imageStyle: {
    maxHeight: Utility.getScreenHeight() / 2.65,
    height: 300,
  },
  stickyBottomRow: {
    position: 'absolute',
    bottom: Utility.isIOS() ? 30 : 0,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    height: 120,
    flexDirection: 'column',
    paddingLeft: 20,
    paddingTop: 7,
    paddingRight: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: -1 },
    shadowOpacity: 0.1,
    shadowRadius: 9,
    elevation: 1,
  },
  alignToBottomRight: {
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    padding: 20,
    textShadowColor: 'rgba(0,0,0,0.5)',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 6,
    color: colors.white,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h2,
  },

  row: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 25,
    paddingRight: 25,
    alignItems: 'center',
  },

  rowWithSpaceAround: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 15,
  },
  column: {
    flexDirection: 'column',
    paddingLeft: 15,
    paddingTop: 15,
    paddingRight: 15,
  },

  tag: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h4,
    color: colors.foxyBlack,
  },
  number: {
    fontFamily: 'Roboto-Bold',
    marginTop: 10,
    fontSize: size.h2,
  },

  subHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 10,
    color: colors.silverWicked,
  },
  productItems: {
    height: 40,
    width: 40,
    borderRadius: 40,
    marginLeft: 4,
    borderColor: colors.silver,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
  },

  circleBall: {
    height: 8,
    width: 8,
    borderRadius: 20,
    borderColor: colors.silverDark,
    backgroundColor: colors.silverLit,
    marginLeft: 5,
  },

  heading: {
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    fontSize: size.h2,
    marginLeft: 10,
  },
  post_description: {
    fontFamily: 'Roboto-Regular',
    color: colors.silverLit,
    marginTop: 10,
    fontSize: size.h3,
  },
  bottomSheetContainer: {
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',

    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  bottomSheetImageIcon: {
    height: 25,
    width: 25,
    marginLeft: 15,
    backgroundColor: colors.foxyGreen,
  },
  bottomSheetColumn: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderTopLeftRadius: 8,

    height: 150,
    width: Utility.getScreenWidth(),
    borderTopRightRadius: 8,
  },
  bottomSheetRow: {
    padding: 20,

    flexDirection: 'row',
  },
  bottomSheetText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h2,
    marginLeft: 15,
    color: colors.foxyBlack,
  },
});

export default styles;
