import { View, FlatList, TouchableOpacity } from 'react-native';
import StyleSheet from 'react-native-media-query';
import React, { useEffect, useRef, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import FastImageView from '../FastImageView';
import colors from '../../theme/Colors';
import appImages from '../../theme/Images';
import AnimatedLikeButton from '../shared/AnimatedLikeButton';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { isPresent } from '../../utils/BooleanUtility';

export default function ProductImagesDesktop({
  images = [],
  itemData = {},
  selectedVariant,
  currentSku,
}) {
  const [firstImage] = images;
  const [currentImage, setCurrentImage] = useState(firstImage);
  const scrollRef = useRef(null);

  useEffect(() => {
    setCurrentImage(images[0]);
  }, [images]);

  const isArrowUpButtonDisabled = images[0] === currentImage;
  const isArrowDownButtonDisabled = images.slice(-1)[0] === currentImage;

  const handleArrowUp = () => {
    const currentIndex = images.findIndex(image => image === currentImage) - 1;
    setCurrentImage(images[currentIndex]);
  };

  const handleArrowDown = () => {
    const currentIndex = images.findIndex(image => image === currentImage) + 1;
    setCurrentImage(images[currentIndex]);
  };

  const {
    slug = '',
    content = '',
  } = itemData;

  const likedThisItem = isPresent(selectedVariant)
    ? selectedVariant
    : itemData;

  const {
    id = '',
    type = '',
    mrp = '',
    name = '',
  } = likedThisItem;

  const smallImage = {
    width: 416,
    height: 416,
    src: currentImage,
  };

  const largeImage = {
    src: currentImage,
    width: 1024,
    height: 1024,
  };

  return (
    <View style={styles.container}>
      <View style={styles.scrollContainer}>
        <FlatList
          ref={scrollRef}
          data={images}
          renderItem={({ item }) => (
            <ProductImage
              image={item}
              currentImage={currentImage}
              setCurrentImage={setCurrentImage}
            />
          )}
          keyExtractor={item => item}
        />
        <View style={styles.arrowContainer}>
          <TouchableOpacity
            style={[styles.arrow, isArrowUpButtonDisabled && styles.disabledArrow]}
            onPress={handleArrowUp}
            disabled={isArrowUpButtonDisabled}
          >
            <FastImageView source={appImages.whiteUpArrow} width={10} height={6} />
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.arrow, isArrowDownButtonDisabled && styles.disabledArrow]}
            onPress={handleArrowDown}
            disabled={isArrowDownButtonDisabled}
          >
            <FastImageView source={appImages.whiteDownArrow} width={10} height={6} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.zoomImageContainer}>
        <ReactImageMagnify
          smallImage={smallImage}
          largeImage={largeImage}
          imageStyle={styles.enlargedImageStyle}
          enlargedImageStyle={styles.enlargedImageStyle}
          enlargedImageContainerStyle={styles.enlargedImageContainerStyle}
          enlargedImageContainerDimensions={styles.enlargedImageContainerDimensions}
        />
        <View style={styles.likeButtonContainer}>
          <AnimatedLikeButton
            id={id}
            likedItemSkuId={currentSku}
            type={type}
            slug={slug}
            mrp={mrp}
            skuId={Utility.getSkuId(itemData)}
            content={content}
            itemName={name}
            screenName={SCREEN_CONSTANTS.PRODUCT_DETAIL}
            addToCartLikeButton
            selectedVariant={selectedVariant}
            itemData={itemData}
          />
        </View>
      </View>
    </View>
  );
}

const ProductImage = ({ image, setCurrentImage, currentImage }) => (
  <TouchableOpacity
    style={[styles.imageContainer, currentImage === image && styles.activeImageContainer]}
    onPress={() => setCurrentImage(image)}
    dataSet={{ media: ids.imageContainer }}
    onMouseEnter={() => setCurrentImage(image)}
  >
    <FastImageView key={image} source={image} width={47.52} height={55} resizeMode="cover" />
  </TouchableOpacity>
);

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  scrollContainer: {
    marginRight: 8,
    height: 416,
    paddingBottom: 5,
  },
  zoomImageContainer: {
    backgroundColor: colors.white,
  },
  imageContainer: {
    width: 60,
    height: 60,
    alignItems: 'center',
    backgroundColor: colors.white,
    borderWidth: 1.5,
    borderColor: '#c4c4c4',
    marginBottom: 8,
    padding: 1,
    ':hover': {
      borderColor: '#eb6768',
    },
  },
  activeImageContainer: {
    borderColor: '#eb6768',
  },
  arrowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingHorizontal: 8,
    paddingBottom: 3,
  },
  arrow: {
    borderRadius: '50%',
    backgroundColor: colors.black,
    height: 16,
    width: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabledArrow: {
    opacity: 0.5,
    cursor: 'no-drop',
  },
  enlargedImageStyle: { 'object-fit': 'contain' },
  enlargedImageContainerStyle: {
    marginLeft: 10,
    background: colors.white,
  },
  enlargedImageContainerDimensions: {
    width: 583,
    height: 583,
  },
  likeButtonContainer: {
    position: 'absolute',
    bottom: 8,
    right: 16,
    zIndex: 10,
  },
});
