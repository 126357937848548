import {
  Text,
  View,
  ScrollView,
  ActivityIndicator,
  BackHandler,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import React, { PureComponent, useRef } from 'react';
import { KeyboardAwareScrollView } from '../../../libraries/KeyboardAwareScrollView';
import styles from './styles';
import colors from '../../../theme/Colors';
import { OutlinedTextField } from 'react-native-material-textfield';
import UploadingImageAndVideo from './UploadingImageAndVideo';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import Utility from '../../../utils/Utility';
import {
  addOrderRequestDetail,
  setVideoUploadProgress,
  getActiveCallBack,
} from '../../../actions/ActionTypes';
import {
  publishPost,
  addPostToLocalState,
} from '../../../actions/UploadPostActions';
import uuidv1 from 'uuid/v1';
import uuidv5 from 'uuid/v5';
import { uploadOrderRequestImage } from '../../../actions/FacialAnalysisActions';
import { StaticNavigationHeader } from '../../../components/shared';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import withNavigation from '../../../utils/WithNavigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CameraUtils from '../../../utils/CameraUtils';
import UploadStatusTray from '../../feed/UploadStatusTray';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import ActiveCallBackRequestConfirmPage from './ActiveCallBackRequestConfirmPage';

class RequestCallBackPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isReasonBodyEmpty: false,
      callBackRequestId: '',
      imageErrorMessage: false,
      isActiveCallBack: false,
      isLoading: true,
    };
    this.body = '';
    this.mediaContent = [];
    const { navigation, route } = this.props;
    this.headerTitle = route.params?.headerTitle || 'Request a callback';
    this.callBackRequestReasonId = route.params?.callBackRequestReasonId;
    this.entityType = route.params?.entityType;
    this.orderAasmState = route.params?.orderAasmState;
    this.entityId = route.params?.entityId;
    this.orderId = route.params?.orderId;
    this.previousScreen = route.params?.previousScreen;
    this.returnRequestId = route.params?.returnRequestId || '';
    this.extraScrollHeight = Utility.isAndroid() ? 0 : -160;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    const { getActiveCallBack } = this.props;
    getActiveCallBack(
      this.orderId,
      this.callBackRequestReasonId,
      this.getActiveCallBackDetailsCallBack,
    );
  }

  componentDidUpdate(prevProps) {
    const { isVideoUploading, navigation } = this.props;
    const { callBackRequestId } = this.state;
    let isVideoExist = false;
    this.mediaContent.forEach((image) => {
      if (image.mime === 'video/mp4') {
        isVideoExist = true;
      }
    });
    if (isVideoExist && prevProps.isVideoUploading && !isVideoUploading) {
      navigation.navigate('SelectCallBackTimeSlot', {
        selectedMediaData: this.mediaContent,
        reasonBody: this.body,
        headerTitle: this.headerTitle,
        callBackRequestReasonId: this.callBackRequestReasonId,
        entityId: this.entityId,
        orderId: this.orderId,
        callBackRequestId,
      });
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  getActiveCallBackDetailsCallBack = (success, response) => {
    const { navigation } = this.props;
    if (success) {
      if (Utility.isPresent(response.data)) {
        this.setState({
          isActiveCallBack: true,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        AnalyticsManager.logEvent('page_load_2', {
          [EventParameterKey.PAGE]: SCREEN_CONSTANTS.REQUEST_CALLBACK_PAGE,
          [EventParameterKey.SOURCE]: this.headerTitle,
        });
      }
    }
  };

  onChangeBody = (value) => {
    this.body = value;
    if (this.body?.length >= 10) {
      this.setState({ isReasonBodyEmpty: false });
    }
  };

  setMediaContent = (media) => {
    this.mediaContent = media;
    this.setState({
      imageErrorMessage: false,
    });
  };

  requestCallBackReason = () => {
    const bodyFieldRef = useRef();
    const moveToField = () => {
      bodyFieldRef?.current?.focus();
    };
    const { isReasonBodyEmpty } = this.state;
    return (
      <View style={styles.writeCallBackReasonContainer}>
        <OutlinedTextField
          ref={bodyFieldRef}
          height={115}
          inputContainerStyle={styles.bodyInputContainerStyle}
          onChangeText={this.onChangeBody}
          label='Type your message here ...'
          keyboardType='default'
          multiline
          numberOfLines={15}
          error={
            isReasonBodyEmpty
              ? 'Please enter a detailed explanation of your issue here.'
              : null
          }
          maxLength={600}
          activeLineWidth={0.8}
          lineWidth={0.8}
          onSubmitEditing={moveToField}
          tintColor='#979BAA'
          style={{ outline: 'none' }}
          labelTextStyle={styles.labelTextStyle}
        />
      </View>
    );
  };

  onPressActionButton = () => {
    const { navigation, addOrderRequestDetail } = this.props;
    if (this.body?.length < 10) {
      this.setState({ isReasonBodyEmpty: true });
      return;
    }
    if (
      this.previousScreen === 'ReturnReplaceReasonPage' &&
      Utility.isBlank(this.mediaContent)
    ) {
      this.setState({
        imageErrorMessage: true,
      });
      return;
    }
    const data = {
      orderId: this.orderId,
      callRequestTime: '',
      callBackRequestReasonId: this.callBackRequestReasonId,
      reason: this.body,
      email: '',
      return_request_id: this.returnRequestId,
    };
    addOrderRequestDetail(data, this.addOrderRequestDetailCallBack);
  };

  convertImageToBase64AndUpload = (uri, callBackRequestId) => {
    const { uploadOrderRequestImage, navigation } = this.props;
    CameraUtils.convertImageToBase64(uri)
      .then((response) => {
        const postData = {
          base64Data: `data:image/jpeg;base64,${response}`,
          id: callBackRequestId,
          type: 'CallbackRequest',
        };
        uploadOrderRequestImage(postData);
        navigation.navigate('SelectCallBackTimeSlot', {
          selectedMediaData: this.mediaContent,
          reasonBody: this.body,
          headerTitle: this.headerTitle,
          callBackRequestReasonId: this.callBackRequestReasonId,
          entityId: this.entityId,
          orderId: this.orderId,
          callBackRequestId,
        });
      })
      .catch((error) => {});
  };

  addOrderRequestDetailCallBack = (success, response) => {
    const { navigation } = this.props;
    if (success) {
      AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
        [EventParameterKey.SOURCE]: this.headerTitle,
        [EventParameterKey.ENTITY_ID]: this.entityId,
        [EventParameterKey.ENTITY_TYPE]: this.entityType,
        [EventParameterKey.STATUS]: this.orderAasmState,
        [EventParameterKey.PAGE]: SCREEN_CONSTANTS.REQUEST_CALLBACK_PAGE,
        [EventParameterKey.CTA]: 'Submit',
        request_reason_length: this.body.length,
        media_type: 'photo/video',
        media_count: this.mediaContent?.length,
      });
      const callBackRequestId = response.callback_request_id;
      if (Utility.isBlank(this.mediaContent)) {
        navigation.navigate('SelectCallBackTimeSlot', {
          selectedMediaData: this.mediaContent,
          reasonBody: this.body,
          headerTitle: this.headerTitle,
          callBackRequestReasonId: this.callBackRequestReasonId,
          entityId: this.entityId,
          orderId: this.orderId,
          callBackRequestId,
        });
        return;
      }
      this.setState({
        callBackRequestId: callBackRequestId,
      });
      this.mediaContent.forEach((image) => {
        if (image.mime === 'video/mp4') {
          this.postVideo(image, callBackRequestId);
        } else {
          this.convertImageToBase64AndUpload(image.path, callBackRequestId);
        }
      });
    }
  };

  postVideo = (video, callBackRequestId) => {
    const postId = uuidv1();
    const { publishPost, addPostToLocalState, isVideoUploading, navigation } =
      this.props;
    const postData = {
      id: postId,
      localId: postId,
      category: 'video',
      postCacheDirectoryPath: '',
      uploadType: 'FoxyVideo',
      items: [
        {
          mimeType: 'video/mp4',
          uuid: uuidv5(video.path, uuidv5.URL),
          uri: video.path,
          mediaUriAbsolutePath: video.path,
          mediaUriExternalDirectoryPath: '',
          mediaBase64Uri: '',
          thumbnail: '',
          height: Utility.getScreenHeight(),
          width: Utility.getScreenWidth(),
          videoLength: 0,
          mediaType: 'video',
          caption: '',
          recentProductSearchID: '',
          products: [],
          videoFrames: [],
          productsTag: [],
        },
      ],
      postDescription: this.reasonBody,
      entityType: 'CallbackRequest',
      entityId: callBackRequestId,
      postThumbnail: '',
      postCategory: 'video',
      hashTags: [],
      mentions: [],
      editingMode: false,
      skuId: '',
    };
    Utility.setVideoUploadProgress = setVideoUploadProgress;
    addPostToLocalState(postData, (isAllowedToNavigate) => {
      if (!isVideoUploading) {
        publishPost(postId, postData);
      }
    });
  };

  bottomActionButton = () => {
    return (
      <View style={styles.buttonContainer}>
        <FoxyShadowButton
          width={Utility.getScreenWidth() - 24}
          title={'Submit'}
          onPress={this.onPressActionButton}
          style={styles.button}
          firstColor={colors.black}
          secondColor={colors.black}
          underlayColor={colors.subtitle}
        />
      </View>
    );
  };

  bottomButton = () => {
    return Platform.select({
      ios: (
        <KeyboardAvoidingView behavior='position' keyboardVerticalOffset={-12}>
          <this.bottomActionButton />
        </KeyboardAvoidingView>
      ),
      android: <this.bottomActionButton />,
      web: <this.bottomActionButton />,
    });
  };

  render() {
    const { imageErrorMessage, isActiveCallBack, isLoading } = this.state;
    const appendImageCardStyle = imageErrorMessage
      ? styles.errorUploadContentCardStyle
      : styles.uploadContentCardStyle;
    const activeCallBackHeading = 'We have already noted your request! ✔️';
    const activeCallBackSubHeading =
      'Our customer care will get in touch with you at the earliest.';
    if (isLoading) {
      return (
        <ActivityIndicator
          size='large'
          style={{
            color: colors.foxyPink,
            marginTop: 12,
          }}
          color={colors.green}
        />
      );
    }
    if (isActiveCallBack) {
      return (
        <ActiveCallBackRequestConfirmPage
          headerTitle={this.headerTitle}
          heading={activeCallBackHeading}
          subHeading={activeCallBackSubHeading}
          navigation={this.props.navigation}
          orderId={this.orderId}
          previousScreen={this.previousScreen}
        />
      );
    }

    return (
      <>
        <StaticNavigationHeader title={this.headerTitle} />
        <View style={styles.container}>
          <KeyboardAwareScrollView
            enableAutomaticScroll
            enableOnAndroid
            extraScrollHeight={this.extraScrollHeight}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps='handled'
          >
            <View style={styles.subContainer}>
              <Text style={styles.topHeading}>Tell us what went wrong</Text>
              <Text style={styles.subHeading}>
                Please detail your concern with what went wrong so that we can
                assist you better.
              </Text>
              <this.requestCallBackReason />
              <UploadingImageAndVideo
                setMediaContent={this.setMediaContent}
                appendImageCardStyle={appendImageCardStyle}
                headerTitle={this.headerTitle}
              />
              {imageErrorMessage && (
                <Text style={styles.errorMessage}>
                  *You need to add an image/video to initiate a return request.
                </Text>
              )}
            </View>
          </KeyboardAwareScrollView>
          {/* <View style={{ marginBottom: 60 }}>
                  <UploadStatusTray />
                </View> */}
          <this.bottomButton />
        </View>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isVideoUploading: store.UserAccountInfo.isUploading,
    userEmail: store.UserAccountInfo.profile.email,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addOrderRequestDetail,
      publishPost,
      addPostToLocalState,
      uploadOrderRequestImage,
      setVideoUploadProgress,
      getActiveCallBack,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(RequestCallBackPage),
);
