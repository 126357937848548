import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Keyboard,
  ScrollView,
  ActivityIndicator,
  TextInput,
  NativeModules,
  NativeEventEmitter,
} from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import StyleSheet from 'react-native-media-query';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaymentStyles } from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { RNPayuPayment } from '../../lib/RNPayuPayment';
import FullWidthDivider from '../../utils/FullWidthDivider';
import {
  saveUpiDetails,
  saveIntentDetails,
  validateUpi,
} from '../../actions/ActionTypes';
import { element } from 'prop-types';
import _ from 'lodash';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { isDesktop, isWeb, isWebMobile } from '../../utils/BooleanUtility';

class GooglePayPayment extends Component {
  upiPaymentConstants = {
    vpaInfoText: 'e.g. mobilenumber@bank or username@bank',
    saveVPAText: 'VPA details will be saved securely for future transactions',
    invalidVpaText: 'This VPA is invalid',
  };

  paymentConstants = {
    headerText: 'UPI ID',
    vpa: 'VPA',
    enterVpaText: 'Enter Virtual Payment address',
  };

  constructor(props) {
    super(props);
    this.ref = [];
    this.ref.vpa = React.createRef();
    this.state = {
      mobileNumber: Utility.isPresent(this.props.mobileNumber)
        ? this.props.mobileNumber.replace('+91', '')
        : this.props.guestPhoneNumber,
      isMobileNumberValid: false,
      isLoaderVisible: false,
      saveUpiSelected: true,
      error: '',
    };
    this.lastEnteredPhoneNumber = '';
    this.mobileNumberRegex = /^[+\d{12,15}$]/;
    if (Utility.isAndroid()) {
      this.payuSuccess = new NativeEventEmitter(NativeModules.payment);
    }
    this.debounceOrderCreate = _.debounce(
      this.validateAndcreateOrderAndPay,
      1000,
      {
        leading: true,
        trailing: false,
      },
    );
  }

  resetTextFields = () => {
    this.setState({
      mobileNumber: '',
    });
  };

  showLoader = () => {
    this.setState({
      isLoaderVisible: true,
    });
  };

  goBack = () => {
    const { hidePaymentDetailsScreen } = this.props;
    this.resetTextFields();
    Keyboard.dismiss();
    hidePaymentDetailsScreen();
  };

  showBackAlert = () => {
    Alert.alert(
      'Confirmation',
      'Are you sure you want to cancel the transaction?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        { text: 'OK', onPress: this.goBack },
      ],
      { cancelable: true },
    );
  };

  saveUpiCheckboxPressed = () => {
    this.setState((prevState) => ({
      saveUpiSelected: !prevState.saveUpiSelected,
    }));
  };

  addVPA = (text) => {
    const { error } = this.state;
    const maskedText = text.replace(/[^0-9+-]/g, '');
    let phone = '';

    if (this.lastEnteredPhoneNumber.indexOf('-') !== text.length) {
      phone =
        maskedText.startsWith('+91') && maskedText.indexOf('-') === -1
          ? Utility.insertCharAtPosition(3, text, '-')
          : text;
    } else {
      phone = text;
    }
    this.lastEnteredPhoneNumber = phone;
    const newState = { mobileNumber: phone };
    if (error) {
      newState.warning = !this.mobileNumberRegex.test(maskedText);
    }
    const enteredPhoneNumber = Utility.removeDashFromText(phone);
    this.lastProcessedNumber = enteredPhoneNumber;
    this.setState(newState);
    if (!this.userStartedPhonenumberTyping) {
      this.userStartedPhonenumberTyping = true;
    }
  };

  showPaymentProcessingScreen = () => {
    const { showPaymentProcessingScreen } = this.props;
    showPaymentProcessingScreen();
  };

  payWithUpiOrNumber = (mobileNumber) => {
    if (isWeb()) {
      this.createOrderAndPay(mobileNumber);
    } else {
      this.createOrderAndPayUpi();
    }
  };

  validateUpiAndcreateOrderAndPay = (upiSource) => {
    const { validateUpi } = this.props;
    const { mobileNumber } = this.state;

    validateUpi(mobileNumber, (success, response) => {
      if (success && response?.valid) {
        this.setState(
          {
            isVpaValid: true,
          },
          () => this.payWithUpiOrNumber(mobileNumber),
        );
      } else {
        this.setState({
          error: response?.error,
        });
      }
    });
  };

  createOrderAndPay = (mobileNumber) => {
    const { createOrder } = this.props;

    const isUpi = mobileNumber.includes('@');

    const upiParams = {
      bankcode: isUpi ? 'UPI' : 'TEZOMNI',
      phone_number: mobileNumber,
      virtual_bank_account_id: mobileNumber,
    };
    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: 'upi',
      isSavedPaymentMethod: true,
      paymentMethodPayload: upiParams,
      payuPayload: upiParams,
      extraData: {
        method: 'TEZOMNI',
        details: {
          name: 'Google pay',
          intent_url: isWeb() ? '' : 'https://gpay.app.goo.gl/NW44',
          icon: `https://cdn3.foxy.in/media/app_asset/image/910/gpay_selected_3x.png`,
          merchant_name: `${Config.APP_NAME}`,
        },
      },
    };

    createOrder(paymentPayload);
  };

  validateAndcreateOrderAndPay = (upiSource) => {
    let { mobileNumber } = this.state;

    Keyboard.dismiss();

    if (mobileNumber.includes('@')) {
      this.validateUpiAndcreateOrderAndPay();
      return;
    }

    mobileNumber = mobileNumber.replace('-', '');
    if (this.mobileNumberRegex.test(mobileNumber)) {
      this.setState({ isMobileNumberValid: true });
      const valid = Utility.phoneNumberFormatter(mobileNumber);

      if (!valid.error) {
        this.createOrderAndPay(mobileNumber);
      } else {
        this.setState({ error: valid.message, isMobileNumberValid: false });
      }
    } else {
      this.setState({
        isMobileNumberValid: false,
        error: 'Please enter a 10-digit phone number.',
      });
    }
  };

  createOrderAndPayUpi = () => {
    const { createOrder } = this.props;
    const { mobileNumber } = this.state;
    const upiParams = {
      virtual_bank_account_id: mobileNumber,
    };

    const payuPayload = {
      vpa: mobileNumber,
    };

    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: 'upi',
      isSavedPaymentMethod: true,
      paymentMethodPayload: upiParams,
      payuPayload,
      extraData: {
        method: 'upi',
      },
    };

    createOrder(paymentPayload);
  };

  saveUpi = () => {
    const { mobileNumber, saveUpiSelected } = this.state;

    if (!saveUpiSelected) {
      return null;
    }

    if (Utility.isPresent(mobileNumber)) {
      const data = { mode: 'upi', title: 'upi', detail: mobileNumber };
      this.props.saveUpiDetails(data);
    }
  };

  saveIntent = (appPackage, appName, appImage) => {
    if (Utility.isPresent(appName)) {
      const data = {
        mode: 'upi',
        title: appName,
        detail: appPackage,
        image: appImage,
      };
      this.props.saveIntentDetails(data);
    }
  };

  payWithUPIS = () => {
    //TODO: Move this function in cartmodal.js
    const {
      viewOrderStatusScreen,
      paymentSuccess,
      updateLastPaymentMode,
      setCurrentPaymentDetails,
      initiatePayment,
    } = this.props;
    const { mobileNumber, saveUpiSelected } = this.state;
    const upiParams = {
      vpa: mobileNumber,
    };
    setCurrentPaymentDetails('upi', upiParams); //to retry if failed

    RNPayuPayment.payWithUPI(upiParams)
      .then((res) => {
        console.tron.log('pay with saved card successfull', res);
        if (Utility.isPresent(res.status) && res.status === 'success') {
          viewOrderStatusScreen(res);
        } else {
          viewOrderStatusScreen(res);
        }
      })
      .catch((e) => {
        console.tron.log('pay with upi failure', e);
        viewOrderStatusScreen(e);
      });
    this.setState({
      mobileNumber: '',
    });
  };

  payWithUPI = (mobileNumber) => {
    //TODO: Move this function in cartmodal.js
    const {
      viewOrderStatusScreen,
      paymentSuccess,
      updateLastPaymentMode,
      setCurrentPaymentDetails,
      initiatePayment,
      openOrderStatusScreen,
    } = this.props;
    const { saveUpiSelected } = this.state;
    const upiParams = {
      bankcode: 'TEZOMNI',
      phone_number: mobileNumber,
    };
    setCurrentPaymentDetails('TEZOMNI', upiParams); //to retry if failed

    RNPayuPayment.payWithUPI(upiParams)
      .then((res) => {
        console.tron.log('pay with saved card successfull', res);
        if (Utility.isPresent(res.status) && res.status === 'success') {
          openOrderStatusScreen(res);
        } else {
          openOrderStatusScreen(res);
        }
      })
      .catch((e) => {
        console.tron.log('pay with upi failure', e);
        openOrderStatusScreen(e);
      });
    this.setState({
      mobileNumber: '',
    });
  };

  render() {
    const styles = PaymentStyles;
    const {
      isVpaValid,
      isLoaderVisible,
      saveUpiSelected,
      error,
      mobileNumber,
    } = this.state;

    const { guestPhoneNumber } = this.props;

    let mobilePadding = {};
    if (isWebMobile()) {
      mobilePadding = {
        paddingLeft: 20,
        paddingRight: 20,
      };
    }

    return (
      <View
        style={{
          backgroundColor: colors.white,
          borderRadius: 6,
          paddingBottom: 10,
        }}
      >
        <View
          style={[
            styles.container,
            {
              marginLeft: Utility.largePadding,
              marginRight: Utility.largePadding,
            },
            mobilePadding,
          ]}
          dataSet={{ media: ids.container }}
        >
          <View styles={styles.enterCvvContainer}>
            <Text style={{ marginBottom: 8, color: '#606481' }}>
              Enter mobile number/Google Pay UPI ID
            </Text>
            <TextInput
              placeholder='Enter your linked mobile number'
              placeholderTextColor={colors.silver}
              lineWidth={1}
              blurOnSubmit={false}
              value={Utility.isPresent(mobileNumber) ? mobileNumber : ''}
              onChangeText={this.addVPA}
              ref={this.ref.vpa}
              containerStyle={styles.vpaContainer}
              onSubmitEditing={Keyboard.dismiss}
              returnKeyType='done'
              keyboardType='default'
              autoCapitalize={false}
              // autoFocus={shouldAutoFocus}
              style={{
                borderRadius: 4,
                paddingLeft: 8,
                borderColor: colors.border,
                borderWidth: 1,
                padding: 8,
                height: 45,
                color: colors.foxyBlack,
              }}
            />
            {!Utility.isBlank(error) && (
              <Text
                style={[
                  styles.cashActionButton,
                  {
                    color: colors.foxyRed,
                    marginTop: 2,
                    fontSize: 12,
                    alignSelf: 'flex-start',
                  },
                ]}
              >
                {error}
              </Text>
            )}

            <FoxyShadowButton
              width={isDesktop() ? '100%' : Utility.getScreenWidth() - 34}
              title={'Request Payment'}
              onPress={this.debounceOrderCreate}
              backgroundColor={colors.primaryActionBackgroundColor}
              style={gpaystyles.payButton}
              firstColor={colors.linearGradientGreenFirst}
              secondColor={colors.linearGradientGreenSecond}
            />
          </View>
        </View>
      </View>
    );
  }
}

const { ids, styles: gpaystyles } = StyleSheet.create({
  payButton: { marginTop: 12 },
  container: {
    '@media (min-width: 768px)': {
      width: '100%',
    },
  },
});

const mapStateToProps = function (state) {
  return {
    mobileNumber: state.UserAccountInfo.mobileNumber,
    guestPhoneNumber: state.bag.defaultAddress.phone_number,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      saveUpiDetails,
      saveIntentDetails,
      validateUpi,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(GooglePayPayment));
