import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import StyleSheet from 'react-native-media-query';
import PropTypes from 'prop-types';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { isPresent } from '../../utils/BooleanUtility';

export default function BannerDescription(props) {
  const { heading, subHeading, buttonText, ctaColor, onPress } = props;
  const numberOfLinesForHeading = Utility.isPresent(subHeading) ? 1 : 2;
  return (
    <View style={styles.largeBannerDescription} dataSet={{ media: ids.largeBannerDescription }}>
      <View style={styles.descriptionTextContainer} dataSet={{ media: ids.descriptionTextContainer }}>
        <Text
          numberOfLines={numberOfLinesForHeading}
          style={[styles.heading, styles.textStyle]}
        >
          {heading}
        </Text>
        {Utility.isPresent(subHeading) && (
          <Text
            numberOfLines={2}
            style={[styles.subHeading, styles.textStyle]}
          >
            {subHeading}
          </Text>
        )}
      </View>
      {isPresent(buttonText) && (
        <TouchableOpacity
          style={[styles.buttonContainer, { backgroundColor: `${ctaColor}` }]}
          onPress={onPress}
        >
          <Text style={styles.buttonText}>
            {buttonText}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
}

BannerDescription.prototype = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  buttonHeading: PropTypes.string,
  onButtonAction: PropTypes.func,
};

BannerDescription.defaultProps = {
  heading: null,
  subHeading: null,
  buttonHeading: null,
  onButtonAction: () => {},
};

const { ids, styles } = StyleSheet.create({
  largeBannerDescription: {
    flexDirection: 'row',
    height: (Utility.getScreenWidth() - 24) / 6,
    fontFamily: 'Roboto-Regular',
    '@media (min-width: 800px)': {
      width: '100%',
      height: 'auto',
    },
  },

  postBannerDescription: {
    height: (Utility.getScreenWidth() - 24) / 1.7,
    fontFamily: 'Roboto-Regular',
  },
  descriptionTextContainer: {
    flex: 3,
    flexDirection: 'column',
    height: (Utility.getScreenWidth() - 24) / 6,
    justifyContent: 'center',
    marginLeft: 12,
    '@media (min-width: 800px)': {
      height: 'auto',
      paddingVertical: 20,
    },
  },
  textStyle: {
    textAlignVertical: 'center',
    fontFamily: 'Roboto-Regular',
  },
  buttonContainer: {
    fontSize: size.h3,
    alignSelf: 'center',
    borderRadius: 4,
    marginRight: 11,
    padding: 7,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 11,
  },
  heading: {
    fontSize: size.h3,
    color: colors.foxyBlack,
  },
  subHeading: {
    fontSize: size.h4,
    color: '#979BAA',
  },
  buttonText: {
    opacity: 1,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
  },
});
