import { NativeModules } from 'react-native';
import { jsonParser } from './JsonUtility';
import RemoteConfig from './RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../config/Constants';
import { isAndroid, isBlank } from './BooleanUtility';

export const createAndroidNotificationChannels = () => {
  const androidNotificationChannels = jsonParser(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.android_notification_channels),
  );
  if (!isAndroid()) {
    return;
  }

  if (isBlank(androidNotificationChannels)) {
    return;
  }

  NativeModules.NotificationsChannelModule.createNotificationChannel(
    androidNotificationChannels,
  );
};
