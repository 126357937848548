import { NativeModules } from 'react-native';
import Utility from './Utility';

export default class NotificationSoundsManager {
  static saveNotificationSounds = (sounds) => {
    if (Utility.isIOS()) {
      // NativeModules.NotificationSoundManager.downloadSounds(sounds);
    } else {
      // TODO
    }
  };
}
