import { Platform } from 'react-native';
import {
  isEmpty,
  isNull,
  isUndefined,
  isNumber,
  isDate,
  isNaN,
} from 'lodash';
import Config from '../libraries/ReactNativeConfig';

export const isBlank = (value) =>
  ((isEmpty(value) ||
    (typeof value === 'string' && isEmpty(value.trim())) ||
    isNull(value) ||
    isUndefined(value) ||
    value === 'null' ||
    value === 'undefined') &&
    !isNumber(value) &&
    !isDate(value)) ||
  isNaN(value);

export const isPresent = (value) => !isBlank(value);

export const isIOS = () => Platform.OS === 'ios';

export const isAndroid = () => Platform.OS === 'android';

export const isNative = () => Platform.OS === 'android' || Platform.OS === 'ios';

export const isWeb = () => Platform.OS === 'web';

export const isCartInBottomTab = () => Config.TABS.includes('Cart');

export const isDesktop = () => isWeb() && window.innerWidth >= 1024;

export const isWebIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const isWebAndroid = () => /Android/i.test(navigator.userAgent);

export const isWebMobile = () => isWeb() && !isDesktop();
