import React, { PureComponent } from 'react';
import { View, StyleSheet, TouchableWithoutFeedback, Text } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

export default class ContactUsCard extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { onRowClick, title, description, wrapperStyle } = this.props;
    return (
      <View
        style={Utility.isBlank(wrapperStyle) ? style.wrapper : wrapperStyle}
      >
        <TouchableWithoutFeedback onPress={onRowClick}>
          <View style={style.container}>
            <Text style={style.helpText}>{title}</Text>
            <Text style={style.helpSubText}>{description}</Text>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}

const style = StyleSheet.create({
  wrapper: {
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 10,
    marginBottom: 8,
  },
  container: {
    backgroundColor: colors.cyan,
    borderColor: colors.foxyBlue,
    borderWidth: 0.5,
    borderRadius: 4,
    flexDirection: 'column',
    padding: 10,
    overflow: 'visible',
  },

  helpText: {
    fontFamily: 'Roboto-Medium',
    color: '#418ce8',
    fontSize: size.h4,
  },
  helpSubText: {
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    fontSize: size.h5,
  },
});
