import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import ShimmerPlaceHolder from '../../../libraries/ReactNativeShimmerPlaceholder';

class DuringRoutineCardPlaceholder extends PureComponent {
  constructor(props) {
    super(props);
    this.shimmerColors = ['#f7F7f7', '#eeeeee', '#f7F7f7'];
  }

  header = () => {
    return (
      <View style={styles.headerContainer}>
        <ShimmerPlaceHolder
          colorShimmer={this.shimmerColors}
          autoRun
          style={styles.headerIcon}
        />
        <View style={styles.titleContainer}>
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.title}
          />
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.subtitle}
          />
        </View>
      </View>
    );
  };

  pointsContainer = () => {
    return (
      <View style={styles.pointContainer}>
        <ShimmerPlaceHolder
          colorShimmer={this.shimmerColors}
          autoRun
          style={styles.point}
        />
        <ShimmerPlaceHolder
          colorShimmer={this.shimmerColors}
          autoRun
          style={styles.pointText}
        />
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <this.header />
        <View style={styles.divider} />
        <this.pointsContainer />
        <this.pointsContainer />
        <this.pointsContainer />
        <this.pointsContainer />
        <ShimmerPlaceHolder
          colorShimmer={this.shimmerColors}
          autoRun
          style={styles.actionButtonContainer}
        />
      </View>
    );
  }
}

export default DuringRoutineCardPlaceholder;

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    padding: 12,
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: 12,
    backgroundColor: colors.white,
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    height: 14,
  },
  headerContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  subtitle: {
    height: 12,
    marginTop: 4,
    width: '30%',
  },
  headerIcon: {
    width: 32,
    height: 32,
    marginRight: 12,
    resizeMode: 'contain',
    borderRadius: 16,
  },
  pointContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 12,
    borderRadius: 4,
  },
  point: {
    width: 16,
    height: 16,
    borderRadius: 8,
    marginRight: 8,
  },
  pointText: {
    height: 32,
    width: '90%',
  },
  actionButtonContainer: {
    height: 40,
    width: '100%',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    marginTop: 12,
    borderRadius: 8,
  },
  divider: {
    backgroundColor: colors.background2,
    width: '100%',
    height: 1,
    marginVertical: 12,
  },
});
