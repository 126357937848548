import React from 'react';
import { StyleSheet } from 'react-native';
import LottieView from 'lottie-react-native';
import animations from '../../../../theme/Animations';

export default function HeaderAnimation({
  showAnimation = false,
  animation = animations.alertSuccess,
  animationStyle = styles.animation,
}) {
  if (!showAnimation) return null;
  return (
    <LottieView
      source={animation}
      style={animationStyle}
      autoPlay
      loop={false}
    />
  );
}

const styles = StyleSheet.create({
  animation: {
    height: 120,
    width: 120,
    alignSelf: 'center',
  },
});
