class FacebookAnalytics {
  static trackEvent = (event) => {
  };

  static logStandardEvent = (eventName, valueToSum, parameters) => {
  };

  static logPurchaseEvent = (purchaseAmount, currencyCode, parameters) => {
  }
}

export default FacebookAnalytics;
