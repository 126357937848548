import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  Text,
  TextInput,
  KeyboardAvoidingView,
  Keyboard,
} from 'react-native';
import Modal from 'react-native-modal';
import { PopUpStyles } from './styles';
import FullWidthDivider from '../../utils/FullWidthDivider';
import images from '../../theme/Images';
import { withEither } from '../../lib/Monads';
import Utility from '../../utils/Utility';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCurrentPincode } from '../../actions/ActionTypes';

class ChangePincodeModal extends Component {
  state = {
    opacity: 0,
    pincode: '',
  };

  addPincode = (value) => {
    this.setState({
      pincode: value,
    });
  };

  pinInput = () => {
    const { pincode } = this.state;
    return (
      <TextInput
        style={PopUpStyles.textInputStyleForPin}
        placeholder="Enter your pin code"
        onChangeText={this.addPincode}
        value={pincode}
        autoFocus={true}
        keyboardType="number-pad"
      />
    );
  };

  cancelpressed = () => {
    const { togglePincodeModal } = this.props;
    togglePincodeModal();
    Keyboard.dismiss();
  };

  continuePressed = () => {
    const { pincode } = this.state;
    const { hitPincodeAPI, saveCurrentPincodeToRedux } = this.props;
    if (!Utility.isBlank(pincode)) {
      saveCurrentPincodeToRedux(pincode);
      hitPincodeAPI(pincode);
    }
    this.cancelpressed();
  };

  locationButton = () => (
    <TouchableOpacity
      style={PopUpStyles.locationButton}
      onPress={this.getCurrentPin}
    >
      <Image source={images.location} style={PopUpStyles.locationImage} />
    </TouchableOpacity>
  );

  isIosFn = () => Utility.isIOS();

  customKeyboardAvoid = withEither(this.isIosFn, KeyboardAvoidingView)(View);

  getCurrentPin = () => {
    const { getCurrentPincode } = this.props;
    getCurrentPincode((success, response) => {
      if (success) {
        this.setState({
          pincode: response.pincode.toString(),
        });
      }
    });
  };

  render() {
    const submitText = 'Continue';
    const reviewText = 'Delivery to pin';
    const styles = PopUpStyles;
    const { isVisible } = this.props;
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.8}
        backdropTransitionOutTiming={0}
        style={styles.changePincodeModal}
        onBackdropPress={this.cancelpressed}
        swipeDirection="down"
        onSwipeComplete={this.cancelpressed}
      >
        <SafeAreaView style={{ flex: 1, justifyContent: 'flex-end' }}>
          <this.customKeyboardAvoid
            behavior="position"
            enabled
            keyboardVerticalOffset={-18}
          >
            <View style={PopUpStyles.container}>
              <View style={PopUpStyles.addReviewHeader}>
                <Text style={PopUpStyles.reviewHeaderText}>{reviewText}</Text>
                <TouchableOpacity
                  style={PopUpStyles.cancelButton}
                  onPress={this.cancelpressed}
                >
                  <Image
                    source={images.cross}
                    style={PopUpStyles.cancelStyle}
                  />
                </TouchableOpacity>
              </View>
              <FullWidthDivider />
              <View style={PopUpStyles.locationContainer}>
                <this.pinInput />
                <this.locationButton />
              </View>
              <TouchableOpacity
                style={[
                  PopUpStyles.submitButton,
                  { marginBottom: 16 + Utility.bottomInset },
                ]}
                onPress={this.continuePressed}
              >
                <Text style={PopUpStyles.submitText}>{submitText}</Text>
              </TouchableOpacity>
            </View>
          </this.customKeyboardAvoid>
        </SafeAreaView>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ getCurrentPincode }, dispatch),
});

export default connect(null, mapDispatchToProps)(ChangePincodeModal);
