import { StyleSheet, Dimensions } from 'react-native';
import colors from '../../theme/Colors';
import { MEDIA } from '../../config/LayoutConstants/ContentMediaConfig';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

const MediaCardStyles = StyleSheet.create({
  railLayoutCardStyleLight: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  gridLayoutCardStyleLight: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  listLayoutCardStyleLight: {
    borderColor: colors.borderColor,
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  railLayoutCardStyleDark: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  gridLayoutCardStyleDark: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  listLayoutCardStyleDark: {
    borderColor: colors.borderColor,
    flexDirection: 'row',
    backgroundColor: 'black',
  },
  cardLayoutCardStyle: {
    flexDirection: 'column',
    margin: 12,
    borderWidth: 0,
    borderRadius: 4,
  },
  cardLayoutCardStyleDark: {
    flexDirection: 'column',
    margin: 12,
    borderWidth: 0,
    borderRadius: 4,
  },
  cardLayoutCardStyleLight: {
    flexDirection: 'column',
    margin: 12,
    borderWidth: 0,
    borderRadius: 4,
  },
  reviewGridLayoutCardStyle: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    marginTop: 6,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  likeButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTextStyle: {
    marginLeft: 12,
    marginRight: 45,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
  },
  listTextStyle: {
    position: 'absolute',
    top: 24,
    left: 0,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    lineHeight: 14,
  },
  railTextStyle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    marginTop: 8,
    marginLeft: 8,
    marginRight: 8,
  },
  gridTextStyle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    marginTop: 8,
    marginLeft: 8,
    marginRight: 8,
  },
  gridRailTextStyle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
  },

  cardViewCount: {
    color: colors.white,
    fontFamily: 'Roboto-Regular',

    fontSize: 12,
    marginLeft: 8,
    fontStyle: 'normal',
  },
  listViewCount: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',

    fontSize: 10,
    marginLeft: 4,
    fontStyle: 'normal',
  },
  railViewCount: {
    position: 'absolute',
    color: colors.disabled,
    fontFamily: 'Roboto-Regular',

    fontSize: 10,
    fontStyle: 'normal',
    right: 0,
  },
  gridViewCount: {
    position: 'absolute',
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',

    fontSize: 10,
    fontStyle: 'normal',
    right: 0,
  },
  railMediaDescriptionContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  gridMediaDescriptionContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  listMediaDescriptionContainer: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 12,
    marginRight: 0,
    height: 64,
  },
  cardMediaDescriptionContainer: {
    position: 'absolute',
    bottom: 0,
    flex: 1,
    height: 83,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  listImageBackgroundStyle: {
    marginLeft: 8,
    marginTop: 4,
    marginBottom: 4,
    overflow: 'hidden',
    borderRadius: 4,
  },
  imageBackgroundStyleForContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  listSeparatorStyle: {
    height: 1,
    backgroundColor: '#eaeaea',
  },
  listContentTypeIndicatorStyle: {
    alignContent: 'center',
    width: 40,
    height: 40,
  },
  railContentTypeIndicatorStyle: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 20,
    height: 10,
  },
  gridContentTypeIndicatorStyle: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 20,
    height: 10,
  },
  cardContentTypeIndicatorStyle: {
    position: 'absolute',
    top: 12,
    right: 10,
    width: 30,
    height: 15,
  },
  railDescriptionContainerStyle: {
    position: 'absolute',
    height: MEDIA.CONSTANTS.descriptionHeight,
    width: '100%',
    bottom: 0,
  },
  listDescriptionContainerStyle: {
    flex: 1,
    marginRight: 34,
  },
  gridDescriptionContainerStyle: {
    position: 'absolute',
    height: MEDIA.CONSTANTS.descriptionHeight,
    width: '100%',
    bottom: 0,
  },
  gridRailDescriptionContainerStyle: {},
  gradientStyle: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  cardArtistContainer: {
    marginLeft: 12,
    marginRight: 8,
    marginBottom: 12,
    marginTop: 4,
    height: 18,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  listArtistContainer: {
    marginRight: 8,
    marginBottom: 8,
    marginTop: 4,
    height: 18,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  railArtistContainer: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8,
    marginTop: 4,
    height: 18,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  gridArtistContainer: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8,
    marginTop: 4,
    height: 18,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  reviewGridArtistContainer: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8,
    marginTop: 4,
    height: 18,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  artistMiniAvatar: {
    height: 18,
    width: 18,
    borderRadius: 9,
    overflow: 'hidden',
    elevation: 2,
    // resizeMode: 'cover',
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
  },
  artistName: {
    fontFamily: 'Roboto-Medium',
    fontSize: 10,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 4,
    color: colors.black,
  },
  railViewsStyle: {
    flexDirection: 'row',
  },
  listViewsStyle: {
    flexDirection: 'row',
  },
  gridViewsStyle: {
    flexDirection: 'row',
  },
  viewsContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    width: 26,
  },
  listDotStyle: {
    borderRadius: 4,
    height: 4,
    width: 4,
    marginLeft: 4,
    backgroundColor: colors.disabled,
  },
  cardDotStyle: {
    height: 4,
    width: 4,
    borderRadius: 4,
    backgroundColor: colors.silverLit,
    marginLeft: 8,
  },
  verifiedTick: {
    height: 16,
    width: 16,
    marginTop: 1,
    resizeMode: 'cover',
  },
  likeButtonStyleForCard: {
    position: 'absolute',
    right: 12,
    bottom: 12,
  },
  likeButtonStyle: {
    marginRight: 22,
  },
  ratingRectangle: {
    position: 'absolute',
    top: 6,
    right: 6,
    flexDirection: 'row',
    width: 26,
    height: 17,
    borderRadius: 4,
    backgroundColor: colors.green,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ratingnumber: {
    fontSize: size.h5,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 11,
    marginTop: 2,
  },
  verticalPlaylistDescContainer: {
    justifyContent: 'flex-start',
    width: '100%',
  },
  verticalPlayistTextStyle: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 8,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  verticalPlaylistArtistContainer: {
    paddingTop: 12,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 8,
    height: 18,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  verticalPlaylistCardGradientStyle: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  verticalPlaylistCardTitle: {
    fontFamily: 'Roboto',
    fontSize: size.h4_5,
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
    marginLeft: 7,
    width: '80%',
  },
  verticalPlaylistCardArtistName: {
    fontFamily: 'Roboto',
    fontSize: 11,
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 7,
    color: colors.white,
  },
  verticalPlaylistCardArtistDetails: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  verticalPlaylistCardArtistRating: {
    fontFamily: 'Roboto',
    fontSize: size.h5,
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 7,
    color: colors.white,
  },
  verticalCardArtistContainer: {
    paddingTop: 24,
    marginLeft: 16,
    marginRight: 8,
    marginBottom: 26,
    height: 68,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // alignItems: 'center',
  },
  verticalCardMediaDescriptionContainer: {
    position: 'absolute',
    top: 0,
    flex: 1,
    height: 83,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  verticalCardArtistMiniAvatar: {
    height: 28,
    width: 28,
    borderRadius: 14,
    overflow: 'hidden',
  },
  ratingStars: {
    height: 12,
    width: 120,
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 4,
  },
});

export default MediaCardStyles;
