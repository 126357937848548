import React from 'react';
import { Text, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import colors from '../../../../theme/Colors';

export default function HyperLink({
  onPress = () => {},
  children = null,
}) {
  return (
    <TouchableWithoutFeedback
      onPress={onPress}
    >
      <Text style={styles.hyperLink}>{children}</Text>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  hyperLink: {
    fontSize: 14,
    color: colors.green,
    textAlign: 'justify',
    paddingHorizontal: 12,
    marginTop: 12,
  },
});
