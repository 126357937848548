import { StyleSheet } from 'react-native';
import React, { Component } from 'react';
import _ from 'lodash';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import CancellationReasonsModal from '../../modals/CancellationReasonsModal';
import FoxyAlert from '../../camera/shared/FoxyAlert';
import images from '../../../theme/Images';

// eslint-disable-next-line react/prefer-stateless-function
export default class CancelOrder extends Component {
  state = {
    showSuccessModal: false,
  };

  render() {
    const {
      setCancelOrderModalVisible,
      isCancelOrderModalVisible,
      cancellationReasons,
      cancelOrder,
      item,
    } = this.props;
    const { showSuccessModal } = this.state;
    return (
      <>
        <FoxyShadowButton
          title={'Cancel Order'}
          onPress={_.memoize(
            () => setCancelOrderModalVisible(true),
            () => [true],
          )}
          backgroundColor={colors.black}
          style={styles.buttonContainer}
          firstColor={colors.black}
          secondColor={colors.black}
          underlayColor={colors.black}
        />
        <CancellationReasonsModal
          isVisible={isCancelOrderModalVisible}
          onDismiss={_.memoize(
            () => setCancelOrderModalVisible(false),
            () => [false],
          )}
          cancellationReasons={cancellationReasons}
          cancelOrder={(item, selectedReason) => {
            this.setState({ showSuccessModal: true });
            cancelOrder(item, selectedReason);
          }}
          item={item}
        />
        <FoxyAlert
          isVisible={showSuccessModal}
          hideSecondButton
          alertBoxTitle={'We have noted your request'}
          alertMessage={
            'Our team will process your request for cancellation. You might get a call from us today/tomorrow'
          }
          firstButtonTitle={'OKAY'}
          firstButtonOnPress={() => {
            this.setState({
              showSuccessModal: false,
            });
          }}
          onTapOutside={() => {
            this.setState({
              showSuccessModal: false,
            });
          }}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.successInfo}
          showImage
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 3,
    width: Utility.getScreenWidth() - 24,
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
  },
});
