import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import withNavigation from '../../../utils/WithNavigation';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import Utility, { openUrlInBrowser } from '../../../utils/Utility';
import FastImageView from '../../FastImageView';
import RoutinesUtility from '../RoutinesUtility';
import DebouncedTouchableOpacity from '../../shared/DebouncedTouchableOpacity';
import { navigateToScreen } from '../../../utils/NavigationUtility';

class RoutineLiveActionCard extends PureComponent {
  constructor(props) {
    super(props);
    const {
      itemData: { duration = '', startsAt: activityStartTime = new Date() },
    } = this.props;
    const activityEndTime = new Date(
      new Date(activityStartTime).getTime() + duration * 60 * 1000,
    );
    this.offsetStartTime =
      RoutinesUtility.getLocalTimeFromUTC(activityStartTime);
    this.offsetEndTime = RoutinesUtility.getLocalTimeFromUTC(activityEndTime);
    this.isLive = RoutinesUtility.isLive(activityStartTime, activityEndTime);
  }

  getLiveText = () => {
    const {
      itemData: { startsAt: activityStartTime = new Date() },
    } = this.props;
    const hasLiveEnded = new Date() > this.offsetEndTime;
    if (this.isLive) {
      return 'LIVE';
    }
    if (hasLiveEnded) {
      return 'LIVE ENDED';
    }
    return RoutinesUtility.getDateString(activityStartTime);
  };

  onCardPress = () => {
    const {
      itemData: { activity = {} },
      navigation,
      artist,
    } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'RoutineLiveStream',
      params: {
        artist,
        activity,
        dateText: this.getLiveText(),
        isLive: this.isLive,
      },
    });

    // navigation.push('RoutineLivestream', {
    //   artist,
    //   activity,
    //   dateText: this.getLiveText(),
    //   isLive: this.isLive,
    // });
  };

  render() {
    const {
      itemData: {
        activity: {
          title = '',
          imageUrl = '',
          arguments: { artistName = '' } = {},
        } = {},
      },
      artist,
    } = this.props;

    const dateContainer = this.isLive
      ? [styles.dateContainer, { backgroundColor: colors.bright_pink }]
      : styles.dateContainer;
    const dateText = this.getLiveText();
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.onCardPress}
        style={styles.container}
      >
        <FastImageView source={imageUrl} style={styles.backgroundImage} />
        <View style={styles.contentContainer}>
          <View style={dateContainer}>
            <FastImageView
              source={images.routines.routine_benefits_icons.live_white}
              style={styles.liveIcon}
            />
            <Text style={styles.date}>{dateText}</Text>
          </View>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.artistName}>{artistName}</Text>
        </View>
        {/* <View style={styles.calenderIconContainer}>
          <FastImageView source={imageUrl} style={styles.calenderIcon} />
        </View> */}
      </DebouncedTouchableOpacity>
    );
  }
}

export default withNavigation(RoutineLiveActionCard);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
  },
  backgroundImage: {
    width: '100%',
    aspectRatio: 1,
    resizeMode: 'cover',
    borderRadius: 4,
    alignSelf: 'center',
  },
  contentContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 12,
    left: 12,
    alignItems: 'flex-start',
  },
  title: {
    color: colors.white,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    marginBottom: 4,
  },
  artistName: {
    color: colors.white,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
  },
  date: {
    color: colors.white,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
  },
  dateContainer: {
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderRadius: 16,
    marginBottom: 4,
    backgroundColor: colors.subtitle,
    flexDirection: 'row',
    alignItems: 'center',
  },
  liveIcon: {
    width: 16,
    height: 12,
    resizeMode: 'contain',
    marginRight: 6,
  },
  calenderIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  calenderIconContainer: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: colors.white,
    opacity: 0.7,
    position: 'absolute',
    bottom: 16,
    right: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.bright_pink,
  },
});
