import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import _ from 'lodash';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import OffersInfo from './OffersInfo';
import CodExpandedMessage from '../cart/CodExpandedMessage';

const PrepaidOnly = (props) => {
  const {
    totalPrice,
    totalForCod,
    codCharges,
    isSpecialOfferApplied,
    codErrorMessage,
    couponCodes,
    closeCodAndScroll,
    payWithCod,
    prepaidPayment,
    extraChargesMessage,
    renderLoader,
    removeOffer,
    isStoreModeEnabled = false,
  } = props;

  const paymentModeSuffix = isStoreModeEnabled
    ? 'to Pay at Store'
    : ' to enable COD';

  let title = `Remove coupon${
    couponCodes?.length > 1 ? 's' : ''
  } ${paymentModeSuffix}`;

  if (Utility.isBlank(couponCodes)) {
    title = 'Place order using COD';
  }

  return (
    <View
      style={{
        padding: 12,
        backgroundColor: colors.white,
      }}
    >
      {Utility.isPresent(couponCodes) && (
        <OffersInfo
          couponCodes={couponCodes}
          prepaidPayment={prepaidPayment}
          renderLoader={renderLoader}
          removeOffer={removeOffer}
          isStoreModeEnabled={isStoreModeEnabled}
        />
      )}

      <FoxyShadowButton
        width={Utility.getScreenWidth() - 34}
        title={title}
        onPress={payWithCod}
        backgroundColor={colors.primaryActionBackgroundColor}
        textStyle={styles.optPrepaidButtonText}
        firstColor={colors.linearGradientGreenFirst}
        secondColor={colors.linearGradientGreenSecond}
        disabled={couponCodes?.length > 0 ? true : false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  optPrepaidButtonText: {
    fontWeight: 'normal',
    fontSize: 14,
  },
  codButton: {
    marginBottom: 8,
    borderColor: colors.green,
    borderWidth: 1,
  },
  codButtonText: {
    color: colors.green,
    fontWeight: 'normal',
    fontSize: 14,
  },
});

export default PrepaidOnly;
