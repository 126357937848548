import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Utility from '../../../utils/Utility';
import RoundedButton from '../../layout/buttons/RoundedButton';
import colors from '../../../theme/Colors';
import withNavigation from '../../../utils/WithNavigation';
import {
  claimMissedDelightOffer,
  getOrderOffers,
} from '../../../actions/OrderActions';

import CouponCodePill from '../../cart/CouponCodePill';
import ShipmentDAO from '../../../utils/ShipmentDAO';
import RemoteConfig from '../../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../../config/Constants';
import FoxyAlert from '../../camera/shared/FoxyAlert';
import images from '../../../theme/Images';

class DelightOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offersData: {},
      offersLoading: false,
      showSuccessModal: false,
    };
  }

  componentDidMount() {
    const { getOrderOffers, orderId } = this.props;
    getOrderOffers(orderId, (success, response) => {
      if (success) {
        this.setState({ offersData: response[0] });
      }
    });
  }

  onClaimMissedDelightOffer = () => {
    const { claimMissedDelightOffer, orderId, delay = {} } = this.props;
    const data = {
      orderId,
      delay: ShipmentDAO.shipmentDelayType(delay),
    };
    this.setState({
      offersLoading: true,
    });
    claimMissedDelightOffer(data, (success, response) => {
      this.setState({
        offersLoading: false,
        showSuccessModal: true,
      });
      if (success) {
        this.setState({ offersData: response[0] });
      }
    });
  };

  render() {
    const { offersLoading, offersData, showSuccessModal } = this.state;
    const { navigation, isDelightMissed } = this.props;

    if (!isDelightMissed) {
      return null;
    }

    const missedDelightOfferData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.missed_delight_offer_data),
    );

    return (
      <>
        <TouchableOpacity
          style={styles.container}
          onPress={this.onClaimMissedDelightOffer}
        >
          <Text style={styles.header}>{`${Config.APP_NAME} DELIGHT`}</Text>
          <Text style={styles.subheading}>
            {missedDelightOfferData?.heading || ''}
          </Text>
          {Utility.isBlank(offersData) ? (
            <RoundedButton
              buttonText={'Claim Now'}
              buttonTextColor={colors.white}
              buttonColor={colors.black}
              buttonAction={this.onClaimMissedDelightOffer}
              style={styles.roundedButton}
              loading={offersLoading}
            />
          ) : (
            <CouponCodePill
              couponCode={offersData.coupon_code}
              style={styles.couponCodeContainer}
            />
          )}

          <View style={styles.couponCodeRightCutClipper} />
          <View style={styles.couponCodeLeftCutClipper} />
        </TouchableOpacity>
        <FoxyAlert
          isVisible={showSuccessModal}
          hideSecondButton
          alertBoxTitle={'COUPON UNLOCKED'}
          alertMessage={missedDelightOfferData?.heading}
          firstButtonTitle={'MY REWARDS'}
          firstButtonOnPress={() => {
            navigation.navigate('PersonalisedOffers', {
              horizontal: false,
            });
            this.setState({
              showSuccessModal: false,
            });
          }}
          onTapOutside={() => {
            this.setState({
              showSuccessModal: false,
            });
          }}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.successInfo}
          showJson
        />
      </>
    );
  }
}
const styles = StyleSheet.create({
  couponCodeRightCutClipper: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.background,
    bottom: 45,
    right: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
    borderLeftColor: '#ECF3FE',
    borderTopColor: '#ECF3FE',
    borderBottomColor: '#ECF3FE',
    borderRightColor: colors.background,
  },
  couponCodeLeftCutClipper: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.background,
    bottom: 45,
    left: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
    borderRightColor: '#ECF3FE',
    borderTopColor: '#ECF3FE',
    borderBottomColor: '#ECF3FE',
    borderLeftColor: colors.background,
  },
  container: {
    width: Utility.getScreenWidth() - 24,
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: colors.white,
    justifyContent: 'flex-start',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
    borderWidth: 4,
    borderColor: colors.prompt.ultraLightBlue,
  },
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    color: colors.foxyBlack,
    textAlign: 'left',
  },
  subheading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#173143CC',
    textAlign: 'left',
  },
  roundedButton: { width: 96, alignSelf: 'flex-end', height: 24 },
  couponCodeContainer: {
    marginRight: 4,
    flex: 1,
    paddingHorizontal: 4,
    padding: 2,
    borderWidth: 1,
    backgroundColor: '#F6F9FF',
    borderColor: colors.prompt.borderBlue,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    width: 90,
    alignSelf: 'flex-end',
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      claimMissedDelightOffer,
      getOrderOffers,
    },
    dispatch,
  ),
});

export default withNavigation(connect(null, mapDispatchToProps)(DelightOffer));
