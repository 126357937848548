// Dependencies
import React, { Component } from 'react';
import { View, FlatList } from 'react-native';
import lodash, { capitalize, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import { LAYOUT, ORIENTATION } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
// Components
import styles from './styles';
import { LAYOUT_CONFIG } from '../../../config/LayoutConstants/LayoutConfig';
import { AnalyticsUtilty } from '../../../analytics';
import { DefaultSizeProductVerticalRail } from '../../Product';
import { DefaultSizeVariantVerticalRail } from '../../variants';
import {
  SmallProductVerticalRail,
  LargeProductVerticalRail,
} from '../../Product/OtherProducts';
import {
  SmallVariantVerticalRail,
  LargeVariantVerticalRail,
} from '../../variants/OtherSkus';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { getVerticalRailHeight } from './VerticalRailHelper';

class VerticalRail extends Component {
  static getComponentHeight(item) {
    return getVerticalRailHeight(item);
  }

  static Components = {
    DefaultSizeProductVerticalRail,
    DefaultSizeVariantVerticalRail,
    LargeProductVerticalRail,
    SmallProductVerticalRail,
    LargeVariantVerticalRail,
    SmallVariantVerticalRail,
  };

  constructor() {
    super();
    this.viewedIds = [];
  }

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const {
      id,
      size,
      content,
      navigation,
      toggleCartVisibility,
      listName,
      previousScreen,
      onPress,
      toggleUnavailableProductsModal,
      showToast,
      listIndex,
      listContent,
      listData = {},
      extraEventParameters = {},
    } = this.props;

    if (item === undefined) {
      return null;
    }

    let ContainerComponent =
      VerticalRail.Components[
        `${upperFirst(size)}${capitalize(item.type)}VerticalRail`
      ];

    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <ContainerComponent
          toggleUnavailableProductsModal={toggleUnavailableProductsModal}
          id={item.id || item.productId}
          itemData={item}
          listName={listName}
          listContent={listContent}
          layout={LAYOUT.VERTICALRAIL}
          navigation={navigation}
          size={size}
          type={item.type}
          orientation={ORIENTATION.HORIZONTAL}
          listId={id}
          index={index}
          toggleCartVisibility={toggleCartVisibility}
          previousScreen={previousScreen}
          onPress={onPress}
          refreshOfferStrip={this.props.refreshOfferStrip}
          showToast={showToast}
          listIndex={listIndex}
          listData={listData}
          extraEventParameters={extraEventParameters}
        />
      </ErrorBoundaryComponent>
    );
  };

  render() {
    const { feed } = this.props;
    this.displayCount = feed
      ? lodash.max([
          LAYOUT_CONFIG.minRailContForVerticalRail,
          this.props.displayCount || 0,
        ])
      : this.props.displayCount || 3;
    this.itemData = this.props.item.filter((x) => !!x);

    if (this.displayCount > LAYOUT_CONFIG.maxRailCount) {
      this.displayCount = LAYOUT_CONFIG.maxRailCount;
    }
    return (
      <View style={styles.verticalRailView}>
        <FlatList
          data={this.itemData.slice(0, this.displayCount)}
          horizontal
          style={styles.flatlistStyle}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item, index }) => this.getComponent(item, index)}
          keyExtractor={(item) => `${item.schema}_${item.id}`}
          initialNumToRender={5}
        />
      </View>
    );
  }
}

// PropTypes
VerticalRail.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
  }),
  item: PropTypes.array,
};

export default VerticalRail;
