// Dependencies
import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../theme/Colors';

class VerticalRailShimmer extends Component {
  constructor(props) {
    super(props);
    this.shimmerColors = [
      colors.shimmer.darkGrey,
      colors.shimmer.lightGrey,
      colors.shimmer.darkGrey,
    ];
    this.imageShimmerColors = [
      colors.shimmer.lightGrey,
      colors.shimmer.extraLightGrey,
      colors.shimmer.lightGrey,
    ];
  }

  descriptionPlaceholder = () => {
    return (
      <View style={sharedStyles.description}>
        {_.map([1, 2], (e) => {
          return (
            <View style={sharedStyles.descriptionContainer}>
              <ShimmerPlaceHolder
                colorShimmer={this.shimmerColors}
                autoRun
                style={sharedStyles.brand}
              />
              <ShimmerPlaceHolder
                colorShimmer={this.shimmerColors}
                autoRun
                style={sharedStyles.name}
              />
              <ShimmerPlaceHolder
                colorShimmer={this.shimmerColors}
                autoRun
                style={sharedStyles.price}
              />
              <View style={sharedStyles.addToCartContainer}>
                <ShimmerPlaceHolder
                  colorShimmer={this.shimmerColors}
                  autoRun
                  style={sharedStyles.addToCart}
                />
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  render() {
    return (
      <View style={sharedStyles.shimmerContainer}>
        <ShimmerPlaceHolder
          colorShimmer={this.imageShimmerColors}
          autoRun
          style={sharedStyles.imageShimmer}
        />

        <this.descriptionPlaceholder />
      </View>
    );
  }
}

const sharedStyles = StyleSheet.create({
  imageShimmer: {
    height: 16,
    width: 80,
    borderRadius: 8,
    marginBottom: 12,
    marginTop: 36,
  },
  descriptionContainer: {
    height: 136,
    width: 332,
    backgroundColor: colors.white,
    borderRadius: 3,
    marginRight: 12,
    paddingHorizontal: 16,
    paddingLeft: 120,
  },
  brand: { height: 18, width: 74, borderRadius: 4, marginTop: 12 },
  name: { height: 12, width: 200, borderRadius: 4, marginTop: 12 },
  price: { height: 12, width: 124, borderRadius: 4, marginTop: 4 },
  addToCartContainer: {
    marginTop: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  addToCart: { height: 42, width: 43, borderRadius: 50, marginTop: 4 },
  description: { flexDirection: 'row' },
});

export default VerticalRailShimmer;
