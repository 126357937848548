import AnalyticsEvent from './AnalyticsEvent';
import WebEngage from 'react-native-webengage';
import { isBlank, isPresent, isWeb } from '../utils/BooleanUtility';

const webengage = new WebEngage();
class WebengageAnalytics {
  static optIn = () => {
    webengage.user.setOptIn('push', true);
    webengage.user.setOptIn('in_app', true);
    webengage.user.setOptIn('email', true);
    webengage.user.setOptIn('sms', true);
    webengage.user.setOptIn('whatsapp', true);
  };

  static trackEvent = (event) => {
    if (isWeb()) return;
    if (event instanceof AnalyticsEvent) {
      let { meta, eventType } = event;
      if (isBlank(meta)) {
        meta = null;
      }
      webengage.track(eventType, meta);
    }
  };

  static setUserId = (userId) => {
    webengage.user.login(userId);
  };

  static setUserAlias = (userId) => {
    webengage.user.login(userId);
  };

  static setUserProperty = (key, value) => {
    webengage.user.setAttribute(key, value);
  };

  static setGeneralUserAttributes = (object) => {
    if (isWeb()) return;
    const { email, contactNumber, firstName, lastName } = object;
    if (firstName) {
      webengage.user.setFirstName(firstName);
    }
    if (lastName) {
      webengage.user.setLastName(lastName);
    }
    if (isPresent(email)) {
      webengage.user.setEmail(email);
    }
    if (isPresent(contactNumber)) {
      webengage.user.setPhone(contactNumber);
    }
  };

  static currentScreen = (screenName, meta) => {
    webengage.screen(screenName, meta);
  };

  static logout = () => {
    webengage.user.logout();
  };
}

export default WebengageAnalytics;
