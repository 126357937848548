import React from 'react';
import { View, Text, TouchableOpacity, Clipboard } from 'react-native';
import { ids, styles } from './styles';
import LinearGradient from 'react-native-linear-gradient';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import { AnalyticsManager, EventType } from '../../analytics';

export default function DashboardOneLinkCard(props) {
  const {
    instagramHandle = '',
    onEditPress = () => {},
    showCopiedToClipboard = () => {},
  } = props;

  const copyOneLinkHandle = () => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.COPY_ONELINK, {
      btn_location: 'dashboard',
    });
    Clipboard.setString(`foxy.in/@${instagramHandle}`);
    showCopiedToClipboard();
  };

  const EditProfileButton = () => {
    return (
      <TouchableOpacity onPress={onEditPress} style={styles.editProfileButton}>
        <FastImageView source={images.edit_new} style={styles.editButtonIcon} />
        <Text style={styles.editButtonText}>Edit Profile</Text>
      </TouchableOpacity>
    );
  };

  return (
    <LinearGradient
      colors={['rgba(255,154,158,0.2)', 'rgba(255,154,158,0.2)']}
      style={[styles.dashboardInfoCardContainer, { alignItems: 'flex-start' }]}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 0 }}
    >
      <Text style={[styles.registrationCompletedHeading, { marginTop: 12 }]}>
        One Link
      </Text>
      <Text style={[styles.registrationCompletedSubheading, { marginTop: 6 }]}>
        The only link you’ll ever need
      </Text>
      <Text
        style={[
          styles.registrationCompletedSubheading,
          { paddingHorizontal: 0, alignSelf: 'flex-start', marginLeft: 24 },
        ]}
        dataSet={{ media: ids.addIntroVideoSubheading }}
      >
        foxy.in/@{instagramHandle}
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            bottom: -2,
          }}
          onPress={copyOneLinkHandle}
        >
          <FastImageView source={images.copy_blue} style={styles.copyImage} />
          <Text style={styles.copyText}>Copy</Text>
        </TouchableOpacity>
      </Text>
      <EditProfileButton />
    </LinearGradient>
  );
}
