// Dependencies
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { ProductDetailDescription } from '.';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import List from '../../containers/List/List';
import RichContent from './RichContent/RichContent';
import { isDesktop } from '../../utils/BooleanUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';

const ProductDiscontinued = (props) => {
  const {
    itemData,
    selectedVariant,
    onPressMore = () => {},
    setDescriptionBoxRef,
    displaySp,
    productPage = {},
    sendAlertForPrice,
  } = props;

  const { product_page_type, rich_content = {} } = itemData;

  const [upperList, setupperList] = useState(null);
  const [lowerList, setLowerList] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setupperList({
        display: 'vertical',
        content: 'mixed',
        objects: itemData.consolidated_list_upper,
      });
      setLowerList({
        display: 'vertical',
        content: 'mixed',
        objects: itemData.consolidated_list_lower,
      });
    }, 0);
  }, [itemData.consolidated_list_upper, itemData.consolidated_list_lower]);

  return (
    <>
      {product_page_type === 'foxy' && (
        <ProductDetailDescription
          description={itemData.description || ''}
          howTo={itemData.how_to || ''}
          ingredients={itemData.ingredients || ''}
          name={itemData.name}
          expiryDate={itemData.expiry_date}
          bestBefore={itemData.best_before}
          itemData={itemData}
          selectedVariant={selectedVariant}
          onPressMore={onPressMore}
          setDescriptionBoxRef={setDescriptionBoxRef}
          sku={
            Utility.isPresent(selectedVariant)
              ? selectedVariant?.sku
              : itemData?.sku
          }
          displaySp={displaySp}
          productPage={productPage}
          sendAlertForPrice={sendAlertForPrice}
        />
      )}

      {Utility.isPresent(upperList?.objects) && (
        <View>
          <List
            itemData={upperList}
            id={itemData.id}
            previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
            verticalComponentWidth={isDesktop() ? PRODUCT_RIGHT_CONTAINER_WIDTH : undefined}
          />
        </View>
      )}
      {!isDesktop() && Utility.isPresent(lowerList?.objects) && (
        <List
          itemData={lowerList}
          id={itemData.id}
          previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
        />
      )}
      <RichContent itemData={rich_content?.page_end} />
    </>
  );
};

const styles = StyleSheet.create({
  emptyView: {
    width: Utility.getScreenWidth(),
    height: 70 + Utility.bottomInset,
  },
  upperList: {
    backgroundColor: colors.background,
  },
  lowerList: {
    backgroundColor: colors.background,
    paddingBottom: 20,
    marginTop: 12,
  },
  rateProductContainer: { backgroundColor: colors.white },
});

export default ProductDiscontinued;
