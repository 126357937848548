// install to conversion specific component, need to be fix

// Dependencies
import React, { Component } from 'react';
import { View, Text, Image } from 'react-native';
import { withMaybe, withEither } from '../../lib/Monads';
import Utility from '../../utils/Utility';
// Components
import ProductCardStyles from './styles/styles';
import { THEME, LAYOUT, SIZE, CURRENCY_SYMBOL } from '../../config/Constants';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import { ProductInCartStyles } from './styles';
import images from '../../theme/Images';
import FastImageView from '../FastImageView';

class FreeProductDescription extends Component {
  constructor(props) {
    super(props);
    const pattern = `${this.props.brandName} `;
    const rep = new RegExp(pattern, 'gi');
    this.name = this.props?.name?.replace(rep, '');
  }

  shortenName = (name, limit) =>
    name.length > limit ? (name = `${name.substring(0, limit)}...`) : name;

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  discountConditionFn = (props) =>
    parseFloat(props.sp) !== parseFloat(props.mrp);

  smallListCondition = (props) =>
    props.layout === LAYOUT.LIST && props.size === SIZE.small;

  showBrandCondition = () => true;

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  brandNameText = (props) => (
    <Text
      numberOfLines={
        PRODUCT.MAX_LINES.BRAND_NAME[props.layout][props.size] || 1
      }
      style={[
        ProductCardStyles.brandName,
        ProductCardStyles[`${props.layout}BrandName`],
      ]}
    >
      {props?.name?.toUpperCase()}
    </Text>
  );

  brandNameImage = (props) => (
    <View style={ProductCardStyles.brandContainerStyle}>
      <Image
        source={{ uri: props.thumbnail }}
        style={ProductCardStyles.brandImageStyle}
      />
    </View>
  );

  brand = this.brandNameText;

  discountSection = (props) => {
    const { discount } = this.props;

    return (
      <this.conditionalDiscountText
        style={ProductCardStyles.discountRateStyle}
        discount={discount}
      >
        {`${discount}`}
      </this.conditionalDiscountText>
    );
  };

  PricingComponent = (props) => {
    const styles = ProductCardStyles;
    const { additional_discount, display_price, previousScreen, skuCard } =
      this.props;
    const { mrp, sp, discount, theme, layout, size, points } = props;
    let display_discount = `(${discount})`;

    if (previousScreen === 'free_items') {
      return null;
    }

    if (Utility.isPresent(points)) {
      return <this.pointsComponent points={points} />;
    }
    if (skuCard) {
      return (
        <View style={styles[`${layout}PriceContainer`]}>
          <Text
            style={{
              fontSize: 10,
              fontFamily: 'Roboto-Regular',

              fontStyle: 'normal',
              letterSpacing: 0,
              lineHeight: 14,
              color: 'green',
              marginRight: 2,
            }}
          >
            FREE
          </Text>

          <this.conditionalPriceText style={styles.mrpStyle} sp={sp} mrp={mrp}>
            {`${CURRENCY_SYMBOL}${mrp}`}
          </this.conditionalPriceText>
        </View>
      );
    }

    return (
      <View style={styles[`${layout}PriceContainer`]}>
        <Text style={styles[`${theme}spStyle`]}>
          {`${CURRENCY_SYMBOL}${display_price}`}
        </Text>
        <this.conditionalPriceText style={styles.mrpStyle} sp={sp} mrp={mrp}>
          {`${CURRENCY_SYMBOL}${mrp}`}
        </this.conditionalPriceText>

        <this.discountSection display_discount={display_discount} />
      </View>
    );
  };

  variantImage = (props) => {
    const { variantImage } = props;
    if (Utility.isBlank(variantImage) || variantImage === '') {
      return null;
    }
    return (
      <Image
        style={{
          height: 10,
          width: 10,
          borderRadius: 5,
          marginRight: 4,
        }}
        source={{ uri: Utility.getMinifiedImage(variantImage) }}
      />
    );
  };

  productVariant = (props) => {
    const { variant_name, variant_iamge_url, pack_size } = this.props;

    const styles = ProductInCartStyles;
    if (Utility.isBlank(variant_name)) {
      return (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: 8,
            maxWidth: 60,
            padding: 2,
          }}
        >
          <Text
            allowFontScaling={false}
            style={{
              fontFamily: 'Roboto-Regular',

              fontStyle: 'normal',
              letterSpacing: 0,
              color: '#606481',
              fontSize: 10,
              lineHeight: 14,
              marginRight: 2,
            }}
            numberOfLines={1}
            ellipsizeMode='tail'
          >
            {pack_size}
          </Text>
        </View>
      );
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginLeft: 8,
          padding: 2,
        }}
      >
        <this.variantImage variantImage={variant_iamge_url} />
        <Text
          allowFontScaling={false}
          style={{
            fontFamily: 'Roboto-Regular',

            fontStyle: 'normal',
            letterSpacing: 0,
            color: '#606481',
            fontSize: 10,
            lineHeight: 14,
            marginRight: 2,
          }}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {variant_name}
        </Text>
      </View>
    );
  };

  pointsComponent = ({ points }) => {
    return (
      <View style={ProductCardStyles.pointsContainer}>
        <FastImageView
          source={Utility.getMinifiedImage(images.coins, 100, 100)}
          style={ProductCardStyles.coinsImage}
          resizeMode={'stretch'}
        />
        <Text style={ProductCardStyles.coinsValue}>{points} points</Text>
      </View>
    );
  };

  render() {
    const styles = ProductCardStyles;
    const {
      mrp,
      sp,
      discount,
      layout = LAYOUT.RAIL,
      size = 'defaultSize',
      theme = THEME.LIGHT,
      variantBrandName = '',
      type,
      skuCard,
      numberOfLines = 2,
      brandName,
      itemData: { points } = {},
    } = this.props;
    let layoutSize = size;
    if (Utility.isBlank(layoutSize)) {
      layoutSize = 'defaultSize';
    }

    return (
      <View style={styles.desriptionContainer}>
        <this.brand layout={layout} size={layoutSize} name={brandName} />
        <View>
          <Text
            allowFontScaling={false}
            numberOfLines={numberOfLines}
            style={styles[`${theme}ProductName`]}
          >
            {this.name}
          </Text>
        </View>

        {skuCard && Utility.isBlank(points) && <this.productVariant />}
        <this.PricingComponent
          mrp={mrp}
          sp={sp}
          discount={discount}
          theme={theme}
          layout={layout}
          points={points}
        />
      </View>
    );
  }
}

export default FreeProductDescription;
