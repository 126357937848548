export const SAVE_ALLOWED_VALUES = 'SAVE_ALLOWED_VALUES';
export const GET_MY_ATTRIBUTES = 'GET_MY_ATTRIBUTES';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const SAVE_MAPPED_ATTRIBUTES = 'SAVE_MAPPED_ATTRIBUTES';
export const GET_MASTER_ATTRIBUTES_LIST = 'GET_MASTER_ATTRIBUTES_LIST';
export const REFRESH_MY_ATTRIBUTES = 'REFRESH_MY_ATTRIBUTES';
export const MASTER_LIST_LAST_REFRESHED_AT = 'MASTER_LIST_LAST_REFRESHED_AT';
export const UPLOAD_FND_IMAGE = 'UPLOAD_FND_IMAGE';
export const SET_MANUAL_SELFIE_REVIEW = 'SET_MANUAL_SELFIE_REVIEW';
export const UPLOAD_IMAGE_REVIEW = 'UPLOAD_IMAGE_REVIEW';
export const UPLOAD_ORDER_REQUEST_IMAGE = 'UPLOAD_ORDER_REQUEST_IMAGE';

export const masterlistLastUpdatedAt = (date) => {
  return {
    type: MASTER_LIST_LAST_REFRESHED_AT,
    master_list_last_refreshed_at: date,
  };
};

export const getMasterAttributesList = () => {
  return {
    type: GET_MASTER_ATTRIBUTES_LIST,
  };
};
export const uploadImage = (base64Data, analysisReviewPopupRequired = true) => {
  return {
    type: UPLOAD_IMAGE,
    base64Data,
    analysisReviewPopupRequired,
  };
};

export const uploadImageReview = (metaData, callback) => {
  return {
    type: UPLOAD_IMAGE_REVIEW,
    metaData,
    callback,
  };
};

export const refreshMyAttributes = () => {
  return {
    type: REFRESH_MY_ATTRIBUTES,
  };
};
export const saveAllowedValues = (data) => {
  return {
    type: SAVE_ALLOWED_VALUES,
    data,
  };
};

export const saveMappedAttributes = (attributeList) => {
  return {
    type: SAVE_MAPPED_ATTRIBUTES,
    my_mapped_attributes: attributeList,
  };
};

export const uploadFNDImage = (imageUrl) => {
  return {
    type: UPLOAD_FND_IMAGE,
    imageUrl,
  };
};

export const uploadOrderRequestImage = (metaData) => {
  return {
    type: UPLOAD_ORDER_REQUEST_IMAGE,
    metaData,
  };
};

export function setFacialAnalysisManualProgress(isManuallyReviewed) {
  return {
    type: SET_MANUAL_SELFIE_REVIEW,
    isManuallyReviewed,
  };
}