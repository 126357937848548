import { capitalize, upperFirst } from 'lodash';
import Utility from '../../../utils/Utility';
import DefaultSizeProductSimpleGrid from '../../Product/DefaultSizeProductSimpleGrid';
import DefaultSizeVariantSimpleGrid from '../../variants/DefaultSizeVariantSimpleGrid';

const Components = {
  DefaultSizeProductSimpleGrid,
  DefaultSizeVariantSimpleGrid,
  LargeProductGrid: DefaultSizeProductSimpleGrid,
  SmallProductGrid: DefaultSizeProductSimpleGrid,
  SlimProductGrid: DefaultSizeProductSimpleGrid,
  LargeVariantGrid: DefaultSizeVariantSimpleGrid,
  SmallVariantGrid: DefaultSizeVariantSimpleGrid,
  SlimVariantGrid: DefaultSizeVariantSimpleGrid,
};

export const getGridComponent = (
  cardType,
  content,
  size,
  _freeProductScreen,
) => {
  let ContainerComponent = Components[content];
  if (content === 'product' || content === 'sku') {
    ContainerComponent =
      Components[`${upperFirst(size)}${capitalize(cardType)}SimpleGrid`];
  }

  return ContainerComponent;
};

export const getCardHeight = (
  cardType,
  content,
  size,
  freeProductScreen,
  columns,
) => {
  const ContainerComponent = getGridComponent(
    cardType,
    content,
    size,
    freeProductScreen,
  );

  switch (ContainerComponent) {
    case DefaultSizeProductSimpleGrid:
    case DefaultSizeVariantSimpleGrid:
      return Utility.getDynamicWidthForGrid(2, 16) + 124;
    default:
      return 0;
  }
};
