import { takeLatest } from 'redux-saga/effects';
import { URL } from '../config/Constants';

import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';
import {
  FETCH_POINTS_HISTORY,
  FETCH_POINTS_USAGE,
  POST_POINTS_USAGE,
} from '../actions/PointsActions';

function* fetchPointsHistory(action) {
  const { callback = () => {}, pageNo = 0 } = action;
  const postData = {
    method: 'GET',
    headers: yield getApiHeaders(),
  };

  const response = yield fetch(`${URL.POINTS}${pageNo}`, postData);
  if (response.status >= 200 && response.status < 300) {
    try {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `PointsSaga.js:fetchPointsHistory() ${error}`,
        },
      );
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

function* fetchPointUsages(action) {
  const { callback = () => {} } = action;
  const postData = {
    method: 'GET',
    headers: yield getApiHeaders(),
  };

  const response = yield fetch(`${URL.POINT_USAGE}new`, postData);
  if (response.status >= 200 && response.status < 300) {
    try {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `PointsSaga.js:fetchPointsUsages() ${error}`,
        },
      );
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

function* postPointUsage(action) {
  const { id, callback = () => {} } = action;
  const postData = {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: JSON.stringify({ point_usage: { redeemable_sku_id: id } }),
  };

  const response = yield fetch(`${URL.POINT_USAGE}`, postData);
  if (response.status >= 200 && response.status < 300) {
    try {
      const json = yield convertJsonFromResponse(response);
      callback(true, json);
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `PointsSaga.js:PostPointsUsages() ${error}`,
        },
      );
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

export default function* watchPointsSaga() {
  yield takeLatest(FETCH_POINTS_HISTORY, fetchPointsHistory);
  yield takeLatest(FETCH_POINTS_USAGE, fetchPointUsages);
  yield takeLatest(POST_POINTS_USAGE, postPointUsage);
}
