import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import CircularProgress from './CircularProgress';

class RoutineTotalProgressCard extends PureComponent {
  render() {
    const { progress = 60 } = this.props;
    return (
      <LinearGradient
        colors={[colors.peach, colors.light_peach]}
        style={styles.container}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
      >
        <CircularProgress size={100} strokeWidth={8} progress={progress} />
        <Text style={styles.text}>
          You completed {progress}% of the campaign. Do remember that completing
          the routine gives you a better shot at forming a habit!
        </Text>
      </LinearGradient>
    );
  }
}

export default RoutineTotalProgressCard;

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    padding: 12,
    paddingLeft: 8,
    marginTop: 16,
    marginHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    marginLeft: 20,
    flexWrap: 'wrap',
    flex: 1,
  },
});
