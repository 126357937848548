import { normalize } from 'normalizr';
import merge from 'lodash/merge';
import _ from 'lodash';
import * as types from '../actions/ActionTypes';
import initialState from './initialState';
import { UPDATE_MEDIA_DATA } from '../actions/MediaDetailActions';
import {
  listSchema,
  videoSchema,
  productSchema,
  artistSchema,
  brandSchema,
  campaignSchema,
  campaignsSchema,
  offerSchema,
} from '../config/Schema';
import { schemaBySlug } from '../config/SchemaBySlug';
import { STORY_RAIL } from '../config/Constants';
import Utility from '../utils/Utility';

export default function data(state = initialState.data, action) {
  switch (action.type) {
    case types.UPDATE_LIST_DATA: {
      const normalizedData = normalize(action.data, listSchema);
      const normalizedBySlugData = normalize(action.data, schemaBySlug.lists);
      return {
        ...state,
        entities: {
          ...state.entities,
          ads: {
            ...state.entities.ads,
            ...normalizedData.entities.ads,
            ...normalizedBySlugData.entities.ads,
          },
          artists: merge(
            {},
            merge(
              {},
              state.entities.artists,
              normalizedBySlugData.entities.artists,
            ),
            normalizedData.entities.artists,
          ),
          banners: {
            ...state.entities.banners,
            ...normalizedData.entities.banners,
            ...normalizedBySlugData.entities.banners,
          },
          brands: {
            ...state.entities.brands,
            ...normalizedData.entities.brands,
            ...normalizedBySlugData.entities.brands,
          },
          features: {
            ...state.entities.features,
            ...normalizedData.entities.features,
            ...normalizedBySlugData.entities.features,
          },
          lists: {
            ...state.entities.lists,
            ..._.omit(normalizedData.entities.lists, [STORY_RAIL.id]),
            ..._.omit(normalizedBySlugData.entities.lists, [STORY_RAIL.slug]),
          },
          offers: {
            ...state.entities.offers,
            ...normalizedData.entities.offers,
            ...normalizedBySlugData.entities.offers,
          },
          products: {
            ...state.entities.products,
            ...normalizedData.entities.products,
            ...normalizedBySlugData.entities.products,
          },
          salons: {
            ...state.entities.salons,
            ...normalizedData.entities.salons,
            ...normalizedBySlugData.entities.salons,
          },
          short_banners: {
            ...state.entities.short_banners,
            ...normalizedData.entities.short_banners,
            ...normalizedBySlugData.entities.short_banners,
          },
          videos: {
            ...state.entities.videos,
            ...normalizedData.entities.videos,
            ...normalizedBySlugData.entities.videos,
          },
          images: {
            ...state.entities.images,
            ...normalizedData.entities.images,
            ...normalizedBySlugData.entities.images,
          },
          media_categories: {
            ...state.entities.media_categories,
            ...normalizedData.entities.media_categories,
            ...normalizedBySlugData.entities.media_categories,
          },
          tags: {
            ...state.entities.tags,
            ...normalizedData.entities.tags,
            ...normalizedBySlugData.entities.tags,
          },
        },
      };
    }
    case types.MERGE_LIST_DATA:
      return merge(
        {},
        merge({}, state, normalize(action.data, listSchema)),
        normalize(action.data, schemaBySlug.lists),
      );
    case UPDATE_MEDIA_DATA:
      return merge({}, state, normalize(action.data, videoSchema));
    case types.UPDATE_OFFER_DATA:
      return merge({}, state, normalize(action.data, offerSchema));
    case types.UPDATE_OFFER_DATA_BY_SLUG:
      return merge({}, state, normalize(action.data, schemaBySlug.offers));
    case types.UPDATE_PRODUCT_DATA:
      return merge({}, state, normalize(action.data, productSchema));
    case types.UPDATE_PRODUCT_DATA_BY_SLUG:
      return merge({}, state, normalize(action.data, schemaBySlug.products));
    case types.UPDATE_ARTIST_DATA:
      return merge({}, state, normalize(action.data, artistSchema));
    case types.UPDATE_ARTIST_DATA_BY_SLUG:
      return merge({}, state, normalize(action.data, schemaBySlug.artists));
    case types.UPDATE_BRAND_DATA:
      return merge({}, state, normalize(action.data, brandSchema));
    case types.UPDATE_BRAND_DATA_BY_SLUG:
      return merge({}, state, normalize(action.data, schemaBySlug.brands));
    case types.UPDATE_CAMPAIGN_DATA:
      return merge({}, state, normalize(action.data, campaignsSchema));
    case types.UPDATE_ARTIST_STORY_LIST:
      return {
        ...state,
        entities: {
          ...state.entities,
          lists: {
            ...state.entities.lists,
            [action.data.id]: action.data,
            [action.data.slug]: action.data,
          },
          artists:
            !Utility.isBlank(action.data.objects) &&
            action.data.objects.length !== 0
              ? {
                  ...state.entities.artists,
                  [action.data.objects[0].id]: action.data.objects[0],
                  [action.data.objects[0].slug]: action.data.objects[0],
                }
              : { ...state.entities.artists },
        },
      };
    case types.LOGOUT_USER: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ads: {},
          artists: {},
          banners: {},
          brands: {},
          features: {},
          lists: {},
          products: {},
          salons: {},
          short_banners: {},
          videos: {},
          images: {},
          media_categories: {},
          tags: {},
          offers: {},
        },
      };
    }
    default:
      return state;
  }
}
