import React, { useRef } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import images from '../../theme/Images';
import { isPresent } from '../../utils/BooleanUtility';
import colors from '../../theme/Colors';

export default function ImageUploader({ image, setImage, postInsertImage = () => {} }) {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result;
        setImage(base64String);
      };

      reader.readAsDataURL(file);
      postInsertImage();
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <View style={styles.avatarContainer}>
      <TouchableOpacity onPress={handleUploadClick} style={styles.avatar}>
        <Image
          source={{ uri: image || images.uploadProfileImagePlaceholder }}
          style={styles.image}
          resizeMode="cover"
        />
        {isPresent(image) && <Text style={styles.edit}>Edit</Text>}
      </TouchableOpacity>
      <Text style={styles.caption}>Add your profile picture</Text>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  avatar: {
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    borderRadius: 75,
    overflow: 'clip',
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 75,
    overflow: 'clip',
  },
  edit: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    color: colors.white,
    '-webkit-backdrop-filter': 'blur(8px)',
    backdropFilter: 'blur(8px)',
    width: '100%',
    backgroundColor: '#00000042',
    fontSize: 11,
    height: 23,
    lineHeight: 23,
    fontFamily: 'Roboto-Regular',
  },
  caption: {
    alignSelf: 'center',
    color: colors.disabled,
    fontSize: 12,
    marginTop: 4,
    fontFamily: 'Roboto-Regular',
  },
});
