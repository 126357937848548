import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ScrollView } from 'react-native-gesture-handler';
import { withMaybe } from '../../lib/Monads';
import { List } from '../List';
import styles from './styles';
import {
  getEmptySearchData,
  clearSearchHistory,
} from '../../actions/ActionTypes';
import Options from './Options';
import colors from '../../theme/Colors';
import { Header } from '../../components/header';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { MAX_LINES } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { AnalyticsManager } from '../../analytics';
import Masonry from '../../components/feature/Masonry';
import ErrorBoundary from '../../components/shared/ErrorBoundary';

class EmptySearch extends Component {
  componentDidMount() {
    AnalyticsManager.setCurrentScreen(SCREEN_CONSTANTS.SEARCH);
  }

  searchConstants = {
    history: 'History',
    topSearches: 'Top Searches',
  };

  clearHistory = () => {
    const { clearSearchHistory } = this.props;
    clearSearchHistory();
  };

  clearButtonCondition = (props) =>
    props.heading !== this.searchConstants.history;

  clearButton = withMaybe(this.clearButtonCondition)(TouchableOpacity);

  header = (props) => {
    const { heading } = props;
    return (
      <View style={styles.headingContainer}>
        <Text numberOfLines={MAX_LINES.single} style={styles.subHeading}>
          {heading}
        </Text>
        <this.clearButton heading={heading} onPress={this.clearHistory}>
          <Text style={styles.clearButton}>Clear</Text>
        </this.clearButton>
      </View>
    );
  };

  history = (props) => {
    const { optionArray, onPillPress } = props;
    if (optionArray.length === 0) {
      return null;
    }
    return (
      <>
        <this.header heading={this.searchConstants.history} />
        <Options optionArray={optionArray} onPillPress={onPillPress} />
      </>
    );
  };

  topSearches = (props) => {
    const { optionArray, onPillPress } = props;
    const { navigation } = this.props;
    if (optionArray.length === 0) {
      return null;
    }
    return (
      <>
        <this.header heading={this.searchConstants.topSearches} />
        <Options
          maxHeight
          optionArray={optionArray}
          onPillPress={onPillPress}
          navigation={navigation}
        />
      </>
    );
  };

  getList = (props) => {
    const { id, item, name, index } = props;
    const { navigation } = this.props;
    return (
      <>
        <Header item={item} previousScreen={SCREEN_CONSTANTS.EMPTY_SEARCH} />
        <List
          id={id}
          previousScreen={SCREEN_CONSTANTS.EMPTY_SEARCH}
          itemData={item}
          listId={id}
          listName={name}
          index={index}
          listContent={
            Utility.isPresent(item) && Utility.isPresent(item.content)
              ? item.content
              : ''
          }
          navigation={navigation}
        />
      </>
    );
  };

  productSearchEmptyState = () => (
    <View style={styles.emptySearchLoader}>
      <ActivityIndicator animating color={colors.foxyPink} size='large' />
    </View>
  );

  getFeatureList = (props) => {
    const { id, item, name } = props;
    const { showFreeProductScreen, navigation } = this.props;
    if (Utility.isBlank(item)) {
      return null;
    }
    return (
      <Masonry
        itemData={item}
        isSearchDesign
        showCart={this.props.toggleCartVisibility}
        showFreeProductScreen={showFreeProductScreen}
        navigation={navigation}
      />
    );
  };

  dismissInputKeyboard = () => {
    const { dismissKeyboard } = this.props;
    dismissKeyboard();
  };

  render() {
    let {
      searchHistory,
      topSearches,
      emptySearchVideos = {},
      emptySearchProducts,
      onPillPress,
      emptySearchFeature,
      isPreviousScreenScanProduct,
      emptySearchError,
      loyaltyPlanEntryPoint,
    } = this.props;

    if (searchHistory.length > 10) {
      searchHistory = searchHistory.slice(
        0,
        searchHistory.length - (searchHistory.length - 10),
      );
    }
    if (isPreviousScreenScanProduct || Utility.isBlank(topSearches)) {
      return <this.productSearchEmptyState />;
    }
    return (
      <ErrorBoundary showServerError={emptySearchError} hideHeader>
        <ScrollView
          style={styles.emptySearchContainer}
          keyboardShouldPersistTaps='handled'
          onScrollBeginDrag={this.dismissInputKeyboard}
        >
          <this.history optionArray={searchHistory} onPillPress={onPillPress} />
          <this.topSearches
            optionArray={topSearches}
            onPillPress={onPillPress}
          />
          <this.getList
            id={loyaltyPlanEntryPoint?.id}
            item={loyaltyPlanEntryPoint}
            name={'Loyalty Plan Entry Point'}
            index={0}
          />
          <this.getFeatureList
            id='empty-search-products'
            item={emptySearchFeature}
            name='Most searched products'
          />
          <this.getList
            id='empty-search-videos'
            item={emptySearchVideos}
            name='Trending videos'
            index={0}
          />
          <this.getList
            id='empty-search-products'
            item={emptySearchProducts}
            name='Most searched products'
            index={1}
          />
          <View style={styles.emptySearchBackground} />
        </ScrollView>
      </ErrorBoundary>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getEmptySearchData, clearSearchHistory }, dispatch),
  };
}

export default withNavigation(connect(null, mapDispatchToProps)(EmptySearch));
