import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  ScrollView,
  Linking,
  BackHandler,
  RefreshControl,
} from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import Permissions from '../../libraries/ReactNativePermissions';
import { connect } from 'react-redux';
import StyleSheet from 'react-native-media-query';
import { bindActionCreators } from 'redux';
import FastImageView from '../../components/FastImageView';
import RoutineActionCard from '../../components/Routines/RoutineDetails/RoutineActionCard/RoutineActionCard';
import RoutineCalenderCard from '../../components/Routines/RoutineDetails/RoutineCalenderCard';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import {
  getRoutineDetails,
  routineActivityDone,
} from '../../actions/RoutineActions';
import { uploadImageReview } from '../../actions/FacialAnalysisActions';
import moment from 'moment';
import RoutineActivityList from '../../components/Routines/RoutineDetails/RoutineActivityList';
import DuringRoutineCardPlaceholder from '../../components/Routines/ShimmerPlaceholderCards/DuringRoutineCardPlaceholder';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import RoutineDailyProgressCard from '../../components/Routines/RoutineDetails/RoutineDailyProgressCard';
import _ from 'lodash';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import LeaderBoard from '../../components/Routines/LeaderBoard';
import RoutineParticipationInfo from '../../components/Routines/RoutineParticipationInfo';
import CongratulationsCard from '../../components/Routines/CongratulationsCard';
import ThankYouCard from '../../components/Routines/ThankYouCard';
import RoutineTotalProgressCard from '../../components/Routines/RoutineDetails/RoutineTotalProgressCard';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import LargeActionCard from '../../components/layout/cards/LargeActionCard';
import RemoteConfig from '../../utils/RemoteConfig';
import {
  NOTIFICATION_TYPE,
  REMOTE_CONFIG_KEYS,
  ROUTINE_ACTIVITY_ACTIONS,
  ROUTINE_ACTIVITY_TYPES,
  WWW_WEB_URL,
} from '../../config/Constants';
import RoutineDailyUploadProgress from '../../components/Routines/RoutineDetails/RoutineDailyUploadProgress';
import ImagePicker from 'react-native-image-crop-picker';
import CameraUtils from '../../utils/CameraUtils';
import Toast from 'react-native-easy-toast';
import RoutineLiveActionCard from '../../components/Routines/RoutineDetails/RoutineLiveActionCard';
import CouponCodeCopyCard from '../../components/orders/actionCards/CouponCodeCopyCard';
import RoutinesUtility from '../../components/Routines/RoutinesUtility';
import RoutineLiveStreamEndedCard from '../../components/Routines/RoutineDetails/RoutineLiveStreamEndedCard';
import RoutineScoreFeatureCard from '../../components/Routines/RoutineDetails/RoutineScoreFeatureCard';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { isDesktop, isNative, isWeb } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { getFirebasePerformanceTrace } from '../../utils/PerformanceUtility';

var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class DuringRoutineDetails extends Component {
  constructor(props) {
    super(props);
    const { navigation, route } = props;

    this.isRoutineActive = route.params?.isRoutineActive ?? true;
    this.slug = route.params?.slug || '';
    this.currentState = route.params?.extra?.current_state;
    if (Utility.isBlank(this.isRoutineActive)) {
      if (this.currentState === 'active') {
        this.isRoutineActive = true;
      } else if (this.currentState === 'inactive') {
        this.isRoutineActive = false;
      }
    }
    this.state = {
      selectedDay: this.isRoutineActive ? 1 : 0,
      selectedDayDateTime: new Date(),
      itemData: {},
      routineDaysData: [],
      dailyProgress: [],
      dailyPhotoes: [],
      isLoading: true,
      serverError: false,
      retryLoading: false,
      connectionError: false,
      upcomingEvent: {},
      myDailyRanks: [],
      liveStreams: [],
      readExternalStoragePermission: false,
      selectedDayActivitiesData: [],
    };

    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.DURING_ROUTINE);
    this.trace.start();
    if (this.isRoutineActive) {
      this.fetchRoutineDaysDetails();
    } else {
      this.fetchPostRoutine();
    }

    this.shimmerColors = ['#fafafa', '#eeeeee', '#fafafa'];
    this.LayoutComponent = {
      list: RoutineActivityList,
      liveStream: RoutineLiveActionCard,
      feature: RoutineActionCard,
      poll: RoutineActionCard,
      liveStreamEnded: RoutineLiveStreamEndedCard,
    };
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onBackPress,
      );
    }
  }

  componentDidMount() {
    AnalyticsManager.setCurrentScreen(
      EventParameterValue.SCREEN_NAME.MY_VIDEOS,
    );
    this.rateExperienceDetails = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.routine_rate_experience,
    );

    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      Utility.setStatusbarTranslucent();
    });
    this.unsubscribeBlur = navigation.addListener('blur', () => {
      Utility.setStatusBarWhite();
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribeBlur();
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  fireNotification = () => {
    const { has_selfie } = this.props;
    let isRoutine = this.orderResponse?.no_of_routine_items !== 0;
    if (!isRoutine || Utility.isBlank(this.orderResponse?.skus)) return;
    const routineName = this.orderResponse?.skus[0].name ?? '';
    const artist = this.orderResponse?.routine_artist ?? '';
    let title = `You're just 1 selfie away...`;
    let description = `Get product recommendations directly from the expert! Take your selfie & see what ${artist} recommends for you.`;
    let link = `${WEB_URL}/take_selfie?utm_source=local_notification&utm_medium=take_selfie`;
    let cta = NOTIFICATION_ACTIONS.CLICK_SELFIE.cta;
    let type = NOTIFICATION_TYPE.SUBSCRIPTION_SUCCESS_OPEN_SELFIE;
    if (has_selfie) {
      title = `Get ready for ${routineName}!`;
      description = `Congrats! You have successfully subscribed to the routine. Tap here for more info`;
      link = `${WEB_URL}/all_routines?utm_source=local_notification&utm_medium=routines`;
      cta = NOTIFICATION_ACTIONS.CONTINUE.cta;
      type = NOTIFICATION_TYPE.SUBSCRIPTION_SUCCESS_GOTO_ROUTINE;
    }
    const payload = {
      title,
      description,
      deepLink: link,
      primary_cta: cta,
      primary_destination: link,
      secondary_cta: NOTIFICATION_ACTIONS.DISMISS.cta,
      secondary_destination: NOTIFICATION_ACTIONS.DISMISS.action,
      type,
    };
    NotificationManager.createNotification(
      payload,
      NOTIFICATION_IDENTIFIERS.SELFIE_ANALYSIS,
    );
  };

  parseDailyLiveStreamVideos = (routineDays) => {
    let liveStreams = [];
    if (Utility.isBlank(routineDays)) return liveStreams;
    liveStreams = routineDays.map((day) => {
      const liveStreamArray = day?.routineActivities.filter((activity) => {
        const { activity: { arguments: args = {} } = {} } = activity;
        if (args?.cardType === 'liveStream') {
          return true;
        }
        return false;
      });
      if (Utility.isPresent(liveStreamArray) && liveStreamArray.length > 0) {
        return liveStreamArray[0];
      }
      return null;
    });
    return liveStreams;
  };

  calculateDailyProgress = (routineDays) => {
    let progressArray = [];
    if (Utility.isBlank(routineDays)) return progressArray;
    progressArray = routineDays.map((day) => {
      const myPoints = day.myPoints;
      const totalPoints = day.totalPoints;
      if (totalPoints > 0) {
        const percent = (myPoints / totalPoints) * 100;
        const percentString = `${percent}%`;
        return percentString;
      }
      return '0%';
    });
    return progressArray;
  };

  calculateDailyRanks = (routineDays) => {
    let dailyRanks = [];
    if (Utility.isBlank(routineDays)) return dailyRanks;
    const routineDaysWithTop3Ranks = routineDays.filter(
      (day) =>
        Utility.isPresent(day.myRank) &&
        Utility.isPresent(day.dayNum) &&
        day.myRank < 4,
    );
    dailyRanks = routineDaysWithTop3Ranks.map((day) => {
      return { myRank: day.myRank, day: day.dayNum };
    });
    return dailyRanks;
  };

  parseDailyPhotoes = (routineDays) => {
    let dailyPhotoes = [];
    if (Utility.isBlank(routineDays)) return dailyPhotoes;
    let tRoutineDays = routineDays.filter((item) =>
      Utility.isPresent(item.myPhoto),
    );
    dailyPhotoes = tRoutineDays.map((item) => {
      return { image: item.myPhoto, day: item.dayNum };
    });
    return dailyPhotoes;
  };

  parseActivitiesData = (response) => {
    if (Utility.isBlank(response)) return {};
    return response.routineDays;
  };

  fetchPostRoutine = () => {
    const { getRoutineDetails, navigation } = this.props;
    getRoutineDetails(this.slug, (success, response) => {
      if (!this.traceStopped) {
        this.trace.stop();
        this.traceStopped = true;
      }
      if (!success) {
        if (response.errorType === 'api_failure') {
          this.setState({
            isLoading: false,
            serverError: response.status === 'server_error',
            connectionError: response.status === 'connection_error',
            retryLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
            serverError: false,
            connectionError: true,
            retryLoading: false,
          });
        }
        return;
      }
      try {
        new JSONAPIDeserializer({
          typeAsAttribute: false,
          pluralizeType: true,
          keyForAttribute: 'camelCase',
        })
          .deserialize(response)
          .then((data) => {
            console.tron.log('post routine data: ', data);
            const routineDays = this.parseActivitiesData(data);
            this.setState({
              itemData: data,
              routineDaysData: routineDays,
              dailyProgress: this.calculateDailyProgress(routineDays),
              myDailyRanks: this.calculateDailyRanks(routineDays),
              dailyPhotoes: this.parseDailyPhotoes(routineDays),
              isLoading: false,
              connectionError: false,
            });
            AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
              [EventParameterKey.PAGE]: SCREEN_CONSTANTS.POST_ROUTINE,
            });
            this.fireRoutineViewEvent();
          });
      } catch (e) {}
    });
  };

  fetchRoutineDaysDetails = () => {
    const { getRoutineDetails, navigation } = this.props;
    const { selectedDay } = this.state;
    getRoutineDetails(this.slug, (success, response) => {
      if (!this.traceStopped) {
        this.trace.stop();
        this.traceStopped = true;
      }
      if (!success) {
        if (response.errorType === 'api_failure') {
          this.setState({
            isLoading: false,
            serverError: response.status === 'server_error',
            connectionError: response.status === 'connection_error',
            retryLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
            serverError: false,
            connectionError: true,
            retryLoading: false,
          });
        }
        return;
      }

      try {
        new JSONAPIDeserializer({
          typeAsAttribute: false,
          pluralizeType: true,
          keyForAttribute: 'camelCase',
        })
          .deserialize(response)
          .then((data) => {
            const { routineDays = [] } = data;
            const { currentDay = 1 } = data;
            this.setState(
              {
                routineDaysData: routineDays,
                selectedDay: currentDay,
                liveStreams: this.parseDailyLiveStreamVideos(routineDays),
                dailyPhotoes: this.parseDailyPhotoes(routineDays),
                isLoading: false,
                retryLoading: false,
                itemData: data,
                connectionError: false,
              },
              this.setRoutineActivitiesForTheDay,
            );
            AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
              [EventParameterKey.PAGE]: SCREEN_CONSTANTS.DURING_ROUTINE,
            });
            this.fireRoutineViewEvent(selectedDay);
            if (isNative()) {
              this.createDuringRoutineNotifications(data);
            }
          });
      } catch (e) {}
    });
  };

  fireRoutineViewEvent = (day = '') => {
    const {
      itemData: { id: routineId = '', name = '' },
    } = this.state;
    const page = this.isRoutineActive
      ? SCREEN_CONSTANTS.DURING_ROUTINE
      : SCREEN_CONSTANTS.POST_ROUTINE;
    let viewMeta = {
      [EventParameterKey.ROUTINES.PAGE_TYPE]: page,
      [EventParameterKey.ROUTINES.ROUTINE_NAME]: name,
      [EventParameterKey.ROUTINES.ROUTINE_ID]: routineId,
    };
    if (Utility.isPresent(day) && this.isRoutineActive) {
      viewMeta = {
        ...viewMeta,
        [EventParameterKey.ROUTINES.ROUTINE_DAY]: day,
      };
    }
    AnalyticsManager.logEvent(EventType.routine.ROUTINE_VIEW, viewMeta);
  };

  fireRoutineActionCardEvent = (id = '', activityType = '') => {
    const {
      selectedDay,
      routineDaysData = [],
      itemData: { id: routineId = '', name = '' },
    } = this.state;
    const { routineActivities = [] } = routineDaysData[selectedDay - 1];
    const activity = routineActivities.filter(
      (element) => element?.id === id,
    )?.[0];
    const action = ROUTINE_ACTIVITY_ACTIONS[`${activityType}`];
    console.tron.log('activity details', activity, id, routineActivities);
    AnalyticsManager.logEvent(EventType.routine.ROUTINE_CARD_ACTION, {
      [EventParameterKey.ROUTINES.CARD_ID]: id,
      [EventParameterKey.ROUTINES.ROUTINE_NAME]: name,
      [EventParameterKey.ROUTINES.ROUTINE_ID]: routineId,
      [EventParameterKey.TYPE]: activityType,
      [EventParameterKey.ACTION]: action,
      [EventParameterKey.ROUTINES.ROUTINE_DAY]: selectedDay,
      [EventParameterKey.ROUTINES.CARD_LIVE_TIME]:
        RoutinesUtility.getDateString(activity?.startsAt),
      [EventParameterKey.ROUTINES.CARD_CLICK_TIME]:
        RoutinesUtility.getLocalDateString(),
    });
  };

  onBackPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  onShareTapped = () => {
    const {
      itemData: { name = '', id = '', skuId = '', shareUrl = '' },
    } = this.state;
    const generalShareOption = {
      title: 'Foxy',
      message: `Check out this amazing routine I am participating in : ${name}. Let's join it together & help each other achieve our goals ✨`,
      url: shareUrl,
    };
    Utility.onShareTapped(shareUrl, generalShareOption);
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.ACTION]: 'share',
      [EventParameterKey.NAME]: name,
      [EventParameterKey.ID]: id,
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.DURING_ROUTINE,
      [EventParameterKey.SKU_ID]: skuId,
    });
  };

  navigateToDetailsPage = () => {
    const { navigation } = this.props;
    const {
      itemData,
      itemData: { id = '', name = '', skuId = '' },
    } = this.state;
    const source = this.isRoutineActive
      ? SCREEN_CONSTANTS.POST_ROUTINE
      : SCREEN_CONSTANTS.DURING_ROUTINE;
    navigation.navigate('PreRoutineDetails', {
      slug: this.slug,
      source: source,
      itemData: itemData,
    });
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.ACTION]: 'info',
      [EventParameterKey.NAME]: name,
      [EventParameterKey.ID]: id,
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.DURING_ROUTINE,
      [EventParameterKey.SKU_ID]: skuId,
    });
  };

  onChatTapped = () => {
    const { navigation } = this.props;
    const {
      itemData: {
        name = '',
        skuId = '',
        routineDiscussionGroups = [],
        cardImageUrl = '',
        id: routineId = '',
      } = {},
    } = this.state;
    navigation.navigate('RoutineGroups', {
      routineGroups: routineDiscussionGroups,
      cardImageUrl,
      routineId,
    });
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.ACTION]: 'chat',
      [EventParameterKey.NAME]: name,
      [EventParameterKey.ID]: routineId,
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.DURING_ROUTINE,
      [EventParameterKey.SKU_ID]: skuId,
    });
  };

  emptyHeader = () => {
    const {
      itemData: { name = '' },
    } = this.state;

    const headerStyle = isWeb()
      ? styles.headerContainerWeb
      : styles.headerContainer;
    return (
      <View style={headerStyle}>
        <TouchableOpacity
          style={styles.headerIconContainer}
          onPress={this.onBackPress}
        >
          <Image source={images.ic_back_product} style={styles.headerIcon} />
        </TouchableOpacity>
      </View>
    );
  };

  header = () => {
    const {
      itemData: { name = '' },
    } = this.state;

    const headerStyle = isWeb()
      ? styles.headerContainerWeb
      : styles.headerContainer;
    return (
      <View style={headerStyle}>
        <TouchableOpacity
          style={styles.headerIconContainer}
          onPress={this.onBackPress}
        >
          <Image source={images.ic_back_product} style={styles.headerIcon} />
        </TouchableOpacity>
        <Text style={styles.headerTitle}>{name}</Text>
        <TouchableOpacity
          style={styles.headerIconContainer}
          onPress={this.onChatTapped}
        >
          <FastImageView
            source={images.routines.whatsApp_white}
            style={styles.headerIcon}
            tintColor={colors.black}
            resizeMode='contain'
          />
        </TouchableOpacity>

        {isNative() && (
          <TouchableOpacity
            style={styles.headerIconContainer}
            onPress={this.onShareTapped}
          >
            <FastImageView
              source={images.routines.share_icon}
              style={styles.headerIcon}
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={styles.headerIconContainer}
          onPress={this.navigateToDetailsPage}
        >
          <FastImageView
            source={images.routines.info_icon}
            style={styles.headerIcon}
          />
        </TouchableOpacity>
      </View>
    );
  };

  actionButtonPress = (id, activityType = '', successCallback = () => {}) => {
    if (!this.isRoutineActive) {
      return;
    }
    this.markRoutineActivityAsDone(id, activityType, successCallback);
  };

  markRoutineActivityAsDone = (
    id,
    activityType,
    successCallback = () => {},
  ) => {
    const { itemData } = this.state;
    const { routineActivityDone } = this.props;
    this.fireRoutineActionCardEvent(id, activityType);
    routineActivityDone(this.slug, id, (success, response) => {
      if ((success, response)) {
        this.setState({
          itemData: {
            ...itemData,
            myRewardPoints: response?.points || itemData.myRewardPoints,
          },
        });
        if (activityType === ROUTINE_ACTIVITY_TYPES.UPLOAD) {
          if (Utility.isBlank(response.image_urls)) {
            this.uploadMedia(id, successCallback, response?.id);
            return;
          }
        }
        this.updateMarkAsDoneInState(id);
        successCallback(true);
      }
    });
  };

  updateMarkAsDoneInState = (id) => {
    const { routineDaysData = [], selectedDay } = this.state;
    const { routineActivities = [] } = routineDaysData[selectedDay - 1];
    const updatedActivitiesData = routineActivities?.map((activity) => {
      if (activity?.id === id) {
        return { ...activity, markedDone: true };
      }
      return activity;
    });
    const updatedRoutineDay = {
      ...routineDaysData[selectedDay - 1],
      routineActivities: updatedActivitiesData,
    };
    const updatedRoutineDaysData = routineDaysData?.map((routineDay) => {
      if (routineDay?.dayNum === updatedRoutineDay?.dayNum) {
        return updatedRoutineDay;
      }
      return routineDay;
    });
    this.setState({
      routineDaysData: updatedRoutineDaysData,
    });
  };

  uploadMedia = (id, successCallback, activity_id) => {
    const { readExternalStoragePermission } = this.state;
    if (readExternalStoragePermission) {
      ImagePicker.openPicker({
        mediaType: 'photo',
        multiple: false,
      })
        .then((image) => {
          this.convertImageToBase64AndUpload(
            id,
            image?.path,
            successCallback,
            activity_id,
          );
        })
        .catch((e) => {
          successCallback(false);
        });
    } else {
      this.RequestExternalStoragePermission(id, successCallback, activity_id);
    }
  };

  RequestExternalStoragePermission = (id, successCallback, activity_id) => {
    // const mediaLibrary = Utility.isAndroid() ? 'storage' : 'photo';
    Permissions.request('photo').then((response) => {
      const isPermissionAllowed = response === 'authorized';
      const metaData = { type: 'media_library' };
      if (response === 'authorized') {
        this.setState(
          {
            readExternalStoragePermission: true,
          },
          () => {
            this.uploadMedia(id, successCallback, activity_id);
          },
        );
        return;
      }
      if (Utility.isIOS() && response === 'denied') {
        this.openSettings(successCallback);
      }
      if (response === 'restricted') {
        this.openSettings(successCallback);
      }
      Utility.logPermissionEvent(isPermissionAllowed, metaData);
    });
  };

  openSettings = (successCallback) => {
    const { navigation } = this.props;
    Alert.alert(
      'Permission Required',
      'Change Access permission inside Settings to allow photo access',
      [
        {
          text: 'Open Settings',
          onPress: () => {
            Linking.openSettings();
          },
        },
        {
          text: 'Cancel',
          onPress: () => {
            successCallback(false);
          },
          style: 'cancel',
        },
      ],
    );
  };

  convertImageToBase64AndUpload = (id, uri, successCallback, activity_id) => {
    const { uploadImageReview } = this.props;
    const { selectedDay } = this.state;
    CameraUtils.convertImageToBase64(uri)
      .then((response) => {
        const postData = {
          base64Data: `data:image/jpeg;base64,${response}`,
          id: activity_id,
          type: 'UsersRoutineActivity',
        };
        uploadImageReview(postData, (success) => {
          if (success) {
            this.markRoutineActivityAsDone(
              id,
              ROUTINE_ACTIVITY_TYPES.UPLOAD,
              successCallback,
            );
            successCallback(true);
            return;
          }
          this.toast?.show('Error Uploading! Please try again.', 1000);
        });
      })
      .catch((error) => {
        successCallback(false);
      });
  };

  checkIfLiveStreamEnded = (activityStartTime, duration) => {
    const fiveMinIntervalInMil = 5 * 60 * 1000;
    const timezoneOffsetDate =
      RoutinesUtility.getLocalTimeFromUTC(activityStartTime);
    const liveStreamEndedTime = new Date(
      timezoneOffsetDate.getTime() +
        duration * 60 * 1000 +
        fiveMinIntervalInMil,
    );
    return new Date() > liveStreamEndedTime;
  };

  getLayoutComponentForActionCard = (item) => {
    const {
      activityType = '',
      duration = '',
      startsAt = new Date(),
      activity: { arguments: args = {} } = {},
    } = item;

    let LayoutComponent = this.LayoutComponent[`${activityType}`.toLowerCase()];
    if (args?.cardType === 'liveStream') {
      LayoutComponent = this.LayoutComponent['liveStream'];
      const hasLiveEnded = this.checkIfLiveStreamEnded(startsAt, duration);
      if (hasLiveEnded) {
        LayoutComponent = this.LayoutComponent['liveStreamEnded'];
      }
    }
    return LayoutComponent;
  };

  setActionButtonVisibility = (item) => {
    const { dailyPhotoes = [], selectedDay } = this.state;
    const {
      activityType = '',
      startsAt = new Date(),
      activity: { arguments: args = {} } = {},
    } = item;
    const dailyUploadExists = dailyPhotoes.filter(
      (element) => element?.day === selectedDay,
    );
    const isUploadActionCard =
      args?.action === 'upload' && Utility.isPresent(dailyUploadExists);
    const isPollActionCard = activityType === 'Poll';
    const isEnabled =
      new Date() > RoutinesUtility.getLocalTimeFromUTC(startsAt);
    const isToday = RoutinesUtility.isToday(startsAt);
    if (isPollActionCard && !isToday) {
      return 'disabled';
    }
    if (isUploadActionCard || isPollActionCard) {
      return 'hidden';
    }
    if (isEnabled && isToday && this.isRoutineActive) {
      return 'visible';
    }
    return 'disabled';
  };

  renderRoutineActivity = ({ item, index }) => {
    const {
      selectedDayActivitiesData = [],
      dailyPhotoes = [],
      itemData: { artist = {} } = {},
    } = this.state;

    const {
      startsAt: currentActivityStartTime = '',
      activity: { id: listId = '' } = {},
    } = item;
    const { navigation } = this.props;
    const { startsAt: nextActivityStartTime = '' } =
      selectedDayActivitiesData?.[index + 1] || {};

    const LayoutComponent = this.getLayoutComponentForActionCard(item);
    if (LayoutComponent === null || LayoutComponent === undefined) return null;
    const buttonVisibility = this.setActionButtonVisibility(item);
    const currentActivityOffsetTime = RoutinesUtility.getLocalTimeFromUTC(
      currentActivityStartTime,
    );
    const nextActivityOffsetTime = RoutinesUtility.getLocalTimeFromUTC(
      nextActivityStartTime,
    );
    const timeToShow = moment(currentActivityOffsetTime)
      .format('hh:mm a')
      .replace(/^0+/, '');

    return (
      <View style={styles.routineActivityContainer}>
        <View style={styles.routineItemContainer}>
          <View style={{ marginBottom: 12 }}>
            <LayoutComponent
              itemData={item}
              slug={this.slug}
              listId={listId}
              onActionPress={this.actionButtonPress}
              dailyPhotos={dailyPhotoes}
              buttonVisibility={buttonVisibility}
              inactivePollTapped={this.inactivePollTapped}
              artist={artist}
              navigation={navigation}
            />
          </View>
        </View>
        <View style={styles.timelineContainer}>
          <Text style={styles.timelineText}>{timeToShow}</Text>
          {Utility.isPresent(nextActivityStartTime) &&
            this.verticalLineIndicator(
              currentActivityOffsetTime,
              nextActivityOffsetTime,
            )}
        </View>
      </View>
    );
  };

  verticalLineIndicator = (currentActivityTime, nextActivityTime) => {
    const progress = this.getIndicatorProgress(
      currentActivityTime,
      nextActivityTime,
    );
    const indicatorStyle = [styles.verticalLineGreen, { height: progress }];
    return (
      <View style={{ height: '90%' }}>
        <View style={styles.verticalLine} />
        <View style={indicatorStyle} />
      </View>
    );
  };

  getIndicatorProgress = (currentActivityTimestamp, nextActivityTimestamp) => {
    const { selectedDayDateTime } = this.state;
    if (RoutinesUtility.isDateInFuture(selectedDayDateTime)) {
      return '0%';
    }
    if (
      RoutinesUtility.isDateInPast(selectedDayDateTime) ||
      !this.isRoutineActive
    ) {
      return '100%';
    }
    const currentTimeInMinutes =
      new Date().getHours() * 60 + new Date().getMinutes();
    const currentActivityTimeInMinutes =
      new Date(currentActivityTimestamp).getHours() * 60 +
      new Date(currentActivityTimestamp).getMinutes();
    const nextActivityTimeInMinutes =
      new Date(nextActivityTimestamp).getHours() * 60 +
      new Date(nextActivityTimestamp).getMinutes();
    if (currentTimeInMinutes > currentActivityTimeInMinutes) {
      if (currentTimeInMinutes >= nextActivityTimeInMinutes) {
        return '100%';
      }
      const totalTimeDiff =
        nextActivityTimeInMinutes - currentActivityTimeInMinutes;
      const currentActivityTimeDiff =
        currentTimeInMinutes - currentActivityTimeInMinutes;
      return `${((currentActivityTimeDiff * 100) / totalTimeDiff).toFixed(0)}%`;
    }
    return '0%';
  };

  onChangeDay = (day) => {
    this.setState(
      {
        selectedDay: day,
      },
      this.setRoutineActivitiesForTheDay,
    );
    if (this.isRoutineActive) {
      this.fireRoutineViewEvent(day);
    }
  };

  setRoutineActivitiesForTheDay = () => {
    const {
      routineDaysData,
      selectedDay,
      itemData: { startsAt = '' },
    } = this.state;
    const selectedIndex = routineDaysData.findIndex(
      (element) => element?.dayNum === selectedDay,
    );

    const { routineActivities: activitiesForDay = [] } =
      routineDaysData[selectedIndex] || {};

    let sortedActivityList = activitiesForDay?.sort((a, b) => {
      const timeA = RoutinesUtility.getLocalTimeFromUTC(a?.startsAt);
      const timeB = RoutinesUtility.getLocalTimeFromUTC(b?.startsAt);
      return timeA - timeB;
    });
    sortedActivityList = sortedActivityList.filter((item) =>
      Utility.isPresent(item),
    );

    const selectedDayDateTime = RoutinesUtility.addXDaysToDate(
      startsAt,
      selectedDay - 1,
    );
    this.setState({
      selectedDayActivitiesData: sortedActivityList,
      selectedDayDateTime: selectedDayDateTime,
    });
  };

  screenPlaceholder = () => {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <ShimmerPlaceHolder
          colorShimmer={this.shimmerColors}
          autoRun
          style={styles.placeholderTitle}
        />
        <FlatList
          data={new Array(5)}
          horizontal
          keyExtractor={(item) => `${item}`}
          renderItem={() => (
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.placeholderListItem}
            />
          )}
          showsHorizontalScrollIndicator={false}
          bounces={false}
        />
        <View style={styles.routineActivityContainer}>
          <View style={styles.routineItemContainer}>
            <DuringRoutineCardPlaceholder />
          </View>
          <View style={styles.timelineContainer}>
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.timelineTextPlaceholder}
            />
            <View style={styles.verticalLine} />
          </View>
        </View>
        <View style={styles.routineActivityContainer}>
          <View style={styles.routineItemContainer}>
            <DuringRoutineCardPlaceholder />
          </View>
          <View style={styles.timelineContainer}>
            <ShimmerPlaceHolder
              colorShimmer={this.shimmerColors}
              autoRun
              style={styles.timelineTextPlaceholder}
            />
          </View>
        </View>
      </ScrollView>
    );
  };

  dashedLine = () => {
    return (
      <View style={styles.dashedBorder}>
        <View style={styles.borderMaskContainer}>
          <View style={styles.borderMask} />
        </View>
      </View>
    );
  };

  upcomingLiveStream = (selectedDay, numberOfDays) => {
    const { liveStreams } = this.state;
    for (let i = selectedDay + 1; i <= numberOfDays; i += 1) {
      const liveStream = liveStreams[i - 1];
      if (Utility.isPresent(liveStream)) {
        return liveStream;
      }
    }
    return null;
  };

  getLiveText = () => {
    const {
      itemData: { startsAt: activityStartTime = new Date() },
    } = this.props;
    const hasLiveEnded = new Date() > this.offsetEndTime;
    if (this.isLive) {
      return 'LIVE';
    }
    if (hasLiveEnded) {
      return 'LIVE ENDED';
    }
    return RoutinesUtility.getDateString(activityStartTime);
  };

  renderUpcomingSection = ({ upcomingLiveStream, artist }) => {
    return (
      <>
        <this.upComingDivider />
        <View style={{ marginBottom: 36 }}>
          <RoutineLiveActionCard
            itemData={upcomingLiveStream}
            listId={upcomingLiveStream?.activity?.id}
            artist={artist}
          />
        </View>
      </>
    );
  };

  progressFeatureCardRail = () => {
    const {
      itemData: { percentile = '', consistency = '', myRewardPoints = '' },
    } = this.state;
    const pointsExist =
      Utility.isPresent(myRewardPoints) && myRewardPoints !== 0;
    const consistencyHeading = pointsExist ? 'Consistency' : 'Your Score';
    const consistencySubheading = pointsExist
      ? `Better than ${consistency}% of participants who logged in & completed tasks`
      : 'You will be able to see your daily progress here';
    if (!this.isRoutineActive) {
      return null;
    }
    const cardWidth = pointsExist
      ? Utility.getScreenWidth() - 60
      : Utility.getScreenWidth() - 24;
    return (
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.progressFeatureRailContainer}
      >
        {pointsExist && (
          <RoutineScoreFeatureCard
            heading={'Participation'}
            subheading={`Better than ${percentile}% of the participants in this challenge`}
            backgroundColor={colors.light_purple}
            imageUrl={images.routines.participation_feature}
            buttonBackground={colors.routine_golden}
            buttonTextColor={colors.black}
            totalPoints={myRewardPoints}
            width={cardWidth}
          />
        )}
        <RoutineScoreFeatureCard
          heading={consistencyHeading}
          subheading={consistencySubheading}
          backgroundColor={colors.foxyBlack}
          imageUrl={images.routines.consistency_feature}
          buttonBackground={colors.routine_golden}
          buttonTextColor={colors.black}
          totalPoints={myRewardPoints}
          width={cardWidth}
        />
      </ScrollView>
    );
  };

  renderPageContent = () => {
    const {
      routineDaysData,
      selectedDay,
      isLoading,
      selectedDayActivitiesData,
      itemData: { artist = {} } = {},
    } = this.state;
    if (isLoading) {
      return <this.screenPlaceholder />;
    }

    const upcomingLiveStream = this.upcomingLiveStream(
      selectedDay,
      routineDaysData.length,
    );
    return (
      <>
        <this.progressFeatureCardRail />
        <RoutineCalenderCard
          numberOfDays={routineDaysData.length}
          selectedDay={selectedDay}
          onChangeDay={this.onChangeDay}
          isRoutineActive={this.isRoutineActive}
        />
        <FlatList
          data={selectedDayActivitiesData}
          renderItem={this.renderRoutineActivity}
          keyExtractor={(item) => `${item?.name}${item?.id}`}
          scrollEnabled={true}
          showsVerticalScrollIndicator={false}
        />
        {Utility.isPresent(upcomingLiveStream) && (
          <this.renderUpcomingSection
            upcomingLiveStream={upcomingLiveStream}
            artist={artist}
          />
        )}
      </>
    );
  };

  rateExperience = () => {
    const { navigation } = this.props;
    const {
      itemData: { id: routineId = '', name = '' },
    } = this.state;
    const routineName = encodeURIComponent(name);
    navigation.navigate('WebUrlView', {
      destination: `https://foxyapp.typeform.com/to/vjKZcy17#routine_name=${routineName}&routine_id=${routineId}`,
    });
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.TYPE]: EventParameterValue.ITEM_TYPE.FEEDBACK,
      [EventParameterKey.NAME]: 'rate_experience',
      [EventParameterKey.ENTITY]: 'routine',
      [EventParameterKey.ENTITY_ID]: routineId,
    });
  };

  upComingDivider = () => {
    return (
      <View style={styles.upcomingHeaderContainer}>
        <this.dashedLine />
        <View style={styles.upcomingTitleContainer}>
          <Text style={styles.upcoming}>Upcoming</Text>
        </View>
      </View>
    );
  };

  rateExperienceCard = () => {
    const { selectedDay } = this.state;
    const isPostRoutinePage = selectedDay === 0;
    if (!isPostRoutinePage || !this.rateExperienceDetails) {
      return null;
    }
    const {
      heading = '',
      subheading = '',
      image_url = '',
      button_image_url = '',
      button_text = '',
    } = JSON.parse(this.rateExperienceDetails);
    return (
      <LargeActionCard
        imageUrl={image_url}
        heading={heading}
        subheading={subheading}
        backgroundColor={'#EDECFF'}
        firstActionButtonText={button_text}
        primaryButtonIcon={button_image_url}
        primaryButtonAction={this.rateExperience}
      />
    );
  };

  renderOfferItem = ({ item }) => {
    return <CouponCodeCopyCard itemData={item} showToast={this.showToast} />;
  };

  offerRail = () => {
    const {
      itemData: { offers = [] },
    } = this.state;
    if (Utility.isBlank(offers)) return null;
    return (
      <>
        <Text style={styles.offerRailHeading}>Offers you earned</Text>
        <FlatList
          data={offers}
          renderItem={this.renderOfferItem}
          contentContainerStyle={styles.offerFlatlist}
        />
      </>
    );
  };

  renderPostRoutinePage = () => {
    const {
      dailyProgress,
      selectedDay,
      myDailyRanks,
      dailyPhotoes,
      isLoading,
      itemData: {
        leaderBoard = {},
        artist: { name: artistName = '' } = {},
        completedPercent = '',
        dailyLogins = '',
        participantCount = '',
        myRewardPoints,
        myRank,
        myProgress = [],
      },
      routineDaysData = [],
    } = this.state;
    const { phone = '' } = this.props;
    const isPostRoutinePage = selectedDay === 0;
    return (
      <View style={styles.container}>
        {!isDesktop() && <this.header />}
        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={isLoading}
              onRefresh={this.fetchPostRoutine}
            />
          }
        >
          <ThankYouCard artistName={artistName} />
          <RoutineDailyProgressCard dailyProgress={dailyProgress} />
          <this.renderPageContent />
          <this.rateExperienceCard />
          {isPostRoutinePage && (
            <>
              <RoutineDailyUploadProgress
                dailyPhotoes={myProgress}
                routineLength={routineDaysData.length}
              />
              <LeaderBoard
                leaderBoard={leaderBoard}
                myNumber={phone}
                myRank={myRank}
                myRewardPoints={myRewardPoints}
              />
              <this.offerRail />
              <RoutineParticipationInfo
                dailyLogins={dailyLogins}
                participantCount={participantCount}
              />
              {parseInt(completedPercent) !== 0 && (
                <RoutineTotalProgressCard
                  progress={parseInt(completedPercent)}
                />
              )}
              <CongratulationsCard myDailyRanks={myDailyRanks} />
            </>
          )}
        </ScrollView>
        <Toast style={styles.toast} ref={this.toastRef} />
      </View>
    );
  };

  renderActiveRoutinePage = () => {
    const { isLoading } = this.state;
    return (
      <View style={styles.container}>
        {!isDesktop() && <this.header />}
        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={isLoading}
              onRefresh={this.fetchRoutineDaysDetails}
            />
          }
        >
          <this.renderPageContent />
        </ScrollView>
        <Toast style={styles.toast} ref={this.toastRef} />
      </View>
    );
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  showToast = (message = 'sdsdds') => {
    this.toast?.show(message, 3000);
  };

  renderRoutineDetail = () => {
    if (this.isRoutineActive) {
      return <this.renderActiveRoutinePage />;
    }
    return <this.renderPostRoutinePage />;
  };

  onPressReload = () => {
    this.setState(
      {
        retryLoading: true,
      },
      this.fetchDetails,
    );
  };

  inactivePollTapped = (item) => {
    this.toastRef.show('Poll not open yet');
  };

  setToastRef = (ref) => {
    this.toastRef = ref;
  };

  render() {
    const { serverError, retryLoading, connectionError } = this.state;
    return (
      <>
        {(serverError || connectionError) && <this.emptyHeader />}
        <ErrorBoundary
          showServerError={serverError}
          onPressReload={this.onPressReload}
          connectionError={connectionError}
          loading={retryLoading}
          hideHeader
        >
          <this.renderRoutineDetail />
        </ErrorBoundary>
        <Toast ref={this.setToastRef} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  phone: state.UserAccountInfo.mobileNumber,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getRoutineDetails,
      routineActivityDone,
      uploadImageReview,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DuringRoutineDetails);

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  headerContainerWeb: {
    padding: 12,
    paddingRight: 0,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 10,
  },
  headerContainer: {
    padding: 12,
    paddingRight: 0,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Utility.isIOS() ? 56 : 46,
  },
  headerTitle: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    flex: 1,
  },
  headerIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  headerIconContainer: {
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: colors.white,
    marginRight: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  routineItemContainer: {
    marginLeft: 52,
    marginRight: 12,
  },
  verticalLine: {
    width: 1,
    flex: 1,
    backgroundColor: colors.divider,
  },
  verticalLineGreen: {
    width: 1,
    backgroundColor: colors.green,
    zIndex: 1,
    top: 0,
    position: 'absolute',
  },
  timelineText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3_5,
    marginBottom: 4,
  },
  timelineTextPlaceholder: {
    height: 16,
    width: 32,
    borderRadius: 8,
  },
  timelineContainer: {
    position: 'absolute',
    top: 0,
    left: 8,
    height: '100%',
    width: 58,

    alignItems: 'center',
  },
  routineActivityContainer: {
    marginBottom: 22,
    marginTop: 4,
  },
  placeholderTitle: {
    width: 100,
    height: 20,
    alignSelf: 'center',
    borderRadius: 4,
  },
  placeholderListItem: {
    height: 60,
    width: 37,
    marginVertical: 20,
    marginHorizontal: 12,
    borderRadius: 20,
  },
  toast: {
    position: 'absolute',
    bottom: 0,
    maxWidth: Utility.getScreenWidth() - 32,
  },
  dashedBorder: {
    alignSelf: 'center',
    width: '60%',
    height: 1,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.foxyBlack,
    borderStyle: 'dashed',
  },
  borderMaskContainer: {
    top: 0,
    width: '100%',
    height: 1,
    backgroundColor: colors.white,
    zIndex: 2,
  },
  borderMask: {
    flex: 1,
    backgroundColor: colors.routine_green_bg,
  },
  upcoming: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    flex: 1,
    paddingHorizontal: 8,
    marginTop: -8,
    backgroundColor: colors.background,
  },
  upcomingTitleContainer: {
    position: 'absolute',
    zIndex: 1,
  },
  upcomingHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginTop: 18,
    marginBottom: 16,
  },
  offerRailHeading: {
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    marginBottom: 8,
    marginTop: 12,
    marginLeft: 16,
  },
  offerFlatlist: {
    marginBottom: 12,
  },
  progressFeatureRailContainer: {
    paddingHorizontal: 6,
    marginVertical: 8,
  },
});
