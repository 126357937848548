import React, { PureComponent } from 'react';
import AudioHandler from '../../utils/AudioHandler';
import VerticalSingleMediaCard from './VerticalSingleMediaCard';

export default class FoxyVerticalSingleMediaCard extends PureComponent {
  setVerticalSingleCardRef = (ref) => {
    const { iteratorIndex, setVerticalSingleMediaCardRef } = this.props;
    setVerticalSingleMediaCardRef(iteratorIndex, ref);
  };

  render() {
    const {
      item,
      itemData,
      listData,
      id,
      slug,
      index,
      key,
      iteratorIndex,
      goToNextPage,
      goToPrevPage,
      toggleMuteButton,
      isMuted,
      previousScreen,
      totalNumberOfVideos,
      cardWidth,
    } = this.props;
    return (
      <VerticalSingleMediaCard
        onRef={this.setVerticalSingleCardRef}
        // onRef={(ref) => {
        //   this.verticalCardsRef[iteratorIndex] = ref;
        // }}
        item={item}
        itemData={itemData}
        listData={listData}
        id={id}
        slug={slug}
        index={this.currentIndex}
        key={iteratorIndex}
        iteratorIndex={iteratorIndex}
        goToNextPage={goToNextPage}
        goToPrevPage={goToPrevPage}
        toggleMuteButton={toggleMuteButton}
        isMuted={AudioHandler.isMuted}
        previousScreen={previousScreen}
        totalNumberOfVideos={totalNumberOfVideos}
        cardWidth={cardWidth}
        {...this.props}
      />
    );
  }
}
