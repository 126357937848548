import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  FlatList,
  Text,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import PropTypes from 'prop-types';
import CardView from '../../libraries/ReactNativeCardView';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Toast from 'react-native-easy-toast';
import {
  CUSTOMIZED_SKU_FORM_PAGE,
  LAYOUT,
  MAX_LINES,
} from '../../config/Constants';
import { ProductInfoStyles, ProductDetailStyles } from './styles';
import Images from '../../theme/Images';
import LikeButton from '../shared/LikeButton';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import FullWidthDivider from '../../utils/FullWidthDivider';
import { withEither, withMaybe } from '../../lib/Monads';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import images from '../../theme/Images';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import {
  getVariantsForProduct,
  getRecommendedVariants,
} from '../../actions/ActionTypes';
import VariantSectionList from './VariantSectionList';
import RequestProductButton from './RequestProductButton';
import VariantImagesWithHeader from './VariantImagesWithHeader';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
  AnalyticsUtilty,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import RemoteConfig from '../../utils/RemoteConfig';
import IncrementDecrement from './IncrementDecrement';
import Config from '../../libraries/ReactNativeConfig';
import VariantModalQuantity from './VariantModalQuantity';
import AppConfig from '../../config/AppConfig';
import VariantUtility from '../../utils/VariantUtility';
import WithNavigation from '../../utils/WithNavigation';
import ProductRatingAndMoreInfo from './ProductRatingAndMoreInfo';
import { getPriceText } from '../../utils/NumberUtility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { isDesktop } from '../../utils/BooleanUtility';

class VariantModal extends Component {
  constructor(props) {
    super(props);
    this.specialPriceVariant = [];
    this.premiumVariant = [];
    this.moreVariant = [];
    this.soldOutVariant = [];
    this.itemData = this.props.route?.params?.itemData;
    this.state = {
      variantAttributes: [],
      recommendedVariants: [],
      variantMrp: '',
      variantSp: '',
      variantDiscount: '',
      selectedSku: null,
      selectedVariant: {},
      selectedVariantId: '',
      selectedVariantName: '',
      selectedVariantImage: '',
      selectedVariantColor: '',
      productImages: [],
      quantity: 1,
      selectedMultiVariantPrimary: {},
      selectedMultiVariantSecondary: {},
      multiVariantAttributes: {},
    };
    const {
      property_count: variantPropertyCount = 0,
      variant_type: variantType = '',
    } = this.itemData;
    this.variantModalContainerStyle =
      variantType === PRODUCT.ATTRIBUTE_TYPE.IMAGE
        ? ProductInfoStyles.variantModalContainer
        : ProductInfoStyles.variantModalContainerForSize;
    this.variantModalContainerStyle = [
      this.variantModalContainerStyle,
      variantPropertyCount > 1 && { height: '90%' },
      isDesktop() && { width: '80%', height: '80%', alignSelf: 'center' },
    ];
  }

  componentDidMount() {
    this.hitApis();
  }

  setSelectedMultiVariantSecondary = (item) => {
    this.setState({
      selectedMultiVariantSecondary: item,
    });
  };

  setSelectedMultiVariantPrimary = (item) => {
    this.setState({
      selectedMultiVariantPrimary: item,
    });
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  discountConditionFn = (props) =>
    parseFloat(props.sp) == parseFloat(props.mrp);

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  VARIANT_TEXT = {
    RECOMMENDED: 'Recommended for you',
    PREMIUM: 'Premium/New Arrivals',
    SPECIAL_PRICE: 'Special Price',
    MORE: 'More Variants',
    OUT_OF_STOCK: 'Out of stock',
  };

  hitApiCallback = (success, responseData) => {
    if (success) {
      this.setState({
        variantAttributes: responseData.variant_attributes,
        multiVariantAttributes: responseData?.multi_level_variant_attributes,
      });
      if (responseData?.multi_level_variant_attributes?.length === 1) {
        this.primaryVariantSelected(
          responseData?.multi_level_variant_attributes[0],
        );
      }
      const {
        route: {
          params: { itemData } = {},
        } = {},
      } = this.props;
      const { multiVariantAttributes } = this.state;
      if (multiVariantAttributes.length === 1) {
        this.primaryVariantSelected(multiVariantAttributes[0]);
      }
      if (
        Utility.isBlank(responseData.variant_attributes) ||
        responseData.variant_attributes.length === 0
      ) {
        //fire variant error
        AnalyticsUtility.fireVariantErrorAnalytics(
          this.itemData.id,
          this.itemData.name,
        );
      }
      this.checkIfOnlyOneVariantIsAvailable(responseData.variant_attributes);
      let meta = {
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_NAME]: itemData.name,
        [EventParameterKey.VARIANT_COUNT]:
          Utility.isPresent(multiVariantAttributes) &&
          multiVariantAttributes.length > 0
            ? multiVariantAttributes.length
            : 0,
        [EventParameterKey.BRAND_ID]: itemData.brand.id,
        [EventParameterKey.BRAND_NAME]: itemData.brand.name,
      };

      AnalyticsManager.logEvent(
        EventType.discoveryEvents.PRODUCT_VARIANT_VIEW,
        meta,
      );
    }
  };

  hitApis = () => {
    const {
      getVariantsForProduct,
      getRecommendedVariants,
      itemData,
      offer_id = '',
    } = this.props;
    const { slug = '' } = this.itemData;
    AnalyticsUtilty.recordTime(
      {
        screen_name: SCREEN_CONSTANTS.VARIANTS,
        ...Utility.setRecordTimeEventMeta(itemData, 'product_variant'),
      },
      this.hitApiCallback,
      getVariantsForProduct,
      slug.replace('.json', ''),
      offer_id,
    );
    getRecommendedVariants(this.itemData.id, (success, responseData) => {
      if (success) {
        this.setState({
          recommendedVariants: responseData,
        });
      }
    });
  };

  updateSelectedVariant = (item) => {
    const {
      route: {
        params: { updateSelectedVariantForReview = () => {} } = {},
      } = {},
    } = this.props;
    if (!item) return;
    let imageArray = item.images;
    if (Utility.isAndroid()) {
      imageArray = Utility.getWebpImages(imageArray);
    }
    this.setState({
      selectedVariant: item,
      productImages: imageArray,
    });
    updateSelectedVariantForReview(item);
  };

  checkIfOnlyOneVariantIsAvailable = (variantAttribute) => {
    if (Utility.isBlank(variantAttribute) && variantAttribute.length === 0) {
      return;
    }
    let availableVariant = {};
    let inStockCount = 0;
    const allowedVariantValues = variantAttribute[0].allowed_values;
    if (allowedVariantValues.length === 0) {
      return;
    }
    allowedVariantValues.forEach((variant) => {
      if (!variant.outOfStock) {
        inStockCount += 1;
        availableVariant = variant;
      }
    });
    if (inStockCount === 1) {
      setTimeout(() => {
        this.updateSelectedVariant(availableVariant);
      }, 200);
    }
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  PricingComponent = (props) => {
    const { mrp, sp, discount, showPrice } = props;
    if (!showPrice) {
      return null;
    }
    const styles = ProductInfoStyles;
    return (
      <View style={styles.priceContainer}>
        <Text style={styles.spStyle}>{`${getPriceText(sp)} `}</Text>
        <this.conditionalPriceText style={styles.mrpStyle} sp={sp} mrp={mrp}>
          {getPriceText(mrp)}
        </this.conditionalPriceText>
        <this.conditionalDiscountText
          style={styles.discountRateStyle}
          discount={discount}
        >
          {`(${discount})`}
        </this.conditionalDiscountText>
      </View>
    );
  };

  checkIfShadeVariantIsSelected = (item) => {
    const { selectedVariant, selectedMultiVariantPrimary } = this.state;
    if (Utility.isPresent(selectedMultiVariantPrimary)) {
      return selectedMultiVariantPrimary?.value === item?.value;
    }
    if (Utility.isPresent(selectedVariant)) {
      return selectedVariant?.id === item?.variant?.id;
    }
    return false;
  };

  handleVariantItemPress = (item = {}, parentValue) => {
    const { product_category: productCategory = {} } = this.itemData;
    const { navigation } = this.props;
    const changeProductOnPrimaryVariantSelect = AppConfig.getBooleanValue(
      Config.CHANGE_PRODUCT_ON_PRIMARY_VARIANT_SELECT,
    );
    if (parentValue) {
      this.secondaryVariantSelected(item);
    } else {
      const { multi_level_variant_attributes = [] } = item;
      if (
        changeProductOnPrimaryVariantSelect &&
        Utility.isPresent(multi_level_variant_attributes)
      ) {
        const firstVariant = multi_level_variant_attributes[0].variant;
        this.updateSelectedVariant(firstVariant); // updating top level primary variant
        this.secondaryVariantSelected(multi_level_variant_attributes[0]); // updating secondary variant
      }
      this.primaryVariantSelected(item);
    }
    const { variant = {} } = item;
    const { sku = {} } = variant;
    const { id: skuId, needs_customization: needsCustomization = false } = sku;
    if (CUSTOMIZED_SKU_FORM_PAGE && needsCustomization && skuId) {
      navigation.navigate(CUSTOMIZED_SKU_FORM_PAGE, { item, productCategory });
      setTimeout(this.goBack, 0);
    }
  };

  sizeVariantItem = (item) => {
    const { selectedVariant } = this.state;
    if (selectedVariant === '' && item.default !== undefined) {
      this.updateSelectedVariant(item);
    }
    const selectedContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? ProductDetailStyles.flexWrappedSizeContainerSelected
      : ProductDetailStyles.sizeContainerSelected;
    const unselectedContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? ProductDetailStyles.flexWrappedSizeContainer
      : ProductDetailStyles.sizeContainer;
    const containerStyle = this.checkIfShadeVariantIsSelected(item)
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const isDisabled = item.variant?.outOfStock || item.outOfStock;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    return (
      <TouchableOpacity
        onPress={_.memoize(
          () => this.handleVariantItemPress(item),
          () => [item.value ?? item.id],
        )}
        style={containerStyle}
        disabled={isDisabled}
      >
        <Text style={textStyle}>{item?.value || item.display_name}</Text>
        {isDisabled && (
          <View style={ProductDetailStyles.disabledSizePillLine} />
        )}
      </TouchableOpacity>
    );
  };

  productSizeAttribute = (props) => {
    const {
      selectedMultiVariantPrimary,
      selectedVariant,
      selectedMultiVariantSecondary,
      multiVariantAttributes,
    } = this.state;
    if (Utility.isBlank(multiVariantAttributes)) {
      return null;
    }

    const {
      variant_attributes: selectedVariantAttributes,
      multi_level_variant_attributes: selectedMultiVariantAttributes,
    } = selectedMultiVariantPrimary || {};

    const flatListContentContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? ProductDetailStyles.flexWrappedContentContainerStyle
      : {};

    return (
      <ScrollView
        style={[isDesktop() && ProductInfoStyles.variantListContainerStyle]}
        scrollEnabled={isDesktop()}
      >
        <View style={ProductDetailStyles.productSizeVariantContainer}>
          <Text style={ProductInfoStyles.shadeTextWithLeftMargin}>
            {multiVariantAttributes?.[0]?.name}:{' '}
            <Text style={ProductInfoStyles.selectedShadeText}>
              {selectedMultiVariantPrimary?.value ??
                selectedVariant?.display_name}
            </Text>
          </Text>
          <FlatList
            data={multiVariantAttributes}
            horizontal={
              !AppConfig.getBooleanValue(
                Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
              )
            }
            renderItem={({ item }) => this.sizeVariantItem(item)}
            keyExtractor={(item) => `${item.display_name}`}
            showsHorizontalScrollIndicator={false}
            style={ProductDetailStyles.flatListItemStyle}
            contentContainerStyle={flatListContentContainerStyle}
            extraData={{
              ...this.state.selectedVariant,
              ...selectedMultiVariantPrimary,
            }}
          />
          {(Utility.isPresent(selectedVariantAttributes) ||
            Utility.isPresent(selectedMultiVariantAttributes)) && (
            <Text style={ProductInfoStyles.shadeTextWithMargin}>
              {selectedMultiVariantAttributes?.[0]?.name ??
                selectedVariantAttributes?.[0]?.name}{' '}
              :{' '}
              <Text style={ProductInfoStyles.selectedShadeText}>
                {selectedMultiVariantSecondary?.value}
              </Text>
            </Text>
          )}
          <FlatList
            data={selectedMultiVariantAttributes ?? selectedVariantAttributes}
            horizontal={
              !AppConfig.getBooleanValue(
                Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
              )
            }
            contentContainerStyle={flatListContentContainerStyle}
            renderItem={this.sizeVariantsPill}
            keyExtractor={this.sizeListKeyExtractor}
            style={ProductDetailStyles.secondaryVariantFlatlistStyle}
            extraData={{
              ...selectedMultiVariantPrimary,
              ...selectedMultiVariantSecondary,
            }}
          />
        </View>
      </ScrollView>
    );
  };

  sizeListKeyExtractor = (item) => `${item.value}`;

  secondaryVariantSelected = (item) => {
    this.setSelectedMultiVariantSecondary(item);
    this.updateSelectedVariant(item?.variant);
  };

  sizeVariantsPill = ({ item, index }) => {
    const styles = ProductInfoStyles;
    const { selectedMultiVariantSecondary, selectedMultiVariantPrimary } =
      this.state;
    const { value: parentVariantValue } = selectedMultiVariantPrimary;
    const isDisabled =
      Utility.isBlank(item.variant) || item?.variant?.outOfStock;
    const isSelected =
      selectedMultiVariantSecondary?.value === item?.value && !isDisabled;
    const selectedContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? styles.flexWrappedSelectedSizePillBorder
      : styles.selectedSizePillBorder;
    const unselectedContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? styles.flexWrappedUnselectedSizePillBorder
      : styles.unselectedSizePillBorder;
    const borderStyle = isSelected
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    return (
      <TouchableOpacity
        disabled={isDisabled}
        style={borderStyle}
        onPress={_.memoize(
          () => this.handleVariantItemPress(item, parentVariantValue),
          () => [item.value ?? item.id, parentVariantValue],
        )}
      >
        <Text style={textStyle}>{item?.value}</Text>
        {isDisabled && (
          <View style={ProductDetailStyles.disabledSizePillLine} />
        )}
      </TouchableOpacity>
    );
  };

  conditionalScrollView = withEither(isDesktop, View)(ScrollView);

  variantScreen = (props) => {
    const { recommendedVariants } = props;
    const {
      productImages,
      quantity,
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
    } = this.state;
    const { previousScreen, onItemAddToCartFromCollab, campaignId } =
      this.props;
    const { sp, webp_images } = this.itemData;
    if (Utility.isBlank(this.itemData)) {
      return null;
    }
    const { variantAttributes, selectedVariant, multiVariantAttributes } =
      this.state;
    if (Utility.isBlank(multiVariantAttributes)) {
      return (
        <View style={ProductInfoStyles.variantHeaderContainer}>
          <VariantImagesWithHeader
            itemData={this.itemData}
            imageArray={this.itemData.images}
            selectedVariantName={selectedVariant.name}
            selectedVariantImage={selectedVariant.image_url}
            selectedVariant={selectedVariant}
          />
        </View>
      );
    }
    const styles = ProductInfoStyles;
    const imageCarouselContainer = [isDesktop() && { flex: 1 }];
    const sectionListContainer = [
      ProductDetailStyles.extraMarginTop,
      isDesktop() && {
        flexDirection: 'row',
        height: '100%',
      },
    ];
    if (this.itemData.variant_type !== PRODUCT.ATTRIBUTE_TYPE.IMAGE) {
      // size variant
      // TODO: use smaller modal height got size variant, get variant type initially from backend
      return (
        <ScrollView
          style={[
            styles.scrollViewStyle,
            isDesktop() && styles.scrollViewBottomPadding,
          ]}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.scrollViewBottomPadding}
          scrollEnabled={!isDesktop()}
        >
          <View style={sectionListContainer}>
            <View style={imageCarouselContainer}>
              <VariantImagesWithHeader
                itemData={this.itemData}
                imageArray={this.itemData.images}
                selectedVariantName={selectedVariant.name}
                selectedVariantImage={selectedVariant.image_url}
                selectedVariant={selectedVariant}
              />
              {(this.itemData.stocked_status === 'in_stock' ||
                this.itemData.stocked_status === 'stocked_out') && (
                <View style={ProductDetailStyles.ratingsMarginTop}>
                  <ProductRatingAndMoreInfo
                    rating={this.itemData?.rating}
                    id={this.itemData?.id}
                    sku_id={this.itemData?.sku_id}
                  />
                </View>
              )}
              {/* <this.quantityContainer /> */}
            </View>
            <this.productSizeAttribute
              variantAttributes={variantAttributes}
              multiVariantAttributes={multiVariantAttributes}
            />
          </View>
        </ScrollView>
      );
    }
    const allowedVariantValues = _.filter(
      variantAttributes?.[0].allowed_values,
      (item) => item.outOfStock !== true,
    );
    if (!Utility.isBlank(recommendedVariants)) {
      this.recommendedVariants = _.filter(allowedVariantValues, (item) =>
        recommendedVariants.includes(item.id),
      );
    }
    const imageArray =
      this.itemData.images.length === 0 ? this.itemData.images : productImages;

    return (
      <this.conditionalScrollView
        style={[
          styles.scrollViewStyle,
          isDesktop() && styles.scrollViewBottomPadding,
        ]}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollViewBottomPadding}
      >
        <VariantSectionList
          itemData={this.itemData}
          recommendedVariants={this.recommendedVariants}
          selectedVariantName={selectedVariant.display_name}
          selectedVariantImage={selectedVariant.image_url}
          selectedVariant={selectedVariant}
          updateSelectedVariant={this.updateSelectedVariant}
          imageArray={imageArray}
          variantAttributes={variantAttributes}
          previousScreen={previousScreen}
          onItemAddToCartFromCollab={onItemAddToCartFromCollab}
          campaignId={campaignId}
          quantity={quantity}
          setVariantQuantity={this.setVariantQuantity}
          selectedMultiVariantPrimary={selectedMultiVariantPrimary}
          selectedMultiVariantSecondary={selectedMultiVariantSecondary}
          setSelectedMultiVariantSecondary={
            this.setSelectedMultiVariantSecondary
          }
          setSelectedMultiVariantPrimary={this.setSelectedMultiVariantPrimary}
          multiVariantAttributes={multiVariantAttributes}
          handleVariantItemPress={this.handleVariantItemPress}
        />
      </this.conditionalScrollView>
    );
  };

  primaryVariantSelected = (item) => {
    const { multiVariantAttributes } = this.state;
    if (VariantUtility.checkIfMultiVariant(multiVariantAttributes)) {
      this.setSelectedMultiVariantPrimary(item);
      return;
    }
    this.updateSelectedVariant(item?.variant ?? item);
  };

  pricingInfo = (props) => {
    const {
      mrp,
      sp,
      discount,
      itemData,
      skuToAdd,
      selectedVariant,
      outOfStock,
      priority,
      additional_discount,
    } = props;

    const {
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
      multiVariantAttributes,
    } = this.state;

    if (Utility.isBlank(selectedVariant)) {
      return null;
    }

    const {
      listName,
      listData = {},
      listIndex,
      layout,
      campaignId,
      onItemAddToCartFromCollab,
      listId = '',
      force_apply_coupon,
    } = this.props;
    const unStockedVariant =
      selectedVariant && selectedVariant.stocked_status === 'unstocked'
        ? true
        : false;
    return (
      <View style={ProductDetailStyles.pricingAndCartContainerVariantModal}>
        <View style={{ flexDirection: 'row' }}>
          <View style={ProductDetailStyles.pricingContainer}>
            <View style={{ flexDirection: 'row' }}>
              <this.conditionalPriceText
                style={ProductDetailStyles.mrpStyle}
                sp={sp}
                mrp={mrp}
              >
                {getPriceText(mrp)}
              </this.conditionalPriceText>

              {Utility.isPresent(discount) && (
                <Text
                  style={ProductDetailStyles.discountRateStyle}
                  discount={discount}
                >
                  {`${discount}`}
                </Text>
              )}
            </View>
            <Text
              style={ProductDetailStyles.spStyle}
            >{getPriceText(sp)}</Text>
          </View>
          {outOfStock || priority === 'Discontinued' ? (
            <RequestProductButton
              skuId={skuToAdd}
              showToast={this.showToast}
              priority={priority}
              addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
              unstocked={unStockedVariant}
              variantModal
            />
          ) : (
            <IncrementDecrement
              addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
              skuId={skuToAdd}
              previousScreen={SCREEN_CONSTANTS.VARIANTS}
              hasVariants={itemData.has_variants}
              selectedVariant={selectedVariant}
              selectedVariantName={selectedVariant.display_name}
              showCart={this.goBack}
              showToast={this.showToast}
              itemData={itemData}
              listId={listId}
              listName={listName}
              listIndex={listIndex}
              layout={layout}
              campaignId={campaignId}
              listData={listData}
              onItemAddToCartFromCollab={onItemAddToCartFromCollab}
              variantModal
              cancelpressed={this.goBack}
              refreshOfferStrip={this.props.refreshOfferStrip}
              refreshOffersDetailsPageDiscountStrip={
                this.props.refreshOffersDetailsPageDiscountStrip
              }
              force_apply_coupon={force_apply_coupon}
              selectedMultiVariantPrimary={selectedMultiVariantPrimary}
              selectedMultiVariantSecondary={selectedMultiVariantSecondary}
              multiVariantAttributes={multiVariantAttributes}
            />
          )}
        </View>
      </View>
    );
  };

  chooseVariant = () => {
    return (
      <FoxyShadowButton
        width={Utility.getScreenWidth() - 34}
        title='Select'
        onPress={this.goBack}
        backgroundColor={colors.primaryActionBackgroundColor}
        style={ProductInfoStyles.chooseVariantButton}
        Loading={false}
        firstColor={colors.linerGradientGreenFirst}
        secondColor={colors.linerGradientGreenSecond}
      />
    );
  };

  setVariantQuantity = (quantity) => {
    this.setState({
      quantity,
    });
  };

  crossButton = () => {
    return (
      <TouchableOpacity
        hitSlop={Utility.getHitSlop()}
        onPress={this.goBack}
        style={ProductDetailStyles.crossButtonContainer}
      >
        <Image style={ProductDetailStyles.crossButton} source={images.cross} />
      </TouchableOpacity>
    );
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  render() {
    const {
      route: {
        params: {
          isVisible,
          previousScreen,
          selectOnly = false,
          comboOffer = false,
        } = {},
      } = {},
    } = this.props;
    const { recommendedVariants } = this.state;
    const styles = ProductInfoStyles;
    if (Utility.isBlank(this.itemData)) {
      return null;
    }
    const { selectedVariant } = this.state;
    let {
      mrp,
      discount,
      outOfStock,
      priority,
      final_sp: sp,
      offer_code,
      offer_value,
      additional_discount,
    } = this.itemData;

    let skuToAdd = this.itemData.sku_id;

    if (Utility.isPresent(discount)) {
      discount = `(${discount})`;
    }

    if (!Utility.isBlank(selectedVariant)) {
      mrp = selectedVariant.mrp;
      sp = selectedVariant.final_sp;

      skuToAdd = selectedVariant.sku_id;
      outOfStock = selectedVariant.outOfStock;
      offer_code = selectedVariant.offer_code;
      offer_value = selectedVariant.offer_value;
      discount = selectedVariant.discount;
      if (!Utility.isBlank(selectedVariant.additional_discount)) {
        discount = selectedVariant.additional_discount;
      }
    }

    // const imageArray = productImages.length === 0 ? images : productImages;
    const modalHeaderTitle =
      this.itemData.variant_type === PRODUCT.ATTRIBUTE_TYPE.IMAGE
        ? 'Select a shade'
        : 'Select a size';
    return (
      <>
        <DebouncedTouchableOpacity
          style={styles.touchableContainer}
          onPress={this.goBack}
        />
        <View style={styles.variantModal} pointerEvents='box-none'>
          <View style={this.variantModalContainerStyle}>
            <View style={styles.variantModalHeader}>
              <Text style={styles.variantModalHeaderTextWithMargin}>
                {modalHeaderTitle}
              </Text>
            </View>
            <this.variantScreen recommendedVariants={recommendedVariants} />
            {!selectOnly ? (
              <this.pricingInfo
                mrp={mrp}
                sp={sp}
                discount={discount}
                additional_discount={additional_discount}
                itemData={this.itemData}
                skuToAdd={skuToAdd}
                selectedVariant={selectedVariant}
                outOfStock={outOfStock}
                priority={priority}
                comboOffer={comboOffer}
              />
            ) : (
              <this.chooseVariant />
            )}
            <this.crossButton />
          </View>
          <Toast ref={this.toastRef} />
        </View>
      </>
    );
  }
}

VariantModal.propTypes = {
  brand: PropTypes.shape({
    name: PropTypes.string,
    images: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
  }),
  image_url: PropTypes.string,
};
VariantModal.defaultProps = {
  brand: {
    name: '',
    images: {
      thumbnail: '',
    },
  },
  image_url: '',
};

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { getVariantsForProduct, getRecommendedVariants },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps)(VariantModal),
);
