import {
  View,
  Text,
  StyleSheet,
  Image,
  FlatList,
} from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import colors from '../../../theme/Colors';
import RoundedButton from '../../layout/buttons/RoundedButton';
import images from '../../../theme/Images';
import ShopifyOrderItem from './ShopifyOrderItem';

export default function ShopifyOrderItems({
  orderItems = [],
  status,
  trackingUrl,
  createdAt,
  orderName,
}) {
  const navigation = useNavigation();
  const trackUrl = () => {
    navigation.navigate('WebUrlView', {
      destination: trackingUrl,
      title: `Tracking - ${orderName}`,
    });
  };
  return (
    <View style={styles.container}>
      <Text style={styles.orderStatusHeading}>{status}</Text>
      <View style={styles.orderStatusSubheadingContainer}>
        <Text style={styles.subheading}>{`Placed on ${createdAt}`}</Text>

        {trackingUrl && (
          <RoundedButton
            buttonText="Track status"
            buttonTextColor={colors.white}
            buttonColor={colors.black}
            buttonAction={trackUrl}
            style={styles.roundedButtonContainer}
            buttonTextStyle={styles.buttonText}
            renderProp={TrackShipmentImage}
          />
        )}
      </View>

      <View>
        <FlatList
          data={orderItems}
          ListHeaderComponent={Divider}
          ListFooterComponent={Divider}
          ItemSeparatorComponent={Divider}
          renderItem={({ item }) => <ShopifyOrderItem {...item} />}
        />
      </View>
    </View>
  );
}

const Divider = () => <View style={styles.divider} />;

const TrackShipmentImage = () => (
  <Image
    source={images.foxyPromise.fastDelivery}
    style={styles.trackingImage}
    tintColor={colors.white}
    resizeMode="contain"
  />
);

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    marginTop: 20,
    marginBottom: 12,
    backgroundColor: colors.white,
    paddingHorizontal: 12,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: colors.borderColor,
    marginVertical: 12,
  },
  orderStatusHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  orderStatusSubheadingContainer: {
    flexDirection: 'row',
  },
  subheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    flex: 2,
  },
  roundedButtonContainer: {
    height: 36,
    borderRadius: 16,
    alignItems: 'center',
    marginTop: -14,
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  buttonText: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    textAlign: 'center',
  },
  trackingImage: {
    height: 18,
    width: 18,
    marginRight: 4,
    tintColor: colors.white,
  },
});
