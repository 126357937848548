import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import _, { capitalize } from 'lodash';
import FastImageView from '../FastImageView';
import AddToCart from './AddToCart';
import { ScaleAnimate } from '../shared';
import { LAYOUT, CURRENCY_SYMBOL } from '../../config/Constants';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import withNavigation from '../../utils/WithNavigation';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { getProductOfferMessage } from '../../utils/OffersUtil';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { connect } from 'react-redux';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

const dividingFactor = isDesktop() ? 2 : 1;

const CARD_ASPECT_RATIO = 5 / 4;
const NUM_CARDS_VISIBLE_IN_SCREEN = 1.2;
const CARD_WIDTH = Utility.getScreenWidth() / (dividingFactor * NUM_CARDS_VISIBLE_IN_SCREEN);
const CARD_HEIGHT = CARD_WIDTH / CARD_ASPECT_RATIO;
const SINGLE_CARD_WIDTH = Utility.getScreenWidth() / dividingFactor - 24;
const SINGLE_CARD_HEIGHT = SINGLE_CARD_WIDTH / CARD_ASPECT_RATIO;

class DefaultSizeProductFeaturedRail extends PureComponent {
  static getComponentHeight({ objects = [] }) {
    const style =
      objects?.length === 1 ? styles.singleCardContainer : styles.container;
    return style.height;
  }

  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    const {
      itemData: { final_sp = '0', sp = '0' } = {},
      listData: {
        options: { theme = 'dark', is_free } = {},
        objects = [],
      } = {},
    } = this.props;
    this.finalSp = final_sp ? `${CURRENCY_SYMBOL}${final_sp}` : '';
    this.sp = sp ? `${CURRENCY_SYMBOL}${sp}` : '';
    this.isFree = parseInt(final_sp) === 0 || (is_free == 1 ?? is_free);

    if (this.isFree) {
      this.finalSp = 'FREE';
    }
    this.containerStyle =
      objects?.length === 1 ? styles.singleCardContainer : styles.container;
  }

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
      prompts,
      outOfStock = false,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData: { additional_data = {}, options: listOptions } = {},
      listData = {},
    } = this.props;

    const options = Utility.isPresent(listOptions) ? listOptions : {};
    const { theme = 'dark' } = options;

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.FEATURED_RAIL}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.FEATURED_PRODUCT}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={!!outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        singleStockedVariantSku={singleStockedVariantSku}
        prompts={prompts}
        additional_data={additional_data}
        isFancyCard
        listData={listData}
        soldOutTextColor={colors.white}
        theme={theme}
      />
    );
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug, variants_details = {} } = {},
      toggleCartVisibility,
      previousScreen = '',
    } = this.props;

    navigateToScreen({
      navigation,
      type: 'push',
      screen: SCREEN_CONSTANTS.PRODUCT_PAGE,
      params: {
        id,
        slug,
        display: LAYOUT.SCREEN,
        itemData,
        toggleCartVisibility,
        variants_details,
        previousScreen,
      },
    });
  };

  onPress = () => {
    const {
      listIndex,
      index,
      itemData,
      listId,
      listData = {},
      previousScreen,
      listName,
    } = this.props;

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemData.type,
      itemData.name,
      index,
      listId,
      listData.display,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listData.content,
      itemData.id,
      itemData.slug,
      listData?.slug,
      {},
      itemData,
    );
    this.debouncedNavigate();
  };

  render() {
    const {
      itemData = {},
      itemData: {
        id = '',
        sku_id: skuId,
        prompts,
        has_variants: hasVariants,
        outOfStock,
        priority,
        single_stocked_variant: singleStockedVariantSku,
        featured_image_url: featuredImage = '',
      } = {},
      listData: { options: { theme = 'dark' } = {} } = {},
      boostedOffers = {},
      todayDeals = {},
    } = this.props;
    const message = getProductOfferMessage(itemData, todayDeals, boostedOffers);
    const Theme = capitalize(theme);
    const finalPriceStyle = styles[`finalPrice${Theme}`];
    const priceTitle = styles[`priceTitle${Theme}`];
    const offerMessageStyle = styles[`offerMessage${Theme}`];
    return (
      <ScaleAnimate {...this.props} onPress={this.onPress}>
        <View style={this.containerStyle}>
          <FastImageView
            resizeMode='stretch'
            source={featuredImage}
            style={styles.imageBanner}
          />

          <View style={styles.bottomContainer}>
            <View style={styles.pricingContainer}>
              <Text style={finalPriceStyle}>{this.finalSp}</Text>
              <Text style={priceTitle}>{this.sp}</Text>
            </View>
            {Utility.isPresent(message) && (
              <Text style={offerMessageStyle}>{message}</Text>
            )}
          </View>
          <View style={styles.addToCartIconContainer}>
            <this.addToCart
              skuId={skuId}
              toggleCartState={this.toggleCartState}
              layout={LAYOUT.GRID}
              addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
              id={id}
              itemData={itemData}
              hasVariants={hasVariants}
              showCart={this.showCart}
              priority={priority}
              outOfStock={outOfStock}
              singleStockedVariantSku={singleStockedVariantSku}
              prompts={prompts}
            />
          </View>
        </View>
      </ScaleAnimate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    todayDeals: state.todayDeals,
    boostedOffers: state.boostedOffers,
  };
};

export default withNavigation(
  connect(mapStateToProps, null)(DefaultSizeProductFeaturedRail),
);

const styles = StyleSheet.create({
  container: {
    height: CARD_HEIGHT,
    width: CARD_WIDTH,
    marginVertical: 8,
    paddingHorizontal: 8,
  },
  singleCardContainer: {
    height: SINGLE_CARD_HEIGHT,
    width: SINGLE_CARD_WIDTH,
    marginRight: 12,
    marginVertical: 8,
  },
  imageBanner: {
    width: '100%',
    height: '100%',
    borderRadius: 12,
    backgroundColor: 'grey',
  },
  bottomContainer: {
    alignSelf: 'flex-start',
    marginBottom: 0,
    position: 'absolute',
    bottom: 14,
    marginLeft: 12,
  },
  pricingContainer: {
    flexDirection: 'row',
    marginTop: 28,
    alignItems: 'center',
  },
  finalPriceLight: {
    fontSize: 20,
    lineHeight: 21,
    color: colors.white,
    fontFamily: 'Roboto-Bold',
    marginRight: 8,
  },
  finalPriceDark: {
    fontSize: 20,
    lineHeight: 21,
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    marginRight: 8,
  },
  priceTitleLight: {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Roboto-Regular',
    textDecorationLine: 'line-through',
    marginBottom: -4,
    color: colors.white,
  },
  priceTitleDark: {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Roboto-Regular',
    textDecorationLine: 'line-through',
    marginBottom: -4,
    color: colors.black,
  },
  offerMessageLight: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.white,
  },
  offerMessageDark: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.black,
  },
  addToCartIconContainer: {
    position: 'absolute',
    borderStyle: 'solid',
    right: 12,
    bottom: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 26,
  },
});
