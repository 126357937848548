import {
  CDN_3,
  CLOUDFRONT_URL,
  DEFAULT_IMAGE_FORMAT,
} from '../config/Constants';
import { isBlank, isIOS, isPresent, isWeb } from './BooleanUtility';
import { getRealScreenHeight, getScreenWidth } from './LayoutUtility';

const getImageUrl = (
  url,
  width = 360,
  height,
  format = DEFAULT_IMAGE_FORMAT,
) => {
  if (isBlank(url)) {
    return '';
  }

  let standardWidth = width;
  let newUrl = url.replace(`${CLOUDFRONT_URL}/`, `${CDN_3}/`);
  if (!url.includes('width=')) {
    if (width <= 120) {
      standardWidth = 120;
    } else if (width > 120 && width <= 180) {
      standardWidth = 180;
    } else if (width > 180 && width <= 360) {
      standardWidth = 360;
    } else if (width > 360 && width <= 480) {
      standardWidth = 720; // for full width image use higher resolution as lower resolution is looking pixelated
    } else if (width > 480 && width <= 720) {
      standardWidth = 720;
    } else if (width > 720) {
      standardWidth = 1024;
    } else {
      standardWidth = 360;
    }
    newUrl = `${newUrl}?width=${standardWidth}`;
  }

  if (!newUrl.includes('format=')) {
    if (!isIOS()) {
      newUrl = `${newUrl}&format=${format}`;
    } else {
      newUrl = `${newUrl}&format=png`;
    }
  }
  return newUrl;
};

// eslint-disable-next-line import/prefer-default-export
export const getMinifiedImage = (
  url,
  width = 360,
  height = isWeb() ? getRealScreenHeight() : getScreenWidth(),
) => {
  if (isBlank(url)) {
    return '';
  }
  let newImageUrl = url;
  if (isPresent(url)) {
    newImageUrl = getImageUrl(url, width, height);
  }

  return newImageUrl;
};
