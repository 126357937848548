import { takeLatest, select, put } from 'redux-saga/effects';
import { NativeModules } from 'react-native';
import {
  CALCULATE_UAC_SCORE_APP_DEVICE_INFO,
  hasUACDeviceEventTriggered,
  uacBeautyAppEventTriggered,
  uacCommerceAppEventTriggered,
  uacDeviceEventTriggered,
  updateUACScore,
  userS1EventTriggered,
  userS2EventTriggered,
} from '../actions/ActionTypes';
import Utility from '../utils/Utility';
import { AnalyticsManager, EventType, EventParameterKey } from '../analytics';
import { URL, GEOLOCATION_KEY, REMOTE_CONFIG_KEYS } from '../config/Constants';
import RemoteConfig from '../utils/RemoteConfig';
import UACUtils from '../utils/UACUtils';
export function* calculateAppAndDeviceInfoScore() {
  try {
    const { user_s1_triggered } = yield select(
      (state) => state.UserAccountInfo.uac_events_status,
    );

    const appInfo = yield NativeModules.AppDetect.getAppsPromise();
    const uac_apps = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.uac_apps);
    const appScore = Utility.calculateAppInfoScore(
      Utility.jsonParser(uac_apps),
      appInfo,
    );

    const uac_apps_commerce = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.uac_apps_commerce,
    );
    const commerceAppScore = Utility.calculateCommerceInfoScore(
      Utility.jsonParser(uac_apps_commerce),
      appInfo,
    );

    const uac_apps_beauty = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.uac_apps_beauty,
    );

    const beautyAppScore = Utility.calculateCommerceInfoScore(
      Utility.jsonParser(uac_apps_beauty),
      appInfo,
    );

    const getDeviceInfo =
      yield NativeModules.DeviceDetails.getDeviceInfoWithPromise();
    const new_uac_device = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.new_uac_device,
    );
    const deviceInfoScore = Utility.calculateUACDeviceScore(
      Utility.jsonParser(new_uac_device),
      Utility.jsonParser(getDeviceInfo),
    );

    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_DEVICE_SCORE, {
      [EventParameterKey.SCORE]: deviceInfoScore,
    });
    // yield put(uacDeviceEventTriggered(deviceInfoScore));

    AnalyticsManager.logEvent(
      EventType.onboardingEvent.UAC_COMMERCE_APPS_SCORE,
      {
        [EventParameterKey.SCORE]: commerceAppScore,
      },
    );
    // yield put(uacCommerceAppEventTriggered(commerceAppScore));

    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_BEAUTY_APPS_SCORE, {
      [EventParameterKey.SCORE]: beautyAppScore,
    });
    // yield put(uacBeautyAppEventTriggered(beautyAppScore));

    UACUtils.deviceScore = deviceInfoScore;
    UACUtils.beautyAppScore = beautyAppScore;
    UACUtils.commerceAppScore = commerceAppScore;

    const uac = {
      deviceScore: UACUtils.deviceScore,
      beautyAppScore: UACUtils.beautyAppScore,
      commerceAppScore: UACUtils.commerceAppScore,
    };

    yield put(uacBeautyAppEventTriggered(uac));
    yield put(hasUACDeviceEventTriggered(true));
  } catch (error) {
    /**
     * Sometimes remoteconfig got values after this function call so initially value for remote config maybe undefined
     * so we can call this function again
     */
    if (!Utility.calculateAppAndDeviceInfoScoreExceptionOccured) {
      yield calculateAppAndDeviceInfoScore();
      Utility.calculateAppAndDeviceInfoScoreExceptionOccured = true;
    }
  }
}

const calculateLocationScore = (arrPlaces, remoteUACPlaces) => {
  const { address_components: addressComponents } = arrPlaces;
  let level1 = '';
  let level2 = '';
  let matched = false;
  let finalScore = 0;
  addressComponents.forEach((address) => {
    if (address.types.indexOf('administrative_area_level_1') != -1) {
      level1 = address.long_name;
    }
    if (address.types.indexOf('administrative_area_level_2') != -1) {
      level2 = address.long_name;
    }
  });
  for (let i = remoteUACPlaces.length; i--; i >= 0) {
    const places = remoteUACPlaces[i];

    const regex = new RegExp(places, 'i');
    if (regex.test(level1) || regex.test(level2)) {
      matched = true;
      finalScore = i;
      return finalScore;
    }
  }
  if (matched) {
    return finalScore;
  }
  return 0;
};

export default function* watchUACScoreCalculation() {
  yield takeLatest(
    CALCULATE_UAC_SCORE_APP_DEVICE_INFO,
    calculateAppAndDeviceInfoScore,
  );
}
