import { NativeModules } from 'react-native';
import Utility from './Utility';
import RemoteConfig from './RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../config/Constants';

export default class CacheMedia {
  static videoUrls = [];

  static saveVideoUrlsForCaching = (urls) => {
    this.videoUrls = [...this.videoUrls, ...urls];
    // console.tron.log('CACHING_URLS', this.videoUrls);
    if (
      Utility.isIOS() &&
      this.videoUrls.length <=
        RemoteConfig.getIntValue(REMOTE_CONFIG_KEYS.maxVideoDownloadLimit)
    ) {
      NativeModules.BGNetworkTaskHandler.startMediaDownloads(this.videoUrls);
      // console.tron.log('IOS SAVING URLS', this.videoUrls);
    }
    if (
      Utility.isAndroid() &&
      this.videoUrls.length ===
        RemoteConfig.getIntValue(REMOTE_CONFIG_KEYS.android_max_cache_limit)
    ) {
      let urlString = '';
      this.videoUrls.forEach((element) => {
        urlString += `${element},`;
      });
      NativeModules.CacheManager.saveStoryUrls(urlString);
      // console.tron.log('ANDROID SAVING URLS', this.videoUrls);
    }
  };
}
