import React from 'react';
import { Image, TouchableOpacity, StyleSheet } from 'react-native';
import images from '../../../../theme/Images';

export default function CrossIcon({ showCross = false, onPress = () => {} }) {
  if (!showCross) return null;
  return (
    <TouchableOpacity
      style={styles.crossStyle}
      onPress={onPress}
    >
      <Image source={images.cross} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  crossStyle: {
    position: 'absolute',
    top: -8,
    right: -8,
    height: 30,
    width: 30,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: 'white',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

