import React, { PureComponent } from 'react';
import { Text, View } from 'react-native';
import GrayScaledImage from '../../containers/inviteCenter/GrayscaleImage';
import { withEither } from '../../lib/Monads';

class ConditionalContactImageOrInitials extends PureComponent {
  ContactPicture = (props) => {
    const {
      item: { thumbnailPath },
      enableGrayscale,
      imageStyle,
    } = props;

    return (
      <GrayScaledImage
        source={{ uri: thumbnailPath }}
        style={imageStyle}
        resizeMode='contain'
        enableGrayscale={enableGrayscale}
      />
    );
  };

  NameInitials = (props) => {
    const {
      item: { nameInitial },
      initialsStyle,
    } = props;

    return <Text style={initialsStyle}>{nameInitial}</Text>;
  };

  isContactPictureAvailable = (props) => {
    const {
      item: { hasThumbnail },
    } = props;
    return hasThumbnail;
  };

  conditionContactPictureOrInitials = withEither(
    this.isContactPictureAvailable,
    this.ContactPicture,
  )(this.NameInitials);

  render() {
    const {
      backgroundStyle,
      item,
      imageStyle,
      initialsStyle,
      enableGrayscale = false,
    } = this.props;
    return (
      <View style={backgroundStyle}>
        <this.conditionContactPictureOrInitials
          item={item}
          imageStyle={imageStyle}
          initialsStyle={initialsStyle}
          enableGrayscale={enableGrayscale}
          backgroundStyle={backgroundStyle}
        />
      </View>
    );
  }
}

export default ConditionalContactImageOrInitials;
