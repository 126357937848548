import React from 'react';
import { StyleSheet } from 'react-native';
import Utility from '../../../../utils/Utility';
import FastImageView from '../../../FastImageView';

export default function HeaderImage({
  imageUrl,
  isProfilePicture = false,
  isAbsolute = false,
  isAsset = false,
}) {
  if (!imageUrl) return null;
  let pictureStyles = isProfilePicture ? styles.profilePicture : styles.regularPicture;
  if (isAbsolute) {
    pictureStyles = styles.absolutePicture;
  }
  const minifiedUrl = isAsset ? imageUrl : Utility.getMinifiedImage(imageUrl, 300, 300);
  return (
    <FastImageView
      source={minifiedUrl}
      resizeMode={FastImage.resizeMode.contain}
      style={pictureStyles}
    />
  );
}

const styles = StyleSheet.create({
  profilePicture: {
    height: 76,
    width: 76,
    borderRadius: 38,
    alignSelf: 'center',
    marginBottom: 10,
    marginTop: 4,
  },
  regularPicture: {
    width: 60,
    height: 82,
    alignSelf: 'center',
    marginBottom: 4,
    marginTop: 4,
  },
  absolutePicture: {
    width: 60,
    height: 60,
    alignSelf: 'center',
    position: 'absolute',
    top: -30,
  },
});
