import React, { Component } from 'react';
import {
  Linking,
  Text,
  View,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import { OutlinedTextField } from 'react-native-material-textfield';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StarRating } from '../../../lib/StarRating';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import FastImageView from '../../FastImageView';
import RoundedButton from '../../layout/buttons/RoundedButton';
import { updateAppRating } from '../../../actions/ActionTypes';
import Config from '../../../libraries/ReactNativeConfig';
import {
  isAndroid,
  isIOS,
  isNative,
  isWebAndroid,
  isWebIOS,
} from '../../../utils/BooleanUtility';
import {
  APP_STORE_LINK,
  APP_STORE_LINK_WEB,
  PLAY_STORE_LINK,
} from '../../../config/Constants';

// eslint-disable-next-line react/prefer-stateless-function
class LargeActionRatingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      showLoader: false,
      showRatingDescription: false,
      ratingDescription: '',
    };
    const { backgroundColor = '#fff', style } = this.props;
    this.containerStyle = [styles.container, style, { backgroundColor }];
    this.innerContainer = [styles.endStateContainer, { backgroundColor }];
  }

  showDescriptionBox = () => {
    const { rating } = this.state;
    if (rating <= 3 && rating > 0) {
      this.setShowDescription(true);
    } else {
      this.setShowDescription(false);
    }
  };

  navigateToAppStore = () => {
    const { addOrderActionCard, primaryButtonAction } = this.props;
    const { rating } = this.state;

    let appLink = PLAY_STORE_LINK;

    if (isIOS() || isWebIOS()) {
      appLink = APP_STORE_LINK_WEB;
    }

    if (rating > 3) {
      primaryButtonAction();
      Linking.openURL(appLink);
    }
    // addOrderActionCard();
  };

  hideLoader = () => {
    this.setState(
      {
        showLoader: false,
      },
      this.navigateToAppStore,
    );
  };

  onFinishRating = (rating) => {
    const { updateAppRating } = this.props;
    updateAppRating({
      app_rating: {
        rating,
      },
    });
    this.setState(
      {
        rating,
        showLoader: rating > 3,
      },
      this.showDescriptionBox,
    );
    setTimeout(this.hideLoader, 1000);
  };

  onChangeBody = (value = '') => {
    this.setState({
      ratingDescription: value.trim(),
    });
  };

  setShowDescription = (show) => {
    this.setState({
      showRatingDescription: show,
    });
  };

  submitRating = () => {
    const { primaryButtonAction, addOrderActionCard, updateAppRating } =
      this.props;
    const { ratingDescription, rating } = this.state;
    primaryButtonAction();
    updateAppRating({
      app_rating: {
        rating,
        description: ratingDescription,
      },
    });
    addOrderActionCard();
  };

  endState = (props) => {
    const { imageUrl } = this.props;
    const { rating } = this.state;
    const {
      endStateData: { heading = '', subheading = '' },
    } = props;
    return (
      <View style={this.innerContainer}>
        <FastImageView
          resizeMode='contain'
          source={imageUrl}
          style={
            rating > 3 ? styles.endStateImageLarge : styles.endStateImageSmall
          }
        />

        <View style={styles.endStateHeadingSubheading}>
          <Text style={styles.endStateHeading}>{heading}</Text>

          {rating <= 3 ? (
            <Text
              style={styles.endStateSubheading}
              numberOfLines={2}
              ellipsizeMode='tail'
            >
              {subheading}
            </Text>
          ) : (
            <StarRating
              rating={rating}
              count={5}
              defaultRating={0}
              size={25}
              disabled
              onFinishRating={this.onFinishRating}
            />
          )}
        </View>
      </View>
    );
  };

  render() {
    const { imageUrl, heading, subheading, style, appRating, showEndState } =
      this.props;
    if (Utility.isPresent(appRating)) {
      return null;
    }

    const { showLoader, showRatingDescription, ratingDescription, rating } =
      this.state;

    const endStateData = {
      heading: rating <= 3 ? 'Thank you for your feedback' : 'Your rating',
      subheading: 'It helps us to improve continuously',
    };

    if (showEndState) {
      return <this.endState endStateData={endStateData} />;
    }

    let loadingText = 'Taking you to Play Store';
    if (isWebIOS() || isIOS()) {
      loadingText = 'Taking you to App Store';
    }

    return (
      <View style={this.containerStyle}>
        <FastImageView
          resizeMode='contain'
          source={imageUrl}
          style={styles.image}
        />
        {heading && <Text style={styles.heading}>{heading}</Text>}
        <Text style={styles.subheading}>{subheading}</Text>

        {!showLoader ? (
          <StarRating
            rating={rating}
            count={5}
            defaultRating={0}
            size={40}
            onFinishRating={this.onFinishRating}
            alignSelf='center'
          />
        ) : (
          <View style={styles.loadingContainer}>
            <Text style={styles.loadingText}>{loadingText}</Text>
            <ActivityIndicator
              size='small'
              color={colors.foxyBlack}
              style={styles.activityContainer}
            />
          </View>
        )}

        {showRatingDescription && (
          <>
            <OutlinedTextField
              editable
              multiline
              maxLength={100}
              onChangeText={this.onChangeBody}
              value={ratingDescription}
              label={'Tell us what went wrong'}
              lineWidth={0}
              activeLineWidth={0}
              inputContainerStyle={styles.inputContainerStyle}
              style={styles.descriptionBoxStyle}
              textAlignVertical='top'
              autoFocus
            />

            <RoundedButton
              buttonText={'Submit'}
              buttonTextColor={colors.white}
              buttonColor={colors.black}
              buttonAction={this.submitRating}
              style={styles.roundedButtonContainer}
              disabled={Utility.isBlank(ratingDescription)}
              buttonTextStyle={styles.buttonText}
            />
          </>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth() - 24,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
    marginTop: 3,
  },
  image: { height: 140, width: 227, marginTop: 20, marginBottom: 12 },
  heading: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: '#404E9F',
    lineHeight: 21.09,
    marginBottom: 4,
  },
  subheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginBottom: 4,
    textAlign: 'center',
    marginTop: 6,
  },
  loadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    lineHeight: 14.09,
    marginBottom: 4,
    textAlign: 'center',
    marginTop: 6,
  },
  activityContainer: { marginVertical: 25, marginLeft: 12 },
  descriptionBox: {
    backgroundColor: '#fff',
    borderColor: colors.border,
    borderWidth: 1,
    borderRadius: 8,
    width: Utility.getScreenWidth() - 32,
    marginTop: 8,
    padding: 0,
  },
  // inputBox: { padding: 0, backgroundColor: 'red' },
  roundedButtonContainer: {
    width: 101,
    height: 32,
    alignSelf: 'center',
    marginTop: 16,
  },
  buttonText: { fontSize: 12, fontFamily: 'Roboto-Medium' },
  endStateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    width: Utility.getScreenWidth() - 24,
    paddingHorizontal: 12,
    paddingVertical: 12,
    borderRadius: 8,
  },
  endStateImageSmall: { height: 62, width: 68, marginRight: 8 },
  endStateImageLarge: { height: 62, width: 90, marginRight: 8 },
  endStateHeadingSubheading: { flexDirection: 'column', flexShrink: 1 },
  endStateHeading: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: '#404E9F',
  },
  endStateSubheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
  },
  inputContainerStyle: {
    backgroundColor: colors.white,
    height: 120,
    width: Utility.getScreenWidth() - 24 - 24,
    borderRadius: 8,
    paddingTop: 0,
    paddingLeft: 12,
    borderBottomWidth: 0,
    marginTop: 20,
  },
  descriptionBoxStyle: { alignSelf: 'flex-start' },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateAppRating,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(LargeActionRatingCard);
