import React from 'react';
import { View, Text, TouchableOpacity, Clipboard } from 'react-native';
import { ids, styles } from './styles';
import LinearGradient from 'react-native-linear-gradient';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import { AnalyticsManager, EventType } from '../../analytics';

export default function DashboardInviteLinkCard(props) {
  const { referralUrl = '', showCopiedToClipboard = () => {}, handle, } = props;

  const copyReferralUrl = () => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.DASHBOARD_INVITE, {
      handle,
    });
    Clipboard.setString(referralUrl);
    showCopiedToClipboard();
  };

  const InviteLinkButton = () => {
    return (
      <TouchableOpacity
        onPress={copyReferralUrl}
        style={[styles.editProfileButton, { borderRadius: 4 }]}
      >
        <FastImageView
          source={images.inviteIcon}
          style={styles.editButtonIcon}
        />
        <Text style={styles.editButtonText}>Invite</Text>
      </TouchableOpacity>
    );
  };

  return (
    <LinearGradient
      colors={['rgba(255,154,158,0.2)', 'rgba(255,154,158,0.2)']}
      style={styles.dashboardInfoCardContainer}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 0 }}
    >
      <FastImageView source={images.pro_invite} style={styles.proInviteImage} />
      <Text style={[styles.registrationCompletedHeading, { marginTop: 12 }]}>
        Know someone you can benefit
      </Text>
      <Text style={[styles.registrationCompletedSubheading, { marginTop: 6 }]}>
        Refer it to your friends and get a chance to win an iphone. Copy your
        referral code below and tap on invite to get started.
      </Text>
      <Text
        style={[
          styles.registrationCompletedSubheading,
          { paddingHorizontal: 0, alignSelf: 'flex-start', marginLeft: 24 },
        ]}
        dataSet={{ media: ids.addIntroVideoSubheading }}
      >
        {referralUrl}
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            bottom: -2,
          }}
          onPress={copyReferralUrl}
        >
          <FastImageView source={images.copy_blue} style={styles.copyImage} />
          <Text style={styles.copyText}>Copy</Text>
        </TouchableOpacity>
      </Text>
      <InviteLinkButton />
    </LinearGradient>
  );
}
