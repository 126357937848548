// Dependencies
import React, { Component } from 'react';
import { View, Image, ImageBackground, Text } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import { withMaybe } from '../../lib/Monads';
import Card from '../../lib/Card';
import {
  LAYOUT,
  ORIENTATION,
  THEME,
  MAX_LINES,
  SIZE,
} from '../../config/Constants';
import fontSize from '../../theme/Fonts';
import colors from '../../theme/Colors';

// Components
import { MEDIA } from '../../config/LayoutConstants/ContentMediaConfig';
import MediaCardStyles from './styles';
import images from '../../theme/Images';
import ScaleAnimate from '../shared/ScaleAnimate';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';

class MediaReviewCard extends Component {
  artistVerifiedCondition = (props) => !props.verified;

  verifiedTickWithCondition = withMaybe(this.artistVerifiedCondition)(Image);

  getStyles = ({ layout, size = SIZE.defaultSize }) => {
    const styles = MediaCardStyles;
    const { viewheight, viewwidth, imageheight, imagewidth } =
      MEDIA.CARD[layout][size] || MEDIA.CARD[layout].defaultSize;
    const cardDimensions = {
      height: viewheight,
      width: viewwidth,
    };
    const imageBackgroundStyle = {
      alignItems: 'center',
      justifyContent: 'center',
      width: imagewidth,
      height: imageheight,
    };
    const cardStyle = [cardDimensions, styles.reviewGridLayoutCardStyle];
    return {
      cardStyle,
      imageBackgroundStyle,
    };
  };

  // Functional components
  navigateToMedia = () => {
    const { navigation, itemData, listId, index } = this.props;

    navigation.push('ContentModal', {
      itemData,
      listId,
      index,
    });
  };

  MediaImage = (props) => {
    const { imageUrl, imageBackgroundStyle, star } = props;
    const styles = MediaCardStyles;
    return (
      <ImageBackground source={{ uri: imageUrl }} style={imageBackgroundStyle}>
        <ImageBackground
          style={[
            styles.ratingRectangle,
            { backgroundColor: PRODUCT.RATING.color[Math.floor(star)] },
          ]}
        >
          <Text numberOfLines={MAX_LINES.single} style={styles.ratingnumber}>
            {star}
          </Text>
          <Image
            source={images.greenStar}
            style={{
              marginLeft: 2,
              height: 10,
              width: 10,
              tintColor: colors.white,
            }}
          />
        </ImageBackground>
      </ImageBackground>
    );
  };

  MediaDescription = (props) => {
    const { layout = LAYOUT.RAIL, artistName, artistImage, verified } = props;
    const styles = MediaCardStyles;
    return (
      <LinearGradient
        colors={['rgba(0, 0, 0, 0)', 'black']}
        style={[
          styles.gradientStyle,
          { height: MEDIA.CONSTANTS.gradientHeight.reviewGrid },
        ]}
      >
        <View style={styles[`${layout}ArtistContainer`]}>
          <ImageBackground
            borderRadius={9}
            resizeMode="cover"
            source={{ uri: artistImage }}
            style={MediaCardStyles.artistMiniAvatar}
          />
          <Text
            numberOfLines={MAX_LINES.single}
            style={[
              MediaCardStyles.artistName,
              {
                fontSize: fontSize.h5,
                color: colors.white,
                maxWidth: MEDIA.MAX_WIDTH.ARTIST_NAME[layout],
              },
            ]}
          >
            {artistName}
          </Text>
          <this.verifiedTickWithCondition
            verified={verified}
            source={images.verifiedTick}
            style={MediaCardStyles.verifiedTick}
          />
        </View>
      </LinearGradient>
    );
  };

  render() {
    const { layout, itemData } = this.props;
    const { cardStyle, imageBackgroundStyle } = this.getStyles(this.props);
    const artistName = (itemData.artist && itemData.artist.name) || '';
    const artistImage =
      (itemData.artist &&
        itemData.artist.images &&
        itemData.artist.images.thumbnail) ||
      'https://apan.net/people/avatar.png';
    const verified = itemData.artist && itemData.artist.verified;
    return (
      <ScaleAnimate
        {...this.props}
        layout={layout}
        onPress={this.navigateToMedia}
      >
        <Card
          cardElevation={2}
          cardMaxElevation={2}
          cornerRadius={4}
          borderWidth={1}
          style={cardStyle}
        >
          <this.MediaImage
            {...this.props}
            imageBackgroundStyle={imageBackgroundStyle}
            imageUrl={itemData.image_url}
            star={itemData.star}
          />
          <this.MediaDescription
            layout={layout}
            artistName={artistName}
            artistImage={artistImage}
            verified={verified}
          />
        </Card>
      </ScaleAnimate>
    );
  }
}

// PropTypes
MediaReviewCard.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string,
  size: PropTypes.string,
  itemData: PropTypes.shape({
    image_url: PropTypes.string,
    id: PropTypes.string,
  }),
  theme: PropTypes.string,
  listId: PropTypes.string,
};

MediaReviewCard.defaultProps = {
  theme: THEME.LIGHT,
  listId: null,
};

export default withNavigation(MediaReviewCard);
