import { Text, StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';
import BagIcon from '../../components/header/BagIcon';

export default function Bag({ navigation }) {
  const navigationToCart = () => {
    navigation.navigate('Cart');
  };
  return (
    <TouchableOpacity style={styles.container} onPress={navigationToCart}>
      <BagIcon handleTap={() => {}} showCart />
      <Text style={styles.text}>Bag</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
  },
  image: {
    width: 20,
    height: 20,
    lineHeight: 1.5,
    alignSelf: 'center',
  },
  text: {
    fontSize: 12,
    color: '#173143',
    lineHeight: 1.5,
    alignSelf: 'center',
    marginTop: 12,
  },
});
