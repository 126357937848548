import Razorpay from 'react-native-customui';
import Config from '../../libraries/ReactNativeConfig';
import Utility from '../../utils/Utility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import NetworkStats from '../../utils/NetworkStat';

export default function checkoutWithRazorpay(
  response = {},
  extraData,
  paymentMethodDetails,
  cancelPayment,
  orderPayload,
  cartItems,
  cartPricing,
  upgradeToPrepaid,
) {
  const { pg_params = {} } = response;
  const meta = {
    orderPayload,
    extraData,
    cartItems,
    cartPricing,
    payuParams: pg_params,
    UtoP: upgradeToPrepaid,
    payuPayload: paymentMethodDetails,
    networkInfo: NetworkStats.networkDetails,
  };

  AnalyticsUtility.firePaymentInitiate(meta);

  const {
    pg_params: {
      razorpay_order_id: rzpOrderId,
      amount,
      phone_number: phoneNumber,
      email,
      key,
      order_id: orderID,
    } = {},
  } = response;

  const { method, package_name: packageName } = extraData;
  const { cardNumber, cvv, cardName, expiryMonth, expiryYear, vpa } =
    paymentMethodDetails;
  const options = {
    description: `${Config.APP_NAME}: OrderID:${orderID}`,
    currency: Config.ISO_CURRENCY_CODE,
    key_id: key,
    order_id: rzpOrderId,
    amount,
    contact: phoneNumber,
    method,
  };
  if (Utility.isPresent(email)) {
    options.email = email;
  }
  if (method === 'card') {
    options.card = {
      number: cardNumber,
      name: cardName,
      expiry_month: expiryMonth,
      expiry_year: expiryYear,
      cvv,
    };
  } else if (method === 'upi') {
    options.vpa = vpa;
  } else if (method === 'intent') {
    options.method = 'upi';
    options['_[flow]'] = 'intent';
    if (Utility.isIOS() && packageName === 'gpay') {
      options.upi_app_package_name = 'google_pay';
    } else {
      options.upi_app_package_name = packageName;
    }
  }

  RazorpayCheckoutWithTimeout(options)
    .then((data) => {
      console.tron.log(`Raz Success: ${data}`);
    })
    .catch((error) => {
      cancelPayment();
      console.tron.log(`Raz Error: ${error?.code} | ${error?.description}`);
    });
}

function RazorpayCheckoutWithTimeout(options) {
  // Create a timeout promise that rejects after the specified timeout
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Promise timed out'));
    }, 300000);
  });

  // Use Promise.race() to race the original promise with the timeout promise
  return Promise.race([Razorpay.open(options), timeoutPromise]);
}
