import { schema } from 'normalizr';
import { brandSchema } from './Schema';

const videos = new schema.Entity('videos', undefined, {
  idAttribute: (value) => value.slug,
});
const images = new schema.Entity('images', undefined, {
  idAttribute: (value) => value.slug,
});
const salons = new schema.Entity('salons', undefined, {
  idAttribute: (value) => value.slug,
});
const brands = new schema.Entity('brands', undefined, {
  idAttribute: (value) => value.slug,
});
const artists = new schema.Entity('artists', undefined, {
  idAttribute: (value) => value.slug,
});
const lists = new schema.Entity('lists', undefined, {
  idAttribute: (value) => value.slug,
});
const features = new schema.Entity('features', undefined, {
  idAttribute: (value) => value.id, // TODO: need modification in api
});
const ads = new schema.Entity('ads', undefined, {
  idAttribute: (value) => value.slug,
});
const banners = new schema.Entity('banners', undefined, {
  idAttribute: (value) => value.slug,
});
export const products = new schema.Entity('products', undefined, {
  idAttribute: (value) => value.slug,
});
export const short_banners = new schema.Entity('short_banners', undefined, {
  idAttribute: (value) => value.id, // TODO: need modification in api
});

export const variant_attributes = new schema.Entity(
  'variant_attributes',
  undefined,
  {
    idAttribute: (value) => value.id,
  },
);
export const variants = new schema.Entity('variants', undefined, {
  idAttribute: (value) => value.id, // TODO: need modification in api
});
export const reviews = new schema.Entity('reviews', undefined, {
  idAttribute: (value) => value.slug,
});
const media_categories = new schema.Entity('media_categorys', undefined, {
  idAttribute: (value) => value.slug,
});
const tags = new schema.Entity('tags', undefined, {
  idAttribute: (value) => value.slug,
});
const offers = new schema.Entity('offers', undefined, {
  idAttribute: (value) => value.slug,
});
export const objectsArrayBySlug = new schema.Array(
  {
    products,
    videos,
    images,
    salons,
    brands,
    artists,
    lists,
    features,
    ads,
    banners,
    short_banners,
    media_categories,
    tags,
    offers,
  },
  (input, _parent, _key) => `${input.type}s`,
);

artists.define({
  objects: objectsArrayBySlug,
});

brands.define({
  objects: objectsArrayBySlug,
});

variant_attributes.define({
  allowed_values: [variants],
});

products.define({
  objects: objectsArrayBySlug,
  brand: brandSchema,
  reviews,
  variants,
});

salons.define({
  objects: objectsArrayBySlug,
});

images.define({
  objects: objectsArrayBySlug,
});

videos.define({
  artist: artists,
  objects: objectsArrayBySlug,
});

lists.define({
  objects: objectsArrayBySlug,
});

reviews.define({
  video_reviews: lists,
});

offers.define({
  list: lists,
});

export const schemaBySlug = {
  videos,
  images,
  salons,
  brands,
  artists,
  lists,
  features,
  ads,
  banners,
  products,
  short_banners,
  reviews,
  tags,
  offers,
};
