import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Keyboard,
  TextInput,
} from 'react-native';
import { connect } from 'react-redux';
import { PaymentStyles } from './styles';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { AnalyticsManager } from '../../analytics';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { StyleSheet } from 'react-native';
import { isDesktop } from '../../utils/BooleanUtility';

class PaytmWallet extends Component {
  constructor(props) {
    super(props);
    this.ref = [];
    this.ref.vpa = React.createRef();
    this.state = {
      mobileNumber: Utility.isPresent(this.props.mobileNumber)
        ? this.props.mobileNumber.replace('+91', '')
        : this.props.guestPhoneNumber,
      error: '',
    };
    this.lastEnteredPhoneNumber = '';
    this.mobileNumberRegex = /^[+\d{12,15}$]/;
  }

  resetTextFields = () => {
    this.setState({
      mobileNumber: '',
    });
  };

  changePaytmPhoneNumber = (text) => {
    const { error } = this.state;
    const maskedText = text.replace(/[^0-9+-]/g, '');
    let phone = '';

    this.setState({
      error: '',
    });

    if (this.lastEnteredPhoneNumber.indexOf('-') !== text.length) {
      phone =
        maskedText.startsWith('+91') && maskedText.indexOf('-') === -1
          ? Utility.insertCharAtPosition(3, text, '-')
          : text;
    } else {
      phone = text;
    }
    this.lastEnteredPhoneNumber = phone;
    const newState = { mobileNumber: phone };
    if (error) {
      newState.warning = !this.mobileNumberRegex.test(maskedText);
    }
    const enteredPhoneNumber = Utility.removeDashFromText(phone);
    this.lastProcessedNumber = enteredPhoneNumber;
    this.setState(newState);
    if (!this.userStartedPhonenumberTyping) {
      this.userStartedPhonenumberTyping = true;
    }
  };

  showPaymentProcessingScreen = () => {
    const { showPaymentProcessingScreen } = this.props;
    showPaymentProcessingScreen();
  };

  onPaytmLinkAccountPress = () => {
    const { navigation, createOrder = () => {} } = this.props;
    Keyboard.dismiss();

    AnalyticsManager.logEvent('paytm_link_initiate');

    const { mobileNumber } = this.state;

    if (this.mobileNumberRegex.test(mobileNumber)) {
      const valid = Utility.phoneNumberFormatter(mobileNumber);

      if (!valid.error) {
        navigation.navigate('PaytmVerify', {
          mobileNumber,
          createOrder,
        });
      } else {
        this.setState({ error: 'Not a valid phone number' });
      }
    } else {
      this.setState({
        error: 'Please enter a 10-digit phone number.',
      });
    }
  };

  render() {
    const styles = PaymentStyles;
    const { error, mobileNumber } = this.state;
    const { guestPhoneNumber } = this.props;
    return (
      <View style={paytmStyles.container}>
        <View style={paytmStyles.innerContainer}>
          <View styles={styles.enterCvvContainer}>
            <Text style={paytmStyles.heading}>Connect your Paytm Account</Text>
            <TextInput
              placeholder='Enter your Paytm mobile number'
              placeholderTextColor={colors.silver}
              lineWidth={1}
              blurOnSubmit={false}
              value={Utility.isPresent(mobileNumber) ? mobileNumber : ''}
              onChangeText={this.changePaytmPhoneNumber}
              ref={this.ref.vpa}
              containerStyle={styles.vpaContainer}
              onSubmitEditing={Keyboard.dismiss}
              returnKeyType='done'
              keyboardType='number-pad'
              autoCapitalize={false}
              maxLength={10}
              keyboardAppearance={'light'}
              style={paytmStyles.inputBox}
              color={colors.black}
            />
            {!Utility.isBlank(error) && (
              <Text style={paytmStyles.errorText}>{error}</Text>
            )}

            <FoxyShadowButton
              width={isDesktop() ? '100%' : Utility.getScreenWidth() - 34}
              title={'Link Account'}
              onPress={this.onPaytmLinkAccountPress}
              backgroundColor={colors.primaryActionBackgroundColor}
              style={paytmStyles.linkButton}
              firstColor={colors.linearGradientGreenFirst}
              secondColor={colors.linearGradientGreenSecond}
            />
          </View>
        </View>
      </View>
    );
  }
}

const paytmStyles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 6,
    paddingBottom: 10,
  },
  linkButton: {
    marginTop: 12,
  },
  errorText: {
    color: colors.foxyRed,
    marginTop: 2,
    fontSize: 12,
    alignSelf: 'flex-start',
  },
  inputBox: {
    borderRadius: 4,
    paddingLeft: 8,
    borderColor: colors.border,
    borderWidth: 1,
    padding: 8,
    height: 45,
    color: colors.foxyBlack,
  },
  heading: { marginBottom: 8, color: '#606481' },
  innerContainer: {
    flexDirection: 'column',
    marginLeft: Utility.largePadding,
    marginRight: Utility.largePadding,
  },
});

const mapStateToProps = function (state) {
  return {
    mobileNumber: state.UserAccountInfo.mobileNumber,
    guestPhoneNumber: state.bag.defaultAddress.phone_number,
  };
};

export default withNavigation(connect(mapStateToProps, null)(PaytmWallet));
