import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';

const styles = StyleSheet.create({
  foxyMatchText: {
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    fontSize: 12,
  },
  foxyMatchTextCta: {
    fontFamily: 'Roboto-Bold',
    color: colors.subtitle,
    fontSize: 12,
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: 8,
    overflow: 'visible',
    tintColor: '#6C6C6C',
    paddingHorizontal: 4,
    resizeMode: 'contain',
  },
  playIcon: {
    height: 13,
    width: 16,
    marginRight: 8,
    overflow: 'visible',
    tintColor: '#6C6C6C',
    paddingHorizontal: 4,
    resizeMode: 'contain',
  },
  container: { flexDirection: 'row', alignItems: 'center', marginVertical: 8 },
});

export default styles;
