// Dependencies
import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  NativeModules,
} from 'react-native';
import { connect } from 'react-redux';
import Images from '../../theme/Images';
import styles from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import AppConfig from '../../config/AppConfig';
import _ from 'lodash';
import Config from '../../libraries/ReactNativeConfig';
import { getMinifiedImage } from '../../utils/ImageUtils';

class BagIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCartVisible: false,
    };
  }

  handleTap = () => {
    const { navigation, fireVideoCtaClickEvent } = this.props;
    if (AppConfig.isCartPresentInBottomTabs()) {
      navigation.navigate('Cart', {
        source: 'navigation_header',
      });
    } else {
      navigation.push('Cart', {
        source: 'navigation_header',
      });
    }
    if (fireVideoCtaClickEvent) {
      fireVideoCtaClickEvent('cart');
    }
  };

  onCartDismiss = () => {
    this.setState({
      isCartVisible: false,
    });
  };

  itemsIndicator = (props) => {
    const { showCart } = props;
    const { cartItems = [] } = this.props;

    if (Utility.isAndroid()) {
      NativeModules.UserPreferences.updateCartUpdatedAt();
      if (!showCart || Utility.isBlank(cartItems) || cartItems.length === 0) {
        NativeModules.UserPreferences.saveCartItemsCount('0');
      } else if (cartItems.length === 1 && cartItems[0]?.is_free) {
        NativeModules.UserPreferences.saveCartItemsCount('0');
      } else {
        if (cartItems.length === 1 && cartItems[0]?.is_free) {
          NativeModules.UserPreferences.saveCartItemsCount('0');
        }
        NativeModules.UserPreferences.saveCartItemsCount(
          cartItems.length.toString(),
        );
      }
    }
    if (!showCart || Utility.isBlank(cartItems) || cartItems.length === 0) {
      return null;
    }

    const quantityToShow = _.reduce(
      cartItems,
      (total, item) => {
        return total + item.quantity;
      },
      0,
    );
    return (
      <View style={styles.indicatorStyle}>
        <Text
          allowFontScaling={false}
          style={styles.cartItemCountIndicatorText}
        >
          {quantityToShow}
        </Text>
      </View>
    );
  };

  render() {
    const { showCart, darkMode } = this.props;

    let cartImage = showCart ? Images.ic_bag_product : '';
    if (Utility.isPresent(Config.CART_HEADER_IMAGE)) {
      cartImage = { uri: getMinifiedImage(Config.CART_HEADER_IMAGE) };
    }
    const style = darkMode
      ? styles.notificationStyleWhite
      : styles.notificationStyle;
    return (
      <>
        <DebouncedTouchableOpacity
          testID="header-cart-icon"
          accessibilityLabel="header-cart-icon"
          {...this.props}
          onPress={this.handleTap}
          hitSlop={Utility.getHitSlop()}
        >
          <Image source={cartImage} style={style} />
          <this.itemsIndicator showCart={showCart} />
        </DebouncedTouchableOpacity>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.bag?.cartItems,
});

export default withNavigation(connect(mapStateToProps, null)(BagIcon));
