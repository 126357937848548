import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Keyboard,
  ScrollView,
  ActivityIndicator,
  TextInput,
} from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import StyleSheet from 'react-native-media-query';
import { TextField } from 'react-native-material-textfield';
import { PaymentStyles } from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { RNPayuPayment } from '../../lib/RNPayuPayment';
import FullWidthDivider from '../../utils/FullWidthDivider';
import { Grayscale } from '../../libraries/ReactNativeColorMatrixImageFilters';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { isDesktop, isWebMobile } from '../../utils/BooleanUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';

class CardPayment extends Component {
  constructor(props) {
    super(props);
    const { metaData = {} } = this.props;
    this.ref = [];
    this.ref.cardName = React.createRef();
    this.ref.cardNumber = React.createRef();
    this.ref.expiryDate = React.createRef();
    this.ref.cvv = React.createRef();
    this.state = {
      cardNumber: Utility.isPresent(metaData.cardNumber)
        ? metaData.cardNumber
        : '',
      cardName: Utility.isPresent(metaData.cardName) ? metaData.cardName : '',
      cvv: Utility.isPresent(metaData.cardCVV) ? metaData.cardCVV : '',
      expiryDate: Utility.isPresent(metaData.cardExpire)
        ? metaData.cardExpire
        : '',
      saveCardSelected: true,
      isCardValid: true,
      isNameEmpty: false,
      isCVVEmpty: false,
      isExpiryDateEmpty: false,
      isCardNumEmpty: false,
      cardBrand: 'unknown',
      isLoaderVisible: false,
      expireBeforeToday: false,
    };
  }

  goBack = () => {
    AnalyticsUtility.fireGoBackEvent();
    const { hidePaymentDetailsScreen } = this.props;
    this.resetTextFields();
    hidePaymentDetailsScreen();
  };

  showLoader = () => {
    this.setState({
      isLoaderVisible: true,
    });
  };

  showBackAlert = () => {
    Keyboard.dismiss();
    Alert.alert(
      'Confirmation',
      'Are you sure you want to cancel the transaction?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        { text: 'OK', onPress: this.goBack },
      ],
      { cancelable: true },
    );
  };

  addCardName = (value) => {
    const { metaData } = this.props;
    this.setState({ cardName: value, isNameEmpty: false });
    metaData.setCardName(value);
  };

  checkForCardValidity = (cardNumber) => {
    const cardNumWithoutSpace = cardNumber.replace(/\s/g, '');
    const brand = Utility.getCardBrand(cardNumWithoutSpace);
    const isCardValid = Utility.evaluateLuhnAlgorithm(cardNumWithoutSpace);
    this.setState({
      cardBrand: brand,
    });
    if (cardNumWithoutSpace.length >= 15) {
      //check for card validity only if user has entered enough number
      this.setState({
        isCardValid,
      });
    } else {
      this.setState({
        isCardValid: true,
      });
    }
  };

  addCardNumber = (value) => {
    const { cardNumber } = this.state;
    const { metaData } = this.props;
    let valueToSet = '';
    if (
      value.replace(/\s/g, '').length > cardNumber.replace(/\s/g, '').length
    ) {
      if (
        value.replace(/\s/g, '').length % 4 === 0 &&
        value.replace(/\s/g, '').length !== 16
      ) {
        valueToSet = `${value} `;
      } else {
        valueToSet = value;
      }
    } else {
      valueToSet = value;
    }

    this.setState(
      { cardNumber: valueToSet, isCardNumEmpty: false },
      this.checkForCardValidity(valueToSet),
    );

    metaData.setCardNumber(value);
  };

  addExpiryDate = (value) => {
    const { expiryDate } = this.state;
    const { metaData } = this.props;
    let valueToSet = '';
    if (value.length > expiryDate.length) {
      if (value.length === 1 && parseInt(value) > 1) {
        valueToSet = `0${value}/`;
      } else if (value.length === 2) {
        valueToSet = `${value}/`;
      } else {
        valueToSet = value;
      }
    } else {
      valueToSet = value;
    }

    this.setState({
      expiryDate: valueToSet,
      isExpiryDateEmpty: false,
      expireBeforeToday: false,
    });
    if (valueToSet.length >= 5) {
      this.next('cvv');
    }
    metaData.setCardExpire(value);
  };

  next = (fieldName) => {
    this.ref[fieldName].current.focus();
  };

  addCVV = (value) => {
    const { metaData } = this.props;
    metaData.setCardCVV(value);
    this.setState({ cvv: value, isCVVEmpty: false });
  };

  saveCardCheckboxPressed = () => {
    this.setState((prevState) => ({
      saveCardSelected: !prevState.saveCardSelected,
    }));
  };

  paymentConstants = {
    headerText: 'Enter Details',
    cardName: 'Name on card',
    cardNumber: 'Card number',
    expiryDate: 'Expiry date (MM/YY)',
    cvv: 'CVV',
    saveCard: 'Save this card securely',
    invalidCardText: 'Card number is invalid',
    emptyExpiryDateText: 'Enter expiry date',
    expireBeforeToday: `Invalid expiry`,
    cvvEmptyText: 'Enter valid cvv',
    emptyNameText: 'Enter a name',
  };

  shouldContinueToPayment = () => {
    const { cardNumber, cardName, expiryDate, cvv } = this.state;

    if (expiryDate.length === 0) {
      this.setState({
        isExpiryDateEmpty: true,
      });
    }

    const expiryMonth = `${expiryDate.substr(0, 2)}`;
    const expiryYear = `20${expiryDate.substr(-2)}`;

    const today = new Date();
    const someday = new Date();
    someday.setFullYear(expiryYear, expiryMonth, 1);

    if (someday < today) {
      this.setState({
        expireBeforeToday: true,
      });
    }
    if (cvv.length < 3) {
      this.setState({
        isCVVEmpty: true,
      });
    }

    if (cardNumber.length === 0) {
      this.setState({
        isCardNumEmpty: true,
      });
    }
    return !(
      cardNumber.length === 0 ||
      expiryDate.length === 0 ||
      cvv.length === 0
    );
  };

  resetTextFields = () => {
    this.setState({
      cardNumber: '',
      cardName: '',
      cvv: '',
      expiryDate: '',
      saveCardSelected: false,
      isCardValid: true,
      isNameEmpty: false,
      isCVVEmpty: false,
      isExpiryDateEmpty: false,
      isCardNumEmpty: false,
      expireBeforeToday: false,
    });
  };

  makePayment = () => {
    //TODO: Move this function in cartmodal.js
    const {
      viewOrderStatusScreen,
      paymentSuccess,
      setCurrentPaymentDetails,
      updateLastPaymentMode,
      initiatePayment,
      openOrderStatusScreen,
    } = this.props;
    const {
      cardNumber,
      cardName,
      expiryDate,
      saveCardSelected,
      cvv,
      cardBrand,
    } = this.state;
    const carNumberWithoutSpace = cardNumber.replace(/\s/g, '');
    const expiryMonth = `${expiryDate.substr(0, 2)}`;
    const expiryYear = `20${expiryDate.substr(-2)}`;
    const saveCard = saveCardSelected;
    if (!this.shouldContinueToPayment()) {
      return;
    }
    this.resetTextFields();
    const cardParams = {
      cardNumber: carNumberWithoutSpace,
      cvv,
      cardName,
      expiryMonth,
      expiryYear,
      cardBrand,
    };
    console.tron.log('card param for card payment is ', cardParams);
    setCurrentPaymentDetails('card', cardParams); //to retry if failed
    initiatePayment(); //To start loader in payment details
    RNPayuPayment.payWithCard(cardParams, saveCard)
      .then((res) => {
        console.tron.log('pay with card successfull', res);
        if (Utility.isPresent(res.status) && res.status === 'success') {
          openOrderStatusScreen(res);
        } else {
          openOrderStatusScreen(res);
        }
      })
      .catch((e) => {
        console.tron.log('pay with card failure', e);
        openOrderStatusScreen(e);
      });
  };

  createOrderAndPay = () => {
    const {
      cardNumber,
      cardName,
      expiryDate,
      saveCardSelected,
      cvv,
      cardBrand,
    } = this.state;
    if (!this.shouldContinueToPayment()) {
      return;
    }
    const carNumberWithoutSpace = cardNumber.replace(/\s/g, '');
    const cardNumberFirstString = carNumberWithoutSpace.substr(0, 6);
    const cardNumberSecondString = carNumberWithoutSpace.substr(-4);
    const maskedCardNumber = `${cardNumberFirstString}'XXXXXX'${cardNumberSecondString}`;
    const expiryMonth = `${expiryDate.substr(0, 2)}`;
    const expiryYear = `20${expiryDate.substr(-2)}`;
    const paymentMethodPayload = {
      masked_card_number: maskedCardNumber,
    };
    const cardParams = {
      cardNumber: carNumberWithoutSpace,
      cvv,
      cardName,
      expiryMonth,
      expiryYear,
      cardBrand,
    };

    Keyboard.dismiss();
    const { createOrder } = this.props;

    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: 'credit_card',
      isSavedPaymentMethod: saveCardSelected,
      paymentMethodPayload,
      payuPayload: cardParams,
      extraData: {
        method: 'card',
        save: saveCardSelected,
      },
    };

    createOrder(paymentPayload);
  };

  cardImages = () => (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Grayscale>
        <Image
          source={images.cardBrand['visa']}
          style={{
            height: 16,
            width: 32,
            marginRight: 3,
          }}
        />
      </Grayscale>

      <Grayscale>
        <Image
          source={images.cardBrand['mastercard']}
          style={{
            height: 16,
            width: 32,
            marginRight: 3,
          }}
        />
      </Grayscale>

      <Grayscale>
        <Image
          source={images.cardBrand['amex']}
          style={{
            height: 16,
            width: 32,
            marginRight: 3,
          }}
        />
      </Grayscale>

      <Grayscale>
        <Image
          source={images.cardBrand['rupay']}
          style={{
            height: 16,
            width: 32,
            marginRight: 3,
          }}
        />
      </Grayscale>
    </View>
  );

  cardImageForSpecificCardBrand = ({ cardBrand }) => (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Image
        source={images.cardBrand[cardBrand]}
        style={{
          height: 16,
          width: 32,
        }}
      />
    </View>
  );

  render() {
    const styles = PaymentStyles;
    const {
      cardName,
      cardNumber,
      cvv,
      expiryDate,
      saveCardSelected,
      isCardValid,
      isNameEmpty,
      isCVVEmpty,
      isExpiryDateEmpty,
      isCardNumEmpty,
      cardBrand,
      isLoaderVisible,
      expireBeforeToday,
    } = this.state;
    const { shouldAutoFocus } = this.props;
    const saveCardColor = saveCardSelected ? colors.foxyBlack : colors.silver;
    const checkboxImage = saveCardSelected ? images.greenCheck : '';
    let expireError = '';
    if (isExpiryDateEmpty) {
      expireError = this.paymentConstants.emptyExpiryDateText;
    } else if (expireBeforeToday) {
      expireError = this.paymentConstants.expireBeforeToday;
    }

    let mobileWebPadding = {};
    if (isWebMobile()) {
      mobileWebPadding = {
        paddingLeft: 20,
        paddingRight: 20,
      };
    }

    return (
      <View style={{ flex: 1, backgroundColor: colors.white, borderRadius: 6 }}>
        <View
          style={[
            styles.container,
            {
              marginLeft: Utility.largePadding,
              marginRight: Utility.largePadding,
            },
            mobileWebPadding,
          ]}
          dataSet={{ media: ids.container }}
        >
          <ScrollView
            bounces={false}
            style={[styles.scrollView, { marginBottom: 10 }]}
            keyboardShouldPersistTaps='handled'
          >
            <View
              style={styles.textFieldWrapper}
              dataSet={{ media: ids.textFieldWrapper }}
            >
              {/* <Text style={styles.cardTextFieldLabel}>{this.paymentConstants.cardNumber}</Text> */}
              <View style={styles.textFieldContainer}>
                <TextField
                  placeholder={this.paymentConstants.cardNumber}
                  placeholderTextColor={colors.silver}
                  lineWidth={1}
                  blurOnSubmit={false}
                  value={cardNumber}
                  onChangeText={this.addCardNumber}
                  ref={this.ref.cardNumber}
                  onSubmitEditing={() => this.next('expiryDate')}
                  keyboardType='numeric'
                  returnKeyType='next'
                  maxLength={24}
                  error={
                    isCardNumEmpty
                      ? this.paymentConstants.invalidCardText
                      : isCardValid
                      ? null
                      : this.paymentConstants.invalidCardText
                  }
                  labelHeight={8}
                  textAlignVertical='center'
                  inputContainerStyle={styles.cardTextFieldInputContainer}
                  hideLabel={false}
                  onFocus={() => {
                    console.tron.log('card no focusd');
                  }}
                  activeLineWidth={0}
                  tintColor={colors.silver}
                />
              </View>

              <View style={{ flexDirection: 'row' }}>
                <View style={styles.textFieldContainer}>
                  {/* <Text style={styles.cardTextFieldLabel}>{this.paymentConstants.expiryDate}</Text> */}
                  <TextField
                    placeholder={this.paymentConstants.expiryDate}
                    placeholderTextColor={colors.silver}
                    lineWidth={1}
                    blurOnSubmit={false}
                    value={expiryDate}
                    onChangeText={this.addExpiryDate}
                    ref={this.ref.expiryDate}
                    onSubmitEditing={() => this.next('cvv')}
                    returnKeyType='next'
                    keyboardType='numeric'
                    maxLength={5}
                    error={Utility.isPresent(expireError) && expireError}
                    textAlignVertical='center'
                    labelHeight={6}
                    inputContainerStyle={[
                      styles.cardTextFieldInputContainer,
                      { width: 170 },
                    ]}
                    hideLabel={false}
                    onFocus={() => {
                      console.tron.log('expiry focusd');
                    }}
                    activeLineWidth={0}
                    tintColor={colors.silver}
                  />
                </View>
                <View style={[styles.textFieldContainer, { marginLeft: 16 }]}>
                  <TextField
                    placeholder={this.paymentConstants.cvv}
                    placeholderTextColor={colors.silver}
                    lineWidth={1}
                    blurOnSubmit={false}
                    value={cvv}
                    onChangeText={this.addCVV}
                    ref={this.ref.cvv}
                    maxLength={4}
                    containerStyle={styles.cvvContainer}
                    onSubmitEditing={Keyboard.dismiss}
                    keyboardType='numeric'
                    returnKeyType='done'
                    labelHeight={6}
                    error={
                      isCVVEmpty ? this.paymentConstants.cvvEmptyText : null
                    }
                    secureTextEntry
                    textAlignVertical='center'
                    inputContainerStyle={[
                      styles.cardTextFieldInputContainer,
                      { width: 60 },
                    ]}
                    hideLabel={false}
                    onFocus={() => {
                      console.tron.log('cvv focusd');
                    }}
                    activeLineWidth={0}
                    tintColor={colors.silver}
                  />
                </View>
              </View>
              {cardBrand === 'unknown' ? (
                <this.cardImages />
              ) : (
                <this.cardImageForSpecificCardBrand cardBrand={cardBrand} />
              )}
            </View>

            <TouchableOpacity
              onPress={this.saveCardCheckboxPressed}
              style={styles.saveCardDetailsView}
            >
              <View style={[styles.checkbox, { borderColor: saveCardColor }]}>
                <Image source={checkboxImage} style={styles.checkboxTick} />
              </View>
              <Text style={[styles.saveCardText, { color: saveCardColor }]}>
                {this.paymentConstants.saveCard}
              </Text>
            </TouchableOpacity>
            <FoxyShadowButton
              width={isDesktop() ? '100%' : Utility.getScreenWidth() - 34}
              title={'Pay'}
              onPress={this.createOrderAndPay}
              backgroundColor={colors.primaryActionBackgroundColor}
              style={{
                marginTop: 12,
                borderColor: colors.primaryActionBackgroundColor,
                borderWidth: 1,
              }}
              firstColor={colors.primaryActionBackgroundColor}
              secondColor={colors.linearGradientGreenSecond}
            />
          </ScrollView>
        </View>
      </View>
    );
  }
}

export default withNavigation(CardPayment);

const { ids } = StyleSheet.create({
  container: {
    '@media (min-width: 768px)': {
      width: '100%',
    },
  },
  textFieldWrapper: {
    '@media (min-width: 768px)': {
      marginTop: 0,
    },
  },
});
