import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCartItems } from '../../actions/ActionTypes';
import Items from '../../components/cart/CartItems';
import withNavigation from '../../utils/WithNavigation';

export class CartItems extends Component {
  render() {
    const { cartItems, navigation } = this.props;
    return <Items cartItems={cartItems} navigation={navigation} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getCartItems,
    },
    dispatch,
  ),
});

export default withNavigation(connect(null, mapDispatchToProps)(CartItems));
