import { takeLatest } from 'redux-saga/effects';
import {
  CREATE_INVITE_REQUEST,
  ACCEPT_INVITE_REQUEST,
  REJECT_INVITE_REQUEST,
  GET_LOYALTY_PLAN_INVITATIONS,
  GET_REGISTRATION_INVITATIONS,
  BULK_CREATE_INVITE,
} from '../actions/InviteActions';
import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { convertJsonFromResponse, getApiHeaders } from './GeneratorUtil';

function* createInviteRequestAsync(action) {
  console.tron.log('create request invite');
  const {
    data: { purpose, purpose_data: { id = '0', duration } = {}, possible_host_ids = [] },
    callback = () => {},
  } = action;

  const idInt = parseInt(id);
  const postData = {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: JSON.stringify({
      invitation_request: {
        purpose,
        purpose_data: { id: idInt },
        possible_host_ids,
      },
    }),
  };

  const response = yield fetch(URL.REQUEST_INVITE_V1, postData);
  const json = yield convertJsonFromResponse(response);
  console.tron.log('request invite response', json);
  if (response.status >= 200 && response.status < 300) {
    callback(true, json);
  } else if (response.status === 422) {
    try {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(false, json);
    } catch (error) {
      // AnalyticsManager.logEvent(
      //   EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
      //   {
      //     [EventParameterKey.SOURCE]: `GroupDealSaga.js:createGroupDealsAsync() ${error}`,
      //   },
      // );
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

function* acceptInviteRequestAsync(action) {
  const { inviteId, callback = () => {} } = action;
  const postData = {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: {},
  };
  const url = `${URL.REQUEST_INVITE_V1}/${inviteId}/approve`;
  const response = yield fetch(url, postData);
  if (response.status >= 200 && response.status < 300) {
    callback(true);
  } else {
    callback(false);
  }
}

function* rejectInviteRequestAsync(action) {
  const { inviteId = 1, callback = () => {} } = action;
  const postData = {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: {},
  };
  const response = yield fetch(
    `${URL.REQUEST_INVITE_V1}/${inviteId}/deny`,
    postData,
  );
  if (response.status >= 200 && response.status < 300) {
    callback(true);
  } else {
    callback(false);
  }
}

function* getLoyaltyPlanInvitationsAsync(action) {
  const { callback = () => {} } = action;

  const getData = {
    method: 'GET',
    headers: yield getApiHeaders(),
  };

  const response = yield fetch(URL.GET_INVITATIONS_V1, getData);
  const json = yield convertJsonFromResponse(response);
  console.tron.log('request invite response', json);
  if (response.status >= 200 && response.status < 300) {
    callback(true, json);
  } else if (response.status === 422) {
    try {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(false, json);
    } catch (error) {
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

function* getRegistrationInvitationsAsync(action) {
  const { callback = () => {} } = action;

  const getData = {
    method: 'GET',
    headers: yield getApiHeaders(),
  };

  const response = yield fetch(`${URL.INVITATIONS}Registration`, getData);
  const json = yield convertJsonFromResponse(response);
  console.tron.log('request invite response', json);
  if (response.status >= 200 && response.status < 300) {
    callback(true, json);
  } else if (response.status === 422) {
    try {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(false, json);
    } catch (error) {
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}


/** Sample body to create invitations in bulk
   {
      invitations: [
         {invitee_country_code: '+230', invitee_phone_number: '36990000', purpose: 'Registration'},
         {invitee_country_code: '+91', invitee_phone_number: '3699000000', purpose: 'LoyaltyPlan', purpose_data: {loyalty_plan_id: 1}},
         ...
       ]
     }
*/

function* bulkCreateInviteAsync(action) {
  const { invitations = [], callback = () => {} } = action;
  console.tron.log('create request invite', invitations);

  const postData = {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: JSON.stringify({
      invitations,
    }),
  };

  const response = yield fetch(URL.INVITATIONS_BULK_CREATE, postData);
  const json = yield convertJsonFromResponse(response);
  console.tron.log('request invite response', json);
  if (response.status >= 200 && response.status < 300) {
    callback(true, json);
  } else if (response.status === 422) {
    try {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(false, json);
    } catch (error) {
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

export default function* watchInviteSaga() {
  yield takeLatest(CREATE_INVITE_REQUEST, createInviteRequestAsync);
  yield takeLatest(ACCEPT_INVITE_REQUEST, acceptInviteRequestAsync);
  yield takeLatest(REJECT_INVITE_REQUEST, rejectInviteRequestAsync);
  yield takeLatest(
    GET_LOYALTY_PLAN_INVITATIONS,
    getLoyaltyPlanInvitationsAsync,
  );
  yield takeLatest(
    GET_REGISTRATION_INVITATIONS,
    getRegistrationInvitationsAsync,
  );
  yield takeLatest(BULK_CREATE_INVITE, bulkCreateInviteAsync);
}
