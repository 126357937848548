export const GET_LOYALTY_PLAN_PROMPT_DETAILS =
  'GET_LOYALTY_PLAN_PROMPT_DETAILS';
export const GET_LOYALTY_PLANS = 'GET_LOYALTY_PLANS';
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const INVITE_CODE_VALIDATE = 'INVITE_CODE_VALIDATE';
export const INVITE_CODE_CLAIM = 'INVITE_CODE_CLAIM';
export const SET_CONGRATS_MODAL_EDGE = 'SET_CONGRATS_MODAL_EDGE';
export const ADD_CONTINUE_ACTION = 'ADD_CONTINUE_ACTION';

export function getLoyaltyPlanPromptDetails(slug, callback) {
  return {
    type: GET_LOYALTY_PLAN_PROMPT_DETAILS,
    slug,
    callback,
  };
}

export function getLoyaltyPlans(
  callback,
  upgradable,
  minimumEdgePriority = -1,
) {
  return {
    type: GET_LOYALTY_PLANS,
    callback,
    upgradable,
    minimumEdgePriority,
  };
}

export function getTestimonials(callback) {
  return {
    type: GET_TESTIMONIALS,
    callback,
  };
}

export function inviteCodeValidate(invite_code, callback) {
  return {
    type: INVITE_CODE_VALIDATE,
    invite_code,
    callback,
  };
}

export function inviteCodeClaim(invite_code, callback) {
  return {
    type: INVITE_CODE_CLAIM,
    invite_code,
    callback,
  };
}

export function setCongratsModalForEdge(show) {
  return {
    type: SET_CONGRATS_MODAL_EDGE,
    show,
  };
}

export function addContinueAction(slug, image, continueScreenType) {
  return {
    type: ADD_CONTINUE_ACTION,
    slug,
    image,
    continueScreenType,
  };
}
