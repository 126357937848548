import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { fetchArtist, viewPublisherStory } from '../../actions/ActionTypes';
import PublisherStoryCard from '../../components/Artist/PublisherStoryCard';
import ArtistDetail from '../../components/Artist/ArtistDetail';
import Utility from '../../utils/Utility';
import { LAYOUT } from '../../config/Constants';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import PageNotFound from '../../components/shared/PageNotFound';
import { AnalyticsUtilty } from '../../analytics';

class Artist extends Component {
  constructor(props) {
    super(props);
    const { route, layout } = props;
    this.layout = layout || (route.params?.display ?? LAYOUT.SCREEN);
    this.isLayoutRailOrGridOrList =
      this.layout === LAYOUT.RAIL ||
      this.layout === LAYOUT.GRID ||
      this.layout === LAYOUT.LIST;
    this.isLayoutScreen = this.layout === LAYOUT.SCREEN;
    this.state = {
      artistData: props.itemData,
      showShimmer: true,
      isPageLoading: true,
      serverError: false,
    };
  }

  componentDidMount = () => {
    if (this.isLayoutScreen) {
      this.hitArtistApi();
    }
  };

  artistApiCallback = (success, data, status) => {
    if (success) {
      this.setState({
        artistData: data,
        showShimmer: false,
      });
      setTimeout(this.fireListRenderedEvent, 1000);
      if (Utility.isBlank(data.objects) || data.objects.length === 0) {
        AnalyticsUtility.fireContentErrorAnalytics(
          data.id,
          data.name,
          'artist',
        );
      }
    } else {
      Utility.clearPageLoadTimer();
      this.setState({
        showShimmer: false,
        isPageLoading: status !== 404,
        serverError: status !== 404,
      });
    }
  };

  fireListRenderedEvent = () => {
    const { artistData } = this.state;
    Utility.fireListRenderedEvent(artistData, SCREEN_CONSTANTS.ARTIST_DETAILS);
  };

  hitArtistApi = () => {
    const { route, fetchArtist, itemData } = this.props;
    const slugString = route.params?.slug;
    AnalyticsUtilty.recordTime(
      {
        screen_name: SCREEN_CONSTANTS.ARTIST,
        ...Utility.setRecordTimeEventMeta(itemData, 'artist'),
      },
      this.artistApiCallback,
      fetchArtist,
      slugString,
    );
  };

  onStoryView = (artist) => {
    const { viewPublisherStory } = this.props;
    viewPublisherStory(artist);
  };

  render() {
    const { artistTypes, route } = this.props;

    const { artistData, showShimmer, isPageLoading, serverError } = this.state;

    if (!isPageLoading && Utility.isBlank(artistData)) {
      return <PageNotFound goToHome slug={route.params?.slug} />;
    }

    if (Utility.isBlank(artistData)) {
      if (this.isLayoutScreen) {
        return (
          <ArtistDetail
            layout={this.layout}
            showFullShimmer={showShimmer}
            isPageLoading={isPageLoading}
            serverError={serverError}
          />
        );
      }
      return null;
    }

    const {
      type,
      share_url,
      name,
      image_url,
      objects,
      instagram_url,
      youtube_url,
      instagram_handle,
      youtube_handle,
      banner_image_url,
      address,
      artist_type,
      followers_count,
      video_count,
      views_count,
      subscribers_count,
      bio = '',
      emoji,
      location,
      insta_posts_count,
      call_to_action,
      id,
      slug,
    } = artistData;

    const baseProps = {
      id,
      slug,
      type,
      share_url,
      name,
      image_url,
      objects,
      instagram_url,
      youtube_handle,
      youtube_url,
      instagram_handle,
      banner_image_url,
      address,
      artist_type,
      followers_count,
      video_count,
      views_count,
      subscribers_count,
      location,
      insta_posts_count,
      call_to_action,
      emoji,
      bio,
      artistTypes,
    };

    let filteredProps = {};
    if (this.isLayoutScreen) {
      if (Utility.isBlank(artistData)) {
        return <ArtistDetail {...baseProps} layout={this.layout} />;
      }
      return (
        <ArtistDetail
          {...artistData}
          layout={this.layout}
          showShimmer={showShimmer}
          hitArtistApi={this.hitArtistApi}
          artistTypes={artistTypes}
          isPageLoading={isPageLoading}
        />
      );
    }
    filteredProps = _.pick(baseProps, [
      'id',
      'name',
      'image_url',
      'slug',
      'layout',
      'objects',
    ]);
    return (
      <PublisherStoryCard
        {...filteredProps}
        layout={this.layout}
        onStoryView={this.onStoryView}
      />
    );
  }
}

const mapStateToProps = (store) => {
  return {
    artistTypes: store.UserAccountInfo.artistTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchArtist, viewPublisherStory }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Artist);
