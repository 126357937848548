// Dependencies
import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';

import _ from 'lodash';
import Images from '../../theme/Images';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import LinearGradient from 'react-native-linear-gradient';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
// import { urlForCurrentSession } from 'react-native-ux-cam';
import images from '../../theme/Images';
import {
  AnalyticsEvent,
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import { useSelector } from 'react-redux';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';

const Super = ({ values = [], principalAttributes }) => {
  if (Utility.isBlank(values)) {
    return null;
  }
  // const
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Image
        style={{
          height: 14,
          width: 14,
          overflow: 'visible',
          marginRight: 8,
        }}
        source={images.rating.filled_star}
      />
      <View style={{ flexDirection: 'row' }}>
        {_.map(values, (value, index) => {
          return (
            <Text
              style={{
                fontSize: 14,
                fontFamily: 'Roboto-Regular',
                color: colors.foxyBlack,
              }}
            >
              {`${principalAttributes[value]}${
                index < values.length - 1 ? ', ' : ' '
              }`}
            </Text>
          );
        })}
      </View>
    </View>
  );
};

const Indications = ({ values = [], principalAttributes }) => {
  if (Utility.isBlank(values)) {
    return null;
  }
  // const
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Image
        style={{
          height: 12,
          width: 14,
          overflow: 'visible',
          marginRight: 8,
        }}
        source={images.thumbsUpGreen}
      />
      <View style={{ flexDirection: 'row' }}>
        {_.map(values, (value, index) => {
          return (
            <Text
              style={{
                fontSize: 14,
                fontFamily: 'Roboto-Regular',
                color: colors.foxyBlack,
              }}
            >
              {`${principalAttributes[value]}${
                index < values.length - 1 ? ', ' : ' '
              }`}
            </Text>
          );
        })}
      </View>
    </View>
  );
};

const CounterIndications = ({ values = [], principalAttributes }) => {
  if (Utility.isBlank(values)) {
    return null;
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Image
        style={{
          height: 12,
          width: 14,
          overflow: 'visible',
          marginRight: 8,
        }}
        source={images.thumbsDownRed}
      />
      <View style={{ flexDirection: 'row' }}>
        {_.map(values, (value, index) => {
          return (
            <Text
              style={{
                fontSize: 14,
                fontFamily: 'Roboto-Regular',
                color: colors.foxyBlack,
              }}
            >
              {`${principalAttributes[value]}${
                index < values.length - 1 ? ', ' : ' '
              }`}
            </Text>
          );
        })}
      </View>
    </View>
  );
};

const ProductIngredients = (props) => {
  const {
    ingredients = [],
    navigation,
    productId,
    productPositiveIngredients,
    productNegativeIngredients,
    principalAttributes,
    productPage = {},
    sku_id,
  } = props;

  const facialAnalysis =
    useSelector((state) => state.UserAccountInfo.facialAnalysis) || {};
  const { my_attributes_values = [] } = facialAnalysis || {};

  if (Utility.isBlank(ingredients)) {
    return null;
  }

  const ingredient_for_product = Utility.findIndicationsAndCounterIndications(
    ingredients,
    my_attributes_values,
    principalAttributes,
  );

  if (Utility.isBlank(ingredient_for_product)) {
    return null;
  }
  return (
    <>
      <View
        style={{
          backgroundColor: colors.background,
          zIndex: 1,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontFamily: 'Roboto-Bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: colors.foxyBlack,
            paddingLeft: 12,
            paddingBottom: 4,
            paddingTop: isDesktop() ? 0 : 32,
          }}
        >
          {productPage?.ingredients?.heading}
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingRight: 12,
          paddingBottom: 10,
        }}
      >
        {Object.entries(ingredient_for_product).map(([key, value]) => {
          if (Utility.isBlank(key)) {
            return null;
          }
          return (
            <TouchableOpacity
              onPress={() => {
                AnalyticsManager.logEvent(
                  EventType.product.PRODUCT_ELEMENT_CLICK,
                  {
                    [EventParameterKey.SKU_ID]: sku_id,
                    [EventParameterKey.PRODUCT_ID]: productId,
                    [EventParameterKey.ELEMENT_NAME]: 'ingredients',
                  },
                );
                navigateToScreen({
                  navigation,
                  type: 'push',
                  screen: isWeb() ? 'Ingredients' : 'MoreItems',
                  params: {
                    slug: value?.slug,
                  },
                });
              }}
              style={{
                backgroundColor: colors.white,
                marginHorizontal: 12,
                marginTop: 8,
                padding: 12,
                borderRadius: 8,
                width: isDesktop() ? PRODUCT_RIGHT_CONTAINER_WIDTH - 12: Utility.getScreenWidth() - 24,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: 'Roboto-Bold',
                    fontStyle: 'normal',
                    color: colors.foxyBlack,
                    marginBottom: 4,
                  }}
                >
                  {key}
                </Text>

                <View>
                  <Super
                    values={value?.mySuper}
                    principalAttributes={principalAttributes}
                  />
                  <Indications
                    values={value?.myIndications}
                    principalAttributes={principalAttributes}
                  />
                  <CounterIndications
                    values={value?.myContraIndications}
                    principalAttributes={principalAttributes}
                  />
                </View>
              </View>
              <View>
                <Image
                  style={{
                    height: 32,
                    width: 32,
                    overflow: 'visible',
                    resizeMode: 'contain',
                  }}
                  source={{ uri: Utility.getMinifiedImage(value?.image) }}
                />
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    </>
  );
};

export default ProductIngredients;
