import { Dimensions, StatusBar } from 'react-native';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';

export const UPLOAD_POST = {
  height: {
    headerContainerInset: 40,
    postBasicInfoContainer: 124,
    contentCategoryContainer: 160,
    taggedProductsContainer: 48,
    postDescription: 100,
    editVideoContainer: 48,
    editSettingsContainer: 48,
    postCoverImageContainer: 140,
    get publishPostContainer() {
      return (
        Utility.screenHeight -
        (this.headerContainer +
          // + this.postBasicInfoContainer
          this.contentCategoryContainer +
          this.taggedProductsContainer +
          this.postDescription +
          // + this.editVideoContainer
          // + this.editSettingsContainer
          this.postCoverImageContainer)
      );
    },
  },
};

export const CONTENT_CATEGORY = {
  0: 'Skincare Rituals',
  1: 'Skincare Type',
  2: 'Skincare Condition',
  3: 'Haircare Type',
  4: 'Haircare Condition',
  5: 'Haircare Rituals',
  6: 'Home-made Remedies',
  7: 'Demos & Reviews',
  8: 'Makeup Tutorials',
  9: 'Complete Makeover',
  10: 'Looks',
  11: 'Fashion',
  12: 'HairStyle',
  13: 'HairCut by FaceType',
  14: 'Hair Texture',
  15: 'Hair Styling',
  16: 'Bridal',
  17: 'Beard',
};

export const CONTENT_SUBCATEGORY = {
  0: [
    'Waxing',
    'Threading',
    'Pedicure',
    'Manicure',
    'Exfoliation',
    'Facials',
    'Sun Protection',
  ],
  1: ['Dry', 'Oily', 'Normal', 'Combination'],
  2: [
    'Dehydration',
    'Sensitivity',
    'Pigmentation',
    'Ageing',
    'Acne',
    'Tanning',
  ],
  3: ['Straight', 'Wavy', 'Curly', 'Thick', 'Thin', 'Fine', 'Flat'],
};

export const UPLOADING_OPTIONS = {
  0: 'INSTAGRAM',
  1: 'FACEBOOK',
  2: 'WHATSAPP',
};

export const UPLOADING_OPTIONS_TITLE = {
  0: 'Add to your instagram story',
  1: 'Post on your facebook page',
  2: 'Share with friends on WhatsApp',
};
