import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableWithoutFeedback,
  StatusBar,
} from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import AppTrackingTransparencyManager from '../../utils/AppTrackingTransparency/AppTrackingTransparencyManager';
import FastImageView from '../FastImageView';
import { isNative } from '../../utils/BooleanUtility';

export default class SelfieAnalysis extends PureComponent {
  constructor() {
    super();
  }

  text = (props) => {
    const { text, style } = props;
    if (Utility.isBlank(text)) return null;
    return <Text style={style}>{text}</Text>;
  };

  retakeSelfiePill = () => {
    const { onRetakePress } = this.props;
    return (
      <TouchableWithoutFeedback onPress={onRetakePress}>
        <View style={styles.retakePill}>
          <this.text style={styles.pillText} text="Retake" />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  fullWidthImage = () => {
    return (
      <Image
        source={images.selfie_prompt_v2}
        style={styles.fullWidthImage}
        resizeMode="contain"
      />
    );
  };

  circleImage = () => {
    const { selfieImageUrl } = this.props;
    console.tron.log('LocalImageUrl', selfieImageUrl);
    return (
      <View style={styles.circleImage}>
        <Image source={{ uri: selfieImageUrl }} style={styles.circleImage} />
      </View>
    );
  };

  progressBar = () => {
    const { progressBarFill } = this.props;
    return (
      <View style={styles.progressBarWrapper} onPress={this.onProfileTap}>
        <AnimatedCircularProgress
          size={150}
          width={5}
          fill={progressBarFill}
          rotation={0}
          tintColor={colors.green}
          backgroundColor={colors.silverLit}
        />
      </View>
    );
  };

  circleImageWithProgressBar = () => {
    return (
      <>
        <this.circleImage />
        <this.progressBar />
      </>
    );
  };

  circleImageWithRetakeButton = () => {
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <this.circleImage />
        <this.retakeSelfiePill />
      </View>
    );
  };

  middleImageSection = () => {
    const { currentState, progressBarFill } = this.props;
    let Component = null;
    if (currentState === 'default') {
      Component = this.fullWidthImage;
    } else if (currentState === 'upload_error') {
      Component = this.circleImageWithRetakeButton;
    } else if (currentState === 'analysis_error' || currentState === 'login') {
      Component = this.circleImage;
    } else if (currentState === 'analysing') {
      Component = this.circleImageWithProgressBar;
    }
    const containerStyles =
      currentState === 'default'
        ? styles.imageContainerWithNoMargins
        : styles.imageContainer;
    return <View style={containerStyles}>{<Component />}</View>;
  };

  bottomTextSection = (props) => {
    const { title, description } = props;
    return (
      <View style={styles.bottomTextContainer}>
        <this.text style={styles.messageTitle} text={title} />
        <this.text style={styles.descriptionText} text={description} />
      </View>
    );
  };

  buttonContainer = () => {
    const { buttonTitle, onButtonPress } = this.props;
    return (
      <TouchableWithoutFeedback onPress={onButtonPress}>
        <View style={styles.buttonContainer}>
          <View style={styles.buttonLayout}>
            <View style={styles.cameraIconContainer}>
              {isNative() && (
                <FastImageView
                  source={images.take_selfie_camera_icon}
                  style={styles.cameraIconStyle}
                />
              )}
            </View>
            <this.text text={buttonTitle} style={styles.buttonText} />
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  statusBar = () => {
    if (Utility.isAndroid()) return null;
    return <StatusBar backgroundColor="transparent" bar-style="dark-content" />;
  };

  render() {
    const { messageTitle, messageDescription, hideButton } = this.props;

    if (Utility.isIOS()) {
      AppTrackingTransparencyManager.showAppTrackingPrompt();
    }

    return (
      <>
        <this.statusBar />
        <View style={styles.container}>
          <View style={styles.cardWrapper}>
            <this.middleImageSection />
            <View style={styles.bottomContainerStyle}>
              <this.text
                style={styles.headerText}
                text="Your personalised Home Page"
              />
              <this.bottomTextSection
                title={messageTitle}
                description={messageDescription}
              />
              {!hideButton && <this.buttonContainer />}
            </View>
          </View>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardWrapper: {
    height: 360,
    width: Utility.getScreenWidth() - 12,
    backgroundColor: colors.selfieCardBackground,
    borderRadius: 8,
    alignItems: 'center',
  },
  bottomContainerStyle: {
    alignItems: 'center',
    bottom: 16,
    alignContent: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  headerText: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    color: colors.darkPurpleText,
  },
  descriptionText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
    textAlign: 'center',
  },

  imageContainer: {
    marginTop: 28,
    marginBottom: 15,
  },

  imageContainerWithNoMargins: {},
  fullWidthImage: {
    height: 250,
    width: Utility.getScreenWidth() - 12,
  },

  circleImage: {
    height: 140,
    width: 140,
    borderRadius: 140 / 2,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
  },

  progressBarWrapper: {
    position: 'absolute',
    height: 140,
    width: 140,
    // bottom: Utility.isAndroid() ? 10 : 4,

    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },

  retakePill: {
    height: 28,
    width: 92,
    backgroundColor: colors.white,
    borderRadius: 14,
    borderWidth: 1,
    borderColor: colors.border,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
  },
  pillText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.foxyBlack,
  },
  messageTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.foxyBlack,
    // marginTop: 2,
    // marginBottom: 5,
  },
  bottomTextContainer: {
    paddingLeft: 15,
    paddingRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
  },
  buttonContainer: {
    marginTop: 10,
  },
  buttonLayout: {
    borderRadius: 4,
    backgroundColor: colors.black,
    height: Utility.getButtonHeight(),
    width: Utility.getScreenWidth() - 48,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    color: colors.white,
  },
  cameraIconContainer: {
    paddingRight: 8,
    paddingTop: 2,
  },
  cameraIconStyle: {
    width: 20,
    height: 20,
  },
});
