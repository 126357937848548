import React, { useEffect, useState } from 'react';
import { FlatList, ScrollView } from 'react-native';
import { useDispatch } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import {
  useAuthToken,
  useEmail,
  useHardwareBackKeyPress,
} from '../../lib/hooksUtil';
import { StaticNavigationHeader } from '../shared';
import OrderHistoryShimmer from '../shared/OrderHistoryShimmer';
import Utility from '../../utils/Utility';
import EmptyShopifyOrders from './EmptyShopifyOrders';
import LoginScreen from './LoginScreen';
import ShopifyOrderHistoryFooter from './ShopifyOrderHistoryFooter';
import {
  getShopifyOrdersHistory,
  verifyEmail,
} from '../../actions/ActionTypes';
import ShopifyOrderHistoryItem from './ShopifyOrderHistoryItem';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

export default function ShopifyOrderHistory() {
  const [showShimmer, setShowShimmer] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pastOrders, setPastOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [refreshPage, setRefreshPage] = useState(0);
  const [isListEnd, setIsListEnd] = useState(false);
  const [emailVerificationError, setEmailVerificationError] = useState(null);
  const authToken = useAuthToken();
  const dispatch = useDispatch();
  useHardwareBackKeyPress();
  const { emailVerified } = useEmail();
  const { params = {} } = useRoute();
  const { params: { email: emailId, email_token: emailToken } = {} } = params;


  useEffect(() => {
    if (emailVerified) {
      setRefreshPage((refresh) => refresh + 1);
    }
  }, [emailVerified]);

  useEffect(() => {
    if (emailId && emailToken) {
      dispatch(
        verifyEmail(emailId, emailToken, (success, response) => {
          if (success) {
            setPastOrders([]);
            setPage(0);
          }
          if (!success) {
            const { error } = response;
            if (error) {
              setEmailVerificationError(error);
            }
          }
        }),
      );
    }
  }, [emailId, emailToken]);

  useEffect(() => {
    if (!Utility.isBlank(authToken)) {
      if (page > 0) {
        setLoading(true);
      } else {
        setShowShimmer(true);
      }
      dispatch(
        getShopifyOrdersHistory(page, (success, response) => {
          if (success) {
            try {
              new JSONAPIDeserializer({
                typeAsAttribute: false,
                pluralizeType: true,
                keyForAttribute: 'camelCase',
              })
                .deserialize(response)
                .then((data) => {
                  if (Utility.isBlank(data)) {
                    setIsListEnd(true);
                  } else {
                    setIsListEnd(false);
                  }
                  setPastOrders((orders) => [...orders, ...data]);
                });
            } catch (e) {}
          }

          setLoading(false);
          setShowShimmer(false);
        }),
      );
    }
  }, [page, authToken, refreshPage]);

  if (Utility.isBlank(authToken)) {
    return <LoginScreen />;
  }
  if (showShimmer) {
    return <Shimmer />;
  }
  if (!pastOrders.length) {
    return (
      <>
        <StaticNavigationHeader title="Order History" />
        <EmptyShopifyOrders emailVerificationError={emailVerificationError} />
      </>
    );
  }

  const fetchMoreOrders = () => {
    if (!isListEnd && !loading && !showShimmer) {
      setPage((count) => count + 1);
    }
  };

  return (
    <ScrollView>
      <StaticNavigationHeader title="Order History" />
      <FlatList
        data={pastOrders}
        renderItem={({ item }) => <ShopifyOrderHistoryItem item={item} />}
        onEndReached={fetchMoreOrders}
        showsVerticalScrollIndicator={false}
        onEndReachedThreshold={0.3}
      />
      <ShopifyOrderHistoryFooter
        showLoader={loading}
        isListEnd={isListEnd}
        emailVerificationError={emailVerificationError}
      />
    </ScrollView>
  );
}

const Shimmer = () => (
  <>
    <StaticNavigationHeader title="Order History" />
    <OrderHistoryShimmer />
  </>
);
