import React from 'react';
import { View, Text, StyleSheet, PixelRatio } from 'react-native';
import Accordion from '../layout/Accordion';
import { PaymentStyles } from '../cart/styles';
import CardPayment from '../cart/CardPayment';
import NetBankingExpanded from '../cart/NetBankingExpanded';
import UpiPayment from '../cart/UpiPayment';
import PaytmWallet from '../cart/PaytmWallet';
import GooglePayPayment from '../cart/GooglePayPayment';
import CodPayment from '../cart/CodPayment';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { AnalyticsManager } from '../../analytics';
import { isDesktop } from '../../utils/BooleanUtility';
import RowAccordion from '../layout/RowAccordion';
import FastImageView from '../FastImageView';
import BlinkPayment from '../cart/BlinkPayment';

const PaymentComponent = {
  'Credit Card/Debit Card': CardPayment,
  NetBanking: NetBankingExpanded,
  UPI: UpiPayment,
  'PayTm Wallet': PaytmWallet,
  'Google Pay': GooglePayPayment,
  'Cash On Delivery': CodPayment,
  'Blink': BlinkPayment,
};

const PaymentOptionTitle = ({ item = {}, expandedStatus }) => {
  const devicePixelRatio = PixelRatio.get();
  const { image: itemUnselectedImage, imageSelected: itemSelectedImage } = item;
  const image =
    expandedStatus.includes(item.title) && expandedStatus.includes('expanded')
      ? itemSelectedImage?.uri || itemSelectedImage
      : itemUnselectedImage?.uri || itemUnselectedImage;
  const textStyle =
    expandedStatus.includes(item.title) && expandedStatus.includes('expanded')
      ? PaymentStyles.paymentTypeTextSelected
      : PaymentStyles.paymentTypeText;
  const minifiedImage = Utility.getMinifiedImage(
    image,
    PaymentStyles.paymentTypeImage.height * devicePixelRatio,
    PaymentStyles.paymentTypeImage.width * devicePixelRatio,
  );
  return (
    <View style={styles.titleContainer}>
      <View style={PaymentStyles.paymentOption}>
        <FastImageView
          source={minifiedImage}
          style={PaymentStyles.paymentTypeImage}
          resizeMode='contain'
        />
        <Text style={textStyle}>{item.header}</Text>
      </View>
    </View>
  );
};

const PaymentExpandableComponent = ({
  item,
  createOrder,
  updateExpandableComponent,
  metaData,
  supportedIOSIntents,
  renderLoader,
}) => {
  const Component = PaymentComponent[`${item?.title}`];

  if (!Component) return null;

  return (
    <Component
      createOrder={createOrder}
      updateExpandableComponent={updateExpandableComponent}
      metaData={metaData}
      supportedIOSIntents={supportedIOSIntents}
      renderLoader={renderLoader}
    />
  );
};

const AccordionType = isDesktop() ? RowAccordion : Accordion;

export default function PaymentOption({
  item,
  updateExpandableComponent,
  expandedStatus,
  createOrder,
  UtoP,
  metaData,
  paytmDetails,
  supportedIOSIntents,
  renderLoader,
  semi_digital_cart,
}) {
  const { isUpgradeToPrepaid = false } = UtoP;

  const hidePaymentOptions = Utility.jsonParser(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.hide_payment_options),
  );

  if (hidePaymentOptions[item.identifier]) {
    return null;
  }
  if (item.identifier === 'Cash' && isUpgradeToPrepaid) {
    return null;
  }

  if (item.identifier === 'Cash' && semi_digital_cart) {
    return null;
  }

  if (Utility.isPresent(paytmDetails) && item.identifier === 'PayTmWallet') {
    return null;
  }
  if (item.identifier === 'GPay' && Utility.isAndroid()) {
    return null;
  }
  if (
    Utility.isIOS() &&
    supportedIOSIntents.includes('gpay') &&
    item.title === 'Google Pay'
  ) {
    return null;
  }
  return (
    <AccordionType
      title={() => (
        <PaymentOptionTitle item={item} expandedStatus={expandedStatus} />
      )}
      expandableComponent={() => {
        return (
          <PaymentExpandableComponent
            item={item}
            createOrder={createOrder}
            updateExpandableComponent={updateExpandableComponent}
            metaData={metaData}
            supportedIOSIntents={supportedIOSIntents}
            renderLoader={renderLoader}
          />
        );
      }}
      item={item}
      updateExpandableComponent={updateExpandableComponent}
      expandedStatus={expandedStatus}
    />
  );
}
const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'column',
    borderTopWidth: 2,
    borderTopColor: colors.background,
  },
});
