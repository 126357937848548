import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import _ from 'lodash';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { AnalyticsManager } from '../../analytics';

class OffersInfo extends React.Component {
  onRemoveTap = () => {
    const { renderLoader = () => {}, removeOffer, couponCodes } = this.props;
    let prepaidOnlyCoupons = '';

    _.forEach(couponCodes, (code, index) => {
      prepaidOnlyCoupons = `${code?.coupon_code || ''}${
        index === couponCodes?.length - 1 ? ' ' : '& '
      }`;
    });
    renderLoader(true);
    removeOffer('remove_prepaid_only', () => {
      AnalyticsManager.logEvent('prepaid_ony_coupons_removed', {
        offer_ids: prepaidOnlyCoupons,
      });
      renderLoader(false);
    });
  };

  removeCouponsButton = () => {
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.onRemoveTap}
        hitSlope={Utility.getHitSlop()}
      >
        <Text style={styles.removeText}>Remove</Text>
      </DebouncedTouchableOpacity>
    );
  };

  render() {
    const { couponCodes, isStoreModeEnabled } = this.props;

    let prepaidOnlyCoupons = '';

    const paymentModeSuffix = isStoreModeEnabled
      ? 'on Pay at Store'
      : 'with cash on delivery';

    _.forEach(couponCodes, (code, index) => {
      prepaidOnlyCoupons = `${code?.coupon_code || ''}${
        index === couponCodes?.length - 1 ? ' ' : '& '
      }`;
    });
    return (
      <View style={styles.container}>
        <Text style={styles.textContainer}>
          {`${
            couponCodes?.length > 1 ? 'Coupons' : 'Coupon'
          } ${prepaidOnlyCoupons}is not applicable ${paymentModeSuffix}. `}
          <this.removeCouponsButton />
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', marginBottom: 12, flexWrap: 'wrap' },
  textContainer: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  removeText: {
    color: colors.cta.lightBlue,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    top: 5,
  },
});

export default OffersInfo;
