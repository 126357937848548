export default class StringUtils {
  static strikeThrough = (text) => {
    return text
      .split('')
      .map((char) => char + '\u0336')
      .join('');
  };

  static isEmojiPresent = (string) => {
    const pattern =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    return pattern.test(string);
  };
}
