const EventType = {
  FB: {
    EVENT_NAME_ADDED_TO_CART: 'fb_mobile_add_to_cart',
    EVENT_NAME_ADDED_TO_WISHLIST: 'fb_mobile_add_to_wishlist', // Verified; parent product doesn't have sku id
    EVENT_NAME_INITIATED_CHECKOUT: 'fb_mobile_initiated_checkout', // Verified; is not triggered after adding a new payment method, value_to_sum is zero
    EVENT_NAME_ADDED_PAYMENT_INFO: 'fb_mobile_add_payment_info', // Verified; value_to_sum should be set to cart value
    EVENT_NAME_COMPLETED_REGISTRATION: 'fb_mobile_complete_registration',
    EVENT_NAME_VIEWED_CONTENT: 'fb_mobile_content_view', // Verified; parent product doesn't have sku id
    EVENT_NAME_SEARCHED: 'fb_mobile_search',
  },
  appLifeCycleEvents: {
    appStart: 'app_start',
    app_detect: 'app_detect',
    APP_DETECT_FAILURE: 'app_detect_failure',
    INFO_DEVICE_MEMORY: 'info_device_memory',
    APP_FOREGROUND: 'app_in_foreground',
    APP_BACKGROUND: 'app_in_background',
    GET_FOXY_LIVE_APP: 'get_foxy_live_app',
    CALL_INITIATE: 'call_initiate',
    IMPRESSION: 'impression',
    EXCEPTION_CAPTURED: 'exception_captured',
    NOTIFICATION_STATUS: 'notification_status',
    NOTIFICATION_ACTION: 'notification_action',
  },
  registrationEvents: {
    loginComplete: 'login_complete',
  },
  playbackEvents: {
    playStart: 'play_start',
    playStatus: 'play_status',
    playStop: 'play_stop',
    playResume: 'play_resume',
    playPause: 'play_pause',
  },
  viewRelatedEvents: {
    screenVisible: 'screen_visible',
  },
  camera: {
    CAMERA_OPEN: 'camera_open',
    CAMERA_EXIT: 'camera_exit',
    FLASH_ON: 'flash_on',
    FLASH_OFF: 'flash_off',
    PHOTO_CLICK: 'photo_click',
    CAMERA_CLICK: 'camera_click',
    ITEM_SCAN: 'item_scan',
  },
  googleRemarketingEvents: {
    // Common events recommended for all apps
    LOGIN: 'login', // DONE when a user logs in. params: method
    // See PURCHASE and REFUND in the retail section below
    // PURCHASE: 'purchase', // when a user completes a purchase params: transaction_id, value, currency, tax, shipping, items, coupon
    // REFUND: 'refund', // when a user receives a refund transaction_id, value, currency, tax, shipping, items
    SEARCH: 'search', // DONE when a user searches your content search_term
    SELECT_CONTENT: 'select_content', // DONE when a user has selected content content_type, item_id
    SHARE: 'share', // DONE when a user has shared content content_type, item_id
    SIGN_UP: 'sign_up', // when a user has signed up. Allows you to see which methods of sign-up (e.g., Google account, email address, etc.) are most popular method

    // Events recommended for retail/e-commerce apps
    ADD_PAYMENT_INFO: 'add_payment_info', // when a user submits their payment information coupon, currency, items, payment_type, value
    ADD_SHIPPING_INFO: 'add_shipping_info', // DONE when a user submits their shipping information coupon, currency, items, shipping_tier, value
    ADD_TO_CART: 'add_to_cart', // DONE when a user adds items to cart currency, items, value
    ADD_TO_WISHLIST: 'add_to_wishlist', // DONE when a user adds items to a wishlist currency, items, value
    BEGIN_CHECKOUT: 'begin_checkout', // DONE when a user begins checkout coupon, currency, items, value
    PURCHASE: 'purchase', // DONE when a user completes a purchase affiliation, coupon, currency, items, transaction_id, shipping, tax, value (required parameter)
    REFUND: 'refund', // when a refund is issued affiliation, coupon, currency, items, transaction_id, shipping, tax, value
    REMOVE_FROM_CART: 'remove_from_cart', // DONE when a user removes items from a cart currency, items, value
    SELECT_ITEM: 'select_item', // DONE when an item is selected from a list items, item_list_name, item_list_id
    SELECT_PROMOTION: 'select_promotion', // when a user selects a promotion items, promotion_id, promotion_name, creative_name, creative_slot, location_id
    VIEW_CART: 'view_cart', // DONE when a user views their cart currency, items, value
    VIEW_ITEM: 'view_item', // when a user views an item currency, items, value
    VIEW_ITEM_LIST: 'view_item_list', // when a user sees a list of items/offerings items, item_list_name, item_list_id
    VIEW_PROMOTION: 'view_promotion', // when a promotion is shown to a user items, promotion_id, promotion_name, creative_name, creative_slot, location_id
    VIEW_SEARCH_RESULT: 'view_search_results',
  },
  onboardingEvent: {
    // Fired while setting up SMS retriever
    ONBOARDING_NOTIFICATION_LATER_PRESS: 'onboarding_notification_later_press',
    APP_KILL_LAST_STATUS: 'app_kill_last_status',
    FIRST_SCREEN_LAUNCH: 'first_screen_launch',
    APP_KILLED: 'app_killed',
    ANDROID_SMS_RETRIEVER_SETUP: 'android_sms_retriever_setup',
    ANDROID_SMS_RETRIEVER_ERROR: 'android_sms_retriever_error',
    TRUECALLER_MODAL_VIEW: 'truecaller_modal_view',

    // Fired while setting up SMS listeners
    ANDROID_SMS_LISTENER_SETUP: 'android_sms_listener_setup',
    ANDROID_SMS_LISTENER_ERROR: 'android_sms_listener_error',

    ANDROID_SMS_LISTENER_UNREGISTER: 'android_sms_listener_unregister',
    ANDROID_SMS_LISTENER_UNREGISTER_ERROR:
      'android_sms_listener_unregister_error',

    ANDROID_SMS_LISTENER_REGISTER_RETRY: 'android_sms_listener_register_retry',

    // When the video on the onboading screen starts playing
    ONBOARDING_VIDEO_LOAD: 'onboarding_video_load',

    // When GET started button loads and visible to user
    SCREEN_LOAD: 'screen_load',

    // When the video on the onboarding screen throw any error
    ONBOARDING_VIDEO_LOAD_ERROR: 'onboarding_video_load_error',

    ONBOARDING_VIDEO_PLAY: 'onboarding_video_play',

    // When user either clicks on the "Terms of Use" or "Privacy Policy"
    ONBOARDING_LINK_CLICK: 'onboarding_link_click',

    // When user clicks continue on the "Terms of Use" or "Privacy Policy" Page
    ONBOARDING_LINK_CONTINUE: 'onboarding_link_continue',

    // When user clicked on the "Get Started" button on onboarding screen
    ONBOARDING_START: 'onboarding_start',

    // When user views the hint request on the devices
    HINT_REQUEST_VIEW: 'hint_request_view',

    // When user views presses none on the hint request modal
    HINT_REQUEST_SKIP: 'hint_request_skip',

    // NEW: When user start Typing phone number,
    PHONE_NUMBER_INPUT_START: 'phone_number_input_start',

    // NEW:when user entered till 10th digit og phone
    PHONE_NUMBER_INPUT_END: 'phone_number_input_end',

    // NEW: When someone click cross button on phone number input field
    PHONE_NUMBER_CLEAR: 'phone_number_clear',

    // NEW: when user enter wrong number and see error on phone number input field
    PHONE_NUMBER_INPUT_ERROR: 'phone_number_input_error',

    // When user enters the phone number
    PHONE_NUMBER_INPUT: 'phone_number_input',

    TRUECALLER_ERROR: 'truecaller_error',

    // When user tap outside the phone numbr modal
    LOGIN_MODAL_SKIP_ATTEMPT: 'login_modal_skip_attempt',

    LOGIN_MODAL_SKIP: 'login_modal_skip',

    // when phone number change
    PHONE_NUMBER_CHANGE: 'phone_number_change',
    PHONE_NUMBER_INPUT_FOCUSED: 'phone_number_input_focused',

    // When OTP is not received by the user's device in 15 sec.
    OTP_DELAYED: 'otp_delay',

    // When user requests again for the OTP on the device
    OTP_REREQUEST: 'otp_rerequest',

    // When otp is detected on the device, logs time_taken
    OTP_DETECT: 'otp_detect',

    // When otp is filled on the device, logs input_method
    OTP_FILL: 'otp_fill',

    // When the user enters wrong OTP
    OTP_WRONG: 'otp_wrong',

    // When OTP is successfully verified, logs input_method
    ACCOUNT_LOGIN: 'account_login',

    SELFIE_PROMPT_VIEW: 'selfie_prompt_view',
    SELFIE_PROMPT_CONTINUE: 'selfie_prompt_continue',
    SELFIE_GET_SIZE_ERROR: 'selfie_get_size_error',
    SELFIE_CROPPING_ERROR: 'selfie_cropping_error',
    SELFIE_RESIZE_ERROR: 'selfie_resize_error',
    SELFIE_ENCODING_ERROR: 'selfie_encoding_error',
    IMAGE_MISSING_FACE: 'image_missing_face',
    AUTOCLICKED_IMAGE_UNPROCESSABLE: 'autoclicked_image_unprocessable',
    FIREBASE_FACE_DETECTION_ERROR: 'firebase_face_detection_error',

    // Whenever the camera is opened
    SELFIE_CAMERA_OPEN: 'selfie_camera_open',
    SELFIE_CAMERA_OPEN_FROM_REVIEW_RM: 'selfie_camera_open_from_rm',

    // When user clicks the selfie while onboarding, logs time_taken and input_method
    SELFIE_CLICK: 'selfie_click',

    // When app retry to upload selfie
    SELFIE_UPLOAD_RETRY: 'selfie_upload_retry',
    SELFIE_UPLOAD_RETRY_EXHAUSTED: 'selfie_upload_retry_exhausted',
    SELFIE_UPLOAD_ATTEMPT_FAILED: 'selfie_upload_attempt_failed',
    SELFIE_ANALYSIS_FAILURE: 'selfie_analysis_failure',
    SELFIE_ANALYSIS_STATE: 'selfie_analysis_state',
    SELFIE_REVIEW_MODAL_DISPLAY: 'selfie_review_modal_display',
    SELFIE_RETRY_UPLOAD_MODAL_DISPLAY: 'selfie_retry_upload_modal_display',
    SELFIE_RETRY_ANALYSIS_MODAL_DISPLAY: 'selfie_retry_analysis_modal_display',

    // When selfie is uploaded successfully, logs time_taken and response_status
    SELFIE_UPLOAD_SUCCESS: 'selfie_upload_success',

    // When selfie upload fails, logs time_taken, response_status (optional) and raw_error
    SELFIE_UPLOAD_FAILURE: 'selfie_upload_failure',

    // When selfie analysis is complete from the server and the local processing
    // of the response is completed logs gender, attribute count and time taken
    SELFIE_ANALYSIS_SUCCESS: 'selfie_analysis_success',

    ADVERTISING_ID_DETECTION_FAILURE: 'advertising_id_detection_failure',
    ADVERTISING_ID_DETECTION_BLANK: 'advertising_id_detection_empty',

    // whenever there is any message from face detection i.e TOO FAR, TOO CLOSE,
    // PERFECT, FACE NOT DETECTED
    FACE_DETECTION_MESSAGE: 'face_detection_message',
    // When there is an error on the API call for selfie review, logs time_taken and error
    SELFIE_ANALYSIS_ERROR: 'selfie_analysis_error',
    SELFIE_ANALYSIS_DELAY: 'selfie_analysis_delay', // Not implemented yet
    SELFIE_REVIEW_CLICK: 'selfie_review_click',
    SELFIE_RETAKE: 'selfie_retake',
    SELFIE_REVIEW_COMPLETE: 'selfie_review_complete',
    ATTRIBUTE_DETECT: 'attribute_detect',
    ATTRIBUTE_UPDATE: 'attribute_update',
    ATTRIBUTE_SELECT: 'attribute_select',
    NETWORK_INFO: 'network_info',
    FND_IMAGE_UPLOAD: 'fnd_image_uploaded',
    FND_IMAGE_UPLOAD_FAILURE: 'fnd_image_upload_failure',
    SELFIE_ANALYSIS_REATTEMPT: 'selfie_analysis_reattempt',
    SELFIE_ANALYSIS_RETRY: 'selfie_analysis_retry',
    ONBOARDING_SKIP: 'onboarding_skip',
    SKIP_COUNTER_START: 'login_skip_counter_start',
    SKIP_COUNTER_FINISH: 'login_skip_counter_finish',
    SKIP_COUNTER_RESET: 'login_skip_counter_reset',
    SKIP_BUTTON_VISIBLE: 'login_skip_button_visible',
    SKIP_BUTTON_HIDDEN: 'login_skip_button_hidden',
    SELFIE_REVIEW_MODAL_DISMISS: 'selfie_review_modal_dismiss',
    SELFIE_RETRY_UPLOAD_MODAL_DISMISS: 'selfie_retry_upload_modal_dismiss',
    SELFIE_RETRY_ANALYSIS_MODAL_DISMISS: 'selfie_retry_analysis_modal_dismiss',
    SCREEN_GLOW_ON: 'screen_glow_on',
    SCREEN_GLOW_OFF: 'screen_glow_off',
    SCREEN_FLASH_ON: 'screen_flash_on',
    LOGIN_INITIATE: 'login_initiate',
    ACTIVITY_INDICATOR_DISMISS: 'activity_indicator_dismiss',
    FORCE_LOGGED_OUT_TRIGGER: 'force_logged_out_trigger',
    ONBOARDING_NOTIFICATION_VISIBLE: 'onboarding_notification_visible',
    UAC_DEVICE_SCORE: 'uac_device_score',
    UAC_COMMERCE_APPS_SCORE: 'uac_commerce_apps_score',
    UAC_BEAUTY_APPS_SCORE: 'uac_beauty_apps_score',
    UAC_GENDER_SCORE: 'uac_gender_score',
    UAC_SELFIE_SCORE: 'uac_selfie_score',
    UAC_NEW_SCORE: 'uac_new_score',
    UAC_NEW_SCORE_T: 'uac_new_score_t',
    UAC_GENDER_SCORE_ERROR: 'uac_gender_score_error',
    SELFIE_PROMPT_SKIP: 'selfie_prompt_skip',
    SELFIE_INITIATE: 'selfie_initiate',
    SELFIE_ANALYSIS_INITIATE: 'selfie_analysis_initiate',
  },
  artistEvents: {
    IS_ARTIST: 'is_artist',
    ARTIST_SIGNUP_START: 'artist_signup_start',
    ARTIST_TYPE_SELECT: 'artist_type_select',
    ARTIST_SIGNUP_CLOSE: 'artist_signup_close',
    ARTIST_SIGNUP_RESUME: 'artist_signup_resume',
    BRAND_LIKE: 'brand_like',
    YOUTUBE_CONNECT_INITIATE: 'youtube_connect_initiate',
    YOUTUBE_CONNECT_COMPLETE: 'youtube_connect_complete',
    YOUTUBE_CONNECT_CANCEL: 'youtube_connect_cancel',
    INSTAGRAM_CONNECT: 'instagram_connect',
    ARTIST_SIGNUP_END: 'artist_signup_end',
    DASHBOARD_CLICK: 'dashboard_click',
    BOOST_REQUEST: 'boost_request',
    REVIEW_REQUEST: 'review_request',
    INSTAGRAM_VERIFY_INITIATE: 'instagram_verify_initiate',
    INSTAGRAM_VERIFY_COMPLETE: 'instagram_verify_complete',
    CAMPAIGN_VIEW: 'campaign_view',
    CAMPAIGN_DETAIL: 'campaign_detail',
    CAMPAIGN_REGISTRATION_REQUESTED: 'campaign_registration_requested',
    CAMPAIGN_REGISTERATION_APPROVED: 'campaign_registeration_approved',
    CAMPAIGN_REGISTERATION_DECLINED: 'campaign_registeration_declined',
    CAMPAIGN_VIDEO_SUBMITTED: 'campaign_video_submitted',
    CAMPAIGN_CASH_REQUESTED: 'campaign_cash_requested',
    CAMPAIGN_GIFT_REQUESTED: 'campaign_gift_requested',
    CAMPAIGN_CASH_DISBURSED: 'campaign_cash_disbursed',
    GALLERY_OPEN: 'gallery_open',
    VIDEO_UPLOAD_START: 'video_upload_start',
    VIDEO_UPLOAD_FINISH: 'video_upload_finish',
    VIDEO_UPLOAD_FAILURE: 'video_upload_failure',
    VIDEO_APPROVED: 'video_approved',
    VIDEO_REJECTED: 'video_rejected',
    MODAL_VIEW: 'modal_view',
    MODAL_ACTION: 'modal_action',
    MODAL_CLOSE: 'modal_close',
  },
  discoveryEvents: {
    PERSONALISED_CARD_VIEW: 'personalised_card_view',
    PRODUCT_VIEW_USER_ENGAGEMENT: 'product_view_user_engagement',
    ACTIVATED_USER: 'activated_user',
    HOMEPAGE_VIEW: 'homepage_view',
    NON_PERSONALISED_HOMEPAGE_REQUEST: 'non_personalised_homepage_request',
    ITEM_CLICK: 'item_click',
    LIST_ITEM_VIEW: 'list_item_view',
    LIST_ITEM_CLICK: 'list_item_click',
    FEED_VIEW: 'feed_view',
    OFFERS_VIEW: 'offers_view',
    MYKIT_VIEW: 'mykit_view',
    ARTIST_VIEW: 'artist_view',
    BRAND_VIEW: 'brand_view',
    CART_VIEW: 'cart_view',
    LIST_DETAIL_VIEW: 'list_detail_view',
    MEDIA_CATEGORY_VIEW: 'media_category_view',
    PRODUCT_CATEGORY_VIEW: 'product_category_view',
    PRODUCT_VIEW: 'product_view',
    VARIANT_VIEW: 'variant_view',
    PRODUCT_ADDED_TO_CART: 'product_add_to_cart',
    PRODUCT_REMOVE_FROM_CART: 'product_remove_from_cart',
    ITEM_LIKE: 'item_like',
    ITEM_UNLIKE: 'item_unlike',
    ARTIST_FOLLOW: 'artist_follow',
    ARTIST_UNFOLLOW: 'artist_unfollow',
    PRODUCT_VARIANT_VIEW: 'product_variant_view',
    LIST_VIEW: 'list_view',
    COUPON_TOAST: 'coupon_toast',
    DYNAMIC_CARD_DISMISS: 'dynamic_card_dismiss',
    TAB_VIEW: 'tab_view',
    TAB_CLICK: 'tab_click',
    PAYOFF_ACTION: 'payoff_action',
    FEATURE_ACTION_CLICK: 'feature_action_click',
    NOTIFICATION_CENTER: 'notification_center',
    PAGE_VIEW: 'page_view',
    ITEM_ACTION: 'item_action',
    CATEGORY_VIEW: 'category_view',
    FILTER_ACTION: 'filter_action',
    FOOTER_BAG_BUTTON_CLICK: 'footer_bag_button_click',
  },
  search: {
    SEARCH_OPEN: 'search_open',
    SEARCH_INITIATE: 'search_initiate',
    SEARCH_COMPLETE: 'search_complete',
    SEARCH_RESULT_CLICK: 'search_result_click',
    SEARCH_AUTOCOMPLETE_CLICK: 'search_autocomplete_click',
    SEARCH_ABANDON: 'search_abandon',
    SEARCH_MORE_CLICK: 'search_more_click',
    SEARCH_RESULT_EMPTY: 'search_result_empty',
    SEARCH_TOGGLE_BUTTON_CLICK: 'search_toggle_button_click',
  },
  videoEvents: {
    VIDEO_OPEN: 'video_open',
    VIDEO_START: 'video_start',
    VIDEO_ENGAGE: 'video_engage',
    VIDEO_SCRUB: 'video_scrub',
    VIDEO_CHANGE: 'video_change',
    VIDEO_STOP: 'video_stop',
    VIDEO_COMPLETE: 'video_complete',
    VIDEO_END: 'video_end',
    VIDEO_ARTIST_CLICK: 'video_artist_click',
    VIDEO_ARTIST_FOLLOW: 'video_artist_follow',
    VIDEO_ERROR: 'video_error',
    VIDEO_BUFFERING_START: 'video_buffering_start',
    VIDEO_BUFFERING_STOP: 'video_buffering_stop',
    VIDEO_CTA_CLICK: 'video_cta_click',
  },
  profileEvents: {
    NAME_UPDATE: 'name_update',
    PROFILE_PICTURE_UPDATE: 'profile_picture_update',
    EMAIL_UPDATE: 'email_update',
    ADDRESS_ADD: 'address_add',
    ADDRESS_UPDATE: 'address_update',
    ADDRESS_DELETE: 'address_delete',
    ADDRESS_MODAL_VIEW: 'address_modal_view',
    ADDRESS_ERROR: 'address_error',
    ADDRESS_INITIATE: 'address_initiate',
    ADDRESS_INPUT_START: 'address_input_start',
    EDIT_ADDRESS_USER_BACK: 'edit_address_user_back',
    PIN_CODE_INPUT: 'pin_code_input',
    EMAIL_ADD: 'email_add',
    EMAIL_ERROR: 'email_error',
    PROFILE_ANALYSIS_CLICK: 'profile_analysis_click',
    OTHER_ACTIONS_CLICK: 'other_actions_click',
    ADDRESS_SUGGESTION_SELECTED: 'address_suggestion_selected',
    EDIT_WISHLIST: 'edit_wishlist',
    WISHLIST_ICON_CLICK: 'wishlist_icon_clicked',
    HEART_ICON_VIEW: 'heart_icon_view',
    tooltip_view: 'tooltip_view',
    tooltip_action: 'tooltip_action',
    ACCOUNT_DELETE: 'account_delete',
    BIRTH_DATE_ERROR: 'birth_date_error',
  },
  miscAppEvents: {
    APP_SHARE: 'app_share',
    APP_LOAD: 'app_load',
    ITEM_SHARE: 'item_share',
    PERMISSION_ALLOW: 'permission_allow',
    PERMISSION_DENY: 'permission_deny',
    NETWORK_TYPE: 'network_type',
    PERMISSION_PROMPT: 'permission_prompt',
    SETTINGS_PROMPT: 'settings_prompt',
  },
  errorEvents: {
    IMAGE_ERROR: 'image_error',
    CONTENT_ERROR: 'content_error',
    VARIANT_ERROR: 'variant_error',
    DESCRIPTION_ERROR: 'description_error',
    PAGE_LOAD_DELAY: 'page_load_delay',
    PAGE_LOAD_ERROR: 'page_load_error',
    CARD_RENDER_ERROR: 'card_render_error',
  },

  myProfile: {
    PROFILE_VIEW: 'profile_view',
    PROFILE_COMPLETE_INITIATE: 'profile_complete_initiate',
  },

  payment: {
    PAYMENT_METHOD_CLICK: 'payment_method_click',
    PAYMENT_METHOD_VIEW: 'payment_method_view',
    PAYMENT_INITIATE: 'payment_initiate',
    PAYMENT_FAILURE: 'payment_failure',
    PAYMENT_SUCCESS: 'payment_success',
    PAYMENT_CANCEL: 'payment_cancel',
    INITIATE_CHECKOUT: 'initiate_checkout',
    CONTACTLESS_CHECKED: 'contactless_checked',
    CONTACTLESS_UNCHECKED: 'contactless_unchecked',
    PAYMENT_METHOD_UPGRADE: 'payment_method_upgrade',
    OPT_FOR_PLASTIC_FREE_PACKAGING_CHECKED:
      'opt_for_plastic_free_packaging_checked',
    OPT_FOR_PLASTIC_FREE_PACKAGING_UNCHECKED:
      'opt_for_plastic_free_packaging_unchecked',
    PAYMENT_ACTION: 'payment_action',
    PAYMENT_RESPONSE: 'payment_response',
    RETRY_PAYMENT: 'retry_payment',
  },
  order: {
    ORDER_CREATE: 'order_create',
    ORDER_CONFIRM: 'order_confirm',
    DELIVERY_TYPE: 'delivery_type',
    MODAL_VIEW: 'modal_view',
    MODAL_CLOSE: 'modal_close',
    APP_REVIEW: 'app_review',
    ORDER_CREATE_ERROR: 'order_create_error',
  },
  product: {
    PRICE_CLICK: 'price_click',
    PRODUCT_DESCRIPTION_EXPANDED: 'product_description_expanded',
    PRODUCT_DESCRIPTION_COLLAPSED: 'product_description_collapsed',
    RETURN_POLICY_CLICK: 'return_policy_click',
    PRICE_COMPARISON_CLICK: 'price_comparison_click',
    AUTHENTICITY_CLICK: 'authenticity_click',
    STOCK_ERROR: 'stock_error',
    DESCRIPTION_DETAIL: 'description_detail',
    DESCRIPTION_EXPAND: 'description_expand',
    DESCRIPTION_COLLAPSED: 'description_collapsed',
    PRODUCT_ELEMENT_VIEW: 'product_element_view',
    PRODUCT_ELEMENT_CLICK: 'product_element_click',
    SET_ALERT: 'set_alert',
  },
  offer: {
    CART_OFFER_CLICK: 'cart_offer_click',
    OFFER_APPLIED: 'offer_applied',
    OFFER_APPLICATION_FAILURE: 'offer_application_failure',
    FREE_SURPRISE_CLICKED: 'free_surprise_clicked',
    OFFER_REMOVE: 'offer_remove',
    OFFER_SCROLL: 'offer_scroll',
    PROMPT_VISIBLE: 'prompt_visible',
    PROMPT_ACTION_CLICK: 'prompt_action_click',
    VIEW_TNC_CLICK: 'view_tnc_click',
    CLOSE_TNC_CLICK: 'close_tnc_click',
    MODAL_CLOSE: 'modal_close',
    OFFER_BANNER_CLICK: 'offer_banner_click',
    OFFER_SUCCESS_MODAL_VIEW: 'offer_success_modal_view',
    OFFER_SUCCESS_MODAL_DISMISS: 'offer_success_modal_dismiss',
    OFFER_SUCCESS_MODAL_CTA_CLICK: 'offer_success_modal_cta_click',
    PROMPTS_VISIBLE: 'prompts_visible',
    OFFER_ERROR_MODAL: 'offer_error_modal',

  },
  deeplink: {
    DEEP_LINK_OPEN: 'deep_link_open',
  },
  pageLoad: {
    PAGE_LOAD: 'page_load',
    PAGE_LOAD_2: 'page_load_2',
  },
  forceUpdate: {
    FORCE_UPDATE_POPUP_VIEW: 'force_update_popup_view',
    FORCE_UPDATE_CONTINUE: 'force_update_continue',
    IN_APP_CAMPAIGN_SHOWN: 'in_app_campaign_shown',
    IN_APP_CAMPAIGN_DISMISS: 'in_app_campaign_dismiss',
    IN_APP_CAMPAIGN_CLICKED: 'in_app_campaign_clicked',
    IN_APP_CAMPAIGN_CUSTOM_ACTION: 'in_app_campaign_custom_action',
  },
  notificationModal: {
    NOTIFICATION_PERMISSION_MODAL: 'notification_permission_modal',
  },
  panZoom: {
    ZOOM_MODAL_CLICK: 'zoom_modal_click',
    ZOOMING: 'zooming',
    ZOOM_MODAL_OPEN: 'zoom_modal_open',
    ZOOM_MODAL_CLOSE: 'zoom_modal_close',
  },

  filters: {
    QUICK_FILTER_APPLY: 'quick_filter_apply',
    FILTER_MODAL_OPEN: 'filter_modal_open',
    FILTER_VALUE_SELECT: 'filter_value_select',
    FILTER_VALUE_UNSELECT: 'filter_value_unselect',
    FILTER_APPLY: 'filter_apply',
    FILTER_RESET: 'filter_reset',
    QUICK_FILTER_REMOVE: 'quick_filter_remove',
    FILTER_RESULT_EMPTY: 'filter_result_empty',
  },
  verticalVideoCard: {
    VERTICAL_VIDEO_CARD: 'vertical_video_card',
  },
  storyEvents: {
    STORY_OPEN: 'story_open',
    STORY_LIST_LOAD: 'story_list_load',
  },
  account_settings: {
    DELETE_SELFIE: 'delete_selfie',
    DELETE_SELFIE_INITIATE: 'delete_selfie_initiate',
    DELETE_ACCOUNT_INITIATE: 'delete_account_initiate',
    CONTACT_US: 'contact_us',
    SELFIE_ANALYSIS_CLICK: 'selfie_analysis_click',
    DELETE_ATTRIBUTES_CHECKED: 'delete_attribute_checked',
    DELETE_ATTRIBUTES_REASON: 'delete_selfie_reason',
  },
  review_upload: {
    MEDIA_SELECT: 'media_select',
    MEDIA_REMOVE: 'media_remove',
    MEDIA_UPLOAD_START: 'media_upload_start',
    MEDIA_UPLOAD_FINISH: 'media_upload_finish',
    MEDIA_UPLOAD_FAILED: 'media_upload_failed',
    MEDIA_UPLOAD_RETRY: 'media_upload_retry',
  },
  contacts: {
    CONTACT_SEARCH: 'contact_search',
    CONTACT_SELECT: 'contact_select',
    CONTACT_DESELECT: 'contact_deselect',
  },
  invitations: {
    INVITATION_SENT: 'invitation_sent',
    GROUP_DEAL_CREATE: 'group_deal_create',
    REFERRAL_CREATE: 'referral_create',
    INVITATION_SHARE: 'invitation_share',
    INVITATION_ACTION: 'invitation_action',
  },
  salon: {
    PAY_ON_FOXY_CLICK: 'pay_on_foxy_click',
    PERMISSION_MODAL: 'permission_modal',
    CLAIM_REWARD_CLICK: 'claim_reward',
    SPIN_THE_WHEEL_CLICK: 'spin_the_wheel_click',
    PAY_BILL_BUTTON_CLICK: 'pay_bill_button_click',
  },
  MODAL_ACTION: 'modal_action',
  influencer: {
    BIO_PAGE_VIEW: 'bio_page_view',
    INTRO_PLAY_VIDEO: 'intro_video_play',
    INFLUENCER_LINK_CLICK: 'influencer_link_click',
  },
  ERROR_EVENTS: {
    RETRY: 'retry_button_action',
    GO_TO_HOME: 'go_to_home_button_action',
  },
  rewards: {
    CLAIM_REWARD_CLICK: 'claim_reward_click',
    CLAIM_REWARD_SUCCESS: 'claim_reward_success',
    CLAIM_REWARD_FAILURE: 'claim_reward_failure',
    CARD_ACTION: 'card_action',
  },
  RETAIL_EVENTS: {
    STORE_BUTTON_CLICK: 'store_button_click',
  },
  routine: {
    ROUTINE_SUBSCRIBE: 'routine_subscribe',
    ROUTINE_VIEW: 'routine_view',
    ITEM_CLICK: 'item_click',
    ROUTINE_CARD_ACTION: 'item_click', // This is changed to item_click as per doc
  },
  EDGE: {
    MEMBERSHIP_ACTIVATED: 'membership_activated',
    EDGE_ACTIVE: 'edge_active',
    EDGE_BUYER: 'edge_buyer',
  },
  firebaseWeb: {
    SCREEN_VIEW: 'screen_view',
  },
  GTM: {
    ADD_TO_CART: 'addToCart',
    REMOVE_FROM_CART: 'removeFromCart',
    TRANSACTION: 'transaction',
    PURCHASE: 'purchase',
    VIEW_ITEM: 'view_item',
    VIEW_ITEM_LIST: 'view_item_list',
    VIEW_SEARCH_RESULT: 'view_search_results',
    ADD_TO_CART_EVENT: 'add_to_cart',
    CHECKOUT: 'checkout',
    PRODUCT_CLICK: 'productClick',
    PROMOTION_CLICK: 'promotionClick',
  },
  MISC_WEB_EVENTS: {
    WEB_LOAD: 'web_load',
    APP_ONLY_OFFER_MODAL_VIEW: 'app_only_offer_modal_view',
    APP_ONLY_OFFER_MODAL_CTA_CLICK: 'app_only_offer_modal_cta_click',
    APP_ONLY_OFFER_MODAL_DISMISS: 'app_only_offer_modal_dismiss',
    GET_APP_CLICK: 'get_app_click',
    LOGIN_CLICK: 'login_click', // Desktop top bar login click
    WEB_TO_APP_BANNER_DISMISS: 'web_to_app_banner_dismiss',
    WEB_TO_APP_BANNER_VIEW: 'web_to_app_banner_view',
    HEADER_CLICK: 'header_click',
    BACK_BUTTON: 'back_button',
  },
  FB_PIXEL: {
    AddToCart: 'AddToCart',
    InitiateCheckout: 'InitiateCheckout',
    Purchase: 'Purchase',
    Search: 'Search',
    ViewContent: 'ViewContent',
  },
  INFLUENCER: {
    ONELINK_LANDING_PAGE_VIEW: 'onelink_landing_page_view',
    GET_STARTED_CLICK: 'get_started_click',
    ADD_SOCIAL_MEDIA_ACCOUNTS_PAGE_VIEW: 'add_social_media_accounts_page_view',
    SUBMIT_SOCIAL_MEDIA_ACCOUNTS: 'submit_social_media_accounts',
    IMPORT_FROM_LINKTREE_MODAL_VIEW: 'import_from_linktree_modal_view',
    IMPORT_FROM_LINKTREE_SUBMIT: 'import_from_linktree_submit',
    UPDATE_PROFILE_INFORMATION_PAGE_VIEW: 'update_profile_information_page_view',
    PROFILE_PIC_UPLOAD: 'profile_pic_upload',
    SUBMIT_PROFILE_INFORMATION: 'submit_profile_information',
    UPLOAD_INTRO_VIDEO_PAGE_VIEW: 'upload_intro_video_page_view',
    INTRO_VIDEO_UPLOAD: 'intro_video_upload',
    SKIP_INTRO_VIDEO: 'skip_intro_video',
    INSTAGRAM_VERIFICATION_PAGE_VIEW: 'instagram_verification_page_view',
    COPY_ONELINK: 'copy_onelink',
    COPY_ONELINK_REFERRAL: 'copy_onelink_referral',
    GO_TO_INSTAGRAM_CLICK: 'go_to_instagram_click',
    VERIFY_INSTAGRAM_CLICK: 'verify_instagram_click',
    INSTAGRAM_VERIFICATION_FAILED: 'instagram_verification_failed',
    INSTAGRAM_VERIFICATION_SUCCESS: 'instagram_verification_success',
    BIO_PAGE_VIEW: 'bio_page_view',
    INTRO_VIDEO_PLAY: 'intro_video_play',
    INFLUENCER_LINK_CLICK: 'influencer_link_click',
    INFLUENCER_DASHBOARD_VIEW: 'influencer_dashboard_view',
    DASHBOARD_EDIT_PROFILE_CLICK: 'dashboard_edit_profile_click',
    DASHBOARD_SHARE_PROFILE_CLICK: 'dashboard_share_profile_click',
    DASHBOARD_INVITE: 'dashboard_invite',
    PRO_LINK_MODAL_VIEW: 'pro_link_modal_view',
    PRO_LINK_ADD: 'pro_link_add',
    PRO_LINK_DELETE: 'pro_link_delete',
    USER_SHARE_ONELINK: 'user_share_onelink',
    SKIP_INSTAGRAM_VERIFICATION: 'skip_instagram_verification',
  },
};

export default EventType;
