// Dependencies
import React, { Component } from 'react';
import {
  View,
  TouchableWithoutFeedback,
  Image,
  Text,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import { LAYOUT } from '../../config/Constants';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { playlistStyle } from '../media/Style';
import { navigateToScreen } from '../../utils/NavigationUtility';

class MoreOptions extends Component {
  navigateToProduct = (product) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'navigate',
      screen: 'Product',
      params: {
        id: product.id,
        slug: product.slug,
        display: LAYOUT.SCREEN,
        itemData: product,
      },
    });
  };

  navigateToMorePage = () => {
    const {
      navigation,
      listId,
      listName,
      previousScreen,
      listIndex,
      listSlug,
    } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'MoreItems',
      params: {
        id: listId,
        heading: listName,
        navigation,
        title: listName,
        previousScreen,
        index: listIndex,
        slug: listSlug,
      },
    });
  };

  product = (props) => {
    const styles = playlistStyle;
    const { product, index, totalProducts } = props;
    if (Utility.isBlank(product)) {
      return null;
    }
    let imageUrl = '';
    try {
      imageUrl = product.images.length > 0 ? product.images[0] : '';
    } catch {}

    if (index > 2) {
      return null;
    }

    return (
      <TouchableOpacity onPress={this.navigateToMorePage}>
        <Image
          source={{ uri: Utility.getSmallImageUrl(imageUrl, 120, 120) }}
          style={styles.similarProductCircles}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const styles = playlistStyle;
    const { products } = this.props;

    return (
      <TouchableWithoutFeedback onPress={this.navigateToMorePage}>
        <View style={style.container}>
          <View style={styles.horizontalProductUsedContainer}>
            <View style={styles.productUsedTextContainer}>
              <Text
                style={{
                  fontFamily: 'Roboto-Bold',
                  fontSize: 12,
                  fontStyle: 'normal',
                  color: colors.silver,
                }}
              >
                Similar
              </Text>
            </View>
            <View
              style={{
                height: 52,
                marginLeft: 14,
                flexDirection: 'row-reverse',
                alignItems: 'center',
              }}
            >
              {products.map((product, index) =>
                index < 6 ? (
                  <this.product
                    key={`more_options_${product.id}`}
                    product={product}
                    index={index}
                    totalProducts={products.length}
                    id={product.id}
                    products={products}
                  />
                ) : null,
              )}
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const style = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    borderTopWidth: 1,
    position: 'absolute',
    bottom: 2,
    right: 16,
    width: Utility.getScreenWidth() - 172,
  },
});

export default withNavigation(MoreOptions);
