import React, { useEffect, useState, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import CouponCodePill from '../cart/CouponCodePill';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import ScaleAnimate from '../shared/ScaleAnimate';
import { styles } from './styles';

/**  FIXME: LOT INLINE FUNCTIONS to be removed. Should be converted to PureComponent.
 * BEING USED AT PRODUCT_PAGE/CART/INVITE CENTRE/CART_PROMPT.
 * REFACTORING/TEST EFFORT : HIGH */
const SlimOfferCoupon = (props) => {
  const {
    coupon,
    description,
    couponCode,
    navigation,
    promptLoading = false,
    removeOffer,
    couponLayoutWidth,
    setCouponLayoutWidth,
    onCtaPress,
    showCopyButton,
    enableGrayscale,
    clipperColorLeft = colors.white,
    clipperColorRight = colors.white,
    borderRadius = 2,
  } = props;

  if (Utility.isBlank(description)) {
    return null;
  }

  const couponCodePillWidth = Utility.isPresent(couponLayoutWidth)
    ? { width: couponLayoutWidth }
    : {};

  const Icon = () => {
    if (showCopyButton) {
      return <Image source={images.copyButton} style={styles.copyButton} />;
    }
    return couponCode.status === 'active' ||
      couponCode.status === 'initialized' ? (
      <>
        {!promptLoading ? (
          <Image source={images.rightGrayChevron} style={styles.chevron} />
        ) : null}
      </>
    ) : (
      <View style={styles.crossContainer}>
        {!promptLoading ? (
          <Image source={images.success} style={styles.cross} />
        ) : null}
      </View>
    );
  };

  const containerStyle =
    couponCode.status === 'active' || couponCode.status === 'initialized'
      ? styles.promptContainerCta
      : [styles.promptContainer, { borderRadius }];

  const pillStyle =
    couponCode.status === 'active' || couponCode.status === 'initialized'
      ? styles.pillStyleCta
      : styles.pillStyle;
  return (
    <ScaleAnimate
      onPress={() =>
        onCtaPress(
          couponCode.cta_url,
          couponCode.offer_id,
          navigation,
          couponCode,
        )
      }
    >
      <View
        style={
          enableGrayscale
            ? [
                containerStyle,
                {
                  backgroundColor: '#F8F8F8',
                  borderColor: '#F8F8F8',
                },
              ]
            : containerStyle
        }
      >
        {promptLoading ? (
          <View
            onLayout={(e) => {
              if (
                e.nativeEvent.layout.width > couponLayoutWidth &&
                couponLayoutWidth < 80
              ) {
                setCouponLayoutWidth(e.nativeEvent.layout.width);
              }
            }}
            style={[couponCodePillWidth, { height: 22 }]}
          >
            <CouponCodePill
              couponCode={coupon}
              style={
                enableGrayscale
                  ? [pillStyle, { backgroundColor: '#F8F8F8' }]
                  : pillStyle
              }
            />
          </View>
        ) : (
          <View
            onLayout={(e) => {
              if (
                e.nativeEvent.layout.width > couponLayoutWidth &&
                couponLayoutWidth < 80
              ) {
                setCouponLayoutWidth(e.nativeEvent.layout.width);
              }
            }}
            style={[couponCodePillWidth, { height: 22 }]}
          >
            <CouponCodePill
              couponCode={coupon}
              // couponCodeStatus={couponCode.status}
              style={
                enableGrayscale
                  ? [
                      pillStyle,
                      {
                        backgroundColor: '#F2F2F2',
                        borderColor: '#F2F2F2',
                      },
                    ]
                  : pillStyle
              }
            />
          </View>
        )}
        <View style={styles.descriptionContainer}>
          {!promptLoading ? (
            <Text style={styles.descriptionText} numberOfLines={2}>
              {description}
            </Text>
          ) : (
            <ShimmerPlaceHolder
              autoRun
              style={styles.descriptionShimmer}
              colorShimmer={['#01B46012', '#eeeeee', '#01B46012']}
            />
          )}
        </View>

        <Icon />
        <View
          style={[
            styles.couponCodeLeftCutClipperSmall,
            {
              backgroundColor: clipperColorLeft,
              borderColor: clipperColorLeft,
            },
          ]}
        />
        <View
          style={[
            styles.couponCodeRightCutClipperSmall,
            {
              backgroundColor: clipperColorRight,
              borderColor: clipperColorRight,
            },
          ]}
        />
      </View>
    </ScaleAnimate>
  );
};

export default SlimOfferCoupon;
