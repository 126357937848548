import { Fragment } from 'react';

export {
  TouchableOpacity,
  TouchableWithoutFeedback,
  ScrollView,
  FlatList,
  TouchableHighlight,
  TextInput,
} from 'react-native';

export const TapGestureHandler = Fragment;
export const PanGestureHandler = Fragment;
export const State = {};
