import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../theme/Colors';

class NotificationCount extends PureComponent {
  render() {
    const { count } = this.props;
    if (count === 0) {
      return null;
    }
    return (
      <View style={styles.countContainer}>
        <Text style={styles.countText}>{count}</Text>
      </View>
    );
  }
}

export default NotificationCount;

const styles = StyleSheet.create({
  countContainer: {
    height: 18,
    width: 18,
    backgroundColor: colors.notificationCountPink,
    borderRadius: 9,
    justifyContent: 'center',
    alignItems: 'center',
  },
  countText: {
    color: colors.white,
    fontFamily: 'Roboto-Regular',
    fontSize: 9,
  },
});
