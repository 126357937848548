import React, { PureComponent } from 'react';
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
} from 'react-native';

import Utility from '../../../../utils/Utility';

import FastImageView from '../../../../components/FastImageView';
import colors from '../../../../theme/Colors';
import images from '../../../../theme/Images';
import size from '../../../../theme/Fonts';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';

class USNRemovalModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: -1,
    };
  }

  componentDidMount() {
    const { usns = [] } = this.props;
    if (Utility.isPresent(usns) && usns.length === 1) {
      this.setState({ selectedIndex: 0 });
    }
  }

  selectUSN = (index) => {
    this.setState({ selectedIndex: index });
  };

  USN = ({ item = '', index }) => {
    const isSelected = this.state.selectedIndex === index;
    const container = isSelected
      ? styles.selectedUSNContainer
      : styles.USNItemContainer;

    const onPress = () => {
      this.selectUSN(index);
    };
    return (
      <TouchableOpacity onPress={onPress} style={container}>
        {isSelected && (
          <Image
            style={styles.greenTick}
            source={images.greenCircularTick}
            resizeMode='contain'
          />
        )}
        <FastImageView
          style={styles.image}
          source={this.props.image}
          resizeMode='contain'
        />
        <View style={styles.idContainer}>
          <FastImageView
            source={images.retailStore.qrIcon}
            style={styles.idIcon}
          />
          <Text style={styles.idText}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  onRemove = () => {
    const {
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      usns,
      closeModal,
    } = this.props;
    const { selectedIndex } = this.state;
    if (selectedIndex === -1) return;
    const data = {
      usn: usns[selectedIndex],
      quantity: -1,
      source: 'cart',
    };

    incrementDecrementCartBeforeCall(data);
    incrementDecrementCart(data, (response) => {});
    closeModal();
  };

  render() {
    const { isVisible, usns, closeModal } = this.props;
    if (Utility.isBlank(usns) || !isVisible) {
      return null;
    }

    return (
      <FoxyAlert
        isVisible={isVisible}
        height={Utility.getScreenHeight() / 3}
        alertBoxTitle='Remove item from Bag ?'
        alertMessage={`P.S : Please make sure you match the code before removing`}
        firstButtonTitle='Remove'
        firstButtonOnPress={this.onRemove}
        firstButtonTextColor={colors.disabled}
        showSecondButton
        secondButtonTitle={'Keep'}
        secondButtonTextColor={colors.cta.lightBlue}
        secondButtonOnPress={closeModal}
        onTapOutside={closeModal}
        autoWrapContent
      >
        <FlatList
          data={usns}
          horizontal
          contentContainerStyle={styles.listContainer}
          renderItem={this.USN}
          ItemSeparatorComponent={() => <View style={{ width: 12 }} />}
          showsHorizontalScrollIndicator={false}
        />
      </FoxyAlert>
    );
  }
}

export default USNRemovalModal;

const styles = StyleSheet.create({
  listContainer: {
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  USNItemContainer: {
    padding: 12,
    borderWidth: 1.5,
    borderRadius: 4,
    borderColor: colors.grey,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedUSNContainer: {
    padding: 12,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: colors.green,
    alignItems: 'center',
    justifyContent: 'center',
  },
  greenTick: {
    height: 15,
    width: 15,
    position: 'absolute',
    top: 6,
    left: 6,
    zIndex: 1,
  },
  idContainer: {
    backgroundColor: '#E4F2FF',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    marginTop: 8,
    paddingHorizontal: 10,
    paddingVertical: 2,
    flexDirection: 'row',
  },
  image: { height: 80, width: 80 },
  idIcon: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
    opacity: 0.7,
  },
  idText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4_5,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 4,
  },
});
