import { StyleSheet } from 'react-native';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';

const PagerStyles = StyleSheet.create({
  dotStyle: {
    width: 6,
    height: 6,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    backgroundColor: '#4E4E4E',
    opacity: 0.6,
  },

  activeDotStyle: {
    width: 6,
    height: 6,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    backgroundColor: 'white',
    opacity: 1,
    borderWidth: 0.5,
    borderColor: '#4E4E4E',
  },

  dotStyleBottom: {
    width: 32,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    backgroundColor: '#F19E54',
    opacity: 0.6,
  },

  dotStyleFeature: {
    width: 6,
    height: 6,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#4E4E4E',
    opacity: 0.6,
    marginTop: 8,
  },

  activeDotStyleFeature: {
    width: 6,
    height: 6,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    marginTop: 8,
    backgroundColor: 'white',
    opacity: 1,
    borderWidth: 0.5,
    borderColor: '#4E4E4E',
  },

  paginationStyle: {
    position: 'absolute',
    left: null,
    right: 20,
    top: 8,
    bottom: null,
  },

  bottomPaginationStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },

  pageContainer: {
    marginHorizontal: Utility.padding,
    marginVertical: 8,
    width: Utility.getScreenWidth() - 2 * Utility.padding,
    height: (1 / 3) * (Utility.getScreenWidth() - 24),
  },
  pageImageStyle: {
    flex: 1,
    height: 100,
    resizeMode: 'stretch',
  },
  pageContainerFeature: {
    marginVertical: 8,
    width: Utility.getScreenWidth(),
    height: (Utility.getScreenWidth() - 24) / 1.3 + 3.3,
  },
  pageContainerFeatureLargeBanner: {
    marginVertical: 8,
    width: Utility.getScreenWidth(),
    height: (Utility.getScreenWidth() - 24) / 1.25,
  },
  pageContainerFeatureDesktop: {
    marginVertical: 8,
    width: Utility.getScreenWidth(),
    height: (Utility.getScreenWidth() - 24) / 1.3 / 2 + 3.3,
  },
  pageContainerFeatureDesktopLargeBanner: {
    marginVertical: 8,
    width: Utility.getScreenWidth(),
    height: (Utility.getScreenWidth() - 24) / 1.25 / 2,
  },
  pagerBottomContainerOverFlow: {
    marginVertical: 8,
    width: Utility.getScreenWidth(),
    height: 140,
  },
  pagerBottomContainerFeature: {
    marginVertical: 8,
    width: Utility.getScreenWidth(),
    height: (Utility.getScreenWidth() - 24) / 1.5,
  },
  pageContainerRating: {
    marginTop: 8,
    width: Utility.getScreenWidth(),
    height: 220,
  },

  dotStyleBottomPager: {
    width: 6,
    height: 6,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: colors.white,
    opacity: 1,
    borderWidth: 0.5,
    borderColor: colors.matterhornGrey,
  },
});

export default PagerStyles;
