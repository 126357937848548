import PropTypes from 'prop-types';

class AnalyticsEvent {
  constructor({ eventType = 'none', meta = {}, isForcedPriority = false }) {
    this.eventType = eventType;
    this.meta = meta;
    this.isForcedPriority = isForcedPriority;
  }
}

AnalyticsEvent.propTypes = {
  isForcedPriority: PropTypes.bool,
};

export default AnalyticsEvent;
