import { View, Image, TextInput, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import images from '../../theme/Images';
import {
  getEmptySearchData,
  getSearchAutoSuggest,
} from '../../actions/ActionTypes';
import SearchSuggestionContainer from './SearchSuggestionContainer';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { AnalyticsManager, EventType } from '../../analytics';

export default function SearchInput({ navigation }) {
  const dispatch = useDispatch();
  const textInputRef = useRef(null);
  const [searchQuery, setInputText] = useState('');
  const [searchSuggestResults, setSearchSuggestResults] = useState([]);
  const [showSearchSuggest, setShowSearchSuggest] = useState(false);

  useEffect(() => {
    dispatch(getEmptySearchData(() => {}));
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      if (!textInputRef.current.contains(event.target)) {
        hideSearchSuggest(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const navigateToSearch = () => {
    hideSearchSuggest();
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Search',
      params: { q: searchQuery },
    });
  };

  const onTextChange = (value) => {
    setInputText(value);
    if (value.length > 2) {
      fetchSearchSuggestions();
    } else {
      setSearchSuggestResults([]);
    }
  };

  const fetchSearchSuggestions = () => {
    dispatch(
      getSearchAutoSuggest(encodeURIComponent(searchQuery), (callbackData) => {
        const { suggestions = [] } = callbackData;
        setSearchSuggestResults(suggestions);
      }),
    );
  };

  const showsSearchSuggest = () => setShowSearchSuggest(true);
  const hideSearchSuggest = () => setShowSearchSuggest(false);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.searchBar}>
          <Image
            source={{ uri: images.desktop.searchIcon }}
            style={styles.image}
          />
          <View style={{ flex: 1, overflow: 'hidden' }}>
            <TextInput
              ref={textInputRef}
              style={styles.textInput}
              placeholder='Search products, videos, artists, etc...'
              onChangeText={onTextChange}
              onSubmitEditing={navigateToSearch}
              onFocus={showsSearchSuggest}
            />
          </View>

          <SearchSuggestionContainer
            suggestions={searchSuggestResults}
            showSearchSuggest={showSearchSuggest}
            searchQuery={searchQuery}
            navigation={navigation}
            hideSearchSuggest={hideSearchSuggest}
          />
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    lineHeight: 1.5,
    textAlign: 'center',
  },
  searchBar: {
    flexDirection: 'row',
    backgroundColor: '#F7F7F7',
    height: 32,
    width: 360,
    alignItems: 'center',
    borderRadius: 18,
    textAlign: 'center',
  },
  image: {
    height: 16,
    width: 16,
    marginHorizontal: 12,
    color: '#173143e8',
    fontSize: 20,
    lineHeight: 24,
  },
  textInput: {
    fontSize: 12,
    backgroundColor: 'transparent',
    paddingRight: 10,
    width: '100%',
  },
});
