import initialState from './initialState';
import Utility from '../utils/Utility';

import {
  REMOVE_BLUE_CIRCLE,
  HIDE_STORIES_INTRO,
  APPEND,
  VIEWED_TOPIC,
} from '../actions/StoriesAction';
import { LOGOUT_USER } from '../actions/ActionTypes';

export default function stories(state = initialState.stories, action) {
  switch (action.type) {
    case REMOVE_BLUE_CIRCLE:
      return {
        ...state,
        viewedStories: [...state.viewedStories, action.id],
      };
    case HIDE_STORIES_INTRO:
      return {
        ...state,
        hideStoriesIntro: true,
      };
    case APPEND:
      return {
        ...state,
        append: true,
      };
    case VIEWED_TOPIC:
      let viewedVideos = state.viewedTopics[action.topicId]
      if (Utility.isBlank(viewedVideos)) {
        viewedVideos = [];
      }
      if (!viewedVideos.includes(action.videoId)) {
        viewedVideos.push(action.videoId)
        state.viewedTopics[action.topicId] = viewedVideos;
      }
      return {
        ...state,
      };
    case LOGOUT_USER:
      return {
        ...state,
        viewedStories: [],
        viewedTopics: {},
      };
    default:
      return state;
  }
}
