import React from 'react';
import { useSwipeable } from 'react-swipeable';

const GestureRecognizer = ({ onSwipeUp, onSwipeDown, children }) => {
  const handlers = useSwipeable({
    onSwipedUp: onSwipeUp,
    onSwipedDown: onSwipeDown,
  });

  return <div {...handlers}>{children}</div>;
};

export default GestureRecognizer;
