// Dependencies
import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Modal from 'react-native-modal';
import _ from 'lodash';
import { ProductDetailStyles } from './styles';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { withEither, withMaybe } from '../../lib/Monads';
import { MAX_LINES, CURRENCY_SYMBOL } from '../../config/Constants';

class ShareModal extends Component {
  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  discountConditionFn = (props) =>
    parseFloat(props.sp) === parseFloat(props.mrp);

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  closeModal = () => {
    this.props.hideShareModal();
  };

  onSocialOptionPress = (socialMediaType) => {
    const { onSocialShareOptionTapped } = this.props;
    onSocialShareOptionTapped(socialMediaType);
    this.closeModal();
  };

  showProductDescFn = (props) => props.isProduct === true;

  productDescription = (props) => {
    const styles = ProductDetailStyles;
    const { sp = '', mrp = '', brand = '', discount = '' } = props;
    return (
      <View style={{ justifyContent: 'space-around', overflow: 'hidden' }}>
        <Text
          numberOfLines={MAX_LINES.single}
          style={styles.brandNameInPreview}
        >
          {brand?.name?.toUpperCase()}
        </Text>
        <Text numberOfLines={MAX_LINES.single} style={styles.nameInPreview}>
          {props.name.replace(`${brand.name} `, '')}
        </Text>
        <View style={styles.previewPriceContainer}>
          <Text style={styles.previewSpStyle}>{`${CURRENCY_SYMBOL}${sp} `}</Text>
          <this.conditionalPriceText
            style={styles.previewMrpStyle}
            sp={sp}
            mrp={mrp}
          >
            {`${CURRENCY_SYMBOL}${mrp}`}
          </this.conditionalPriceText>
          <this.conditionalDiscountText
            style={styles.previewDiscountRateStyle}
            discount={discount}
          >
            {`(${discount})`}
          </this.conditionalDiscountText>
        </View>
      </View>
    );
  };

  previewDescription = (props) => {
    const { name = '', subHeading = '' } = props;
    const styles = ProductDetailStyles;
    return (
      <View style={{ justifyContent: 'space-around', overflow: 'hidden' }}>
        <Text numberOfLines={MAX_LINES.single} style={styles.nameInPreview}>
          {name}
        </Text>
        <Text numberOfLines={MAX_LINES.single} style={styles.nameInPreview}>
          {subHeading}
        </Text>
      </View>
    );
  };

  description = withEither(
    this.showProductDescFn,
    this.productDescription,
  )(this.previewDescription);

  socialShareOption = (props) => {
    const styles = ProductDetailStyles;
    const { socialMediaType, name } = props;
    return (
      <TouchableOpacity
        style={styles.socialShareContainer}
        onPress={() => this.onSocialOptionPress(socialMediaType)}
      >
        <Image
          source={images.socialShareIcons[socialMediaType]}
          style={styles.socialImage}
        />
        <Text style={styles.socialMediaTypeText}>{name}</Text>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      isShareModalVisible,
      image,
      isProduct = false,
      name,
      sp,
      mrp,
      discount,
      brand,
      subHeading,
    } = this.props;
    const styles = ProductDetailStyles;
    return (
      <Modal
        isVisible={isShareModalVisible}
        onBackdropPress={this.closeModal}
        style={styles.shareModal}
        swipeDirection="down"
        onSwipeComplete={this.closeModal}
      >
        <View style={styles.shareModalContainer}>
          <View style={styles.offerTermsHeading}>
            <Text style={styles.offerTermsHeadingText}>Share</Text>
          </View>
          <View style={styles.previewContainer}>
            {/* <FastImage
              source={{ uri: Utility.getMinifiedImage(image, 200, 200) }}
              style={styles.previewImage}
              resizeMode='contain'
            /> */}
            <View style={styles.previewDescriptionContainer}>
              <this.description
                isProduct={isProduct}
                name={name}
                sp={sp}
                mrp={mrp}
                discount={discount}
                brand={brand}
                subHeading={subHeading}
              />
            </View>
          </View>
          <View
            style={[
              styles.offerActionButtonsContainer,
              { marginBottom: Utility.bottomInset },
            ]}
          >
            <View style={styles.shareOptionsContainer}>
              <this.socialShareOption
                socialMediaType="whatsapp"
                name="WhatsApp"
              />
              <this.socialShareOption
                socialMediaType="instagram"
                name="Instagram"
              />
              <this.socialShareOption
                socialMediaType="facebook"
                name="Facebook"
              />
              <this.socialShareOption socialMediaType="copy" name="Copy" />
              <this.socialShareOption socialMediaType="more" name="More" />
            </View>
          </View>
          <TouchableOpacity
            hitSlop={Utility.getHitSlop()}
            onPress={this.closeModal}
            style={styles.offerCancelButtonView}
          >
            <Image
              style={{ height: 12, width: 12, tintColor: colors.black }}
              source={images.cross}
            />
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }
}

ShareModal.propTypes = {
  isShareModalVisible: PropTypes.bool,
  image: PropTypes.string,
  isProduct: PropTypes.bool,
  name: PropTypes.string,
  sp: PropTypes.number,
  mrp: PropTypes.number,
  discount: PropTypes.string,
  brand: PropTypes.string,
  subHeading: PropTypes.string,
  onSocialShareOptionTapped: PropTypes.func,
};

ShareModal.defaultProps = {
  isShareModalVisible: false,
  image: null,
  isProduct: false,
  name: '',
  sp: null,
  mrp: null,
  discount: '',
  brand: '',
  subHeading: '',
  onSocialShareOptionTapped: () => {},
};

export default ShareModal;
