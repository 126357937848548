import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { CURRENCY_SYMBOL } from '../../../config/Constants';
import colors from '../../../theme/Colors';

export default function PaymentDetails({ orderItems = [], shippingCharges }) {
  return (
    <View style={styles.paymentDetailsContainer}>
      <View>
        {orderItems.map((orderItem = {}) => (
          <OrderItem {...orderItem} key={orderItem.name} />
        ))}
      </View>

      <View style={styles.discountContainer}>
        <Text style={styles.discountText} numberOfLines={1}>
          Shipping Charges
        </Text>
        <Text style={styles.discount}>
          {shippingCharges <= 0 ? 'FREE' : `${CURRENCY_SYMBOL}${shippingCharges}`}
        </Text>
      </View>
    </View>
  );
}

const OrderItem = ({ quantity, name, esp }) => (
  <View style={styles.orderItemContainer}>
    <Text
      style={styles.orderItem}
      numberOfLines={1}
    >
      {`${quantity} x ${name}`}
    </Text>
    {esp === 'FREE' ? (
      <Text style={styles.orderItemPrice}>{esp}</Text>
    ) : (
      <Text style={styles.orderItemPrice}>
        {`${CURRENCY_SYMBOL}${esp * (quantity || 1) || 0}`}
      </Text>
    )}
  </View>
);

const styles = StyleSheet.create({
  orderItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 14,
  },
  orderItem: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    width: '80%',
  },
  orderItemPrice: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  paymentDetailsContainer: {
    backgroundColor: colors.white,
  },
  discountContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 14,
  },
  discountText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    width: '80%',
  },
  discount: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
});
