import {
  View,
  TouchableOpacity,
  Text,
  Linking,
} from 'react-native';
import React from 'react';
import StyleSheet from 'react-native-media-query';
import { useNavigation, useRoute } from '@react-navigation/native';
import colors from '../../theme/Colors';
import { APP_DOWNLOAD_LINK, FOXY_URLS } from '../../config/Constants';
import AnalyticsManager from '../../analytics/AnalyticsManager';
import { EventType } from '../../analytics';
import { isWebAndroid, isWebIOS } from '../../utils/BooleanUtility';

export default function ApplyOnAppOfferModal() {
  const navigation = useNavigation();
  const { params = {} } = useRoute();
  const { couponCode, message, offerId } = params;
  const closePrompt = () => {
    AnalyticsManager.logEvent(EventType.MISC_WEB_EVENTS.APP_ONLY_OFFER_MODAL_DISMISS, {
      event_id: offerId,
    });
    navigation.pop();
  };

  const handlePress = () => {
    AnalyticsManager.logEvent(EventType.MISC_WEB_EVENTS.APP_ONLY_OFFER_MODAL_CTA_CLICK, {
      event_id: offerId,
      text: 'Apply offer on app',
    });
    closePrompt();
    AnalyticsManager.logEvent(EventType.MISC_WEB_EVENTS.GET_APP_CLICK, { location: location.pathname });
    if (isWebIOS() || isWebAndroid()) {
      Linking.openURL(FOXY_URLS.appOnlyOffers);
    } else {
      Linking.openURL(APP_DOWNLOAD_LINK);
    }
  };

  return (
    <>
      <TouchableOpacity onPress={closePrompt} style={styles.touchableContainer} />
      <View style={styles.modalContainer} pointerEvents="box-none">
        <View style={styles.bottomModal} dataSet={{ media: ids.bottomModal }}>
          <Text style={styles.title}>{`${couponCode} can only be applied on app`}</Text>
          <Text style={styles.subtitle}>{message}</Text>
          <TouchableOpacity onPress={closePrompt} style={styles.closeButton}>
            <Text style={styles.closeButtonText}>No, I don't want this offer</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.primaryButton} onPress={handlePress}>
            <Text style={styles.primaryButtonText}>Apply offer on app</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
}

const { ids, styles } = StyleSheet.create({
  container: { backgroundColor: 'white', flex: 1 },
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.translucent,
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 1,
    padding: 20,
    paddingBottom: 24,
    '@media (min-width: 800px)': {
      top: '50%',
      left: '50%',
      bottom: 'auto',
      right: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: 16,
      maxWidth: 470,
    },
  },
  title: {
    fontSize: 18,
    fonWeight: '700',
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    marginTop: 10,
  },
  subtitle: {
    marginTop: 8,
    fontSize: 16,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
  },
  closeButton: {
    border: '2px solid black',
    borderRadius: 4,
    paddingVertical: 11,
    marginTop: 20,
    alignItems: 'center',
  },
  closeButtonText: {
    fontSize: 16,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
  },
  primaryButton: {
    border: '2px solid black',
    borderRadius: 4,
    paddingVertical: 12,
    marginTop: 12,
    marginBottom: 8,
    alignItems: 'center',
    backgroundColor: colors.black,
  },
  primaryButtonText: {
    fontSize: 16,
    color: colors.white,
    fontFamily: 'Roboto-Regular',
  },
});
