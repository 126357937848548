import { takeEvery, takeLatest } from 'redux-saga/effects';
import { URL } from '../config/Constants';
import {
  CREATE_GROUP_DEAL,
  FETCH_GROUP_DEALS,
  PATCH_GROUP_DEAL_INVITE,
  PATCH_GROUP_DEAL,
  ADD_TO_GROUP_DEAL,
} from '../actions/ReferralActions';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';

const inviteStatusConfig = {
  true: 'accepted',
  false: 'rejected',
  reinvite: 'reinvite',
};

function* createGroupDealsAsync(action) {
  const {
    skuId = '',
    contacts = [],
    callback = () => {},
    hostId = '',
  } = action;
  const type = Utility.isBlank(skuId) ? 'free_gift' : 'absolute';
  const contactsForInviting = [];

  if (Utility.isPresent(contacts) && contacts.length > 0) {
    contacts?.forEach((contact) => {
      const obj = {};
      obj.name = contact.displayName;
      obj.phone_number = contact.phoneNumber;
      obj.country_code = contact.cc;
      contactsForInviting.push(obj);
    });
  }

  const group_deal_invitations =
    Utility.isBlank(skuId) && Utility.isPresent(hostId)
      ? [{ host_public_id: hostId }]
      : contactsForInviting;

  const postData = {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: JSON.stringify({
      sku_id: skuId,
      group_deal_type: type,
      group_deal_invitations,
    }),
  };

  const response = yield fetch(URL.GROUP_DEALS_V2, postData);
  const json = yield convertJsonFromResponse(response);
  if (response.status >= 200 && response.status < 300) {
    callback(true, json);
  } else if (response.status === 422) {
    try {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(false, json);
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `GroupDealSaga.js:createGroupDealsAsync() ${error}`,
        },
      );
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

function* fetchGroupDealsAsync(action) {
  const { callback = () => {} } = action;
  const postData = {
    method: 'GET',
    headers: yield getApiHeaders(),
  };

  const response = yield fetch(`${URL.GROUP_DEALS_V2}`, postData);
  if (response.status >= 200 && response.status < 300) {
    try {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `GroupDealSaga.js:fetchGroupDealsAsync() ${error}`,
        },
      );
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

function* patchGroupDealInvitationAsync(action) {
  const { callback = () => {}, id, isAccepted } = action;
  const status = inviteStatusConfig[isAccepted];
  const postData = {
    method: 'PATCH',
    headers: yield getApiHeaders(),
    body: JSON.stringify({
      group_deal_invitation: {
        status,
      },
    }),
  };
  const response = yield fetch(`${URL.ACCEPT_GROUP_DEALS}${id}`, postData);
  if (response.status >= 200 && response.status < 300) {
    try {
      const json = yield response.json();
      callback(true, json);
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `GroupDealSaga.js:patchGroupDealInvitationAsync() ${error}`,
        },
      );
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

function* patchGroupDealAsync(action) {
  const { callback = () => {}, groupId } = action;
  const postData = {
    method: 'PATCH',
    headers: yield getApiHeaders(),
  };
  const response = yield fetch(`${URL.GROUP_DEALS_V2}${groupId}`, postData);
  try {
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      const json = yield response.json();
      callback(false, json);
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: `GroupDealSaga.js:patchGroupDealAsync() ${error}`,
    });
    callback(false, {});
  }
}

function* addToGroupDealsAsync(action) {
  const { id, callback = () => {} } = action;
  const postData = {
    method: 'PATCH',
    headers: yield getApiHeaders(),
    body: JSON.stringify({
      group_deal: {
        status: 'add',
      },
    }),
  };

  const response = yield fetch(`${URL.GROUP_DEALS}${id}`, postData);
  try {
    const json = yield convertJsonFromResponse(response);
    //  201: SPECIALLY SPECIFIED BY BACKEND FOR SHOWING THE SUCCESS MODAL
    if (response.status === 201) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else if (response.status === 422) {
      //  422 :SPECIALLY SPECIFIED BY BACKEND FOR SHOWING THE ERROR MODAL
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(false, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: `GroupDealSaga.js:addToGroupDealsAsync() ${error}`,
    });
    callback(false, {});
  }
}

export default function* watchGroupDealsSaga() {
  yield takeLatest(CREATE_GROUP_DEAL, createGroupDealsAsync);
  yield takeLatest(FETCH_GROUP_DEALS, fetchGroupDealsAsync);
  yield takeLatest(PATCH_GROUP_DEAL_INVITE, patchGroupDealInvitationAsync);
  yield takeLatest(PATCH_GROUP_DEAL, patchGroupDealAsync);
  yield takeLatest(ADD_TO_GROUP_DEAL, addToGroupDealsAsync);
}
