// Dependencies
import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Config from '../../libraries/ReactNativeConfig';
import { debounce } from 'lodash';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import styles from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import {
  showImageProgressBar,
  showPopover,
  getUserFacialAttributes,
  selfieAnalysisState,
  imageUploadPercentage,
} from '../../actions/LoginActions';
import colors from '../../theme/Colors';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FastImageView from '../FastImageView';
import { MoEReactInbox } from '../../libraries/ReactMoe';
import AppConfig from '../../config/AppConfig';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';

class TabNavigationLeftHeader extends Component {
  constructor() {
    super();
    this.state = {
      showPopover: false,
      popoverMessage: '',
      popoverActionsButtonText: '',
      showNotificationIndication: false,
    };

    this.isErrorSet = false;
    this.isFeedTab = false;
    this.errorWhileTakingSelfie = false;

    this.debouncedProfileNavigate = debounce(this.onProfileTap, 1000, {
      leading: true,
      trailing: false,
    });
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.onFocus();
    });
  }

  componentDidUpdate(prevProps) {
    const {
      selfieImageUrl,
      authToken,
      facialAnalysis: { uploadPercentage, currentState, showPopOver },
      navigation,
    } = this.props;

    if (
      Utility.invokeSelfiePromptAfterLogin &&
      Utility.isBlank(prevProps.authToken) &&
      Utility.isPresent(authToken)
    ) {
      if (Utility.isBlank(selfieImageUrl)) {
        navigation.navigate(SCREEN_CONSTANTS.SELFIE_INTRO);
        Utility.invokeSelfiePromptAfterLogin = false;
      }
    }

    if (
      prevProps.facialAnalysis.uploadPercentage !== 100 &&
      uploadPercentage === 100
    ) {
      this.hideProgressBar();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onFocus = () => {
    this.setNotificationCount();
  };

  setNotificationCount = async () => {
    if (Utility.isWeb()) return;
    const count = (await MoEReactInbox.getUnClickedCount()) || 0;
    const { showNotificationIndication } = this.state;
    const showIndication = count > 0;
    if (showIndication !== showNotificationIndication) {
      this.setState({
        showNotificationIndication: showIndication,
      });
    }
  };

  showPopover = (message, buttonText) => {
    this.setState({
      showPopover: true,
      popoverMessage: message,
      popoverActionsButtonText: buttonText,
    });
  };

  handleTap = () => {
    const { showCart } = this.props;
    if (showCart) {
      this.props.toggleCartVisibility();
    }
  };

  onProfileTap = () => {
    const { navigation, authToken, phoneNumberModalTitle, selfieImageUrl } =
      this.props;
    navigation.push('ConsumerProfile', {});
  };

  progressBar = (props) => {
    const { percentage } = props;
    const { size } = this.props;
    let fill = !percentage ? 0 : percentage;

    return (
      <TouchableOpacity
        style={
          size === 'large' ? styles.largeProgressBar : styles.smallProgressBar
        }
        onPress={this.debouncedProfileNavigate}
      >
        <AnimatedCircularProgress
          size={size === 'large' ? 56 : 35}
          width={3}
          fill={fill}
          rotation={0}
          tintColor={colors.green}
          backgroundColor={colors.silverLit}
        />
      </TouchableOpacity>
    );
  };

  hideProgressBar = () => {
    const { showImageProgressBar } = this.props;
    showImageProgressBar(false);
  };

  closePopover = () => {
    const { showPopover } = this.props;
    this.setState({ showPopover: false });
    this.hideProgressBar();
    showPopover('hidden');
  };

  navigateToSelfie = () => {
    const { navigation, facialAnalysis, showPopover } = this.props;
    const { popoverActionsButtonText } = this.state;
    this.isErrorSet = false;
    this.closePopover();

    if (this.errorWhileTakingSelfie) {
      navigation.navigate('AttributeSelector', {
        previousScreen: SCREEN_CONSTANTS.SELFIE_CARD,
      });
    } else {
      showPopover('hidden');
      navigation.navigate('AttributeSelector', {});
    }
  };

  profilePic = () => {
    const {
      imageUrl,
      tabBar,
      instagram_profile,
      selfieImageUrl,
      size = 'small',
      image_uri,
    } = this.props;
    this.isFeedTab = true;

    const imageSource =
      Utility.currentProfilePicToShowString(
        selfieImageUrl,
        instagram_profile,
        imageUrl,
        size,
      ) || image_uri;

    return (
      <FastImageView
        testID='profile-icon'
        accessibilityLabel='profile-icon'
        source={imageSource}
        style={
          size === 'small'
            ? styles.navbarProfileIconStyle
            : styles.navbarProfileIconStyleLarge
        }
        resizeMode='cover'
        enableProfilePicPlaceholder={Utility.isBlank(imageSource)}
      />
    );
  };

  render() {
    const { tabBar, facialAnalysis, email = '', size = 'small' } = this.props;

    const { showNotificationIndication } = this.state;

    const showIndication = Utility.isBlank(email) || showNotificationIndication;

    if (AppConfig.getBooleanValue(Config.DISABLE_PROFILE_IN_FEED_HEADER)) {
      return null;
    }
    return (
      <DebouncedTouchableOpacity
        style={
          size === 'small'
            ? styles.leftNavbarHeaderStyle
            : styles.leftNavbarHeaderStyleLarge
        }
        onPress={this.debouncedProfileNavigate}
        hitSlop={Utility.getHitSlop('my_profile')}
      >
        <this.profilePic />
        {facialAnalysis.show_progress_around_pic && (
          <this.progressBar percentage={facialAnalysis.analysis_progress} />
        )}
        {showIndication && size === 'small' && (
          <View style={styles.profileIconIndicator} />
        )}
      </DebouncedTouchableOpacity>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  imageUrl: state.UserAccountInfo.profile.image_url,
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
  instagram_profile: state.UserAccountInfo.instagram_profile,
  authToken: state.UserAccountInfo.authToken,
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  email: state.UserAccountInfo.profile.email,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      showImageProgressBar,
      showPopover,
      getUserFacialAttributes,
      selfieAnalysisState,
      imageUploadPercentage,
    },

    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(TabNavigationLeftHeader),
);
