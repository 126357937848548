import {
  Text,
  StyleSheet,
  View,
  Image,
  FlatList,
  Animated,
  TouchableOpacity,
} from 'react-native';
import React, { PureComponent } from 'react';
import styles from './styles';
import images from '../../theme/Images';
import { StaticNavigationHeader } from '../../components/shared';
import FastImageView from '../../components/FastImageView';
import { getFaqSectionData } from '../../actions/ActionTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import withNavigation from '../../utils/WithNavigation';

class FaqQuestionSection extends PureComponent {
  constructor(props) {
    super(props);
    const { navigation } = this.props;
    this.breadCrumbsData = '';
    this.state = {
      faq_question_data: [],
      isLoading: true,
      headerTitle: '',
    };
  }

  componentDidMount() {
    const { route, getFaqSectionData } = this.props;
    let slugString = route.params?.slug;
    slugString = slugString.replace('v2', 'v1');
    getFaqSectionData(slugString, this.getFaqSectionDataCallBack);
  }

  getFaqSectionDataCallBack = (success, response) => {
    if (success) {
      this.setState({
        faq_question_data: response.data,
        isLoading: false,
        headerTitle: response.data?.attributes?.name,
      });
      AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
        [EventParameterKey.SCREEN_NAME]: response.data?.attributes?.name?.slice(
          0,
          45,
        ),
      });
    }
  };

  onPressQuestion = (slug, id, question, index) => {
    const { navigation } = this.props;
    const { headerTitle } = this.state;
    this.breadCrumbsData = ['Need help', headerTitle, question];
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.LIST_POSITION]: index,
      [EventParameterKey.ITEM_NAME]: question.slice(0, 45),
    });
    navigation.navigate('FaqAnswerSection', {
      slug,
      id,
      breadCrumbsData: this.breadCrumbsData,
    });
  };

  faqQuestion = ({ item, index }) => {
    const { attributes = {}, id } = item;
    const { question = '', slug = '' } = attributes;
    return (
      <TouchableOpacity
        onPress={() => this.onPressQuestion(slug, id, question, index)}
      >
        <View style={styles.questionContainer}>
          <Text style={styles.questionTextStyle}>{question}</Text>
          <Image source={images.chevronRight} style={styles.rightArrowStyle} />
        </View>
        <View style={styles.horizontalLine} />
      </TouchableOpacity>
    );
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  keyExtractor = (item, index) => index;

  render() {
    const { faq_question_data, isLoading, headerTitle } = this.state;
    const { attributes: { faqs: { data: faqsData = {} } = {} } = {} } =
      faq_question_data;
    return (
      <View style={styles.container}>
        <StaticNavigationHeader title={headerTitle} />
        <FlatList
          style={styles.questionListContainer}
          data={faqsData}
          renderItem={this.faqQuestion}
          keyExtractor={this.keyExtractor}
        />
      </View>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getFaqSectionData,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(FaqQuestionSection),
);
