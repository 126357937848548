// Dependencies
import React, { Component } from 'react';
import { View, Image, Text, FlatList } from 'react-native';
import { connect } from 'react-redux';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ProfileStyles } from './styles';
import ScaleAnimate from '../shared/ScaleAnimate';
import { bindActionCreators } from 'redux';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import _ from 'lodash';
import { updateCurrentPostID } from '../../actions/LoginActions';

class Drafts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draftArray: [],
    };
  }

  goToUploadPost = (id) => {
    const { navigation, updateCurrentPostID } = this.props;
    const postID = id;
    updateCurrentPostID(postID);
    navigation.navigate('UploadVideo', {
      previousScreen: SCREEN_CONSTANTS.MY_PROFILE,
      uploadVideoFromProfile: this.props.uploadVideoFromProfile,
    });
  };

  getDraftComponent = (item, index) => {
    const { data: { items = [] } = {} } = item;
    const styles = ProfileStyles;
    if (Utility.isBlank(item)) {
      return null;
    }
    if (Utility.isBlank(item.data)) {
      return null;
    }

    if (Utility.isBlank(items)) {
      return null;
    }

    if (Utility.isBlank(items[0].mediaUriAbsolutePath)) {
      return null;
    }
    // let imageUri = item.data.postThumbnail;
    // if (Utility.isBlank(imageUri) && Utility.isBlank) {
    //   return null;
    // }
    // const firstFour = imageUri.substring(0, 4);
    // if (firstFour !== 'file') {
    //   imageUri = `file://${imageUri}`;
    // }
    return (
      <ScaleAnimate
        {...this.props}
        onPress={() => this.goToUploadPost(item.id)}
      >
        <View style={styles.draftCard}>
          <Image
            source={{ uri: items[0].mediaUriAbsolutePath }}
            style={styles.draftImage}
          />
        </View>
      </ScaleAnimate>
    );
  };

  render() {
    const styles = ProfileStyles;
    const { posts } = this.props;
    if (Utility.isBlank(posts)) {
      return null;
    }
    let draftArray = Object.keys(posts).map((key) => ({
      id: key,
      data: posts[key],
    }));
    // draftArray = _.filter(
    //   draftArray,
    //   (draft) => draft.data.postThumbnail !== '',
    // );
    if (draftArray.length === 0) {
      return null;
    }
    return (
      <View style={styles.draftContainer}>
        <Text style={styles.draftHeader}>Drafts</Text>
        <View style={styles.railView}>
          <FlatList
            data={draftArray}
            horizontal
            style={styles.draftFlatlistStyle}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item, index }) =>
              this.getDraftComponent(item, index)
            }
            keyExtractor={(item, index) => `${index}_${item.id}`}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  profile: !Utility.isBlank(state.UserAccountInfo.profile),
  posts: state.Posts,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateCurrentPostID,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Drafts),
);
