import { StyleSheet } from 'react-native';
import colors from '../../../../../theme/Colors';
import Utility from '../../../../../utils/Utility';

const AddressStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    paddingHorizontal: 12,
  },
  divider: {
    height: 1,
    width: 90,
    backgroundColor: 'red',
  },
  textFieldContainer: {
    marginLeft: 16,
    marginRight: 16,
  },
  keyboardAwareScrollView: {
    marginLeft: 16,
    marginRight: 16,
  },
  saveAddressButton: {
    marginTop: 8,
  },
  header: {
    position: 'absolute',
    top: 0,
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 6,
    borderBottomWidth: 1,
    borderBottomColor: colors.background,
  },
  backArrow: {
    width: 20,
    height: 17.5,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
    marginLeft: 16,
  },
  addressHeaderText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 18,

    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 12,
    color: colors.foxyBlack,
  },
  addAddressButton: {
    position: 'absolute',
    right: 12,
    left: 12,
    height: 44,
    bottom: 4,
    backgroundColor: colors.green,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iosActionContainer: {
    height: 70,
    bottom: Utility.bottomInset,
    width: Utility.getScreenWidth(),
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  actionBottonContainer: {
    position: 'absolute',
    height: 70,
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  addAddressText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.white,
  },
  placeNameButtonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 30,
    marginTop: 12,
  },
  placeNameButton: {
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 12,
    marginRight: 8,
  },
  addPlaceNameText: {
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
    marginTop: 16,
  },
  placeNameButtonText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginRight: 16,
    marginLeft: 16,
  },
});

export default AddressStyles;
