import React from 'react';
import PropTypes from 'prop-types';

import { View, Animated, Easing, Text, StyleSheet } from 'react-native';
import colors from '../theme/Colors';
import Utility from '../utils/Utility';

class ProgressBarAnimated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: props.value,
    };

    this.widthAnimation = new Animated.Value(0);
    this.backgroundAnimation = new Animated.Value(0);
    this.backgroundInterpolationValue = null;
  }

  componentDidMount() {
    const { progress } = this.state;
    if (progress > 0) {
      this.animateWidth();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { progress } = this.state;
    if (props.value !== progress) {
      if (props.value >= 0 && props.value <= this.props.maxValue) {
        this.setState({ progress: props.value }, () => {
          if (progress === this.props.maxValue) {
            // Callback after complete the progress
            const callback = this.props.onComplete;
            if (callback) {
              setTimeout(callback, this.props.barAnimationDuration);
            }
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { value, backgroundColorOnComplete, maxValue } = this.props;
    if (value !== prevProps.value) {
      this.animateWidth();

      if (backgroundColorOnComplete) {
        if (value === maxValue) {
          this.animateBackground();
        }
      }
    }
  }

  animateWidth() {
    const { maxValue } = this.props;
    const toValue =
      (this.props.width * this.state.progress) / maxValue -
      this.props.borderWidth * 2;
    Animated.timing(this.widthAnimation, {
      easing: Easing[this.props.barEasing],
      toValue: toValue > 0 ? toValue : 0,
      duration: this.props.barAnimationDuration,
    }).start();
  }

  animateBackground() {
    Animated.timing(this.backgroundAnimation, {
      toValue: 1,
      duration: this.props.backgroundAnimationDuration,
    }).start();
  }

  barValues = () => {
    const { value, final, current, fromOffer } = this.props;

    if (value === 0 || !fromOffer) {
      return null;
    }

    if (value > 85) {
      return <Text style={styles.finalText}>{final}</Text>;
    }
    return (
      <>
        <Animated.Text
          style={[
            styles.currentText,
            {
              left: this.widthAnimation,
              marginLeft: -1 * Math.ceil(current.length * 3),
            },
          ]}
        >
          {current}
        </Animated.Text>

        <Text style={styles.finalText}>{final}</Text>
      </>
    );
  };

  render() {
    const {
      width,
      height,
      borderColor,
      borderWidth,
      borderRadius,
      underlyingColor,
      backgroundColor,
      backgroundColorOnComplete,
      final,
      current,
    } = this.props;
    if (backgroundColorOnComplete) {
      this.backgroundInterpolationValue = this.backgroundAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [backgroundColor, backgroundColorOnComplete],
      });
    }

    return (
      <View
        style={{
          width,
          height,
          borderWidth,
          borderColor,
          borderRadius,
          backgroundColor: underlyingColor,
        }}
      >
        <Animated.View
          style={{
            height: height - borderWidth * 2,
            width: this.widthAnimation,
            backgroundColor:
              this.backgroundInterpolationValue || backgroundColor,
            borderRadius,
          }}
        />

        <this.barValues />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  finalText: {
    fontSize: 12,
    color: '#173143AD',
    position: 'absolute',
    right: 0,
    marginTop: 8,
    fontFamily: 'Roboto-Regular',
  },
  currentText: {
    fontSize: 12,
    color: '#173143AD',
    position: 'absolute',
    marginTop: 8,
    fontFamily: 'Roboto-Regular',
    paddingRight: 40,
  },
});

ProgressBarAnimated.propTypes = {
  /**
   * Bar values
   */
  value: PropTypes.number,
  maxValue: PropTypes.number,

  /**
   * Animations
   */
  barEasing: PropTypes.oneOf([
    'bounce',
    'cubic',
    'ease',
    'sin',
    'linear',
    'quad',
  ]),
  barAnimationDuration: PropTypes.number,
  backgroundAnimationDuration: PropTypes.number,

  /**
   * StyleSheet props
   */
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  backgroundColorOnComplete: PropTypes.string,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.number,

  /**
   * Callbacks
   */
  onComplete: PropTypes.func,
};

ProgressBarAnimated.defaultProps = {
  value: 0,
  maxValue: 100,

  barEasing: 'linear',
  barAnimationDuration: 500,
  backgroundAnimationDuration: 2500,

  height: 15,

  backgroundColor: '#148cF0',
  backgroundColorOnComplete: null,

  borderWidth: 1,
  borderColor: '#C8CCCE',
  borderRadius: 6,

  onComplete: null,
};

export default ProgressBarAnimated;
