import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  Text,
  KeyboardAvoidingView,
  Keyboard,
  FlatList,
} from 'react-native';
import Modal from 'react-native-modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FullWidthDivider from '../../utils/FullWidthDivider';
import images from '../../theme/Images';
import { withEither } from '../../lib/Monads';
import Utility from '../../utils/Utility';
import { PopUpStyles } from '../Product/styles';
import { getAllEmojis } from '../../actions/ActionTypes';
import FastImageView from '../FastImageView';

class EmojiModal extends Component {
  cancelpressed = () => {
    const { toggleEmojiModal } = this.props;
    console.tron.log('backdrop pressed');
    toggleEmojiModal();
  };

  isIosFn = () => Utility.isIOS();

  customKeyboardAvoid = withEither(this.isIosFn, KeyboardAvoidingView)(View);

  componentDidMount() {
    const { emojis, getAllEmojis } = this.props;
    if (Utility.isBlank(emojis)) {
      getAllEmojis();
    }
  }

  getEmojiItem = (item) => {
    const { emojiSelected } = this.props;
    const styles = PopUpStyles;
    return (
      <TouchableOpacity onPress={() => emojiSelected(item.image_url)}>
        <FastImageView
          source={Utility.getMinifiedImage(item.image_url)}
          style={styles.emojiItem}
          resizeMode="contain"
        />
      </TouchableOpacity>
    );
  };

  emojiView = () => {
    const { emojiSelected, emojis } = this.props;
    const styles = PopUpStyles;
    return (
      <View
        style={{
          height: 300,
          width: '100%',
          marginBottom: 22,
        }}
      >
        {/* <EmojiSelector columns={8} onEmojiSelected={emoji => emojiSelected(emoji)} /> */}
        <Text style={[styles.modalTitle, { margin: 8, marginLeft: 20 }]}>
          Select an emoji
        </Text>
        <TouchableOpacity
          style={[PopUpStyles.cancelButton, { top: 12, right: 20 }]}
          onPress={this.cancelpressed}
        >
          <Image source={images.cross} style={PopUpStyles.cancelStyle} />
        </TouchableOpacity>
        <FlatList
          data={emojis}
          numColumns={7}
          scrollEnabled
          keyExtractor={(item) => `${item.id}_${item.name}_emojis`}
          renderItem={({ item }) => this.getEmojiItem(item)}
          style={styles.emojiFlatListStyle}
        />
      </View>
    );
  };

  render() {
    const styles = PopUpStyles;
    const { isVisible } = this.props;
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.1}
        backdropTransitionOutTiming={0}
        style={styles.changePincodeModal}
        onBackdropPress={this.cancelpressed}
        swipeDirection="down"
        onSwipeComplete={this.cancelpressed}
      >
        <SafeAreaView style={{ flex: 1, justifyContent: 'flex-end' }}>
          <this.customKeyboardAvoid
            behavior="position"
            enabled
            keyboardVerticalOffset={-18}
          >
            <View
              style={[
                PopUpStyles.container,
                {
                  borderTopRightRadius: 8,
                  borderTopLeftRadius: 8,
                },
              ]}
            >
              <FullWidthDivider componentStyle={{ marginBottom: 8 }} />
              <View style={PopUpStyles.locationContainer}>
                <this.emojiView />
              </View>
            </View>
          </this.customKeyboardAvoid>
        </SafeAreaView>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  emojis: state.UserAccountInfo.emojis,
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ getAllEmojis }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmojiModal);
