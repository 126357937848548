import React from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import TopLayer from './TopLayer';
import MiddleLayer from './MiddleLayer';
import BottomLayer from './BottomLayer';
import WebFooter from '../../components/webFooter/WebFooter';

export default function ForYou() {
  return (
    <ScrollView style={styles.container}>
      <TopLayer />
      <MiddleLayer />
      <BottomLayer />
      <WebFooter />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F5F6F8',
    paddingTop: 30,
  },
});

export const ForYouOptions = {
  headerShadowVisible: false,
  title: 'For You',
};
