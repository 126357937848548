import { isPresent } from './BooleanUtility';

export const getSkuId = (itemData) => {
  let id;
  if (
    isPresent(itemData) &&
    isPresent(itemData.variants_details)
  ) {
    id = itemData.variants_details.principal_sku_id;
  } else if (
    isPresent(itemData) &&
    isPresent(itemData.variant_attributes) &&
    isPresent(itemData.variant_attributes[0].allowed_values) &&
    isPresent(itemData.variant_attributes[0].allowed_values[0].sku_id)
  ) {
    id = itemData.variant_attributes[0].allowed_values[0].sku_id;
  } else {
    id = itemData.sku_id;
  }

  return id;
}