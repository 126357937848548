import { select, call, takeEvery, put } from 'redux-saga/effects';
import { Upload } from 'react-native-tus-client';
import { URL, UPLOAD_STATUS_TRAY_CONSTANTS } from '../config/Constants';
import {
  BLOT_MEDIA_UPLOAD_SUCCESSFUL,
  UPLOAD_MEDIA_TO_S3,
  ABORT_UPLOAD,
  markMediaUploadSuccessful,
  updateCurrentPostID,
  markMediaUploadBegin,
  blotMediaUploadSuccessful,
  showUploadTrayOnFeed,
} from '../actions/LoginActions';
import Utility from '../utils/Utility';
import {
  setVideoUploadStatus,
  setVideoUploadProgress,
  stopUploading,
} from '../actions/ActionTypes';
import { deleteDraft, publishPost } from '../actions/UploadPostActions';
import NotificationManager from '../utils/NotificationsManager';
import { AnalyticsManager, EventType } from '../analytics';

var upload = null;
const { failed, paused, success, in_progress } = UPLOAD_STATUS_TRAY_CONSTANTS;

function* stopUploadingAndSetStatusFailed() {
  yield put(setVideoUploadStatus(failed));
  yield put(stopUploading());
}

function* abortMediaUpload() {
  if (upload !== null) {
    try {
      upload.abort();
    } catch (e) {}
    yield stopUploadingAndSetStatusFailed();
  }
}

function uploadVideoWithTUS(fileUrl, callback, isMediaVideo) {
  let lastProgress = 0.0;
  return new Promise((resolve, reject) => {
    upload = new Upload(fileUrl.replace('file://', ''), {
      endpoint: `${URL.TUS_VIDEO_UPLOAD}`,
      chunkSize: 1024,
      onSuccess: () => {
        if (isMediaVideo) {
          NotificationManager.hideVideoUploadingNotification();
        }
        resolve({ status: true, upload_url: upload.url });
      },
      onError: (error) => {
        if (isMediaVideo) {
          if (error.toString().includes('java.net.UnknownHostException')) {
            setTimeout(() => {
              NotificationManager.checkConnection();
            }, 1000);
            resolve({ status: paused });
          } else {
            NotificationManager.retryVideoUpload();
            AnalyticsManager.logFirebaseEvent(
              EventType.review_upload.MEDIA_UPLOAD_FAILED,
            );
          }
        }
        resolve({ status: false });
      },
      onProgress: (uploaded, total) => {
        if (uploaded === 0) return;
        let currentProgress = ((uploaded / total) * 100) | 0;
        if (currentProgress > lastProgress) {
          lastProgress = currentProgress;
          if (isMediaVideo) {
            NotificationManager.setProgressToVideoUploadNotification(
              total,
              uploaded,
            );
          }
          Utility.setVideoUploadProgress(uploaded / total);
        }
      },
    });
    upload.start();
    AnalyticsManager.logFirebaseEvent(
      EventType.review_upload.MEDIA_UPLOAD_START,
    );
  });
}

function* uploadMedia(action) {
  const { fileUri, callback } = action;
  NotificationManager.showVideoUploadingNotification();
  const isMediaVideo = action.markUploadingDone;

  try {
    yield put(markMediaUploadBegin());
    if (isMediaVideo) {
      yield put(showUploadTrayOnFeed(true));
    }
    yield put(setVideoUploadStatus(in_progress));
    const uploaded = yield uploadVideoWithTUS(fileUri, callback, isMediaVideo);

    if (uploaded.status === true) {
      yield put(setVideoUploadProgress(0));
      if (action.markUploadingDone) {
        yield put(setVideoUploadStatus(success));
        yield put(
          blotMediaUploadSuccessful(
            Utility.extractImageName(fileUri),
            uploaded.upload_url,
            callback,
          ),
        );
      }
    } else if (uploaded.status === paused) {
      yield put(setVideoUploadStatus(paused));
    } else {
      yield stopUploadingAndSetStatusFailed();
    }
  } catch (error) {
    yield stopUploadingAndSetStatusFailed();
  }
}

const markSuccessUploadingMedia = (
  postServerID,
  authToken,
  cardImageName,
  mediaFileNames,
  uploadId,
) => {
  const postData = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      auth_token: `${authToken}`,
      medium: {
        card_image: `${cardImageName}`,
        video: `${mediaFileNames[0]}`,
        tus_upload_id: `${uploadId}`,
      },
    }),
  };

  const response = fetch(
    `${URL.MEDIA_UPLOAD_SUCCESSFUL}${postServerID}/asset_upload_successful`,
    postData,
  );
  return response;
};

function* blotMediaUploadSuccess(action) {
  try {
    const { tusFilePath } = action;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const postId = yield select((state) => state.UserAccountInfo.currentPostId);
    const posts = yield select((state) => state.Posts);
    const currentPost = posts[postId];
    const cardImageName = Utility.extractImageName(currentPost.postThumbnail);
    const mediaFileNames = [];
    for (let i = 0; i < currentPost.items.length; i += 1) {
      const mediaIndex = currentPost.items[i].uri.lastIndexOf('/') + 1;
      const mediaFileName = currentPost.items[i].uri.substr(mediaIndex);
      mediaFileNames.push(mediaFileName);
    }
    const response = yield call(
      markSuccessUploadingMedia,
      currentPost.id,
      authToken,
      cardImageName,
      mediaFileNames,
      tusFilePath,
    );

    if (response.status >= 200 && response.status < 300) {
      yield put(deleteDraft(postId));
      yield put(updateCurrentPostID(''));
      yield put(markMediaUploadSuccessful());
      Utility.setVideoUploadProgress(100);
      AnalyticsManager.logFirebaseEvent(
        EventType.review_upload.MEDIA_UPLOAD_FINISH,
      );
      const Posts = yield select((state) => state.Posts);
      if (Object.keys(Posts).length > 0) {
        const key = Object.keys(Posts)[0];
        const currentPos = Posts[key];
        yield put(publishPost(currentPos.id, currentPos.items[0]));
      }
    } else {
      yield put(setVideoUploadStatus(failed));
      AnalyticsManager.logFirebaseEvent(
        EventType.review_upload.MEDIA_UPLOAD_FAILED,
      );
    }
  } catch (error) {
    yield put(setVideoUploadStatus(failed));
  }
}

export default function* watchMediaUploadOnS3Saga() {
  yield takeEvery(UPLOAD_MEDIA_TO_S3, uploadMedia);
  yield takeEvery(BLOT_MEDIA_UPLOAD_SUCCESSFUL, blotMediaUploadSuccess);
  yield takeEvery(ABORT_UPLOAD, abortMediaUpload);
}
