import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import {
  updateUserAttributeRemote,
  setGenderUserAttribute,
} from '../../actions/LoginActions';

import { uacGenderEventTriggered } from '../../actions/ActionTypes.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';

import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import FastImageView from '../FastImageView';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

class SelectGenderCard extends PureComponent {
  textView = (props) => {
    const { text, header } = props;
    if (Utility.isBlank(text)) {
      return null;
    }
    return (
      <Text style={header ? styles.headerText : styles.descText}>{text}</Text>
    );
  };

  button = (props) => {
    const { text, imgSrc, onPress } = props;
    return (
      <TouchableWithoutFeedback onPress={onPress}>
        <View style={styles.buttonLayout}>
          {Utility.isPresent(imgSrc) && (
            <FastImageView style={styles.buttonIcons} source={imgSrc}/>
          )}
          <this.textView header={true} text={text} />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  buttonRow = () => {
    return (
      <View style={styles.buttonRow}>
        <View style={{ flex: 1 }} testID="women-btn-guest-page" accessibilityLabel="women-btn-guest-page">
          <this.button
            text='Women'
            imgSrc={images.gender.female.true}
            onPress={this.onPressFemaleGender}
          />
        </View>
        <View style={styles.spacer} />
        <View style={{ flex: 1 }} testID="men-btn-guest-page" accessibilityLabel="men-btn-guest-page">
          <this.button
            text='Men'
            imgSrc={images.gender.male.true}
            onPress={this.onPressMaleGender}
          />
        </View>
      </View>
    );
  };

  onPressGenderButton = (gender) => {
    const { updateUserAttributeRemote, setGenderUserAttribute } = this.props;
    const genderId = gender === 'male' ? 62 : 63;
    let genderObject = { id: 28, new_values: [genderId] };
    const genderObjectLocal = { user_attribute_id: 28, new_values: [genderId] };
    AnalyticsManager.setUserProperty('gender_selected_by_user', gender);
    updateUserAttributeRemote(genderObject);
    setTimeout(() => {
      setGenderUserAttribute(genderObjectLocal);
    }, 200);
  };

  onPressMaleGender = () => {
    const { currentState, uacGenderEventTriggered } = this.props;
    const uac_gender = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.uac_gender);
    const jsonUacGender = Utility.jsonParser(uac_gender);
    let paramState = EventParameterValue.STATE.NON_LOGGED_IN_USER;
    if (currentState === 'upload_error') {
      paramState = EventParameterValue.STATE.UPLOAD_ERROR;
    } else if (currentState === 'analysis_error') {
      paramState = EventParameterValue.STATE.ANALYSIS_ERROR;
    }
    this.onPressGenderButton('male');
    AnalyticsManager.logEvent(
      EventType.discoveryEvents.NON_PERSONALISED_HOMEPAGE_REQUEST,
      {
        [EventParameterKey.TYPE]: 'male',
        [EventParameterKey.STATE]: paramState,
      },
    );
    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_GENDER_SCORE, {
      [EventParameterKey.SCORE]: jsonUacGender.male,
    });
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.NON_PERSONALISED_FEED,
      [EventParameterKey.ENTITY_NAME]: SCREEN_CONSTANTS.NON_PERSONALISED_FEED,
      [EventParameterKey.ITEM_TYPE]:
        EventParameterValue.CARDS.SELECT_GENDER_CARD,
      [EventParameterKey.ITEM_NAME]: EventParameterValue.GENDER.MALE,
      [EventParameterKey.ITEM_POSITION]: -1,
    });
    uacGenderEventTriggered(jsonUacGender.male);
  };

  onPressFemaleGender = () => {
    const { currentState, uacGenderEventTriggered } = this.props;

    const uac_gender = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.uac_gender);
    const jsonUacGender = Utility.jsonParser(uac_gender);

    let paramState = EventParameterValue.STATE.NON_LOGGED_IN_USER;
    if (currentState === 'upload_error') {
      paramState = EventParameterValue.STATE.UPLOAD_ERROR;
    } else if (currentState === 'analysis_error') {
      paramState = EventParameterValue.STATE.ANALYSIS_ERROR;
    }
    AnalyticsManager.logEvent(
      EventType.discoveryEvents.NON_PERSONALISED_HOMEPAGE_REQUEST,
      {
        [EventParameterKey.TYPE]: 'female',
        [EventParameterKey.STATE]: paramState,
      },
    );
    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_GENDER_SCORE, {
      [EventParameterKey.SCORE]: jsonUacGender.female,
    });
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.NON_PERSONALISED_FEED,
      [EventParameterKey.ENTITY_NAME]: SCREEN_CONSTANTS.NON_PERSONALISED_FEED,
      [EventParameterKey.ITEM_TYPE]:
        EventParameterValue.CARDS.SELECT_GENDER_CARD,
      [EventParameterKey.ITEM_NAME]: EventParameterValue.GENDER.FEMALE,
      [EventParameterKey.ITEM_POSITION]: -1,
    });
    uacGenderEventTriggered(jsonUacGender.female);
    this.onPressGenderButton('female');
  };

  logEvent = () => {
    const { currentState } = this.props;
    let paramState = EventParameterValue.STATE.NON_LOGGED_IN_USER;
    if (currentState === 'logged_in_user') {
      paramState = EventParameterValue.STATE.LOGGED_IN_USER;
    } else if (currentState === 'upload_error') {
      paramState = EventParameterValue.STATE.UPLOAD_ERROR;
    } else if (currentState === 'analysis_error') {
      paramState = EventParameterValue.STATE.ANALYSIS_ERROR;
    } else if (currentState === 'analysing') {
      paramState = EventParameterValue.STATE.ANALYSING;
    }

    AnalyticsManager.logEvent(
      EventType.discoveryEvents.PERSONALISED_CARD_VIEW,
      {
        [EventParameterKey.STATE]: paramState,
      },
    );
  };

  render() {
    const {
      headerText,
      descriptionText,
      showFooterRetakeButton,
      onPressRetakeButton,
      showGenderCard,
    } = this.props;

    if (!showGenderCard) return null;
    this.logEvent();
    return (
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <this.textView header={true} text={headerText} />
          <this.textView text={descriptionText} />
          {showFooterRetakeButton && (
            <View style={{ marginBottom: 15 }}>
              <this.button text='Retake' onPress={onPressRetakeButton} />
            </View>
          )}
          {!showFooterRetakeButton && <this.buttonRow />}
        </View>
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateUserAttributeRemote,
      setGenderUserAttribute,
      uacGenderEventTriggered,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(SelectGenderCard);

const styles = StyleSheet.create({
  wrapper: {
    width: Utility.getScreenWidth(),
    paddingLeft: 10,
    paddingRight: 10,
  },
  container: {
    padding: 8,
    maxHeight: 120,
    marginTop: 4,
    backgroundColor: colors.darkGreyBackground,
    borderRadius: 4,
  },
  headerText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.black,
  },
  descText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    marginTop: 4,
    color: colors.black,
  },
  buttonLayout: {
    height: 40,
    marginTop: 10,
    borderRadius: 8,
    backgroundColor: colors.white,
    borderColor: colors.black,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1.5,
    flexDirection: 'row',
  },

  buttonRow: {
    marginTop: 10,
    flexDirection: 'row',
    backgroundColor: colors.background,
  },
  spacer: {
    width: 20,
  },
  buttonIcons: {
    marginRight: 10,
    padding: 4,
    height: 20,
    width: 20,
  },
});
