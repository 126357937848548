import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Keyboard,
  ActivityIndicator,
  BackHandler,
} from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import { TextField } from 'react-native-material-textfield';
import { PaymentStyles } from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { RNPayuPayment } from '../../lib/RNPayuPayment';
import FullWidthDivider from '../../utils/FullWidthDivider';
import { StaticNavigationHeader } from '../shared';

class SavedCard extends Component {
  constructor(props) {
    super(props);
    this.ref = [];
    this.ref.cvv = React.createRef();
    this.state = {
      cvv: '',
      isLoaderVisible: false,
    };
    const { route } = props;
    this.selectedSavedCard = route.params?.params?.selectedPaymentOption;
    this.createOrder = route.params?.createOrder;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  resetTextFields = () => {
    this.setState({
      cvv: '',
    });
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  showBackAlert = () => {
    Alert.alert(
      'Confirmation',
      'Are you sure you want to cancel the transaction?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        { text: 'OK', onPress: this.goBack },
      ],
      { cancelable: true },
    );
  };

  addCVV = (value) => this.setState({ cvv: value });

  paymentConstants = {
    headerText: 'Enter Details',
    cvv: 'CVV',
    enterCvvText: 'Enter CVV/CVC number for your card ending with',
  };

  createOrderAndPay = () => {
    const { cvv } = this.state;
    const cardParams = {
      cvv,
      cardToken: this.selectedSavedCard.card_token,
    };

    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: 'card',
      isSavedPaymentMethod: true,
      paymentMethodPayload: cardParams,
      payuPayload: cardParams,
      extraData: {
        method: 'saved_card',
      },
    };

    this.createOrder(paymentPayload);
  };

  showLoader = () => {
    this.setState({
      isLoaderVisible: true,
    });
  };

  payWithSavedCard = () => {
    //TODO: Move this function in cartmodal.js
    const {
      viewOrderStatusScreen,
      paymentSuccess,
      updateLastPaymentMode,
      setCurrentPaymentDetails,
      initiatePayment,
      openOrderStatusScreen,
    } = this.props;
    const { cvv } = this.state;
    const cardParams = {
      cvv,
      cardToken: this.selectedSavedCard.card_token,
    };
    console.tron.log('card param is ', cardParams);
    setCurrentPaymentDetails('savedCard', cardParams); //to retry if failed
    initiatePayment(); //To starts loader in payment details
    RNPayuPayment.payWithSavedCard(cardParams)
      .then((res) => {
        console.tron.log('pay with saved card successfull', res);
        if (Utility.isPresent(res.status) && res.status === 'success') {
          paymentSuccess(res);
        } else {
          openOrderStatusScreen(res);
        }
        //for saving
      })
      .catch((e) => {
        console.tron.log('pay with saved card failure', e);
        openOrderStatusScreen(e);
      });
    this.setState({
      cvv: '',
    });
  };

  render() {
    const styles = PaymentStyles;
    const { cvv, isLoaderVisible } = this.state;

    return (
      <View style={{ height: '100%', backgroundColor: 'white' }}>
        <StaticNavigationHeader title="Enter details" />
        <View
          style={[
            styles.container,
            {
              marginLeft: Utility.largePadding,
              marginRight: Utility.largePadding,
              backgroundColor: 'white',
            },
          ]}
        >
          <View
            style={[styles.scrollView, { marginBottom: Utility.bottomInset }]}
          >
            <View styles={styles.enterCvvContainer}>
              <Text style={styles.enterCvvText}>
                {`${this.paymentConstants.enterCvvText} *${this.selectedSavedCard.display_name}`}
              </Text>
              <TextField
                placeholder="CVV"
                lineWidth={1}
                blurOnSubmit={false}
                value={cvv}
                onChangeText={this.addCVV}
                ref={this.ref.cvv}
                maxLength={4}
                containerStyle={styles.cvvContainerForSavedCard}
                onSubmitEditing={this.dismissKeyboard}
                keyboardType="numeric"
                returnKeyType="done"
                secureTextEntry
                textAlign="center"
                tintColor={colors.silver}
              />
              <TouchableOpacity
                onPress={this.createOrderAndPay}
                style={styles.submitButton}
                disabled={isLoaderVisible}
              >
                <Text style={styles.submitText}>Submit</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default withNavigation(SavedCard);
