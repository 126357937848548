import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  LayoutAnimation,
  Platform,
  UIManager,
} from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShimmerPlaceHolder from '../libraries/ReactNativeShimmerPlaceholder';
import { fetchPersonalizedList } from '../actions/ActionTypes';
import RoundedButton from '../components/layout/buttons/RoundedButton';
import withNavigation from '../utils/WithNavigation';
import Utility from '../utils/Utility';
import { playlistStyle } from '../components/media/Style';
import colors from '../theme/Colors';
import List from './List/List';
import images from '../theme/Images';
import RemoteConfig from '../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../config/Constants';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';
import Config from '../libraries/ReactNativeConfig';
import AppConfig from '../config/AppConfig';
import { isWeb } from '../utils/BooleanUtility';

export class FoxyPersonalisedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personalisedData: {},
      showMoreItems: false,
      loading: false,
    };
    if (
      Platform.OS === 'android' &&
      UIManager.setLayoutAnimationEnabledExperimental
    ) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
    if (Utility.isPresent(this.props.selfieImageUrl)) {
      Utility.setPersonalizedItemsLoadStart(
        'personalized_items_for_lists',
        0,
        '',
        false,
        { slug: this.props.slug, selfie: true },
      );
    } else {
      Utility.setPersonalizedItemsLoadStart(
        'personalized_items_for_lists',
        0,
        '',
        false,
        { selfie: false, slug: this.props.slug },
      );
    }
  }

  componentDidMount() {
    if (Utility.isPresent(this.props.selfieImageUrl)) {
      this.setLoading(true);
      const { fetchPersonalizedList, slug = '' } = this.props;
      const toSplit = slug.includes('.json')
        ? `${slug.split('.json')[0]}/personalized_details?`
        : `${slug.split('?Nan=Nan')[0]}/personalized_details?Nan=Nan`;

      fetchPersonalizedList(toSplit, 0, (success, data) => {
        const { personalized_products: { objects = [] } = {} } = data;
        this.setLoading(false);

        Utility.setPersonalizedItemsLoadEnd('personalized_items_for_lists', {
          no_of_items: objects.length,
          slug: this.props.slug,
          selfie: true,
        });
        if (success) {
          this.setState({
            personalisedData: data?.personalized_products,
          });
        }
      });
    }
  }

  setLoading = (loading) => {
    this.setState({
      loading,
    });
  };

  product = () => {
    return (
      <ShimmerPlaceHolder
        autoRun
        style={{ height: 40, width: 40, borderRadius: 20, marginRight: -15 }}
      />
    );
  };

  productList = ({ item }) => {
    const { images = [], image_url } = item;
    let image;
    if (Utility.isBlank(images) || images.length === 0) {
      image = image_url;
    } else {
      image = images[0];
    }
    return (
      <Image
        source={{ uri: Utility.getSmallImageUrl(image, 120, 120) }}
        style={{
          height: 40,
          marginRight: -15,
          width: 40,
          borderWidth: 1,
          borderRadius: 20,
          borderColor: colors.borderDark,
          resizeMode: 'cover',
          backgroundColor: colors.white,
          overflow: 'hidden',
        }}
      />
    );
  };

  setShowMore = (showMore) => {
    const { personalisedData } = this.state;
    const {
      slug = '',
      previousScreen = '',
      extraEventParameters = {},
    } = this.props;
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.TYPE]: 'matched_products_view',
      [EventParameterKey.MATCHED_PRODUCTS_COUNT]:
        personalisedData?.objects.length,
      [EventParameterKey.SLUG]: slug,
      [EventParameterKey.SELFIE]: true,
      [EventParameterKey.SCREEN_NAME]: previousScreen,
      ...extraEventParameters,
    });
    this.setState({ showMoreItems: showMore });
  };

  fetchedItems = () => {
    const { personalisedData, showMoreItems } = this.state;
    const { subheading } = this.props;
    return (
      <View
        style={{
          paddingHorizontal: 12,
          marginVertical: 16,
        }}
      >
        <Text
          style={{
            fontSize: 14,
            color: colors.foxyBlack,
            fontFamily: 'Roboto-Bold',
          }}
        >
          It’s a match
        </Text>
        <Text
          style={{
            fontSize: 14,
            color: '#979BAA',
            fontFamily: 'Roboto-Regular',
            marginTop: 2,
          }}
        >
          {subheading}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 12,
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            {personalisedData?.objects.slice(0, 5).map((item) => {
              return <this.productList item={item} />;
            })}
          </View>
          {!showMoreItems && (
            <RoundedButton
              buttonText={'View'}
              buttonTextColor={'#fff'}
              buttonColor={'#000'}
              buttonAction={() => {
                this.setShowMore(true);
              }}
              style={{
                height: 32,
                borderRadius: 20,
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 8,
                paddingHorizontal: 16,
              }}
              buttonTextStyle={{
                fontSize: 14,
                fontFamily: 'Roboto-Medium',
                marginLeft: 8,
              }}
              chevronDown
            />
          )}
          {showMoreItems && (
            <TouchableOpacity
              onPress={() => {
                this.setShowMore(false);
              }}
              hitSlop={Utility.getHitSlop()}
              style={{
                flexDirection: 'row',
                alignSelf: 'flex-end',
                alignItems: 'center',
                marginRight: 12,
                marginTop: 8,
                marginBottom: 12,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto-Medium',
                  color: colors.cta.lightBlue,
                }}
              >
                Hide
              </Text>
              <Image
                style={{
                  width: 12,
                  height: 12,
                  resizeMode: 'contain',
                  tintColor: colors.cta.lightBlue,
                  marginLeft: 4,
                }}
                source={images.chevronUp}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };

  fetchingItems = () => {
    return (
      <View
        style={{
          paddingHorizontal: 12,
          marginVertical: 12,
        }}
      >
        <Text
          style={{
            fontSize: 14,
            color: colors.foxyBlack,
            fontFamily: 'Roboto-Medium',
          }}
        >
          Is there a match ?
        </Text>
        <Text
          style={{
            fontSize: 14,
            color: '#979BAA',
            fontFamily: 'Roboto-Regular',
            marginTop: 2,
          }}
        >
          Products which you shouldn’t miss
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 12,
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            {[1, 2, 3, 4].map(() => {
              return <this.product />;
            })}
          </View>
        </View>
      </View>
    );
  };

  fetchingItemsBlank = () => {
    return (
      <View
        style={{
          paddingHorizontal: 12,
          marginVertical: 12,
        }}
      >
        <Text
          style={{
            fontSize: 14,
            color: colors.foxyBlack,
            fontFamily: 'Roboto-Medium',
          }}
        >
          No products found
        </Text>
        <Text
          style={{
            fontSize: 14,
            color: '#979BAA',
            fontFamily: 'Roboto-Regular',
            marginTop: 2,
          }}
        >
          We could not find any matching products for your preferences. Worry
          not the list is your’s to explore
        </Text>
      </View>
    );
  };

  fetchingItemsNoSelfie = () => {
    const { personalisedData, showMoreItems } = this.state;
    const { navigation } = this.props;
    return (
      <View
        style={{
          paddingHorizontal: 12,
          marginVertical: 16,
        }}
      >
        <Text
          style={{
            fontSize: 14,
            color: colors.foxyBlack,
            fontFamily: 'Roboto-Medium',
          }}
        >
          Is there a match ?
        </Text>
        <Text
          style={{
            fontSize: 14,
            color: '#979BAA',
            fontFamily: 'Roboto-Regular',
            marginTop: 2,
          }}
        >
          Take a selfie, tell us about your goals and we’ll help you find the
          right products
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 12,
          }}
        >
          <></>

          <RoundedButton
            buttonText={'Take Selfie'}
            buttonTextColor={'#fff'}
            buttonColor={'#000'}
            buttonAction={() => {
              navigation.navigate('TakeSelfie');
            }}
            style={{
              // height: 32,
              borderRadius: 20,
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: 8,
              paddingHorizontal: 16,
              justifyContent: 'center',
            }}
            buttonTextStyle={{
              fontSize: 14,
              fontFamily: 'Roboto-Medium',
              marginLeft: 8,
            }}
            showChevron
          />
        </View>
      </View>
    );
  };

  openWebLink = () => {
    const { navigation, userInfo, slug = '' } = this.props;
    const {
      profile: { name: user_name = '' },
      guestProfile: { guestAuthToken: guest_token = '' },
    } = userInfo;
    const reportUrl = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.report_personalised_product,
    );

    const webLink = reportUrl
      .replace('${user_name}', user_name.toString())
      .replace('${guest_token}', guest_token.toString())
      .replace('${slug}', slug.toString());
    navigation.navigate('WebUrlView', {
      destination: webLink,
      title: 'Personalised products not relevant ?',
    });
  };

  render() {
    const { personalisedData, showMoreItems, loading } = this.state;
    const {
      navigation,
      selfieImageUrl,
      extraEventParameters = {},
      previousScreen = '',
    } = this.props;
    if (AppConfig.getBooleanValue(Config.PROFILE_HIDE_FACIAL_ANALYSIS) || isWeb()) {
      return null;
    }

    if (Utility.isBlank(selfieImageUrl)) {
      return <this.fetchingItemsNoSelfie />;
    }
    if (loading) {
      return <this.fetchingItems />;
    }
    if (Utility.isBlank(personalisedData?.objects)) {
      return null;
    }
    return (
      <View>
        <this.fetchedItems />
        {showMoreItems && (
          <View>
            <List
              itemData={personalisedData}
              navigation={navigation}
              previousScreen={
                previousScreen || `personalizedHorizontal:${this.props.slug}`
              }
              extraEventParameters={extraEventParameters}
            />
            <TouchableOpacity
              style={{
                height: 40,
                width: Utility.getScreenWidth() - 12,
                justifyContent: 'center',
                marginTop: -20,
              }}
              onPress={this.openWebLink}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto-Medium',
                  color: colors.cta.lightBlue,
                  // textAlign: 'right',
                  position: 'absolute',
                  right: 16,
                }}
              >
                Personalised products not relevant ?
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  selfieImageUrl: store.UserAccountInfo.profile.selfie_image_url,
  userInfo: store.UserAccountInfo,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchPersonalizedList }, dispatch),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(FoxyPersonalisedItems),
);
