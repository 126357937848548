import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

const ProductRatingStyles = StyleSheet.create({
  ratingStatsContainer: {
    marginTop: 30,
    height: 69,
    width: Utility.getScreenWidth() - 2 * Utility.padding,
    marginLeft: Utility.padding,
    marginRight: Utility.padding,
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  ratingTextStyle: {
    fontSize: 25,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    lineHeight: 29,
    fontFamily: 'Roboto-Regular',
  },
  ratingAndReviewTextContainer: {
    height: 35,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ratingAndReviewText: {
    fontSize: 10,

    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.foxyBlack,
  },
  ratingBarsContainer: { height: 69, flex: 0.6 },
  ratingBarContainer: {
    flex: 0.2,
    marginRight: 16,
    flexDirection: 'row',
  },
  smallRatingTextStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 10,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  smallStarAndRatingNumberContainer: {
    flex: 0.15,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  smallStar: {
    height: 7,
    width: 8,
    marginLeft: 2,
    marginTop: 3,
  },
  barRatingCountContainer: {
    flex: 0.2,
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 8,
  },
  barContainer: { height: 5, backgroundColor: '#d8d8d8', borderRadius: 2.5 },
  defaultBarStyle: {
    position: 'absolute',
    left: 0,
    height: 5,
    borderRadius: 2.5,
  },
  rateProductMessageText: {
    lineHeight: 17,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    alignSelf: 'flex-start',
  },

  ratingTextContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rateProductContainer: {
    backgroundColor: colors.white,
  },
  rateTextContainer: { paddingTop: 15, paddingHorizontal: 12 },
  rateProductCard: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginBottom: 16,
  },
  rateProductCardForDynamicElement: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: 16,
    marginLeft: 16,
    marginTop: 10,
  },
  reviewComponentContainer: {
    width: Utility.getScreenWidth() - 2 * Utility.largePadding,
    marginLeft: Utility.largePadding,
    marginRight: Utility.largePadding,
  },
  reviewComponentHeader: {
    flexDirection: 'row',
    height: 36,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  userRatingImage: {
    height: 36,
    width: 36,
    borderRadius: 18,
  },
  reviewContainer: { marginTop: 37 },
  headerDetailContainer: {
    height: 36,
    width: Utility.getScreenWidth() - 2 * Utility.largePadding - 36,
    flexDirection: 'row',
  },
  nameAndStar: {
    height: 36,
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  dateContainer: {
    height: 36,
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
  },
  ratedByUserName: {
    marginLeft: 10,
    lineHeight: 16,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#24253d',
  },
  smallStarsContainer: {
    marginLeft: 10,
    flexDirection: 'row',
  },
  smallReviewStarStyle: {
    height: 14,
    width: 14,
    marginRight: 4,
  },
  dateText: {
    lineHeight: 14,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
    color: 'rgba(36, 37, 61, 0.5)',
  },
  verifiedText: {
    lineHeight: 11,
    fontFamily: 'Roboto-Medium',
    fontSize: 10,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.foxyPink,
  },
  redTickStyle: { marginRight: 2, marginTop: 1 },
  reviewContentContainer: {
    marginTop: 16,
    marginBottom: 8,
  },
  reviewHeadingText: {
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontSize: 13,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  reviewDetailText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 4,
  },
  readMore: {
    color: colors.foxyPink,
    alignSelf: 'flex-end',
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  verticalDivider: {
    width: 1,
    backgroundColor: colors.border,
    height: 70,
    marginLeft: 12,
    marginRight: 12,
  },
});

export default ProductRatingStyles;
