import { View, Text, StyleSheet, Image } from 'react-native';
import React from 'react';
import images from '../../theme/Images';

export default function BottomLayer() {
  return (
    <View style={styles.container}>
      <View style={styles.benefits}>
        <Benefit
          source={images.selfieAnalysis}
          text="Selfie Analysis & personalised recommendations"
        />
        <Benefit
          source={images.instantRewardsAndGifts}
          text="Instant Rewards & free gifts. Best deals in town"
        />
        <Benefit
          source={images.honestReviewsAndExpertContent}
          text="Honest Reviews & expert video content"
        />
        <Benefit
          source={images.sustainableBusiness}
          text="Sustainable Business Model with Foxy Zero"
        />
      </View>
      <View style={styles.circleContainer}>
        <View style={styles.dottedCircle} />
        <View style={styles.bottomCircle} />
      </View>
    </View>
  );
}


const Benefit = ({ source = '', text = '' }) => (
  <View style={styles.benefit}>
    <Image source={{ uri: source }} style={styles.image} />
    <Text style={styles.text}>{text}</Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 'calc(0.3401094605 * 100vw)',
    marginBottom: 50,
  },
  benefits: {
    width: '100%',
    height: '20vw',
    flexDirection: 'row',
    justifyContent: 'space-around',
    zIndex: 1,
    position: 'relative',
    top: '10%',
    paddingLeft: '8%',
    paddingRight: '3%',
    marginTop: 20,
  },
  benefit: {
    width: '12%',
  },
  image: {
    height: '6vw',
    width: '6vw',
  },
  text: {
    fontSize: '1.3vw',
    fontFamily: 'Roboto-Regular',
    lineHeight: 28,
  },
  circleContainer: {
    position: 'absolute',
    bottom: '2%',
    right: 0,
    width: '30%',
  },
  dottedCircle: {
    display: 'block',
    width: '13vw',
    height: '13vw',
    bottom: 0,
    float: 'left',
    borderRadius: '50%',
    border: '1px dashed black',
    position: 'absolute',
    zIndex: 1,
  },
  bottomCircle: {
    display: 'block',
    float: 'right',
    transform: 'rotate(-58.9deg)',
    backgroundImage: 'linear-gradient(184.48deg, #fcd0a3 3.63%, #e0effd 80.42%)',
    height: '33vw',
    width: '33vw',
    borderRadius: '50%',
    left: '20%',
    position: 'relative',
  },
});
