import React, { Component } from 'react';
import { View } from 'react-native';

class Card extends Component {
  render() {
    const { borderWidth = 0, cornerRadius, overflow = 'hidden', dataSet } = this.props;
    return (
      <View
        style={[
          {
            borderRadius: cornerRadius,
            borderWidth,
            borderColor: '#efefef',
            overflow,
          },
          this.props.style,
        ]}
        dataSet={dataSet}
      >
        {this.props.children}
      </View>
    );
  }
}

export default Card;
