import React, { PureComponent } from 'react';
import { View } from 'react-native';
import _ from 'lodash';
import ScaleAnimate from '../shared/ScaleAnimate';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ids, styles } from './styles';
import FastImageView from '../FastImageView';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';

class ReferralCard extends PureComponent {
  constructor(props) {
    super(props);
    const { itemData: { image_url = '' } = {} } = this.props;
    this.debouncedOnPress = _.debounce(this.onPress, 600, {
      leading: true,
      trailing: false,
    });
    this.minifiedImageUrl = Utility.getMinifiedImage(
      image_url,
      Utility.getScreenWidth(),
    );
  }

  onPress = () => {
    const {
      renderReferralModal,
      itemData,
      previousScreen,
      listIndex,
      itemData: {
        destination,
        external_link,
        title = '',
        display,
        slug = '',
      } = {},
      listContent,
      listName,
      listId = '',
      listSlug = '',
      listDisplay = '',
      index,
    } = this.props;
    renderReferralModal(true);

    const url = Utility.isPresent(external_link) ? external_link : destination;

    const listNames = url.split('/')[url.split('/').length - 1];

    if (Utility.isPresent(itemData)) {
      const { id, type } = itemData;
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        listNames,
        index,
        listId,
        listDisplay,
        Utility.isPresent(listName) ? listName : title,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
      );
    }
  };

  render() {
    const { itemData: { image_url = '', display } = {} } = this.props;
    if (isWeb()) return null;
    if (Utility.isBlank(image_url)) {
      return null;
    }

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedOnPress}>
        <View style={styles.largeBanner} dataSet={{ media: ids.largeBanner }}>
          <FastImageView
            resizeMode="stretch"
            source={this.minifiedImageUrl}
            style={styles.referralLargeBannerImage}
            height={isDesktop() ? (Utility.getScreenWidth() - 24) / 1.32 / 2 : undefined}
          />
        </View>
      </ScaleAnimate>
    );
  }
}

export default withNavigation(ReferralCard);
