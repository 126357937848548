import { takeEvery, call, put } from 'redux-saga/effects';
import { getInterestsList, FETCH_INTERESTS } from '../actions/LoginActions';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

const fetchInterestsList = (headers) => {
  const response = fetch(URL.GET_INTERESTS, {
    method: 'GET',
    headers: headers,
  });
  return response;
};

function* getInterests(action) {
  const { callback } = action;
  try {
    const headers = yield getApiHeaders();
    const response = yield call(fetchInterestsList, headers);
    if (response.status === 200) {
      const json = yield convertJsonFromResponse(response);
      if (callback && Utility.isPresent(json)) {
        callback(json);
      }
    } else {
      console.tron.log(
        `Error in getting Interests List: ${JSON.stringify(response)}`,
      );
    }
  } catch (error) {
    console.tron.log(`Error in getting Interests ${error}`);
  }
}

export default function* watchGetInterestsListSaga() {
  yield takeEvery(FETCH_INTERESTS, getInterests);
}
