import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import images from '../../theme/Images';
import { findIngredients } from '../../utils/Utility';
import _ from 'lodash';
import styles from './styles';
import {
  AnalyticsEvent,
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import { isPresent } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

const navigateToIngredients = (slug, navigation, uaavsArray) => {
  let uaavs = '';
  _.forEach(uaavsArray, (uaav) => {
    uaavs += `&user_attribute_allowed_value_id[]=${uaav.id}`;
  });
  navigateToScreen({
    navigation,
    type: 'push',
    screen: 'MoreItems',
    params: {
      slug: `/${slug}`,
      uaavs,
    },
  });
};

const Concern = (props) => {
  const {
    myAttributesValues,
    item,
    heroIngredients,
    goal,
    navigation,
    productCategorySlug,
    sku_id,
    id,
    matchedProduct = false,
  } = props;

  const positiveIngredients = {};
  const { newGood } = findIngredients(heroIngredients, myAttributesValues);

  _.forEach(newGood, (positive) => {
    if (isPresent(goal[`${positive.indication}`])) {
      positiveIngredients[`${goal[`${positive.indication}`]}`] = [
        ...(isPresent(positiveIngredients[`${goal[`${positive.indication}`]}`])
          ? positiveIngredients[`${goal[`${positive.indication}`]}`]
          : []),
        {
          name: `${positive.name}`,
          slug: `${positive.slug}`,
          id: `${positive.indication}`,
        },
      ];
    }
  });

  let beforeTextPositive = '';
  let afterTextPositive = '';
  let ctaPositive = '';

  return (
    <View>
      {isPresent(positiveIngredients) &&
        _.map(positiveIngredients, (e, index) => {
          if (e.length == 1) {
            ctaPositive = `Contains ${e[0].name} `;
            afterTextPositive = `to aid ${index}`;
          }
          if (e.length > 1) {
            ctaPositive = `${e.length} ingredients `;
            afterTextPositive = `to aid ${index}`;
          }

          return (
            <TouchableOpacity
              onPress={() => {
                AnalyticsManager.logEvent(
                  EventType.product.PRODUCT_ELEMENT_CLICK,
                  {
                    [EventParameterKey.SKU_ID]: sku_id,
                    [EventParameterKey.PRODUCT_ID]: id,
                    [EventParameterKey.ELEMENT_NAME]: 'foxy_match',
                    [EventParameterKey.ELEMENT_TYPE]: 'goal',
                  },
                );
                navigateToIngredients(productCategorySlug, navigation, e);
              }}
              disabled={matchedProduct}
            >
              <View style={styles.container}>
                <Image source={images.foxyMatch.thumbsUp} style={styles.icon} />
                {/* <Text style={styles.foxyMatchText}>{beforeTextPositive}</Text> */}

                <Text
                  style={styles.foxyMatchTextCta}
                  allowFontScaling={false}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  {ctaPositive}
                </Text>

                <Text
                  style={styles.foxyMatchText}
                  allowFontScaling={false}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  {afterTextPositive}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
    </View>
  );
};

export default Concern;
