import moment from 'moment';

export default class TimeUtility {
  static getTime(time) {
    const secs = time % 60;
    const s2 = (time - secs) / 60;
    const mins = s2 % 60;
    const hrs = (s2 - mins) / 60;
    const roundedSeconds = Math.round(secs);
    const hours =
      TimeUtility.addZeros(hrs) > 0 ? `${TimeUtility.addZeros(hrs)}:` : '';
    return `${hours}${TimeUtility.addZeros(mins)}:${TimeUtility.addZeros(
      roundedSeconds,
    )}`;
  }

  static addZeros(time) {
    return time < 10 ? `0${time}` : time;
  }

  static formatTime(time, format) {
    const Time = moment().startOf('day').seconds(time).format(format);

    return Time;
  }

  static getDiffInMinsFromCurrentTime = (previousTime) => {
    const currentTime = new Date().getTime();
    const timeDiff = Math.abs(currentTime - previousTime);
    const diffMins = Math.ceil(timeDiff / (1000 * 60 * 1)) - 1;

    return diffMins;
  };

  static isStoreOpen = (startTime, endTime) => {
    const hours = new Date().getHours();
    return hours >= startTime && hours <= endTime;
  };

  static convert24HoursTo12Hours = (time) => {
    return time > 12 ? time - 12 : time;
  };

  static isStartTimeIn24Hours(startDateStr) {
    const currentDate = new Date();
    const startDate = new Date(startDateStr);
    if (currentDate > startDate) {
      return false;
    }
    const timeDiff = startDate.getTime() - currentDate.getTime();
    const timeDiffInHours = timeDiff / (1000 * 60 * 60);
    if (timeDiffInHours < 24) {
      return true;
    }
    return false;
  }

  static addXDaysToDate = (date, dayNum) => {
    const selectedDayInMil = 60 * 60 * 24 * 1000 * dayNum;
    return new Date(date.getTime() + selectedDayInMil);
  };

  static setTimeForTimeStamp = (dateStr, timeToSetStr) => {
    const date = new Date(dateStr);
    const timeToSet = new Date(timeToSetStr);
    const hours = timeToSet.getHours();
    const minutes = timeToSet.getMinutes();
    date.setHours(hours);
    date.setMinutes(minutes);
    return new Date(date);
  };

  static isRoutineLive = (startDateStr, endDateStr) => {
    const currentDate = new Date().getDate();
    const startDate = new Date(startDateStr).getDate();
    const endDate = new Date(endDateStr).getDate();
    return currentDate >= startDate && currentDate <= endDate;
  };

  static getMonthDayDate = (someDateStr) => {
    const date = new Date(someDateStr);
    return `${TimeUtility.getMonthShortString(
      date.getMonth(),
    )} ${date.getDate()}`;
  };

  static isToday = (someDateStr) => {
    const today = new Date();
    const someDate = new Date(someDateStr);
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  static isTimeInFuture = (someDateStr) => {
    const currentDate = new Date();
    const someDate = new Date(someDateStr);
    return (
      someDate.getDate() > currentDate.getDate() &&
      someDate.getMonth() > currentDate.getMonth() &&
      someDate.getFullYear() > currentDate.getFullYear()
    );
  };

  static getMonthString(month) {
    let monthStr = 'December';
    switch (month) {
      case 0:
        monthStr = 'January';
        break;
      case 1:
        monthStr = 'February';
        break;
      case 2:
        monthStr = 'March';
        break;
      case 3:
        monthStr = 'April';
        break;
      case 4:
        monthStr = 'May';
        break;
      case 5:
        monthStr = 'June';
        break;
      case 6:
        monthStr = 'July';
        break;
      case 7:
        monthStr = 'August';
        break;
      case 8:
        monthStr = 'September';
        break;
      case 9:
        monthStr = 'October';
        break;
      case 10:
        monthStr = 'November';
        break;
      default:
        break;
    }
    return monthStr;
  }

  static getMonthShortString(month) {
    let monthStr = '';
    switch (month) {
      case 0:
        monthStr = 'Jan';
        break;
      case 1:
        monthStr = 'Feb';
        break;
      case 2:
        monthStr = 'Mar';
        break;
      case 3:
        monthStr = 'Apr';
        break;
      case 4:
        monthStr = 'May';
        break;
      case 5:
        monthStr = 'Jun';
        break;
      case 6:
        monthStr = 'Jul';
        break;
      case 7:
        monthStr = 'Aug';
        break;
      case 8:
        monthStr = 'Sep';
        break;
      case 9:
        monthStr = 'Oct';
        break;
      case 10:
        monthStr = 'Nov';
        break;
      case 11:
        monthStr = 'Dec';
        break;
      default:
        break;
    }
    return monthStr;
  }

  static getDateString(someDateStr) {
    const someDate = new Date(someDateStr);
    const hours =
      someDate.getHours() > 12 ? 12 - someDate.getHours() : someDate.getHours();
    const minutes = someDate.getMinutes();
    const amOrPm = someDate.getHours() > 12 ? 'PM' : 'AM';
    const date = `${TimeUtility.getMonthString(
      someDate.getMonth(),
    )} ${someDate.getDate()}, ${hours}:${minutes}${amOrPm}`;
    return date;
  }

  static getEndDay(endDateStr) {
    const currentDate = new Date();
    const endDate = new Date(endDateStr);
    if (currentDate < endDate) {
      const timeDiff = endDate.getTime() - currentDate.getTime();
      const timeDiffInMinutes = timeDiff / (1000 * 60);
      const timeDiffInHours = timeDiffInMinutes / 60;
      const timeDiffInDays = timeDiffInHours / 24;
      if (timeDiffInDays <= 7) {
        const daysStr = timeDiffInDays <= 1 ? 'day' : 'days';
        return `Ends in ${parseInt(timeDiffInDays)} ${daysStr}`;
      }
      const endMonthString = TimeUtility.getMonthShortString(
        endDate.getMonth(),
      );
      return `Ends ${endMonthString} ${endDate.getDate()}`;
    }
    return '';
  }

  static isExpired = (dateStr) => {
    return new Date(dateStr) < new Date();
  };

  static getStartDateString(startDateStr, endDateStr) {
    const currentDate = new Date();
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    if (currentDate < startDate) {
      const timeDiff = startDate.getTime() - currentDate.getTime();
      const timeDiffInMinutes = timeDiff / (1000 * 60);
      const timeDiffInHours = timeDiffInMinutes / 60;
      const timeDiffInDays = timeDiffInHours / 24;
      if (timeDiffInMinutes <= 60) {
        const minuteStr = timeDiffInMinutes <= 1 ? 'minute' : 'minutes';
        return `Starts in ${parseInt(timeDiffInMinutes)} ${minuteStr}`;
      }
      if (timeDiffInHours <= 24) {
        const hourStr = timeDiffInHours == 1 ? 'hour' : 'hours';
        // No string to be displayed when routine is starting within 24 hrs
        return ''; //`Starts in ${parseInt(timeDiffInHours)} ${hourStr}`;
      }
      if (timeDiffInDays <= 15) {
        const daysStr = timeDiffInDays <= 1 ? 'day' : 'days';
        return `Starts in ${parseInt(timeDiffInDays)} ${daysStr}`;
      }
      const startMonthString = RoutinesUtility.getMonthString(
        startDate.getMonth(),
      );
      return `Starts on ${startMonthString} ${startDate.getDate()}`;
    }

    if (currentDate > endDate) {
      const timeDiff = currentDate.getTime() - endDate.getTime();
      const timeDiffInMinutes = timeDiff / (1000 * 60);
      const timeDiffInHours = timeDiffInMinutes / 60;
      const timeDiffInDays = timeDiffInHours / 24;
      if (timeDiffInMinutes <= 60) {
        const minuteStr = timeDiffInMinutes <= 1 ? 'minute' : 'minutes';
        return `${parseInt(timeDiffInMinutes)} ${minuteStr} ago`;
      }
      if (timeDiffInHours <= 24) {
        const hourStr = timeDiffInHours === 1 ? 'hour' : 'hours';
        return `${parseInt(timeDiffInHours)} ${hourStr} ago`;
      }
      if (timeDiffInDays <= 15) {
        const daysStr = timeDiffInDays <= 1 ? 'day' : 'days';
        return `${parseInt(timeDiffInDays)} ${daysStr} ago`;
      }
      const endMonthString = RoutinesUtility.getMonthString(endDate.getMonth());
      return `Ended on ${endMonthString} ${endDate.getDate()}`;
    }
    return `${RoutinesUtility.getMonthString(
      startDate.getMonth(),
    )} ${startDate.getDate()}`;
  }

  static getTimeDiff(eventOccureTime, pageLoadedAt, diffInSec = false) {
    if (diffInSec) {
      return (
        Math.abs(eventOccureTime.getTime() - pageLoadedAt.getTime()) / 1000
      );
    }
    return Math.abs(eventOccureTime.getTime() - pageLoadedAt.getTime());
  }
}
