import { useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { isNative } from '../../utils/BooleanUtility';

export default function useProductData() {
  const { params = {} } = useRoute();
  const productData = useSelector(state => state.product) || {};
  if (isNative()) return params;
  const { shortSlug } = params;
  if (shortSlug === productData.shortSlug) {
    return productData;
  }
  return { shortSlug };
}
