import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import _ from 'lodash';
import size from '../../../theme/Fonts';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

const ArtistOnboardingStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    top: 16,
    width: Utility.getScreenWidth(),
  },
  column: {
    flexDirection: 'column',
    flex: 0.9,
    paddingLeft: 10,
    alignSelf: 'center',
  },
  travelTimeHeader: {
    flexDirection: 'column',
    paddingLeft: 10,
  },
  salonSubContainer: { marginLeft: 20, marginRight: 20 },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  heading: {
    fontSize: size.h1,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
  },
  greadientStyle: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
  },
  descriptionFont: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
  },
  bottomBar: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    right: 0,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomText: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: colors.foxyBlack,
  },
  listHeader: {
    fontFamily: 'Roboto-Bold',
    color: colors.silver,
    marginTop: 30,
    fontSize: size.h5,
  },

  // Css for Service Coverage

  rowLayout: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1,
    paddingLeft: 15,
  },
  time_box: {
    height: 90,
    width: Utility.getScreenWidth() / 5,
    borderWidth: 1,
    borderColor: colors.borderDark,
    padding: 4,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  time_box_active: {
    height: 90,
    width: Utility.getScreenWidth() / 5,
    borderWidth: 2,
    borderColor: colors.green,
    padding: 4,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  safeAreaContainer: {
    flex: 1,
    width: Utility.getScreenWidth(),
    paddingTop: 15,
  },
  scrollview: {
    flex: 1,
  },
  onboardingHeaderStyle: {
    height: 65,
    paddingLeft: 20,

    paddingRight: 20,
    justifyContent: 'center',

    width: Utility.getScreenWidth(),
  },
  onboardinNavigationHeaderStyle: {
    height: 55,
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    overflow: 'visible',
  },
  navigationBackButtonContainer: {
    // position: 'absolute',
    height: 32,
    width: 32,
    alignSelf: 'center',
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    marginLeft: 12,
    // left: 12,
    // bottom: 8,
    zIndex: 1,
  },
  navigationHeaderText: {
    // position: 'absolute',
    // bottom: 12,
    // left: 48,
    // right: 88,
    fontFamily: 'Roboto-Regular',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  secondaryButtonContainer: {
    // alignSelf: 'center',
    // justifyContent: 'center',
    // alignItems: 'center',
    position: 'absolute',
    right: 16,
    top: 5,
  },
  navigationHeaderSecondaryText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
  },
  animatedProgressbar: {
    position: 'absolute',
    bottom: 0,
  },
  navbarIconDefaultStyle: {
    width: 20,
    height: 17.5,
    resizeMode: 'contain',
  },
  onboardingTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 20,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  onboardingSubTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
  },
  skipButtonInHeader: {
    position: 'absolute',
    right: 20,
    bottom: 34,
  },
  artistTypeContainer: {
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 4,
    margin: 4,
    width: _.memoize(Utility.getDynamicWidth)(2, 26),
    height: 0.5 * _.memoize(Utility.getDynamicWidth)(2, 26),
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  artistTypeImage: {
    height: 30,
    width: 30,
    // resizeMode: 'contain',
    overflow: 'visible',
  },
  artistTypeText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    textAlign: 'center',
  },
  brandAndContentContainer: {
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 4,
    margin: 4,
    width: _.memoize(Utility.getDynamicWidth)(3, 32),
    height: _.memoize(Utility.getDynamicWidth)(3, 32),
    overflow: 'hidden',
  },
  brandImageStyle: {
    // resizeMode: 'cover',
    width: _.memoize(Utility.getDynamicWidth)(3, 32),
    height: _.memoize(Utility.getDynamicWidth)(3, 32),
    borderRadius: 4,
  },
  brandName: {
    position: 'absolute',
    alignSelf: 'center',
    top: '50%',
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontSize: size.h4,
    textAlign: 'center',
  },
  artistTypeFlatlist: {
    marginLeft: 15,
    marginRight: 15,
  },
  servicesFlatlist: {
    marginLeft: 20,
    marginRight: 20,
    width: Utility.getScreenWidth() - 40,
  },
  brandsFlatlist: {
    marginLeft: 16,
    marginRight: 16,
  },
  circularTickInArtistType: {
    position: 'absolute',
    right: 6,
    top: 6,
  },
  serviceTypeContainer: {
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 4,
    width: '100%',
    justifyContent: 'flex-start',
    marginTop: 6,
    marginBottom: 6,
    overflow: 'hidden',
  },
  nameAndImageContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 108,
  },
  serviceImage: {
    height: 56,
    width: 56,
    marginLeft: 8,
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 2,
  },
  priceContainer: {
    position: 'absolute',
    bottom: 0,
    height: 36,
    backgroundColor: colors.borderDark,
    width: '100%',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleAndSubtitleContainer: {
    justifyContent: 'flex-start',
  },
  serviceTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 8,
    marginLeft: 10,
  },
  serviceDetails: {
    fontFamily: 'Roboto-Regular',
    color: colors.silver,

    fontSize: size.h4,
    marginLeft: 10,
    marginRight: 60,
    marginBottom: 4,
  },
  priceStartsText: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,

    fontSize: size.h4,
    marginLeft: 16,
  },
  priceTextContainer: {
    position: 'absolute',
    alignSelf: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  priceText: {
    fontFamily: 'Roboto-Bold',
    color: colors.white,
    fontSize: size.h2,
  },
  chevronDown: {
    marginLeft: 4,
    marginTop: 4,
    resizeMode: 'cover',
  },
  genderSelectionContainer: {
    width: '100%',
    height: 34,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  genderContainer: {
    height: 34,
    borderRadius: 4,
    borderWidth: 1,
    width: Utility.getDynamicWidth(2, 20),
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkbox: {
    marginLeft: 8,
  },
  genderText: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,

    fontSize: size.h2,
    marginLeft: 8,
  },
  genderimage: {
    position: 'absolute',
    right: 16,
    alignSelf: 'center',
    marginTop: 4,
  },
  gradientDivider: {
    height: 2,
    width: Utility.getScreenWidth(),
    marginBottom: 4,
  },
  referContainer: {},
  marginTop: {
    marginTop: 30,
  },

  // Congratulation Screen
  congratsContainer: {
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  congratsImageContainer: {
    height: '100%',
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
  },
  innerContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  contentAreaText: {
    flex: 0.8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardArtistProfile: {
    height: 156,
    marginLeft: 12,
    marginRight: 12,
    bottom: 10,
    borderRadius: 8,
    backgroundColor: colors.white,
    alignItems: 'center',
    flexDirection: 'row',
  },
  glitterImage: {
    position: 'absolute',
    top: 0,
    height: '80%',
    width: '100%',
    overflow: 'visible',
  },
  cardVideoCreate: {
    height: 312,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    backgroundColor: colors.white,
    marginLeft: 12,
    marginRight: 12,
  },
  congratsHeadingText: {
    color: colors.white,
    fontSize: 21,
    fontFamily: 'Roboto-Bold',
  },
  congratsSubHeadingText: {
    color: colors.white,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
  },
  circleImage: {
    height: 84,
    width: 84,
    borderRadius: 42,
    marginLeft: 8,
  },
  artistName: {
    fontSize: size.h2,
    color: colors.foxyBlack,
  },
  artistHandle: {
    fontSize: size.h4,
    color: colors.silverLit,
  },
  levelBox: {
    height: 30,
    marginTop: 10,
    width: 98,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.silverLit,
    borderWidth: 1,
    flexDirection: 'row',
    borderRadius: 8,
  },
  levelText: {
    fontSize: size.h4,
  },
  referFriendsHeading: {
    fontSize: 21,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
  },
  inviteLinkBox: {
    borderColor: colors.silverLit,
    borderWidth: 1,
    left: 0,
    borderRadius: 8,

    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    width: 252,
    right: 0,
  },
  inviteLinkStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },
  videoCreateTitleContainer: {
    flex: 0.3,
    width: '100%',
  },
  videoCreateImage: {
    width: '100%',
    flex: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconColumn: {
    flexDirection: 'column',

    justifyContent: 'center',
    alignItems: 'center',
  },
  iconText: {
    fontSize: size.h5,
    fontFamily: 'Roboto-Regular',
  },
  inviteButtonLayout: {
    position: 'absolute',
    bottom: 12,
    height: 46,
    width: Utility.getScreenWidth() - 52,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    backgroundColor: colors.green,
  },
  closeButton: {
    position: 'absolute',
    right: 12,
    top: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    borderRadius: 20,
    backgroundColor: 'transparent',
    width: 32,
    padding: 10,
  },

  interestText: {
    position: 'absolute',
    fontSize: size.h4,
    color: colors.white,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    elevation: 1,
    shadowRadius: 2,
    shadowColor: colors.black,
    shadowOpacity: 1,
    bottom: 4,
    left: 6,
    right: 6,
    zIndex: 1,
    shadowOffset: { width: 1, height: 1 },
  },
  spacing: {
    width: '100%',
    height: 64,
  },
  readMoreText: {
    color: colors.foxyBlue,
    alignSelf: 'center',
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginRight: 20,
    marginBottom: 4,
  },
  autoCompleteHeading: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
  },
  autocompleteDescription: {
    color: colors.silver,
    fontSize: size.h4,
  },
  autocompleteContainer: {
    backgroundColor: '#ffffff',
    padding: 8,
    marginTop: 3,
    marginBottom: 3,
    flexDirection: 'column',
  },
  heading_active: {
    fontSize: size.h1,
    color: colors.green,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
  },

  descriptionFont_active: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.green,
  },
  createVideoText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 20,
    alignSelf: 'center',
  },
  fontMedium: {
    fontSize: 16,
  },

  navigationHeaderSubtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
  },

  permission_layout_row: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  permission_layout_head_text: {
    fontSize: size.h1,
    //  marginTop: 30,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  permission_layout_desc_text: {
    fontSize: size.h3,
    color: colors.silver,
    alignContent: 'center',
    fontFamily: 'Roboto-Regular',
    marginTop: 6,
    textAlign: 'justify',
  },
  permission_layout_container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  permission_layout_innerContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 7,
    padding: 15,
    height: 330,
    width: 300,
    flexDirection: 'column',
    // justifyContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  permission_layout_button: {
    height: 46,
    backgroundColor: colors.green,
    width: 230,
    // marginTop: 40,
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',

    justifyContent: 'center',
  },
  permission_layout_buttonText: {
    color: colors.white,
    fontSize: size.h1,
    marginLeft: 10,
    fontFamily: 'Roboto-Bold',
  },
  centeredRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  centeredItems: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ArtistOnboardingStyles;
