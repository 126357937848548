import images from '../../theme/Images';

export const SocialMediaOptions = [
  {
    image: images.instagramIcon,
    title: 'Instagram: Follow Today!',
    backgroundColor: '#F0DCDD',
    identifier: 'instagram',
    social_media_platform_id: 1,
  },
  {
    image: images.twitterIcon,
    title: `Let's Tweet it out!`,
    backgroundColor: '#DEEBFA',
    identifier: 'twitter',
    social_media_platform_id: 2,
  },
  {
    image: images.email,
    title: 'Write to me here',
    backgroundColor: '#FDF4D1',
    identifier: 'email',
    social_media_platform_id: 4,
  },
  {
    image: images.socialMediaHolder,
    title: `I'm on Profile!`,
    identifier: 'profile',
    backgroundColor: '#4285f42b',
    social_media_platform_id: 61,
  },
  {
    image: images.foxy_logo_small,
    title: 'Foxy: All things beauty',
    identifier: 'foxy',
    cta_url: 'https://foxy.in',
    backgroundColor: '#0000001a',
  },
];

export const getProAccountObjectForId = (pro_accounts, id) => {
  return (
    pro_accounts.find(
      (proAccount) => proAccount.social_media_platform_id === id,
    ) || {}
  );
};


export default { SocialMediaOptions, getProAccountObjectForId };
