import React, { Component } from 'react';
import { View, ActivityIndicator, BackHandler } from 'react-native';
import { debounce } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchListDetail } from '../../actions/ActionTypes';
import Addresses from '../../components/cart/CartAddresses';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';

export class MediaPlaylist extends Component {
  constructor(props) {
    super(props);
    const { route } = props;
    this.slug = route.params?.slug;
    this.debounceNavigateToCart = debounce(this.navigateToCart, 1000, {
      leading: true,
      trailing: false,
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getMediaPlaylist();
    }, 0);
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  navigateToCart = () => {
    const { navigation } = this.props;
    navigation.replace('Cart');
  };

  getMediaPlaylist = () => {
    const {
      itemData = {},
      fetchListDetail,
      navigation,
      showToast,
    } = this.props;
    fetchListDetail(this.slug, 0, (success, response) => {
      if (success && response.objects.length > 0) {
        navigation.replace('ContentModal', {
          itemData: response.objects[0],
          listId: response.id,
          index: 0,
          listData: response,
          id: itemData.id,
        });
      } else {
        navigation.goBack();
      }
    });
  };

  render() {
    const { address, onRefresh } = this.props;

    return (
      <View
        style={{
          height: Utility.getScreenHeight(),
          width: Utility.getScreenWidth(),
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          bottom: 0,
          zIndex: 1,
          backgroundColor: colors.translucent,
        }}
      >
        <ActivityIndicator animating color={colors.foxyPink} size='large' />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchListDetail,
    },
    dispatch,
  ),
});

export default withNavigation(connect(null, mapDispatchToProps)(MediaPlaylist));
