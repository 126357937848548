import React, { Component } from 'react';
import { NativeModules, Platform } from 'react-native';
import Utility from './Utility';
import { DeviceEventEmitter } from 'react-native';
import HeadphoneDetection from 'react-native-headphone-detection';
import { ANDROID } from '../config/Constants';
import { isWeb } from './BooleanUtility';

export default class AudioHandler extends Component {
  static isMuted = true;

  static initialize = () => {
    if (isWeb()) return;
    HeadphoneDetection.isAudioDeviceConnected().then((value) => {
      AudioHandler.updateAudioStatus(value);
    });

    AudioHandler.setListener();
  };

  static updateAudioStatus = (value) => {
    AudioHandler.isMuted = !(value.audioJack || value.bluetooth);
    if (!AudioHandler.isMuted) {
      this.changeAudioSessionCategoryToPlayBack();
      DeviceEventEmitter.emit('headPhoneStatus', 'connected');
    } else {
      DeviceEventEmitter.emit('headPhoneStatus', 'disconnected');
    }
  };

  static setListener = () => {
    HeadphoneDetection.addListener((callback) => {
      AudioHandler.updateAudioStatus(callback);
    });
  };

  // static addListener = ((connection) => void) => EventSubscription;

  static changeAudioSessionCategoryToAmbient = () => {
    if (Utility.isIOS()) {
      NativeModules.AudioManager.setAudioCategoryToAmbient();
    } else if (
      Utility.isAndroid() &&
      Platform.Version >= ANDROID.BUILD.VERSIONS.OREO_PRE //AudioFocusRequest.requestBuilder is not available below oreo_pre
    ) {
      NativeModules.UserPreferences.getAudioFocus();
    }
  };

  static changeAudioSessionCategoryToPlayBack = () => {
    if (Utility.isIOS()) {
      NativeModules.AudioManager.setAudioCategoryToPlayback();
    }
  };
}
