import { StyleSheet, Platform } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

const headerStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  listContainer: {
    width: '100%',
    paddingBottom: Utility.padding - Utility.spacingBetweenItems,
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 32,
    marginBottom: 8,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 12,
    marginRight: 12,
    marginTop: 16,
  },
  emptyView: {
    height: 8,
    width: '100%',
    backgroundColor: colors.background,
  },
  headerImage: {
    width: 28,
    height: 28,
    marginRight: 8,
  },
  headerHeadingContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 12,
    marginRight: 12,
    marginTop: 12,
  },
  headerHeadingContainerWithoutMargin: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 12,
    marginRight: 12,
  },
  headingTitleContainer: {
    // flex: 1,
    width: Utility.getScreenWidth() - 32,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headingTitleContainerIcon: {
    // flex: 1,
    width: Utility.getScreenWidth() - 32,
    flexDirection: 'row',

    alignItems: 'center',
  },
  headingTitleContainerDpIcon: {
    flex: 1,
    width: Utility.getScreenWidth() - 32,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  headingSubtitle: {
    marginTop: 4,
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#979BAA',
    alignContent: 'flex-start',
    marginRight: 36,
  },
  headingSubtitleWithEmoji: {
    marginTop: 4,
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#979BAA',
    alignContent: 'flex-start',
    marginRight: 36,
  },
  headingTitle: {
    fontSize: 18,
    lineHeight: 21,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    alignContent: 'flex-start',
  },
  headingTitleWithWidth: {
    fontSize: 18,
    lineHeight: 21,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    alignContent: 'flex-start',
    width: Utility.getScreenWidth() - 100,
  },
  headingTitleDpIcon: {
    fontSize: 18,
    lineHeight: 21,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    alignContent: 'flex-start',
    width: Utility.getScreenWidth() - 120,
  },
  subHeading: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    flex: 1,
    alignContent: 'flex-start',
  },
  morebuttonstyle: {
    fontSize: 14,
    color: colors.cta.lightBlue,
    alignContent: 'flex-end',
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
    marginLeft: 12,
  },
  moreButtonWithImageStyle: {
    fontSize: 14,
    color: colors.cta.lightBlue,
    alignContent: 'flex-end',
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
  },
  morebuttonFooterstyle: {
    fontSize: 14,
    color: colors.cta.lightBlue,
    alignContent: 'flex-end',
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
    marginRight: 12,
    marginBottom: 16,
  },
  navbarIconDefaultStyle: {
    resizeMode: 'contain',
    height: 24,
    width: 24,
  },
  navigationBackHeader: {
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
    height: 24,
    width: 24,
  },
  navbarProfileIconStyle: {
    width: 30,
    height: 30,
    position: 'absolute',
    // resizeMode: 'cover',
    borderRadius: 15,
    overflow: 'hidden',
    borderWidth: 0.7,
    borderColor: colors.border,
    left: 5,
    top: 0,
    ...Platform.select({
      web: { left: 5 },
      default: { left: -0 },
    }),
  },
  navbarProfileIconStyleLarge: {
    width: 56,
    height: 56,
    borderRadius: 28,
    overflow: 'hidden',
    borderWidth: 0.7,
    borderColor: colors.border,
  },
  notificationStyle: {
    width: 24,
    height: 24,
  },
  notificationStyleWithTint: {
    width: 24,
    height: 24,
    tintColor: Config.WISHLIST_HEADER_ICON_TINT_COLOR,
  },
  notificationStyleWhite: {
    width: 17.5,
    height: 20,
    tintColor: 'white',
  },
  rightNavbarHeaderStyle: {
    flexDirection: 'row',
    width: 100,
    justifyContent: 'flex-end',
    ...Platform.select({
      android: {},
      ios: {},
      web: { marginRight: 10 },
    }),
  },
  cartIconMargin: {
    marginRight: 10,
  },
  leftNavbarHeaderStyle: {
    flexDirection: 'row',
    width: 30,
    // justifyContent: 'flex-end',
    height: 30,
  },
  leftNavbarHeaderStyleLarge: {
    // flexDirection: 'row',
    width: 60,
    // justifyContent: 'flex-end',
  },
  backHeaderStyle: {
    flexDirection: 'row',
  },
  indicatorStyle: {
    height: 16,
    width: 16,
    backgroundColor: Config.BAG_ICON_INDICATOR_COLOR ?? colors.foxyPink,
    position: 'absolute',
    right: -5,
    bottom: -6,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cartItemCountIndicatorText: {
    fontSize: 10,
    color: colors.white,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
  },

  popOverContainer: {
    flexDirection: 'row',
    padding: 10,
    backgroundColor: colors.foxyBlue,
  },
  popOverText: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h3,
    color: colors.white,
  },
  popoverActionButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    marginLeft: 5,

    color: colors.instagram,
  },
  notificationIndication: {
    height: 8,
    width: 8,
    backgroundColor: colors.foxyPink,
    borderRadius: 4,
    position: 'absolute',
    top: 6,
    left: 20,
  },
  profileIconIndicator: {
    height: 8,
    width: 8,
    backgroundColor: Config.PROFILE_HEADER_ICON_INDICATOR_COLOR,
    borderRadius: 4,
    position: 'absolute',
    top: 20,
    left: 25,
    ...Platform.select({
      web: { left: 25 },
      default: { left: 20 },
    }),
  },
  textStyle: {
    fontSize: 14,
    color: colors.foxyBlack,
  },
  headerImageStyle: {
    height: 40,
    width: 40,
    borderRadius: 20,
    overflow: 'hidden',
    marginRight: 8,
    alignSelf: 'center',
  },
  headerSubContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  largeProgressBar: {
    position: 'absolute',
    height: 56,
    width: 56,
    bottom: 0,
    left: 0,
    borderRadius: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallProgressBar: {
    position: 'absolute',
    height: 35,
    width: 35,
    bottom: -2.5,
    left: -2,
    borderRadius: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftContainer: Platform.select({
    android: {},
    ios: {},
    web: { marginLeft: 10 },
  }),
  headerRightElementText: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    color: colors.green,
    lineHeight: 26,
  },
});

export default headerStyles;
