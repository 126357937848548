import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ActivityIndicator,
  BackHandler,
  StyleSheet,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ScrollView } from 'react-native-gesture-handler';
import {
  getOfferRewards,
  addFreeProductToCart,
  getCartItems,
  getFreeItemForOffer,
} from '../../actions/ActionTypes';
import { CartStyles } from './styles';
import StaticNavigationHeader from '../shared/StaticNavigationHeader';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import List from '../../containers/List/List';
import { AnalyticsManager } from '../../analytics';
import ContactUsCard from '../orders/ContactUsCard';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import RemoteConfig from '../../utils/RemoteConfig';
import OfferFeatureCard from '../../components/feature/OfferFeatureCard';
import { REMOTE_CONFIG_KEYS, CURRENCY_SYMBOL } from '../../config/Constants';

const freeProductScreenStyles = StyleSheet.create({
  loadingContainer: {
    height: Utility.getWindowHeight(),
    width: Utility.getScreenWidth(),
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: { flex: 1, backgroundColor: colors.white, borderRadius: 6 },
  scrollViewContainer: {
    flexDirection: 'column',
    marginBottom: Utility.bottomInset,
    backgroundColor: colors.background,
  },
  offerText: { color: colors.foxyBlack },
  footerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
  },
  footerText: { fontSize: 14, color: '#8C8E9B' },
  contactUsCardWrapper: {
    paddingLeft: 15,
    paddingRight: 25,
    marginTop: 20,
    marginBottom: 8,
    width: '100%',
  },
});

class FreeProductScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem:
        !Utility.isBlank(this.props.cartOffers) &&
        !Utility.isBlank(this.props.cartOffers.sku)
          ? this.props.cartOffers.sku.sku_id
          : null,
      freeProductList: {},
    };
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    const { getOfferRewards } = this.props;
    const offerActive = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.active_offer);
    getOfferRewards(offerActive, (success, { reward = {} }) => {
      this.setState({
        isLoaderVisible: false,
        freeProductList: reward,
      });
    });
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  updateSelectedItem = (id) => {
    this.setState(
      {
        selectedItem: id,
        isLoaderVisible: false,
      },
      this.onItemSelect,
    );
  };

  header = () => (
    <View style={CartStyles.header}>
      <TouchableOpacity
        onPress={this.goBack}
        style={CartStyles.crossButtonContainer}
        hitSlop={Utility.getHitSlop()}
      >
        <Image style={CartStyles.crossImage} source={images.cross} />
      </TouchableOpacity>
    </View>
  );

  onPressConfirm = () => {
    const {
      addFreeProductToCart,
      getCartItems,
      navigation,
      getFreeItemForOffer,
    } = this.props;

    const { selectedItem } = this.state;
    this.setState({
      isLoaderVisible: true,
    });

    const active_offer = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.active_offer);
    addFreeProductToCart(selectedItem, active_offer, (success, response) => {
      this.setState({
        isLoaderVisible: false,
      });
      if (success) {
        getCartItems((response) => {
          if (!response) {
            this.showToast('Something went wrong', 1000);
          }
        });

        getFreeItemForOffer(active_offer);
      }
      navigation.goBack();
    });

    AnalyticsManager.logEvent('change_free_product', {
      screen: 'cart',
      sku: selectedItem,
    });
  };

  onItemSelect = () => {
    const {
      addFreeProductToCart,
      getCartItems,
      navigation,
      getFreeItemForOffer,
    } = this.props;
    const active_offer = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.active_offer);
    const { selectedItem } = this.state;
    this.setState({
      isLoaderVisible: true,
    });

    addFreeProductToCart(selectedItem, active_offer, (success, response) => {
      this.setState({
        isLoaderVisible: false,
      });
      if (success) {
        getCartItems((response) => {
          if (!response) {
            this.showToast('Something went wrong', 1000);
          }
        });

        getFreeItemForOffer(active_offer);
      }
    });

    AnalyticsManager.logEvent('change_free_product', {
      screen: 'cart',
      sku: selectedItem,
    });
  };

  onClickContactUsCard = () => {
    Utility.openWhatsApp('Hey, i need help');
  };

  render() {
    const { viewFreeProductScreen, cartOffers } = this.props;
    const { freeProductList } = this.state;
    const { isLoaderVisible } = this.state;
    const offerText = isLoaderVisible
      ? 'Fetching personalized gifts for you'
      : 'Offer not found';
    if (Utility.isBlank(freeProductList)) {
      return (
        <>
          <StaticNavigationHeader title='Choose free gift' />
          <View style={freeProductScreenStyles.loadingContainer}>
            <ActivityIndicator
              size='large'
              color='green'
              animating={isLoaderVisible}
            />
            <Text style={freeProductScreenStyles.offerText}>{offerText}</Text>
          </View>
        </>
      );
    }
    return (
      <View style={freeProductScreenStyles.container}>
        <StaticNavigationHeader title='Choose free gift' />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={freeProductScreenStyles.scrollViewContainer}
          ref={(ref) => {
            this.scrollListReftop = ref;
          }}
        >
          <OfferFeatureCard itemData={cartOffers} inFreeProducts />
          {Utility.isPresent(freeProductList) && (
            <List
              itemData={freeProductList}
              id={freeProductList.id}
              previousScreen='Free'
              updateSelectedItem={this.updateSelectedItem}
              selectedItem={this.state.selectedItem}
              freeProductScreen
            />
          )}
          <View style={freeProductScreenStyles.footerContainer}>
            <Text style={freeProductScreenStyles.footerText}>
              Claim your free gift on order above {CURRENCY_SYMBOL}499
            </Text>
            <ContactUsCard
              wrapperStyle={freeProductScreenStyles.contactUsCardWrapper}
              title='Need help ?'
              description='Click here to get connected with support'
              onRowClick={this.onClickContactUsCard}
            />
          </View>
        </ScrollView>

        <FoxyShadowButton
          width={Utility.getScreenWidth() - 34}
          title='Confirm'
          onPress={this.onPressConfirm}
          backgroundColor={colors.primaryActionBackgroundColor}
          style={styles.payButton}
          Loading={isLoaderVisible}
          firstColor={colors.linearGradientGreenFirst}
          secondColor={colors.linearGradientGreenSecond}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  payButton: {
    marginBottom: Utility.bottomInset + 16,
    paddingTop: 4,
    alignSelf: 'center',
  },
});

const mapStateToProps = (state) => ({
  cartOffers: state.cart.cartOffers,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getOfferRewards,
      addFreeProductToCart,
      getCartItems,
      getFreeItemForOffer,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(FreeProductScreen),
);
