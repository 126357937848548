import { isDesktop } from '../../utils/BooleanUtility';

export const LAYOUT_CONFIG = {
  minRailCount: 3,
  minRailContForVerticalRail: 1,
  minListCount: 1,
  minMoreCount: 1,
  minGridCount: isDesktop() ? 4 : 2,
  maxRailCount: 10,
  numColumn: {
    artist: 3,
    ad: 3,
    product: 2,
    salon: 2,
    list: 2,
    media: 2,
  },
};

export default LAYOUT_CONFIG;
