import React, { Fragment, PureComponent } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
  FlatList,
} from 'react-native';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withEither } from '../../lib/Monads';
import { CURRENCY_SYMBOL } from '../../config/Constants';
import { ProductInfoStyles, ProductDetailStyles, productDetailIds } from './styles';
import Images from '../../theme/Images';
import Utility from '../../utils/Utility';
import { withMaybe } from '../../lib/Monads';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import VariantList from './VariantList';
import VariantImagesWithHeader from './VariantImagesWithHeader';
import { addToCart } from '../../actions/ActionTypes';
import UnavailableProductVideoCard from './UnavailableProductVideoCard';
import VariantModalQuantity from './VariantModalQuantity';
import Config from '../../libraries/ReactNativeConfig';
import ProductRatingAndMoreInfo from './ProductRatingAndMoreInfo';
import { isDesktop } from '../../utils/BooleanUtility';

class VariantSectionList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      variantsInCart: this.getvariantsInCart(),
    };
  }

  componentDidUpdate(prevProps) {
    const { cartItems } = this.props;
    const preCartItems = prevProps.cartItems;
    if (cartItems.length !== preCartItems.length) {
      this.setState({
        variantsInCart: this.getvariantsInCart(),
      });
    }
  }

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  discountConditionFn = (props) =>
    parseFloat(props.sp) === parseFloat(props.mrp);

  state = {
    showVideoCard: false,
  };

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  VARIANT_TEXT = {
    RECOMMENDED: 'Recommended for you',
    PREMIUM: 'Premium/New Arrivals',
    UNSTOCKED: 'Not Reviewed',
    SPECIAL_PRICE: 'Special Price',
    MORE: 'Standard Variants',
    OUT_OF_STOCK: 'Out of stock',
    IN_BAG: 'In your bag',
  };

  getvariantsInCart = () => {
    const { cartItems, itemData } = this.props;
    if (Utility.isBlank(cartItems)) {
      return [];
    }
    const variantArray = _.compact(
      cartItems.map((cartItem) => {
        if (
          !Utility.isBlank(cartItem.variant) &&
          !Utility.isBlank(cartItem.product) &&
          itemData.id === cartItem.product.id
        ) {
          return cartItem.variant;
        }
      }),
    );
    return variantArray;
  };

  PricingComponent = (props) => {
    const { mrp, sp, discount, showPrice } = props;
    if (!showPrice) {
      return null;
    }
    const styles = ProductInfoStyles;
    return (
      <View style={styles.priceContainer}>
        <Text style={styles.spStyle}>{`${CURRENCY_SYMBOL}${sp} `}</Text>
        <this.conditionalPriceText style={styles.mrpStyle} sp={sp} mrp={mrp}>
          {`${CURRENCY_SYMBOL}${mrp}`}
        </this.conditionalPriceText>
        <this.conditionalDiscountText
          style={styles.discountRateStyle}
          discount={discount}
        >
          {`(${discount})`}
        </this.conditionalDiscountText>
      </View>
    );
  };

  onClickCross = () => {
    this.setState({ showVideoCard: false });
  };

  onInfoPress = () => {
    this.setState({ showVideoCard: true });
  };

  showIconConditionfn = ({ title }) => title === this.VARIANT_TEXT.UNSTOCKED;

  notReviewedVariants = (props) => {
    const { title } = props;
    const { showVideoCard } = this.state;
    return (
      <View style={{ width: '100%' }}>
        <View
          style={ProductDetailStyles.notReviewedMessageTextContainerVariant}
        >
          <Text style={ProductDetailStyles.notReviewedMessageTextVariant}>
            {title}
          </Text>
          {
            <TouchableOpacity onPress={this.onInfoPress}>
              <Image
                source={Images.info}
                style={ProductDetailStyles.notReviewedMessageIcon}
              />
            </TouchableOpacity>
          }
        </View>
        <View
          style={{
            flex: 1,
            alignSelf: 'flex-start',
            marginTop: 10,
            marginLeft: 16,
          }}
        >
          {showVideoCard && (
            <UnavailableProductVideoCard
              onClick={this.onClickCross}
              inVariant
            />
          )}
        </View>
      </View>
    );
  };

  otherVariants = (props) => {
    const { title } = props;
    return <Text style={ProductInfoStyles.dockedShadeText}>{title}</Text>;
  };

  dockerShadeText = withEither(
    this.showIconConditionfn,
    this.notReviewedVariants,
  )(this.otherVariants);

  sectionHeader = ({ section: { title, data, showPriceInDock } }) => {
    const { itemData } = this.props;
    const { showVideoCard } = this.state;
    if (
      Utility.isBlank(data) ||
      Utility.isBlank(data[0]) ||
      data[0].length === 0
    ) {
      return null;
    }
    const height =
      showVideoCard && title === this.VARIANT_TEXT.UNSTOCKED ? 130 : 30;
    return (
      <View style={[ProductInfoStyles.variantDock, { height }]}>
        <this.dockerShadeText title={title} />
        <View style={{ marginTop: 4 }}>
          <this.PricingComponent
            mrp={itemData.mrp}
            sp={itemData.sp}
            discount={itemData.discount}
            showPrice={showPriceInDock}
          />
        </View>
      </View>
    );
  };

  sectionListRenderItem = ({ section = {} }) => {
    const {
      itemData,
      selectedVariant,
      updateSelectedVariant,
      previousScreen,
      cartItems,
      onItemAddToCartFromCollab,
      campaignId,
    } = this.props;
    const { showPrice, data = [], type } = section;
    if (
      Utility.isBlank(data) ||
      Utility.isBlank(data[0]) ||
      data[0].length === 0
    ) {
      return null;
    }
    return (
      <>
        <View style={ProductDetailStyles.sectionListItemContainer} />
        <VariantList
          showPrice={showPrice}
          allowedVariantValues={data[0]}
          selectedVariant={selectedVariant}
          updateSelectedVariant={updateSelectedVariant}
          type={type}
          cartItems={cartItems}
          itemData={itemData}
          previousScreen={previousScreen}
          onItemAddToCartFromCollab={onItemAddToCartFromCollab}
          campaignId={campaignId}
        />
      </>
    );
  };

  sizeVariantsList = () => {
    const {
      selectedMultiVariantPrimary: {
        multi_level_variant_attributes: secondaryVariantAttributes = [],
      } = {},
      selectedMultiVariantPrimary = {},
      selectedMultiVariantSecondary,
    } = this.props;
    if (Utility.isBlank(secondaryVariantAttributes)) return null;
    const styles = ProductInfoStyles;
    return (
      <>
        <Text style={styles.sectionListShadeText}>
          Size :{' '}
          <Text style={styles.selectedShadeText}>
            {selectedMultiVariantSecondary?.value}
          </Text>
        </Text>
        <FlatList
          data={secondaryVariantAttributes}
          horizontal
          renderItem={this.sizeVariantsPill}
          keyExtractor={this.sizeListKeyExtractor}
          style={ProductDetailStyles.secondaryVariantFlatlistStyle}
          contentContainerStyle={
            ProductDetailStyles.secondaryVariantFlatlistContainerStyle
          }
          extraData={{
            ...selectedMultiVariantPrimary,
            ...selectedMultiVariantSecondary,
          }}
        />
      </>
    );
  };

  sizeListKeyExtractor = (item) => `${item.value}`;

  sizeVariantsPill = ({ item, index }) => {
    const styles = ProductInfoStyles;
    const {
      handleVariantItemPress = () => {},
      selectedMultiVariantPrimary = {},
      selectedMultiVariantSecondary,
    } = this.props;
    const { value: parentVariantValue } = selectedMultiVariantPrimary;
    const isDisabled = item?.variant?.outOfStock;
    const isSelected =
      selectedMultiVariantSecondary?.value === item?.value && !isDisabled;
    const borderStyle = isSelected
      ? styles.selectedSizePillBorder
      : styles.unselectedSizePillBorder;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    return (
      <TouchableOpacity
        disabled={isDisabled}
        style={borderStyle}
        onPress={_.memoize(
          () => handleVariantItemPress(item, parentVariantValue),
          () => [item.value, parentVariantValue],
        )}
      >
        <Text style={textStyle}>{item?.value}</Text>
        {isDisabled && (
          <View style={ProductDetailStyles.disabledSizePillLine} />
        )}
      </TouchableOpacity>
    );
  };

  multipleVariantsList = () => {
    const {
      itemData,
      selectedVariant,
      updateSelectedVariant,
      previousScreen,
      cartItems,
      onItemAddToCartFromCollab,
      campaignId,
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
      multiVariantAttributes,
      handleVariantItemPress,
    } = this.props;
    const { variantsInCart } = this.state;
    const styles = ProductInfoStyles;
    const Container = isDesktop() ? ScrollView : Fragment;
    return (
      <Container style={styles.variantListContainerStyle} scrollEnabled>
        <View style={styles.variantListBottomPadding}>
          <View style={styles.sectionListContainer}>
            <VariantList
              showPrice={false}
              allowedVariantValues={multiVariantAttributes}
              selectedVariant={selectedVariant}
              updateSelectedVariant={updateSelectedVariant}
              cartItems={cartItems}
              itemData={itemData}
              previousScreen={previousScreen}
              onItemAddToCartFromCollab={onItemAddToCartFromCollab}
              campaignId={campaignId}
              selectedMultiVariantPrimary={selectedMultiVariantPrimary}
              selectedMultiVariantSecondary={selectedMultiVariantSecondary}
              variantsInCart={variantsInCart}
              handleVariantItemPress={handleVariantItemPress}
            />
          </View>

          <this.sizeVariantsList />
        </View>
      </Container>
    );
  };

  ProductRating = ({ itemData }) => {
    if (
      itemData.stocked_status === 'in_stock' ||
      itemData.stocked_status === 'stocked_out'
    ) {
      return (
        <View style={ProductDetailStyles.ratingsMarginTop}>
          <ProductRatingAndMoreInfo
            rating={itemData?.rating}
            id={itemData?.id}
            sku_id={itemData?.sku_id}
          />
        </View>
      );
    }
    return null;
  };

  render() {
    const {
      itemData,
      recommendedVariants,
      selectedVariantName,
      selectedVariantImage,
      selectedVariant,
      updateSelectedVariant,
      imageArray,
      previousScreen,
      cartItems,
      onItemAddToCartFromCollab,
      campaignId,
      imageWebpArray,
    } = this.props;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    const SectionListContainer = isDesktop() ? View : ScrollView;
    const ImageCarouselContainer = isDesktop() ? View : Fragment;
    return (
      <SectionListContainer style={ProductDetailStyles.sectionListContainer} dataSet={{ media: productDetailIds.sectionListContainer }}>
        <ImageCarouselContainer style={{ flex: 1 }}>
          <VariantImagesWithHeader
            itemData={itemData}
            imageArray={imageArray}
            selectedVariantName={selectedVariantName}
            selectedVariantImage={selectedVariantImage}
            previousScreen={previousScreen}
            imageWebpArray={imageWebpArray}
            selectedVariant={selectedVariant}
            hideSelectedVariantColor={this.multipleVartiantFn}
          />
          <this.ProductRating itemData={itemData} />
        </ImageCarouselContainer>
        <this.multipleVariantsList />
      </SectionListContainer>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  const { bag: { cartItems = {} } = {} } = store;
  return {
    cartItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addToCart,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(VariantSectionList);
