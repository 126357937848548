import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Vibration,
  TouchableOpacity,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../../../theme/Colors';
import size from '../../../../theme/Fonts';
import {
  APP_ASSET_URL,
  ROUTINE_ACTIVITY_TYPES,
} from '../../../../config/Constants';
import FastImageView from '../../../FastImageView';
import images from '../../../../theme/Images';
import FoxyButton from '../../../shared/FoxyButton';
import _ from 'lodash';
import Utility from '../../../../utils/Utility';
import HTML from 'react-native-render-html';
import RoutinesUtility from '../../RoutinesUtility';
import {
  getPollsData,
  selectPollOption,
} from '../../../../actions/RoutineActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PollActionCardContent from './PollActionCardContent';
import DynamicLinkManager from '../../../../utils/DynamicLinkManager';
import withNavigation from '../../../../utils/WithNavigation';
import NavigationService from '../../../../navigator/NavigationService';
import DebouncedTouchableOpacity from '../../../shared/DebouncedTouchableOpacity';
import { navigateToScreen } from '../../../../utils/NavigationUtility';
var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class RoutineActionCard extends Component {
  LAYOUT_COMPONENT = {
    feature: this.featureTypeContent,
    upload: this.featureTypeContent,
    poll: this.pollTypeContent,
  };

  constructor(props) {
    super(props);
    this.headerIcon = `${APP_ASSET_URL}/441/sample_action_icon.png`;
    const {
      itemData: {
        activity: { backgroundColor = '#F9DDFF' } = {},
        markedDone = false,
      } = {},
    } = this.props;
    this.state = {
      markAsDone: markedDone,
      isLoading: false,
      initialImageNumToRender: 0,
      pollQuestionsData: [],
    };
    const gradientColor = Utility.isBlank(backgroundColor)
      ? '#F9DDFF'
      : backgroundColor;
    this.gradientColors = [`${gradientColor}66`, gradientColor];
    this.fetchPollData();
  }

  fetchPollData = () => {
    const { itemData: { activity: { id = '' } = {}, activityType = '' } = {} } =
      this.props;
    if (activityType === 'Poll') {
      const { getPollsData } = this.props;
      getPollsData(id, (success, response) => {
        if (!success) return;
        try {
          new JSONAPIDeserializer({
            typeAsAttribute: false,
            pluralizeType: true,
            keyForAttribute: 'camelCase',
          })
            .deserialize(response)
            .then((data) => {
              console.tron.log(data, 'poll data');
              const { pollQuestions = [] } = data;
              this.setState({
                pollQuestionsData: pollQuestions,
              });
            });
        } catch (e) {}
      });
    }
  };

  featureTypeContent({ itemData }) {
    const { activity: { body = '' } = {}, activityType = '' } = itemData;
    const data = body?.replace(/(\r\n|\n|\r)/gm, '');
    if (Utility.isBlank(data)) return null;
    return (
      <HTML
        html={`${data}`}
        tagsStyles={{
          p: styles.htmlText,
          ol: styles.htmlText,
          ul: styles.htmlText,
          div: styles.htmlText,
          span: styles.htmlText,
        }}
      />
    );
  }

  pollTypeContent({
    pollsData,
    selectedPollOptionIds,
    onPollOptionPress,
    buttonVisibility,
    inactivePollTapped = () => {},
  }) {
    if (Utility.isBlank(pollsData)) return null;
    return (
      <View>
        <PollActionCardContent
          pollsData={pollsData}
          onPollOptionPress={onPollOptionPress}
          buttonVisibility={buttonVisibility}
          inactivePollTapped={inactivePollTapped}
        />
      </View>
    );
  }

  onPollOptionPress = (item) => {
    const { id: pollOptionId, pollQuestionId = '' } = item;
    const {
      selectPollOption,
      onActionPress = () => {},
      itemData: { id: activityId, activity: { id: pollId = '' } } = {},
    } = this.props;
    selectPollOption(
      pollId,
      pollQuestionId,
      [pollOptionId],
      (success, response) => {
        if (!success) return;
        try {
          new JSONAPIDeserializer({
            typeAsAttribute: false,
            pluralizeType: true,
            keyForAttribute: 'camelCase',
          })
            .deserialize(response)
            .then((data) => {
              const { pollQuestions = [] } = data;
              this.setState({
                pollQuestionsData: pollQuestions,
              });
              onActionPress(activityId, ROUTINE_ACTIVITY_TYPES.POLL);
            });
        } catch (e) {}
      },
    );
  };

  navigateToScreen = (route, slug, path, extra = {}, params, url) => {
    const { navigation } = this.props;
    navigation.navigate(route, { slug, extra, params, url });
  };

  onPlayPress = () => {
    const { itemData: { supportingUrl = '' } = {} } = this.props;
    DynamicLinkManager.handleDynamicLink(supportingUrl, this.navigateToScreen);
  };

  header = () => {
    const {
      itemData: {
        activity: {
          title = '',
          imageUrl = images.routines.sample_action_card_image,
        } = {},
        duration = '',
        points = '',
        supportingUrl,
      } = {},
    } = this.props;
    const image = Utility.isBlank(imageUrl)
      ? images.routines.sample_action_card_image
      : imageUrl;
    return (
      <View style={styles.headerContainer}>
        <FastImageView source={image} style={styles.headerIcon} />
        <View style={styles.titleContainer}>
          <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">
            {title}
          </Text>
          <Text style={styles.subtitle}>
            {duration} mins <Text style={styles.point}>•</Text> {points} points
          </Text>
        </View>
        {Utility.isPresent(supportingUrl) && (
          <DebouncedTouchableOpacity onPress={this.onPlayPress}>
            <FastImageView
              source={images.routines.play_icon}
              style={styles.playIcon}
            />
          </DebouncedTouchableOpacity>
        )}
      </View>
    );
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading: isLoading,
    });
  };

  navigateToScreen = (path, destination) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: path,
      params: {
        newDestination: destination, // webview reads data from destination
        slug: destination,
      },
    });
  };

  onActionButtonPress = () => {
    const {
      onActionPress,
      itemData: {
        id = '',
        activity: { arguments: extraData = {}, destination, externalLink } = {},
        participationId = '', //TODO: Changes this, participationId will not be served from backend now
      } = {},
      itemData,
    } = this.props;
    const navigateTo = destination || externalLink;

    this.setLoading(true);
    Vibration.vibrate(200);

    if (Utility.isPresent(navigateTo)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        navigateTo,
        this.navigateToScreen,
      );
    }

    const isUpload = extraData?.action === ROUTINE_ACTIVITY_TYPES.UPLOAD;
    const actionId = isUpload ? participationId : id;
    const activityType = isUpload
      ? ROUTINE_ACTIVITY_TYPES.UPLOAD
      : ROUTINE_ACTIVITY_TYPES.ACTION;
    onActionPress(id, activityType, this.setMarkAsDone, participationId);
  };

  setMarkAsDone = (markDone) => {
    this.setState({
      markAsDone: markDone,
    });
    this.setLoading(false);
  };

  getActionButtonColor = (isButtonDisabled) => {
    const { markAsDone, isLoading } = this.state;
    let buttonColor = colors.subtitle;
    if (markAsDone) {
      buttonColor = colors.disabled_green;
    } else if (!isButtonDisabled && !isLoading) {
      buttonColor = colors.black;
    }
    return buttonColor;
  };

  getActionButtonImage = () => {
    const { markAsDone, isLoading } = this.state;
    const { itemData: { activity: { arguments: extraData = {} } = {} } = {} } =
      this.props;
    let buttonIcon = null;
    if (markAsDone) {
      buttonIcon = images.whiteTick;
    } else if (extraData?.action === 'upload') {
      buttonIcon = images.routines.upload_icon;
    }
    return buttonIcon;
  };

  actionButton = () => {
    const {
      itemData: {
        activity: { ctaText = '', arguments: extraData = {} } = {},
        activityType,
      } = {},
      buttonVisibility = '',
    } = this.props;

    if (activityType === 'Poll') {
      return null;
    }
    const { markAsDone, isLoading } = this.state;
    if (buttonVisibility === 'hidden') return null;
    const title = markAsDone ? 'Done' : ctaText;
    const isButtonDisabled = buttonVisibility === 'disabled';
    const buttonColor = this.getActionButtonColor(isButtonDisabled);
    const buttonIcon = this.getActionButtonImage();
    return (
      <View style={styles.actionButtonContainer}>
        <FoxyButton
          title={title}
          show_button_icon={markAsDone || extraData?.action === 'upload'}
          actionButtonColor={buttonColor}
          onActionPress={this.onActionButtonPress}
          layoutStyle={styles.actionButton}
          textStyle={styles.actionButtonText}
          disabled={isButtonDisabled || markAsDone || isLoading}
          icon_type={buttonIcon}
          iconStyle={styles.actionButtonIcon}
          showLoader={isLoading}
        />
      </View>
    );
  };

  actionContent = () => {
    const {
      itemData: { activityType = '' } = {},
      itemData = {},
      buttonVisibility = '',
      inactivePollTapped = () => {},
    } = this.props;
    const { pollQuestionsData = [] } = this.state;
    const LayoutComponent =
      this.LAYOUT_COMPONENT[`${activityType}`.toLowerCase()];
    if (LayoutComponent === undefined || LayoutComponent === null) return null;
    return (
      <LayoutComponent
        itemData={itemData}
        pollsData={pollQuestionsData}
        onPollOptionPress={this.onPollOptionPress}
        buttonVisibility={buttonVisibility}
        inactivePollTapped={inactivePollTapped}
      />
    );
  };

  imageRailFooterComponent = () => {
    const { initialImageNumToRender } = this.state;
    const { dailyPhotos } = this.props;
    return (
      <View style={[styles.dailyImage, { backgroundColor: colors.lightGrey }]}>
        {dailyPhotos.length > initialImageNumToRender && (
          <Text style={styles.moreImagesText}>
            +{dailyPhotos.length - initialImageNumToRender}
          </Text>
        )}
      </View>
    );
  };

  uploadedImages = () => {
    const {
      dailyPhotos = [],
      itemData: {
        myPhotos = [],
        activity: { arguments: extraData = {} } = {},
      } = {},
    } = this.props;
    const { initialImageNumToRender } = this.state;
    if (Utility.isBlank(myPhotos) || extraData?.action !== 'upload')
      return null;
    return (
      <FlatList
        data={myPhotos}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={this.renderDailyImage}
        keyExtractor={(item) => `day_${item?.day}`}
        onLayout={this.onFlatListLayout}
        ListFooterComponent={this.imageRailFooterComponent}
        initialNumToRender={initialImageNumToRender}
      />
    );
  };

  onFlatListLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    this.setState({
      initialImageNumToRender: (width / 30).toFixed(0) - 1,
    });
  };

  renderDailyImage = ({ item }) => {
    return (
      <FastImageView
        source={Utility.getMinifiedImage(item, 22, 40)}
        style={styles.dailyImage}
      />
    );
  };

  render() {
    return (
      <LinearGradient
        colors={this.gradientColors}
        style={styles.container}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 0 }}
      >
        <this.header />
        <View style={styles.divider} />
        <this.actionContent />
        <this.uploadedImages />
        <this.actionButton />
      </LinearGradient>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getPollsData,
      selectPollOption,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(RoutineActionCard),
);

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    padding: 12,
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: 12,
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
  },
  headerContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  point: {
    color: colors.subtitle,
    width: 4,
    height: 4,
  },
  htmlText: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginVertical: 4,
  },
  subtitle: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
  },
  headerIcon: {
    width: 32,
    height: 32,
    marginRight: 12,
    resizeMode: 'contain',
  },
  playIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  actionButtonContainer: {
    height: 40,
    width: '100%',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    marginTop: 12,
  },
  actionButton: {
    left: 0,
    right: 0,
    height: 40,
    borderRadius: 8,
  },
  actionButtonText: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
  actionButtonIcon: {
    width: 18,
    height: 18,
    tintColor: colors.white,
    resizeMode: 'contain',
    marginRight: 4,
  },
  divider: {
    backgroundColor: colors.white,
    width: '100%',
    height: 1,
    marginVertical: 12,
  },
  dailyImage: {
    height: 40,
    width: 22,
    borderRadius: 4,
    resizeMode: 'cover',
    marginRight: 8,
    marginBottom: 12,
    justifyContent: 'center',
  },
  moreImagesText: {
    color: colors.black,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'center',
  },
});
