import React, { useState } from 'react';
import { Text, View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { paymentIds, PaymentStyles } from '../cart/styles';
import { PaymentOptions } from '../../config/PaymentConstants';
import PaymentOption from './PaymentOption';

const Payment = ({
  updateExpandableComponent,
  expandedStatus,
  createOrder,
  UtoP,
  paytmDetails,
  supportedIOSIntents,
  renderLoader,
  hideCashOnDelivery = false,
  semi_digital_cart,
}) => {
  const [upiHandle, setUpiHandle] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardExpire, setCardExpire] = useState('');
  const [cardCVV, setCardCVV] = useState('');

  const metaData = {
    upiHandle,
    setUpiHandle,
    cardExpire,
    setCardExpire,
    cardName,
    setCardName,
    cardNumber,
    setCardNumber,
    cardCVV,
    setCardCVV,
  };

  const PayOptionItem = (item) => {
    const showPaymentOption = !(hideCashOnDelivery && item?.id === 'cash');
    if (!showPaymentOption) {
      return null;
    }
    return (
      <PaymentOption
        item={item}
        expandedStatus={expandedStatus}
        updateExpandableComponent={updateExpandableComponent}
        createOrder={createOrder}
        UtoP={UtoP}
        metaData={metaData}
        paytmDetails={paytmDetails}
        supportedIOSIntents={supportedIOSIntents}
        renderLoader={renderLoader}
        semi_digital_cart={semi_digital_cart}
      />
    );
  };

  return (
    <>
      <Text style={PaymentStyles.preferredMethodsText} dataSet={{ media: paymentIds.preferredMethodsText }}>
        Pay via
      </Text>
      <View style={styles.container} dataSet={{ media: ids.container }}>
        {PaymentOptions.map(PayOptionItem)}
      </View>
    </>
  );
};

export default Payment;

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    '@media (min-width: 768px)': {
      position: 'relative',
      marginBottom: 200,
    },
  },
});
