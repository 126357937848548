const envVariables = process.env;

const Config = Object.keys(envVariables)
  .filter((key) => key.startsWith('REACT_APP_'))
  .reduce((result, key) => {
    let newKey = key.replace('REACT_APP_', '');
    if (newKey.includes('WEB_OVERRIDE_')) {
      newKey = newKey.replace('WEB_OVERRIDE_', '');
    }
    return { ...result, [newKey]: envVariables[key] };
  }, {});

export default Config;
