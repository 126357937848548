export const ENTER_STORE_MODE = 'ENTER_STORE_MODE';
export const EXIT_STORE_MODE = 'EXIT_STORE_MODE';
export const GET_STORE_INFORMATION = 'GET_STORE_INFORMATION';
export const GET_STORE_CART_STATUS = 'GET_STORE_CART_STATUS';
export const UPDATE_STORE_CART_STATUS = 'UPDATE_STORE_CART_STATUS';

export const enterStoreMode = (storeData) => {
  return {
    type: ENTER_STORE_MODE,
    storeData,
  };
};
export const exitStoreMode = () => {
  return {
    type: EXIT_STORE_MODE,
  };
};

export const getStoreInformation = (slug, callback) => {
  return {
    type: GET_STORE_INFORMATION,
    slug,
    callback,
  };
};

export const getStoreCartStatus = (callback) => {
  return {
    type: GET_STORE_CART_STATUS,
    callback,
  };
};

export const updateStoreCartStatus = (data, callback) => {
  return {
    type: UPDATE_STORE_CART_STATUS,
    data,
    callback,
  };
};
