import { size } from 'lodash';
import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 8,
    width: Utility.getScreenWidth(),
  },
  columnStyle: {
    maxWidth: Utility.getScreenWidth(),
  },
  listContainer: { marginHorizontal: 8, marginTop: 12 },
});

export const getStylesForStaggeredItem = (columnWidth) => {
  const borderRadius = 8;
  return StyleSheet.create({
    container: {
      margin: 4,
      maxWidth: columnWidth,
      shadowColor: colors.black,
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.18,
      shadowRadius: 1.0,

      elevation: 1,
    },
    imageContainer: {
      width: columnWidth,
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
      backgroundColor: colors.white,
    },
    cardDescription: {
      paddingHorizontal: 8,
      fontFamily: 'Roboto',
      fontSize: size.h3_5,
      color: colors.black,
    },
    link: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: 1,
      width: 172,
      paddingHorizontal: 6,
    },
    linkText: { fontFamily: 'Roboto', fontSize: size.h3, color: colors.grey },
    icon: {
      height: 13,
      width: 13,
      marginRight: 4,
    },
    description: {
      flex: 1,
      backgroundColor: colors.white,
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      marginTop: 2,
      paddingVertical: 4,
    },
  });
};
