/* eslint-disable no-unused-expressions */
import { takeLatest, put, select } from 'redux-saga/effects';

import {
  GET_PAYMENT_CURRENT_STATUS,
  SEND_PAYMENT_REQUEST,
} from '../actions/PaymentActions';
import { URL } from '../config/Constants';
import {
  getApiHeaders,
  convertJsonFromResponse,
  getAddressTypeForId,
} from './GeneratorUtil';
import Utility from '../utils/Utility';

function* getPaymentCurrentStatusAsync(action) {
  const { txnId, callback = () => {}, user_action } = action;
  const url = `${URL.GET_PAYMENT_STATUS}/${txnId}/status`;

  try {
    const response = yield fetch(url, {
      method: 'PATCH',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ user_action: user_action }),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.tron.log(` error in payment status${JSON.stringify(error)}`);
  }
}

// {

//   "payment_method":"upi",
//   "payment_method_value": {
//       "virtual_bank_account_id":"htgyl@upi",
//       "bankcode":"UPI"
//   },
//   "vpa":"htgyl@upi",
//   "bank_code":"UPI",
//   "save_payment_method":false,
//   "order_id":"2000120597"

// }

function* sendPaymentRequestAsync(action) {
  const {
    payload: {
      paymentMethod = 'cod',
      addressId = '',
      paymentMethodPayload = {},
      isSavedPaymentMethod = false,
      paymentGateway = '',
      params = {},
      order_id = '',
    } = {},
    callback = () => {},
  } = action;
  const url = `${URL.GET_PAYMENT_STATUS}`;
  const UtoP = yield select((state) => state.bag.UtoP);

  const { isUpgradeToPrepaid, orderId } = UtoP;

  const { isContactLess: isContactLessDelivery, isNewPaymentJourney } =
    yield select((state) => state.bag);

  const addressType = yield getAddressTypeForId(addressId);

  try {
    const body = {
      order: {
        address_id: addressId,
        address_type: addressType,
      },
      payment_method: paymentMethod,
      contact_less: isContactLessDelivery,
      payment_method_value: paymentMethodPayload,
      order_id,
      vpa: paymentMethodPayload?.virtual_bank_account_id,
      bank_code: paymentMethodPayload?.bankcode,
    };
    if (isSavedPaymentMethod) {
      body.save_payment_method = true;
    }
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(body),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.tron.log(` error in payment ${JSON.stringify(error)}`);
  }
}

export default function* watchPaymentSaga() {
  yield takeLatest(GET_PAYMENT_CURRENT_STATUS, getPaymentCurrentStatusAsync);
  yield takeLatest(SEND_PAYMENT_REQUEST, sendPaymentRequestAsync);
}
