import React from 'react';
import { useSelector } from 'react-redux';
import InviteFriendCard from './inviteFriendCard/InviteFriendCard';
import { isPresent } from '../../utils/BooleanUtility';

export default function GroupDealCard(props) {
  const {
    productPersonalizedDetails: { group_deals = [] } = {},
    showToast,
    productData = {},
    onInviteFriendCardLayoutMeasurement,
    selectedVariant,
  } = props;
  const todayDeals = useSelector((state) => state.todayDeals) || {};
  const sku = productData?.sku || selectedVariant?.sku || {};
  const { group_deal_skus: personalizedGroupDeals = {} } = todayDeals;
  let { group_buying_price: groupPrice, required_group_size: groupSize, id } = sku;
  if (isPresent(personalizedGroupDeals[id]?.group_buying_price)) {
    groupPrice = personalizedGroupDeals[id]?.group_buying_price;
    groupSize = personalizedGroupDeals[id]?.required_group_size;
  }

  let invitees = [];
  let status = 'uninitialized';
  let couponCode = 'XXXXXX';
  if (group_deals.length && group_deals.length !== 0) {
    const { group_deal_status, group_deal_invitations, initiator_coupon_code } = group_deals[0];
    status = group_deal_status;
    invitees = group_deal_invitations;
    couponCode = initiator_coupon_code;
  }

  return (
    <InviteFriendCard
      groupPrice={groupPrice}
      groupSize={groupSize}
      productData={productData}
      status={status}
      invitees={invitees}
      couponCode={couponCode}
      showToast={showToast}
      onInviteFriendCardLayoutMeasurement={onInviteFriendCardLayoutMeasurement}
    />
  );
}
