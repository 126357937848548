import React from 'react';
import { useRoute } from '@react-navigation/native';
import { API_DOMAIN } from '../../config/Constants';
import ShopOnline from '../../containers/List/ShopOnline';

export default function ShopOnlineBrand() {
  const { params = {} } = useRoute();
  const { name } = params;
  const url = `${API_DOMAIN}/api/v6/brands/${name}/shop_online`;
  return <ShopOnline url={url} />;
}
