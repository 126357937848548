import _ from 'lodash';
import * as types from '../actions/ActionTypes';
import initialState from './initialState';
import Utility from '../utils/Utility';

export default function cart(state = initialState.cart, action) {
  if (action.data === undefined) {
    if (action.type === types.LOGOUT_USER) {
      return {
        ...state,
        cartItems: [],
      };
    }
    return state;
  }
  const { sku_id = '' } = action.data;
  switch (action.type) {
    case types.SAVE_CART:
      return {
        ...state,
        pricingData: action.data,
      };
    case types.ADD_TO_CART:
      const tempCartItems = _.filter(
        state.cartItems,
        (item) => !(item.sku_id === sku_id),
      );
      const oldItem = _.find(state.cartItems, (item) => item.sku_id === sku_id);
      const mergedItem = _.merge({}, oldItem, action.data);
      return {
        ...state,
        cartItems: [...tempCartItems, mergedItem],
      };

    case types.REMOVE_FROM_CART:
      return {
        ...state,
        cartItems: _.filter(
          state.cartItems,
          (cartItems) => !(cartItems.sku_id === sku_id),
        ),
      };
    case types.ORDER_SUCCESS:
      return {
        ...state,
        cartItems: [],
      };
    case types.SAVE_CART_ITEMS:
      let tempCartArray = [];
      action.data.map((cartItem) => {
        if (Utility.isBlank(cartItem.variant)) {
          tempCartArray = [...tempCartArray, { ...cartItem, variantId: '' }];
        } else {
          tempCartArray = [
            ...tempCartArray,
            {
              ...cartItem,
              variantId: cartItem.variant.id,
              mrp: cartItem.variant.mrp,
              sp: cartItem.variant.sp,
              discount: cartItem.variant.discount,
              productImage:
                cartItem.variant.images.length > 0
                  ? cartItem.variant.images[0]
                  : cartItem.productImage,
            },
          ];
        }
      });
      return {
        ...state,
        cartItems: [...tempCartArray],
      };
    case types.UPDATE_CART_ITEMS:
      return {
        ...state,
        cartItems: action.data,
      };
    case types.APPLY_CART_COUPON_SUCCESS:
      return {
        ...state,
        appliedCouponCode: action.data,
      };
    case types.SAVE_CART_ITEM:
      const uniqueCartItems = _.filter(
        state.cartItems,
        (item) => !(item.sku_id === action.data.sku_id),
      );

      if (action.data.quantity > 0) {
        return {
          ...state,
          cartItems: [...uniqueCartItems, action.data],
        };
      }

      return {
        ...state,
        cartItems: [...uniqueCartItems],
      };
    case types.SAVE_FREE_OFFER_ITEM:
      if (_.isEqual(action.data, state.cartOffers)) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        cartOffers: action.data,
      };

    case types.ADD_FREE_ITEM_TO_CART:
      const tempItems = _.filter(
        state.freeItems,
        (item) => !(item.sku_id === sku_id),
      );
      const existingFreeItems = _.find(
        state.freeItems,
        (item) => item.sku_id === sku_id,
      );
      const mergedFreeItem = _.merge({}, existingFreeItems, action.data);
      return {
        ...state,
        freeItems: [...tempItems, mergedFreeItem],
      };

    case types.REMOVE_FREE_ITEM_TO_CART:
      return {
        ...state,
        freeItems: _.filter(
          state.freeItems,
          (freeItems) => !(freeItems.sku_id === sku_id),
        ),
      };
    case types.RESET_FREE_ITEMS_IN_CART:
      return {
        ...state,
        freeItems: action.data,
      };

    default:
      return state;
  }
}
