import React, { PureComponent } from 'react';
import _ from 'lodash';
import Config from '../../libraries/ReactNativeConfig';
import images from '../../theme/Images';
import EmptyState from '../../components/layout/emptyState/Emptystate';
import Utility from '../../utils/Utility';
import WithNavigation from '../../utils/WithNavigation';

class SwipeInitiateCard extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
  }
  navigate = () => {
    const { navigation, listData, previousScreen, navigateToSearch } =
      this.props;
    navigation.navigate('SwipableCards', {
      listData,
      previousScreen,
      navigateToSearch,
    });
  };

  render() {
    const image = Utility.isPresent(Config.WISHLIST_EMPTY_IMAGE)
      ? { uri: Config.WISHLIST_EMPTY_IMAGE }
      : images.empty_wishlist;
    return (
      <EmptyState
        title="Tell us what you want"
        subtitle="Add products to your wishlist to see them in your flash deals"
        buttonText="Let’s do it"
        image={image}
        onPress={this.debouncedNavigate}
      />
    );
  }
}

export default WithNavigation(SwipeInitiateCard);
