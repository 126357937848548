import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import {
  SEARCH_ITEM,
  MERGE_LIST_DATA,
  EMPTY_SEARCH,
  EMPTY_SEARCH_DATA_RECEIVED,
  SEARCH_SUGGESTION,
  SEARCH_SUGGESTIONS_RECEIVED,
  SEARCH_AUTO_SUGGEST,
  PAGINATED_MORE_SEARCH_RESULT,
  SET_SEARCH_RESULT_CLICKS,
} from '../actions/ActionTypes';

import {
  SEARCH_URL,
  EMPTY_SEARCH_URL,
  URL,
  SEARCH_AUTOSUGGEST,
  API_DOMAIN,
} from '../config/Constants';
import Utility from '../utils/Utility';

import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';

export function* searchItemAsync(action) {
  const {
    showProductsAndBrandsOnly,
    data: { query, barcode, type, page, filtersUrl },
    hideOosProduct,
  } = action;
  let url = '';
  if (action.isPreviousScreenScanProduct) {
    if (_.isArray(query)) {
      url = `${URL.SEARCH_PRODUCT}?q=${encodeURI(query[0])},${encodeURI(
        query[1],
      )},${encodeURI(query[2])}&barcode=${barcode}&type=products`;
    } else {
      url = `${URL.SEARCH_PRODUCT}?q=${encodeURI(
        query,
      )}&barcode=${barcode}&type=products`;
    }
  } else if (type) {
    url = `${SEARCH_URL}?q=${encodeURI(query)}&type=${type}`;
  } else {
    url = `${SEARCH_URL}?q=${encodeURI(query)}`;
  }
  if (Utility.isPresent(page) && page > 0) {
    url += `&page=${page}&type=products`;
  }
  if (showProductsAndBrandsOnly) {
    url += '&products_and_brands_only=true';
  }

  // FIXME: filtersUrl contains random object unless it is initialized with an intended string.
  // this random object gets appended in the api as a stringified Object causes bad response in some cases.
  // remove (&& filtersUrl instanceof String) from the condition
  // and turn off the ONLY product and BRANDS switch in search to reproduce the problem.
  if (Utility.isPresent(filtersUrl)) {
    url = `${url}${filtersUrl}`;
  }
  url += `&hide_oos=${hideOosProduct}`;

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response?.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      action.callback(json);
      return;
    }
    action.callback(false);
  } catch (e) {}
}

export function* searchAutoSuggestAsync(action) {
  const { query, callback } = action;
  const url = `${SEARCH_AUTOSUGGEST}?q=${query}&send_store_slug=true`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response?.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(json);
      return;
    }
    action.callback(false);
  } catch (error) {}
}

export function* emptySearchAsync(action) {
  const { callback } = action;
  const url = EMPTY_SEARCH_URL;
  try {
    const response = yield fetch(`${url}?show_special_offer=true`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
      if (json.top_searches.length > 0) {
        yield put({
          type: EMPTY_SEARCH_DATA_RECEIVED,
          data: json.top_searches,
        });
      }
      // yield put({ type: MERGE_LIST_DATA, data: json.feature_card });
      // yield put({ type: MERGE_LIST_DATA, data: json.trending_videos });
      // yield put({ type: MERGE_LIST_DATA, data: json.most_searched_products });
    }
  } catch (error) {}
}

export function* searchSuggestionsAsync(action) {
  const query = action.payload;
  const url = `${SEARCH_AUTOSUGGEST}?q=${query}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put({ type: SEARCH_SUGGESTIONS_RECEIVED, data: json });
      }
    }
  } catch (error) {}
}

export function* paginatedMoreSeachResultAsync(action) {
  const { slug, page_no, callback, filtersUrl } = action;
  let url = `${API_DOMAIN}${slug}&type=products&page=${page_no}`;
  if (Utility.isBlank(filtersUrl)) {
    url = `${url}${filtersUrl}`;
  }
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        callback(json);
      }
    }
  } catch (error) {}
}
function* searchResultClicks(action) {
  const {
    data: { query = '', type = '', id = '' },
    callback,
  } = action;
  const postData = {
    query,
    convertable_type: _.startCase(_.capitalize(type)).replace(' ', ''),
    convertable_id: id,
  };
  try {
    const response = yield fetch(URL.SEARCH_RESULT_CLICKS, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postData),
    });
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: `SearchSaga.js searchResultClicks ${error}`,
    });
  }
}

// Our watcher Saga:
export default function* watchSearchItem() {
  yield takeEvery(SEARCH_ITEM, searchItemAsync);
  yield takeEvery(EMPTY_SEARCH, emptySearchAsync);
  yield takeLatest(SEARCH_SUGGESTION, searchSuggestionsAsync);
  yield takeLatest(SEARCH_AUTO_SUGGEST, searchAutoSuggestAsync);
  yield takeEvery(PAGINATED_MORE_SEARCH_RESULT, paginatedMoreSeachResultAsync);
  yield takeEvery(SET_SEARCH_RESULT_CLICKS, searchResultClicks);
}
