import { StyleSheet } from 'react-native';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';

const styles = StyleSheet.create({
  railView: {
    marginVertical: 4,
  },
  fancyRailView: {
    marginVertical: 8,
    marginLeft: 4,
  },
  storyRailView: {
    marginLeft: Utility.padding - Utility.spacingBetweenItems,
    marginTop: 8,
  },
  railViewSingleCard: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
  },
  flatlistStyle: {
    overflow: 'visible',
    width: Utility.getScreenWidth(),
  },
  railContainerStyle: { paddingHorizontal: 12 },
  flatListOnRoutinePageStyle: {
    overflow: 'hidden',
    paddingVertical: 4,
    width: Utility.getScreenWidth(),
  },
  shimmerContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 70,
    width: 64,
    marginTop: 8,
    paddingLeft: 16,
    marginBottom: 44,
  },
  shimmerCircle: {
    height: 64,
    width: 64,
    marginRight: 16,
    borderRadius: 32,
    backgroundColor: '#EAEAEA',
  },
  storyText: {
    height: 24,
    width: 64,
    marginTop: 12,
    borderRadius: 8,
    backgroundColor: '#EAEAEA',
  },
  backgroundImage: {
    width: Utility.getScreenWidth(),
  },
  emptyCardView: {
    backgroundColor: 'transparent',
  },
  chevron: { height: 16, width: 16, marginLeft: 4 },
  amountContainer: {
    position: 'absolute',
    top: -36,
    right: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  amount: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    color: colors.green,
    lineHeight: 26,
  },
  headingTitle: {
    fontSize: 18,
    lineHeight: 21,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    alignContent: 'flex-start',
  },
  subHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    letterSpacing: 0,
    color: colors.subtitle,
    alignContent: 'flex-start',
  },
  emptyOfferPromptContainer: { marginLeft: 12, paddingBottom: 8 },
  footerContainer: { flexDirection: 'row' },
});

export default styles;
