import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  Image,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CartStyles } from '../../components/cart/styles';
import {
  optedForPlasticFreePackaging,
  getCartPricing,
  fetchCartEmptyState,
} from '../../actions/ActionTypes';
import Utility from '../../utils/Utility';
import List from '../List/List';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import VerticalRailShimmer from '../../components/shared/VerticalRailShimmer';
import { MY_LIKED_PRODUCTS } from '../../config/Constants';
import WithNavigation from '../../utils/WithNavigation';

class AddFromWishlist extends Component {
  constructor(props) {
    super(props);
    this.state = { emptyCartProductList: {}, showShimmer: false };
    this.myWishlist = `${MY_LIKED_PRODUCTS}?source=cart`;
  }

  componentDidMount() {
    this.setState(
      {
        showShimmer: true,
      },
      () => {
        this.getMyLikedProducts(this.myWishlist);
      },
    );
  }

  getMyLikedProducts = (slug) => {
    const { fetchCartEmptyState } = this.props;
    fetchCartEmptyState(slug, (success, data) => {
      this.setState({
        showShimmer: false,
      });
      if (success) {
        this.setState({
          emptyCartProductList: data,
        });
      }
    });
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  productHeader = (props) => {
    const styles = CartStyles;
    const { itemData } = props;
    if (Utility.isBlank(itemData) || itemData?.objects?.length === 0) {
      return null;
    }
    return (
      <View style={sharedStyles.headerContainer}>
        <Text style={styles.subHeading}>{itemData?.name}</Text>
      </View>
    );
  };

  render() {
    const { emptyCartProductList, showShimmer } = this.state;

    if (showShimmer) {
      return <VerticalRailShimmer />;
    }

    if (Utility.isBlank(emptyCartProductList?.objects)) {
      return null;
    }

    return (
      <View
        style={{
          backgroundColor: emptyCartProductList?.background_color,
          marginTop: 12,
        }}
      >
        <this.productHeader itemData={emptyCartProductList} />
        <List
          itemData={emptyCartProductList}
          id={emptyCartProductList.id}
          previousScreen={SCREEN_CONSTANTS.CART}
        />
      </View>
    );
  }
}

const sharedStyles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 8,
    marginTop: 28,
  },
});

const mapStateToProps = (state) => ({
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      optedForPlasticFreePackaging,
      getCartPricing,
      fetchCartEmptyState,
    },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps)(AddFromWishlist),
);
