import React, { useEffect, useRef, useState } from 'react';
import { View, FlatList } from 'react-native';
import List from '../List/List';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { styles } from './styles';
import ErrorBoundaryComponent from '../../components/shared/ErrorBoundaryComponent';
import TabGroup from './TabGroup';
import ListPlaceHolder from '../../components/shared/GridScreenPlaceHolder';


const RenderShimmer = () => {
  return (
    <View style={styles.shimmer}>
      <ListPlaceHolder columnCount={1} />
    </View>
  );
};

const MultiLevelTabs = (props) => {
  const { item = [], previousScreen = '', listData = {} } = props;
  const [selectedList, setSelectedList] = useState({});
  const [tabs, setTabsData] = useState(item);
  const [defaultList, setdefaultList] = useState(item[1]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    updateStateFromProps();
  }, [selectedList]);

  const updateStateFromProps = () => {
    tabs[1] = selectedList;
  };
  const flatListRef = useRef(null);
  if (isBlank(tabs)) return null;
  if (isPresent(selectedList)) {
    tabs[1] = selectedList;
  } else {
    tabs[1] = defaultList;
  }

  const setSelectedListData = (selectedData = {}) => {
    setSelectedList(selectedData);
    setIsLoading(false);
    scrollToTop();
  };

  const setListRef = (ref) => {
    flatListRef.current = ref;
  };

  const scrollToTop = () => {
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0 });
  };

  const renderItem = ({ item, index }) => {
    if (item.type === 'list') {
      if (isLoading) return <RenderShimmer />;
      return (
        <List
          itemData={item}
          setListRef={setListRef}
          previousScreen={previousScreen}
          listData={listData}
        />
      );
    }
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <TabGroup
          itemData={item?.objects}
          display={item?.display}
          setSelectedTabList={setSelectedListData}
          index={index}
          defaultList={defaultList}
          listData={listData}
          previousScreen={previousScreen}
          setIsLoading={setIsLoading}
        />
      </ErrorBoundaryComponent>
    );
  };

  const keyExtractor = (item, index) => `${index}_${item.type}`;

  return (
    <View>
      <FlatList
        data={tabs}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        stickyHeaderIndices={[0]}
        ref={setListRef}
        extraData={isLoading}
      />
    </View>
  );
};

export default MultiLevelTabs;
