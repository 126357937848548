import React, { Component } from 'react';
import {
  View,
  ScrollView,
  SafeAreaView,
  Text,
  Image,
  FlatList,
  TouchableWithoutFeedback,
  BackHandler,
  Clipboard,
  Platform,
  Linking,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Share from '../../libraries/ReactNativeShare';
import Toast from 'react-native-easy-toast';
import _ from 'lodash';
import styles from './styles/refer';
import images from '../../theme/Images';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import {
  PERMISSION_STORE,
  AVAILABLE_BUILD_TYPES,
  AnalyticsScreenNames,
} from '../../config/Constants';
import { UPLOADING_OPTIONS } from '../../config/LayoutConstants/UploadPostConfig';
import LocationPermissionModal from '../Artist/Onboarding/LocationPermissionModal';
import { uploadContacts, getMyReferral } from '../../actions/ActionTypes';
import { StaticNavigationHeader } from '../shared';
import {
  AnalyticsEvent,
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

class ReferAndEarn extends Component {
  constructor() {
    super();
    this.state = {
      showPermissionModal: false,
      contacts: [],
      myReferrals: [],
      totalPoints: 0,
      dynamicLink: '',
    };
  }

  componentDidMount = () => {
    const { getMyReferral, navigation, invitationCode } = this.props;
    // if (Utility.isAndroid()) {
    //   Utility.isPermissionAllowed(PERMISSION_STORE.read_contact, (isAllowed) => {
    //     console.tron.log('Permission is allowed', isAllowed);
    //     if (!isAllowed) {
    //       this.setState({
    //         ...this.state,
    //         showPermissionModal: true,
    //       });
    //     } else {
    //       this.uploadContactsToServer();
    //     }
    //   });
    // } else if (Utility.isIOS()) {
    //   Utility.checkPermission(PERMISSION_STORE.read_contact, true, (isAllowed) => {
    //     if (isAllowed) {
    //       this.uploadContactsToServer();
    //     }
    //   });
    // }

    getMyReferral((result) => {
      console.tron.log('My Referrals ', result);
      this.setState({
        ...this.state,
        myReferrals: result.referrals,
        totalPoints: result.total_coins,
      });
    });

    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress,
    );
  };

  handleBackPress = () => {
    const { navigation } = this.props;
    navigation.goBack(); // works best when the goBack is async
    return true;
  };

  componentWillUnmount() {
    if (Platform.OS === 'android') {
      this.backHandler.remove();
    }
  }

  copyReferLink = () => {
    const { foxyInviteCode, isArtist, invitationCode } = this.props;
    console.tron.log('My Invitation link', foxyInviteCode);
    const string = isArtist ? foxyInviteCode : invitationCode;
    Clipboard.setString(string);
    this.toast.show('Copied to clipboard');
  };

  _referLinkSection = (props) => {
    const { foxyInviteCode, isArtist, invitationCode } = this.props;
    return (
      <View style={styles.referSection}>
        <Text style={styles.linkText}>
          {isArtist ? foxyInviteCode : invitationCode}
        </Text>
        <TouchableWithoutFeedback
          onPress={this.copyReferLink}
        ></TouchableWithoutFeedback>
      </View>
    );
  };

  _shareRow = () => (
    <View style={styles.shareSection}>
      <Text style={[styles.subHeading, { paddingLeft: 20 }]}>Invite via</Text>
      <View style={[styles.row, { marginTop: 5 }]}>
        <this._shareOptions
          src={images.socialShareIcons.whatsapp}
          title="WhatsApp"
          onClick={() => this.socialShare('whatsapp')}
        />
        <this._shareOptions
          src={images.socialShareIcons.igFull}
          title="Instagram"
          onClick={() => this.socialShare('instagram')}
        />
        <this._shareOptions
          src={images.socialShareIcons.facebook}
          title="Facebook"
          onClick={() => this.socialShare('facebook')}
        />
        <this._shareOptions
          src={images.socialShareIcons.sms}
          title="Sms"
          onClick={() => this.shareOnSms()}
        />
        <this._shareOptions
          src={images.socialShareIcons.more}
          title="More"
          onClick={() => this.socialShare(null)}
        />
      </View>
    </View>
  );

  shareOnSms = () => {
    AnalyticsManager.logEvent(EventType.miscAppEvents.APP_SHARE, {
      [EventParameterKey.MEDIUM]: EventParameterValue.MEDIUM.SMS,
    });
    if (Utility.isAndroid()) {
      this.socialShare('sms');
    } else {
      const { invitationCode } = this.props;
      const body =
        Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only
          ? `Download ${Config.APP_NAME}, a must have tool for beauty influencers. A special welcome gift awaits you if you install using this link or code: \n${invitationCode}`
          : `Download ${Config.APP_NAME}, a must have tool for beauty enthusiasts. A special welcome gift awaits you if you install using this link or code:`;
      Linking.openURL(`mms:body=${body}`);
    }
  };

  _shareOptions = (props) => (
    <TouchableWithoutFeedback onPress={props.onClick}>
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image source={props.src} style={{ height: 48, width: 48 }} />
        <Text style={{ fontSize: 10, marginTop: 5 }}>{props.title}</Text>
      </View>
    </TouchableWithoutFeedback>
  );

  _topSection = (props) => (
    <View style={styles.topContainer}>
      <Text style={styles.headerText}>Grow the tribe!</Text>
      <Text style={styles.subHeading}>
        Invite fellow beauty influencers and professionals and earn vouchers
        with their first approved video!!
      </Text>
      {/* <Text style={styles.subHeading}>
      PS. Both granted once their first kickass video is approved.       </Text> */}

      <this._referLinkSection myReferLink={props.myReferLink} />

      <this._shareRow />
    </View>
  );

  _flatListRenderItem = (props) => (
    <View style={styles.row_profile_item}>
      <View style={{ flexDirection: 'row' }}>
        <Image
          style={styles.profile_pic}
          source={props.imgSrc || images.profileEmptyState}
        />
        <View style={styles.col}>
          <Text style={styles.user_name}>{props.userName}</Text>

          <Text style={styles.user_handle}>{props.userHandle || '-'}</Text>
        </View>
      </View>
      {props.first_successful_order && (
        <View style={styles.reminded}>
          <Text style={[styles.reminded, { color: colors.foxyGreen }]}>
            +50
          </Text>
        </View>
      )}
      {!props.first_successful_order && (
        <View style={styles.remindButton}>
          <Text style={{ fontSize: size.h3 }}>Remind</Text>
        </View>
      )}
    </View>
  );

  _bottomSection = (props) => (
    <View style={styles.column}>
      <View style={[styles.row, { flex: 1 }]}>
        <Text style={styles.historyFont}>History</Text>
        <Text style={{ justifyContent: 'flex-end', paddingRight: 20 }}>
          Total Earned : {props.total_points}
        </Text>
      </View>

      <FlatList
        data={this.state.myReferrals}
        renderItem={({ item }) => (
          <this._flatListRenderItem
            first_successful_order={item.first_successful_order}
            imgSrc={item.image_url}
            userName={item.name}
            userHandle={item.handle}
          />
        )}
      />
    </View>
  );

  // TODO: Use a sharing manager rather than putting this logic inline.
  socialShare = (shareOn) => {
    AnalyticsManager.logEvent(EventType.miscAppEvents.APP_SHARE, {
      [EventParameterKey.MEDIUM]:
        EventParameterValue.MEDIUM[_.toUpper(shareOn)],
    });
    const { invitationCode } = this.props;
    console.tron.log('Invitation code', invitationCode);
    // let shareMedium = shareOn==='sms'? 'sms':Share.Soc
    const shareOptions = {
      title: `Share ${Config.APP_NAME} App Using`,
      message:
        Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only
          ? `Download ${Config.APP_NAME}, a must have tool for beauty influencers. A special welcome gift awaits you if you install using this link or code:`
          : `Download ${Config.APP_NAME}, a must have tool for beauty enthusiasts. A special welcome gift awaits you if you install using this link or code:`,
      url: invitationCode,
      social: `${shareOn}`, // 'sms'//Share.Social[shareOn],
    };
    if (!shareOn) {
      Share.open(shareOptions)
        .then((res) => {})
        .catch((err) => {
          console.tron.log('Seems like this app is not installed on your system');
        });
    } else {
      Share.shareSingle(shareOptions);
    }
  };

  render() {
    const { showPermissionModal, totalPoints, myReferrals } = this.state;
    const { invitationCode } = this.props;
    return (
      <>
        <StaticNavigationHeader title="Invite and Earn" />
        <SafeAreaView style={styles.container}>
          {/* <OnboardingNavigationHeader
          title=""
          showProgressBar={false}
          shouldPop
          headerStyle={styles.headerColor}
        /> */}
          <ScrollView style={styles.wrapper}>
            <>
              <this._topSection myReferLink={invitationCode} />

              {myReferrals.length > 0 && (
                <this._bottomSection total_points={totalPoints} />
              )}
            </>
          </ScrollView>
          <Toast
            position="center"
            ref={(ref) => {
              this.toast = ref;
            }}
          />
          <LocationPermissionModal
            showModal={showPermissionModal}
            title="Allow Contact Permission"
            description={`Earn 50 coins for every person that joins ${Config.APP_NAME} using your contact`}
            buttonText="Allow"
            showButtonIcon={false}
          />
        </SafeAreaView>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  invitationCode: state.UserAccountInfo.invitationCode,
  foxyInviteCode: state.UserAccountInfo.artistProfile.foxyInviteCode,
  isArtist: state.UserAccountInfo.artistProfile.isArtist,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      uploadContacts,

      getMyReferral,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferAndEarn);
