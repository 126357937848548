import { put, takeLatest } from 'redux-saga/effects';
import { FETCH_RELATED_CONTENT, MERGE_LIST_DATA } from '../actions/ActionTypes';

import { API_DOMAIN } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

export function* fetchRelatedContentAsync(action) {
  const slug = action.payload;
  const url = `${API_DOMAIN}${slug}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (Utility.isPresent(json)) {
      yield put({ type: MERGE_LIST_DATA, data: json });
    }
  } catch (error) {}
}

// Our watcher Saga:
export default function* watchFetchRelateContent() {
  yield takeLatest(FETCH_RELATED_CONTENT, fetchRelatedContentAsync);
}
