import React from 'react';
import { View, Text, Image } from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import images from '../../theme/Images';

const { ids, styles } = StyleSheet.create({
  pillText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    zIndex: 5,
    maxWidth: 120,
    '@media (min-width: 768px)': {
      fontSize: 12,
    },
  },
  couponIcon: { height: 14, width: 14, marginRight: 4, resizeMode: 'contain' },
});

const CouponCodePill = (props) => {
  const {
    couponCode,
    couponCodeStatus,
    style,
    is_club_member_offer,
    isEdgeOffer,
  } = props;
  let backgroundColor;
  let borderColor;
  let opacity;
  if (
    couponCodeStatus === 'uninitialized' ||
    couponCodeStatus === 'initialized' ||
    couponCodeStatus === 'active'
  ) {
    backgroundColor = colors.couponCodePill.background.inActive;
    borderColor = colors.couponCodePill.border.inActive;
    opacity = 0.5;
  } else {
    backgroundColor = colors.couponCodePill.background.active;
    borderColor = colors.couponCodePill.border.active;
    opacity = 1;
  }
  return (
    <View
      style={[
        {
          backgroundColor,
          padding: 2,
          borderWidth: 1,
          borderColor,
          borderStyle: 'dashed',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 2,
          minWidth: 70,
          opacity,
          flexDirection: 'row',
        },
        style,
      ]}
    >
      {!!isEdgeOffer && (
        <Image source={{ uri: images.edge.offer }} style={styles.couponIcon} />
      )}
      <Text
        style={styles.pillText}
        numberOfLines={1}
        ellipsizeMode='tail'
        allowFontScaling={false}
        dataSet={{ media: ids.pillText }}
      >
        {couponCode}
      </Text>
    </View>
  );
};

export default CouponCodePill;
