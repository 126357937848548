import { findIndex } from 'lodash';
import { isDesktop } from './BooleanUtility';

export default class ArrayUtility {
  static toggleArrayElements(array, currentItem) {
    const newArray = array.includes(currentItem)
      ? array.filter((item) => item !== currentItem)
      : [...array, currentItem];
    return newArray;
  }
}

export const restructuredPersonalizedElements = (attributes) => {
  return Object.entries(attributes).map(([key, values]) => ({
    user_attribute_id: key,
    new_values: values,
  }));
};


export const getIndexOfEmptyState = (objects) =>
  findIndex(objects, (e) => e.content === 'filters_empty_state');

export const restructureVerticalItemForOffers = (items = []) => {
  if (!isDesktop()) return items;
  const outputArray = [];

  for (let i = 0; i < items.length; i++) {
    const currentObj = items[i];

    if (isOfferWithCouponItem(currentObj) && i + 1 < items.length && isOfferWithCouponItem(items[i + 1])) {
      outputArray.push({ type: 'offer_pair', data: [currentObj, items[++i]] });
    } else {
      outputArray.push(currentObj);
    }
  }

  return outputArray;
};

const isOfferWithCouponItem = ({ type, offer_type: offerType, display }) => (
  type === 'offer' && offerType === 'offer_coupon' && display === 'offer-banner-with-rail'
);
