import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { END_STATES } from '../../../config/Constants';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import FastImageView from '../../FastImageView';

export default class LargeActionCard extends Component {
  constructor(props) {
    super(props);

    const { backgroundColor = '#fff', style } = this.props;
    this.containerStyle = [
      styles.container,
      style,
      { backgroundColor },
    ];
    this.innerContainer = [styles.endState, { backgroundColor }];
  }

  endState = (props) => {
    const { backgroundColor = '#fff', imageUrl } = this.props;
    const {
      endStateData: { heading = '', subheading = '' },
      large = false,
    } = props;
    return (
      <View style={this.innerContainer}>
        <FastImageView
          resizeMode='contain'
          source={imageUrl}
          style={large ? styles.largeActionCard : styles.smallActionCard}
        />

        <View style={styles.headingSubheadingContainer}>
          {Utility.isPresent(heading) && (
            <Text style={styles.heading}>{heading}</Text>
          )}
          <Text
            style={styles.subheading}
            numberOfLines={large ? 12 : 2}
            ellipsizeMode='tail'
          >
            {subheading}
          </Text>
        </View>
      </View>
    );
  };

  render() {
    const {
      backgroundColor = '#fff',
      imageUrl,
      heading,
      subheading,
      firstActionButtonText,
      style,
      secondaryActionButtonText,
      primaryButtonAction,
      secondaryButtonAction,
      primaryButtonIcon,
      secondaryButtonIcon,
      showEndState = false,
      delay = {},
      displayPaymentMode = '',
      primaryButtonActionCode,
    } = this.props;

    if (
      primaryButtonActionCode === 'external_condition_status' &&
      !Utility.isDelayed(delay)
    ) {
      return null;
    }

    if (
      primaryButtonActionCode === 'get_detailed_status_from_brand' &&
      !Utility.isDelayed(delay)
    ) {
      return null;
    }

    if (
      primaryButtonActionCode === 'prepay_and_save' &&
      displayPaymentMode !== 'COD'
    ) {
      return null;
    }

    const externalConditionEndState = {
      heading: '',
      subheading,
    };

    if (primaryButtonActionCode === 'external_condition_status') {
      return <this.endState endStateData={externalConditionEndState} large />;
    }

    if (showEndState) {
      return (
        <this.endState
          endStateData={END_STATES[`${primaryButtonActionCode}`] || {}}
        />
      );
    }

    return (
      <View style={this.containerStyle}>
        <FastImageView
          resizeMode='contain'
          source={imageUrl}
          style={styles.image}
        />
        {Utility.isPresent(heading) && (
          <Text style={styles.heading_2}>{heading}</Text>
        )}
        <Text style={styles.subheading_2}>{subheading}</Text>
        {Utility.isPresent(firstActionButtonText) && (
          <FoxyShadowButton
            width={Utility.getScreenWidth() - 24 - 12}
            title={firstActionButtonText}
            onPress={primaryButtonAction}
            style={styles.primaryButtonContainer}
            textStyle={styles.primaryButtonText}
            firstColor={colors.black}
            secondColor={colors.black}
            underlayColor={colors.black}
            showActionIcon={!!primaryButtonIcon}
            actionIcon={{ uri: primaryButtonIcon }}
          />
        )}
        {Utility.isPresent(secondaryActionButtonText) && (
          <FoxyShadowButton
            title={secondaryActionButtonText}
            onPress={secondaryButtonAction}
            style={styles.secondaryButtonContainer}
            firstColor='transparent'
            secondColor='transparent'
            underlayColor='#444'
            textStyle={styles.secondaryButtonText}
            showActionIcon={!!secondaryButtonIcon}
            actionIcon={{ uri: secondaryButtonIcon }}
            border={styles.border}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  largeActionCard: {
    height: 100,
    width: 100,
    marginRight: 8,
  },
  smallActionCard: {
    height: 62,
    width: 68,
    marginRight: 8,
  },
  headingSubheadingContainer: { flexDirection: 'column', flexShrink: 1 },
  heading: { fontSize: 18, fontFamily: 'Roboto-Bold', color: '#404E9F' },
  subheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
  },
  image: { height: 140, width: 227, marginTop: 20, marginBottom: 12 },
  heading_2: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: '#404E9F',
    lineHeight: 21.09,
    marginBottom: 4,
  },
  subheading_2: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginBottom: 4,
    textAlign: 'center',
    marginTop: 6,
  },
  primaryButtonContainer: { marginTop: 12 },
  primaryButtonText: { borderRadius: 8 },
  secondaryButtonContainer: {
    marginTop: 12,
    alignItems: 'center',
    width: Utility.getScreenWidth() - 24 - 12,
  },
  secondaryButtonText: { color: colors.secondActionBorderColor },
  border: { borderColor: colors.secondActionBorderColor, borderWidth: 1 },
  endStateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: Utility.getScreenWidth() - 24,
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginHorizontal: 12,
    borderRadius: 8,
  },
  container: {
    width: Utility.getScreenWidth() - 24,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
  },
  endState: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    width: Utility.getScreenWidth() - 24,
    paddingHorizontal: 12,
    paddingVertical: 12,
    borderRadius: 8,
  },
});
