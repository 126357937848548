import React, { PureComponent } from 'react';
import {
  Animated,
  View,
  StyleSheet,
  Text,
  ScrollView,
  Image,
} from 'react-native';

import HTML from 'react-native-render-html';

import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import WebListTitle from '../../containers/List/WebListTitle';
import CustomNavigationHeader from './CustomNavigationHeader';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { getScreenWidth } from '../../utils/LayoutUtility';
import DockedHeader from '../../utils/DockedHeader';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';

export default class FullScreenProductDescription extends PureComponent {
  constructor(props) {
    super(props);
    this.navigationRefs = null;
    this.closeToBottom = false;
    this.closeToTop = false;
    this.ignoredStyles = [
      'font-family',
      'line-height',
      'height',
      'width',
      'max-width',
    ];
  }

  htmlView = (props) => {
    const { title, htmlData, marginTop = 0 } = props;
    if (Utility.isBlank(htmlData)) {
      return null;
    }

    const width = Utility.getScreenWidth();
    const data = htmlData.replace(/(\r\n|\n|\r)/gm, '');
    return (
      <View style={{ marginTop, paddingLeft: 8, paddingRight: 8 }}>
        <Text style={styles.htmlViewerTitle}>{title}</Text>
        <View style={styles.htmlViewerContainer}>
          <HTML
            html={`${data}`}
            containerStyle={styles.htmlViewerContainer}
            style={styles.fullWidth}
            imagesMaxWidth={width}
            ignoredStyles={this.ignoredStyles}
            tagsStyles={webViewStyle}
          />
        </View>
      </View>
    );
  };

  closeComponent = () => {
    const { onCloseDueToScroll } = this.props;
    if (this.closeToTop) {
      onCloseDueToScroll();
    }
  };

  goBack = () => {
    AnalyticsUtility.fireAppLoadEvent();
    const { navigation } = this.props;
    navigation.goBack();
  };

  render() {
    const { route } = this.props;
    const {
      name,
      description,
      howTo,
      ingredients,
      metrologicalInfo,
      resetStatusBar,
      onSearchIconTap,
      onPressCart,
    } = route.params;

    const aboutName = `About ${name}`;
    return (
      <>
        {!isDesktop() && (
          <CustomNavigationHeader
            screenName={SCREEN_CONSTANTS.BRAND_DETAILS}
            name={aboutName}
            showCartIcon
            showSearchButton
            goBack={this.goBack}
            onSearchIconTap={onSearchIconTap}
            onPressCart={onPressCart}
            resetStatusBar={resetStatusBar}
            showShareButton={false}
            utmCampaign={'brand'}
          >
            <DockedHeader name={aboutName} />
          </CustomNavigationHeader>
        )}
        {isWeb() && <WebListTitle title={aboutName} />}
        <ScrollView
          style={styles.scrollViewContainer}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.wrapper}>
            <this.htmlView title='' htmlData={`<div>${description}</div>`} />
            <this.htmlView
              title='Ingredients'
              htmlData={`<div>${ingredients}</div>`}
              marginTop={20}
            />
            <this.htmlView
              title='How to Use'
              htmlData={`<div>${howTo}</div>`}
              marginTop={20}
            />
            <this.htmlView
              title='Additional Info'
              htmlData={`<div>${metrologicalInfo}</div>`}
              marginTop={24}
            />
            <View style={styles.separator}></View>
          </View>
        </ScrollView>
      </>
    );
  }
}

const webViewStyle = StyleSheet.create({
  a: {
    color: colors.foxyPink,
  },
  p: { color: colors.foxyBlack },
  li: { color: colors.foxyBlack },
  ol: { color: colors.foxyBlack },
  ul: { color: colors.foxyBlack },
  div: { color: colors.foxyBlack },
  span: { color: colors.foxyBlack },
  b: { color: colors.foxyBlack },
});

const styles = StyleSheet.create({
  container: {
    marginTop: Utility.isAndroid() ? 6 : 25,
  },

  wrapper: {
    paddingTop: 15,
    paddingBottom: 15,
  },

  htmlViewerTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h2,
    color: colors.foxyBlack,
    marginBottom: 8,
  },
  separator: { height: 150 },
  // htmlContainerStyle: { marginTop: 10 },
  htmlStyle: { width: '100%' },
  htmlViewerContainer: {
    // marginTop: 10,
  },
  fullWidth: {
    width: '100%',
  },

  scrollViewContainer: {
    paddingBottom: 150,
    width: getScreenWidth(),
    alignSelf: 'center',
    paddingHorizontal: 12,
    backgroundColor: colors.white,
  },

  a: {
    color: colors.foxyPink,
  },
  p: { color: colors.foxyBlack, marginTop: 2, marginBottom: 2 },
  li: { color: colors.foxyBlack },
  ol: { color: colors.foxyBlack },
  ul: { color: colors.foxyBlack },
  div: { color: colors.foxyBlack },
});
