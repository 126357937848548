import React from 'react';
import { View, Text, TouchableOpacity, Clipboard } from 'react-native';
import { ids, styles } from './styles';
import LinearGradient from 'react-native-linear-gradient';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';

export default function DashboardContactUsCard() {
  const onClickContactUsCard = () => {
    Utility.openWhatsApp(
      `Hi, I'm facing some issues in verifying my OneLink on Instagram. Please help.`,
    );
  };

  const ChatWithUs = () => {
    return (
      <TouchableOpacity
        onPress={onClickContactUsCard}
        style={[styles.editProfileButton, { borderRadius: 4 }]}
      >
        <FastImageView
          source={images.routines.whatsApp_white}
          style={styles.editButtonIcon}
        />
        <Text style={styles.editButtonText}>Chat with us</Text>
      </TouchableOpacity>
    );
  };

  return (
    <LinearGradient
      colors={['#e6fdea', '#e6fdea']}
      style={[styles.dashboardInfoCardContainer, { alignItems: 'flex-start' }]}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 0 }}
    >
      <Text style={[styles.registrationCompletedHeading, { marginTop: 12 }]}>
        Need help?
      </Text>
      <Text style={[styles.registrationCompletedSubheading, { marginTop: 6 }]}>
        Our team is just a WhatsApp message away
      </Text>
      <ChatWithUs />
    </LinearGradient>
  );
}
