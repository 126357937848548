// Dependencies
import React, { Component } from 'react';
import {
  View,
  ScrollView,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import Orientation from 'react-native-orientation';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
// import sharedStyles from './styles';
import Utility from '../../utils/Utility';
import foxyImages from '../../theme/Images';
import colors from '../../theme/Colors';
import StaticNavigationHeader from './StaticNavigationHeader';
import FastImageView from '../FastImageView';
import Config from '../../libraries/ReactNativeConfig';
import { isDesktop } from '../../utils/BooleanUtility';

class ScreenPlaceholder extends Component {
  componentDidMount() {
    // Orientation.lockToPortrait();
  }

  componentWillUnmount() {
    const { firePageLoadEnd = () => {} } = this.props;
    firePageLoadEnd();
  }

  railPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.railPlaceholderShimmerContainer}>
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.productBrandNamePlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.productTextPlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.productTextPlaceholder}
        />
      </View>
    );
  };

  contentPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.contentPlaceholderShimmerContainer}>
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.cntentImagePlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.contentNamePlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.contentArtistImagePlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.contentArtistNamePlaceholder}
        />
      </View>
    );
  };

  headerPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={{ width: '100%', marginTop: 24 }}>
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={
            isDesktop()
              ? styles.headerTextShimmerStyleDesktop
              : styles.headerTextShimmerStyle
          }
        />
      </View>
    );
  };

  headerPlaceholderSmall = () => {
    const styles = sharedStyles;
    return (
      <ShimmerPlaceHolder
        colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
        autoRun
        style={styles.headerTextShimmerSmallStyle}
      />
    );
  };

  headerPlaceholderExtraSmall = () => {
    const styles = sharedStyles;
    return (
      <ShimmerPlaceHolder
        colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
        autoRun
        style={styles.headerTextShimmerExtraSmallStyle}
      />
    );
  };

  headerPlaceholderTwo = () => {
    const styles = sharedStyles;
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          // backgroundColor: colors.white,
        }}
      >
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.headerTextShimmerExtraSmallTwoStyle}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.headerTextShimmerExtraSmallTwoStyle}
        />
      </View>
    );
  };

  headerPlaceholderArray = () => {
    const styles = sharedStyles;
    return (
      <View
        style={{
          flexDirection: 'row',
          overflow: 'hidden',
          flexWrap: 'wrap',
        }}
      >
        {_.map([1, 2, 3, 4, 5], (e) => {
          return (
            <ShimmerPlaceHolder
              colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
              autoRun
              style={styles.headerTextShimmerArrayStyle}
            />
          );
        })}
      </View>
    );
  };

  offerPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View
        style={
          isDesktop()
            ? styles.offerPlaceholderDesktop
            : styles.offerPlaceholderMobile
        }
      >
        {_.map([1, 2], (e) => {
          return (
            <View
              style={{
                height: 128,
                width: 280,
                backgroundColor: colors.white,
                borderRadius: 3,
                marginRight: 12,
                paddingHorizontal: 16,
              }}
            >
              <ShimmerPlaceHolder
                colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                autoRun
                style={{
                  height: 18,
                  width: 74,
                  borderRadius: 4,
                  marginTop: 12,
                }}
              />
              <ShimmerPlaceHolder
                colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                autoRun
                style={{
                  height: 12,
                  width: 248,
                  borderRadius: 4,
                  marginTop: 12,
                }}
              />
              <ShimmerPlaceHolder
                colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                autoRun
                style={{
                  height: 12,
                  width: 124,
                  borderRadius: 4,
                  marginTop: 4,
                }}
              />
              <View
                style={{
                  marginTop: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <ShimmerPlaceHolder
                  colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                  autoRun
                  style={{
                    height: 20,
                    width: 72,
                    borderRadius: 4,
                    marginTop: 4,
                  }}
                />
                <ShimmerPlaceHolder
                  colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                  autoRun
                  style={{
                    height: 24,
                    width: 92,
                    borderRadius: 8,
                    marginTop: 4,
                  }}
                />
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  productImagePlaceHolder = () => {
    return (
      <View
        style={sharedStyles.imagePlaceholderContainer}
        dataSet={{ media: ids.imagePlaceholderContainer }}
      >
        <FastImageView
          style={{
            width: 160,
            height: 160,
            resizeMode: 'contain',
            opacity: 0.2,
          }}
          source={Config.PRODUCT_PAGE_ANIMATED_LOGO}
        />
        <View
          style={{
            position: 'absolute',
            bottom: 16,
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {_.map([1, 2, 3, 4, 5], (e) => {
            return (
              <View
                style={{
                  height: 6,
                  width: 6,
                  backgroundColor: e === 1 ? '#979BAA' : '#DFE0E5',
                  borderRadius: 3,
                  marginRight: 6,
                }}
              />
            );
          })}
        </View>

        <View
          style={{
            height: 32,
            width: 32,
            borderRadius: 16,
            position: 'absolute',
            bottom: 32,
            right: 16,
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: '#F4F6F8',
          }}
        />
      </View>
    );
  };

  productImagePlaceHolderDesktop = () => {
    const styles = sharedStyles;
    return (
      <>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'column',
            marginRight: 4,
          }}
        >
          {_.map([1, 2, 3, 4, 5, 6], (e) => {
            return (
              <ShimmerPlaceHolder
                colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                autoRun
                style={styles.smallImageStyle}
              />
            );
          })}
        </View>
        <View
          style={sharedStyles.imagePlaceholderContainerDesktop}
          dataSet={{ media: ids.imagePlaceholderContainerDesktop }}
        >
          <FastImageView
            style={{
              width: 180,
              height: 180,
              resizeMode: 'contain',
              opacity: 0.2,
            }}
            source={Config.PRODUCT_PAGE_ANIMATED_LOGO}
          />
        </View>
      </>
    );
  };

  pillsAndImageName = () => {
    const style = isDesktop()
      ? sharedStyles.pillsAndImageDesktopView
      : sharedStyles.pillsAndImageMobileView;
    return (
      <View style={style}>
        <this.headerPlaceholder />
        <this.headerPlaceholderSmall />
        <this.headerPlaceholderExtraSmall />
        <this.headerPlaceholderTwo />
        <this.headerPlaceholderArray />
      </View>
    );
  };

  desktopExtraInfoPlaceHolder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.desktopExtraInfoView}>
        {_.map([1, 2, 3, 4], (e) => {
          return (
            <ShimmerPlaceHolder
              colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
              autoRun
              style={styles.extraInfoImageStyle}
            />
          );
        })}
      </View>
    );
  };

  desktopIngredientsPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.desktopIngredientsHeaderStyle}>
        {_.map([1, 2, 3, 4], (e) => {
          return (
            <View style={styles.desktopIngredientsStyle}>
              <ShimmerPlaceHolder
                colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                autoRun
                style={styles.desktopIngredientText}
              />
            </View>
          );
        })}
      </View>
    );
  };

  mobileViewPlaceHolder = () => {
    const styles = sharedStyles;
    return (
      <ScrollView>
        <View style={styles.shimmerContainer}>
          <this.productImagePlaceHolder />
          <this.pillsAndImageName />
          <this.offerPlaceholder />
        </View>
      </ScrollView>
    );
  };

  desktopPlaceHolder = () => {
    const styles = sharedStyles;
    return (
      <ScrollView>
        <View style={styles.desktopContainer}>
          <this.productImagePlaceHolderDesktop />
          <View style={{ flexDirection: 'column' }}>
            <this.pillsAndImageName />
            <this.desktopExtraInfoPlaceHolder />
            <this.offerPlaceholder />
            <this.desktopIngredientsPlaceholder />
          </View>
        </View>
      </ScrollView>
    );
  };

  render() {
    const { hideHeader = false } = this.props;
    return (
      <>
        {!hideHeader && <StaticNavigationHeader title=' ' />}

        {isDesktop() ? (
          <this.desktopPlaceHolder />
        ) : (
          <this.mobileViewPlaceHolder />
        )}
      </>
    );
  }
}

const { ids, styles: sharedStyles } = StyleSheet.create({
  likeButtonStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  desktopContainer: {
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    marginTop: 64,
    alignSelf: 'center',
  },
  addToCartLikeButtonStyles: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderLeftWidth: 2,
    borderTopWidth: 2,
    borderBottomWidth: 2,
    height: 40,
    width: '100%',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderColor: colors.border,
  },
  gradientStyle: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '25%',
  },

  backContainer: {
    position: 'absolute',
    zIndex: 1,
    top: 35,
    left: 16,
  },
  backImage: {
    tintColor: 'white',
    overflow: 'visible',
    elevation: 2,
    shadowRadius: 4,
    shadowColor: colors.black,
    shadowOpacity: 0.6,
    shadowOffset: { width: 1, height: 2 },
  },
  pillsAndImageMobileView: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingHorizontal: 12,
    paddingBottom: 12,
  },
  pillsAndImageDesktopView: {
    width: Utility.getScreenWidth() - 468,
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingHorizontal: 12,
    paddingBottom: 12,
    marginLeft: 8,
    borderRadius: 4,
  },
  desktopExtraInfoView: {
    width: Utility.getScreenWidth() - 468,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
    paddingHorizontal: 12,
    marginLeft: 8,
    borderRadius: 4,
  },
  staticNavigationHeaderContainer: {
    height: 70,
    width: '100%',
    overflow: 'visible',
    zIndex: 1,
    flexDirection: 'row',
    elevation: 2,
    backgroundColor: colors.white,
  },
  divider: {
    position: 'absolute',
    bottom: 0,
    height: 1,
    backgroundColor: colors.border,
    width: '100%',
  },

  navbarIconDefaultStyle: {
    width: 20,
    height: 17.5,
    resizeMode: 'contain',
  },
  pageHeaderText: {
    position: 'absolute',
    fontFamily: 'Roboto-Regular',
    fontSize: 16,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    left: 48,
    bottom: Utility.isIOS() ? 13 : 18,
    right: 40,
    overflow: 'hidden',
  },
  shimmerContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.background,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },
  itemPlaceholder: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginLeft: 12,
    marginRight: 16,
    width: Utility.getScreenWidth() - 2 * Utility.largePadding,
  },

  railPlaceholderShimmerContainer: {
    height: 208,
    width: 146,
    margin: 5,
    marginTop: 24,
    borderRadius: 4,
    backgroundColor: colors.white,
    justifyContent: 'flex-end',
  },

  railPlaceholderShimmerContainerInModal: {
    height: 100,
    width: 146,
    margin: 5,
    marginTop: 12,
    borderRadius: 4,
    backgroundColor: colors.white,
  },

  contentPlaceholderShimmerContainer: {
    height: 132,
    width: 150,
    margin: 5,
    marginTop: 24,
    borderRadius: 4,
    backgroundColor: colors.white,
  },
  cntentImagePlaceholder: {
    width: 150,
    height: 132 - 52,
    borderRadius: 4,
  },
  contentNamePlaceholder: {
    margin: 5,
    width: 140,
    height: 12,
    marginTop: 8,
  },
  contentArtistImagePlaceholder: {
    position: 'absolute',
    left: 5,
    bottom: 5,
    height: 18,
    width: 18,
    borderRadius: 9,
  },
  contentArtistNamePlaceholder: {
    position: 'absolute',
    bottom: 5,
    height: 18,
    width: 70,
    left: 28,
  },
  productTextPlaceholder: {
    margin: 5,
    width: 136,
    height: 12,
  },
  productBrandNamePlaceholder: {
    margin: 5,
    width: 80,
    height: 12,
  },
  smallImageStyle: {
    height: 60,
    width: 60,
    backgroundColor: '#DFE0E5',
    borderRadius: 4,
    marginBottom: 8,
  },
  extraInfoImageStyle: {
    height: 60,
    width: 120,
    backgroundColor: '#DFE0E5',
    borderRadius: 4,
    marginBottom: 8,
  },
  desktopIngredientsStyle: {
    width: Utility.getScreenWidth() - 468,
    backgroundColor: colors.white,
    height: 64,
    marginLeft: 8,
    marginVertical: 4,
    borderRadius: 6,
    justifyContent: 'center',
  },
  desktopIngredientText: {
    height: 20,
    width: 124,
    borderRadius: 4,
    marginLeft: 12,
  },
  desktopIngredientsHeaderStyle: {
    marginTop: 32,
  },
  headerTextShimmerStyle: {
    height: 18,
    width: Utility.getScreenWidth() - 32,
    borderRadius: 4,
  },
  headerTextShimmerStyleDesktop: {
    height: 18,
    width: '96%',
    borderRadius: 4,
  },
  headerTextShimmerSmallStyle: {
    height: 18,
    width: Utility.getScreenWidth() / 2,
    borderRadius: 4,
    marginTop: 8,
  },
  headerTextShimmerExtraSmallStyle: {
    height: 18,
    width: Utility.getScreenWidth() / 4,
    borderRadius: 4,
    marginTop: 24,
  },
  headerTextShimmerExtraSmallTwoStyle: {
    height: 18,
    width: 140,
    borderRadius: 4,
    marginTop: 12,
  },
  headerTextShimmerArrayStyle: {
    height: 24,
    width: 86,
    borderRadius: 12,

    marginRight: 8,
    marginTop: 12,
  },
  offerPlaceholderMobile: { flexDirection: 'row', marginTop: 32 },
  offerPlaceholderDesktop: {
    flexDirection: 'row',
    marginTop: 32,
    marginLeft: 8,
  },
  navigationHeader: {
    position: 'absolute',
    height: 44,
    width: Utility.getScreenWidth(),
    overflow: 'visible',
    backgroundColor: 'white',
    zIndex: 1,
    flexDirection: 'row',
    elevation: 2,
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  imagePlaceholderContainer: {
    height: 1.1 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    zIndex: 1,
    backgroundColor: colors.white,
    paddingTop: Utility.topInset,
    '@media (min-width: 768px)': {
      height: Utility.getScreenWidth() / 3,
    },
  },
  imagePlaceholderContainerDesktop: {
    height: 400,
    width: 400,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    backgroundColor: colors.white,
    borderRadius: 4,
  },
});

export default ScreenPlaceholder;
