import { Text, View, Image, StyleSheet } from 'react-native';
import React, { Component } from 'react';
import Markdown from 'react-native-markdown-display';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import FastImageView from '../../components/FastImageView';
import LoyaltyPlanBenefitsCard from './LoyaltyPlanBenefitsCard';
import { APP_ASSET_URL, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';

export default class LoyaltyPlansWithBenefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlanIndex: 0,
      currentLoyaltyBenefits: {},
    };
    this.benefitCardImagesData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.loyalty_plan_benefits_images),
    );
    this.mesh = `${APP_ASSET_URL}/531/mesh_1__3_.png`;
  }

  onPlanPress = (index, currentLoyaltyBenefits) => {
    this.setCurrentPlanIndex(index);
    this.setCurrentPlanBenefits(currentLoyaltyBenefits);
  };

  setCurrentPlanIndex = (index) => {
    this.setState({
      selectedPlanIndex: index,
    });
  };

  setCurrentPlanBenefits = (currentLoyaltyBenefits) => {
    this.setState({
      currentLoyaltyBenefits,
    });
  };

  benefitsListItem = (e) => {
    return (
      <View style={styles.benefitListItemContainer}>
        <Image
          source={images.check_circle}
          style={styles.benefitsListItemImage}
        />
        <Markdown style={styles}>{e?.shortDescription}</Markdown>
      </View>
    );
  };

  planBenefitsContainer = ([key, value], index) => {
    const imageData = this.benefitCardImagesData[key] || {};
    const {
      top = -24,
      width = 109,
      height = 140,
      right = 0,
      background_color: backgroundColor = '',
      image_url = '',
    } = imageData;
    const imageStyle = [
      styles.planBenefitsImage,
      {
        top,
        width,
        height,
        right,
      },
    ];
    const planBenefitsContainerStyle = [
      styles.planBenefitsContainer,
      { backgroundColor },
    ];
    return (
      <>
        <View style={planBenefitsContainerStyle}>
          <View style={{ flex: 1, paddingVertical: 12 }}>
            {value.map(this.benefitsListItem)}
          </View>
          <FastImageView
            resizeMode={'stretch'}
            source={images.edge.mesh}
            style={styles.meshImage}
          />
          <FastImageView
            resizeMode={'stretch'}
            source={image_url}
            style={imageStyle}
          />
        </View>
      </>
    );
  };

  render() {
    const { selectedPlanIndex, currentLoyaltyBenefits = {} } = this.state;
    const {
      item: loyaltyPlans = [],
      listData = {},
      previousScreen = '',
    } = this.props;
    const sortedLoyaltyPlans = loyaltyPlans.sort((a, b) => {
      const { loyalty_plan: { data: { attributes: aArgs = {} } = {} } = {} } =
        a;
      const aArguments = Utility.isPresent(aArgs) ? aArgs : {};
      const { priority: aPriority } = aArguments;

      const { loyalty_plan: { data: { attributes: bArgs = {} } = {} } = {} } =
        b;
      const bArguments = Utility.isPresent(bArgs) ? bArgs : {};
      const { priority: bPriority } = bArguments;
      return aPriority - bPriority;
    });
    return (
      <View>
        <View style={styles.horizontalRailContainer}>
          {sortedLoyaltyPlans.map((item, index) => (
            <LoyaltyPlanBenefitsCard
              itemData={item?.loyalty_plan}
              onPlanPress={this.onPlanPress}
              selectedPlanIndex={selectedPlanIndex}
              index={index}
              setCurrentPlanBenefits={this.setCurrentPlanBenefits}
              currentLoyaltyBenefits={currentLoyaltyBenefits}
              listData={listData}
              previousScreen={previousScreen}
            />
          ))}
        </View>
        <View style={{ backgroundColor: '#fff' }}>
          {Object.entries(currentLoyaltyBenefits).map(
            this.planBenefitsContainer,
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  horizontalRailContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    marginTop: 8,
    backgroundColor: colors.white,
    paddingVertical: 20,
  },
  planBenefitsContainer: {
    width: Utility.getScreenWidth() - 24,
    minHeight: 162,
    alignSelf: 'center',
    borderRadius: 16,
    marginVertical: 14,
    flexDirection: 'row',
  },
  meshImage: {
    width: 132,
    height: '100%',
    bottom: 0,
  },
  planBenefitsImage: {
    position: 'absolute',
    top: -24,
    right: 0,
    resizeMode: 'contain',
    width: 119,
    height: 150,
  },
  benefitListItemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: 20,
    marginVertical: 6,
  },
  benefitsListItemImage: {
    height: 16,
    width: 16,
    resizeMode: 'contain',
    marginRight: 4,
    marginTop: 2,
  },
  body: {
    color: colors.white,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    marginLeft: 12,
  },
  paragraph: {
    marginTop: 0,
    marginBottom: 0,
  },
});
