// Dependencies
import { element } from 'prop-types';
import React, { Component } from 'react';
import { View, StyleSheet, SafeAreaView } from 'react-native';
import Orientation from 'react-native-orientation';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import StaticNavigationHeader from './StaticNavigationHeader';
import { isDesktop } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class GridScreenPlaceHolder extends Component {
  componentDidMount() {
    // Orientation.lockToPortrait();
    // this.shimmerColors = ;
  }

  componentWillUnmount() {
    const { firePageLoadEnd = () => {} } = this.props;
    firePageLoadEnd();
  }

  shimmerContainer = () => (
    <View style={styles.cardContainer}>
      {/* <ShimmerPlaceHolder autoRun style={styles.cardContainer}/>     */}
      <ShimmerPlaceHolder
        autoRun
        style={styles.cardImageContainer}
        colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.containerBrandName}
        colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.containerProductName}
        colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.containerProductNameSecondLine}
        colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
      />
    </View>
  );

  render() {
    const { columnCount = 3, fullscreen } = this.props;
    const arrayViews = new Array(columnCount).fill(1);

    return (
      <>
        {fullscreen && <StaticNavigationHeader title="" />}
        <View style={styles.wrapper}>
          {arrayViews.map((element, index) => (
            <View style={styles.row} key={index}>
              <this.shimmerContainer />
              <this.shimmerContainer />
              {
                isDesktop() && (
                  <>
                    <this.shimmerContainer />
                    <this.shimmerContainer />
                  </>
                )
              }
            </View>
          ))}
        </View>
      </>
    );
  }
}

const numberOfItems = isDesktop() ? 4 : 2;

const styles = StyleSheet.create({
  shimmerStyle: {},
  wrapper: {
    backgroundColor: colors.background,
    bottom: 10,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  container: {
    flexDirection: 'row',
    paddingLeft: 8,
    paddingRight: 8,
  },
  leftTopMenu: {
    position: 'absolute',
    width: 40,
    height: 12,
    top: 20,
    borderRadius: 4,
    left: 15,
  },

  rightTopMenu: {
    position: 'absolute',
    width: 40,
    height: 12,
    top: 20,
    borderRadius: 4,
    right: 15,
  },
  ratingBox: {
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    width: 80,
    backgroundColor: '#ffffff',
  },

  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: Utility.getDynamicWidthForGrid(numberOfItems, 16),
    height: Utility.getDynamicWidthForGrid(numberOfItems, 16) + 110,
    alignSelf: 'center',
  },

  cardImageContainer: {
    position: 'absolute',
    height: Utility.getDynamicWidthForGrid(numberOfItems, 32) + 16,
    top: 20,
    left: 15,
    width: Utility.getDynamicWidthForGrid(numberOfItems, 32) - 20,
    borderRadius: 8,
  },

  containerBrandName: {
    position: 'absolute',
    left: 15,
    bottom: 56,
    width: 80,
    height: 12,
    borderRadius: 4,
  },
  containerProductName: {
    position: 'absolute',
    left: 15,
    bottom: 36,
    width: Utility.getDynamicWidthForGrid(numberOfItems, 32) - 20,
    height: 12,
    borderRadius: 4,
  },
  containerProductNameSecondLine: {
    position: 'absolute',
    left: 15,
    bottom: 16,
    width: Utility.getDynamicWidthForGrid(numberOfItems, 32) - 20,
    height: 12,
    borderRadius: 4,
  },
  containerPrice: {
    position: 'absolute',
    left: 15,
    bottom: 8,
    width: 60,
    height: 10,
    borderRadius: 4,
  },

  row: {
    flexDirection: 'row',
    paddingLeft: 8,
    paddingRight: 8,
  },
});

export default GridScreenPlaceHolder;
