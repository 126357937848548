import React, { Component } from 'react';
import { View, Text, BackHandler } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCartOffers, getCartItems } from '../../actions/ActionTypes';
import { getOffersPrompt } from '../../actions/PromptsActions';
import PromoCode from '../../components/cart/PromoCode';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';

export class CartPricing extends Component {
  constructor(props) {
    super(props);
    this.state = { offersPrompt: {}, cartOffers: [], isOffersLoading: false };
  }

  componentDidMount() {
    this.getCartOffers();
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  getCartOffers = () => {
    const { getCartOffers, getOffersPrompt } = this.props;
    this.setState({
      isOffersLoading: true,
    });
    getOffersPrompt((success, response) => {
      if (success) {
        this.setState({
          offersPrompt: response,
          //   isOffersLoading: false,
        });
      }
    });
    getCartOffers((success, offersArray) => {
      if (success) {
        this.setState({
          cartOffers: offersArray,
          isOffersLoading: false,
        });
      }
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  render() {
    const { navigation, cartOffers, offersPrompt, isOffersLoading } =
      this.state;
    return (
      <PromoCode
        cartOffers={cartOffers}
        offersPrompt={offersPrompt}
        isOffersLoading={isOffersLoading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  pricingData: state.bag.cartPricing,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getCartOffers,
      getOffersPrompt,
      getCartItems,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CartPricing),
);
