import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import _ from 'lodash';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import ScaleAnimate from '../shared/ScaleAnimate';
import Card from '../../lib/Card';
import { ORIENTATION, LAYOUT } from '../../config/Constants';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  AnalyticsUtilty,
} from '../../analytics';
import FastImageView from '../FastImageView';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

class DefaultSizeBrandGrid extends PureComponent {
  static brandLayoutStyle = {
    vertical: {
      cardElevation: 2,
      cardMaxElevation: 2,
      cornerRadius: 4,
    },
    horizontal: {
      cardElevation: 0,
      cardMaxElevation: 0,
      cornerRadius: 0,
    },
  };

  static getComponentHeight(columns) {
    return Utility.getDynamicWidthForGrid(columns, 16);
  }

  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceBrandCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
  }

  navigate = () => {
    const {
      navigation,
      id,
      itemData,
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      toggleCartVisibility,
      layout,
      previousScreen,
      listData,
      listId,
      listName,
      listContent,
    } = this.props;

    if (previousScreen === 'recommendation') {
      this.followBrand && this.followBrand();
      return null;
    }

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };

      this.debounceBrandCardClick(meta);
    }

    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.id,
        'brand',
        itemData.name,
        index,
        listId,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        itemData.id,
        itemData?.slug,
        listData?.slug,
      );
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Brand',
      params: {
        id,
        itemData,
        slug: itemData.slug,
        display: LAYOUT.SCREEN,
        toggleCartVisibility,
      },
    });
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  brandImage = (props) => {
    const { imageUrl, imageWidth, imageHeight, name } = props;

    return (
      <View style={styles.brandImageContainer}>
        <FastImageView
          source={imageUrl}
          resizeMode='cover'
          height={imageHeight}
          width={imageWidth}
          style={styles.roundEdges}
          onError={this.imageError}
        >
          {Utility.isBlank(imageUrl) ? (
            <Text style={styles.placeholderText} numberOfLines={2}>
              {name}
            </Text>
          ) : null}
        </FastImageView>
      </View>
    );
  };

  render() {
    const {
      listData,
      orientation = ORIENTATION.HORIZONTAL,
      previousScreen,
      itemData: { image_webp_url, name } = {},
      itemData,
    } = this.props;

    if (itemData === undefined) {
      return null;
    }

    const minifiedImageUrl = Utility.getMinifiedImage(image_webp_url, 200);

    let noOfColumns = 2;

    if (Utility.isPresent(listData) && previousScreen !== 'more_page') {
      noOfColumns = listData.columns || 2;
    }

    if (isDesktop()) {
      noOfColumns *= 2;
    }

    let imageHeight = Utility.getDynamicWidthForGrid(noOfColumns, 16);
    let imageWidth = Utility.getDynamicWidthForGrid(noOfColumns, 16);

    if (noOfColumns === 3) {
      imageWidth = imageWidth - 2;
      imageHeight = imageHeight - 2;
    } else if (noOfColumns === 4) {
      imageWidth = imageWidth - 4;
      imageHeight = imageHeight - 4;
    }

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <Card
          {...DefaultSizeBrandGrid.brandLayoutStyle[orientation]}
          style={styles.verticalCardStyle}
        >
          <this.brandImage
            imageUrl={minifiedImageUrl}
            imageBackgroundStyle={styles.imageBackgroundStyle}
            name={name}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
          />
        </Card>
      </ScaleAnimate>
    );
  }
}

export default withNavigation(DefaultSizeBrandGrid);

const styles = StyleSheet.create({
  verticalCardStyle: {
    backgroundColor: 'transparent',
    margin: 4,
    overflow: 'visible',
    borderRadius: 4,
    alignSelf: 'center',
  },
  imageBackgroundStyle: {
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'black',
  },
  brandImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholderText: {
    position: 'absolute',
    color: 'white',
    bottom: 8,
    left: 8,
    right: 8,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  roundEdges: {
    borderRadius: 4,
  },
});
