import { memoize } from 'lodash';
import React from 'react';
import { Text } from 'react-native';
import { styles } from './styles';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';

const PillsTabCard = (props) => {
  const { selectedIndex, index, item, onTabPress } = props;
  const pillTabStyle =
    index === selectedIndex
      ? styles.selectedPillsTabStyle
      : styles.unselectedPillsTabStyle;
  const tabTextStyle =
    index === selectedIndex
      ? styles.selectedPillsTabTextStyle
      : styles.unselectedPillsTabTextStyle;
  return (
    <DebouncedTouchableOpacity
      {...props}
      style={pillTabStyle}
      onPress={memoize(
        () => onTabPress(item, index),
        () => [index],
      )}
      delayPressOut={0}
    >
      <Text style={tabTextStyle}>{item.name}</Text>
    </DebouncedTouchableOpacity>
  );
};

export default PillsTabCard;
