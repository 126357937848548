import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import HTML from 'react-native-render-html';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

class RoutineBenefitsCard extends PureComponent {
  render() {
    const { htmlData = '' } = this.props;
    let data = htmlData?.replace(/(\r\n|\n|\r)/gm, '');
    data = data.replace('<ul>', '');
    data = data.replace('</ul>', '');
    if (Utility.isBlank(data)) return null;
    return (
      <View style={styles.container}>
        <HTML
          html={`${data}`}
          tagsStyles={{
            li: styles.listItemContainer,
            img: styles.listItemIcon,
            a: {
              color: colors.foxyPink,
              marginVertical: 4,
            },
            p: { color: colors.foxyBlack, marginVertical: 4 },
            ol: { color: colors.foxyBlack, marginVertical: 4 },
            ul: { color: colors.foxyBlack, marginVertical: 4 },
            div: { color: colors.foxyBlack, marginVertical: 4 },
            span: { color: colors.foxyBlack, marginVertical: 4 },
            br: { color: colors.foxyBlack, marginVertical: 4 },
          }}
        />
      </View>
    );
  }
}

export default RoutineBenefitsCard;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingTop: 14,
    paddingBottom: 14,
    borderRadius: 8,
    alignItems: 'flex-start',
    backgroundColor: colors.white,
    marginVertical: 6,
    overFlow: 'hidden',
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    marginBottom: 6,
  },
  listItemText: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    width: '90%',
  },
  listItemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingVertical: 6,
    width: Utility.getScreenWidth() - 60,
    paddingHorizontal: 8,
  },
  listItemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    marginRight: 8,
    tintColor: colors.black,
    marginTop: 12,
  },
});
