import { schema } from 'normalizr';

export const videoSchema = new schema.Entity('videos');
export const imageSchema = new schema.Entity('images');
export const salonSchema = new schema.Entity('salons');
export const brandSchema = new schema.Entity('brands');
export const artistSchema = new schema.Entity('artists');
export const listSchema = new schema.Entity('lists');
export const featureSchema = new schema.Entity('features');
export const adSchema = new schema.Entity('ads');
export const bannerSchema = new schema.Entity('banners');
export const productSchema = new schema.Entity('products');
export const shortBannerSchema = new schema.Entity('short_banners');
export const variantAttributesSchema = new schema.Entity('variant_attributes');
export const variantSchema = new schema.Entity('variants');
export const reviewSchema = new schema.Entity('reviews');
export const addressSchema = new schema.Entity('address');
export const addressesSchema = new schema.Array(addressSchema);
export const campaignSchema = new schema.Entity('campaign');
export const campaignsSchema = new schema.Array(campaignSchema);
export const categorySchema = new schema.Array('media_categorys');
export const tagSchema = new schema.Entity('tags');
export const offerSchema = new schema.Entity('offers');

export const objectsArray = new schema.Array(
  {
    products: productSchema,
    videos: videoSchema,
    images: imageSchema,
    salons: salonSchema,
    brands: brandSchema,
    artists: artistSchema,
    lists: listSchema,
    features: featureSchema,
    ads: adSchema,
    banners: bannerSchema,
    short_banners: shortBannerSchema,
    reviews: reviewSchema,
    media_categories: categorySchema,
    tags: tagSchema,
    offers: offerSchema,
  },
  (input, _parent, _key) => `${input.type}s`,
);

artistSchema.define({
  objects: objectsArray,
});

brandSchema.define({
  objects: objectsArray,
});

variantAttributesSchema.define({
  allowed_values: [variantSchema],
});

productSchema.define({
  objects: objectsArray,
  brand: brandSchema,
  reviews: reviewSchema,
  variant_attributes: [variantAttributesSchema],
});

salonSchema.define({
  objects: objectsArray,
});

imageSchema.define({
  objects: objectsArray,
  artist: artistSchema,
});

videoSchema.define({
  artist: artistSchema,
  objects: objectsArray,
});

listSchema.define({
  objects: objectsArray,
});

reviewSchema.define({
  video_reviews: listSchema,
});

campaignSchema.define({
  products: new schema.Array(productSchema),
});

offerSchema.define({
  list: listSchema,
});
