import * as types from '../actions/ActionTypes';
import initialState from './initialState';

export default function searchDetails(state = initialState.searchDetails, action) {
  switch (action.type) {
    case types.EMPTY_SEARCH_DATA_RECEIVED:
      return {
        ...state,
        topSearches: action.data,
      };
    case types.SEARCH_SUGGESTIONS_RECEIVED:
      return {
        ...state,
        searchSuggestions: action.data.suggestions,
      };
    default:
      return state;
  }
}
