import { useEffect, useState } from 'react';
import Config from '../../libraries/ReactNativeConfig';

import AppConfig from '../../config/AppConfig';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import VariantUtility from '../../utils/VariantUtility';

const useDefaultSelectedVariantForProduct = (data, routeSkuId = '') => {
  const [selectedVariant, setSelectedVariant] = useState({});
  const [currentSku, setCurrentSku] = useState(data?.sku_id);

  const [selectedMultiVariantPrimary, setSelectedMultiVariantPrimary] =
    useState({});
  const [selectedMultiVariantSecondary, setSelectedMultiVariantSecondary] =
    useState({});
  useEffect(() => {
    if (
      !AppConfig.getBooleanValue(
        Config.PRODUCT_PAGE_SHOW_DEFAULT_SELECTED_VARIANT,
      )
    ) {
      return;
    }
    const {
      variants_details: { principal_sku_id = '' } = {},
      multi_level_variant_attributes = [],
    } = data;

    const preSelectedSkuId = routeSkuId || principal_sku_id;

    if (
      VariantUtility.checkIfMultiVariant(
        data?.multi_level_variant_attributes,
      )
    ) {
      let primaryVariantToBeSet =
        VariantUtility.getParentVariantForChildVariantId(
          preSelectedSkuId,
          data?.multi_level_variant_attributes,
        );

      // in case empty first variant should be selected.
      if (isBlank(primaryVariantToBeSet)) {
        primaryVariantToBeSet = multi_level_variant_attributes[0];
      }

      const {
        multi_level_variant_attributes: primaryMultiVariantAttributes = [],
      } = primaryVariantToBeSet;
      let secondaryVariantToBeSet = primaryMultiVariantAttributes?.filter(
        (secondaryVariant) => secondaryVariant?.variant?.sku_id === preSelectedSkuId,
      )?.[0];

      // in case empty first variant should be selected.
      if (isBlank(secondaryVariantToBeSet)) {
        secondaryVariantToBeSet = primaryMultiVariantAttributes[0];
      }

      setSelectedMultiVariantPrimary(primaryVariantToBeSet);
      setSelectedMultiVariantSecondary(secondaryVariantToBeSet);
      const {
        variant: selectedVariantData = {},
        variant: { sku_id: selectedVariantSkuId } = {},
      } = secondaryVariantToBeSet;
      setSelectedVariant(selectedVariantData);
      setCurrentSku(selectedVariantSkuId);
    } else {
      const selectedVariantItem =
        multi_level_variant_attributes.filter(
          (e) => e?.variant?.sku_id === preSelectedSkuId,
        )?.[0]?.variant || multi_level_variant_attributes?.[0]?.variant;

      if (isPresent(selectedVariantItem) && isBlank(selectedVariant)) {
        setSelectedVariant(selectedVariantItem);
        setCurrentSku(selectedVariantItem.sku_id);
      } else {
        setCurrentSku(data.sku_id);
      }
    }
  }, [
    selectedVariant,
    data?.multi_level_variant_attributes?.length,
    data?.sku_id,
  ]);

  return {
    selectedVariant,
    selectedMultiVariantPrimary,
    setSelectedVariant,
    setSelectedMultiVariantPrimary,
    selectedMultiVariantSecondary,
    setSelectedMultiVariantSecondary,
    currentSku,
    setCurrentSku,
  };
};

export default useDefaultSelectedVariantForProduct;
