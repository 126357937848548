import _ from 'lodash';
import * as types from '../actions/ActionTypes';
import initialState from './initialState';
import Utility from '../utils/Utility';

export default function queue(state = initialState.queue, action) {
  if (Utility.isBlank(action.data)) {
    return state;
  }
  const { id, type } = action.data;
  switch (action.type) {
    case types.FOLLOW_ARTIST_FAILED:
      return {
        ...state,
        followedArtist: _.includes(state.followedArtist, action.data.id)
          ? state.followedArtist
          : [...state.followedArtist, action.data.id],
      };
    case types.UNFOLLOW_ARTIST_FAILED:
      return {
        ...state,
        unfollowedArtist: _.includes(state.unfollowedArtist, action.data.id)
          ? state.unfollowedArtist
          : [...state.unfollowedArtist, action.data.id],
      };
    case types.LIKE_ITEM_FAILED:
      const likedItem = _.capitalize(type);
      return {
        ...state,
        [`liked${likedItem}`]: _.includes(state[`liked${likedItem}`], action.data.id)
          ? state[`liked${likedItem}`]
          : [...state[`liked${likedItem}`], action.data.id],
      };
    case types.UNLIKE_ITEM_FAILED:
      const unlikedItem = _.capitalize(type);
      return {
        ...state,
        [`unliked${unlikedItem}`]: _.includes(state[`unliked${unlikedItem}`], action.data.id)
          ? state[`unliked${unlikedItem}`]
          : [...state[`unliked${unlikedItem}`], action.data.id],
      };
    default:
      return state;
  }
}
