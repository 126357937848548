import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import CustomerReview from './CustomerReview';
import colors from '../../theme/Colors';
import FullWidthDivider from '../../utils/FullWidthDivider';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import images from '../../theme/Images';

export default function ShopOnlineRating({ rating = {} }) {
  const navigation = useNavigation();
  const { product = {} } = rating;
  const {
    slug,
    id,
    image_url: imageUrl,
    brand_name: brandName,
    name,
  } = product;
  const handlePress = () => navigateToScreen({
    navigation,
    type: 'push',
    screen: 'Product',
    params: { slug },
  });
  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.product} onPress={handlePress}>
        <Image source={{ uri: imageUrl }} style={styles.image} resizeMode="contain" />
        <View>
          <Text style={styles.brandName}>{brandName}</Text>
          <Text style={styles.productName}>{name}</Text>
        </View>
        <Image source={images.chevronRight} style={styles.arrow} />
      </TouchableOpacity>
      <CustomerReview
        item={rating}
        productId={id}
        productSlug={slug}
      />
      <FullWidthDivider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingTop: 20,
  },
  product: {
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  image: {
    width: 36,
    height: 36,
    marginRight: 10,
  },
  brandName: {
    fontFamily: 'Roboto-Regular',
    fontSize: 11,
    fontWeight: '500',
    color: colors.subtitle,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: getScreenWidth() - 100,
  },
  productName: {
    fontFamily: 'Roboto-Regular',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: colors.foxyBlack,
    maxWidth: getScreenWidth() - 100,
  },
  arrow: {
    height: 12,
    width: 12,
    tintColor: colors.black,
    position: 'absolute',
    right: 15,
  },
});
