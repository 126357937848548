import { takeEvery, takeLatest, select } from 'redux-saga/effects';
import { UNLINK_DEVICE } from '../actions/LoginActions';
import { URL } from '../config/Constants';
import { getApiHeaders } from './GeneratorUtil';

export function* unlinkDevice() {
  const deviceId = yield select((state) => state.UserAccountInfo.deviceId);

  const payload = {
    ssid: deviceId,
  };

  const response = yield fetch(URL.UNLINK_DEVICE, {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: JSON.stringify(payload),
  });
}

export default function* watchAutoLoginSaga() {
  yield takeLatest(UNLINK_DEVICE, unlinkDevice);
}
