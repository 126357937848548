import React, { PureComponent } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from '../profile/styles/YoutubeStyles';
import images from '../../theme/Images';

export default class CollapsedIngredientsCards extends PureComponent {
  render() {
    const { itemData, onActionPressed } = this.props;

    return (
      <View style={{ marginHorizontal: 12, marginBottom: 10 }}>
        <TouchableOpacity
          style={styles.verifyInstaContainer}
          onPress={onActionPressed}
        >
          <Text allowFontScaling={false} style={styles.verifyInstaText}>
            {itemData.title}
          </Text>
          <Image source={images.chevronRight} style={styles.rightArrow} />
        </TouchableOpacity>
      </View>
    );
  }
}
