import React, { PureComponent } from 'react';

import { AnalyticsManager, EventType } from '../../analytics';


export default class ErrorBoundaryComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      isError: false,
    };
  }

  componentDidCatch(error, info) {
    const { screenName, itemData = {}, listData = {} } = this.props;
    this.setState({
      isError: true,
    });
    AnalyticsManager.logEvent(EventType.errorEvents.CARD_RENDER_ERROR, {
      screenName,
      item_type: itemData?.type,
      item_id: itemData?.id,
      item_name: itemData?.name,
      list_name: listData?.name,
      list_id: listData?.id,
    });
  }

  render() {
    const { isError } = this.state;
    const { children } = this.props;

    return isError ? null : children;
  }
}
