// Dependencies
import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';

class OrderHistoryShimmer extends Component {
  constructor(props) {
    super(props);
    this.shimmerColors = ['#f7F7f7', '#eeeeee', '#f7F7f7'];
  }

  offerPlaceholder = () => {
    return (
      <View style={styles.offerPlaceholderContainer}>
        <View style={styles.innerContainer}>
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_1}
          />
          <this.divider />
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_2}
          />
          <ShimmerPlaceHolder
            colorShimmer={this.shimmerColors}
            autoRun
            style={styles.shimmer_3}
          />
          <View style={styles.shimmerArrayContainer}>
            {_.map([1, 2, 3], (e) => {
              return (
                <ShimmerPlaceHolder
                  colorShimmer={this.shimmerColors}
                  autoRun
                  style={styles.shimmerArrayItem}
                />
              );
            })}
          </View>
        </View>
      </View>
    );
  };

  divider = () => {
    return <View style={styles.divider} />;
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.shimmerContainer}>
          <this.offerPlaceholder />
          <this.offerPlaceholder />
          <this.offerPlaceholder />
          <this.offerPlaceholder />
          <this.offerPlaceholder />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  shimmerContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.background,
  },
  offerPlaceholderContainer: {
    flexDirection: 'row',
    marginTop: 12,
    marginHorizontal: 12,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },
  innerContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    paddingBottom: 12,
  },
  shimmer_1: {
    height: 16,
    width: Utility.getScreenWidth() / 3,
    borderRadius: 4,
    marginVertical: 12,
    marginLeft: 12,
  },
  shimmer_2: {
    height: 16,
    width: Utility.getScreenWidth() / 4,
    borderRadius: 4,
    marginTop: 12,
    marginLeft: 12,
  },
  shimmer_3: {
    height: 16,
    width: Utility.getScreenWidth() / 2,
    borderRadius: 4,
    marginTop: 4,
    marginLeft: 12,
  },
  shimmerArrayContainer: { flexDirection: 'row', alignItems: 'center' },
  shimmerArrayItem: {
    height: 36,
    width: 36,
    borderRadius: 24,
    marginRight: 4,
    marginTop: 12,
    marginLeft: 12,
  },
  divider: {
    height: 1,
    backgroundColor: colors.background,
    width: Utility.getScreenWidth(),
  },
  container: {
    flex: 1,
    backgroundColor: '#f4f6f8',
  },
});

export default OrderHistoryShimmer;
