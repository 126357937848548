import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  Image,
} from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';

export default class SavedPaymentCard extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      onCardPress,
      bankName,
      cardNumber,
      expiryMonth,
      expiryYear,
      cardUserName,
      cardBrand,
      onPressCard,
      onDeleteCard,
    } = this.props;

    const brand = cardBrand.toLowerCase();
    return (
      <TouchableWithoutFeedback onPress={onPressCard}>
        <View style={styles.cardLayoutContainer}>
          <View
            style={[
              styles.row,
              {
                flex: 0.7,
                justifyContent: 'space-between',
                paddingRight: 5,
                paddingLeft: 5,
              },
            ]}
          >
            <Text style={styles.bankName}>{bankName}</Text>
            <TouchableWithoutFeedback onPress={onDeleteCard}>
              <Image source={images.delete} />
            </TouchableWithoutFeedback>
          </View>

          <View style={[styles.column, { flex: 1.6 }]}>
            <Text style={styles.cardNumber}>{cardNumber}</Text>
            <View
              style={[
                styles.row,
                {
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                },
              ]}
            >
              <Text style={styles.cardUserName}>{cardUserName}</Text>
              <Image
                source={images.cardBrand[brand.toLowerCase()]}
                style={styles.cardBrandLogoInSavedCard}
              />
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  cardLayoutContainer: {
    height: 110,
    width: 220,
    padding: 8,
    borderWidth: 1,
    borderColor: colors.borderDark,
    marginRight: 8,
    marginTop: 0,
    flexDirection: 'column',
    borderRadius: 4,
    backgroundColor: colors.white,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  bankName: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    color: colors.foxyBlack,
  },
  cardNumber: {
    marginTop: 16,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h4,

    color: colors.foxyBlack,
  },
  cardUserName: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.foxyBlack,
  },
  cardBrandLogoInSavedCard: {
    height: 20,
    width: 30,
    resizeMode: 'cover',
  },

  deleteIcon: {
    position: 'absolute',
    right: 10,
  },
});
