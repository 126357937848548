import React from 'react';
import { Image } from 'react-native';

const FastImage = ({ children, source, ...props }) => {
  return (
    <Image source={source.uri} {...props}>
      {children}
    </Image>
  );
};

export default FastImage;
