import { Text, TouchableOpacity, View } from 'react-native';
import React, { PureComponent } from 'react';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import uuidv1 from 'uuid/v1';
import uuidv5 from 'uuid/v5';
import styles from './styles';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import { getSelectedDateAndTime } from '../../../utils/DateAndTimeUtility';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  addOrderRequestDetail,
  setVideoUploadProgress,
  updateOrderRequestDetail,
} from '../../../actions/ActionTypes';
import { StaticNavigationHeader } from '../../../components/shared';
import withNavigation from '../../../utils/WithNavigation';
import {
  publishPost,
  addPostToLocalState,
} from '../../../actions/UploadPostActions';
import { uploadOrderRequestImage } from '../../../actions/FacialAnalysisActions';
import AddEmailModal from './AddEmailModal';
import _ from 'lodash';
import { REGEX } from '../../../config/Constants';
import UploadStatusTray from '../../feed/UploadStatusTray';
import { setCongratsModalForEdge } from '../../../actions/FoxyEdgeAction';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import RoutinesUtility from '../../../components/Routines/RoutinesUtility';
import DebouncedTouchableOpacity from '../../../components/shared/DebouncedTouchableOpacity';

class SelectCallBackTimeSlot extends PureComponent {
  constructor(props) {
    super(props);
    const currentDate = new Date().toUTCString();
    this.currentDateTime = moment.utc(currentDate);
    const defaultDateAndTime = getSelectedDateAndTime();
    this.state = {
      selectedSlotDate: new Date(),
      isSelectedDatePill: true,
      selectSlotTime: defaultDateAndTime,
      isAddEmailModalVisible: false,
      isSelectedTimePillIndex: 0,
    };
    const { navigation, route } = this.props;
    this.selectedMediaContent = route.params?.selectedMediaData;
    this.reasonBody = route.params?.reasonBody;
    this.headerTitle = route.params?.headerTitle || 'Request a callback';
    this.callBackRequestReasonId = route.params?.callBackRequestReasonId;
    this.entityId = route.params?.entityId;
    this.orderId = route.params?.orderId;
    this.callBackRequestId = route.params?.callBackRequestId;
    this.currentTime = moment().format('h:00 a');
    this.slotTimed = [
      {
        slotTime: 'AnyTime',
        availableSlotTime: this.currentTime,
        initialTime: this.currentTime,
      },
      {
        slotTime: '8:00 am - 12:00 pm ',
        availableSlotTime: '11:00 am',
        initialTime: '08:00 am',
      },
      {
        slotTime: '12:00 pm - 4:00 pm ',
        availableSlotTime: '03:00 pm',
        initialTime: '12:00 pm',
      },
      {
        slotTime: '4:00 pm - 8:00 pm',
        availableSlotTime: '07:00 pm',
        initialTime: '04:00 pm',
      },
    ];
  }

  componentDidMount() {
    AnalyticsManager.logEvent('page_load_2', {
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.SELECT_CALLBACK_SLOT_TIME,
      [EventParameterKey.SOURCE]: this.headerTitle,
    });
  }

  validate = (email) => {
    try {
      return REGEX.EMAIL.test(email);
    } catch (err) {
      return false;
    }
  };

  handleSelectSlotTodayDate = () => {
    this.selectSlotDate('Today');
  };

  handleSelectSlotTomorrowDate = () => {
    this.selectSlotDate('Tomorrow');
  };

  datePills = () => {
    const { isSelectedDatePill } = this.state;
    const currentDate = moment().format('MMM D');
    const nextDate = moment().add(1, 'days').format('MMM D');
    const isSelectedPillStyle = isSelectedDatePill
      ? styles.SelectedPillStyle
      : styles.pillStyle;
    const isSelectedSecondPillStyle = !isSelectedDatePill
      ? styles.SelectedPillStyle
      : styles.pillStyle;
    return (
      <View style={styles.datePillContainer}>
        <View style={isSelectedPillStyle}>
          <DebouncedTouchableOpacity
            {...this.props}
            onPress={this.handleSelectSlotTodayDate}
          >
            <Text style={styles.pillText}>Today, {currentDate}</Text>
          </DebouncedTouchableOpacity>
        </View>
        <View style={isSelectedSecondPillStyle}>
          <DebouncedTouchableOpacity
            {...this.props}
            onPress={this.handleSelectSlotTomorrowDate}
          >
            <Text style={styles.pillText}>Tomorrow, {nextDate}</Text>
          </DebouncedTouchableOpacity>
        </View>
      </View>
    );
  };

  hideAddEmailModalVisible = () => {
    this.setState({
      isAddEmailModalVisible: false,
    });
  };

  onPressSubmit = () => {
    const { userEmail, navigation, updateOrderRequestDetail } = this.props;
    const { selectSlotTime } = this.state;
    if (Utility.isBlank(userEmail)) {
      this.setState({
        isAddEmailModalVisible: true,
      });
      return;
    }
    if (Utility.isBlank(selectSlotTime)) {
      return;
    }
    const data = {
      callRequestTime: selectSlotTime,
      email: userEmail,
      callBackRequestId: this.callBackRequestId,
    };
    updateOrderRequestDetail(data, this.updateOrderRequestDetailCallBack);
  };

  updateOrderRequestDetailCallBack = (success, response) => {
    const { navigation } = this.props;
    const { selectSlotTime } = this.state;
    if (success) {
      AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
        [EventParameterKey.SOURCE]: this.headerTitle,
        [EventParameterKey.PAGE]: SCREEN_CONSTANTS.SELECT_CALLBACK_SLOT_TIME,
        [EventParameterKey.CTA]: 'Submit',
        selected_slot_time: selectSlotTime,
      });
      navigation.navigate('ConfirmRequestPage', {
        headerTitle: this.headerTitle,
        heading: 'We have noted your request! ✔️',
        subHeading:
          'Our customer care will get in touch with you at the earliest.',
      });
    }
  };

  selectSlotDate = (day) => {
    const { isSelectedDatePill } = this.state;
    let selectedDate = new Date();
    if (day === 'Tomorrow') {
      const tomorrow = new Date(selectedDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      selectedDate = tomorrow;
    }
    const defaultDateAndTime = getSelectedDateAndTime(selectedDate);
    this.setState({
      isSelectedDatePill: !isSelectedDatePill,
      selectedSlotDate: selectedDate,
      selectSlotTime: defaultDateAndTime,
    });
  };

  onPressAddEmail = (emailId) => {
    const { updateOrderRequestDetail, navigation } = this.props;
    const { selectSlotTime } = this.state;
    const data = {
      orderId: '',
      callRequestTime: selectSlotTime,
      callBackRequestReasonId: '',
      reason: '',
      email: emailId,
    };
    updateOrderRequestDetail(data, this.updateOrderRequestDetailCallBack);
  };

  selectSlotTime = (time, index) => {
    const { selectedSlotDate } = this.state;
    const selectedDateAndTime = getSelectedDateAndTime(selectedSlotDate, time);
    this.setState({
      selectSlotTime: selectedDateAndTime,
      isSelectedTimePillIndex: index,
    });
  };

  checkIfTimeSlotIsAvailable = (item = {}) => {
    const { availableSlotTime, slotTime } = item;
    const { selectedSlotDate } = this.state;
    if (RoutinesUtility.isToday(selectedSlotDate)) {
      if (slotTime === 'AnyTime') {
        return false;
      }
      const slotDateTime = moment(availableSlotTime, 'hh:mm a').toDate();
      return new Date().getTime() > slotDateTime.getTime();
    }
    return !RoutinesUtility.isDateInFuture(selectedSlotDate);
  };

  timeSelectPills = ({ item, index }) => {
    const { selectedSlotDate, isSelectedTimePillIndex } = this.state;
    const isSelectedPillStyle =
      index === isSelectedTimePillIndex
        ? styles.SelectedPillStyle
        : styles.pillStyle;
    const isDisabled = this.checkIfTimeSlotIsAvailable(item);
    const isActiveSlotPillStyle = isDisabled
      ? styles.disablePillStyle
      : isSelectedPillStyle;
    return (
      <View>
        <TouchableOpacity
          style={isActiveSlotPillStyle}
          onPress={() => this.selectSlotTime(item.initialTime, index)}
          disabled={isDisabled}
        >
          <Text style={styles.pillText}>{item.slotTime}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  selectTimeSlot = () => {
    return (
      <View style={styles.timeSlotContainer}>
        <Text style={styles.boldText}>Preferred time slot</Text>
        <Text style={styles.subHeading}>
          Our customer care teams operate between 8:00 AM and 8:00 PM daily.
          They will fulfill all requests within the following time slots -
        </Text>
        <View style={styles.timePillContainer}>
          {this.slotTimed.map((item, index) => (
            <this.timeSelectPills item={item} index={index} />
          ))}
        </View>
      </View>
    );
  };

  render() {
    const { selectedSlotDate, isAddEmailModalVisible } = this.state;
    return (
      <View style={styles.container}>
        <StaticNavigationHeader title={this.headerTitle} />
        <View style={styles.timePickerContainer}>
          <Text style={styles.boldText}>Preferred day for you</Text>
          <this.datePills />
          <this.selectTimeSlot />
        </View>
        <AddEmailModal
          onDismiss={this.hideAddEmailModalVisible}
          isVisible={isAddEmailModalVisible}
          onPressAddEmail={this.onPressAddEmail}
        />
        <View style={styles.buttonContainer}>
          <FoxyShadowButton
            width={Utility.getScreenWidth() - 24}
            title={'Submit'}
            onPress={this.onPressSubmit}
            style={styles.button}
            firstColor={colors.black}
            secondColor={colors.black}
            underlayColor={colors.subtitle}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isVideoUploading: store.UserAccountInfo.isUploading,
    userEmail: store.UserAccountInfo.profile.email,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addOrderRequestDetail,
      publishPost,
      addPostToLocalState,
      uploadOrderRequestImage,
      setVideoUploadProgress,
      updateOrderRequestDetail,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(SelectCallBackTimeSlot),
);
