import { StyleSheet, Platform } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { isIOS } from '../../utils/BooleanUtility';

const iconsBottomSpace = Utility.isIOS() ? 8 : 12;
const sharedStyles = StyleSheet.create({
  likeButtonStyle: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  likeButtonImage: {
    width: 25,
    height: 25,
    marginTop: 2,
    resizeMode: 'contain',
    tintColor: colors.transparent,
  },
  listOrCardLikeImage: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
    zIndex: 100,
  },
  unLikeButtonImage: {
    width: 25,
    height: 25,
    marginTop: 2,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
  },
  addToCartLikeButtonStyles: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderLeftWidth: 2,
    borderTopWidth: 2,
    borderBottomWidth: 2,
    height: 40,
    width: '100%',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderColor: colors.border,
  },
  gradientStyle: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '25%',
  },
  likeButtonContainerForHeader: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: iconsBottomSpace,
    right: 88,
  },
  backContainer: {
    position: 'absolute',
    zIndex: 1,
    top: 35,
    left: 16,
  },
  backImage: {
    tintColor: 'white',
    overflow: 'visible',
    elevation: 2,
    shadowRadius: 4,
    shadowColor: colors.black,
    shadowOpacity: 0.6,
    shadowOffset: { width: 1, height: 2 },
  },
  staticNavigationHeaderContainer: {
    height: 70,
    width: Utility.screenWidth,
    overflow: 'visible',
    // zIndex: 1,
    flexDirection: 'row',
    // elevation: 2,
    backgroundColor: colors.white,
    alignSelf: 'center',
  },
  productDescriptionHeaderContainer: {
    height: 70,
    width: '100%',
    // overflow: 'visible',
    zIndex: 1,
    flexDirection: 'row',
    // elevation: 2,
    backgroundColor: colors.white,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  transparentHeaderContainer: {
    height: 70 + Utility.topInset,
    width: '100%',
    // overflow: 'visible',
    zIndex: 1,
    flexDirection: 'row',
    // elevation: 2,
    backgroundColor: colors.white,
    position: 'absolute',
    top: 0 + Utility.topInset,
    left: 0,
    right: 0,
  },
  divider: {
    position: 'absolute',
    bottom: 0,
    height: 1,
    backgroundColor: colors.border,
    width: '100%',
  },
  navigationBackButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    left: 12,
    bottom: iconsBottomSpace,
    zIndex: 1,
  },
  navbarIconDefaultStyle: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  navbarIconBlackStyle: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
  },
  backButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    left: 12,
    zIndex: 1,
    bottom: 8,
  },
  pageHeaderText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    position: 'absolute',
    bottom: Utility.isIOS() ? 13 : 18,
    right: 40,
    overflow: 'hidden',
    ...Platform.select({
      web: {
        left: 88,
      },
      default: {
        left: 48,
      },
    }),
  },
  pageHeaderTextWithSubtitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    position: 'absolute',

    bottom: Utility.isIOS() ? 20 : 25,
    right: 40,
    overflow: 'hidden',
    ...Platform.select({
      web: {
        left: 88,
      },
      default: {
        left: 48,
      },
    }),
  },
  pageHeaderTitleShimmer: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    position: 'absolute',
    bottom: Utility.isIOS() ? 13 : 18,
    right: 40,
    width: 58,
    overflow: 'hidden',
    ...Platform.select({
      web: {
        left: 88,
      },
      default: {
        left: 48,
      },
    }),
  },
  shimmerContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.background,
    alignSelf: 'center',
  },
  shimmerContainerWhite: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.white,
    paddingBottom: 30,
    alignSelf: 'center',
  },
  itemPlaceholder: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginLeft: 12,
    marginRight: 16,
    width: Utility.getScreenWidth() - 2 * Utility.largePadding,
    marginTop: 24,
  },
  railPlaceholderShimmerContainer: {
    height: 208,
    width: 146,
    margin: 5,
    marginTop: 24,
    borderRadius: 4,
    backgroundColor: colors.white,
    justifyContent: 'flex-end',
  },

  railPlaceholderShimmerContainerInModal: {
    height: 100,
    width: 146,
    margin: 5,
    marginTop: 12,
    borderRadius: 4,
    backgroundColor: colors.white,
  },

  contentPlaceholderShimmerContainer: {
    height: 132,
    width: 150,
    margin: 5,
    marginTop: 24,
    borderRadius: 4,
    backgroundColor: colors.white,
  },
  cntentImagePlaceholder: {
    width: 150,
    height: 132 - 52,
    borderRadius: 4,
  },
  contentNamePlaceholder: {
    margin: 5,
    width: 140,
    height: 12,
    marginTop: 8,
  },
  contentArtistImagePlaceholder: {
    position: 'absolute',
    left: 5,
    bottom: 5,
    height: 18,
    width: 18,
    borderRadius: 9,
  },
  contentArtistNamePlaceholder: {
    position: 'absolute',
    bottom: 5,
    height: 18,
    width: 70,
    left: 28,
  },
  productTextPlaceholder: {
    margin: 5,
    width: 136,
    height: 12,
  },
  productBrandNamePlaceholder: {
    margin: 5,
    width: 80,
    height: 12,
  },
  headerTextShimmerStyle: {
    height: 22,
    width: 160,
    borderRadius: 4,
    backgroundColor: '#EAEAEA',
  },
  headerLongTextShimmerStyle: {
    height: 22,
    width: 220,
    borderRadius: 4,
    backgroundColor: '#EAEAEA',
  },
  navigationHeader: {
    position: 'absolute',
    height: 44,
    width: Utility.getScreenWidth(),
    overflow: 'visible',
    backgroundColor: 'white',
    zIndex: 1,
    flexDirection: 'row',
    elevation: 2,
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  story: {
    height: 64,
    width: 64,
    borderRadius: 32,
    backgroundColor: '#EAEAEA',
  },
  storyContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 16,
  },
  largeBannerContainer: {
    height: 198,
    width: Utility.getScreenWidth() - 24,
    marginTop: 24,
    borderRadius: 8,
    backgroundColor: '#EAEAEA',
  },
  edgeFeatureCardPlaceholder: {
    height: 94,
    marginTop: 12,
    width: Utility.getScreenWidth() - 28,
    borderRadius: 16,
    backgroundColor: colors.border,
  },
  storyText: {
    height: 24,
    width: 64,
    marginTop: 12,
    borderRadius: 8,
    backgroundColor: '#EAEAEA',
  },
  gridContainer: {
    height: Utility.getDynamicWidthForGrid(2, 16),
    width: Utility.getDynamicWidthForGrid(2, 16),
    borderRadius: 8,
    backgroundColor: '#EAEAEA',
    marginRight: 4,
  },
  mediaCardPlaceholder: {
    width: Utility.getScreenWidth() - 80,
    height: 525,
    marginRight: 12,
    marginVertical: 12,
    borderRadius: 16,
    backgroundColor: colors.white,
  },
  mediaCardPlaceholderTitle: {
    width: '100%',
    height: 16,
    marginBottom: 4,
  },
  mediaCardPlaceholderSubtitle: {
    width: '40%',
    height: 12,
  },
  mediaCardPlaceholderContent: {
    flexDirection: 'row',
    position: 'absolute',
    top: 12,
    left: 12,
    width: '100%',
    zIndex: 1,
    padding: 12,
  },
  mediaCardPlaceholderProfile: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 12,
  },
  navigationShareButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: iconsBottomSpace,
    right: 52,
  },
  navigationBackButtonButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: iconsBottomSpace,
    right: 16,
  },
  navbarShareIconStyle: {
    resizeMode: 'contain',
  },
  navbarInfoIconStyle: {
    resizeMode: 'contain',
    height: 24,
    width: 24,
  },

  scanIconSize: {
    resizeMode: 'contain',
    height: 24,
    width: 24,
  },
  featureCardPlaceholderContainer: {
    backgroundColor: colors.white,
    borderRadius: 16,
    marginRight: 12,
    width: Utility.getScreenWidth() - 60,
    height: 160,
    marginVertical: 12,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 20,
  },
  featurePlaceholderTitle: {
    width: 166,
    height: 28,
    marginBottom: 8,
  },
  featurePlaceholderSubtitle: {
    width: 200,
    height: 40,
  },
  featurePlaceholderButton: {
    height: 22,
    width: 94,
    borderRadius: 16,
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  tabNavigationRightHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginLeft: 'auto',
    marginBottom: isIOS() ? 13 : 18,
  },
});

export default sharedStyles;
