// Constants
export const ADD_POST = 'ADD_POST';
export const ADD_POST_CACHE_DIRECTORY_PATH = 'ADD_POST_CACHE_DIRECTORY_PATH';
export const ADD_ITEMS_IN_CURRENT_POST = 'ADD_ITEMS_IN_CURRENT_POST';
export const UPDATE_CONTENT_ITEMS = 'UPDATE_CONTENT_ITEMS';
export const DELETE_CURRENT_CONTENT_ITEM = 'DELETE_CURRENT_CONTENT_ITEM';
export const SAVE_CONTENT_URI_ABSOLUTE_PATH = 'SAVE_CONTENT_URI_ABSOLUTE_PATH';
export const ADD_VIDEO_FRAMES = 'ADD_VIDEO_FRAMES';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT_SEARCH_LIST_ID = 'UPDATE_PRODUCT_SEARCH_LIST_ID';
export const UPDATE_CROP_IMAGE = 'UPDATE_CROP_IMAGE';
export const CREATE_POST = 'CREATE_POST';
export const ADD_CAPTION = 'ADD_CAPTION';
export const ADD_THUMBNAIL = 'ADD_THUMBNAIL';
export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
export const TOGGLE_VIDEOTYPE = 'TOGGLE_VIDEOTYPE';
export const ADD_HASHTAG = 'ADD_HASHTAG';
export const DELETE_HASHTAGS = 'DELETE_HASHTAGS';
export const TOGGLE_SOCIALMEDIA = 'TOGGLE_SOCIALMEDIA';
export const TOGGLE_EDIT_VIDEO = 'TOGGLE_EDIT_VIDEO';
export const TOGGLE_PUBLISHING_SETTINGS = 'TOGGLE_PUBLISHING_SETTINGS';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const SHOW_SEARCH_SUGGESTIONS = 'SHOW_SEARCH_SUGGESTIONS';
export const STORE_MEDIA_ITEMS = 'STORE_MEDIA_ITEMS';
export const SAVE_MEDIA_ITEM_BASE64_URI = 'SAVE_MEDIA_ITEM_BASE64_URI';
export const SEARCH_HASHTAG = 'SEARCH_HASHTAG';
export const SEARCH_ARTIST = 'SEARCH_ARTIST';
export const PUBLISH_POST = 'PUBLISH_POST';
export const ADD_POST_THUMBNAIL = 'ADD_POST_THUMBNAIL';
export const MEDIA_EDITING_INITIATE = 'MEDIA_EDITING_INITIATE';
export const UPDATE_EDITED_VIDEO_FRAGMENT = 'UPDATE_EDITED_VIDEO_FRAGMENT';
export const ADD_SPLIT_VIDEOS = 'ADD_SPLIT_VIDEOS';
export const GET_MEDIA_CATEGORIES = 'GET_MEDIA_CATEGORIES';
export const STORE_POST_SERVER_ID = 'STORE_POST_SERVER_ID';
export const UPDATE_CONCATENATED_VIDEO = 'UPDATE_CONCATENATED_VIDEO';
export const REORDER_MEDIA_ITEMS = 'REORDER_MEDIA_ITEMS';
export const ADD_POST_TO_LOCAL_STATE = 'ADD_POST_TO_LOCAL_STATE';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_DRAFT = 'DELETE_DRAFT';
export const UPDATE_POST_TITLE = 'UPDATE_POST_TITLE';
export const UPLOAD_SCANNED_IMAGE = 'UPLOAD_SCANNED_IMAGE';
export const SAVE_SCANNED_IMAGE_URI = 'SAVE_SCANNED_IMAGE_URI';

export function addPost(post) {
  return {
    type: ADD_POST,
    post,
  };
}

export function addPostCacheDirectoryPath(postID, postCacheDirectoryPath) {
  return {
    type: ADD_POST_CACHE_DIRECTORY_PATH,
    postID,
    postCacheDirectoryPath,
  };
}

export function addItemsInCurrentPost(postID, newContentItems) {
  return {
    type: ADD_ITEMS_IN_CURRENT_POST,
    postID,
    newContentItems,
  };
}

export function updateEditedVideoFragment(
  postID,
  currentContentItemIndex,
  fragment,
) {
  return {
    type: UPDATE_EDITED_VIDEO_FRAGMENT,
    postID,
    currentContentItemIndex,
    fragment,
  };
}

export function updateContentItems(postID, contentItems, callback) {
  return {
    type: UPDATE_CONTENT_ITEMS,
    postID,
    contentItems,
    callback,
  };
}

export function deleteCurrentContentItem(postID, currentContentItemIndex) {
  return {
    type: DELETE_CURRENT_CONTENT_ITEM,
    postID,
    currentContentItemIndex,
  };
}

export function saveContentUriAbsolutePath(
  postID,
  currentContentItemIndex,
  path,
) {
  return {
    type: SAVE_CONTENT_URI_ABSOLUTE_PATH,
    postID,
    currentContentItemIndex,
    path,
  };
}

export function addVideoFrames(postID, currentContentItemIndex, fileNames) {
  return {
    type: ADD_VIDEO_FRAMES,
    postID,
    currentContentItemIndex,
    fileNames,
  };
}

export function addProducts(postID, currentMediaItemIndex, product) {
  return {
    type: ADD_PRODUCTS,
    postID,
    currentMediaItemIndex,
    product,
  };
}

export function deleteProduct(postID, currentMediaItemIndex, productID) {
  return {
    type: DELETE_PRODUCT,
    postID,
    currentMediaItemIndex,
    productID,
  };
}

export function updateProductSearchListId(
  postID,
  currentMediaItemIndex,
  listID,
) {
  return {
    type: UPDATE_PRODUCT_SEARCH_LIST_ID,
    postID,
    currentMediaItemIndex,
    listID,
  };
}

export function updateCropImage(postID, currentMediaItemIndex, newMediaUri) {
  console.tron.log(
    'Data in Update crop images',
    postID,
    currentMediaItemIndex,
    newMediaUri,
  );
  return {
    type: UPDATE_CROP_IMAGE,
    postID,
    currentMediaItemIndex,
    newMediaUri,
  };
}

export function createPost(media) {
  return {
    type: CREATE_POST,
    media,
  };
}

export function addCaption(postId, caption) {
  return {
    type: ADD_CAPTION,
    postId,
    caption,
  };
}

export function addThumbnail(postId, thumbnail) {
  return {
    type: ADD_THUMBNAIL,
    postId,
    thumbnail,
  };
}

export function toggleCategory(postId, categoryName) {
  return {
    type: TOGGLE_CATEGORY,
    postId,
    categoryName,
  };
}

export function toggleVideoType(postId, videoType) {
  return {
    type: TOGGLE_VIDEOTYPE,
    postId,
    videoType,
  };
}

export function addHashTag(postId, tagName) {
  return {
    type: ADD_HASHTAG,
    postId,
    tagName,
  };
}

export function toggleSocialMedia(postId, socialMediaSites) {
  return {
    type: TOGGLE_SOCIALMEDIA,
    postId,
    socialMediaSites,
  };
}

export function toggleEditVideo(postId) {
  return {
    type: TOGGLE_EDIT_VIDEO,
    postId,
  };
}

export function togglePublishingSettings(postId) {
  return {
    type: TOGGLE_PUBLISHING_SETTINGS,
    postId,
  };
}

export function searchProduct(searchTerms, callback) {
  return {
    type: SEARCH_PRODUCT,
    searchTerms,
    callback,
  };
}

export function showSearchSuggestion(searchText, callback) {
  return {
    type: SHOW_SEARCH_SUGGESTIONS,
    searchText,
    callback,
  };
}

export function reorderMediaItems(postID, ItemKeyToMove, ItemNewPosition) {
  return {
    type: REORDER_MEDIA_ITEMS,
    postID,
    ItemKeyToMove,
    ItemNewPosition,
  };
}

export function publishPost(postID, postData, callback) {
  return {
    type: PUBLISH_POST,
    postID,
    postData,
    callback,
  };
}

export function updatePostTitle(postID, postData) {
  return {
    type: UPDATE_POST_TITLE,
    postID,
    postData,
  };
}

export function addPostThumbnail(postID, thumbnailImage) {
  return {
    type: ADD_POST_THUMBNAIL,
    postID,
    thumbnailImage,
  };
}

export function mediaEditingInitiate(postID) {
  return {
    type: MEDIA_EDITING_INITIATE,
    postID,
  };
}

export function storeMediaItems(
  postID,
  currentMediaItemIndex,
  mediaItemExternalPath,
) {
  return {
    type: STORE_MEDIA_ITEMS,
    postID,
    currentMediaItemIndex,
    mediaItemExternalPath,
  };
}

export function saveMediaItemBase64Uri(
  postID,
  currentMediaItemIndex,
  mediaItemBase64Uri,
) {
  return {
    type: SAVE_MEDIA_ITEM_BASE64_URI,
    postID,
    currentMediaItemIndex,
    mediaItemBase64Uri,
  };
}

export function searchHashTag(searchString, callback) {
  return {
    type: SEARCH_HASHTAG,
    searchString,
    callback,
  };
}

export function searchArtist(searchString, callback) {
  return {
    type: SEARCH_ARTIST,
    searchString,
    callback,
  };
}

export function addSplitVideos(
  postID,
  currentContentItemIndex,
  VideoPart1Data,
  VideoPart2Data,
) {
  return {
    type: ADD_SPLIT_VIDEOS,
    postID,
    currentContentItemIndex,
    VideoPart1Data,
    VideoPart2Data,
  };
}

export function getMediaCategories() {
  return {
    type: GET_MEDIA_CATEGORIES,
  };
}

export function storePostServerID(postID, serverID) {
  return {
    type: STORE_POST_SERVER_ID,
    postID,
    serverID,
  };
}

export function updateConcatenatedVideo(postID, contentItems) {
  return {
    type: UPDATE_CONCATENATED_VIDEO,
    postID,
    contentItems,
  };
}

export function addPostToLocalState(postObject, callback) {
  return {
    type: ADD_POST_TO_LOCAL_STATE,
    postObject,
    callback,
  };
}

export function updatePost(postObject, callback) {
  return {
    type: UPDATE_POST,
    title: postObject.title,
    media_category_id: postObject.media_category_id,
    postId: postObject.postId,
    callback,
  };
}

export function deleteDraft(data) {
  return {
    type: DELETE_DRAFT,
    data,
  };
}

export function uploadScannedImage(imageUrl, callback) {
  return {
    type: UPLOAD_SCANNED_IMAGE,
    imageUrl,
    callback,
  };
}

export function saveLastScannedImageUrl(imageUrl) {
  return {
    type: SAVE_SCANNED_IMAGE_URI,
    imageUrl,
  };
}
