import { StyleSheet, Dimensions, Platform } from 'react-native';
import colors, { Theme } from '../../../theme/Colors';

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Theme.camera.backgroundColor,
  },
});

export default styles;
