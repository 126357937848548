import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  Text,
  KeyboardAvoidingView,
  Keyboard,
  ActivityIndicator,
} from 'react-native';
import Modal from 'react-native-modal';
import { popUpIds, PopUpStyles } from './styles';
import images from '../../theme/Images';
import { withEither } from '../../lib/Monads';
import Utility from '../../utils/Utility';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { editProfile } from '../../actions/ActionTypes';
import { TextField } from 'react-native-material-textfield';
import colors from '../../theme/Colors';
import { AnalyticsManager, EventParameterKey } from '../../analytics';

class AddEmailModal extends Component {
  addEmailModalConstants = {
    invalidEmail: 'Email address is invalid',
  };

  state = {
    email: '',
    isEmailValid: true,
    showLoader: false,
  };

  addEmail = (value) => {
    const isValid = value.includes('.') ? Utility.validateEmail(value) : true;
    this.setState({
      email: value,
      isEmailValid: isValid,
    });
  };

  emailInput = () => {
    const { email, isEmailValid } = this.state;
    return (
      <View style={PopUpStyles.addEmailContainer}>
        <TextField
          style={PopUpStyles.textInputStyleForEmail}
          label="Email"
          placeholder="Enter your email"
          onChangeText={this.addEmail}
          value={email}
          autoFocus={true}
          autoCapitalize={false}
          hideLabel={false}
          inputContainerStyle={{ borderBottomColor: colors.border }}
          error={
            !isEmailValid ? this.addEmailModalConstants.invalidEmail : null
          }
          tintColor={colors.silver}
        />
      </View>
    );
  };

  cancelpressed = () => {
    const { hideEmailModal } = this.props;
    hideEmailModal();
    Keyboard.dismiss();
  };

  continuePressed = () => {
    const { email } = this.state;
    const { editProfile, furtherAction, skuId, stocked_status } = this.props;
    AnalyticsManager.logEvent(EventParameterKey.ITEM_ACTION_NEW, {
      [EventParameterKey.TYPE]: 'email_submit',
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.EMAIL_PRESENT]: Utility.isPresent(email),
      [EventParameterKey.STOCKED_STATUS]: stocked_status,
    });
    const isValid = Utility.validateEmail(email);
    if (!isValid) {
      this.setState({
        isEmailValid: isValid,
      });
      return;
    }
    const user = {};
    user.email = email;
    Keyboard.dismiss();
    this.setState({
      showLoader: true,
    });
    editProfile(user, {}, (callbackData, success) => {
      if (success) {
        this.cancelpressed();
        setTimeout(() => {
          if (furtherAction) {
            furtherAction();
          }
          this.setState({
            showLoader: false,
          });
        }, 1000);
      } else {
        this.cancelpressed();
      }
    });
  };

  isIosFn = () => Utility.isIOS();

  customKeyboardAvoid = withEither(this.isIosFn, KeyboardAvoidingView)(View);

  render() {
    const submitText = 'Save';
    const reviewText = 'Add Email';
    const styles = PopUpStyles;
    const { isVisible } = this.props;
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.8}
        backdropTransitionOutTiming={0}
        style={styles.changePincodeModal}
        onBackdropPress={this.cancelpressed}
        swipeDirection="down"
        onSwipeComplete={this.cancelpressed}
      >
        <SafeAreaView style={styles.addEmailModalContainer} dataSet={{ media: popUpIds.addEmailModalContainer }}>
          <this.customKeyboardAvoid
            behavior="position"
            enabled
            keyboardVerticalOffset={-18}
          >
            <View style={PopUpStyles.container} dataSet={{ media: popUpIds.container }}>
              <View style={PopUpStyles.addReviewHeader}>
                <Text style={PopUpStyles.reviewHeaderText}>{reviewText}</Text>
                <TouchableOpacity
                  style={PopUpStyles.cancelButton}
                  onPress={this.cancelpressed}
                >
                  <Image
                    source={images.cross}
                    style={PopUpStyles.cancelStyle}
                  />
                </TouchableOpacity>
              </View>
              <this.emailInput />
              <TouchableOpacity
                style={[
                  PopUpStyles.submitButton,
                  { marginBottom: 22 + Utility.bottomInset },
                ]}
                onPress={this.continuePressed}
              >
                <ActivityIndicator
                  style={styles.activityIndicatorInActionButton}
                  animating={this.state.showLoader}
                  color={colors.white}
                ></ActivityIndicator>
                <Text style={PopUpStyles.submitText}>{submitText}</Text>
              </TouchableOpacity>
            </View>
          </this.customKeyboardAvoid>
        </SafeAreaView>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ editProfile }, dispatch),
});

export default connect(null, mapDispatchToProps)(AddEmailModal);
