export const REMOVE_BLUE_CIRCLE = 'REMOVE_BLUE_CIRCLE';
export const HIDE_STORIES_INTRO = 'HIDE_STORIES_INTRO';
export const APPEND = 'APPEND';
export const VIEWED_TOPIC = 'VIEWED_TOPIC'

export const removeBlueCircle = (id) => {
  return {
    type: REMOVE_BLUE_CIRCLE,
    id,
  };
};

export const hideStoriesIntro = () => {
  return {
    type: HIDE_STORIES_INTRO,
  };
};

export const append = () => {
  return {
    type: APPEND,
  };
};

export function viewTopic(topicId, videoId) {
  return {
    type: VIEWED_TOPIC,
    topicId,
    videoId,
  };
}

