import React, { PureComponent } from 'react';
import { TouchableHighlight } from 'react-native';
import _ from 'lodash';

export default class DebouncedTouchableHighlight extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedOnPress = _.debounce(this.customOnPress, 1000, {
      leading: true,
      trailing: false,
    });
  }

  customOnPress = () => {
    const { onPress } = this.props;
    onPress();
  };

  render() {
    const { children } = this.props;
    return (
      <TouchableHighlight {...this.props} onPress={this.debouncedOnPress}>
        {children}
      </TouchableHighlight>
    );
  }
}
