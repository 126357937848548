import { StyleSheet, Platform } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

const BrandStyles = StyleSheet.create({
  verticalCardStyle: {
    backgroundColor: 'white',
    borderColor: '#efefef',
    margin: 4,
    elevation: 3,
    shadowRadius: 1,
    shadowOpacity: 0.24,
    shadowColor: colors.black,
    shadowOffset: { width: 1, height: 2 },
    overflow: 'visible',
    borderRadius: 4,
  },
  horizontalCardStyle: {
    borderRadius: 4,
    backgroundColor: '#fff',
    borderWidth: 0,
    borderColor: '#efefef',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  likeButtonStyle: {
    marginRight: 22,
  },
  brandBannerContainer: {
    bottom: 0,
  },
  errorStateContainer: {
    flex: 1,
  },
  brandName: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    color: colors.foxyBlack,
    fontStyle: 'normal',
    lineHeight: 14,
    marginTop: 5,
  },
  brandImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  brandDescStyle: {
    backgroundColor: 'white',
    marginBottom: 2,
    ...Platform.select({
      web: { alignItems: 'flex-start' },
      default: { alignItems: 'center' },
    }),
  },
  brandShortDescription: {
    marginTop: 12,
    marginHorizontal: 12,
    color: '#173143',
    opacity: 0.7,
  },
  descButtonContainer: {
    flexDirection: 'row',
    elevation: -2,
    height: 60,
    bottom: 8,
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    left: 12,
    ...Platform.select({
      web: {
        alignSelf: 'flex-start',
      },
    }),
  },
  knowMoreButtonContainer: {
    flex: 8,
    alignItems: 'flex-end',
    right: 15,
    ...Platform.select({
      web: { marginLeft: 20 },
      default: {},
    }),
  },
  shareButtonStyle: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
    backgroundColor: '#fff',
    elevation: 5,
    borderRadius: 16,
    shadowColor: '#555',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
  listImageBackgroundStyle: {
    marginLeft: 8,
    borderRadius: 4,
    overflow: 'hidden',
  },
  gridImageBackgroundStyle: {
    borderRadius: 4,
    overflow: 'hidden',
  },
  railImageBackgroundStyle: {
    borderRadius: 4,
    overflow: 'hidden',
  },
  listSeparatorStyle: {
    height: 1,
    backgroundColor: '#eaeaea',
    alignSelf: 'center',
    width: Utility.getScreenWidth() - 24,
  },
  scrollview: {
    backgroundColor: colors.background,
  },
  bannerImage: {
    zIndex: 1,
    width: Utility.getScreenWidth(),
    height: 0.333 * Utility.getScreenWidth(),
    overflow: 'visible',
    backgroundColor: 'black',
  },
  likeButton: {
    flex: 1,
    alignItems: 'flex-start',
    elevation: 2,
    shadowRadius: 4,
    shadowColor: colors.black,
    shadowOpacity: 0.6,
    shadowOffset: { width: 1, height: 1 },
    overflow: 'visible',
  },
  blurView: {
    zIndex: 1,
    flex: 1,
    justifyContent: 'flex-end',
    overflow: 'visible',
    height: 0.333 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },
  placeholderText: {
    position: 'absolute',
    color: 'white',
    bottom: 8,
    left: 8,
    right: 8,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  // blurView: {
  //   // position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   bottom: 0,
  //   right: 0,
  // },
  feedFooterContainer: {
    width: Utility.getScreenWidth(),
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerButtonView: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 16,
    borderColor: colors.foxyPink,
    borderWidth: 1,
  },
  footerTextHeading: {
    textAlign: 'center',
    color: '#979BAA',
    fontSize: 12,
    fontWeight: '400',
  },
  footerButtonText: {
    textAlign: 'center',
    color: colors.cta.lightBlue,
    fontSize: 14,
    marginTop: 8,
    fontWeight: '500',
    marginRight: 4,
  },
  footerChevronImage: {
    tintColor: '#4285F4',
    marginTop: 6,
  },
  footerSearchImage: {
    tintColor: '#4285F4',
    marginTop: 4,
  },
  footerTextAndImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullScreenRichDescriptionStyle: {
    height: Utility.getScreenHeight(),
    width: '100%',
    paddingHorizontal: 12,
    backgroundColor: colors.white,
  },
});

export default BrandStyles;
