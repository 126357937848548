// Dependencies
import React, { Component } from 'react';
import { View, Image, ImageBackground, Text, Platform } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import Video from '../../libraries/ReactNativeVideo';
import _, { camelCase } from 'lodash';
import { withMaybe } from '../../lib/Monads';
import Card from '../../lib/Card';
import {
  LAYOUT,
  ORIENTATION,
  THEME,
  MAX_LINES,
  SIZE,
  ContentPlatforms,
} from '../../config/Constants';
import fontSize from '../../theme/Fonts';
import colors from '../../theme/Colors';

// Components
import { MEDIA } from '../../config/LayoutConstants/ContentMediaConfig';
import MediaCardStyles from './styles';
import images from '../../theme/Images';
import LikeButton from '../shared/LikeButton';
import ScaleAnimate from '../shared/ScaleAnimate';
import { playlistStyle } from './Style';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import MultipleProgressView from '../../lib/MultipleProgressView';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { AnalyticsUtilty } from '../../analytics';
import Orientation from 'react-native-orientation';
import FastImageView from '../FastImageView';
import ErrorBoundaryComponent from '../shared/ErrorBoundaryComponent';
import { isNative } from '../../utils/BooleanUtility';

class HorizontalPlaylistCard extends Component {
  static VideoComponents = {
    foxy: Video,
  };

  volumeListener = null;

  state = {
    shouldPlay: false,
    muted: true,
  };

  componentDidMount() {
    // console.tron.log('componentDidMount adding volume listener');
    // this.volumeListener = SystemSetting.addVolumeListener((data) => {
    //   const volume = this.isAndroid ? data.music : data.value;
    //   this.setState({ muted: volume === 0 });
    // });

    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.lockScreenToPortrait();
    });
  }

  lockScreenToPortrait = (payload) => {
    if (isNative()) {
      Orientation.lockToPortrait();
    }
  };

  componentWillUnmount() {
    // SystemSetting.removeVolumeListener(this.volumeListener);
    this.unsubscribe();
  }

  forRailOrGridLayout = (props) =>
    props.layout == LAYOUT.RAIL || props.layout == LAYOUT.GRID;

  listOrCardCondition = (props) =>
    !(props.layout === LAYOUT.CARD || props.layout === LAYOUT.LIST);

  artistVerifiedCondition = (props) => !props.verified;

  viewsWithCondition = withMaybe(this.listOrCardCondition)(Text);

  LikeButtonWithCondition = withMaybe(this.forRailOrGridLayout)(LikeButton);

  verifiedTickWithCondition = withMaybe(this.artistVerifiedCondition)(Image);

  navigateToMedia = () => {
    const {
      navigation,
      item,
      id,
      display,
      listName,
      listIndex,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' } = {},
      listContent,
    } = this.props;

    const itemData = item[0];
    if (Utility.isPresent(itemData)) {
      const { slug = '' } = itemData;
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.metadata.id,
        itemData.type,
        itemData.metadata.title,
        0,
        id,
        display,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
      );
    }
    navigation.push('ContentModal', {
      itemData,
      listId: id,
      index: 0,
      listData,
    });
  };

  // onViewportEnter = () => {
  //   const { updateCurrentlyPlayingIndex, currentlyPlayingIndex, index } = this.props;
  //   const { shouldPlay } = this.state;
  //   if (Utility.isPresent(currentlyPlayingIndex) || shouldPlay) {
  //     return;
  //   }

  //   updateCurrentlyPlayingIndex(index);
  //   this.setState({ shouldPlay: true });
  // };

  // onViewportLeave = () => {
  //   const { updateCurrentlyPlayingIndex } = this.props;
  //   const { shouldPlay } = this.state;
  //   if (shouldPlay) {
  //     updateCurrentlyPlayingIndex(null);
  //     this.setState({ shouldPlay: false });
  //   }
  // };

  MediaImage = (props) => {
    const { imageUrl, orientation } = props;
    const { shouldPlay } = this.state;
    if (shouldPlay) {
      return null;
    }
    const styles = playlistStyle;
    let newImageUrl = imageUrl;
    if (!Utility.isBlank(imageUrl)) {
      newImageUrl = Utility.getMinifiedImage(
        imageUrl,
        MEDIA.CARD.horizontalPlaylist.defaultSize.imagewidth,
        MEDIA.CARD.horizontalPlaylist.defaultSize.imageheight,
      );
    }
    return (
      <ImageBackground
        resizeMode="cover"
        source={{ uri: newImageUrl }}
        style={{
          width: MEDIA.CARD.horizontalPlaylist.defaultSize.imagewidth,
          height: MEDIA.CARD.horizontalPlaylist.defaultSize.imageheight,
        }}
      >
        <Image
          source={images.videoIcon}
          style={[
            styles.verticalPlaylistContentTypeIndicatorStyle,
            { top: 8 },
          ]}
        />
      </ImageBackground>
    );
  };

  MediaViews = (props) => {
    const { views } = props;
    if (views === '0' || views === 0) {
      return null;
    }
    return (
      <View style={MediaCardStyles.viewsContainer}>
        <View style={MediaCardStyles.cardDotStyle} />
        <Text
          style={[MediaCardStyles.cardViewCount, { color: colors.white }]}
        >{`${views}`}</Text>
        <Text
          style={[
            MediaCardStyles.cardViewCount,
            { color: colors.white, marginLeft: 2 },
          ]}
        >
          views
        </Text>
      </View>
    );
  };

  progressIndicator = (props) => {
    const { totalVideos } = props;
    return (
      <View style={{ marginLeft: 6, marginRight: 6, marginBottom: 8 }}>
        <MultipleProgressView
          currentIndex={0}
          total={_.min([totalVideos, 15])}
          progress={0}
        />
      </View>
    );
  };

  MediaDescription = (props) => {
    const { shouldPlay } = this.state;
    if (shouldPlay) {
      return null;
    }
    const {
      views,
      description,
      size,
      artistName,
      artistImage,
      verified,
      orientation,
      totalProducts,
      totalVideos,
    } = props;
    const styles = MediaCardStyles;
    const descriptionStyle = styles.cardMediaDescriptionContainer;
    const gradientArray = ['rgba(0, 0, 0, 0)', 'rgba(1, 1, 1, 0.7)'];
    const marginBottom = totalProducts > 0 ? 52 : 0;
    return (
      <View style={[descriptionStyle, { marginBottom }]}>
        <LinearGradient
          colors={gradientArray}
          style={[
            MediaCardStyles.gradientStyle,
            { height: MEDIA.CONSTANTS.gradientHeight.card },
          ]}
        >
          <Text
            numberOfLines={MAX_LINES.single}
            style={MediaCardStyles.cardTextStyle}
          >
            {description}
          </Text>
          <View style={[styles.cardArtistContainer, { marginBottom: 6 }]}>
            <FastImageView
              borderRadius={9}
              resizeMode="cover"
              source={artistImage}
              style={MediaCardStyles.artistMiniAvatar}
            />
            <Text
              numberOfLines={MAX_LINES.single}
              style={[
                MediaCardStyles.artistName,
                {
                  fontSize: fontSize.h4,
                  color: colors.white,
                },
              ]}
            >
              {artistName}
            </Text>
            <this.verifiedTickWithCondition
              verified={verified}
              source={images.verifiedTick}
              style={MediaCardStyles.verifiedTick}
            />

            <this.MediaViews
              orientation={orientation}
              views={views}
              size={size}
              textColor={colors.white}
              viewCountColor={colors.white}
            />
          </View>
          <this.progressIndicator totalVideos={totalVideos} />
        </LinearGradient>
      </View>
    );
  };

  product = (props) => {
    const styles = playlistStyle;
    const { product, index, totalProducts } = props;
    if (Utility.isBlank(product)) {
      return null;
    }
    let imageUrl = '';

    try {
      imageUrl = Utility.getImageUrlForProducts(
        product.images,
        product.image_url,
      );
    } catch {}

    const newUrl = Utility.getMinifiedImage(imageUrl, 120, 120);

    if (index === 5) {
      return (
        <View
          style={[
            styles.productCircle,
            {
              justifyContent: 'center',
              alignItems: 'center',
            },
          ]}
        >
          <Text
            style={[styles.productsUsedText, { color: colors.foxyBlack }]}
          >{`+${totalProducts - 4}`}</Text>
        </View>
      );
    }
    return (
      <View>
        <Image source={{ uri: newUrl }} style={styles.productCircle} />
      </View>
    );
  };

  productsUsed = (props) => {
    const styles = playlistStyle;
    const { products } = props;
    const productImagesWidth =
      Utility.getScreenWidth() - 4 * Utility.padding - 75;
    return (
      <View style={styles.horizontalProductUsedContainer}>
        <View style={styles.productUsedTextContainer}>
          <Text style={styles.productsUsedText}>Products Used</Text>
        </View>
        <View
          style={[styles.productUsedImages, { width: productImagesWidth }]}
        >
          {products.map((product, index) =>
            index < 6 ? (
              <this.product
                key={`horizontal_playlist_${product.id}`}
                product={product}
                index={index}
                totalProducts={products.length}
              />
            ) : null,
          )}
        </View>
      </View>
    );
  };

  renderMedia = (props) => {
    const { height } = props;
    const { shouldPlay, muted } = this.state;
    const {
      layout,
      item,
      currentlyPlayingIndex,
      index,
      listData = '',
      previousScreen = '',
    } = this.props;
    const itemData = item[0];
    if (
      layout !== LAYOUT.HORIZONTAL_PLAYLIST ||
      !shouldPlay ||
      (currentlyPlayingIndex !== index &&
        !Utility.isBlank(currentlyPlayingIndex))
    ) {
      // console.tron
      //   .log(`returning because one of the conditions failed layout:${layout} shouldPlay : ${shouldPlay}
      // currentlyPlaying index : ${currentlyPlayingIndex} and index : ${index}`);
      return null;
    }

    const { metadata: { contentPlatform, videoUrl, id: videoId } = {} } =
      itemData;

    // const {
    //   onError,
    //   onLoadStart,
    //   onLoad,
    //   onBuffer,
    //   handleProgress,
    //   onEnd,
    // } = this;

    const VideoContainerComponent =
      HorizontalPlaylistCard.VideoComponents[contentPlatform];
    const control =
      contentPlatform === ContentPlatforms.foxy
        ? false
        : Platform.select({ ios: 0, android: 2, web: false });
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <VideoContainerComponent
          ref={this.saveVideoPlayerRef}
          style={{
            width: '100%',
            height,
            // zIndex: 10,
            // position: 'absolute',
          }}
          fullscreen={false}
          muted={muted}
          // onError={onError}
          controls={control}
          source={{ uri: videoUrl }}
          paused={false}
          resizeMode='cover'
          // onBuffer={onBuffer}
          // onLoad={onLoad}
          // onLoadStart={onLoadStart}
          // onProgress={handleProgress}
          // onEnd={onEnd}
          apiKey='dfYRbjBFNJD334'
          videoId={videoId}
          // onReady={onLoad}
          play
          loop
          repeat
        />
      </ErrorBoundaryComponent>
    );
  };

  render() {
    const style = playlistStyle;
    const { display: disp, item, previousScreen } = this.props;
    if (Utility.isBlank(item) || item.length === 0) {
      return null;
    }
    const display = camelCase(disp);

    const media = item[0];
    const {
      metadata: { orientation = 'horizontal', title, viewCount },
      artist: { verified },
    } = media;
    let products = [];
    const productsListArray = media.objects;
    if (
      !Utility.isBlank(productsListArray) &&
      productsListArray.length > 0 &&
      productsListArray[0].content === 'product'
    ) {
      products = productsListArray[0].objects;
    }
    const artistName = (media.artist && media.artist.name) || '';
    // TODO: Use a default image for an artist
    const artistImage =
      Utility.getMinifiedImage(
        media.artist && media.artist.images && media.artist.images.thumbnail,
        120,
        120,
      ) || 'https://apan.net/people/avatar.png';

    return (
      <>
        <ScaleAnimate {...this.props} onPress={this.navigateToMedia}>
          {/* <ViewportAwareView onViewportEnter={this.onViewportEnter} onViewportLeave={this.onViewportLeave}> */}
          <Card
            style={[
              style.container,
              {
                height:
                  products.length > 0
                    ? MEDIA.CARD[display].defaultSize.viewheight
                    : MEDIA.CARD[display].defaultSize.imageheight,
                width: MEDIA.CARD[display].defaultSize.width,
              },
            ]}
          >
            <this.MediaImage
              {...this.props}
              imageUrl={media.image_url}
              orientation={orientation}
            />
            <this.MediaDescription
              orientation={orientation}
              description={title || ''}
              views={viewCount || ''}
              artistName={artistName}
              artistImage={artistImage}
              verified={verified}
              totalProducts={products.length}
              totalVideos={item.length}
            />
            <this.renderMedia
              height={MEDIA.CARD[display].defaultSize.imageheight}
            />
            <this.productsUsed products={products} />
          </Card>
          {/* </ViewportAwareView> */}
        </ScaleAnimate>
      </>
    );
  }
}

// PropTypes
HorizontalPlaylistCard.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string,
  size: PropTypes.string,
  item: PropTypes.array,
  itemData: PropTypes.shape({
    image_url: PropTypes.string,
    id: PropTypes.string,
  }),
  theme: PropTypes.string,
  listId: PropTypes.string,
  index: PropTypes.number,
  currentlyPlayingIndex: PropTypes.number,
  updateCurrentlyPlayingIndex: PropTypes.func,
};

HorizontalPlaylistCard.defaultProps = {
  index: -1,
  currentlyPlayingIndex: null,
  layout: LAYOUT.HORIZONTAL_PLAYLIST,
  updateCurrentlyPlayingIndex: () => {},
};

export default withNavigation(HorizontalPlaylistCard);
