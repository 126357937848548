import { View, Image, StyleSheet, Text } from 'react-native';
import React from 'react';
import Buttons from './Buttons';
import images from '../../theme/Images';

export default function MiddleLayer() {
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          source={{ uri: images.forYouPic2 }}
          style={styles.image}
        />
        <View style={styles.smallCircle} />
      </View>
      <View style={styles.reviews}>
        <Text style={styles.heading}> Honest Reviews </Text>
        <Text style={styles.subheading}> Real People. Real Reviews.</Text>
        <Text style={styles.text}>Watch Honest Reviews on products from people like you. Earn rewards every time you review a product</Text>
        <Buttons />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 'calc(0.5863956216 * 100vw)',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'start',
    marginBottom: 20,
  },
  imageContainer: {
    height: '56vw',
    width: '57vw',
    backgroundImage: 'linear-gradient(180deg, #ffb983 0%, rgba(235, 103, 104, 0.76) 100%)',
    borderRadius: '50%',
    position: 'relative',
    right: '13%',
    bottom: '10%',
    display: 'inline-block',
    zIndex: 1,
  },
  image: {
    width: '25vw',
    height: '49vw',
    left: '50%',
    position: 'relative',
    bottom: '5%',
  },
  smallCircle: {
    transform: 'rotate(-58.9deg)',
    backgroundImage: 'linear-gradient(184.48deg, #fcd0a3 3.63%, #e0effd 80.42%)',
    borderRadius: '50%',
    width: '17vw',
    height: '17vw',
    left: '23%',
    position: 'absolute',
    bottom: '-15%',
  },
  reviews: {
    width: '44%',
    position: 'relative',
    right: '8%',
    top: '6%',
    height: 'fit-content',
  },
  heading: {
    color: '#3c6082',
    fontSize: '4vw',
    fontWeight: 900,
    fontFamily: 'Roboto-Bold',
    lineHeight: 86.4,
  },
  subheading: {
    color: '#e4454c',
    fontSize: '2.7vw',
    fontFamily: 'Roboto-Regular',
    lineHeight: 58.3,
  },
  text: {
    marginTop: '4%',
    fontSize: '1.5vw',
    fontFamily: 'Roboto-Regular',
    lineHeight: 32.4,
  },
});
