import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';

const RatingCard = ({ itemData, index }) => {
  const { body = '', user_image_url } = itemData;
  const bgColor = Utility.getTestimonialCardBgColor(index);
  return (
    <View style={styles.wrapper}>
      <View
        style={[styles.testimonialContainer, { backgroundColor: `${bgColor}` }]}
      >
        <FastImageView
          style={styles.commentsQuote}
          source={images.edge.testimonyQuotes}
        />
        <Text
          style={styles.testimonyText}
          numberOfLines={4}
          ellipsizeMode='tail'
        >
          {body}
        </Text>
        <FastImageView style={styles.profilePic} source={user_image_url} />
      </View>
    </View>
  );
};

export default RatingCard;

const styles = StyleSheet.create({
  wrapper: {
    height: 220,
    width: Utility.getScreenWidth(),
    justifyContent: 'center',
    padding: 13,
    paddingTop: 4,
  },

  testimonialContainer: {
    flex: 1,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
  },

  testimonyText: {
    marginTop: 12,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },

  commentsQuote: {
    height: 40,
    width: 52,
    resizeMode: 'contain',
  },

  profilePic: {
    height: 44,
    width: 44,
    borderRadius: 40,
    marginTop: 12,
    borderWidth: 1,
    resizeMode: 'cover',
    borderColor: colors.foxyBlack,
  },
});
