import { StyleSheet, Dimensions, Platform } from 'react-native';

import colors, { Theme } from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';
import {
  ADD_PRODUCT,
  ADD_PRODUCT_BOTTOM_SECTION,
} from '../../../config/LayoutConstants/AddProductsConfig';

const { topSectionInset, bottomSectionInset } = ADD_PRODUCT.height;
const { leftSection, rightSection } = ADD_PRODUCT_BOTTOM_SECTION.width;
const { buttonsHeight } = ADD_PRODUCT_BOTTOM_SECTION.height;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },

  cameraView: {
    flex: 1,
    justifyContent: 'space-between',
  },

  topSectionContainer: {
    justifyContent: 'flex-end',
  },

  containerTitle: {
    color: Theme.addProducts.topSection.textColor,
    fontSize: size.h2,
  },

  topSectionInset: {
    height: topSectionInset,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },

  middleSection: {
    width: Utility.screenWidth,
    justifyContent: 'space-between',
  },

  scannedProductListContainer: {
    position: 'absolute',
    left: (Utility.screenWidth - 300) / 2,
    width: 300,
    bottom: 50,
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },

  scannedProductsListBox: {
    width: 300,
    height: 224,
    backgroundColor: 'white',
    borderRadius: 8,
    overflow: 'hidden',
  },

  searchProductButton: {
    height: 46,
    width: 300,
    backgroundColor: colors.background,
    alignItems: 'center',
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  searchProductButtonText: {
    color: 'rgb(153, 156, 173)',
    fontSize: size.h3,
    width: 300 - (32 + 18 + 10),
  },

  recognizedProducts: {
    height: 224 - 46,
  },

  productShutterButtonContainer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    alignItems: 'center',
  },

  bottomSectionContainer: {
    justifyContent: 'flex-start',
    backgroundColor: colors.background,
  },

  bottomSectionInset: {
    height: bottomSectionInset,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },

  bottomSectionLeftPanel: {
    height: 40,
    width: Utility.screenWidth - (84 + 40),
  },

  showAddedProductsContainer: {
    height: buttonsHeight,
    maxWidth: leftSection,
    flexDirection: 'row',
  },

  productsSet: {
    height: buttonsHeight,
    minWidth: 50,
    maxWidth: leftSection - 50,
  },

  doneButton: {
    width: rightSection,
    height: buttonsHeight,
    backgroundColor: colors.white,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },

  doneButtonText: {
    color: colors.black,
    fontSize: 14,
  },

  topScannedProductInfo: {
    width: Utility.screenWidth - 24,
    height: 77,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  searchBoxContainer: {
    height: 60,
    width: Utility.screenWidth,
    justifyContent: 'center',
    alignItems: 'center',
  },

  searchText: {
    width: 240,
    marginLeft: 10,
    color: 'rgb(153, 156, 173)',
  },

  searchBox: {
    height: 40,
    width: 328,
    backgroundColor: colors.background,
    borderRadius: 20,
    flexDirection: 'row',
    paddingHorizontal: 18,
    alignItems: 'center',
  },

  activityIndicatorContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  searchSuggestionsContainer: {
    position: 'absolute',
    left: (Utility.screenWidth - 290) / 2,
    right: (Utility.screenWidth - 290) / 2,
    minHeight: 40,
    maxHeight: 120,
    backgroundColor: colors.background,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  topProductResult: {
    height: 77,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
  },

  topListProductImage: {
    height: 61,
    width: 56,
    backgroundColor: 'grey',
    borderRadius: 4,
  },

  topListProductInfo: {
    width: Utility.screenWidth - (24 + 61 + 40 + 8),
    height: 68,
    justifyContent: 'space-between',
  },

  topProductBrandName: {
    color: 'rgb(156, 159, 179)',
    fontSize: 10,
  },

  topProductName: {
    color: 'black',
    fontSize: 12,
  },

  topProductShadeInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  topProductShadeName: {
    color: 'black',
    marginLeft: 4,
    fontSize: 12,
  },

  activityIndicatorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;

// import { StyleSheet, Dimensions, Platform } from 'react-native';

// import { ADD_PRODUCT } from '../../../config/LayoutConstants/AddProductsConfig';

// const {
//   screenHeight,
//   topInset,
//   bottomInset,
//   statusBar,
//   bottomNavBar,
//   ContainerHeight,
// } = ADD_PRODUCT.height;
// const { screenWidth } = ADD_PRODUCT.width;

// const styles = StyleSheet.create({

//   backgroundLayout: {
//     flex: 1,
//     backgroundColor: 'black',
//     justifyContent: 'center',
//   },

//   container: {
//     height: ContainerHeight,
//   },

//   searchContainer: {
//     width: 325,
//     height: 45,
//     borderRadius: 4,
//     borderWidth: 2,
//     borderColor: 'white',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//   },

//   searchContainerLeftSection: {
//     width: 40,
//     flexDirection: 'row',
//     alignItems: 'center',
//   },

//   sectionSeparator: {
//     height: 32,
//     width: 2,
//     backgroundColor: 'white',
//   },

//   searchText: {
//     fontSize: 16,
//     color: 'white',
//   },

//   searchContainerRightSection: {
//     width: 40,
//   },

//   mediaItemThumbnail: {
//     height: 40,
//     width: 40,
//     marginRight: 10,
//     borderRadius: 4,
//     borderWidth: 2,
//   },
// });

// export default styles;
