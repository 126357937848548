import React from 'react';
import { View, StyleSheet } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';

export default function RowAccordion(props) {
  const {
    title = () => {},
    expandedStatus = '',
    item = {},
    expandableComponent,
    updateExpandableComponent,
  } = props || {};
  const { title: itemTitle } = item || {};
  const toggleExpand = () => {
    if (expandedStatus.includes(itemTitle)) return;
    updateExpandableComponent(itemTitle);
  };
  return (
    <>
      <TouchableWithoutFeedback
        ref={RowAccordion}
        onPress={toggleExpand}
      >
        <View style={styles.title}>
          {typeof title === 'function' && title()}
        </View>
      </TouchableWithoutFeedback>
      {
        expandedStatus.includes(itemTitle)
        && expandedStatus.includes('expanded')
        && (
          <View style={styles.expandable}>
            {expandableComponent()}
          </View>
        )
      }
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    width: '35%',
    zIndex: 1,
  },
  expandable: {
    flex: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'white',
    paddingVertical: 24,
    paddingHorizontal: 20,
    width: '65%',
    minHeight: '99%',
    marginTop: 2,
    zIndex: 5,
  },
});
