import Utility from './Utility';

export default class VariantUtility {
  static checkIfMultiVariant(multiVariantAttributes) {
    return (
      Utility.isPresent(multiVariantAttributes) &&
      Utility.isPresent(
        multiVariantAttributes[0]?.multi_level_variant_attributes,
      )
    );
  }

  static getParentVariantForChildVariantId(variantId, multiVariantAttributes) {
    return (
      multiVariantAttributes.filter(
        VariantUtility.filterParentAttributes(variantId),
      )?.[0] || {}
    );
  }

  static filterParentAttributes = (variantId) => (attribute) => {
    const { multi_level_variant_attributes: multiVariantAttributes = [] } =
      attribute || {};
    return Utility.isPresent(multiVariantAttributes)
      ? Utility.isPresent(
          multiVariantAttributes.filter(
            VariantUtility.filterParentAttributes(variantId),
          )?.[0],
        ) || false
      : VariantUtility.checkIfAttributeIsPresent(variantId)(attribute) || false;
  };

  static checkIfAttributeIsPresent = (variantId) => (attribute) => {
    return attribute.variant?.id === variantId;
  };
}
