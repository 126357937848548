import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, Linking } from 'react-native';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { Svg, Circle, Mask, Rect, Defs } from 'react-native-svg';
import FastImageView from '../../components/FastImageView';
import ScaleAnimate from '../../components/shared/ScaleAnimate';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility, { openUrlInBrowser } from '../../utils/Utility';
import { connect } from 'react-redux';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import WithNavigation from '../../utils/WithNavigation';

class FoxyEdgeLoyaltyPlanCard extends PureComponent {
  tiltedCardContainer = () => {
    return (
      <View style={styles.cardContainerTilted}>
        <this.maskedCard fillColor={colors.edgeOfferCard.background} />
      </View>
    );
  };

  maskedCard = ({ fillColor }) => {
    return (
      <Svg height={'100%'} width={'100%'}>
        <Defs>
          <Mask id={`clip`}>
            <Rect fill={colors.white} width='100%' height='100%' />
            <Circle
              r={'12'}
              cx={`${Utility.getScreenWidth() - 36 - 54}`}
              cy={'0'}
            />
            <Circle
              r={'12'}
              cx={`${Utility.getScreenWidth() - 36 - 54}`}
              cy={'120'}
            />
          </Mask>
        </Defs>
        <Rect width='100%' height='100%' fill={fillColor} mask={`url(#clip)`} />
      </Svg>
    );
  };

  verticalRightContainer = () => {
    return (
      <View style={styles.verticalRightContainer}>
        <Svg height={'100%'} width={'100%'}>
          <Defs>
            <Mask id={`clip`}>
              <Rect fill={colors.white} width='100%' height='100%' />
              <Circle r={'12'} cx={'0'} cy={'0'} />
              <Circle r={'12'} cx={'0'} cy={'120'} />
            </Mask>
          </Defs>
          <Rect
            width='100%'
            height='100%'
            fill={colors.edgeOfferCard.offer_blue}
            mask={`url(#clip)`}
          />
        </Svg>
        <this.benefitsText />
      </View>
    );
  };

  benefitsText = () => {
    const { item = {}, item: { options = {} } = {} } = this.props;
    if (Utility.isBlank(item || options)) return null;
    const { benefitText = '' } = options;
    return (
      <Text
        style={styles.verticalRightText}
        numberOfLines={3}
        ellipsizeMode={'tail'}
      >
        {benefitText}
      </Text>
    );
  };

  offerCardContentPlaceHolder = () => {
    return (
      <View style={styles.cardContainer}>
        <this.maskedCard fillColor={colors.edgeOfferCard.offer_blue_light} />
        <this.verticalRightContainer />
        <View style={styles.offerCardContentContainer}>
          <View style={styles.horizontalLeftCard}>
            <View style={styles.leftCardTitleContainer}>
              <ShimmerPlaceHolder
                autoRun
                style={styles.titlePlaceholder}
                colorShimmer={[
                  colors.edgeOfferCard.offer_blue_light,
                  '#C8DEFD',
                  colors.edgeOfferCard.offer_blue_light,
                ]}
              />
              <ShimmerPlaceHolder
                autoRun
                style={styles.subTitlePlaceholder}
                colorShimmer={[
                  colors.edgeOfferCard.offer_blue_light,
                  '#C8DEFD',
                  colors.edgeOfferCard.offer_blue_light,
                ]}
              />
            </View>
            <ShimmerPlaceHolder
              autoRun
              style={styles.imagePlaceholder}
              colorShimmer={[
                colors.edgeOfferCard.offer_blue_light,
                '#C8DEFD',
                colors.edgeOfferCard.offer_blue_light,
              ]}
            />
            <ShimmerPlaceHolder
              autoRun
              style={styles.buttonPlaceholder}
              colorShimmer={[
                colors.edgeOfferCard.offer_blue_light,
                '#C8DEFD',
                colors.edgeOfferCard.offer_blue_light,
              ]}
            />
          </View>
        </View>
      </View>
    );
  };

  offerCardContainer = () => {
    const {
      item: {
        image_url = '',
        heading = '',
        subHeading = '',
        primaryButtonText = '',
        options: { highlitedText = '' },
      } = {},
      item = {},
    } = this.props;
    if (Utility.isBlank(item)) return <this.offerCardContentPlaceHolder />;
    return (
      <View style={styles.cardContainer}>
        <this.maskedCard fillColor={colors.edgeOfferCard.offer_blue_light} />
        <this.verticalRightContainer />
        <View style={styles.offerCardContentContainer}>
          <View style={styles.horizontalLeftCard}>
            <View style={styles.leftCardTitleContainer}>
              <Text style={styles.firstTitle}>{heading}</Text>
              <Text style={styles.cardTitle}>{highlitedText}</Text>
            </View>
            <View style={styles.cardBodyContainer}>
              <Text style={styles.bodyText}>{subHeading}</Text>
              <View style={styles.horizontalLine} />
            </View>
            <FastImageView
              style={styles.image}
              source={Utility.getMinifiedImage(image_url, 200, 200)}
            />
            <View style={styles.buttonContainer}>
              <Text style={styles.buttonText}>{primaryButtonText}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  onPress = () => {
    const { navigation, previousScreen } = this.props;
    navigation.push('BecomeMemberModal', {
      previousScreen: previousScreen || 'order_detail',
      renewMemberModal: true,
    });
  };

  render() {
    return (
      <ScaleAnimate {...this.props} onPress={this.onPress}>
        <View style={styles.container}>
          <this.tiltedCardContainer />
          <this.offerCardContainer />
        </View>
      </ScaleAnimate>
    );
  }
}

const mapStateToProps = (state) => ({
  membership_cohort: state.todayDeals.membership_cohort,
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
  date_of_birth: state.UserAccountInfo.profile.date_of_birth,
});

export default WithNavigation(
  connect(mapStateToProps, null)(FoxyEdgeLoyaltyPlanCard),
);

const styles = StyleSheet.create({
  container: {
    marginBottom: 12,
    alignSelf: 'center',
    justifyContent: 'center',
    height: 150,
    width: '100%',
    backgroundColor: colors.white,
  },
  horizontalLeftCard: {
    position: 'absolute',
    width: Utility.getScreenWidth() - 24 - 66,
    height: 120,
    overflow: 'hidden',
  },
  verticalTextContainer: {
    position: 'absolute',
    width: 120,
    height: 54,
    justifyContent: 'center',
    alignItems: 'center',
    right: -33,
    backgroundColor: '#96B5F8',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    transform: [{ rotate: '-90deg' }],
    overflow: 'hidden',
  },
  verticalContainerCornerCircle: {
    height: 24,
    width: 24,
    borderRadius: 12,
    position: 'absolute',
    top: -12,
    right: -8,
    backgroundColor: '#5B6986',
  },
  leftCardTitleContainer: {
    marginLeft: 20,
    marginTop: 12,
  },
  firstTitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4_5,
    height: 16,
  },
  cardTitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h0_24,
  },
  cardBodyContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    marginTop: 4,
  },
  horizontalLine: {
    height: 2,
    width: 33,
    backgroundColor: colors.foxyBlack,
    marginTop: 8,
    marginLeft: 6,
  },
  bodyText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    fontWeight: '400',
    color: '#8EB6FE',
  },
  image: {
    height: 40,
    width: 40,
    position: 'absolute',
    top: 12,
    right: 10,
  },
  imagePlaceholder: {
    height: 40,
    width: 40,
    position: 'absolute',
    top: 12,
    right: 10,
    borderRadius: 20,
  },
  verticalRightText: {
    color: colors.white,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4_5,
    alignSelf: 'center',
    position: 'absolute',
    zIndex: 2,
    textAlign: 'center',
    transform: [{ rotate: '-90deg' }],
    width: '100%',
  },
  buttonContainer: {
    position: 'absolute',
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#8EB6FE',
    height: 20,
    bottom: 12,
    marginLeft: 20,
  },
  buttonText: {
    paddingHorizontal: 16,
    paddingVertical: 2,
    height: 18,
    alignSelf: 'center',
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontWeight: '500',
    fontSize: size.h5,
  },
  cardContainer: {
    width: Utility.getScreenWidth() - 24,
    height: 120,
    alignSelf: 'center',
    backgroundColor: colors.transparent,
    marginVertical: 12,
    borderRadius: 20,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
  },
  cardContainerTilted: {
    width: Utility.getScreenWidth() - 24,
    height: 120,
    alignSelf: 'center',
    backgroundColor: colors.transparent,
    marginVertical: 12,
    borderRadius: 20,
    overflow: 'hidden',
    transform: [{ rotate: '-4.0deg' }],
    position: 'absolute',
    top: 0,
  },
  verticalRightContainer: {
    height: 120,
    width: 66,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
  },
  offerCardContentContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    flex: 1,
    backgroundColor: colors.transparent,
  },
  titlePlaceholder: {
    height: 16,
    width: 108,
    borderRadius: 4,
  },
  subTitlePlaceholder: {
    height: 24,
    width: 142,
    marginTop: 12,
    borderRadius: 4,
  },
  buttonPlaceholder: {
    height: 20,
    width: 85,
    borderRadius: 16,
    marginTop: 20,
    left: 20,
  },
});
