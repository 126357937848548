import { APP_ASSET_URL } from '../config/Constants';

const gifs = {
  add_to_cart: {
    uri: `${APP_ASSET_URL}/90/add_to_cart.gif`,
  },
  buy_now: { uri: `${APP_ASSET_URL}/91/buy_now.gif` },
  click_here_red: {
    uri: `${APP_ASSET_URL}/92/click_here_red.gif`,
  },
  click_here_white: {
    uri: `${APP_ASSET_URL}/93/click_here_white.gif`,
  },
  shop_now_cap: {
    uri: `${APP_ASSET_URL}/94/shop_now_cap.gif`,
  },
  shop_now_curly: {
    uri: `${APP_ASSET_URL}/95/shop_now_curly.gif`,
  },
  tap_here_green: {
    uri: `${APP_ASSET_URL}/96/tap_here_green.gif`,
  },
  tap_here_red: {
    uri: `${APP_ASSET_URL}/97/tap_here_red.gif`,
  },
};

export default gifs;
