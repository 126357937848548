import React from 'react';
import MoreItemsList from '../feed/MoreItemsList';
import AppInstallPrompts from '../../components/shared/AppInstallPrompts';
import { FOXY_URLS } from '../../config/Constants';

export default function ListPage() {
  return (
    <>
      <MoreItemsList />
      <AppInstallPrompts link={FOXY_URLS.appInstallPage.list} />
    </>
  );
}
