/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
  Image,
  StyleSheet,
  Text,
  View,
  BackHandler,
  Linking,
  ScrollView,
} from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import Video from '../../libraries/ReactNativeVideo';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImagePicker from 'react-native-image-crop-picker';
import Permissions from '../../libraries/ReactNativePermissions';
import uuidv1 from 'uuid/v1';
import uuidv5 from 'uuid/v5';
import withNavigation from '../../utils/WithNavigation';
import { StaticNavigationHeader } from '../../components/shared';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import {
  publishPost,
  addPostToLocalState,
} from '../../actions/UploadPostActions';
import {
  updateLastVideoUpload,
  setVideoUploadProgress,
  setVideoUploadStatus,
} from '../../actions/ActionTypes';

import UploadStatusTray from '../feed/UploadStatusTray';
import RoundedButton from '../../components/layout/buttons/RoundedButton';
import { getScreenWidth } from '../../utils/LayoutUtility';

class UploadUnboxingVideo extends Component {
  constructor(props) {
    super(props);
    this.player = null;
    this.state = {
      media: [],
    };
    this.mediaContainsOneVideo = false;
    this.addOrderActionCard = props.route.params?.addOrderActionCard;

    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  bulletPoint = ({ title }) => {
    return (
      <View style={styles.bulletPointContainer}>
        <Image source={images.greenCircularTick} style={styles.bullet} />
        <Text style={styles.bulletText}>{title}</Text>
      </View>
    );
  };

  assignRef = (ref) => {
    this.player = ref;
  };

  seekTo0 = () => {
    this.player.seek(0);
  };

  onUploadMediaPress = () => {
    const { readExternalStoragePermission } = this.state;

    if (readExternalStoragePermission) {
      ImagePicker.openPicker({
        mediaType: 'video',
        multiple: true,
      }).then((images) => {
        const imageArray = this.createMediaArray(images.slice(0, 7));
        this.setState({ media: imageArray });
      });
    } else {
      this.RequestExternalStoragePermission();
    }
  };

  openAppSettings = () => {
    Linking.openSettings();
  };

  openSettings = () => {
    const { navigation } = this.props;
    Alert.alert(
      'Permission Required',
      'Change Access permission inside Settings to allow photo access',
      [
        {
          text: 'Open Settings',
          onPress: this.openAppSettings,
        },
        {
          text: 'Cancel',
          onPress: () => navigation.goBack(),
          style: 'cancel',
        },
      ],
    );
  };

  RequestExternalStoragePermission = () => {
    const mediaLibrary = Utility.isAndroid() ? 'storage' : 'photo';
    Permissions.request(mediaLibrary).then((response) => {
      const isPermissionAllowed = response === 'authorized';
      const metaData = { type: 'media_library' };
      if (response === 'authorized') {
        this.setState({ readExternalStoragePermission: true }, () => {
          this.onUploadMediaPress();
        });
        return;
      }
      if (Utility.isIOS() && response === 'denied') {
        this.openSettings();
      }
      if (response === 'restricted') {
        this.openSettings();
      }
      Utility.logPermissionEvent(isPermissionAllowed, metaData);
    });
  };

  createMediaArray = (mediaArray) => {
    const filteredArray = [];
    mediaArray.forEach((media) => {
      if (filteredArray.length > 7) {
        this.showToast('Only 7 media objects can be added in a review');
        return filteredArray;
      }
      if (media.mime === 'video/mp4') {
        if (!this.mediaContainsOneVideo) {
          this.mediaContainsOneVideo = true;
          filteredArray.push(media);
        } else {
          //   this.showToast('Only one video can be added per review');
        }
      } else {
        filteredArray.push(media);
      }
    });
    return filteredArray;
  };

  trayGoBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  uploadVideo = () => {
    const { media = [] } = this.state;

    if (Utility.isPresent(this.state.media)) {
      return (
        <>
          <Video
            style={styles.video}
            source={{ uri: media[0].path }}
            paused
            resizeMode="cover"
            ref={this.assignRef}
            onLoad={this.seekTo0}
          />

          <UploadStatusTray goBack={this.trayGoBack} />
        </>
      );
    }
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={styles.uploadVideoContainer}
        onPress={() => this.onUploadMediaPress('video')}
      >
        <Image source={images.upload_ic} style={styles.uploadImage} />
        <Text style={styles.uploadText}>Tap to upload your video</Text>
      </DebouncedTouchableOpacity>
    );
  };

  postVideo = (video) => {
    const postId = uuidv1();
    const videoTitle = this.reviewTitle;
    const postData = {
      id: postId,
      localId: postId,
      category: 'unboxing',
      postCacheDirectoryPath: '',
      uploadType: 'FoxyVideo',
      items: [
        {
          mimeType: 'video/mp4',
          uuid: uuidv5(video.path, uuidv5.URL),
          uri: video.path,
          mediaUriAbsolutePath: video.path,
          mediaUriExternalDirectoryPath: '',
          mediaBase64Uri: '',
          thumbnail: '',
          height: Utility.getScreenHeight(),
          width: Utility.getScreenWidth(),
          videoLength: 0,
          mediaType: 'video',
          caption: videoTitle,
          recentProductSearchID: '',
          products: [],
          videoFrames: [],
          productsTag: [],
        },
      ],
      postThumbnail: '',
      postCategory: 'video',
      hashTags: [],
      mentions: [],
      editingMode: false,
    };
    const {
      publishPost,
      addPostToLocalState = () => {},
      isVideoUploading,
      setVideoUploadProgress,
    } = this.props;

    Utility.setVideoUploadProgress = setVideoUploadProgress;
    addPostToLocalState(postData, () => {
      this.addOrderActionCard();
      if (!isVideoUploading) {
        publishPost(postId, postData);
      }
    });
  };

  onButtonPress = () => {
    const { media = [] } = this.state;
    this.postVideo(media[0]);
  };

  render() {
    const { media = [] } = this.state;
    return (
      <>
        <StaticNavigationHeader title="Upload Unboxing Video" />
        <ScrollView style={styles.mainContainer}>
          <Text style={styles.communityText}>
            Help the community by uploading a short video of your experience
            with unboxing your order from {Config.APP_NAME}. Be honest in what
            you feel about the products. Follow these pointers and you are fine
          </Text>
          <Text style={styles.videoGuidelinesHeading}>Video Guidelines</Text>
          <this.bulletPoint title="Aspect ratio is vertical" />
          <this.bulletPoint
            title={`A clear video of you unboxing your box from ${Config.APP_NAME}`}
          />
          <this.bulletPoint title="Lots of love from your end 💕" />
          <this.bulletPoint title="Read our Community Guidelines" />

          <this.uploadVideo />
          <RoundedButton
            buttonText={'Submit'}
            buttonTextColor={colors.white}
            buttonColor={colors.black}
            buttonAction={this.onButtonPress}
            style={styles.buttonContainer}
            disabled={!media[0]}
            buttonTextStyle={styles.buttontext}
          />
        </ScrollView>
      </>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: '#fff',
    flex: 1,
    paddingHorizontal: 12,
    paddingBottom: 100,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  communityText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 12,
  },
  videoGuidelinesHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginTop: 14,
  },
  buttonContainer: {
    width: 101,
    height: 32,
    alignSelf: 'center',
    marginTop: 16,
  },
  buttontext: { fontSize: 12, fontFamily: 'Roboto-Medium' },
  bulletPointContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  bullet: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    marginRight: 8,
  },
  bulletText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  video: {
    height: 350,
    width: 200,
    borderColor: '#979BAA',
    borderWidth: 1,
    borderRadius: 16,
    borderStyle: 'dotted',
    alignSelf: 'center',
    marginTop: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadVideoContainer: {
    height: 350,
    width: 200,
    borderColor: '#979BAA',
    borderWidth: 1,
    borderRadius: 16,
    borderStyle: 'dotted',
    alignSelf: 'center',
    marginTop: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadImage: { height: 48, width: 48, resizeMode: 'contain' },
  uploadText: { fontSize: 14, fontFamily: 'Roboto-Regular', color: '#979BAA' },
});

const mapStateToProps = (store) => {
  return {
    ratings: store.UserAccountInfo.productRatings,
    isVideoUploading: store.UserAccountInfo.isUploading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      publishPost,
      setVideoUploadProgress,
      updateLastVideoUpload,
      addPostToLocalState,
      setVideoUploadStatus,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(UploadUnboxingVideo),
);
