// Dependencies
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import RateProduct from './RateProduct';
import ProductDetailDescription from './ProductDetailDescription';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import ProductRating from '../productRatings/ProductRating';
import AppConfig from '../../config/AppConfig';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import List from '../../containers/List/List';
import { isDesktop, isPresent } from '../../utils/BooleanUtility';
import RichContent from './RichContent/RichContent';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';

const ProductOutOfStock = (props) => {
  const {
    itemData,
    selectedVariant,
    onReview,
    onPressMore = () => {},
    setDescriptionBoxRef,
    navigation,
    likedRatings,
    toggleVariantModal,
    onRatingLayout,
    updateReadMoreLayout,
    scrollToClosedReadMoreSection,
    displaySp,
    productPage = {},
    sendAlertForPrice,
  } = props;

  const {
    product_page_type,
    variant_attributes = [],
    rich_content = {},
  } = itemData;

  const [upperList, setupperList] = useState(null);
  const [lowerList, setLowerList] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setupperList({
        display: 'vertical',
        content: 'mixed',
        objects: itemData.consolidated_list_upper,
      });
      setLowerList({
        display: 'vertical',
        content: 'mixed',
        objects: itemData.consolidated_list_lower,
      });
    }, 0);
  }, [itemData.consolidated_list_upper, itemData.consolidated_list_lower]);

  return (
    <>
      {Utility.isPresent(upperList?.objects) && (
        <View>
          <List
            itemData={upperList}
            id={itemData.id}
            previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
            useInViewPort={isDesktop()}
            verticalComponentWidth={
              isDesktop() ? PRODUCT_RIGHT_CONTAINER_WIDTH : undefined
            }
          />
        </View>
      )}
      {product_page_type === 'foxy' && (
        <ProductDetailDescription
          description={itemData.description || ''}
          howTo={itemData.how_to || ''}
          ingredients={itemData.ingredients || ''}
          name={itemData.name}
          expiryDate={itemData.expiry_date}
          bestBefore={itemData.best_before}
          itemData={itemData}
          selectedVariant={selectedVariant}
          onPressMore={onPressMore}
          setDescriptionBoxRef={setDescriptionBoxRef}
          sku={
            Utility.isPresent(selectedVariant)
              ? selectedVariant.sku
              : itemData.sku
          }
          displaySp={displaySp}
          productPage={productPage}
          sendAlertForPrice={sendAlertForPrice}
          updateReadMoreLayout={updateReadMoreLayout}
          scrollToClosedReadMoreSection={scrollToClosedReadMoreSection}
        />
      )}

      {!AppConfig.getBooleanValue(Config.HIDE_RATING) && (
        <View onLayout={onRatingLayout}>
          <ProductRating
            rating={itemData.rating}
            reviewData={itemData.reviews}
            reviewsCount={itemData.reviews_count}
            ratingsCount={itemData.ratings_count}
            navigation={navigation}
            productId={itemData.id}
            productSlug={itemData.slug}
            likedRatings={likedRatings}
            variants={variant_attributes}
            toggleVariantModal={toggleVariantModal}
            itemData={itemData}
            productPage={productPage}
          />

          <View style={styles.rateProductContainer}>
            <RateProduct
              reviewData={itemData.reviews}
              onReview={onReview}
              slug={itemData.slug}
              id={itemData.sku_id}
              itemData={itemData}
              selectedVariant={selectedVariant}
            />
          </View>
        </View>
      )}
      {!isDesktop() && isPresent(rich_content?.post_reviews) && (
        <View style={styles.separator} />
      )}
      <RichContent itemData={rich_content?.post_reviews} />
      {!isDesktop() && Utility.isPresent(lowerList?.objects) && (
        <View>
          <List
            itemData={lowerList}
            id={itemData.id}
            previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
          />
        </View>
      )}
      <RichContent itemData={rich_content?.page_end} />
    </>
  );
};

const styles = StyleSheet.create({
  emptyView: {
    width: Utility.getScreenWidth(),
    height: 70 + Utility.bottomInset,
  },
  upperList: {
    backgroundColor: colors.background,
  },
  lowerList: {
    backgroundColor: colors.background,
    paddingBottom: 20,
    marginTop: 12,
  },
  rateProductContainer: { backgroundColor: colors.white },
});

export default ProductOutOfStock;
