import { isDesktop } from '../../utils/BooleanUtility';
import Utility from '../../utils/Utility';

export const MediaConstant = {
  descriptionHeight: 52,
  smallRailWidth: 150,
  smallRailHeight: 132,
  largeRailWidth: 150,
  largerRailSize: 209,
  defaultHorizontalSpacing: 16,
  reviewGridHorizontalSpacing: 24,
  cardDimentionRatio: 0.5,
  playlistWidth: Utility.getScreenWidth() - 2 * Utility.padding,
  playlistCardAspectRatio: 5 / 7,
  NUM_OF_CARDS: {
    LIST: isDesktop() ? 2 : 1,
    GRID: isDesktop() ? 4 : 2,
    REVIEWGRID: isDesktop() ? 6 : 3,
  },
  SPACING: {
    LIST: 0,
    GRID: 16,
    REVIEWGRID: 24,
  },
};

export const MEDIA = {
  MAX_LINES: {
    TITLE: {
      rail: 1,
      list: 2,
      grid: 1,
      gridRail: 1,
      card: 1,
    },
  },
  MAX_WIDTH: {
    ARTIST_NAME: {
      rail: MediaConstant.smallRailWidth - 84,
      list: 71,
      grid:
        Utility.getDynamicWidthForGrid(
          MediaConstant.NUM_OF_CARDS.GRID,
          MediaConstant.defaultHorizontalSpacing,
        ) - 76,
      gridRail: 71,
      card: Utility.getScreenWidth() / 2,
      reviewGrid:
        Utility.getDynamicWidthForGrid(
          MediaConstant.NUM_OF_CARDS.REVIEWGRID,
          MediaConstant.reviewGridHorizontalSpacing,
        ) - 48,
    },
  },
  CONSTANTS: {
    descriptionHeight: MediaConstant.descriptionHeight,
    gradientHeight: {
      rail: 80,
      card: 98,
      reviewGrid: 40,
    },
  },
  CARD: {
    list: {
      defaultSize: {
        viewwidth: Utility.getScreenWidth(),
        viewheight: Utility.listDefaultViewHeight,
        imagewidth: 78,
        imageheight: 48,
      },
    },
    rail: {
      small: {
        viewwidth: MediaConstant.smallRailWidth,
        viewheight: MediaConstant.smallRailHeight,
        imagewidth: MediaConstant.smallRailWidth,
        imageheight: MediaConstant.smallRailHeight - MediaConstant.descriptionHeight,
      },
      large: {
        viewwidth: MediaConstant.largeRailWidth,
        viewheight: MediaConstant.largeRailWidth,
        imagewidth: MediaConstant.largeRailWidth,
        imageheight: MediaConstant.largeRailWidth,
      },
      larger: {
        viewwidth: MediaConstant.largeRailWidth,
        viewheight: MediaConstant.largerRailSize,
        imagewidth: MediaConstant.largeRailWidth,
        imageheight: MediaConstant.largerRailSize,
      },
      defaultSize: {
        viewwidth: MediaConstant.smallRailWidth,
        viewheight: MediaConstant.smallRailHeight,
        imagewidth: MediaConstant.smallRailWidth,
        imageheight: MediaConstant.smallRailHeight - MediaConstant.descriptionHeight,
      },
    },
    grid: {
      defaultSize: {
        viewwidth: Utility.getDynamicWidthForGrid(
          MediaConstant.NUM_OF_CARDS.GRID,
          MediaConstant.defaultHorizontalSpacing,
        ),
        viewheight:
          (9 / 16)
            * Utility.getDynamicWidthForGrid(
              MediaConstant.NUM_OF_CARDS.GRID,
              MediaConstant.defaultHorizontalSpacing,
            )
          + MediaConstant.descriptionHeight,
        imagewidth: Utility.getDynamicWidthForGrid(
          MediaConstant.NUM_OF_CARDS.GRID,
          MediaConstant.defaultHorizontalSpacing,
        ),
        imageheight:
          (9 / 16)
          * Utility.getDynamicWidthForGrid(
            MediaConstant.NUM_OF_CARDS.GRID,
            MediaConstant.defaultHorizontalSpacing,
          ),
      },
      small: {
        viewwidth: Utility.getDynamicWidthForGrid(
          MediaConstant.NUM_OF_CARDS.GRID,
          MediaConstant.defaultHorizontalSpacing,
        ),
        viewheight:
          (9 / 16)
            * Utility.getDynamicWidthForGrid(
              MediaConstant.NUM_OF_CARDS.GRID,
              MediaConstant.defaultHorizontalSpacing,
            )
          + MediaConstant.descriptionHeight,
        imagewidth: Utility.getDynamicWidthForGrid(
          MediaConstant.NUM_OF_CARDS.GRID,
          MediaConstant.defaultHorizontalSpacing,
        ),
        imageheight:
          (9 / 16)
          * Utility.getDynamicWidthForGrid(
            MediaConstant.NUM_OF_CARDS.GRID,
            MediaConstant.defaultHorizontalSpacing,
          ),
      },
      large: {
        viewwidth: Utility.getDynamicWidthForGrid(
          MediaConstant.NUM_OF_CARDS.GRID,
          MediaConstant.defaultHorizontalSpacing,
        ),
        viewheight:
          (9 / 16)
            * Utility.getDynamicWidthForGrid(
              MediaConstant.NUM_OF_CARDS.GRID,
              MediaConstant.defaultHorizontalSpacing,
            )
          + MediaConstant.descriptionHeight,
        imagewidth: Utility.getDynamicWidthForGrid(
          MediaConstant.NUM_OF_CARDS.GRID,
          MediaConstant.defaultHorizontalSpacing,
        ),
        imageheight:
          (9 / 16)
          * Utility.getDynamicWidthForGrid(
            MediaConstant.NUM_OF_CARDS.GRID,
            MediaConstant.defaultHorizontalSpacing,
          ),
      },
    },
    reviewGrid: {
      defaultSize: {
        viewwidth: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
        viewheight: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
        imagewidth: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
        imageheight: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
      },
      small: {
        viewwidth: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
        viewheight: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
        imagewidth: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
        imageheight: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
      },
      large: {
        viewwidth: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
        viewheight: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
        imagewidth: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
        imageheight: Utility.getDynamicWidthForGrid(3, MediaConstant.reviewGridHorizontalSpacing),
      },
    },
    card: {
      fullWidth: {
        viewwidth: Utility.getScreenWidth() - 2 * Utility.padding,
        viewheight: MediaConstant.cardDimentionRatio * Utility.getScreenWidth(),
        imagewidth: Utility.getScreenWidth() - 2 * Utility.padding,
        imageheight: MediaConstant.cardDimentionRatio * Utility.getScreenWidth(),
      },
      defaultSize: {
        viewwidth: Utility.getScreenWidth() - 2 * Utility.padding,
        viewheight: MediaConstant.cardDimentionRatio * Utility.getScreenWidth(),
        imagewidth: MediaConstant.playlistWidth,
        imageheight: MediaConstant.cardDimentionRatio * Utility.getScreenWidth(),
      },
    },
    horizontalPlaylist: {
      defaultSize: {
        viewwidth: MediaConstant.playlistWidth,
        viewheight: MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio,
        imagewidth: MediaConstant.playlistWidth,
        imageheight:
          MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio
          - MediaConstant.descriptionHeight,
      },
      small: {
        viewwidth: MediaConstant.playlistWidth,
        viewheight: MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio,
        imagewidth: MediaConstant.playlistWidth,
        imageheight:
          MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio
          - MediaConstant.descriptionHeight,
      },
      large: {
        viewwidth: MediaConstant.playlistWidth,
        viewheight: MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio,
        imagewidth: MediaConstant.playlistWidth,
        imageheight:
          MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio
          - MediaConstant.descriptionHeight,
      },
    },
    verticalPlaylist: {
      defaultSize: {
        viewwidth: MediaConstant.playlistWidth,
        viewheight: MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio,
        imagewidth:
          (9 / 16) * (MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio),
        imageheight: MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio,
      },
      small: {
        viewwidth: MediaConstant.playlistWidth,
        viewheight: MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio,
        imagewidth:
          (9 / 16) * (MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio),
        imageheight: MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio,
      },
      large: {
        viewwidth: MediaConstant.playlistWidth,
        viewheight: MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio,
        imagewidth:
          (9 / 16) * (MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio),
        imageheight: MediaConstant.playlistWidth * MediaConstant.playlistCardAspectRatio,
      },
    },
  },
  SCREEN: {},
};

export default MEDIA;
