import React, { useState } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Image,
} from 'react-native';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { ids, styles } from './styles';
import { useNavigation, useRoute } from '@react-navigation/native';
import { OutlinedTextField } from 'react-native-material-textfield';
import colors from '../../theme/Colors';
import { useDispatch } from 'react-redux';
import { addProLink, fetchOGDetails } from '../../actions/InfluencerActions';

export default function AddLinksModal() {
  const navigation = useNavigation();
  const { params: { link_type = '', refresh = () => {} } = {} } = useRoute();
  const [url, setUrl] = useState('');
  const dispatch = useDispatch();
  const [urlError, setUrlError] = useState('');

  const closeModal = () => {
    navigation.goBack();
  };

  const onChangeUrl = (value = '') => {
    setUrl(value);
    setUrlError('');
  };

  const onAddProLinkPress = () => {
    dispatch(
      addProLink(link_type, url, (success, response) => {
        if (!success) {
          setUrlError('Unable to add this URL.');
          return;
        }
        refresh();
        closeModal();
        // const { id = '' } = response;
        // dispatch(
        //   fetchOGDetails(id, (success, response) => {
        //     if (!success) {
        //       const { error_message = '' } = response;
        //       setUrlError(error_message);
        //       return;
        //     }
        //     refresh();
        //     closeModal();
        //   }),
        // );
      }),
    );
  };

  return (
    <>
      <DebouncedTouchableOpacity
        style={styles.touchableContainer}
        onPress={closeModal}
      />
      <View style={styles.modalContainer} pointerEvents='box-none'>
        <View style={styles.modal} dataSet={{ media: ids.modal }}>
          <Text style={styles.headerTitle}>Add a new link.</Text>
          <View
            style={{ paddingVertical: 16, marginTop: 24, marginBottom: 12 }}
          >
            <OutlinedTextField
              label='URL'
              tintColor={colors.subtitle}
              inputContainerStyle={styles.textInputContainerStyle}
              lineWidth={0}
              activeLineWidth={0}
              onChangeText={onChangeUrl}
              labelOffset={{ x1: -17, y1: -20, y0: -8 }}
              contentInset={{ input: 8 }}
              value={url}
              error={urlError}
            />
          </View>
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={[styles.buttonContainer, { paddingBottom: 0 }]}
          >
            <TouchableOpacity style={styles.button} onPress={onAddProLinkPress}>
              <Text style={[styles.buttonText, { fontSize: 14 }]}>Add</Text>
            </TouchableOpacity>
          </KeyboardAvoidingView>
        </View>
      </View>
    </>
  );
}
