import React from 'react';
import { View, StyleSheet } from 'react-native';

export const Grayscale = ({ children }) => (
  <View style={styles.grayscaleContainer}>{children}</View>
);

export const RGBA = ({ children, red, green, blue }) => {
  return (
    <View style={styles.redScaleContainer}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  grayscaleContainer: {
    filter: 'grayscale(100%)',
  },
  redScaleContainer: {
    filter: 'hue-rotate(330deg)',
    opacity: 0.65,
  },
});
