import React, { Component } from 'react';
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  LayoutAnimation,
  Platform,
  UIManager,
  Image,
  StyleSheet,
} from 'react-native';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getProductPersonalizedDetails } from '../../actions/ProductPageElementsActions';
import Utility from '../../utils/Utility';
import colors, { colorCode } from '../../theme/Colors';
import DefaultSizeProductList from './DefaultSizeProductList';
import images from '../../theme/Images';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export class FoxyPersonalizedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMore: false,
    };
  }

  toggleLoadMore = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this.setState((prevState) => ({
      loadMore: !prevState.loadMore,
    }));
  };

  renderPills = ({ ingredients = [], star_ingredients = [] }) => {
    if (Utility.isBlank(ingredients)) {
      return null;
    }
    star_ingredients.sort((first, second) => {
      const { name } = first;
      if (ingredients.includes(name)) {
        return -1;
      }
      if (!ingredients.includes(name)) {
        return 1;
      }
      return 0;
    });

    return (
      <View style={styles.pillContainer}>
        {_.map(star_ingredients, (ingredient) => {
          return (
            <View
              style={[
                styles.ingredientPill,
                {
                  backgroundColor: ingredients.includes(ingredient.name)
                  ? colors.lightBackgroundGreen
                  : '#979BAA33',
                },
              ]}
            >
              <Text
                style={[
                  styles.pillText,
                  { color: ingredients.includes(ingredient.name)
                    ? '#01b460'
                    : '#979BAA',
                  },
                ]}
              >
                {ingredient.name}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };

  render() {
    const { loadMore } = this.state;
    const {
      productPersonalizedDetails = {},
      productPersonalizedDetails: {
        personalized_ingredient_products: {
          name = '',
          sub_title = '',
          items = [],
        } = {},
      } = {},
    } = this.props;
    if (Utility.isBlank(items[0])) {
      return null;
    }
    const moreItems = items.slice(1);

    return (
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          <Text style={styles.title} ellipsizeMode='tail' numberOfLines={1}>
            {name}
          </Text>
          {Utility.isPresent(sub_title) ? (
            <Text
              style={styles.subTitle}
              ellipsizeMode='tail'
              numberOfLines={1}
            >
              {sub_title}
            </Text>
          ) : null}
        </View>

        <View>
          <DefaultSizeProductList
            itemData={items[0] || {}}
            listName={name}
            renderIngredients={() => (
              <this.renderPills
                ingredients={items[0]?.matched_ingredients}
                star_ingredients={items[0]?.star_ingredients}
              />
            )}
          />
        </View>

        {loadMore && moreItems.length > 0 && (
          <FlatList
            data={moreItems}
            keyExtractor={(_, index) => index.toString()}
            renderItem={({ item: product, index }) => {
              return (
                <DefaultSizeProductList
                  itemData={product || {}}
                  renderIngredients={() => (
                    <this.renderPills
                      ingredients={product?.matched_ingredients}
                      star_ingredients={product?.star_ingredients}
                    />
                  )}
                />
              );
            }}
            extraData={this.state}
          />
        )}

        {!loadMore && moreItems.length > 1 && (
          <TouchableOpacity
            onPress={this.toggleLoadMore}
            hitSlop={Utility.getHitSlop()}
            style={{
              flexDirection: 'row',
              alignSelf: 'flex-end',
              alignItems: 'center',
              marginRight: 4,
              marginTop: 4,
            }}
          >
            <Text style={styles.loadMoreText}>{!loadMore && 'Load More'}</Text>
            <Image
              style={{
                width: 12,
                height: 12,
                resizeMode: 'contain',
                tintColor: colors.cta.lightBlue,
                marginLeft: 4,
              }}
              source={images.chevronDown}
            />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  loadMoreText: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
    // flex: 1,
    // justifyContent: 'flex-end',
    // marginRight: 12,
    // alignSelf: 'flex-end',
  },
  subTitle: {
    marginTop: 4,
    fontSize: 14,
    lineHeight: 18,
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    flex: 1,
    alignContent: 'flex-start',
    marginBottom: 12,
  },
  title: {
    fontSize: 18,
    lineHeight: 21,
    fontFamily: 'Roboto-Bold',
    fontWeight: '700',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    flex: 1,
    alignContent: 'flex-start',
    marginTop: 40,
  },
  innerContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.background,
  },
  container: { backgroundColor: colors.background, marginHorizontal: 12 },
  pillContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    // height: 18,
    flexWrap: 'wrap',
    marginHorizontal: 16,
    marginBottom: 4,
    alignSelf: 'flex-start',
  },
  ingredientPill: {
    // paddingVertical: 5,
    paddingHorizontal: 9,
    borderColor: colors.linerGradientGreenFirst,
    backgroundColor: colors.lightBackgroundGreen,
    borderWidth: 0,
    borderRadius: 12,
    marginRight: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pillText: {
    fontSize: 12,
    color: '#01b460',
    fontFamily: 'Roboto-Regular',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ getProductPersonalizedDetails }, dispatch),
});

export default connect(null, mapDispatchToProps)(FoxyPersonalizedProducts);
