import Config from '../../libraries/ReactNativeConfig';

export const STATUS = {
  EXPIRED: 'expired',
  UNINITIALIZED: 'uninitialized',
  INITIALIZED: 'initialized',
  COMPLETED: 'completed',
  PARTIALLY_COMPLETED: 'partially_completed',
};

export const INVITE_STATUS = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  PENDING: 'pending',
  EXPIRED: 'expired',
};

export const HEADINGS = {
  GROUP_DEAL: {
    ACTIVE: 'Active Group Deal',
    EXPIRED: 'Expired Group Deal',
    UNLOCKED: 'Unlocked Group Deal',
    REJECTED: 'Rejected Group Deal',
    INVITATION: 'Invitation for group deal',
  },
  REFERRAL: {
    ACTIVE: `Claim your ${Config.APP_NAME} kit`,
    EXPIRED: 'Expired Referral',
    REJECTED: 'Rejected Group Deal',
    INVITATION: 'Invitation for group deal',
  },
};

export const CARD_TYPE = {
  GROUP_DEAL: 'absolute',
  REFERRAL: 'free_gift',
};

export const FREE_GIFT_DATA = { sku: 'free_gift' };

export const REFERRAL_CARD_TYPE = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
};
