import React, { Component } from 'react';
import { View, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import Modal from 'react-native-modal';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import styles from '../camera/shared/styles/AlertBoxStyles';
import images from '../../theme/Images';

class ModalProgressView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      height,

      isVisible,
    } = this.props;

    return (
      <Modal
        isVisible={isVisible}
        onBackdropPress={this.onPressOutside}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          marginBottom: 0,
          marginRight: 0,
          marginLeft: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onSwipeEnd={this.onPressOutside}
        onSwipeComplete={this.onPressOutside}
        swipeDirection="down"
        swipeThreshold={200}
        propagateSwipe
        backdropTransitionOutTiming={0}
      >
        <View
          style={[
            styles.alertBoxContainer,
            {
              backgroundColor: 'transparent',
              top: (Utility.screenHeight - height - 50) / 2,
              height,
              alignSelf: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <ActivityIndicator size="large" color={colors.foxyGreen} />
        </View>
      </Modal>
    );
  }
}

ModalProgressView.propTypes = {
  isVisible: PropTypes.bool,
  height: PropTypes.number,
};

ModalProgressView.defaultProps = {
  isVisible: false,
  height: 150,
};

export default ModalProgressView;
