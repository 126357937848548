import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

class RoutineDailyProgressCard extends PureComponent {
  constructor(props) {
    super(props);
    this.progressColors = [
      ['#F2B1B1', '#FFF5F5'],
      ['#F5AB5B', '#FFECD8'],
      ['rgba(249, 218, 190, 1)', 'rgba(255, 240, 227, 0.74)'],
      ['rgba(253, 92, 99, 1)', 'rgba(255, 224, 226, 0.97)'],
      ['#F5AB5B', '#FFECD8'],
      ['#F2B1B1', '#FFF5F5'],
      ['rgba(253, 92, 99, 1)', 'rgba(255, 224, 226, 0.97)'],
      ['#F2B1B1', '#FFF5F5'],
      ['#F5AB5B', '#FFECD8'],
      ['rgba(249, 218, 190, 1)', 'rgba(255, 240, 227, 0.74)'],
      ['rgba(253, 92, 99, 1)', 'rgba(255, 224, 226, 0.97)'],
      ['#F5AB5B', '#FFECD8'],
      ['#F2B1B1', '#FFF5F5'],
      ['rgba(253, 92, 99, 1)', 'rgba(255, 224, 226, 0.97)'],
      ['#F2B1B1', '#FFF5F5'],
      ['#F5AB5B', '#FFECD8'],
      ['rgba(249, 218, 190, 1)', 'rgba(255, 240, 227, 0.74)'],
      ['rgba(253, 92, 99, 1)', 'rgba(255, 224, 226, 0.97)'],
      ['#F5AB5B', '#FFECD8'],
      ['#F2B1B1', '#FFF5F5'],
      ['rgba(253, 92, 99, 1)', 'rgba(255, 224, 226, 0.97)'],
    ];
  }

  renderDailyProgress = ({ item, index }) => {
    const { dailyProgress = [] } = this.props;
    const numberOfDays = dailyProgress.length;
    const colors = this.progressColors[index];
    const progressBarWidth =
      numberOfDays > 0
        ? (Utility.getScreenWidth() - 54 - (numberOfDays - 1) * 8) /
          numberOfDays
        : 0;
    const progressStyle = [
      styles.progressGradient,
      {
        height: item,
        width: progressBarWidth - 8,
      },
    ];
    return (
      <View style={styles.progressContainer}>
        <View
          style={[
            styles.backgroundContainer,
            { width: progressBarWidth },
          ]}
        >
          <LinearGradient
            colors={colors}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 0 }}
            style={progressStyle}
          />
        </View>
        <Text style={styles.progressText}>Day {index + 1}</Text>
      </View>
    );
  };

  render() {
    const { dailyProgress = [] } = this.props;
    const actualProgressDays = dailyProgress.filter((item) => item !== '0%');
    if (Utility.isBlank(actualProgressDays)) return null;
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Daily Progress</Text>
        <FlatList
          data={dailyProgress}
          renderItem={this.renderDailyProgress}
          horizontal
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => `${item.index}`}
          bounces={false}
          style={styles.flatlist}
        />
      </View>
    );
  }
}

export default RoutineDailyProgressCard;

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth() - 24,
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 12,
    paddingRight: 0,
    marginBottom: 36,
    marginLeft: 12,
  },
  flatlist: {
    width: '100%',
    marginTop: 12,
  },
  title: {
    color: colors.light_grey,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
  },
  backgroundContainer: {
    width: 40,
    height: 75,
    borderRadius: 8,
    backgroundColor: colors.ultraLightGray,
    padding: 4,
    justifyContent: 'flex-end',
  },
  progressGradient: {
    height: '100%',
    width: 32,
    alignSelf: 'center',
    borderRadius: 8,
  },
  progressText: {
    color: colors.light_grey,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    marginTop: 4,
    alignSelf: 'center',
  },
  progressContainer: {
    marginRight: 10,
  },
});
