import React, { useState } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { AnalyticsManager } from '../../analytics';
import { isDesktop } from '../../utils/BooleanUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';
import FoxyPromiseModal from './FoxyPromiseModal';
import images from '../../theme/Images';
import { getMinifiedImage } from '../../utils/ImageUtils';

const FoxyPromise = ({ onPress, style, promiseImage, promise }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.foxyPromiseContainer} dataSet={{ media: ids.foxyPromiseContainer }}>
        <View style={styles.imageContainer}>
          <Image source={promiseImage} style={style} />
        </View>

        <Text style={styles.promiseText} allowFontScaling={false} dataSet={{ media: ids.promiseText }}>
          {promise}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const FoxyPromises = ({
  productPage = {},
  product_id,
  brand = {},
}) => {
  const { foxy_promise: foxyPromise = {}, promise } = productPage;
  const {
    orignal = {},
    delivery = {},
    return: returnPromise = {},
    payment = {},
  } = foxyPromise;
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { certificate_image_url: certificateImage } = brand;
  const promises = [
    { ...orignal, image: getMinifiedImage(certificateImage, 382), isOriginal: true },
    { ...delivery, image: images.foxyPromiseFreeDelivery },
    { ...returnPromise, image: images.foxyPromiseHassleFreeReturn },
    { ...payment, image: images.foxyPromiseSecurePayments },
  ];
  const hideModal = () => setShowModal(false);
  const handlePress = (newIndex) => {
    AnalyticsManager.logEvent('item_action_2', {
      item_name: promise,
      item_id: product_id,
    });
    setIndex(newIndex);
    setShowModal(true);
  };
  return (
    <View style={styles.container}>
      {!isDesktop() && (
        <View style={styles.headingContainer}>
          <Text style={styles.heading}>{promise?.heading || ''}</Text>
        </View>
      )}

      <View style={styles.bottomContainer} dataSet={{ media: ids.bottomContainer }}>
        <View style={styles.flexRow}>
          <FoxyPromise
            promise='100% Original Products'
            promiseImage={images.foxyPromise.orignalProducts}
            style={styles.originalProduct}
            onPress={() => handlePress(0)}
          />
          <FoxyPromise
            promise={foxyPromise.delivery?.heading}
            promiseImage={images.deliveryPromise}
            style={styles.foxyPromise}
            onPress={() => handlePress(1)}
          />
          <FoxyPromise
            promise='Hassle-Free Returns'
            promiseImage={images.foxyPromise?.easyReturns}
            style={styles.return}
            onPress={() => handlePress(2)}
          />
          <FoxyPromise
            promise='Secure Payments'
            promiseImage={images.foxyPromise.securePayments}
            style={styles.securePayments}
            onPress={() => handlePress(3)}
          />
        </View>
      </View>
      <FoxyPromiseModal
        promises={promises}
        showModal={showModal}
        index={index}
        hideModal={hideModal}
        setIndex={setIndex}
      />
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  container: { marginBottom: 16 },
  headingContainer: { backgroundColor: colors.background, zIndex: 1 },
  heading: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 32,
    marginBottom: 12,
    marginHorizontal: 12,
  },
  bottomContainer: {
    flexDirection: 'column',
    padding: 16,
    backgroundColor: colors.white,
    marginHorizontal: 12,
    '@media (min-width: 768px)': {
      padding: 0,
      paddingTop: 12,
      marginHorizontal: 0,
      backgroundColor: colors.background,
      marginBottom: -32,
    },
  },
  flexRow: { flexDirection: 'row', justifyContent: 'space-around', flex: 1 },
  originalProduct: {
    width: 36,
    height: 40,
    resizeMode: 'contain',
    alignSelf: 'center',
    overflow: 'visible',
  },
  foxyPromise: {
    width: 36,
    height: 36,
    resizeMode: 'contain',
    alignSelf: 'center',
    overflow: 'visible',
  },
  return: {
    width: 36,
    height: 34,
    resizeMode: 'contain',
    alignSelf: 'center',
    overflow: 'visible',
  },
  securePayments: {
    width: 36,
    height: 40,
    resizeMode: 'contain',
    alignSelf: 'center',
    overflow: 'visible',
  },
  foxyPromiseContainer: {
    width: getScreenWidth() / 5,
    alignContent: 'center',
    marginVertical: 8,
    overflow: 'visible',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'center',
      width: PRODUCT_RIGHT_CONTAINER_WIDTH / 4 - 20,
    },
  },
  imageContainer: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 12,
  },
  promiseText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#000',
    '@media (min-width: 768px)': {
      marginTop: -10,
    },
  },
});

export default FoxyPromises;
