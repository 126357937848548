const check = async () => 'denied';

const request = async () => 'denied';

const requestMultiple = async (permissionTypes) => {
  const results = {};
  for (const permissionType of permissionTypes) {
    results[permissionType] = "denied";
  }
  return results;
};

const Permissions = { check, request, requestMultiple };
export default Permissions;
