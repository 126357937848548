import React from 'react';
import { View, Text, Image } from 'react-native';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import _ from 'lodash';
import styles from './styles';
import {
  AnalyticsEvent,
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';

const Trust = (props) => {
  const {
    heroIngredients,
    myAttributesValues,
    item,
    item: {
      positive_ingredient_threshold = '',
      negative_ingredient_threshold = '',
    },
    productPositiveIngredients,
    productNegativeIngredients,
    trusted,
    sku_id,
    id,
  } = props;

  let positiveCount = Object.keys(productPositiveIngredients).length;
  let negativeCount = Object.keys(productNegativeIngredients).length;

  _.forEach(productPositiveIngredients, (positiveIngredients = []) => {
    positiveCount += positiveIngredients.length;
  });

  _.forEach(productNegativeIngredients, (negativeIngredients = []) => {
    negativeCount += negativeIngredients.length;
  });

  if (
    positiveCount <= positive_ingredient_threshold ||
    negativeCount > negative_ingredient_threshold ||
    !trusted
  ) {
    AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_VIEW, {
      [EventParameterKey.SKU_ID]: sku_id,
      [EventParameterKey.PRODUCT_ID]: id,
      [EventParameterKey.HAS_TRUST]: false,
    });
    return null;
  }
  AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_VIEW, {
    [EventParameterKey.SKU_ID]: sku_id,
    [EventParameterKey.PRODUCT_ID]: id,
    [EventParameterKey.HAS_TRUST]: true,
  });
  return (
    <View style={styles.container}>
      <Image source={images.foxyMatch.perfect} style={styles.icon} />
      <Text style={styles.foxyMatchText}>
        Perfect match! If you don’t like it, we will take back the product even
        if opened within 48 hours
      </Text>
    </View>
  );
};

export default Trust;
