import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';

class VariantModalQuantity extends PureComponent {
  constructor(props) {
    super(props);
    this.incrementHitSlop = Utility.getHitSlop('quantityPlus');
    this.decrementHitSlop = Utility.getHitSlop('quantityMinus');
  }

  onIncrement = () => {
    const { setVariantQuantity = () => {}, quantity } = this.props;
    setVariantQuantity(quantity + 1);
  };

  onDecrement = () => {
    const { setVariantQuantity = () => {}, quantity } = this.props;
    if (quantity > 1) {
      setVariantQuantity(quantity - 1);
    }
  };

  render() {
    const { quantity } = this.props;
    return (
      <View style={styles.container}>
        <TouchableOpacity onPress={this.onDecrement}>
          <View hitSlop={this.decrementHitSlop}>
            <FastImageView
              source={images.minus}
              style={styles.incrementDecrementButton}
              resizeMode='contain'
            />
          </View>
        </TouchableOpacity>
        <Text style={styles.incrementDecrementText}>{quantity}</Text>
        <TouchableOpacity onPress={this.onIncrement}>
          <View hitSlop={this.incrementHitSlop}>
            <FastImageView
              source={images.plusBlack}
              style={styles.incrementDecrementButton}
              resizeMode='contain'
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default VariantModalQuantity;

const styles = StyleSheet.create({
  container: {
    width: 72,
    height: 32,
    backgroundColor: colors.white,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    marginVertical: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.border,
  },
  incrementDecrementText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: colors.black,
  },
  incrementDecrementButton: {
    width: 8,
    height: 8,
  },
});
