// Dependencies
import React, { PureComponent } from 'react';
import {
  View,
  ImageBackground,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  FlatList,
} from 'react-native';
import {
  State,
  TapGestureHandler,
} from '../../libraries/ReactNativeGestureHandler';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { denormalize } from 'normalizr';
import Carousel from 'react-native-looped-carousel';
import _ from 'lodash';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import Images from '../../theme/Images';
import { MAX_LINES } from '../../config/Constants';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import MediaComponent from '../media';
import { videoView, videoWatched } from '../../actions/ActionTypes';
import PlayerMidControls from '../media/PlayerMidControls';
import InViewPort from '../../utils/InViewPort';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { withEither } from '../../lib/Monads';
import { ProductInfoStyles } from './styles';

import FastImageView from '../FastImageView';
import colors from '../../theme/Colors';
import ImageZoom from '../../libraries/ReactNativeImagePanZoom';
import Swiper from 'react-native-swiper';
import Config from '../../libraries/ReactNativeConfig';
import { isDesktop } from '../../utils/BooleanUtility';

const styles = StyleSheet.create({
  swiperContainer: {
    height: 0.72 * Utility.getScreenWidth(),
    overflow: 'hidden',
  },
  carouselContainer: {
    height: '100%',
    width: '100%',
  },
  viewPortContainer: {
    height: 0.62 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },
  chosenBulletStyles: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#4E4E4E',
    opacity: 0.6,
    borderWidth: 0.5,
    borderColor: '#4E4E4E',
  },
  dotStyle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#4E4E4E',
    opacity: 0.6,
  },
  tagTextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '92%',
  },
  productTag: {
    position: 'absolute',
    left: 0,
    maxWidth: 120,
    height: 64,
    flexDirection: 'row',
    alignItems: 'center',
    top: Utility.isIOS() ? 10 : 20,
  },
  productTagText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
    margin: 4,
  },
  productDetailImage: {
    height: 0.62 * Utility.getScreenWidth(),
    width: Utility.getScreenWidth(),
  },

  mediaControl: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  playerContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playerStyle: { width: '100%', height: '100%', top: 0, position: 'absolute' },
});

class VariantProductImages extends PureComponent {
  constructor(props) {
    super(props);
    this.swiperRef = React.createRef();
    this.doubleTapRef = React.createRef();
    this.state = {
      paused: true,
      isBuffering: false,
      currentImageIndex: 0,
    };
    this.debouncedOpenFullScreenImageModal = _.debounce(
      this.openFullScreenImageModal,
      200,
      {
        leading: false,
        trailing: true,
      },
    );
    const { itemData: { product_image_aspect_shape = 'square' } = {} } = props;
    this.productImageAspectShape = product_image_aspect_shape;
    this.imageUrl = null;
    const { previousScreen } = this.props;
    const conditionalTopInset = Utility.isBlank(previousScreen)
      ? 'WithoutTopInset'
      : 'WithTopInset';
    this.modalContainerStyle = [
      ProductInfoStyles[
        `swiperContainer${_.capitalize(
          this.productImageAspectShape,
        )}Image${conditionalTopInset}`
      ],
      isDesktop() && { width: '100%', height: '100%' },
    ];
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { currentImageIndex } = this.state;
    if (prevState.currentImageIndex !== currentImageIndex) {
      this.scrollImage(currentImageIndex);
    }
  };

  scrollImage = (index) => {
    this.swiperRef.current?.scrollTo(index);
  };

  imageError = () => {
    const { id, name, navigation, onScreen = '' } = this.props;
    AnalyticsUtility.fireImageErrorAnalytics(
      id,
      this.imageUrl,
      name,
      'product',
      onScreen,
    );
  };

  onProductImageDoubleTap = (event) => {
    if (event.nativeEvent.state === State.ACTIVE) {
      const { doubleTapped = () => {} } = this.props;
      this.debouncedOpenFullScreenImageModal.cancel();
      doubleTapped();
    }
  };

  imageTapped = () => {
    AnalyticsManager.logEvent(EventType.panZoom.ZOOM_MODAL_OPEN, {
      [EventParameterKey.INDEX]: this.props.currentImageIndex,
      [EventParameterKey.SKU_ID]: this.props.skuId,
    });
    this.debouncedOpenFullScreenImageModal();
  };

  openFullScreenImageModal = () => {
    const {
      navigation,
      images,
      itemData,
      addedToCart,
      addToCartLayout,
      showCart,
      skuId,
      refreshOfferStrip,
      showToast,
      mrp,
      sp,
      discount,
      selectedVariant,
      campaignId,
      isProductInStock,
      showFreeProductScreen,
      shouldShowChooseGiftScreen,
      offerId,
      name,
    } = this.props;
    const { currentImageIndex } = this.state;
    navigation.navigate('FullScreenImageModal', {
      images,
      itemData,
      addedToCart,
      addToCartLayout,
      showCart,
      skuId,
      refreshOfferStrip,
      showToast,
      mrp,
      sp,
      discount,
      selectedVariant,
      campaignId,
      isProductInStock,
      showFreeProductScreen,
      shouldShowChooseGiftScreen,
      offerId,
      name,
      currentImageIndex,
      swiperIndexChanged: this.swiperIndexChanged,
    });
  };

  reportBuffering = (isBuffering) => {
    this.setState({ isBuffering });
  };

  getZoomableImage = (imageUrl) => {
    if (typeof imageUrl === 'object') {
      return <this.heroVideo />;
    }
    const newUrl = Utility.isAndroid()
      ? imageUrl
      : Utility.getMinifiedImage(
          imageUrl,
          ProductInfoStyles.productDetailSquareImage.height,
          Utility.getScreenWidth(),
        );
    this.imageUrl = newUrl;

    return (
      <ImageZoom
        ref={this.imageZoomRef}
        cropWidth={Utility.getScreenWidth()}
        cropHeight={ProductInfoStyles.productDetailSquareImage.height}
        imageWidth={Utility.getScreenWidth()}
        imageHeight={ProductInfoStyles.productDetailSquareImage.height}
        maxScale={2.5}
        enableSwipeDown={true}
        // horizontalOuterRangeOffset={this.horizontalOffsetChangedOfZoomedImage}
      >
        <FastImageView
          resizeMode='cover'
          style={ProductInfoStyles.productDetailSquareImage}
          source={newUrl}
          onError={this.imageError}
        />
      </ImageZoom>
    );
  };

  getImage = (imageUrl) => {
    if (typeof imageUrl === 'object') {
      return <this.heroVideo />;
    }
    const newUrl = Utility.getMinifiedImage(imageUrl);

    this.imageUrl = newUrl;
    const imageStyle = [
      ProductInfoStyles[
        `variantModal${_.capitalize(this.productImageAspectShape)}Image`
      ],
      isDesktop() && { width: '100%', height: '100%' },
    ];
    return (
      <TouchableWithoutFeedback onPress={this.imageTapped}>
        <FastImageView
          resizeMode='contain'
          style={imageStyle}
          source={newUrl}
          onError={this.imageError}
        />
      </TouchableWithoutFeedback>
    );
  };

  tag = (props) => {
    const { tag } = props;
    if (Utility.isBlank(tag)) {
      return null;
    }

    return (
      <ImageBackground
        resizeMode='contain'
        source={Images.myProfile.freeProductTag}
        style={styles.productTag}
      >
        <View style={styles.tagTextContainer}>
          <Text
            numberOfLines={MAX_LINES.single}
            allowFontScaling={false}
            style={styles.productTagText}
          >
            {tag[0]}
          </Text>
        </View>
      </ImageBackground>
    );
  };

  togglePlayPause = () => {
    this.setState((prevState) => ({ paused: !prevState.paused }));
  };

  renderMiddleControls = (props) => {
    const { paused, isBuffering } = this.state;
    return (
      <PlayerMidControls
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 150,
        }}
        togglePlayPause={this.togglePlayPause}
        nextButtonTapped={() => {}}
        previousButtonTapped={() => {}}
        paused={paused}
        isBuffering={isBuffering}
      />
    );
  };

  checkVisible = (visible) => {
    if (!visible) {
      this.setState({ paused: true });
    }
  };

  onVideoProgress = () => {
    AnalyticsManager.logEvent('on_video_progress');
  };

  onLoadVideo = () => {
    AnalyticsManager.logEvent('on_video_load');
  };

  onMediaComponentTap = () => {
    AnalyticsManager.logEvent('on_video_player_tap');
  };

  onMediaDoubleTap = () => {
    AnalyticsManager.logEvent('on_video_player_double_tap');
  };

  swiperIndexChanged = (index) => {
    // setTimeout(() => {
    this.setState({
      currentImageIndex: index,
    });
    // }, 0);
  };

  carouselCondition = () => Utility.isPresent(this.props.hero_video);

  carouselWithoutHeroVideo = () => {
    const {
      images,
      hero_video: heroVideo,
      noHeroVideo = false,
      enableInlineZoom,
      selectedVariant,
    } = this.props;
    const styles = ProductInfoStyles;
    let showBullets = images.length > 1;

    if (this.productImageAspectShape === 'vertical') {
      return (
        <FlatList
          data={images}
          horizontal
          style={styles.productImagesRailContainer}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item, index }) => this.getImage(item, index)}
          keyExtractor={(item, index) => `${index}_${item.id}`}
          bounces={false}
        />
      );
    }

    const dot = <View style={styles.dotStyle} />;
    const activeDot = (
      <View
        style={[
          ProductInfoStyles.dotStyle,
          {
            backgroundColor: 'white',
            opacity: 1,
            borderWidth: 0.5,
            borderColor: '#4E4E4E',
            overflow: 'hidden',
          },
        ]}
      />
    );

    return (
      <Swiper
        ref={this.swiperRef}
        loop={false}
        dot={dot}
        activeDot={activeDot}
        index={0}
        autoplay
        onIndexChanged={this.swiperIndexChanged}
        containerStyle={ProductInfoStyles.swiperContainerStyle}
      >
        {images.map((item) => this.getImage(item))}
      </Swiper>
    );
  };

  carouselWithHeroVideo = () => {
    const {
      images,
      webpImages,
      hero_video: heroVideo,
      noHeroVideo = false,
    } = this.props;

    const styles = ProductInfoStyles;
    let showBullets = images.length > 1;
    if (Utility.isPresent(heroVideo) && images.length === 1 && !noHeroVideo) {
      showBullets = true;
    }
    return (
      <Carousel
        horizontal
        bullets={showBullets}
        style={styles.carouselContainerVariantModal}
        bulletStyle={styles.dotStyle}
        chosenBulletStyle={styles.chosenBulletStyle}
        currentPage={0}
        // videoBulletStyle={styles.triangleStyle}
        // chosenTriangleBulletStyle={styles.chosenTriangleStyle}
        autoplay={false}
        isLooped
        isHeroVideoPresent={!Utility.isBlank(heroVideo)}
      >
        {images.map((item) => this.getImage(item))}
        {/* {<this.heroVideo />} */}
      </Carousel>
    );
  };

  conditionalCarousel = withEither(
    this.carouselCondition,
    this.carouselWithHeroVideo,
  )(this.carouselWithoutHeroVideo);

  heroVideo = () => {
    const { hero_video: heroVideo } = this.props;
    const { paused } = this.state;
    if (Utility.isBlank(heroVideo)) {
      return null;
    }

    const { navigation, videoView, user_engagement, videoWatched } = this.props;
    const {
      image_url: imageUrl,
      artist: { name: artistName = '', id: artistId } = {},
      metadata: {
        contentPlatform = 'youtube',
        videoUrl,
        title: contentTitle,
        id: videoId,
        orientation = 'horizontal',
        duration,
      },
    } = heroVideo;

    const shouldShowList = false;

    return (
      // <InViewPort onChange={this.checkVisible}>
      <View style={styles.viewPortContainer}>
        <MediaComponent
          ref={this.saveMediaComponentRef}
          videoId={videoId}
          videoUrl={videoUrl}
          videoTitle={contentTitle}
          artistId={artistId}
          artistName={artistName}
          contentPlatform={contentPlatform}
          duration={duration}
          isFullscreen={false}
          shouldShowList={shouldShowList}
          playerStyle={styles.playerStyle}
          playerContainerStyle={styles.playerContainer}
          paused={paused}
          imageUrl={imageUrl}
          currentPage={0}
          reportBuffering={this.reportBuffering}
          onVideoProgress={this.onVideoProgress}
          onLoadVideo={this.onLoadVideo}
          onMediaComponentTap={this.onMediaComponentTap}
          onMediaDoubleTap={this.onMediaDoubleTap}
          videoView={videoView}
          userEngagement={user_engagement}
          videoWatched={videoWatched}
          navigation={navigation}
        />
        <View style={styles.mediaControl}>
          <this.renderMiddleControls
            togglePlayPause={this.togglePlayPause}
            contentPlatform={contentPlatform}
          />
        </View>
      </View>
      // </InViewPort>
    );
  };

  render() {
    return (
      <>
        <TapGestureHandler
          ref={this.doubleTapRef}
          maxDelayMs={200}
          onHandlerStateChange={this.onProductImageDoubleTap}
          numberOfTaps={2}
        >
          <View style={this.modalContainerStyle}>
            <this.carouselWithoutHeroVideo />

            {/* <this.tag tag={tag} /> */}
          </View>
        </TapGestureHandler>
      </>
    );
  }
}

export default withNavigation(VariantProductImages);
