import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import { MAX_LINES } from '../../config/Constants';
import { ProductInfoStyles } from './styles';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import VariantProductImages from './VariantProductImages';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import { isDesktop } from '../../utils/BooleanUtility';

class VariantImagesWithHeader extends PureComponent {
  variantProductConstants = {
    imageAspectRatio: 0.62,
    selectedVariantHeight: 20,
    topInset: 40,
    productSectionDefaultHeight:
      (isDesktop() ? 0.30 : 0.62) * Utility.getScreenWidth() + 40,
    extraVerticalImagePadding: 20,
  };

  constructor(props) {
    super(props);
    const height = this.getProductSectionHeight();
    this.containerStyle = [
      ProductInfoStyles.productDetailsContainer,
      { height },
    ];
    const { itemData: { product_image_aspect_shape = 'square' } = {} } =
      this.props;
    this.isVerticalImage = product_image_aspect_shape === 'vertical';
  }

  getProductSectionHeight = () => {
    const { previousScreen = '', selectedVariantName } = this.props;
    let height = this.variantProductConstants.productSectionDefaultHeight;
    if (!Utility.isBlank(previousScreen)) {
      height =
        this.variantProductConstants.productSectionDefaultHeight +
        (Utility.isIOS() ? this.variantProductConstants.topInset : 0);
    }
    if (!Utility.isBlank(selectedVariantName)) {
      height += this.variantProductConstants.selectedVariantHeight;
    }
    if (this.isVerticalImage) {
      height -= this.variantProductConstants.extraVerticalImagePadding;
    }
    return height;
  };

  render() {
    const {
      itemData,
      previousScreen = '',
      imageArray,
      selectedVariant,
      navigation,
    } = this.props;
    const {
      brand: { name: brandName } = {},
      name: productName,
      outOfStock,
      mrp,
      sp,
      discount,
    } = itemData;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    const images = Utility.isBlank(imageArray) ? itemData.images : imageArray;
    return (
      <>
        <View style={this.containerStyle}>
          <VariantProductImages
            images={images}
            previousScreen={previousScreen}
            noHeroVideo
            navigation={navigation}
            enableInlineZoom
            name={productName}
            isProductInStock={!outOfStock}
            itemData={itemData}
            mrp={mrp}
            sp={sp}
            discount={discount}
            addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
            selectedVariant={selectedVariant}
          />
        </View>
        <View style={ProductInfoStyles.nameContainer}>
          <Text style={ProductInfoStyles.variantBrandNameStyle}>
            {brandName}
          </Text>
          <Text
            numberOfLines={MAX_LINES.single}
            style={ProductInfoStyles.variantNameStyle}
          >
            {productName}
          </Text>
        </View>
      </>
    );
  }
}

export default VariantImagesWithHeader;
