import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Keyboard,
  TextInput,
  Linking,
} from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaymentStyles } from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { RNPayuPayment } from '../../lib/RNPayuPayment';
import {
  saveUpiDetails,
  saveIntentDetails,
  validateUpi,
} from '../../actions/ActionTypes';

import FoxyShadowButton from '../../lib/FoxyShadowButton';

import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import _ from 'lodash';
import { isDesktop } from '../../utils/BooleanUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';

const INTENT_PREFIXES = {
  tez: 'tez://upi/',
  gpay: 'tez://upi/',
  phonepe: 'phonepe://',
  paytm: `paytmmp://upi/`,
  generic: 'upi://',
};

class UpiPayment extends Component {
  upiPaymentConstants = {
    vpaInfoText: 'e.g. mobilenumber@bank or username@bank',
    saveVPAText: 'VPA details will be saved securely for future transactions',
    invalidVpaText: 'This VPA is invalid',
  };

  paymentConstants = {
    headerText: 'UPI ID',
    vpa: 'VPA',
    enterVpaText: 'Enter Virtual Payment address',
  };

  constructor(props) {
    super(props);
    const { metaData = {} } = this.props;
    this.ref = [];
    this.ref.vpa = React.createRef();
    this.state = {
      vpa: Utility.isPresent(metaData.upiHandle) ? metaData.upiHandle : '',
      isVpaValid: false,
      isLoaderVisible: false,
      saveUpiSelected: true,
      error: '',
    };
  }

  directIntentUrlForIOS = (paymentApp, pgParams) => {
    const prefix = safelyGet([paymentApp], INTENT_PREFIXES)
      ? safelyGet([paymentApp], INTENT_PREFIXES)
      : safelyGet(['generic'], INTENT_PREFIXES);
    const deepLinkURI = upiDeepLinkParams(pgParams);

    return `${prefix}${deepLinkURI}`;
  };

  openDirectIntentURL = (paymentApp, pgParams) => {
    const intentUrl = directIntentUrlForIOS(paymentApp, pgParams);
    Linking.openURL(intentUrl);
    setTimeout(function () {
      console.tron.log('payment started');
    }, 2000);
  };

  safelyGet = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

  upiDeepLinkParams = (pgParams) => {
    const currency = pgParams['cu'];
    const reference = pgParams['tr'];
    const amount = pgParams['am'];
    const txnId = pgParams['tid'];
    const txnNote = pgParams['tn'];
    const merchantName = pgParams['pn'];
    const merchantVpa = pgParams['pa'];
    const merchantCode = pgParams['mc'];

    return `pay?pa=${merchantVpa}&pn=${merchantName}&tr=${reference}&tn=${txnNote}&am=${amount}&cu=${currency}&mc=${merchantCode}&tid=${txnId}`;
  };

  resetTextFields = () => {
    this.setState({
      vpa: '',
    });
  };

  showLoader = (isLoaderVisible) => {
    this.setState({
      isLoaderVisible,
    });
  };

  goBack = () => {
    AnalyticsUtility.fireGoBackEvent();
    const { hidePaymentDetailsScreen } = this.props;
    this.resetTextFields();
    Keyboard.dismiss();
    hidePaymentDetailsScreen();
  };

  showBackAlert = () => {
    Alert.alert(
      'Confirmation',
      'Are you sure you want to cancel the transaction?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        { text: 'OK', onPress: this.goBack },
      ],
      { cancelable: true },
    );
  };

  saveUpiCheckboxPressed = () => {
    this.setState({
      saveUpiSelected: !this.state.saveUpiSelected,
    });
  };

  addVPA = (value) => {
    const { metaData } = this.props;
    metaData.setUpiHandle(value);
    this.setState({ vpa: value, error: '' });
  };

  createOrderAndPay = (details) => {
    const { createOrder } = this.props;
    const { vpa, saveUpiSelected } = this.state;
    const upiParams = {
      virtual_bank_account_id: vpa,
      bankcode: 'UPI',
    };

    const payuPayload = {
      vpa,
    };

    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: 'upi',
      isSavedPaymentMethod: saveUpiSelected,
      paymentMethodPayload: upiParams,
      payuPayload,
      extraData: {
        method: 'upi',
        details,
      },
    };

    createOrder(paymentPayload);
  };

  validateUpiAndcreateOrderAndPay = (upiSource) => {
    const { validateUpi } = this.props;
    const { vpa } = this.state;
    this.showLoader(true);
    validateUpi(vpa, (success, response) => {
      this.showLoader(false);
      if (success && response?.valid) {
        this.setState(
          {
            isVpaValid: true,
          },
          () => this.createOrderAndPay(response?.details),
        );
      } else {
        this.setState({
          error: response?.error,
        });
      }
    });
  };

  saveUpi = () => {
    const { vpa, saveUpiSelected } = this.state;

    if (!saveUpiSelected) {
      return null;
    }

    if (Utility.isPresent(vpa)) {
      const data = { mode: 'upi', title: 'upi', detail: vpa };
      this.props.saveUpiDetails(data);
    }
  };

  saveIntent = (appPackage, appName, appImage) => {
    if (Utility.isPresent(appName)) {
      const data = {
        mode: 'upi',
        title: appName,
        detail: appPackage,
        image: appImage,
      };
      this.props.saveIntentDetails(data);
    }
  };

  payWithUPI = () => {
    //TODO: Move this function in cartmodal.js
    const {
      viewOrderStatusScreen,
      paymentSuccess,
      updateLastPaymentMode,
      setCurrentPaymentDetails,
      initiatePayment,
      openOrderStatusScreen,
    } = this.props;
    const { vpa, saveUpiSelected } = this.state;
    const upiParams = {
      vpa,
    };

    setCurrentPaymentDetails('upi', upiParams); //to retry if failed
    RNPayuPayment.payWithUPI(upiParams)
      .then((res) => {
        console.tron.log('pay with saved card successfull', res);
        if (Utility.isPresent(res.status) && res.status === 'success') {
          openOrderStatusScreen(res);
        } else {
          openOrderStatusScreen(res);
        }
      })
      .catch((e) => {
        console.tron.log('pay with upi failure', e);
        openOrderStatusScreen(e);
      });
    this.setState({
      vpa: '',
    });
  };

  payWithIntent = (appPackage, appName, image) => {
    const {
      viewOrderStatusScreen,
      paymentSuccess,
      updateLastPaymentMode,
      setCurrentPaymentDetails,
      initiatePayment,
      openOrderStatusScreen,
      firePaymentMethodClick,
    } = this.props;
    firePaymentMethodClick(appPackage, 'intent');

    setCurrentPaymentDetails('intent', appPackage);

    RNPayuPayment.payWithIntent(appPackage)
      .then((res) => {
        this.saveIntent(appPackage, appName, image);
        // updateLastPaymentMode('intent', appName, appPackage, image);
        openOrderStatusScreen(res);
      })
      .catch((e) => {
        openOrderStatusScreen(e);
      });
  };

  createOrderAndPayWithIntent = (appPackage, appName, image) => {
    const { createOrder } = this.props;

    const params = {
      name: appName,
      package_name: appPackage,
      image,
    };

    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: 'intent',
      isSavedPaymentMethod: true,
      paymentMethodPayload: params,
      payuPayload: params,
      extraData: {
        method: 'intent',
        name: appName,
        package_name: appPackage,
      },
    };

    createOrder(paymentPayload);
  };

  sortApps = (app1, app2) => {
    if (app1.priority < app2.priority) {
      return -1;
    }
    if (app1.priority > app2.priority) {
      return 1;
    }
    return 0;
  };

  androidIntents = () => {
    const { appInstalled } = this.props;

    const installedUpiApp = { ...appInstalled };

    const installedUpiAppsArray =
      Utility.objectToArrayConverter(installedUpiApp);

    installedUpiAppsArray.sort(this.sortApps);

    const topUpiApps = installedUpiAppsArray.slice(0, 4);

    const styles = PaymentStyles;

    return (
      <>
        <View style={styles.androidIntent}>
          {topUpiApps.map((app) => {
            return (
              <TouchableOpacity
                onPress={_.memoize(
                  () =>
                    this.createOrderAndPayWithIntent(
                      app.package,
                      app.name,
                      app.image_url,
                    ),
                  () => [app.package, app.name, app.image_url],
                )}
                style={styles.androidIntentContainer}
              >
                <View style={styles.androidIntentImageContainer}>
                  <Image
                    source={{ uri: app.image_url }}
                    style={styles.androidIntentImage}
                  />
                  <Text numberOfLines={1} ellipsizeMode="tail">
                    {app.name}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
        {!Utility.isBlank(topUpiApps) && (
          <View style={styles.androidIntentOrTextContainer}>
            <Text style={styles.androidIntentOrText}>or</Text>
          </View>
        )}
      </>
    );
  };

  isIOSAppInstalled = (app) =>
    app['url_scheme'] && Linking.canOpenURL(app['url_scheme']);

  singleIOSIntent = (app) => {
    const styles = PaymentStyles;
    return (
      <View style={styles.singleIosIntent}>
        <TouchableOpacity
          onPress={_.memoize(
            () =>
              this.createOrderAndPayWithIntent(
                app.package,
                app.name,
                app.image_url,
              ),
            () => [app.package, app.name, app.image_url],
          )}
        >
          <View style={styles.singleIosIntentContainer}>
            <View style={styles.singleIosIntentImageContainer}>
              <Image
                resizeMode="contain"
                style={styles.singleIosIntentImage}
                source={{ uri: app.image_url }}
              />
            </View>
            <Text style={styles.singleIosIntentText}>{app.name}</Text>
          </View>
        </TouchableOpacity>

        <View style={styles.singleIosIntentOrTextContainer}>
          <Text style={styles.singleIosIntentOrText}>or</Text>
        </View>

        {/* {Utility.isPresent(details?.intent_url) && (
    <View style={paymentVerifyingStyles.saperatorContainer}>
      <Text style={paymentVerifyingStyles.saperatorText}>
        Tap to open app
      </Text>
    </View>
  )} */}
      </View>
    );
  };

  iosIntents = () => {
    const { supportedIOSIntents } = this.props;

    if (Utility.isBlank(supportedIOSIntents)) {
      return null;
    }

    const allUpiApps = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.upi_apps_ios),
    );

    const allUpiAppsKeysArray = Utility.objectToArrayConverterKeys(allUpiApps);

    const filteredIntents = allUpiAppsKeysArray.filter((value) =>
      supportedIOSIntents.includes(value),
    );

    const upiAppsArray = Utility.objectToArrayConverterKeysWithFilteredKeys(
      filteredIntents,
      allUpiApps,
    );

    upiAppsArray.sort(this.sortApps);

    const topUpiApps = upiAppsArray.slice(0, 4);

    if (topUpiApps.length == 1) {
      return this.singleIOSIntent(topUpiApps[0]);
    }

    const styles = PaymentStyles;

    return (
      <>
        <View style={styles.iosIntentsContainer}>
          {topUpiApps.map((app) => {
            return (
              <TouchableOpacity
                onPress={_.memoize(
                  () =>
                    this.createOrderAndPayWithIntent(
                      app.package,
                      app.name,
                      app.image_url,
                    ),
                  () => [app.package, app.name, app.image_url],
                )}
                style={styles.iosIntentConatiner}
              >
                <View style={styles.iosIntentImageContainer}>
                  <Image
                    source={{ uri: app.image_url }}
                    style={styles.iosIntentImage}
                  />
                  <Text numberOfLines={1} ellipsizeMode="tail">
                    {app.name}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
        {!Utility.isBlank(topUpiApps) && (
          <View style={styles.iosIntentOrTextContainer}>
            <Text style={styles.iosIntentOrText}>or</Text>
          </View>
        )}
      </>
    );
  };

  render() {
    const styles = PaymentStyles;
    const { vpa, isVpaValid, isLoaderVisible, saveUpiSelected, error } =
      this.state;
    const { shouldAutoFocus } = this.props;

    const checkboxImage = saveUpiSelected ? images.greenCheck : '';

    return (
      <View style={styles.upiContainer}>
        {/* <FullWidthDivider margin={16} setDividerMargin={false} /> */}

        <View styles={styles.enterCvvContainer}>
          {Utility.isAndroid() ? <this.androidIntents /> : <this.iosIntents />}
          <TextInput
            placeholder="Enter your UPI ID"
            placeholderTextColor={colors.silver}
            lineWidth={1}
            blurOnSubmit={false}
            value={vpa}
            onChangeText={this.addVPA}
            ref={this.ref.vpa}
            containerStyle={styles.vpaContainer}
            onSubmitEditing={Keyboard.dismiss}
            returnKeyType="done"
            autoCapitalize={false}
            // autoFocus={shouldAutoFocus}
            style={styles.upiTextField}
          />
          {!Utility.isBlank(error) && (
            <Text style={styles.upiTextFieldError}>{error}</Text>
          )}
          <TouchableOpacity
            onPress={this.saveUpiCheckboxPressed}
            style={styles.saveUpiContainer}
          >
            <View style={styles.checkboxImage}>
              {this.state.saveUpiSelected && (
                <Image source={checkboxImage} style={styles.checkboxTick} />
              )}
            </View>
            <Text style={styles.saveUPIText}>Securely save your UPI ID</Text>
          </TouchableOpacity>

          <FoxyShadowButton
            width={isDesktop() ? '100%' : Utility.getScreenWidth() - 34}
            title={'Pay'}
            onPress={this.validateUpiAndcreateOrderAndPay}
            backgroundColor={colors.primaryActionBackgroundColor}
            style={styles.createOrderButton}
            firstColor={colors.linearGradientGreenFirst}
            secondColor={colors.linearGradientGreenSecond}
            Loading={isLoaderVisible}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    appInstalled: state.UserAccountInfo.appInstalled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      saveUpiDetails,
      saveIntentDetails,
      validateUpi,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(UpiPayment));
