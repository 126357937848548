import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import FastImageView from '../../components/FastImageView';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import ErrorBoundaryComponent from '../../components/shared/ErrorBoundaryComponent';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class LoyaltyPlanBenefitsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
    };
    this.deserializeItemData();
  }

  deserializeItemData = () => {
    const {
      itemData = {},
      index,
      currentLoyaltyBenefits = {},
      setCurrentPlanBenefits = () => {},
      selectedPlanIndex,
    } = this.props;
    new JSONAPIDeserializer({
      typeAsAttribute: false,
      pluralizeType: true,
      keyForAttribute: 'camelCase',
    })
      .deserialize(itemData)
      .then((data) => {
        this.setState({ itemData: data });
        const { groupedLoyaltyBenefits = [] } = data;
        if (index === selectedPlanIndex && Utility.isBlank(currentLoyaltyBenefits)) {
          setCurrentPlanBenefits(groupedLoyaltyBenefits);
        }
      });
  };

  toolTopArrow = ({ visible }) => {
    if (!visible) return null;
    return (
      <View style={styles.toolTipArrowContainer}>
        <FastImageView
          style={styles.toolTipArrowImage}
          source={images.edge.tooltip}
        />
      </View>
    );
  };

  render() {
    const {
      selectedPlanIndex,
      onPlanPress = () => {},
      index = 0,
      previousScreen = '',
      listData = {},
    } = this.props;
    const { itemData = {} } = this.state;
    if (Utility.isBlank(itemData)) return null;
    const isSelected = index === selectedPlanIndex;
    const backgroundColor = isSelected ? '#FFEFE1' : '#f4f6f8';
    const containerStyle = [styles.planContainer, { backgroundColor }];
    const {
      displayName = '',
      shortDescription = '',
      ribbonText = '',
      groupedLoyaltyBenefits = [],
    } = itemData;
    return (
      <ErrorBoundaryComponent
        itemData={itemData}
        listData={listData}
        screenName={previousScreen}
      >
        <TouchableOpacity
          style={containerStyle}
          onPress={() => onPlanPress(index, groupedLoyaltyBenefits)}
        >
          <Text style={styles.planTitle}>{displayName}</Text>
          <Text style={styles.planTag}>{shortDescription}</Text>
          <Text style={styles.planTargetUserText}>{ribbonText}</Text>
        </TouchableOpacity>
        <this.toolTopArrow visible={isSelected} />
      </ErrorBoundaryComponent>
    );
  }
}

export default LoyaltyPlanBenefitsCard;

const styles = StyleSheet.create({
  planContainer: {
    width: (Utility.getScreenWidth() - 12 * 4) / 3,
    height: 140,
    borderRadius: 16,
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 4,
    justifyContent: 'space-between',
  },
  planTitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    marginTop: 16,
  },
  planTag: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#AA5817',
    textAlign: 'center',
    marginTop: 16,
  },
  planTargetUserText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.silver,
    textAlign: 'center',
    marginTop: 12,
  },
  toolTipArrowContainer: { justifyContent: 'center', alignItems: 'center' },
  toolTipArrowImage: {
    height: 22,
    width: 40,
  },
});
