import {
  Alert,
  Linking,
  NativeEventEmitter,
  NativeModules,
  PermissionsAndroid,
  Platform,
} from 'react-native';
import Permissions from '../libraries/ReactNativePermissions';
import AnalyticsManager from '../analytics/AnalyticsManager';
import EventType from '../analytics/AnalyticsEventType';
import EventParameterKey from '../analytics/EventParameterKey';
import EventParameterValue from '../analytics/EventParameterValue';
import { isAndroid, isBlank, isIOS, isPresent, isWeb } from './BooleanUtility';
import { savePermissionState } from '../actions/ActionTypes';
import DeviceInfoUtils from './DeviceInfoUtils';
import {
  PERMISSION_CONSTANTS,
  PERMISSION_STATUS,
  PERMISSION_STORE,
} from '../config/Constants';
import { triggerPermissionsEvent } from '../analytics/PermissionsEvents';
import { createAndroidNotificationChannels } from './AndroidNotificationsUtils';
import { getStoreRef } from '../store/StoreUtility';
import { ReactMoE } from '../libraries/ReactMoe';

export default class PermissionsUtility {
  static notificationSource = 'app_start';

  static SHOW_NOTIFICATION_PROMPT_FOR_CURRENT_SESSION = {
    wishlist_page: true,
    order_completion: true,
    notify_me: true,
    product_wishlisting: true,
    wallet_page: true,
    routine_page: true,
  };

  static openSettingsAlert = (
    alertTitle = '',
    alertDescription = '',
    onCancelAlert = () => {},
  ) => {
    Alert.alert(alertTitle, alertDescription, [
      {
        text: 'Open Settings',
        onPress: Linking.openSettings,
      },
      {
        text: 'Cancel',
        onPress: onCancelAlert,
        style: 'cancel',
      },
    ]);
  };

  static notificationManagerIOS = new NativeEventEmitter(
    NativeModules.LocalNotificationHandler,
  );

  static requestPermissionAndOpenSettings = async (
    type = '',
    alertTitle = '',
    alertDescription = '',
    onCancelAlert = () => {},
  ) => {
    try {
      const response = await Permissions.request(type);
      const isPermissionAllowed = response === 'authorized';
      if ((isIOS() && response === 'denied') || response === 'restricted') {
        this.openSettingsAlert(alertTitle, alertDescription, onCancelAlert);
      }
      PermissionsUtility.logPermissionEvent(isPermissionAllowed, {
        type,
      });
      return isPermissionAllowed;
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `PermissionsUtility.js, type: ${type}. Error:  ${error}`,
        },
      );
      return false;
    }
  };

  static osBasedPermissionName = (permissionName) => {
    if (isAndroid()) {
      return permissionName.android;
    }
    return permissionName.ios;
  };

  static checkPermissionStatic(
    permissionName,
    requiredInIOS,
    callback,
    meta = {},
  ) {
    const permissionTag =
      PermissionsUtility.osBasedPermissionName(permissionName);
    if (isAndroid()) {
      PermissionsAndroid.request(permissionTag)
        .then((data) => {
          if (data === 'granted') {
            PermissionsUtility.logPermissionEvent(true, meta);
          } else {
            PermissionsUtility.logPermissionEvent(false, meta);
          }
          callback(data);
        })
        .catch(() => {
          callback(false);
        });
    }
    if (requiredInIOS) {
      Permissions.request(permissionTag)
        .then((data) => {
          if (data === 'authorized') {
            PermissionsUtility.logPermissionEvent(true, meta);
          } else {
            PermissionsUtility.logPermissionEvent(false, meta);
          }
          callback(data);
        })
        .catch((err) => callback(false));
    }
  }

  static createPermissionMetaObject = (screen_name, permission_name) => {
    return { screen_name, permission_name };
  };

  static checkIOSPermissionStatus = (callback = () => {}) => {
    const state = getStoreRef().getState();
    const { permissionStatus = {} } = state;
    const { notification = '' } = permissionStatus;

    getIOSNotificationPermission('app_start', (permission) => {
      let status = 'not_asked';
      if (
        isBlank(notification) &&
        permission === 'NotificationNotDetermined'
      ) {
        status = 'not_asked';
        // CleverTapAnalytics.subscribeToNotifications(false);
      } else if (permission === 'NotificationProvisional') {
        status = 'provisional';
        // CleverTapAnalytics.subscribeToNotifications(true);
      } else if (permission === 'NotificationDenied') {
        status = 'never_ask_again';
        // CleverTapAnalytics.subscribeToNotifications(false);
      } else if (permission === 'NotificationAuthorized') {
        status = 'allowed';
        // CleverTapAnalytics.subscribeToNotifications(true);
      }

      callback(status);

      savePermissionStatus(PERMISSION_CONSTANTS.notification, status, true);
    });
    getLiveActivityPermission((status) => {
      savePermissionStatus(PERMISSION_CONSTANTS.liveActivity, status);
    });
  };

  static permissionRequestCallback = (granted, data, permissionName) => {
    const state = getStoreRef().getState();
    const { permissionStatus = {} } = state;

    let currentStatus = data;
    const prevStatus = permissionStatus['notification'];
    if (isBlank(prevStatus) && data === 'never_ask_again') {
      currentStatus = 'dismissed';
    }

    if (granted) {
      ReactMoE.pushPermissionResponseAndroid(granted);
      // CleverTapAnalytics.subscribeToNotifications(granted);
      createAndroidNotificationChannels();
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_ALLOW,
        permissionName,
        this.notificationSource,
      );
      currentStatus = 'allowed';
    } else {
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_DENY,
        permissionName,
        this.notificationSource,
      );
    }

    savePermissionStatus(PERMISSION_CONSTANTS.notification, currentStatus);
  };

  static checkAndroidNotificationPermission = (callback = () => {}) => {
    const state = getStoreRef().getState();
    const { permissionStatus = {} } = state;
    const currentNotificationStatus = permissionStatus['notification'];

    if (Platform.Version < 33) {
      checkNotificationStatusFromNativeLayer(
        currentNotificationStatus,
        callback,
      );
      return;
    }

    if (
      isPresent(currentNotificationStatus) &&
      currentNotificationStatus !== 'never_ask_again'
    ) {
      /*
       * Handles the case where notification is manually set/unset from settings by user.
       * if notification status is either allowed/denied/not_asked if will check from native layer
       * If notification is never_ask_again, it will go to check permission and perform as it is.
       * This was done also to prevent showing permision modal twice to a user as per requirement.
       */
      checkNotificationStatusFromNativeLayer(
        currentNotificationStatus,
        callback,
      );
      return;
    }

    checkNotificationStatusFromNativeLayer(
      currentNotificationStatus,
      (status) => {
        if (status === 'allowed') {
          // This is a corner case where user turned on notifications before opening app for the first time or
          // before seeing first notification prompt.
          callback('allowed');
        } else {
          if (isBlank(currentNotificationStatus)) {
            // This case occurs when user opens the app for the first time after installing.
            callback('not_asked');
          } else if (status === 'never_ask_again') {
            // This case occurs when user has denied notification permission everytime or turned
            // notifications off from the device settings/
            callback('never_ask_again');
            return;
          }
          // Trigger notification prompt to get notification permission.
          triggerPermissionsEvent(
            EventType.miscAppEvents.PERMISSION_PROMPT,
            EventParameterValue.PERMISSIONS.notifications,
            'app_launch',
          );
          checkPermission(
            PERMISSION_STORE.notification,
            false,
            (granted, data) =>
              this.permissionRequestCallback(
                granted,
                data,
                EventParameterValue.PERMISSIONS.notifications,
              ),
            {},
            false,
          );
        }
      },
    );
  };

  static requestPermission = {
    notification: {
      android: PermissionsUtility.checkAndroidNotificationPermission,
      ios: PermissionsUtility.checkIOSPermissionStatus,
    },
  };

  static askPermissionOnAppStartUp = (permission) => {
    if (isWeb()) return;
    PermissionsUtility.requestPermission[permission][Platform.OS]((status) => {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.NOTIFICATION_STATUS,
        {
          [EventParameterKey.NOTIFICATION_STATUS]: status,
        },
      );
    });
  };

  static shouldShowNotificationModalForCurrentSession(type) {
    return PermissionsUtility.SHOW_NOTIFICATION_PROMPT_FOR_CURRENT_SESSION[
      type
    ];
  }

  static setNotificationModalVisibilityForCurrentSession(type, visibility) {
    PermissionsUtility.SHOW_NOTIFICATION_PROMPT_FOR_CURRENT_SESSION[type] =
      visibility;
  }

  static resetNotificationModalVisibilityForCurrentSession() {
    PermissionsUtility.SHOW_NOTIFICATION_PROMPT_FOR_CURRENT_SESSION = {
      wishlist_page: true,
      order_completion: true,
      notify_me: true,
      product_wishlisting: true,
      wallet_page: true,
      routine_page: true,
    };
  }
}

export const savePermissionStatus = (
  permission,
  status,
  saveToRedux = true,
  sendResponseToServer = true,
) => {
  if (saveToRedux) {
    getStoreRef().dispatch(savePermissionState(permission, status));
  }

  AnalyticsManager.setUserProperty(
    'notification_status',
    PERMISSION_STATUS[status] || 'not_asked',
  );

  if (sendResponseToServer) {
    DeviceInfoUtils.retrieveDeviceInfo();
  }
};

export const getIOSNotificationPermission = (source, callback) => {
  if (isAndroid() || isWeb()) {
    return;
  }
  const event = 'notification_permission';
  const permissionListener = PermissionsUtility.notificationManagerIOS.addListener(
    event,
    ({ action }) => {
      callback(action);
      permissionListener?.remove();
    },
  );

  if (isIOS()) {
    NativeModules.LocalNotificationHandler.checkNotificationPermissions();
  }
};

/**
 * FIXME:
 * 1 - These methods shouldn't be here in Utility. because PermissionUtility is responsible for this action.
 * 2 - Raising and event is also a responsibility of permissionUtility.
 *
 * Moving further, we will remove these  related methods from this class
 */
export const checkPermission = (permissionName, requiredInIOS, callback) => {
  const permissionTag = isAndroid()
    ? permissionName.android
    : permissionName.ios;
  if (isAndroid()) {
    PermissionsAndroid.request(permissionTag)
      .then((data) => {
        if (data === 'granted') {
          callback(true, data);
        } else {
          callback(false, data);
        }
      })
      .catch(() => callback(false, {}));
  }
  if (requiredInIOS && isIOS()) {
    Permissions.request(permissionTag)
      .then((data) => {
        if (data === 'authorized') {
          callback(true, 'authorized');
        } else {
          callback(false, 'denied');
        }
      })
      .catch((err) => callback(false));
  }
};

export const checkNotificationStatusFromNativeLayer = (
  previousStatus,
  callback = () => {},
) => {
  NativeModules.NotificationsChannelModule.notificationEnabledForApp()
    .then((status) => {
      ReactMoE.pushPermissionResponseAndroid(status);
      // CleverTapAnalytics.subscribeToNotifications(status);
      if (status) {
        savePermissionStatus(PERMISSION_CONSTANTS.notification, 'granted');
        callback('allowed');
      } else if (!status && previousStatus === 'granted') {
        savePermissionStatus(
          PERMISSION_CONSTANTS.notification,
          'never_ask_again',
        );
        callback('never_ask_again');
      } else {
        callback(previousStatus);
      }
    })
    .catch((err) => {
      console.tron.log(
        'Exception:checkNotificationStatusFromNativeLayer()',
        err,
      );
    });
};

export const checkAndroidNotificationStatusFromNativeLayer = (
  previousStatus,
  callback = () => {},
) => {
  NativeModules.NotificationsChannelModule.notificationEnabledForApp()
    .then((status) => {
      if (status) {
        callback('allowed');
      } else if (!status && previousStatus === 'granted') {
        callback('never_ask_again');
      } else {
        callback(previousStatus);
      }
    })
    .catch((err) => {
      console.tron.log(
        'Exception:checkNotificationStatusFromNativeLayer()',
        err,
      );
    });
};

export const getLiveActivityPermission = (callback) => {
  if (isAndroid()) {
    callback(false);
  } else {
    NativeModules.LocalNotificationHandler.getLiveActivityPermission(
      (status) => {
        callback(status);
      },
    );
  }
};

export const getNotificationStatus = (callback) => {
  if (isAndroid()) {
    NativeModules.NotificationsChannelModule.notificationEnabledForApp()
      .then((status) => {
        let notificationStatus = 'never_ask_again';
        if (status) {
          notificationStatus = 'allowed';
        }
        callback(notificationStatus);
      })
      .catch((err) => {
        callback('status_fetch_error');
      });
  } else {
    getIOSNotificationPermission('AppNavigator', (permission) => {
      let status = 'not_asked';
      if (permission === 'NotificationNotDetermined') {
        status = 'not_asked';
      } else if (permission === 'NotificationProvisional') {
        status = 'provisional';
      } else if (permission === 'NotificationDenied') {
        status = 'never_ask_again';
      } else if (permission === 'NotificationAuthorized') {
        status = 'allowed';
      }
      callback(status);
    });
  }
};

