// Dependencies
import _ from 'lodash';
import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text } from 'react-native';
import ScaleAnimate from '../shared/ScaleAnimate';

// Components
import { COLLECTION } from '../../config/LayoutConstants/CollectionConfig';
import CollectionCardStyle from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { fetchTagList } from '../../actions/ActionTypes';
import { MORE_ITEMS, LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import Card from '../../lib/Card';
import {
  AnalyticsUtilty,
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../analytics';
import LinearGradient from 'react-native-linear-gradient';
import FastImageView from '../FastImageView';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { navigateToScreen } from '../../utils/NavigationUtility';
class IngredientCollectionCard extends Component {
  static cardLayoutStyle = {
    vertical: {
      cardElevation: 2,
      cardMaxElevation: 2,
      cornerRadius: 4,
    },
  };

  constructor(props) {
    super(props);
    this.item = this.props.itemData.objects;
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceCollectionCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.x = { x: 0, y: 0 };
    this.y = { x: 0, y: 1 };
  }

  collectionDescription = (props) => {
    const { display, itemData, layout } = props;

    if (Utility.isBlank(itemData)) {
      return null;
    }

    // todo_vishwender: if horizontal, no of lines = 3
    let noOfLines = 2;
    let containerStyle = CollectionCardStyle.colouredCardTextContainer;
    const textColor = !Utility.isBlank(itemData.other_color)
      ? itemData.other_color
      : colors.foxyBlack;
    let textStyle = [
      CollectionCardStyle.colouredCardTextStyle,
      { color: textColor },
    ];
    if (layout === 'rail') {
      containerStyle = CollectionCardStyle.colouredMediumCardTextContainer;
      textStyle = [
        CollectionCardStyle.colouredMediumCardTextStyle,
        { color: textColor },
      ];
    } else if (layout === 'gridRail') {
      containerStyle =
        CollectionCardStyle.colouredCardRectangleSmallTextContainer;
      textStyle = [
        CollectionCardStyle.colouredSmallRectangleCardTextStyle,
        { color: textColor },
      ];
      noOfLines = 3;
    } else if (layout === 'grid' && display === 'rectangularGrid') {
      containerStyle =
        CollectionCardStyle.colouredCardRectangleDefaultSizeTextContainer;
      textStyle = [
        CollectionCardStyle.colouredMediumRectangleCardTextStyle,
        { color: textColor },
      ];
      noOfLines = 3;
    }

    return (
      <View style={containerStyle}>
        <Text numberOfLines={noOfLines} style={textStyle}>
          {_.capitalize(itemData.name)}
        </Text>
      </View>
    );
  };

  SeparatorStyle = (props) => {
    const { layout } = props;
    if (layout === 'list') {
      return <View style={CollectionCardStyle.listSeparatorStyle} />;
    }
    return null;
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      listData: { slug: listSlug = '' } = {},
      index,
      id,
      layout,
      listName,
      listIndex,
      previousScreen,
      search,
      searchQuery,
      elementItemCounts,
      listContent,
    } = this.props;

    //FIXME: this section should be a common function which will accept props, and calculated event meta itself
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;

      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };
      this.debounceCollectionCardClick(meta);
    }
    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.id,
        itemData.content,
        itemData.name,
        index,
        id,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        itemData.slug,
        listSlug,
      );
    }
    let slug = itemData.slug;
    if (slug.charAt(0) !== '/') {
      slug = '/'.concat(slug);
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: MORE_ITEMS,
      params: {
        id: itemData.id,
        displayCount: itemData.objects?.length,
        heading: itemData.name,
        item: itemData,
        navigation,
        title: itemData.name,
        slug: slug,
      },
    });
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  navigateToMedia = () => {
    const { navigation, itemData = {}, fetchTagList } = this.props;
    const index = 0;
    const relatedContentSlug = itemData.slug?.replace(
      '.json',
      '/related-content.json',
    );

    fetchTagList(relatedContentSlug, 0, (success, data) => {
      if (success) {
        navigation.push('ContentModal', {
          listId: null,
          index: 0,
          slug: relatedContentSlug,
          listData: data,
          itemData: data.objects[0],
        });
      }
    });
  };

  getStyles = ({ layout, size = 'defaultSize' }) => {
    const {
      display,
      listData,
      previousScreen,
      itemData: { type },
    } = this.props;
    let layoutSize = size;
    if (Utility.isBlank(layoutSize)) {
      layoutSize = 'defaultSize';
    }

    let cardType = layout;
    if (layout === 'grid' && display === 'rectangularGrid') {
      cardType = 'rectangularGrid';
    } else if (layout === 'gridRail') {
      cardType = 'colouredRectangleRail';
    } else if (layout === 'rail') {
      cardType = 'gridSquareRail';
    } else {
      cardType = 'gridSquareRail';
    }

    const isIngredientCard = layout === LAYOUT.GRID && type === 'ingredient';
    let { viewwidth, viewheight, imagewidth, imageheight } =
      COLLECTION.CARD[cardType][layoutSize];
    if (
      (Utility.isPresent(listData) &&
        previousScreen !== 'more_page' &&
        layout === 'grid' &&
        display !== 'rectangularGrid') ||
      isIngredientCard
    ) {
      const noOfColumns = listData.columns || 2;
      [viewwidth, viewheight, imagewidth, imageheight] =
        Utility.getCardDimentions(noOfColumns, 'collectionCard');
    }

    const cardDimensions = {
      height: viewheight,
      width: viewwidth,
    };
    let imageStyle = {
      height: isIngredientCard ? 120 : imageheight,
      width: imagewidth,
      borderRadius: 4,
    };
    let cardStyle = [
      CollectionCardStyle.colouredCardContainer,
      {
        height: viewheight,
        width: viewwidth,
        overflow: 'hidden',
      },
    ];
    if (display === 'rectangularGrid' || layout === 'gridRail') {
      cardStyle = [
        CollectionCardStyle.colouredCardContainerHorizontal,
        {
          height: viewheight,
          width: viewwidth,
          overflow: 'hidden',
        },
      ];
    }

    return {
      cardStyle,
      imageStyle,
    };
  };

  cardImage = () => {
    const { layout, display, itemData: collectionData = {} } = this.props;
    const { cardStyle, imageStyle } = this.getStyles(this.props);
    let colouredCardImageStyle = CollectionCardStyle.colouredCardImageStyle;

    if (collectionData === undefined) {
      return null;
    }

    if (layout === 'gridRail') {
      colouredCardImageStyle =
        CollectionCardStyle.colouredCardRectangleSmallImageStyle;
    } else if (layout === 'grid' && display === 'rectangularGrid') {
      colouredCardImageStyle =
        CollectionCardStyle.colouredCardRectangleDefaultSizeImageStyle;
    }

    const cardImage = Utility.getMinifiedImage(
      collectionData.icon_image_url,
      imageStyle.height,
      imageStyle.width,
    );
    return (
      <FastImageView
        resizeMode="contain"
        source={cardImage}
        height={imageStyle.height}
        width={imageStyle.width}
        style={colouredCardImageStyle}
      />
    );
  };

  render() {
    const {
      orientation,
      display,
      itemData: collectionData = {},
      layout,
    } = this.props;
    const { cardStyle, imageStyle } = this.getStyles(this.props);
    if (collectionData === undefined) {
      return null;
    }

    const startColor = collectionData?.background_color_start || colors.white;
    const endColor = collectionData?.background_color_end || colors.white;

    const gradientArray = [startColor, endColor];

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <Card
          {...IngredientCollectionCard.cardLayoutStyle[orientation]}
          style={cardStyle}
        >
          <LinearGradient
            colors={gradientArray}
            style={styles.container}
            start={this.x}
            end={this.y}
          />
          <this.cardImage />
          <this.collectionDescription
            itemData={collectionData}
            layout={layout}
            display={display}
          />
        </Card>
        <this.SeparatorStyle layout={layout} />
      </ScaleAnimate>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchTagList }, dispatch),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(IngredientCollectionCard),
);

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
  },
});
