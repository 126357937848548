import { call, put, takeLatest } from 'redux-saga/effects';
import { UPLOAD_CONTACTS, UPLOAD_CONTACTS_V3 } from '../actions/ActionTypes';
import {
  saveAlreadyInvitedContacts,
  saveActiveContacts,
} from '../actions/ContactsActions';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';
import { URL } from '../config/Constants';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import Utility from '../utils/Utility';

const mapContactsForUploading = (contact) => {
  const { displayName: name, phoneNumber: phone_number, email, cc } = contact;
  return {
    name,
    phone_number,
    email,
    cc,
  };
};

function* uploadContactV3(action) {
  const { purpose = '', contactList = [], callback = () => {} } = action;
  const headers = yield getApiHeaders();
  const contactsToUpload = contactList.map(mapContactsForUploading);

  const response = yield fetch(
    `${URL.UPLOAD_PHONE_BOOK_V3}?purpose=${purpose}`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        user: {
          contacts: contactsToUpload,
        },
      }),
    },
  ); //call(sendContacts, contactList);
  if (response.status >= 200 && response.status < 300) {
    try {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      yield put(saveActiveContacts(json.data));
      callback(true, json);
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `UploadContactsSaga.js:uploadContacts() ${error}`,
        },
      );
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}

function* uploadContact(action) {
  const { contactList = [], callback = () => {} } = action;
  const headers = yield getApiHeaders();
  const contactsToUpload = contactList.map(mapContactsForUploading);
  const sendContacts = (contactList) => {
    const response = fetch(URL.UPLOAD_PHONE_BOOK, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        user: {
          contacts: contactsToUpload,
        },
      }),
    });
    return response;
  };

  const response = yield call(sendContacts, contactList);
  if (response.status >= 200 && response.status < 300) {
    try {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      yield put(saveAlreadyInvitedContacts(json.data));
      callback(true, json);
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `UploadContactsSaga.js:uploadContacts() ${error}`,
        },
      );
      callback(false, {});
    }
  } else {
    callback(false, {});
  }
}
export default function* watchUploadContactSaga() {
  yield takeLatest(UPLOAD_CONTACTS, uploadContact);
  yield takeLatest(UPLOAD_CONTACTS_V3, uploadContactV3);
}
