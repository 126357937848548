import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { select } from 'redux-saga/effects';
import { ORIENTATION } from '../../config/Constants';
import { fetchList } from '../../actions/ActionTypes';
import {
  Grid, Rail, Vertical, VerticalRail,
} from '../../components/layout';
import List from './List';
import Utility from '../../utils/Utility';

class OfflineList extends Component {
  constructor(props) {
    super(props);
  }

  LayoutStyle = {
    list: List,
    grid: Grid,
    vertical: Vertical,
    rail: Rail,
    verticalRail: VerticalRail,
  };

  getArrayFromObject(object, type) {
    return Object.keys(object).map(key => ({
      id: key,
      type,
      data: object[key],
    }));
  }

  componentDidMount() {
    this.createOfflineData();
  }

  replaceAll = (str, mapObj) => {
    const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    return str.replace(re, matched => mapObj[matched.toLowerCase()]);
  };

  createOfflineKeyValue = (item) => {
    let offlineObj = {};
    const mapObj = {
      'of ': ' ',
      'for ': ' ',
      'the ': ' ',
      '-': ' ',
      api: '',
      v1: '',
      '/': '',
      products: ' ',
      brands: ' ',
      artists: ' ',
      videos: ' ',
      '.json': '',
    };
    for (const [key, value] of Object.entries(item)) {
      const name = this.replaceAll(value.name, mapObj).replace(/[0-9]/g, '');
      const slug = this.replaceAll(value.slug, mapObj).replace(/[0-9]/g, '');
      const id = value.id;
      name.split(' ').map((item, index) => {
        const word = _.lowerCase(item);
        offlineObj = Utility.isBlank(offlineObj.word)
          ? { ...offlineObj, [word]: [id] }
          : { ...offlineObj, [offlineObj.word]: [...offlineObj.word, id] };
      });
      return offlineObj;
    }
  };

  createOfflineData = () => {
    const {
      products, artists, brands, videos,
    } = this.props;
    this.createOfflineKeyValue(products);
  };

  createItem = () => {
    const {
      favouriteProducts,
      favouriteVideos,
      followedArtists,
      favouriteBrands,
      favouriteSalons,
      FavouriteList,
      viewedProducts,
      viewedVideos,
      viewedArtists,
      cartItems,
    } = this.props;
    const favVideosArray = this.getArrayFromObject(favouriteVideos, 'video');
    const favProductsArray = this.getArrayFromObject(favouriteProducts, 'product');
    const followedArtistsArray = this.getArrayFromObject(followedArtists, 'artist');
    const favBrandsArray = this.getArrayFromObject(favouriteBrands, 'brand');
    const favSalonsArray = this.getArrayFromObject(favouriteSalons, 'salon');
    const cartItemsArray = this.getArrayFromObject(cartItems, 'product');
    const viewedProductsArray = this.getArrayFromObject(viewedProducts, 'product');
    const viewedVideosArray = this.getArrayFromObject(viewedVideos, 'video');
    let viewedArtistsArray = this.getArrayFromObject(viewedArtists, 'artist');
    viewedArtistsArray = _.sortBy(viewedArtistsArray, obj => obj.data.length);
    viewedArtistsArray = _.slice(viewedArtistsArray, 0, 20);
    this.itemData = {
      type: 'list',
      share_url: 'https://foxy.in/lists/collection-page',
      display_count: 9,
      id: 'collection1',
      objects: [
        Utility.createFavouriteListObject(
          'favourite-videos',
          'rail',
          'media',
          'small',
          favVideosArray.length,
          'Favourite Videos',
          favVideosArray,
        ),
        Utility.createFavouriteListObject(
          'favourite-products',
          'rail',
          'product',
          'defaultSize',
          favProductsArray.length,
          'Favourite Products',
          favProductsArray,
        ),
        Utility.createFavouriteListObject(
          'followed-artist',
          'rail',
          'artist',
          'defaultSize',
          followedArtistsArray.length,
          'Followed Artists',
          followedArtistsArray,
        ),
        Utility.createFavouriteListObject(
          'favourite-brands',
          'rail',
          'brand',
          'defaultSize',
          favBrandsArray.length,
          'Favourite Brands',
          favBrandsArray,
        ),
        Utility.createFavouriteListObject(
          'cart-items',
          'rail',
          'product',
          'defaultSize',
          cartItemsArray.length,
          'Items in your bag',
          cartItemsArray,
        ),
        Utility.createFavouriteListObject(
          'favourite-salons',
          'rail',
          'salon',
          'defaultSize',
          favSalonsArray.length,
          'Favourite Salons',
          favSalonsArray,
        ),
        Utility.createFavouriteListObject(
          'viewed-videos',
          'rail',
          'media',
          'small',
          viewedVideosArray.length,
          'Frequently Viewed Videos',
          viewedVideosArray,
        ),
        Utility.createFavouriteListObject(
          'viewed-products',
          'rail',
          'product',
          'defaultSize',
          viewedProductsArray.length,
          'Frequently Viewed Products',
          viewedProductsArray,
        ),

        Utility.createFavouriteListObject(
          'viewed-artists',
          'rail',
          'artist',
          'small',
          viewedArtistsArray.length,
          'Frequently Viewed Artists',
          viewedArtistsArray,
        ),
      ],
    };
  };

  render() {
    this.createItem();
    const list = this.itemData;
    const display = list.display;
    const displayCount = list.display_count;
    return (
      <Vertical
        id={list.id}
        orientation={ORIENTATION.HORIZONTAL}
        display={display}
        displayCount={displayCount}
        content={list.content}
        item={list.objects}
        size={list.size}
        refreshing={false}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    favouriteProducts: state.UserAccountInfo.favourites.products,
    favouriteVideos: state.UserAccountInfo.favourites.videos,
    followedArtists: state.UserAccountInfo.followedArtists,
    favouriteBrands: state.UserAccountInfo.favourites.brands,
    favouriteSalons: state.UserAccountInfo.favourites.salons,
    favouriteLists: state.UserAccountInfo.favourites.lists,
    cartItems: state.bag.cartItems,
    viewedProducts: state.UserAccountInfo.viewedProducts,
    viewedVideos: state.UserAccountInfo.viewedVideos,
    viewedArtists: state.UserAccountInfo.viewedArtists,
    products: state.data.entities.products,
    artists: state.data.entities.artists,
    videos: state.data.entities.videos,
    brands: state.data.entities.brands,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchList }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OfflineList);
