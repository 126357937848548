export default class Session {
  static isMasterAttributeApiRequestInQueue = false;

  static previousAccountData = {};

  static groupDealIdFromDeeplink = '';

  static initialAppDeeplink = '';

  static setInitialAppDeeplink(value) {
    this.initialAppDeeplink = value;
  }
}
