import { takeLatest } from 'redux-saga/effects';
import {
  GET_STORE_INFORMATION,
  GET_STORE_CART_STATUS,
  UPDATE_STORE_CART_STATUS,
} from '../actions/RetailStoreActions';
import { API_DOMAIN, URL } from '../config/Constants';
import { getApiHeaders } from './GeneratorUtil';

function* getStoreInformation(action) {
  const { slug, callback } = action;
  try {
    const response = yield fetch(`${API_DOMAIN}${slug}`, {
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const jsonResponse = yield response.json();
      callback(true, jsonResponse);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

function* getStoreCartStatus(action) {
  const { slug, callback } = action;
  try {
    const response = yield fetch(
      `${API_DOMAIN}/api/v1/carts/store_cart_status`,
      {
        headers: yield getApiHeaders(),
      },
    );

    if (response.status >= 200 && response.status < 300) {
      const jsonResponse = yield response.json();
      callback(true, jsonResponse);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

function* updateStoreCartStatus(action) {
  const { data, callback } = action;
  try {
    const response = yield fetch(
      `${API_DOMAIN}/api/v1/carts/update_store_cart_status`,
      {
        method: 'POST',
        headers: yield getApiHeaders(),
        body: JSON.stringify(data),
      },
    );

    if (response.status >= 200 && response.status < 300) {
      const jsonResponse = yield response.json();
      callback(true, jsonResponse);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export default function* watchRetailStoreSaga() {
  yield takeLatest(GET_STORE_INFORMATION, getStoreInformation);
  yield takeLatest(GET_STORE_CART_STATUS, getStoreCartStatus);
  yield takeLatest(UPDATE_STORE_CART_STATUS, updateStoreCartStatus);
}
