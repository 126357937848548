import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Rating } from 'react-native-rating-element';
import colors from '../../theme/Colors';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import images from '../../theme/Images';
import { isPresent } from '../../utils/BooleanUtility';
import CertificateButton from './CertificateButton';

const ProductRatingAndMoreInfo = ({
  onRatingTap = () => {},
  rating = 0,
  id,
  sku_id,
  disableRatingText = false,
  ratingSize,
  marginBetweenRatingIcon,
  entityName,
  previousScreen = '',
  data,
  show,
}) => {
  if (rating === 0) {
    return null;
  }
  const size = disableRatingText ? 10 : 20;

  const onIconTap = () => {
    AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
      [EventParameterKey.SKU_ID]: sku_id,
      [EventParameterKey.PRODUCT_ID]: id,
      [EventParameterKey.ELEMENT_NAME]: 'rating',
      [EventParameterKey.RATING]: rating,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: previousScreen,
    });
    onRatingTap();
  };

  return (
    <View
      style={disableRatingText ? styles.disableContainer : styles.container}
    >
      <View style={styles.innerContainer}>
        <Rating
          rated={parseFloat(rating)}
          totalCount={5}
          size={isPresent(ratingSize) ? ratingSize : size}
          onIconTap={onIconTap}
          marginBetweenRatingIcon={
            isPresent(marginBetweenRatingIcon) ? marginBetweenRatingIcon : 3
          }
          direction='row'
          type='custom' // default is always to "icon"
          selectedIconImage={images.filled_star}
          emptyIconImage={images.empty_star}
        />
        {!disableRatingText && <Text style={styles.ratingText}>{rating}</Text>}
      </View>

      {show && <CertificateButton data={data} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingTop: 12,
    paddingBottom: 12,
    paddingHorizontal: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disableContainer: {
    backgroundColor: 'transparent',
    paddingTop: 2,
    paddingHorizontal: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratingText: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    marginLeft: 8,
  },
});

export default React.memo(ProductRatingAndMoreInfo);
