import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { CURRENCY_SYMBOL } from '../../../config/Constants';
import colors from '../../../theme/Colors';

export default function Savings({ discount }) {
  if (discount > 0) {
    return (
      <View style={styles.savingsContainer}>
        <Text style={styles.discountText} numberOfLines={1}>
          Total Savings
        </Text>
        <Text style={styles.discount}>{`${CURRENCY_SYMBOL}${discount}`}</Text>
      </View>
    );
  }
  return null;
}

const styles = StyleSheet.create({
  discountText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    width: '80%',
  },
  discount: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  savingsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.ultraLightGreen,
    marginHorizontal: -8,
    marginTop: -4,
    marginBottom: -8,
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 4,
  },
});
