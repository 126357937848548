import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import { Header } from '../../header';
import { StaticNavigationHeader } from '../../shared';
import PersonalisedOffersRail from './PersonalisedOffersRail';

export default class PersonalisedOffers extends PureComponent {
  render() {
    return (
      <View style={styles.wrapper}>
        <StaticNavigationHeader title='Your Offers & Rewards' />
        <ScrollView>
          <PersonalisedOffersRail {...this.props} previousScreen='rewards' />
          <View style={styles.emptyView} />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },

  emptyView: {
    height: 50,
  },
});
