// Dependencies
import React, { Component } from 'react';
import { View, ImageBackground, Text } from 'react-native';
import Card from '../../lib/Card';
// Components
import SalonCardStyles from './styles';
import LikeButton from '../shared/LikeButton';
import { ORIENTATION, LAYOUT } from '../../config/Constants';
import { SALON } from '../../config/LayoutConstants/SalonConfig';
import ScaleAnimate from '../shared/ScaleAnimate';

class SalonCard extends Component {
  static salonLayoutStyle = {
    vertical: {
      cardElevation: 2,
      cardMaxElevation: 2,
      cornerRadius: 4,
    },
    horizontal: {
      cardElevation: 0,
      cardMaxElevation: 0,
      cornerRadius: 0,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      liked: false,
    };
  }

  getStyles = ({ layout, size = 'defaultSize', orientation = ORIENTATION.HORIZONTAL }) => {
    const styles = SalonCardStyles;
    const {
      viewwidth, viewheight, imagewidth, imageheight,
    } = SALON.CARD[layout][size] || SALON.CARD[layout].defaultSize;
    const separatorStyle = styles[`${layout}SeparatorStyle`];
    const cardDimensions = {
      height: viewheight,
      width: viewwidth,
    };
    const imageBackgroundStyle = [
      styles[`${layout}ImageBackgroundStyle`],
      {
        width: imagewidth,
        height: imageheight,
      },
    ];
    const cardStyle = [cardDimensions, styles[`${orientation}CardStyle`]];
    return {
      cardStyle,
      imageBackgroundStyle,
      separatorStyle,
    };
  };

  toggleLike = () => {
    this.setState(prevState => ({ liked: !prevState.liked }));
  };

  // Functional component
  SalonImage = (props) => {
    const { imageUrl, imageBackgroundStyle } = props;
    return (
      <View style={SalonCardStyles.salonImageContainer}>
        <ImageBackground
          source={{
            uri: imageUrl,
          }}
          resizeMode="cover"
          style={imageBackgroundStyle}
        />
      </View>
    );
  };

  SalonDescription = (props) => {
    const styles = SalonCardStyles;
    return (
      <View style={styles.salonDescStyle}>
        <Text style={SalonCardStyles.salonName}>{props.name}</Text>
        <Text style={SalonCardStyles.salonAddress}>{props.shortAddress}</Text>
      </View>
    );
  };

  conditionalRenderFn = (x) => x === LAYOUT.RAIL || x === LAYOUT.GRID;

  render() {
    const { orientation = ORIENTATION.HORIZONTAL, showDescription } = this.props;
    const { cardStyle, imageBackgroundStyle, separatorStyle } = this.getStyles(this.props);
    const { itemData: salonData } = this.props;
    if (salonData === undefined) {
      return null;
    }
    return (
      <ScaleAnimate>
        <Card {...SalonCard.salonLayoutStyle[orientation]} style={cardStyle}>
          <this.SalonImage
            imageUrl={salonData.image_url}
            imageBackgroundStyle={imageBackgroundStyle}
          />
          {showDescription ? (
            <>
              <this.SalonDescription name={salonData.name} shortAddress={salonData.short_address} />
              <View style={SalonCardStyles.likeButtonStyle}>
                <LikeButton
                  id={salonData.id}
                  type={salonData.type}
                  liked={this.state.liked}
                  slug={salonData.slug}
                  toggleLike={this.toggleLike}
                  layout={LAYOUT.LIST}
                  conditionalRenderFn={this.conditionalRenderFn}
                />
              </View>
            </>
          ) : null}
        </Card>
        <View style={separatorStyle} />
      </ScaleAnimate>
    );
  }
}

export default SalonCard;
