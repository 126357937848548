export const GET_PRODUCT_RATINGS = 'GET_PRODUCT_RATINGS';
export const FETCH_PRODUCT_FOR_REVIEW = 'FETCH_PRODUCT_FOR_REVIEW';

export function getProductRatings(metaData, callback) {
  return {
    type: GET_PRODUCT_RATINGS,
    metaData,
    callback,
  };
}

export function fetchProductForReview(url, lookback_days, callback = () => {}) {
  return {
    type: FETCH_PRODUCT_FOR_REVIEW,
    url,
    lookback_days,
    callback,
  };
}
