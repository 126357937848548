import {
  Image,
  LayoutAnimation,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
  UIManager,
} from 'react-native';
import React from 'react';
import { CURRENCY_SYMBOL } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
import images from '../../../theme/Images';
import colors from '../../../theme/Colors';

if (
  Platform.OS === 'android'
  && UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export default function PaymentDetailsHeader({
  showDetails,
  setShowDetails,
  orderName,
  totalAmount,
}) {
  const toggleShowDetails = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setShowDetails((prevShowDetails) => !prevShowDetails);
  };
  return (
    <View style={styles.paymentDetailsHeaderContainer}>
      <View style={styles.paymentDetailsHeaderInnerContainer}>
        <Text style={styles.orderId}>{`Order ${orderName}`}</Text>
        <Text style={styles.deliveryDate}>{}</Text>
      </View>
      <View style={styles.bottomContainer}>
        <Text style={styles.amount}>{`${CURRENCY_SYMBOL}${totalAmount}`}</Text>
        <TouchableOpacity
          onPress={toggleShowDetails}
          style={styles.viewDetailsContainer}
          hitSlop={Utility.getHitSlop('likeButton')}
        >
          <Text style={styles.viewDetails}>View Details</Text>
          <Image
            source={showDetails ? images.chevronUp : images.chevronDown}
            style={styles.chevron}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  paymentDetailsHeaderContainer: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    paddingVertical: 12,
  },
  paymentDetailsHeaderInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orderId: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  deliveryDate: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
  },
  amount: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.subtitle,
  },
  viewDetailsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewDetails: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
  },
  chevron: {
    height: 12,
    width: 12,
    resizeMode: 'contain',
    tintColor: colors.cta.lightBlue,
    marginLeft: 8,
  },
});
