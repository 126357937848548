import React from 'react';

const UploadButtonWeb = ({ fileInputRef, handleFileChange }) => {
  return (
    <input
      type='file'
      ref={fileInputRef}
      id='fileInput'
      style={{ display: 'none' }}
      onChange={handleFileChange}
      accept='image/*'
      multiple // Allow multiple file selection
    />
  );
};

export default UploadButtonWeb;
