import React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  View,
  TouchableWithoutFeedback,
  SafeAreaView,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';

const ModalContainer = ({ children }) => {
  const navigation = useNavigation();
  const goBack = () => {
    navigation.goBack();
  };

  return (
    <>
      <TouchableWithoutFeedback onPress={goBack}>
        <View style={styles.touchableContainer} />
      </TouchableWithoutFeedback>
      <View style={styles.modalContainer} dataSet={{ media: ids.modalContainer }} pointerEvents='box-none'>
        <SafeAreaView style={styles.SafeAreaView}>{children}</SafeAreaView>
      </View>
    </>
  );
};

export default ModalContainer;

const { ids, styles } = StyleSheet.create({
  touchableContainer: {
    height: '100%',
    width: '100%',
    ...Platform.select({
      web: { backgroundColor: colors.translucent },
      default: {},
    }),
  },
  modalContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.translucent,
    left: 0,
    right: 0,
    '@media (min-width: 768px)': {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});
