import React, { Component } from 'react';
import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Image,
} from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import { StaticNavigationHeader } from '.';

class ServerError extends Component {
  constructor() {
    super();
    this.state = {
      isError: false,
      retrying: false,
    };
  }

  componentDidMount() {
    const { goToHome = false, slug } = this.props;
    // if (goToHome) {
    //   Utility.setPageLoadStart('page_load_2', 0, '', false, {
    //     status: 'page_not_found',
    //     slug,
    //   });
    // }
  }

  onResetState = () => {
    this.setState({
      isError: false,
    });
  };

  onRetryPress = () => {
    const { slug, onPress = () => {} } = this.props;
    Utility.pageLoadStart = Date.now();
    AnalyticsManager.logEvent(EventType.ERROR_EVENTS.RETRY, {
      [EventParameterKey.NAME]: EventParameterValue.ERROR_BUTTON_ACTION.RETRY,
      [EventParameterKey.PAGE]: EventParameterValue.ERROR_PAGE.PAGE_NOT_FOUND,
      slug,
    });
    onPress();
  };

  _button = () => {
    const { retrying } = this.state;
    return (
      <TouchableOpacity onPress={this.onRetryPress}>
        <View style={style.button}>
          {!retrying ? (
            <Text style={style.buttonText}>Retry</Text>
          ) : (
            Utility.getActivityIndicatorSmall()
          )}
        </View>
      </TouchableOpacity>
    );
  };

  onGotoHomePress = () => {
    const { navigation, slug } = this.props;
    Utility.pageLoadStart = Date.now();
    AnalyticsManager.logEvent(EventType.ERROR_EVENTS.GO_TO_HOME, {
      [EventParameterKey.NAME]: EventParameterValue.ERROR_BUTTON_ACTION.RETRY,
      [EventParameterKey.PAGE]: EventParameterValue.ERROR_PAGE.PAGE_NOT_FOUND,
      slug,
    });
    navigation.navigate('Feed');
  };

  _buttonGoToHome = () => {
    const { retrying } = this.state;
    return (
      <TouchableOpacity onPress={this.onGotoHomePress}>
        <View style={style.button}>
          {!retrying ? (
            <Text style={style.buttonText}>Go to Home</Text>
          ) : (
            Utility.getActivityIndicatorSmall()
          )}
        </View>
      </TouchableOpacity>
    );
  };

  _fallbackComponent = () => {
    const { goToHome = false, hideHeader = false } = this.props;
    return (
      <>
        {!hideHeader && <StaticNavigationHeader title='' />}
        <SafeAreaView
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              padding: 35,
            }}
          >
            <Image
              source={images.fourofour}
              style={{ width: 320, height: 220, resizeMode: 'contain' }}
            />
            <Text style={style.heading}>Not found</Text>
            <Text style={style.subHeading}>
              Sorry, the page you are looking for does not exist
            </Text>

            {!goToHome ? <this._button /> : <this._buttonGoToHome />}
          </View>
        </SafeAreaView>
      </>
    );
  };

  render() {
    return <this._fallbackComponent />;
  }
}

const style = StyleSheet.create({
  heading: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    color: colors.foxyBlack,
    marginBottom: 8,
    marginTop: 5,
  },
  subHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    textAlign: 'center',
    color: colors.subtitle,
  },
  button: {
    height: 46,
    marginTop: 30,
    width: 180,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: colors.darkBorder,
  },
  buttonText: {
    fontSize: size.h2,
    color: colors.foxyBlack,
    textAlign: 'center',
    fontFamily: 'Roboto-Medium',
  },
});

export default withNavigation(ServerError);
