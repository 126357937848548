export const GET_PRODUCT_PERSONALIZED_ELEMENTS =
  'GET_PRODUCT_PERSONALIZED_ELEMENTS';

export function getProductPersonalizedDetails(slug, callback) {
  return {
    type: GET_PRODUCT_PERSONALIZED_ELEMENTS,
    slug,
    callback,
  };
}
