// Dependencies
import React, { PureComponent } from 'react';
import {
  View,
  FlatList,
  Text,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from 'react-native';
import PropTypes from 'prop-types';
import { max } from 'lodash';
import { LAYOUT, ORIENTATION } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
// Components
import LAYOUT_CONFIG from '../../../config/LayoutConstants/LayoutConfig';
import GridStyles from './SimpleGridStyles';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import Images from '../../../theme/Images';
import colors from '../../../theme/Colors';
import { getCardHeight, getGridComponent } from './SimpleGridHelper';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { isDesktop } from '../../../utils/BooleanUtility';

class SimpleGrid extends PureComponent {
  static getComponentHeight({
    objects = [],
    content,
    size,
    freeProductScreen,
    columns,
    display_count: displayCount,
  }) {
    const cardCount = objects.length;
    if (cardCount === 0) return 0;
    const cardType = objects[0].type;
    const rowCount =
      displayCount < cardCount ? displayCount / columns : cardCount / columns;
    return (
      getCardHeight(cardType, content, size, freeProductScreen, columns) *
      rowCount
    );
  }

  constructor(props) {
    super(props);
    const { item, content, size, freeProductScreen, listData, displayCount } =
      props;
    this.displayCount = max([LAYOUT_CONFIG.minListCount, displayCount || 0]);
    this.itemData = item.filter((item) => !!item);
    this.column = LAYOUT_CONFIG.numColumn[content] || 2;
    this.viewedItemsIds = [];
    this.state = {
      hideActivityIndicator: false,
    };
    this.ContainerComponent = getGridComponent(
      this.itemData[0]?.type,
      content,
      size,
      freeProductScreen,
    );
    this.cardHeight = getCardHeight(
      this.itemData[0]?.type,
      content,
      size,
      freeProductScreen,
      this.column,
    );
  }

  getComponent = ({ item, index }) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const {
      type,
      size,
      id,
      content,
      navigation,
      toggleCartVisibility,
      listName,
      listIndex,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' } = {},
      refreshOfferStrip,
      maxFreeItemsToSelect,
      showToast,
      fromPersonalisedCard,
      updateSelectedItem,
      selectedItem,
      viewFreeProductScreen,
      listContent,
      onPress,
      searchQuery,
      elementItemCounts,
      freeProductScreen,
      search,
      display,
      paginateLastItem,
      unLike,
      showEditButton,
      removeFromList,
      foxyPoints,
      force_apply_coupon,
      offer_id,
      extraEventParameters = {},
      offersData,
    } = this.props;
    if (content === 'sku' || content === 'product') {
      this.ContainerComponent = getGridComponent(
        item.type,
        content,
        size,
        freeProductScreen,
      );
    }

    if (item === undefined || this.ContainerComponent === undefined) {
      return null;
    }

    const ContainerComponent = this.ContainerComponent;
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <ContainerComponent
          id={item.id}
          layout={LAYOUT.GRID}
          itemData={item}
          listName={listName}
          listContent={listContent}
          listSlug={listSlug}
          type={item.type}
          size={size}
          listIndex={listIndex}
          navigation={navigation}
          orientation={ORIENTATION.VERTICAL}
          listId={id}
          index={index}
          toggleCartVisibility={toggleCartVisibility}
          previousScreen={previousScreen}
          listData={listData}
          refreshOfferStrip={refreshOfferStrip}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          personalisedGrid={false}
          onPress={onPress}
          search={search}
          searchQuery={searchQuery}
          elementItemCounts={elementItemCounts}
          content={content}
          updateSelectedItem={updateSelectedItem}
          selectedItem={selectedItem}
          viewFreeProductScreen={viewFreeProductScreen}
          freeProductScreen={freeProductScreen}
          display={display}
          hideActivityIndicator={this.hideActivityIndicator}
          paginateLastItem={paginateLastItem}
          showEditButton={showEditButton}
          removeFromList={removeFromList}
          foxyPoints={foxyPoints}
          force_apply_coupon={force_apply_coupon}
          offer_id={offer_id}
          extraEventParameters={extraEventParameters}
          offersData={offersData}
        />
      </ErrorBoundaryComponent>
    );
  };

  refetchFeed = () => {
    const { refetchFeed } = this.props;
    refetchFeed();
  };

  renderFooter = () => {
    // it will show indicator at the bottom of the list when data is loading otherwise it returns null
    const { loadMore = false, feedLoadFailed, previousScreen } = this.props;

    const { hideActivityIndicator } = this.state;

    if (
      previousScreen !== SCREEN_CONSTANTS.OFFER_DETAIL ||
      previousScreen === 'search'
    ) {
      return null;
    }

    if (feedLoadFailed) {
      return (
        <View style={GridStyles.feedFooterContainer}>
          <TouchableOpacity onPress={() => this.refetchFeed()}>
            <View style={GridStyles.footerTextAndImageContainer}>
              <Text style={GridStyles.footerButtonText}>Load More</Text>
              <Image
                style={GridStyles.footerChevronImage}
                source={Images.chevronDown}
              />
            </View>
          </TouchableOpacity>
        </View>
      );
    }

    if (loadMore) {
      return (
        <ActivityIndicator
          size='large'
          style={GridStyles.footerActivityIndicator}
          color={colors.green}
        />
      );
    }

    return <View style={GridStyles.emptyFooterView} />;
  };

  hideActivityIndicator = () => {
    this.setState({ hideActivityIndicator: true });
  };

  keyExtractor = (item) => `${item.id}_grid`;

  listKey = (item, index) => `SimpleGrid$${item.id}`;

  getItemLayout = (item, index) => {
    return { length: this.cardHeight, offset: this.cardHeight * index, index };
  };

  onEndReached = () => {
    const { endReached = () => {} } = this.props;
    endReached();
  };

  render() {
    const {
      previousScreen,
      listData,
      paginationResult,
      isSearchResultScreen,
      productAndBrandsOnlySwitchValue,
      content,
      search,
      filtersEnabled,
      onPressResetButton,
      verticalListHeader,
      showEditButton,
      editableList,
    } = this.props;

    if (Utility.isBlank(this.itemData) && Utility.isPresent(listData.objects)) {
      this.itemData = listData.objects;
    }

    if (Utility.isBlank(this.itemData)) {
      return null;
    }

    this.displayCount = max([
      LAYOUT_CONFIG.minGridCount,
      this.props.displayCount || 0,
    ]);
    this.itemData = this.props.item.filter((item) => !!item);
    if (this.displayCount > this.itemData.length) {
      this.displayCount = this.itemData.length;
    }

    if (
      previousScreen === SCREEN_CONSTANTS.SEARCH &&
      listData.content === 'product' &&
      !Utility.isBlank(paginationResult)
    ) {
      this.itemData = this.itemData.concat(Object.values(paginationResult));
    }

    if (
      previousScreen === SCREEN_CONSTANTS.SEARCH &&
      listData.content !== 'product' &&
      productAndBrandsOnlySwitchValue
    ) {
      this.displayCount = 2;
    }

    if (
      isSearchResultScreen &&
      productAndBrandsOnlySwitchValue &&
      listData.content === 'product'
    ) {
      this.displayCount = this.itemData.length;
    }

    const columns =
      previousScreen !== SCREEN_CONSTANTS.MORE_PAGE &&
      !Utility.isBlank(listData) &&
      !Utility.isBlank(listData.display) &&
      listData.display === 'grid' &&
      Utility.isPresent(listData.columns)
        ? listData.columns
        : this.column;

    const data =
      previousScreen === SCREEN_CONSTANTS.MORE_PAGE
        ? this.itemData
        : this.itemData.slice(0, this.displayCount);

    const dataToRender = showEditButton ? editableList : data;
    const getItemLayoutFn = search ? undefined : this.getItemLayout;

    return (
      <FlatList
        data={dataToRender}
        numColumns={isDesktop() ? columns * 2 : columns}
        scrollEnabled
        extraData={this.props}
        showsVerticalScrollIndicator={false}
        extraData={this.state}
        keyExtractor={this.keyExtractor}
        listKey={this.listKey} // added to prevent virtuaized list crash
        renderItem={this.getComponent}
        style={GridStyles.flatlistStyle}
        initialNumToRender={8}
        maxToRenderPerBatch={30}
        ListFooterComponent={this.renderFooter}
        ListEmptyComponent={this.emptyState}
        ListHeaderComponent={verticalListHeader}
        getItemLayout={getItemLayoutFn}
        onEndReached={this.onEndReached}
      />
    );
  }
}

SimpleGrid.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
    size: PropTypes.string,
  }),
  item: PropTypes.array,
  content: PropTypes.string,
};

export default SimpleGrid;
