import {
  View,
  TouchableOpacity,
  Linking,
  Image,
  StyleSheet,
} from 'react-native';
import React from 'react';
import { EXTERNAL_LINKS, FOXY_URLS } from '../../config/Constants';
import images from '../../theme/Images';
import { AnalyticsManager, EventType } from '../../analytics';
import { isWebAndroid, isWebIOS } from '../../utils/BooleanUtility';

export default function Buttons() {
  const onAppStorePress = () => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.GET_APP_CLICK,
      { location: location.pathname },
    );
    if (isWebIOS()) {
      Linking.openURL(FOXY_URLS.dynamicAppLink);
    } else {
      Linking.openURL(EXTERNAL_LINKS.appleStore);
    }
  };

  const onPlayStorePress = () => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.GET_APP_CLICK,
      { location: location.pathname },
    );
    if (isWebAndroid()) {
      Linking.openURL(FOXY_URLS.dynamicAppLink);
    } else {
      Linking.openURL(EXTERNAL_LINKS.googlePaly);
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={onAppStorePress}
        style={styles.leftButton}
      >
        <Image
          source={{ uri: images.downloadAppStore }}
          style={styles.image}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={onPlayStorePress}>
        <Image
          source={{ uri: images.downloadPlayStore }}
          style={styles.image}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: '10%',
    flexDirection: 'row',
  },
  leftButton: {
    marginRight: 5,
  },
  image: {
    width: '18vw',
    height: 44.69,
  },
});
