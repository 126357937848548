import { Dimensions, StatusBar } from 'react-native';
import Utility from '../../utils/Utility';

export const ADD_PRODUCT = {
  height: {
    screenHeight: Dimensions.get('screen').height,
    topInset: Utility.topInset,
    bottomInset: Utility.bottomInset,
    statusBar: StatusBar.currentHeight,
    bottomNavBar: Utility.bottomNavBarHeightAndroid,
    topSectionInset: 40,
    bottomSectionInset: 72,
    get topSection() {
      return 50 + this.statusBar;
    },
    bottomSection: 72,
  },
  width: {
    screenWidth: Dimensions.get('screen').width,
  },
};

export const ADD_PRODUCT_BOTTOM_SECTION = {
  height: {
    buttonsHeight: 40,
  },
  width: {
    rightSection: 84,
    get leftSection() {
      return ADD_PRODUCT.width.screenWidth - (this.rightSection + 40);
    },
  },
};

export const SEARCH_PRODUCT_MODAL = {
  height: {
    container: 580,
    topBarContainer: 24,
    searchBoxContainer: 40,
    taggedProductsContainerInset: 56,
    get productsListContainer() {
      return (
        this.container
        - (this.topBarContainer + this.searchBoxContainer + this.taggedProductsContainer)
      );
    },
  },
};

export const SEARCH_PRODUCT_MODAL_TAGGED_PRODUCTS_CONTAINER = {
  height: {
    buttonsHeight: 40,
  },
  width: {
    rightSection: 84,
    get leftSection() {
      return ADD_PRODUCT.width.screenWidth - (this.rightSection + 40);
    },
  },
};
