import React, { PureComponent } from 'react';
import {
  StyleSheet,
  Animated,
  TouchableOpacity,
  Text,
  View,
} from 'react-native';
import colors from '../../theme/Colors';
import images from '../../theme/Images';

const STAR_IMAGE = images.defaultStar;
const STAR_SELECTED_IMAGE = images.rating.filled_star;
const STAR_SIZE = 40;

export default class Star extends PureComponent {
  rateTextHeader = {
    1: 'HATED IT',
    2: 'IT WAS BAD',
    3: 'COULD BE BETTER',
    4: 'LIKED IT',
    5: 'LOVED IT',
  };

  static defaultProps = {
    selectedColor: '#f1c40f',
  };

  constructor() {
    super();
    this.springValue = new Animated.Value(1);

    this.state = {
      selected: false,
    };
  }

  spring() {
    const { position, starSelectedInPosition } = this.props;

    this.springValue.setValue(1.2);

    Animated.spring(this.springValue, {
      toValue: 1,
      friction: 1,
      tension: 1,
    }).start();

    this.setState({ selected: !this.state.selected });
    starSelectedInPosition(position);
  }

  onHover() {
    const { position, onHover: hover } = this.props;

    this.springValue.setValue(1.2);

    Animated.spring(this.springValue, {
      toValue: 1,
      friction: 1,
      tension: 1,
    }).start();

    this.setState({ selected: true });
    hover(position);
  }

  render() {
    const {
      fill,
      size,
      selectedColor,
      isDisabled,
      position,
      selectedRating,
      selectedStarPosition,
    } = this.props;

    let starSource = fill ? STAR_SELECTED_IMAGE : STAR_IMAGE;

    if (selectedStarPosition === position) {
      starSource = selectedRating;
    }
    return (
      <TouchableOpacity
        activeOpacity={1}
        onPress={this.spring.bind(this)}
        onMouseEnter={this.onHover.bind(this)}
        disabled={isDisabled}
      >
        <Animated.Image
          source={starSource}
          style={[
            styles.starStyle,
            {
              width: size || STAR_SIZE,
              height: size || STAR_SIZE,
              transform: [{ scale: this.springValue }],
            },
          ]}
        />
        {selectedStarPosition === position ? (
          <Text
            style={styles.ratingText}
            // numberOfLines={2}
            allowFontScaling={false}
          >
            {this.rateTextHeader[selectedStarPosition]}
          </Text>
        ) : (
          <View style={{ height: 28 }} />
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  starStyle: {
    marginLeft: 12,
    marginRight: 12,
  },
  ratingText: {
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: '400',
    color: colors.foxyBlack,
    textAlign: 'center',
    maxWidth: 48,
    height: 28,
    marginLeft: 8,
    marginTop: 4,
  },
});
