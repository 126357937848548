import { camelCase, capitalize, upperFirst } from 'lodash';
// Components
import Collection from '../../../containers/collection/Collection';
import { Tag } from '../../../containers/tag';
import Salon from '../../../containers/salon';
import { Category } from '../../../containers/category';
import Product from '../../../containers/product';
import UsersAttribute from '../../../containers/UserAttributes/UsersAttribute';
import DefaultSizeProductRail from '../../Product/DefaultSizeProductRail';
import LargeProductRail from '../../Product/LargeProductRail';
import HorizontalProductRail from '../../Product/HorizontalProductRail';
import {
  DefaultSizeVariantRail,
  LargeVariantRail,
  SmallVariantRail,
} from '../../variants';
import { DefaultSizeVideoRail } from '../../media';
import { SmallVideoRail, LargeVideoRail } from '../../media/OtherMediaCards';
import { DefaultSizeArtistRail } from '../../Artist';
import {
  SmallArtistRail,
  LargeArtistRail,
} from '../../Artist/OtherArtistCards';
import DefaultSizeBrandRail from '../../brand/DefaultSizeBrandRail';
import { SmallBrandRail, LargeBrandRail } from '../../brand/OtherBrands';
import IngredientCollectionCard from '../../Collection/IngredientCollectionCard';
import FeatureCard from '../../feature/FeatureCard';
import TallBanner from '../../tall-banner/TallBanner';
import RoutineRailWithDescriptionCard from '../../Routines/RoutineRailWithDescriptionCard';
import { LinkCardRail } from '../../links/LinkCard';
import SlimVariantRail from '../../variants/SlimVariantRail';
import SlimProductRail from '../../Product/SlimProductRail';
import DynamicProductRail from '../../Product/DynamicProductRail';
import DynamicVariantRail from '../../variants/DynamicVariantRail';
import DefaultSizeProductFeaturedRail from '../../Product/DefaultSizeProductFeaturedRail';
import DefaultSizeOfferPromptFancyRail from '../offerPrompt/DefaultSizeOfferPromptFancyRail';
import FancyCard from '../../../containers/FoxyEdge/FancyCard';
import FancyHeroCard from '../../../containers/FoxyEdge/FancyHeroCard';

const Components = {
  salon: Salon,
  product: Product,
  list: Collection,
  product_category: Collection,
  brand: Collection,
  media_category: Category,
  tag: Tag,
  feature: FeatureCard,
  user_attribute_allowed_value: UsersAttribute,
  DefaultSizeProductRail,
  DefaultSizeVariantRail,
  HorizontalProductRail,
  LargeProductRail,
  SmallProductRail: DefaultSizeProductRail,
  LargeVariantRail,
  SmallVariantRail,
  DefaultSizeVideoRail,
  SmallVideoRail,
  LargeVideoRail,
  DefaultSizeArtistRail,
  SmallArtistRail,
  LargeArtistRail,
  DefaultSizeBrandRail,
  SmallBrandRail,
  LargeBrandRail,
  ingredient: IngredientCollectionCard,
  offer: Collection,
  tall_banner: TallBanner,
  routine: RoutineRailWithDescriptionCard,
  pro_link: LinkCardRail,
  SlimVariantRail,
  SlimProductRail,
  DefaultSizeProductFeaturedRail,
  DynamicProductRail,
  DynamicVariantRail,
  DefaultSizeOfferPromptFancyRail,
};

export const getRailComponent = (
  cardType,
  content,
  size,
  display,
  noOfListItems,
) => {
  let CardContainer = Components[content];
  if (
    content === 'product' ||
    content === 'sku' ||
    content === 'media' ||
    content === 'artist' ||
    content === 'brand' ||
    content === 'offer_prompt'
  ) {
    if (display === 'fancyRail' && ['product', 'sku'].includes(content)) {
      CardContainer = noOfListItems > 1 ? FancyCard : FancyHeroCard;
    } else {
      CardContainer =
        Components[
          `${upperFirst(size)}${upperFirst(camelCase(cardType))}${upperFirst(
            display,
          )}`
        ];
    }
  }

  if (content === 'mixed') {
    CardContainer = Components[cardType];
  }
  return CardContainer;
};

export const getRailHeight = (item) => {
  const { objects = [], content, size, display } = item;
  const ContainerComponent = getRailComponent(
    objects[0].type,
    content,
    size,
    camelCase(display),
  );
  try {
    return ContainerComponent.getComponentHeight(item);
  } catch (error) {
    return 0;
  }
};
