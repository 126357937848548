import React from 'react';
import { View } from 'react-native';
import { range } from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { StaticNavigationHeader } from '../../components/shared';
import colors from '../../theme/Colors';
import styles from './styles';

const InfluencerPlaceHolder = () => {
  const mapTagPlaceholders = () => {
    return (
      <ShimmerPlaceHolder
        style={styles.tagPlaceHolder}
        colorShimmer={colors.shimmerColorArray}
        autoRun
      />
    );
  };

  const Tags = () => {
    return (
      <View style={styles.tagPlaceHolderContainer}>
        {range(0, 3).map(mapTagPlaceholders)}
      </View>
    );
  };

  const mapSocialTagPlaceholders = () => {
    return (
      <ShimmerPlaceHolder
        style={styles.socialTagPlaceHolder}
        colorShimmer={colors.shimmerColorArray}
        autoRun
      />
    );
  };

  const SocialMediaTags = () => {
    return (
      <View style={styles.socialMediaTagPlaceHolderContainer}>
        {range(0, 6).map(mapSocialTagPlaceholders)}
      </View>
    );
  };

  return (
    <>
      <StaticNavigationHeader />
      <View style={styles.introBackground}>
        <View style={styles.introContainer}>
          <ShimmerPlaceHolder
            style={styles.profileImage}
            colorShimmer={colors.shimmerColorArray}
            autoRun
          />
          <ShimmerPlaceHolder
            style={styles.namePlaceHolder}
            colorShimmer={colors.shimmerColorArray}
            autoRun
          />
          <Tags />
          <SocialMediaTags />
        </View>
      </View>
    </>
  );
};

export default InfluencerPlaceHolder;
