import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';

class ThankYouCard extends PureComponent {
  render() {
    const { artistName = '' } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={styles.title}>Thank you for joining</Text>
          <Text style={styles.subtitle} numberOfLines={2}>
            Thank you for joining with {artistName}
          </Text>
        </View>
        <FastImageView
          source={images.routines.fireWorks}
          style={styles.image}
        />
      </View>
    );
  }
}

export default ThankYouCard;

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    backgroundColor: '#FFF4EB',
    flexDirection: 'row',
    alignItems: 'center',
    height: 84,
    marginHorizontal: 12,
    marginVertical: 20,
  },
  textContainer: {
    marginHorizontal: 12,
    width: Utility.getScreenWidth() - 130,
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    marginTop: 4,
  },
  subtitle: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto',
    flexShrink: 1,
    marginBottom: 4,
    marginTop: 6,
  },
  image: {
    height: 70,
    width: 70,
    marginRight: 12,
  },
});
