import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { RefreshControl } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { AnalyticsScreenNames } from '../../config/Constants';
import { getMyCollection } from '../../actions/ActionTypes';
import List from './List';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';

class FavouriteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: {},
      isRefreshing: false,
    };
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.onDidFocus();
    });
    this.getMyCollectionData();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  myCollectionApiCallback = (success, data) => {
    if (success) {
      this.setState({
        listData: data,
        isRefreshing: false,
      });
      AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
        [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.MYKIT,
      });
    } else {
      this.setState({
        isRefreshing: false,
      });
    }
  };

  getMyCollectionData = () => {
    const { getMyCollection } = this.props;
    AnalyticsUtility.recordTime(
      { screen_name: SCREEN_CONSTANTS.MYKIT },
      this.myCollectionApiCallback,
      getMyCollection,
      this.myCollectionApiCallback,
    );
  };

  refreshPage = () => {
    this.setState({
      isRefreshing: true,
    });
    this.getMyCollectionData();
  };

  onDidFocus = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.MYKIT_VIEW);
    this.getMyCollectionData();
  };

  render() {
    const { isRefreshing, listData } = this.state;
    const { navigation } = this.props;

    return (
      <ErrorBoundary
        screen_name={AnalyticsScreenNames.MY_KIT}
        itemData={listData}
      >
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={this.refreshPage}
            />
          }
        >
          <List
            itemData={listData}
            navigation={navigation}
            previousScreen={SCREEN_CONSTANTS.MYKIT}
          />
        </ScrollView>
      </ErrorBoundary>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        getMyCollection,
      },
      dispatch,
    ),
  };
}

export default withNavigation(connect(null, mapDispatchToProps)(FavouriteList));
