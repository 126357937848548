import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';
import FastImageView from '../../FastImageView';

class RoutineScoreFeatureCard extends PureComponent {
  render() {
    const {
      heading = '',
      subheading = '',
      totalPoints = '',
      backgroundColor = '',
      imageUrl = '',
      buttonBackground = '',
      buttonTextColor = '',
      bodyColor = colors.white,
      width,
    } = this.props;
    const containerStyle = [styles.container, { backgroundColor, width }];
    const buttonTextStyle = [styles.buttonText, { color: backgroundColor }];
    const buttonTextBold = [styles.buttonText, { color: buttonTextColor }];
    const buttonContainerStyle = [
      styles.buttonContainer,
      { backgroundColor: buttonBackground },
    ];
    const headingStyle = [styles.title, { color: buttonBackground }];
    const bodyStyle = [styles.subheading, { color: bodyColor }];
    const boldSubheading = subheading.match(/[0-9]+%\s?/g)?.[0];
    const subheadingArray = subheading.split(/[0-9]+%\s?/g);
    return (
      <View style={containerStyle}>
        <View style={styles.contentContainer}>
          <View>
            <Text style={headingStyle}>{heading}</Text>
            <Text style={bodyStyle} numberOfLines={3} ellipsizeMode='tail'>
              {subheadingArray[0]}
              <Text style={styles.boldSubheading}>{boldSubheading}</Text>{' '}
              {subheadingArray[1]}
            </Text>
          </View>
          {Utility.isPresent(totalPoints) && totalPoints !== 0 && (
            <View style={buttonContainerStyle}>
              <Text style={buttonTextStyle}>
                Your score :<Text style={buttonTextBold}> {totalPoints}</Text>
              </Text>
            </View>
          )}
        </View>
        <FastImageView source={imageUrl} style={styles.image} />
      </View>
    );
  }
}

export default RoutineScoreFeatureCard;

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    paddingLeft: 16,
    paddingTop: 12,
    flexDirection: 'row',
    marginTop: 25,
    overflow: 'visible',
    marginHorizontal: 6,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'flex-start',
    marginBottom: 12,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    marginBottom: 4,
  },
  subtitle: {
    color: colors.white,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    marginBottom: 8,
  },
  buttonContainer: {
    backgroundColor: colors.routine_golden,
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderRadius: 10,
    marginTop: 8,
  },
  buttonText: {
    color: colors.light_purple,
    fontSize: size.h4,
    fontFamily: 'Roboto-Bold',
  },
  image: {
    width: 110,
    marginTop: -25,
    height: 130,
    resizeMode: 'contain',
    marginBottom: 10,
  },
  boldSubheading: {
    color: colors.white,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h0,
  },
});
