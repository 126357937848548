export default class UACUtils {
  static beautyAppScore = 0;

  static commerceAppScore = 0;

  static deviceScore = 0;

  static selfieScore = 0;

  static genderScore = 0;
}
