import { Dimensions } from 'react-native';

let { height, width } = Dimensions.get('window');
if (height < width) {
  let temp = height;
  height = width;
  width = temp;
}

const guidelineBaseWidth = 360;
const guidelineBaseHeight = 672;

const ScalingUtility = {
  scale: (size) => (width / guidelineBaseWidth) * size,

  verticalScale: (size) => (height / guidelineBaseHeight) * size,

  moderateScale: (size, factor = 0.5) =>
    size + (ScalingUtility.scale(size) - size) * factor,

  moderateVerticalScale: (size, baseHeight) => baseHeight / (baseHeight / size),
};

export default ScalingUtility;
