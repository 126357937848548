import React from 'react';
import { StyleSheet, View } from 'react-native';
import Config from '../libraries/ReactNativeConfig';
import FastImageView from '../components/FastImageView';
import Utility from '../utils/Utility';
import TabNavigationRightHeader from '../components/header/TabNavigationRightHeader';
import TabNavigationLeftHeader from '../components/header/TabNavigationLeftHeader';

const withTabNavigationHeader = (
  component,
  options = { type: 'Feed' },
) => {
  const tabBar = options.type;

  if (Utility.getCurrentAppType()) {
    component.navigationOptions = {
      headerTitle: (
        <View style={styles.headerTitleContainer}>
          <FastImageView
            source={Config.HEADER_LOGO}
            style={styles.headerTitleImage}
          />
        </View>
      ),
      headerTitleStyle: {
        textAlign: 'center',
        alignSelf: 'center',
        flex: 1, // must to centrally align title on android, react navigation bug
      },
      headerRight: <TabNavigationRightHeader tabBar={tabBar} />,
      headerLeft: <TabNavigationLeftHeader tabBar={tabBar} />,
    };
  } else {
    component.navigationOptions = {
      title: tabBar,
      headerTitleStyle: {
        textAlign: 'center',
        alignSelf: 'center',
        flex: 1, // must to centrally align title on android, react navigation bug
      },
      headerRight: <TabNavigationRightHeader tabBar={tabBar} />,
      headerLeft: <TabNavigationLeftHeader tabBar={tabBar} />,
    };
  }
  return component;
};

export default withTabNavigationHeader;

const styles = StyleSheet.create({
  headerTitleImage: {
    height: parseInt(Config.HEADER_LOGO_HEIGHT),
    width: parseInt(Config.HEADER_LOGO_WIDTH),
    resizeMode: 'contain',
  },
  headerTitleContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
