import { StyleSheet } from 'react-native';
import Utility from '../../../utils/Utility';

const GridStyles = StyleSheet.create({
  container: {
    marginLeft: Utility.padding - Utility.spacingBetweenItems,
    marginRight: Utility.padding - Utility.spacingBetweenItems,
    marginVertical: 8,
  },
  flatlistStyle: {
    overflow: 'visible',
  },
});

export default GridStyles;
