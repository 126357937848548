import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from './Utility';

class FullWidthDivider extends PureComponent {
  render() {
    const { margin = 0, setDividerMargin = true, componentStyle } = this.props;
    return (
      <View
        style={[
          styles.divider,
          {
            marginLeft: setDividerMargin ? margin : null,
            marginRight: setDividerMargin ? margin : null,
            width: Utility.getScreenWidth() - 2 * margin,
          },
          componentStyle,
        ]}
      />
    );
  }
}

const styles = StyleSheet.create({
  divider: {
    height: 1,
    backgroundColor: colors.background,
  },
});
export default FullWidthDivider;
