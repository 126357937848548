import {
  fetchAndActivate,
  getString,
  getNumber,
  getBoolean,
} from 'firebase/remote-config';
import { remoteConfig } from '../libraries/Firebase';
import { DEFAULT_REMOTE_CONFIG_VALUES } from '../config/Constants';

class RemoteConfig {
  static initRemoteConfig() {
    remoteConfig.settings.minimumFetchIntervalMillis = 10000;

    remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG_VALUES;

    fetchAndActivate(remoteConfig)
      .then(() => {
        // ...
      })
      .catch((err) => {
        // ...
      });
  }

  static getValue(key) {
    return getString(remoteConfig, key);
  }

  static getIntValue(key) {
    return getNumber(remoteConfig, key);
  }

  static getBooleanValue(key) {
    return getBoolean(remoteConfig, key);
  }
}

export default RemoteConfig;
