import {
  Text,
  View,
  FlatList,
  Linking,
  BackHandler,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import React, { Component } from 'react';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import StaticNavigationHeader from '../../components/shared/StaticNavigationHeader';
import FastImageView from '../../components/FastImageView';
import withNavigation from '../../utils/WithNavigation';
import RoundedButton from '../../components/layout/buttons/RoundedButton';
import images from '../../theme/Images';
import OverlappingHorizontalImageList from '../../components/shared/OverlappingHorizontalImageList';
import size from '../../theme/Fonts';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { getScreenWidth } from '../../utils/LayoutUtility';

export class RoutineGroups extends Component {
  constructor(props) {
    super(props);
    const { navigation, route } = this.props;
    this.routineGroups = route.params?.routineGroups;
    this.cardImageUrl = route.params?.cardImageUrl;
    this.routineId = route.params?.routineId;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onBackPress,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  whatsappImage = () => {
    return (
      <FastImageView
        source={images.routines.whatsApp_white}
        style={styles.groupCardButtonIcon}
        resizeMode='contain'
      />
    );
  };

  onWhatsappClick = (item) => {
    const { displayName = '', inviteLink = '' } = item;
    Linking.openURL(inviteLink);
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.TYPE]: EventParameterValue.ITEM_TYPE.WHATSAPP_JOIN,
      [EventParameterKey.NAME]: displayName,
      [EventParameterKey.ENTITY]: 'routine',
      [EventParameterKey.ENTITY_ID]: this.routineId,
    });
  };

  onBackPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  renderItem = ({ item, index }) => {
    const { displayName = '', inviteLink = '' } = item;
    const participatnsArray = images.routines.participantsArray;
    const filteredArray = participatnsArray
      .sort(() => 0.5 - Math.random())
      .slice(0, 3);
    return (
      <>
        <DebouncedTouchableOpacity
          {...this.props}
          onPress={() => this.onWhatsappClick(item)}
        >
          <View style={styles.cardContainer}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FastImageView
                source={{
                  uri: Utility.getMinifiedImage(this.cardImageUrl, 52, 52),
                }}
                style={styles.cardImage}
              />
              <View
                style={{ marginLeft: 8, width: Utility.getScreenWidth() - 200 }}
              >
                <Text style={styles.title}>{displayName}</Text>
                <OverlappingHorizontalImageList
                  images={filteredArray}
                  size={24}
                />
              </View>
            </View>
            <View>
              <RoundedButton
                buttonText={'Join now'}
                buttonTextColor={colors.white}
                buttonColor={colors.black}
                buttonAction={() => this.onWhatsappClick(item)}
                style={styles.buttonStyle}
                buttonTextStyle={styles.buttonText}
                renderProp={this.whatsappImage}
                dataSet={{ media: ids.buttonStyle }}
              />
            </View>
          </View>
        </DebouncedTouchableOpacity>
      </>
    );
  };

  render() {
    return (
      <>
        <StaticNavigationHeader title='Chat' />
        <View style={styles.container}>
          <Text style={styles.heading}>Join our Whatsapp community</Text>
          <Text style={styles.subheading}>
            to get your questions answered directly by the experts
          </Text>
          <FlatList data={this.routineGroups} renderItem={this.renderItem} />
        </View>
      </>
    );
  }
}

export default withNavigation(RoutineGroups);

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingHorizontal: 16,
    marginTop: 16,
    ...Platform.select({
      web: { width: getScreenWidth() - 32, alignSelf: 'center' },
      default: {},
    }),
  },
  heading: {
    fontSize: 18,
    color: '#000',
    fontFamily: 'Roboto-Bold',
  },
  subheading: {
    fontSize: 14,
    color: '#979BAA',
    fontFamily: 'Roboto-Regular',
  },
  groupCardButtonIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 12,
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 12,
    borderRadius: 8,
  },
  cardImage: { width: 52, height: 52, borderRadius: 12 },
  title: {
    fontSize: size.h3,
    color: colors.black,
    fontFamily: 'Roboto-Medium',
    marginBottom: 4,
  },
  buttonStyle: {
    height: 28,
    width: 84,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
    '@media (min-width: 768px)': {
      width: 84 * 2,
      marginRight: 20,
    },
  },
});
