import React, { PureComponent } from 'react';
import { Animated, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import ArtistHeader from '../artistHeader';
import { EventParameterKey } from '../../analytics';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { isDesktop } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
// import ProgressBarView from '../progressBarView';

const defaultStyles = StyleSheet.create({
  defaultHeaderContainerStyle: {
    height: 120 + Utility.topInset,
    width: isDesktop() ? getScreenWidth() / 2.5 : Utility.getScreenWidth(),
    paddingTop: Utility.isIOS() ? 38 : 0,
    zIndex: 60,
    position: 'absolute',
    overflow: 'hidden',
    top: new Animated.Value(Utility.topInset),
  },
});

class ContentPageHeader extends PureComponent {
  getStyle = (parentComponent) => {
    const style = {
      height: 32,
      width: isDesktop() ? getScreenWidth() / 2.5 : Utility.getScreenWidth(),
      flexDirection: 'row',
      top:
        parentComponent !== SCREEN_CONSTANTS.CONTENT_PAGE_CAROUSEL ||
        Utility.isIOS()
          ? 0
          : (isDesktop() ? 20 : 40),
    };

    return style;
  };

  render() {
    const {
      artistId,
      artistSlug,
      artistName,
      artistThumbnail,
      contentTitle,
      contentSubtitle = '',
      headerContainerStyle,
      isFollowingArtist,
      videoMetadata,
      onCancelTap,
      onFollowTapped,
      showCrossButton,
      parentComponent = '',
      lastNotificationModalDisplayTime,
      destination,
      productRating,
      listId,
      foxyHandle,
      publisherType = '',
      publisherSlug = '',
      publisherId = '',
    } = this.props;
    const style = this.getStyle(parentComponent);
    const headerStyle = Utility.isPresent(headerContainerStyle)
      ? headerContainerStyle
      : defaultStyles.defaultHeaderContainerStyle;
    return (
      <Animated.View style={headerStyle}>
        <View style={style} viewName='description'>
          <ArtistHeader
            artistId={artistId}
            artistSlug={artistSlug}
            artistName={artistName}
            contentTitle={contentTitle}
            contentSubTitle={contentSubtitle}
            artistThumbnail={artistThumbnail}
            isFollowingArtist={isFollowingArtist}
            videoMetadata={videoMetadata}
            onCancelTap={onCancelTap}
            onFollowTapped={onFollowTapped}
            showCrossButton={showCrossButton}
            lastNotificationModalDisplayTime={lastNotificationModalDisplayTime}
            destination={destination}
            productRating={productRating}
            listId={listId}
            foxyHandle={foxyHandle}
            publisherType={publisherType}
            publisherSlug={publisherSlug}
            publisherId={publisherId}
          />
        </View>
      </Animated.View>
    );
  }
}

ContentPageHeader.propTypes = {
  artistId: PropTypes.string.isRequired,
  artistSlug: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  artistThumbnail: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  availableWidth: PropTypes.number.isRequired,
  isFollowingArtist: PropTypes.bool,
  videoMetadata: PropTypes.shape({
    [EventParameterKey.VIDEO_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_TITLE]: PropTypes.string,
    [EventParameterKey.ARTIST_NAME]: PropTypes.string,
    [EventParameterKey.ARTIST_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_DURATION]: PropTypes.number,
    [EventParameterKey.VIDEO_TYPE]: PropTypes.string,
  }).isRequired,
  headerContainerStyle: PropTypes.shape({}).isRequired,
  onCancelTap: PropTypes.func.isRequired,
  onFollowTapped: PropTypes.func.isRequired,
};

ContentPageHeader.defaultProps = {
  isFollowingArtist: false,
};

export default ContentPageHeader;
