import React, { PureComponent } from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  FlatList,
  BackHandler,
  Linking,
} from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';
import { StaticNavigationHeader } from '../../components/shared';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';

class AccountSettings extends PureComponent {
  constructor(props) {
    super(props);
    const { selfieImageUrl, authToken } = props;
    this.settingOptions = [
      {
        title: 'App Permissions',
        type: 'app_permissions',
      },
    ];

    if (Utility.isPresent(selfieImageUrl)) {
      this.settingOptions.push({
        title: 'Delete Selfie',
        showAdditionalList: true,
        type: 'delete_selfie',
      });
    }
    if (Utility.isPresent(authToken)) {
      this.settingOptions.push({
        title: 'Delete Account',
        type: 'delete_account',
      });
    }

    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }

    this.debouncedNavigateToScreen = _.debounce(this.navigateToScreen, 1000, {
      leading: true,
      trailing: false,
    });
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  navigateToScreen = (type) => {
    const { navigation } = this.props;
    switch (type) {
      case 'app_permissions':
        Linking.openSettings();
        break;
      case 'delete_selfie':
        AnalyticsManager.logEvent(
          EventType.account_settings.DELETE_SELFIE_INITIATE,
          {
            [EventParameterKey.SCREEN_NAME]: 'account_settings',
          },
        );
        navigation.push('DeleteSelfie');
        break;
      case 'delete_account':
        navigation.push('DeleteAccount');
        AnalyticsManager.logEvent(
          EventType.account_settings.DELETE_ACCOUNT_INITIATE,
          {
            [EventParameterKey.SCREEN_NAME]: 'account_settings',
          },
        );
        break;
      default:
        break;
    }
  };

  settingsOptionRow = (props) => {
    const { title, type } = props;
    return (
      <View style={styles.borderLayout}>
        <TouchableOpacity onPress={() => this.debouncedNavigateToScreen(type)}>
          <View style={styles.row}>
            <View style={{ flexDirection: 'column' }}>
              <Text style={styles.menuOptionText}>{title}</Text>
            </View>
            <Image
              source={images.chevronRight}
              style={{ tintColor: colors.darkBorder }}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  keyExtractor = (item, index) => item.id;

  render() {
    return (
      <View style={styles.backgroundContainer}>
        <StaticNavigationHeader title={'Account Settings'} />
        <FlatList
          data={this.settingOptions}
          extraData={this.state}
          style={styles.flatlistContainer}
          showsHorizontalScrollIndicator={false}
          keyExtractor={this.keyExtractor}
          renderItem={({ item }) => this.settingsOptionRow(item)}
        />
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  authToken: state.UserAccountInfo.authToken,
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
});

export default withNavigation(connect(mapStateToProps, null)(AccountSettings));

const styles = {
  backgroundContainer: {
    height: Utility.getScreenHeight(),
    backgroundColor: colors.background,
  },
  flatlistContainer: {
    flex: 1,
    paddingVertical: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 24,
    height: 54,
    backgroundColor: '#FFF',
    marginBottom: 4,
  },
  menuOptionText: {
    fontFamily: 'Roboto',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },
  image: {
    height: 40,
    width: 40,
  },
};
