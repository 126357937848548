import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FoxyRoundedButton from '../../../lib/FoxyRoundedButton';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

// eslint-disable-next-line react/prefer-stateless-function
export default class SmallActionCard extends Component {
  constructor(props) {
    super(props);

    const { backgroundColor = '#fff', style } = this.props;
    this.containerStyle = [
      styles.container,
      style,
      { backgroundColor },
    ];
  }

  render() {
    const {
      backgroundColor,
      imageUrl,
      heading,
      subheading,
      firstActionButtonText,
      style,
      primaryButtonAction,
      primaryButtonIcon,
      secondaryActionButtonText,
      secondaryButtonAction,
      secondaryButtonIcon,
      actionCardsId,
      invoiceFileUrl = '',
    } = this.props;
    if (Utility.isBlank(invoiceFileUrl) && actionCardsId === 10) return null;
    return (
      <View style={this.containerStyle}>
        {Utility.isBlank(secondaryActionButtonText) && actionCardsId === 20 && (
          <View style={styles.confirmationIcon} />
        )}
        <Text style={styles.heading}>{heading}</Text>
        {Utility.isPresent(firstActionButtonText) && (
          <FoxyRoundedButton
            title={firstActionButtonText}
            onPress={primaryButtonAction}
            backgroundColor={colors.black}
            titleColor={colors.white}
            leftIcon={primaryButtonIcon}
            iconTintColor={colors.white}
            iconHeight={11}
            iconWidth={11}
          />
        )}
        {Utility.isPresent(secondaryActionButtonText) && (
          <FoxyRoundedButton
            title={secondaryActionButtonText}
            onPress={secondaryButtonAction}
            backgroundColor={colors.black}
            titleColor={colors.white}
            leftIcon={secondaryButtonIcon}
            iconTintColor={colors.foxyRed}
            iconHeight={11}
            iconWidth={11}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth() - 24,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
    marginTop: 3,
    flexDirection: 'row',
    height: 52,
  },
  heading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    lineHeight: 14.09,
    textAlign: 'center',
  },
  confirmationIcon: {
    height: 8,
    width: 8,
    backgroundColor: colors.darkGreen,
    borderRadius: 10,
    marginRight:1
  },
});
