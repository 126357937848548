import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  Text,
  TouchableOpacity,
} from 'react-native';
import _ from 'lodash';
import { LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import WithNavigation from '../../utils/WithNavigation';
import { navigateToScreen } from '../../utils/NavigationUtility';

class ProductGroups extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.navigateCache = {};
    this.availableColorStyleCache = {};
  }

  navigate = (slug) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: { slug },
    });
  };

  memoizedNavigate = () => {
    return (slug) => {
      if (!this.navigateCache[slug]) {
        this.navigateCache[slug] = () => this.debouncedNavigate(slug);
      }
      return this.navigateCache[slug];
    };
  };

  memoizedAvailableColorStyle = () => {
    return (backgroundColor) => {
      if (!this.availableColorStyleCache[backgroundColor]) {
        this.availableColorStyleCache[backgroundColor] = [
          styles.availableColor,
          { backgroundColor },
        ];
      }
      return this.availableColorStyleCache[backgroundColor];
    };
  };

  renderAvailableColor = ({ index, item }) => {
    const { name = '', color, slug = '', image } = item;
    const backgroundColor = Utility.isBlank(image) ? color : '';
    const onPress = this.memoizedNavigate()(slug);
    const availableColorStyle =
      this.memoizedAvailableColorStyle()(backgroundColor);
    return (
      <View style={styles.itemContainer}>
        <TouchableOpacity style={styles.colorContainer} onPress={onPress}>
          <FastImageView
            style={availableColorStyle}
            source={image}
            resizeMethod={'stretch'}
          />
        </TouchableOpacity>
        <Text numberOfLines={1} ellipsizeMode={'tail'} style={styles.colorText}>
          {name}
        </Text>
      </View>
    );
  };

  render() {
    const {
      itemData: {
        product_group: { items = [] } = {},
        product_page_type = 'foxy',
      },
    } = this.props;
    if (
      Utility.isBlank(items) ||
      items.length === 0 ||
      product_page_type === 'foxy'
    )
      return null;

    return (
      <View style={styles.container}>
        <Text style={styles.heading}>Available Colors</Text>
        <FlatList
          horizontal
          data={items}
          renderItem={this.renderAvailableColor}
          contentContainerStyle={styles.flatListContent}
          showsHorizontalScrollIndicator={false}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    marginVertical: 12,
  },
  flatListContent: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  heading: {
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    fontSize: size.h1,
    marginVertical: 12,
    paddingHorizontal: 12,
  },
  itemContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorContainer: {
    borderRadius: 60,
    borderWidth: 1,
    borderColor: colors.background,
    padding: 2,
    marginVertical: 12,
    marginLeft: 12,
    marginRight: 12,
  },
  colorText: {
    color: colors.black,
    marginBottom: 12,
    width: (Utility.getScreenWidth() - 12) / 5.5 - 4,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
  },
  availableColor: {
    height: (Utility.getScreenWidth() - 12) / 5.5 - 28,
    width: (Utility.getScreenWidth() - 12) / 5.5 - 28,
    borderRadius: 50,
  },
});
export default WithNavigation(ProductGroups);
