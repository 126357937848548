import { Text, View, FlatList, TouchableOpacity } from 'react-native';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getReturnReplaceReason,
  addReturnReplaceReasonId,
} from '../../../actions/ActionTypes';
import styles from './styles';
import withNavigation from '../../../utils/WithNavigation';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import { StaticNavigationHeader } from '../../../components/shared';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';

class ReturnReplaceReasonPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedReturnReason: '',
      returnReplaceReasons: [],
      selectedReturnReasonId: '',
      isShowError: false,
    };
    const { navigation, route } = this.props;
    this.headerTitle = route.params?.headerTitle;
    this.callBackRequestReasonId = route.params?.callBackRequestReasonId;
    this.entityType = route.params?.entityType;
    this.entityId = route.params?.entityId;
    this.orderId = route.params?.orderId;
    this.orderAasmState = route.params?.orderAasmState;
  }

  componentDidMount() {
    const { navigation, getReturnReplaceReason } = this.props;
    getReturnReplaceReason(this.getReturnReplaceReasonCallBack);
  }

  getReturnReplaceReasonCallBack = (success, response) => {
    if (success) {
      this.setState({
        returnReplaceReasons: response.data,
      });
      AnalyticsManager.logEvent('page_load_2', {
        [EventParameterKey.PAGE]: SCREEN_CONSTANTS.RETURN_REPLACE_REASONS,
      });
    }
  };

  onPressReturnReason = (item, id) => {
    this.setState({
      selectedReturnReason: item,
      selectedReturnReasonId: id,
      isShowError: false,
    });
  };

  reasonList = ({ item, index }) => {
    const {
      attributes: { value = '' },
      id,
    } = item;
    const { selectedReturnReasonId } = this.state;
    const isSelectedReasonStyle =
      selectedReturnReasonId === id
        ? styles.selectedReasonContainerStyle
        : styles.reasonListContainer;
    return (
      <TouchableOpacity
        style={isSelectedReasonStyle}
        onPress={() => this.onPressReturnReason(value, id)}
      >
        <Text style={styles.reasonTextStyle}>{value}</Text>
      </TouchableOpacity>
    );
  };

  bottomButtonAction = () => {
    const { selectedReturnReason, selectedReturnReasonId } = this.state;
    const { addReturnReplaceReasonId, navigation } = this.props;
    if (Utility.isBlank(selectedReturnReason)) {
      this.setState({
        isShowError: true,
      });
      return null;
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.TYPE]: selectedReturnReason.slice(0, 45),
      [EventParameterKey.ENTITY_ID]: this.entityId,
      [EventParameterKey.ENTITY_TYPE]: this.entityType,
      [EventParameterKey.STATUS]: this.orderAasmState,
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.RETURN_REPLACE_REASONS,
      [EventParameterKey.CTA]: 'Continue',
    });
    addReturnReplaceReasonId(
      selectedReturnReasonId,
      this.entityId,
      this.entityType,
      (success, response) => {
        if (!success) return;
        if (selectedReturnReasonId) {
          navigation.navigate('RequestCallBackPage', {
            headerTitle: selectedReturnReason,
            callBackRequestReasonId: this.callBackRequestReasonId,
            entityId: this.entityId,
            previousScreen: 'ReturnReplaceReasonPage',
            orderId: this.orderId,
            entityType: this.entityType,
            orderAasmState: this.orderAasmState,
            returnRequestId: response?.return_request_id,
          });
        }
      },
    );
  };

  keyExtractor = (item, index) => index;

  render() {
    const { returnReplaceReasons, selectedReturnReasonId, isShowError } =
      this.state;
    return (
      <>
        <StaticNavigationHeader title={this.headerTitle} />
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <Text style={styles.heading}>
              Please help us understand the issue better
            </Text>
            <FlatList
              style={styles.listContainer}
              data={returnReplaceReasons}
              renderItem={this.reasonList}
              keyExtractor={this.keyExtractor}
              extraData={selectedReturnReasonId}
            />
            {isShowError && (
              <Text style={styles.errorMessage}>
                *Please select a reason to Continue.
              </Text>
            )}
          </View>
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 36}
              style={styles.foxyShadowButton}
              title={'Continue'}
              backgroundColor={colors.codGray}
              firstColor={colors.black}
              secondColor={colors.black}
              underlayColor={colors.black}
              borderRadius={4}
              onPress={this.bottomButtonAction}
            />
          </View>
        </View>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getReturnReplaceReason,
      addReturnReplaceReasonId,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(ReturnReplaceReasonPage),
);
