import React, { useRef } from 'react';
import { View, TouchableOpacity, Image, Text } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useNavigation } from '@react-navigation/native';
import { PopUpStyles } from './styles';
import images from '../../theme/Images';
import Toast from 'react-native-easy-toast';
import withNavigation from '../../utils/WithNavigation';
import colors from '../../theme/Colors';
import DefaultSizeProductList from './DefaultSizeProductList';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import ProductPricingAndAddToCart from './ProductPricingAndAddToCart';
import { isPresent } from '../../utils/BooleanUtility';

const ReplacementProductModal = (props) => {
  const toastRef = useRef(null);
  const {
    route: {
      params: {
        selectedVariant = '',
        itemData = {},
        currentSku,
        productName,
        itemData: { brand: { name: brandName } = {} },
      } = {},
    },
  } = props;

  const navigation = useNavigation();

  const cancelpressed = () => {
    navigation.goBack();
  };

  const showToast = (message) => {
    if (isPresent(toastRef) && isPresent(toastRef.current)) {
      toastRef.current.show(message, 2000);
    }
  };


  return (
    <>
      <DebouncedTouchableOpacity onPress={navigation.goBack}>
        <View style={styles.touchableContainer} />
      </DebouncedTouchableOpacity>
      <View style={styles.modalContainer} pointerEvents='box-none'>
        <View style={styles.bottomModal} dataSet={{ media: ids.bottomModal }}>
          <View style={PopUpStyles.container}>
            <View style={PopUpStyles.addReviewHeader}>
              <Text
                style={PopUpStyles.reviewHeaderText}
                numberOfLines={1}
              >{`${brandName} recommends`}</Text>

              <TouchableOpacity
                style={PopUpStyles.cancelButton}
                onPress={cancelpressed}
              >
                <Image source={images.cross} style={PopUpStyles.cancelStyle} />
              </TouchableOpacity>
            </View>
            <Text
              style={{
                fontSize: 14,
                fontFamily: 'Roboto-regular',
                color: colors.foxyBlack,
                paddingHorizontal: 14,
              }}
            >{`This product for you as an alternative to ${productName}`}</Text>

            <DefaultSizeProductList
              itemData={itemData}
              cancelpressed={cancelpressed}
              fromModal
              previousScreen='replacement_modal'
            />
            <ProductPricingAndAddToCart
              selectedVariant={selectedVariant}
              itemData={itemData}
              currentSku={currentSku}
              avoidPositionAbsolute
              showToast={showToast}
            />
          </View>
        </View>
      </View>
      <Toast ref={toastRef} />
    </>
  );
};

const { ids, styles } = StyleSheet.create({
  container: { backgroundColor: 'white', flex: 1 },
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.translucent,
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingBottom: 24,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 1,
    '@media (min-width: 800px)': {
      top: '50%',
      left: '50%',
      bottom: 'auto',
      right: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  },
});

export default ReplacementProductModal;
