import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import colors from '../../../theme/Colors';
import FastImageView from '../../FastImageView';
import _ from 'lodash';

const variantShadeStyleCache = {};

const memoizedAvailableColorStyle = () => {
  return (backgroundColor) => {
    if (!variantShadeStyleCache[backgroundColor]) {
      variantShadeStyleCache[backgroundColor] = [
        styles.variantShade,
        { backgroundColor },
      ];
    }
    return variantShadeStyleCache[backgroundColor];
  };
};

const VariantShades = (props) => {
  const { items = [] } = props;
  return (
    <View style={styles.shadeItemContainer}>
      {items.slice(0, 4).map((item, index) => {
        const { image, color } = item;
        const variantShadeStyle = memoizedAvailableColorStyle()(item.color);
        return <FastImageView source={image} style={variantShadeStyle} />;
      })}
    </View>
  );
};

const VariantCount = (props) => {
  const { items = [] } = props;

  if (items.length < 4) {
    return null;
  }
  return (
    <Text
      style={styles.variantCountText}
      numberOfLines={1}
      ellipsizeMode='tail'
    >
      {items.length}
    </Text>
  );
};

export default function VariantGroup(props) {
  const { layout, group: { items = [] } = {} } = props;

  if (items?.length === 0) return null;
  return (
    <View style={styles[`variantShadeContainer${_.capitalize(layout)}`]}>
      <VariantShades items={items} />
      <VariantCount items={items} />
    </View>
  );
}

const styles = StyleSheet.create({
  variantShade: {
    height: 12,
    width: 12,
    borderRadius: 6,
    marginLeft: -7,
    borderWidth: 1,
    borderColor: colors.border,
  },
  variantCountText: {
    fontSize: 10,
    marginLeft: 2,
    fontFamily: 'Roboto-Regular',
    color: colors.silver,
  },
  variantShadeContainerRail: {
    flexDirection: 'row',
    paddingHorizontal: 6,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 12,
    top: 10,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: '#ffffff',
  },
  variantShadeContainerGrid: {
    flexDirection: 'row',
    paddingHorizontal: 6,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 12,
    top: 10,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: colors.white,
  },
  shadeItemContainer: {
    flexDirection: 'row-reverse',
    marginRight: 3,
  },
});
