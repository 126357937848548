// Dependencies
import React, { Component } from 'react';
import { ImageBackground, View, Image, Text, FlatList } from 'react-native';
import ShimmerPlaceHolder from '../../../libraries/ReactNativeShimmerPlaceholder';
import styles from './styles';

class StoriesPlaceholder extends Component {
  railPlaceholder = () => (
    <View style={styles.shimmerContainer}>
      <this.shimmerCircle />
      <this.shimmerCircle />
      <this.shimmerCircle />
      <this.shimmerCircle />
      <this.shimmerCircle />
    </View>
  );

  shimmerCircle = () => (
    <View style={{ flexDirection: 'column' }}>
      <ShimmerPlaceHolder
        autoRun
        style={styles.shimmerCircle}
        colorShimmer={['#ffffff1A', '#ffffff33', '#ffffff1A']}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.storyText}
        colorShimmer={['#ffffff1A', '#ffffff33', '#ffffff1A']}
      />
    </View>
  );

  render() {
    return <this.railPlaceholder />;
  }
}

export default StoriesPlaceholder;
