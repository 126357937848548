import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import ShimmerPlaceHolder from '../../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../../theme/Colors';
import { styles } from '../RoutineDetails/AboutRoutineCard';

class PreRoutineCardPlaceholder extends PureComponent {
  detailsCardShimmer = () => {
    return (
      <ShimmerPlaceHolder
        autoRun
        style={placeholderStyle.cardContainer}
        colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
      />
    );
  };

  render() {
    return (
      <View style={placeholderStyle.container}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.title}
          colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
        />
        <ShimmerPlaceHolder
          autoRun
          style={placeholderStyle.body}
          colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
        />
        <View style={styles.detailCardsContainer}>
          <this.detailsCardShimmer />
          <this.detailsCardShimmer />
          <this.detailsCardShimmer />
        </View>
      </View>
    );
  }
}

export default PreRoutineCardPlaceholder;

const placeholderStyle = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 20,
    alignItems: 'flex-start',
    borderRadius: 8,
    backgroundColor: colors.white,
    marginHorizontal: 12,
  },
  body: {
    height: 54,
    width: '100%',
  },
  cardContainer: {
    borderRadius: 8,
    flex: 1,
    marginHorizontal: 5,
    height: 54,
  },
});
