import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import styles from './styles';
import {
  AnalyticsEvent,
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import { navigateToScreen } from '../../utils/NavigationUtility';

const navigateToIngredients = (slug, navigation, uaavsArray) => {
  let uaavs = '';
  _.forEach(uaavsArray, (uaav) => {
    uaavs += `&ingredient_id[]=${uaav.id}`;
  });
  navigateToScreen({
    navigation,
    type: 'push',
    screen: 'MoreItems',
    params: {
      slug: `/${slug}`,
      uaavs,
    },
  });
};

const ingredients = (props) => {
  const {
    productPositiveIngredients = {},
    productNegativeIngredients = {},
    navigation,
    productCategorySlug,
    sku_id,
    id,
    matchedProduct = false,
  } = props;
  let afterTextNegative = '';
  let ctaNegative = '';
  let afterTextPositive = '';
  let ctaPositive = '';

  return (
    <View>
      {Utility.isPresent(productPositiveIngredients) &&
        _.map(productPositiveIngredients, (e, index) => {
          if (e.length == 1) {
            ctaPositive = `Contains ${e[0].name} `;

            afterTextPositive = `good for your ${index}`;
          }
          if (e.length > 1) {
            ctaPositive = `${e.length} ingredients `;
            afterTextPositive = `good for your ${index}`;
          }

          return (
            <TouchableOpacity
              onPress={() => {
                AnalyticsManager.logEvent(
                  EventType.product.PRODUCT_ELEMENT_CLICK,
                  {
                    [EventParameterKey.SKU_ID]: sku_id,
                    [EventParameterKey.PRODUCT_ID]: id,
                    [EventParameterKey.ELEMENT_NAME]: 'foxy_match',
                    [EventParameterKey.ELEMENT_TYPE]: 'ingredients',
                  },
                );
                navigateToIngredients(productCategorySlug, navigation, e);
              }}
              disabled={matchedProduct}
            >
              <View style={styles.container}>
                <Image source={images.foxyMatch.thumbsUp} style={styles.icon} />

                <Text
                  style={styles.foxyMatchTextCta}
                  allowFontScaling={false}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  {ctaPositive}
                </Text>

                <Text
                  style={styles.foxyMatchText}
                  allowFontScaling={false}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  {afterTextPositive}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      {Utility.isPresent(productNegativeIngredients) &&
        _.map(productNegativeIngredients, (e, index) => {
          if (e.length == 1) {
            ctaNegative = `Contains ${e[0].name} `;
            afterTextNegative = `bad for your ${index}`;
          }
          if (e.length > 1) {
            ctaNegative = `${e.length} ingredients`;
            afterTextNegative = ` bad for your ${index}`;
          }
          return (
            <View style={styles.container}>
              <Image source={images.foxyMatch.thumbsDown} style={styles.icon} />
              {/* <Text style={styles.foxyMatchText}>{beforeTextNegative}</Text> */}

              <Text
                style={styles.foxyMatchTextCta}
                allowFontScaling={false}
                numberOfLines={1}
                ellipsizeMode='tail'
              >
                {ctaNegative}
              </Text>

              <Text
                style={styles.foxyMatchText}
                allowFontScaling={false}
                numberOfLines={1}
                ellipsizeMode='tail'
              >
                {afterTextNegative}
              </Text>
            </View>
          );
        })}
    </View>
  );
};

export default ingredients;
