import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  Text,
  Animated,
  Linking,
} from 'react-native';
import { Alert } from '../../libraries/NativeLibraries';
import { PopUpStyles } from './styles';
import colors from '../../theme/Colors';
import FullWidthDivider from '../../utils/FullWidthDivider';
import images from '../../theme/Images';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import Modal from 'react-native-modal';
import Utility from '../../utils/Utility';
import UploadVideoManager from '../../utils/UploadVideoManager';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withNavigation from '../../utils/WithNavigation';
import { addPostToLocalState } from '../../actions/UploadPostActions';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

class AddReviewModal extends Component {
  state = {
    opacity: 0,
    reviewAdded: false,
  };

  staticText = {
    thankYouText: 'Thank you for the rating!',
    uplaodVideoMsg: 'Upload a video to tell us what you liked',
  };

  onDismissed = () => {
    this.props.onReviewDismissed();
    Animated.timing(this.state.bottom, { toValue: 322 }).start();
  };

  goToCamera = () => {
    //console.tron.log check permission and go to camera
    const { navigation, id } = this.props;
    UploadVideoManager.openImagePicker(
      'video',
      (mediaUrl) => {
        console.tron.log('success video uri is ', mediaUrl);
        this.createVideoObject(mediaUrl);
        this.cancelpressed();
      },
      (failureResponse) => {
        console.tron.log('failure response is ', failureResponse);
      },
    );
  };

  createVideoObject = (mediaUrl) => {
    UploadVideoManager.createVideoFile(mediaUrl, 'video', (postData) => {
      const videoData = postData;
      videoData.reviewProductId = this.props.id; //add product id to be reviewd in post data in redux
      this.addPostDataInRedux(videoData);
    });
  };

  addPostDataInRedux = (postData) => {
    const { navigation, addPostToLocalState, id } = this.props;
    addPostToLocalState(postData, (isAllowedToNavigate) => {
      if (isAllowedToNavigate) {
        navigation.navigate('UploadVideo', {
          previousScreen: SCREEN_CONSTANTS.PRODUCT_DETAIL,
          uploadVideoForReview: this.uploadVideoForReview,
          reviewProductId: id,
        });
      }
    });
  };

  uploadVideoForReview = (currentPostId, postData) => {
    const { updatePostDataForVideoUpload } = this.props;
    console.tron.log('reached in uplaod for review', currentPostId, postData);
    updatePostDataForVideoUpload(currentPostId, postData);
  };

  openAlert = (message) => {
    Alert.alert('Permission Required', message, [
      {
        text: 'Settings',
        onPress: Linking.openSettings,
      },
      {
        text: 'Cancel',
        onPress: () => console.tron.log('cancel pressed'),
        style: 'cancel',
      },
    ]);
  };

  checkPermissions = () => {
    const noPermissionMessage =
      "We don't have permission to access your camera, Please go to settings and enable camera permission";
    Utility.checkForPermission(
      'camera',
      noPermissionMessage,
      this.checkAudioPermission,
      this.openAlert,
    );
  };

  checkAudioPermission = () => {
    const noPermissionMessage =
      "We don't have permission to record your audio, Please go to settings and enable microphone permission";
    Utility.checkForPermission(
      'microphone',
      noPermissionMessage,
      this.goToCamera,
      this.openAlert,
    );
  };

  cancelpressed = () => {
    const { toggleReview } = this.props;
    toggleReview();
  };

  smallStars = () => {
    const stars = [];
    const rating = this.props.rating;
    for (let index = 0; index < 5; index++) {
      const tintColor =
        index < rating
          ? PRODUCT.RATING.color[Math.floor(rating)]
          : colors.disabled;
      stars.push(
        <Image
          source={images.greenStar}
          style={[PopUpStyles.smallReviewStarStyle, { tintColor }]}
          key={`${rating}_${index}`}
        />,
      );
    }
    return stars;
  };

  ratingAndStars = () => (
    <View style={PopUpStyles.starContainer}>
      {this.smallStars()}
    </View>
  );

  openCamera = () => {
    this.setState({
      reviewAdded: true,
    });
  };

  addVideo = (props) => {
    const { title } = props;
    return (
      <View style={PopUpStyles.videoContainer}>
        <Text style={PopUpStyles.addReviewSubHeading}>
          {this.staticText.uplaodVideoMsg}
        </Text>
        <TouchableOpacity
          onPress={this.openCamera}
          style={PopUpStyles.addVideoButton}
        >
          <Image
            source={images.addReviewVideoIcon}
            style={PopUpStyles.videoIcon}
          />
          <Image source={images.whitePlus} style={PopUpStyles.plusIcon} />
          <Text style={PopUpStyles.addReviewButtonText}>Add a review</Text>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    const submitText = 'Continue to camera';
    const reviewText = 'Add a review';
    const { reviewAdded } = this.state;
    const { isVisible } = this.props;
    const styles = PopUpStyles;
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.8}
        backdropTransitionOutTiming={0}
        style={styles.changePincodeModal}
        onBackdropPress={this.cancelpressed}
        swipeDirection="down"
        onSwipeComplete={this.cancelpressed}
      >
        <SafeAreaView style={{ flex: 1, justifyContent: 'flex-end' }}>
          <View style={PopUpStyles.container}>
            <View style={PopUpStyles.addReviewHeader}>
              <Text style={PopUpStyles.reviewHeaderText}>{reviewText}</Text>
              <TouchableOpacity
                onPress={this.cancelpressed}
                style={PopUpStyles.cancelButton}
              >
                <Image source={images.cross} style={PopUpStyles.cancelStyle} />
              </TouchableOpacity>
            </View>
            <FullWidthDivider />
            <Text style={PopUpStyles.addReviewSubHeading}>
              {this.staticText.thankYouText}
            </Text>
            <Text style={PopUpStyles.addReviewSubHeading}>
              {this.staticText.uplaodVideoMsg}
            </Text>
            <this.ratingAndStars />
            {/* <this.addOrShowVideo reviewAdded={reviewAdded} /> */}
            <TouchableOpacity
              style={[
                PopUpStyles.submitButton,
                { marginBottom: 16 + Utility.bottomInset },
              ]}
              onPress={this.checkPermissions}
            >
              <Text style={PopUpStyles.submitText}>{submitText}</Text>
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addPostToLocalState,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(AddReviewModal),
);
