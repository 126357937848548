import React from 'react';
import { View, StyleSheet } from 'react-native';
import _ from 'lodash';

import {
  Concern,
  Ingredients,
  Ranking,
  Rating,
  SocialValidation,
  Trust,
  HonestReviews,
} from '../FoxyMatchProductCard';

const LayoutComponent = {
  Goal: Concern,
  Ingredients,
  Ranking,
  Rating,
  Trust,
  'Social Validation': SocialValidation,
  'Honest Reviews': HonestReviews,
};

const FoxyMatchItemProductCard = (props) => {
  const {
    item = {},
    heroIngredients,
    myAttributesValues,
    productCategoryName,
    rating,
    productPositiveIngredients,
    productNegativeIngredients,
    openList,
    scrollToRatings,
    goal,
    navigation,
    productCategorySlug,
    trusted,
    sku_id,
    id,
    matchedProduct,
  } = props;

  const { focus_area } = item;

  const LayoutComponentRender = LayoutComponent[`${focus_area}`];

  return (
    <LayoutComponentRender
      item={item}
      heroIngredients={heroIngredients}
      myAttributesValues={myAttributesValues}
      productCategoryName={productCategoryName}
      rating={rating}
      productPositiveIngredients={productPositiveIngredients}
      productNegativeIngredients={productNegativeIngredients}
      openList={openList}
      scrollToRatings={scrollToRatings}
      goal={goal}
      navigation={navigation}
      productCategorySlug={productCategorySlug}
      trusted={trusted}
      sku_id={sku_id}
      id={id}
      matchedProduct={matchedProduct}
    />
  );
};

export default FoxyMatchItemProductCard;
