export const CREATE_GROUP_DEAL = 'CREATE_GROUP_DEAL';
export const FETCH_GROUP_DEALS = 'FETCH_GROUP_DEALS';
export const PATCH_GROUP_DEAL_INVITE = 'PATCH_GROUP_DEAL_INVITE';
export const PATCH_GROUP_DEAL = 'PATCH_GROUP_DEAL';
export const ADD_TO_GROUP_DEAL = 'ADD_TO_GROUP_DEAL';

export const createGroupDeal = (skuId, contacts, callback, hostId) => {
  return {
    type: CREATE_GROUP_DEAL,
    skuId,
    contacts,
    callback,
    hostId,
  };
};

export const fetchGroupDeals = (callback) => {
  return {
    type: FETCH_GROUP_DEALS,
    callback,
  };
};

export const acceptGroupDealInvitation = (id, isAccepted, callback) => {
  return {
    type: PATCH_GROUP_DEAL_INVITE,
    id,
    isAccepted,
    callback,
  };
};

export const reinitializeReferral = (groupId, callback) => {
  return {
    type: PATCH_GROUP_DEAL,
    groupId,
    callback,
  };
};

export const addToGroupDeal = (id, callback) => {
  return {
    type: ADD_TO_GROUP_DEAL,
    id,
    callback,
  };
};
