export const setCookie = (name, value, daysToExpire) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + daysToExpire);

  const cookieValue = `${name}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}; path=/`;

  document.cookie = cookieValue;
};

export const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return null;
};

export const updateCookie = (name, newValue, daysToExpire) => {
  if (getCookie(name) !== null) {
    setCookie(name, newValue, daysToExpire);
  }
};

export const deleteCookie = (name) => {
  setCookie(name, '', -1); // Set the expiration date in the past to delete the cookie
};

export const getScreenClassification = () => {
  if (window.innerWidth < 768) {
    return 'mobile';
  }
  if (window.innerWidth >= 768 && window.innerWidth < 1103) {
    return 'tablet';
  }
  return 'desktop';
};
