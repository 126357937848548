import { StyleSheet } from 'react-native';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';

const styles = StyleSheet.create({
  adContainer: {
    flexDirection: 'row',
    margin: 8,
  },
  adText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#9d9d9d',
  },
  shortBannerStyle: {
    height: 117,
    margin: 4,
    backgroundColor: colors.background,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: Utility.getDynamicWidth(2, 8),
    backgroundColor: colors.white,
  },
  shortBannerCardText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 8,
  },
  bannerTextContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  largeBannerText: {
    marginLeft: 14,
  },
  smallBannerText: {
    marginLeft: 34,
  },
  largeImagePosition: {
    position: 'absolute',
    bottom: 0,
    marginLeft: 13,
  },
  smallImagePosition: {
    position: 'absolute',
    left: 0,
    bottom: 16,
  },
  bannerImage: {
    height: (1 / 3) * (Utility.getScreenWidth() - 24),
    borderRadius: 4,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: colors.border,
    backgroundColor: colors.white,
  },
});

export default styles;
