import React, { useState, useRef, useEffect } from 'react';
import { View, Animated, Easing } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import CouponDescription from './CouponDescription';

const CouponPrompt = (props) => {
  const {
    promptData,
    changeCouponState,
    style,
    loading,
    componentWidth,
    errors,
    setError = () => {},
    isValidOnSku,
    showToast = () => {},
    setUnfulfilledCoupon = () => {},
    image_url,
    navigateToInviteContacts,
    previousScreen = '',
  } = props;

  const selected = Utility.isPresent(promptData) ? promptData.selected : false;

  const [isActive, setIsActive] = useState(selected);

  const spin = useRef(new Animated.Value(0)).current; // Initial value for rotation: 0

  const easeIn = () => {
    Animated.timing(spin, {
      toValue: 1,
      duration: 200,
      easing: Easing.ease,
      useNativeDriver: true, // To make use of native driver for performance
    }).start();
  };

  const easeOut = () => {
    Animated.timing(spin, {
      toValue: 0,
      duration: 200,
      easing: Easing.ease,
      useNativeDriver: true, // To make use of native driver for performance
    }).start();
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevApplied = usePrevious(promptData.selected);
  useEffect(() => {
    if (Utility.isPresent(promptData) && prevApplied !== promptData.selected) {
      setIsActive(promptData.selected);
      if (promptData.selected) {
        easeIn();
      } else {
        easeOut();
      }
    }
    if (errors) {
      setIsActive(promptData.selected);
      if (!promptData.selected) {
        easeOut();
      }

      setError();
    }
    if (!isValidOnSku) {
      easeOut();
    }
  });

  if (Utility.isBlank(promptData)) {
    return null;
  }

  return (
    <View style={style}>
      <CouponDescription
        promptData={promptData}
        inActive={!isActive}
        loading={loading}
        couponCodeName={promptData.coupon_code}
        easeIn={easeIn}
        easeOut={easeOut}
        setIsActive={setIsActive}
        isValidOnSku={isValidOnSku}
        previousScreen={previousScreen}
        buttonAction={() => {
          if (
            promptData.status === 'active' &&
            promptData.reward_value_type === 'free_product'
          ) {
            setUnfulfilledCoupon({
              condition_unfullfilled: true,
              coupon_code: promptData.coupon_code,
              can_offer_fullfill: false,
              offer_id: promptData.offer_id,
              message: `You are now eligible for ${
                promptData?.reward_quantity || ''
              } FREE product`,
              addition_message: 'Add this product for free',
              images: [image_url],
              cta_text: 'View Products',
              cta_url: promptData.url,
              bar_percent: 0,
              expected_bar_percent: 0,
            });
          }
          if (promptData?.selected) {
            if (
              promptData.status === 'active' &&
              promptData.reward_value_type === 'free_product'
            ) {
              return;
            }
            showToast('Offer already applied');
            return;
          }

          if (!!promptData.auto && isActive) {
            setIsActive(false);
            return;
          }
          changeCouponState(
            promptData.coupon_code,
            promptData.applied,
            promptData,
          );
        }}
        navigateToInviteContacts={navigateToInviteContacts}
        componentWidth={componentWidth}
      />
    </View>
  );
};

export default CouponPrompt;
