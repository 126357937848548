// Dependencies
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  View,
  ScrollView,
  TouchableOpacity,
  ImageBackground,
  Text,
  Animated,
  FlatList,
  StyleSheet,
  findNodeHandle,
} from 'react-native';
import _ from 'lodash';
import {
  fetchRelatedContent,
  getArtistContent,
  fetchMorePageList,
} from '../../actions/ActionTypes';
import styles from './styles';
import { MAX_LINES, SCREEN } from '../../config/Constants';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { CustomNavigationHeader } from '../../components/Product';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import List from '../List/List';
import ConsumerProfileSelfieMarker from '../../components/onboarding/ImageMarkers/ConsumerProfileSelfieMarker';
import ImageTagsProcessingUtil from '../../utils/ImageTagsProcessingUtil';
import { goBackWithFallbackHome } from '../../utils/NavigationUtility';

class PersonalisedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollY: new Animated.Value(0),
      isCartVisible: false,
      viewRef: null,
    };
    this.sortFacialAttributes = true;
    this.previousScreen = SCREEN_CONSTANTS.PERSONALISED_PAGE;
    this.BannerImage = Animated.createAnimatedComponent(ImageBackground);
  }

  toggleCartVisibility = () => {
    this.props.navigation.push('Cart', {
      source: 'personalized_page',
    });
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  goBack = () => {
    const { navigation } = this.props;
    goBackWithFallbackHome(navigation);
  };

  dockedHeader = (props) => {
    const { name } = props;
    return (
      <View style={styles.dockedHeaderContainer}>
        <Text
          numberOfLines={1}
          style={styles.productNameInHeaderStyle}
          allowFontScaling={false}
        >
          {name}
        </Text>
      </View>
    );
  };

  imageLoaded = () => {
    this.setState({ viewRef: findNodeHandle(this.backgroundImage) });
  };

  banner = (props) => {
    let { banner_image_url, item } = props;
    if (Utility.isBlank(banner_image_url)) {
      if (item.objects.length > 0) {
        banner_image_url = item?.objects[0]?.image_url;
      }
    }
    return (
      <View style={styles.bannerStyle}>
        <Animated.Image //banner background
          useNativeDriver
          ref={(img) => {
            this.backgroundImage = img;
          }}
          source={{
            uri: banner_image_url,
          }}
          style={[
            styles.bannerImageStyle,
            {
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.67 * Utility.getScreenWidth() + 200,
                  0.67 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
            },
          ]}
          onLoadEnd={this.imageLoaded}
        />
        {/* <AnimatedBlurView //blurview
          style={[
            styles.bannerImageStyle,
            {
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.67 * Utility.getScreenWidth() + 200,
                  0.67 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
            },
          ]}
          viewRef={this.state.viewRef}
          blurType='light'
          blurAmount={2}
        /> */}

        {/* <Animated.View //Overlay
          style={[
            styles.imageOverlay,
            {
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.75 * Utility.getScreenWidth() + 200,
                  0.75 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              useNativeDriver: true,
            },
          ]}
        /> */}

        <View style={PersonalisedPageStyle.bannerContainer}>
          <View style={PersonalisedPageStyle.filterOverLay} />
          <Text
            numberOfLines={MAX_LINES.two}
            style={PersonalisedPageStyle.bannerText}
          >
            {item.name?.toUpperCase().split('-').join(' ')}
          </Text>
        </View>
      </View>
    );
  };

  _facialMarkerTags = (props) => {
    const { marker } = props;
    const { navigation } = this.props;

    return (
      <View style={PersonalisedPageStyle.attributesMarkerStyle}>
        <ConsumerProfileSelfieMarker
          shadowOpacity={Utility.isAndroid() ? 1.0 : 0.3}
          shadowElevation={1}
          title={marker.name}
          values={marker.values}
          onPressMarker={() => {
            navigation.navigate('AttributeSelector');
          }}
        />
      </View>
    );
  };

  topSection = () => {
    let {
      facialAnalysis: { facialProperties },
      profile,
      navigation,
      selfieImageUrl,
      listData,
      item,
      showToast,
      bannerImageUrl,
    } = this.props;
    let subHeading = '';
    const {
      content,
      banner_image_url,
      slug,
      share_url: shareUrl,
      id,
      type,
      name,
    } = listData;

    const { user_attribute_ids } = listData;
    if (
      this.sortFacialAttributes &&
      Utility.isPresent(profile.selfie_image_url) &&
      !Utility.isBlank(facialProperties)
    ) {
      const myAttribute = facialProperties.filter((item) =>
        user_attribute_ids.includes(item.id)
      );
      myAttribute.map((id) => {
        facialProperties.splice(facialProperties.indexOf(id), 1);
        facialProperties.unshift(id);
      });
      this.sortFacialAttributes = false;
    }

    facialProperties = ImageTagsProcessingUtil.getAllowedTags(facialProperties);
    const { scrollY } = this.state;
    let screenName = `personalised more`;
    return (
      <>
        {Utility.isPresent(profile.selfie_image_url) && (
          <this.banner
            banner_image_url={bannerImageUrl}
            content={content}
            item={listData}
            imageUrl={selfieImageUrl}
          />
        )}

        {Utility.isPresent(profile.selfie_image_url) && (
          <View style={PersonalisedPageStyle.attributesCardContainer}>
            <Text style={PersonalisedPageStyle.myAttributesHeading}>
              From your selfie
            </Text>
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate(SCREEN.ATTRIBUTE_SELECTOR);
              }}
            >
              <Text style={PersonalisedPageStyle.reviewAttributeText}>
                Update
              </Text>
            </TouchableOpacity>
          </View>
        )}

        <FlatList
          style={{ marginLeft: 5 }}
          data={facialProperties}
          horizontal
          showsHorizontalScrollIndicator={false}
          renderItem={({ item, index }) => {
            return (
              Utility.isPresent(item.values) && (
                <this._facialMarkerTags marker={item} />
              )
            );
          }}
        />
      </>
    );
  };

  render() {
    const {
      facialAnalysis: { facialProperties },

      navigation,

      listData,
      item,

      showToast,
      listIndex,
    } = this.props;

    const {
      content,
      banner_image_url,
      slug,
      share_url: shareUrl,
      id,
      type,
      name,
    } = listData;

    const { scrollY } = this.state;
    let screenName = 'personalised more';

    if (Utility.isBlank(listData.objects)) {
      return (
        <View
          style={{
            backgroundColor: colors.background,
            flex: 1,
          }}
        >
          <CustomNavigationHeader
            content={content}
            scrollY={scrollY}
            goBack={this.goBack}
            shareUrl={shareUrl}
            id={id}
            type={type}
            screenName={SCREEN_CONSTANTS.MORE_PAGE}
          />
          <this.topSection />
        </View>
      );
    }

    return (
      <>
        <ErrorBoundary screen_name={screenName} itemData={item}>
          <ScrollView
            style={{
              backgroundColor: colors.background,
            }}
            scrollEventThrottle={16}
            onScroll={Animated.event([
              { nativeEvent: { contentOffset: { y: scrollY } } },
            ])}
          >
            <this.topSection />

            <List
              item={listData}
              itemData={listData}
              navigation={navigation}
              id={listData.id}
              showBanner={false}
              toggleCartVisibility={this.toggleCartVisibility}
              previousScreen='personalised'
              showToast={showToast}
              listIndex={listIndex}
            />
            <View style={PersonalisedPageStyle.verticalSpacing} />
          </ScrollView>
        </ErrorBoundary>
      </>
    );
  }
}

const PersonalisedPageStyle = StyleSheet.create({
  attributesCardContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  myAttributesHeading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    color: '#999999',
    marginBottom: 10,
  },
  reviewAttributeText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    color: colors.cta.lightBlue,
    marginBottom: 10,
  },
  verticalSpacing: {
    width: '100%',
    height: 20,
    backgroundColor: colors.background,
  },
  attributesMarkerStyle: {
    marginRight: 8,
    marginTop: 5,
    marginBottom: 5,
  },
  bannerText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 36,
    fontStyle: 'normal',
    color: colors.white,
    textAlign: 'center',
    marginLeft: 8,
  },
  filterOverLay: {
    position: 'absolute',
    opacity: 0.4,
    width: '100%',
    height: '100%',
    zIndex: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerContainer: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { fetchRelatedContent, getArtistContent, fetchMorePageList },
    dispatch
  ),
});

const mapStateToProps = (store, ownProps) => {
  return {
    facialAnalysis: store.UserAccountInfo.facialAnalysis,
    profile: store.UserAccountInfo.profile,
    selfieImageUrl: store.UserAccountInfo.profile.selfie_image_url,
  };
};

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PersonalisedPage)
);
