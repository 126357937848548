import Utility from '../../utils/Utility';

export const CONTENT_PAGE_LAYOUT = {
  ProgressHeader: {
    height: 30.0,
  },
  ProductList: {
    //FIXME: Use productHeaderHeight from mediaDetailListContainer/styles
    horizontalListHeight: Utility.isAndroid() ? 116 : 98,
    
  },
};
