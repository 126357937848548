import React, { Component } from 'react';
import Config from '../../../libraries/ReactNativeConfig';
import { View, StyleSheet, Text, FlatList, BackHandler } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import {
  getOfferPrompt,
  addToCart,
  applyCartCoupon,
  removeOffer,
  getCartPricing,
  renderOfferShimmers,
} from '../../../actions/ActionTypes';
import DynamicLinkManager from '../../../utils/DynamicLinkManager';
import OfferPrompt from '../../prompts/OfferPrompt';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../../analytics';
import OfferShimmer from '../../shared/OfferShimmer';
import FullWidthShimmer from '../../../lib/FullWidthShimmer';
import { getPersonalisedLists } from '../../../actions/PersonalisedActions';
import FoxyAlert from '../../camera/shared/FoxyAlert';
import images from '../../../theme/Images';
import FastImageView from '../../FastImageView';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import {
  getTabsRoutes,
  navigateToScreen,
} from '../../../utils/NavigationUtility';
import { isBlank } from '../../../utils/BooleanUtility';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';

class PersonalisedOffersRail extends Component {
  static getComponentHeight(item) {
    return 241.2;
  }

  constructor(props) {
    super(props);
    const { route, horizontal } = this.props;

    this.horizontal = route.params?.horizontal ?? horizontal ?? true;

    this.enablePagination = route.params?.enablePagination ?? false;
    this.page = 0;

    this.state = {
      promptData: {},
      loading: true,
      errors: false,
      notClubbableCouponCode: '',
      notClubbableOfferId: '',
      notClubbableWith: {},
      errorMessage: '',
      unfulfilledCoupon: {},
      successfullyAppliedCoupon: {},
      horizontal: this.horizontal,
    };

    this.componentVisible = false;
    this.minEspOfferId = '';
    this.overlappingOfferIds = [];
    this.order = [];
    this.chooseGiftIdentifier = 'choose_free_gift';
    this.promptStyle = !this.horizontal
      ? styles.promptFullWidth
      : styles.prompt;

    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    const { renderOfferShimmers, navigation } = this.props;

    renderOfferShimmers(true);

    this.unsubscribe = navigation.addListener('focus', () => {
      this.refreshOfferList();
    });

    this.hitApiCall(true, false);
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 5000);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
    this.unsubscribe();
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  hitApiCall = (requiredRenderOfferShimmers, paginated) => {
    const { getPersonalisedLists, renderOfferShimmers } = this.props;
    const { promptData } = this.state;

    if (this.enablePagination && paginated) {
      this.page += 1;
    }

    getPersonalisedLists(
      'offers',
      (success, response) => {
        if (requiredRenderOfferShimmers) {
          renderOfferShimmers(false);
        }

        if (!success) {
          return;
        }

        let dataArray = response;
        if (paginated) {
          dataArray = [...promptData, ...response];
        }

        this.setState(
          {
            promptData: dataArray,
          },
          () => {
            this.paginateApiCall(response.length);
          },
        );
      },
      this.page,
    );
  };

  paginateApiCall = (arraySize) => {
    if (!this.enablePagination || arraySize < 5) {
      return;
    }

    this.hitApiCall(false, true);
  };

  refreshOfferList = () => {
    const { getPersonalisedLists, renderOfferShimmers } = this.props;
    this.page = 0;
    this.hitApiCall(true, false);
    // renderOfferShimmers(true);

    // getPersonalisedLists('offers', (success, response) => {
    //   renderOfferShimmers(false);
    //   this.setState({
    //     promptData: response,
    //   });
    // });
  };

  navigateToClubbableModal = () => {
    const { navigation } = this.props;
    const { notClubbableCouponCode, notClubbableOfferId, notClubbableWith } =
      this.state;
    navigation.navigate(SCREEN_CONSTANTS.CLUBBABLE_MODAL, {
      notClubbableCouponCode,
      navigation,
      notClubbableOfferId,
      notClubbableWith,
      applyOffer: this.forceApplyOffer,
    });
  };

  offerNotClubbable = (
    notClubbableCouponCode,
    notClubbableOfferId,
    notClubbableWith,
  ) => {
    this.setState(
      {
        notClubbableCouponCode,
        notClubbableOfferId,
        notClubbableWith,
      },
      this.navigateToClubbableModal,
    );
  };

  setUnfulfilledCoupon = (data) => {
    this.setState(
      {
        unfulfilledCoupon: data,
      },
      this.navigateToUnfulfilledModal,
    );
  };

  removeUnFullfilledCoupon = () => {
    this.setState({
      unfulfilledCoupon: {},
    });
  };

  setSuccessfullyAppliedCoupon = (data) => {
    this.setState({
      successfullyAppliedCoupon: data,
    });
  };

  removeSuccessfullyAppliedCoupon = () => {
    this.setState({
      successfullyAppliedCoupon: {},
    });
  };

  forceApplyOffer = (code) => {
    const { applyCartCoupon, hitApis } = this.props;
    renderOfferShimmers(true);
    applyCartCoupon(
      code,
      (success, callbackData) => {
        const appliedCoupon = callbackData?.prompts?.find(
          (coupon) => coupon.coupon_code === code,
        );
        if (success) {
          this.setSuccessfullyAppliedCoupon(appliedCoupon);
        }
        this.refreshOfferList();
        this.flatListRef?.scrollToOffset({ animated: true, y: 0 });
      },
      false,
      true,
    );
  };

  applyOffer = (code, id) => {
    const { applyCartCoupon, renderOfferShimmers, showToast } = this.props;

    renderOfferShimmers(true);

    setTimeout(() => {
      renderOfferShimmers(false);
    }, 5000);

    applyCartCoupon(
      code,
      (success, callbackData) => {
        renderOfferShimmers(false);
        this.refreshOfferList();
        if (Utility.isBlank(callbackData)) {
          this.setState({
            errors: true,
          });
          return;
        }
        if (success) {
          this.setSuccessfullyAppliedCoupon(callbackData);
          AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
            [EventParameterKey.MODAL_NAME]:
              EventParameterValue.MODAL_NAME.SUCCESS_OFFER,
            [EventParameterKey.offer_id]: callbackData?.offer_id,
          });
        } else if (callbackData.clubbable === false) {
          const offerPrompts = [];
          _.forEach(callbackData.offers_prompts, (e) => {
            offerPrompts.push(e.offer_id);
          });

          this.offerNotClubbable(
            callbackData.coupon_code,
            callbackData.offer_id,
            callbackData.offers_prompts,
          );
        } else {
          this.setState({
            errorMessage: `${callbackData.errors[0] || ''} `,
          });
        }

        this.refreshOfferList();
      },
      false,
      false,
    );
  };

  navigateToUnfulfilledModal = () => {
    const { navigation } = this.props;
    const { unfulfilledCoupon } = this.state;
    if (isBlank(unfulfilledCoupon)) return;
    navigation.navigate(SCREEN_CONSTANTS.UNFULFILLED_OFFER_MODAL, {
      navigation,
      itemData: unfulfilledCoupon,
      navigateToOfferDetail: this.onViewProductsTap,
    });
  };

  changeCouponState = (coupon, applied, promptData = {}) => {
    const {
      applyCartCoupon,
      showToast,
      removeOffer,
      skuId,

      getCartPricing,
      renderOfferShimmers = () => {},
    } = this.props;

    renderOfferShimmers(true);

    setTimeout(() => {
      renderOfferShimmers(false);
    }, 5000);

    if (!applied) {
      applyCartCoupon(coupon, (response, json) => {
        renderOfferShimmers(false);
        if (Utility.isBlank(json)) {
          this.setState({
            errors: true,
          });
          return;
        }
        if (response) {
          const appliedCoupon = json?.prompts?.find(
            (couponCode) => couponCode.coupon_code === coupon,
          );

          this.setSuccessfullyAppliedCoupon(appliedCoupon);
          AnalyticsManager.logEvent('offer_applied', {
            source: 'product_detail',
            coupon_code: coupon,
            offer_id: promptData.offer_id,
            offer_type: 'offer_coupon',
            offer_applied_by: 'user_applied',
          });
          AnalyticsManager.logFirebaseEvent(
            EventType.googleRemarketingEvents.SELECT_PROMOTION,
            {
              promotion_name: coupon,
              promotion_id: promptData.offer_id,
              items: [{ id: skuId }],
            },
          );
          this.flatListRef?.scrollToOffset({ animated: true, y: 0 });
        } else if (!!json.condition_unfullfilled) {
          this.setUnfulfilledCoupon(json);
          this.setState({
            errors: true,
          });
          AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
            [EventParameterKey.modal_name]:
              EventParameterValue.MODAL_NAME.UNFULLFILLED_OFFER,
            [EventParameterKey.offer_id]: json?.offer_id,
          });
        } else {
          this.setState({
            errors: true,
          });
          if (json.clubbable === false) {
            const offerPrompts = [];
            _.forEach(json.offers_prompts, (e) => {
              offerPrompts.push(e.offer_id);
            });
            AnalyticsManager.logEvent('modal_view', {
              modal_name: 'replace_offer',
              new_offer_id: json.offer_id,
              old_offer_ids: offerPrompts.toString(),
            });
            this.offerNotClubbable(
              json.coupon_code,
              json.offer_id,
              json.offers_prompts,
            );
            return;
          }
          if (Utility.isPresent(json.errors)) {
            this.setState({
              errorMessage: `${json.errors[0]}`,
            });
          }
        }
        getCartPricing();
      });
    }
    if (applied) {
      removeOffer(coupon, (response, json) => {
        if (response) {
          AnalyticsManager.logEvent('offer_remove', {
            source: 'product_detail',
            coupon_code: coupon,
            offer_id: promptData.offer_id,
            offer_type: 'offer_coupon',
            offer_applied_by: 'user_applied',
          });
        } else if (Utility.isPresent(json.errors)) {
          showToast(json.errors[0]);
        }
        getCartPricing();
      });
    }
  };

  setError = () => {
    this.setState({
      errors: false,
    });
  };

  getComponent = ({ item = [], index }) => {
    const {
      showChooseGiftScreen,
      isPromptLoadingShimmer,
      selectedVariantSku,
      showToast,
      previousScreen,
      listData,
      listData: { id: listId, slug: listSlug } = {},
      listIndex,
      parentListsData,
    } = this.props;
    const { promptData } = this.state;
    if (Utility.isBlank(promptData)) {
      return null;
    }

    const updatedParentListData = Utility.isPresent(parentListsData)
      ? parentListsData.concat({ listId, listSlug, item_position: listIndex })
      : [{ listId, listSlug, item_position: listIndex }];

    if (promptData?.length === 1) {
      this.promptStyle = styles.promptFullWidth;
    }

    const { valid_on_skus = [] } = item;

    const { errors } = this.state;

    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <OfferPrompt
          promptData={item}
          changeCouponState={this.changeCouponState}
          removeCoupon={this.changeCouponState}
          navigateToOfferDetail={this.navigateToOfferDetail}
          showChooseGiftScreen={showChooseGiftScreen}
          loading={isPromptLoadingShimmer}
          style={this.promptStyle}
          errors={errors}
          setError={this.setError}
          previousScreen={previousScreen}
          listData={listData}
          parentListsData={updatedParentListData}
          index={index}
          isValidOnSku={
            Utility.isPresent(selectedVariantSku) &&
            Utility.isPresent(valid_on_skus)
              ? valid_on_skus.includes(selectedVariantSku)
              : true
          }
          componentWidth={this.promptStyle.width}
          showToast={showToast}
        />
      </ErrorBoundaryComponent>
    );
  };

  handleRouteFromLink = (route, slug, path, extra) => {
    const { navigation } = this.props;
    navigation.push(route, { slug, extra });
  };

  onViewProductsTap = (url, id) => {
    const { navigation } = this.props;

    if (Utility.isBlank(url)) {
      return;
    }
    if (url.includes(this.chooseGiftIdentifier)) {
      navigation.push('ChooseFreeGiftScreen', {
        id,
      });
      return;
    }

    if (Utility.isPresent(url)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        url,
        (route, slug, path, extra) => {
          navigateToScreen({
            navigation,
            type: 'push',
            screen: route,
            params: { slug, extra },
          });
        },
      );
    }
  };

  navigateToOfferDetail = (item) => {
    const { url = '' } = item;
    const { navigation, previousScreen = 'product_details' } = this.props;

    if (Utility.isBlank(item)) {
      return;
    }

    AnalyticsManager.logEvent('prompt_action_click', {
      offer_id: item.offer_id,
      coupon_code: item.coupon_code,
      prompt_location: previousScreen,
      cta_text: Utility.isPresent(item.cta) ? item.cta : 'VIEW PRODUCTS',
      prompt_status: item.status,
    });

    if (Utility.isPresent(url)) {
      if (url === Config.HTTPS_DOMAIN_NAME) {
        navigation.navigate('Feed');
      }

      const promptData = {
        type: 'list',
        previousScreen: previousScreen,
        id: item.offer_id,
      };

      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        url,
        (route, slug, path, extra) => {
          if (getTabsRoutes().includes(route)) {
            navigation.navigate(route, { slug, extra });
            return;
          }
          navigation.push(route, { slug, extra, promptData });
        },
      );
    }
  };

  onScrollBeginDrag = () => {
    AnalyticsManager.logEvent('offer_scroll', {
      type: 'personalised',
    });
  };

  onFirstButtonPressForErrorMessage = () => {
    this.setState({
      errorMessage: '',
    });
  };

  closeSuccessOfferAppliedModal = () => {
    this.setState({
      successfullyAppliedCoupon: '',
    });
  };

  onFirstButtonPressForSuccessfulCoupon = () => {
    const { successfullyAppliedCoupon } = this.state;
    this.setState({
      successfullyAppliedCoupon: '',
    });
    this.onViewProductsTap(
      successfullyAppliedCoupon?.cta_url,
      successfullyAppliedCoupon?.offer_id,
    );
  };

  onPressEmptyStateButton = () => {
    const { navigation } = this.props;
    navigation.navigate('Feed');
  };

  emptyState = () => {
    if (this.horizontal) return null;
    const { halfHeightEmptyState } = this.props;
    const height = !halfHeightEmptyState
      ? Utility.getScreenHeight() - 100
      : 200;
    return (
      <View style={[styles.emptyStateContainer, { height }]}>
        <FastImageView source={images.discountCoupon} height={96} width={96} />
        <Text style={styles.emptyStateTitle}>No active offers found</Text>

        <TouchableOpacity
          style={styles.emptyStateActionButton}
          onPress={this.onPressEmptyStateButton}
        >
          <Text style={styles.emptyStateButtonText}>{'Start Shopping'}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  listNameContainer = () => {
    const { listData } = this.props;
    let headerName = listData?.name;
    let subtitle = listData?.subtitle;

    if (!this.horizontal) {
      headerName = 'Your personalised offers 💯';
      subtitle = ' Limited time offers available only to you';
    }

    if (Utility.isBlank(headerName)) return null;
    return (
      <View>
        <Text style={styles.title} ellipsizeMode='tail' numberOfLines={1}>
          {headerName || ''}
        </Text>
        <Text
          style={styles.subtitle}
          allowFontScaling={false}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {subtitle || ''}
        </Text>
      </View>
    );
  };

  flatListRef = (ref) => {
    this.flatListRef = ref;
  };

  keyExtractor = (item, index) => `${index}_grid`;

  render() {
    const {
      promptData,
      loading,
      errorMessage,
      successfullyAppliedCoupon,
      unfulfilledCoupon,
      horizontal,
    } = this.state;
    const { isOfferShimmerVisible, listData, navigation } = this.props;

    if (Utility.isBlank(promptData)) {
      if (loading) {
        return <OfferShimmer horizontal={horizontal} />;
      }
      if (!this.horizontal) {
        return <this.emptyState />;
      }
      return null;
    }

    return (
      <View>
        <this.listNameContainer />

        <FlatList
          ref={this.flatListRef}
          data={promptData}
          extraData={this.state}
          scrollEnabled
          onScrollBeginDrag={this.onScrollBeginDrag}
          horizontal={horizontal}
          showsHorizontalScrollIndicator={false}
          keyExtractor={this.keyExtractor}
          renderItem={this.getComponent}
        />
        {isOfferShimmerVisible && <FullWidthShimmer style={styles.shimmer} />}

        <FoxyAlert
          isVisible={Utility.isPresent(successfullyAppliedCoupon)}
          hideSecondButton
          alertBoxTitle={successfullyAppliedCoupon?.coupon_code}
          alertMessage={successfullyAppliedCoupon?.message}
          firstButtonTitle={
            Utility.isPresent(successfullyAppliedCoupon?.cta_text)
              ? successfullyAppliedCoupon?.cta_text
              : 'YAY !'
          }
          firstButtonOnPress={this.onFirstButtonPressForSuccessfulCoupon}
          onTapOutside={this.removeSuccessfullyAppliedCoupon}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showJson
        />

        <FoxyAlert
          isVisible={Utility.isPresent(errorMessage)}
          hideSecondButton
          alertBoxTitle='Oops'
          alertMessage={errorMessage}
          firstButtonTitle='Okay'
          firstButtonOnPress={this.onFirstButtonPressForErrorMessage}
          onTapOutside={this.onFirstButtonPressForErrorMessage}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showImage
          isAbsolute
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    marginHorizontal: 12,
    marginTop: 24,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#979BAA',
    marginBottom: 12,
    marginLeft: 12,
  },
  shimmer: {
    width: Utility.getScreenWidth(),
    flex: 1,
    height: '99%',
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 8,
    paddingBottom: 6,
  },

  emptyStateContainer: {
    height: Utility.getScreenHeight() - 100,
    justifyContent: 'center',
    alignItems: 'center',
  },

  emptyStateTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.black,
    marginTop: 15,
  },

  emptyStateActionButton: {
    height: 28,
    width: 180,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: colors.black,
    borderRadius: 16,
    marginTop: 20,
  },

  emptyStateButtonText: {
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    fontSize: size.h4,
  },
  prompt: {
    width: 280,
    height: 150,
    marginLeft: 12,
    marginRight: 12,
    elevation: 5,
    marginTop: 8,
    cursor: 'pointer',
  },
  promptFullWidth: {
    width: Utility.getScreenWidth() - 24,
    height: 150,
    marginLeft: 12,
    marginRight: 12,
    elevation: 5,
    marginTop: 8,
    cursor: 'pointer',
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getOfferPrompt,
      addToCart,
      applyCartCoupon,
      removeOffer,
      getCartPricing,
      renderOfferShimmers,
      getPersonalisedLists,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  isOfferShimmerVisible: state.bag.isOfferShimmerVisible,
  authToken: state.UserAccountInfo.authToken,
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PersonalisedOffersRail),
);
