import initialState from './initialState';

import {
  SAVE_CONTACTS,
  SAVE_ALREADY_INVITED_CONTACTS,
  SAVE_ACTIVE_CONTACTS,
} from '../actions/ContactsActions';

export default function contactsData(
  state = initialState.contactsData,
  action,
) {
  switch (action.type) {
    case SAVE_CONTACTS:
      return {
        ...state,
        contacts: action.contacts,
      };
    case SAVE_ALREADY_INVITED_CONTACTS:
      return {
        ...state,
        alreadyInvitedContacts: action.alreadyInvitedContacts,
      };
    case SAVE_ACTIVE_CONTACTS:
      return {
        ...state,
        activeContacts: action.activeContacts,
      };
    default:
      return state;
  }
}
