import moment from 'moment';
import { isBlank, isPresent } from './BooleanUtility';

export const getSelectedDateAndTime = (date = new Date(), time) => {
  const selectedTime = isPresent(time) ? time : moment().format('h:00 a');
  const formatDate = moment(date).format('MMM D');
  const dateAndTime = `${formatDate} ${selectedTime}`;
  return dateAndTime;
};

export const isTimestampCoveredInLastXhours = (
  timestamp,
  hoursThreshold,
) => {
  if (isBlank(hoursThreshold)) {
    return;
  }

  const currentTime = new Date();

  const timeDifference = currentTime - timestamp;

  const hoursDifference = timeDifference / (1000 * 60 * 60);
  return hoursDifference >= hoursThreshold || isBlank(timestamp);
};
