import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { denormalize } from 'normalizr';
import { List } from '../List';
import { getArtistContent } from '../../actions/ActionTypes';
import styles from './styles';
import { listSchema } from '../../config/Schema';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ARTIST_CONSTANTS } from '../../config/Constants';
import { StaticNavigationHeader } from '../../components/shared';

class ArtistContent extends Component {
  componentDidMount() {
    this.props.getArtistContent(ARTIST_CONSTANTS.artistContentSlug);
  }

  render() {
    const { itemData, navigation } = this.props;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    return (
      <>
        <View style={styles.container}>
          <StaticNavigationHeader title='Exclusive for Artists' />
          <List
            itemData={itemData}
            navigation={navigation}
            showBanner={false}
            id={itemData.id}
            listName={itemData.name}
            listContent={itemData.content}
          />
        </View>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ getArtistContent }, dispatch),
});

const mapStateToProps = (store, ownProps) => {
  itemData = ownProps.itemData;

  return {
    itemData,
  };
};

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(ArtistContent),
);
