import { fetch } from '@react-native-community/netinfo';
import { AnalyticsManager } from '../analytics';
import Utility from './Utility';

export default class NetworkStats {
  static networkDetails = null;

  static getNetworkInformation() {
    try {
      fetch().then((state) => {
        this.networkDetails = state;
        Utility.networkInfo = state;
      });
    } catch (e) {
      AnalyticsManager.logEvent('exception_captured', {
        source: 'addNetworkInfo',
      });
    }
  }
}
