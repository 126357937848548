import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  Text,
  KeyboardAvoidingView,
  Keyboard,
  ActivityIndicator,
} from 'react-native';
import Modal from 'react-native-modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TextField } from 'react-native-material-textfield';
import images from '../../theme/Images';
import { withEither } from '../../lib/Monads';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { PopUpStyles } from '../Product/styles';
import { addDeliveryInstruction } from '../../actions/OrderActions';
import { AnalyticsManager } from '../../analytics';

class AddDeliveryInstructionsModal extends Component {
  addEmailModalConstants = {
    invalidEmail: 'Email address is invalid',
  };

  constructor(props) {
    super(props);
    this.hitSlop = Utility.getHitSlop();
    this.ref = [];
    this.ref.phoneNumber = React.createRef();
    this.ref.instructions = React.createRef();
  }

  state = {
    alternateMobileNumber: '',
    deliveryInstruction: '',
    showLoader: false,
  };

  isIosFn = () => Utility.isIOS();

  customKeyboardAvoid = withEither(this.isIosFn, KeyboardAvoidingView)(View);

  moveToField = (fieldName) => {
    this.ref[fieldName]?.current?.focus();
  };

  mobileInput = () => {
    const { alternateMobileNumber } = this.state;
    return (
      <View style={PopUpStyles.addEmailContainer}>
        <TextField
          style={PopUpStyles.textInputStyleForEmail}
          label='Alternate Contact Number'
          ref={this.ref.phoneNumber}
          placeholder='9999999999'
          onChangeText={this.addAlternateMobileNumber}
          value={alternateMobileNumber}
          autoCapitalize={false}
          hideLabel={false}
          autoFocus
          keyboardType='phone-pad'
          inputContainerStyle={{ borderBottomColor: colors.border }}
          tintColor={colors.silver}
        />
      </View>
    );
  };

  onAddInstructions = (value) => {
    this.setState({
      deliveryInstruction: value,
    });
  };

  instructionsInput = () => {
    const { email } = this.state;
    return (
      <View style={PopUpStyles.addEmailContainer}>
        <TextField
          style={PopUpStyles.textInputStyleForEmail}
          label='Anything else'
          ref={this.ref.instructions}
          placeholder='Ex. Please give it to the guard'
          onChangeText={this.onAddInstructions}
          value={email}
          autoCapitalize={false}
          hideLabel={false}
          inputContainerStyle={PopUpStyles.inputContainerStyle}
          tintColor={colors.silver}
        />
      </View>
    );
  };

  cancelpressed = () => {
    const { hideEmailModal } = this.props;
    hideEmailModal();
    Keyboard.dismiss();
  };

  continuePressed = () => {
    const { deliveryInstruction, alternateMobileNumber } = this.state;
    const {
      addDeliveryInstruction,
      furtherAction,
      id,
      addOrderActionCard,
      status,
    } = this.props;

    const shipmentId = id.split('-')[1];
    if (id.split('-')[0] !== 'shipment') {
      return;
    }
    this.setState({
      showLoader: true,
    });

    AnalyticsManager.logEvent('modal_action', {
      modal_name: 'delivery_instruction',
      state: status,
      entity_type: id?.split('-')[0],
      entity_id: id?.split('-')[1],
    });

    const body = {
      instructions: deliveryInstruction,
      alternate_mobile_number: alternateMobileNumber,
      shipment_id: id?.split('-')[1],
    };

    addDeliveryInstruction(shipmentId, body, () => {
      this.setState({
        showLoader: false,
      });
      addOrderActionCard();
      this.cancelpressed();
    });
  };

  addAlternateMobileNumber = (value) => {
    let val = value.replace(/[^0-9]/g, '');
    val = val.slice(-10);
    if (val.length === 10) {
      this.moveToField('instructions');
    }
    this.setState({
      alternateMobileNumber: val,
    });
  };

  render() {
    const styles = PopUpStyles;
    const { isVisible } = this.props;
    const {
      showLoader,
      alternateMobileNumber = '',
      deliveryInstruction = '',
    } = this.state;
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.8}
        backdropTransitionOutTiming={0}
        style={styles.changePincodeModal}
        onBackdropPress={this.cancelpressed}
        swipeDirection="down"
        onSwipeComplete={this.cancelpressed}
      >
        <SafeAreaView style={PopUpStyles.addDeliveryInstructionContainer}>
          <this.customKeyboardAvoid
            behavior="position"
            enabled
            keyboardVerticalOffset={-18}
          >
            <View style={PopUpStyles.container}>
              <View style={PopUpStyles.addReviewHeader}>
                <Text style={PopUpStyles.reviewHeaderText}>
                  {'Add Delivery Instructions'}
                </Text>
                <TouchableOpacity
                  style={PopUpStyles.cancelButton}
                  onPress={this.cancelpressed}
                  hitSlop={this.hitSlop}
                >
                  <Image
                    source={images.cross}
                    style={PopUpStyles.cancelStyle}
                  />
                </TouchableOpacity>
              </View>
              <this.mobileInput />
              <this.instructionsInput />
              <TouchableOpacity
                style={PopUpStyles.submitButton}
                onPress={this.continuePressed}
                disabled={
                  Utility.isBlank(alternateMobileNumber) &&
                  Utility.isBlank(deliveryInstruction)
                }
              >
                <ActivityIndicator
                  style={styles.activityIndicatorInActionButton}
                  animating={showLoader}
                  color={colors.white}
                />
                <Text style={PopUpStyles.submitText}>{'Add'}</Text>
              </TouchableOpacity>
            </View>
          </this.customKeyboardAvoid>
        </SafeAreaView>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ addDeliveryInstruction }, dispatch),
});

export default connect(null, mapDispatchToProps)(AddDeliveryInstructionsModal);
