export const SAVE_CONTACTS = 'SAVE_CONTACTS';
export const SAVE_ALREADY_INVITED_CONTACTS = 'SAVE_ALREADY_INVITED_CONTACTS';
export const SAVE_ACTIVE_CONTACTS = 'SAVE_ACTIVE_CONTACTS';

export const saveContacts = (contacts = []) => {
  return {
    type: SAVE_CONTACTS,
    contacts,
  };
};

export const saveAlreadyInvitedContacts = (alreadyInvitedContacts) => {
  return {
    type: SAVE_ALREADY_INVITED_CONTACTS,
    alreadyInvitedContacts,
  };
};

export const saveActiveContacts = (activeContacts) => {
  return {
    type: SAVE_ACTIVE_CONTACTS,
    activeContacts,
  };
};
