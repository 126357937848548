import React, { Component } from 'react';
import {
  View,
  Text,
  FlatList,
  Linking,
  BackHandler,
  RefreshControl,
  StyleSheet,
} from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import withNavigation from '../../utils/WithNavigation';
import StaticNavigationHeader from '../shared/StaticNavigationHeader';
import {
  getOrderDetails,
  getShipmentDetails,
  heldOrder,
  cancelShipment,
} from '../../actions/OrderActions';
import LargeActionCard from '../layout/cards/LargeActionCard';
import Utility from '../../utils/Utility';
import PaymentDetails from './actionCards/PaymentDetails';
import OrderStatus from './actionCards/OrderStatus';
import colors from '../../theme/Colors';
import LargeActionRatingCard from './actionCards/LargeActionRatingCard';
import SmallActionCard from '../layout/cards/SmallActionCard';
import {
  renderReferralModal,
  setUpgradeToPrepaid,
  addOrderActionCard,
} from '../../actions/ActionTypes';
import AddDeliveryInstructionsModal from '../modals/AddDeliveryInstructionsModal';
import FoxyAlert from '../camera/shared/FoxyAlert';
import OptionsModal from '../modals/OptionsModal';
import RateProductCards from './actionCards/RateProductCards';
import CancelOrder from './actionCards/CancelOrder';
import OrderAddress from '../payment/paymentStatus/OrderAddress';
import DelayOffers from './actionCards/DelayOffers';
import OrderDetailShimmer from '../shared/OrderDetailShimmer';
import SelectSlotModal from '../modals/SelectSlotModal';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import images from '../../theme/Images';
import DelightOffer from './actionCards/DelightOffer';
import FirstAttemptFailActionCard from './actionCards/FirstAttemptFailActionCard';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import CancelShipment from './actionCards/CancelShipment';
import { URL } from '../../config/Constants';
import ListActionCard from './actionCards/ListActionCard';
import FoxyEdgeLoyaltyPlanCard from '../../containers/FoxyEdge/FoxyEdgeLoyaltyPlanCard';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import RoutineDetailsRail from '../Routines/RoutineDetails/RoutineDetailsRail';
import ContactsUsUtility from '../../utils/ContactUsUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isNative, isWeb } from '../../utils/BooleanUtility';
import { getFirebasePerformanceTrace } from '../../utils/PerformanceUtility';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

const ContainerComponent = {
  small_action_card: SmallActionCard,
  large_action_card: LargeActionCard,
  shipping_details: OrderAddress,
  large_action_rating_card: LargeActionRatingCard,
  order_status: OrderStatus,
  payment_details: PaymentDetails,
  rate_products: RateProductCards,
  cancel_order: CancelOrder,
  delay_offer: DelayOffers,
  delight: DelightOffer,
  delivery_fail_attempt_reasons: FirstAttemptFailActionCard,
  cancel_shipment: CancelShipment,
  list: ListActionCard,
};

if (isNative()) {
  ContainerComponent.loyalty_plan_touch_point = FoxyEdgeLoyaltyPlanCard;
  ContainerComponent.suggested_routines = RoutineDetailsRail;
}

class OrderDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      date: new Date(),
      open: false,
      isrequestCallbackConfirmationModalVisible: false,
      isUpdateDeliverystatusModalVisible: false,
      isCancelOrderModalVisible: false,
      showShimmer: false,
      refreshing: false,
      datePlusOne: '',
      skillName: '',
      showShipmentCancelSuccess: false,
      showShipmentCancelFailure: false,
    };
    const { navigation, route } = this.props;
    this.id = route.params?.id;
    if (Utility.isBlank(this.id)) {
      /**
       * After recent code merge, now are are receiving destination as whole url.
       * Still keeping params.url as a safe refactoring, because we don't know if params are changing on bases of navigation from different screens.
       */
      const {
        params: { url = '', slug = '', destination = '' },
      } = route;

      let urlLink = url;
      if (Utility.isBlank(urlLink) && Utility.isPresent(destination)) {
        urlLink = destination;
      }
      if (Utility.isPresent(urlLink) && Utility.isPresent(slug)) {
        this.id = `${urlLink.includes('order') ? 'Order' : 'Shipment'}-${slug}`;
      }
    }

    this.entity_type = this?.id?.split('-')[0];
    this.entity_id = this?.id?.split('-')[1];

    this.status = route.params?.status;
    this.renderProp = route.params?.renderProp;
    this.index = route.params?.index;
    this.totalShipments = route.params?.totalShipments || 0;
    this.merchantOrderName = route.params?.merchantOrderName || '';

    this.data = {};
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.message = `Hey! I need help with this order - #${this.entity_id}`;
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.ORDER_DETAILS);
    this.trace.start();
  }

  componentDidMount() {
    const { getOrderDetail, getShipmentDetail, navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.onFocus();
    });
    const fnName = `get${_.capitalize(this.entity_type)}Details`;
    AnalyticsManager.setCurrentScreen('orders');

    if (typeof this.props[fnName] !== 'function') {
      return;
    }

    this.setShowShimmer(true);
    this.props[fnName](this.entity_id, (success, response) => {
      this.setShowShimmer(false);
      this.trace.stop();
      if (success) {
        try {
          new JSONAPIDeserializer({
            typeAsAttribute: false,
            pluralizeType: true,
            keyForAttribute: 'camelCase',
          })
            .deserialize(response)
            .then((data) => {
              this.setState({ orderData: data }, () => {
                const { aasmState: orderAasmState = '', actionCards = [] } =
                  data;

                const cardIds = actionCards.map((card) => card.id);
                AnalyticsManager.logEvent('page_load_2', {
                  [EventParameterKey.PAGE]: this.entity_type,
                  [EventParameterKey.ID]: this.entity_id,
                  [EventParameterKey.AASM_STATE]: orderAasmState,
                  [EventParameterKey.CARD_IDS]: cardIds.join(','),
                });
              });
            });
        } catch (e) {}
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  setCancelOrderModalVisible = (show) => {
    this.setState({
      isCancelOrderModalVisible: show,
    });
  };

  onRefresh = () => {
    const { getOrderDetail, getShipmentDetail } = this.props;
    const fnName = `get${_.capitalize(this.entity_type)}Details`;
    this.setState({
      refreshing: true,
    });
    this.props[fnName](this.entity_id, (success, response) => {
      if (success) {
        this.setState({
          refreshing: false,
        });
        try {
          new JSONAPIDeserializer({
            typeAsAttribute: false,
            pluralizeType: true,
            keyForAttribute: 'camelCase',
          })
            .deserialize(response)
            .then((data) => {
              this.setState({ orderData: data });
            });
        } catch (e) {}
      }
    });
  };

  setShowShimmer = (showShimmer) => {
    this.setState({
      showShimmer,
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  setDate = (value, datePlusOne) => {
    this.setState({
      date: value,
      datePlusOne,
    });
  };

  setUpdateDeliverystatusModalVisible = (show) => {
    if (show) {
      AnalyticsManager.logEvent('modal_view', {
        modal_name: 'customer_status',
        entity_type: this.entity_type,
        entity_id: this.entity_id,
        status: this.status,
      });
    } else {
      AnalyticsManager.logEvent('modal_close', {
        modal_name: 'customer_status',
        entity_type: this.entity_type,
        entity_id: this.entity_id,
        status: this.status,
      });
    }
    this.setState({
      isUpdateDeliverystatusModalVisible: show,
    });
  };

  cancelOrder = (item, selectedReason) => {
    const { heldOrder } = this.props;

    AnalyticsManager.logEvent('card_action', {
      entity_type: this.entity_type,
      entity_id: this.entity_id,
      card_id: item.id,
      button: 'primary',
    });

    const data = {
      entity_id: this.entity_id,
      cancellation_reason: selectedReason.id,
    };

    heldOrder(data, (success) => {
      if (success) {
        AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
          source: 'held_order',
        });
      } else {
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            source: 'held_order',
          },
        );
      }
    });
  };

  cancelShipment = (item, selectedReason) => {
    const { cancelShipment } = this.props;

    AnalyticsManager.logEvent('card_action', {
      entity_type: this.entity_type,
      entity_id: this.entity_id,
      card_id: item.id,
      button: 'primary',
    });

    const data = {
      entity_id: this.entity_id,
      cancellation_reason: selectedReason?.id,
    };

    cancelShipment(data, (success) => {
      if (success) {
        this.setShowShipmentCancelSuccess(true);
        AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
          source: 'cancel_shipment',
        });
      } else {
        this.setShowShipmentCancelFailure(true);
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            source: 'cancel_shipment',
          },
        );
      }
    });
  };

  showDeliveryInstructionModal = (show) => {
    if (show) {
      AnalyticsManager.logEvent('modal_view', {
        modal_name: 'add_delivery_instruction',
        entity_type: this.entity_type,
        entity_id: this.entity_id,
        status: this.status,
      });
    } else {
      AnalyticsManager.logEvent('modal_close', {
        modal_name: 'add_delivery_instruction',
        entity_type: this.entity_type,
        entity_id: this.entity_id,
        status: this.status,
      });
    }
    this.setState({
      showDeliveryInstructionModal: show,
    });
  };

  setrequestCallbackConfirmationModal = (
    requestCallbackConfirmationModalVisible,
  ) => {
    const { addOrderActionCard } = this.props;
    const { skillName } = this.state;
    this.setState(
      {
        isrequestCallbackConfirmationModalVisible:
          requestCallbackConfirmationModalVisible,
      },
      () => {
        addOrderActionCard(this.data);
        if (requestCallbackConfirmationModalVisible) {
          AnalyticsManager.logEvent('modal_view', {
            modal_name: `${skillName}_confirmation`,
            entity_type: this.entity_type,
            entity_id: this.entity_id,
            status: this.status,
          });
        } else {
          AnalyticsManager.logEvent('modal_close', {
            modal_name: `${skillName}_confirmation`,
            entity_type: this.entity_type,
            entity_id: this.entity_id,
            status: this.status,
          });
        }
      },
    );
  };

  renderReferralModal = () => {
    const { renderReferralModal } = this.props;

    renderReferralModal(true);
  };

  goToPayments = () => {
    const { navigation, setUpgradeToPrepaid } = this.props;
    const { orderData: { id, totalAmount } = {} } = this.state;
    setUpgradeToPrepaid({
      isUpgradeToPrepaid: true,
      orderId: id,
    });

    navigation.navigate('Payments', { UtoPOrderAmount: totalAmount });
  };

  uploadVideo = () => {
    const { navigation } = this.props;
    navigation.push('UploadUnboxingVideo', {
      addOrderActionCard: this.addShipmentLostCustomerStatus,
    });
  };

  rateExperience = () => {
    const { navigation } = this.props;
    const {
      orderData: { orderId = '', id = '' },
    } = this.state;
    navigation.navigate('WebUrlView', {
      destination: `https://foxyapp.typeform.com/to/cpRO4mCd#order_id=${
        orderId || id
      }&source=orderpage`,
    });
  };

  navigateToContactUs = () => {
    const { navigation } = this.props;
    const {
      orderData: {
        aasmState: orderAasmState = '',
        deliveryDate,
        id = '',
        orderId = '',
      },
      orderData,
    } = this.state;

    navigation.navigate('ContactUsReasonPage', {
      entityType: this.entity_type,
      entityId: this.entity_id,
      orderAasmState,
      deliveryDate,
      orderId: orderId || id,
    });
  };

  setOpen = (open, skillName) => {
    if (open) {
      AnalyticsManager.logEvent('modal_view', {
        modal_name: skillName,
        entity_type: this.entity_type,
        entity_id: this.entity_id,
        status: this.status,
      });
    } else {
      AnalyticsManager.logEvent('modal_close', {
        modal_name: skillName,
        entity_type: this.entity_type,
        entity_id: this.entity_id,
        status: this.status,
      });
    }

    this.setState({
      open,
      skillName,
    });
  };

  openMail = () => {
    const {
      orderData: { orderId },
    } = this.state;
    Linking.openURL(
      `mailto:escalations@foxy.in?subject=${
        Config.APP_NAME
      } Delivery Escalation  #order ${
        orderId || this.entity_id
      }&placeholder=Escalate your concerns directly to our head of customer service …`,
    );

    this.addShipmentLostCustomerStatus();
  };

  primaryButton = (primaryButtonAction, item) => {
    const { addOrderActionCard } = this.props;
    const { orderCardAction = [] } = this.props;
    const {
      orderData: {
        aasmState: orderAasmState = '',
        orderId = '',
        invoiceFileUrl = '',
      },
    } = this.state;
    const orderCardForThisOrder =
      orderCardAction[`${this.entity_type}_${this.entity_id}`] || {};
    const orderCard = orderCardForThisOrder[item.id] || {};

    this.data = {
      id: item.id,
      primaryAction: true,
      secondaryAction: orderCard?.secondaryAction || false,
      isSecondaryActionPresent: Utility.isPresent(item.secondaryButtonText),
      entity_type: this.entity_type,
      entity_id: this.entity_id,
    };

    AnalyticsManager.logEvent('card_action', {
      [EventParameterKey.ENTITY_TYPE]: this.entity_type,
      [EventParameterKey.ENTITY_ID]: this.entity_id,
      [EventParameterKey.AASM_STATE]: orderAasmState,
      [EventParameterKey.CARD_ID]: item.id,
      [EventParameterKey.BUTTON]: 'primary',
    });

    if (item?.displayType === 'large_action_rating_card') {
      return;
    }

    if (item?.displayType === 'delivery_fail_attempt_reasons') {
      return;
    }

    switch (primaryButtonAction) {
      case 'rate_experience':
        this.rateExperience();
        break;

      case 'add_delivery_instructions': {
        this.showDeliveryInstructionModal(true);
        break;
      }
      case 'chat_with_us': {
        ContactsUsUtility.onContactUsOptionPress();
        break;
      }
      case 'prepay_and_save': {
        this.goToPayments();
        break;
      }
      case 'get_detailed_status_from_brand': {
        this.openMail();
        break;
      }

      case 'referral': {
        if (isNative()) {
          this.renderReferralModal();
        }
        break;
      }
      case 'mild_delay': {
        this.rateExperience();
        break;
      }
      case 'severe_delay': {
        this.rateExperience();
        break;
      }
      case 'upload_video': {
        this.uploadVideo();
        break;
      }
      case 'download_invoice': {
        if (invoiceFileUrl) {
          Linking.openURL(invoiceFileUrl);
        } else {
          Utility.openWhatsApp(
            encodeURIComponent(
              `Hey! I need invoice for this order #${orderId}-${this.entity_id}`,
            ),
            ContactsUsUtility.openWhatsappAlert,
          );
        }
        break;
      }

      case 'update_shipment_status': {
        this.setUpdateDeliverystatusModalVisible(true);
        break;
      }

      default:
    }
  };

  invokeReturnPrimeFlow = () => {
    const { navigation } = this.props;
    const {
      orderData: { orderEmail },
    } = this.state;

    const orderNumber = this.merchantOrderName?.replace('#', '');
    const shopifyStore = Config.SHOPIFY_STORE_ACCOUNT;
    const channelId = Config.RETURN_PRIME_CHANNEL_ID;

    const returnPrimeUrl = `${URL.RETURN_PRIME_URL}?order_number=${orderNumber}&email=${orderEmail}&store=${shopifyStore}&channel_id=${channelId}`;

    navigation.navigate('WebUrlView', {
      disableHttpErrorReturn: true,
      newDestination: returnPrimeUrl,
    });
  };

  returnAndExchangeFlow = () => {
    const flow = Config.RETURN_EXCHANGE_SUPPORTED_FLOW;

    switch (flow) {
      case 'return_prime':
        this.invokeReturnPrimeFlow();
        break;

      default:
        break;
    }
  };

  secondaryButton = (secondaryButtonAction, item) => {
    const { orderCardAction = [] } = this.props;
    const { secondaryButtonExternalLink } = item;
    const {
      orderData: { aasmState: orderAasmState = '', deliveryDate },
    } = this.state;
    this.setState({
      skillName: secondaryButtonAction,
    });

    const orderCardForThisOrder =
      orderCardAction[`${this.entity_type}_${this.entity_id}`] || {};
    const orderCard = orderCardForThisOrder[item.id] || {};

    this.data = {
      id: item.id,
      secondaryAction: true,
      primaryAction: orderCard?.primaryAction || false,
      isSecondaryActionPresent: Utility.isPresent(item.secondaryButtonText),
      entity_type: this.entity_type,
      entity_id: this.entity_id,
    };
    AnalyticsManager.logEvent('card_action', {
      [EventParameterKey.ENTITY_TYPE]: this.entity_type,
      [EventParameterKey.ENTITY_ID]: this.entity_id,
      [EventParameterKey.AASM_STATE]: orderAasmState,
      [EventParameterKey.CARD_ID]: item.id,
      [EventParameterKey.BUTTON]: 'secondary',
    });

    if (Utility.isPresent(secondaryButtonExternalLink)) {
      Linking.openURL(secondaryButtonExternalLink);
      return;
    }

    if (secondaryButtonAction === 'return_and_exchange') {
      this.returnAndExchangeFlow();
      return;
    }

    if (AppConfig.getBooleanValue(Config.ENABLE_ORDER_PAGE_CONTACT_US_MODAL)) {
      ContactsUsUtility.onContactUsOptionPress();
    } else if (secondaryButtonAction === 'contact_us') {
      this.navigateToContactUs();
    } else {
      this.setOpen(true, secondaryButtonAction);
    }
  };

  keyExtractor = (item = {}) => item?.id;

  addShipmentLostCustomerStatus = () => {
    const { addOrderActionCard } = this.props;
    addOrderActionCard(this.data);
  };

  showEndState = (id) => {
    const { orderCardAction = [] } = this.props;
    const orderCardForThisOrder =
      orderCardAction[`${this.entity_type}_${this.entity_id}`] || {};
    const orderCard = orderCardForThisOrder[id] || {};
    let secondaryShown = true;
    const primaryShown = orderCard.primaryAction;
    if (orderCard?.isSecondaryActionPresent) {
      secondaryShown = orderCard.secondaryAction;
    }

    return primaryShown && secondaryShown;
  };

  setShowShipmentCancelSuccess = (show) => {
    this.setState({
      showShipmentCancelSuccess: show,
    });
  };

  setShowShipmentCancelFailure = (show) => {
    this.setState({
      showShipmentCancelFailure: show,
    });
  };

  renderItem = ({ item = {}, index }) => {
    if (Utility.isBlank(item)) return null;
    const {
      heading = '',
      subHeading = '',
      paymentMethod,
      itemTotal,
      deliveryFee,
      discount,
      codCharges,
      totalAmount,
      orderItems,
      coupons,
      aasmState,
      primaryButtonText,
      secondaryButtonText,
      primaryButtonAction,
      secondaryButtonAction,
      primaryButtonIcon,
      secondaryButtonIcon,
      backgroundColor,
      cardImageUrl,
      displayType,
      cancellationReasons,
      detailedSubheading,
      plasticFreePackagingCharges,
      id: actionCardsId,
      suggestedRoutines,
    } = item;
    if (isWeb() && (primaryButtonAction === 'referral' || secondaryButtonAction === 'referral')) {
      return null;
    }
    const {
      orderData: {
        id,
        totalAmount: orderTotalAmount,
        orderItems: orderItemsData,
        awbNumber,
        trackingUrl,
        orderId,
        deliveryDate,
        delay,
        displayPaymentMode,
        orderAddress = {},
        aasmState: orderAasmState = '',
        appRating,
        isDelightMissed,
        invoiceFileUrl,
      },
      isCancelOrderModalVisible,
      showShipmentCancelSuccess = false,
      showShipmentCancelFailure = false,
    } = this.state;

    const { navigation } = this.props;

    const metaData = {
      paymentMethod,
      itemTotal,
      deliveryFee,
      discount,
      codCharges,
      totalAmount,
      orderItems,
      coupons,
      id,
      orderTotalAmount,
      status: this.status,
      aasmState,
      plasticFreePackagingCharges,
    };

    const LayoutComponent = ContainerComponent[`${displayType}`];
    if (LayoutComponent === undefined) {
      return null;
    }

    let primaryButtonTitle = primaryButtonText;
    if (
      AppConfig.getBooleanValue(Config.ENABLE_ORDER_PAGE_CONTACT_US_MODAL) &&
      primaryButtonAction === 'chat_with_us'
    ) {
      primaryButtonTitle = 'Contact Us';
    }
    return (
      <View style={styles[`${displayType}`]}>
        <LayoutComponent
          backgroundColor={backgroundColor}
          imageUrl={cardImageUrl}
          heading={heading}
          subheading={subHeading}
          firstActionButtonText={primaryButtonTitle}
          buttonWidth={Utility.getScreenWidth() - 52}
          metaData={metaData}
          secondaryActionButtonText={secondaryButtonText}
          primaryButtonAction={_.memoize(
            () => this.primaryButton(primaryButtonAction, item),
            () => [primaryButtonAction, item.id],
          )}
          secondaryButtonAction={_.memoize(
            () => this.secondaryButton(secondaryButtonAction, item),
            () => [item.id],
          )}
          orderItemsData={orderItemsData}
          setCancelOrderModalVisible={this.setCancelOrderModalVisible}
          isCancelOrderModalVisible={isCancelOrderModalVisible}
          cancellationReasons={cancellationReasons}
          index={this.index}
          totalShipments={this.totalShipments}
          awbNumber={awbNumber}
          trackingUrl={trackingUrl}
          orderId={orderId || id}
          detailedSubheading={detailedSubheading}
          primaryButtonIcon={primaryButtonIcon}
          secondaryButtonIcon={secondaryButtonIcon}
          navigation={navigation}
          showEndState={this.showEndState(item.id)}
          deliveryDate={deliveryDate}
          delay={delay}
          shipment_id={id}
          displayPaymentMode={displayPaymentMode}
          primaryButtonActionCode={primaryButtonAction}
          orderAddress={orderAddress}
          onRefresh={this.onRefresh}
          addOrderActionCard={this.addShipmentLostCustomerStatus}
          orderAasmState={orderAasmState}
          appRating={appRating}
          id={this.id}
          item={item}
          isDelightMissed={isDelightMissed}
          cancelOrder={this.cancelOrder}
          merchantOrderName={this.merchantOrderName}
          cancelShipment={this.cancelShipment}
          showShipmentCancelSuccess={showShipmentCancelSuccess}
          showShipmentCancelFailure={showShipmentCancelFailure}
          setShowShipmentCancelFailure={this.setShowShipmentCancelFailure}
          setShowShipmentCancelSuccess={this.setShowShipmentCancelSuccess}
          previousScreen={SCREEN_CONSTANTS.ORDER_DETAILS}
          actionCardsId={actionCardsId}
          invoiceFileUrl={invoiceFileUrl}
          suggestedRoutines={suggestedRoutines}
        />
      </View>
    );
  };

  onFocus = () => {
    Utility.setStatusBarColor(colors.white);
  };

  otherShipments = () => {
    if (this.totalShipments <= 1) {
      return null;
    }
    return (
      <View style={styles.otherShipments}>
        <Text style={styles.otherShipmentsText}>
          Other Shipments In This Order
        </Text>
        {this.renderProp && (
          <this.renderProp
            fromOrderDetails
            shipment_id={this.entity_id}
            item_status={this.status}
          />
        )}
      </View>
    );
  };

  onSlotModalConfirm = (dateRequested, datePlusOnes) => {
    this.setOpen(false);
    this.setDate(dateRequested, datePlusOnes);
    setTimeout(() => {
      this.setrequestCallbackConfirmationModal(true);
    }, 500);
  };

  onRequestCallbackModalAction = () => {
    const { skillName } = this.state;
    AnalyticsManager.logEvent('modal_action', {
      modal_name: `${skillName}`,
      state: this.status,
      entity_type: this.entity_type,
      entity_id: this.entity_id,
    });
    this.setrequestCallbackConfirmationModal(false);
  };

  render() {
    const {
      orderData: { actionCards = [], id, orderId },
      showDeliveryInstructionModal,
      open,
      date,
      isrequestCallbackConfirmationModalVisible,
      isUpdateDeliverystatusModalVisible,
      showShimmer,
      refreshing,
      datePlusOne,
      skillName,
    } = this.state;

    if (showShimmer) {
      return (
        <>
          <StaticNavigationHeader
            title={`${this.entity_type?.toUpperCase()} #${this.entity_id}`}
          />
          <OrderDetailShimmer />
        </>
      );
    }

    return (
      <>
        <StaticNavigationHeader
          title={`${this.entity_type?.toUpperCase()} DETAILS`}
        />

        <FlatList
          data={actionCards}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={() => {}} />
          }
          keyExtractor={this.keyExtractor}
          extraData={this.state}
          renderItem={this.renderItem}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={this.otherShipments}
          contentContainerStyle={styles.contentContainerStyle}
          initialNumToRender={actionCards.length}
        />
        <AddDeliveryInstructionsModal
          isVisible={showDeliveryInstructionModal}
          hideEmailModal={_.memoize(
            () => this.showDeliveryInstructionModal(false),
            () => [false],
          )}
          id={this.id}
          addOrderActionCard={this.addShipmentLostCustomerStatus}
          status={this.status}
        />
        <SelectSlotModal
          isVisible={open}
          hideEmailModal={_.memoize(
            () => this.setOpen(false),
            () => [false],
          )}
          id={this.id}
          addOrderActionCard={this.addShipmentLostCustomerStatus}
          onConfirm={this.onSlotModalConfirm}
          skillName={skillName}
          status={this.status}
          orderId={orderId || id}
        />

        <FoxyAlert
          isVisible={isrequestCallbackConfirmationModalVisible}
          hideSecondButton
          alertBoxTitle={'We have noted your request'}
          alertMessage={`Our team will call you on ${moment(date).format(
            'Do MMMM',
          )} between ${moment(date).format('h A')} - ${datePlusOne}.`}
          firstButtonTitle={'okay'}
          firstButtonOnPress={this.onRequestCallbackModalAction}
          onTapOutside={_.memoize(
            () => {
              this.setrequestCallbackConfirmationModal(false);
            },
            () => [false],
          )}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.successInfo}
          showImage
        />
        <OptionsModal
          isVisible={isUpdateDeliverystatusModalVisible}
          id={this.id}
          status={this.status}
          onDismiss={_.memoize(
            () => this.setUpdateDeliverystatusModalVisible(false),
            () => [false],
          )}
          addOrderActionCard={this.addShipmentLostCustomerStatus}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    paddingBottom: 32,
    backgroundColor: colors.background,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  otherShipments: {
    backgroundColor: colors.white,
    marginTop: 12,
    paddingHorizontal: 16,
  },
  otherShipmentsText: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    textAlignVertical: 'center',
    marginVertical: 8,
  },
  small_action_card: {
    margin: 12,
    padding: 0,
    backgroundColor: colors.background,
  },
  large_action_card: {
    margin: 0,
    padding: 6,
    backgroundColor: colors.white,
  },
  shipping_details: {
    marginVertical: 12,
    padding: 0,
    backgroundColor: colors.white,
  },
  large_action_rating_card: {
    margin: 0,
    padding: 6,
    backgroundColor: colors.white,
  },
  order_status: {
    marginVertical: 12,
    padding: 0,
    backgroundColor: colors.white,
  },
  payment_details: {
    margin: 0,
    padding: 0,
    backgroundColor: colors.white,
  },
  rate_products: {
    margin: 0,
    padding: 6,
    backgroundColor: colors.white,
  },
  cancel_order: {
    margin: 0,
    padding: 0,
    backgroundColor: colors.white,
  },
  delay_offer: {
    margin: 0,
    padding: 6,
    backgroundColor: colors.white,
  },
  delight: {
    marginTop: 0,
    padding: 6,
    backgroundColor: colors.white,
  },
});

const mapStateToProps = (state) => ({
  orderCardAction: state.UserAccountInfo.orderActionsCards,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getOrderDetails,
      getShipmentDetails,
      setUpgradeToPrepaid,
      renderReferralModal,
      addOrderActionCard,
      heldOrder,
      cancelShipment,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetails),
);
