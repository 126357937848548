import { View, StyleSheet, Image } from 'react-native';
import React from 'react';
import MoreItemsList from '../feed/MoreItemsList';
import NavigationStacksUtility from '../../utils/NavigationStacksUtility';
import images from '../../theme/Images';


export default function Brands() {
  return (
    <View style={styles.container}>
      <MoreItemsList slug="/api/v6/lists/shop-by-brand.json?" hideHeader />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export const brandsHeaderOption = NavigationStacksUtility.getListTabHeaderOptions({
  title: 'Brands',
});

export const BrandsStackNavigationOptions = {
  tabBarLabel: 'Brands',
  tabBarActiveTintColor: '#EC564F',
  tabBarInactiveTintColor: '#979BAA',
  tabBarStyle: {
    borderTopWidth: 0,
    elevation: 0,
  },
  tabBarOptions: {
    showIcon: true,
    showLabel: true,
    labelStyle: {
      fontSize: 11,
      fontFamily: 'Roboto-Regular',
    },
    iconStyle: {
      flexGrow: 0,
    },
  },
  tabBarIcon: ({ focused }) => {
    const image = focused ? images.brandsActiveIcon : images.brandsInactiveIcon;
    const activeColor = '#EC564F';
    const tintColor = focused ? activeColor : '#979BAA';
    return (
      <Image
        testID="brands-tab-icon"
        accessibilityLabel="brands-tab-icon"
        source={image}
        style={{
          height: 24,
          width: 24,
          resizeMode: 'contain',
          tintColor,
        }}
      />
    );
  },
};
