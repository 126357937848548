import React, { Component } from 'react';
import withNavigation from '../../../utils/WithNavigation';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { TABBAR_CONSTANT } from '../../../config/Constants';
import withTabNavigationHeader from '../../../lib/withTabNavigationHeader';

class ScanPlaceholder extends Component {
  scanPlaceholderConstants = {
    headerText: 'Coming Soon',
    contentTop:
      'You will be able to watch expert reviews, check offers and compare prices just by scanning a product. We will notify you once this feature is live.',
  };

  constructor(props) {
    super(props);
    const { navigation, route } = props;
    this.navListener = navigation.addListener('focus', () => {
      this.previousScreen = route.params?.previousScreen ?? '';
      navigation.navigate('ScanProduct', {
        previousScreen: this.previousScreen,
      });
    });
  }

  componentWillUnmount() {
    this.navListener();
  }

  onDidFocus = () => {
    this.navigateToScan();
  };

  goBack = () => {
    const { navigation, route } = this.props;
    const previousScreen = route.params?.previousScreen ?? '';
    if (previousScreen === SCREEN_CONSTANTS.COLLECTION) {
      navigation.navigate('MyCollection');
    }
  };

  render() {
    return null;
  }
}

export default withNavigation(
  withTabNavigationHeader(ScanPlaceholder, { type: TABBAR_CONSTANT.scan }),
);
