import initialState from './initialState';

import { REMOVE_FOLLOW_CARD } from '../actions/FeatureCardActions';

export default function followFeatureCard(
  state = initialState.followFeatureCard,
  action,
) {
  switch (action.type) {
    case REMOVE_FOLLOW_CARD:
      return {
        ...state,
        dismissedOrFollowedCardIds: [
          ...state.dismissedOrFollowedCardIds,
          action.id,
        ],
      };

    default:
      return state;
  }
}
