import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';

import {
  GET_PAYOFF,
  GET_ALL_REVIEWS,
  GET_LIST_DATA,
  GET_MEMBER_PAGE,
} from '../actions/PayoffActions';
import Utility from '../utils/Utility';
import { URL } from '../config/Constants';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

function* getPayoffAsync(action) {
  const { callback = () => {}, page, pageNo = 0 } = action;
  const url = `${URL.PAYOFF}${page}?page=${pageNo}`;

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.tron.log(` error in getting payoff page${JSON.stringify(error)}`);
  }
}

function* getAllReviewsAsync(action) {
  const { callback = () => {}, page, firstVideoId } = action;
  let videoId = firstVideoId;
  if (page > 0) {
    videoId = 0;
  }
  const url = `${URL.REVIEWS}${page}&video_id=${videoId}`;
  // console.tron.log('REVIEWS_URL', url);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.tron.log(` error in getting payoff page${JSON.stringify(error)}`);
  }
}

function* getMemberPageAsync(action) {
  console.tron.log('getListDataAsync', action);
  const { callback = () => {}, page } = action;

  const url = `${URL.ALT_BRANDS}${page}`;

  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false, { connectionError: true });
  }, 25000);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    clearTimeout(timer);
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401 && sendCallbackData) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300 && sendCallbackData) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.tron.log(` error in getting list page${JSON.stringify(error)}`);
  }
}

function* getListDataAsync(action) {
  console.tron.log('getListDataAsync', action);
  const { callback = () => {}, page, pageNo = 0 } = action;
  let url = `${URL.ALT_BRANDS}${page}?page=${pageNo}`;

  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false, { connectionError: true });
  }, 25000);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    clearTimeout(timer);
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401 && sendCallbackData) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300 && sendCallbackData) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.tron.log(` error in getting list page${JSON.stringify(error)}`);
  }
}

export default function* watchPayoffSaga() {
  yield takeEvery(GET_PAYOFF, getPayoffAsync);
  yield takeEvery(GET_LIST_DATA, getListDataAsync);
  yield takeLatest(GET_ALL_REVIEWS, getAllReviewsAsync);
  yield takeLatest(GET_MEMBER_PAGE, getMemberPageAsync);
}
