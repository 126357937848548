import { Text, View, FlatList, TouchableOpacity } from 'react-native';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCancelReason } from '../../../actions/ActionTypes';
import styles from './styles';
import withNavigation from '../../../utils/WithNavigation';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import { StaticNavigationHeader } from '../../../components/shared';
import CancelOrder from '../cancelOrder/CancelOrder';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';

class CancelOrderReasonPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCancelReason: '',
      cancelReasons: [],
      selectedCancelReasonId: '',
      isShowError: false,
    };
    const { navigation, route } = this.props;
    this.headerTitle = route.params?.headerTitle;
    this.orderId = route.params?.orderId;
    this.entityType = route.params?.entityType;
    this.orderAasmState = route.params?.orderAasmState;
    this.entityId = route.params?.entityId;
  }

  componentDidMount() {
    const { navigation, getCancelReason } = this.props;
    getCancelReason(this.getCancelReasonCallBack);
  }

  getCancelReasonCallBack = (success, response) => {
    if (success) {
      this.setState({
        cancelReasons: response.data,
      });
      AnalyticsManager.logEvent('page_load_2', {
        [EventParameterKey.PAGE]: SCREEN_CONSTANTS.CANCEL_ORDER_REASON_PAGE,
      });
    }
  };

  onPressCancelReason = (item, id) => {
    this.setState({
      selectedCancelReason: item,
      selectedCancelReasonId: id,
      isShowError: false,
    });
  };

  reasonList = ({ item, index }) => {
    const {
      attributes: { value = '' },
      id,
    } = item;
    const { selectedCancelReasonId } = this.state;
    const isSelectedReasonStyle =
      selectedCancelReasonId === id
        ? styles.selectedReasonContainerStyle
        : styles.reasonListContainer;
    return (
      <TouchableOpacity
        style={isSelectedReasonStyle}
        onPress={() => this.onPressCancelReason(value, id)}
      >
        <Text style={styles.reasonTextStyle}>{value}</Text>
      </TouchableOpacity>
    );
  };

  bottomButtonAction = () => {
    const { selectedCancelReason, selectedCancelReasonId } = this.state;
    const { navigation } = this.props;
    if (Utility.isBlank(selectedCancelReasonId)) {
      this.setState({
        isShowError: true,
      });
      return;
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.TYPE]: selectedCancelReason.slice(0, 45),
      [EventParameterKey.ENTITY_ID]: this.entityId,
      [EventParameterKey.ENTITY_TYPE]: this.entityType,
      [EventParameterKey.STATUS]: this.orderAasmState,
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.CANCEL_ORDER_REASON_PAGE,
      [EventParameterKey.CTA]: 'Continue',
    });
    navigation.navigate('CancelOrder', {
      cancelReasonId: selectedCancelReasonId,
      orderId: this.orderId,
      entityType: this.entityType,
    });
  };

  keyExtractor = (item, index) => index;

  render() {
    const { cancelReasons, selectedCancelReasonId, isShowError } = this.state;
    return (
      <>
        <StaticNavigationHeader title={this.headerTitle} />
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <Text style={styles.heading}>
              Please help us understand the issue better
            </Text>
            <FlatList
              style={styles.listContainer}
              data={cancelReasons}
              renderItem={this.reasonList}
              keyExtractor={this.keyExtractor}
              extraData={selectedCancelReasonId}
            />
            {isShowError && (
              <Text style={styles.errorMessage}>
                *Please select a reason to Continue.
              </Text>
            )}
          </View>
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 36}
              style={styles.foxyShadowButton}
              title={'Continue'}
              backgroundColor={colors.codGray}
              firstColor={colors.black}
              secondColor={colors.black}
              underlayColor={colors.black}
              borderRadius={4}
              onPress={this.bottomButtonAction}
            />
          </View>
        </View>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getCancelReason,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(CancelOrderReasonPage),
);
