import { takeLatest, call, put, delay } from 'redux-saga/effects';
import RNFetchBlob from '../libraries/RNFetchBlob';
import {
  UPLOAD_SCANNED_IMAGE,
  saveLastScannedImageUrl,
} from '../actions/UploadPostActions';
import { getApiHeaders } from './GeneratorUtil';
import { URL } from '../config/Constants';
import Utility from '../utils/Utility';

const uploadFileToS3 = async (fileUri, headers) => {
  const header = headers;
  header['Content-type'] = 'multipart/form-data';
  const formdata = [];

  formdata.push({
    name: 'product_picture[image]',
    filename: Utility.extractImageName(fileUri),
    data: RNFetchBlob.wrap(fileUri),
  });

  const response = await RNFetchBlob.fetch(
    'POST',
    URL.UPLOAD_SCANNED_PRODUCT_IMAGE,
    header,
    formdata,
  )
    .uploadProgress((written, total) => {})
    .progress((received, total) => {})
    .then((res) => {
      // NotificationManager.hideScannedImageUploadNotification();
      const { data, respInfo } = res;
      if (respInfo.status === 201) {
        if (typeof data === 'string') {
          return Utility.jsonParser(data);
        }
        return data;
        //TODO: return response from here after parsing with json
      }
      return undefined;
    })
    .catch((error) => {
      // NotificationManager.retryScannedImageUpload();
      //TODO: return undefined object
      return undefined;
    });
  return response;
};

export function* retryScannedImageUploading(imageUrl) {
  const headers = yield getApiHeaders();
  for (let index = 0; index < 4; index += 1) {
    const results = yield call(uploadFileToS3, imageUrl, headers);
    if (Utility.isPresent(results)) {
      return results;
    }
    yield delay(1000);
  }
  return undefined;
}

export function* uploadScannedImage(action) {
  const { imageUrl, callback } = action;
  yield put(saveLastScannedImageUrl(imageUrl));
  try {
    const results = yield retryScannedImageUploading(imageUrl);
    if (Utility.isPresent(results)) {
      callback(results);
      return;
    }
    callback(false);
  } catch (error) {}
}

export default function* watchScanProductImageSaga() {
  yield takeLatest(UPLOAD_SCANNED_IMAGE, uploadScannedImage);
}
