import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useNavigation, useRoute } from '@react-navigation/native';

// Creating the HOC
function withNavigation(Component) {
  const ComponentWithNavigation = (props) => {
    const navigation = useNavigation();
    const route = useRoute();

    return <Component {...props} navigation={navigation} route={route} />;
  };

  hoistNonReactStatics(ComponentWithNavigation, Component);

  return ComponentWithNavigation;
}

export default withNavigation;
