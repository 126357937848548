import {
  DefaultSizeVideoGrid,
  DefaultSizeVideoRail,
  DefaultSizeVideoList,
} from '.';

export const SmallVideoGrid = DefaultSizeVideoGrid;
export const LargeVideoGrid = DefaultSizeVideoGrid;
export const SmallVideoRail = DefaultSizeVideoRail;
export const LargeVideoRail = DefaultSizeVideoRail;
export const SmallVideoList = DefaultSizeVideoList;
export const LargeVideoList = DefaultSizeVideoList;
