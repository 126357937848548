export const GET_PAYOFF = 'GET_PAYOFF';
export const GET_LIST_DATA = 'GET_LIST_DATA';
export const GET_ALL_REVIEWS = 'GET_ALL_REVIEWS';
export const GET_MEMBER_PAGE = 'GET_MEMBER_PAGE';

export function getPayoff(page, callback, pageNo) {
  return {
    type: GET_PAYOFF,
    page,
    callback,
    pageNo,
  };
}

export function getListData(page, callback, pageNo) {
  return {
    type: GET_LIST_DATA,
    page,
    callback,
    pageNo,
  };
}

export function getMemberPage(page, callback) {
  return {
    type: GET_MEMBER_PAGE,
    page,
    callback,
  };
}

export function getAllReviews(firstVideoId, page, callback) {
  return {
    type: GET_ALL_REVIEWS,
    firstVideoId,
    page,
    callback,
  };
}
