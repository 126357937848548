import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { ScrollView, BackHandler, RefreshControl } from 'react-native';
import ErrorBoundary from '../shared/ErrorBoundary';
import { fetchRoutines } from '../../actions/ActionTypes';
import { List } from '../../containers/List';
import { connect } from 'react-redux';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { StyleSheet, View } from 'react-native';
import Utility from '../../utils/Utility';
import { StaticNavigationHeader } from '../shared';
import withNavigation from '../../utils/WithNavigation';
import { setNotificationModalDisplayTime } from '../../actions/ActionTypes';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import NotificationModal from '../../utils/Notification/NotificationModal';
import { NOTIFICATION_REQUEST_MODAL_TYPE } from '../../config/Constants';
import PermissionsUtility from '../../utils/PermissionsUtility';
import { getFirebasePerformanceTrace } from '../../utils/PerformanceUtility';

class Routines extends PureComponent {
  Components = {
    list: List,
  };

  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      retryLoading: false,
      connectionError: false,
      loading: true,
      itemData: {},
      canDisplayNotificationRequestModal: false,
      showNotificationPrompt: false,
    };
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.colorShimmerArray = ['#ffffff1A', '#ffffff33', '#ffffff1A'];
    this.trace = getFirebasePerformanceTrace('Routines');
    this.trace.start();
  }

  componentDidMount() {
    this._navListener = this.props.navigation.addListener('focus', () => {
      Utility.setStatusBarWhite();
      this.fetchRoutines();
    });

    setTimeout(() => {
      this.displayNotificationRequestModal();
    }, 800);
  }

  componentWillUnmount() {
    this._navListener();
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  showNotificationModal = (show, showNotification) => {
    this.setState({
      canDisplayNotificationRequestModal: show,
      showNotificationPrompt: showNotification,
    });
  };

  displayNotificationRequestModal = () => {
    const {
      lastNotificationModalDisplayTime,
      setNotificationModalDisplayTime,
      initial_app_opened_at,
    } = this.props;

    if (
      !PermissionsUtility.shouldShowNotificationModalForCurrentSession(
        'routine_page',
      )
    ) {
      return;
    }

    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      'routine',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          const currentDate = new Date();
          setNotificationModalDisplayTime(currentDate.getTime());
          PermissionsUtility.setNotificationModalVisibilityForCurrentSession(
            'routine_page',
            false,
          );
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  fetchRoutines = () => {
    const { slug = '/api/v6/lists/routines', fetchRoutines } = this.props;
    if (!Utility.isBlank(slug)) {
      fetchRoutines(slug, (success, response = {}) => {
        if (!this.traceStopped) {
          this.trace.stop();
          this.traceStopped = true;
        }
        if (success) {
          this.setState({
            serverError: false,
            connectionError: false,
            loading: false,
            itemData: response,
          });
          AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
            [EventParameterKey.PAGE]: SCREEN_CONSTANTS.ROUTINES_PAGE,
          });
          return;
        }
        if (response.errorType === 'api_failure') {
          this.setState({
            loading: false,
            serverError: response.status === 'server_error',
            // connection_error to be added on Routines page. Handling is added here
            connectionError: response.status === 'connection_error',
            retryLoading: false,
          });
        } else {
          this.setState({
            loading: false,
            serverError: false,
            connectionError: true,
            retryLoading: false,
          });
        }
      });
    }
  };

  onPressReload = () => {
    this.setState(
      {
        retryLoading: true,
      },
      this.fetchRoutines,
    );
  };

  showToast = (message) => {
    this.toast.show(message, 1500);
  };

  renderRoutineDescriptionShimmer = () => {
    return (
      <View style={styles.cardContainerPlaceholder}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardImaegShimmer}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentTitlePlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentDescriptionPlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentArtistPlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentDurationPlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.cardContentCTAPlaceholder}
          colorShimmer={this.colorShimmerArray}
        />
      </View>
    );
  };

  renderShimmer = () => {
    return (
      <View style={styles.shimmerContainer}>
        <ShimmerPlaceHolder
          colorShimmer={this.colorShimmerArray}
          autoRun
          style={styles.titleShimmer}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.subtitleShimmer}
          colorShimmer={this.colorShimmerArray}
        />
        <this.renderRoutineDescriptionShimmer />
        <this.renderRoutineDescriptionShimmer />
        <this.renderRoutineDescriptionShimmer />
      </View>
    );
  };

  render() {
    const { navigation } = this.props;
    const { serverError, retryLoading, connectionError, loading, itemData } =
      this.state;

    if (loading) {
      return (
        <>
          <StaticNavigationHeader title={itemData?.name} />
          <this.renderShimmer />
        </>
      );
    }

    const ContainerComponent = this.Components[itemData.type];

    const isServerError = serverError || Utility.isBlank(itemData);

    const { showNotificationPrompt, canDisplayNotificationRequestModal } =
      this.state;
    return (
      <>
        <StaticNavigationHeader title={itemData.name} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={this.fetchRoutines}
            />
          }
        >
          <ErrorBoundary
            showServerError={isServerError}
            onPressReload={this.onPressReload}
            connectionError={connectionError}
            loading={retryLoading}
            hideHeader
          >
            <ContainerComponent
              navigation={navigation}
              itemData={itemData}
              id={itemData.id}
              listName={itemData.name}
              listContent={itemData.content}
              type={itemData.type}
              display={itemData.display}
              layout={itemData.display}
              showToast={this.showToast}
              previousScreen={SCREEN_CONSTANTS.ROUTINES_PAGE}
            />
          </ErrorBoundary>
        </ScrollView>

        <NotificationModal
          isNotificationModalVisible={canDisplayNotificationRequestModal}
          showNotificationModal={this.showNotificationModal}
          showNotificationPrompt={showNotificationPrompt}
          type={NOTIFICATION_REQUEST_MODAL_TYPE.ROUTINES}
          showAnimation
        />
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    lastNotificationModalDisplayTime:
      state.UserAccountInfo.lastNotificationModalDisplayTime,
    initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchRoutines,
      setNotificationModalDisplayTime,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Routines),
);

const styles = StyleSheet.create({
  shimmerContainer: { paddingHorizontal: 12, marginTop: 32 },
  titleShimmer: {
    height: 20,
    width: 150,
    backgroundColor: '#EAEAEA',
  },
  subtitleShimmer: {
    height: 20,
    width: 200,
    backgroundColor: '#EAEAEA',
  },
  cardContainerPlaceholder: {
    width: '100%',
    height: 286,
  },
  cardImaegShimmer: {
    width: '100%',
    height: 118,
    backgroundColor: '#EAEAEA',
  },
  cardContentContainerPlaceholder: {
    width: '100%',
    height: 158,
    backgroundColor: '#EAEAEA',
  },
  cardContentTitlePlaceholder: {
    marginLeft: 8,
    marginTop: 8,
    width: 150,
    height: 16,
    backgroundColor: '#EAEAEA',
  },
  cardContentDescriptionPlaceholder: {
    marginLeft: 8,
    marginTop: 8,
    width: '90%',
    height: 64,
    backgroundColor: '#EAEAEA',
  },
  cardContentArtistPlaceholder: {
    marginLeft: 8,
    marginTop: 8,
    width: 164,
    height: 16,
    backgroundColor: '#EAEAEA',
  },
  cardContentDurationPlaceholder: {
    marginLeft: 8,
    marginTop: 8,
    width: 100,
    height: 32,
    backgroundColor: '#EAEAEA',
  },
  cardContentCTAPlaceholder: {
    marginRight: 0,
    marginTop: 8,
    width: 156,
    height: 32,
    borderRadius: 32,
    backgroundColor: '#EAEAEA',
  },
});
