import React, { PureComponent } from 'react';
import { View, ImageBackground } from 'react-native';
import { playlistStyle, verticalPlaylistCardHeight } from './Style';
import Utility from '../../utils/Utility';

class VerticalMediaBgImage extends PureComponent {
  constructor(props) {
    super(props);

    const { imageUrl, cardWidth } = props;
    let newImageUrl = imageUrl;
    if (!Utility.isBlank(imageUrl)) {
      newImageUrl = Utility.getMinifiedImage(
        imageUrl,
        cardWidth,
        verticalPlaylistCardHeight,
      );
    }

    this.source = { uri: newImageUrl };
  }

  bgImageCard = () => {
    const styles = playlistStyle;
    return (
      <ImageBackground
        resizeMode='cover'
        source={this.source}
        style={styles.verticalPlaylistImageBackgroundStyle}
        blurRadius={8}
      />
    );
  };

  render() {
    const { showBgImage } = this.props;
    const styles = playlistStyle;

    if (!showBgImage) {
      return null;
    }

    return (
      <View style={styles.verticalPlaylistMediaImageContainer}>
        <this.bgImageCard />
      </View>
    );
  }
}

export default VerticalMediaBgImage;
