import * as types from '../actions/ActionTypes';
import initialState from './initialState';

export default function storeDetails(state = initialState.storeDetails, action) {
  switch (action.type) {
    case types.STORE_DATA_RECEIVED:
      return {
        id: action.data.id,
      };
    default:
      return state;
  }
}
