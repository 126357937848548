import { StyleSheet } from 'react-native';

const listingStyles = StyleSheet.create({
  flatlistContainer: {
    marginBottom: 4,
    marginTop: 4,
  },
});

export default listingStyles;
