import React, { useEffect, useState } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Image,
} from 'react-native';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import { styles } from './styles';
import { ScaleAnimate } from '../shared';

export default function AddLinkCard({
  title = '',
  onPress = () => {},
  image,
  url_domain,
}) {
  return (
    <ScaleAnimate onPress={onPress}>
      <View style={styles.addLinkContainer}>
        <View style={styles.addLinkContainerImageContainer}>
          <FastImageView
            source={image || images.plus_button_circle}
            style={styles.plusButton}
          />
        </View>

        <View style={styles.addLinkContainerFooter}>
          <Text style={styles.addLinkContainerHeading}>{title}</Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FastImageView
              source={images.linkChain}
              style={styles.linkChainImage}
            />
            <Text style={styles.addLinkContainerSubheading}>
              {' '}{url_domain || `your.link`}
            </Text>
          </View>
        </View>
      </View>
    </ScaleAnimate>
  );
}
