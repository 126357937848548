// This login card will be shown on Consumer profile in case of user is not logged in
import React from 'react';
import Config from '../../libraries/ReactNativeConfig';
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';

const styles = StyleSheet.create({
  container: {
    height: 104,
    flexDirection: 'row',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
    paddingBottom: 12,
    marginLeft: 12,
    marginTop: 24,
    borderRadius: 4,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth() - 24,
  },

  profilePicEmptyStateStyle: {
    height: 80,
    width: 80,
  },

  columnContainer: {
    flexDirection: 'column',
    paddingLeft: 20,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  headerText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h2,
  },

  button: {
    width: 132,
    height: 36,
    backgroundColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
  },

  loginButtonText: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
});

const LoginCard = (props) => {
  const { onPress } = props;
  const profileEmptyImage = Utility.isPresent(Config.PROFILE_HEADER_IMAGE)
    ? { uri: Config.PROFILE_HEADER_IMAGE }
    : images.profileEmptyState;
  return (
    <View style={styles.container}>
      <Image
        source={profileEmptyImage}
        style={styles.profilePicEmptyStateStyle}
      />
      <View style={styles.columnContainer}>
        <Text style={styles.headerText}>Login to create your profile</Text>
        <TouchableOpacity onPress={onPress}>
          <View style={styles.button} testID="profile-login-btn" accessibilityLabel="profile-login-btn">
            <Text style={styles.loginButtonText}>Login</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LoginCard;
