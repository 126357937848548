import { StyleSheet } from 'react-native';
import React, { Component } from 'react';
import _ from 'lodash';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import CancellationReasonsModal from '../../modals/CancellationReasonsModal';
import FoxyAlert from '../../camera/shared/FoxyAlert';
import images from '../../../theme/Images';

// eslint-disable-next-line react/prefer-stateless-function
export default class CancelShipment extends Component {
  state = {
    showSuccessModal: false,
  };

  constructor(props) {
    super(props);
    this.buttonContainerStyle = [
      styles.buttonContainer,
      { width: Utility.getScreenWidth() - 24 },
    ];
  }

  render() {
    const {
      setCancelOrderModalVisible,
      isCancelOrderModalVisible,
      cancellationReasons,
      cancelShipment,
      item,
      showShipmentCancelSuccess,
      showShipmentCancelFailure,
      setShowShipmentCancelFailure,
      setShowShipmentCancelSuccess,
    } = this.props;
    return (
      <>
        <FoxyShadowButton
          title={'Cancel Order'}
          onPress={_.memoize(
            () => setCancelOrderModalVisible(true),
            () => [true],
          )}
          backgroundColor={colors.black}
          style={this.buttonContainerStyle}
          firstColor={colors.black}
          secondColor={colors.black}
          underlayColor={colors.black}
        />
        <CancellationReasonsModal
          isVisible={isCancelOrderModalVisible}
          onDismiss={_.memoize(
            () => setCancelOrderModalVisible(false),
            () => [false],
          )}
          cancellationReasons={cancellationReasons}
          cancelOrder={(item, selectedReason) => {
            cancelShipment(item, selectedReason);
          }}
          item={item}
        />
        <FoxyAlert
          isVisible={showShipmentCancelSuccess}
          hideSecondButton
          alertBoxTitle={'We have noted your request'}
          alertMessage={'Our team will process your request for cancellation.'}
          firstButtonTitle={'OKAY'}
          firstButtonOnPress={_.memoize(
            () => setShowShipmentCancelSuccess(false),
            () => [false],
          )}
          onTapOutside={_.memoize(
            () => setShowShipmentCancelSuccess(false),
            () => [false],
          )}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.successInfo}
          showImage
        />
        <FoxyAlert
          isVisible={showShipmentCancelFailure}
          hideSecondButton
          alertBoxTitle={'Oops!'}
          alertMessage={'Something went wrong, please try again.'}
          firstButtonTitle={'OKAY'}
          firstButtonOnPress={_.memoize(
            () => setShowShipmentCancelFailure(false),
            () => [false],
          )}
          onTapOutside={_.memoize(
            () => setShowShipmentCancelFailure(false),
            () => [false],
          )}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 3,
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
  },
});
