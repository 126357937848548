import _ from 'lodash';
import * as types from '../actions/ActionTypes';
import initialState from './initialState';

export default function artistDetails(state = initialState.artistDetails, action) {
  switch (action.type) {
    case types.FETCH_ARTIST:
      return {
        ...state,
        isLoading: true,
      };
    case types.UPDATE_ARTIST_DATA:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
