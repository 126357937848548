import React from 'react';
import { View, StyleSheet } from 'react-native';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { isBlank, isDesktop } from '../../utils/BooleanUtility';
import List from '../../containers/List/List';
import { getScreenWidth } from '../../utils/LayoutUtility';

const DesktopList = ({
  list,
  itemId,
  content,
  display,
}) => {
  if (!isDesktop() || isBlank(list)) {
    return null;
  }
  return (
    <View style={styles.container}>
      <List
        itemData={{
          objects: list,
          content,
          display,
        }}
        id={itemId}
        previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
        useInViewPort
      />
    </View>
  );
};

export default DesktopList;

const styles = StyleSheet.create({
  container: {
    width: getScreenWidth(),
    alignSelf: 'center',
  },
});
