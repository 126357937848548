import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';

export default class EmptyStateCard extends Component {
  imageContainer = (props) => (
    <View style={styles.imageContainer}>
      <Image source={props.imgSrc} />
    </View>
  );

  textContainer = (props) => (
    <View style={styles.textContainer}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }} testID="add-address-profile" accessibilityLabel="add-address-profile">
        <Text style={styles.headingText}>{props.headingText}</Text>
        <View style={styles.cheveronBackGround}>
          <Image
            source={images.chevronRight}
            style={{ tintColor: colors.white, height: 10, width: 10 }}
          />
        </View>
      </View>
      <Text style={styles.subHeadingText}>{props.subheadingText}</Text>
    </View>
  );

  render() {
    const {
      backgroundColor,
      leftAlignImage,
      imageUrl,
      headingText,
      subheadingText,
      imgSrc,
      onPressCard,
    } = this.props;
    return (
      <TouchableWithoutFeedback onPress={onPressCard}>
        <View style={[styles.wrapper, { backgroundColor }]}>
          {leftAlignImage && <this.imageContainer imgSrc={imgSrc} />}
          <this.textContainer
            headingText={headingText}
            subheadingText={subheadingText}
          />
          {!leftAlignImage && <this.imageContainer imgSrc={imgSrc} />}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    height: 80,
    width: Utility.getScreenWidth() - 32,
    flexDirection: 'row',
    borderRadius: 4,
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.borderDark,
  },
  imageContainer: {
    flex: 0.4,
  },

  textContainer: {
    flex: 1,
    flexDirection: 'column',
  },

  headingText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h2,
    color: colors.foxyBlack,
  },
  subHeadingText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.subtitle,
  },
  cheveronBackGround: {
    height: 15,
    width: 15,
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.foxyBlack,
    borderRadius: 20,
  },
});
