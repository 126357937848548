// Dependencies
import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  Keyboard,
  TouchableWithoutFeedback,
  NativeModules,
  Platform,
} from 'react-native';
import Modal from 'react-native-modal';
import images from '../../theme/Images';
import Utility from '../Utility';
import colors from '../../theme/Colors';
import ActionButton from '../../components/shared/ActionButton';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';
import RemoteConfig from '../RemoteConfig';
import {
  NOTIFICATION_REQUEST_MODAL_TYPE,
  PERMISSION_CONSTANTS,
  PERMISSION_STORE,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import NotificationManager from '../NotificationsManager';
import LottieView from 'lottie-react-native';
import animations from '../../theme/Animations';
import { StatusBar } from 'react-native';
import withNavigation from '../WithNavigation';
import { ReactMoE } from '../../libraries/ReactMoe';
import NotificationUtils from '../NotificationUtils';
import FastImageView from '../../components/FastImageView';
import { BlurView } from '../../libraries/ReactNativeCommunityBlur';
import { connect } from 'react-redux';
import { checkAndroidNotificationStatusFromNativeLayer, savePermissionStatus } from '../PermissionsUtility';
import { triggerPermissionsEvent } from '../../analytics/PermissionsEvents';
import { createAndroidNotificationChannels } from '../AndroidNotificationsUtils';

class NotificationModal extends Component {
  constructor(props) {
    super(props);
    this.bottomDockHeight = 70;
    this.state = {
      promptData: {},
      animationJson: null,
    };
    this.getRemoteConfigData();
    this.notificationModalAssets = {};
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.getRemoteConfigData();
    });
  }

  componentDidUpdate(prevProps) {
    const { isNotificationModalVisible } = this.props;

    if (!prevProps.isNotificationModalVisible && isNotificationModalVisible) {
      this.fireEvent(
        EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.MODAL_SHOWN,
      );
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  getRemoteConfigData = async () => {
    const { promptData } = this.state;
    this.fetchAnimationJson();
    const remoteRequestData = await JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_prompt_request),
    );
    if (Utility.isBlank(promptData) && Utility.isPresent(remoteRequestData)) {
      this.setState({
        promptData: remoteRequestData,
      });
    }

    this.notificationModalAssets = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_modal_assets),
    );
  };

  fetchAnimationJson = () => {
    const { type } = this.props;
    if (typeof animations.notificationPrompt[type] !== 'string') return;
    //try {
    //  fetch(animations.notificationPrompt[type], {
    //    method: 'GET',
    //  })
    //    .then((response) => response?.json())
    //    .then((responseData) => {
    //      this.setState({
    //        animationJson: responseData,
    //      });
    //    });
    //} catch (e) {
    //  console.tron.log(e);
    //}
  };

  closeModal = (action) => {
    const { showNotificationModal } = this.props;
    showNotificationModal(false);
    this.fireEvent(action);
  };

  fireEvent = (action) => {
    AnalyticsManager.logEvent(
      EventType.notificationModal.NOTIFICATION_PERMISSION_MODAL,
      {
        [EventParameterKey.ACTION]: action,
        [EventParameterKey.SOURCE]: this.props.type,
      },
    );
  };

  acceptButtonDock = (props) => {
    const { type } = this.props;
    const { promptData } = this.state;
    const allowButtonText = promptData[type + '_action_1'] || 'Yes Definitely!';
    return (
      <View style={styles.actionButtonContainer}>
        <ActionButton
          onActionPress={this.acceptAction}
          title={allowButtonText}
          actionButtonColor={colors.green}
        />
      </View>
    );
  };

  onPressNoButton = () => {
    this.closeModal(
      EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.CLICK_NO,
    );
  };

  cancelButtonDock = (props) => {
    const { type } = this.props;
    const { promptData } = this.state;
    const cancelButtonText =
      promptData[type + '_action_2'] || 'Remind Me Later';
    return (
      <View style={styles.cancelButtonContainer}>
        <ActionButton
          onActionPress={this.onPressNoButton}
          title={cancelButtonText}
          actionButtonColor={colors.white}
          textColor={colors.black}
        />
      </View>
    );
  };

  triggerAndroidNotificationSettings = (currentPermissionState) => {
    const { notificationPermission = '' } = this.props;
    if (Platform.Version < 33 && currentPermissionState === 'never_ask_again') {
      return true;
    }

    //FIXME: Once app-start notification permission code merged change this static variable to redux
    return (
      currentPermissionState === 'never_ask_again' &&
      notificationPermission === 'never_ask_again'
    );
  };

  acceptAction = () => {
    const {
      showNotificationPrompt,
      type: source,
      notificationPermission = '',
    } = this.props;

    if (Utility.isAndroid()) {
      /**
       * @param channelId: if not found, open app level notification settings
       */

      this.closeModal(
        EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.CLICK_YES,
      );
      checkAndroidNotificationStatusFromNativeLayer(
        notificationPermission,
        (status) => {
          if (this.triggerAndroidNotificationSettings(status)) {
            triggerPermissionsEvent(
              EventType.miscAppEvents.SETTINGS_PROMPT,
              EventParameterValue.PERMISSIONS.notifications,
              source,
            );
            NativeModules.NotificationsChannelModule.openNotificationChannelSettings(
              null,
            );
            return;
          }

          // Show os prompt
          triggerPermissionsEvent(
            EventType.miscAppEvents.PERMISSION_PROMPT,
            EventParameterValue.PERMISSIONS.notifications,
            source,
          );

          Utility.checkPermission(
            PERMISSION_STORE.notification,
            false,
            this.permissionRequestCallback,
            {},
            false,
          );
        },
      );
      return;
    }

    if (showNotificationPrompt) {
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_PROMPT,
        EventParameterValue.PERMISSIONS.notifications,
        source,
      );
      NotificationManager.getNotificationPermission(source);
    } else {
      triggerPermissionsEvent(
        EventType.miscAppEvents.SETTINGS_PROMPT,
        EventParameterValue.PERMISSIONS.notifications,
        source,
      );
      Utility.openSettings();
    }

    this.closeModal(
      EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.CLICK_YES,
    );
  };

  permissionRequestCallback = (granted, data) => {
    const { type: source } = this.props;

    // Internally creates a General Notification channel if user has granted notification permission.
    ReactMoE.pushPermissionResponseAndroid(granted);
    // CleverTapAnalytics.subscribeToNotifications(granted);

    if (granted) {
      createAndroidNotificationChannels();
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_ALLOW,
        EventParameterValue.PERMISSIONS.notifications,
        source,
      );
    } else {
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_DENY,
        EventParameterValue.PERMISSIONS.notifications,
        source,
      );
    }

    NotificationUtils.previousNotificationPermissionState = data;
    savePermissionStatus(PERMISSION_CONSTANTS.notification, data);
  };

  notificationTitleText = (type) => {
    const { promptData } = this.state;
    return promptData[type + '_title'] || 'Enable Notifications!';
  };

  notificationSubtitleText = (type) => {
    const { promptData } = this.state;
    return promptData[type + '_subtitle'] || 'Get the best offer updates';
  };

  notificationImage = (type) => {
    const { promptData } = this.state;
    return promptData[type + '_image'] || images.notificationDefaultImage;
  };

  dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  onPressCancel = () => {
    this.closeModal(
      EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.CROSS,
    );
  };

  onModalTapOutside = () => {
    return;
  };

  showPlatformSpecificPermissionPath = () => {
    if (!this.isNotificationPermissionRestricted()) {
      return null;
    }

    const image = this.notificationModalAssets[Platform.OS];

    return (
      <FastImageView
        style={styles.permissionPathImage}
        source={image}
        resizeMode={'contain'}
      />
    );
  };

  showImageOrAnimation = () => {
    const { showAnimation = false, type } = this.props;
    const { animationJson } = this.state;
    const bannerImage = this.notificationImage();

    return (
      <View style={styles.notificationModalImageView}>
        <FastImageView
          style={styles.modalImageContainer}
          resizeMode={'contain'}
          source={bannerImage}
        />

        <this.showPlatformSpecificPermissionPath />
      </View>
    );
  };

  isNotificationPermissionRestricted = () => {
    const { showNotificationPrompt, notificationPermission = '' } = this.props;

    //FIXME: Once app start code is merged replace this static with actual redux value
    if (Utility.isAndroid() && notificationPermission === 'never_ask_again') {
      return true;
    }

    if (Utility.isIOS() && !showNotificationPrompt) {
      return true;
    }

    return false;
  };

  render() {
    const { isNotificationModalVisible, type, notificationPermission } =
      this.props;
    if (!isNotificationModalVisible) {
      return null;
    }
    if (
      notificationPermission === 'granted' ||
      notificationPermission === 'authorized'
    ) {
      return null;
    }

    const hideStatusBar =
      type === NOTIFICATION_REQUEST_MODAL_TYPE.WISHLIST_PAGE;

    const modalStyle = this.isNotificationPermissionRestricted()
      ? styles.modalStyle
      : styles.modalStyleWithoutSecondaryImage;
    return (
      <>
        <BlurView
          style={styles.blurView}
          blurRadius={1}
          blurType={'light'}
          blurAmount={0.5}
        />
        <Modal
          isVisible={isNotificationModalVisible}
          onBackdropPress={this.onModalTapOutside}
          style={styles.notificationModal}
          onRequestClose={this.onModalTapOutside}
          statusBarTranslucent={hideStatusBar}
          deviceHeight={Utility.getScreenHeight()}
        >
          <View style={modalStyle}>
            <TouchableWithoutFeedback
              onPress={this.dismissKeyboard}
              style={styles.contentView}
            >
              <View style={styles.contentView}>
                <Text style={styles.titleText} numberOfLines={2}>
                  {this.notificationTitleText(type)}
                </Text>
                <Text style={styles.subtitleText} numberOfLines={3}>
                  {this.notificationSubtitleText(type)}
                </Text>
                <this.showImageOrAnimation />
              </View>
            </TouchableWithoutFeedback>

            <this.acceptButtonDock />
            <this.cancelButtonDock />

            <TouchableOpacity
              hitSlop={Utility.getHitSlop()}
              onPress={this.onPressCancel}
              style={styles.cancelButtonView}
            >
              <Image
                style={styles.crossButtonContainer}
                source={images.popupClose}
              />
            </TouchableOpacity>
          </View>
        </Modal>
      </>
    );
  }
}

const styles = StyleSheet.create({
  notificationModal: {
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    height: Utility.getScreenHeight(),
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButtonContainer: {
    position: 'absolute',
    bottom: 52 + 6,
    left: 0,
    right: 0,
    height: 52,
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelButtonContainer: {
    position: 'absolute',
    bottom: 2,
    left: 0,
    right: 0,
    height: 52,
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButtonView: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    right: 12,
    elevation: 2,
    shadowRadius: 4,
    height: 46,
    borderRadius: 4,
    backgroundColor: colors.black,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowOffset: { width: 1, height: 1 },
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelButtonView: {
    padding: 4,
    position: 'absolute',
    top: 12,
    right: 12,
    shadowRadius: 4,
    height: 32,
    width: 32,
    borderRadius: 16,
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalStyle: {
    width: Utility.getScreenWidth() - 24,
    minHeight: 500,

    borderRadius: 12,
    backgroundColor: 'white',
  },
  modalStyleWithoutSecondaryImage: {
    width: Utility.getScreenWidth() - 24,
    minHeight: 400,
    borderRadius: 12,
    backgroundColor: 'white',
  },
  titleText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    letterSpacing: 0,
    color: colors.foxyBlack,
    textAlign: 'center',
    marginLeft: 32,
    marginRight: 32,
    marginTop: 8,
    alignSelf: 'center',
  },
  subtitleText: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    marginLeft: 32,
    marginRight: 32,
    marginTop: 8,
    alignSelf: 'center',
    textAlign: 'center',
  },
  contentView: {
    justifyContent: 'center',
    alignItems: 'center',
    top: 32,
  },
  crossButtonContainer: { height: 24, width: 24 },
  modalImageContainer: { height: 180, width: Utility.getScreenWidth() - 48 },
  permissionPathImage: { height: 80, width: Utility.getScreenWidth() - 40 },
  blurView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: Utility.getScreenHeight(),
  },

  notificationModalImageView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const mapStateToProps = (state) => ({
  notificationPermission: state.permissionStatus?.notification,
});

export default withNavigation(
  connect(mapStateToProps, null)(NotificationModal),
);
