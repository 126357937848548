import React from 'react';
import { Text, View } from 'react-native';

const VariantSize = (props) => {
  const { packSize } = props;
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 3,
        maxWidth: 60,
        marginRight: 3,
        paddingVertical: 3,
      }}
    >
      <Text
        allowFontScaling={false}
        style={{
          fontFamily: 'Roboto-Regular',

          fontStyle: 'normal',
          letterSpacing: 0,
          color: '#606481',
          fontSize: 10,
          lineHeight: 14,
          marginRight: 2,
        }}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {packSize}
      </Text>
    </View>
  );
};

export default function SkuSizeVariant(props) {
  const { packSize, containerStyle } = props;
  return (
    <View style={containerStyle}>
      <VariantSize packSize={packSize} />
    </View>
  );
}
