import {
  View,
  TouchableOpacity,
  Linking,
  Image,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import React from 'react';
import images from '../../theme/Images';
import { FOXY_URLS } from '../../config/Constants';
import { AnalyticsManager, EventType } from '../../analytics';
import { isWebAndroid, isWebIOS } from '../../utils/BooleanUtility';

export default function DownloadAppButtons({
  width,
  height,
  containerStyle,
  onPress = () => {},
  link,
  from,
}) {
  const onAppStorePress = () => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.GET_APP_CLICK,
      { location: from || location.pathname },
    );
    onPress();
    if (isWebIOS() && link) {
      Linking.openURL(link);
    } else {
      Linking.openURL(FOXY_URLS.appStore);
    }
  };

  const onPlayStorePress = () => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.GET_APP_CLICK,
      { location: from || location.pathname },
    );
    onPress();
    if (isWebAndroid() && link) {
      Linking.openURL(link);
    } else {
      Linking.openURL(FOXY_URLS.playStore);
    }
  };

  return (
    <View style={[styles.container, containerStyle]} dataSet={{ media: ids.container }}>
      {!isWebAndroid() && (
        <TouchableOpacity
          onPress={onAppStorePress}
          style={styles.leftButton}
        >
          <Image
            source={{ uri: images.desktop.appleStoreFooterIcon }}
            style={[styles.image, width && { width, height }]}
            dataSet={{ media: ids.image }}
          />
        </TouchableOpacity>
      )}
      {!isWebIOS() && (
        <TouchableOpacity onPress={onPlayStorePress}>
          <Image
            source={{ uri: images.desktop.googlePlayFooterIcon }}
            style={[styles.image, width && { width, height }]}
            dataSet={{ media: ids.image }}
          />
        </TouchableOpacity>
      )}
    </View>
  );
}

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: 20,
    '@media (min-width: 768px)': {
      marginBottom: 30,
    },
  },
  leftButton: {
    marginRight: 5,
  },
  image: {
    width: 87,
    height: 23,
    ':hover': {
      opacity: 0.7,
    },
    '@media (min-width: 768px)': {
      width: 164,
      height: 44,
    },
  },
});
