import _ from 'lodash';
import Utility from '../../utils/Utility';
import FilterAnalyticsEvents from './FiltersAnalyticsEvents';
import { isPresent, isBlank } from '../../utils/BooleanUtility';
import { getIndexOfEmptyState } from '../../utils/ArrayUtility';

export default class FilterUtils {
  static removeSelectedQuickFilterInFilterModal = (item, selectedFilters) => {
    const filters = selectedFilters;
    const arrCurrentObj = filters[item.criteria_name];
    const objectsAfterItemRemove = _.remove(
      arrCurrentObj,
      (element) => element.value != item.value,
    );
    filters[item.criteria_name] = objectsAfterItemRemove;
    return filters;
  };

  static generateUrlFromQuickFilters = (
    item,
    selected,
    index,
    listObject,
    currentSlug,
    selectedFilters,
    selectedQuickFilters,
    prevAppliedFiltersFromModal,
    screenName,
    entityName = '',
  ) => {
    let slug = currentSlug;
    let copySelectedFilters = {};
    let arrSelectedQuickFilters = selectedQuickFilters;

    if (selected) {
      const criteria =
        item.criteria_name === 'sort_by'
          ? item.criteria_name
          : `${item.criteria_name}[]`;
      slug = `${currentSlug}&${criteria}=${item.value}`;
      arrSelectedQuickFilters.push(item);
      prevAppliedFiltersFromModal.push(item);
      copySelectedFilters = this.groupSelectedFilter(
        selectedQuickFilters,
        true,
      );
      FilterAnalyticsEvents.logQuickFilterApplyEvent(
        item,
        listObject,
        index,
        screenName,
        entityName,
      );
    } else {
      const criteria = `&${item.criteria_name}[]=${item.value}`;
      slug = slug.replace(criteria, '');
      arrSelectedQuickFilters = arrSelectedQuickFilters.filter(
        (element) => element.name !== item.name,
      );

      copySelectedFilters = this.removeSelectedQuickFilterInFilterModal(
        item,
        selectedFilters,
      );
      FilterAnalyticsEvents.logQuickFilterResetEvent(item, listObject, index, screenName, entityName);
    }
    return {
      slug,
      groupedSelectedFilters: copySelectedFilters,
      pageNo: 0,
      allowNextPageLoad: true,
      filtersEnabled: isPresent(copySelectedFilters),
      selectedQuickFilters: arrSelectedQuickFilters,
      prevAppliedFiltersFromModal,
    };
  };

  static generateUrlFromFiltersArray = (item = []) => {
    let url = '';
    item.forEach((element) => {
      if (Array.isArray(element.value)) {
        element.value.forEach((itemValue) => {
          if (itemValue instanceof Object) {
            url = `${url}&${element.criteria_name}[]=${itemValue.value}`;
          } else {
            url = `${url}&${element.criteria_name}[]=${itemValue}`;
          }
        });
      } else {
        url = `${url}&${element.criteria_name}[]=${element.value}`;
      }
    });

    return url;
  };

  static groupSelectedFilter = (
    item,
    selectedFilters,
    isQuickFilters = false,
  ) => {
    let arrayToBeGroup = item;
    if (isQuickFilters && isPresent(selectedFilters)) {
      const rawArray =
        Utility.convertSelectedFiltersObjectIntoArray(selectedFilters);
      const newArray = [...rawArray, ...item];
      arrayToBeGroup = _.uniqBy(newArray, (e) => e.value);
    }
    const groupBySelectedFilters = _.groupBy(
      arrayToBeGroup,
      (element) => element.criteria_name,
    );
    return groupBySelectedFilters;
  };

  static toggleQuickFilters = (
    item,
    sortFilters,
    listObject,
    refQuickFilter,
    prevAppliedFiltersFromModal,
  ) => {
    const { quick_filters = [] } = listObject;
    let highlightedQuickFilers = [];
    highlightedQuickFilers = item.filter((element) =>
      quick_filters.some((filters) => element.value === filters.value),
    );
    if (refQuickFilter) {
      refQuickFilter.applyFilters(
        prevAppliedFiltersFromModal,
        highlightedQuickFilers,
        sortFilters,
      );
    }
    return highlightedQuickFilers;
  };

  static onFilterApplied = (
    filter,
    added,
    index = -1,
    listObject,
    selectedFilters,
    refQuickFilter,
    currentSlug,
    prevAppliedFiltersFromModal,
    selectedQuickFilters,
    screenName,
    entityName,
  ) => {
    let prevAppliedFilters = prevAppliedFiltersFromModal;

    let newSlug = '';

    if (Array.isArray(filter)) {
      const url = this.generateUrlFromFiltersArray(filter);
      newSlug = `${decodeURIComponent(listObject.slug)}?Nan=Nan${url}`;
      let isFilterEnabled = false;
      const groupBySelectedFilters = this.groupSelectedFilter(
        filter,
        selectedFilters,
      );
      FilterAnalyticsEvents.logFilterApplyEvent(
        groupBySelectedFilters,
        listObject,
        screenName,
        entityName,
      );
      if (isBlank(filter)) {
        isFilterEnabled = false;
      } else {
        isFilterEnabled = true;
      }
      const sortByFilters = groupBySelectedFilters.sort_by;
      prevAppliedFilters = this.toggleQuickFilters(
        filter,
        sortByFilters,
        listObject,
        refQuickFilter,
        prevAppliedFilters,
      );
      let selectedQuickFilterArray = selectedQuickFilters;
      if (isBlank(groupBySelectedFilters)) {
        selectedQuickFilterArray = [];
      }
      return {
        slug: newSlug,
        pageNo: 0,
        allowNextPageLoad: true,
        filtersEnabled: isFilterEnabled,
        groupedSelectedFilters: groupBySelectedFilters,
        selectedQuickFilters: selectedQuickFilterArray,
        prevAppliedFiltersFromModal: prevAppliedFilters,
      };
    }

    if (!!currentSlug && currentSlug?.indexOf('?Nan') === -1) {
      currentSlug = `${currentSlug}?Nan=Nan`;
    }

    return this.generateUrlFromQuickFilters(
      filter,
      added,
      index,
      listObject,
      currentSlug,
      selectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
      screenName,
      entityName,
    );
  };

  static getList = (listData) => {
    if (isPresent(listData) && isBlank(listData.objects)) {
      return { list: listData };
    }
    const indexOfFiltersList = _.findIndex(
      listData.objects,
      (e) => e.content === 'product' && isPresent(e.quick_filters),
    );
    if (indexOfFiltersList === -1) {
      const emptyStateIndex = getIndexOfEmptyState(listData.objects);
      if (emptyStateIndex !== -1) {
        return { list: listData.objects[emptyStateIndex] };
      }
      return { list: listData };
    }
    return { list: listData.objects[indexOfFiltersList] };
  };

  static getFiltersFromList = (listData) => {
    if (isPresent(listData) && isBlank(listData.objects)) {
      return { list: listData, filters: [], quickFilters: [] };
    }
    const { objects } = listData;
    const indexOfFiltersList = _.findIndex(
      objects,
      (e) => e.type === 'quick_filters',
    );
    if (indexOfFiltersList === -1) {
      return { list: listData, filters: [], quickFilters: [] };
    }

    const filters = {
      list: listData[indexOfFiltersList],
      filters: objects[indexOfFiltersList].filters,
      quickFilters: objects[indexOfFiltersList].quick_filters,
    };
    return filters;
  };

  static appendFilteredListToMasterListObjects = (
    slug,
    apiResponse,
    masterList,
  ) => {
    const masterListClone = { ...masterList };
    let listObjects =
      Utility.removeShimmerAndEmptyStateFromListObject(masterListClone);
    if (apiResponse?.objects?.length === 0) {
      listObjects = Utility.addFiltersEmptyStateInListObject(listObjects);
    }
    masterListClone.objects = listObjects;

    if (apiResponse?.objects?.length > 0) {
      const indexOfProductList = listObjects.length;
      masterListClone.objects[indexOfProductList] = apiResponse;
    }
    return { ...masterListClone };
  };
}
