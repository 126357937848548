import { isBlank } from './BooleanUtility';

export default class ImageTagsProcessingUtil {
  static getNonSelectedTags = (facialProperties = {}, title = '') => {
    if (isBlank(title)) {
      return facialProperties.filter(
        (element) => element.values.length < 1 && element.ask_user,
      );
    }
    return facialProperties.filter(
      (element) =>
        element.values.length < 1 && element.name !== title && element.ask_user,
    );
  };

  static getCurrentSelectedTag = (facialProperties = {}, title) =>
    facialProperties.filter((element) => element.name === title);

  static getAllowedTags = (facialProperties = []) =>
    facialProperties.filter((element) => element.ask_user);

  static getSelectedTag = (facialProperties = {}) =>
    facialProperties.filter(
      (element) => element.values.length > 0 && element.ask_user,
    );

  static getSelectedTagIndex = (facialProperties = {}, title) => {
    let index = 0;
    let allowedProperties =
      ImageTagsProcessingUtil.getAllowedTags(facialProperties);
    allowedProperties.forEach((element, i) => {
      if (element.name === title) {
        index = i;
      }
    });

    return index;
  };
}
