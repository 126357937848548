import { StyleSheet } from 'react-native';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import colors from '../../../theme/Colors';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import CancellationReasonsModal from '../../modals/CancellationReasonsModal';
import FoxyAlert from '../../camera/shared/FoxyAlert';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';
import { cancelShipment } from '../../../actions/OrderActions';

export default function CancelShopifyOrder({
  item,
  cancellationReasons = [],
  shipmentId,
}) {
  const [isCancelOrderModalVisible, setIsCancelOrderModalVisible] = useState(false);
  const [showShipmentCancelFailure, setShowShipmentCancelFailure] = useState(false);
  const [showShipmentCancelSuccess, setShowShipmentCancelSuccess] = useState(false);
  const dispatch = useDispatch();

  const showCancelModal = () => setIsCancelOrderModalVisible(true);
  const hideCancelModal = () => setIsCancelOrderModalVisible(false);
  const hideShipmentCancelSuccessModal = () => setShowShipmentCancelSuccess(false);
  const hideShipmentCancelFailureModal = () => setShowShipmentCancelFailure(false);

  const cancelShopifyOrder = (_, selectedReason) => {
    const data = {
      entity_id: shipmentId,
      cancellation_reason: selectedReason?.id,
    };
    dispatch(
      cancelShipment(data, (success) => {
        if (success) {
          setShowShipmentCancelSuccess(true);
        } else {
          setShowShipmentCancelFailure(true);
        }
      }),
    );
  };

  if (!shipmentId) return null;

  return (
    <>
      <FoxyShadowButton
        title="Cancel Order"
        onPress={showCancelModal}
        backgroundColor={colors.black}
        style={styles.buttonContainer}
        firstColor={colors.black}
        secondColor={colors.black}
        underlayColor={colors.black}
      />
      <CancellationReasonsModal
        isVisible={isCancelOrderModalVisible}
        onDismiss={hideCancelModal}
        cancellationReasons={cancellationReasons}
        cancelOrder={cancelShopifyOrder}
        item={item}
      />
      <FoxyAlert
        isVisible={showShipmentCancelSuccess}
        hideSecondButton
        alertBoxTitle="We have noted your request"
        alertMessage="Our team will process your request for cancellation."
        firstButtonTitle="OKAY"
        firstButtonOnPress={hideShipmentCancelSuccessModal}
        onTapOutside={hideShipmentCancelSuccessModal}
        autoWrapContent
        firstButtonTextColor={colors.cta.lightBlue}
        image_url={images.successInfo}
        showImage
      />
      <FoxyAlert
        isVisible={showShipmentCancelFailure}
        hideSecondButton
        alertBoxTitle="Oops!"
        alertMessage="Something went wrong, please try again."
        firstButtonTitle="OKAY"
        firstButtonOnPress={hideShipmentCancelFailureModal}
        onTapOutside={hideShipmentCancelFailureModal}
        autoWrapContent
        firstButtonTextColor={colors.cta.lightBlue}
      />
    </>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 5,
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    width: Utility.getScreenWidth() - 24,
  },
});
