import React, { Component } from 'react';
import { View, StatusBar, BackHandler } from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';
import { denormalize } from 'normalizr';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { FOXY_URLS, LAYOUT } from '../../config/Constants';
import { fetchCategory, fetchList } from '../../actions/ActionTypes';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { AnalyticsManager } from '../../analytics';
import FilterUtils from '../../components/sort-filter-options/FilterUtils';
import SortingFilteringModal from '../../components/sort-filter-options/SortingFilteringModal';
import ScreenPlaceholder from '../../components/shared/ScreenPlaceholder';
import ServerError from '../../components/shared/ServerError';
import PageNotFound from '../../components/shared/PageNotFound';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import CategoryDetail from './CategoryDetail';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { getCategoryUrlParamsObject, getFullSlugFromName, nestedParamToName } from '../../utils/StringUtility';
import { isWeb } from '../../utils/BooleanUtility';
import AppInstallPrompts from '../../components/shared/AppInstallPrompts';

class Category extends Component {
  constructor(props) {
    super(props);
    Utility.setPageLoadStart('category_detail');
    this.state = {
      categoryData: {},
      showShimmer: true,
      stickyPosition: -1,
      selectedFilters: {},
      filters: [],
      quickFilters: [],
      filtersEnabled: false,
      filteredSlug: '',
      isPageLoading: true,
      serverError: false,
      hideOosProduct: false,
    };

    this.slug = '';
    this.prevAppliedFiltersFromModal = [];
    this.selectedQuickFilters = [];
    this.quickFiltersRef = null;
    this.fnAutoListPagination = null;
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
  }

  componentDidMount() {
    this.getSlugAndHitApi();
    this.fireListRenderedEvent;
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  getSlugAndHitApi = () => {
    const { layout } = this.props;
    if (layout === undefined) {
      let slugString = this.getRouteSlug();
      slugString = slugString.replace(/\/v\d+\//, '/v6/');

      this.hitApiCall(slugString);
    }
  };

  onRefresh = () => {
    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.filtersEnabled = false;
    this.selectedQuickFilters = [];
    this.getSlugAndHitApi();
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  fireListRenderedEvent = () => {
    const { itemData } = this.props;
    Utility.fireListRenderedEvent(itemData, SCREEN_CONSTANTS.CATEGORY);
  };

  fetchCategoryCallBack = (success, data, status) => {
    this.setState({
      showShimmer: false,
    });
    if (success) {
      this.addFiltersObjectIntoListData(data);
    } else {
      const { route: { params: { query = '' } = {} } = {} } = this.props;
      AnalyticsManager.logEvent('fetch_category_fail', {
        query,
      });
      Utility.clearPageLoadTimer();
      this.setState({
        isPageLoading: false,
        serverError: status !== 404,
      });
    }
  };

  firePageLoadEnd = () => {
    Utility.setPageLoadEnd(SCREEN_CONSTANTS.CATEGORY);
  };

  hitApiCall = (slug) => {
    this.setState({
      showShimmer: true,
    });
    const { itemData, fetchCategory } = this.props;
    AnalyticsUtility.recordTime(
      {
        screen_name: SCREEN_CONSTANTS.CATEGORY,
        ...Utility.setRecordTimeEventMeta(itemData, 'category'),
      },
      this.fetchCategoryCallBack,
      fetchCategory,
      { slug },
    );
  };

  setListPaginatedCallApiRef = (fnAutoListPagination) => {
    this.fnAutoListPagination = fnAutoListPagination;
  };

  addFiltersObjectIntoListData = (apiResponse) => {
    const response = apiResponse;
    const {
      list: objects,
      position: stickyPosition,
      slug,
    } = Utility.createFilterObjectIfExists(
      apiResponse.list.objects,
      true,
      false,
      true,
    );
    this.slug = slug;
    response.list.objects = objects;
    const { filters, quickFilters } = FilterUtils.getFiltersFromList(
      response.list,
    );

    this.setState({
      categoryData: response,
      filters,
      quickFilters,
      showShimmer: false,
      stickyPosition,
    });
  };

  onFiltersPress = (item, added, index) => {
    const { navigation } = this.props;
    const {
      categoryData,
      categoryData: { id = '', name = '', slug: brandSlug = '' },
      selectedFilters,
      filters,
      hideOosProduct,
    } = this.state;
    const { list } = FilterUtils.getList(categoryData.list);
    if (
      item.criteria_name === 'quick_filter_sort_by' ||
      item.criteria_name === 'quick_filter_foxy_match'
    ) {
      navigation.navigate('SortingFilteringModal', {
        selectedFilters,
        filterModel: filters,
        onFilterApplyPress: this.onFiltersPress,
        listName: name,
        listId: id,
        listSlug: brandSlug,
        screenName: SCREEN_CONSTANTS.CATEGORY,
        listData: categoryData,
        entityName: name,
        onPressHideOosCheckBox: this.onPressHideOosCheckBox,
        hideOosProduct,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
      });
      return;
    }

    const {
      slug,
      pageNo,
      allowNextPageLoad,
      filtersEnabled,
      groupedSelectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
    } = FilterUtils.onFilterApplied(
      item,
      added,
      index,
      list,
      selectedFilters,
      this.quickFiltersRef,
      this.slug,
      this.prevAppliedFiltersFromModal,
      this.selectedQuickFilters,
      SCREEN_CONSTANTS.CATEGORY,
      name,
    );

    this.slug = slug;
    this.page = pageNo;
    this.allowNextPageLoad = allowNextPageLoad;
    this.selectedQuickFilters = selectedQuickFilters;
    this.filtersEnabled = filtersEnabled;
    this.prevAppliedFiltersFromModal = prevAppliedFiltersFromModal;
    categoryData.list.objects = Utility.addShimmerInListObject(
      categoryData.list,
    );
    this.filtersEnabled = this.slug.includes('&');
    this.setState({
      selectedFilters: groupedSelectedFilters,
      categoryData,
      filteredSlug: this.slug,
    });

    this.fetchFilteredList(this.slug);
  };

  getQuickFiltersListRef = (ref) => {
    this.quickFiltersRef = ref;
  };

  onPressHideOosCheckBox = (isHideOos) => {
    this.setState({
      hideOosProduct: isHideOos,
    });
  };

  resetFilters = () => {
    const { navigation } = this.props;
    this.page = 0;
    this.filtersEnabled = false;
    this.slug = this.slug.substr(0, this.slug.indexOf('&'));
    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.selectedQuickFilters = [];

    this.fetchFilteredList(this.slug, true);
  };

  fetchFilteredList = (slug) => {
    const { fetchList } = this.props;
    const { hideOosProduct } = this.state;
    this.page = 0;
    // FIXME: Buggy Code, need to fix
    fetchList({ slug, hideOosProduct }, this.page, (success, data) => {
      const { categoryData } = this.state;
      const paginateLastItem = categoryData.list.paginate_last_item;
      const listData = FilterUtils.appendFilteredListToMasterListObjects(
        slug,
        data,
        categoryData.list,
      );
      categoryData.list = listData;
      this.setState(
        {
          categoryData: { ...categoryData }, // spreading : this will create a new object ref and will re-render component. Otherwise we are just changing list of main object. Id of previous object will be same and component will not get re-render
        },
        // this.renderPaginationList,
      );
    });
  };

  renderPaginationList = () => {
    const { categoryData = {} } = this.state;
    const paginateLastItem = categoryData.list.paginate_last_item;
    if (paginateLastItem) {
      this.fnAutoListPagination(this.slug, -1, true);
    }
  };

  itemDataNullFn = (props) => !props.itemData;

  isLayoutScreenFn = (props) => props.layout === 'screen';

  renderScreenPlaceHolder = () => {
    const { showShimmer, isPageLoading, categoryData } = this.state;
    if (!showShimmer) {
      return null;
    }
    return (
      <ScreenPlaceholder fullscreen firePageLoadEnd={this.firePageLoadEnd} />
    );
  };

  getRouteSlug = () => {
    const { route = {} } = this.props;
    const { params = {} } = route;
    const {
      slug,
      first = '',
      second = '',
      third = '',
    } = params;
    return slug || getFullSlugFromName(nestedParamToName([first, second, third]), 'categories');
  }

  render() {
    const {
      navigation,
      route,
      itemData,
      isLoading,
      toggleCartVisibility,
      onPressResetButton,
    } = this.props;
    const {
      categoryData,
      showShimmer,
      isPageLoading,
      serverError,
      hideOosProduct,
    } = this.state;
    let { layout } = this.props;
    layout = layout || (route.params?.display ?? LAYOUT.SCREEN);
    return (
      <>
        <this.renderScreenPlaceHolder />
        {!showShimmer && (
          <>
            <CategoryDetail
              {...this.props}
              categoryData={categoryData}
              id={categoryData.id}
              layout={layout}
              isLoading={showShimmer} // for placeholder
              toggleCartVisibility={toggleCartVisibility}
              stickyPosition={this.state.stickyPosition}
              selectedFilters={this.state.selectedFilters}
              onFiltersPress={this.onFiltersPress}
              getQuickFiltersListRef={this.getQuickFiltersListRef}
              onPressResetButton={this.resetFilters}
              setListPaginatedCallApiRef={this.setListPaginatedCallApiRef}
              slug={this.slug}
              filteredSlug={this.state.filteredSlug}
              resetStatusBar={this.resetStatusBar}
              filtersEnabled={this.filtersEnabled}
              isPageLoading={isPageLoading}
              onRefresh={this.onRefresh}
              serverError={serverError}
              hideOosProduct={hideOosProduct}
            />
          </>
        )}
        <AppInstallPrompts link={FOXY_URLS.appInstallPage.category} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchCategory, fetchList }, dispatch),
});

export default connect(null, mapDispatchToProps)(Category);
