// Dependencies
import React, { Component } from 'react';
import { TouchableOpacity, Text } from 'react-native';

// Components, Styles ,...
import PropTypes from 'prop-types';
import colors from '../theme/Colors';
import FoxyIcon from './FoxyIcon';
import Utility from '../utils/Utility';

class FoxyRoundedButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      height,
      borderColor,
      backgroundColor,
      titleColor,
      title,
      leftIcon,
      rightIcon,
      iconTintColor,
      onPress,
      iconHeight = 20,
      iconWidth = 20,
    } = this.props;
    
    return (
      <TouchableOpacity
        style={{
          height,
          backgroundColor,
          borderColor,
          borderWidth: 1,
          borderRadius: 20,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 12,
        }}
        onPress={onPress}
      >
        {!Utility.isBlank(leftIcon) && (
          <FoxyIcon
            disableIcon
            icon={leftIcon}
            height={iconHeight}
            width={iconWidth}
            backgroundColor={colors.transparent}
            tintColor={'#fff'}
          />
        )}
        <Text
          style={{
            color: titleColor,
            fontSize: 14,
            fontFamily: 'Roboto-Regular',
            paddingLeft: 8,
          }}
        >
          {title}
        </Text>
        {!Utility.isBlank(rightIcon) && (
          <FoxyIcon
            disableIcon
            icon={rightIcon}
            backgroundColor={colors.transparent}
            tintColor={iconTintColor}
          />
        )}
      </TouchableOpacity>
    );
  }
}

FoxyRoundedButton.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
  titleColor: PropTypes.string,
  borderColor: PropTypes.string,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  iconTintColor: PropTypes.string,
  onPress: PropTypes.func,
};

FoxyRoundedButton.defaultProps = {
  height: 40,
  backgroundColor: colors.white,
  titleColor: colors.black,
  title: 'Button',
  borderColor: colors.transparent,
  leftIcon: '',
  rightIcon: '',
  iconTintColor: colors.black,
  onPress: () => {},
};

export default FoxyRoundedButton;

// // Dependencies
// import React, { Component } from 'react';
// import { Image, TouchableOpacity, Text } from 'react-native';

// // Components, Styles ,...
// import PropTypes from 'prop-types';
// import images from '../theme/Images';
// import colors from '../theme/Colors';

// class FoxyRoundedButton extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       buttonEnabled: false,
//     };
//   }

//   onButtonPress = () => {
//     const { buttonEnabled } = this.state;
//     this.setState(prevState => ({
//       buttonEnabled: !prevState.buttonEnabled,
//     }));
//     this.props.onPress();
//   };

//   render() {
//     const {
//       height,
//       inactive,
//       borderRadius,
//       borderColor,
//       enableBackgroundColor,
//       disableBackgroundColor,
//       enableTitleColor,
//       disableTitleColor,
//       onPress,
//       title,
//       disableTintColor,
//       enableTintColor,
//       leftIcon,
//       onLeftIconPress,
//       onRightIconPress,
//       rightIcon,
//       marginLeft,
//       marginTop,
//     } = this.props;
//     const { buttonEnabled } = this.state;
//     const backgroundColor = buttonEnabled ? enableBackgroundColor : disableBackgroundColor;
//     const titleColor = buttonEnabled ? enableTitleColor : disableTitleColor;
//     const tintColor = buttonEnabled ? enableTintColor : disableTintColor;
//     return (
//       <TouchableOpacity
//         disabled={inactive}
//         style={{
//           borderRadius,
//           backgroundColor,
//           borderColor,
//           height,
//           marginLeft,
//           marginTop,
//           borderWidth: 1,
//           justifyContent: 'center',
//           alignItems: 'center',
//           paddingHorizontal: 16.0,
//           flexDirection: 'row',
//         }}
//         onPress={this.onButtonPress}
//       >
//         <>
//           {leftIcon !== undefined && (
//             <TouchableOpacity onPress={onLeftIconPress}>
//               <Image
//                 source={leftIcon}
//                 style={{
//                   tintColor,
//                   width: 16,
//                   height: 16,
//                   marginRight: 12,
//                 }}
//               />
//             </TouchableOpacity>
//           )}
//         </>
//         <Text style={{ color: titleColor }}>{title}</Text>
//         <>
//           {rightIcon !== undefined && (
//             <TouchableOpacity onPress={onRightIconPress}>
//               <Image
//                 source={rightIcon}
//                 style={{
//                   tintColor,
//                   width: 12,
//                   height: 16,
//                   marginLeft: 12,
//                 }}
//               />
//             </TouchableOpacity>
//           )}
//         </>
//       </TouchableOpacity>
//     );
//   }
// }

// FoxyRoundedButton.propTypes = {
//   height: PropTypes.number,
//   borderRadius: PropTypes.number,
//   disableBackgroundColor: PropTypes.string,
//   enableBackgroundColor: PropTypes.string,
//   borderColor: PropTypes.string,
//   marginLeft: PropTypes.number,
//   marginTop: PropTypes.number,
//   inactive: PropTypes.bool,
//   title: PropTypes.string,
//   disableTitleColor: PropTypes.string,
//   enableTitleColor: PropTypes.string,
//   disableTintColor: PropTypes.string,
//   enableTintColor: PropTypes.string,
//   leftIcon: PropTypes.any,
//   onLeftIconPress: PropTypes.func,
//   rightIcon: PropTypes.any,
//   onRightIconPress: PropTypes.func,
//   onPress: PropTypes.func,
// };

// FoxyRoundedButton.defaultProps = {
//   height: 36,
//   borderRadius: 20,
//   marginLeft: 0,
//   marginTop: 0,
//   inactive: false,
//   disableBackgroundColor: 'transparent',
//   enableBackgroundColor: 'white',
//   disableTitleColor: 'white',
//   enableTitleColor: 'black',
//   disableTintColor: 'white',
//   enableTintColor: 'black',
//   borderColor: 'white',
//   tintColor: 'white',
//   onPress: () => {},
//   onLeftIconPress: () => {},
//   onRightIconPress: () => {},
// };

// export default FoxyRoundedButton;
