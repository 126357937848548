import React, { PureComponent } from 'react';
import {
  View,
  Image,
  TouchableWithoutFeedback,
  BackHandler,
} from 'react-native';
import { style } from './RecommendationStyle';
import Utility from '../../utils/Utility';
import CrossButton from '../shared/CrossButton';
import { AnalyticsManager } from '../../analytics';
import images from '../../theme/Images';

export default class RecommendationPrompt extends PureComponent {
  constructor(props) {
    super(props);

    const { route } = props;
    this.hideStoriesIntro = route.params?.hideStoriesIntro;
    this.itemData = route.params?.itemData;
    this.item = route.params?.item;
    this.sortStories = route.params?.sortStories;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  onHardwareBackKeyPress = () => {
    this.goBack();
    return true;
  };

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  navigateToStories = () => {
    const { navigation } = this.props;

    this.hideStoriesIntro();

    AnalyticsManager.logEvent('story_intro_gif_continue');

    navigation.replace('StoryDetails', {
      item: this.item,
      itemData: this.itemData,
      sortStories: this.sortStories,
    });
  };

  navigateToRecommendationFlow = () => {
    const { navigation } = this.props;

    AnalyticsManager.logEvent('story_intro_gif_edit_preferences');

    navigation.replace('FollowRecommendations', {
      item: this.item,
      itemData: this.itemData,
      hideStoriesIntro: this.hideStoriesIntro,
      goBack: this.goBack,
      sortStories: this.sortStories,
    });
  };

  goBack = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent('story_intro_gif_close');
    this.hideStoriesIntro();
    navigation.goBack();
  };

  overlay = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          position: 'absolute',
          height: Utility.getScreenHeight(),
          width: Utility.getScreenWidth(),
        }}
      >
        <TouchableWithoutFeedback
          style={style.leftContainer}
          onPress={this.navigateToRecommendationFlow}
        >
          <View style={style.leftContainer} />
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback
          style={style.rightContainer}
          onPress={this.navigateToStories}
        >
          <View style={style.rightContainer} />
        </TouchableWithoutFeedback>

        <CrossButton onPress={this.goBack} />
      </View>
    );
  };

  render() {
    return (
      <View style={style.container}>
        <Image
          style={{
            width: '100%',
            height: '100%',
            resizeMode: 'stretch',
          }}
          source={images.animations.foxy_story}
        />
        <this.overlay />
      </View>
    );
  }
}
