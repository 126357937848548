import AnalyticsEvent from './AnalyticsEvent';
import { ReactMoE, MoEProperties } from '../libraries/ReactMoe';
import { isBlank, isPresent, isWeb } from '../utils/BooleanUtility';

class MoengageAnalytics {
  // TODO: Added this implementation to break the dependency cycle between
  // AnalyticsManager, MoengageAnalytics and Utility. Should be refactored
  // and removed.

  static trackEvent = (event) => {
    if (isWeb()) return;
    if (event instanceof AnalyticsEvent) {
      let { meta, eventType } = event;

      const properties = new MoEProperties();

      if (isBlank(meta)) {
        meta = null;
      }

      for (var key in meta) {
        if (meta.hasOwnProperty(key)) {
          properties.addAttribute(key, meta[key]);
        }
      }

      ReactMoE.trackEvent(eventType, properties);
    }
  };

  static setUserId = (userId) => {
    if (isWeb()) return;
    ReactMoE.setUserUniqueID(userId);
  };

  static setUserAlias = (userId) => {
    if (isWeb()) return;
    ReactMoE.setAlias(userId);
  };

  static setUserProperty = (key, value) => {
    if (isWeb()) return;
    ReactMoE.setUserAttribute(key, value);
  };

  static setGeneralUserAttributes = (object) => {
    if (isWeb()) return;
    const { name, email, contactNumber, firstName, lastName } = object;
    if (isPresent(name)) {
      ReactMoE.setUserName(name);
      ReactMoE.setUserFirstName(firstName);
      if (lastName) {
        ReactMoE.setUserLastName(lastName);
      }
    }
    if (isPresent(email)) {
      ReactMoE.setUserEmailID(email);
    }
    if (isPresent(contactNumber)) {
      ReactMoE.setUserContactNumber(contactNumber);
    }
  };
}

export default MoengageAnalytics;
