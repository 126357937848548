import { Linking } from 'react-native';
import { takeLatest } from 'redux-saga/effects';
import { GET_IOS_UPI_APPS } from '../actions/ActionTypes';
import { REMOTE_CONFIG_KEYS } from '../config/Constants';
import RemoteConfig from '../utils/RemoteConfig';

export function* getInstalledUpiAppsOnIOS(action) {
  const { callback } = action;

  const object = JSON.parse(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.upi_apps_ios),
  );

  const objectKeys = Object.keys(object);
  const availableApps = [];
  for (key of objectKeys) {
    const packageName = object[key].url_scheme;
    const isAllowed = yield Linking.canOpenURL(packageName);
    if (isAllowed) {
      availableApps.push(object[key]);
    }
  }
  callback(availableApps);
}

export default function* watchDeviceSaga() {
  yield takeLatest(GET_IOS_UPI_APPS, getInstalledUpiAppsOnIOS);
}
