import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';

class BirthDayInfoCard extends PureComponent {
  render() {
    return (
      <>
        <Text style={styles.title}>Your Details</Text>
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <Image
              style={styles.giftLogo}
              source={{
                uri: 'https://cdn3.foxy.in/media/app_asset/image/521/image_303.png',
              }}
            />
            <Text style={styles.body}>
              so that we can send a welcome gift & birthday surprises your way
              🎁
            </Text>
          </View>
        </View>
      </>
    );
  }
}
export default BirthDayInfoCard;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: '#FFFBCB',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  subContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  giftLogo: {
    height: 40,
    width: 40,
    marginRight: 10,
  },
  body: {
    flex: 1,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.black,
  },
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    color: colors.foxyBlack,
    marginVertical: 6,
  },
});
