import React, { Component } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import _ from 'lodash';
import { TouchableOpacity } from 'react-native-gesture-handler';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import FastImageView from '../FastImageView';

const styles = StyleSheet.create({
  description: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    lineHeight: 16,
    color: colors.foxyBlack,
  },
  indicationsText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    lineHeight: 14,
    color: '#fff',
  },
  indicationContainer: {
    paddingHorizontal: 6,
    paddingVertical: 3,
    marginRight: 4,
    borderRadius: 4,
    marginTop: 8,
  },
  rightContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'wrap',
  },
  ingredientName: { fontSize: 18, fontFamily: 'Roboto-Bold', lineHeight: 22 },
  container: { marginHorizontal: 12, marginTop: 8 },
  linearContainer: { padding: 12, borderRadius: 8 },
  leftContainer: { flexDirection: 'row', marginBottom: 12 },
  imageContainer: { width: 96, height: 96 },
  nameContainer: { flex: 1, marginLeft: 12 },
});

export class Ingredient extends Component {
  constructor(props) {
    super(props);
    const { ingredientAttributesForPayoff = [], itemData } = this.props;
    this.itemDataIndications = itemData?.indications?.filter((e) => Utility.isBlank(ingredientAttributesForPayoff) ||
      ingredientAttributesForPayoff?.includes(e)).slice(0, 6);
  }

  componentDidMount() {
    this.debouncedNavigate = _.debounce(this.navigateToIngredients, 1000, {
      leading: true,
      trailing: false,
    });
  }

  navigateToIngredients = (slug) => {
    const {
      index,
      listId,
      previousScreen,
      listData: {
        name: listName = '',
        type: listType = '',
        content: listContent = '',
        display: listDisplay = '',
        slug: listSlug = '',
      },
      listIndex,
      navigation,
    } = this.props;
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      listId,
      listType,
      listName,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listDisplay,
      '',
      '',
      listSlug,
    );
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'MoreItems',
      params: {
        slug,
      },
    });
  };

  render() {
    const { itemData } = this.props;

    if (!itemData?.icon_image_url) {
      return null;
    }

    const startColor = itemData?.background_color_start || colors.white;
    const endColor = itemData?.background_color_end || colors.white;
    return (
      <TouchableOpacity
        style={styles.container}
        onPress={() => this.debouncedNavigate(itemData?.slug)}
      >
        <LinearGradient
          colors={[startColor, endColor]}
          style={styles.linearContainer}
        >
          <View style={styles.leftContainer}>
            <FastImageView
              source={itemData?.icon_image_url}
              style={styles.imageContainer}
              resizeMode='contain'
            />
            <View style={styles.nameContainer}>
              <Text
                style={[
                  styles.ingredientName,
                  { color: itemData?.other_color },
                ]}
              >
                {itemData?.name}
              </Text>
              <View style={styles.rightContainer}>
                {_.map(this.itemDataIndications, (e) => {
                  if (
                    Utility.isBlank(e) ||
                    Utility.isBlank(itemData?.indications_mapping[`${e}`])
                  ) {
                    return null;
                  }
                  return (
                    <View
                      style={[
                        { backgroundColor: itemData?.other_color },
                        styles.indicationContainer,
                      ]}
                    >
                      <Text style={styles.indicationsText}>
                        {itemData?.indications_mapping[`${e}`]}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
          <View>
            <Text style={styles.description}>
              {itemData?.description || ''}
            </Text>
          </View>
        </LinearGradient>
      </TouchableOpacity>
    );
  }
}

export default withNavigation(Ingredient);
