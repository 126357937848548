import { StyleSheet, Platform } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';

const styles = StyleSheet.create({
  searchScreen: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
  },
  navigationHeaderContainer: {
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
    top: 0,
    zIndex: 1,
    flexDirection: 'row',
  },
  navigationBackButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 40,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 6,

    backgroundColor: 'rgba(255, 255, 255,0.8)',
    left: 12,
  },
  navbarIconDefaultStyle: {
    width: 20,
    height: 17.5,
    resizeMode: 'contain',
  },
  searchHeaderBackButton: {
    width: 20,
    height: 17.5,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
  },
  textInputStyle: {
    position: 'absolute',
    bottom: 6,
    right: 24,
    height: 40,
    left: 54,
    color: colors.foxyBlack,
    paddingRight: 12,
  },
  divider: {
    position: 'absolute',
    height: 1,
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.border,
  },
  searchSuggestion: {
    position: 'absolute',
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
    zIndex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: Utility.getWindowHeight() - 216,
  },
  searchSuggestionContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 50,
    width: Utility.getScreenWidth(),
  },
  searchSuggestionText: {
    color: colors.foxyBlack,
    marginLeft: 60,
    marginRight: 16,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
  },
  searchSuggestionImage: {
    position: 'absolute',
    left: 12,
    alignSelf: 'center',
    height: 34,
    width: 34,
    borderRadius: 2,
  },
  searchSuggestionCategoryText: {
    color: colors.silver,
    marginLeft: 60,
    marginRight: 16,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    marginTop: 4,
  },
  historyIcon: {
    height: 24,
    width: 24,
    marginLeft: 16,
    tintColor: colors.silver,
    fontSize: 10,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
  },
  emptySearchContainer: {
    flex: 1,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
  },
  emptySearchBackground: {
    height: Utility.getScreenHeight() / 3,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
  },
  emptySearchLoader: {
    height: Utility.getScreenHeight() / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headingContainer: {
    flexDirection: 'row',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 20,
    marginBottom: 8,
  },
  subHeading: {
    fontSize: 18,
    lineHeight: 18,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
    flex: 1,
    alignContent: 'flex-start',
  },
  optionsContainer: {
    width: Utility.getScreenWidth() - 24,
    flexDirection: 'row',
    marginLeft: 12,
    marginRight: 12,
    overflow: 'hidden',
    flexWrap: 'wrap',
    paddingTop: 4,
    paddingBottom: 4,
  },
  optionPill: {
    height: 24,
    margin: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    borderColor: colors.silver,
    flexWrap: 'wrap',
    backgroundColor: colors.border,
    maxWidth: Utility.getScreenWidth() - 4 * Utility.padding,
  },
  optionText: {
    alignSelf: 'center',
    color: '#173143',
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    maxWidth: Utility.getScreenWidth() - 4 * Utility.largePadding,
    marginLeft: 16,
    marginRight: 16,
    ...Platform.select({
      web: { marginTop: 0 },
      default: { marginTop: 6 },
    }),
  },
  optionTextWithImage: {
    alignSelf: 'center',
    color: '#173143',
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    maxWidth: Utility.getScreenWidth() - 4 * Utility.largePadding,
    marginLeft: 4,
    marginRight: 16,
    ...Platform.select({
      web: { marginTop: 0 },
      default: { marginTop: 6 },
    }),
  },
  optionImage: { height: 22, width: 22, resizeMode: 'contain', marginLeft: 16 },
  clearButton: {
    fontSize: 14,
    color: colors.cta.lightBlue,
    alignContent: 'flex-end',
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
  },
  clearText: {
    alignSelf: 'center',
    height: 12,
    width: 12,
    tintColor: colors.foxyBlack,
  },
  scanQr: {
    alignSelf: 'center',
    height: 20,
    width: 20,
    tintColor: colors.foxyBlack,
  },
  clearTextContainer: {
    position: 'absolute',
    width: 32,
    height: 40,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 6,

    backgroundColor: 'rgba(255, 255, 255,0.8)',
    right: 12,
  },
  imageSearchIcon: {
    position: 'absolute',
    height: 16,
    width: 16,
    right: 16,
    bottom: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  camera: {
    tintColor: colors.border,
    height: 16,
    width: 24,
    resizeMode: 'contain',
  },
  storeSwitchContainer: {
    height: 40,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginTop: 12,
  },
  storeSwitch: {
    position: 'absolute',
    right: 8,
    bottom: 10,
    height: 24,
  },
  searchInStoreText: {
    color: colors.silver,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 16,
  },
  searchSuggestContainer: {
    flex: 1,
    position: 'absolute',
    top: Utility.isIOS() ? (Utility.isIphone14ProOrLater() ? 100 : 80) : 60,
    left: 0,
    right: 0,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
    zIndex: 2,
  },
  searchSuggestContainerWithKeyboard: {
    backgroundColor: colors.white,
  },
  searchSuggestItem: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    borderBottomWidth: 0.7,
    borderBottomColor: colors.background,
    height: 62,
  },
  searchSuggestIcon: {
    width: 40,
    height: 40,
    paddingRight: 10,
    resizeMode: 'contain',
    marginLeft: 12,
  },
  searchSuggestImage: {
    width: 40,
    height: 40,
    paddingRight: 10,
    resizeMode: 'contain',
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 4,
    marginLeft: 12,
  },
  searchSuggestAnimation: {
    width: 40,
    height: 40,
    paddingRight: 10,
    marginLeft: 12,
    resizeMode: 'contain',
  },
  searchSuggestGif: {
    width: 16,
    height: 16,
    paddingRight: 10,
    resizeMode: 'contain',
  },
  searchSuggestTextBox: {
    width: '100%',

    padding: 5,
    paddingVertical: 8,
    marginLeft: 12,
    marginRight: 12,
  },
  searchSuggestText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    lineHeight: 18,
    marginRight: 8,
  },

  searchSuggestSubText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.subText,
  },
  indicator: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    // height: 80,
  },
  NoResultContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    width: Utility.getScreenWidth() - 80,
  },
  loaderContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  autosuggestContainer: {
    backgroundColor: colors.white,
    marginBottom: 100,
    width: Utility.getScreenWidth(),
  },
  autoSuggestNameContainer: {
    flexDirection: 'row',
    maxWidth: 0.8 * Utility.getScreenWidth(),
  },
  autoSuggestProductNameContainer: {
    flexDirection: 'row',
    maxWidth: 0.8 * Utility.getScreenWidth(),
    height: 34,
  },
});

export default styles;
