import React, { Component } from 'react';
import {
  Text,
  View,
  Keyboard,
  ScrollView,
  TouchableOpacity,
  Animated,
  ActivityIndicator,
  BackHandler,
  KeyboardAvoidingView,
  FlatList,
} from 'react-native';
import { TextField } from 'react-native-material-textfield';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import uuidv1 from 'uuid/v1';
import PropTypes from 'prop-types';
import Toast from 'react-native-easy-toast';

import styles from './styles';
import Utility from '../../../utils/Utility';
import colors, { Theme } from '../../../theme/Colors';
import { UPLOAD_POST } from '../../../config/LayoutConstants/UploadPostConfig';
import * as UploadPostActions from '../../../actions/UploadPostActions';
import * as LoginActions from '../../../actions/LoginActions';
import ContentCategory from '../../../lib/ContentCategory';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { StaticNavigationHeader } from '../../../components/shared';
import { withEither } from '../../../lib/Monads';
import FoxyAlert from '../../../components/camera/shared/FoxyAlert';
import FullWidthDivider from '../../../utils/FullWidthDivider';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../../analytics';

const {
  postDescription,
  contentCategoryContainer,
  taggedProductsContainer,
  postCoverImageContainer,
} = UPLOAD_POST.height;

class UploadVideo extends Component {
  constructor(props) {
    super(props);
    const { route } = props;
    this.previousScreen = route.params?.previousScreen ?? '';
    this.post = route.params?.post;
    this.mediaItemsExternalDirectoryPath =
      route.params?.mediaItemsExternalDirectoryPath;
    this.navigatingFrom = route.params?.navigatingFrom;
    this.postTitle = '';
    this.iconBackground = Theme.uploadPost.iconBackground;
    this.iconTintColor = Theme.uploadPost.iconTintColor;
    this.currentPost = props.posts[props.currentPostID];
    this.mediaItems = props.posts[props.currentPostID].items;
    this.artistSuggestions = [];
    // FIXME: Arrow function should return a value for map to work. Someone just wanted to use reduce but only knew about map.
    Object.keys(props.followedArtists).map((key) => {
      const artistData = props.entity.artists[key];
      this.artistSuggestions.push(artistData);
    });
    this.mkdirOptions = Utility.isAndroid()
      ? {}
      : { NSURLIsExcludedFromBackupKey: true };
    this.mergeCount = 0;
    this.state = {
      tagText: '',
      hashtags: [],
      currentHashtags: [],
      description: this.currentPost.postTitle,
      postThumbnail: props.posts[props.currentPostID].postThumbnail,
      hashTagState: false,
      showPeoplesSuggestion: false,
      publishPost: false,
      selectedContentCategory: !Utility.isBlank(
        props.posts[props.currentPostID].postCategory,
      )
        ? [props.posts[props.currentPostID].postCategory]
        : [],
      postHashTags: '',
      mentions: '',
      uploadingProcessBegan: false,
      showBackAlert: false,
      showSaveAlert: false,
      isTitleEmpty: false,
    };
    this.postThumbnail = '';
    this.shouldUpload = true;
    this.toastRef = React.createRef();
    this.uploadingProgress = new Animated.Value(0);

    this.productsTagged = [];
    this.currentPost.items.forEach((array, index) => {
      this.productsTagged.push(array.products);
    });
    this.postProducts = _.compact(this.productsData);
    this.campaignId = route.params?.campaignId;
    this.campaignName = route.params?.campaignName;
    this.campaignProducts = route.params?.campaignProducts;
    this.reviewProductId = route.params?.reviewProductId;
    this.setupSafeVariables(Utility.topInset, Utility.bottomInset);
    this.bottomDockHeight = 70;
  }

  componentDidMount() {
    const { loadMediaCategories, uploadPostActions } = this.props;
    if (loadMediaCategories) {
      uploadPostActions.getMediaCategories();
    }
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          this.setState({
            showBackAlert: true,
          });
          return true;
        },
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler.remove();
    }
  }

  setupSafeVariables = (topInset, bottomInset) => {
    const { deviceAPILevel } = Utility;
    this.topInset = topInset;
    this.bottomInset = bottomInset;
    if (Utility.isAndroid()) {
      if (
        (deviceAPILevel < 28 && Utility.statusBarHeight() > 24) ||
        (deviceAPILevel >= 28 && Utility.statusBarHeight() < 33)
      ) {
        this.headerContainerHeight = 40 + 10;
        this.publishPostContainerHeight =
          Utility.screenHeight -
          (this.headerContainerHeight +
            contentCategoryContainer +
            taggedProductsContainer +
            postDescription +
            postCoverImageContainer +
            Utility.statusBarHeight());
      } else {
        this.headerContainerHeight = 40 + Utility.statusBarHeight();
        this.publishPostContainerHeight =
          Utility.screenHeight -
          (this.headerContainerHeight +
            contentCategoryContainer +
            taggedProductsContainer +
            postDescription +
            postCoverImageContainer);
      }
    } else {
      this.headerContainerHeight = 40 + topInset + Utility.statusBarHeight();
      this.publishPostContainerHeight =
        Utility.screenHeight -
        (this.headerContainerHeight +
          contentCategoryContainer +
          taggedProductsContainer +
          postDescription +
          postCoverImageContainer);
    }
    this.forceUpdate();
  };

  isIosFn = () => Utility.isIOS();

  customKeyboardAvoid = withEither(this.isIosFn, KeyboardAvoidingView)(View);

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  title = (props) => {
    const moreButtonText = this.previousScreen !== '' ? '' : 'Change';
    return (
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{props.titleText}</Text>
        {props.showRightButton && (
          <TouchableOpacity onPress={props.onRightButtonPress}>
            <Text style={styles.MoreButton}>{moreButtonText}</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  postDescription = (props) => {
    const { description, isTitleEmpty } = this.state;
    return (
      <View style={styles.postDescriptionContainer}>
        <TextField
          defaultValue={props.description}
          // placeholder="Title of the post..."
          label="Title"
          placeholderColor={colors.subtitle}
          onChangeText={this.enterDescription}
          style={styles.postText}
          hideLabel={false}
          value={description}
          lineWidth={1}
          autoFocus
          placeholderTextColor={colors.subtitle}
          returnKeyType="done"
          inputContainerStyle={{ borderBottomColor: colors.border }}
          error={isTitleEmpty ? 'Title is mandatory' : null}
          tintColor={colors.silver}
        />
        {/* <this.hashTagsAndMentionsContainer
        toggleHashtagState={props.toggleHashtagState}
        toggleShowPeoplesSuggestion={props.toggleShowPeoplesSuggestion}
      /> */}
      </View>
    );
  };

  // hashTagsAndMentionsContainer = props => (
  //   <View style={styles.hashTagAndMentionContainer}>
  //     <TouchableOpacity style={styles.hashTagButton} onPress={props.toggleHashtagState}>
  //       <Text style={styles.buttonText}># Hashtags</Text>
  //     </TouchableOpacity>
  //   </View>
  // );

  enterDescription = (text) => {
    const lastEnteredCharacter = _.last(text);
    if (lastEnteredCharacter !== '#' || lastEnteredCharacter !== '@') {
      this.setState({ description: text, isTitleEmpty: false });
    }
  };

  // onKeyPress = (e) => {
  //   const { description, hashTagState } = this.state;
  //   const { uploadPostActions } = this.props;
  //   if (hashTagState) {
  //     const words = description.split(' ');
  //     const LastWord = words[words.length - 1];
  //     uploadPostActions.searchHashTag(LastWord.substr(1), (hashTagSuggestions) => {
  //       this.setState({ hashtags: hashTagSuggestions });
  //     });
  //   }
  //   if (e.nativeEvent.key === '@') {
  //     const newDescription = description.substring(0, description.length - 1);
  //     this.setState({
  //       showPeoplesSuggestion: true,
  //       hashTagState: true,
  //       description: `${newDescription} @`,
  //     });
  //   }
  //   if (e.nativeEvent.key === '#') {
  //     const newDescription = description.substring(0, description.length - 1);
  //     this.setState({ hashTagState: true, description: `${newDescription} #` });
  //   }
  //   if (e.nativeEvent.key === ' ') {
  //     this.setState({ hashTagState: false });
  //   }
  //   if (e.nativeEvent.key === 'Backspace') {
  //     const lastCharacter = description.charAt(description.length - 1);
  //     if (lastCharacter === '#') {
  //       this.setState({ hashTagState: false });
  //     }
  //     if (lastCharacter === ' ') {
  //       const words = description.split(' ');
  //       const secondLastWord = words[words.length - 2];
  //       const secondLastFirstCharacter = secondLastWord[0];
  //       if (secondLastFirstCharacter === '#') {
  //         this.setState({ hashTagState: true });
  //       }
  //     }
  //   }
  // };

  onSubmitTag = () => {
    const { tagText, currentHashtags } = this.state;
    currentHashtags.push(tagText);
    this.setState({ currentHashtags });
  };

  toggleHashtagState = () => {
    const { hashTagState } = this.state;
    if (hashTagState === false) {
      this.setState((prevState) => ({
        description: prevState.description.concat(' #'),
      }));
    }
    this.setState((prevState) => ({ hashTagState: !prevState.hashTagState }));
  };

  getCategoryComponent = (item, index, props) => {
    const { selectedContentCategory } = this.state;
    return (
      <ContentCategory
        key={item.id}
        item={item}
        index={index}
        onPress={this.toggleContentCategorySelection}
        selectedContentCategory={selectedContentCategory}
      />
    );
  };

  contentCategoryContainer = (props) => (
    <View style={styles.contentCategoryContainer}>
      <this.title titleText="Pick a type of video" showRightButton={false} />
      <View style={styles.contentCategoryRailHeight}>
        {/* <ScrollView
          horizontal
          style={styles.contentCategoryRailContainer}
          showsHorizontalScrollIndicator={false}
        > */}
        <FlatList
          data={props.mediaCategories}
          scrollEnabled={false}
          keyExtractor={(item) => `${item.name}_grid`}
          numColumns={3}
          renderItem={({ item, index }) =>
            this.getCategoryComponent(item, index, props)
          }
          showsVerticalScrollIndicator={false}
          style={styles.brandsFlatlist}
          extraData={this.state.selectedContentCategory}
        />
        {/* {props.mediaCategories.map((item, index) => (
            <ContentCategory
              key={item.id}
              item={item}
              index={index}
              onPress={this.toggleContentCategorySelection}
              selectedContentCategory={props.selectedContentCategory}
            />
          ))} */}
        {/* </ScrollView> */}
      </View>
    </View>
  );

  toggleContentCategorySelection = (index) => () => {
    const { selectedContentCategory } = this.state;
    const newSelectedContentCategory = [];
    if (!selectedContentCategory.includes(index)) {
      newSelectedContentCategory.push(index);
    }
    this.setState({ selectedContentCategory: newSelectedContentCategory });
  };

  showSaveToDraftToast = () => {
    this.saveToDraft();
    this.toast.show('Successfully saved to draft');
  };

  actionButton = () => (
    <TouchableOpacity style={styles.actionButton} onPress={this.publishPost}>
      <Text style={styles.actionButtonText}>Upload</Text>
    </TouchableOpacity>
  );

  bottomButtonDock = (props) => {
    let buttonText = 'Publish';
    if (this.navigatingFrom === 'post_edit') {
      buttonText = 'Update';
    }
    const width =
      this.navigatingFrom === 'post_edit'
        ? Utility.getScreenWidth() / 1.2
        : 202;
    return (
      <this.customKeyboardAvoid
        behavior="position"
        enabled
        keyboardVerticalOffset={-18}
      >
        <View
          style={[
            styles.actionBottonContainer,
            {
              height: this.bottomDockHeight + Utility.bottomInset,
              backgroundColor: 'white',
              marginBottom: 12,
            },
          ]}
        >
          <this.actionButton />
          <TouchableOpacity onPress={this.showSaveToDraftAlert}>
            <Text style={styles.saveButtonText}>Save</Text>
          </TouchableOpacity>

          {/* <View style={styles.actionButtonContainer}>
            {this.navigatingFrom !== 'post_edit' && (
              <TouchableOpacity style={styles.saveToDraftButtonContainer} onPress={this.showSaveToDraftAlert}>
                <Text style={styles.saveToDraftButtonText}>Save to drafts</Text>
              </TouchableOpacity>
            )}
            <FoxyShadowButton title={buttonText} width={width} height={46} onPress={this.publishPost} />
          </View> */}
        </View>
      </this.customKeyboardAvoid>
    );
  };

  updateMedia = () => {
    const { navigation, route, uploadPostActions } = this.props;
    const { description, selectedContentCategory } = this.state;
    const mediaObject = route.params?.actualDataObject;
    this.setState({
      ...this.state,
      uploadingProcessBegan: true,
    });

    uploadPostActions.updatePost(
      {
        title: description,
        media_category_id: selectedContentCategory[0],
        postId: mediaObject.id,
      },
      (response) => {
        this.setState({
          ...this.state,
          uploadingProcessBegan: false,
        });

        if (response) {
          this.toastRef.current.show('Media Updated');
          setTimeout(() => {
            const selectedIndex = route.params?.selectedIndex;
            const videoType = route.params?.videoType;
            if (route.params?.navigatingFrom === 'post_edit') {
              navigation.goBack();
              route.params?.updatePost(selectedIndex, description, videoType);
            } else {
              navigation.navigate('MyUploads');
              route.params?.updatePost(selectedIndex, description, videoType);
            }
          }, 1000);
        } else {
          this.toastRef.current.show(
            'Something went wrong while updating media',
          );
        }
      },
    );
  };

  publishPost = () => {
    const { description, selectedContentCategory } = this.state;
    console.tron.log('selected cat is', selectedContentCategory);
    if (this.navigatingFrom === 'post_edit') {
      this.updateMedia();
    } else if (Utility.isBlank(description)) {
      if (this.shouldUpload) {
        this.setState({
          isTitleEmpty: true,
        });
        this.toastRef.current.show('Title is Mandatory');
      }
    } else if (selectedContentCategory.length === 0) {
      if (this.shouldUpload) {
        Keyboard.dismiss();
        this.toast.show('Please select a category');
      }
    } else if (selectedContentCategory[0] === 'video') {
      Keyboard.dismiss();
      if (this.shouldUpload) {
        this.toast.show('Please select a category');
      }
    } else {
      if (this.shouldUpload) {
        this.setState({ uploadingProcessBegan: true });
      }
      if (this.currentPost.uploadType === 'FoxyVideo') {
        this.updateVideoFileOnStore(this.mediaItems[0].mediaUriAbsolutePath);
      } else {
        this.uploadPostOnFoxyApp();
      }
    }
  };

  updateVideoFileOnStore = (output) => {
    const { uploadPostActions, currentPostID, route } = this.props;
    let campaignId = '';
    let reviewProductId = '';
    let forGift = false;
    if (this.previousScreen === SCREEN_CONSTANTS.MY_PROFILE) {
      campaignId = route.params?.campaignId ?? '';
      forGift = route.params?.forGift ?? false;
      if (Utility.isPresent(campaignId)) {
        forGift = false;
      }
    }
    if (Utility.isPresent(this.reviewProductId)) {
      reviewProductId = this.reviewProductId;
    }
    let mediaUriAbsolutePath = output;
    const firstFour = mediaUriAbsolutePath.substring(0, 4);
    if (firstFour !== 'file') {
      mediaUriAbsolutePath = `file://${mediaUriAbsolutePath}`;
    }
    const VideoData = [
      {
        mimeType: 'video/mp4',
        uuid: uuidv1(),
        uri: output,
        mediaUriAbsolutePath,
        mediaUriExternalDirectoryPath: '',
        mediaBase64Uri: '',
        mediaType: 'video',
        caption: '',
        recentProductSearchID: '',
        products: this.postProducts,
        videoFrames: [],
        productsTag: [],
        campaignId,
        forGift,
        reviewProductId,
      },
    ];
    uploadPostActions.updateContentItems(currentPostID, VideoData, () => {
      console.tron.log('video data is ', VideoData);
      this.uploadPostOnFoxyApp(VideoData);
    });
  };

  uploadPostOnFoxyApp = (VideoData) => {
    const { description, selectedContentCategory, postHashTags, mentions } =
      this.state;
    const {
      uploadPostActions,
      loginActions,
      currentPostID,
      navigation,
      route,
    } = this.props;
    const postData = {
      title: description,
      postHashTags,
      mentions,
      selectedContentCategory,
      fileUrl: VideoData[0].mediaUriAbsolutePath,
    };
    if (Utility.isPresent(this.campaignId)) {
      postData.campaignId = this.campaignId;
      postData.campaignProducts = this.campaignProducts;
      AnalyticsManager.logEvent(
        EventType.artistEvents.CAMPAIGN_VIDEO_SUBMITTED,
        {
          [EventParameterKey.CAMPAIGN_ID]: this.campaignId,
          [EventParameterKey.CAMPAIGN_NAME]: this.campaignName,
        },
      );
    }
    if (Utility.isPresent(this.reviewProductId)) {
      postData.reviewProductId = this.reviewProductId;
    }
    if (!this.shouldUpload) {
      uploadPostActions.updatePostTitle(currentPostID, postData);
      return;
    }
    loginActions.markMediaUploadBegin();
    if (this.previousScreen === SCREEN_CONSTANTS.PRODUCT_DETAIL) {
      const uploadVideoForReview = route.params?.uploadVideoForReview;
      uploadVideoForReview(currentPostID, postData);
      navigation.goBack();
    }
  };

  togglePublishPost = () =>
    this.setState((prevState) => ({ publishPost: !prevState.publishPost }));

  showActivityIndicator = () => (
    <View
      style={{
        zIndex: 100,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <ActivityIndicator size="large" color="black" />
    </View>
  );

  saveToDraft = () => {
    this.shouldUpload = false;
    this.toast.show('Saved to draft');
    setTimeout(() => {
      this.publishPost();
    }, 500);
  };

  confirmDraft = () => {
    Keyboard.dismiss();
    this.setState({
      showBackAlert: false,
    });
    this.saveToDraft();
    setTimeout(() => {
      this.goBack();
    }, 1000);
  };

  confirmDraftOnCurrentScreen = () => {
    this.setState({
      showSaveAlert: false,
    });
    Keyboard.dismiss();
    this.saveToDraft();
  };

  cancelDraft = () => {
    const { uploadPostActions, currentPostID } = this.props;
    const data = currentPostID;
    uploadPostActions.deleteDraft(data);
    this.goBack();
    this.setState({
      showBackAlert: false,
    });
  };

  cancelAlertOnCurrentScreen = () => {
    this.setState({
      showSaveAlert: false,
      showBackAlert: false,
    });
  };

  showDraftAlert = () => {
    Keyboard.dismiss();
    this.setState({
      showBackAlert: true,
    });
  };

  showSaveToDraftAlert = () => {
    Keyboard.dismiss();
    this.setState({
      showSaveAlert: true,
    });
  };

  render() {
    const {
      posts,
      entity,
      currentPostID,
      followedArtists,
      mediaCategories,
      uploadPostActions,
      isUploading,
      navigation,
    } = this.props;
    const {
      hashTagState,
      description,
      hashtags,
      showBackAlert,
      publishPost,
      selectedContentCategory,
      uploadingProcessBegan,
      showSaveAlert,
    } = this.state;
    const currentPost = posts[currentPostID];
    let title = 'Upload Video';
    if (this.navigatingFrom === 'post_edit') {
      title = 'Update Video';
    }
    return (
      <View style={styles.container}>
        <StaticNavigationHeader
          title={title}
          customBack
          onBackPress={this.showDraftAlert}
        />
        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
        <ScrollView style={{ height: Utility.screenHeight - 100 }}>
          <Toast ref={this.toastRef} />
          <this.postDescription
            description={description}
            post={posts}
            currentPostID={currentPostID}
            entity={entity}
            toggleHashtagState={this.toggleHashtagState}
            toggleShowPeoplesSuggestion={this.toggleShowPeoplesSuggestion}
          />
          {/* <FullWidthDivider margin={16}/> */}
          <this.contentCategoryContainer
            mediaCategories={mediaCategories}
            selectedContentCategory={selectedContentCategory}
          />
          <FullWidthDivider margin={16} />
        </ScrollView>
        <FoxyAlert
          isVisible={showBackAlert}
          alertBoxTitle="Save as Draft?"
          alertMessage={'You can resume uploading this video from "My Videos"'}
          firstButtonTitle="Save to Draft"
          firstButtonTextColor={colors.dodgerBluePressed}
          secondButtonTitle="Discard"
          firstButtonOnPress={this.confirmDraft}
          secondButtonOnPress={this.cancelDraft}
          showCross
          cancelPressed={this.cancelAlertOnCurrentScreen}
        />
        <FoxyAlert
          isVisible={showSaveAlert}
          alertBoxTitle="Save as Draft?"
          alertMessage={'You can resume uploading this video from "My Videos"'}
          firstButtonTitle="Save to Draft"
          firstButtonTextColor={colors.dodgerBluePressed}
          secondButtonTitle="Cancel"
          firstButtonOnPress={this.confirmDraftOnCurrentScreen}
          secondButtonOnPress={this.cancelAlertOnCurrentScreen}
        />
        <this.bottomButtonDock
          publishPostContainerHeight={this.publishPostContainerHeight}
          bottomInset={this.bottomInset}
        />
        {uploadingProcessBegan && <this.showActivityIndicator />}
      </View>
    );
  }
}

UploadVideo.propTypes = {
  posts: PropTypes.any,
  entity: PropTypes.any,
  currentPostID: PropTypes.any,
  uploadPostActions: PropTypes.any,
  loginActions: PropTypes.any,
  isUploading: PropTypes.any,
  mediaCategories: PropTypes.any,
  navigation: PropTypes.any,
  followedArtists: PropTypes.any,
  loadMediaCategories: PropTypes.bool,
  downloadLogo: PropTypes.bool,
};

export default connect(
  (state, ownProps) => ({
    posts: state.Posts,
    entity: state.data.entities,
    currentPostID: Utility.isBlank(ownProps.currentPostId)
      ? state.UserAccountInfo.currentPostId
      : ownProps.currentPostID,
    handle: state.UserAccountInfo.profile.handle,
    followedArtists: state.UserAccountInfo.followedArtists,
    isUploading: state.UserAccountInfo.isUploading,
    mediaCategories: state.UserAccountInfo.mediaCategories,
    loadMediaCategories:
      state.UserAccountInfo.singletonSteps.loadMediaCategories,
  }),
  (dispatch) => ({
    uploadPostActions: bindActionCreators(UploadPostActions, dispatch),
    loginActions: bindActionCreators(LoginActions, dispatch),
  }),
)(UploadVideo);
