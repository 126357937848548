import { StyleSheet } from 'react-native';

const avatarImageDim = 36;
const sidePadding = 15.0;
const topPadding = 10.0;
const internalPadding = 12.0;
const titleHorizontalPaddingAvatarImage = 12.0;
const artistFollowButtonDim = 44.0;

const styles = StyleSheet.create({
  avatarImageContainer: {
    width: 36,
    height: 36,
    marginRight: 12.0,
  },
  textContainer: {
    width: 202,
    height: 30,
    top: 3,
  },
  contentTitle: {
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#ffffff',
    lineHeight: 14,
    height: 14,
    zIndex: 10000,
    fontFamily: 'Roboto-Medium',
  },
  artistName: {
    fontSize: 11,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#efefef',
    lineHeight: 13,
    height: 13,
    zIndex: 10000,
    fontFamily: 'Roboto-Regular',
  },
  avatarImage: {
    width: avatarImageDim,
    height: avatarImageDim,
    borderRadius: avatarImageDim / 2,
    zIndex: 10000,
  },
  followButton: {
    width: 41.0,
    height: 25.0,
    marginTop: 5.5,
    marginBottom: 5.5,
    position: 'absolute',
    right: 20,
    zIndex: 10000,
  },
  titleText: {
    color: 'white',
  },
});

export default styles;
