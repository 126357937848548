import StyleSheet from 'react-native-media-query';
import Utility from '../../utils/Utility';
import { getScreenWidth } from '../../utils/LayoutUtility';

const avatarImageDim = 36;
const sidePadding = 15.0;
const topPadding = 10.0;
const internalPadding = 12.0;
const titleHorizontalPaddingAvatarImage = 12.0;
const artistFollowButtonDim = 44.0;

export const { ids, styles } = StyleSheet.create({
  avatarImageContainer: {
    width: 36,
    height: 36,
    marginRight: 12.0,
  },
  textContainer: {
    width: 202,
    height: 30,
    top: 3,
  },
  artistTextContainer: {
    height: 30,
    justifyContent: 'center',
  },
  artistTextView: { flexDirection: 'row', marginBottom: 2 },
  contentTitle: {
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#ffffff',
    zIndex: 10000,
    fontFamily: 'Roboto-Medium',
    paddingRight: 16,
    lineHeight: 16,
  },
  contentArtistName: {
    fontSize: 13,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#ffffff',
    height: 20,
    zIndex: 10000,
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  contentDescription: {
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#ffffff',
    zIndex: 10000,
    fontFamily: 'Roboto-Regular',
  },
  artistName: {
    fontSize: 11,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#efefef',
    lineHeight: 13,
    height: 13,
    zIndex: 10000,
    fontFamily: 'Roboto-Regular',
  },
  avatarImage: {
    width: avatarImageDim,
    height: avatarImageDim,
    borderRadius: avatarImageDim / 2,
    resizeMode: 'cover',
    zIndex: 10000,
  },
  followButton: {
    marginLeft: 8,
  },
  followButtonIcon: { height: 16, width: 16 },
  whitedot: {
    width: 4.0,
    height: 4.0,
    backgroundColor: 'white',
    borderRadius: 2.0,
    marginLeft: 6,
  },
  titleText: {
    color: 'white',
  },
  container: {
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    marginTop: Utility.isIOS() ? 14 : 0,
    overflow: 'visible',
    '@media (min-width: 768px)': {
      width: getScreenWidth() / 2.5,
      marginLeft: 10,
    },
  },
  containerByRatio: {
    width: (296 * Utility.getScreenWidth()) / 380,
    flexDirection: 'row',
    marginLeft: 12,
    alignItems: 'flex-start',
    overflow: 'visible',
    '@media (min-width: 768px)': {
      width: (296 * getScreenWidth() / 2.5) / 380,
    },
  },
  artistContainerView: {
    flexDirection: 'column',
    width: Utility.isAndroid()
      ? Utility.getScreenWidth() * 0.8
      : Utility.getScreenWidth() * 0.7,
    '@media (min-width: 768px)': {
      width: (getScreenWidth() / 2.5) * 0.75,
    },
  },
  recommendationTextView: { marginTop: -2 },
  artistNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  artistContainer: {
    width: '90%',
    justifyContent: 'center',
    '@media (min-width: 768px)': {
      width: getScreenWidth() / 3,
    },
  },
});
