import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import size from '../../theme/Fonts';

const styles = {
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  orderDetailContainer: {
    // height: 120,
    flexL: 1,
    width: Utility.getScreenWidth() - 12,
    marginTop: 12,
    borderWidth: 1,
    borderColor: colors.customerSupport.customerOrderStatus.borderColor,
    borderRadius: 4,
    marginLeft: 6,
  },
  detailWrapper: {
    paddingVertical: 10,
  },
  rowWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 12,
    marginRight: 12,
    marginTop: 8,
  },
  orderDetailText: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  orderDetailSubText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  orderDetailBlueText: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.cta.lightBlue,
  },
  disableAwbStyleText: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.cta.subtitle,
  },
  listContainer: {
    marginTop: 12,
    marginBottom: 12,
  },
  listItemText: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    marginLeft: 12,
    flex: 1,
  },
  listItemTextBold: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    marginLeft: 15,
    flex: 1,
  },

  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 10,
  },

  pointStyle: {
    borderWidth: 1,
    height: 12,
    width: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.white,
    marginTop: 36,
    marginLeft: 16,
  },

  point: {
    width: 8,
    height: 8,
    backgroundColor: colors.black,
    borderRadius: 6,
  },
  horizontalLine: {
    height: '100%',
    width: 2,
    position: 'absolute',
    left: 20,
    top: 36,
    resizeMode: 'stretch',
  },
  currentStateText: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  lastLocationText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 4,
  },
  instructionsText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    marginTop: 8,
  },
  statusChangedText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  stepShowContainer: {
    marginTop: 32,
    marginLeft: 12,
  },
  buttonWidth: {
    width: Utility.getScreenWidth() - 52,
  },
  buttonContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: '#ffffffb3',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: Utility.isIOS() ? 20 : 8,
  },
  linkButtonContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: '#ffffffb3',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: Utility.isIOS() ? 20 : 8,
  },
  linkIconStyle: {
    width: 21,
    height: 21,
  },
  secondaryButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: '#ffffffb3',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: Utility.isIOS() ? 20 : 8,
  },
  button: {
    // height: 44,
    borderWidth: 1,
    borderColor: colors.foxyBlack,
  },
  textStyle: {
    color: colors.foxyBlack,
  },
  shipmentMessageStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    paddingHorizontal: 20,
    paddingTop: 28,
  },
};

export default styles;
