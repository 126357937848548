import React, { Component } from 'react';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import _ from 'lodash';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import FastImageView from '../FastImageView';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';

// eslint-disable-next-line react/prefer-stateless-function
class Shipment extends Component {
  constructor(props) {
    super(props);
    const {
      shipment: { shipmentItems } = {},
      orderItems,
      showOrder,
      onlyOneOrder,
    } = this.props;
    onlyOneOrder && this.onActionCardClick();
    this.orderItems = {};
    if (!showOrder) {
      shipmentItems.forEach((shipmentItem = {}) => {
        const { orderItem } = shipmentItem;

        this.orderItems[orderItem.status] = {
          ...{
            [orderItem.id]: {
              ...(this.orderItems[orderItem.status]?.id || {}),
              ...orderItem,
            },
          },
          ...this.orderItems[orderItem.status],
        };
      });
    } else {
      orderItems.forEach((orderItem) => {
        this.orderItems[orderItem.status] = {
          ...{
            [orderItem.id]: {
              ...(this.orderItems[orderItem.status]?.id || {}),
              ...orderItem,
            },
          },
          ...this.orderItems[orderItem.status],
        };
      });
    }
  }

  onActionCardClick = (props = {}) => {
    const {
      id,
      index,
      totalShipments,
      renderProp,
      navigation,
      merchantOrderName,
    } = this.props;
    const { status = '' } = props;

    navigation.push('OrderDetails', {
      id,
      status,
      index,
      totalShipments,
      renderProp,
      merchantOrderName,
    });
  };

  actionItem = (props) => {
    const { orderItem = {}, status } = props;
    const { shipment: { external } = {}, platform } = this.props;

    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={styles.actionItemContainer}
        onPress={() => this.onActionCardClick({ status })}
      >
        <View style={styles.actionItemInternalContainer}>
          <Text style={styles.actionItemHeading}>
            {orderItem[Object.keys(orderItem)[0]]?.heading}
          </Text>
          {external && (
            <View style={styles.externalTagContainer}>
              <Image source={images.ic_coupon} style={styles.actionItemImage} />
              <Text style={styles.tagText}>Fulfilled By Brand</Text>
            </View>
          )}
          {platform === 'offline_store' && (
            <View style={styles.storeTagContainer}>
              <Image source={images.ic_store} style={styles.actionItemImage} />
              <Text style={styles.tagText}>Store Pickup</Text>
            </View>
          )}
        </View>
        <Text style={styles.actionItemSubheading}>
          {orderItem[Object.keys(orderItem)[0]]?.subheading}
        </Text>
        <ScrollView
          style={styles.imagesContainer}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {_.map(orderItem, (item = {}) => {
            const { sku: { imageUrl } = {} } = item;

            return (
              <FastImageView
                source={Utility.getMinifiedImage(imageUrl, 120)}
                style={styles.orderItemImage}
                resizeMode='contain'
              />
            );
          })}
        </ScrollView>
        <Image source={images.chevronRight} style={styles.chevronRight} />
      </DebouncedTouchableOpacity>
    );
  };

  render() {
    return (
      <View>
        {Object.entries(this.orderItems).map(([key, value]) => {
          return <this.actionItem orderItem={value} status={key} />;
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  actionItemContainer: {
    paddingTop: 8,
    borderTopWidth: 1,
    borderTopColor: colors.background,
  },
  actionItemInternalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionItemHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  externalTagContainer: {
    flexDirection: 'row',
    marginLeft: 8,
    alignItems: 'center',
    backgroundColor: '#E4F2FF',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 16,
  },
  actionItemImage: {
    height: 12,
    width: 12,
    tintColor: colors.foxyBlack,
    marginRight: 6,
    resizeMode: 'contain',
    opacity: 0.7,
  },
  tagText: {
    fontSize: 11,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  actionItemSubheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#979BAA',
    marginRight: 52,
  },
  imagesContainer: {
    flexDirection: 'row',
    marginVertical: 8,
    zIndex: 1,
    height: 50,
  },
  orderItemImage: {
    height: 36,
    width: 36,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: '#F4F6F8',
    marginRight: 4,
  },
  chevronRight: {
    height: 12,
    width: 7,
    position: 'absolute',
    tintColor: colors.foxyBlack,
    right: 12,
    top: 12,
  },
  storeTagContainer: {
    flexDirection: 'row',
    marginLeft: 8,
    alignItems: 'center',
    backgroundColor: '#FFF6DD',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 16,
  },
});

export default withNavigation(Shipment);
