import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

const styles = StyleSheet.create(
  (function () {
    const tempStyles = {
      container: {
        padding: 12,
        width: Utility.getScreenWidth(),
        flexDirection: 'column',
      },

      title: {
        fontFamily: 'Roboto-Medium',
        color: colors.black,
        fontSize: size.h2,
      },
      subtitle: {
        fontFamily: 'Roboto-Regular',
        color: colors.black,
        fontSize: size.h3,
        width: '90%',
      },

      row: {
        flexDirection: 'row',
        marginTop: 16,
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      avatarContainer: {
        height: 32,
        width: 32,
        borderRadius: 16,
        marginRight: 4,
        backgroundColor: colors.transparent,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 12,
      },
      imageStyle: { height: 32, width: 32, borderRadius: 16 },
      buttonContainer: {
        width: 150,
        height: 32,
        backgroundColor: colors.black,
        borderRadius: 32,
        justifyContent: 'center',
        alignItems: 'center',
      },
      buttonText: {
        fontFamily: 'Roboto-Medium',
        fontSize: size.h4,
        color: colors.white,
      },
      shimmerLong: {
        height: 20,
        width: '80%',
        borderRadius: 8,
      },
      shimmerShort: {
        height: 16,
        width: '50%',
        marginVertical: 12,
        borderRadius: 8,
      },
      icon: {
        height: 16,
        width: 16,
      },
      image: {
        width: 32,
        height: 32,
        borderRadius: 16,
      },
      text: {
        fontFamily: 'Roboto-Medium',
        fontWeight: '500',
        color: colors.white,
      },
      margin: { marginVertical: 12, marginBottom: 16 },
      rowStyle: { flexDirection: 'row' },
      couponContainer: {
        width: Utility.getScreenWidth(),
        height: 40,
        marginHorizontal: -12,
      },
    };

    colors.contactsInitialsColor.forEach(({ bgColor, textColor }, index) => {
      tempStyles[`avatarBackground${index}`] = [
        tempStyles.avatarContainer,
        { backgroundColor: bgColor },
      ];
      tempStyles[`textColor${index}`] = [
        tempStyles.text,
        { color: textColor },
      ];

      // styles for stacking the avatars invitees are greater than 4
      /** assigning negative margins and descending zIndex to each avatar based on their
       * index to create the effect of stacking */
      /**  First item in the stack will be always have negative left margin also
       *  to shift behind the initiators static avatar */
      tempStyles[`stackedAvatarBackground${index}`] = [
        tempStyles[`avatarBackground${index}`],
        {
          marginRight: -12,
          zIndex: 11 - index,
        },
      ];
      if (index === 0) {
        tempStyles[`stackedAvatarBackground${index}`] = [
          tempStyles[`stackedAvatarBackground${index}`],
          { marginLeft: -16 },
        ];
      }
    });
    return tempStyles;
  })(),
);

export default styles;
