import React, { Component, PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  SafeAreaView,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Platform,
} from 'react-native';
import Modal from 'react-native-modal';

import BottomSheet from '../onboarding/BottomSheet';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import {
  AnalyticsManager,
  AnalyticsEvent,
  EventType,
  EventParameterKey,
} from '../../analytics';

export default class BottomModal extends PureComponent {
  openWhatsApp = (message) => {
    Utility.openWhatsApp(message);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isVisible) {
      if (!prevProps.isVisible) {
        if (Utility.isBlank(this.props.modalName)) {
          // AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW,{});
        } else {
          AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
            [EventParameterKey.MODAL_NAME]: this.props.modalName,
          });
        }
      }
    }
  }

  buttonLayout = (props) => (
    <>
      <TouchableWithoutFeedback
        style={[styles.buttonLayout, { marginBottom: Utility.bottomInset }]}
        onPress={props.onActionClick}
      >
        <View
          style={[
            styles.centerAlignedColumn,
            styles.buttonLayout,
            { flexDirection: 'row' },
          ]}
        >
          <Text style={[styles.headerText, { color: colors.white }]}>
            {props.buttonTitle}
          </Text>
          {props.isProcessing && (
            <ActivityIndicator color={colors.white} size="large" />
          )}
        </View>
      </TouchableWithoutFeedback>
      {props.showWhatsAppBar && (
        <TouchableWithoutFeedback
          onPress={() => this.openWhatsApp(props.whatsappMessage)}
        >
          <Text style={styles.whatsappLink}> Contact us on WhatsApp</Text>
        </TouchableWithoutFeedback>
      )}
    </>
  );

  onBackdropPress = () => {
    const { onPressOutside } = this.props;
    onPressOutside();
  };

  render() {
    const {
      header,
      subheading,
      buttonTitle,
      onClose,
      onActionClick,
      onPressOutside,
      imgSrc,
      isProcessing,
      isVisible,
      whatsappMessage,
      showWhatsAppBar,
    } = this.props;
    return (
      <Modal
        isVisible={isVisible}
        onBackdropPress={this.onBackdropPress}
        style={{
          position: 'absolute',
          // height: Utility.getWindowHeight(),
          bottom: 0,
          left: 0,
          right: 0,
          marginBottom: 0,
          marginRight: 0,
          marginLeft: 0,
          justifyContent: 'flex-end',
        }}
        onSwipeEnd={this.onBackdropPress}
        onSwipeComplete={this.onBackdropPress}
        swipeDirection="down"
        swipeThreshold={200}
        propagateSwipe
        backdropTransitionOutTiming={0}
      >
        <SafeAreaView>
          <View containerStyle={styles.bottomSheetWrapper}>
            <View style={styles.contentContainer}>
              <View style={styles.row}>
                <Text style={styles.headerText}>{header}</Text>
                <TouchableWithoutFeedback onPress={onClose}>
                  <View style={styles.roundCircle}>
                    <Image
                      source={images.cross}
                      style={{ tintColor: '#000000' }}
                    />
                  </View>
                </TouchableWithoutFeedback>
              </View>

              <View style={styles.centerAlignedColumn}>
                <Image
                  style={styles.imageStyle}
                  source={imgSrc}
                  resizeMode="contain"
                />

                <Text style={styles.subheadingFont}>{subheading}</Text>

                <this.buttonLayout
                  isProcessing={isProcessing}
                  buttonTitle={buttonTitle}
                  onActionClick={onActionClick}
                  whatsappMessage={whatsappMessage || ''}
                  showWhatsAppBar={showWhatsAppBar}
                />
              </View>
            </View>
          </View>
        </SafeAreaView>
      </Modal>
    );
  }
}

BottomModal.defaultProps = {
  showWhatsAppBar: true,
};

const styles = StyleSheet.create({
  centerAlignedColumn: {
    justifyContent: 'center',
    marginTop: 18,
    alignItems: 'center',
  },
  bottomSheetWrapper: {
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingLeft: 1,
    paddingRight: 1,

    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  contentContainer: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    padding: 20,
    minHeight: 320,
    borderTopRightRadius: 16,
    marginBottom: -12,
    ...Platform.select({
      web: { width: Utility.getScreenWidth() - 40 },
      default: { width: Utility.getScreenWidth() },
    }),
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    color: colors.foxyBlack,
  },
  roundCircle: {
    height: 28,
    width: 28,
    borderRadius: 40,
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyle: {
    // height: 120,
    // width: 120,
  },
  subheadingFont: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    marginTop: 20,
    textAlign: 'justify',
    color: colors.foxyBlack,
  },
  buttonLayout: {
    height: 46,
    backgroundColor: colors.green,
    borderRadius: 4,
    ...Platform.select({
      web: { width: Utility.getScreenWidth() - 60 },
      default: { width: Utility.getScreenWidth() - 40 },
    }),
  },
  whatsappLink: {
    marginBottom: 30,
    marginTop: 15,
    fontFamily: 'Roboto-Bold',
    color: colors.green,
    fontSize: size.h3,
  },
});
