import React, { PureComponent } from 'react';
import { View, Text, FlatList } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FastImageView from '../../components/FastImageView';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import ScalingUtility from '../../utils/ScalingUtility';
import Utility from '../../utils/Utility';
import withNavigation from '../../utils/WithNavigation';
import { incrementDecrementCart } from '../../actions/ActionTypes';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';

const keyExtractor = (item, index) => item?.sku_id?.toString() || index;
class OfferCapModal extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = props;
    const {
      coupon_code = '',
      openedFromOffer = false,
      data = {},
    } = route.params;

    this.incrementButtonText = `Remove ${coupon_code} and increase quantity`;
    this.offerButtonText = openedFromOffer
      ? `Do not apply ${coupon_code}`
      : 'Do not increase quantity';

    AnalyticsManager.logEvent(EventType.order.MODAL_VIEW, {
      [EventParameterKey.SOURCE]: openedFromOffer
        ? 'apply_offer'
        : 'cart_increment',
      [EventParameterKey.SKU_ID]: data?.sku_id,
      [EventParameterKey.COUPON_CODE]: coupon_code,
      [EventParameterKey.MODAL_NAME]: 'offer_cap_modal',
    });
  }

  componentWillUnmount() {
    const { route } = this.props;
    const { coupon_code = '' } = route.params;
    AnalyticsManager.logEvent(EventType.order.MODAL_CLOSE, {
      [EventParameterKey.COUPON_CODE]: coupon_code,
      [EventParameterKey.MODAL_NAME]: 'offer_cap_modal',
    });
  }

  closeModal = () => {
    const { navigation } = this.props;
    navigation.pop();
  };

  onTapIncrementButton = () => {
    const { incrementDecrementCart, route, navigation } = this.props;
    const { data = {} } = route.params;
    const dataToSend = { ...data, isForceIncrement: true };

    incrementDecrementCart(dataToSend, () => {});

    this.buttonTapEvent(this.incrementButtonText, data?.sku_id);

    navigation.pop();
  };

  onTapOfferButton = () => {
    const { navigation, route } = this.props;
    navigation.pop();

    this.buttonTapEvent(this.offerButtonText, null);
  };

  buttonTapEvent = (text, skuId) => {
    const { route } = this.props;
    const { coupon_code = '' } = route.params;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
      [EventParameterKey.ACTION]: 'button_tap',
      [EventParameterKey.TYPE]: text,
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.COUPON_CODE]: coupon_code,
      [EventParameterKey.MODAL_NAME]: 'offer_cap_modal',
    });
  };

  renderOfferCartItem = ({ item }) => {
    let image = item?.product?.image_url;
    image = Utility.getMinifiedImage(image, 32, 32);
    return (
      <View style={styles.imageContainer}>
        <FastImageView source={image} style={styles.imageStyle} />
        {item.quantity > 1 && (
          <View style={styles.cartItemQuantityContainer}>
            <Text style={styles.cartItemQuantity}>{item.quantity}</Text>
          </View>
        )}
      </View>
    );
  };

  renderOfferCartItems = ({ eligibleSkuIds }) => {
    if (Utility.isBlank(eligibleSkuIds)) return null;
    const { cartItems } = this.props;
    let cartItemsToRender = [];
    let totalQuantity = 0;
    cartItems.forEach((cartItem) => {
      if (eligibleSkuIds.includes(cartItem.sku_id)) {
        cartItemsToRender.push(cartItem);
        totalQuantity += cartItem.quantity;
      }
    });
    if (Utility.isBlank(cartItemsToRender)) return null;

    return (
      <>
        <View style={styles.listContainer}>
          <FlatList
            data={cartItemsToRender}
            renderItem={this.renderOfferCartItem}
            keyExtractor={keyExtractor}
            horizontal
            contentContainerStyle={styles.cartItemsContainer}
            showsHorizontalScrollIndicator={false}
          />
        </View>
        <Text style={styles.listText}>
          {`${totalQuantity} products in bag are eligible`}
        </Text>
      </>
    );
  };

  render() {
    const { route } = this.props;
    const {
      heading = '',
      subheading = '',
      eligible_sku_ids = [],
      openedFromOffer = false,
    } = route.params;
    return (
      <>
        <DebouncedTouchableOpacity
          {...this.props}
          style={styles.container}
          onPress={this.closeModal}
        />
        <View style={styles.modal} dataSet={{ media: ids.modal }}>
          <Text style={styles.heading}>{heading}</Text>
          <Text style={styles.subheading}>{subheading}</Text>
          <this.renderOfferCartItems eligibleSkuIds={eligible_sku_ids} />
          {!openedFromOffer && (
            <DebouncedTouchableOpacity
              {...this.props}
              style={styles.incrementButton}
              onPress={this.onTapIncrementButton}
            >
              <Text style={styles.incrementButtonText}>
                {this.incrementButtonText}
              </Text>
            </DebouncedTouchableOpacity>
          )}
          <DebouncedTouchableOpacity
            {...this.props}
            style={styles.offerButton}
            onPress={this.onTapOfferButton}
          >
            <Text style={styles.offerButtonText}>{this.offerButtonText}</Text>
          </DebouncedTouchableOpacity>
          <DebouncedTouchableOpacity
            {...this.props}
            style={styles.cross}
            hitSlop={Utility.getHitSlop()}
            onPress={this.closeModal}
            dataSet={{ media: ids.cross }}
          >
            <FastImageView
              style={styles.crossImage}
              source={images.blackCross}
            />
          </DebouncedTouchableOpacity>
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      incrementDecrementCart,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(OfferCapModal),
);

const { ids, styles } = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.translucent,
  },
  listContainer: {
    height: 46,
    marginBottom: 8,
  },
  cartItemsContainer: {
    justifyContent: 'center',
    height: 46,
  },
  listText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontWeight: '400',
    color: '#979BAA',
    marginBottom: 12,
  },
  emptyView: {
    width: Utility.getScreenWidth(),
    top: 0,
    backgroundColor: colors.yellow,
    marginBottom: 0,
  },
  modal: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    position: 'absolute',
    bottom: 0,
    paddingTop: 24.0,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 14.0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    zIndex: 999,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2,
      bottom: null,
      alignSelf: 'center',
      justifySelf: 'center',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  heading: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '700',
    fontSize: 18,
    alignSelf: 'flex-start',
    width: Utility.getScreenWidth() - 80,
    color: colors.black,
  },
  subheading: {
    marginVertical: 12,
    alignSelf: 'flex-start',
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    fontSize: 14,
    color: colors.black,
  },
  imageContainer: {
    height: 46,
    width: 46,
  },
  imageStyle: {
    marginTop: 7,
    height: 32,
    width: 32,
    borderWidth: 0.5,
    borderRadius: 2,
    borderColor: '#F4F6F8',
    marginRight: 12,
  },
  cartItemQuantityContainer: {
    height: 14,
    width: 14,
    right: 7,
    top: 7,
    position: 'absolute',
    borderRadius: 7,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FD5C63',
  },
  cartItemQuantity: {
    fontFamily: 'Roboto-Regular',
    fontWeight: '700',
    fontSize: 8,
    color: colors.white,
  },
  cross: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: '#E4E4E4',
    right: 14,
    position: 'absolute',
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width: 768px)': {
      marginTop: 0,
    },
  },
  crossImage: {
    right: 1,
    bottom: 1,
    width: 11,
    height: 11,
    alignSelf: 'center',
  },
  incrementButton: {
    height: 40,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.black,
    marginBottom: 10,
    marginTop: 6,
  },
  incrementButtonText: {
    fontFamily: 'Roboto-Medium',
    fontWeight: '500',
    fontSize: 14,
    color: colors.black,
  },
  offerButtonText: {
    fontFamily: 'Roboto-Medium',
    fontWeight: '500',
    fontSize: 14,
    color: colors.white,
  },
  offerButton: {
    height: 40,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.black,
    borderRadius: 4,
    marginBottom: 18,
  },
});
