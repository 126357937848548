import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../theme/Colors';
import LeaderBoardCard from './LeaderBoardCard';

class MyLeaderBoardCard extends PureComponent {
  constructor(props) {
    super(props);
    this.colors = ['#EDEFFF', '#FFEEEE'];
  }
  render() {
    const { data } = this.props;
    return (
      <View style={styles.container}>
        <LinearGradient
          colors={this.colors}
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          style={styles.progressGradient}
        />
        <View style={styles.leaderBoardContainer}>
          <LeaderBoardCard data={data} />
        </View>
      </View>
    );
  }
}

export default MyLeaderBoardCard;

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 12,
    marginHorizontal: 4,
    marginVertical: 16,
    borderColor: colors.purple,
    height: 52,
  },
  leaderBoardContainer: {
    top: 0,
    width: '100%',
    position: 'absolute',
  },
  progressGradient: {
    top: 0,
    height: '100%',
    width: '100%',
    alignSelf: 'center',
    borderRadius: 12,
  },
});
