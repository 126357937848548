import { StyleSheet, Dimensions } from 'react-native';
import colors, { Theme } from '../../../theme/Colors';
import { UPLOAD_POST } from '../../../config/LayoutConstants/UploadPostConfig';
import Utility from '../../../utils/Utility';

const {
  headerContainer,
  headerContainerInset,
  postBasicInfoContainer,
  contentCategoryContainer,
  taggedProductsContainer,
  postDescription,
  editVideoContainer,
  editSettingsContainer,
  publishPostContainer,
  postCoverImageContainer,
} = UPLOAD_POST.height;

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },

  headerContainer: {
    justifyContent: 'flex-end',
  },

  MoreButton: {
    color: colors.foxyPink,
    fontSize: 14,
  },

  header: {
    height: headerContainerInset,
    backgroundColor: 'white',
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },

  headerText: {
    color: 'black',
    marginLeft: 12,
    fontSize: 16,
  },

  postBasicInfoContainer: {
    height: postBasicInfoContainer,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  postTitleStuffContainer: {
    width: 230,
    justifyContent: 'space-between',
  },

  postText: {
    color: colors.foxyBlack,
    padding: 0,
    fontSize: 14,
  },

  postTitleLength: {
    color: 'rgb(227,227,227)',
  },

  hashTagAndMentionContainer: {
    flexDirection: 'row',
  },

  hashTagButton: {
    height: 24,
    width: 84,
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: colors.border,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },

  mentionButton: {
    height: 24,
    width: 84,
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: colors.border,
    justifyContent: 'center',
    alignItems: 'center',
  },

  buttonText: {
    color: colors.black,
  },

  postCoverContainer: {
    width: 75,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
  },

  coverImage: {
    height: 76,
    width: 54,
    borderRadius: 4,
    backgroundColor: 'grey',
  },

  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 16,
    paddingHorizontal: 16,
    marginBottom: 12,
    marginTop: 4,
  },

  title: {
    color: colors.foxyBlack,
    fontSize: 16,
  },

  contentCategoryContainer: {
    // height: 160,
    // paddingHorizontal: 16,
    paddingVertical: 16,
    // borderBottomWidth: 1,
    // borderBottomColor: colors.border,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  contentCategoryRailHeight: {
    // height: 98,
    overflow: 'visible',
    alignItems: 'center',
  },

  contentCategoryRailContainer: {
    flexDirection: 'row',
    flex: 1,
  },

  taggedProductsContainer: {
    height: taggedProductsContainer,
    width: Utility.screenWidth,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
  },

  addedProductsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  productsSet: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  editVideoContainer: {
    height: editVideoContainer,
    width: Utility.screenWidth,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
  },

  postDescriptionContainer: {
    height: 80,
    width: Utility.screenWidth,
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 16,
    // borderBottomWidth: 1,
    borderBottomColor: 'transparent',
  },

  editSettingsContainer: {
    height: editSettingsContainer,
    width: Utility.screenWidth,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
  },

  leftSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  leftSectionText: {
    color: 'black',
    fontSize: 14,
    marginRight: 6,
  },

  publishPostContainer: {
    justifyContent: 'flex-start',
    width: Utility.screenWidth,
  },

  publishPostContainerInset: {
    width: Utility.screenWidth,
    justifyContent: 'flex-end',
    padding: 16,
    alignItems: 'center',
  },

  sharingContainer: {
    height: 72,
    alignSelf: 'flex-start',
    justifyContent: 'space-between',
  },

  sharingContainerText: {
    color: 'grey',
    fontSize: 12,
  },

  sharingMediaContainer: {
    height: 40,
    flexDirection: 'row',
  },

  actionButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  saveToDraftButtonContainer: {
    width: Utility.screenWidth - (64 + 165),
    justifyContent: 'center',
    alignItems: 'center',
  },

  saveToDraftButtonText: {
    color: 'rgb(151, 154, 171)',
    fontSize: 16,
  },

  hashTagsSuggestions: {
    position: 'absolute',
    left: 0,
    right: 0,
    minHeight: 40,
    backgroundColor: 'white',
    zIndex: 100,
    paddingHorizontal: 16,
    borderBottomWidth: 0.2,
    borderColor: colors.border,
  },

  hashTag: {
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  peoplesSuggestionContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'white',
  },

  searchBoxContainer: {
    height: 70,
    width: Utility.screenWidth,
    justifyContent: 'center',
    alignItems: 'center',
  },

  searchBox: {
    width: 328,
    height: 40,
    backgroundColor: colors.border,
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  textInputBox: {
    fontSize: 16,
    color: 'grey',
    width: 328 - (32 + 26),
    height: 40,
    alignSelf: 'center',
  },

  peoplesContainer: {
    height: Utility.screenHeight - (70 + 20),
    width: Utility.screenWidth,
    paddingVertical: 16,
  },

  postCoverImageContainer: {
    height: postCoverImageContainer,
    width: Utility.screenWidth,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
  },
  actionBottonContainer: {
    position: 'absolute',
    height: 70,
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  actionButton: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    height: 46,
    backgroundColor: colors.primaryActionBackgroundColor,
    width: Utility.getScreenWidth() - 40,
  },
  actionButtonText: {
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontSize: 18,
    marginLeft: 5,
  },
  saveButtonText: {
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,

    fontSize: 18,
    marginTop: 12,
    marginBottom: 16,
  },
});

export default styles;
