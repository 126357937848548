import React, { Component } from 'react';
import {
  View,
  KeyboardAvoidingView,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';

import Utility from '../../utils/Utility';

export default class BottomSheet extends Component {
  constructor(props) {
    super(props);
  }

  renderAccordingToOs = (props) => {
    const { children, containerStyle, onPressOutside } = this.props;
    if (Utility.isAndroid()) {
      return (
        <TouchableWithoutFeedback onPress={onPressOutside}>
          <View style={[styles.container, containerStyle]}>
            <TouchableWithoutFeedback>
              <View>{children}</View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      );
    }
    return (
      <TouchableWithoutFeedback onPress={onPressOutside}>
        <View style={[styles.container, containerStyle]}>
          <KeyboardAvoidingView
            behavior='position'
            enabled
            keyboardVerticalOffset={-18}
          >
            <TouchableWithoutFeedback>
              <View>{children}</View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  render() {
    return (
      <this.renderAccordingToOs onPressOutside={this.props.onPressOutside} />
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    zIndex: 100,
    elevation: 2,
  },
});
