import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';

import ProductDescription from './ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AddToCart from './AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT, MORE_ITEMS } from '../../config/Constants';
import colors from '../../theme/Colors';
import ProductColorVariants from '../variants/ProductColorVariants';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import ProductSizeVariants from '../variants/ProductSizeVariants';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { applyFlashDeal } from '../../actions/ActionTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FastImageView from '../FastImageView';
import Images from '../../theme/Images';
import size from '../../theme/Fonts';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

class SlimProductRail extends PureComponent {
  static getComponentHeight() {
    return styles.railCardContainer.height;
  }

  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug },
      toggleCartVisibility,
      applyFlashDeal,
      previousScreen,
    } = this.props;

    // if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
    applyFlashDeal({
      type: 'product',
      id,
    });
    // }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        id,
        slug,
        display: LAYOUT.SCREEN,
        itemData,
        toggleCartVisibility,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData = {},
      listId,
      layout,
      previousScreen,
      listName,
      listContent,
      toggleUnavailableProductsModal,
      listData: {
        options: listOptions = {},
        display_count = '',
        slug: listSlug = {},
      } = {},
      listData = {},
      navigation,
    } = this.props;

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      itemData.id,
      itemData.slug,
      listSlug,
      {},
      itemData,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      campaignId,
      onItemAddToCartFromCollab,
      listData: {
        additional_data = {},
        options: { cta_position_bottom = '0' } = {},
      } = {},
      orientation,
      itemData,
      hideAddToCart,
      itemData: {
        id,
        sku_id: skuId,
        has_variants: hasVariants,
        outOfStock = false,
        priority,
        single_stocked_variant: singleStockedVariantSku,
      } = {},
      listData = {},
    } = this.props;

    const style =
      cta_position_bottom === '0'
        ? styles.cartImageContainer
        : { width: '100%' };

    return (
      <View style={style}>
        <AddToCart
          skuId={skuId}
          orientation={orientation}
          toggleCartState={this.toggleCartState}
          layout={LAYOUT.SLIMRAIL}
          addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
          hideAddToCart={hideAddToCart}
          id={id}
          itemData={itemData}
          hasVariants={hasVariants}
          showCart={this.showCart}
          priority={priority}
          outOfStock={outOfStock}
          refreshOfferStrip={refreshOfferStrip}
          refreshOffersDetailsPageDiscountStrip={
            refreshOffersDetailsPageDiscountStrip
          }
          previousScreen={previousScreen}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          campaignId={campaignId}
          onItemAddToCartFromCollab={onItemAddToCartFromCollab}
          listId={listId}
          listName={listName}
          listIndex={listIndex}
          listContent={listContent}
          index={index}
          singleStockedVariantSku={singleStockedVariantSku}
          additional_data={additional_data}
          listData={listData}
          cta_position_bottom={cta_position_bottom}
        />
      </View>
    );
  };

  productShadesCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Shade';

  productSizeCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Size';

  conditionalVariants = compose(
    withEither(this.productShadesCondition, ProductColorVariants),
    withEither(this.productSizeCondition, ProductSizeVariants),
  )(ProductOtherVariants);

  productVariants = (props) => {
    const {
      itemData: { image_url: imageUrl, variants_details: variantDetails = {} },
    } = this.props;
    const { containerStyle } = props;

    const {
      variants_count: variantsCount = 0,
      variants_shade_images: variantShadeImages = [],
      variants_property_name: variantPropertyName = '',
    } = variantDetails;

    return (
      <this.conditionalVariants
        variantImage={imageUrl}
        variantsCount={variantsCount}
        variantShadesImages={variantShadeImages}
        variantsPropertyName={variantPropertyName}
        containerStyle={containerStyle}
      />
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      itemData: {
        offer_value,
        final_sp,
        additional_discount,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        brand: { name: brandName } = {},
        sku_id: skuId,
        has_variants: hasVariants,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
        transparent_image_url = '',
        transparent_image_vibrant_colors: {
          vibrant_color: backgroundColor = '#B57CD6',
        } = {},
      } = {},
      listData: {
        options: listOptions = {},
        display = '',
        display_count = '',
        subTitle = '',
      } = {},
      index,
    } = this.props;

    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const display_price = final_sp;

    const options = Utility.isPresent(listOptions) ? listOptions : {};

    const {
      show_borders_for_list_items = 0,
      show_timer_in_subtitle = false,
      cta_position_bottom = '0',
    } = options;

    const showBorder =
      Utility.isPresent(transparent_image_url) || show_borders_for_list_items
        ? 'WithBorder'
        : '';
    const isBottomCTAPresent =
      cta_position_bottom === '0' ? 'WithoutBottomCTA' : '';

    const containerStyle =
      styles[`railCardContainer${showBorder}${isBottomCTAPresent}`];

    return (
      <View>
        <ScaleAnimate
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
        >
          <Card style={containerStyle}>
            <View style={styles.imageContainer}>
              <ProductImageAndRating
                layout={LAYOUT.SLIMRAIL}
                rating={rating}
                images={images}
                webp_images={webp_images}
                image_webp_url={image_webp_url}
                imageUrl={imageUrl}
                id={id}
                name={name}
                previousScreen={previousScreen}
                itemData={itemData}
                transparentImageUrl={transparent_image_url}
              />
            </View>
            {cta_position_bottom !== '1' && <this.addToCart />}
            <View style={styles.fullCardDivider} />
            <View style={styles.productDescriptionContainer}>
              <ProductDescription
                itemData={itemData}
                name={name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={brandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.SLIMRAIL}
                id={id}
                previousScreen={previousScreen}
                numberOfLines={1}
              />
            </View>
            {cta_position_bottom === '1' && <this.addToCart />}
            {index === display_count - 1 && show_timer_in_subtitle === true && (
              <View style={styles.viewMoreIconContainer}>
                <FastImageView
                  source={Images.view_more_icon}
                  style={styles.viewMoreIcon}
                  resizeMode='contain'
                />
                <Text style={styles.viewMoreText}>View More</Text>
              </View>
            )}
          </Card>
        </ScaleAnimate>
      </View>
    );
  }
}

const dividingScale = isDesktop() ? 4.8 : 2.4;

const styles = StyleSheet.create({
  railCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: Utility.getScreenWidth() / dividingScale,
    height: Utility.getScreenWidth() / dividingScale + 144,
    alignSelf: 'center',
  },
  railCardContainerWithBorder: {
    backgroundColor: colors.white,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: Utility.getScreenWidth() / dividingScale,
    height: Utility.getScreenWidth() / dividingScale + 144,
    alignSelf: 'center',
    borderColor: colors.border,
    borderWidth: 1,
  },
  railCardContainerWithoutBottomCTA: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: Utility.getScreenWidth() / dividingScale,
    height: Utility.getScreenWidth() / dividingScale + 106,
    alignSelf: 'center',
  },
  railCardContainerWithBorderWithoutBottomCTA: {
    backgroundColor: colors.white,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: Utility.getScreenWidth() / dividingScale,
    height: Utility.getScreenWidth() / dividingScale + 106,
    alignSelf: 'center',
    borderColor: colors.border,
    borderWidth: 1,
  },
  imageContainer: {
    height: Utility.getScreenWidth() / dividingScale,
    resizeMode: 'contain',
    overflow: 'hidden',
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 85,
    right: 7,
    zIndex: 1,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: '#ffffff',
  },
  viewMoreIconContainer: {
    position: 'absolute',
    borderRadius: 8,
    width: Utility.getScreenWidth() / dividingScale,
    height: Utility.getScreenWidth() / dividingScale + 144,
    backgroundColor: colors.white,
    opacity: 0.9,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  viewMoreIcon: {
    height: 80,
    width: 80,
  },
  viewMoreText: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    marginTop: 4,
    color: colors.cta.lightBlue,
  },
  productDescriptionContainer: {
    flex: 1,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      applyFlashDeal,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(SlimProductRail),
);
