import React, { Component } from 'react';
import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Image,
} from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import StaticNavigationHeader from './StaticNavigationHeader';

class ServerError extends Component {
  constructor() {
    super();
    this.state = {
      isError: false,
      retrying: false,
    };
  }

  componentDidMount() {
    const { goToHome = false } = this.props;
    if (goToHome) {
      Utility.setPageLoadStart('feed', 0, '', false, {
        status: 'server_error',
      });
    }
  }

  onResetState = () => {
    this.setState({
      isError: false,
    });
  };

  onRetryPress = () => {
    const { onPress = () => {} } = this.props;
    Utility.pageLoadStart = Date.now();
    AnalyticsManager.logEvent(EventType.ERROR_EVENTS.RETRY, {
      [EventParameterKey.NAME]: EventParameterValue.ERROR_BUTTON_ACTION.RETRY,
      [EventParameterKey.PAGE]: EventParameterValue.ERROR_PAGE.SERVER_ERROR,
      [EventParameterKey.SCREEN_NAME]: EventParameterValue.SCREEN_NAME.FEED,
    });
    onPress();
  };

  onGoToHomePress = () => {
    const { navigation } = this.props;
    Utility.pageLoadStart = Date.now();
    AnalyticsManager.logEvent(EventType.ERROR_EVENTS.RETRY, {
      [EventParameterKey.NAME]:
        EventParameterValue.ERROR_BUTTON_ACTION.GO_TO_HOME,
      [EventParameterKey.PAGE]: EventParameterValue.ERROR_PAGE.SERVER_ERROR,
    });
    navigation.navigate('Feed');
  };

  _button = () => {
    const { darkMode = false, loading = false } = this.props;
    const { retrying } = this.state;
    const subheadingStyle = darkMode
      ? darkModeButtonText
      : normalModeButtonText;
    const indicatorColor = darkMode ? colors.white : colors.subtitle;
    const showRetryText = !retrying && !loading;
    return (
      <TouchableOpacity onPress={this.onRetryPress} disabled={loading}>
        <View style={style.button}>
          {showRetryText ? (
            <Text style={subheadingStyle}>Retry</Text>
          ) : (
            Utility.getActivityIndicatorSmall({}, indicatorColor, 25)
          )}
        </View>
      </TouchableOpacity>
    );
  };

  _buttonGoToHome = () => {
    const { darkMode = false, loading = false } = this.props;
    const { retrying } = this.state;
    const subheadingStyle = darkMode
      ? darkModeButtonText
      : normalModeButtonText;
    const indicatorColor = darkMode ? colors.white : colors.subtitle;
    const showGoToHomeText = !retrying && !loading;
    return (
      <TouchableOpacity onPress={this.onGoToHomePress}>
        <View style={style.button}>
          {showGoToHomeText ? (
            <Text style={subheadingStyle}>Go to Home</Text>
          ) : (
            Utility.getActivityIndicatorSmall({}, indicatorColor, 25)
          )}
        </View>
      </TouchableOpacity>
    );
  };

  renderContainer = () => {
    const { goToHome = false, darkMode = false } = this.props;
    const headingStyle = darkMode
      ? darkModeHeading
      : normalModeHeading;
    return (
      <View style={style.container}>
        <Image source={images.serverError} style={style.image} />
        <Text style={headingStyle}>Oops! Something went wrong</Text>
        <Text style={style.subHeading}>
          An unexpected error occurred. Please retry or check back later
        </Text>

        {!goToHome ? <this._button /> : <this._buttonGoToHome />}
      </View>
    );
  };

  _fallbackComponent = () => {
    const { hideHeader } = this.props;
    if (hideHeader) {
      return (
        <>
          <SafeAreaView style={style.safeArea}>
            <this.renderContainer />
          </SafeAreaView>
        </>
      );
    }
    return (
      <View style={style.safeArea}>
        <StaticNavigationHeader title='' />
        <this.renderContainer />
      </View>
    );
  };

  render() {
    return <this._fallbackComponent />;
  }
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 35,
  },
  safeArea: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  image: { width: 200, height: 200 },
  heading: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    marginBottom: 8,
    marginTop: 5,
  },

  subHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    textAlign: 'center',
    color: colors.subtitle,
  },

  button: {
    height: 46,
    marginTop: 30,
    width: 180,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: colors.darkBorder,
  },
});

const themeStyle = StyleSheet.create({
  darkModeHeading: { color: colors.white },

  normalModeHeading: { color: colors.foxyBlack },

  darkModeButtonText: {
    fontSize: size.h2,
    color: colors.white,
    fontWeight: '500',
  },

  normalModeButtonText: {
    fontSize: size.h2,
    color: colors.foxyBlack,
    fontWeight: '500',
  },
});

const darkModeHeading = StyleSheet.compose(style.heading, themeStyle.darkModeHeading);
const normalModeHeading = StyleSheet.compose(style.heading, themeStyle.normalModeHeading);
const darkModeButtonText = StyleSheet.compose(style.subHeading, themeStyle.darkModeButtonText);
const normalModeButtonText = StyleSheet.compose(style.subHeading, themeStyle.normalModeButtonText);

export default withNavigation(ServerError);
