import { Text, View, StyleSheet, BackHandler } from 'react-native';
import React, { Component } from 'react';
import FastImageView from '../../components/FastImageView';
import colors from '../../theme/Colors';
import { size, memoize } from 'lodash';
import images from '../../theme/Images';
import Utility, { openUrlInBrowser } from '../../utils/Utility';
import { StaticNavigationHeader } from '../../components/shared';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { getScreenWidth } from '../../utils/LayoutUtility';

export default class RoutineLivestream extends Component {
  constructor(props) {
    super(props);
    const { route } = props;
    this.artist = route.params?.artist;
    this.activity = route.params?.activity;
    this.dateText = route.params?.dateText;
    this.isLive = route.params?.isLive;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  render() {
    const dateContainer = this.isLive
      ? [styles.dateContainer, { backgroundColor: colors.bright_pink }]
      : styles.dateContainer;

    if (Utility.isBlank(this.activity)) {
      return null;
    }

    const { title = '', body = '', externalLink = '' } = this.activity;

    return (
      <View style={styles.container}>
        <StaticNavigationHeader title='' skipDivider />
        <View style={styles.contentContainer}>
          <View style={styles.middleSection}>
            <FastImageView
              source={images.comparePriceImage.foxy}
              style={styles.foxyLogo}
            />
            <Text style={styles.saperatorText}>X</Text>
            <Text style={styles.artistNameContainer}>{this.artist.name}</Text>
          </View>
          <FastImageView
            source={this.artist.imageUrl}
            style={styles.artistImage}
          />
          <View style={dateContainer}>
            <FastImageView
              source={images.routines.routine_benefits_icons.live_white}
              style={styles.liveIcon}
            />
            <Text style={styles.date}>{this.dateText}</Text>
          </View>

          <View style={styles.innerContainer}>
            <Text style={styles.title}>{`${JSON.stringify(title)}`}</Text>
            {Utility.isPresent(body) && (
              <Text style={styles.body}>{`${JSON.stringify(body)}`}</Text>
            )}
          </View>
        </View>
        <FoxyShadowButton
          width={Utility.getScreenWidth() - 34}
          title={'Join Now'}
          onPress={memoize(
            () => {
              openUrlInBrowser(externalLink);
            },
            () => [externalLink],
          )}
          backgroundColor={colors.black}
          style={styles.shadowButton}
          firstColor='#000'
          secondColor='#000'
          disabled={!this.isLive}
          underlayColor='#000'
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    width: getScreenWidth(),
    alignSelf: 'center',
  },

  contentContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    textAlign: 'center',
  },

  date: {
    color: colors.white,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
  },
  dateContainer: {
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderRadius: 16,
    marginBottom: 4,
    backgroundColor: colors.subtitle,
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 12,
  },
  shadowButton: {
    marginTop: 12,
    marginBottom: Utility.bottomInset + 12,
    backgroundColor: '#fff',
    width: Utility.getScreenWidth() - 34,
    alignSelf: 'center',
  },
  liveIcon: {
    width: 16,
    height: 12,
    resizeMode: 'contain',
    marginRight: 6,
  },

  body: {
    fontSize: 12,
    color: colors.subtitle,
    fontFamily: 'Roboto-Regular',
    marginTop: 12,
    textAlign: 'center',
  },
  innerContainer: { marginTop: 12, paddingHorizontal: 12 },
  middleSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  foxyLogo: { height: 20, width: 70, resizeMode: 'contain' },
  saperatorText: {
    fontSize: 18,
    color: '#FF5079',
    fontFamily: 'Roboto-Bold',
    marginHorizontal: 8,
  },
  artistNameContainer: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
  },
  artistImage: { height: 200, width: 200, borderRadius: 100 },
});
