import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import CountDownWithoutText from './CounterWithoutText';
import TimeUtility from '../utils/TimeUtility';
import colors from '../theme/Colors';
import size from '../theme/Fonts';

const styles = StyleSheet.create({
  container: {},
  parallelogram: {
    paddingLeft: 12,
    paddingRight: 12,
    height: 22,
  },
  parallelogramInnerLightRed: {
    position: 'absolute',
    left: 8,
    right: 8,
    top: 0,
    backgroundColor: '#FFECE5',
    height: '100%',
  },
  parallelogramInner: {
    position: 'absolute',
    left: 8,
    right: 8,
    top: 0,
    backgroundColor: '#E6EFFF',
    height: '100%',
  },
  parallelogramRight: {
    top: 0,
    right: 0,
    position: 'absolute',
  },
  parallelogramLeft: {
    top: 0,
    left: 0,
    position: 'absolute',
  },
  triangleCorner: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 8,
    borderTopWidth: 22,
    borderRightColor: 'transparent',
    borderTopColor: '#E6EFFF',
  },
  triangleCornerLightRed: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 8,
    borderTopWidth: 22,
    borderRightColor: 'transparent',
    borderTopColor: '#FFECE5',
  },
  triangleCornerBottomRight: {
    transform: [{ rotate: '180deg' }],
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h6,
    alignSelf: 'center',
    marginTop: 0,
  },
  expiryDetailsContainer: {
    flexDirection: 'row',
  },
  date: {
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
    marginTop: 4,
    color: colors.black,
  },
});

export default class Timer extends PureComponent {
  constructor(props) {
    super(props);
    const { ends_at = '' } = props;
    const expireDate = new Date(ends_at);
    const currentDate = new Date();
    const timeDiff = expireDate.getTime() - currentDate.getTime();
    const timeDiffInHours = timeDiff / (1000 * 60 * 60);
    let isRedBackground = true;
    if (timeDiffInHours >= 6) {
      isRedBackground = false;
    }
    this.state = {
      showRedBackground: isRedBackground,
    };
  }

  TriangleRight = () => {
    const { showRedBackground } = this.state;
    const triangleCorner = showRedBackground
      ? styles.triangleCornerLightRed
      : styles.triangleCorner;
    return <View style={[triangleCorner, styles.parallelogramRight]} />;
  };

  TriangleLeft = () => {
    const { showRedBackground } = this.state;
    const triangleCorner = showRedBackground
      ? styles.triangleCornerLightRed
      : styles.triangleCorner;
    return (
      <View
        style={[
          triangleCorner,
          styles.triangleCornerBottomRight,
          styles.parallelogramLeft,
        ]}
      />
    );
  };

  isShortTimeLimitReached = (reached) => {
    const { showRedBackground } = this.state;
    if (showRedBackground != reached) {
      this.setState({
        showRedBackground: reached,
      });
    }
  };

  onChange = (timeLeft) => {
    const hours = parseInt(timeLeft / (60 * 60), 10);
    if (hours < 6) {
      this.isShortTimeLimitReached(true);
    }
  };

  onTimerFinish = () => {
    const { onTimerFinish = () => {} } = this.props;
    onTimerFinish();
  };

  countdown = ({ timeDiff }) => {
    return (
      <View>
        <CountDownWithoutText
          size={10}
          until={timeDiff}
          //   onFinish={refresh}
          digitStyle={styles.digitStyle}
          onChange={this.onChange}
          timeToShow={['H', 'M', 'S']}
          onFinish={this.onTimerFinish}
          showSeparator
        />
      </View>
    );
  };

  textWithCountdown = (timeDiff) => {
    return (
      <View style={styles.expiryDetailsContainer}>
        <this.countdown timeDiff={timeDiff} />
      </View>
    );
  };

  renderExpiryDetails = () => {
    const { ends_at = '' } = this.props;
    const expireDate = new Date(ends_at);
    const currentDate = new Date();
    let text = 'Ends in';
    if (currentDate > expireDate) {
      text = 'Expired ';
    } else {
      const timeDiff = expireDate.getTime() - currentDate.getTime();
      const timeDiffInHours = timeDiff / (1000 * 60 * 60);
      if (timeDiffInHours >= 48) {
        text = `${TimeUtility.getEndDay(ends_at)}`;
      } else {
        return this.textWithCountdown(timeDiff / 1000);
      }
    }
    return (
      <View style={styles.expiryDetailsContainer}>
        <Text style={styles.date}>{text}</Text>
      </View>
    );
  };

  render() {
    const { showRedBackground } = this.state;
    const parallelogramInnerStyle = showRedBackground
      ? styles.parallelogramInnerLightRed
      : styles.parallelogramInner;
    const { ends_at = '' } = this.props;
    const expireDate = new Date(ends_at);
    const currentDate = new Date();
    if (currentDate > expireDate) {
      return null;
    }
    return (
      <View style={styles.parallelogram}>
        <this.TriangleLeft />
        <View style={parallelogramInnerStyle} />
        <this.renderExpiryDetails />
        <this.TriangleRight />
      </View>
    );
  }
}
