// Dependencies
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image, TouchableOpacity, View, Text } from 'react-native';
import CardView from '../../libraries/ReactNativeCardView';
import ScaleAnimate from '../shared/ScaleAnimate';

// Components
import { COLLECTION } from '../../config/LayoutConstants/CollectionConfig';
import CollectionCardStyle from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { fetchTagList, setSearchResultClicks } from '../../actions/ActionTypes';
import { MORE_ITEMS, MAX_LINES, LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import { withMaybe } from '../../lib/Monads';
import images from '../../theme/Images';
import Card from '../../lib/Card';
import {
  AnalyticsUtilty,
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../analytics';
import LinearGradient from 'react-native-linear-gradient';
import FastImageView from '../FastImageView';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isWeb } from '../../utils/BooleanUtility';
import { getCompactSlug } from '../../utils/StringUtility';

class CollectionCard extends Component {
  static cardLayoutStyle = {
    vertical: {
      cardElevation: 0,
      cardMaxElevation: 0,
      cornerRadius: 4,
    },
  };

  constructor(props) {
    super(props);
    const { itemData: collectionData = {} } = this.props;
    this.item = this.props.itemData.objects;
    const { first_item_url } = collectionData;

    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceCollectionCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );

    const { cardStyle, imageStyle } = this.getStyles(this.props);
    this.imageStyle = imageStyle;
    this.cardStyle = cardStyle;

    let imageSource = collectionData.image_url;
    if (collectionData.type === 'offer') {
      imageSource = collectionData.card_image;
    }

    if (Utility.isBlank(imageSource)) {
      imageSource = first_item_url;
      if (collectionData.type === 'ingredient') {
        imageSource = collectionData.icon_image_url;
      }
    }

    this.imageSource = Utility.getMinifiedImage(imageSource, 300);
  }

  collectionDescription = (props) => {
    const { itemData } = props;

    if (Utility.isBlank(itemData)) {
      return null;
    }

    const { objects } = itemData;

    const productsCount =
      objects.length === 1
        ? `${objects.length} Item`
        : `${objects.length} Items`;
    return (
      <View style={CollectionCardStyle.descriptionContainer}>
        <View>
          <Text numberOfLines={1} style={CollectionCardStyle.ListHeadingText}>
            {_.capitalize(itemData.name)}
          </Text>
          <Text style={CollectionCardStyle.ListSubHeadingText}>
            {productsCount}
          </Text>
        </View>
      </View>
    );
  };

  showDescriptionConditionFn = (props) => !(props.layout === 'list');

  collectionDescriptionWithCondition = withMaybe(
    this.showDescriptionConditionFn,
  )(this.collectionDescription);

  SeparatorStyle = (props) => {
    const { layout } = props;
    if (layout === 'list') {
      return <View style={CollectionCardStyle.listSeparatorStyle} />;
    }
    return null;
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      index = '',
      id,
      layout,
      listName,
      listIndex,
      previousScreen,
      search,
      searchQuery,
      elementItemCounts,
      listContent,
      type,
      setSearchResultClicks,
      categoryIds = [],
      itemData: {
        all_products_slug: allProductSlug = '',
        navigate_to_all_products: navigateToAllProducts = false,
      } = {},
      extraEventParameters = {},
      listData = {},
      parentListsData = [],
    } = this.props;

    //FIXME: this section should be a common function which will accept props, and calculated event meta itself
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      const data = {
        query: searchQuery,
        type,
        id,
      };
      setSearchResultClicks(data);
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;

      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };
      this.debounceCollectionCardClick(meta);
    }
    if (Utility.isPresent(itemData)) {
      const extraParams = {
        ...extraEventParameters,
        ...AnalyticsUtilty.getParentsList(parentListsData),
      };
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.id,
        itemData.type,
        itemData.name,
        index || itemData?.display_order,
        listData?.id,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        itemData.id,
        itemData?.slug,
        listData?.slug,
        extraParams,
      );
    }

    if (Utility.isBlank(itemData)) return;

    if (itemData.type === 'offer') {
      navigateToScreen({
        navigation,
        type: isWeb() ? 'push' : 'navigate',
        screen: SCREEN_CONSTANTS.OFFER_DETAIL,
        params: {
          slug: itemData.slug,
          shortSlug: getCompactSlug(itemData.slug),
        },
      });
      return;
    }
    if (itemData.type === 'brand' || itemData.type === 'product_category') {
      let screen =
        previousScreen === SCREEN_CONSTANTS.CATEGORY ||
        itemData.type === 'product_category'
          ? SCREEN_CONSTANTS.CATEGORY
          : 'Brand';
      if (isWeb() && itemData.slug.includes('brands') && itemData.slug.includes('categories')) {
        screen = 'Brand';
      }
      navigateToScreen({
        navigation,
        type: 'push',
        screen,
        params: {
          slug: itemData.slug,
        },
      });
      return;
    }
    navigateToScreen({
      navigation,
      type: 'push',
      screen: MORE_ITEMS,
      params: {
        id: itemData.id,
        displayCount: itemData?.objects?.length,
        heading: itemData.name,
        item: itemData,
        navigation,
        title: itemData.name,
        slug: itemData.slug,
        previousScreen,
      },
    });
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  navigateToMedia = () => {
    const { navigation, itemData = {}, fetchTagList } = this.props;
    const index = 0;
    const relatedContentSlug = itemData.slug?.replace(
      '.json',
      '/related-content.json',
    );

    fetchTagList(relatedContentSlug, 0, (success, data) => {
      if (success) {
        navigation.push('ContentModal', {
          listId: null,
          index: 0,
          slug: relatedContentSlug,
          listData: data,
          itemData: data.objects[0],
        });
      }
    });
  };

  getStyles = ({ layout, size = 'defaultSize' }) => {
    const { listData, previousScreen } = this.props;
    let layoutSize = size;
    if (Utility.isBlank(layoutSize)) {
      layoutSize = 'defaultSize';
    }

    let { viewwidth, viewheight, imagewidth, imageheight } =
      COLLECTION.CARD[layout][layoutSize];
    if (
      Utility.isPresent(listData) &&
      previousScreen !== 'more_page' &&
      layout === 'grid'
    ) {
      const noOfColumns = listData.columns || 2;
      [viewwidth, viewheight, imagewidth, imageheight] =
        Utility.getCardDimentions(noOfColumns, 'collectionCard');
    }

    const cardDimensions = {
      height: viewheight,
      width: viewwidth,
    };
    let imageStyle = {
      height: imageheight,
      width: imagewidth,
      backgroundColor: colors.white,
    };
    let cardStyle = [CollectionCardStyle.cardContainer, cardDimensions];
    if (layout === 'list') {
      cardStyle = [CollectionCardStyle.cardContainerList, cardDimensions];
      imageStyle = [
        imageStyle,
        {
          marginRight: 10,
          marginLeft: 10,
          elevation: 0,
        },
      ];
    }

    return {
      cardStyle,
      imageStyle,
    };
  };

  cardImage = () => {
    const { itemData: collectionData = {} } = this.props;
    // const { imageStyle } = this.getStyles(this.props);

    if (collectionData === undefined) {
      return null;
    }

    // let imageSource = collectionData.image_url;
    // if (collectionData.type === 'offer') {
    //   imageSource = collectionData.card_image;
    // }

    // const cardImage = Utility.getMinifiedImage(
    //   imageSource,
    //   Utility.getScreenWidth(),
    //   Utility.getScreenWidth(),
    // );

    return <FastImageView source={this.imageSource} style={this.imageStyle} />;
  };

  alternativeCartImage = () => {
    const { itemData: collectionData = {}, layout } = this.props;
    const { first_item_url } = collectionData;
    // const { imageStyle } = this.getStyles(this.props);
    // const { imageStyle } = this.getStyles(this.props);

    if (Utility.isBlank(collectionData)) {
      return null;
    }

    // let alternativeCardImage =
    //   Utility.isPresent(first_item_url) &&
    //   Utility.getMinifiedImage(
    //     first_item_url,
    //     this.imageStyle.width,
    //     this.imageStyle.height,
    //   );

    // if (collectionData.type === 'ingredient') {
    //   alternativeCardImage = Utility.getMinifiedImage(
    //     collectionData.icon_image_url,
    //     this.imageStyle.width,
    //     this.imageStyle.height,
    //   );
    // }

    // if (Utility.isBlank(alternativeCardImage)) {
    //   return null;
    // }
    return (
      <View>
        <FastImageView source={this.imageSource} style={this.imageStyle} />
        <View style={CollectionCardStyle.collectionCardEmptyImageContainer}>
          {/* <LineraGradient
            colors={['transparent', '#E4E4E4', '#E4E4E4']}
            style={CollectionCardStyle.collectionCardEmptyImageGradient}
          /> */}

          {layout !== LAYOUT.LIST && (
            <Text
              style={CollectionCardStyle.collectionCardEmptyImageCardText}
              numberOfLines={2}
            >
              {collectionData.name}
            </Text>
          )}

          {layout !== LAYOUT.LIST && (
            <LinearGradient
              colors={[
                '#ffffff00',
                '#ffffff00',
                '#ffffff00',
                '#E4E4E400',
                '#E4E4E4AA',
                '#E4E4E4',
              ]}
              style={[
                CollectionCardStyle.collectionCardEmptyImageGradient,
                { height: this.imageStyle.height / 2 },
              ]}
            />
          )}
        </View>
      </View>
    );
  };

  render() {
    const { orientation, itemData: collectionData = {}, layout } = this.props;
    // const { cardStyle, imageStyle } = this.getStyles(this.props);
    let LayoutComponent = CardView;

    const cardImage = Utility.getMinifiedImage(
      collectionData.image_url,
      this.imageStyle.width,
      this.imageStyle.height,
    );

    let backgroundColor = colors.white;
    if (Utility.isBlank(cardImage) && layout !== 'list') {
      backgroundColor = 'black';
    }
    if (layout === 'list') {
      LayoutComponent = Card;
    }

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <LayoutComponent
          style={this.cardStyle}
        >
          {Utility.isBlank(cardImage) ? (
            <this.alternativeCartImage />
          ) : (
            <this.cardImage />
          )}
          <this.collectionDescriptionWithCondition
            itemData={collectionData}
            layout={layout}
            style={[this.imageStyle, { backgroundColor }]}
          />
        </LayoutComponent>
        <this.SeparatorStyle layout={layout} />
      </ScaleAnimate>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchTagList, setSearchResultClicks }, dispatch),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(CollectionCard),
);
