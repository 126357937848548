// Dependencies
import React, { Component } from 'react';
import { View, TouchableOpacity, Text, Image } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ProductInfoStyles } from './styles';
import colors from '../../theme/Colors';
import {
  getCurrentPincode,
  getDeliveryTime,
  saveCurrentPincode,
  saveLastUsedAddress,
  getProductServiceability,
} from '../../actions/ActionTypes';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import GradientDivider from '../../utils/GradientDivider';
import images from '../../theme/Images';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import DeliveryInformation from '../cart/DeliveryInfo';
import TimeUtility from '../../utils/TimeUtility';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import AppConfig from '../../config/AppConfig';
import Config from '../../libraries/ReactNativeConfig';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';
import { isPresent } from '../../utils/BooleanUtility';

class ProductDelivery extends Component {
  productDeliveryConstants = {
    deliveryCardHeader: 'Delivery Info',
    returnPolicyButtonText: 'See Policy',
    returnText: {
      true: 'Return Pickup Available',
      false: 'Return Pickup Not Available',
    },
    codAvailabilityText: {
      true: 'COD Available',
      false: 'COD Not Available',
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      deliveryTimeText: '',
      isChangePincodeModalVisible: false,
      deliveryTime: '',
      codAvailable: true,
      returnPickupAvailable: true,
      delivery: true,
    };
    this.emptyAddressDeliveryData = JSON.parse(
      RemoteConfig.getValue(
        REMOTE_CONFIG_KEYS.product_page_empty_address_delivery_data,
      ),
    );
  }

  componentDidMount() {
    const { lastUsedAddress, addresses, skuId } = this.props;
    let currentPincode = '';
    if (isPresent(lastUsedAddress)) {
      currentPincode = lastUsedAddress.pincode;
    } else if (addresses && Object.keys(addresses).length > 0) {
      currentPincode = Object.entries(addresses)[0][1]?.pincode;
    }
    if (Utility.isBlank(currentPincode)) {
      this.hitGetCurrentPinAPI();
    } else {
      this.hitAPIForDeliveryTime(currentPincode);
    }
  }

  getServiceabilityData = (pincode) => {
    const { getProductServiceability } = this.props;
    getProductServiceability(pincode, (success, data) => {
      if (success) {
        this.setState({
          codAvailable: data.cod_available,
          returnPickupAvailable: data.return_available,
        });
      }
    });
  };

  hitGetCurrentPinAPI = () => {
    const { getCurrentPincode } = this.props;
    getCurrentPincode((success, response) => {
      this.hitAPIForDeliveryTime(response.pincode);
    });
  };

  saveCurrentPincodeToRedux = (pincode) => {
    const { saveCurrentPincode } = this.props;
    const data = { pincode };
    saveCurrentPincode(data);
  };

  hitAPIForDeliveryTime = (pincode) => {
    const { getDeliveryTime, skuId } = this.props;
    getDeliveryTime(pincode, skuId, (success, response) => {
      if (success) {
        this.setState({
          deliveryTime: response.duration,
          delivery: response.delivery,
        });
      }
    });
    this.getServiceabilityData(pincode);
  };

  smallSeparator = () => <View style={ProductInfoStyles.smallSeparator} />;

  togglePincodeModal = () => {
    this.setState((prevState) => ({
      isChangePincodeModalVisible: !prevState.isChangePincodeModalVisible,
    }));
  };

  navigateToAddress = () => {
    const { navigation } = this.props;
    navigation.navigate('Address', {
      previousScreen: SCREEN_CONSTANTS.PRODUCT_DETAIL,
      selectAddress: this.selectAddress,
    });
  };

  selectAddress = (address) => {
    // this funtion is called when used changes address from product page,
    // therefore we need to get delivery time based on new pincode
    const { saveLastUsedAddress } = this.props;
    saveLastUsedAddress(address);
    this.hitAPIForDeliveryTime(address.pincode);
  };

  deliveryLocation = () => {
    const { currentCity, lastUsedAddress, addresses } = this.props;

    if (Utility.isBlank(addresses)) {
      return null;
    }
    let changeText = 'Change';
    if (Utility.isBlank(addresses)) {
      changeText = 'Add Address';
    }
    let textToShowForDeliveryLocation = currentCity;

    let addressArray = [];
    let location = '';
    let contact_name = '';
    if (!Utility.isBlank(addresses)) {
      addressArray = Object.keys(addresses).map((key) => ({
        id: key,
        name: addresses[key].name,
        pincode: addresses[key].pincode,
        line1: addresses[key].line1,
        line2: addresses[key].line2,
        default: addresses[key].default,
        contact_name: addresses[key].contact_name,
      }));
      let defaultAddress = addressArray.filter((item) => item.default);
      if (Utility.isBlank(defaultAddress)) {
        defaultAddress = addressArray.splice(0, 1);
      }
      if (Utility.isBlank(defaultAddress[0]?.line2)) {
        textToShowForDeliveryLocation = `${defaultAddress[0].line1}, ${defaultAddress[0].pincode}`;
      } else {
        textToShowForDeliveryLocation = `${defaultAddress[0].line1}, ${defaultAddress[0].line2}, ${defaultAddress[0].pincode}`;
      }
      location = `${defaultAddress[0].name}`;
      contact_name = `${defaultAddress[0].contact_name}`;
    }

    const locationIcon = Utility.getAddressIcon(location);

    return (
      <View style={ProductInfoStyles.deliveryLocationContainer}>
        <Image
          source={locationIcon}
          style={ProductInfoStyles.deliveryInfoIcon}
        />
        <View style={ProductInfoStyles.deliveryTextContainer}>
          <Text numberOfLines={3} style={ProductInfoStyles.contactNameStyle}>
            {`${contact_name} : `}
            <Text ellipsizeMode='tail' style={ProductInfoStyles.statsTextStyle}>
              {textToShowForDeliveryLocation}
            </Text>
          </Text>
        </View>
      </View>
    );
  };

  returnPolicyButton = () => {
    const styles = ProductInfoStyles;
    const { toggleReturnModal } = this.props;
    return (
      <TouchableOpacity onPress={toggleReturnModal}>
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: 12,
            paddingLeft: 5,
            color: '#4990e2',
          }}
        >
          {this.productDeliveryConstants.returnPolicyButtonText}
        </Text>
      </TouchableOpacity>
    );
  };

  BlankAddressState = ({ orderCampaign: { expected_instock_date = '' } }) => {
    const preOrderString = `Item is expected to ship on or before `;
    const preOrderDate = expected_instock_date ?? '';
    const { heading = '', message = '' } = this.emptyAddressDeliveryData;
    return (
      <View style={ProductInfoStyles.deliveryStatsContainer}>
        <View style={ProductInfoStyles.addressEmptyStateContainerHeading}>
          <Image
            source={images.foxyPromise.fastDelivery}
            style={ProductInfoStyles.deliveryInfoIcon}
          />
          {Utility.isPresent(preOrderDate) ? (
            <View style={{ flexDirection: 'row', marginLeft: 4 }}>
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: 14,
                  color: colors.foxyBlack,
                }}
              >
                {preOrderString}
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto-Bold',
                  fontSize: 14,
                  color: colors.foxyBlack,
                  marginTop: Utility.isIOS() ? 2 : 0,
                }}
              >
                {preOrderDate}
              </Text>
            </View>
          ) : (
            <Text
              numberOfLines={1}
              style={ProductInfoStyles.addressEmptyStateText}
            >
              {heading}
            </Text>
          )}
        </View>

        <Text style={ProductInfoStyles.addressEmptyStateInfo}>{message}</Text>
      </View>
    );
  };

  deliveryStats = () => {
    let deliveryText = '';
    const { delivery, deliveryTime } = this.state;
    const { addresses, orderCampaign = {} } = this.props;
    const deliveryDuration = deliveryTime;
    if (!Utility.isBlank(deliveryDuration) && delivery) {
      deliveryText = `Expected Delivery in ${deliveryDuration}`;
    } else if (!delivery) {
      deliveryText = deliveryDuration;
    }
    const styles = ProductInfoStyles;
    const { codAvailable, returnPickupAvailable } = this.state;
    const returnPickupTextColor = returnPickupAvailable
      ? colors.foxyBlack
      : colors.subtitle;
    const codFontColor = codAvailable ? colors.foxyBlack : colors.subtitle;

    if (Utility.isBlank(addresses)) {
      return <this.BlankAddressState orderCampaign={orderCampaign} />;
    }
    return (
      <View style={styles.deliveryStatsContainer}>
        <DeliveryInformation
          address={addresses}
          fromAddress
          orderCampaign={orderCampaign}
        />
      </View>
    );
  };

  render() {
    const { address, addresses, productPage = {} } = this.props;
    const styles = ProductInfoStyles;
    const addressText = Utility.isBlank(addresses)
      ? 'Add address'
      : 'Change address';
    if (AppConfig.getBooleanValue(Config.HIDE_PRODUCT_DELIVERY_IN_PRODUCT_PAGE)) {
      return null;
    }
    return (
      <>
        <View style={styles.deliveryAndReturnsContainer}>
          <Text style={styles.productInfoCardHeaderText}>
            {productPage?.address?.heading || ''}
          </Text>
          <View style={styles.deliverAndReturnsCard} dataSet={{ media: ids.deliverAndReturnsCard }}>
            <this.deliveryLocation />
            <this.deliveryStats />
            <TouchableOpacity
              style={productDeliveryStyles.button}
              onPress={this.navigateToAddress}
              hitSlop={Utility.getHitSlop()}
              dataSet={{ media: ids.button }}
            >
              <Text style={productDeliveryStyles.address}>
                {addressText}
              </Text>
              <Image
                style={productDeliveryStyles.rightIcon}
                source={images.chevronRight}
              />
            </TouchableOpacity>
          </View>
        </View>
      </>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  currentPincode: store.UserAccountInfo.currentPincode,
  currentCity: store.UserAccountInfo.currentCity,
  lastUsedAddress: store.UserAccountInfo.lastUsedAddress,
  addresses: store.UserAccountInfo.addresses,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getCurrentPincode,
      getDeliveryTime,
      saveCurrentPincode,
      saveLastUsedAddress,
      getProductServiceability,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(ProductDelivery));

const { ids, styles: productDeliveryStyles } = StyleSheet.create({
  deliverAndReturnsCard: {
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH,
    },
  },
  rightIcon: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    tintColor: colors.cta.lightBlue,
    marginLeft: 8,
  },
  address: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: colors.cta.lightBlue,
  },
  button: {
    alignSelf: 'flex-end',
    paddingRight: 16,
    paddingBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
    '@media (min-width: 768px)': {
      marginTop: 10,
    },
  },
});
