import React from 'react';
import { View, StyleSheet } from 'react-native';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../../libraries/ReactNativeShimmerPlaceholder';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

const SlimGridShimmer = () => {
  const shimmerColors = [
    colors.shimmer.darkGrey,
    colors.shimmer.lightGrey,
    colors.shimmer.darkGrey,
  ];
  const imageShimmerColors = [
    colors.shimmer.lightGrey,
    colors.shimmer.extraLightGrey,
    colors.shimmer.lightGrey,
  ];

  const DescriptionPlaceholder = () => {
    return (
      <View style={sharedStyles.description}>
        {_.map([1, 2], (e) => (
          <View style={sharedStyles.descriptionContainer} key={e}>
            <ShimmerPlaceHolder
              colorShimmer={imageShimmerColors}
              autoRun
              style={sharedStyles.imageShimmer}
            />
            <View style={sharedStyles.bottomDescriptionContainer}>
              <ShimmerPlaceHolder
                colorShimmer={shimmerColors}
                autoRun
                style={sharedStyles.brand}
              />
              <ShimmerPlaceHolder
                colorShimmer={shimmerColors}
                autoRun
                style={sharedStyles.name}
              />
              <ShimmerPlaceHolder
                colorShimmer={shimmerColors}
                autoRun
                style={sharedStyles.price}
              />
            </View>

            <View style={sharedStyles.addToCartContainer}>
              <ShimmerPlaceHolder
                colorShimmer={shimmerColors}
                autoRun
                style={sharedStyles.addToCart}
              />
            </View>
          </View>
        ))}
      </View>
    );
  };

  return (
    <View style={sharedStyles.shimmerContainer}>
      <DescriptionPlaceholder />
    </View>
  );
};

const sharedStyles = StyleSheet.create({
  imageShimmer: {
    height: (Utility.getScreenWidth() * 0.67) / 2,
    width: (Utility.getScreenWidth() * 0.67) / 2 - 24,
  },
  descriptionContainer: {
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: (Utility.getScreenWidth() * 0.67) / 2,
    height: (Utility.getScreenWidth() * 0.67) / 2 + 140,
    alignSelf: 'center',
    padding: 12,
    backgroundColor: colors.white,
    alignContent: 'center',
  },
  brand: {
    height: 12,
    width: 80,
    borderRadius: 4,
    marginTop: 8,
    alignSelf: 'flex-start',
  },
  name: {
    height: 12,
    width: 108,
    borderRadius: 4,
    marginTop: 12,
    alignSelf: 'center',
  },
  price: {
    height: 12,
    width: 108,
    borderRadius: 4,
    marginTop: 4,
    alignSelf: 'center',
  },
  addToCartContainer: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addToCart: {
    height: 24,
    width: 108,
    alignSelf: 'center',
  },
  description: { flexDirection: 'row' },
  shimmerContainer: {
  },
  bottomDescriptionContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
});

export default SlimGridShimmer;
