import React, { Component } from 'react';
import {
  Text,
  TouchableHighlight,
  Image,
  ImageBackground,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import images from '../../../theme/Images';
import { InterestStyles as styles } from './styles';
import Utility from '../../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';

class Interest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interestStatus: false,
    };
    Object.assign(this, this.props);
  }

  componentDidMount() {
    const { selectedInterests, interest } = this.props;

    if (selectedInterests.includes(interest.id)) {
      this.setState({
        interestStatus: true,
      });
    }
  }

  onPress = () => {
    const { interestStatus } = this.state;
    const { previousScreen, toggleInterest } = this.props;

    if (
      !Utility.isBlank(previousScreen) &&
      previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE
    ) {
      toggleInterest(interestStatus, this.interest.id);
      this.setState((prevState) => ({
        interestStatus: !prevState.interestStatus,
      }));
    } else {
      toggleInterest(interestStatus, this.interest.id);
      this.setState((prevState) => ({
        interestStatus: !prevState.interestStatus,
      }));
    }
  };

  render() {
    const GradientArray = [
      'transparent',
      'transparent',
      'transparent',
      'transparent',
    ];
    const { interestStatus } = this.state;

    return (
      <ImageBackground
        source={{
          uri: Utility.getMinifiedImage(
            this.interest.image_url,
            this.imageDimension,
          ),
        }}
        style={[
          styles.interestImage,
          { height: this.imageDimension, width: this.imageDimension },
        ]}
        imageStyle={{ borderRadius: 4 }}
      >
        <TouchableHighlight
          onPress={this.onPress}
          style={{ flex: 1 }}
          underlayColor='transparent'
        >
          <LinearGradient
            colors={GradientArray}
            style={styles.GradientStyles}
            start={{ x: 0, y: 0 }}
            end={{ x: 0, y: 1 }}
          >
            <View style={styles.iconFrame} onPress={this.onPress}>
              <Image
                source={
                  interestStatus
                    ? images.greenCircularTick
                    : images.interest.unselected
                }
              />
            </View>
            {/* <Text style={{ color: 'white' }}>{this.interest.name}</Text> */}
          </LinearGradient>
        </TouchableHighlight>
      </ImageBackground>
    );
  }
}

export default Interest;
