import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import size from '../../../theme/Fonts';
import { getScreenWidth } from '../../../utils/LayoutUtility';

const styles = {
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  subContainer: {
    paddingHorizontal: 20,
  },
  heading: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    paddingTop: 12,
  },
  reasonListContainer: {
    width: Utility.getScreenWidth() - 40,
    height: 48,
    backgroundColor: colors.lightestGrey,
    borderRadius: 24,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 8,
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: colors.white,
  },
  selectedReasonContainerStyle: {
    width: Utility.getScreenWidth() - 40,
    height: 48,
    backgroundColor: colors.lightestBlue,
    borderRadius: 24,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 8,
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: colors.prompt.lightBlue,
  },
  reasonTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  listContainer: {
    marginTop: 59,
  },
  foxyShadowButton: {
    marginHorizontal: 16,
  },
  buttonContainer: {
    position: 'absolute',
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingTop: 12,
    bottom: Utility.isIOS() ? 10 : 0,
    paddingBottom: 12,
  },
  errorMessage: {
    marginTop: 4,
    fontSize: size.h4,
    color: colors.foxyRed,
  },
};
export default styles;
