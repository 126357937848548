import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { View, BackHandler, Text, Image } from 'react-native';
import _ from 'lodash';
import withNavigation from '../../../../utils/WithNavigation';
import Utility from '../../../../utils/Utility';
import { getCartItems, setContactLess } from '../../../../actions/ActionTypes';
import { setCurrentPaymentFlow } from '../../../../actions/PaymentActions';
import CartItems from '../../components/cart/CartItems';
import RemoteConfig from '../../../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';

import { PaymentStyles } from '../../components/cart/styles';
import { TouchableOpacity } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native';
// import OrderSummaryShimmer from './OrderSummaryShimmer';
import ShimmerPlaceHolder from '../../../../libraries/ReactNativeShimmerPlaceholder';

import { playlistStyle } from '../../../../components/media/Style';
import images from '../../../../theme/Images';
import colors from '../../../../theme/Colors';

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      isModalVisible: false,
      refreshing: false,
    };

    this.modalMessage = null;
    this.source = this.props.route?.params?.source || '';
    this.allowWithoutAcceptingContactless = null;
    this.debouncedNavigate = _.debounce(this.navigateToCart, 1000, {
      leading: true,
      trailing: false,
    });
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    this.modalMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.switch_contact_less_modal_message,
    );

    this.allowWithoutAcceptingContactless = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.allow_without_contactless,
    );
    const { setCurrentPaymentFlow } = this.props;
    setCurrentPaymentFlow(true);
    this.getCart();
    Utility.furtherAction = {
      action: null,
      params: [],
      phone_number: '',
      isActionAsync: false,
    };
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  modalVisibility = (isVisible) => {
    this.setState({
      isModalVisible: isVisible,
    });
  };

  getCart = () => {
    const { getCartItems } = this.props;

    getCartItems((success) => {
      this.setState({
        refreshing: false,
      });
      if (!success) {
        this.showToast('Something went wrong', 500);
      } else {
        const {
          cartItems = [],
          cartPricing = {},
          address = [],
          authToken,
          cartPrompts,
        } = this.props;
        const meta = {
          cartItems,
          cartPricing,
          address,
          authToken,
        };
        try {
          // AnalyticsUtilty.fireCartViewEvent(meta);
          // AnalyticsUtilty.firePageLoadEvent(SCREEN_CONSTANTS.CART);
          // AnalyticsUtilty.fireCartPromptVisible(cartPrompts);
        } catch (e) {}
      }
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  showToast = (message, duration = 1000) => {
    this.toast.show(message, duration);
  };

  renderLoader = (isLoaderVisible) => {
    this.setState({
      isLoaderVisible,
    });
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  navigateToCart = () => {
    const { navigation } = this.props;
    navigation.replace('Cart', {
      source: 'order_summary',
    });
  };

  getComponent = (cartPrompt) => {
    return (
      <View
        style={{
          backgroundColor: '#DFF5EA',
          paddingHorizontal: 16,
          paddingVertical: 8,
          width: Utility.getScreenWidth(),
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontFamily: 'Roboto-Medium',
            color: '#00B55D',
            alignSelf: 'flex-start',
          }}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {cartPrompt?.message || ''}
        </Text>
      </View>
    );
  };

  closeModal = () => {
    const { setContactLess } = this.props;
    setContactLess({ isContactLess: true });
    this.setState({
      isModalVisible: false,
    });
  };

  onRefresh = () => {
    this.setState({
      refreshing: true,
    });
    this.getCart();
  };

  product = (props) => {
    const styles = playlistStyle;
    const { product, index, totalProducts } = props;
    if (Utility.isBlank(product)) {
      return null;
    }
    let imageUrl = '';
    // try {
    //   imageUrl = product.images.length > 0 ? product.images[0] : '';
    // } catch {}

    imageUrl = Utility.isPresent(product.offer)
      ? product?.offer?.card_image
      : product?.product?.image_url;

    return (
      <Image
        source={{
          uri: Utility.getSmallImageUrl(imageUrl, 120, 120),
        }}
        style={{
          height: 36,
          marginRight: 8,
          width: 36,
          borderWidth: 1,
          borderRadius: 18,
          borderColor: colors.borderDark,
          resizeMode: 'cover',
          backgroundColor: colors.white,
          overflow: 'hidden',
          flexDirection: 'row',
        }}
      />
    );
  };

  cartProducts = ({ cartItems }) => {
    const { navigation, addedToCartProduct } = this.props;

    if (Utility.isPresent(addedToCartProduct) && Utility.isBlank(cartItems)) {
      return (
        <CartItems
          cartItems={[
            { sku_id: addedToCartProduct.sku_id, product: addedToCartProduct },
          ]}
          navigation={navigation}
          showToast={this.showToast}
          hideAddToCart
        />
      );
    }

    if (cartItems.length > 1) {
      return (
        <View
          style={{
            flexDirection: 'row',
            marginBottom: 16,
            paddingHorizontal: 12,
            alignItems: 'center',
          }}
        >
          {cartItems.map((product, index) => {
            if (index === 6) {
              return (
                <View
                  style={{
                    height: 36,
                    marginRight: 8,
                    width: 36,
                    borderWidth: 1,
                    borderRadius: 18,
                    borderColor: colors.borderDark,
                    resizeMode: 'cover',
                    overflow: 'hidden',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.white,
                  }}
                >
                  <Text style={[playlistStyle.productsUsedText, { color: colors.foxyBlack }]}>
                    {`+${cartItems?.length - 6}`}
                  </Text>
                </View>
              );
            }
            if (index < 6) {
              return (
                <this.product
                  key={`more_options_${product.id}`}
                  product={product}
                  index={index}
                  totalProducts={cartItems.length}
                  id={product.id}
                  products={cartItems}
                />
              );
            }
            return null;
          })}
        </View>
      );
    }
    return (
      <CartItems
        cartItems={cartItems.slice(0, 1)}
        navigation={navigation}
        showToast={this.showToast}
        hideAddToCart
      />
    );
  };

  render() {
    const {
      cartItems,
      cartPricing = {},
      navigation,
      isOfferShimmerVisible,
      addedToCartProduct,
    } = this.props;

    return (
      <View
        style={{
          backgroundColor: colors.background,
          paddingVertical: 12,
          marginTop: 4,
          borderRadius: 8,
        }}
        onPress={() => {
          navigation.push('Cart', {
            source: 'order_summary',
          });
        }}
      >
        <Text
          style={{
            fontSize: 14,
            color: '#979BAA',
            fontFamily: 'Roboto-Medium',
            marginBottom: 12,
            paddingHorizontal: 12,
          }}
          ellipsizeMode='tail'
          numberOfLines={1}
        >
          {'Cart Summary' || ''}
        </Text>
        <TouchableOpacity
          style={{
            backgroundColor: colors.background,
          }}
          onPress={this.debouncedNavigate}
        >
          <View
            style={{
              backgroundColor: colors.white,
              paddingVertical: 12,
              paddingTop: 12,
              borderRadius: 8,
            }}
          >
            <this.cartProducts cartItems={cartItems} />
            <View style={PaymentStyles.orderSummaryDivider} />

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingHorizontal: 12,
              }}
            >
              {Utility.isBlank(addedToCartProduct) ? (
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: 'Roboto-Bold',
                    color: colors.foxyBlack,
                  }}
                >{`${cartItems.length} items · ${CURRENCY_SYMBOL}${cartPricing.total}`}</Text>
              ) : (
                <View style={{ flexDirection: 'row' }}>
                  <ShimmerPlaceHolder
                    autoRun
                    style={[styles.price, { width: 54, marginRight: 12 }]}
                    colorShimmer={['#fafafa', '#cccccc', '#fafafa']}
                  />
                  <ShimmerPlaceHolder
                    autoRun
                    style={[styles.price, { width: 54 }]}
                    colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
                  />
                </View>
              )}

              <Image
                source={images.chevronRight}
                style={PaymentStyles.rightArrow}
              />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

OrderSummary.propTypes = {
  getCartItems: PropTypes.func,
  cartItems: PropTypes.array,
  cartPricing: PropTypes.object,
  address: PropTypes.array,
  authToken: PropTypes.string,
  cartPrompts: PropTypes.object,
  navigation: PropTypes.any,
  setContactLess: PropTypes.func,
  optForPlasticFreePackaging: PropTypes.bool,
  isContactLess: PropTypes.bool,
};

OrderSummary.defaultProps = {
  getCartItems: () => {},
  cartItems: [],
  cartPricing: {},
  address: [],
  authToken: '',
  cartPrompts: {},
  navigation: {},
  setContactLess: () => {},
  optForPlasticFreePackaging: true,
  isContactLess: false,
};

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
  cartPricing: state.bag.cartPricing,
  cartPrompts: state.bag.cartPrompts,
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  isContactLess: state.bag.isContactLess,
  isOfferShimmerVisible: state.bag.isOfferShimmerVisible,
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
  addedToCartProduct: state.bag.addedToCartProduct,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getCartItems,
      setContactLess,
      setCurrentPaymentFlow,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(OrderSummary),
);
