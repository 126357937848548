import React, { Component } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import Offer from './Offer';
import Utility from '../../utils/Utility';
import { LAYOUT, ORIENTATION } from '../../config/Constants';
import BottomSearch from '../../components/bottomSearchComponent';
import ErrorBoundaryComponent from '../../components/shared/ErrorBoundaryComponent';

export default class OfferBannerWithRail extends Component {
  static LayoutStyle = {
    offer: Offer,
    // bottomSearchContainer: BottomSearch,
  };

  getComponent = (props) => {
    const { item, index } = props;
    const {
      navigation,
      size,
      id,
      listIndex,
      elementItemCounts,
      previousScreen,
      showToast,
      toggleCartVisibility,
      hideActivityIndicator,
      listData = {},
    } = this.props;
    if (Utility.isBlank(item)) {
      return null;
    }

    const CardContainer = OfferBannerWithRail.LayoutStyle[item.type];
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <CardContainer
          id={item.id}
          listName={item.name}
          itemData={item}
          data={item}
          layout={LAYOUT.BANNER_WITH_RAIL}
          navigation={navigation}
          size={size}
          type={item.type}
          orientation={ORIENTATION.VERTICAL}
          listId={id}
          index={index}
          listIndex={listIndex}
          elementItemCounts={elementItemCounts}
          previousScreen={previousScreen}
          invokedFromOfferBannerWithRail
          showToast={showToast}
          toggleCartVisibility={toggleCartVisibility}
          hideActivityIndicator={hideActivityIndicator}
        />
      </ErrorBoundaryComponent>
    );
  };

  renderItem = ({ item, index }) => (
    <this.getComponent item={item} index={index} />
  );
  keyExtractor = (item, index) => `${index}_${item.id}_offerBannerWithRail`;

  render() {
    const { item } = this.props;
    this.itemData = item;
    // Flat list here and it will render the component based upon object type.
    return (
      <View
        style={{
          marginVertical: 8,
        }}
      >
        <FlatList
          data={this.itemData}
          style={styles.flatlistStyle}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderItem}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  flatlistStyle: {
    overflow: 'visible',
  },
});
