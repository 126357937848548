export const GET_INFLUENCER_DETAILS = 'GET_INFLUENCER_DETAILS';
export const CHECK_HANDLE_AVAILABILITY = 'CHECK_HANDLE_AVAILABILITY';
export const UPDATE_INFLUENCER = 'UPDATE_INFLUENCER';
export const ADD_PROLINK = 'ADD_PROLINK';
export const FETCH_OG_DETAILS = 'FETCH_OG_DETAILS';
export const CREATE_INTRO_VIDEO = 'CREATE_INTRO_VIDEO';
export const INSTA_PRO_VERIFICATION_STATUS = 'INSTA_PRO_VERIFICATION_STATUS';
export const GET_INFLUENCER_TAGS = 'GET_INFLUENCER_TAGS';
export const GET_PRO_ACCOUNT_LINKS = 'GET_PRO_ACCOUNT_LINKS';
export const VERIFY_ONELINK = 'VERIFY_ONELINK';
export const SET_ARTIST_HANDLE = 'SET_ARTIST_HANDLE';

export function getInfluencerDetails(handle, callback) {
  return {
    type: GET_INFLUENCER_DETAILS,
    handle,
    callback,
  };
}

export function checkHandleAvailability(handle, callback) {
  return {
    type: CHECK_HANDLE_AVAILABILITY,
    handle,
    callback,
  };
}

export function updateInfluencerData(data, callback) {
  return {
    type: UPDATE_INFLUENCER,
    data,
    callback,
  };
}

export function addProLink(link_type, url, callback) {
  return {
    type: ADD_PROLINK,
    link_type,
    url,
    callback,
  };
}

export function fetchOGDetails(id, callback) {
  return {
    type: FETCH_OG_DETAILS,
    id,
    callback,
  };
}

export function createIntroVideo(video, callback) {
  return {
    type: CREATE_INTRO_VIDEO,
    video,
    callback,
  };
}

export function getInstaProVerificationStatus(callback) {
  return {
    type: INSTA_PRO_VERIFICATION_STATUS,
    callback,
  };
}

export function getInfluencerTags(callback) {
  return {
    type: GET_INFLUENCER_TAGS,
    callback,
  };
}

export function getProAccountLinks(callback) {
  return {
    type: GET_PRO_ACCOUNT_LINKS,
    callback,
  };
}

export function verifyOneLink(callback) {
  return {
    type: VERIFY_ONELINK,
    callback,
  };
}

export function setArtistHandle(handle) {
  return {
    type: SET_ARTIST_HANDLE,
    handle,
  };
}
