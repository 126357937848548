import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

const DrawerMenuStyles = StyleSheet.create({
  drawerMenuContainer: {
    position: 'absolute',
    bottom: -20,
    left: -20,
    width: Utility.getScreenWidth() - 40,
    height: Utility.getScreenHeight(),
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
  },
  hamburgerMenuContainer: {
    height: 20,
    width: 20,
    marginLeft: 16,
  },
  animatedHamburger: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  drawerModal: {
    // flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default DrawerMenuStyles;
