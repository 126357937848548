import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import size from '../../theme/Fonts';

const styles = StyleSheet.create({
  congratsModal: {
    position: 'absolute',
    height: Utility.getWindowHeight(),
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollview: {
    backgroundColor: colors.white,
  },
  profileCardContainer: {
    height: 96,
    width: '100%',
    paddingLeft: 16,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  profileImage: {
    height: 76,
    width: 76,
    borderTopLeftRadius: 60,
    borderTopRightRadius: 60,
    borderBottomLeftRadius: 60,
    borderBottomRightRadius: 60,
    overflow: 'visible',
    alignItems: 'center',
    justifyContent: 'center',
    //marginLeft: 16,
    backgroundColor: colors.background,
  },
  profileCardNameContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 12,
    marginRight: 12,
  },
  badgeContainer: {
    height: 30,
    width: 112,
    backgroundColor: colors.badgeBackground,
    marginTop: 12,
    borderRadius: 8,
    flexDirection: 'row',
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: 'center',
  },
  userName: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h2,
    fontStyle: 'normal',
    letterSpacing: 0,
    maxWidth: Utility.getScreenWidth() - 186,
  },
  userHandle: {
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    fontSize: size.h3,

    fontStyle: 'normal',
    letterSpacing: 0,
  },
  badgeIcon: {
    height: 15,
    width: 14,
    resizeMode: 'contain',
  },
  badgeRightArrow: {
    position: 'absolute',
    right: 8,
    tintColor: colors.foxyBlack,
  },
  badgeText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h4,
    marginLeft: 6,
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  dashboardCard: {
    margin: 6,
    backgroundColor: colors.white,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: Utility.getDynamicWidth(2, 12),
    height: 76,
  },
  statsCard: {
    margin: 6,
    backgroundColor: colors.white,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: Utility.getDynamicWidth(2, 12),
    height: 164,
  },
  dashboardCardImage: { height: '100%', width: '100%' },
  artistBanner: {
    height: 72,
    width: Utility.getScreenWidth() - 24,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFCFD1',
    margin: 12,
    marginTop: 6,
    borderRadius: 4,
    marginBottom: 6,
  },
  artistBannerTextContainer: {
    width: '70%',
  },

  artistBannerImage: {
    position: 'absolute',
    right: 20,
    bottom: 0,
    width: 70,
    height: 60,
    resizeMode: 'contain',
  },
  artistInitialBannerImage: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 90,
    height: 60,
    resizeMode: 'contain',
    marginBottom: 4,
  },
  artistBannerTitle: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h3,
    marginLeft: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  artistBannerSubTitle: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,

    marginLeft: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginTop: 2,
  },
  dashboardCardTitle: {
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontSize: size.h3,
    marginLeft: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginTop: 8,
  },
  dashboardCardSubtitle: {
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    fontSize: size.h4,

    marginLeft: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginTop: 4,
    marginRight: 16,
    maxWidth: Utility.getDynamicWidth(2, 6),
  },
  statsMiniContainer: {
    height: 80,
    width: '100%',
    flexDirection: 'row',

    resizeMode: 'contain',
  },
  dashboardDivider: {
    height: 1,
    marginLeft: 2,
    marginRight: 2,
    borderRadius: 0.5,
    backgroundColor: '#fbfdfe',
    width: Utility.getDynamicWidth(2, 8) - 4,
  },
  dashboardStatsTextContainer: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  dashboardCardChangeStats: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: 12,
  },
  analyticsLogoContainer: {
    position: 'absolute',
    top: 8,
    left: 12,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  analyticsLogo: {
    height: 12,
    width: 16,
    resizeMode: 'contain',
  },
  socialStatsTitle: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h4,
    marginLeft: 8,
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  followersCount: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h2,
    marginLeft: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginBottom: 4,
  },
  followersText: {
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    fontSize: size.h4,

    marginLeft: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginBottom: 8,
  },
  percentageChangeText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 4,
    color: colors.green,
  },
  headingContainer: {
    flexDirection: 'row',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: 8,
  },
  subHeading: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    flex: 1,
    alignContent: 'flex-start',
  },
  morebuttonstyle: {
    fontSize: 14,
    color: '#fd5c63',
    alignContent: 'flex-end',
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
  },
  addImageButton: {
    position: 'absolute',
    height: 16,
    width: 16,
    bottom: 2,
    left: 40,
  },
  completeProfileText: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyPink,
    fontSize: size.h4,

    fontStyle: 'normal',
    letterSpacing: 0,
  },
  completeProfileButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightArrow: {
    height: 10,
    width: 10,
    marginTop: 2,
    marginLeft: 4,
    tintColor: colors.foxyPink,
    resizeMode: 'cover',
  },
  addAccountImage: {
    height: 10,
    width: 10,
    marginLeft: 4,
    marginBottom: 2,
    resizeMode: 'cover',
  },
  editProfileButton: {
    height: 30,
    borderRadius: 4,
    position: 'absolute',
    right: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.border,
    top: 30,
    width: 78,
  },
  editButtonText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 6,
    marginRight: 6,
  },
  profileEditHandleContainer: {
    height: 90,
    width: '100%',
    paddingLeft: 20,

    paddingRight: 20,
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  profileImageContainer: {
    height: 130,
    width: 130,
  },
  profileImageEditBox: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    justifyContent: 'center',

    alignItems: 'center',
    alignSelf: 'center',
    width: 89,
    height: 18,
    backgroundColor: 'black',
    opacity: 0.7,
  },
  profileImageEditText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: 'white',
    height: 14,
    width: 25,
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
    marginBottom: 4,
  },
  userProfileImage: {
    height: 130,
    width: 130,
    borderRadius: 70,
    backgroundColor: colors.background,
    overflow: 'hidden',
  },
  handleTextFieldContainer: {
    marginLeft: 15,
    height: 70,
    flexDirection: 'row',

    alignItems: 'flex-end',
    justifyContent: 'space-around',
    width: Utility.getScreenWidth() - 150,
  },
  nameTextFieldContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  emailTextFieldContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  bioTextFieldContainer: {
    // flexDirection: 'row',
    // alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  actionBottonContainer: {
    position: 'absolute',
    height: 70,
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: 'rgba(244, 246, 248,0.9)',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  icon: {
    width: 16,
    marginTop: 2,
    height: 16,
    marginRight: 4,
  },
  container: {
    flex: 1,
  },
  profileOptionItem: {
    height: 52,
  },
  profileOptionContainer: {
    flexDirection: 'row',
    backgroundColor: 'yellow',
    height: '100%',
    marginRight: 20,
    marginLeft: 20,
  },
  getBookingandCertificationContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 50,
  },
  bookingandCertificateHeaderText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 24,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginTop: 20,
    color: colors.foxyBlack,
  },
  bookingandCertificateContentText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    marginTop: 20,
    marginLeft: 24,
    marginRight: 24,
    textAlign: 'center',

    color: colors.subtitle,
  },
  addressButtonContainer: {
    height: 48,
    width: Utility.getScreenWidth() - 40,
    marginTop: 16,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  manageAddressText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 16,
  },
  addressRightArrow: {
    position: 'absolute',
    right: 16,
    alignSelf: 'center',
    tintColor: colors.silver,
  },
  inReviewHeader: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 16,
    marginTop: 16,
    marginBottom: 12,
  },
  railView: {
    marginLeft: Utility.padding - Utility.spacingBetweenItems,
  },
  spacingForNotch: {
    width: '100%',
    height: Utility.largePadding,
  },
  addImageButtonInProfileEdit: {
    position: 'absolute',
    height: 20,
    width: 20,
    bottom: 12,
    right: 8,
  },

  uploadContentContainer: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    padding: 20,

    minHeight: 350,

    width: Utility.getScreenWidth(),
    borderTopRightRadius: 16,
  },
  uploadRrow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  uploadHeaderText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    color: colors.foxyBlack,
    alignSelf: 'center',
  },
  uploadCenterAlignedColumn: {
    justifyContent: 'center',
    marginTop: 4,
    alignItems: 'center',
  },
  uploadSubheadingFont: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    marginTop: 20,
    textAlign: 'justify',
    paddingLeft: 15,
    paddingRight: 15,
    color: colors.foxyBlack,
  },
  logout: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h2,
    marginTop: 28,
    textAlign: 'center',
    color: colors.foxyBlack,
    alignSelf: 'center',
  },
  textInputText: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },
  textInputTextDisabled: {
    color: colors.subtitle,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    width: 160,
  },
  myAddressHeader: {
    color: colors.silver,
    fontSize: 12,
    // fontFamily: 'Roboto-Regular',

    marginLeft: 20,
    marginTop: 20,
  },
  uploadButton: {
    height: 46,
    width: Utility.getScreenWidth() - 40,
    backgroundColor: colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    borderRadius: 4,
  },
  uploadText: {
    color: colors.white,
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
  },
  rightAlignItemsRow: {
    flexDirection: 'row',
    bottom: 5,
    // flex: 1,
    justifyContent: 'flex-end',

    alignItems: 'center',
  },
  handleContainer: {
    borderRadius: 4,
    padding: 6,
    width: 104,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.silver,
    backgroundColor: 'white',
  },
  handleChangeRowText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    color: colors.foxyBlack,
  },
  circularTick: {
    position: 'absolute',
    right: 8,
    alignSelf: 'center',
  },
  ctaButton: {
    width: Utility.getScreenWidth() - 40,
    height: 40,
    marginTop: 8,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.subtitle,
    flexDirection: 'row',
  },
  ctaActionButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },
  emojiButton: {
    height: 30,
    width: 40,
    marginLeft: 20,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 6,
  },
  artistTypeImageInBio: {
    height: 16,
    width: 16, // resizeMode: 'contain'
  },
  emojiTitle: {
    color: colors.subtitle,
    fontSize: 12,
    marginLeft: 20,
    marginTop: 10,
  },

  foxyPassContainer: {
    height: 24,
    width: 24,
    borderRadius: 30,
    backgroundColor: colors.white,
    position: 'absolute',
    right: 10,
    bottom: 0,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
