import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet, Text } from 'react-native';
import { memoize } from 'lodash';

import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { TabNavigationLeftHeader } from '../header';
import RoundedButton from '../layout/buttons/RoundedButton';
import { isWeb } from '../../utils/BooleanUtility';

export default class SelfieCard extends Component {
  _showProgressIndicator = (props) => (
    <TabNavigationLeftHeader size='large' image_uri={props.image_uri.uri} />
  );

  render() {
    const {
      onCardPress,
      image_url,
      title,
      body,
      isLocalImage,
      showProgressIndicator,
      webpImageUrl,
      ctaText,
      background_color,
    } = this.props;

    if (isWeb()) return null;
    let image_uri = isLocalImage
      ? image_url
      : { uri: Utility.getMinifiedImage(image_url, 200, 200) };

    if (Utility.isAndroid() && Utility.isPresent(webpImageUrl)) {
      image_uri = { uri: webpImageUrl };
    }

    return (
      <TouchableOpacity
        onPress={onCardPress}
        style={memoize(
          () => {
            return [
              styles.mainContainer,
              { backgroundColor: background_color },
            ];
          },
          () => [background_color],
        )()}
      >
        <View style={styles.newFeatureDescriptionContainer}>
          <Text
            style={styles.featureHeading}
            ellipsizeMode='tail'
            numberOfLines={1}
          >
            {title}
          </Text>

          <Text numberOfLines={1} style={styles.featureSubHeading}>
            {body}
          </Text>
          <RoundedButton
            buttonText={ctaText}
            buttonTextColor={colors.white}
            buttonColor={colors.black}
            buttonAction={onCardPress}
            style={styles.buttonContainer}
            buttonTextStyle={styles.buttonText}
            showChevron
          />
        </View>
        <this._showProgressIndicator
          showProgressIndicator={showProgressIndicator}
          image_uri={image_uri}
        />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  selfieImageStyle: {
    height: 52,
    width: 52,
    marginLeft: 12,
  },

  selfieCardColumn: {
    flexDirection: 'column',
  },
  centerAlignedRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chevronContainer: {
    borderRadius: 30,
    backgroundColor: colors.foxyBlack,
    justifyContent: 'center',
    padding: 5,
    height: 15,
    width: 15,
    right: 10,
    alignItems: 'center',
  },

  chevronStyle: {
    height: 20,
    width: 20,
    tintColor: colors.white,
    marginTop: 4,
    marginRight: 4,
  },
  newFeatureDescriptionContainer: {
    flexDirection: 'column',
    // justifyContent: 'flex-start',
    // marginRight: 0,
    // flex: 1,
    width: '80%',
  },
  featureHeading: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    letterSpacing: 0,
    color: colors.foxyBlack,
    // marginTop: 4,
    marginRight: 4,
    maxWidth: '85%',
  },
  featureSubHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
    marginTop: 2,
    marginRight: 0,
    // width: '80%',
  },
  featureSubHeadingBold: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
    marginTop: 2,
    marginRight: 0,
    width: '80%',
  },
  featureCancelContainer: {
    position: 'absolute',
    top: 12,
    right: 12,
    width: 15,
    height: 15,
  },
  featureCross: { tintColor: '#9D9D9D', height: 15, width: 15 },
  progressIndicatorContainer: {
    position: 'absolute',
    top: 20,
    bottom: 0,
    right: 15,
  },
  mainContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 102,
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 8,
    paddingHorizontal: 12,
  },
  buttonContainer: {
    width: 88,
    // height: 28,
    alignSelf: 'flex-start',
    marginTop: 8,
  },
  buttonText: { fontSize: 12, fontFamily: 'Roboto-Medium' },
});
