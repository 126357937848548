import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from '../../../containers/List';
import { getListDetails } from '../../../actions/ActionTypes';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import FoxyEdgeDiscoveryCard from '../../../containers/FoxyEdge/FoxyEdgeDiscoveryCard';
import Utility from '../../../utils/Utility';
import withNavigation from '../../../utils/WithNavigation';

class ListActionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
    };

    const { getListDetails, firstActionButtonText: listId } = this.props;
    getListDetails(listId, (success, response) => {
      this.setState({
        itemData: response,
      });
    });
  }

  render() {
    const { itemData } = this.state;
    return (
      <List
        itemData={itemData}
        id={itemData.id}
        previousScreen={SCREEN_CONSTANTS.NON_PERSONALISED_FEED}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getListDetails,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(ListActionCard),
);
