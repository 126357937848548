// Dependencies
import { StyleSheet, Dimensions, Platform, StatusBar } from 'react-native';

// Themes, ...
import colors, { Theme } from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import ScalingUtility from '../../../utils/ScalingUtility';

const modalHeight = ScalingUtility.moderateScale(396);
const { width, height } = Dimensions.get('window');

const ProfileDetailsStyles = StyleSheet.create({
  modal: {
    width,
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: Theme.light.backgroundColor,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },

  titleContainer: {
    backgroundColor: 'white',
    width,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 24.0,
    zIndex: 1,
  },

  /* titleContainer: {
    height: modalHeight / 7.47,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 24,
    zIndex: 1,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  }, */

  formContainer: {
    height: modalHeight / 1.14,
    backgroundColor: 'white',
    justifyContent: 'center',
  },

  ScrollViewContainerInset: {
    paddingHorizontal: 20.0,
    paddingVertical: 6.0,
    justifyContent: 'center',
  },

  titleContainerText: {
    fontSize: size.h1,
    color: Theme.light.textColor,

    fontFamily: 'Roboto-Regular',
  },

  profilePicPlusHandle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  profilePicWithHandle: {
    height: 90, // Modified
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  profilePic: {
    height: 90.0,
    width: 90.0,
    backgroundColor: colors.silver,
    borderWidth: 0,
    borderRadius: 45,
    marginTop: 10,
  },

  instagramHandle: {
    width: width / 1.71,
    height: 27.0,
    borderRadius: 4,
    backgroundColor: '#4990e2',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 13.0,
    flexDirection: 'row',
  },

  instagramHandleText: {
    color: 'white',
  },

  formView: {
    marginTop: 13,
  },

  icon: {
    width: 16,
    marginTop: 2,
    height: 16,
    marginRight: 4,
  },

  locationIcon: {
    width: 20,
    height: 20,
    marginRight: 6,
  },

  locationSuffixText: {
    fontSize: 16,
    color: '#4990e2',
  },
});

export default ProfileDetailsStyles;
