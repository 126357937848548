import { StyleSheet } from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import { isDesktop } from '../../../utils/BooleanUtility';

const numOfItems = isDesktop() ? 4 : 2;

const IncrementDecrementStyles = StyleSheet.create({
  incrementDecrementSwitchSmall: {
    flexDirection: 'row',
    alignSelf: 'center',
    position: 'absolute',
    width: '100%',
    borderRadius: 4,
    borderTopWidth: 1,
    borderColor: colors.border,
    justifyContent: 'space-around',
    alignItems: 'center',
    bottom: 0,

    backgroundColor: colors.white,
    height: 28,
  },
  productQuantAndVariantText: {
    fontFamily: 'Roboto-Medium',

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
    fontSize: 10,
  },
  productQuantAndVariantTextWhite: {
    fontFamily: 'Roboto-Medium',

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
    fontSize: 10,
  },
  incrementDecrementSwitch: {
    flexDirection: 'row',
    alignSelf: 'center',
    // marginTop: 8,
    // marginHorizontal: 6,
    position: 'absolute',
    width: Utility.getDynamicWidthForGrid(numOfItems, 16) - 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.border,
    justifyContent: 'space-around',
    alignItems: 'center',
    bottom: 0,
    marginBottom: 8,
    backgroundColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
    height: 38,
  },

  incrementDecrementSwitchModal: {
    height: 48,
    flexDirection: 'row',
    borderRadius: 4,
    marginVertical: 8,
    width: Utility.getDynamicWidthForGrid(numOfItems, 16) - 16,
    backgroundColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
    // marginBottom: 12,
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: colors.border,
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  plus: {
    resizeMode: 'stretch',
    height: 14,
    width: 14,

    tintColor: colors.black,
  },
  delete: {
    height: 14,
    width: 14,

    tintColor: colors.black,
  },
  plusDisabled: {
    height: 14,
    width: 14,
    tintColor: colors.addToCart.disable,
  },
  plusWhite: {
    resizeMode: 'stretch',
    height: 14,
    width: 14,

    tintColor: colors.white,
  },
  deleteWhite: {
    height: 14,
    width: 14,

    tintColor: colors.white,
  },
  plusWhileCartChanging: {
    height: 14,
    width: 14,
    tintColor: colors.grey,
  },
  activityIndicator: {
    width: 40,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  quantityContainer: {
    width: 40,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  decrementButtonContainer: {
    // position: 'absolute',
    width: 26,
    top: 0,
    left: 0,
    height: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 1000000000,
  },
  incrementButtonContainer: {
    // position: 'absolute',
    width: 26,
    top: 0,
    right: 0,
    height: 30,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  minus: {
    height: 1.2,
    width: 10,
    backgroundColor: colors.black,
    zIndex: 1,
  },
  minusWhite: {
    height: 1.2,
    width: 10,
    backgroundColor: colors.white,
    zIndex: 1,
  },
  minusDisabled: {
    height: 2,
    width: 10,
    backgroundColor: colors.addToCart.disable,
    zIndex: 1,
  },
  minusWhileCartChanging: {
    height: 2,
    width: 10,
    backgroundColor: colors.grey,
    zIndex: 1,
  },
  minusWhiteWhileCartChanging: {
    height: 2,
    width: 10,
    backgroundColor: colors.white_background,
    zIndex: 1,
  },
});

export default IncrementDecrementStyles;
