import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ActivityIndicator,
  BackHandler,
  StyleSheet,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { ScrollView } from 'react-native-gesture-handler';
import LinearGradient from 'react-native-linear-gradient';
import { connect } from 'react-redux';
import Toast from 'react-native-easy-toast';
import { bindActionCreators } from 'redux';
import StaticNavigationHeader from '../shared/StaticNavigationHeader';
import { CartStyles } from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import List from '../../containers/List/List';
import {
  getOfferRewards,
  addFreeProductToCart,
  getCartItems,
} from '../../actions/ActionTypes';

import { AnalyticsManager } from '../../analytics';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { CustomNavigationHeader } from '../Product';

class ChooseFreeGiftScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem:
        !Utility.isBlank(this.props.cartOffers) &&
        !Utility.isBlank(this.props.cartOffers.sku)
          ? this.props.cartOffers.sku.sku_id
          : null,
      freeProductList: {},
    };
    this.id = this.props.route.params?.id;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    const { getOfferRewards, cartOffers, navigation, route, props } =
      this.props;
    const offerID = route.params?.params?.offer_id;
    if (Utility.isPresent(offerID)) {
      this.id = offerID;
    }
    if (!Utility.isPresent(cartOffers.errors)) {
      navigation.replace('FreeProductScreen');
      return;
    }
    getOfferRewards(this.id, (success, res) => {
      this.setState({
        freeProductList: res.reward,
      });
    });
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  updateSelectedItem = (id) => {
    this.setState(
      {
        selectedItem: id,
        isLoaderVisible: false,
      },
      this.onItemSelect,
    );
  };

  header = () => (
    <View style={CartStyles.header}>
      <TouchableOpacity
        onPress={this.goBack}
        style={CartStyles.crossButtonContainer}
        hitSlop={Utility.getHitSlop()}
      >
        <Image style={CartStyles.crossImage} source={images.cross} />
      </TouchableOpacity>
    </View>
  );

  onPressConfirm = () => {
    const { addFreeProductToCart, getCartItems, navigation } = this.props;

    const { selectedItem, freeProductList } = this.state;
    this.setState({
      isLoaderVisible: true,
    });

    if (!Utility.isPresent(freeProductList?.objects)) {
      navigation.goBack();
      return;
    }

    addFreeProductToCart(selectedItem, this.id, (success) => {
      this.setState({
        isLoaderVisible: false,
      });
      if (success) {
        getCartItems((response) => {
          if (!response) {
            this.showToast('Something went wrong', 1000);
          }
        });
      }
      navigation.goBack();
    });

    AnalyticsManager.logEvent('change_free_product', {
      screen: 'cart',
      sku: selectedItem,
    });
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  onItemSelect = () => {
    const { addFreeProductToCart, getCartItems } = this.props;

    const { selectedItem } = this.state;

    addFreeProductToCart(selectedItem, this.id, (success) => {
      if (success) {
        getCartItems((response) => {
          if (!response) {
            this.showToast('Something went wrong', 1000);
          }
        });
      }
    });

    AnalyticsManager.logEvent('change_free_product', {
      screen: 'cart',
      sku: selectedItem,
    });
  };

  onClickContactUsCard = () => {
    Utility.openWhatsApp('Hey, i need help');
  };

  showToast = (message, duration = 1000) => {
    this.toast.show(message, duration);
  };

  showUnlockToast = () => {
    this.showToast(
      `Shop more on ${Config.APP_NAME} to unlock more gifts`,
      1000,
    );
  };

  render() {
    const { viewFreeProductScreen } = this.props;
    const { freeProductList } = this.state;
    const otherProductsList = { ...freeProductList };
    console.tron.log('FREE PRODUCT LIST', otherProductsList);
    otherProductsList.objects = otherProductsList.disabled_objects;
    const { isLoaderVisible } = this.state;
    const offerName = freeProductList?.name || 'Select free gift';
    const buttonName = Utility.isPresent(freeProductList?.objects)
      ? 'Confirm'
      : 'Continue Shopping';
    if (Utility.isBlank(freeProductList)) {
      return (
        <>
          <StaticNavigationHeader title="" showCart showSearchButton />
          <View
            style={{
              height: Utility.getScreenHeight(),
              width: Utility.getScreenWidth(),
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
          >
            <View
              style={{
                height: Utility.getWindowHeight(),
                width: Utility.getScreenWidth(),
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator size="large" color="green" />
              <Text style={{ color: colors.foxyBlack }}>
                Fetching personalized gifts for you
              </Text>
            </View>
            <Text
              style={{
                fontSize: 14,
                fontFamily: 'Roboto-Regular',
                color: colors.foxyBlack,
                marginTop: 8,
              }}
            >
              Fetching personalized gifts for you.
            </Text>
          </View>
        </>
      );
    }
    return (
      <View style={{ flex: 1, backgroundColor: colors.background }}>
        <StaticNavigationHeader
          title={`${offerName}`}
          showCart
          showSearchButton
        />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            flexDirection: 'column',
            backgroundColor: colors.background,
            width: Utility.getScreenWidth(),
            alignSelf: 'center',
          }}
          ref={(ref) => {
            this.scrollListReftop = ref;
          }}
        >
          {Utility.isPresent(freeProductList?.objects) && (
            <>
              <Text
                style={{
                  fontSize: 18,
                  fontFamily: 'Roboto-Bold',
                  color: colors.foxyBlack,
                  marginTop: 22,
                  marginBottom: 4,
                  marginLeft: 12,
                }}
                allowFontScaling={false}
              >
                {'Pick your free gift 🎁'}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto-Regular',
                  color: '#979BAA',
                  marginBottom: 12,
                  marginLeft: 12,
                }}
                allowFontScaling={false}
                numberOfLines={2}
                ellipsizeMode="tail"
              >
                Shop more on {Config.APP_NAME} to unlock more gifts
              </Text>
            </>
          )}
          {Utility.isPresent(freeProductList) && (
            <List
              itemData={freeProductList}
              id={freeProductList.id}
              previousScreen="Free"
              updateSelectedItem={this.updateSelectedItem}
              selectedItem={this.state.selectedItem}
              viewFreeProductScreen={viewFreeProductScreen}
              freeProductScreen
            />
          )}
          {Utility.isPresent(freeProductList?.disabled_objects) && (
            <TouchableOpacity onPress={this.showUnlockToast} activeOpacity={1}>
              <>
                <LinearGradient
                  colors={['transparent', 'transparent']}
                  style={{
                    height: '100%',
                    width: '100%',
                    zIndex: 1,
                    position: 'absolute',
                  }}
                />
                <Text
                  style={{
                    fontSize: 18,
                    fontFamily: 'Roboto-Bold',
                    color: colors.foxyBlack,
                    marginTop: 32,
                    marginBottom: 4,
                    marginLeft: 12,
                  }}
                  allowFontScaling={false}
                >
                  {'Unlock more gifts 🔓'}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: 'Roboto-Regular',
                    color: '#979BAA',
                    marginBottom: 12,
                    marginLeft: 12,
                  }}
                  allowFontScaling={false}
                  numberOfLines={2}
                  ellipsizeMode="tail"
                >
                  Shop more on {Config.APP_NAME} to unlock more gifts
                </Text>
                <View>
                  <List
                    itemData={otherProductsList}
                    id={freeProductList.id}
                    previousScreen="Free"
                    updateSelectedItem={this.updateSelectedItem}
                    selectedItem={this.state.selectedItem}
                    viewFreeProductScreen={viewFreeProductScreen}
                    freeProductScreen="disabled"
                  />
                </View>
              </>
            </TouchableOpacity>
          )}
        </ScrollView>

        <FoxyShadowButton
          width={Utility.getScreenWidth() - 34}
          title={buttonName}
          onPress={this.onPressConfirm}
          backgroundColor={colors.primaryActionBackgroundColor}
          style={styles.payButton}
          Loading={isLoaderVisible}
          firstColor={colors.linearGradientGreenFirst}
          secondColor={colors.linearGradientGreenSecond}
        />
        <Toast style={CartStyles.toast} ref={this.toastRef} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  payButton: {
    marginBottom: Utility.bottomInset + 16,
    marginTop: 8,
    alignSelf: 'center',
  },
});

const mapStateToProps = (state) => {
  return {
    cartOffers: state.cart.cartOffers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getOfferRewards,
      addFreeProductToCart,
      getCartItems,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(ChooseFreeGiftScreen),
);
