import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  GET_LOCATION,
  ADD_LAT_LONG,
  addLocality,
} from '../actions/LoginActions';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';

import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

const fetchLocation = (latitude, longitude, headers) => {
  let url = URL.GET_LOCATION;
  if (latitude !== undefined && latitude !== null) {
    url = `${url}lat=${latitude}`;
  }
  if (longitude !== undefined && longitude !== null) {
    url = `${url}&long=${longitude}`;
  }
  const response = fetch(url, {
    method: 'GET',
    headers: headers,
  });
  return response;
};

function* getLocation(action) {
  try {
    const latitude = yield select((state) => state.UserAccountInfo.latitude);
    const longitude = yield select((state) => state.UserAccountInfo.longitude);
    console.tron.log(`Latitide : ${latitude} Longitude: ${longitude}`);
    const headers = yield getApiHeaders();
    const response = yield call(fetchLocation, latitude, longitude, headers);
    console.tron.log(`Response: ${JSON.stringify(response.status)}`);

    const json = yield convertJsonFromResponse(response);
    if (response.status === 200) {
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(addLocality(json.location));
      action.callback(json.location);
      console.tron.log(`Response: ${JSON.stringify(json.location)}`);
    } else if (Utility.isPresent(json)) {
      console.tron.log(`Error Message: ${JSON.stringify(json)}`);
    }
  } catch (error) {
    console.tron.log(`Error in getting response ${error}`);
  }
}

export default function* watchLocationSaga() {
  yield takeEvery(GET_LOCATION, getLocation);
  yield takeEvery(ADD_LAT_LONG, getLocation);
}
