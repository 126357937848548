import React, { PureComponent } from 'react';
import { View, FlatList, Text, StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import SuggestedRoutine from '../../orders/actionCards/SuggestedRoutine';
import RoutineRailWithDescriptionCard from '../RoutineRailWithDescriptionCard';
const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class RoutineDetailsRail extends PureComponent {
  renderRoutine = ({ item }) => {
    const { navigation, previousScreen, orderAasmState = '' } = this.props;

    return (
      <View style={styles.routineContainer}>
        <RoutineRailWithDescriptionCard
          itemData={{ routine: item }}
          navigation={navigation}
          previousScreen={previousScreen}
          orderAasmState={orderAasmState}
        />
      </View>
    );
  };

  render() {
    const { suggestedRoutines = [] } = this.props;
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Based on your purchase</Text>
        <FlatList
          data={suggestedRoutines}
          renderItem={this.renderRoutine}
          horizontal
          showsHorizontalScrollIndicator={false}
        />
      </View>
    );
  }
}

export default RoutineDetailsRail;

const styles = StyleSheet.create({
  container: {
    padding: 12,
    backgroundColor: '#fff',
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h2,
    fontFamily: 'Roboto-Bold',
    marginBottom: 12,
  },
  routineContainer: {
    marginRight: 12,
    flex: 1,
  },
});
