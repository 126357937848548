import React, { PureComponent } from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  Animated,
  StyleSheet,
} from 'react-native';
import PropTypes from 'prop-types';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import colors from '../../theme/Colors';

class CrossButton extends PureComponent {
  onCancelTap = () => {
    const { previousScreen, index, publisher_id } = this.props;
    if (previousScreen === 'story_details') {
      AnalyticsManager.logEvent('story_close', {
        index,
        publisher_id,
      });
    }
    this.props.onCancelTap();
  };

  render() {
    const { crossButtonWidth, previousScreen } = this.props;
    const containerStyle =
      previousScreen === 'story_details'
        ? styles.storyDetailContainer
        : styles.container;
    const touchableOpacityStyle = [
      containerStyle,
      {
        width: crossButtonWidth,
        height: crossButtonWidth,
      },
    ];
    return (
      <TouchableOpacity
        onPress={this.onCancelTap}
        style={touchableOpacityStyle}
      >
        <Image
          style={styles.image}
          source={images.mediaDetailPage.closeButton}
          hitSlop={
            Utility.isPresent(previousScreen) &&
            previousScreen === 'story_details'
              ? Utility.getHitSlop('cross')
              : Utility.getHitSlop('contentPageCrossButton')
          }
        />
      </TouchableOpacity>
    );
  }
}

CrossButton.propTypes = {
  onCancelTap: PropTypes.func,
  crossButtonWidth: PropTypes.number,
};

CrossButton.defaultProps = {
  crossButtonWidth: 44.0,
};

export default CrossButton;

const styles = StyleSheet.create({
  storyDetailContainer: {
    position: 'absolute',
    justifyContent: 'center',
    right: 12,
    top: 10,
    zIndex: 100,
    backgroundColor: colors.black,
  },
  container: {
    position: 'absolute',
    justifyContent: 'center',
    right: 12,
    top: -10,
    zIndex: 100,
    backgroundColor: colors.transparentOpaque,
  },
  image: {
    alignSelf: 'center',
    color: '#dddddd',
    width: 21,
    height: 21,
  },
});
