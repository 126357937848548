import { camelCase } from 'lodash';
import BottomSearch from '../../components/bottomSearchComponent';
import CardSequence from '../../components/feature/CardSequence';
import Masonry from '../../components/feature/Masonry';
import Grid from '../../components/layout/Grid/Grid';
import GridRail from '../../components/layout/GridRail';
import Listing from '../../components/layout/Listing/Listing';
import Pager from '../../components/layout/pager/Pager';
import Rail from '../../components/layout/Rail/Rail';
import ReviewGrid from '../../components/layout/Grid/ReviewGrid';
import Section from '../../components/layout/Section';
import StoryRail from '../../components/layout/Rail/StoryRail';
import VerticalRail from '../../components/layout/verticalRail';
import { ExpandableList } from '../../components/layout/expandableList';
import PersonalisedGrid from '../../components/layout/Grid/personalisedGrid';
import { ListWithIngredients } from '../../components/layout/ListWithIngredients';
import StaggeredGrid from '../../components/layout/masonry/StaggeredGrid';
import PersonalizedHorizontal from '../../components/layout/matchedProducts/PersonalizedHorizontal';
import PersonalisedOffersRail from '../../components/layout/personalisedOffers/PersonalisedOffersRail';
import Tabbed from '../../components/layout/tabbed/Tabbed';
import TickingBanners from '../../components/layout/tickingBanners/TickingBanners';
import PersonalisedRail from '../../components/layout/verticalRail/personalisedRail';
import {
  HorizontalPlaylistCard,
  VerticalPlaylistCard,
} from '../../components/media';
import LoyaltyPlansWithBenefits from '../FoxyEdge/LoyaltyPlansWithBenefits';
import OfferBannerWithRail from '../offer/OfferBannerWithRail';
import OfferTimerBannerWithRail from '../offer/OfferTimerBannerWithRail';
import SwipeInitiateCard from '../todayDeals/SwipeInitiateCard';
import OverflowBanner from '../../components/layout/overflowBanner/OverflowBanner';
import VerticalBanner from '../../components/layout/verticalBanner/VerticalBanner';
import CouponRail from '../../components/layout/personalisedOffers/CouponRail';
import ListWithDescription from '../../components/layout/ListWithDescription';
import SimpleGrid from '../../components/layout/simpleGrid/SimpleGrid';
import { isDesktop } from '../../utils/BooleanUtility';
import PageWithSideBar from '../../components/layout/pageWithSideBar/PageWithSideBar';
import MultiLevelTabs from '../tabs/MultiLevelTabs';

const LayoutComponents = {
  list: Listing, // search Lipstik, top rail is Listing, confirm with Anurag
  grid: Grid,
  simpleGrid: SimpleGrid,
  rectangularGrid: Grid,
  personalizedGrid: PersonalisedGrid,
  gridRail: GridRail, // not known
  cardSequence: CardSequence,
  rail: Rail, // removed in video
  pager: Pager, // on top on offers page, with carousel // this
  pagerBottom: Pager,
  card: PersonalisedRail, // on tap of Personalised grid
  verticalRail: VerticalRail, // not being used currently
  storyRail: StoryRail, // not here
  reviewGrid: ReviewGrid, // not known
  horizontalPlaylist: HorizontalPlaylistCard,
  verticalPlaylist: VerticalPlaylistCard,
  offerBannerWithRail: OfferBannerWithRail, // On offers page(banner + list of products)
  offerTimerBannerWithRail: OfferTimerBannerWithRail, // same as OfferBannerWithRail + timer
  tabbed: Tabbed,
  expandableList: ExpandableList,
  matchedProduct: PersonalizedHorizontal,
  section: Section,
  listWithIngredients: ListWithIngredients,
  personalizedHorizontal: PersonalizedHorizontal,
  heroRail: PersonalizedHorizontal,
  fancyRail: Rail,
  swipableCards: SwipeInitiateCard,
  personalisedOffersRail: PersonalisedOffersRail,
  tickingBanners: TickingBanners,
  bottomSearchContainer: BottomSearch,
  unimplemented: CardSequence,
  staggeredGrid: StaggeredGrid,
  masonry: Masonry,
  tabbedRail: LoyaltyPlansWithBenefits,
  overflowBanner: OverflowBanner,
  verticalBanner: VerticalBanner,
  coupon: CouponRail,
  listWithDesc: ListWithDescription,
  multiLevelTabs: MultiLevelTabs,
  pageWithSidebar: PageWithSideBar,
  featuredRail: Rail,
};

export const getListComponent = (display) => {
  return LayoutComponents[camelCase(display)];
};

export const getListComponentHeight = (item) => {
  const { display } = item;

  try {
    const Component = getListComponent(display);
    return Component.getComponentHeight(item);
  } catch (error) {
    return 0;
  }
};
