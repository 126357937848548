import React, { Component } from 'react';
import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Image,
} from 'react-native';
import { AnalyticsManager } from '../../analytics';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import {
  EventParameterKey,
  EventType,
  EventParameterValue,
} from '../../analytics';

export default class NoInternet extends Component {
  constructor() {
    super();
    this.state = {
      isError: false,
      retrying: false,
    };
  }

  componentDidMount() {
    Utility.setPageLoadStart('feed', 0, '', false, {
      status: 'connection_error',
    });
  }

  onResetState = () => {
    this.setState({
      isError: false,
    });
  };

  onRetryPress = () => {
    const { onPress = () => {} } = this.props;
    Utility.pageLoadStart = Date.now();
    AnalyticsManager.logEvent(EventType.ERROR_EVENTS.RETRY, {
      [EventParameterKey.NAME]: EventParameterValue.ERROR_BUTTON_ACTION.RETRY,
      [EventParameterKey.PAGE]: EventParameterValue.ERROR_PAGE.CONNECTION_ERROR,
      [EventParameterKey.SCREEN_NAME]: EventParameterValue.SCREEN_NAME.FEED,
    });
    onPress();
  };

  _button = () => {
    const { retrying } = this.state;
    const { loading, darkMode } = this.props;
    const textStyle = darkMode
      ? darkModeButtonText
      : normalModeButtonText;
    const indicatorColor = darkMode ? colors.white : colors.subtitle;
    const showRetryText = !retrying && !loading;
    return (
      <TouchableOpacity onPress={this.onRetryPress} disabled={loading}>
        <View style={style.button}>
          {showRetryText ? (
            <Text style={textStyle}>Retry</Text>
          ) : (
            Utility.getActivityIndicatorSmall({}, indicatorColor, 25)
          )}
        </View>
      </TouchableOpacity>
    );
  };

  _fallbackComponent = () => {
    const description = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.connection_error,
    );
    return (
      <SafeAreaView
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 35,
          }}
        >
          <Image source={images.no_internet} />
          <Text style={style.heading}>Connection Error</Text>
          <Text style={style.subHeading}>{description}</Text>

          <this._button />
        </View>
      </SafeAreaView>
    );
  };

  render() {
    return <this._fallbackComponent />;
  }
}

const style = StyleSheet.create({
  heading: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    color: colors.foxyBlack,
    marginBottom: 8,
    marginTop: 16,
  },
  subHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    textAlign: 'center',
    color: colors.subtitle,
  },
  button: {
    height: 46,
    marginTop: 30,
    width: 180,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: colors.darkBorder,
  },
});

const themeStyle = StyleSheet.create({
  darkModeButtonText: {
    fontSize: size.h2,
    color: colors.white,
    fontFamily: 'Roboto-Medium',
  },

  normalModeButtonText: {
    fontSize: size.h2,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
  },
});

const darkModeButtonText = StyleSheet.compose(style.subHeading, themeStyle.darkModeButtonText);
const normalModeButtonText = StyleSheet.compose(style.subHeading, themeStyle.normalModeButtonText);
