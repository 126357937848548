import moment from 'moment';
import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import { connect } from 'react-redux';
import CountDown from '../../../helpers/Counter';
import withNavigation from '../../../utils/WithNavigation';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';
import OverlappingHorizontalImageList from '../../shared/OverlappingHorizontalImageList';
import RoutinesUtility from '../RoutinesUtility';
import { CURRENCY_SYMBOL } from '../../../config/Constants';
import { getScreenWidth } from '../../../utils/LayoutUtility';

class AboutRoutineCard extends PureComponent {
  colouredDetailsCard = ({ title, subtitle, backgroundColor }) => {
    const cardStyle = [styles.cardContainer, { backgroundColor }];
    return (
      <View style={cardStyle}>
        <Text style={styles.cardTitle}>{title}</Text>
        <Text style={styles.cardSubtitle}>{subtitle}</Text>
      </View>
    );
  };

  getTimeDifferenceForCountdown = () => {
    const { startsAt } = this.props;
    const startDate = RoutinesUtility.getLocalTimeFromUTC(startsAt);
    const currentDate = new Date();
    if (currentDate > startDate) {
      return null;
    }
    const timeDiff = startDate.getTime() - currentDate.getTime();
    const timeDiffInHours = timeDiff / (1000 * 60 * 60);
    if (timeDiffInHours > 24) {
      return null;
    }
    return timeDiff;
  };

  countdown = (timeDiff) => {
    if (Utility.isBlank(timeDiff)) return null;
    return (
      <CountDown
        size={12}
        until={timeDiff / 1000}
        digitStyle={styles.digitStyle}
        digitTxtStyle={styles.digitTxtStyle}
        timeLabelStyle={styles.timeLabelStyle}
        separatorStyle={styles.separatorStyle}
        timeToShow={['H', 'M', 'S']}
        timeLabels={{ h: 'hrs', m: 'min', s: 'sec' }}
        showSeparator
      />
    );
  };

  totalParticipants = () => {
    const { participants } = this.props;
    if (parseInt(participants) < 7) return null;
    const randomInitialNumber = Utility.getRandom(0, 6);
    return (
      <View style={styles.totalParticipantsContainer}>
        <View style={styles.totalParticipantsTitleContainer}>
          <OverlappingHorizontalImageList
            images={images.routines.participantsArray.slice(
              randomInitialNumber,
              randomInitialNumber + 3,
            )}
          />
          <Text style={styles.totalParticipantsTitle}>Total Participants</Text>
        </View>
        <Text style={styles.totalParticipantsSubtitle}>{participants}</Text>
      </View>
    );
  };

  render() {
    const {
      description = '',
      mrp = '',
      startsAt,
      duration = '',
      membership_cohort,
      isUserSubscribed = false,
    } = this.props;

    const startsAtText = moment(startsAt).format('MMM D');
    const timeDiff = this.getTimeDifferenceForCountdown();
    const title = Utility.isBlank(timeDiff)
      ? 'About this routine'
      : 'Starting in';
    return (
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
          {this.countdown(timeDiff)}
        </View>
        {!!isUserSubscribed && (
          <Text style={styles.subscribed}>
            You are successfully subscribed ✅
          </Text>
        )}

        <Text style={styles.body}>{description}</Text>

        <View style={styles.detailCardsContainer}>
          <this.colouredDetailsCard
            title='PRICE'
            subtitle={
              membership_cohort === 'member' ? 'FREE' : `${CURRENCY_SYMBOL}${mrp}`
            }
            backgroundColor={colors.routine_about_card.light_green}
          />
          <this.colouredDetailsCard
            title='STARTS'
            subtitle={startsAtText}
            backgroundColor={colors.routine_about_card.light_blue}
          />
          <this.colouredDetailsCard
            title='DURATION'
            subtitle={`${duration} days`}
            backgroundColor={colors.routine_about_card.light_yellow}
          />
        </View>
        <this.totalParticipants />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  membership_cohort: state.todayDeals.membership_cohort,
});

export default withNavigation(connect(mapStateToProps, null)(AboutRoutineCard));

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 20,
    alignItems: 'flex-start',
    borderRadius: 8,
    backgroundColor: colors.white,
    marginVertical: 6,
    ...Platform.select({
      web: {},
      default: { flex: 1 },
    }),
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    marginBottom: 12,
  },
  body: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
  },
  subscribed: {
    color: '#01B460',
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    marginBottom: 8,
    fontStyle: 'italic',
  },
  cardContainer: {
    height: 54,
    flex: 1,
    paddingVertical: 10,
    borderRadius: 8,
    alignItems: 'center',
    marginHorizontal: 5,
    justifyContent: 'center',
  },
  detailCardsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 6,
    paddingTop: 12,
    ...Platform.select({
      web: { width: getScreenWidth() - 42 },
      default: {},
    }),
  },
  cardTitle: {
    color: colors.subtitle,
    fontSize: size.h4_5,
    fontFamily: 'Roboto-Medium',
  },
  cardSubtitle: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  digitTxtStyle: {
    color: colors.white,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    marginTop: 0,
    marginLeft: 2.1,
  },
  separatorStyle: {
    color: colors.black,
    marginBottom: 8,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  totalParticipantsContainer: {
    backgroundColor: colors.routine_card_bg,
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 12,
  },
  totalParticipantsTitle: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginLeft: 12,
  },
  totalParticipantsSubtitle: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
  },
  totalParticipantsTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});
