// Dependencies
import { StyleSheet, Dimensions, Platform } from 'react-native';

// Themes
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

const { width, height } = Dimensions.get('window');

const SelfiePromptStyles = StyleSheet.create({
  modal: {
    flex: 1,
  },

  selfiePromptImage: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.foxyBlack,
    padding: 24,
    width,
  },

  titleContainer: {
    height: height / 4.5,
    justifyContent: 'center',
  },

  title: {
    color: 'white',
    textAlign: 'center',
  },

  manualButton: {
    height: 30,
    marginBottom: 30,
    paddingTop: 10,

    justifyContent: 'center',
    /* alignItems: 'flex-start' */
  },

  manualButtonText: {
    fontSize: size.h4,
    color: colors.white,
    paddingTop: 5,
    textAlign: 'center',
  },
  skipButtonContainer: { position: 'absolute', right: 24, top: 50 },
  skipText: {
    fontSize: 14,
    color: colors.white,
    fontFamily: 'Roboto-Regular',
  },

  bottomButtonContainer: {
    bottom: Utility.isAndroid() ? 20 : 40,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getScreenWidth(),
  },
  foxyLogoContainer: {
    position: 'absolute',
    top: 30,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  linearGradientOverlay: {
    position: 'absolute',
    height: '100%',
    width: Utility.getScreenWidth(),
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export default SelfiePromptStyles;
