import React, { Component, PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import Modal from 'react-native-modal';
import PropTypes from 'prop-types';
import images from '../../../theme/Images';
import size from '../../../theme/Fonts';
import colors from '../../../theme/Colors';

export default class LocationPermissionModal extends PureComponent {
  render() {
    const {
      title,
      description,
      buttonText,
      imageSrc,
      showButtonIcon,
      onClick,
    } = this.props;
    return (
      <View>
        <Modal isVisible={this.props.showModal} style={stylesheet.container}>
          <View style={stylesheet.innerContainer}>
            <Image source={imageSrc} />
            <Text style={stylesheet.head_text}>{title}</Text>
            <Text style={stylesheet.desc_text}>{description}</Text>

            <TouchableWithoutFeedback onPress={onClick}>
              <View style={stylesheet.button}>
                {showButtonIcon && (
                  <Image
                    source={images.location}
                    style={{ height: 22, width: 22 }}
                    tintColor='#ffffff'
                  />
                )}

                <Text style={stylesheet.buttonText}>{buttonText}</Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </Modal>
      </View>
    );
  }
}

const stylesheet = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  head_text: {
    fontSize: size.h1,
    marginTop: 30,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  desc_text: {
    fontSize: size.h3,
    color: colors.silver,
    alignContent: 'center',
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 7,
    padding: 15,
    height: 330,
    width: 300,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    height: 46,
    backgroundColor: colors.green,
    width: 230,
    marginTop: 40,
    flexDirection: 'row',
    alignItems: 'center',

    justifyContent: 'center',
  },
  buttonText: {
    color: colors.white,
    fontSize: size.h1,
    marginLeft: 10,
    fontFamily: 'Roboto-Bold',
  },
});

LocationPermissionModal.defaultProps = {
  title: 'Enable Location',
  description:
    'We need your location access to suggest you nearby salons and stores.',
  buttonText: 'Allow Location',

  showButtonIcon: true,
};
LocationPermissionModal.PropTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  buttonText: PropTypes.any,
  imageSrc: PropTypes.any,
  showButtonIcon: PropTypes.bool,
};
