import { View, StyleSheet } from 'react-native';
import React from 'react';
import Config from '../../libraries/ReactNativeConfig';
import NavigationService from '../../navigator/NavigationService';
import { StaticNavigationHeader } from '../shared';
import EmptyState from '../layout/emptyState/Emptystate';
import images from '../../theme/Images';
import colors from '../../theme/Colors';

export default function LoginScreen() {
  return (
    <>
      <StaticNavigationHeader title="Order History" />
      <View style={styles.container}>
        <EmptyState
          title="No orders found"
          buttonText="Login to see your orders"
          subtitle={`Place your first order on ${Config.APP_NAME}!`}
          image={images.emptyState.collection}
          onPress={NavigationService.renderOnboarding}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background,
  },
});
