import { put, takeLatest } from 'redux-saga/effects';
import {
  BRAND_DATA_RECEIVED,
  FETCH_BRAND,
  UPDATE_BRAND_DATA,
  UPDATE_BRAND_DATA_BY_SLUG,
} from '../actions/ActionTypes';
import Utility from '../utils/Utility';
import { API_DOMAIN } from '../config/Constants';
import {
  getApiHeaders,
  convertJsonFromResponse,
} from './GeneratorUtil';

export function* fetchBrandAsync(action) {
  const {
    payload: { slug },
    callback = () => {},
  } = action;
  const url = `${API_DOMAIN}${slug}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json)
    } else {
      callback(false, {}, response.status);
    }
  } catch (error) {
    callback(false);
  }
}

// Our watcher Saga:
export default function* watchFetchBrand() {
  yield takeLatest(FETCH_BRAND, fetchBrandAsync);
}
