import { StyleSheet, Dimensions, Platform } from 'react-native';

import colors, { Theme } from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';
import {
  ADD_PRODUCT,
  ADD_PRODUCT_BOTTOM_SECTION,
} from '../../../config/LayoutConstants/AddProductsConfig';

const { topSectionInset, bottomSectionInset } = ADD_PRODUCT.height;
const { leftSection, rightSection } = ADD_PRODUCT_BOTTOM_SECTION.width;
const { buttonsHeight } = ADD_PRODUCT_BOTTOM_SECTION.height;

const styles = StyleSheet.create({
  backCover: {
    position: 'absolute',
    backgroundColor: 'rgba(52, 52, 52, 0)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: (Utility.getScreenWidth() * 0.8) / 0.66,
    width: Utility.getScreenWidth() * 0.8,
  },

  permissionText: {
    color: colors.white,
    textAlign: 'center',
    alignSelf: 'center',
    width: Utility.getScreenWidth() * 0.5,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3, //14,
  },

  topSection: {
    height: 84,
    width: Utility.getScreenWidth() - 20,
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
  },

  permissionTextHeader: {
    color: colors.white,
    textAlign: 'center',
    alignSelf: 'center',
    width: 150,
    fontFamily: 'Roboto-Medium',
    fontSize: 24,
    fontStyle: 'normal',
  },

  permissionButton: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.white,
    padding: 10,
    width: Utility.getScreenWidth() * 0.4,
    borderRadius: 15,
  },

  goToSettingsText: {
    color: colors.black,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'Roboto-Bold',
    fontSize: size.h2,
  },

  container: {
    flex: 1,
    backgroundColor: Theme.addProducts.background,
  },

  cameraView: {
    flex: 1,
    justifyContent: 'space-between',
  },

  topSectionContainer: {
    justifyContent: 'flex-end',
  },

  containerTitle: {
    color: Theme.addProducts.topSection.textColor,
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
  },

  submit: {
    // justifyContent: 'center',
    top: 80,
    backgroundColor: colors.black,
    alignSelf: 'center',
    alignItems: 'center',
    position: 'absolute',
    padding: 5,
    borderRadius: 8,
  },

  submitText: {
    color: colors.white,
    textAlign: 'center',
    alignSelf: 'center',
  },

  loaderText: {
    color: colors.white,
    textAlign: 'center',
    alignSelf: 'center',
    top: 20,
  },

  continueButton: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.white,
    padding: 10,
    bottom: 20,
    width: Utility.getScreenWidth() * 0.9,
  },

  button: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.white,
    padding: 10,
    bottom: 30,
    width: Utility.getScreenWidth() * 0.9,
  },

  loaderView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getScreenWidth(),
    height: Utility.getScreenHeight(),
    top: 0,
    position: 'absolute',
  },

  blurView: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getScreenWidth(),
    height: Utility.getScreenHeight(),
    top: 0,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },

  messageForPermissionText: {
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    marginTop: 0,
    width: Utility.getScreenWidth() * 0.9,
    color: colors.white,
  },

  topBox: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  permissionView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  backgroundImage: {
    resizeMode: 'stretch', // or 'stretch'
    alignSelf: 'center',
    flexDirection: 'column',
    width: Utility.getScreenWidth(),
    height: Utility.getScreenHeight(),
  },

  generalMessageText: {
    color: colors.white,
    textAlign: 'center',
    width: Utility.getScreenWidth() - 30,
    bottom: Utility.isAndroid()
      ? Utility.getScreenHeight() * 0.12
      : Utility.getScreenHeight() * 0.15,
    alignSelf: 'center',
    position: 'absolute',
  },

  topSectionInset: {
    height: topSectionInset,
    width: Utility.screenWidth,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },

  topSectionInsetOfScanner: {
    height: 50,
    width: Utility.screenWidth,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingHorizontal: 16,
    position: 'absolute',
  },

  middleSection: {
    width: Utility.screenWidth,
    justifyContent: 'center',
  },

  scannedProductListContainer: {
    position: 'absolute',
    left: (Utility.screenWidth - 300) / 2,
    width: 300,
    bottom: 50,
    borderRadius: 8,
    backgroundColor: colors.white,
    justifyContent: 'space-between',
    overflow: 'hidden',
  },

  scanFrameContainer: {
    position: 'absolute',
    left: 10,
    width: Utility.screenWidth - 20,
    height: 340,
    justifyContent: 'center',
    alignItems: 'center',
  },

  searchProductButton: {
    height: 46,
    width: 300,
    backgroundColor: colors.background,
    alignItems: 'center',
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  searchProductButtonText: {
    color: 'rgb(153, 156, 173)',
    fontSize: size.h3,
    width: 300 - (32 + 18 + 10),
  },

  recognizedProducts: {
    height: 224 - 46,
  },

  productShutterButtonContainer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    alignItems: 'center',
  },

  bottomSectionInset: {
    height: bottomSectionInset,
    width: Utility.screenWidth,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
  },

  bottomSectionLeftPanel: {
    height: 40,
    width: Utility.screenWidth - (84 + 40),
  },

  showAddedProductsContainer: {
    height: buttonsHeight,
    maxWidth: leftSection,
    flexDirection: 'row',
  },

  productsSet: {
    height: buttonsHeight,
    minWidth: 50,
    maxWidth: leftSection - 50,
  },

  doneButton: {
    width: rightSection,
    height: buttonsHeight,
    backgroundColor: colors.white,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },

  doneButtonText: {
    color: colors.black,
    fontSize: size.h3,
  },
  getBookingandCertificationContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 50,
  },
  bookingandCertificateHeaderText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 24,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginTop: 20,
    color: colors.foxyBlack,
  },
  bookingandCertificateContentText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h2,
    marginTop: 20,
    marginLeft: 24,
    marginRight: 24,
    textAlign: 'center',

    color: colors.subtitle,
  },
});

export default styles;

// import { StyleSheet, Dimensions, Platform } from 'react-native';

// import { ADD_PRODUCT } from '../../../config/LayoutConstants/AddProductsConfig';

// const {
//   screenHeight,
//   topInset,
//   bottomInset,
//   statusBar,
//   bottomNavBar,
//   ContainerHeight,
// } = ADD_PRODUCT.height;
// const { screenWidth } = ADD_PRODUCT.width;

// const styles = StyleSheet.create({

//   backgroundLayout: {
//     flex: 1,
//     backgroundColor: 'black',
//     justifyContent: 'center',
//   },

//   container: {
//     height: ContainerHeight,
//   },

//   searchContainer: {
//     width: 325,
//     height: 45,
//     borderRadius: 4,
//     borderWidth: 2,
//     borderColor: 'white',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//   },

//   searchContainerLeftSection: {
//     width: 40,
//     flexDirection: 'row',
//     alignItems: 'center',
//   },

//   sectionSeparator: {
//     height: 32,
//     width: 2,
//     backgroundColor: 'white',
//   },

//   searchText: {
//     fontSize: 16,
//     color: 'white',
//   },

//   searchContainerRightSection: {
//     width: 40,
//   },

//   mediaItemThumbnail: {
//     height: 40,
//     width: 40,
//     marginRight: 10,
//     borderRadius: 4,
//     borderWidth: 2,
//   },
// });

// export default styles;
