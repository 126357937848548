import { camelCase } from 'lodash';
import React, { PureComponent } from 'react';
import { isBlank } from '../../../utils/BooleanUtility';
import AccordionTab from '../Accordian/AccordionTab';
import EmbeddedRichContent from './EmbeddedRichContent';

class RichContent extends PureComponent {
  RichContentLayoutComponent = {
    accordionTab: AccordionTab,
    embedded: EmbeddedRichContent,
  };

  renderRichContent = (item = {}, index) => {
    const { display_type = '', contents = [], title = '' } = item;
    const LayoutComponent =
      this.RichContentLayoutComponent[`${camelCase(display_type)}`];
    if (LayoutComponent === undefined) return null;
    return (
      <LayoutComponent contents={contents} title={title} itemData={item} />
    );
  };

  render() {
    const { itemData = {} } = this.props;
    if (isBlank(itemData)) return null;
    return itemData.map(this.renderRichContent);
  }
}

export default RichContent;
