import { takeLatest, put, select } from 'redux-saga/effects';
import Utility from '../utils/Utility';
import {
  CHECK_IF_PAYTM_ACCOUNT_EXIST,
  ADD_PAYTM_MONEY,
  CHECK_PAYTM_BALANCE,
  savePaytmDetailToRedux,
  SEND_PAYTM_OTP,
  VERIFY_PAYTM_OTP,
  UNLINK_PAYTM,
} from '../actions/PaytmActions';
import { URL } from '../config/Constants';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import { isWeb } from '../utils/BooleanUtility';
import { getWebDeviceId } from '../utils/TokenUtility';

function* addPaytmMoney(action) {
  const { vpa, callback = () => {} } = action;
  let url = `${URL.ADD_PAYTM_MONEY}`;

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response?.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.tron.log(` error in prompt3${JSON.stringify(error)}`);
  }
}

function* checkPaytmBalance(action) {
  let url = `${URL.CHECK_BALANCE}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response?.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put(savePaytmDetailToRedux(json));
      }
    } else {
      yield put(savePaytmDetailToRedux({}));
      console.tron.log('error in checking balance upi', statusCode);
    }
  } catch (error) {
    yield put(savePaytmDetailToRedux({}));
    console.tron.log(`PaytmSaga.checkPaytmBalance: ${JSON.stringify(error)}`);
  }
}
function* sendPaytmOtpAsync(action) {
  const { callback, paytmNumber } = action;
  const deviceId = yield select((state) => state.UserAccountInfo.deviceId);
  try {
    const payload = {
      phone_number: paytmNumber,
      device: isWeb() ? getWebDeviceId() : deviceId,
    };
    const response = yield fetch(URL.SEND_PAYTM_OTP, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payload),
    });

    if (response?.status >= 200 && response?.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
    console.tron.log(`PaytmSaga.sendPaytmOtpAsync: ${JSON.stringify(error)}`);
  }
}

function* verifyPaytmOtpAsync(action) {
  const { callback, paytmMobileNumber, otp, state_token } = action;
  const deviceId = yield select((state) => state.UserAccountInfo.deviceId);
  try {
    const payload = {
      otp,
      state_token,
      phone_number: paytmMobileNumber,
      device_id: isWeb() ? getWebDeviceId() : deviceId,
    };
    const response = yield fetch(URL.VERIFY_PAYTM_OTP, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payload),
    });
    if (response?.status >= 200 && response?.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
    console.tron.log(`PaytmSaga.verifyPaytmOtpAsync: ${JSON.stringify(error)}`);
  }
}

function* unlinkPaytmAsync(action) {
  const { callback } = action;

  try {
    const response = yield fetch(URL.UNLINK_PAYTM, {
      method: 'POST',
      headers: yield getApiHeaders(),
    });

    if (response?.status >= 200 && response?.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
    console.tron.log(`PaytmSaga.unlinkPaytmAsync: ${JSON.stringify(error)}`);
  }
}

export default function* watchPaytmSaga() {
  yield takeLatest(ADD_PAYTM_MONEY, addPaytmMoney);
  yield takeLatest(CHECK_PAYTM_BALANCE, checkPaytmBalance);
  yield takeLatest(SEND_PAYTM_OTP, sendPaytmOtpAsync);
  yield takeLatest(VERIFY_PAYTM_OTP, verifyPaytmOtpAsync);
  yield takeLatest(UNLINK_PAYTM, unlinkPaytmAsync);
}
