import React from 'react';
import PayoffEmptyState from '../feed/PayoffEmptyState';

export default function Payoff() {
  return <PayoffEmptyState />;
}

export const PayoffHeaderOptions = {
  headerShadowVisible: false,
};
