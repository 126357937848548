import { takeEvery, call, put, select } from 'redux-saga/effects';

import {
  UPDATE_CONTENT_ITEMS,
  updateConcatenatedVideo,
} from '../actions/UploadPostActions';

function* updateVideo(action) {
  try {
    yield put(updateConcatenatedVideo(action.postID, action.contentItems));
    yield action.callback();
  } catch (error) {
    console.tron.log(`Error in updating content item ${error}`);
  }
}

export default function* watchUpdateConcatenatedVideoSaga() {
  yield takeEvery(UPDATE_CONTENT_ITEMS, updateVideo);
}
