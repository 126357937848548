import * as types from '../actions/ActionTypes';
import initialState from './initialState';

export default function permissionStatus(
  state = initialState.permissionStatus,
  action,
) {
  switch (action.type) {
    case types.SET_PERMISSION_STATUS:
      return {
        ...state,
        [action.permission]: action.status,
      };

    default:
      return state;
  }
}
