import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import colors from '../../../theme/Colors';
import HeaderImage from './modal/HeaderImage';
import HeaderAnimation from './modal/HeaderAnimation';
import { ModalBody, WhatsappHyperlink } from './modal/ModalBody';
import CrossIcon from './modal/CrossIcon';
import Utility from '../../../utils/Utility';
import ActionButton from './modal/ActionButton';
import DebouncedTouchableOpacity from '../../shared/DebouncedTouchableOpacity';
import { isDesktop } from '../../../utils/BooleanUtility';

export default function AlertModal() {
  const navigation = useNavigation();
  const { params = {} } = useRoute();
  const {
    height = 206,
    alertBoxTitle = '',
    alertMessage = '',
    firstButtonTitle = '',
    firstButtonTextColor = colors.enabled,
    secondButtonTitle = 'Discard',
    secondButtonTextColor = colors.foxyBlack,
    firstButtonOnPress = () => {},
    secondButtonOnPress = () => {},
    textAlignment,
    hideSecondButton = false,
    autoWrapContent = false,
    imageUrl,
    firstActionButtonStyle = {},
    secondActionButtonStyle = {},
    isAsset = false,
    animation,
    animationStyle,
    whatsappHyperlinkMessage = '',
    cancelPressed,
    showAnimation = false,
    isAbsolute = false,
    numberOfLines,
    showCross = false,
    isProfilePicture = false,
    onTapOutside,
  } = params;
  const closeModal = () => {
    navigation.pop();
  };

  const onPressOutside = () => {
    if (typeof onTapOutside === 'function') {
      onTapOutside();
    } else {
      closeModal();
    }
  } 

  const onPressCancel = () => {
    if (typeof cancelPressed === 'function') {
      cancelPressed();
    } else {
      closeModal();
    }
  }

  const boxHeight = autoWrapContent ? {} : { height };
  const containerTop = { top: (Utility.getScreenHeight() - height - 120) / 2 };

  return (
    <>
      <DebouncedTouchableOpacity
        style={styles.touchableContainer}
        onPress={onPressOutside}
      />
      <View style={styles.modalContainer} pointerEvents="box-none">
        <View
          style={[
            styles.alertBoxContainer,
            containerTop,
            boxHeight,
            isDesktop() && styles.desktopContainer,
          ]}
        >
          <View style={styles.alertMessageContainer}>
            <HeaderImage
              imageUrl={imageUrl}
              isProfilePicture={isProfilePicture}
              isAbsolute={isAbsolute}
              isAsset={isAsset}
            />
            <HeaderAnimation
              showAnimation={showAnimation}
              animation={animation}
              animationStyle={animationStyle}
            />
            <ModalBody
              title={alertBoxTitle}
              numberOfLines={numberOfLines}
              textAlign={textAlignment}
              message={alertMessage}
              isAbsolute={isAbsolute}
              headerMediaNotExists={!imageUrl && !showAnimation}
            />
            <WhatsappHyperlink message={whatsappHyperlinkMessage} />
            <CrossIcon showCross={showCross} onPress={onPressCancel} />
          </View>
          <ActionButton
            color={firstButtonTextColor}
            onPress={firstButtonOnPress}
            title={firstButtonTitle}
            style={firstActionButtonStyle}
            opacity={1}
          />
          {!hideSecondButton && (
            <ActionButton
              color={secondButtonTextColor}
              onPress={secondButtonOnPress}
              title={secondButtonTitle}
              style={secondActionButtonStyle}
              opacity={0.7}
            />
          )}
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.translucent,
  },
  alertBoxContainer: {
    position: 'absolute',
    width: 300,
    left: (Utility.getScreenWidth() - 300) / 2,
    backgroundColor: 'white',
    borderRadius: 16,
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  alertMessageContainer: {
    borderBottomColor: colors.borderLight,
    paddingBottom: 16,
    paddingTop: 4,
  },
  desktopContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
