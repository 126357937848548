import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import size from '../../../theme/Fonts';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },

  container: {
    flexDirection: 'column',
    flex: 1,
  },

  topContainer: {
    height: Utility.getScreenHeight(),
    backgroundColor: colors.white,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 24,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  headerText: {
    fontFamily: 'Roboto-Medium',
    // fontSize: size.h1,
    fontSize: 20,
    marginTop: 30,
    // color: colors.foxyBlack,
    color: '#173143',
  },
  subHeading: {
    fontFamily: 'Roboto-Regular',
    // fontSize: size.h3,
    fontSize: 14,
    color: colors.silver,
    textAlign: 'justify',
    marginTop: 6,
  },
  referSection: {
    borderRadius: 8,
    borderWidth: 1,
    width: Utility.getButtonWidth(),
    backgroundColor: colors.white,
    borderColor: colors.borderDark,
    flexDirection: 'row',
    height: 40,
    marginTop: 20,

    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  row: {
    flexDirection: 'row',
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    width: Utility.getScreenWidth(),
    justifyContent: 'space-between',
  },

  column: {
    flexDirection: 'column',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    backgroundColor: colors.white,
  },
  historyFont: {
    fontSize: size.h2,
    color: colors.foxyBlack,
  },

  row_profile_item: {
    flexDirection: 'row',
    flex: 1,
    marginTop: 15,
    justifyContent: 'space-between',
    marginBottom: 15,
  },

  user_name: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },
  user_handle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    color: colors.silverLit,
  },

  profile_pic: {
    height: 36,
    width: 36,
    backgroundColor: colors.silverLit,
    borderRadius: 20,
  },

  col: {
    flexDirection: 'column',
    marginLeft: 15,
  },

  reminded: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 40,
    alignItems: 'center',
  },
  remindButton: {
    flexDirection: 'row',
    width: 80,
    borderRadius: 30,
    marginLeft: 40,
    backgroundColor: '#D5E8FF',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },

  headerColor: {
    backgroundColor: colors.white,
  },

  shareSection: {
    flexDirection: 'column',
    marginTop: 20,
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    //  position: 'absolute',
    bottom: 0,

    alignItems: 'flex-start',
  },
});

export default styles;
