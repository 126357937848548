import { View, StyleSheet, Keyboard, Pressable } from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import Config from '../../libraries/ReactNativeConfig';
import images from '../../theme/Images';
import EmptyState from '../layout/emptyState/Emptystate';
import colors from '../../theme/Colors';
import Email from './Email';
import Utility from '../../utils/Utility';

export default function EmptyShopifyOrders({ emailVerificationError }) {
  const navigation = useNavigation();
  const navigateToStore = () => {
    navigation.navigate(Config.ORDER_HISTORY_EMPTY_STATE_CTA);
  };

  const hideKeyboard = () => Keyboard.dismiss();

  return (
    <View style={styles.container}>
      <Pressable onPress={hideKeyboard}>
        <View style={styles.emptyStateContainer}>
          <EmptyState
            title="No orders found"
            buttonText="Order now"
            subtitle={`Place your first order on ${Config.APP_NAME}!`}
            image={images.emptyState.collection}
            onPress={navigateToStore}
          />
        </View>
      </Pressable>
      <Email emailVerificationError={emailVerificationError} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.background,
    marginBottom: 10,
    zIndex: -1,
  },
  emptyStateContainer: {
    width: Utility.getScreenWidth(),
  },
});
