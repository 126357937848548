import React from 'react';

const withMaybe = (conditionalRenderingFn) => (Component) => (props) =>
  conditionalRenderingFn(props) ? null : <Component {...props} />;

const withEither =
  (conditionalRenderingFn, EitherComponent) => (Component) => (props) =>
    conditionalRenderingFn(props) ? (
      <EitherComponent {...props} />
    ) : (
      <Component {...props} />
    );

export { withMaybe, withEither };
