import * as types from '../actions/ActionTypes';
import initialState from './initialState';
import Utility from '../utils/Utility';
import {
  SAVE_ALLOWED_VALUES,
  SAVE_MAPPED_ATTRIBUTES,
  GET_MASTER_ATTRIBUTES_LIST,
  MASTER_LIST_LAST_REFRESHED_AT,
  SET_MANUAL_SELFIE_REVIEW,
} from '../actions/FacialAnalysisActions';
import { COLLECTION } from '../config/LayoutConstants/CollectionConfig';

export default function facialAnalysis(
  state = initialState.facialAnalysis,
  action,
) {
  switch (action.type) {
    case SAVE_ALLOWED_VALUES:
      return {
        ...state,
        master_attribute_list: action.data,
      };
    case SAVE_MAPPED_ATTRIBUTES:
      return {
        ...state,
        my_mapped_attributes: action.my_mapped_attributes,
      };

    case MASTER_LIST_LAST_REFRESHED_AT:
      return {
        ...state,
        master_list_last_refreshed_at: action.master_list_last_refreshed_at,
      };
    case SET_MANUAL_SELFIE_REVIEW:
      return {
        ...state,
        isManuallyReviewed: action.isManuallyReviewed,
      };

    default:
      return state;
  }
}
