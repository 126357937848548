import {
  Text,
  View,
  FlatList,
  TouchableOpacity,
  BackHandler,
} from 'react-native';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContactUsReasons } from '../../../actions/ActionTypes';
import styles from './styles';
import withNavigation from '../../../utils/WithNavigation';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import { StaticNavigationHeader } from '../../../components/shared';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';

class ContactUsReasonPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSelectReason: false,
      selectedReason: '',
      contactUsReasons: [],
      selectedPrimaryAction: '',
      selectedCallBackRequestReasonId: '',
      selectedReasonIndex: '',
      selectedSlug: '',
      isShowError: false,
    };
    const { navigation, route } = this.props;
    this.entityType = route.params?.entityType;
    this.entityId = route.params?.entityId;
    this.orderAasmState = route.params?.orderAasmState;
    this.orderDeliveryDate = route.params?.deliveryDate;
    this.orderId = route.params?.orderId;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    const { navigation, getContactUsReasons } = this.props;
    getContactUsReasons(
      this.entityType,
      this.entityId,
      this.getContactUsReasonsCallBack,
    );
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  getContactUsReasonsCallBack = (success, response) => {
    if (success) {
      this.setState({
        contactUsReasons: response.data,
      });
      AnalyticsManager.logEvent('page_load_2', {
        [EventParameterKey.PAGE]: SCREEN_CONSTANTS.CONTACT_US_REASONS,
      });
    }
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  onPressReason = (
    primaryText,
    primaryAction,
    callBackRequestReasonId,
    index,
    slug,
  ) => {
    const { isSelectReason } = this.state;
    this.setState({
      isSelectReason: !isSelectReason,
      selectedReason: primaryText,
      selectedPrimaryAction: primaryAction,
      selectedCallBackRequestReasonId: callBackRequestReasonId,
      selectedReasonIndex: index,
      selectedSlug: slug,
      isShowError: false,
    });
  };

  reasonList = ({ item, index }) => {
    const { selectedReasonIndex } = this.state;
    const {
      attributes: {
        primaryText = '',
        primaryAction = '',
        callbackRequestReasonId = '',
        slug = '',
      } = {},
    } = item;
    const isSelectedReasonStyle =
      selectedReasonIndex === index
        ? styles.selectedReasonContainerStyle
        : styles.reasonListContainer;
    return (
      <TouchableOpacity
        style={isSelectedReasonStyle}
        onPress={() =>
          this.onPressReason(
            primaryText,
            primaryAction,
            callbackRequestReasonId,
            index,
            slug,
          )
        }
      >
        <Text style={styles.reasonTextStyle}>{primaryText}</Text>
      </TouchableOpacity>
    );
  };

  bottomButtonAction = () => {
    const {
      selectedReason,
      selectedPrimaryAction,
      selectedCallBackRequestReasonId,
      selectedSlug,
    } = this.state;
    const { navigation } = this.props;
    if (Utility.isBlank(selectedReason)) {
      this.setState({
        isShowError: true,
      });
      return null;
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.TYPE]: selectedReason.slice(0, 45),
      [EventParameterKey.ENTITY_ID]: this.entityId,
      [EventParameterKey.ENTITY_TYPE]: this.entityType,
      [EventParameterKey.STATUS]: this.orderAasmState,
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.CONTACT_US_REASONS,
      [EventParameterKey.CTA]: 'Continue',
    });
    if (selectedPrimaryAction === 'return_order') {
      navigation.navigate('ReturnReplaceReasonPage', {
        headerTitle: selectedReason,
        callBackRequestReasonId: selectedCallBackRequestReasonId,
        entityType: this.entityType,
        entityId: this.entityId,
        orderId: this.orderId,
        orderAasmState: this.orderAasmState,
      });
    } else if (selectedPrimaryAction === 'request_callback') {
      navigation.navigate('RequestCallBackPage', {
        headerTitle: selectedReason,
        callBackRequestReasonId: selectedCallBackRequestReasonId,
        entityType: this.entityType,
        entityId: this.entityId,
        orderId: this.orderId,
        orderAasmState: this.orderAasmState,
      });
    } else if (selectedPrimaryAction === 'cancel_order') {
      navigation.navigate('CancelOrderReasonPage', {
        headerTitle: selectedReason,
        callBackRequestReasonId: selectedCallBackRequestReasonId,
        entityType: this.entityType,
        entityId: this.entityId,
        orderId: this.orderId,
        orderAasmState: this.orderAasmState,
      });
    } else if (selectedPrimaryAction === 'track_order') {
      navigation.navigate('CustomerOrderStatus', {
        headerTitle: selectedReason,
        callBackRequestReasonId: selectedCallBackRequestReasonId,
        entityType: this.entityType,
        entityId: this.entityId,
        orderAasmState: this.orderAasmState,
        deliveryDate: this.orderDeliveryDate,
        orderId: this.orderId,
      });
    } else if (selectedPrimaryAction === 'faq') {
      navigation.navigate('FaqAnswerSection', {
        headerTitle: selectedReason,
        callBackRequestReasonId: selectedCallBackRequestReasonId,
        entityType: this.entityType,
        entityId: this.entityId,
        orderAasmState: this.orderAasmState,
        deliveryDate: this.orderDeliveryDate,
        orderId: this.orderId,
        slug: selectedSlug,
        previousScreen: 'contactUsQuestions',
      });
    }
  };

  keyExtractor = (item, index) => index;

  render() {
    const { contactUsReasons, isSelectReason, isShowError } = this.state;
    return (
      <>
        <StaticNavigationHeader title={'Contact us'} />
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <Text style={styles.heading}>How can we help you?</Text>
            <FlatList
              style={styles.listContainer}
              data={contactUsReasons}
              renderItem={this.reasonList}
              keyExtractor={this.keyExtractor}
              extraData={isSelectReason}
            />
            {isShowError && (
              <Text style={styles.errorMessage}>
                *Please select a reason to Continue.
              </Text>
            )}
          </View>
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 36}
              style={styles.foxyShadowButton}
              title={'Continue'}
              backgroundColor={colors.codGray}
              firstColor={colors.black}
              secondColor={colors.black}
              underlayColor={colors.black}
              borderRadius={4}
              onPress={this.bottomButtonAction}
            />
          </View>
        </View>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getContactUsReasons,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(ContactUsReasonPage),
);
