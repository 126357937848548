import React from 'react';
import { View, Text } from 'react-native';
import StyleSheet from 'react-native-media-query';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import colors from '../../theme/Colors';
import { PaymentStyles } from './styles';
import Utility from '../../utils/Utility';
import PrepaidOnly from '../offer/PrepaidOnly';
import CodExpandedMessage from './CodExpandedMessage';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { REMOTE_CONFIG_KEYS, CURRENCY_SYMBOL } from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import {
  removeOffer,
  setCod,
  getCartItems,
  fetchData,
} from '../../actions/ActionTypes';
import FoxyBottomModal from '../shared/FoxyBottomModal';
import { AnalyticsManager, CartAndPaymentAnalytics } from '../../analytics';
import RecommendedList from '../../containers/cart/RecommendedList';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import WithNavigation from '../../utils/WithNavigation';
import AppConfig from '../../config/AppConfig';
import Config from '../../libraries/ReactNativeConfig';
import { isDesktop } from '../../utils/BooleanUtility';

class CodPayment extends React.Component {
  constructor(props) {
    super(props);

    this.paymentPayload = {
      paymentGateway: '',
      paymentMethod: 'cod',
      isSavedPaymentMethod: true,
      paymentMethodPayload: {},
      payuPayload: {},
      extraData: {
        method: 'cod',
      },
    };
    this.state = {
      showCodConfirmationBottomModal: false,
      recommendedCartItemsList: {},
      showRecommendedCartItemsShimmer: true,
    };
    this.showCodConfirmationModal = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.show_cod_confirmation_modal,
    );
    this.prepaidPayment = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.prepaid_payment,
    );
    this.codConfirmationModalMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.cod_confirmation_modal_message,
    );
    this.recommendedListSlug = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.cart_builder),
    )?.recommendedListSlug;
    this.ShowRecommendedList = JSON.parse(
      RemoteConfig.getValue(
        REMOTE_CONFIG_KEYS.cart_builder_payment_page_recommended_list,
      ),
    );
  }

  componentDidMount() {
    const { setCod } = this.props;
    setCod(true);
    if (this.ShowRecommendedList) {
      this.getCart();
      this.fetchRecommendedList();
    }
  }

  componentWillUnmount() {
    const { setCod } = this.props;
    setCod(false);
  }

  fetchRecommendedList = () => {
    const { fetchData } = this.props;
    const data = {
      method: 'GET',
      slug: this.recommendedListSlug,
    };

    this.setState({
      showRecommendedCartItemsShimmer: true,
    });
    fetchData(data, (success, response = {}) => {
      if (!success || Utility.isBlank(response)) {
        this.setState({
          recommendedCartItemsList: {},
          showRecommendedCartItemsShimmer: false,
        });
        return;
      }
      this.setState({
        recommendedCartItemsList: response,
        showRecommendedCartItemsShimmer: false,
      });
      CartAndPaymentAnalytics.firePageLoadEvent(SCREEN_CONSTANTS.PAYMENT);
    });
  };

  getOrderCampaignStockStatus = (cartItems) => {
    const campaigns = cartItems.map((item) => {
      const {
        product: { order_campaign: { order_campaings_type = '' } = {} } = {},
        variant: {
          order_campaign: {
            order_campaings_type: order_campaign_type_variant = '',
          } = {},
        } = {},
      } = item;
      return Utility.isPresent(order_campaings_type)
        ? order_campaings_type
        : order_campaign_type_variant;
    });
    const isPreOrderCampaignPresent = campaigns.includes('preorder');
    const isBackOrderCampaignPresent = campaigns.includes('backorder');
    if (isPreOrderCampaignPresent && isBackOrderCampaignPresent) {
      return 'preorder_backorder';
    }
    if (isPreOrderCampaignPresent) {
      return 'preorder';
    }
    if (isBackOrderCampaignPresent) {
      return 'backorder';
    }
    return '';
  };

  getCart = () => {
    const { getCartItems } = this.props;

    getCartItems((success, response) => {
      this.setState({
        refreshing: false,
        showRecommendedCartItemsShimmer: false,
      });
      if (!success) {
        this.showToast("Couldn't fetch items in your bag", 500);
      } else {
        this.setState({
          recommendedCartItemsList: response?.recommended_cart_items_list ?? {},
        });
      }
    });
  };

  closeCodAndScroll = () => {
    const {
      updateExpandableComponent,
      pricingData: { total: totalPrice } = {},
    } = this.props;
    AnalyticsUtility.fireSwitchTo({ totalPrice });
    updateExpandableComponent('Cash On Delivery');
  };

  setShowCodConfirmationBottomModal = (isVisible) => {
    this.setState({ showCodConfirmationBottomModal: isVisible });
  };

  openCodConfirmationModalOrdPlaceOrder = () => {
    const { createOrder, authToken } = this.props;

    if (
      Utility.isPresent(authToken) &&
      this.showCodConfirmationModal === 'true'
    ) {
      if (AppConfig.getBooleanValue(Config.HIDE_COD_CONFIRMATION_MODAL)) {
        this.onFirstButtonClick();
      } else {
        AnalyticsManager.logEvent('modal_view', {
          source: 'cod_modal',
        });
        this.setShowCodConfirmationBottomModal(true);
      }
    } else {
      createOrder(this.paymentPayload);
    }
  };

  onFirstButtonClick = () => {
    const { createOrder } = this.props;
    this.setShowCodConfirmationBottomModal(false);
    AnalyticsManager.logEvent('payment_action', {
      action: 'confirm_order',
    });
    createOrder(this.paymentPayload);
  };

  onSecondButtonClick = () => {
    AnalyticsManager.logEvent('payment_action', {
      action: 'switch_to_prepaid',
    });
    this.setShowCodConfirmationBottomModal(false);
  };

  CodExpandMessageWithoutRail = () => {
    const {
      isSpecialOfferApplied,
      pricingData: {
        extra_charges: { cod: { message = '' } = {}, cod = {} } = ({} = {}),
        total_for_cod: totalForCod = '',
        cod_error_message: codErrorMessage = '',
        cod_availability: codAvailability = true,
      } = {},
    } = this.props;
    const buttonBackGroundColor = codAvailability
      ? colors.primaryActionBackgroundColor
      : colors.disabled;
    return (
      <View>
        <View style={PaymentStyles.codPayButtonContainer}>
          <CodExpandedMessage
            isSpecialOfferApplied={isSpecialOfferApplied}
            codErrorMessage={codErrorMessage}
            disabled={!codAvailability}
            extraChargesMessage={message}
          />
          <FoxyShadowButton
            width={isDesktop() ? '100%' : Utility.getScreenWidth() - 34}
            title={'Confirm Order'}
            onPress={this.openCodConfirmationModalOrdPlaceOrder}
            firstColor={colors.linearGradientGreenFirst}
            secondColor={colors.linearGradientGreenSecond}
            disabled={!codAvailability}
            style={isDesktop() && styles.button}
          />
        </View>
      </View>
    );
  };

  codAdditionalChargesMessage = () => {
    const {
      isSpecialOfferApplied,
      pricingData: {
        extra_charges: { cod: { addition_charges_message = '' } = {} } = {},
        cod_charges: codCharges = '',
        cod_error_message: codErrorMessage = '',
        cod_availability: codAvailability = true,
      } = {},
    } = this.props;
    if (
      AppConfig.getBooleanValue(Config.HIDE_COD_ADDITIONAL_CHARGES_TEXT) ||
      codCharges < 1 ||
      Utility.isBlank(addition_charges_message)
    ) {
      return null;
    }
    return (
      <>
        <Text style={styles.orTextStyle}>OR</Text>
        <CodExpandedMessage
          isSpecialOfferApplied={isSpecialOfferApplied}
          codErrorMessage={codErrorMessage}
          disabled={!codAvailability}
          extraChargesMessage={addition_charges_message}
          style={styles.codAdditionalChargesMessage}
        />
      </>
    );
  };

  CodExpandMessageWithRail = () => {
    const {
      isSpecialOfferApplied,
      pricingData: {
        extra_charges: {
          cod: { message = '' } = {},
        } = {},
        cod_charges: codCharges = '',
        cod_error_message: codErrorMessage = '',
        cod_availability: codAvailability = true,
      } = {},
      cartPricing = {},
      cartPricing: { items_total: itemsTotal, discount = 0 } = {},
      navigation,
    } = this.props;
    const {
      recommendedCartItemsList,
      recommendedCartItemsList: { objects: recommendedCartItemObjects = {} },
      showRecommendedCartItemsShimmer,
    } = this.state;
    const { delivery_fee: deliveryFee = '' } = cartPricing;
    const isFreeDelivery =
      deliveryFee === '0.0' || deliveryFee === 0.0 || deliveryFee === 0;

    const showRecommendedList =
      (codCharges > 0 || Utility.isPresent(codErrorMessage)) &&
      Utility.isPresent(recommendedCartItemObjects);

    return (
      <View>
        <View style={PaymentStyles.codPayButtonContainer}>
          <CodExpandedMessage
            isSpecialOfferApplied={isSpecialOfferApplied}
            codErrorMessage={codErrorMessage}
            disabled={!codAvailability}
            extraChargesMessage={message}
          />
          {codAvailability && (
            <FoxyShadowButton
              width={isDesktop() ? '100%' : Utility.getScreenWidth() - 34}
              title={'Confirm Order'}
              onPress={this.openCodConfirmationModalOrdPlaceOrder}
              firstColor={colors.linearGradientGreenFirst}
              secondColor={colors.linearGradientGreenSecond}
              style={isDesktop() && styles.button}
            />
          )}
          <this.codAdditionalChargesMessage />
        </View>
        {/*#We should move RecommendedList out of this component*/}
        {!isDesktop() && (
          <RecommendedList
            itemsTotal={itemsTotal}
            discount={discount}
            isFreeDelivery={isFreeDelivery}
            recommendedCartItems={recommendedCartItemsList}
            showRecommendedList={showRecommendedList}
            listBackGroundColor={colors.white}
            listCartItemStyle={styles.listStyle}
            previousScreen={SCREEN_CONSTANTS.PAYMENT}
            navigation={navigation}
            showRecommendedListShimmer={showRecommendedCartItemsShimmer}
          />
        )}
      </View>
    );
  };

  render() {
    const {
      isSpecialOfferApplied,
      pricingData: {
        extra_charges: { cod: { message = '' } = {} } = ({} = {}),
        total_for_cod: totalForCod = '',
        cod_charges: codCharges = '',
        cod_error_message: codErrorMessage = '',
        total: totalPrice,
        cod_availability: codAvailability = true,
        cod_allowed_beyond_threshold: codAllowedBeyondThreshold,
      } = {},
      cartPrompts,
      renderLoader,
      removeOffer,
      cartPricing = {},
    } = this.props;
    const {
      recommendedCartItemsList = {},
      recommendedCartItemsList: { objects: recommendedCartItemObjects = {} },
    } = this.state;
    const showComponentWithRail =
      this.ShowRecommendedList && codAllowedBeyondThreshold;
    const { digital_cart, delivery_fee: deliveryFee = '' } = cartPricing;
    const { showCodConfirmationBottomModal } = this.state;
    const prepaidOnlyCartPrompts = _.filter(
      cartPrompts,
      (cartPrompt) =>
        cartPrompt.prepaid_only && cartPrompt.status.includes('redeemed'),
    );

    if (Utility.isPresent(prepaidOnlyCartPrompts) && codAvailability) {
      return (
        <>
          <PrepaidOnly
            couponCodes={prepaidOnlyCartPrompts}
            payWithCod={this.openCodConfirmationModalOrdPlaceOrder}
            closeCodAndScroll={this.closeCodAndScroll}
            totalPrice={totalPrice}
            totalForCod={totalForCod}
            codCharges={codCharges}
            isSpecialOfferApplied={isSpecialOfferApplied}
            codErrorMessage={codErrorMessage}
            prepaidPayment={this.prepaidPayment}
            extraChargesMessage={message}
            renderLoader={renderLoader}
            removeOffer={removeOffer}
          />
          <FoxyBottomModal
            isModalVisible={showCodConfirmationBottomModal}
            headingText={`Place order using COD: ${CURRENCY_SYMBOL} ${totalForCod}`}
            subheadingText={this.codConfirmationModalMessage}
            firstButtonText='Confirm Order'
            firstButtonAction={this.onFirstButtonClick}
            onModalClose={this.onSecondButtonClick}
          />
        </>
      );
    }
    return (
      <View dataSet={{ media: ids.container }}>
        {showComponentWithRail ? (
          <this.CodExpandMessageWithRail />
        ) : (
          <this.CodExpandMessageWithoutRail />
        )}
        <FoxyBottomModal
          isModalVisible={showCodConfirmationBottomModal}
          headingText={`Place order using COD: ${CURRENCY_SYMBOL} ${totalForCod}`}
          subheadingText={this.codConfirmationModalMessage}
          firstButtonText='Confirm Order'
          firstButtonAction={this.onFirstButtonClick}
          onModalClose={this.onSecondButtonClick}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pricingData: state.bag.cartPricing,
    cartPrompts: state.bag.cartPrompts,
    cartItems: state.bag.cartItems,
    authToken: state.UserAccountInfo.authToken,
    cartPricing: state.bag.cartPricing,
    deliveryFeeDiscountCouponCode: state.bag.deliveryFeeDiscountCouponCode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setCod,
      removeOffer,
      getCartItems,
      fetchData,
    },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CodPayment),
);
const { ids, styles } = StyleSheet.create({
  orTextStyle: {
    marginTop: 10,
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  listStyle: {
    width: '100%',
    marginTop: -10,
    paddingBottom: 4,
    backgroundColor: colors.white,
  },
  codAdditionalChargesMessage: {
    marginTop: 6,
  },
  container: {
    '@media (min-width: 768px)': {
      width: '100%',
      alignSelf: 'center',
    },
  },
  button: {
    width: '100%',
  },
});
