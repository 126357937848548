import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  StatusBar,
  Appearance,
} from 'react-native';

import Toast from 'react-native-easy-toast';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import withNavigation from '../../utils/WithNavigation';
import colors, { Theme } from '../../theme/Colors';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import UserProfileImage from '../onboarding/UserAttributesSelector/UserProfileImage';
import size from '../../theme/Fonts';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';

class RoutineCheckList extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = props;
    this.onCheckListItemsTap = route.params?.onCheckListItemsTap;
    this.state = {
      systemTheme: 'light',
      listData: route.params?.routineCheckList,
    };
    this.backgroundColor = Theme.light.lightBackgroundColor;
    this.heading = 'Your Routine Checklist';
    this.subHeading =
      'Check off the products from the list that you already have';
    this.updatedIndexes = [];
    this.routineId = route.params?.id || '';
  }

  componentDidMount() {
    this.setState({ systemTheme: Appearance.getColorScheme() });
    this.changeListener = Appearance.addChangeListener(({ colorScheme }) => {
      this.setState({ systemTheme: colorScheme });
    });
  }

  componentWillUnmount() {
    if (Utility.isPresent(this.changeListener)) {
      this.changeListener.remove();
    }
  }

  onItemPress = (index) => {
    if (this.updatedIndexes.includes(index)) {
      this.updatedIndexes = this.updatedIndexes.filter(
        (item) => item !== index,
      );
    } else {
      this.updatedIndexes.push(index);
    }

    const { listData } = this.state;
    const tListData = JSON.parse(JSON.stringify(listData));
    tListData[index].isChecked = !tListData[index].isChecked;
    this.setState({
      listData: tListData,
    });
    const { name, isChecked } = tListData[index];
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.TYPE]: EventParameterValue.ITEM_TYPE.CHECKLIST,
      [EventParameterKey.NAME]: name,
      [EventParameterKey.ENTITY]: 'routine',
      [EventParameterKey.ENTITY_ID]: this.routineId,
      [EventParameterKey.ROUTINES.IS_CHECKED]: isChecked,
    });
  };

  renderCheckListItem = ({ item, index }) => {
    const { name = '', isChecked = false } = item;
    const icon = isChecked
      ? images.routines.checkedIcon
      : images.routines.uncheckedIcon;
    return (
      <TouchableOpacity
        onPress={() => this.onItemPress(index)}
        style={styles.listItemContainer}
      >
        <FastImageView source={icon} style={styles.listItemIcon} />
        <Text style={styles.listItemText}>{name}</Text>
      </TouchableOpacity>
    );
  };

  setToastRef = (ref) => {
    this.toastRef = ref;
  };

  headerWithSelfie = (fontTitleColor, fontSubTitleColor) => {
    return (
      <UserProfileImage
        fontTitleColor={fontTitleColor}
        fontSubTitleColor={fontSubTitleColor}
        heading={this.heading}
        subHeading={this.subHeading}
      />
    );
  };

  headerWithoutSelfie = () => {
    return (
      <View style={styles.headingView}>
        <Text style={styles.headingText}>{this.heading}</Text>
        <Text numberOfLines={2} style={styles.subText}>
          {this.subHeading}
        </Text>
      </View>
    );
  };

  headerView = () => {
    const { systemTheme } = this.state;
    const { isSelfieFlow = false } = this.props;
    console.tron.log('isselfieflow: ', isSelfieFlow, systemTheme);

    const currentTheme = isSelfieFlow ? systemTheme : 'light';

    this.backgroundColor =
      currentTheme === 'light'
        ? Theme.light.lightBackgroundColor
        : Theme.dark.darkBackgroundColor;
    const fontTitleColor =
      currentTheme === 'light' ? Theme.light.textColor : Theme.dark.textColor;
    const fontSubTitleColor =
      currentTheme === 'light'
        ? Theme.light.subTextColor
        : Theme.dark.subTextColor;
    if (isSelfieFlow) {
      return this.headerWithSelfie(fontTitleColor, fontSubTitleColor);
    }
    return this.headerWithoutSelfie();
  };

  onContinueTap = () => {
    console.tron.log('on continue tap');
    this.onCheckListItemsTap(this.updatedIndexes);
    this.goBack();
  };

  goBack = () => {
    const { goBack, navigation } = this.props;
    console.tron.log('this tapped');
    if (Utility.isPresent(goBack)) {
      goBack();
      return;
    }
    navigation.pop();
  };

  recommendationBanner = () => {
    return (
      <View style={styles.bannerContainer}>
        <View style={styles.bannerContentContainer}>
          <Text style={styles.bannerTitle}>Get recommendations</Text>
          <Text style={styles.bannerText}>
            for the remaining products, directly by the expert!
          </Text>
        </View>
        <Image
          style={styles.bannerImage}
          source={images.recommendation_products}
        />
      </View>
    );
  };

  keyExtractor = (item, index) => `${index}`;

  render() {
    const { systemTheme, listData } = this.state;
    const { isSelfieFlow = false } = this.props;

    const currentTheme = isSelfieFlow ? systemTheme : 'light';
    this.backgroundColor =
      currentTheme === 'light'
        ? Theme.light.lightBackgroundColor
        : Theme.dark.darkBackgroundColor;
    const fontTitleColor =
      currentTheme === 'light' ? Theme.light.textColor : Theme.dark.textColor;

    const bottomInsetPadding = isSelfieFlow ? 48 : 6;
    const marginBottom = Utility.isIOS()
      ? Utility.bottomInset + bottomInsetPadding
      : 12;

    return (
      <>
        {Utility.isIOS() && <StatusBar backgroundColor={currentTheme} />}
        <View
          style={[
            styles.container,
            {
              paddingTop: isSelfieFlow ? 0 : Utility.topInset,
              backgroundColor: this.backgroundColor,
            },
          ]}
        >
          <View>
            <this.headerView />
            <TouchableOpacity
              onPress={this.goBack}
              style={[
                styles.imageViewContainer,
                { tintColor: fontTitleColor },
              ]}
            >
              <Image
                source={images.cross}
                style={[styles.imageView, { tintColor: fontTitleColor }]}
              />
            </TouchableOpacity>
          </View>
          <this.recommendationBanner />
          <FlatList
            data={listData}
            scrollEnabled={false}
            keyExtractor={this.keyExtractor}
            renderItem={this.renderCheckListItem}
            style={styles.flatListContainer}
            // contentContainerStyle={styles.contentContainerStyle}
            horizontal={false}
            listKey={this.keyExtractor}
          />
          <FoxyShadowButton
            width={Utility.getScreenWidth() - 16}
            title="Continue"
            backgroundColor={colors.codGray}
            style={{
              marginBottom,
              overflow: 'hidden',
            }}
            borderRadius={16}
            firstColor="#FF585E"
            secondColor="#FE4C83"
            underlayColor="#FF585E"
            onPress={this.onContinueTap}
          />
          <Toast ref={this.setToastRef} />
        </View>
      </>
    );
  }
}

export default withNavigation(RoutineCheckList);

const styles = StyleSheet.create({
  flatListContainer: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: 12,
    marginTop: 16,
  },
  container: {
    height: '100%',
    alignSelf: 'center',
    width: Utility.getScreenWidth(),
    paddingHorizontal: 12,
  },
  contentContainerStyle: {
    justifyContent: 'space-around',
    alignSelf: 'center',
  },
  headingView: {
    flexDirection: 'column',
    paddingHorizontal: 4,
  },
  headingText: {
    color: Theme.light.textColor,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: size.h1,
    marginTop: Utility.isAndroid() ? Utility.topInset + 10 : 8,
  },
  subText: {
    color: Theme.light.subTextColor,
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: size.h3,
    marginTop: 4,
    marginRight: 80,
  },
  imageViewContainer: {
    height: 24,
    width: 24,
    padding: 20,
    position: 'absolute',
    right: 1,
    top: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageView: { height: 12, width: 12 },
  listItemContainer: {
    borderRadius: 4,
    // paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginVertical: 4,
  },
  listItemText: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginLeft: 8,
    flex: 1,
  },
  listItemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    marginLeft: 12,
  },
  bannerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12,
    borderRadius: 8,
    backgroundColor: '#FFF3E4',
    marginTop: 36,
  },
  bannerContentContainer: {
    flexDirection: 'column',
    margin: 12,
    marginRight: 84,
  },
  bannerTitle: {
    color: Theme.light.textColor,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: size.h2,
    marginTop: 8,
  },
  bannerText: {
    color: Theme.light.subTextColor,
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: size.h3,
    marginTop: 4,
  },
  bannerImage: {
    height: 68,
    width: 68,
    position: 'absolute',
    right: 6,
  },
});
