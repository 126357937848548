import React from 'react';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import FoxyEdgeCouponCard from '../../containers/FoxyEdge/FoxyEdgeCouponCard';


export default function CouponDescription(props) {
  const {
    promptData,
    inActive,
    buttonAction = () => {},
    easeIn,
    easeOut,
    setIsActive,
    isValidOnSku,
    navigateToInviteContacts,
    componentWidth,
    previousScreen = '',
  } = props;

  return (
    <FoxyEdgeCouponCard
      onPress={() => {
        if (promptData.group_deal_prompt) {
          navigateToInviteContacts();
          return;
        }
        buttonAction();
        if (!promptData?.selected) {
          if (!inActive) {
            setIsActive(false);
            easeOut();
          } else {
            setIsActive(true);
            easeIn();
          }

          AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
            [EventParameterKey.TYPE]: 'offer_card',
            [EventParameterKey.COUPON_CODE]: promptData.coupon_code,
            [EventParameterKey.SCREEN_NAME]: previousScreen,
          });
        }
      }}
      promptData={promptData}
      disabled={!isValidOnSku}
      componentWidth={componentWidth}
      previousScreen={previousScreen}
    />
  );
}
