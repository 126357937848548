import React from 'react';
import { View, FlatList } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { ScrollView } from 'react-native-gesture-handler';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import Config from '../../libraries/ReactNativeConfig';
import ProductAttributesPill from './ProductAttributesPill';
import colors from '../../theme/Colors';
import AppConfig from '../../config/AppConfig';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop, isPresent, isWeb } from '../../utils/BooleanUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';

const ProductAttributesAndProperties = ({
  brand = {},
  propertyValues = [],
  productCategory = {},
  navigation,
  id,
  sku_id,
  backgroundColor = colors.white,
  addHorizontalPadding = true,
  isLoading,
  reduceWidthForDesktop = false,
}) => {
  const desktopPlaceholder = () => {
    return (
      <View style={styles.desktopShimmerContainer}>
        {_.map([1, 2], (element) => {
          return (
            <View
              style={[
                addHorizontalPadding && { paddingHorizontal: 12 },
                styles.desktopShimmerContainerRow,
              ]}
            >
              {_.map([1, 2, 3], (e) => {
                return (
                  <ShimmerPlaceHolder
                    key={e}
                    colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                    autoRun
                    style={[styles.shimmerPill, { marginTop: 2 }]}
                  />
                );
              })}
            </View>
          );
        })}
      </View>
    );
  };

  if (isLoading) {
    if (isWeb()) return desktopPlaceholder();
    return null;
  }

  const brandArray = AppConfig.getBooleanValue(
    Config.SHOW_BRAND_PILL_ON_PRODUCT_DESCRIPTION,
  )
    ? brand
    : {};
  const brandCategoryAndProperties = _.compact([
    ...[brandArray],
    ...[productCategory].filter((category) => category?.show_category_pill),
    ...propertyValues,
  ]).filter((el) => _.keys(el).length);

  const [numofColumns, setNumofColumns] = React.useState(
    Math.ceil(brandCategoryAndProperties.length / 2),
  );
  const [onLayoutCalled, setOnLayoutCalled] = React.useState(false);

  const placeholderArray = () => {
    return (
      <View style={styles.placeholderContainer} dataSet={{ media: ids.placeholderContainer }}>
        {_.map([1, 2, 3, 4, 5], (e) => {
          return (
            <ShimmerPlaceHolder
              key={e}
              colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
              autoRun
              style={styles.shimmerPill}
            />
          );
        })}
      </View>
    );
  };

  const onLayout = (e) => {
    const {
      nativeEvent: { layout },
    } = e;
    if (!isDesktop() && layout.width < (getScreenWidth() * 2) / 3) {
      setNumofColumns(Math.ceil(brandCategoryAndProperties.length));
    }
    setOnLayoutCalled(true);
  };

  const flatlistStyle = addHorizontalPadding
    ? styles.flatlist
    : styles.flatlistWithoutHMargin;
  const containerStyle = [
    styles.container,
    { backgroundColor },
  ];

  const mediaContainerIds = reduceWidthForDesktop ? ids.reduceWidthDesktop : ids.container;
  return (
    <View style={containerStyle} dataSet={{ media: mediaContainerIds }}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        bounces={false}
      >
        <FlatList
          style={flatlistStyle}
          dataSet={{ media: ids.flatlist }}
          data={brandCategoryAndProperties}
          key={numofColumns}
          numColumns={addHorizontalPadding ? numofColumns : 1}
          keyExtractor={(item, index) =>
            item
              ? typeof item === 'string'
                ? item
                : item.id.toString()
              : index.toString()
          }
          onLayout={onLayout}
          showsHorizontalScrollIndicator={false}
          bounces={false}
          renderItem={({ item }) => {
            if (isPresent(item?.name)) {
              return (
                <ProductAttributesPill
                  attributeName={item?.name}
                  slug={item?.slug}
                  navigation={navigation}
                  type={item?.type}
                  id={id}
                  sku_id={sku_id}
                  category_name={productCategory?.name}
                />
              );
            }
            return (
              <ProductAttributesPill
                attributeName={item}
                slug={productCategory?.slug}
                navigation={navigation}
                type={item?.type}
                id={id}
                sku_id={sku_id}
                category_name={productCategory?.name}
              />
            );
          }}
        />
      </ScrollView>
      <View>{!onLayoutCalled && placeholderArray()}</View>
    </View>
  );
};

export default React.memo(ProductAttributesAndProperties);

const { ids, styles } = StyleSheet.create({
  container: {
    width: getScreenWidth(),
    backgroundColor: colors.white,
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH,
    },
  },
  reduceWidthDesktop: {
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH - 20,
    },
  },
  flatlist: {
    paddingBottom: 8,
    paddingTop: 8,
    marginHorizontal: 12,
    '@media (min-width: 768px)': {
      width: '100%',
    },
  },
  flatlistWithoutHMargin: {
    display: 'flex',
    paddingBottom: 8,
    paddingTop: 8,
  },
  placeholderContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'wrap',
    backgroundColor: colors.white,
    position: 'absolute',
    top: -90,
    paddingHorizontal: 12,
    paddingBottom: 12,
    width: getScreenWidth(),
    zIndex: 100,
    '@media (min-width: 768px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH,
    },
  },
  shimmerPill: {
    height: 24,
    width: 86,
    borderRadius: 12,
    marginRight: 8,
    marginTop: 12,
  },
  desktopShimmerContainerRow: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
  desktopShimmerContainer: {
    paddingVertical: 4,
    backgroundColor: colors.white,
  },
});
