// Dependencies
import React, { Component } from 'react';
import {
  View,
  FlatList,
  Text,
  TouchableOpacity,
  LayoutAnimation,
  Platform,
  UIManager,
  Image,
  StyleSheet,
} from 'react-native';
import { capitalize, _, debounce } from 'lodash';
import colors, { colorCode } from '../../../theme/Colors';
import images from '../../../theme/Images';
import { LAYOUT, THEME, ORIENTATION } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
// Components
import Media from '../../../containers/media/Media';
import Salon from '../../../containers/salon/Salon';
import Collection from '../../../containers/collection/Collection';
import listingStyles from './styles';
import LAYOUT_CONFIG from '../../../config/LayoutConstants/LayoutConfig';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import SearchOffer from '../../../containers/offer/SearchOffer';
import { DefaultSizeProductList } from '../../Product';
import { DefaultSizeVariantList } from '../../variants';
import DefaultSizeBrandList from '../../brand/DefaultSizeBrandList';
import {
  SmallProductList,
  LargeProductList,
} from '../../Product/OtherProducts';
import { SmallBrandList, LargeBrandList } from '../../brand/OtherBrands';
import { SmallVariantList, LargeVariantList } from '../../variants/OtherSkus';
import { DefaultSizeVideoList } from '../../media';
import { SmallVideoList, LargeVideoList } from '../../media/OtherMediaCards';
import { DefaultSizeArtistList } from '../../Artist';
import {
  SmallArtistList,
  LargeArtistList,
} from '../../Artist/OtherArtistCards';
import Ingredient from '../../ingredient/Ingredient';
import { connect } from 'react-redux';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';

class ExpandableList extends Component {
  static Components = {
    media: Media,
    salon: Salon,
    list: Collection,
    video: Media,
    offer: SearchOffer,
    DefaultSizeProductList,
    DefaultSizeVariantList,
    LargeProductList,
    SmallProductList,
    LargeVariantList,
    SmallVariantList,
    DefaultSizeVideoList,
    SmallVideoList,
    LargeVideoList,
    DefaultSizeArtistList,
    SmallArtistList,
    LargeArtistList,
    DefaultSizeBrandList,
    SmallBrandList,
    LargeBrandList,
    ingredient: Ingredient,
  };

  constructor(props) {
    super(props);
    this.viewedIds = [];
    this.onFlatListEndReached = debounce(this.onEndReached, 500);
    this.state = { loadMore: false };
    if (
      Platform.OS === 'android' &&
      UIManager.setLayoutAnimationEnabledExperimental
    ) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
    const { previousScreen, content } = this.props;
    if (previousScreen === 'payoff' && content === 'ingredient') {
      const {
        facialAnalysisMaster: { master_attribute_list = [] },
      } = this.props;
      const attributes = Utility.getCurrentElementAttributes(
        master_attribute_list,
      );
      this.ingredientAttributesForPayoff =
        Utility.getAllowedValueIdsForAttributes(attributes);
    }
  }

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }

    const {
      id,
      size,
      content,
      navigation,
      theme,
      onPress,
      onboarding,
      onCardClick,
      previousScreen,
      search,
      searchQuery,
      elementItemCounts,
      toggleCartVisibility,
      listName,
      listData,
      maxFreeItemsToSelect,
      hideAddToCart,
      campaignId,
      onItemAddToCartFromCollab,
      fromPersonalisedCard,
      showToast,
      listContent,
    } = this.props;

    const listIndex = this.props.index;

    let ContainerComponent = ExpandableList.Components[content];
    if (previousScreen === 'paginatedList') {
      if (item.type === 'product') {
        ContainerComponent = ExpandableList.Components.DefaultSizeProductList;
      } else if (item.type === 'variant') {
        ContainerComponent = ExpandableList.Components.DefaultSizeVariantList;
      } else if (item.type === 'video') {
        ContainerComponent = ExpandableList.Components.DefaultSizeVideoList;
      } else if (item.type === 'artist') {
        ContainerComponent = ExpandableList.Components.DefaultSizeArtistList;
      } else {
        ContainerComponent = ExpandableList.Components[item.type];
      }
    }
    if (
      content === 'product' ||
      content === 'sku' ||
      content === 'media' ||
      content === 'artist'
    ) {
      ContainerComponent =
        ExpandableList.Components[
          `${upperFirst(size)}${capitalize(item.type)}List`
        ];
    }

    if (content === 'brand') {
      ContainerComponent =
        ExpandableList.Components[
          `${upperFirst(size)}${capitalize(item.type)}List`
        ];
    }

    if (item === undefined || ContainerComponent === undefined) {
      return null;
    }

    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <ContainerComponent
          listName={listName}
          listContent={listContent}
          size={size}
          layout={fromPersonalisedCard ? LAYOUT.PERSONALISEDRAIL : LAYOUT.LIST}
          id={item.id}
          itemData={item}
          type={item.type}
          navigation={navigation}
          theme={theme}
          listId={id}
          index={index}
          onPress={onPress}
          iconName={this.props.iconName}
          showCustomIcon={this.props.showCustomIcon}
          showColorComponent={this.props.showColorComponent}
          addedProducts={this.props.addedProducts}
          onboarding={onboarding}
          onCardClick={onCardClick}
          previousScreen={previousScreen}
          listIndex={listIndex}
          search={search}
          searchQuery={searchQuery}
          elementItemCounts={elementItemCounts}
          toggleCartVisibility={toggleCartVisibility}
          listData={listData}
          refreshOfferStrip={this.props.refreshOfferStrip}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          hideAddToCart={hideAddToCart}
          campaignId={campaignId}
          onItemAddToCartFromCollab={onItemAddToCartFromCollab}
          fromPersonalisedCard={fromPersonalisedCard ? true : false}
          showToast={showToast}
          ingredientAttributesForPayoff={this.ingredientAttributesForPayoff}
        />
      </ErrorBoundaryComponent>
    );
  };

  onEndReached = () => {
    const { endReached = () => {} } = this.props;
    endReached();
  };

  keyExtractor = (item, index) => `${item.schema}_${item.id}_${index}list`;

  renderItem = ({ item, index }) => this.getComponent(item, index);

  toggleLoadMore = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this.setState((prevState) => ({
      loadMore: !prevState.loadMore,
    }));
  };

  render() {
    const { feed = false, previousScreen } = this.props;
    const { loadMore } = this.state;

    const itemData = this.props.item.filter((item) => !!item);
    this.displayCount = feed
      ? _.max([LAYOUT_CONFIG.minListCount, this.props.displayCount || 0])
      : this.props.displayCount || 0;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    const styles = listingStyles;
    const margin =
      previousScreen === SCREEN_CONSTANTS.MORE_PAGE ||
      previousScreen === SCREEN_CONSTANTS.SEARCH
        ? 0
        : 4;

    const initial = itemData.slice(0, 3);
    const moreItems = itemData.slice(3);
    return (
      <View style={[styles.flatlistContainer, { marginVertical: 8 }]}>
        <FlatList
          data={initial}
          // data={itemData}
          scrollEnabled
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          initialNumToRender={20}
          listKey='3'
        />

        {loadMore && moreItems.length > 0 && (
          <FlatList
            data={moreItems}
            // data={itemData}
            scrollEnabled
            renderItem={this.renderItem}
            keyExtractor={(_, index) => index.toString()}
            initialNumToRender={20}
            listKey='4'
          />
        )}

        {!loadMore && (
          <TouchableOpacity
            onPress={this.toggleLoadMore}
            hitSlop={Utility.getHitSlop()}
            style={{
              flexDirection: 'row',
              alignSelf: 'flex-end',
              alignItems: 'center',
              marginRight: 12,
              marginTop: 8,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontFamily: 'Roboto-Medium',
                color: colors.cta.lightBlue,
              }}
            >
              {!loadMore && 'Load More'}
            </Text>
            <Image
              style={{
                width: 12,
                height: 12,
                resizeMode: 'contain',
                tintColor: colors.cta.lightBlue,
                marginLeft: 4,
              }}
              source={images.chevronDown}
            />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  facialAnalysisMaster: state.facialAnalysis,
});

export default connect(mapStateToProps, null)(ExpandableList);
