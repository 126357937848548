import React, { PureComponent } from 'react';
import { View, StyleSheet, FlatList, Image, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';

class CongratulationsCard extends PureComponent {
  constructor(props) {
    super(props);
    this.color = ['#FFD7B2', '#FFE3C9'];
  }

  renderDayCard = ({ item }) => {
    let image = images.routines.gold_badge;
    let textColor = colors.gold;
    if (item.myRank === 2) {
      image = images.routines.silver_badge;
      textColor = colors.silverBlue;
    } else if (item.myRank === 3) {
      image = images.routines.bronze_badge;
      textColor = colors.bronze;
    }
    return (
      <View style={styles.listCardContainer}>
        <FastImageView source={image} style={styles.cardImage} />
        <Text style={[styles.cardText, { color: textColor }]}>
          Day {item.day}
        </Text>
      </View>
    );
  };

  render() {
    const { myDailyRanks = [] } = this.props;
    if (Utility.isBlank(myDailyRanks)) return null;
    return (
      <View style={styles.container}>
        <LinearGradient
          colors={this.color}
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          style={styles.progressGradient}
        />
        <View style={styles.contentContainer}>
          <Text style={styles.title}>Congratulations!</Text>
          <Text style={styles.subtitle}>
            You came among the top 3 on these days:
          </Text>
          <FlatList
            data={myDailyRanks}
            style={styles.listContainer}
            contentContainerStyle={styles.contentContainerStyle}
            horizontal
            renderItem={this.renderDayCard}
            showsHorizontalScrollIndicator={false}
          />
        </View>
      </View>
    );
  }
}

export default CongratulationsCard;

const styles = StyleSheet.create({
  container: {
    marginVertical: 16,
    height: 186,
    marginHorizontal: 16,
    borderRadius: 12,
  },
  contentContainer: {
    top: 0,
    position: 'absolute',
    flexDirection: 'column',
    width: '100%',
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  listContainer: {
    height: 96,
    marginHorizontal: 0,
    marginTop: 14,
  },
  listCardContainer: {
    height: 96,
    width: 68,
    borderRadius: 20,
    flexDirection: 'column',
    alignItems: 'center',
    marginHorizontal: 10,
    backgroundColor: colors.white,
  },
  cardImage: {
    height: 40,
    width: 40,
    marginTop: 12,
  },
  cardText: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    flexShrink: 1,
    position: 'relative',
    textAlign: 'center',
    marginHorizontal: 4,
    marginTop: 10,
  },
  title: {
    color: '#FB3A82',
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    position: 'relative',
    marginHorizontal: 24,
    marginTop: 16,
  },
  subtitle: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto',
    flexShrink: 1,
    position: 'relative',
    marginHorizontal: 24,
    marginTop: 8,
  },
  progressGradient: {
    top: 0,
    height: '100%',
    width: '100%',
    alignSelf: 'center',
    borderRadius: 12,
  },
});
