import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import colors from '../../../../theme/Colors';

export default function ActionButton({
  style = {},
  onPress = () => {},
  title = '',
  opacity = 1,
  color = colors.enabled,
}) {
  return (
    <TouchableOpacity
      style={[styles.actionButtonsContainer, style]}
      onPress={onPress}
    >
      <Text
        style={[styles.actionButtonText, { color, opacity }]}
      >
        {title.toUpperCase()}
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  actionButtonsContainer: {
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtonText: {
    fontSize: 16,
    width: 256,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
});

