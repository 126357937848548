import React, { PureComponent } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import StyleSheet from 'react-native-media-query';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';

import ProductDescription from './ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility, { findIngredients } from '../../utils/Utility';
import AddToCart from './AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';
import { LAYOUT, CURRENCY_SYMBOL } from '../../config/Constants';
import colors, { colorCode } from '../../theme/Colors';
import ProductColorVariants from '../variants/ProductColorVariants';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import ProductSizeVariants from '../variants/ProductSizeVariants';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import MoreOptions from './MoreOptions';
import ReviewProduct from './ReviewProduct';
import FoxyMatchForProductCard from './FoxyMatchForProductCard';
import RoundedButton from '../layout/buttons/RoundedButton';
import AnimatedLikeButton from '../shared/LikeButton';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { ProductDetailStyles } from './styles';
import ProductRatingAndMoreInfo from './ProductRatingAndMoreInfo';
import InviteToClaim from './InviteToClaim';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import size from '../../theme/Fonts';
import CollectionCardStyle from '../Collection/styles';
import ContactsUtility from '../../utils/ContactsUtility';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import images from '../../theme/Images';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isDesktop } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class ProductPersonalizedHorizontal extends PureComponent {
  constants = {
    INVITE_TO_CLAIM: 'invite_to_claim',
    WISHLIST_ADD_TO_BAG: 'wishlist_add_to_bag',
    PRODUCT_DETAIL_MODAL: 'product_detail_modal',
  };

  constructor(props) {
    super(props);
    this.state = {
      productPositiveIngredients: {},
      productNegativeIngredients: {},
    };
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    const { route } = props;
    this.previousScreen = route.params?.previousScreen;
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
    const { INVITE_TO_CLAIM, WISHLIST_ADD_TO_BAG } = this.constants;
    this.primaryAction = WISHLIST_ADD_TO_BAG;
    const {
      listData: { additional_data = {} } = {},
      itemData: { sku: { group_buying_price = '' } = {} } = {},
      showProductScanFooter,
    } = props;
    const primaryAction = additional_data?.item_action?.primary;
    if (
      primaryAction === INVITE_TO_CLAIM &&
      Utility.isPresent(group_buying_price)
    ) {
      this.primaryAction = INVITE_TO_CLAIM;
    }

    if (showProductScanFooter) {
      this.primaryAction = this.constants.PRODUCT_DETAIL_MODAL;
    }
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  componentDidMount() {
    this.findIngredients();
  }

  findIngredients = () => {
    const { itemData } = this.props;
    const {
      facialAnalysis: { my_attributes_values: myAttributesValues = [] } = {},
    } = this.props;
    const {
      star_ingredients: heroIngredients = [],
      foxy_match = [],
      product_category: {
        category_user_attributes: { principal = [] } = {},
      } = {},
    } = itemData;
    const positiveIngredients = {};
    const negativeIngredients = {};
    const { newGood, newBad } = findIngredients(
      heroIngredients,
      myAttributesValues,
    );
    let positiveIngredientCount = 0;
    let negativeIngredientCount = 0;

    _.forEach(newGood, (positive) => {
      if (Utility.isPresent(principal[`${positive.indication}`])) {
        positiveIngredientCount += 1;
        positiveIngredients[`${principal[`${positive.indication}`]}`] = [
          ...(Utility.isPresent(
            positiveIngredients[`${principal[`${positive.indication}`]}`],
          )
            ? positiveIngredients[`${principal[`${positive.indication}`]}`]
            : []),
          {
            name: `${positive.name}`,
            slug: `${positive.slug}`,
            id: `${positive.id}`,
          },
        ];
      }
    });

    _.forEach(newBad, (negative) => {
      if (Utility.isPresent(principal[`${negative.contraindication}`])) {
        negativeIngredientCount += 1;
        negativeIngredients[`${principal[`${negative.contraindication}`]}`] = [
          ...(Utility.isPresent(
            negativeIngredients[`${principal[`${negative.contraindication}`]}`],
          )
            ? negativeIngredients[
                `${principal[`${negative.contraindication}`]}`
              ]
            : []),
          {
            name: `${negative.name}`,
            slug: `${negative.slug}`,
            id: `${negative.id}`,
          },
        ];
      }
    });

    // AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_VIEW, {
    //   [EventParameterKey.SKU_ID]: productData?.sku_id,
    //   [EventParameterKey.PRODUCT_ID]: productData?.id,
    //   [EventParameterKey.POSITIVE_INGREDIENT_COUNT]: positiveIngredientCount,
    //   [EventParameterKey.NEGATIVE_INGREDIENT_COUNT]: negativeIngredientCount,
    //   [EventParameterKey.HERO_INGREDIENTS]: heroIngredients.length,
    // });

    this.setState({
      productPositiveIngredients: positiveIngredients,
      productNegativeIngredients: negativeIngredients,
    });
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug, variants_details = {} } = {},
      toggleCartVisibility,
    } = this.props;

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        id,
        slug,
        display: LAYOUT.SCREEN,
        itemData,
        toggleCartVisibility,
        variants_details,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  onPress = () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData,
      listId,
      listData = {},
      layout,
      previousScreen,
      listName,
      listContent,
      toggleUnavailableProductsModal,
      extraEventParameters = {},
    } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }
    const { INVITE_TO_CLAIM } = this.constants;

    let id = itemData.type === 'product' ? itemData.sku_id : itemData.id;
    const listTag =
      this.primaryAction === INVITE_TO_CLAIM
        ? EventParameterValue.GROUP_DEALS_REFERRALS.GROUP_DEAL
        : '';
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listData?.name,
      listIndex,
      listTag,
      !itemData.outOfStock,
      listData?.content,
      itemData.id,
      itemData.slug,
      listData?.slug,
      extraEventParameters,
      itemData,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
      prompts,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      listData,
      index,
      showDefaultAddToCartButton = false,
      extraEventParameters = {},
    } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    const addToCartButtonLayout = showDefaultAddToCartButton
      ? PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL
      : PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CAR;

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={showDefaultAddToCartButton ? undefined : LAYOUT.MATCHED_PRODUCT}
        addToCartLayout={addToCartButtonLayout}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        variantModal={true}
        singleStockedVariantSku={singleStockedVariantSku}
        prompts={prompts}
        smallButton={!showDefaultAddToCartButton}
        style={isDesktop() ? styles.desktopAddToCartStyle : styles.addToCartStyle}
        listData={listData}
        extraEventParameters={extraEventParameters}
      />
    );
  };

  productShadesCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Shade';

  productSizeCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Size';

  conditionalVariants = compose(
    withEither(this.productShadesCondition, ProductColorVariants),
    withEither(this.productSizeCondition, ProductSizeVariants),
  )(ProductOtherVariants);

  productVariants = (props) => {
    const {
      itemData: { image_url: imageUrl, variants_details: variantDetails = {} },
    } = this.props;
    const { containerStyle } = props;

    const {
      variants_count: variantsCount = 0,
      variants_shade_images: variantShadeImages = [],
      variants_property_name: variantPropertyName = '',
    } = variantDetails;

    return (
      <this.conditionalVariants
        variantImage={imageUrl}
        variantsCount={variantsCount}
        variantShadesImages={variantShadeImages}
        variantsPropertyName={variantPropertyName}
        containerStyle={containerStyle}
      />
    );
  };

  renderPills = ({
    star_ingredients = [],
    matched_ingredients = [],
    ingredient_for_product,
  }) => {
    if (Utility.isBlank(star_ingredients)) {
      return null;
    }

    const green_array = [];
    const attribute_array = [];
    const neutral_array = [];

    Object.entries(ingredient_for_product).map(([key, value]) => {
      if (value.myIndications?.length > 0 || value.mySuper?.length > 0) {
        green_array.push(key);
        if (value.myIndications?.length > 0) {
          value.myIndications.map((e) => {
            if (!attribute_array.includes(value.mapping[e])) {
              attribute_array.push(value.mapping[e]);
            }
          });
        }
        if (value.mySuper?.length > 0) {
          value.mySuper.map((e) => {
            if (!attribute_array.includes(value.mapping[e])) {
              attribute_array.push(value.mapping[e]);
            }
          });
        }
      } else {
        neutral_array.push(key);
      }
    });

    const newArray = [...attribute_array, ...green_array, ...neutral_array];

    return (
      <View style={styles.pillContainer}>
        {newArray.map((pill, index) => {
          if (index === 5 && newArray.length - 5 !== 1) {
            return (
              <View
                style={[
                  styles.ingredientPill,
                  { backgroundColor: '#979BAA33' },
                ]}
              >
                <Text
                  style={[
                    styles.pillText,
                    { color: '#979BAA' },
                  ]}
                >
                  {`+ ${newArray.length - 5} more`}
                </Text>
              </View>
            );
          }
          if (index > 5) {
            return null;
          }
          return (
            <View
              style={[
                styles.ingredientPill,
                {
                  backgroundColor:
                  green_array.includes(pill) || attribute_array.includes(pill)
                    ? colors.lightBackgroundGreen
                    : '#979BAA33',
                }
              ]}
            >
              <Text
                style={[
                  styles.pillText,
                  {
                    color:
                    green_array.includes(pill) || attribute_array.includes(pill)
                      ? '#01b460'
                      : '#979BAA',
                  },
                ]}
              >
                {pill}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };

  likeButton = () => {
    const {
      itemData,
      extraEventParameters = {},
      previousScreen = '',
    } = this.props;
    return (
      <AnimatedLikeButton
        ref={(ref) => (this.likeButtonRef = ref)}
        id={itemData.id}
        type={itemData.type}
        slug={itemData.slug}
        mrp={itemData.mrp}
        skuId={Utility.getSkuId(itemData)}
        layout={LAYOUT.LIST}
        content={itemData.content}
        onLike={this.onLike}
        itemName={itemData.name}
        screenName={previousScreen || 'payoff'}
        matchedProduct
        extraEventParameters={extraEventParameters}
      />
    );
  };

  productHeroDescription = (props) => {
    const { heroDescription } = props;
    if (Utility.isBlank(heroDescription)) return null;

    return (
      <View style={ProductDetailStyles.heroDescriptionBoxContainer}>
        <Text
          style={ProductDetailStyles.productDescriptionHeroStyle}
          numberOfLines={10}
        >
          {heroDescription}
        </Text>
      </View>
    );
  };

  renderGroupDealButtonAndPrice = () => {
    const {
      itemData = {},
      itemData: {
        sku: { id: skuID = '' } = {},
        mrp,
        has_variants: hasVariants,
        single_stocked_variant: singleStockedVariantSku,
      } = {},
      navigation,
      listData: {
        additional_data: {
          item_action: { primary: primaryAction = '' } = {},
        } = {},
      } = {},
      activeGroupDeals = [],
      imageUrl,
    } = this.props;

    const groupBuyingPrice = itemData?.sku?.group_buying_price;

    const enableInviteCenter = activeGroupDeals?.includes(skuID);
    if (Utility.isBlank(groupBuyingPrice)) return null;
    const percentDiscount = ContactsUtility.calculateDiscountPercentage(
      groupBuyingPrice,
      mrp,
    );
    return (
      <View>
        <Text style={styles.groupDealText}>
          {`🔒 ${CURRENCY_SYMBOL}${groupBuyingPrice} (${percentDiscount}% off) for your gang today`}
        </Text>
        <InviteToClaim
          navigation={navigation}
          itemData={itemData}
          enableInviteCenter={enableInviteCenter}
          selfieImageUrl={imageUrl}
        />
      </View>
    );
  };

  likePress = () => {
    if (Utility.isBlank(this.likeButtonRef)) return;
    this.likeButtonRef.likeProduct();
  };

  showPricingInfoText = () => {
    const { itemData: { final_sp, mrp, additional_discount } = {} } =
      this.props;
    return (
      <View style={styles.pricingContainer}>
        <View style={styles.pricingTextWrapper}>
          <Text style={styles.finalPricingText}>{`₹${final_sp}`}</Text>
          <Text style={styles.finalPricingText}>{`${CURRENCY_SYMBOL}${final_sp}`}</Text>
          <Text style={styles.discountText}>{`${CURRENCY_SYMBOL}${mrp}`}</Text>
        </View>
        <Text
          style={styles.discountPercentageText}
        >{`${additional_discount}`}</Text>
      </View>
    );
  };

  renderWishlistAndAddToBagButtons = () => {
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      itemData: {
        id,
        sku_id: skuId,
        prompts,
        has_variants: hasVariants,
        outOfStock,
        priority,
        single_stocked_variant: singleStockedVariantSku,
      },
      extraEventParameters = {},
    } = this.props;
    const viewStyle = styles.wishlistButtonsContainer;

    return (
      <View style={viewStyle}>
        <RoundedButton
          buttonText={'Wishlist'}
          buttonTextColor={colors.black}
          buttonColor={colors.white}
          buttonAction={this.likePress}
          style={styles.wishlistButtonStyle}
          dataSet={{ media: ids.wishlistButtonStyle }}
          buttonTextStyle={styles.wishlistButtonTextStyle}
          renderProp={this.likeButton}
          extraEventParameters={extraEventParameters}
        />

        <this.addToCart
          skuId={skuId}
          orientation={orientation}
          toggleCartState={this.toggleCartState}
          layout={LAYOUT.LIST}
          addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
          hideAddToCart={hideAddToCart}
          id={id}
          itemData={itemData}
          hasVariants={hasVariants}
          showCart={this.showCart}
          priority={priority}
          outOfStock={outOfStock}
          previousScreen={previousScreen}
          singleStockedVariantSku={singleStockedVariantSku}
          prompts={prompts}
          smallButton
          extraEventParameters={extraEventParameters}
        />
      </View>
    );
  };

  onPressAddToBag = () => {
    const { addedToBag, onAddToBag } = this.props;

    onAddToBag(addedToBag);
  };

  renderProductModalFooter = ({ onAddToBag, addedToBag }) => {
    const extraProps = !addedToBag ? {} : {};

    const buttonLabel = !addedToBag ? 'Add to Bag' : 'View Bag';

    return (
      <View style={styles.productModalFooterContainer}>
        <this.showPricingInfoText />
        <FoxyShadowButton
          width={150}
          showActionIcon
          onPress={this.onPressAddToBag}
          actionIcon={images.usnScan.addTobagWhite}
          title={buttonLabel}
          {...extraProps}
        />
      </View>
    );
  };

  renderFooter = () => {
    const { onAddToBag, addedToBag } = this.props;
    const { INVITE_TO_CLAIM, WISHLIST_ADD_TO_BAG, PRODUCT_DETAIL_MODAL } =
      this.constants;
    switch (this.primaryAction) {
      case INVITE_TO_CLAIM:
        return <this.renderGroupDealButtonAndPrice />;
      case WISHLIST_ADD_TO_BAG:
        return <this.renderWishlistAndAddToBagButtons />;
      case PRODUCT_DETAIL_MODAL:
        return (
          <this.renderProductModalFooter
            onAddToBag={onAddToBag}
            addedToBag={addedToBag}
          />
        );
      default:
        return <this.renderWishlistAndAddToBagButtons />;
    }
  };

  render() {
    if (Utility.isBlank(this.primaryAction)) {
      return null;
    }
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      itemData: {
        offer_value,
        final_sp,
        additional_discount,
        type,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        brand: { name: brandName } = {},
        sku_id: skuId,
        prompts,
        has_variants: hasVariants,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
        hero_description,
        product_name,
        product_category: {
          category_user_attributes: { principal = {} } = {},
        } = {},
        star_ingredients,
        sku: { group_buying_price = '' } = {},
      } = {},
      navigation,
      facialAnalysis,
      noOfItems,
      facialAnalysis: { my_attributes_values = [] } = {},
      todayDeals: { skus = {} } = {},
      containerWidth = 58,
      excludePricing = true,
      onlyPricing = true,
      showRating = true,
      listData = {},
      entityName = '',
    } = this.props;

    const groupBuyingPrice = itemData?.sku?.group_buying_price;

    const { productPositiveIngredients = {}, productNegativeIngredients = {} } =
      this.state;

    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const display_price = final_sp;

    const ingredient_for_product = Utility.findIndicationsAndCounterIndications(
      star_ingredients,
      my_attributes_values,
      principal,
    );

    const disableTodayDealsMessage = Utility.isPresent(groupBuyingPrice);
    const marginWidth = getScreenWidth() / (isDesktop() ? 2 : 1);
    return (
      <View>
        <ScaleAnimate
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
        >
          <Card
            style={[
              styles.cardContainer,
              {
                width:
                noOfItems === 1
                  ? marginWidth - 24
                  : marginWidth - containerWidth,
              },
            ]}
          >
            <View style={styles.imageContainer}>
              <ProductImageAndRating
                layout={LAYOUT.PERSONALIZED_HORIZONTAL}
                rating={rating}
                images={images}
                webp_images={webp_images}
                image_webp_url={image_webp_url}
                imageUrl={imageUrl}
                id={id}
                name={name}
                previousScreen={previousScreen}
                matchedProduct
              />
            </View>
            <View>
              <ProductDescription
                itemData={itemData}
                name={Utility.isPresent(product_name) ? product_name : name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={brandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.MATCHED_PRODUCT}
                id={id}
                previousScreen={previousScreen}
                prompts={prompts}
                matchedProduct
                numberOfLines={2}
                excludePricing={excludePricing}
                disableTodayDealsOrPromptMessage={disableTodayDealsMessage}
              />

              {Utility.isPresent(hero_description) && (
                <this.productHeroDescription
                  heroDescription={hero_description}
                />
              )}

              <View style={{ marginLeft: 8, paddingVertical: 8 }}>
                <ProductRatingAndMoreInfo
                  rating={itemData?.rating}
                  ratingSize={14}
                  marginBetweenRatingIcon={1.5}
                  disableRatingText
                  visible={showRating}
                  listData={listData}
                  entityName={entityName}
                  previousScreen={previousScreen}
                />
              </View>

              <this.renderPills
                matched_ingredients={itemData?.matched_ingredients}
                star_ingredients={itemData?.star_ingredients}
                ingredient_for_product={ingredient_for_product}
              />
              <FoxyMatchForProductCard
                itemData={itemData}
                facialAnalysis={facialAnalysis}
                navigation={navigation}
                productPositiveIngredients={productPositiveIngredients}
                productNegativeIngredients={productNegativeIngredients}
                scrollToRatings={this.scrollToRatings}
                isSelfiePresent={Utility.isPresent(imageUrl)}
                renderOnboardingForFoxyMatch={this.renderOnboardingForFoxyMatch}
                productPage={this.productPageData}
                matchedProduct
              />
            </View>
            <View
              style={{
                // paddingTop: 8,
                paddingBottom: 8,
              }}
            >
              <ProductDescription
                itemData={itemData}
                name={name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={brandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.MATCHED_PRODUCT}
                id={id}
                previousScreen={previousScreen}
                onlyPricing={onlyPricing}
              />
              <this.renderFooter />
            </View>
          </Card>
        </ScaleAnimate>
      </View>
    );
  }
}

const { ids, styles } = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 12,
    width: Utility.getScreenWidth() - 58,
    height: 580,
    alignSelf: 'center',
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2 - 58,
    },
  },
  imageContainer: {
    flex: 1,
    marginBottom: 12,
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 78,
    right: 10,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 80,
    backgroundColor: colors.white,
  },
  innerContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.background,
  },
  container: { backgroundColor: colors.background, marginHorizontal: 12 },
  pillContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    // height: 31,
    marginHorizontal: 8,
    // marginBottom: 4,
    flexWrap: 'wrap',
    marginTop: 4,
    overflow: 'hidden',
  },
  ingredientPill: {
    // paddingVertical: 5,
    paddingHorizontal: 9,
    borderColor: colors.linerGradientGreenFirst,
    backgroundColor: colors.lightBackgroundGreen,
    borderWidth: 0,
    borderRadius: 12,
    marginRight: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ingredientPillDisabled: {
    // paddingVertical: 5,
    paddingHorizontal: 9,
    borderColor: colors.linerGradientGreenFirst,
    backgroundColor: colors.lightGrey,
    borderWidth: 0,
    borderRadius: 12,
    marginRight: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pillText: {
    fontSize: size.h4,
    color: colors.green,
    fontFamily: 'Roboto-Regular',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  pillTextDisabled: {
    fontSize: size.h4,
    color: colors.grey,
    fontFamily: 'Roboto-Regular',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  groupDealText: {
    fontSize: size.h4,
    color: colorCode.ratings.three,
    marginLeft: 8,
    marginVertical: 8,
  },
  gotoInviteCenterButton: {
    borderRadius: 32,
    marginLeft: 16,
    height: 32,
    width: 207,
    backgroundColor: colors.black,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 2.5,
  },
  wishlistButtonStyle: {
    width: (Utility.getScreenWidth() - 94) / 2,
    height: 36,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colors.black,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
    '@media (min-width: 768px)': {
      width: (getScreenWidth() / 2 - 94) / 2,
    },
  },
  wishlistButtonTextStyle: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
    marginLeft: 8,
  },
  wishlistButtonsContainer: {
    flexDirection: 'row',
    marginTop: 8,
    justifyContent: 'space-around',
    marginBottom: 8,
  },
  wishlistButtonsContainerRightAligned: {
    flexDirection: 'row',
    marginTop: 8,
    justifyContent: 'flex-end',
    marginBottom: 8,
    borderTopWidth: 0.2,
    borderTopColor: colors.cyan,
  },

  pricingContainer: {
    justifyContent: 'center',
  },

  pricingTextWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  finalPricingText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    color: colors.black,
  },

  discountText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h2,
    color: colors.subtitle,
    marginLeft: 5,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },

  discountPercentageText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h2,
    color: colors.foxyPink,
  },

  productModalFooterContainer: {
    height: 60,
    paddingLeft: 12,
    paddingRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  footerButtonContainer: {
    width: 150,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#01B460',
  },

  textStyle: {
    fontFamily: 'Roboto-Medium',
    color: '#01B460',
  },
  addToCartStyle: {
    width: (Utility.getScreenWidth() - 94) / 2,
  },
  desktopAddToCartStyle: {
    width: (getScreenWidth() / 2 - 94) / 2,
  },
});
const mapStateToProps = (state) => ({
  user_engagement: state.UserAccountInfo.user_engagement,
  favourites: state.UserAccountInfo.favourites,
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  couponCodes:
    state.bag.cartPricing && state.bag.cartPricing.coupon_codes
      ? state.bag.cartPricing.coupon_codes
      : [],
  imageUrl: state.UserAccountInfo.profile.selfie_image_url,
  authToken: state.UserAccountInfo.authToken,
  activeGroupDeals: state.UserAccountInfo.activeGroupDeals,
});

export default withNavigation(
  connect(mapStateToProps)(ProductPersonalizedHorizontal),
);
