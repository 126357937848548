import {
  ENTER_STORE_MODE,
  EXIT_STORE_MODE,
} from '../actions/RetailStoreActions';
import initialState from './initialState';

const RetailStore = (state = initialState.retailStore, action) => {
  switch (action.type) {
    case ENTER_STORE_MODE:
      return {
        ...action.storeData,
      };

    case EXIT_STORE_MODE:
      return {};

    default:
      return state;
  }
};

export default RetailStore;
