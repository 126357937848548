// Some Constants Are unwanted, need to removed them
export const SCREEN_CONSTANTS = {
  BRAND: 'brand',
  INTEREST: 'interest',
  PROFILE_EDIT: 'profile_edit',
  MY_PROFILE: 'famous',
  UNCOMPLETED_PROFILE: 'uncompleted_profile',
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram',
  CONSUMER_PROFILE: 'consumer_profile',
  TAKE_SELFIE: 'take_selfie',
  SELFIE_PROMPT: 'selfie_prompt',
  PRODUCT: 'product',
  PERSONALISED_PAGE: 'personalised_page',
  COLLECTION: 'collection',
  CHANGE_INSTA_HANDLE: 'change_insta_handle',
  MORE_PAGE: 'more_page',
  SELFIE_TRAY: 'selfie_tray',
  SELFIE_CARD: 'selfie_card',
  ARTIST: 'artist',
  MEDIA_CARD: 'media_card',
  IMAGE_REVIEW: 'image_review',
  FULL_SCREEN_VARIANT: 'full_screen_variant',
  SEARCH: 'search',
  FEED: 'feed',
  ARTIST_DETAILS: 'artist_details',
  BRAND_DETAILS: 'brand_details',
  BRAND_COLLAB_DETAIL: 'brand_collab_detail',
  PRODUCT_DETAIL: 'product_detail',
  POST_DETAIL: 'post_details',
  STORE: 'store',
  SELFIE_REVIEW_PROMPT: 'selfie_review_prompt',
  CAMERA: 'camera',
  REFER_AND_EARN: 'refer_and_earn',
  SALON_LOCATION: 'salon_location',
  MYKIT: 'my_kit',
  VARIANTS: 'variant_modal',
  HOME: 'home',
  TAG_DETAIL: 'tag_detail',
  CART: 'cart',
  ARTIST_PROPOSITION_QUICK_VIEW: 'artist_proposition_quick_view',
  FREE_GIFTS: 'free_items',
  OFFER_DETAIL: 'OfferDetail',
  OFFER: 'offer',
  SELFIE_INTRO: 'SelfieIntro',
  NON_PERSONALISED_FEED: 'non_personalised_feed',
  SELFIE_REVIEW: 'selfie_review',
  MY_ORDERS: 'my_orders',
  CONTENT_PAGE: 'content_page',
  ORDER_SUCCESS: 'order_success',
  CHECKOUT: 'checkout',
  FOLLOW_RECOMMENDATION: 'follow_recommendation',
  EMPTY_SEARCH: 'empty_search',
  PROFILE_ICON: 'profile_icon',
  ATTRIBUTE_SELECTOR: 'AttributeSelector',
  PAYOFF_EMPTY_STATE: 'PayoffEmptyState',
  CONTENT_PAGE_CAROUSEL: 'ContentPageCarousel',
  FULL_SCREEN_SELFIE_BANNER: 'fullScreenSelfieBanner',
  ALT_BRANDS: 'altBrands',
  PAYOFF: 'payoff',
  NOTIFICATION_CENTER: 'notification_center',
  WISH_DEALS: 'wish_deals',
  INVITE_CENTRE: 'invite_centre',
  INVITE_CONTACTS: 'invite_contacts',
  WISHLIST_BUILDER: 'wishlist_builder',
  PRODUCT_CARD: 'product_card',
  INVITE_TO_CLAIM_MODAL: 'invite_to_claim_modal',
  PERSONALISED_HORIZONTAL: 'personalised_horizontal',
  INFLUENCER: 'influencer',
  MEDIA_COMPONENT_WITHOUT_CONTROLS: 'MediaComponentWithoutControls',
  RETAIL_STORE: 'retailStore',
};
