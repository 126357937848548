import _ from 'lodash';
import initialState from './initialState';
import createReducer from '../helpers/CreateReducer';
import Utility from '../utils/Utility';

const actionHandlers = {
  ADD_POST: (state, action) =>
    Object.assign({}, state, { [action.post.localId]: action.post }),

  ADD_POST_CACHE_DIRECTORY_PATH: (state, action) => ({
    ...state,
    [action.postID]: {
      ...state[action.postID],
      postCacheDirectoryPath: action.postCacheDirectoryPath,
    },
  }),

  ADD_ITEMS_IN_CURRENT_POST: (state, action) => {
    const contentItems = state[action.postID].items;
    const updatedContentItems = _.uniq(
      contentItems.concat(action.newContentItems),
    );
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: updatedContentItems,
      },
    };
  },

  DELETE_CURRENT_CONTENT_ITEM: (state, action) => {
    const contentItems = state[action.postID].items.slice();
    contentItems.splice(action.currentContentItemIndex, 1);
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: contentItems,
      },
    };
  },

  SAVE_CONTENT_URI_ABSOLUTE_PATH: (state, action) => {
    const contentItems = state[action.postID].items;
    contentItems[action.currentContentItemIndex].mediaUriAbsolutePath =
      action.path;
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: contentItems,
      },
    };
  },

  SAVE_MEDIA_ITEM_BASE64_URI: (state, action) => {
    const contentItems = state[action.postID].items;
    contentItems[action.currentMediaItemIndex].mediaBase64Uri =
      action.mediaItemBase64Uri;
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: contentItems,
      },
    };
  },

  STORE_MEDIA_ITEMS: (state, action) => {
    const contentItems = state[action.postID].items;
    contentItems[action.currentMediaItemIndex].mediaUriExternalDirectoryPath =
      action.mediaItemExternalPath;
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: contentItems,
      },
    };
  },

  ADD_VIDEO_FRAMES: (state, action) => {
    const mediaItems = state[action.postID].items;
    mediaItems[action.currentContentItemIndex].videoFrames = action.fileNames;
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: mediaItems,
      },
    };
  },

  UPDATE_EDITED_VIDEO_FRAGMENT: (state, action) => {
    const mediaItems = state[action.postID].items.slice();
    mediaItems[action.currentContentItemIndex] = action.fragment;
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: mediaItems,
        editingMode: false,
      },
    };
  },

  ADD_SPLIT_VIDEOS: (state, action) => {
    const mediaItems = state[action.postID].items.slice();
    mediaItems.splice(
      action.currentContentItemIndex,
      1,
      action.VideoPart1Data,
      action.VideoPart2Data,
    );
    console.tron.log('Split Videos New Media Items', mediaItems);
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: mediaItems,
        editingMode: false,
      },
    };
  },

  PUBLISH_POST: (state, action) => {
    if (Utility.isBlank(action.postID)) {
      return { ...state };
    }
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        postTitle: action.postData.title,
        postCategory:
          action.postData.selectedContentCategory &&
          action.postData.selectedContentCategory.length > 0
            ? action.postData.selectedContentCategory[0]
            : '',
        hashTags: action.postData.postHashTags,
        mentions: action.postData.mentions,
      },
    };
  },

  ADD_POST_THUMBNAIL: (state, action) => ({
    ...state,
    [action.postID]: {
      ...state[action.postID],
      postThumbnail: action.thumbnailImage,
      editingMode: false,
    },
  }),

  MEDIA_EDITING_INITIATE: (state, action) => ({
    ...state,
    [action.postID]: {
      ...state[action.postID],
      editingMode: true,
    },
  }),

  ADD_PRODUCTS: (state, action) => {
    const mediaItems = state[action.postID].items;
    mediaItems[action.currentMediaItemIndex].products.push(action.product[0]);
    mediaItems[action.currentMediaItemIndex].products = _.uniq(
      mediaItems[action.currentMediaItemIndex].products,
    );
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: mediaItems,
      },
    };
  },

  DELETE_PRODUCT: (state, action) => {
    const mediaItems = state[action.postID].items;
    mediaItems[action.currentMediaItemIndex].products = _.filter(
      mediaItems[action.currentMediaItemIndex].products,
      (id) => id !== action.productID,
    );
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: mediaItems,
      },
    };
  },

  UPDATE_CROP_IMAGE: (state, action) => {
    const mediaItems = state[action.postID].items;
    mediaItems[action.currentMediaItemIndex].uri = action.newMediaUri;
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: mediaItems,
      },
    };
  },

  REORDER_MEDIA_ITEMS: (state, action) => {
    const mediaItems = state[action.postID].items.slice();
    const itemToMove = mediaItems.splice(action.ItemKeyToMove, 1);
    mediaItems.splice(action.ItemNewPosition, 0, itemToMove[0]);
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: mediaItems,
      },
    };
  },

  UPDATE_PRODUCT_SEARCH_LIST_ID: (state, action) => {
    const mediaItems = state[action.postID].items;
    mediaItems[action.currentMediaItemIndex].recentProductSearchID =
      action.listID;
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: mediaItems,
      },
    };
  },

  ADD_CAPTION: (state, action) => ({
    ...state,
    [action.postId]: {
      ...state[action.postId],
      caption: action.caption,
    },
  }),

  ADD_THUMBNAIL: (state, action) => ({
    ...state,
    [action.postId]: {
      ...state[action.postId],
      thumbnailLocation: action.thumbnail,
    },
  }),

  TOGGLE_CATEGORY: (state, action) => ({
    ...state,
    [action.postId]: {
      ...state[action.postId],
      categories: {
        ...state[action.postId].categories,
        [`${action.categoryName}`]:
          !state[action.postId].categories[action.categoryName],
      },
    },
  }),

  TOGGLE_VIDEOTYPE: (state, action) => ({
    ...state,
    [action.postId]: {
      ...state[action.postId],
      videoTypes: {
        ...state[action.postId].videoTypes,
        [`${action.videoType}`]:
          !state[action.postId].videoTypes[action.videoType],
      },
    },
  }),

  ADD_HASHTAG: (state, action) => ({
    ...state,
    [action.postId]: {
      ...state[action.postId],
      hashtags: [...state[action.postId].hashtags, action.tagName],
    },
  }),

  TOGGLE_SOCIALMEDIA: (state, action) => ({
    ...state,
    [action.postId]: {
      ...state[action.postId],
      socialMedia: {
        ...state[action.postId].socialMedia,
        [`${action.socialMediaSites}`]:
          !state[action.postId].socialMedia[action.socialMediaSites],
      },
    },
  }),

  TOGGLE_EDIT_VIDEO: (state, action) => ({
    ...state,
    [action.postId]: {
      ...state[action.postId],
      editVideo: !state[action.postId].editVideo,
    },
  }),

  TOGGLE_PUBLISHING_SETTINGS: (state, action) => ({
    ...state,
    [action.postId]: {
      ...state[action.postId],
      editPublishingSettings: !state[action.postId].editPublishingSettings,
    },
  }),

  STORE_POST_SERVER_ID: (state, action) => ({
    ...state,
    [action.postID]: {
      ...state[action.postID],
      id: action.serverID,
    },
  }),

  UPDATE_CONCATENATED_VIDEO: (state, action) => {
    let mediaItems = state[action.postID].items;
    mediaItems = action.contentItems;
    return {
      ...state,
      [action.postID]: {
        ...state[action.postID],
        items: mediaItems,
      },
    };
  },

  DELETE_DRAFT: (state, action) => {
    const newPost = _.omit(state, [action.data]);
    return newPost;
  },

  UPDATE_POST_TITLE: (state, action) => ({
    ...state,
    [action.postID]: {
      ...state[action.postID],
      postTitle: action.postData.title,
      postCategory:
        action.postData.selectedContentCategory &&
        action.postData.selectedContentCategory.length > 0
          ? action.postData.selectedContentCategory[0]
          : '',
    },
  }),
};

const Posts = createReducer(initialState.posts, actionHandlers);

export default Posts;
