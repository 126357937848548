// Dependencies
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { View, Animated, ScrollView, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { memoize } from 'lodash';
import Toast from 'react-native-easy-toast';
import { productDetailIds, ProductDetailStyles } from './styles';
import {
  ProductImages,
  ProductPageCustomNavigationHeader,
  ProductVariant,
} from '.';
import DockedHeader from '../../utils/DockedHeader';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ProductInStock from './ProductInStock';
import { useApiCall, useHardwareBackKeyPress } from '../../lib/hooksUtil';
import OfferDiscountStrip from '../offer/OfferDiscountStrip';
import FoxyMatch from './FoxyMatch';
import GroupDealCard from './GroupDealCard';
import { addToRecentlyViewed, like, unLike } from '../../actions/ActionTypes';
import ScreenPlaceholder from '../shared/ProductPagePlaceHolder';
import { API_DOMAIN, FOXY_URLS, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import ProductPricingAndAddToCart from './ProductPricingAndAddToCart';
import {
  isBlank,
  isDesktop,
  isNative,
  isPresent,
  isWeb,
  isWebAndroid,
  isWebIOS,
  isWebMobile,
} from '../../utils/BooleanUtility';
import { jsonParser } from '../../utils/JsonUtility';
import ProductOutOfStock from './ProductOutOfStock';
import ProductUnstocked from './ProductUnstocked';
import ProductDiscontinued from './ProductDiscontinued';
import ProductDelayTray from './ProductDelayTray';
import RichContent from './RichContent/RichContent';
import Wallet from '../../containers/offer/Wallet';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import ProductDetailTopSection from './ProductDetailTopSection';
import useDefaultSelectedVariantForProduct from './useDefaultSelectedVariantForProduct';
import useActivatedOfferId from './useActivatedOfferId';
import ProductPageWalletHeader from './ProductPageWalletHeader';
import ProductStockedStatus from './ProductStockedStatus';
import ProductImagesDesktop from './ProductImagesDesktop';
import PRODUCT, {
  PRODUCT_LEFT_CONTAINER_WIDTH,
  PRODUCT_RIGHT_CONTAINER_WIDTH,
} from '../../config/LayoutConstants/ProductConfig';
import { getCompactSlug, getFullSlugFromName } from '../../utils/StringUtility';
import {
  goBackWithFallbackHome,
  navigateToScreen,
} from '../../utils/NavigationUtility';
import WebFooter from '../webFooter/WebFooter';
import { getScreenWidth } from '../../utils/LayoutUtility';
import ProductBreadcrumb from './ProductBreadcrumb';
import List from '../../containers/List/List';
import ErrorBoundary from '../shared/ErrorBoundary';
import useProductData from './useProductData';
import DesktopList from './DesktopList';
import Config from '../../libraries/ReactNativeConfig';
import { getFirebasePerformanceTrace } from '../../utils/PerformanceUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import AppInstallPrompts from '../shared/AppInstallPrompts';

const productPageVariant = {
  in_stock: ProductInStock,
  stocked_out: ProductOutOfStock,
  unstocked: ProductUnstocked,
  discontinued: ProductDiscontinued,
};

const fireProductPageLoadAnalytics = (data, previousScreen) => {
  const {
    id,
    name,
    brand,
    mrp,
    sp,
    gwp,
    stocked_status,
    star_ingredients,
    product_category_name = '',
    rating,
  } = data;
  AnalyticsManager.logEvent(EventType.discoveryEvents.PRODUCT_VIEW, {
    [EventParameterKey.PRODUCT_ID]: id,
    [EventParameterKey.PRODUCT_NAME]: name,
    [EventParameterKey.BRAND_ID]: brand?.id,
    [EventParameterKey.BRAND_NAME]: brand?.name,
    [EventParameterKey.PRODUCT_LISTING_PRICE]: mrp,
    [EventParameterKey.PRODUCT_SELLING_PRICE]: sp,
    [EventParameterKey.PRODUCT_STOCKED_STATUS]: gwp ? 'gwp' : stocked_status,
    star_ingredients_count: star_ingredients && star_ingredients?.length,
    [EventParameterKey.RATING]: rating,
    [EventParameterKey.PREVIOUS_SCREEN]: previousScreen,
  });

  AnalyticsManager.logFirebaseEvent(
    EventType.googleRemarketingEvents.VIEW_ITEM,
    {
      currency: 'INR',
      items: [{ id: Utility.getSkuId(data) }],
      value: data?.mrp,
    },
  );
  AnalyticsManager.logFBStandardEvent(
    EventType.FB.EVENT_NAME_VIEWED_CONTENT,
    data.mrp,
    {
      [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: 'INR',
      [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: `${Utility.getSkuId(
        data,
      )}`,
      [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
    },
  );

  AnalyticsManager.logGTMTag({
    event: EventType.GTM.VIEW_ITEM,
    meta: {
      send_to: Config.GTM_ADWORDS || Config.GTM_ID,
      value: data?.mrp,
      items: [
        { id: Utility.getSkuId(data), google_business_vertical: 'retail' },
      ],
    },
  });

  AnalyticsManager.logGTMTag({
    ecommerce: {
      detail: {
        products: [
          {
            name,
            id: Utility.getSkuId(data),
            price: sp,
            brand: brand?.name,
            category: product_category_name,
          },
        ],
      },
    },
  });

  if (isWeb()) {
    AnalyticsManager.logPixelStandardEvent(EventType.FB_PIXEL.ViewContent, {
      content_ids: JSON.stringify([Utility.getSkuId(data)]),
      content_category: product_category_name,
      content_name: name,
      content_type: 'product',
      value: sp,
      currency: 'INR',
    });
  }
};

const ProductDetail = (props) => {
  const {
    slug: routeSlug = '',
    itemData = {},
    isReplacement = false,
    name: discontinuedProductName,
    previousScreen,
    shortSlug,
    sku_id: routeSkuId,
  } = useProductData();

  const traceForProductPageLoad = useRef(null);

  const slug = shortSlug
    ? getFullSlugFromName(shortSlug, 'product')
    : routeSlug;

  const navigation = useNavigation();
  const toastRef = useRef(null);

  const showToast = (message) => {
    if (isPresent(toastRef) && isPresent(toastRef.current)) {
      toastRef.current.show(message, 2000);
    }
  };

  useHardwareBackKeyPress();

  const dispatch = useDispatch();
  const scrollViewRef = useRef(null);

  const [ratingLayout, setRatingLayout] = useState(null);
  const [readMoreLayout, setReadMoreLayout] = useState(null);

  // Start trace at firebase Perf

  // Open Replacement modal if product is replacement
  useEffect(() => {
    if (!isReplacement) return;
    if (isPresent(discontinuedProductName)) {
      navigation.push('ReplacementProductModal', {
        itemData: data,
        selectedVariant,
        currentSku,
        productName: discontinuedProductName,
      });
    }
  }, []);

  useEffect(() => {
    traceForProductPageLoad.current = getFirebasePerformanceTrace('product_detail_new');
    traceForProductPageLoad.current.start();
    Utility.setPageLoadStart('product_page');


    if (Utility.isPresent(itemData)) {
      const {
        id,
        stocked_status,
        multi_level_variant_attributes = [],
      } = itemData;
      traceForProductPageLoad.current.putAttribute('product_id', id.toString());
      traceForProductPageLoad.current.putAttribute(
        'stocked_status',
        stocked_status.toString(),
      );
      traceForProductPageLoad.current.putAttribute(
        'variant_count',
        multi_level_variant_attributes?.length?.toString(),
      );
    }
  }, []);

  // Fetch remote config for product page headings and subheadings
  const productPageConfig = memoize(
    () => {
      return jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.product_page));
    },
    () => [REMOTE_CONFIG_KEYS.product_page],
  )();

  // Fetch Product page data
  const apiUrl = `${API_DOMAIN}${slug.replace(
    'v1',
    'v2',
  )}?show_special_offer=true`;

  const { data = {}, isLoading, error } = useApiCall(apiUrl, itemData);
  // Fire product page analytics
  useEffect(() => {
    if (!isLoading && data) {
      traceForProductPageLoad.current.stop();
      Utility.setPageLoadEnd('product_page');
      fireProductPageLoadAnalytics(data, previousScreen);
    }
  }, [isLoading, data]);

  // Fetch product page personalised elements
  const personalizedProductUrl = `${API_DOMAIN}${slug
    .replace('v1', 'v2')
    .replace('.json', '')}/personalized_details`;

  const { data: productPersonalizedDetails = {} } = useApiCall(
    personalizedProductUrl,
    itemData,
  );

  // Custom hook to find defalt and selected variant
  const {
    selectedVariant,
    selectedMultiVariantPrimary,
    setSelectedVariant,
    setSelectedMultiVariantPrimary,
    selectedMultiVariantSecondary,
    setSelectedMultiVariantSecondary,
    currentSku,
    setCurrentSku,
  } = useDefaultSelectedVariantForProduct(data, routeSkuId);

  // Find offer currently applied on product
  const { offer_id, promptMessages, couponCodes } = useActivatedOfferId(
    data,
    currentSku,
  );
  const scrollY = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    dispatch(addToRecentlyViewed(data.id));
  }, [data]);

  // Render Product page placeholder while data is loading
  if (isBlank(data) && isLoading) {
    return <ScreenPlaceholder />;
  }

  const toggleReview = (rating = 0, id) => {
    navigateToScreen({
      navigation,
      type: isDesktop() ? 'push' : 'navigate',
      screen: 'PostReview',
      params: {
        rating,
        itemData: data,
        selectedVariant,
        id,
        updateSelectedVariant: setSelectedVariant,
        shortSlug: getCompactSlug(slug),
      },
    });
  };

  const navigateToTakeSelfie = () => {
    navigation.navigate('TakeSelfie', {
      previousScreen: 'product_detail',
    });
  };

  const updateSelectedVariant = (item) => {
    setSelectedVariant(item);
    setCurrentSku(item.sku_id);
    AnalyticsUtility.fireVariantView(item, itemData);
  };

  const {
    name = '',
    description,
    metafields = [],
    stocked_status,
    order_campaign = {},
    brand,
    rich_content = {},
    gwp,
    product_category: productCategory = {},
    consolidated_list_upper: consolidatedListUpper = [],
    consolidated_list_lower: consolidatedListLower = [],
  } = data;
  const { slug: productCategorySlug } = productCategory;
  const { order_campaign: variantOrderCampaign = {} } = selectedVariant;

  const RenderProductPage = memoize(
    () => {
      return productPageVariant[`${stocked_status}`];
    },
    () => [stocked_status],
  )();

  const pattern = `${brand?.name} ` || '';
  const rep = new RegExp(pattern, 'gi');
  const productName = name?.replace(rep, '');

  const scrollToRatingSection = () => {
    scrollViewRef?.current.scrollTo({
      y: ratingLayout?.y - 24,
      animated: true,
    });
  };

  const scrollToClosedReadMoreSection = () => {
    scrollViewRef?.current.scrollTo({
      y: readMoreLayout.nativeEvent.layout.y,
      animated: true,
    });
  };

  const onRatingLayout = (e) => {
    setRatingLayout(e.nativeEvent.layout);
  };

  const updateReadMoreLayout = (e) => {
    setReadMoreLayout(e);
  };

  const onMoreShadeTap = () => {
    let { images = [], webp_images = [] } = itemData;
    let { image_url: selectedVariantImage = '' } = selectedVariant;

    navigation.push(SCREEN_CONSTANTS.FULL_SCREEN_VARIANT, {
      itemData: data,
      selectedVariant,
      selectedVariantName: selectedVariant?.name,
      updateSelectedVariant: setSelectedVariant,
      selectedVariantImage,
      imageArray: images,
      imageWebpArray: webp_images,
      isProduct: true,
      screenName: SCREEN_CONSTANTS.PRODUCT_DETAIL,
      previousScreen: SCREEN_CONSTANTS.PRODUCT_DETAIL,
      showSearchIcon: true,
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
      setSelectedMultiVariantSecondary,
      setSelectedMultiVariantPrimary,
      currentSku,
    });
  };

  const goBack = () => goBackWithFallbackHome(navigation);

  const { images: selectedVariantImages } = selectedVariant;
  const { id, images = [] } = data;

  if (isBlank(id)) {
    return <ScreenPlaceholder />;
  }
  let RootComponent = isWeb() ? View : Fragment;
  if (isDesktop()) {
    RootComponent = ScrollView;
  }

  const ProductRightContainer = isDesktop() ? View : Fragment;
  const AnimatedScrollView = isDesktop() ? View : Animated.ScrollView;
  const showMobileProductImages = !isDesktop() && stocked_status === 'in_stock';
  let safeAreaViewTopInset = {};
  let topValueOfSafeArea = Utility.topInset;
  if (isWebAndroid()) {
    topValueOfSafeArea = 50;
  } else if (isWebIOS()) {
    topValueOfSafeArea = 25;
  }

  safeAreaViewTopInset = {
    top: topValueOfSafeArea,
  };

  return (
    <ErrorBoundary
      onScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
      itemData={data}
      pageNotFound={isPresent(error)}
    >
      <RootComponent ref={isDesktop() ? scrollViewRef : null}>
        {!isDesktop() && (
          <ProductPageCustomNavigationHeader
            scrollY={scrollY}
            screenName={SCREEN_CONSTANTS.PRODUCT_DETAIL}
            hideChatButton
            navigationHeaderHeight={0}
            goBack={goBack}
            isProduct
            name={data?.name}
            skuId={currentSku}
            utmCampaign='product'
            product_image_aspect_shape={data?.product_image_aspect_shape}
            brand={data?.brand}
            shareUrl={data?.share_url}
            id={data?.id}
            type={data?.type}
            content={data?.type}
            slug={data?.slug}
            showCartIcon
            showSearchIcon
            navigation={navigation}
          >
            <DockedHeader
              name={productName}
              itemData={data}
              selectedVariant={selectedVariant}
            />
          </ProductPageCustomNavigationHeader>
        )}
        <ProductBreadcrumb productCategorySlug={productCategorySlug} />
        <View
          style={ProductDetailStyles.productDetailContainer}
          dataSet={{ media: productDetailIds.productDetailContainer }}
        >
          <View
            style={[ProductDetailStyles.SafeAreaView, safeAreaViewTopInset]}
            dataSet={{ media: productDetailIds.SafeAreaView }}
          >
            <AnimatedScrollView
              ref={isWebMobile() ? scrollViewRef : null}
              style={ProductDetailStyles.productDetailScrollView}
              dataSet={{ media: productDetailIds.productDetailScrollView }}
              scrollEventThrottle={1}
              onScroll={Animated.event([
                { nativeEvent: { contentOffset: { y: scrollY } } },
              ])}
              removeClippedSubviews
            >
              {isDesktop() && (
                <View style={styles.productImageContainer}>
                  <ProductImagesDesktop
                    images={selectedVariantImages ?? images}
                    itemData={data}
                    selectedVariant={selectedVariant}
                    currentSku={currentSku}
                  />
                  {!isLoading && (
                    <ProductVariant
                      variantAttributes={data?.variant_attributes}
                      hasVariants={data?.has_variants}
                      packSize={data?.pack_size}
                      itemData={data}
                      updateSelectedVariant={updateSelectedVariant}
                      navigation={navigation}
                      multiVariantAttributes={
                        data?.multi_level_variant_attributes
                      }
                      productCategory={data?.product_category}
                      selectedVariant={selectedVariant}
                      selectedMultiVariantPrimary={selectedMultiVariantPrimary}
                      setSelectedMultiVariantPrimary={
                        setSelectedMultiVariantPrimary
                      }
                      selectedMultiVariantSecondary={
                        selectedMultiVariantSecondary
                      }
                      setSelectedMultiVariantSecondary={
                        setSelectedMultiVariantSecondary
                      }
                      onMoreShadeTap={onMoreShadeTap}
                    />
                  )}
                  <ProductPricingAndAddToCart
                    selectedVariant={selectedVariant}
                    itemData={data}
                    currentSku={
                      Utility.isPresent(selectedVariant)
                        ? selectedVariant.sku_id
                        : currentSku
                    }
                    showToast={showToast}
                    isDesktopProductPage
                    fromProductPage
                  />
                </View>
              )}
              <ProductRightContainer style={styles.productRightContainer}>
                {showMobileProductImages && (
                  <ProductImages
                    images={selectedVariantImages ?? images}
                    previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
                    id={id}
                    name={productName}
                    navigation={navigation}
                    itemData={data}
                    addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
                    enableInlineZoom={false}
                    currentSku={currentSku}
                  />
                )}
                <ProductDetailTopSection
                  data={data}
                  selectedVariant={selectedVariant}
                  currentSku={
                    Utility.isPresent(selectedVariant)
                      ? selectedVariant.sku_id
                      : currentSku
                  }
                  isLoading={isLoading}
                  setSelectedVariant={setSelectedVariant}
                  setCurrentSku={setCurrentSku}
                  offer_id={offer_id}
                  selectedMultiVariantPrimary={selectedMultiVariantPrimary}
                  setSelectedMultiVariantPrimary={
                    setSelectedMultiVariantPrimary
                  }
                  selectedMultiVariantSecondary={selectedMultiVariantSecondary}
                  setSelectedMultiVariantSecondary={
                    setSelectedMultiVariantSecondary
                  }
                  promptMessages={promptMessages}
                  scrollToRatingSection={scrollToRatingSection}
                  onMoreShadeTap={onMoreShadeTap}
                  productPage={productPageConfig}
                  onRatingLayout={onRatingLayout}
                  updateReadMoreLayout={updateReadMoreLayout}
                  scrollToClosedReadMoreSection={scrollToClosedReadMoreSection}
                  fromProductPage
                />
                <ProductStockedStatus
                  stocked_status={stocked_status}
                  gwp={gwp}
                />

                <OfferDiscountStrip
                  id={data?.id}
                  skuId={
                    Utility.isPresent(selectedVariant)
                      ? selectedVariant.sku_id
                      : currentSku
                  }
                  type='product'
                  onScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
                  navigation={navigation}
                  offers={data.offers}
                  couponCodes={couponCodes}
                  selectedVariantSku={selectedVariant.sku_id}
                  selectedVariantId={selectedVariant.id}
                  productInfo={data}
                  currentSku={
                    Utility.isPresent(selectedVariant)
                      ? selectedVariant.sku_id
                      : currentSku
                  }
                  productPage={productPageConfig}
                  stocked_status={stocked_status}
                  isPageLoading={isLoading}
                  fromProductPage
                  itemData={data}
                />
                <Wallet
                  walletHeader={ProductPageWalletHeader}
                  source={'product_detail'}
                  previousScreen={'product_detail'}
                  product_id={data.id}
                  selectedVariantId={selectedVariant.id}
                  stocked_status={stocked_status}
                  fromProductPage
                  itemData={data}
                  currentSku={
                    Utility.isPresent(selectedVariant)
                      ? selectedVariant.sku_id
                      : currentSku
                  }
                />
                <RichContent itemData={rich_content?.post_offer_rail} />

                <GroupDealCard
                  productData={data}
                  selectedVariant={selectedVariant}
                  productPersonalizedDetails={productPersonalizedDetails}
                />

                <FoxyMatch
                  itemData={data}
                  navigation={navigation}
                  renderOnboardingForFoxyMatch={navigateToTakeSelfie}
                  isLoading={isLoading}
                  scrollToRatingSection={scrollToRatingSection}
                  adjustWidthInDesktop={isDesktop()}
                />

                {!isLoading && isBlank(error) && (
                  <RenderProductPage
                    itemData={data}
                    navigation={navigation}
                    displaySp={data.final_sp || selectedVariant?.final_sp}
                    onReview={toggleReview}
                    productPersonalizedDetails={productPersonalizedDetails}
                    productPage={productPageConfig}
                    onRatingLayout={onRatingLayout}
                    updateReadMoreLayout={updateReadMoreLayout}
                    selectedVariant={selectedVariant}
                    scrollToClosedReadMoreSection={
                      scrollToClosedReadMoreSection
                    }
                  />
                )}
                {isWeb() && !isDesktop() && <WebFooter />}
                {!isDesktop() && <View style={styles.verticalSpacer} />}
              </ProductRightContainer>
            </AnimatedScrollView>
          </View>
          <ProductDelayTray
            orderCampaign={
              isPresent(variantOrderCampaign)
                ? variantOrderCampaign
                : order_campaign
            }
          />
          <DesktopList
            list={consolidatedListLower}
            itemId={itemData.id}
            content='mixed'
            display='vertical'
          />

          {isDesktop() && <WebFooter />}
          {!isDesktop() && (
            <ProductPricingAndAddToCart
              selectedVariant={selectedVariant}
              itemData={data}
              currentSku={
                Utility.isPresent(selectedVariant)
                  ? selectedVariant.sku_id
                  : currentSku
              }
              showToast={showToast}
              fromProductPage
            />
          )}
          <Toast ref={toastRef} />
        </View>
      </RootComponent>
      <AppInstallPrompts link={FOXY_URLS.appInstallPage.offers} />
    </ErrorBoundary>
  );
};

export default ProductDetail;

const styles = StyleSheet.create({
  verticalSpacer: {
    backgroundColor: colors.background,
    height: 200,
  },
  productImageContainer: {
    width: PRODUCT_LEFT_CONTAINER_WIDTH,
    zIndex: 3,
    position: 'sticky',
    top: 0,
    height: 'fit-content',
  },
  productRightContainer: {
    width: PRODUCT_RIGHT_CONTAINER_WIDTH,
    marginLeft: 10,
    backgroundColor: 'transparent',
  },
});
