import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';

const styles = StyleSheet.create({
  background: {
    backgroundColor: colors.background,
    alignItems: 'center',
    flex: 1,
  },
  socialMediaTagPlaceHolderContainer: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: 32,
  },
  tagPlaceHolderContainer: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: 12,
  },
  introContainer: {
    paddingTop: 24,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    borderBottomRightRadius: 50,
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  cardContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingHorizontal: 24,
    marginBottom: 30,
    borderBottomRightRadius: 200,
    zIndex: 100,
  },
  introBackground: {
    alignItems: 'center',
    backgroundColor: colors.white,
    padding: 12,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },
  headerContainer: {
    alignItems: 'center',
    backgroundColor: colors.white,
    borderBottomRightRadius: 50,
  },
  profilePictureContainer: { alignItems: 'center' },
  profileImage: {
    height: 96,
    width: 96,
    borderRadius: 48,
  },
  videoPlayIcon: { height: 24, width: 24, marginTop: -14 },
  influencerName: {
    color: colors.black,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    marginTop: 24,
  },
  namePlaceHolder: {
    height: 24,
    width: Utility.getScreenWidth() - 150,
    marginTop: 24,
    borderRadius: 4,
  },
  influencerBio: {
    marginTop: 8,
    textAlign: 'center',
    fontSize: size.h3,
    color: colors.black,
  },
  tagPlaceHolder: {
    height: 24,
    width: 112,
    borderRadius: 21,
    marginHorizontal: 4,
  },
  socialTagPlaceHolder: {
    height: 32,
    width: 32,
    borderRadius: 4,
    marginHorizontal: 8,
  },
  socialAccountRow: {
    flexDirection: 'row',
    backgroundColor: colors.foxyGreen,
    height: 74,
    paddingHorizontal: 8,
    marginBottom: 12,
    borderRadius: 37,
    alignItems: 'center',
    justifyContent: 'center',
  },
  disableVerticalOrientationButton: {
    flexDirection: 'row',
    height: 44,
    paddingHorizontal: 12,
    marginBottom: 16,
    borderRadius: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'grey',
    borderWidth: 1,
  },
  disableVerticalOrientationButtonCta: {
    color: colors.black,
    fontFamily: 'Roboto-Medium',
  },
  disableVerticalOrientationButtonIcon: {
    height: 14,
    width: 14,
    tintColor: colors.black,
    marginLeft: 6,
    marginTop: 1,
  },
  rowIcon: { height: 52, width: 52, borderRadius: 32 },
  rowDP: {
    height: 52,
    width: 52,
    borderRadius: 32,
    borderWidth: 2,
    borderColor: colors.white,
  },
  socialAccountTagList: {
    borderBottomRightRadius: 100,
  },
  socialAccountTag: {
    backgroundColor: colors.foxyGreen,
    height: 42,
    width: 42,
    marginHorizontal: 6,
    borderRadius: 21,
    alignItems: 'center',
    justifyContent: 'center',
  },
  disableHorizontalOrientationButton: {
    backgroundColor: colors.white,
    height: 41,
    width: 41,
    marginHorizontal: 6,
    borderRadius: 21,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: 'grey',
  },
  disableHorizontalOrientationButtonIcon: {
    height: 18,
    width: 18,
    tintColor: colors.black,
  },
  socialAccountIcon: { height: 32, width: 32, borderRadius: 16 },
  socialMediaCta: {
    backgroundColor: colors.black,
    paddingHorizontal: 12,
    borderRadius: 16,
    paddingVertical: 4,
    paddingBottom: 6,
    justifyContent: 'center',
    alignContent: 'center',
  },
  socialMediaCtaText: {
    color: colors.white,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
  },
  socialHandle: {
    flex: 1,
    marginHorizontal: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.black,
    fontSize: size.h2,
  },
  rowListContainer: {
    paddingHorizontal: 12,
    paddingTop: 12,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
    borderRadius: 50,
  },
  iconListContainer: {
    paddingVertical: 12,
    backgroundColor: colors.white,
    borderRadius: 52,
  },
  bottomCurveLayer1: {
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
  },
  bottomCurveLayer2: {
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
    marginTop: -50,
    borderTopLeftRadius: 200,
  },
  shareButton: {
    flex: 1,
    height: 32,
    width: 32,
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: 12,
    marginTop: Utility.isIOS() ? Utility.topInset + 4 : 12,
  },
  shareIcon: { height: 24, width: 24 },
  tagsContainer: {
    // flex: 1,
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 12,
  },
  tag: {
    flexDirection: 'row',
    height: 22,
    borderRadius: 12,
    margin: 4,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tagIcon: { height: 14, width: 14, marginRight: 8 },
  tagText: {
    fontFamily: 'Roboto',
    fontSize: size.h3_5,
    color: colors.black,
  },
  registerOneLink: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: Utility.isIOS() ? Utility.topInset + 98 : 98,
    marginTop: 12,
  },
  oneLinkCta: {
    height: 64,
    width: 192,
    borderRadius: 32,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: { height: 26, width: 55, resizeMode: 'contain' },
  oneLinkText: {
    fontSize: size.h0,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
  },
  ctaText: {
    fontSize: size.h3,
    fontFamily: 'Roboto',
    marginTop: 4,
    color: colors.black,
    marginLeft: 6,
  },
  accountListContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getScreenWidth(),
    marginBottom: 4,
    padding: 4,
  },
  scrollContainer: {
    alignItems: 'center',
  },
  errorCardContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getScreenWidth(),
    padding: 24,
    backgroundColor: colors.white,
    marginBottom: 12,
  },
  heading: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    color: colors.foxyBlack,
    marginBottom: 8,
    marginTop: 16,
  },
  subHeading: {
    fontFamily: 'Roboto',
    fontSize: size.h3,
    textAlign: 'center',
    color: colors.black,
  },
  followingButtonContainer: {
    height: 28,
    width: 152,
    borderRadius: 16,
    backgroundColor: 'white',
    marginVertical: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderWidth: 1,
  },
  followingButtonIcon: {
    height: 16,
    width: 16,
    marginRight: 8,
    tintColor: 'black',
  },
  followingButtonText: { fontFamily: 'Roboto-Medium', color: 'black' },
  followButtonContainer: {
    height: 28,
    width: 152,
    borderRadius: 16,
    backgroundColor: 'black',
    marginVertical: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  followButtonIcon: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
  followButtonText: { fontFamily: 'Roboto-Medium', color: 'white' },
  introCardFooterContainer: { marginBottom: -14 },
  errorCardHeight: { height: 120 },
});

export default styles;
