import { Fragment } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';
import { getPerformance, trace } from 'firebase/performance';
import Config from './ReactNativeConfig';

const firebaseConfig = {
  apiKey: Config.FIREBASE_API_KEY,
  authDomain: Config.FIREBASE_AUTH_DOMAIN,
  projectId: Config.FIREBASE_PROJECT_ID,
  storageBucket: Config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID,
  appId: Config.FIREBASE_APP_ID,
  measurementId: Config.FIREBASE_MEASUREMENT_ID,
};

if (Config.FIREBASE_DATABASE_URL) {
  firebaseConfig.databaseURL = Config.FIREBASE_DATABASE_URL;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = Fragment;
const crashlytics = Fragment;
const dynamicLinks = Fragment;
const remoteConfig = getRemoteConfig(app) || (() => {});
const perf = getPerformance(app);
const firebase = {};

export {
  analytics,
  messaging,
  crashlytics,
  dynamicLinks,
  firebase,
  remoteConfig,
  trace,
  perf,
};
