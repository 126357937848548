import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import RoundedButton from '../../layout/buttons/RoundedButton';
import images from '../../../theme/Images';
import colors from '../../../theme/Colors';

export default function TakeSelfieForPersonalization({
  navigation,
  renderOnboardingForFoxyMatch,
}) {
  return (
    <View style={styles.container}>
      <Image style={styles.image} source={images.takeSelfie} />

      <View style={styles.contentContainer}>
        <Text
          style={{
            fontSize: 14,
            fontFamily: 'Roboto-Bold',
            color: colors.foxyBlack,
          }}
        >
          Is this a match ?
        </Text>
        <Text
          style={{
            fontSize: 14,
            fontFamily: 'Roboto-Regular',
            color: colors.foxyBlack,
          }}
        >
          Take a selfie and find out
        </Text>
      </View>
      <RoundedButton
        buttonText={'Take Selfie'}
        buttonTextColor={'#fff'}
        buttonColor={'#000'}
        buttonAction={() => {
          renderOnboardingForFoxyMatch();
        }}
        style={{ width: 101, height: 32, alignSelf: 'flex-end' }}
        buttonTextStyle={{ fontSize: 12, fontFamily: 'Roboto-Medium' }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between',
  },
  image: {
    height: 72,
    width: 72,
  },
  contentContainer: {
    flexDirection: 'column',
    marginLeft: 10,
  },
});
