import React, { PureComponent, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, TouchableOpacity } from 'react-native';
import Toast from 'react-native-easy-toast';
import ProgressBarAnimated from '../../lib/ProgressBarAnimated';
import { publishPost } from '../../actions/UploadPostActions';
import {
  showUploadTrayOnFeed,
  abortMediaUpload,
} from '../../actions/LoginActions';
import {
  setVideoUploadProgress,
  setNumOfUploadRetries,
  setVideoUploadStatus,
} from '../../actions/ActionTypes';
import styles from './styles';
import NotificationManager from '../../utils/NotificationsManager';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import {
  REMOTE_CONFIG_KEYS,
  UPLOAD_STATUS_TRAY_CONSTANTS,
} from '../../config/Constants';
import { AnalyticsManager, EventType } from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import FastImageView from '../../components/FastImageView';

class UploadStatusTray extends PureComponent {
  constructor(props) {
    super(props);
    this.statusTrayConstants = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.upload_status_tray_strings),
    );
    this.state = {};
    this.uploadData = {};
    this.trayDetails = {
      progressValue: 0,
      heading: this.statusTrayConstants.success,
      subHeading: '',
      buttonText: 'GOT IT',
      image: images.uploadTray.success,
    };
  }

  setTrayDetails() {
    const {
      lastVideoUpload = {},
      isUploading = false,
      showUploadTray = false,
    } = this.props;

    const { failed, paused, success, in_progress } =
      UPLOAD_STATUS_TRAY_CONSTANTS;
    const { numOfRetries = 0 } = lastVideoUpload;
    this.uploadData = lastVideoUpload;
    if (Utility.isBlank(this.uploadData.status)) {
      this.uploadData = {
        id: '',
        postData: {},
        status: 'success',
        progress: 0,
      };
    }
    switch (this.uploadData.status) {
      case failed:
        this.trayDetails.progressValue = 0;
        this.trayDetails.heading = this.statusTrayConstants.failed;
        this.trayDetails.image = images.uploadTray.failure;
        break;
      case success:
        this.trayDetails.progressValue = 0;
        this.trayDetails.heading = this.statusTrayConstants.success;
        this.trayDetails.image = images.uploadTray.success;
        break;
      case in_progress:
        this.trayDetails.progressValue = lastVideoUpload.progress * 100;
        this.trayDetails.heading =
          'Uploading.. ' +
          Math.floor(this.trayDetails.progressValue)
            .toString()
            .replace('.', '') +
          '%';
        this.trayDetails.image = images.uploadTray.in_progress;
        this.trayDetails.subHeading = lastVideoUpload.postData.caption;
        break;
      case paused:
        this.trayDetails.progressValue = lastVideoUpload.progress * 100;
        this.trayDetails.heading =
          'Paused.. ' +
          Math.floor(this.trayDetails.progressValue)
            .toString()
            .replace('.', '') +
          '%';
        this.trayDetails.image = images.uploadTray.paused;
        this.trayDetails.subHeading = 'Waiting for better connection';
        break;
      default:
        break;
    }
    this.trayDetails.buttonText =
      this.statusTrayConstants.buttonText[this.uploadData.status];
    if (numOfRetries >= 3 && this.trayDetails.buttonText === 'RETRY') {
      this.trayDetails.heading = ' Sorry! Please try again later.';
      this.trayDetails.buttonText = 'OKAY';
    }
    return true;
  }

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  buttonAction = () => {
    const {
      publishPost,
      lastVideoUpload: { id, postData, numOfRetries = 0 },
      showUploadTrayOnFeed,
      abortMediaUpload,
      setNumOfUploadRetries,
      setVideoUploadProgress,
      goBack,
    } = this.props;
    switch (this.statusTrayConstants.buttonText[this.uploadData.status]) {
      case this.statusTrayConstants.buttonText.failed:
        if (id && postData) {
          if (this.disableTrayIfExceedingMaxRetries()) break;
          Utility.setVideoUploadProgress = setVideoUploadProgress;
          publishPost(id, postData);
          setNumOfUploadRetries(numOfRetries + 1);
          AnalyticsManager.logFirebaseEvent(
            EventType.review_upload.MEDIA_UPLOAD_RETRY,
            {
              retry_count: numOfRetries + 1,
            },
          );
        } else {
          this.showToast('Could not upload. Please try again later.');
          showUploadTrayOnFeed(false);
        }
        break;
      case this.statusTrayConstants.buttonText.success:
        showUploadTrayOnFeed(false);
        typeof goBack === 'function' && goBack();
        break;
      case this.statusTrayConstants.buttonText.in_progress:
        abortMediaUpload();
        break;
      default:
        break;
    }
  };

  disableTrayIfExceedingMaxRetries = () => {
    const {
      lastVideoUpload: { numOfRetries = 0 },
      showUploadTrayOnFeed,
      setNumOfUploadRetries,
    } = this.props;
    if (numOfRetries > 2) {
      setNumOfUploadRetries(0);
      showUploadTrayOnFeed(false);
      NotificationManager.hideVideoUploadingNotification();
      return true;
    }
  };

  renderProgressBar = () => {
    const { status } = this.uploadData;
    const { progressValue } = this.trayDetails;

    if (status === 'failed' || status === 'success') {
      return null;
    }
    return (
      <View style={styles.uploadTrayProgressBarContainer}>
        <ProgressBarAnimated
          width={Utility.getScreenWidth() - 24}
          height={4}
          value={progressValue}
          backgroundColor={colors.cta.lightBlue}
          underlyingColor={colors.addToCart}
          borderWidth={0}
        />
      </View>
    );
  };

  renderDescription = () => {
    const { status } = this.uploadData;
    const { image, heading, subHeading } = this.trayDetails;
    const descriptionContainerStyle =
      status === 'paused'
        ? styles.descriptionContainerLarge
        : styles.descriptionContainer;
    return (
      <View style={styles.headerContainer}>
        <View style={styles.uploadTrayHeader}>
          <FastImageView style={styles.uploadTrayIcon} source={image} />
          <Text style={styles.uploadText} numberOfLines={1}>
            {heading}
          </Text>
        </View>
        <View style={descriptionContainerStyle}>
          {(status === 'paused' || status === 'in_progress') && (
            <Text style={styles.uploadSubHeadText}>{subHeading}</Text>
          )}
        </View>
      </View>
    );
  };

  renderButton = () => {
    if (
      this.uploadData.status === 'in_progress' ||
      this.uploadData.status === 'paused'
    ) {
      return null;
    }
    const { buttonText } = this.trayDetails;
    return (
      <TouchableOpacity
        style={styles.uploadTrayButton}
        onPress={this.buttonAction}
        hitSlop={Utility.getHitSlop()}
      >
        <Text style={styles.uploadStatusButton}>{buttonText}</Text>
      </TouchableOpacity>
    );
  };

  assignRef = (ref) => {
    this.toast = ref;
  };

  render() {
    const { showUploadTray } = this.props;
    if (!showUploadTray) {
      return null;
    }
    this.setTrayDetails();
    return (
      <View style={styles.uploadingTrayContainer}>
        <this.renderProgressBar />
        <this.renderDescription />
        <this.renderButton />
        <Toast style={styles.toast} ref={this.assignRef} />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  lastVideoUpload: state.UserAccountInfo.lastVideoUpload,
  isUploading: state.UserAccountInfo.isUploading,
  showUploadTray: state.UserAccountInfo.showUploadTrayOnFeed,
  currentPostId: state.UserAccountInfo.currentPostId,
  posts: state.Posts,
  postId: state.Posts.postId,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      publishPost,
      setVideoUploadProgress,
      showUploadTrayOnFeed,
      abortMediaUpload,
      setNumOfUploadRetries,
      setVideoUploadStatus,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(memo(UploadStatusTray)),
);
