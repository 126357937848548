import {
  DefaultSizeVariantGrid,
  DefaultSizeVariantList,
  DefaultSizeVariantVerticalRail,
  DefaultSizeVariantRail,
  DefaultSizeVariantCard,
} from '.';

export const SmallVariantCard = DefaultSizeVariantCard;
export const SmallVariantGrid = DefaultSizeVariantGrid;
export const SmallVariantList = DefaultSizeVariantList;
export const SmallVariantVerticalRail = DefaultSizeVariantVerticalRail;
export const LargeVariantCard = DefaultSizeVariantCard;
export const LargeVariantGrid = DefaultSizeVariantGrid;
export const LargeVariantList = DefaultSizeVariantList;
export const LargeVariantVerticalRail = DefaultSizeVariantVerticalRail;
export const LargeVariantRail = DefaultSizeVariantRail;
