import { Image, StyleSheet } from 'react-native';
import React from 'react';
import images from '../../theme/Images';

export default function AccountOptionDivider() {
  return (
    <Image
      source={{ uri: images.desktop.accountOptionDivider }}
      style={styles.image}
    />
  );
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: 2,
  },
});
