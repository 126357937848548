import React, { Component } from 'react';
import {
  TouchableOpacity,
  Image,
  Text,
  TouchableWithoutFeedback,
  View,
  Animated,
  NativeModules,
  LayoutAnimation,
} from 'react-native';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withMaybe, withEither } from '../../lib/Monads';
import {
  followArtist,
  unFollowArtist,
  viewPublisherStory,
  setStoryItemRecommendation,
  unfollowStories,
  followStories,
} from '../../actions/ActionTypes';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { LAYOUT, THEME, LOGIN_MODAL_MESSAGES, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import images from '../../theme/Images';
import { ArtistCardStyles, ArtistDetailStyles } from './styles';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';

import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
  EventParameterValue,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import RemoteConfig from '../../utils/RemoteConfig';
import NavigationService from '../../navigator/NavigationService';

class FollowArtistButton extends Component {
  followArtistButtonConstants = {
    followButtonWidth: 106,
    followedButtonWidth: 70,
    followedTickWidth: 24,
  };

  constructor(props) {
    super(props);
    this.id = props.id;
    this.slug = props.slug;
    this.followed = props.followed;
    this.state = {
      followed: this.followed,
      storyFollowed: false,
      prevFollowed: this.followed,
      follwButtonLeftDistance: this.followed
        ? new Animated.Value(
            -this.followArtistButtonConstants.followButtonWidth,
          )
        : new Animated.Value(0),
      greyTickLeftDistance: this.followed
        ? new Animated.Value(0)
        : new Animated.Value(
            -this.followArtistButtonConstants.followedTickWidth,
          ),
      followedBottonWidth: this.followed
        ? new Animated.Value(
            this.followArtistButtonConstants.followedButtonWidth,
          )
        : new Animated.Value(40),
      followedButtonOpacity: this.followed
        ? new Animated.Value(1)
        : new Animated.Value(0),
    };
    const { UIManager } = NativeModules;
    UIManager.setLayoutAnimationEnabledExperimental &&
      UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  followButtonConstants = {
    text: {
      true: 'Following',
      false: 'Follow',
    },
    color: {
      true: colors.white,
      false: colors.havelockBlue,
    },
    image: {
      true: images.whiteTick,
      false: images.plus,
    },
  };

  toggleStoryFollowButton = () => {
    this.setState((prevState) => ({ storyFollowed: !prevState.storyFollowed }));
  };

  componentDidMount() {
    const { followedArtistStoryRecommendations, itemData: { type, id } = {} } =
      this.props;

    this.setState({
      storyFollowed: Utility.isPresent(
        followedArtistStoryRecommendations[`${type}${id}`],
      ),
    });
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.followed !== state.prevFollowed) {
      return {
        followed: props.followed,
        prevFollowed: props.followed,
        follwButtonLeftDistance: props.followed
          ? new Animated.Value(-106)
          : new Animated.Value(0),
        greyTickLeftDistance: props.followed
          ? new Animated.Value(0)
          : new Animated.Value(-24),
        followedBottonWidth: props.followed
          ? new Animated.Value(70)
          : new Animated.Value(40),
        followedButtonOpacity: props.followed
          ? new Animated.Value(1)
          : new Animated.Value(0),
      };
    }
    // Return null if the state hasn't changed
    return null;
  };

  updateFollow = (followed) => {
    const {
      previousScreen,
      authToken,
      setPhoneNumberModalInfo,
      unfollowStories,
      followStories,
      itemData = {},
    } = this.props;
    if (Utility.isBlank(authToken)) {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('FOLLOW_ARTIST'));
      NavigationService.renderOnboarding();
      AnalyticsManager.logEvent(EventType.onboardingEvent.LOGIN_INITIATE, {
        [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.FOLLOW_ARTIST,
      });
      Utility.invokeSelfieFlowAfterLoginForGuest(false, false, true);
      return;
    }

    if (previousScreen === 'recommendation') {
      this.toggleStoryFollowButton();
    }
    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
    const url = this.slug?.replace(
      '.json',
      followed ? '/unfollow.json' : '/follow.json',
    );
    const { id } = this;
    const { name } = this.props;
    const data = {
      id,
      url,
      lastViewed: Math.floor(Date.now() / 1000),
      slug: this.slug,
      type: itemData.type,
    };
    const analyticsMeta = {
      [EventParameterKey.ARTIST_ID]: data.id,
      [EventParameterKey.ARTIST_NAME]: name,
    };
    if (followed) {
      this.setState({
        followed: false,
      });
      setTimeout(() => {
        AnalyticsManager.logEvent(
          EventType.discoveryEvents.ARTIST_UNFOLLOW,
          analyticsMeta,
        );
        this.props.unFollowArtist(data);
      }, 0);
      unfollowStories(id);
    } else {
      this.setState({
        followed: true,
      });
      setTimeout(() => {
        AnalyticsManager.logEvent(
          EventType.discoveryEvents.ARTIST_FOLLOW,
          analyticsMeta,
        );

        this.props.followArtist(data);
        if (!Utility.isBlank(itemData.stories)) {
          followStories(itemData);
        }
      }, 0);
    }
  };

  animateToFollowed = (showFollowed) => {
    Animated.parallel([
      Animated.timing(this.state.follwButtonLeftDistance, {
        toValue: -this.followArtistButtonConstants.followButtonWidth,
        duration: 200,
      }),
      Animated.timing(this.state.greyTickLeftDistance, {
        toValue: 0,
        duration: 200,
        delay: 100,
      }),
      Animated.timing(this.state.followedBottonWidth, {
        toValue: 70,
        duration: 200,
        delay: 300,
      }),
      Animated.timing(this.state.followedButtonOpacity, {
        toValue: 1,
        duration: 200,
        delay: 350,
      }),
    ]).start(() => this.updateFollow(showFollowed));
  };

  animateToFollowDefaultState = (showFollowed) => {
    Animated.parallel([
      Animated.timing(this.state.follwButtonLeftDistance, {
        toValue: 0,
        duration: 200,
        delay: 300,
      }),
      Animated.timing(this.state.greyTickLeftDistance, {
        toValue: -24,
        duration: 200,
        delay: 100,
      }),
      Animated.timing(this.state.followedBottonWidth, {
        toValue: 40,
        duration: 200,
      }),
      Animated.timing(this.state.followedButtonOpacity, {
        toValue: 0,
        duration: 200,
      }),
    ]).start(() => this.updateFollow(showFollowed));
  };

  toggleFollowUnfollowAnimation = (showFollowed) => {
    if (showFollowed) {
      this.animateToFollowDefaultState(showFollowed);
    } else {
      this.animateToFollowed(showFollowed);
    }
  };

  artistDetailFollowButton = (props) => {
    const { showFollowed = false } = props;
    const styles = ArtistDetailStyles;
    return (
      <TouchableWithoutFeedback
        onPress={() => this.toggleFollowUnfollowAnimation(showFollowed)}
        hitSlop={Utility.getHitSlop()}
      >
        <View style={styles.followButton}>
          <Animated.View
            style={[
              styles.unfollowedStateButton,
              { left: this.state.follwButtonLeftDistance },
            ]}
          >
            <View style={styles.followtextContainer}>
              <Text style={[styles.statsCount, { color: colors.white }]}>
                {this.followButtonConstants.text.false}
              </Text>
            </View>
            <View style={styles.bluePlusContainer}>
              <Image source={images.whitePlus} style={styles.whitePlus} />
            </View>
          </Animated.View>
          <Animated.View
            style={[
              styles.grayTickContainer,
              { left: this.state.greyTickLeftDistance },
            ]}
          >
            <Image
              source={this.followButtonConstants.image.true}
              style={styles.whiteTick}
            />
          </Animated.View>
          <Animated.View
            style={[
              styles.followedTextContainer,
              {
                width: this.state.followedBottonWidth,
                opacity: this.state.followedButtonOpacity,
              },
            ]}
          >
            <Text style={[styles.statsCount, { color: colors.white }]}>
              {this.followButtonConstants.text.true}
            </Text>
          </Animated.View>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  artistCardFollowButton = (props) => {
    const { showFollowed, layout } = props;
    let { disabled } = props;
    const { itemData = {} } = this.props;

    const styles = ArtistCardStyles;
    let followStyle =
      layout === LAYOUT.LIST ? styles.followButtonForList : styles.followButton;

    if (itemData.type === 'brand') {
      followStyle = styles.followBrandButton;
    }
    const backgroundColor = showFollowed
      ? colors.silverDark
      : colors.havelockBlue;
    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={() => this.updateFollow(showFollowed)}
        hitSlop={Utility.getHitSlop()}
        style={[
          styles.circularImageContainer,
          followStyle,
          { backgroundColor },
        ]}
      >
        <Image
          source={showFollowed ? images.whiteTick : images.whitePlus}
          style={styles.plusImageStyle}
        />
      </TouchableOpacity>
    );
  };

  navigationHeaderFollowButton = (props) => {
    const { showFollowed, layout, disabled } = props;
    const styles = ArtistCardStyles;
    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={() => this.updateFollow(showFollowed)}
        hitSlop={Utility.getHitSlop()}
        style={styles.navigationFollowButton}
      >
        <Image
          source={
            showFollowed ? images.artistFollowedDock : images.artistFollowDock
          }
          style={styles.navbarFollowIconStyle}
        />
      </TouchableOpacity>
    );
  };

  followButtonConditionFn = (props) =>
    props.followButtonLayout !== LAYOUT.SCREEN;

  followButton = withEither(
    this.followButtonConditionFn,
    this.artistCardFollowButton,
  )(this.artistDetailFollowButton);

  render() {
    const {
      id,
      followButtonLayout,
      layout,
      disabled = false,
      previousScreen,
    } = this.props;
    const { followed, storyFollowed } = this.state;

    const showFollowed =
      previousScreen === 'recommendation' ? storyFollowed : followed;

    const followBackGroundColor = followed
      ? colors.silverDark
      : colors.followButtonBlueColor;

    if (followButtonLayout === LAYOUT.NAVIGATION_HEADER) {
      return (
        <this.navigationHeaderFollowButton
          showFollowed={showFollowed}
          id={id}
          followButtonLayout={followButtonLayout}
          layout={layout}
          disabled={disabled}
        />
      );
    }
    if (followButtonLayout === LAYOUT.LIST) {
      return (
        <TouchableOpacity
          style={[
            ArtistCardStyles.textualFollowButton,
            { backgroundColor: followBackGroundColor },
          ]}
          onPress={() => this.updateFollow(showFollowed)}
        >
          <Text
            style={{ fontFamily: 'Roboto', fontSize: 12, color: colors.white }}
          >
            {followed ? 'Following' : 'Follow'}
          </Text>
        </TouchableOpacity>
      );
    }
    return (
      <this.followButton
        showFollowed={showFollowed}
        id={id}
        followButtonLayout={followButtonLayout}
        layout={layout}
        disabled={disabled}
      />
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  const { id } = ownProps;
  return {
    followed: !!(
      store.UserAccountInfo.followedArtists &&
      store.UserAccountInfo.followedArtists[id]
    ),
    authToken: store.UserAccountInfo.authToken,

    followedArtistStoryRecommendations:
      store.UserAccountInfo.followedArtistStoryRecommendations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      followArtist,
      unFollowArtist,
      viewPublisherStory,
      setPhoneNumberModalInfo,
      setStoryItemRecommendation,
      unfollowStories,
      followStories,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowArtistButton);
