import React, { Component } from 'react';
import {
  Linking,
  Text,
  View,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import { OutlinedTextField } from 'react-native-material-textfield';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  APP_STORE_LINK,
  PLAY_STORE_LINK,
  REMOTE_CONFIG_KEYS,
} from '../../../config/Constants';
import { StarRating } from '../../../lib/StarRating';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import FastImageView from '../../FastImageView';
import RoundedButton from '../../layout/buttons/RoundedButton';
import { updateAppRating } from '../../../actions/ActionTypes';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import RemoteConfig from '../../../utils/RemoteConfig';
import DebouncedTouchableOpacity from '../../shared/DebouncedTouchableOpacity';
import _ from 'lodash';
import { updateCustomerStatus } from '../../../actions/OrderActions';
import { AnalyticsManager } from '../../../analytics';

// eslint-disable-next-line react/prefer-stateless-function
class FirstAttemptFailActionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRatingDescription: false,
      ratingDescription: '',
      selectedPill: '',
      showLoader: false,
    };
    const { backgroundColor = '#fff', style } = this.props;
    this.containerStyle = [
      styles.container,
      style,
      { backgroundColor },
    ];

    this.innerContainer = [
      styles.endStateContainer,
      { backgroundColor },
    ];

    this.attempt_fail_reasons =
      JSON.parse(
        RemoteConfig.getValue(REMOTE_CONFIG_KEYS.attempt_fail_reasons),
      ) || [];
  }

  onChangeBody = (value = '') => {
    this.setState({
      selectedPill: value.trim(),
    });
  };

  endState = (props) => {
    const { imageUrl } = this.props;

    const {
      endStateData: { heading = '', subheading = '' },
    } = props;
    return (
      <View style={this.innerContainer}>
        <FastImageView
          resizeMode='contain'
          source={imageUrl}
          style={styles.endStateImageLarge}
        />

        <View style={styles.endStateHeadingSubheading}>
          <Text style={styles.endStateHeading}>{heading}</Text>

          <Text
            style={styles.endStateSubheading}
            numberOfLines={2}
            ellipsizeMode='tail'
          >
            {subheading}
          </Text>
        </View>
      </View>
    );
  };

  updateAttemptFailureReason = () => {
    const {
      updateCustomerStatus,
      id,
      addOrderActionCard,
      status,
      primaryButtonAction,
    } = this.props;
    primaryButtonAction();
    const { selectedPill } = this.state;

    const shipmentId = id.split('-')[1];
    if (id.split('-')[0] !== 'shipment') {
      return;
    }

    const body = {
      shipment: {
        delivery_attempt_failure_reason: selectedPill,
      },
    };

    updateCustomerStatus(shipmentId, body, () => {
      addOrderActionCard();
    });
  };

  onSelectPill = (pill) => {
    this.setState({
      selectedPill: pill,
      showRatingDescription: pill === 'others',
    });
  };

  pills = () => {
    const { selectedPill } = this.state;

    return (
      <View style={styles.pillsContainer}>
        {this.attempt_fail_reasons.map((fr) => {
          return (
            <DebouncedTouchableOpacity
              {...this.props}
              style={
                fr === selectedPill
                  ? styles.pillContainerSelected
                  : styles.pillContainer
              }
              onPress={_.memoize(
                () => this.onSelectPill(fr),
                () => fr,
              )}
            >
              <Text style={styles.pillsText}>{fr}</Text>
            </DebouncedTouchableOpacity>
          );
        })}
        <DebouncedTouchableOpacity
          {...this.props}
          style={
            selectedPill === 'others'
              ? styles.pillContainerSelected
              : styles.pillContainer
          }
          onPress={_.memoize(
            () => this.onSelectPill('others'),
            () => 'others',
          )}
        >
          <Text style={styles.pillsText}>Others</Text>
        </DebouncedTouchableOpacity>
      </View>
    );
  };

  render() {
    const {
      imageUrl,
      heading,
      subheading,
      showEndState,
      firstActionButtonText,
      primaryButtonAction,
      primaryButtonIcon,
      selectedPill,
    } = this.props;

    const { showRatingDescription } = this.state;

    const endStateData = {
      heading: 'Thank you for your feedback',
      subheading: 'It helps us to improve continuously',
    };

    if (showEndState) {
      return <this.endState endStateData={endStateData} />;
    }

    return (
      <View style={this.containerStyle}>
        <FastImageView
          resizeMode='contain'
          source={imageUrl}
          style={styles.image}
        />
        {heading && <Text style={styles.heading}>{heading}</Text>}
        <Text style={styles.subheading}>{subheading}</Text>
        <this.pills />

        {showRatingDescription && (
          <OutlinedTextField
            editable
            multiline
            maxLength={100}
            onChangeText={this.onChangeBody}
            value={selectedPill}
            label={'Tell us what went wrong'}
            lineWidth={0}
            activeLineWidth={0}
            inputContainerStyle={styles.inputContainerStyle}
            style={styles.descriptionBoxStyle}
            textAlignVertical='top'
            autoFocus
          />
        )}

        <FoxyShadowButton
          width={Utility.getScreenWidth() - 24 - 12}
          title={firstActionButtonText}
          onPress={this.updateAttemptFailureReason}
          style={styles.primaryButtonContainer}
          textStyle={styles.primaryButtonText}
          firstColor={colors.black}
          secondColor={colors.black}
          underlayColor={colors.black}
          showActionIcon={!!primaryButtonIcon}
          actionIcon={{ uri: primaryButtonIcon }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth() - 24,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 12,
    alignSelf: 'center',
    borderRadius: 16,
    marginTop: 3,
  },
  image: { height: 140, width: 227, marginTop: 20, marginBottom: 12 },
  heading: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: '#404E9F',
    lineHeight: 21.09,
    marginBottom: 4,
  },
  subheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    lineHeight: 14.09,
    marginBottom: 4,
    textAlign: 'center',
    marginTop: 6,
  },
  loadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    lineHeight: 14.09,
    marginBottom: 4,
    textAlign: 'center',
    marginTop: 6,
  },
  activityContainer: { marginVertical: 25, marginLeft: 12 },
  descriptionBox: {
    backgroundColor: '#fff',
    borderColor: colors.border,
    borderWidth: 1,
    borderRadius: 8,
    width: Utility.getScreenWidth() - 32,
    marginTop: 8,
    padding: 0,
  },
  // inputBox: { padding: 0, backgroundColor: 'red' },
  roundedButtonContainer: {
    width: 101,
    height: 32,
    alignSelf: 'center',
    marginTop: 16,
  },
  buttonText: { fontSize: 12, fontFamily: 'Roboto-Medium' },
  endStateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: Utility.getScreenWidth() - 24,
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginHorizontal: 12,
    borderRadius: 8,
  },
  endStateImageSmall: { height: 62, width: 68, marginRight: 8 },
  endStateImageLarge: { height: 62, width: 90, marginRight: 8 },
  endStateHeadingSubheading: { flexDirection: 'column', flexShrink: 1 },
  endStateHeading: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: '#404E9F',
  },
  endStateSubheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
  },
  inputContainerStyle: {
    backgroundColor: colors.white,
    height: 120,
    width: Utility.getScreenWidth() - 24 - 24,
    borderRadius: 8,
    paddingTop: 0,
    paddingLeft: 12,
    borderBottomWidth: 0,
    marginTop: 20,
  },
  descriptionBoxStyle: { alignSelf: 'flex-start' },
  primaryButtonContainer: { marginTop: 12 },
  primaryButtonText: { borderRadius: 8 },
  pillsContainer: { flexDirection: 'row', marginBottom: 8, flexWrap: 'wrap' },
  pillContainer: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderRadius: 16,
    marginLeft: 8,
    marginTop: 12,
    borderColor: colors.subtitle,
  },
  pillContainerSelected: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderWidth: 1,
    backgroundColor: '#FFEEC8',
    borderRadius: 16,
    marginLeft: 8,
    marginTop: 12,
    borderColor: '#AE0008',
  },
  pillsText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateAppRating,
      updateCustomerStatus,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(FirstAttemptFailActionCard);
