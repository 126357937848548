import { Text, StyleSheet } from 'react-native';
import React from 'react';
import colors from '../../theme/Colors';

export default function AccountOptionItem({ label = '', handlePress = () => {} }) {
  return (
    <Text onPress={handlePress} style={styles.text}>
      {label}
    </Text>
  );
}

const styles = StyleSheet.create({
  text: {
    textAlign: 'left',
    fonSize: 14,
    lineHeight: 16,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginBottom: 12,
  },
});
