import React, { PureComponent } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import _ from 'lodash';

export default class DebouncedTouchableWithoutFeedback extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedOnPress = _.debounce(this.customOnPress, 500, {
      leading: true,
      trailing: false,
    });
  }

  customOnPress = () => {
    const { onPress } = this.props;
    onPress();
  };

  render() {
    const { children } = this.props;
    return (
      <TouchableWithoutFeedback {...this.props} onPress={this.debouncedOnPress}>
        {children}
      </TouchableWithoutFeedback>
    );
  }
}
