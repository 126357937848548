import React from 'react';
import { View, Text } from 'react-native';
import StyleSheet from 'react-native-media-query';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { ProductDetailStyles } from './styles';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isBlank, isDesktop } from '../../utils/BooleanUtility';

const shimmerColor = ['#f7F7f7', '#eeeeee', '#f7F7f7'];

export default function ProductHeroDescription({
  hero_description: heroDescription,
  isLoading,
}) {
  if (isBlank(heroDescription)) {
    return null;
  }

  if (isLoading) {
    return (
      <View style={styles.shimmerContainer}>
        <ShimmerPlaceHolder
          colorShimmer={shimmerColor}
          autoRun
          style={styles.shimmerLineOne}
          dataSet={{ media: ids.shimmerLineOne }}
        />
        <ShimmerPlaceHolder
          colorShimmer={shimmerColor}
          autoRun
          style={styles.shimmerLineTwo}
        />
      </View>
    );
  }
  return (
    <View style={ProductDetailStyles.heroDescriptionBoxContainer}>
      <Text
        style={ProductDetailStyles.productDescriptionHeroStyle}
        numberOfLines={3}
      >
        {heroDescription?.trim()}
      </Text>
    </View>
  );
}

const { ids, styles } = StyleSheet.create({
  shimmerLineOne: {
    height: 12,
    width: getScreenWidth() - 32,
    borderRadius: 4,
    marginTop: 10,
    alignSelf: 'center',
    '@media (min-width: 768px)': {
      width: '100%',
    },
  },
  shimmerLineTwo: {
    height: 12,
    width: 90,
    borderRadius: 4,
    marginTop: 2,
    marginBottom: 12,
  },
  shimmerContainer: { backgroundColor: 'white', paddingHorizontal: 16 },
});
