import { StyleSheet, View, Text, Image } from 'react-native';
import React, { useState } from 'react';
import PaymentDetails from './PaymentDetails';
import PaymentDetailsHeader from './PaymentDetailsHeader';
import ShopifyOrderItems from './ShopifyOrderItems';
import colors from '../../../theme/Colors';
import GrandTotal from './GrandTotal';
import Coupons from './Coupons';
import Savings from './Savings';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';

export default function ShopifyOrderDetailsCard(props) {
  const [showDetails, setShowDetails] = useState(false);
  const {
    orderName,
    totalAmount,
    discount,
    orderItems = [],
    coupons = [],
    shippingCharges,
    createdAt,
    status,
    trackingUrl,
    paymentMethod,
  } = props;

  return (
    <>
      <View style={styles.container}>
        <PaymentDetailsHeader
          setShowDetails={setShowDetails}
          showDetails={showDetails}
          orderName={orderName}
          totalAmount={totalAmount}
        />
        {showDetails && (
          <>
            <PaymentDetails
              orderItems={orderItems}
              shippingCharges={shippingCharges}
            />
            <Divider />
            <GrandTotal totalAmount={totalAmount} />
            <Divider />
            <Savings discount={discount} />
            <Coupons coupons={coupons} />
            <PaidBy paymentMethod={paymentMethod} />
          </>
        )}
      </View>
      <ShopifyOrderItems
        orderItems={orderItems}
        createdAt={createdAt}
        status={status}
        trackingUrl={trackingUrl}
        orderName={orderName}
      />
    </>
  );
}

const PaidBy = ({ paymentMethod }) => {
  return (
    <View style={styles.paidByContainer}>
      <Image source={images.success} style={styles.paidByImage} />
      <Text style={styles.paidByText}>{`${paymentMethod || 'COD'}`}</Text>
    </View>
  );
};

const Divider = () => <View style={styles.divider} />;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingBottom: 3,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: colors.borderColor,
    marginVertical: 12,
  },
  paidByContainer: {
    backgroundColor: colors.background,
    width: Utility.getScreenWidth() - 24,
    height: 36,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 20,
    marginTop: 12,
  },
  paidByImage: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    marginRight: 12,
  },
  paidByText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
});
