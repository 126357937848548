import React from 'react';
import { View, Text } from 'react-native';
import { ProductDetailStyles } from './styles';
import images from '../../theme/Images';
import FastImageView from '../FastImageView';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { jsonParser } from '../../utils/JsonUtility';

export default function ProductPageWalletHeader() {
  const productDetailWalletHeader = jsonParser(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.product_detail_wallet_header),
  );
  return (
    <View>
      <View style={ProductDetailStyles.container}>
        <FastImageView
          source={images.wallet}
          style={ProductDetailStyles.walletImage}
        />
        <Text
          style={ProductDetailStyles.walletText}
          ellipsizeMode='tail'
          numberOfLines={1}
        >
          {productDetailWalletHeader?.heading}
        </Text>
      </View>

      <Text
        style={ProductDetailStyles.descriptionText}
        allowFontScaling={false}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {productDetailWalletHeader?.subheading}
      </Text>
    </View>
  );
}
