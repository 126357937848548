import _ from 'lodash';
import * as types from '../actions/ActionTypes';
import initialState from './initialState';
import Utility from '../utils/Utility';

export default function todayDeals(state = initialState.todayDeals, action) {
  if (Utility.isBlank(action.data)) {
    if (action.type === types.LOGOUT_USER) {
      return {
        ...state,
        skus: {},
        membership_cohort: 'non_member',
        is_upgradable: false,
        remaining_order_amount_for_extension: 'xxx',
        membership_savings: 0,
      };
    }
    return state;
  }

  switch (action.type) {
    case types.ADD_TODAY_DEALS_TO_REDUX:
      return {
        ...state,
        membership_cohort:
          action?.data?.membership_cohort || state.membership_cohort,
        membership_id: action?.data?.membership_plan_id,
        is_upgradable: action?.data?.is_upgradable ?? state.is_upgradable,
        ends_at: action?.data?.ends_at ?? state.ends_at,
        group_deal_skus: action?.data?.group_deal_skus ?? state.group_deal_skus,
        skus: action?.data?.skus ?? state.skus,
        edge_deal_skus: action?.data?.edge_deal_skus ?? state.edge_deal_skus,
        coupon_code: action?.data?.coupon_code,
        remaining_order_amount_for_extension:
          action?.data?.remaining_order_amount_for_extension,
        membership_savings: action?.data?.membership_savings,
      };

    case types.SAVE_BAG:
      if (Utility.isBlank(action.data?.flash_deals?.skus)) {
        return state;
      }

      return {
        ...state,
        ...action.data?.flash_deals,
      };

    case types.SAVE_MEMBERSHIP_COHORT:
      if (Utility.isBlank(action.data)) {
        return state;
      }

      return {
        ...state,
        membership_cohort: action.data.membership_cohort || 'non_member',
      };

    default:
      return state;
  }
}
