import React, { Component } from 'react';
import { Animated, StyleSheet } from 'react-native';
import Config from '../libraries/ReactNativeConfig';

export class BarLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftPosition: new Animated.Value(0),
    };
  }

  componentDidMount() {
    this.startAnimation();
  }

  startAnimation = () => {
    const { leftPosition } = this.state;
    const { iterations = 5, width = 50 } = this.props;
    Animated.loop(
      Animated.sequence([
        Animated.timing(leftPosition, {
          toValue: width / 2,
          duration: 500,
          delay: 0,
          useNativeDriver: true,
        }),
        Animated.timing(leftPosition, {
          toValue: -(width / 2),
          duration: 500,
          useNativeDriver: true,
        }),
      ]),
      {
        iterations: 100,
      },
    ).start();
  };

  render() {
    const { leftPosition } = this.state;
    const { style = {} } = this.props;

    const barStyle = [
      styles.barLoader,
      { transform: [{ translateX: leftPosition }] }
    ];

    return (
      <Animated.View
        style={[barStyle, style]}
      />
    );
  }
}

const styles = StyleSheet.create({
  barLoader: {
    position: 'absolute',
    bottom: 1,
    width: 10,
    height: 2,
    backgroundColor: Config.INCREMENT_DECREMENT_LOADER_COLOR,
  },
});

export default BarLoader;
