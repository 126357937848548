import { find, toUpper } from 'lodash';
import { Linking } from 'react-native';
import AnalyticsManager from '../analytics/AnalyticsManager';
import EventType from '../analytics/AnalyticsEventType';
import EventParameterKey from '../analytics/EventParameterKey';
import PermissionsUtility from './PermissionsUtility';
import Utility from './Utility';
import { isPresent } from './BooleanUtility';

export default class ContactsUtility {
  static calculateDiscountPercentage = (sp, mrp) => {
    try {
      return parseInt(((mrp - sp) / mrp) * 100, 10);
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `Utility.js: calculateDiscountPercentage: ${error}`,
        },
      );
      return -1;
    }
  };

  static getThumbnailOrContactsInitialsFromContacts(
    contactsArrayInRedux = [],
    number = '',
    name = '',
    indexInCurrentArray = 0,
  ) {
    const contact = find(contactsArrayInRedux, (obj) => {
      return `${obj.phoneNumber}` === `${number}`;
    });
    if (Utility.isPresent(contact)) {
      return contact;
    }
    const contactData = {};
    const displayName = Utility.isBlank(name) ? `${number}` : name;
    contactData.nameInitial = ContactsUtility.getNameInitials(displayName);
    contactData.index = indexInCurrentArray;
    return contactData;
  }

  static getNameInitials = (name = '') => {
    let initials = '';
    try {
      const words = `${name}`.split(' ');
      if (words.length < 1) return '--';

      initials = words[0]?.charAt(0);

      if (words.length > 1) {
        initials += words[words.length - 1]?.charAt(0);
      }
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]: `Utility.js:returnInitials() ${error}`,
        },
      );
    }
    return toUpper(initials);
  };

  static openSms = (message, number = '', callback = () => {}) => {
    const encodedMessage = encodeURIComponent(message);
    const url = Utility.isAndroid()
      ? `mms:${number}?body=${encodedMessage}`
      : `mms:/open?addresses=${number}&body=${encodedMessage}`;

    Linking.canOpenURL(url).then((success) => {
      if (!success) return;
      Linking.openURL(url);
      callback();
    });
  };

  static openSmsForMultipleNumbers = (
    message,
    contacts = [],
    callback = () => {},
  ) => {
    let numbers = '';
    contacts.forEach((contact) => {
      numbers += `${contact.phoneNumber},`;
    });
    numbers = numbers.slice(0, -1);
    const encodedMessage = encodeURIComponent(message);
    const url = Utility.isAndroid()
      ? `mms:${numbers}?body=${encodedMessage}`
      : `mms:/open?addresses=${numbers}&body=${encodedMessage}`;

    Linking.canOpenURL(url).then((success) => {
      if (!success) return;
      Linking.openURL(url);
      callback();
    });
  };

  static requestContactsPermission = async () => {
    return PermissionsUtility.requestPermissionAndOpenSettings(
      'contacts',
      'Permission Required',
      'Please change permissions in Settings to allow app to display contacts.',
    );
  };
}

export const getNameFromContacts = (phoneNumber, contacts) => { 
  let displayName = 'Your Friend';
  if (isPresent(phoneNumber)) {
    displayName = find(contacts, (e) => e.phoneNumber == phoneNumber)?.displayName || `Your Friend (${phoneNumber}) `;
  }
  return displayName;
};
