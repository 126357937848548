import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  View,
  Text,
  ActivityIndicator,
  Animated,
  TouchableOpacity,
  BackHandler,
  Platform,
  Linking,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import moment from 'moment';
import FastImageView from '../../components/FastImageView';
import withNavigation from '../../utils/WithNavigation';
import TransparentHeader from '../../components/Product/TransparentHeader';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import DockedHeader from '../../utils/DockedHeader';
import Utility from '../../utils/Utility';
import {
  getRoutineDetails,
  getRoutineSectionLists,
  routineOpened,
  saveCheckList,
} from '../../actions/RoutineActions';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import size from '../../theme/Fonts';
import {
  incrementDecrementCart,
  incrementDecrementCartBeforeCall,
  createRoutineParticipation,
} from '../../actions/ActionTypes';
import AboutRoutineCard from '../../components/Routines/RoutineDetails/AboutRoutineCard';

import RoutineBenefitsCard from '../../components/Routines/RoutineDetails/RoutineBenefitsCard';
import RoutineArtistDetailsCard from '../../components/Routines/RoutineDetails/RoutineArtistDetailsCard';

import PreRoutineCardPlaceholder from '../../components/Routines/ShimmerPlaceholderCards/PreRoutineCardPlaceholder';
import { Vertical } from '../../components/layout';
import {
  LOGIN_MODAL_MESSAGES,
  ORIENTATION,
  CURRENCY_SYMBOL,
  SELFIE_STATE,
  WWW_WEB_URL,
} from '../../config/Constants';

import SelfieUtilities from '../../utils/SelfieUtilities';

import RoutineCheckListCard from '../../components/Routines/RoutineDetails/RoutineCheckListCard';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { List } from '../List';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import RoutinesUtility from '../../components/Routines/RoutinesUtility';
import images from '../../theme/Images';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { StaticNavigationHeader } from '../../components/shared';
import animations from '../../theme/Animations';
import SubscribeRoutineModal from '../FoxyEdge/SubscribeRoutineModal';
import NavigationService from '../../navigator/NavigationService';
import {
  goBackWithFallbackHome,
  navigateToScreen,
} from '../../utils/NavigationUtility';
import { getRealScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import {
  isDesktop,
  isNative,
  isPresent,
  isWeb,
  isWebMobile,
} from '../../utils/BooleanUtility';
import { ScrollView } from 'react-native-gesture-handler';
import WhatsAppCard from './WhatsappCard';
import { getFirebasePerformanceTrace } from '../../utils/PerformanceUtility';

var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

const DEFAULT_ROUTINE_DAYS = 1;

class PreRoutineDetails extends Component {
  constructor(props) {
    super(props);
    const navData = this.props.route.params?.itemData;
    this.state = {
      scrollY: new Animated.Value(0),
      itemData: Utility.isPresent(navData) ? navData : {},
      isLoading: Utility.isPresent(navData) ? false : true,
      listData: {},
      addToCartLoading: false,
      checkList: [],
      serverError: false,
      retryLoading: false,
      connectionError: false,
      isVisible: false,
      isSubscribeRoutineModalVisible: false,
    };
    this.originalListData = {};
    this.canStartFirstTimeOpenFlow = false;
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.PRE_ROUTINE);
    this.trace.start();
    if (Utility.isBlank(navData)) {
      this.fetchDetails();
    } else {
      this.getSectionLists();
    }
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onBackPress,
      );
    }
  }

  componentDidMount() {
    AnalyticsManager.setCurrentScreen(
      EventParameterValue.SCREEN_NAME.MY_VIDEOS,
    );

    const { navigation } = this.props;

    this.unsubscribe = navigation.addListener('focus', () => {
      Utility.setStatusbarTranslucent();
    });
    this.unsubscribeBlur = navigation.addListener('blur', () => {
      Utility.setStatusBarWhite();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      itemData: {
        id: prevId = '',
        isUserSubscribed: isUserSubscribedPrev = false,
      },
    } = prevState;
    const {
      itemData: { id: currentId = '', isUserSubscribed = false },
    } = this.state;

    if (prevId !== currentId || isUserSubscribedPrev !== isUserSubscribed) {
      const { authToken } = this.props;
      // This condition is to start selfie/checklist flow for the user. This is to be done only once
      // when routine is opened for the first time and the user has subscription.
      if (
        !this.isRoutineOpenedEarlier(currentId) &&
        isUserSubscribed &&
        Utility.isPresent(authToken)
      ) {
        const { routineOpened } = this.props;

        routineOpened(currentId);
        this.canStartFirstTimeOpenFlow = true;
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribeBlur();
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  openCheckListPage = (checkList) => {
    const { navigation } = this.props;
    const {
      itemData: { id = '' },
    } = this.state;
    navigation.navigate('RoutineCheckList', {
      routineCheckList: checkList,
      onCheckListItemsTap: this.onCheckListItemsTap,
      id: id,
    });
  };

  openSelfiePage = (checkList) => {
    const { navigation } = this.props;
    const { itemData: { isUserSubscribed = false } = {} } = this.state;
    const screenName = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;

    SelfieUtilities.navigateToTakeSelfieWithAnalytics(screenName, navigation, {
      routineCheckList: isUserSubscribed ? checkList : null,
      onCheckListItemsTap: this.onCheckListItemsTap,
    });
  };

  isFacialAnalysisSuccess = () => {
    const {
      facialAnalysis: { analysis_stage = null },
    } = this.props;
    if (analysis_stage === SELFIE_STATE.COMPLETED) {
      return true;
    }
    return false;
  };

  isSelfieAttributesAvailable = () => {
    const { profile, facialAnalysis: { facialProperties = [] } = {} } =
      this.props;
    return (
      Utility.isPresent(profile.selfie_image_url) ||
      this.isFacialAnalysisSuccess() ||
      Utility.isPresent(facialProperties)
    );
  };

  isRoutineOpenedEarlier = (id) => {
    const { viewedRoutines } = this.props;
    if (Utility.isBlank(id)) return false;
    return viewedRoutines.includes(id);
  };

  fetchDetails = () => {
    const { getRoutineDetails, navigation, route } = this.props;
    const slug = route.params?.slug;
    getRoutineDetails(slug, this.getRoutineDetailsCallback);
  };

  getRoutineDetailsCallback = (success, response) => {
    if (!this.traceStopped) {
      this.trace.stop();
      this.traceStopped = true;
    }
    if (!success) {
      if (response.errorType === 'api_failure') {
        this.setState({
          loading: false,
          serverError: response.status === 'server_error',
          // connection_error to be added on Routines page. Handling is added here
          connectionError: response.status === 'connection_error',
        });
      } else {
        this.setState({
          loading: false,
          serverError: false,
          connectionError: true,
        });
      }
      return;
    }
    new JSONAPIDeserializer({
      typeAsAttribute: false,
      pluralizeType: true,
      keyForAttribute: 'camelCase',
    })
      .deserialize(response)
      .then((data) => {
        const {
          startsAt = '',
          endsAt = '',
          isUserSubscribed = false,
          name = '',
          id = '',
        } = data;

        this.setState({
          itemData: data,
          connectionError: false,
          retryLoading: false,
        });
        const { navigation, route, membership_cohort = '' } = this.props;
        const source = route.params?.source;
        const hideNavigation =
          source === SCREEN_CONSTANTS.DURING_ROUTINE ||
          source === SCREEN_CONSTANTS.POST_ROUTINE;
        const isExpired =
          new Date() > RoutinesUtility.getLocalTimeFromUTC(endsAt);

        if (!hideNavigation && isUserSubscribed && isExpired) {
          this.navigateToRoutineSchedule(false);
          return;
        }
        const isActive =
          RoutinesUtility.getLocalTimeFromUTC(startsAt) < new Date();
        if (!hideNavigation && isUserSubscribed && isActive) {
          this.navigateToRoutineSchedule(true);
          return;
        }
        const page = isUserSubscribed
          ? SCREEN_CONSTANTS.PRE_ROUTINE
          : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
        AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
          [EventParameterKey.PAGE]: page,
        });
        AnalyticsManager.logEvent(EventType.routine.ROUTINE_VIEW, {
          [EventParameterKey.ROUTINES.PAGE_TYPE]: page,
          [EventParameterKey.ROUTINES.ROUTINE_NAME]: name,
          [EventParameterKey.ROUTINES.ROUTINE_ID]: id,
          [EventParameterKey.ROUTINES.MEMBERSHIP_COHORT]: membership_cohort,
        });
        this.setLoading(false);
        this.getSectionLists();
      });
  };

  getSectionLists = () => {
    const { getRoutineSectionLists, navigation, route } = this.props;
    const slug = route.params?.slug;
    getRoutineSectionLists(slug, (listDataSuccess, listDataResponse) => {
      if (!this.traceStopped) {
        this.trace.stop();
        this.traceStopped = true;
      }
      if (!listDataSuccess) {
        return;
      }
      this.originalListData = JSON.parse(JSON.stringify(listDataResponse));
      this.setState({
        listData: listDataResponse,
        retryLoading: false,
        connectionError: false,
      });
      this.createCheckListData(listDataResponse);
    });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading: isLoading,
      retryLoading: isLoading,
    });
  };

  createCheckListData = (listDataResponse) => {
    const {
      itemData: { id: listId },
    } = this.state;

    if (Utility.isBlank(listId)) return;

    const { routine_products: { objects: listObjects = [] } = {} } =
      listDataResponse;

    const { savedCheckLists = {} } = this.props;

    const productsList = listObjects.filter(
      (item) => item.content === 'product',
    );

    if (Utility.isBlank(savedCheckLists)) {
      this.createCheckList(listId, productsList);
    } else {
      const savedCheckList = savedCheckLists[listId];
      if (
        Utility.isBlank(savedCheckList) ||
        productsList.length !== savedCheckList.length
      ) {
        this.createCheckList(listId, productsList);
      } else {
        console.tron.log('sorting list items');
        this.sortListObjects(savedCheckList);
      }
    }
  };

  createCheckList = (listId, productsList) => {
    const { saveCheckList } = this.props;

    const checkListItems = productsList.map((item) => ({
      name: item.name,
      id: item.id,
      isChecked: false,
    }));
    if (Utility.isPresent(checkListItems)) {
      saveCheckList(listId, checkListItems);
      this.startFirstTimeOpenFlow(listId, checkListItems);
    }
  };

  startFirstTimeOpenFlow = (listId, checkList) => {
    if (this.canStartFirstTimeOpenFlow) {
      if (Utility.isPresent(checkList)) {
        this.canStartFirstTimeOpenFlow = false;
        if (this.isSelfieAttributesAvailable()) {
          this.openCheckListPage(checkList);
        } else {
          this.openSelfiePage(checkList);
        }
      }
    }
  };

  sortListObjects = (checkListData) => {
    const { routine_products: { objects: listObjects = [] } = {} } =
      this.originalListData;
    const tempListObjects = JSON.parse(JSON.stringify(listObjects));
    const productsList = tempListObjects.filter(
      (item) => item.content === 'product',
    );

    const tempProductsList = [];
    for (let index = 0; index < productsList.length; index += 1) {
      if (!checkListData[index].isChecked) {
        tempProductsList.push(productsList[index]);
      }
    }
    for (let index = 0; index < productsList.length; index += 1) {
      if (checkListData[index].isChecked) {
        tempProductsList.push(productsList[index]);
      }
    }

    const {
      listData,
      listData: { routine_products: { objects: displayListObjects = [] } = {} },
    } = this.state;

    for (
      let objectsIndex = 0, productsListIndex = 0;
      objectsIndex < displayListObjects.length &&
      productsListIndex < productsList.length;
      objectsIndex += 1
    ) {
      if (displayListObjects[objectsIndex].content === 'product') {
        displayListObjects[objectsIndex] = tempProductsList[productsListIndex];
        productsListIndex += 1;
      }
    }
    const updatedRoutineProducts = {
      ...listData.routine_products,
      objects: displayListObjects,
    };
    const updatedListData = {
      ...listData,
      routine_products: updatedRoutineProducts,
    };
    this.setState({
      listData: updatedListData,
    });
  };

  navigateToRoutineSchedule = (isRoutineActive) => {
    const { navigation, route } = this.props;
    const slug = route.params?.slug;
    const { itemData: { name = '', id = '', startsAt = '' } = {} } = this.state;
    console.tron.log(isRoutineActive, 'isRoutineActive');

    navigateToScreen({
      navigation,
      type: 'replace',
      screen: 'DuringRoutineDetails',
      params: {
        slug: slug,
        isRoutineActive: isRoutineActive,
      },
    });
  };

  subscribeMyRoutine = () => {
    const { createRoutineParticipation, navigation, route } = this.props;

    const slug = route.params?.slug;

    this.setState({ addToCartLoading: true });

    createRoutineParticipation(
      { routine_participation: { routine_slug: slug } },
      this.subscribeRoutineCallback,
    );
  };

  closeModalAndRefresh = () => {
    this.setState({
      isVisible: false,
    });
    setTimeout(() => {
      this.fetchDetails();
    }, 0);
  };

  subscribeRoutineCallback = (success, data) => {
    this.setState({ addToCartLoading: false });
    if (success) {
      const { name = '', mrp = '', id = '' } = this.state.itemData;
      const routineMeta = {
        [EventParameterKey.ROUTINES.ROUTINE_NAME]: name,
        [EventParameterKey.ROUTINES.ROUTINE_PRICE]: mrp,
        [EventParameterKey.SOURCE]: 'routine_detail_page',
        [EventParameterKey.ROUTINES.ROUTINE_ID]: id,
      };
      AnalyticsManager.logEvent(
        EventType.routine.ROUTINE_SUBSCRIBE,
        routineMeta,
      );
      this.setState({ isVisible: true });
    }
  };

  openSubscribeRoutineModal = () => {
    this.setState({ isSubscribeRoutineModalVisible: true });
  };

  setSubscribeRoutineModalVisible = (isVisible) => {
    this.setState({
      isSubscribeRoutineModalVisible: isVisible,
    });
  };

  onSubscribeClick = () => {
    const {
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      navigation,
      cartItems,
      membership_cohort,
      route,
    } = this.props;
    const {
      itemData: {
        mrp = '',
        isUserSubscribed = false,
        cardImageUrl = '',
        startsAt = '',
      } = {},
    } = this.state;
    const isActive = RoutinesUtility.getLocalTimeFromUTC(startsAt) < new Date();

    if (isActive && membership_cohort !== 'member') {
      const screenName = isUserSubscribed
        ? SCREEN_CONSTANTS.PRE_ROUTINE
        : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
      const slug = route.params?.slug;

      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'BecomeMemberModal',
        params: {
          previousScreen: screenName,
          routineMissed: true,
          imageUrl: cardImageUrl,
          slug,
          screenType: 'Routine',
        },
      });

      return;
    }

    if (membership_cohort === 'member') {
      this.subscribeMyRoutine();
    } else {
      this.openSubscribeRoutineModal();
    }
  };

  onRoutineAddToCart = (usn, source = '') => {
    const {
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      navigation,
      cartItems,
      membership_cohort,
    } = this.props;
    const {
      itemData: {
        id = '',
        name = '',
        slug = '',
        skuId = '',
        mrp = '',
        isUserSubscribed = false,
        startsAt = '',
      } = {},
    } = this.state;
    const screen = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: screen,
      [EventParameterKey.ID]: id,
      [EventParameterKey.NAME]: name,
      [EventParameterKey.SLUG]: slug,
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.ROUTINES.MEMBERSHIP_COHORT]: membership_cohort,
    });
    if (membership_cohort === 'member') {
      this.subscribeMyRoutine();
      return;
    }
    const isActive = RoutinesUtility.getLocalTimeFromUTC(startsAt) < new Date();

    const isPresentInCart = cartItems.find((item) => item?.sku_id === skuId);
    if (isPresentInCart) {
      navigation.navigate('Cart', {
        source: SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE,
      });
      return;
    }
    this.setState({
      addToCartLoading: true,
    });
    const data = {
      sku_id: skuId,
      quantity: 1,
      source: 'cart',
    };
    incrementDecrementCartBeforeCall(data);
    incrementDecrementCart(data, (response) => {
      this.setState({
        addToCartLoading: false,
      });
      if (response?.errors?.length > 0) {
        // this.toast.show(response.errors[0]);
        return;
      }
      navigation.navigate('Cart', {
        source: SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE,
      });

      // if (source === 'product_view_modal') {
      //   this.onCloseProductModal();
      // }
    });
  };

  onCheckListItemsTap = (indexes) => {
    const {
      itemData: { id: listId },
    } = this.state;

    const { savedCheckLists = {}, saveCheckList } = this.props;

    const savedCheckList = savedCheckLists[listId];
    for (const index of indexes) {
      savedCheckList[index].isChecked = !savedCheckList[index].isChecked;
    }
    saveCheckList(listId, savedCheckList);
    this.sortListObjects(savedCheckList);
  };

  onShareTapped = () => {
    const {
      itemData: {
        id,
        skuId,
        name = '',
        isUserSubscribed = false,
        shareUrl = '',
      } = {},
    } = this.state;
    const message = isUserSubscribed
      ? `Check out this amazing routine I am participating in : ${name}. Let's join it together & help each other achieve our goals ✨`
      : `Check out this routine I am interested in -  ${name}`;
    const generalShareOption = {
      title: 'Foxy',
      message,
      url: shareUrl,
    };
    const screen = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.ACTION]: 'share',
      [EventParameterKey.NAME]: name,
      [EventParameterKey.ID]: id,
      [EventParameterKey.SCREEN_NAME]: screen,
      [EventParameterKey.SKU_ID]: skuId,
    });

    Utility.onShareTapped(shareUrl, generalShareOption);
  };

  backgroundImage = () => {
    const {
      itemData: { name = '', cardImageUrl = '', bannerImageUrl = '' } = {},
      isLoading = false,
    } = this.state;
    if (isLoading) {
      return (
        <ShimmerPlaceHolder
          autoRun
          style={styles.backgroundImage}
          colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
        />
      );
    }
    if (isDesktop()) {
      return (
        <FastImageView
          source={Utility.getMinifiedImage(
            bannerImageUrl,
            Utility.getScreenWidth(),
            Utility.getScreenHeight() / 2,
          )}
          style={styles.desktopBackgroundImage}
        />
      );
    }
    return (
      <FastImageView
        source={Utility.getMinifiedImage(
          cardImageUrl,
          Utility.getScreenWidth(),
          Utility.getScreenWidth(),
        )}
        style={styles.backgroundImage}
      />
    );
  };

  conditionalRoutineScrollView = () => {
    const {
      itemData: { isUserSubscribed = false },
    } = this.state;
    return isUserSubscribed
      ? this.routineScrollViewContentSubsribed()
      : this.routineScrollViewContentUnsubscribed();
  };

  checkIfRoutineStartTimeExceeded = () => {
    const { itemData: { startsAt = '', endsAt = '' } = {} } = this.state;
    return RoutinesUtility.getLocalTimeFromUTC(endsAt) <= new Date();
  };

  onPressSubscribeRoutine = () => {
    const { authToken } = this.props;

    if (this.checkIfRoutineStartTimeExceeded()) {
      return;
    }
    if (Utility.isBlank(authToken)) {
      this.invokeLoginFlow();
      Utility.furtherAction = {
        action: this.onSubscribeClick,
      };
    } else {
      this.onSubscribeClick();
    }
  };

  invokeLoginFlow = () => {
    const { setPhoneNumberModalInfo } = this.props;
    setPhoneNumberModalInfo(Utility.getLoginModalInfo('DEFAULT'));
    NavigationService.renderOnboarding();
  };

  routineScrollViewContentUnsubscribed = () => {
    const {
      itemData: {
        name = '',
        mrp = '',
        description = '',
        participantCount = '',
        startsAt = '',
        endsAt = '',
        whatYouGet = '',
        artist: artistDetails = {},
        userAttributeAllowedValues = [],
        isUserSubscribed = false,
        routineDays = [],
        bannerImageUrl,
      },
      isLoading = false,
      listData: {
        hero_video_list: heroVideo,
        hero_video_list: {
          id: videoListId,
          display: videoListDisplay = '',
          objects: videoListObjects = [],
          content: videoListContent = '',
          type: videoType = '',
          size: videoListSize = '',
        } = {},
        routine_features: routineFeatures,
        routine_features: {
          id: featureListId,
          display: featureListDisplay = '',
          content: featureListContent = '',
          type: featureListType = '',
          size: featureListSize = '',
        } = {},
      },
    } = this.state;

    console.log('----------State', this.state);

    const routineStartTimeExceeded =
      RoutinesUtility.getLocalTimeFromUTC(startsAt) <= new Date();

    if (isLoading) {
      return <this.routineScrollViewContentPlaceholder />;
    }
    const tags = userAttributeAllowedValues.slice(0, 4);
    return (
      <View style={styles.listContainer} dataSet={{ media: ids.listContainer }}>
        <View style={styles.backgroundUnsubscribed}>
          {isDesktop() && <this.backgroundImage />}
          <Text style={styles.title}>{name}</Text>
          <this.Tags tags={tags} />
          <AboutRoutineCard
            description={description}
            participants={participantCount}
            mrp={mrp}
            startsAt={startsAt}
            duration={routineDays.length || DEFAULT_ROUTINE_DAYS}
            isUserSubscribed={isUserSubscribed}
          />
          <View style={styles.separater} />
          <List
            id={videoListId}
            orientation={ORIENTATION.VERTICAL}
            layout={videoListDisplay}
            listContent={videoListContent}
            itemData={heroVideo}
            size={videoListSize}
            refreshing={false}
            type={videoType}
            previousScreen={SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE}
            shimmerDisplay={'mediaRail'}
          />
          <View style={styles.separater} />
          <RoutineArtistDetailsCard artist={artistDetails} />
          <WhatsAppCard
            imageUrl={bannerImageUrl}
            onClickJoinButton={this.onTapJoinWhatAppGroup}
            show={isDesktop()}
          />
          <View style={styles.separater} />
          <RoutineBenefitsCard htmlData={whatYouGet} />
          {!routineStartTimeExceeded && (
            <List
              id={featureListId}
              orientation={ORIENTATION.VERTICAL}
              layout={featureListDisplay}
              listContent={featureListContent}
              itemData={routineFeatures}
              size={featureListSize}
              refreshing={false}
              type={featureListType}
              previousScreen={SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE}
              isRoutineSubscribed={false}
              onPress={this.onPressSubscribeRoutine}
              shimmerDisplay={'featureRail'}
            />
          )}
          <this.productLists />
        </View>
      </View>
    );
  };

  productLists = () => {
    const {
      itemData: { isUserSubscribed = false } = {},
      listData: {
        routine_products: {
          id: listId,
          display: listDisplay = '',
          objects: listObjects = [],
          content: listContent = '',
          size: listSize = '',
        } = {},
      },
      listData = {},
    } = this.state;
    if (Utility.isBlank(listObjects)) return null;
    const screenName = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    return (
      <>
        <FastImageView
          source={images.routines.products_recommended}
          style={styles.productBanner}
        />
        <Vertical
          id={listId}
          orientation={ORIENTATION.VERTICAL}
          display={listDisplay}
          content={listContent}
          item={listObjects}
          size={listSize}
          refreshing={false}
          previousScreen={screenName}
          listData={listData}
        />
      </>
    );
  };

  routineScrollViewContentSubsribed = () => {
    const {
      itemData: {
        isUserSubscribed,
        name = '',
        mrp = '',
        description = '',
        participantCount = '',
        startsAt = '',
        endsAt = '',
        artist: artistDetails = {},
        userAttributeAllowedValues = [],
        id: routineId,
        whatYouGet = '',
        routineDays = [],
        bannerImageUrl,
      },
      isLoading = false,
      listData: {
        hero_video_list: heroVideo,
        hero_video_list: {
          id: videoListId,
          display: videoListDisplay = '',
          content: videoListContent = '',
          type: videoType = '',
          size: videoListSize = '',
        } = {},
        routine_features: routineFeatures,
        routine_features: {
          id: featureListId,
          display: featureListDisplay = '',
          content: featureListContent = '',
          type: featureListType = '',
          size: featureListSize = '',
        } = {},
      },
    } = this.state;
    if (isLoading) {
      return <this.routineScrollViewContentPlaceholder />;
    }
    const { savedCheckLists = {} } = this.props;
    const currentCheckList = savedCheckLists[routineId];
    const tags = userAttributeAllowedValues.slice(0, 4);
    const screen = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    return (
      <View style={styles.listContainer} dataSet={{ media: ids.listContainer }}>
        <View style={styles.background}>
          {isDesktop() && <this.backgroundImage />}
          <Text style={styles.title}>{name}</Text>
          <this.Tags tags={tags} />
          <AboutRoutineCard
            description={description}
            participants={participantCount}
            mrp={mrp}
            startsAt={startsAt}
            duration={routineDays.length || DEFAULT_ROUTINE_DAYS}
            isUserSubscribed={isUserSubscribed}
          />
          <RoutineBenefitsCard htmlData={whatYouGet} />
          <RoutineCheckListCard
            listData={currentCheckList}
            onCheckListItemsTap={this.onCheckListItemsTap}
            routineId={routineId}
          />
          <this.productLists />

          <View style={styles.separater} />
          <RoutineArtistDetailsCard artist={artistDetails} />
          <WhatsAppCard
            imageUrl={bannerImageUrl}
            onClickJoinButton={this.onTapJoinWhatAppGroup}
            show={isDesktop()}
          />
          <List
            id={videoListId}
            orientation={ORIENTATION.VERTICAL}
            layout={videoListDisplay}
            listContent={videoListContent}
            itemData={heroVideo}
            size={videoListSize}
            refreshing={false}
            type={videoType}
            previousScreen={screen}
            shimmerDisplay={'mediaRail'}
          />

          {isWeb() && <View style={{ height: 100 }} />}
        </View>
      </View>
    );
  };

  routineScrollViewContentPlaceholder = () => {
    return (
      <View style={[styles.listContainer, { paddingBottom: 0 }]}>
        <View style={styles.background}>
          <ShimmerPlaceHolder
            autoRun
            style={styles.titlePlaceholder}
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          />
          <PreRoutineCardPlaceholder />
          <View style={styles.separater} />
          <PreRoutineCardPlaceholder />
          <View style={styles.separater} />
        </View>
      </View>
    );
  };

  subscribeRoutine = () => {
    const {
      itemData: {
        mrp = '',
        isUserSubscribed = false,
        startsAt = '',
        endsAt = '',
      } = {},
      isLoading,
      addToCartLoading,
    } = this.state;
    const { membership_cohort } = this.props;
    if (isLoading || isUserSubscribed) {
      return null;
    }
    const routineStartTimeExceeded = this.checkIfRoutineStartTimeExceeded();

    const priceText = routineStartTimeExceeded
      ? 'Subscriptions closed'
      : `${CURRENCY_SYMBOL} ${mrp}`;
    const buttonContainerStyle = routineStartTimeExceeded
      ? styles.subscriptionButtonDisabledContainer
      : styles.subscriptionButtonContainer;
    return (
      <View style={styles.subscriptionContainer}>
        {membership_cohort === 'member' && !routineStartTimeExceeded ? (
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.priceFreeText}>FREE</Text>
              <Text
                style={styles.priceTextLineThrough}
              >{` ${CURRENCY_SYMBOL}${mrp}`}</Text>
            </View>

            <Text style={styles.priceTextSubtitle}>for Edge members</Text>
          </View>
        ) : (
          <Text
            style={
              routineStartTimeExceeded
                ? styles.priceTextNormal
                : styles.priceText
            }
          >
            {priceText}
          </Text>
        )}

        <TouchableOpacity
          style={buttonContainerStyle}
          onPress={this.onPressSubscribeRoutine}
          disabled={routineStartTimeExceeded}
        >
          {!addToCartLoading && (
            <Text style={styles.subscriptionButtonText}>Subscribe</Text>
          )}
          {addToCartLoading && (
            <ActivityIndicator animating color={colors.white} size='small' />
          )}
        </TouchableOpacity>
      </View>
    );
  };

  onTapJoinWhatAppGroup = () => {
    const { itemData: { routineDiscussionGroups = [] } = {} } = this.state;
    if (isPresent(routineDiscussionGroups)) {
      const inviteLink = routineDiscussionGroups[0]?.inviteLink;
      Linking.openURL(inviteLink);
    }
  };

  onChatTapped = () => {
    const { navigation } = this.props;
    const {
      itemData: {
        name = '',
        skuId = '',
        isUserSubscribed = false,
        routineDiscussionGroups = [],
        cardImageUrl = '',
        id: routineId = '',
      } = {},
    } = this.state;
    navigation.navigate('RoutineGroups', {
      routineGroups: routineDiscussionGroups,
      cardImageUrl,
      routineId,
    });
    const screen = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.ACTION]: 'chat',
      [EventParameterKey.NAME]: name,
      [EventParameterKey.ID]: routineId,
      [EventParameterKey.SCREEN_NAME]: screen,
      [EventParameterKey.SKU_ID]: skuId,
    });
  };

  tag = ({ imageUrl = '', finalName = '' }) => {
    return (
      <View style={styles.tag}>
        {Utility.isPresent(imageUrl) && (
          <FastImageView
            source={Utility.getMinifiedImage(imageUrl, 20, 20)}
            style={styles.tagIcon}
          />
        )}
        <Text style={styles.tagText}>{finalName}</Text>
      </View>
    );
  };

  Tags = ({ tags = [] }) => {
    if (Utility.isBlank(tags)) return null;
    return <View style={styles.tagsContainer}>{tags.map(this.tag)}</View>;
  };

  onBackPress = () => {
    const { navigation } = this.props;
    goBackWithFallbackHome(navigation);
    Utility.setStatusBarWhite();
    return true;
  };

  onPressReload = () => {
    this.setState(
      {
        retryLoading: true,
      },
      this.fetchDetails,
    );
  };

  render() {
    const {
      serverError,
      connectionError,
      retryLoading,
      isVisible,
      itemData: {
        name = '',
        isUserSubscribed = false,
        routineDiscussionGroups = [],
        cardImageUrl = '',
      } = {},
      isSubscribeRoutineModalVisible,
    } = this.state;
    const { authToken, navigation, route } = this.props;

    const screenName = isUserSubscribed
      ? SCREEN_CONSTANTS.PRE_ROUTINE
      : SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE;

    const slug = route.params?.slug;

    return (
      <>
        {connectionError && <StaticNavigationHeader title={name} />}
        <ErrorBoundary
          showServerError={serverError}
          onPressReload={this.onPressReload}
          connectionError={connectionError}
          loading={retryLoading}
          // hideHeader={false}
        >
          <View style={styles.container}>
            {!isDesktop() && (
              <TransparentHeader
                scrollY={this.state.scrollY}
                screenName={screenName}
                goBack={this.onBackPress}
                onShareTapped={this.onShareTapped}
                onChatTapped={this.onChatTapped}
                hideChatButton={
                  routineDiscussionGroups.length === 0 ||
                  Utility.isBlank(authToken)
                }
              >
                <DockedHeader name={'Routine Details'} />
              </TransparentHeader>
            )}
            {isNative() && <this.backgroundImage />}
            <ScrollView
              onScroll={Animated.event([
                { nativeEvent: { contentOffset: { y: this.state.scrollY } } },
              ])}
              style={styles.scrollView}
              showsVerticalScrollIndicator={false}
              bounces={false}
            >
              <>
                {isWebMobile() && <this.backgroundImage />}
                <this.conditionalRoutineScrollView />
              </>
              {isWeb() && <View style={styles.emptyView} />}
            </ScrollView>
            <this.subscribeRoutine />

            <SubscribeRoutineModal
              isModalVisible={isSubscribeRoutineModalVisible}
              setModalVisible={this.setSubscribeRoutineModalVisible}
              previousScreen={'previousScreen'}
              secondaryAction={this.onRoutineAddToCart}
              onRoutineAddToCart
              routineMissed
              slug={slug}
              imageUrl={cardImageUrl}
              screenType={'Routine'}
            />

            <FoxyAlert
              isVisible={isVisible}
              hideSecondButton
              alertBoxTitle={'CONGRATS'}
              alertMessage={'Routine subscribed successfully'}
              firstButtonTitle={'View my routine'}
              firstButtonOnPress={this.closeModalAndRefresh}
              onTapOutside={this.closeModalAndRefresh}
              autoWrapContent
              firstButtonTextColor={colors.cta.lightBlue}
              showJson
              animation={animations.tick_animation}
              animationStyle={{
                height: 100,
                width: 100,
                alignSelf: 'center',
                marginBottom: 12,
              }}
            />
          </View>
        </ErrorBoundary>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getRoutineDetails,
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      getRoutineSectionLists,
      routineOpened,
      saveCheckList,
      setPhoneNumberModalInfo,
      createRoutineParticipation,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  viewedRoutines: state.routines.viewedRoutines,
  savedCheckLists: state.routines.checkList,
  profile: state.UserAccountInfo.profile,
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  cartItems: state.bag.cartItems,
  authToken: state.UserAccountInfo.authToken,
  membership_cohort: state.todayDeals.membership_cohort,
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PreRoutineDetails),
);

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  tag: {
    flexDirection: 'row',
    paddingVertical: 4,
    borderRadius: 12,
    margin: 4,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  tagsContainer: {
    // flex: 1,
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 12,
  },
  tagIcon: { height: 14, width: 14, marginRight: 8 },
  tagText: {
    fontFamily: 'Roboto',
    fontSize: size.h3_5,
    color: colors.black,
  },
  backgroundImage: {
    width: Utility.getScreenWidth(),
    height: Utility.getScreenWidth(),
    resizeMode: 'contain',
  },
  desktopBackgroundImage: {
    width: Utility.getScreenWidth(),
    height: Utility.getScreenHeight() / 2,
    resizeMode: 'contain',
  },
  listContainer: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    width: Utility.getScreenWidth(),
    marginTop: isWeb() ? 0 : Utility.getScreenWidth() - 30,
    paddingBottom: 70,

    '@media (min-width: 768px)': {
      paddingBottom: 250,
      marginTop: 0,
    },
  },
  background: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingHorizontal: 12,
    paddingTop: 12,
    height: '100%',
    backgroundColor: colors.background,
  },
  backgroundUnsubscribed: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingHorizontal: 12,
    paddingBottom: 12,
    paddingTop: 12,
    height: '100%',
    backgroundColor: colors.background,
  },
  scrollView: {
    height: Utility.getScreenHeight() - (Utility.isIOS() ? 0 : 12),

    zIndex: 1,
    ...Platform.select({
      web: {},
      default: {
        position: 'absolute',
      },
    }),
  },
  header: {
    position: 'absolute',
    top: 0,
  },
  title: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    alignSelf: 'center',
    fontSize: 24,
    marginBottom: 12,
  },
  subscriptionContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    ...Platform.select({
      android: { paddingBottom: 0 },
      ios: { paddingBottom: 22 },
      web: { paddingBottom: 10 },
    }),
  },
  priceText: {
    color: colors.foxyBlack,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
  },
  priceTextNormal: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
  },
  priceTextLineThrough: {
    color: colors.foxyBlack,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    textDecorationLine: 'line-through',
    marginLeft: 8,
  },
  priceFreeText: {
    color: colors.green,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
  },
  priceTextSubtitle: {
    color: colors.subtitle,
    fontSize: size.h3_5,
    fontFamily: 'Roboto-Regular',
  },
  subscriptionButtonContainer: {
    width: 180,
    alignItems: 'center',
    paddingVertical: 12,
    backgroundColor: colors.green,
    borderRadius: 8,
  },
  subscriptionButtonDisabledContainer: {
    width: 180,
    alignItems: 'center',
    paddingVertical: 12,
    backgroundColor: colors.darkGrey,
    borderRadius: 8,
  },
  subscriptionButtonText: {
    color: colors.white,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
  },
  separater: {
    height: 12,
  },
  titlePlaceholder: {
    height: 28,
    width: '80%',
    alignSelf: 'center',
    marginBottom: 12,
  },
  productListsTitle: {
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    marginTop: 18,
  },
  productListsSubtitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    marginTop: 4,
  },
  productBanner: {
    resizeMode: 'contain',
    borderRadius: 4,
    height: 100,
    marginTop: 20,
    ...Platform.select({
      web: {},
      default: { flex: 1 },
    }),
  },

  emptyView: {
    height: 150,
  },
});
