import React from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Modal from 'react-native-modal';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import { getScreenWidth } from '../../utils/LayoutUtility';

export default function FoxyPromiseModal({
  index = 0,
  promises = [],
  showModal = false,
  hideModal = () => {},
  setIndex = () => {},
}) {
  const handleNext = () => {
    if (index < promises.length - 1) {
      setIndex(index + 1);
    }
  };
  const handlePrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };
  const promise = promises[index] || {};
  const {
    heading,
    subheading,
    image,
    isOriginal = false,
  } = promise;
  return (
    <Modal
      isVisible={showModal}
      style={styles.modalContainer}
      onBackdropPress={hideModal}
    >
      {index !== 0 && promises.length > 1 && (
        <TouchableOpacity style={[styles.arrow, styles.leftArrow]} onPress={handlePrevious}>
          <Image style={styles.arrowImage} source={images.chevronLeft} />
        </TouchableOpacity>
      )}
      {(index !== promises.length - 1) && (promises.length > 1) && (
        <TouchableOpacity style={[styles.arrow, styles.rightArrow]} onPress={handleNext}>
          <Image style={styles.arrowImage} source={images.chevronRight} />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        <TouchableOpacity
          style={styles.crossButtonContainer}
          onPress={hideModal}
        >
          <Image style={styles.crossButton} source={images.popupClose} />
        </TouchableOpacity>
        <View style={styles.content}>
          {!isOriginal && <Image style={styles.image} source={{ uri: image }} />}
          <Text style={styles.certificateHeader}>{heading}</Text>
          <Text style={styles.certificateSubtitle}>
            {subheading}
          </Text>
          {isOriginal && <Image style={styles.originalImage} source={{ uri: image }} />}
        </View>

        <TouchableOpacity
          style={styles.buttonContainer}
          dataSet={{ media: ids.buttonContainer }}
          onPress={hideModal}
        >
          <Text style={styles.buttonText}>Got it!</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
}

const { ids, styles } = StyleSheet.create({
  modalContainer: {
    height: '100%',
  },
  container: {
    backgroundColor: colors.white,
    borderRadius: 4,
    flexDirection: 'column',
    alignItems: 'center',
    width: getScreenWidth(),
    alignSelf: 'center',
    paddingVertical: 30,
    paddingHorizontal: 24,
    '@media (min-width: 768px)': {
      width: 480,
    },
  },
  crossButtonContainer: {
    position: 'absolute',
    height: 23,
    width: 23,
    right: 12,
    top: 12,
  },
  crossButton: {
    height: 23,
    width: 23,
    position: 'absolute',
  },
  buttonContainer: {
    backgroundColor: colors.black,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 50,
    paddingVertical: 4,
    marginTop: 20,
    width: getScreenWidth() - 100,
    '@media (min-width: 768px)': {
      width: 480 - 100,
    },
  },
  buttonText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: colors.white,
  },
  image: {
    width: 120,
    height: 120,
    marginBottom: 30,
  },
  originalImage: {
    width: 382,
    height: 382,
  },
  certificateHeader: {
    fontSize: 20,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
    fontWeight: '700',
  },
  certificateSubtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
    marginTop: 8,
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: '400',
  },
  content: {
    alignItems: 'center',
    marginTop: 50,
    paddingHorizontal: 25,
  },
  arrow: {
    width: 60,
    height: 60,
    borderRadius: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    position: 'absolute',
    top: '50%',
    marginTop: -30,
  },
  arrowImage: {
    height: 20,
    width: 20,
    tintColor: '#979BAA',
  },
  leftArrow: {
    left: '50%',
    marginLeft: -330,
  },
  rightArrow: {
    right: '50%',
    marginRight: -330,
  },
});
