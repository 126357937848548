import React, { PureComponent } from 'react';
import { View } from 'react-native';

export default class CardView extends PureComponent {
  render() {
    const {
      cardElevation,
      cornerRadius,
      shadowOpacity = 0.24,
      style,
      children,
    } = this.props;
    if (cardElevation > 0) {
      return (
        <View
          style={[
            {
              boxShadow: `0px ${cardElevation}px ${cardElevation}px rgba(0, 0, 0, ${shadowOpacity})`,
              borderRadius: cornerRadius,
            },
            style,
          ]}
        >
          {children}
        </View>
      );
    }
    return (
      <View
        style={[
          {
            borderRadius: cornerRadius,
          },
          style,
        ]}
      >
        {children}
      </View>
    );
  }
}
