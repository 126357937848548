import { isDesktop } from '../../utils/BooleanUtility';
import Utility from '../../utils/Utility';

export const brandConstants = {
  listViewHeight: 64,
  smallRailViewHeight: 92,
  largeRailViewHeight: 120,
  defaultImageWidth: 92,
  NUM_OF_CARDS: {
    LIST: isDesktop() ? 2 : 1,
    GRID: isDesktop() ? 4 : 2,
  },
  SPACING: {
    LIST: 0,
    GRID: 16,
  },
};
export const BRAND = {
  CARD: {
    list: {
      defaultSize: {
        viewwidth: Utility.getScreenWidth() - 24,
        viewheight: Utility.listDefaultViewHeight,
        imagewidth: brandConstants.defaultImageWidth,
        imageheight: brandConstants.defaultImageWidth,
      },
    },
    rail: {
      small: {
        viewwidth: brandConstants.smallRailViewHeight,
        viewheight: brandConstants.smallRailViewHeight,
        imagewidth: brandConstants.smallRailViewHeight,
        imageheight: brandConstants.smallRailViewHeight,
      },
      large: {
        viewwidth: brandConstants.largeRailViewHeight,
        viewheight: brandConstants.largeRailViewHeight,
        imagewidth: brandConstants.largeRailViewHeight,
        imageheight: brandConstants.largeRailViewHeight,
      },
      defaultSize: {
        viewwidth: brandConstants.largeRailViewHeight,
        viewheight: brandConstants.largeRailViewHeight,
        imagewidth: brandConstants.largeRailViewHeight,
        imageheight: brandConstants.largeRailViewHeight,
      },
    },
    grid: {
      defaultSize: {
        viewwidth: Utility.getDynamicWidthForGrid(
          brandConstants.NUM_OF_CARDS.GRID,
          brandConstants.SPACING.GRID,
        ),
        viewheight: Utility.getDynamicWidthForGrid(
          brandConstants.NUM_OF_CARDS.GRID,
          brandConstants.SPACING.GRID,
        ),
        imagewidth: Utility.getDynamicWidthForGrid(
          brandConstants.NUM_OF_CARDS.GRID,
          brandConstants.SPACING.GRID,
        ),
        imageheight: Utility.getDynamicWidthForGrid(
          brandConstants.NUM_OF_CARDS.GRID,
          brandConstants.SPACING.GRID,
        ),
      },
    },
    SCREEN: {},
  },
};
