import React, { PureComponent } from 'react';
import { View, StyleSheet, Image, Text } from 'react-native';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from '../Product/ProductImageAndRating';

import ProductDescription from '../Product/ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AddToCart from '../Product/AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import SkuColorVariant from './SkuColorVariant';
import SkuSizeVariant from './SkuSizeVariant';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { applyFlashDeal } from '../../actions/ActionTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TouchableOpacity } from 'react-native-gesture-handler';
import GridStyles from '../layout/Grid/styles';
import images from '../../theme/Images';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

// import SkuListItem from './SkuListItem';     //have to add this

const numOfItems = isDesktop() ? 4 : 2;

class DefaultSizeVariantGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData: { product_slug: slug, sku_id, id },
      toggleCartVisibility,
      applyFlashDeal,
      previousScreen,
    } = this.props;

    // if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
    applyFlashDeal({
      type: 'Variant',
      id,
    });
    // }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        slug: `/api/v2/products/${slug}.json`,
        display: LAYOUT.SCREEN,
        toggleCartVisibility,
        sku_id,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData,
      listId,
      listData = {},
      layout,
      previousScreen,
      listName,
      listContent,
      toggleUnavailableProductsModal,
      extraEventParameters = {},
    } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      itemData.id,
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      itemData.id,
      itemData?.slug,
      listData?.slug,
      extraEventParameters,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      layout,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
      prompts,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData = {},
      listData: { additional_data = {} } = {},
    } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.PERSONALISEDRAIL}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        prompts={prompts}
        singleStockedVariantSku={singleStockedVariantSku}
        additional_data={additional_data}
        listData={listData}
      />
    );
  };

  skuSizeCondition = ({ variantName }) => Utility.isPresent(variantName);

  conditionalVariants = withEither(
    this.skuSizeCondition,
    SkuSizeVariant,
  )(SkuColorVariant);

  skuVariants = (props) => {
    const { variantName, imageUrl, packSize, containerStyle } = props;

    return (
      <this.conditionalVariants
        variantShade={variantName}
        variantImage={imageUrl}
        packSize={packSize}
        containerStyle={containerStyle}
      />
    );
  };

  productCardRightTopIcon = () => {
    const {
      itemData: { id },
      showEditButton,
      removeFromList,
      itemData,
    } = this.props;

    if (!showEditButton) {
      return null;
    }

    return (
      <View style={GridStyles.trashIconContainer}>
        <TouchableOpacity
          onPress={() => removeFromList(itemData)}
          hitSlop={Utility.getHitSlop()}
        >
          <Image
            source={images.previewScreenIcons.delete}
            style={GridStyles.trashIcon}
          />
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hasVariants,
      hideAddToCart,
      itemData: {
        offer_value,
        final_sp,
        additional_discount,
        type,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        sku_id: skuId,
        pack_size,
        product_name,
        display_name,
        image_url,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
        prompts = [],
      },
      showEditButton,
    } = this.props;

    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const display_price = final_sp;

    return (
      <View>
        <ScaleAnimate
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
        >
          <Card style={styles.cardContainer}>
            <View style={styles.imageContainer}>
              <ProductImageAndRating
                layout={LAYOUT.GRID}
                rating={rating}
                images={images}
                webp_images={webp_images}
                image_webp_url={image_webp_url}
                imageUrl={imageUrl}
                id={id}
                name={name}
                previousScreen={previousScreen}
                isVariant
                itemData={itemData}
                showEditButton={showEditButton}
              />
            </View>
            <View style={styles.fullCardDivider} />
            <View style={{ flex: 1 }}>
              <ProductDescription
                itemData={itemData}
                name={product_name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={variantBrandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.GRID}
                id={id}
                previousScreen={previousScreen}
                prompts={prompts}
              />
            </View>
            <View style={styles.cartImageContainer}>
              <this.addToCart
                skuId={skuId}
                orientation={orientation}
                toggleCartState={this.toggleCartState}
                layout={LAYOUT.GRID}
                addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
                hideAddToCart={hideAddToCart}
                id={id}
                itemData={itemData}
                hasVariants={hasVariants}
                showCart={this.showCart}
                priority={priority}
                outOfStock={outOfStock}
                previousScreen={previousScreen}
                prompts={prompts}
                singleStockedVariantSku={singleStockedVariantSku}
              />
            </View>
          </Card>

          <this.skuVariants
            variantName={display_name}
            imageUrl={image_url}
            packSize={pack_size}
            layout={LAYOUT.GRID}
            containerStyle={styles.variantContainerStyle}
          />
        </ScaleAnimate>
        <this.productCardRightTopIcon />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: Utility.getDynamicWidthForGrid(numOfItems, 16),
    height: Utility.getDynamicWidthForGrid(numOfItems, 16) + 116,
    alignSelf: 'center',
  },
  imageContainer: {
    height: Utility.getDynamicWidthForGrid(numOfItems, 16 - 32),
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 78,
    right: 10,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 80,
    backgroundColor: '#ffffff',
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      applyFlashDeal,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(DefaultSizeVariantGrid),
);
