import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
} from 'react-native';
import { AnalyticsManager, EventParameterKey, EventParameterValue, EventType } from '../../../analytics';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';
import FastImageView from '../../FastImageView';

class RoutineCheckListCard extends Component {
  onItemPress = (item, index) => {
    const { onCheckListItemsTap, routineId } = this.props;
    const { name, isChecked } = item;
    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.TYPE]: EventParameterValue.ITEM_TYPE.CHECKLIST,
      [EventParameterKey.NAME]: name,
      [EventParameterKey.ENTITY]: 'routine',
      [EventParameterKey.ENTITY_ID]: routineId,
      [EventParameterKey.ROUTINES.IS_CHECKED]: !isChecked,
    });
    onCheckListItemsTap([index]);
  };

  checkListItem = ({ item, index }) => {
    const { name = '', isChecked = false } = item;
    const icon = isChecked
      ? images.routines.checkedIcon
      : images.routines.uncheckedIcon;
    return (
      <TouchableOpacity
        onPress={() => this.onItemPress(item, index)}
        style={styles.listItemContainer}
      >
        <FastImageView source={icon} style={styles.listItemIcon} />
        <Text style={styles.listItemText}>{name}</Text>
      </TouchableOpacity>
    );
  };

  render() {
    const { listData = [] } = this.props;
    if (Utility.isBlank(listData)) {
      return null;
    }
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Your Routine Checklist</Text>
        <Text style={styles.subtitle}>
          Check off the products from the list that you already have
        </Text>
        <FlatList
          data={listData}
          renderItem={this.checkListItem}
          keyExtractor={(item, index) => `${index}`}
          extraData={this.props}
          style={{
            width: '100%',
            backgroundColor: colors.white,
          }}
        />
      </View>
    );
  }
}

export default RoutineCheckListCard;

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    width: '100%',
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingTop: 20,
    paddingBottom: 16,
    alignItems: 'flex-start',
    marginTop: 8,
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    marginBottom: 4,
  },
  subtitle: {
    color: colors.subtitle,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginBottom: 8,
  },
  listItemContainer: {
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: colors.routine_card_bg,
    marginVertical: 4,
  },
  listItemText: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginLeft: 8,
  },
  listItemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
});
