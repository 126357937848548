import React from 'react';
import { View, Text, ScrollView } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useApiCall } from '../../lib/hooksUtil';
import WebFooter from '../../components/webFooter/WebFooter';
import { getScreenWidth } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';
import List from './List';
import { StaticNavigationHeader } from '../../components/shared';
import ShopOnlineProductCard from '../../components/Product/ShopOnlineProductCard';
import ShopOnlineRating from '../../components/productRatings/ShopOnlineRating';
import ShopOnlineListPlaceholder from './ShopOnlineListPlaceholder';

export default function ShopOnline({ url }) {
  const { data, isLoading } = useApiCall(url);
  const {
    products_list: productsList = {},
    media_list: mediaList = {},
    ratings = [],
    name: listName,
  } = data;
  const { name: listTitle, content, objects: products = [] } = productsList;
  const { objects: media = [] } = mediaList;
  if (isLoading) {
    return <ShopOnlineListPlaceholder />;
  }
  return (
    <ScrollView>
      <StaticNavigationHeader showCart showSearchButton showLogo />
      <View style={styles.listContainer}>
        <Text style={styles.listTitle} dataSet={{ media: ids.listTitle }}>{listName || listTitle}</Text>
        <View style={styles.productContainer}>
          {content === 'product' && products.map(product => <ShopOnlineProductCard itemData={product} key={product.id} listData={data} />)}
        </View>
        {media.length > 0 && <Text style={styles.title}>Honest Reviews</Text>}
        {media.length > 0 && <List itemData={mediaList} />}
        {ratings.length > 0 && <Text style={styles.title}>Latest Reviews</Text>}
        {ratings.map(rating => <ShopOnlineRating rating={rating} key={rating.id} />)}
      </View>
      <WebFooter />
    </ScrollView>
  );
}

const { ids, styles } = StyleSheet.create({
  listContainer: {
    flex: 1,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  listTitle: {
    backgroundColor: colors.white,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginBottom: 12,
    color: colors.foxyBlack,
    fontSize: 18,
    fontWeight: '700',
    fontFamily: 'Roboto-Medium',
    '@media (min-width: 768px)': {
      marginTop: 20,
    },
  },
  title: {
    backgroundColor: colors.background,
    paddingTop: 30,
    paddingHorizontal: 12,
    paddingBottom: 12,
    color: colors.foxyBlack,
    fontSize: 18,
    fontWeight: '700',
    fontFamily: 'Roboto-Medium',
  },
  productContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center',
    width: getScreenWidth(),
    backgroundColor: colors.background,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
