import { NativeModules } from 'react-native';
import { isAndroid, isIOS } from './BooleanUtility';

export default class BGNetworkTaskManager {
  static downloadedVideoKeys = [];

  static updateDownloadedVideoKeys = (videoKeys) => {
    this.downloadedVideoKeys = videoKeys;
  };

  static removeDownloadedVideoKeys = () => {
    this.downloadedVideoKeys = null;
  };

  static getMediaAssetPresentStatus = (mediaKey) => {
    if (isIOS()) {
      NativeModules.BGNetworkTaskHandler.getMediaAssetPresentStatus(mediaKey);
    }
  };

  static prefetchMedia = (videoUrls) => {
    if (isIOS()) {
      NativeModules.BGNetworkTaskHandler.startMediaDownloads(videoUrls);
    }
    if (isAndroid()) {
      let urls = '';
      videoUrls.forEach((element) => {
        urls += `${element},`;
      });
      NativeModules.CacheManager.saveStoryUrls(urls);
    }
  };

  static saveGuestAuthToken = (guestToken) => {
    if (isIOS()) {
      NativeModules.BGNetworkTaskHandler.saveGuestAuthToken(guestToken);
    }
  };

  static saveAuthToken = (authToken) => {
    if (isIOS()) {
      NativeModules.BGNetworkTaskHandler.saveAuthToken(authToken);
    }
  };

  static deleteAuthToken = () => {
    if (isIOS()) {
      NativeModules.BGNetworkTaskHandler.removeAuthToken();
    }
  };

  static setVideoMaxDownloadLimit = (limit) => {
    if (isIOS()) {
      NativeModules.BGNetworkTaskHandler.setVideoMaxDownloadLimit(limit);
    }
    if (isAndroid()) {
      NativeModules.CacheManager.saveMaxCacheLimit(limit);
    }
  };

  static saveStoryNotificationData = (json) => {
    if (isAndroid()) {
      NativeModules.UserPreferences.saveStoryNotificationStrings(json);
    }
  };
}
