const size = {
  h24: 24,
  h0_24: 24,
  h0: 20,
  h1: 18,
  h2: 16,
  h3: 14,
  h3_5: 13,
  h4: 12,
  h4_5: 11,
  h5: 10,
  h6: 8,
};

export default size;
