export const CHECK_IF_PAYTM_ACCOUNT_EXIST = 'CHECK_IF_PAYTM_ACCOUNT_EXIST';
export const PAYTM_ACCOUNT_EXIST = 'PAYTM_ACCOUNT_EXIST';
export const ADD_PAYTM_MONEY = 'ADD_PAYTM_MONEY';
export const CHECK_PAYTM_BALANCE = 'CHECK_PAYTM_BALANCE';
export const SAVE_PAYTM_DETAIL_TO_REDUX = 'SAVE_PAYTM_DETAIL_TO_REDUX';
export const SEND_PAYTM_OTP = 'SEND_PAYTM_OTP';
export const VERIFY_PAYTM_OTP = 'VERIFY_PAYTM_OTP';
export const UNLINK_PAYTM = 'UNLINK_PAYTM';

export function sendPaytmOtp(paytmNumber, callback) {
  return {
    type: SEND_PAYTM_OTP,
    paytmNumber,
    callback,
  };
}

export function verifyPaytmOtp(otp, state_token, paytmMobileNumber, callback) {
  return {
    type: VERIFY_PAYTM_OTP,
    state_token,
    otp,
    paytmMobileNumber,
    callback,
  };
}

export function addMoneyToPaytm(callback) {
  return {
    type: ADD_PAYTM_MONEY,
    callback,
  };
}

export function checkPaytmBalance(callback) {
  return {
    type: CHECK_PAYTM_BALANCE,
    callback,
  };
}

export function savePaytmDetailToRedux(paytmDetails) {
  return {
    type: SAVE_PAYTM_DETAIL_TO_REDUX,
    paytmDetails,
  };
}

export function unlinkPaytm(callback) {
  return {
    type: UNLINK_PAYTM,
    callback,
  };
}
