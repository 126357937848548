import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,

    backgroundColor: '#f2f2f2',
  },
  container: {
    flex: 1,
  },
  column: {
    flexDirection: 'column',
    flex: 1,
    padding: 12,
  },
  insightContainer: {
    borderRadius: 4,

    backgroundColor: colors.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    flexDirection: 'column',

    elevation: 3,
    marginTop: 12,
    paddingBottom: 12,
  },
  extraPadding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
    justifyContent: 'center',
    flexDirection: 'row',
  },

  heading: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h3,
  },

  horizontalBar: {
    borderColor: '#e2e2e2',
    marginTop: 20,
    borderWidth: 0.5,
  },
  analysisResultContainer: {
    flex: 1,
    padding: 10,
    flexDirection: 'row',
  },

  description: {
    fontFamily: 'Roboto-Regular',
    marginTop: 5,
    color: colors.silverLit,
    fontSize: size.h4,
  },
  verifyInstaContainer: {
    height: 50,
    borderRadius: 4,
    backgroundColor: colors.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    flexDirection: 'row',
    alignItems: 'center',
    elevation: 3,
    marginTop: 12,
    width: '100%',
  },
  smallInstaLogo: {
    height: 20,
    width: 20,
    marginLeft: 16,
  },
  verifyInstaText: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h3,
    marginLeft: 12,
  },
  rightArrow: {
    position: 'absolute',
    right: 16,
    alignSelf: 'center',
    tintColor: colors.foxyBlack,
  },
  instaInsights: {
    height: 112,
    borderRadius: 4,
    backgroundColor: colors.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 3,
    marginTop: 12,
    width: '100%',
  },
  trendingUp: {
    height: 60,
    width: 60,
    resizeMode: 'contain',
  },

  boostContainer: {
    height: 65,
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: colors.foxyBlack,
    borderRadius: 4,
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  recommendationTitleRow: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },

  circle: {
    height: 15,
    width: 15,
    backgroundColor: colors.silver,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },

  askExpertButtonContainer: {
    borderRadius: 4,
    borderColor: colors.borderDark,
    height: 40,
    flexDirection: 'row',
    marginTop: 12,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  unlinkYoutubeAccountSection: {
    borderRadius: 4,
    marginTop: 15,
    height: 40,
    borderWidth: 1,
    borderColor: colors.foxyRed,
    justifyContent: 'center',
    alignItems: 'center',
  },
  unlinkYoutubeText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h2,
    color: colors.foxyBlack,
  },
});

export default styles;
