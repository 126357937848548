import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import FastImageView from '../../FastImageView';
import RoutinesUtility from '../RoutinesUtility';
import { saveLiveStreamStatus } from '../../../actions/RoutineActions';
import Utility from '../../../utils/Utility';
import { ROUTINE_ACTIVITY_TYPES } from '../../../config/Constants';

class RoutineLiveStreamEndedCard extends PureComponent {
  constructor(props) {
    super(props);
    const { buttonVisibility = '' } = this.props;
    this.state = {
      isLoading: false,
    };
    this.isButtonDisabled = buttonVisibility === 'disabled';
  }

  onOptionPress = (didAttend) => {
    const {
      onActionPress,
      itemData: { id = '' },
      saveLiveStreamStatus,
    } = this.props;
    if (didAttend) {
      this.setLoading(true);
      onActionPress(id, ROUTINE_ACTIVITY_TYPES.LIVE_STREAM, () => this.setLoading(false));
    }
    saveLiveStreamStatus({ id, didAttend });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading: isLoading,
    });
  };

  getLiveStreamsForId = () => {
    const { itemData: { id = '' } = {}, liveStreams = [] } = this.props;
    const liveStreamStatus = liveStreams.filter(
      (element) => element?.id === id,
    );
    return liveStreamStatus;
  };

  isMarkedAsDone = () => {
    const liveStreams = this.getLiveStreamsForId();
    return Utility.isPresent(liveStreams) && liveStreams[0]?.didAttend;
  };

  getButtonColor = (isYesButton) => {
    const { isLoading } = this.state;
    if (this.isButtonDisabled || isLoading) {
      return colors.subtitle;
    }
    const isAnswered = Utility.isPresent(this.getLiveStreamsForId());
    const markedDone = this.isMarkedAsDone();
    const isSelected =
      (markedDone && isYesButton) || (!markedDone && !isYesButton);
    if (isAnswered && isSelected) {
      return colors.disabled_green;
    }
    return colors.black;
  };

  render() {
    const yesButtonStyle = [
      styles.buttonContainer,
      { backgroundColor: this.getButtonColor(true) },
    ];
    const noButtonStyle = [
      styles.buttonContainer,
      { backgroundColor: this.getButtonColor(false) },
    ];
    const isAnswered = Utility.isPresent(this.getLiveStreamsForId());
    return (
      <View style={styles.container}>
        <View style={styles.headingContainer}>
          <View style={styles.liveIconContainer}>
            <FastImageView
              source={images.routines.routine_benefits_icons.live_white}
              style={styles.liveIcon}
            />
          </View>
          <Text style={styles.title}>Did you attend today’s live-stream?</Text>
        </View>
        <View style={styles.buttonsRow}>
          <TouchableOpacity
            onPress={() => this.onOptionPress(true)}
            disabled={isAnswered || this.isButtonDisabled}
            style={yesButtonStyle}
          >
            <Image source={images.whiteTick} style={styles.checkIcon} />
            <Text style={styles.buttonText}>Yes</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onOptionPress(false)}
            disabled={isAnswered || this.isButtonDisabled}
            style={noButtonStyle}
          >
            <Image source={images.whiteCross} style={styles.checkIcon} />
            <Text style={styles.buttonText}>No</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      saveLiveStreamStatus,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  liveStreams: state.routines.liveStreams,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoutineLiveStreamEndedCard);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.peach_with_opacity,
    borderRadius: 16,
    padding: 12,
    marginLeft: 12,
  },
  headingContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  liveIconContainer: {
    backgroundColor: colors.sasquatch_socks_pink,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 16,
    marginRight: 10,
  },
  liveIcon: {
    width: 16,
    height: 12,
    resizeMode: 'contain',
  },
  title: {
    color: colors.black,
    flex: 1,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
  },
  buttonsRow: {
    flexDirection: 'row',
    marginTop: 24,
  },
  buttonContainer: {
    borderRadius: 4,
    paddingVertical: 9,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
    paddingHorizontal: 12,
  },
  missedButtonContainer: {
    backgroundColor: colors.light_yellow,
    borderRadius: 4,
    paddingVertical: 9,
    paddingHorizontal: 12,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
  },
  checkIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    marginRight: 8,
  },
});
