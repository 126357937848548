import { View, StyleSheet } from 'react-native';
import React from 'react';
import { useRoute } from '@react-navigation/native';
import MoreItemsList from './feed/MoreItemsList';
import { getFullSlugFromName } from '../utils/StringUtility';
import AppInstallPrompts from '../components/shared/AppInstallPrompts';
import { FOXY_URLS } from '../config/Constants';

export default function Ingredients() {
  const { params = {} } = useRoute();
  const { shortSlug = '' } = params;
  return (
    <View style={styles.container}>
      <MoreItemsList slug={getFullSlugFromName(shortSlug, 'ingredients')} />
      <AppInstallPrompts link={FOXY_URLS.appInstallPage.list} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
  },
});
