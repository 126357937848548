import {
  Image,
  Text,
  View,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import React from 'react';
import images from '../../theme/Images';
import colors from '../../theme/Colors';

export default function FoxyPromise() {
  return (
    <View style={styles.container}>
      <View style={styles.items}>
        <PromiseItem image={images.desktop.hundredPercentPromise} text="100% Original Products" />
        <PromiseItem image={images.desktop.freeDeliveryFoxyPromise} text="Free Delivery on Prepaid" />
        <PromiseItem image={images.desktop.hassleFreeReturnsFoxyPromise} text="Hassle free Returns" />
        <PromiseItem image={images.desktop.securePaymentsFoxyPromise} text="Secure Payments" />
      </View>
    </View>
  );
}

const PromiseItem = ({ image, text }) => (
  <View style={styles.itemContainer} dataSet={{ media: ids.itemContainer }}>
    <Image source={{ uri: image }} style={styles.image} dataSet={{ media: ids.image }} />
    <Text style={styles.text} dataSet={{ media: ids.text }}>{text}</Text>
  </View>
);

const { ids, styles } = StyleSheet.create({
  container: {
    paddingHorizontal: '2%',
    paddingVertical: '2%',
    backgroundColor: '#f9faff',
  },
  items: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  itemContainer: {
    maxWidth: 92,
    alignItems: 'center',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      maxWidth: 122,
    },
  },
  image: {
    width: 36,
    height: 36,
    '@media (min-width: 768px)': {
      width: 40,
      height: 40,
    },
  },
  text: {
    fontSize: 11,
    lineHeight: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    textAlign: 'center',
    marginTop: 8,
    '@media (min-width: 768px)': {
      marginLeft: 2,
      fontSize: 12,
      marginTop: 0,
    },
  },
});
