import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import FastImageView from '../../components/FastImageView';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import { LAYOUT, CURRENCY_SYMBOL } from '../../config/Constants';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import AddToCart from '../../components/Product/AddToCart';
import ScaleAnimate from '../../components/shared/ScaleAnimate';
import _ from 'lodash';
import withNavigation from '../../utils/WithNavigation';
import ProductImageAndRating from '../../components/Product/ProductImageAndRating';

class FancyHeroCard extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    const {
      itemData: {
        final_sp = '',
        outOfStock,
        transparent_image_vibrant_colors: {
          vibrant_color: backgroundColor = '',
        } = {},
        gwp = false,
      } = {},
      listData: { options: listOptions = {}, additional_data = {} } = {},
    } = this.props;

    const backColor = Utility.isBlank(backgroundColor)
      ? '#FBA021'
      : backgroundColor;
    this.containerStyle = [
      styles.cardContainer,
      { backgroundColor: `${backColor}99` },
    ];
    const options = Utility.isBlank(listOptions) ? {} : listOptions;
    const { coupon_code = '', is_free = false } = options;
    const isSoldOut = outOfStock && Utility.isBlank(additional_data?.icon);
    this.addToCartIconContainer = [
      styles.addToCartIconContainer,
      {
        backgroundColor: isSoldOut ? colors.transparent : backColor,
        bottom: isSoldOut ? 44 : 30,
      },
    ];
    this.priceTitle = [styles.priceTitle, { color: backColor }];
    this.finalSp = `${CURRENCY_SYMBOL}${final_sp}`;
    this.isFree = parseInt(final_sp) === 0 || (is_free == 1 ?? is_free);
    if (this.isFree) {
      this.finalSp = 'FREE';
    }
  }

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug, variants_details = {} } = {},
      toggleCartVisibility,
    } = this.props;

    navigation.push('ProductDetailModal', {
      id,
      slug,
      display: LAYOUT.SCREEN,
      itemData,
      toggleCartVisibility,
      variants_details,
      previousScreen: this.props?.previousScreen,
    });
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
      prompts,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData: { additional_data = {}, options: listOptions } = {},
      itemData: {
        transparent_image_vibrant_colors: {
          vibrant_color: backgroundColor = '#B57CD6',
          dark_vibrant_color: textColor = '#FFF',
        } = {},
      } = {},
      listData = {},
    } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    const options = Utility.isPresent(listOptions) ? listOptions : {};
    const { counter_max_value, counter_current_value } = options;
    const isClaimed =
      Utility.isPresent(counter_max_value) &&
      counter_max_value === counter_current_value &&
      counter_max_value !== 0;

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.GRID}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        singleStockedVariantSku={singleStockedVariantSku}
        prompts={prompts}
        additional_data={additional_data}
        isFancyCard
        listData={listData}
        isClaimed={isClaimed}
        soldOutTextColor={colors.white}
      />
    );
  };

  onPress = () => {
    this.debouncedNavigate();
  };

  render() {
    const {
      itemData: {
        name = '',
        product_name = '',
        hero_description = '',
        sp = '',
        id = '',
        sku_id: skuId,
        prompts,
        has_variants: hasVariants,
        outOfStock,
        priority,
        transparent_image_url: image_url = '',
        single_stocked_variant: singleStockedVariantSku,
        final_sp = '',
        images,
        webp_images,
        image_webp_url,
        rating,
        image_url: imageUrl,
        transparent_image_url = '',
        background_image_url = '',
      } = {},
      itemData = {},
      listData: { display = '' },
      card_text: cardText = '',
      transparent_image_vibrant_colors: {
        dark_vibrant_color: textColor = '',
      } = {},
    } = this.props;
    return (
      <ScaleAnimate {...this.props} onPress={this.onPress}>
        <View style={this.containerStyle}>
          <View style={styles.ImageContainer}>
            <ProductImageAndRating
              layout={LAYOUT.RAIL}
              rating={rating}
              images={images}
              webp_images={webp_images}
              image_webp_url={image_webp_url}
              imageUrl={imageUrl}
              id={id}
              name={name}
              transparentImageUrl={transparent_image_url}
              backgroundImageUrl={background_image_url}
              itemData={itemData}
              display={display}
              fancyHeroCard
            />
          </View>
          <View style={styles.bottomContainer}>
            <Text style={styles.title}>
              {name.trim() || product_name.trim()}
            </Text>
            <Text style={styles.subtitle}>{hero_description}</Text>
            <View style={styles.pricing}>
              <Text style={styles.offerTitle}>{this.finalSp}</Text>
              {this.isFree && (
                <Text style={this.priceTitle}>
                  {CURRENCY_SYMBOL}
                  {sp}
                </Text>
              )}
            </View>
          </View>
          {Utility.isPresent(cardText) && (
            <Text
              style={_.memoize(
                () => ([styles.cardText, { color: textColor }]),
                () => [textColor],
              )()}
              numberOfLines={1}
              ellipsizeMode='tail'
            >
              {cardText}
            </Text>
          )}
          <View style={this.addToCartIconContainer}>
            <this.addToCart
              skuId={skuId}
              toggleCartState={this.toggleCartState}
              layout={LAYOUT.GRID}
              addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
              id={id}
              itemData={itemData}
              hasVariants={hasVariants}
              showCart={this.showCart}
              priority={priority}
              outOfStock={outOfStock}
              singleStockedVariantSku={singleStockedVariantSku}
              prompts={prompts}
            />
          </View>
        </View>
      </ScaleAnimate>
    );
  }
}

export default withNavigation(FancyHeroCard);

const styles = StyleSheet.create({
  cardText: {
    fontSize: 16,
    position: 'absolute',
    bottom: 12,
    left: 20,
    fontFamily: 'Roboto-Medium',
  },
  cardContainer: {
    width: Utility.getDynamicWidthForGrid(1, 12),
    height: Utility.getDynamicWidthForGrid(1, 12) * 1.18 + 20,
    borderRadius: 20,
    alignSelf: 'center',
    paddingHorizontal: 20,
    marginVertical: 16,
    marginBottom: 8,
  },
  ImageContainer: {
    flex: 1,
    alignSelf: 'center',
    width: Utility.getDynamicWidthForGrid(1, 0),
    overflow: 'hidden',
    resizeMode: 'contain',
  },
  productImage: {
    position: 'absolute',
    width: '100%',
    height: 216,
    resizeMode: 'contain',
    alignSelf: 'center',
  },

  bottomContainer: {
    alignSelf: 'flex-start',
    marginBottom: 40,
  },
  title: {
    fontSize: size.h1,
    color: colors.white,
    fontFamily: 'Roboto-Bold',
  },
  subtitle: {
    fontSize: size.h3,
    marginTop: 4,
    color: colors.white,
    fontFamily: 'Roboto-Regular',
  },
  pricing: {
    position: 'relative',
    flexDirection: 'row',
    marginTop: 12,
    alignItems: 'center',
  },
  offerTitle: {
    fontSize: 24,
    color: colors.white,
    fontFamily: 'Roboto-Bold',
    marginRight: 8,
  },
  priceTitle: {
    fontSize: size.h1,
    fontFamily: 'Roboto-Medium',
    textDecorationLine: 'line-through',
    marginTop: 2,
  },
  addToCartIconContainer: {
    position: 'absolute',
    right: 28,
    bottom: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 26,
  },
  addToCartIcon: {
    height: 18,
    width: 18,
    alignSelf: 'center',
  },
});
