import StyleSheet from 'react-native-media-query';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import size from '../../../theme/Fonts';
import Config from '../../../libraries/ReactNativeConfig';
import { CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP } from '../../../utils/LayoutUtility';

export const { ids: productInCartIds, styles: ProductInCartStyles } = StyleSheet.create({
  cardStyleForCart: {
    flexDirection: 'row',
    height: 120,
  },
  cardStyleForCartTransparent: {
    flexDirection: 'row',
    height: 120,
    backgroundColor: 'transparent',
  },
  gradientContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  productImage: {
    height: 86,
    width: 86,
    marginLeft: 12,
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 8,
    backgroundColor: colors.white,
  },
  productImageOrders: {
    height: 48,
    width: 48,
    marginLeft: 12,
    marginTop: 10,
    marginBottom: 8,
    marginRight: 10,
    resizeMode: 'contain',
  },
  descriptionContainerStyle: {
    height: '100%',
    width: Utility.getScreenWidth() - 220,
    marginRight: 100,
    marginLeft: 8,
    '@media (min-width: 768px)': {
      width: CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP - 220,
    },
  },
  descriptionContainerStyleWithoutAddToCart: {
    height: '100%',
    width: Utility.getScreenWidth() - 150,
    marginRight: 16,
    marginLeft: 8,
    '@media (min-width: 768px)': {
      width: CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP - 150,
    },
  },
  descriptionContainerStyleWithAddToCart: {
    height: '100%',
    width: Utility.getScreenWidth() - 220,
    marginRight: 100,
    marginLeft: 8,
    '@media (min-width: 768px)': {
      width: CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP - 220,
    },
  },
  descriptionContainerStyleOrders: {
    flexDirection: 'column',
    flex: 1,

    width: Utility.getScreenWidth(),
    marginLeft: 8,
    fontSize: 10,
    padding: 10,

    fontFamily: 'Roboto-Regular',
    '@media (min-width: 768px)': {
      width: CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP,
    },
  },
  productDetailsOrders: {
    flexDirection: 'column',
  },
  priceContainerOrders: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
  brandName: {
    fontFamily: 'Roboto-Regular',
    fontSize: 11,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
    marginTop: 8,
  },
  brandNameWithDispatchDate: {
    fontFamily: 'Roboto-Regular',
    fontSize: 11,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
    marginRight: 24,
  },
  productName: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 12,
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  priceContainer: {
    marginTop: 8,
    flexDirection: 'row',
  },
  brandNameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
  },
  dispatchDateContainer: {
    width: 124,
    height: 18,
    backgroundColor: '#E4F2FF',
    borderRadius: 16,
    marginLeft: 6,
    justifyContent: 'center',
    alignItems: 'center',
    right: 24,
  },
  dispatchDateText: {
    fontSize: 11,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
  },
  spStyle: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  spStyleOrders: {
    color: colors.silver,
    fontSize: 10,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  mrpStyle: {
    color: '#979BAA',
    fontSize: 11,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  productInCartMrp: {
    color: colors.silver,
    fontSize: size.h4,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    marginRight: 4,
  },
  productInCartSp: {
    color: colors.green,
    fontSize: size.h4,
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  discountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: 11,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  couponAppliedStyle: {
    color: Config.CART_ITEM_OFFER_APPLIED_COLOR,
    fontSize: 11,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  incrementDecrementSwitch: {
    // position: 'absolute',
    // right: Utility.largePadding,
    alignSelf: 'center',
    width: 90,
    height: 30,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.borderDark,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 4,
    zIndex: 1000,
  },
  decrementButtonContainer: {
    position: 'absolute',
    width: 26,
    top: 0,
    left: 0,
    height: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 1000000000,
  },
  incrementButtonContainer: {
    position: 'absolute',
    width: 26,
    top: 0,
    right: 0,
    height: 30,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  minus: {
    height: 2,
    width: 10,
    backgroundColor: Utility.isPresent(
      Config.CART_INCREMENT_DECREMENT_TINT_COLOR,
    )
      ? Config.CART_INCREMENT_DECREMENT_TINT_COLOR
      : colors.foxyPink,
    zIndex: 1,
  },
  minusDisabled: {
    height: 2,
    width: 10,
    backgroundColor: colors.addToCart.disable,
    zIndex: 1,
  },
  minusWhileCartChanging: {
    height: 2,
    width: 10,
    backgroundColor: colors.addToCart.whileAddingToCart,
    zIndex: 1,
  },
  plus: {
    resizeMode: 'stretch',
    height: 10,
    width: 10,
    marginBottom: 2,
    tintColor: Utility.isPresent(Config.CART_INCREMENT_DECREMENT_TINT_COLOR)
      ? Config.CART_INCREMENT_DECREMENT_TINT_COLOR
      : colors.foxyPink,
  },
  delete: {
    resizeMode: 'stretch',
    height: 16,
    width: 16,
    marginBottom: 2,
    tintColor: Utility.isPresent(Config.CART_INCREMENT_DECREMENT_TINT_COLOR)
      ? Config.CART_INCREMENT_DECREMENT_TINT_COLOR
      : colors.foxyPink,
  },
  plusDisabled: {
    resizeMode: 'stretch',
    height: 10,
    width: 10,
    marginBottom: 2,
    tintColor: colors.addToCart.disable,
  },
  plusWhileCartChanging: {
    resizeMode: 'stretch',
    height: 10,
    width: 10,
    marginBottom: 2,
    tintColor: colors.addToCart.whileAddingToCart,
  },
  variant: {
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  productQuantandVariantText: {
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    fontSize: 10,
  },
  productQuantityVariantTextSmall: {
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    maxWidth: 54,
    color: colors.foxyBlack,
    fontSize: 8,
  },
  removeProduct: {
    backgroundColor: colors.border,
    height: 16,
    width: 16,
    borderRadius: 8,
    position: 'absolute',
    left: 12,
    top: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  variantPillContainer: {
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    left: 8,
    top: 8,
    borderWidth: 1,
    paddingHorizontal: 4,
    borderRadius: 12,
    borderColor: colors.border,
    maxWidth: 74,
    backgroundColor: '#fff',
    paddingVertical: 1,
  },
  variantImage: { height: 10, width: 10, borderRadius: 5, marginRight: 4 },
  removeProductImage: { tintColor: colors.foxyBlack, height: 8, width: 8 },
  orderStatusStyle: {
    color: colors.green,
    // marginTop: 5,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  eddStyle: {
    color: colors.silver,
    fontSize: 10,
    // marginTop: 10,
    paddingRight: 15,
  },
  orderStatusContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 40,

    // bottom: 12,
  },
  soldByStyle: {
    fontSize: 12,
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    bottom: 8,
  },
  subContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',

    bottom: 7,
  },
  chatStyle: {
    fontSize: 12,
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
  },
  cancelContainer: {
    flexDirection: 'row',
  },
  cancelStyle: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.silver,
  },
  chevronRight: {
    tintColor: colors.foxyPink,
    height: 10,
    width: 10,
    top: 3,
  },
  flexEndAlignedItemsLayout: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  stockErrorText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyPink,
  },
  cartButtonContainer: {
    position: 'absolute',
    right: Utility.largePadding,
    alignItems: 'center',
    alignSelf: 'center',
    zIndex: 100,
  },
  productCardCouponContainer: { flexDirection: 'row', marginTop: 2 },
  productCardCouponImage: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    tintColor: Config.CART_ITEM_OFFER_APPLIED_COLOR,
    opacity: 0.7,
  },
  shimmer: {
    flex: 1,
    height: 16,
    width: 76,
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
  },
  flashDealText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 11,
    color: colors.black,
    marginLeft: 6,
    top: 1,
    letterSpacing: 0,
  },
  flashDealContainer: { position: 'absolute', marginLeft: 8, top: 8 },
  flashDealImage: {
    height: 18,
    width: 80,
    resizeMode: 'contain',
    overflow: 'visible',
    position: 'absolute',
    top: 8,
  },
  fancyCardImage: { width: 28, height: 28, resizeMode: 'contain' },
  fancyCardImageSmall: { width: 20, height: 20, resizeMode: 'contain' },
  fancyCardImageContainer: {
    backgroundColor: colors.transparent,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  fancyCardContainerStyle: {
    height: 54,
    width: 54,
    borderWidth: 4,
    borderColor: colors.white,
    borderRadius: 26,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  soldOutText: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
  },
  featuredRailAddToCartButtonStyle: {
    width: 100,
    height: 30,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  featuredRailAddToCartButtonTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
  },
});
