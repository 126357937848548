import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  BackHandler,
  Platform,
  UIManager,
} from 'react-native';
import withNavigation from '../../utils/WithNavigation';

import HTML from 'react-native-render-html';
import { SharedElement } from 'react-native-shared-element';

import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import Utility from '../../utils/Utility';
import ProductDescriptionHeader from '../shared/ProductDescriptionHeader';

class FullPageProductDescription extends PureComponent {
  static sharedElements = (route, otherNavigation, showing) => {
    const item = route.params?.itemData;
    return [
      {
        id: item.id,
        animation: 'fade',

        resize: 'clip',
        align: 'center-center',
      },
    ];
  };

  constructor(props) {
    super(props);
    const { route } = props;
    this.navigationRefs = null;
    this.closeToBottom = false;
    this.closeToTop = false;
    this.itemData = route.params?.itemData;
    this.ignoredStyles = [
      'font-family',
      'line-height',
      'height',
      'width',
      'max-width',
      'overflow',
      'text-transform',
      'padding',
    ];
    this.state = {
      psudo: true,
    };
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    this.setState({
      psudo: true,
    });
    setTimeout(() => {
      this.setState({
        psudo: false,
      });
    }, 500);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  htmlView = (props) => {
    const { title, htmlData, marginTop = 0 } = props;
    if (Utility.isBlank(htmlData)) {
      return null;
    }

    const width = Utility.getScreenWidth();
    const data = htmlData.replace(/(\r\n|\n|\r)/gm, '');
    return (
      <View style={{ marginTop, paddingLeft: 8, paddingRight: 8 }}>
        <Text style={styles.htmlViewerTitle}>{title}</Text>
        <View style={styles.htmlViewerContainer}>
          <HTML
            html={`${data}`}
            containerStyle={styles.htmlViewerContainer}
            style={styles.fullWidth}
            imagesMaxWidth={width}
            ignoredStyles={this.ignoredStyles}
            tagsStyles={webViewStyle}
          />
        </View>
      </View>
    );
  };

  render() {
    const { description, how_to, ingredients, metrological_info } =
      this.itemData;

    const { psudo } = this.state;

    const styless = !!psudo ? { height: 1000 } : {};

    return (
      <>
        <ProductDescriptionHeader Header title='Product description' />

        <ScrollView
          style={styles.scrollViewContainer}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.wrapper}>
            <SharedElement id={this.itemData.id}>
              <View style={styless}>
                <this.htmlView
                  title=''
                  htmlData={`<div>${description}</div>`}
                />

                <this.htmlView
                  title='Ingredients'
                  htmlData={`<div>${ingredients}</div>`}
                  marginTop={20}
                />
                <this.htmlView
                  title='How to Use'
                  htmlData={`<div>${how_to}</div>`}
                  marginTop={20}
                />
                <this.htmlView
                  title='Additional Info'
                  htmlData={`<div>${metrological_info}</div>`}
                  marginTop={24}
                />
              </View>
            </SharedElement>

            <View style={styles.separator}></View>
          </View>
        </ScrollView>
      </>
    );
  }
}

export default withNavigation(FullPageProductDescription);

const webViewStyle = StyleSheet.create({
  a: {
    color: colors.foxyPink,
  },
  p: { color: colors.foxyBlack },
  li: { color: colors.foxyBlack },
  ol: { color: colors.foxyBlack },
  ul: { color: colors.foxyBlack },
  div: { color: colors.foxyBlack },
  span: { color: colors.foxyBlack },
  b: { color: colors.foxyBlack },
});

const styles = StyleSheet.create({
  container: {
    marginTop: Utility.isAndroid() ? 6 : 25,
  },

  wrapper: {
    paddingTop: Utility.isIOS() ? 30 + Utility.topInset : 36,
    paddingBottom: 15,
  },

  htmlViewerTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h2,
    color: colors.foxyBlack,
    marginBottom: 8,
  },
  separator: { height: 150 },
  // htmlContainerStyle: { marginTop: 10 },
  htmlStyle: { width: '100%' },
  htmlViewerContainer: {
    // marginTop: 10,
  },
  fullWidth: {
    width: '100%',
  },

  scrollViewContainer: {
    paddingBottom: 150,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
    paddingHorizontal: 12,
    alignSelf: 'center',
  },

  a: {
    color: colors.foxyPink,
  },
  p: { color: colors.foxyBlack, marginTop: 2, marginBottom: 2 },
  li: { color: colors.foxyBlack },
  ol: { color: colors.foxyBlack },
  ul: { color: colors.foxyBlack },
  div: { color: colors.foxyBlack },
});
