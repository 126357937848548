import StyleSheet from 'react-native-media-query';
import { Platform } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';

export const { ids, styles } = StyleSheet.create({
  saveMoreContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
    backgroundColor: colors.background,
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginRight: 16,
    borderRadius: 4,
  },
  saveMoreContainerCta: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
    backgroundColor: colors.slimCouponAlternateBackgroundColor,
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginRight: 16,
    borderRadius: 4,
  },
  couponCodeLeftCut: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.white,
    bottom: 45,
    left: -24,
    zIndex: 1000,
  },
  saveMoreCouponPill: {
    paddingHorizontal: 4,
    backgroundColor: colors.grayscale.background,
    borderColor: colors.grayscale.borderColor,
  },
  saveMoreCouponPill_second: {
    paddingHorizontal: 4,
    backgroundColor: colors.grayscale.background,
    borderColor: colors.grayscale.borderColor,
  },
  couponCodeRightCutClipperSmall: {
    height: 18,
    width: 18,
    position: 'absolute',
    backgroundColor: colors.white,
    bottom: '40%',
    right: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
  },
  couponCodeLeftCutClipperSmall: {
    height: 18,
    width: 18,
    position: 'absolute',
    backgroundColor: colors.white,
    bottom: '40%',
    left: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
  },
  couponCodeRightCutClipper: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.white,
    bottom: '40%',
    right: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
  },
  couponCodeLeftCutClipper: {
    height: 20,
    width: 20,
    position: 'absolute',
    backgroundColor: colors.white,
    bottom: '40%',
    left: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
  },
  offerTitle: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    paddingBottom: 8,
    paddingTop: 4,
    marginHorizontal: 12,
    '@media (min-width: 768px)': {
      fontSize: 16,
    },
  },
  subHeadingStyle: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 6,
    ...Platform.select({
      web: {
        width: '90%',
      },
      default: {},
    }),
    '@media (min-width: 768px)': {
      fontSize: 14,
      paddingRight: 10,
    },
  },
  subHeadingStyleCta: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyPink,
    marginTop: 6,
  },
  offerPromptCouponContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingHorizontal: 8,
    marginBottom: 8,
    '@media (min-width: 768px)': {
      marginTop: 5,
    },
  },
  subHeadingContainer: {
    marginBottom: 8,
    // height: 38,
    alignSelf: 'flex-start',
    marginLeft: 8,
  },
  subHeadingContainerSaveMore: {
    marginBottom: 8,
    height: 40,
    alignSelf: 'flex-start',
    marginLeft: 8,
    '@media (min-width: 768px)': {
      marginRight: 8,
    },
  },
  promptContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    backgroundColor: colors.cartPrompt.background,
    paddingHorizontal: 12,
    paddingVertical: 6,
    height: 42,
    marginHorizontal: 12,
  },
  promptContainerCta: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    backgroundColor: colors.slimCouponAlternateBackgroundColor,
    paddingHorizontal: 12,
    paddingVertical: 6,
    height: 42,
    marginHorizontal: 12,
  },
  promptContainer_2: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
    backgroundColor: colors.cartPrompt.background2,
    paddingHorizontal: 12,
    paddingVertical: 6,

    width: Utility.getScreenWidth() - 24,
  },
  pillStyle: {
    marginRight: 4,
    flex: 1,
    paddingHorizontal: 4,
    padding: 2,
    borderWidth: 1,
    backgroundColor: colors.cartPrompt.background,
    borderColor: colors.cartPrompt.borderColor,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
  },
  pillStyleCta: {
    marginRight: 4,
    flex: 1,
    paddingHorizontal: 4,
    padding: 2,
    borderWidth: 1,
    backgroundColor: colors.slimCouponAlternateBackgroundColor,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    borderColor: colors.slimCouponAlternateBackgroundColor,
  },
  descriptionContainer: {
    flex: 20,
    justifyContent: 'center',
    height: 70,
    alignItems: 'flex-start',
  },
  descriptionText: {
    paddingHorizontal: 8,
    color: colors.foxyBlack,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
  },
  descriptionShimmer: {
    width: 230,
    marginHorizontal: 12,
    borderRadius: 4,
  },
  CTAContainer: {
    flex: 1,
    // alignSelf: 'flex-start',
    alignSelf: 'center',
    alignItems: 'flex-end',
  },
  CTA: {
    color: colors.prompt.lightBlue,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
  },
  crossContainer: {
    flex: 1,
    // alignSelf: 'flex-start',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  cross: { width: 15, height: 15, resizeMode: 'contain' },
  chevron: {
    width: 10,
    height: 10,
    resizeMode: 'contain',
    marginTop: 4,
    tintColor: colors.foxyBlack,
  },
  copyButton: {
    width: 16,
    height: 18,
    marginRight: 2,
    resizeMode: 'contain',
    tintColor: colors.black,
  },
  container: {},
  singleCartPromptContainer: {
    width: Utility.getScreenWidth() - 36,
    '@media (min-width: 768px)': {
      width: '100%',
    },
  },
  multipleCartPromptContainer: {
    width: Utility.getScreenWidth() - 108,
    '@media (min-width: 768px)': {
      width: 404,
    },
  },
  icon: {
    height: 24,
    width: 24,
    position: 'absolute',
    top: 6,
    right: 6,
    overflow: 'visible',
    resizeMode: 'contain',
  },
});
