import {
  Text,
  TouchableOpacity,
  View,
  FlatList,
  ScrollView,
} from 'react-native';
import React, { Component } from 'react';
import FastImageView from '../../components/FastImageView';
import styles from './styles';
import images from '../../theme/Images';
import { StaticNavigationHeader } from '../../components/shared';
import Markdown from 'react-native-markdown-display';
import { Linking, Platform } from 'react-native';
import {
  storeFaqLikeAndDislikeData,
  getFaqSectionData,
} from '../../actions/ActionTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import colors from '../../theme/Colors';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import Utility from '../../utils/Utility';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import withNavigation from '../../utils/WithNavigation';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

class FaqAnswerSection extends Component {
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.breadCrumbs = route.params?.breadCrumbsData;
    this.state = {
      disabled: false,
      headerTitle: '',
      faqsAnswer: '',
      faqSlug: '',
      faqId: '',
      isLoading: false,
    };
    this.breadCrumbScreen = ['FaqSection', 'FaqQuestionSection'];
    this.headerTitle = route.params?.headerTitle;
    this.callBackRequestReasonId = route.params?.callBackRequestReasonId;
    this.entityType = route.params?.entityType;
    this.entityId = route.params?.entityId;
    this.orderId = route.params?.orderId;
    this.previousScreen = route.params?.previousScreen;
  }

  componentDidMount() {
    const { route, getFaqSectionData } = this.props;
    let slugString = route.params?.slug;
    slugString = slugString.replace('v2', 'v1');
    getFaqSectionData(slugString, this.getFaqSectionDataCallBack);
  }

  getFaqSectionDataCallBack = (success, response) => {
    if (success) {
      this.setState({
        headerTitle: response.data?.attributes?.question,
        faqsAnswer: response.data?.attributes?.answer,
        faqSlug: response.data?.attributes?.slug,
        faqId: response.data?.id,
        isLoading: true,
      });
      AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
        [EventParameterKey.SCREEN_NAME]:
          response.data?.attributes?.question.slice(0, 45),
      });
    }
  };

  onTapLikeAndDislike = (actionType) => {
    const { storeFaqLikeAndDislikeData } = this.props;
    const { faqSlug, faqId } = this.state;
    const index = faqSlug.lastIndexOf('/');
    const faqSlugKey = faqSlug.substring(index + 1);
    storeFaqLikeAndDislikeData(
      faqSlugKey,
      actionType,
      faqId,
      this.likeAndDislikeButtonActionCallBack,
    );
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.TYPE]: EventParameterValue.ITEM_TYPE.FAQ_FEEDBACK,
      [EventParameterKey.CTA]: actionType,
    });
  };

  likeAndDislikeButtonActionCallBack = (success, response) => {
    if (success) {
      this.setState({
        disabled: true,
      });
    }
  };

  likeDislikeSection = () => {
    const { disabled, faqId } = this.state;
    const { isClickedLikeAndDislike = [] } = this.props;
    const isClickedIcon = isClickedLikeAndDislike.includes(faqId);

    const isChangeLikeIconStyle = isClickedIcon
      ? styles.disabledLikeIconStyle
      : styles.likeIconStyle;

    const isChangeDisLikeIconStyle = isClickedIcon
      ? styles.disabledDisLikeIconStyle
      : styles.dislikeIconStyle;
    return (
      <View style={styles.likeDislikeSectionContainer}>
        <Text style={styles.boldTextStyle}>Was this helpful?</Text>
        <View style={styles.iconContainer}>
          <TouchableOpacity
            onPress={() => this.onTapLikeAndDislike('like')}
            disabled={isClickedIcon}
          >
            <FastImageView
              source={images.faq_like_icon}
              style={isChangeLikeIconStyle}
              resizeMode='contain'
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.onTapLikeAndDislike('dislike')}
            disabled={isClickedIcon}
          >
            <FastImageView
              source={images.faq_dislike_icon}
              style={isChangeDisLikeIconStyle}
              resizeMode='contain'
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  onPressContactUs = () => {
    const { headerTitle = '' } = this.state;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.TYPE]: 'contact_us',
      [EventParameterKey.SOURCE]: headerTitle.slice(0, 45),
    });
    Linking.openURL('mailto:hello@foxy.in');
  };

  contactUsSection = () => {
    return (
      <View style={styles.contactUsContainer}>
        <View style={styles.subContainer}>
          <Text style={styles.boldTextStyle}>
            Here's what else you can do -
          </Text>
          <Text style={styles.subHeadingStyle}>
            You can email us at hello@foxy.in and we’ll get our customer care
            team to deep dive into your issue.
          </Text>
          <TouchableOpacity onPress={this.onPressContactUs}>
            <Text style={styles.blueTextButton}> CONTACT US</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  onPressCrumbText = (index) => {
    if (index === 2) return null;
    const { navigation } = this.props;
    navigation.navigate(this.breadCrumbScreen[index]);
  };

  breadCrumbsSection = ({ item, index }) => {
    if (Utility.isBlank(item)) return null;
    let trimText = item;
    if (item.length > 25) {
      trimText = item.slice(0, 25);
      trimText = `${trimText}...`;
    }
    return (
      <TouchableOpacity onPress={() => this.onPressCrumbText(index)}>
        <Text
          numberOfLines={1}
          ellipsizeMode={'tail'}
          style={styles.breadCrumbsTextStyle}
        >
          {trimText}
          {index < 2 && <Text style={{ color: colors.black }}> / </Text>}
        </Text>
      </TouchableOpacity>
    );
  };

  onPressRequestCallBack = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.CTA]: 'Request a call',
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.FAQ_ANSWER_SECTION,
    });
    navigation.navigate('RequestCallBackPage', {
      headerTitle: this.headerTitle,
      callBackRequestReasonId: this.callBackRequestReasonId,
      entityType: this.entityType,
      entityId: this.entityId,
      orderId: this.orderId,
    });
  };

  bottomButton = () => {
    return (
      <View style={styles.secondaryButtonContainer}>
        <FoxyShadowButton
          width={Utility.getScreenWidth() - 24}
          title={'Request a call'}
          onPress={this.onPressRequestCallBack}
          firstColor={colors.black}
          secondColor={colors.black}
          underlayColor={colors.black}
        />
      </View>
    );
  };

  keyExtractor = (item, index) => index;

  render() {
    const { faqsAnswer = '', headerTitle = '', isLoading } = this.state;
    return (
      <View style={styles.answerSectionContainer}>
        <StaticNavigationHeader title={headerTitle} />
        <ScrollView>
          {isLoading && (
            <View>
              <View style={styles.answerSectionSubContainer}>
                <FlatList
                  horizontal
                  style={styles.breadcrumbsListContainer}
                  data={this.breadCrumbs}
                  renderItem={this.breadCrumbsSection}
                  keyExtractor={this.keyExtractor}
                />
                <View style={styles.answerText}>
                  <Markdown style={styles}>{faqsAnswer}</Markdown>
                </View>
                <View style={styles.greyHorizontalLine} />
                <this.likeDislikeSection />
              </View>
              <this.contactUsSection />
            </View>
          )}
        </ScrollView>
        {this.previousScreen === 'contactUsQuestions' && <this.bottomButton />}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isClickedLikeAndDislike: state.UserAccountInfo.isClickedLikeAndDislike,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      storeFaqLikeAndDislikeData,
      getFaqSectionData,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(FaqAnswerSection),
);
