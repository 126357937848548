export default class ShipmentDAO {
  static shipmentDelayType({ delayInDispatch, delayInDelivery }) {
    if (delayInDispatch === 'severe' || delayInDelivery === 'severe') {
      return 'severe';
    }

    if (delayInDispatch === 'mild' || delayInDelivery === 'mild') {
      return 'mild';
    }

    return 'no_delay';
  }
}
