// Dependencies
import React, { PureComponent } from 'react';
import { TouchableOpacity, Image, View } from 'react-native';
import { connect } from 'react-redux';
import Images from '../../theme/Images';
import styles from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';

class ReferAndShare extends PureComponent {
  goToReferAndEarn = () => {
    const { navigation } = this.props;
    navigation.navigate('ReferAndEarn', {});
  };

  render() {
    const { isArtist, invitationLink } = this.props;
    if (!isArtist || Utility.isBlank(invitationLink)) {
      return null;
    }
    return (
      <View style={styles.rightNavbarHeaderStyle}>
        <TouchableOpacity
          onPress={this.goToReferAndEarn}
          hitSlop={Utility.getHitSlop()}
        >
          <Image
            source={Images.share}
            style={[styles.navbarIconDefaultStyle, {
              tintColor: colors.foxyBlack,
              marginLeft: 44,
            }]}
          />
        </TouchableOpacity>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  isArtist: state.UserAccountInfo.artistProfile.isArtist,
  invitationLink: state.UserAccountInfo.invitationCode,
});

export default withNavigation(connect(mapStateToProps, null)(ReferAndShare));
