import * as types from '../actions/ActionTypes';
import initialState from './initialState';
import { isBlank } from '../utils/BooleanUtility';

export default function productReducer(state = initialState.product, action) {
  if (isBlank(action.data)) {
    return state;
  }
  switch (action.type) {
    case types.UPDATE_PRODUCT:
      return action.data;
    default:
      return state;
  }
}
