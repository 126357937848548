import React, { PureComponent } from 'react';
import { Image, ImageBackground } from 'react-native';
import { Grayscale } from '../../libraries/ReactNativeColorMatrixImageFilters';

class GrayScaledImage extends PureComponent {
  renderImage = () => {
    return <Image {...this.props} />;
  };

  renderConditionalImage = () => {
    const { enableGrayscale } = this.props;
    if (enableGrayscale) {
      return (
        <Grayscale>
          <this.renderImage />
        </Grayscale>
      );
    }
    return <this.renderImage />;
  };

  renderImageBackground = () => {
    return <ImageBackground {...this.props} />;
  };

  renderConditionalImageBackground = () => {
    const { enableGrayscale } = this.props;
    if (enableGrayscale) {
      return (
        <Grayscale>
          <this.renderImageBackground />
        </Grayscale>
      );
    }
    return <this.renderImageBackground />;
  };

  render() {
    const { isBackground } = this.props;
    if (isBackground) {
      return <this.renderConditionalImageBackground />;
    }

    return <this.renderConditionalImage />;
  }
}

export default GrayScaledImage;
