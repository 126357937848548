import React from 'react';
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';

const withNavigationFocus = (Component) => (props) => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const route = useRoute();
  return (
    <Component
      navigation={navigation}
      route={route}
      isFocused={isFocused}
      {...props}
    />
  );
};

export default withNavigationFocus;
