import React, { Component } from 'react';
import { View, Text, Image, Linking } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Config from '../../libraries/ReactNativeConfig';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import colors from '../../theme/Colors';
import TypeWriter from 'react-native-typewriter';
import images from '../../theme/Images';
import ReactNativeCardFlip from 'react-native-card-flip';
import LinearGradient from 'react-native-linear-gradient';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import RoundedButton from '../../components/layout/buttons/RoundedButton';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import AppConfig from '../../config/AppConfig';
import { APP_DOWNLOAD_LINK, FOXY_URLS, PERMISSION_STORE } from '../../config/Constants';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isWeb, isWebAndroid, isWebIOS } from '../../utils/BooleanUtility';
import { AnalyticsManager, EventType } from '../../analytics';

const animationInfo = [
  {
    simple_title_text: 'Is your skin',
    colored_title_text: 'SENSITIVE ?',
    title_color: '#7AA933',
    sub_heading_line_1: `Help ${Config.APP_NAME} know you better`,
    sub_heading_line_1_color: '#7AA933',
    sub_heading_line_2: 'Get personalized beauty recommendations',
    image_mapping: [1, 6, 8, 9, 14],
  },
  {
    simple_title_text: 'Looking to clear',
    colored_title_text: 'ACNE ?',
    title_color: '#7181A7',
    sub_heading_line_1: `Help ${Config.APP_NAME} know you better`,
    sub_heading_line_1_color: '#7181A7',
    sub_heading_line_2: 'Find ingredients beneficial for your concerns',
    image_mapping: [2, 4, 5, 7, 12, 15],
  },
  {
    simple_title_text: 'Want to stop',
    colored_title_text: 'HAIR LOSS ?',
    title_color: '#F16727',
    sub_heading_line_1: `Help ${Config.APP_NAME} know you better`,
    sub_heading_line_1_color: '#F16727',
    sub_heading_line_2: Utility.isPresent(
      Config.PAYOFF_EMPTY_STATE_HAIR_LOSS_SUBHEADING,
    )
      ? Config.PAYOFF_EMPTY_STATE_HAIR_LOSS_SUBHEADING
      : 'Set your goals with the expertise of your smart selfie friend',
    image_mapping: [10, 13, 16],
  },
  {
    simple_title_text: 'Looking to cure',
    colored_title_text: 'DANDRUFF ?',
    title_color: '#3082A9',
    sub_heading_line_1: `Help ${Config.APP_NAME} know you better`,
    sub_heading_line_1_color: '#3082A9',
    sub_heading_line_2: 'Get the best deals on products recommended for you',
    image_mapping: [3, 11],
  },
  {
    simple_title_text: 'Using products right for you ?',
    colored_title_text: '',
    title_color: '#7AA933',
    sub_heading_line_1: `Find your perfect match with ${Config.APP_NAME}`,
    sub_heading_line_1_color: '#7AA933',
    sub_heading_line_2: Utility.isPresent(
      Config.PAYOFF_EMPTY_STATE_PERFECT_MATCH_SUBHEADING,
    )
      ? Config.PAYOFF_EMPTY_STATE_PERFECT_MATCH_SUBHEADING
      : 'your smart selfie friend',
    image_mapping: [],
  },
];

export class PayoffEmptyState extends Component {
  constructor(props) {
    super(props);
    this.index = 0;
    this.card = [];
    this.state = {
      index: 0,
      typing: 1,
      transformStyle: {
        1: [{ rotateY: '180deg' }],
        2: [{ rotateY: '180deg' }],
        3: [{ rotateY: '180deg' }],
        4: [{ rotateY: '180deg' }],
        5: [{ rotateY: '180deg' }],
        6: [{ rotateY: '180deg' }],
        7: [{ rotateY: '180deg' }],
        8: [{ rotateY: '180deg' }],
        9: [{ rotateY: '180deg' }],
        10: [{ rotateY: '180deg' }],
        11: [{ rotateY: '180deg' }],
        12: [{ rotateY: '180deg' }],
        13: [{ rotateY: '180deg' }],
        14: [{ rotateY: '180deg' }],
        15: [{ rotateY: '180deg' }],
        16: [{ rotateY: '180deg' }],
      },
    };
    this.linearGradient = ['#f4f6f800', '#f4f6f800', '#F4F6F8FF', '#F4F6F8FF'];
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      /**
       * @Problem: As we are using enableFreeze, which halt the setState until component is in foreground.
       * In this case, once user will open this component and quickly move to another component, setInterval will keep on running and
       * setState will not get called (will be added to queue) due to enableFreeze until this component is in foreground.
       * Once component is in foreground, all setState will trigger at once and this.index will cause arrayIndexOutOfBound
       * because its incremented value.
       *
       * @Fix: Added Optional Chaining to  animationInfo[this.index]?.image_mapping as a safeguard.
       * removed this.index++ and clearInterval code out of setState Callback to get it cancel as soon index reaches the limit.
       * Otherwise with enableFreeze, setInterval will keep on running in background.
       */
      this.setState(
        {
          index: this.index + 1,
        },
        () => {
          animationInfo[this.index]?.image_mapping.forEach((i) => {
            this.card[i].flip();
          });
        },
      );
      this.index++;
      if (this.index >= animationInfo.length - 1) {
        clearInterval(this.interval);
      }
    }, 3100);
  }

  bottomActionButton = ({ buttonStyle, buttonText, buttonAction }) => {
    return (
      <RoundedButton
        buttonText={buttonText}
        buttonTextColor={colors.white}
        buttonColor={colors.black}
        buttonAction={buttonAction}
        style={buttonStyle}
        buttonTextStyle={styles.buttonText}
      />
    );
  };

  bottomActionButtonSecodary = ({ buttonStyle, buttonText, buttonAction }) => {
    return (
      <RoundedButton
        buttonText={buttonText}
        buttonTextColor={colors.black}
        buttonColor={'#F4f6f8'}
        buttonAction={buttonAction}
        style={buttonStyle}
        buttonTextStyle={styles.buttonText}
      />
    );
  };

  navigateToSelfie = () => {
    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          const { navigation } = this.props;
          navigation.navigate('TakeSelfie', {
            previousScreen: SCREEN_CONSTANTS.PAYOFF_EMPTY_STATE,
          });
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          Linking.openSettings();
        }
      },
    );
  };

  primaryButtonAction = () => {

    if (isWeb()) {
      AnalyticsManager.logEvent(
        EventType.MISC_WEB_EVENTS.GET_APP_CLICK,
        { location: location.pathname },
      );
      if (isWebAndroid() || isWebIOS()) {
        Linking.openURL(FOXY_URLS.dynamicAppLink);
      } else {
        Linking.openURL(APP_DOWNLOAD_LINK);
      }
    }
    const { navigation, selfie_image_url } = this.props;
    const screenName =
      Utility.isBlank(selfie_image_url) &&
      !AppConfig.getBooleanValue(Config.HIDE_SELFIE_FLOW_PAYOFF)
        ? SCREEN_CONSTANTS.TAKE_SELFIE
        : SCREEN_CONSTANTS.ATTRIBUTE_SELECTOR;
    switch (screenName) {
      case SCREEN_CONSTANTS.TAKE_SELFIE:
        this.navigateToSelfie();
        break;
      case SCREEN_CONSTANTS.ATTRIBUTE_SELECTOR:
        navigation.navigate(screenName, {
          previousScreen: SCREEN_CONSTANTS.PAYOFF_EMPTY_STATE,
        });
        break;
      default:
        break;
    }
  };

  retakeSelfieAction = () => {
    const { navigation } = this.props;
    navigation.navigate(SCREEN_CONSTANTS.TAKE_SELFIE, {
      previousScreen: SCREEN_CONSTANTS.PAYOFF_EMPTY_STATE,
    });
  };

  conditionalSecondaryButton = () => {
    const { selfie_image_url } = this.props;
    if (isWeb() || AppConfig.getBooleanValue(Config.HIDE_SELFIE_FLOW_PAYOFF)) {
      return null;
    }
    if (Utility.isBlank(selfie_image_url)) {
      return (
        <View style={styles.alignCenter}>
          <Text style={styles.cameraPermissionText}>
            You’ll be asked for camera permission at next step
          </Text>
        </View>
      );
    }
    return (
      <View style={styles.alignCenter}>
        <this.bottomActionButtonSecodary
          buttonAction={this.retakeSelfieAction}
          buttonStyle={styles.retakeSelfie}
          buttonText={'Retake Selfie'}
        />
      </View>
    );
  };

  renderGridRow = ({ intArray }) => {
    return (
      <View style={styles.gridContainer} dataSet={{ media: ids.gridContainer }}>
        {intArray.map((index) => {
          return (
            <ReactNativeCardFlip ref={(card) => (this.card[index] = card)}>
              <Image
                source={images.ingredients_empty[`empty_uaav_${index}`]}
                style={styles.gridImage}
                dataSet={{ media: ids.gridImage }}
              />
              <Image
                source={images.ingredients[`uaav_${index}`]}
                style={styles.gridImage}
                dataSet={{ media: ids.gridImage }}
              />
            </ReactNativeCardFlip>
          );
        })}
      </View>
    );
  };

  render() {
    const { index } = this.state;
    const { selfie_image_url } = this.props;
    let primaryButtonText = Utility.isPresent(selfie_image_url)
      ? 'Review Selfie'
      : 'Take Smart Selfie';
    if (AppConfig.getBooleanValue(Config.HIDE_SELFIE_FLOW_PAYOFF)) {
      primaryButtonText = 'Build your profile';
    }
    if (isWeb()) primaryButtonText = 'Download App';
    const primaryButtonStyle = Utility.isPresent(selfie_image_url)
      ? styles.reviewSelfie
      : styles.takeSmartSelfie;
    return (
      <View style={styles.container} dataSet={{ media: ids.container }}>
        <View style={styles.animationContainer}>
          <TypeWriter style={styles.typeWriter} typing={1}>
            {animationInfo[index].simple_title_text}
            <Text
              style={{
                fontSize: 24,
                fontFamily: 'Roboto-Bold',
                color: animationInfo[index].title_color,
              }}
            >
              {` ${animationInfo[index].colored_title_text}`}
            </Text>
          </TypeWriter>
        </View>
        <Text
          style={{
            fontSize: 18,
            fontFamily: 'Roboto-Medium',
            color: animationInfo[index].sub_heading_line_1_color,
          }}
        >
          {animationInfo[index].sub_heading_line_1}
        </Text>
        <Text
          style={{
            fontSize: 16,
            fontFamily: 'Roboto-Regular',
            color: colors.silver,
            marginTop: 4,
            height: 40,
          }}
        >
          {animationInfo[index].sub_heading_line_2}
        </Text>
        <View style={styles.emptyScreenGridContainer} dataSet={{ media: ids.emptyScreenGridContainer }}>
          <this.renderGridRow intArray={[1, 2, 3, 4]} />
          <this.renderGridRow intArray={[5, 6, 7, 8]} />
          <this.renderGridRow intArray={[9, 10, 11, 12]} />
          <this.renderGridRow intArray={[13, 14, 15, 16]} />
        </View>
        <LinearGradient
          colors={this.linearGradient}
          style={styles.linearGradient}
        >
          <this.bottomActionButton
            buttonAction={this.primaryButtonAction}
            buttonStyle={primaryButtonStyle}
            buttonText={primaryButtonText}
          />
          <this.conditionalSecondaryButton />
        </LinearGradient>
      </View>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    facialAnalysis: state.UserAccountInfo.facialAnalysis,
    selfie_image_url: state.UserAccountInfo.profile.selfie_image_url,
  };
};

const mapDispatchToProps = {};

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PayoffEmptyState),
);

const { ids, styles } = StyleSheet.create({
  container: {
    marginTop: 40,
    paddingHorizontal: 12,
    flex: 1,
    paddingTop: 16 + Utility.topInset,
    '@media (min-width: 768px)': {
      width: getScreenWidth(),
      alignSelf: 'center',
    },
  },
  takeSmartSelfie: {
    width: 170,
    height: 36,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colors.black,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  reviewSelfie: {
    width: 153,
    height: 36,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colors.black,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  buttonText: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    marginLeft: 8,
  },
  alignCenter: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  cameraPermissionText: {
    fontSize: 11,
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    marginBottom: 12,
  },
  retakeSelfie: {
    width: 153,
    height: 36,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  linearGradient: {
    height: 286,
    width: Utility.getScreenWidth(),
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  animationContainer: { flexDirection: 'row', marginBottom: 32 },
  typeWriter: {
    fontSize: 24,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  emptyScreenGridContainer: {
    flexDirection: 'row',
    marginTop: 82,
    justifyContent: 'space-between',
    '@media (min-width: 768px)': {
      marginTop: 30,
    },
  },
  gridImage: {
    height: 78,
    width: 78,
    resizeMode: 'contain',
    marginBottom: 13,
    '@media (min-width: 768px)': {
      height: 220,
      width: 220,
    },
  },
  gridContainer: {
    marginTop: 32,
    '@media (min-width: 768px)': {
      marginTop: 16,
    },
  },
});
