import {
  Text,
  StyleSheet,
  View,
  FlatList,
  Image,
  Linking,
  TouchableOpacity,
} from 'react-native';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './styles';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import colors from '../../theme/Colors';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import Utility from '../../utils/Utility';
import { StaticNavigationHeader } from '../../components/shared';
import { getShipmentTrackingDetails } from '../../actions/ActionTypes';
import moment from 'moment';
import FastImageView from '../../components/FastImageView';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

class CustomerOrderStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shipmentData: {},
      isLoading: true,
    };
    const { navigation, route } = this.props;
    this.headerTitle = route.params?.headerTitle;
    this.callBackRequestReasonId = route.params?.callBackRequestReasonId;
    this.entityType = route.params?.entityType;
    this.entityId = route.params?.entityId;
    this.orderAasmState = route.params?.orderAasmState;
    this.orderDeliveryDate = route.params?.deliveryDate;
    this.orderId = route.params?.orderId;
  }

  componentDidMount() {
    const { navigation, getShipmentTrackingDetails } = this.props;
    getShipmentTrackingDetails(
      this.entityId,
      this.getShipmentTrackingDetailsCallBack,
    );
  }

  getShipmentTrackingDetailsCallBack = (success, response) => {
    if (success) {
      this.setState({
        shipmentData: response.data,
        isLoading: false,
      });
      AnalyticsManager.logEvent('page_load_2', {
        [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.WHERE_IS_MY_ORDER,
      });
    }
  };

  orderDetail = () => {
    const { shipmentData: { attributes = {} } = {} } = this.state;
    const {
      expectedDeliveryDate = '',
      awbNumber = '',
      aasmState = '',
      deliveryPatner = '',
    } = attributes;
    const formatExpectedShipmentDeliveryState = Utility.isPresent(
      expectedDeliveryDate,
    )
      ? moment(expectedDeliveryDate).format('MMMM D YYYY')
      : '-';
    const formatExpectedDeliveryState =
      this.entityType === 'order'
        ? this.orderDeliveryDate
        : formatExpectedShipmentDeliveryState;
    const status =
      this.entityType === 'order' ? this.orderAasmState : aasmState;
    const awbNumberStatus =
      this.entityType === 'order' ? 'Updated once shipped' : awbNumber;
    const isBlankAWbNumber = Utility.isBlank(awbNumber);
    const awbTextStyle = isBlankAWbNumber
      ? styles.disableAwbStyleText
      : styles.orderDetailBlueText;
    return (
      <View style={styles.orderDetailContainer}>
        <View style={styles.detailWrapper}>
          <View style={styles.rowWrapper}>
            <Text style={styles.orderDetailText}>Expected delivery by:</Text>
            <Text style={styles.orderDetailSubText}>
              {formatExpectedDeliveryState}
            </Text>
          </View>
          <View style={styles.rowWrapper}>
            <Text style={styles.orderDetailText}>Shipment status:</Text>
            <Text style={styles.orderDetailSubText}>{status}</Text>
          </View>
          <View style={styles.rowWrapper}>
            <Text style={styles.orderDetailText}>AWB Number:</Text>
            <TouchableOpacity
              onPress={this.trackUrl}
              disabled={isBlankAWbNumber}
            >
              <Text style={awbTextStyle}>{awbNumberStatus}</Text>
            </TouchableOpacity>
          </View>
          {Utility.isPresent(deliveryPatner) && (
            <View style={styles.rowWrapper}>
              <Text style={styles.orderDetailText}>Delivery partner:</Text>
              <Text style={styles.orderDetailSubText}>{deliveryPatner}</Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  RenderDashedLine = ({ index }) => {
    return <Image style={styles.horizontalLine} source={images.dashedLine} />;
  };

  RenderPoint = ({ index }) => {
    return (
      <View style={styles.pointStyle}>
        <View style={styles.point} />
      </View>
    );
  };

  isLastStep = (index) => index + 1 === this.stepsToShow?.length;

  renderSteps = ({ item, index }) => {
    const {
      attributes: {
        changedAt = '',
        currentState = '',
        currentLocation = '',
        instructions = '',
      },
    } = item;
    return (
      <>
        <View style={styles.listItemContainer}>
          <this.RenderPoint index={index} />
          <View style={styles.stepShowContainer}>
            <Text style={styles.currentStateText}>
              {currentState}{' '}
              <Text style={styles.statusChangedText}>({changedAt})</Text>
            </Text>
            <Text style={styles.lastLocationText}>{currentLocation}</Text>
            <Text style={styles.instructionsText}>{instructions}</Text>
          </View>
        </View>
        <this.RenderDashedLine index={index} />
      </>
    );
  };

  onPressRequestCallBack = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.CTA]: 'Request a call',
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.WHERE_IS_MY_ORDER,
    });
    navigation.navigate('RequestCallBackPage', {
      headerTitle: this.headerTitle,
      callBackRequestReasonId: this.callBackRequestReasonId,
      entityType: this.entityType,
      entityId: this.entityId,
      orderId: this.orderId,
    });
  };

  keyExtractor = (item, index) => index;

  renderOrderData = () => {
    AnalyticsManager.logEvent('page_load_2', {
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.WHERE_IS_MY_ORDER,
    });
    return (
      <>
        <Text style={styles.shipmentMessageStyle}>
          No shipment data generated yet.
        </Text>
      </>
    );
  };

  trackUrl = () => {
    const { navigation } = this.props;
    const {
      shipmentData: {
        attributes: { trackingUrl = '', awbNumber = '' } = {},
      } = {},
    } = this.state;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.CTA]: 'Track status on partner website',
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.WHERE_IS_MY_ORDER,
    });
    navigation.navigate('WebUrlView', {
      destination: trackingUrl,
      title: `Tracking - #${awbNumber}`,
    });
  };

  renderShipmentData = () => {
    const {
      shipmentData: {
        attributes: {
          trackingDetail: { data: shipmentTrackingData = [] } = {},
          shipmentMessage = '',
        } = {},
      } = {},
      shipmentData,
    } = this.state;
    return (
      <>
        {Utility.isPresent(shipmentMessage) && (
          <Text style={styles.shipmentMessageStyle}>{shipmentMessage}</Text>
        )}
        {shipmentTrackingData && (
          <FlatList
            style={styles.listContainer}
            data={shipmentTrackingData}
            renderItem={this.renderSteps}
            keyExtractor={this.keyExtractor}
            bounces={false}
          />
        )}
        <View style={styles.buttonContainer}>
          <FoxyShadowButton
            width={Utility.getScreenWidth() - 24}
            title={'Request a call'}
            textStyle={styles.textStyle}
            onPress={this.onPressRequestCallBack}
            style={styles.button}
            firstColor={colors.white}
            secondColor={colors.white}
            underlayColor={colors.transparent}
          />
        </View>
        <View style={styles.linkButtonContainer}>
          <FoxyShadowButton
            width={Utility.getScreenWidth() - 24}
            title={'Track status on partner website'}
            onPress={this.trackUrl}
            firstColor={colors.black}
            secondColor={colors.black}
            underlayColor={colors.black}
            borderRadius={4}
          />
        </View>
      </>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <StaticNavigationHeader title={this.headerTitle} />
        <this.orderDetail />
        {this.entityType === 'shipment' ? (
          <this.renderShipmentData />
        ) : (
          <this.renderOrderData />
        )}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getShipmentTrackingDetails,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(CustomerOrderStatus),
);
