import { StyleSheet } from 'react-native';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { isDesktop } from '../../utils/BooleanUtility';

const numOfItems = isDesktop() ? 6 : 3;

const AdStyles = StyleSheet.create({
  cardStyle: {
    width: Utility.getDynamicWidthForGrid(numOfItems, 24),
    margin: 4,
    marginBottom: 8,
    marginTop: 8,
    borderRadius: 4,
  },
  adStyle: {
    height: Utility.getDynamicWidthForGrid(numOfItems, 24),
    justifyContent: 'center',
    alignItems: 'center',
    width: Utility.getDynamicWidthForGrid(numOfItems, 24),
    borderRadius: 4,
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  adText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#9d9d9d',
  },
});

export default AdStyles;
