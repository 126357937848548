import { ReactMoE } from '../../libraries/ReactMoe';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import Utility from '../Utility';
import { MoEReactInbox } from '../../libraries/ReactMoe';

const fireInAppEvents = (Event, inAppInfo) => {
  AnalyticsManager.logEvent(Event, {
    [EventParameterKey.CAMPAIGN_ID]: inAppInfo.campaignId,
    [EventParameterKey.CAMPAIGN_NAME]: inAppInfo.campaignName,
  });
};

export default class MoeEngageHandler {
  static setEventListeners = (onClickNotification) => {
    if (Utility.isWeb()) return;
    if (Utility.isAndroid()) {
      // In iOS push notifications are being handled natively
      ReactMoE.setEventListener('pushClicked', onClickNotification);
    }
    ReactMoE.setEventListener('inAppCampaignShown', (inAppInfo) => {
      fireInAppEvents(EventType.forceUpdate.IN_APP_CAMPAIGN_SHOWN, inAppInfo);
    });

    ReactMoE.setEventListener('inAppCampaignClicked', (inAppInfo) => {
      fireInAppEvents(EventType.forceUpdate.IN_APP_CAMPAIGN_CLICKED, inAppInfo);
    });

    ReactMoE.setEventListener('inAppCampaignDismissed', (inAppInfo) => {
      fireInAppEvents(EventType.forceUpdate.IN_APP_CAMPAIGN_DISMISS, inAppInfo);
    });

    ReactMoE.setEventListener('inAppCampaignCustomAction', (inAppInfo) => {
      fireInAppEvents(
        EventType.forceUpdate.IN_APP_CAMPAIGN_CUSTOM_ACTION,
        inAppInfo,
      );
    });
  };

  static removeEventListeners = () => {
    if (Utility.isWeb()) return;
    ReactMoE.removeEventListener('inAppCampaignShown');
    ReactMoE.removeEventListener('inAppCampaignClicked');
    ReactMoE.removeEventListener('inAppCampaignDismissed');
    ReactMoE.removeEventListener('inAppCampaignCustomAction');
  };

  static initialize = () => {
    if (Utility.isWeb()) return;
    const appId = __DEV__
      ? Config.NOTIFICATIONS_HANDLER_APP_ID_DEBUG
      : Config.NOTIFICATIONS_HANDLER_APP_ID;
    console.tron.log('APP ID', appId);
    ReactMoE.initialize(appId);
    MoEReactInbox.initialize(appId);
    ReactMoE.showInApp();
  };
}
