import { takeLatest, takeEvery, put, select } from 'redux-saga/effects';
import {
  updateMediaData,
  favoriteContentFailed,
  UPDATE_MEDIA_ID,
  TOGGLE_FOLLOW_ARTIST,
  TOGGLE_FAVORITE_CONTENT,
} from '../actions/MediaDetailActions';
import { logoutUser } from '../actions/LoginActions';
import * as selectors from './selectors';
import { API_DOMAIN } from '../config/Constants';
import { followArtistFailed } from '../actions/ActionTypes';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

function* fetchMediaDetails(action) {
  const { id, slug, callback } = action;
  const entities = yield select(selectors.entities);
  const video = entities.videos[id];
  if (!(video && Utility.isPresent(video.objects))) {
    const url = `${API_DOMAIN}${slug}`;
    try {
      const response = yield fetch(url, {
        method: 'GET',
        headers: yield getApiHeaders(),
      });
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        callback(json);
      }
    } catch (error) {
      console.tron.log(`error in response  of media api ${error}`);
    }
  }
}

function* toggleArtistFollow(action) {
  const {
    data: { url: slug, authToken },
  } = action;
  const url = `${API_DOMAIN}${slug}`;
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken }),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode !== 200) {
      yield put(followArtistFailed(action.data));
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)} auth token : ${authToken}`);
  }
}

function* toggleFavoriteContent(action) {
  const {
    data: { url: slug, authToken },
  } = action;
  const url = `${API_DOMAIN}${slug}`;
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken }),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode !== 200) {
      yield put(favoriteContentFailed(action.data));
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)}, auth token : ${authToken}`);
  }
}

export default function* watchFetchMediaDetailsActionSaga() {
  yield takeEvery(UPDATE_MEDIA_ID, fetchMediaDetails);
  yield takeLatest(TOGGLE_FOLLOW_ARTIST, toggleArtistFollow);
  yield takeLatest(TOGGLE_FAVORITE_CONTENT, toggleFavoriteContent);
}
