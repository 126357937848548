import * as types from '../actions/ActionTypes';
import initialState from './initialState';

export default function userPersonalizationInfo(
  state = initialState.userPersonalizationInfo,
  action,
) {
  switch (action.type) {
    case types.SAVE_PERSONALIZATION_ATTRIBUTES:
      return {
        ...state,
        ...action.attributes,
      };
    case types.LOGOUT_USER:
      return {};
    default:
      return state;
  }
}
