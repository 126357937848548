import {
  NOTIFICATION_ACTIONS,
  NOTIFICATION_IDENTIFIERS,
  NOTIFICATION_TYPE,
  WEB_URL,
} from '../../config/Constants';
import NotificationManager from '../../utils/NotificationsManager';
import Utility from '../../utils/Utility';

class RoutineNotifications {
  static createRoutineNotifications(data, has_selfie) {
    this.showSelfieAnalysisNotification(data, has_selfie);
  }

  static create1HrBeforeNotification(data) {
    const { name: routineName = '', id, startsAt = '' } = data;
    const title = `All set to start ${routineName}?`;
    const description = `Routine starts in 1 hour! See you on the other side 😉`;
    const notificationType = 'pre_routine';

    this.createNotification(
      title,
      description,
      'deeplink',
      cta,
      notificationType,
      id.toString(),
      startsAt,
    );
  }

  static showSelfieAnalysisNotification(data, has_selfie) {
    if (
      Utility.isBlank(data) ||
      data.length === 0 ||
      data.item_type !== 'Routine'
    ) {
      return;
    }
    const firstRoutine = data[0];
    const {
      name: routineName = '',
      artist: artistName = '',
      slug = '',
    } = firstRoutine;
    let title = `You're just 1 selfie away...`;
    let description = `Get product recommendations directly from the expert! Take your selfie & see what ${artistName} recommends for you.`;
    let link = `${WEB_URL}/take_selfie`;
    let cta = NOTIFICATION_ACTIONS.CLICK_SELFIE.cta;
    let type = NOTIFICATION_TYPE.SUBSCRIPTION_SUCCESS_OPEN_SELFIE;
    if (has_selfie) {
      title = `Get ready for ${routineName}!`;
      description = `Congrats! You have successfully subscribed to the routine. Tap here for more info`;
      link = `${WEB_URL}/routines/${slug}`;
      cta = NOTIFICATION_ACTIONS.CONTINUE.cta;
      type = NOTIFICATION_TYPE.SUBSCRIPTION_SUCCESS_GOTO_ROUTINE;
    }
    this.createNotification(
      title,
      description,
      link,
      cta,
      type,
      NOTIFICATION_IDENTIFIERS.SELFIE_ANALYSIS,
      null,
    );
  }

  static createNotification(
    title,
    description,
    link,
    cta,
    type,
    notificationIdentifier,
    display_time,
  ) {
    const payload = {
      title,
      description,
      deepLink: link,
      primary_cta: cta,
      primary_destination: link,
      secondary_cta: NOTIFICATION_ACTIONS.DISMISS.cta,
      secondary_destination: NOTIFICATION_ACTIONS.DISMISS.action,
      type,
      display_time,
    };
    NotificationManager.createNotification(payload, notificationIdentifier);
  }
}

export default RoutineNotifications;
