import React from 'react';
import { Linking } from 'react-native';
import AccountOptionItem from './AccountOptionItem';
import { FOXY_URLS, PLAY_STORE_LINK } from '../../config/Constants';
import { AnalyticsManager, EventType } from '../../analytics';
import { isWebAndroid, isWebIOS } from '../../utils/BooleanUtility';

export default function GetAppOption() {
  const getApp = () => {
    AnalyticsManager.logEvent(
      EventType.MISC_WEB_EVENTS.GET_APP_CLICK,
      { location: location.pathname },
    );
    if (isWebAndroid() || isWebIOS()) {
      Linking.openURL(FOXY_URLS.dynamicAppLink);
    } else {
      Linking.openURL(FOXY_URLS.playStore);
    }
  };
  return <AccountOptionItem label="Get App" handlePress={getApp} />;
}
