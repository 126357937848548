import React from 'react';
import { Text, StyleSheet } from 'react-native';
import colors from '../../../../theme/Colors';
import HyperLink from './HyperLink';
import Utility from '../../../../utils/Utility';

export const ModalBody = ({
  title = '',
  numberOfLines = 6,
  textAlign = 'center',
  message = '',
  isAbsolute = false,
  headerMediaNotExists = false,
}) => {
  let marginStyle = {};
  if (isAbsolute) {
    marginStyle = { marginTop: 38 };
  } else if (headerMediaNotExists) {
    marginStyle = { marginTop: 18 };
  }
  return (
    <>
      <Text style={[styles.title, marginStyle]}>{title}</Text>
      <Text
        numberOfLines={numberOfLines}
        style={[styles.text, { textAlign }]}
        allowFontScaling={false}
      >
        {message}
      </Text>
    </>
  );
};

export const WhatsappHyperlink = ({ message = '' }) => {
  if (!message) return null;
  const onPress = () => {
    Utility.openWhatsApp(message);
  };

  return (
    <Text style={styles.text}>
      <HyperLink onPress={onPress}>Chat with us on Whatsapp</HyperLink>
      {' for any question'}
    </Text>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    color: colors.foxyBlack,
    width: 300,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
  },
  text: {
    fontSize: 14,
    color: colors.silver,
    paddingHorizontal: 20,
    marginTop: 12,
    fontFamily: 'Roboto',
  },
});

