import AnalyticsEvent from './AnalyticsEvent';
import { gtmDataLayer } from './GTMAnalytics';

class GoogleAnalytics {
  static trackEvent = (event) => {
    if (event instanceof AnalyticsEvent) {
      const { meta, eventType } = event;
      gtmDataLayer({ event: eventType, ...meta });
    }
  };
}

export default GoogleAnalytics;
