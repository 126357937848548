import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Animated,
  Easing,
  TouchableWithoutFeedback,
} from 'react-native';
import styles from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { publishPost } from './../../actions/UploadPostActions';
import { setVideoUploadProgress } from './../../actions/ActionTypes';
import { bindActionCreators } from 'redux';
import CountDown from '../../helpers/Counter';
import { AnalyticsManager } from '../../analytics';
import { CURRENCY_SYMBOL } from '../../config/Constants';
import TextTicker from 'react-native-text-ticker';

class OfferTray extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      animation: new Animated.Value(-70),
    };
  }
  cartTrayConstants = {
    trayText: 'Get free product for your first order',
    viewText: 'VIEW BAG',
  };

  openCart = () => {
    this.props.toggleCartVisibility();
  };

  componentDidMount() {
    const {
      cartItems,
      showCartTray,
      hideCartTray,
      isCartTrayVisible,
      lastVideoUpload: { status = '', progress = 0, postData } = {},
    } = this.props;
    if (Utility.isBlank(cartItems) && isCartTrayVisible) {
      hideCartTray();
    } else if (!Utility.isBlank(cartItems) && !isCartTrayVisible) {
      showCartTray();
    }

    if (
      Utility.isPresent(status) &&
      status === 'in_progress' &&
      Utility.isPresent(postData)
    ) {
      this.publishPostToServer();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { cartItems, showCartTray, hideCartTray } = this.props;
    if (Utility.isBlank(cartItems) && !Utility.isBlank(nextProps.cartItems)) {
      showCartTray();
    } else if (
      !Utility.isBlank(cartItems) &&
      Utility.isBlank(nextProps.cartItems)
    ) {
      hideCartTray();
    }
  }

  hideTray = () => {
    Animated.timing(this.state.animation, {
      toValue: -70,
      duration: 700,
    }).start();
  };

  showUploadProgress = (props) => {
    const {
      posts,
      currentPostId,
      isUploading,
      lastVideoUpload: { status = '' } = {},
    } = this.props;
    const currentPost = posts[currentPostId];
    let uploadMessage = 'Uploading your post';
    let RightSection = this.showUploadingIndicator;
    if (!isUploading) {
      uploadMessage = 'Post Uploaded';
      RightSection = this.viewButton;
    }
    if (status === 'failed') {
      uploadMessage = 'Video uploading failed';
      RightSection = this.retryUpload;
    }

    return (
      <View style={styles.uploadingPostContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image
            source={{ uri: `file://${currentPost.postThumbnail}` }}
            style={styles.postThumbnail}
          />
          <Text style={styles.uploadingStatus}>{uploadMessage}</Text>
        </View>
        <RightSection />
      </View>
    );
  };

  showUploadingIndicator = () => (
    <ActivityIndicator size='small' color={colors.foxyPink} />
  );

  viewButton = () => (
    <TouchableOpacity
      style={styles.viewCartButton}
      onPress={() => {}}
      hitSlop={Utility.getHitSlop()}
    >
      <Text style={styles.viewButtonText}>VIEW</Text>
    </TouchableOpacity>
  );

  publishPostToServer = () => {
    const {
      publishPost,
      setVideoUploadProgress,
      lastVideoUpload: { id, postData } = {},
    } = this.props;

    publishPost(id, postData, (value) => {
      if (value < 100) {
        setVideoUploadProgress(value);
      }
    });
  };

  retryUpload = () => {
    return (
      <TouchableOpacity
        style={styles.viewCartButton}
        onPress={this.publishPostToServer}
        hitSlop={Utility.getHitSlop()}
      >
        <Text style={styles.viewButtonText}>RETRY</Text>
      </TouchableOpacity>
    );
  };

  onCtaPress = () => {
    const { cartItems, navigation } = this.props;
    if (cartItems.length > 1) {
      AnalyticsManager.logEvent('VIEW_BAG', {
        source: 'bottom dock',
      });
      navigation.push('Cart', {
        source: 'offer_tray',
      });
    } else {
      AnalyticsManager.logEvent('VIEW_PRODUCTS', {
        source: 'bottom dock',
      });
      navigation.push('FreeProductScreen');
    }
  };

  render() {
    const { cartOffers, userCreatedAt } = this.props;

    if (Utility.isBlank(userCreatedAt)) {
      return null;
    }
    if (Utility.isBlank(cartOffers)) {
      return null;
    }
    if (
      Utility.isPresent(cartOffers) &&
      !cartOffers.new_user_offer_applicable
    ) {
      return null;
    }
    const {
      cartItems,
      isUploading,
      addTopShadowToTray,
      isCartTrayVisible,
      toggleCartVisibility,
      cartOffers: {
        arguments: { timer_interval, sub_body },
        sku: { mrp, name },
      },
    } = this.props;

    const image =
      !Utility.isBlank(cartOffers) &&
      !Utility.isBlank(cartOffers.sku) &&
      !Utility.isBlank(cartOffers.sku.transparent_image_url)
        ? cartOffers.sku.transparent_image_url
        : cartOffers.sku.images[0];

    const brandName =
      !Utility.isBlank(cartOffers) &&
      !Utility.isBlank(cartOffers.sku) &&
      !Utility.isBlank(cartOffers.sku.brand)
        ? cartOffers.sku.brand.name
        : '';

    const categoryName =
      !Utility.isBlank(cartOffers) && !Utility.isBlank(cartOffers.sku)
        ? cartOffers.sku.category
        : '';

    const displayName =
      !Utility.isBlank(cartOffers) &&
      !Utility.isBlank(cartOffers.sku) &&
      !Utility.isBlank(cartOffers.sku.custom_name)
        ? cartOffers.sku.custom_name
        : `${brandName} ${categoryName}`;

    const productPrice =
      !Utility.isBlank(cartOffers) && !Utility.isBlank(cartOffers.sku)
        ? cartOffers.sku.mrp
        : 0;

    const styleImage = Utility.isBlank(cartOffers.sku.transparent_image_url)
      ? {
          width: 35,
          height: 50,
          position: 'absolute',
          left: 16,
        }
      : {
          width: 35,
          height: 100,
          transform: [{ rotate: '25deg' }],
          position: 'absolute',
          left: 16,
        };

    let text = 'CHANGE';
    // if (isUploading && Utility.isBlank(cartItems)) {
    //   return <this.showUploadProgress isUploading={isUploading} />;
    // }

    if (!isCartTrayVisible) {
      Animated.timing(this.state.animation, {
        toValue: -70,
        duration: 700,
      }).start();
      // return null;
    } else {
      Animated.timing(this.state.animation, {
        toValue: 0,
        duration: 700,
      }).start();
    }

    if (cartItems.length > 1) {
      text = 'CHECKOUT';
    }

    const shadowStyle = addTopShadowToTray
      ? {
          shadowOffset: { width: 1, height: -3 },
          shadowColor: '#dddddd',
        }
      : {};
    return (
      <Animated.View
        style={{
          bottom: this.state.animation,
          width: Utility.getScreenWidth(),
        }}
      >
        <TouchableWithoutFeedback
          onPress={this.onCtaPress}
          hitSlop={Utility.getHitSlop()}
        >
          <View
            style={{
              height: 52,
              width: '100%',
              position: 'absolute',
              bottom: 0,
              backgroundColor: colors.white,
              zIndex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              elevation: 2,
              shadowRadius: 2,
              shadowColor: colors.black,
              shadowOpacity: 0.5,
              shadowOffset: { width: 1, height: 2 },
              overflow: 'visible',
              paddingLeft: 10,
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                marginLeft: 4,
                paddingLeft: 6,
              }}
            >
              <View
                style={[
                  styles.cartText,
                  {
                    marginLeft: 30,
                    paddingLeft: 6,
                    marginTop: 8,
                  },
                ]}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: Utility.getScreenWidth() / 1.6,
                  }}
                >
                  <TextTicker
                    duration={12000}
                    easing={Easing.linear}
                    loop
                    repeatSpacer={50}
                    marqueeDelay={1000}
                  >
                    <Text style={{ color: '#173143', fontWeight: '500' }}>
                      FREE
                    </Text>
                    <Text
                      style={{
                        color: '#173143',
                        fontFamily: 'Roboto-Regular',
                      }}
                    >{` ${displayName} worth ${CURRENCY_SYMBOL}${productPrice}`}</Text>
                  </TextTicker>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={[
                    styles.cartText,
                    {
                      marginBottom: 8,
                      marginLeft: 30,
                      paddingLeft: 6,
                      marginTop: 4,
                      fontSize: 12,
                      color: '#979BAA',
                      fontFamily: 'Roboto-Regular',
                    },
                  ]}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  Offer ends in
                </Text>
                <CountDown
                  size={14}
                  until={Utility.calculateTimeDifference(
                    userCreatedAt,
                    timer_interval,
                  )}
                  endsAt={timer_interval}
                  // onFinish={() => alert('Finished')}
                  digitStyle={{
                    backgroundColor: '#FFF',
                    marginLeft: 0,
                  }}
                  style={{
                    marginLeft: 4,
                  }}
                  digitTxtStyle={{
                    color: '#173143',
                    fontSize: 12,
                    marginLeft: 4,
                    marginBottom: 0,
                    fontFamily: 'Roboto-Bold',
                  }}
                  timeLabelStyle={{ color: 'red', fontWeight: 'bold' }}
                  separatorStyle={{
                    color: '#262626',
                    marginBottom: 2,
                    marginLeft: 4,
                  }}
                  timeToShow={['H', 'M', 'S']}
                  timeLabels={{ m: null, s: null }}
                  showSeparator
                  withoutBlock
                />
              </View>
            </View>
            <TouchableOpacity
              style={{ position: 'absolute', right: 12, bottom: 18 }}
              onPress={this.onCtaPress}
              hitSlop={Utility.getHitSlop()}
            >
              <Text style={styles.viewButtonText}>{text}</Text>
            </TouchableOpacity>
            <Image
              source={{
                uri: Utility.getMinifiedImage(image, 56, 56),
              }}
              style={styleImage}
            />
          </View>
        </TouchableWithoutFeedback>
      </Animated.View>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
  isUploading: state.UserAccountInfo.isUploading,
  currentPostId: state.UserAccountInfo.currentPostId,
  posts: state.Posts,
  lastVideoUpload: state.UserAccountInfo.lastVideoUpload,
  userCreatedAt: state.UserAccountInfo.app_installed_at,
  cartOffers: state.cart.cartOffers,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      publishPost,
      setVideoUploadProgress,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(OfferTray)),
);
