import React, { PureComponent } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import colors from '../../theme/Colors';

class BottomSearch extends PureComponent {
  componentDidMount() {
    const { hideActivityIndicator } = this.props;
    hideActivityIndicator();
  }

  navigateOnPress = () => {
    const { navigation } = this.props;
    navigation.push('Search', {});
  };

  render() {
    return (
      <View style={styles.feedFooterContainer}>
        <Text style={styles.footerTextHeading}>
          Didn’t find what you were looking for ?
        </Text>
        <TouchableOpacity onPress={this.navigateOnPress}>
          <View style={styles.footerTextAndImageContainer}>
            <Text style={styles.footerButtonText}>Search</Text>
            <Image
              style={styles.footerSearchImage}
              source={images.ic_search_product}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withNavigation(BottomSearch);

const styles = StyleSheet.create({
  feedFooterContainer: {
    width: Utility.getScreenWidth(),
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
  },
  footerButtonView: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 16,
    borderColor: colors.foxyPink,
    borderWidth: 1,
  },
  footerTextHeading: {
    textAlign: 'center',
    color: '#979BAA',
    fontSize: 12,
    fontWeight: '400',
  },
  footerButtonText: {
    textAlign: 'center',
    color: colors.cta.lightBlue,
    fontSize: 14,
    marginTop: 8,
    fontWeight: '500',
    marginRight: 4,
  },
  footerChevronImage: {
    tintColor: '#4285F4',
    marginTop: 6,
  },
  footerSearchImage: {
    tintColor: '#4285F4',
    marginTop: 4,
  },
  footerTextAndImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
