import { View, Text } from 'react-native';
import React from 'react';

export default function FoxyCheckBox() {
  return (
    <View>
      <Text>FoxyCheckBox</Text>
    </View>
  );
}
