import React, { useState, useMemo } from 'react';
import { FlatList, StyleSheet, Animated, View } from 'react-native';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import OfferCartItemExpanded from './OfferCartItemExpanded';

const keyExtractor = (item, index) => item?.sku_id?.toString() || index;

const OfferCartItems = ({
  cartItems = [],
  navigation,
  hideAddToCart,
  offersData: {
    id: offerId,
    combo_offer,
    coupon_code,
    combo_offer_details: { quantity_required: quantities = {} } = {},
    lists = [],
  },
  itemData = {},
  scrollY,
  scrollToList,
  setCurrentList,
  refreshing = { refreshing },
  force_apply_coupon,
  slug,
  list_to_sku_id,
}) => {
  if (refreshing || !combo_offer) {
    return null;
  }

  const flatListRef = React.useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  const comboColors = [
    '#2397F1',
    '#C26F18',
    '#01B460',
    '#EE9B0F',
    '#2397F1',
    '#C26F18',
    '#01B460',
    '#EE9B0F',
    '#2397F1',
    '#C26F18',
    '#01B460',
    '#EE9B0F',
    '#2397F1',
    '#C26F18',
    '#01B460',
    '#EE9B0F',
  ];

  cartItems.sort((a, b) => {
    return parseInt(a.id) - parseInt(b.id);
  });

  const cartItemsToRender = useMemo(() => {
    return Object.entries(quantities).reduce(
      (comboCartItems, [listId, quantityRequiredFromThisList]) => {
        const comboOfferList = lists.find((element) => element.id === listId);
        const comboOfferCardPrompt =
          comboOfferList?.combo_builder_card_prompt || '';
        const bgColor = comboColors.shift();

        let totalQuantityForThisList = 0;
        const filteredCartItems = cartItems.reduce(
          (filteredCartItems, cartItem) => {
            if (list_to_sku_id[listId]?.includes(cartItem.sku_id)) {
              filteredCartItems.push(cartItem);
              totalQuantityForThisList += cartItem.quantity;
            }
            return filteredCartItems;
          },
          [],
        );

        if (filteredCartItems.length > 0) {
          filteredCartItems[0] = {
            ...filteredCartItems[0],
            comboOfferCardPrompt,
          };
        }

        comboCartItems.push(...filteredCartItems);

        if (totalQuantityForThisList < quantityRequiredFromThisList) {
          const emptyCardArray = Array(
            quantityRequiredFromThisList - totalQuantityForThisList,
          ).fill({
            type: 'empty',
            list_id: listId,
            bgColor,
          });
          if (totalQuantityForThisList === 0 && emptyCardArray.length > 0) {
            emptyCardArray[0] = {
              ...emptyCardArray[0],
              comboOfferCardPrompt,
            };
          }
          comboCartItems.push(...emptyCardArray);
        }
        return comboCartItems;
      },
      [],
    );
  }, [quantities, lists, cartItems, list_to_sku_id, comboColors]);

  let cartItemsStyle = {};
  let listTitleStyle = {};
  const widthRatio = 0.7;

  if (Utility.isPresent(scrollY)) {
    cartItemsStyle = {
      marginRight: 8,
      zIndex: 1000,
      height: scrollY.interpolate({
        inputRange: [0, 100, 200, 300, 400],
        outputRange: [202, 180, 160, 140, 122],
        extrapolate: 'clamp',
      }),
      width: scrollY.interpolate({
        inputRange: [0, 100, 200, 300, 400, 500],
        outputRange: [
          202 * widthRatio,
          180 * widthRatio,
          160 * widthRatio,
          140 * widthRatio,
          122 * widthRatio,
          122 * widthRatio,
        ],
      }),
    };

    listTitleStyle = {
      height: scrollY.interpolate({
        inputRange: [0, 100, 200, 300, 400],
        outputRange: [30, 20, 10, 0, 0],
        extrapolateLeft: 'clamp',
      }),
      width: scrollY.interpolate({
        inputRange: [0, 100, 200, 300, 400, 500],
        outputRange: [
          202 * widthRatio,
          180 * widthRatio,
          160 * widthRatio,
          140 * widthRatio,
          122 * widthRatio,
          122 * widthRatio,
        ],
        extrapolateLeft: 'clamp',
      }),
    };
  }

  const renderItem = ({ item, index }) => {
    if (item.quantity <= 0) {
      return null;
    }
    const comboOfferCardPrompt = Utility.isPresent(item.comboOfferCardPrompt)
      ? item.comboOfferCardPrompt
      : '';

    return (
      <View style={styles.itemContainer}>
        <Animated.View style={listTitleStyle}>
          <Animated.Text style={styles.listTitle} numberOfLines={1}>
            {comboOfferCardPrompt}
          </Animated.Text>
        </Animated.View>
        <Animated.View style={cartItemsStyle}>
          <OfferCartItemExpanded
            cartItem={item}
            navigation={navigation}
            hideAddToCart={hideAddToCart}
            itemData={itemData}
            setCurrentIndex={() => {
              setCurrentIndex(index);
              scrollToList();
            }}
            currentIndex={currentIndex}
            index={index}
            setCurrentList={setCurrentList}
            coupon_code={coupon_code}
            scrollY={scrollY}
            force_apply_coupon={force_apply_coupon}
            comboOfferCardPrompt={comboOfferCardPrompt}
          />
        </Animated.View>
      </View>
    );
  };

  return (
    <FlatList
      ref={flatListRef}
      data={cartItemsToRender}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      style={styles.container}
      extraData={cartItems}
      horizontal
      contentContainerStyle={styles.largeCartItemsContainer}
      showsHorizontalScrollIndicator={false}
    />
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: colors.white },
  itemContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  largeCartItemsContainer: {
    paddingLeft: 12,
    paddingTop: 16,
    paddingBottom: 16,
  },
  upperComboList: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 16,
    backgroundColor: '#fff',
    zIndex: 1,
  },
  upperComboContainer: { paddingLeft: 12, paddingBottom: 16, paddingTop: 16 },
  roundedButtonContainer: {
    height: 32,
    borderRadius: 20,
    paddingLeft: 12,
    marginLeft: 8,
  },
  roundedButtonText: { fontSize: 14, fontFamily: 'Roboto-Medium' },
  listTitle: {
    marginTop: 6,
    height: '54%',
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.black,
  },
});

export default OfferCartItems;
