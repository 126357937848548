export const GET_PERSONALISED_LISTS = 'GET_PERSONALISED_LISTS';
export const GET_USER_LOCATION = 'GET_USER_LOCATION';

export function getPersonalisedLists(slug, callback, pageNo = 0) {
  return {
    type: GET_PERSONALISED_LISTS,
    slug,
    callback,
    pageNo,
  };
}
export function getUserLocation(callback) {
  return {
    type: GET_USER_LOCATION,
    callback,
  };
}
