import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import size from '../../../theme/Fonts';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    padding: 6,
    // width: 120,
    alignItems: 'flex-start',
    flexDirection: 'column',
    shadowOffset: { width: 1, height: 1 },
    shadowColor: 'rgba(0,0,0,0.5)',
    backgroundColor: colors.white,
  },

  mediaButtonsContainer: {
    borderRadius: 50,
    width: 60,
    height: 60,
    shadowOffset: { width: 1, height: 1 },
    shadowColor: 'rgba(0,0,0,0.5)',
    backgroundColor: colors.white,
    elevation: 1,
    shadowOpacity: Utility.isAndroid() ? 1.0 : 0.3,
    borderColor: colors.foxyPink,
    borderWidth: 2,
    padding: 6,
  },

  titleText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    paddingBottom: 2,
    color: colors.subtitle,
  },

  icon: {
    height: 17,
    width: 17,
    resizeMode: 'contain',
    marginRight: 5,
  },
  colorCircle: {
    height: 17,
    width: 17,
    marginRight: 5,
    borderRadius: 15,
  },
  selectText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyPink,
  },
  cheveronContainer: {
    height: 10,
    width: 10,
    marginLeft: 5,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: colors.foxyPink,
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
    paddingLeft: 5,
  },

  text: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    width: 100,
    color: colors.foxyBlack,
  },
});

export default styles;
