import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Linking } from 'react-native';
import { ids, styles } from './styles';
import { useNavigation, useRoute } from '@react-navigation/native';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import animations from '../../theme/Animations';
import Utility from '../../utils/Utility';
import { useDispatch } from 'react-redux';
import { getInstaProVerificationStatus } from '../../actions/InfluencerActions';
import { withEither } from '../../lib/Monads';
import { compose } from 'recompose';
import { isPresent } from '../../utils/BooleanUtility';

export default function VerificationModal() {
  const navigation = useNavigation();
  const { params: { instagramLink = '' } = {} } = useRoute();
  const dispatch = useDispatch();

  const [verificationStatus, setVerificationStatus] = useState('verifying');

  useEffect(() => {
    const interval = setInterval(getVerificationStatus, 5000);
    setTimeout(() => {
      clearInterval(interval);
      setVerificationStatus('failed');
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const closeModalAndNavigate = () => {
    navigation.replace('Dashboard');
  };

  const getVerificationStatus = () => {
    dispatch(
      getInstaProVerificationStatus((success, response) => {
        if (success) {
          setVerificationStatus('success');
        }
      }),
    );
  };

  const VerifyingContainer = () => {
    return (
      <View style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
        <FastImageView
          source={animations.oneLinkVerifying}
          style={styles.oneLinkVerifyingGif}
        />
        <Text style={styles.headerTitle}>Verifying your one link</Text>
      </View>
    );
  };

  const onClickContactUsCard = () => {
    Utility.openWhatsApp(
      `Hi, I'm facing some issues in verifying my OneLink on Instagram. Please help.`,
    );
  };

  const isVerificationSuccessful = () => verificationStatus === 'success';

  const isVerificationFailed = () => verificationStatus === 'failed';

  const openInstagramLink = () => {
    if (isPresent(instagramLink) && Linking.canOpenURL(instagramLink)) {
      Linking.openURL(instagramLink);
    }
  };

  const VerificationFailedContainer = () => {
    return (
      <View style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
        <FastImageView
          source={animations.oneLinkVerificationFailed}
          style={styles.oneLinkVerificationFailedGif}
        />
        <Text
          style={[
            styles.headerTitle,
            { fontFamily: 'Roboto-Bold', marginTop: 12 },
          ]}
        >
          {`We could not verify your onelink 🤔`}
        </Text>
        <Text
          style={[
            styles.headerSubtitle,
            {
              color: colors.black,
              textAlign: 'center',
              paddingHorizontal: 24,
              marginTop: 12,
            },
          ]}
        >
          Please confirm that correct link is added on your insta profile and
          re-initiate the verification
        </Text>
        <TouchableOpacity
          style={[styles.button, { width: '100%', marginTop: 36 }]}
          onPress={openInstagramLink}
        >
          <Text style={styles.buttonText}>Go to your Insta profile</Text>
        </TouchableOpacity>
        <Text
          style={
            ([styles.headerSubtitle], { color: colors.black, marginTop: 12 })
          }
        >
          Need some help?{' '}
          <TouchableOpacity onPress={onClickContactUsCard}>
            <Text style={([styles.headerSubtitle], { color: colors.foxyBlue })}>
              Contact Us
            </Text>
          </TouchableOpacity>
        </Text>
        <TouchableOpacity
          style={{ position: 'absolute', top: 0, right: 12 }}
          onPress={closeModalAndNavigate}
        >
          <Text style={styles.skipText}>Skip</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const VerificationSuccessfulContainer = () => {
    return (
      <View style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
        <FastImageView
          source={animations.oneLinkVerificationSuccess}
          style={styles.oneLinkVerificationFailedGif}
        />
        <Text
          style={[
            styles.headerTitle,
            { fontFamily: 'Roboto-Bold', marginTop: 12 },
          ]}
        >
          {`You are all set 😎 `}
        </Text>
        <Text
          style={[
            styles.headerSubtitle,
            {
              color: colors.black,
              textAlign: 'center',
              paddingHorizontal: 24,
              marginTop: 12,
            },
          ]}
        >
          Pro tip : Use this link on all platforms to make it easier for your
          followers to discover you
        </Text>
        <TouchableOpacity
          style={[styles.button, { width: '100%', marginTop: 36 }]}
          onPress={closeModalAndNavigate}
        >
          <Text style={styles.buttonText}>Go to Dashboard</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const ContainerComponent = compose(
    withEither(isVerificationFailed, VerificationFailedContainer),
    withEither(isVerificationSuccessful, VerificationSuccessfulContainer),
  )(VerifyingContainer);

  return (
    <>
      <View style={styles.modalContainer} pointerEvents='box-none'>
        <View style={styles.modal} dataSet={{ media: ids.modal }}>
          <ContainerComponent />
        </View>
      </View>
    </>
  );
}
