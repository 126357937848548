import React, { Component } from 'react';
import { View, Image, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import PropTypes from 'prop-types';
import { withMaybe } from '../../lib/Monads';
// Styles Imports
import { ids, styles } from './Style';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { NOTIFICATION_REQUEST_MODAL_TYPE } from '../../config/Constants';
import ProductRatingAndMoreInfo from '../Product/ProductRatingAndMoreInfo';
import CrossButton from '../progressBarView/CrossButton';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotificationModalDisplayTime } from '../../actions/ActionTypes';
import NotificationModal from '../../utils/Notification/NotificationModal';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import AppConfig from '../../config/AppConfig';
import FastImageView from '../FastImageView';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop } from '../../utils/BooleanUtility';

const headerStyles = StyleSheet.create({
  ratingStars: {
    height: 20,
    width: 120,
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: -3,
  },
});
class ArtistHeader extends Component {
  constructor(props) {
    super(props);
    const { followed } = this.props;
    this.state = {
      isFollowing: followed,
      canDisplayNotificationRequestModal: false,
      showNotificationPrompt: false,
    };
  }

  getScreenForPublisherType = (publisherType) => {
    switch (publisherType) {
      case 'routine':
        return 'PreRoutineDetails';
      case 'product_category':
        return 'MoreItems';
      case 'artist':
        return 'Artist';
      case 'brand':
        return 'Brand';
      case 'tag':
        return 'Tag';
      case 'offer':
        return 'OfferDetail';
      default:
        return 'Artist';
    }
  };

  navigateToInfluencerPage = () => {
    if (AppConfig.getBooleanValue(Config.DISABLE_INFLUENCER_PROFILE)) {
      return;
    }
    const {
      navigation,
      foxyHandle,
      videoMetadata = {},
      artistThumbnail,
      publisherType = '',
      publisherSlug = '',
      publisherId = '',
    } = this.props;
    AnalyticsManager.logEvent(
      EventType.videoEvents.VIDEO_ARTIST_CLICK,
      videoMetadata,
    );
    const placeholderImageUrl = Utility.isBlank(artistThumbnail)
      ? images.artistCardPlaceholder.uri
      : Utility.getMinifiedImage(artistThumbnail, 120, 120);
    const screenName = this.getScreenForPublisherType(publisherType);
    navigateToScreen({
      navigation,
      type: 'navigate',
      screen: screenName,
      params: {
        bioHandle: foxyHandle,
        profilePlaceholderUrl: placeholderImageUrl,
        slug: publisherSlug,
        display: 'screen',
        type: 'list',
        id: publisherId,
      },
    });
  };

  sourceUri = () => {
    const { artistThumbnail } = this.props;
    return Utility.isBlank(artistThumbnail)
      ? images.artistCardPlaceholder
      : { uri: Utility.getMinifiedImage(artistThumbnail, 120, 120) };
  };

  // View Rendering Methods
  ArtistAvatarView = () => {
    const source = this.sourceUri();
    return (
      <View style={styles.avatarImageContainer}>
        <FastImageView style={styles.avatarImage} source={source} />
      </View>
    );
  };

  ContentTitleView = () => {
    const { contentTitle } = this.props;
    return (
      <View style={styles.artistTextView}>
        <Text numberOfLines={1} style={styles.contentTitle}>
          {contentTitle}
        </Text>
      </View>
    );
  };

  ContentArtistName = () => {
    const { artistName } = this.props;
    artistName;
    return (
      <View style={styles.artistTextView}>
        <Text numberOfLines={1} style={styles.contentArtistName}>
          {artistName}
        </Text>
      </View>
    );
  };

  toggleFollow = () => {
    const { onFollowTapped } = this.props;
    const { isFollowing } = this.state;
    onFollowTapped(isFollowing);

    if (!isFollowing) {
      this.displayNotificationRequestModal();
    }

    this.setState((prevState) => ({
      isFollowing: !prevState.isFollowing,
    }));
  };

  displayNotificationRequestModal = () => {
    Utility.canDisplayNotificationRequestModal(
      this.props.lastNotificationModalDisplayTime,
      'Store',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          const currentDate = new Date();
          this.props.setNotificationModalDisplayTime(currentDate.getTime());
        }
      },
    );
  };

  showNotificationModal = (show, showNotification) => {
    this.setState({
      canDisplayNotificationRequestModal: show,
      showNotificationPrompt: showNotification,
    });
  };

  FollowButton = () => {
    const icon = this.state.isFollowing
      ? images.mediaDetailPage.followingButtonWhite
      : images.mediaDetailPage.followButtonWhite;
    const { artistName, artistId } = this.props;
    if (Utility.isBlank(artistName) || Utility.isBlank(artistId)) return null;
    return (
      <View>
        <View style={styles.followButton}>
          <Image style={styles.followButtonIcon} source={icon} />
        </View>
      </View>
    );
  };

  NextButton = () => {
    const { onNextButtonTapped } = this.props;
    return (
      <TouchableOpacity
        onPress={onNextButtonTapped}
        style={styles.followButton}
      >
        <Image source={images.mediaDetailPage.nextVideoButton} />
      </TouchableOpacity>
    );
  };

  recommendationTextView = () => {
    const { contentSubTitle = '' } = this.props;
    if (Utility.isBlank(contentSubTitle)) {
      return null;
    }
    return (
      <View style={styles.recommendationTextView}>
        <Text numberOfLines={1} style={styles.contentDescription}>
          {contentSubTitle}
        </Text>
      </View>
    );
  };

  ArtistContainerView = () => {
    const { productRating = 0, listId } = this.props;
    return (
      <TouchableOpacity
        onPress={this.navigateToInfluencerPage}
        style={styles.containerByRatio}
        dataSet={{ media: ids.containerByRatio }}
      >
        <this.ArtistAvatarView />
        <View style={styles.artistContainerView} dataSet={{ media: ids.artistContainerView }}>
          <this.ContentTitleView />

          <View
            // onPress={this.toggleFollow}
            hitSlop={Utility.getHitSlop()}
            style={styles.artistContainer}
            dataSet={{ media: ids.artistContainer }}
          >
            <View style={styles.artistNameContainer}>
              <this.ContentArtistName />
              {Config.DISABLE_FOLLOW_BUTTON_FROM_ARTIST_HEADER !== 'true' && (
                <>
                  <View style={styles.whitedot} />
                  <this.FollowButton />
                </>
              )}
            </View>
          </View>
          <this.recommendationTextView />

          <View style={headerStyles.ratingStars}>
            <ProductRatingAndMoreInfo
              rating={productRating}
              disableRatingText
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  shouldRenderArtistView = () => {
    const { artistName, contentTitle } = this.props;
    return Utility.isBlank(artistName) && Utility.isBlank(contentTitle);
  };

  conditionalArtistView = withMaybe(this.shouldRenderArtistView)(
    this.ArtistContainerView,
  );

  crossButton = () => {
    const { onCancelTap, showCrossButton } = this.props;
    if (!showCrossButton) {
      return null;
    }
    return (
      <CrossButton
        crossButtonWidth={60}
        onCancelTap={onCancelTap}
        previousScreen={SCREEN_CONSTANTS.CONTENT_PAGE}
      />
    );
  };

  render() {
    const { canDisplayNotificationRequestModal, showNotificationPrompt } =
      this.state;
    return (
      <>
        <View style={styles.container} dataSet={{ media: ids.container }}>
          <this.conditionalArtistView />
          <this.crossButton />
        </View>
        {Utility.isIOS() ? (
          <NotificationModal
            isNotificationModalVisible={canDisplayNotificationRequestModal}
            showNotificationModal={this.showNotificationModal}
            showNotificationPrompt={showNotificationPrompt}
            type={NOTIFICATION_REQUEST_MODAL_TYPE.FOLLOW_ARTIST}
          />
        ) : null}
      </>
    );
  }
}

// Prop Types
ArtistHeader.propTypes = {
  artistName: PropTypes.string.isRequired,
  artistThumbnail: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  videoMetadata: PropTypes.shape({
    [EventParameterKey.VIDEO_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_TITLE]: PropTypes.string,
    [EventParameterKey.ARTIST_NAME]: PropTypes.string,
    [EventParameterKey.ARTIST_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_DURATION]: PropTypes.number,
    [EventParameterKey.VIDEO_TYPE]: PropTypes.string,
  }).isRequired,
  isFollowingArtist: PropTypes.bool,
  onFollowTapped: PropTypes.func.isRequired,
  onCancelTap: PropTypes.func.isRequired,
  showCrossButton: PropTypes.bool,
  destination: PropTypes.string,
};

ArtistHeader.defaultProps = {
  isFollowingArtist: false,
  showCrossButton: true,
  destination: '',
};

const mapStateToProps = (store, ownProps = {}) => {
  return {
    followed: !!(
      store.UserAccountInfo.followedArtists &&
      store.UserAccountInfo.followedArtists[ownProps?.artistId]
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setNotificationModalDisplayTime,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(ArtistHeader),
);
