import React from 'react';
import { View, FlatList } from 'react-native';
import { chunk, debounce, range, shuffle, zip } from 'lodash';
import Utility from '../../../utils/Utility';
import LinkCard from '../../links/LinkCard';
import { getStylesForStaggeredItem, styles } from './styles';
import { AnalyticsUtilty } from '../../../analytics';
import DynamicLinkManager from '../../../utils/DynamicLinkManager';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { isDesktop } from '../../../utils/BooleanUtility';

const ImageWidthDivisor = { 2: 2.15, 3: 3.3, 4: 4.35 };

class StaggeredGrid extends React.PureComponent {
  GridComponents = {
    link: LinkCard,
  };

  constructor(props) {
    super(props);
    const { item = [], listData: { columns = 3 } = {}, content = '' } = props;
    const col = isDesktop() ? columns * 2 : columns;
    this.data = zip(...chunk(shuffle(item), col));
    this.columnWidth = Utility.getScreenWidth() / ImageWidthDivisor[col];
    this.LayoutComponent = this.GridComponents[content];
    this.componentStyles = getStylesForStaggeredItem(this.columnWidth);
    this.debouncedHandleRouteFromLink = debounce(this.handleRouteFromLink, 100);
  }

  listKey = (item) => `${item?.id}`;

  Column = ({ index }) => {
    return (
      <FlatList
        data={this.data[index]}
        renderItem={this.GridItem}
        keyExtractor={this.listKey}
        scrollEnabled={false}
        contentContainerStyle={styles.columnStyle}
        showsVerticalScrollIndicator={false}
      />
    );
  };

  GridItem = ({ item }) => {
    const {
      listData: { columns = 2 } = {},
      listData = {},
      previousScreen = '',
    } = this.props;
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <this.LayoutComponent
          item={item}
          columnWidth={this.columnWidth}
          handleDeeplink={this.handleDeeplink}
          columnCount={isDesktop() ? columns * 2 : columns}
          styles={this.componentStyles}
          fireItemClickEvent={this.fireItemClickEvent}
        />
      </ErrorBoundaryComponent>
    );
  };

  handleRouteFromLink = (route, slug, path, extra) => {
    const { navigation } = this.props;
    navigation.navigate(route, { slug, extra });
  };

  handleDeeplink = (url) => {
    DynamicLinkManager.resolveLink(url, this.debouncedHandleRouteFromLink);
  };

  fireItemClickEvent = (itemData) => {
    const {
      previousScreen,
      listId,
      layout,
      listName,
      listIndex,
      listContent,
      listSlug = '',
      index = 0,
    } = this.props;
    const { id, link_type, name } = itemData;
    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        id,
        link_type,
        name,
        index,
        listId,
        layout,
        listName,
        Utility.isPresent(listIndex) ? listIndex : '-1',
        '',
        '',
        listContent,
        '',
        '',
        listSlug,
      );
    }
  };

  render() {
    const { listData: { columns = 2 } = {}, item = [] } = this.props;
    const col = isDesktop() ? columns * 2 : columns;
    if (item?.length < 2) return null;
    const array = [...range(0, col)];
    return (
      <View style={styles.container}>
        <FlatList
          horizontal
          data={array}
          renderItem={this.Column}
          showHorizontalScrollIndicator={false}
          scrollEnabled={false}
          style={styles.listContainer}
        />
      </View>
    );
  }
}

export default StaggeredGrid;
