import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { LAYOUT, CURRENCY_SYMBOL } from '../../../config/Constants';
import FastImageView from '../../FastImageView';
import colors from '../../../theme/Colors';
import { navigateToScreen } from '../../../utils/NavigationUtility';

export default function ShopifyOrderItem({
  quantity,
  imageUrl,
  name,
  esp,
  productSlug,
}) {
  const navigation = useNavigation();
  const onProductCardPress = () => {
    if (productSlug) {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'Product',
        params: {
          slug: `/api/v2/products/${productSlug}`,
          display: LAYOUT.SCREEN,
        },
      });
    }
  };

  return (
    <TouchableOpacity onPress={onProductCardPress}>
      <View style={styles.orderItemContainer}>
        <View style={styles.imageContainer}>
          <FastImageView
            source={`${imageUrl}&width=70`}
            style={styles.image}
            resizeMode="contain"
          />
        </View>

        <View style={styles.itemDetailsContainer}>
          <Text
            style={styles.productName}
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            {name}
          </Text>
          <Text style={styles.quantity}>{`Qty: ${quantity}`}</Text>
        </View>

        <View style={styles.spContainer}>
          <Text style={styles.sp}>{`${CURRENCY_SYMBOL} ${esp || 0}`}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  orderItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  imageContainer: { flex: 1 },
  image: { height: 60, width: 70 },
  itemDetailsContainer: {
    flexDirection: 'column',
    flex: 4,
    justifyContent: 'space-between',
    marginLeft: 22,
  },
  productName: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  quantity: {
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
  },
  spContainer: { flex: 1 },
  sp: {
    color: colors.silver,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    alignSelf: 'flex-end',
  },
});
