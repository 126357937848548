import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_MEDIA_CATEGORIES } from '../actions/UploadPostActions';
import {
  storeMediaCategories,
  markLoadingMediaCategories,
} from '../actions/LoginActions';
import Utility from '../utils/Utility';
import { URL } from '../config/Constants';

import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

const fetchMediaCategories = (headers) => {
  const response = fetch(URL.MEDIA_CATEGORY, {
    method: 'GET',
    headers: headers,
  });
  return response;
};

function* getMediaCategories() {
  try {
    const headers = yield getApiHeaders();
    const response = yield call(fetchMediaCategories, headers);
    if (response.status === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(storeMediaCategories(json));
      yield put(markLoadingMediaCategories());
    }
  } catch (error) {
    // console.tron.log(`Exception occurred in fetching Media Categories List ${error}`);
  }
}

export default function* watchGetMediaCategoriesListSaga() {
  yield takeEvery(GET_MEDIA_CATEGORIES, getMediaCategories);
}
