import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { ScrollView } from 'react-native-gesture-handler';
import MediaDetailListHeader from './MediaDetailListHeader';
import { List } from '../List';
import { ids, styles } from './styles';
import { THEME, SIZE, LAYOUT, SCREEN } from '../../config/Constants';
import Utility from '../../utils/Utility';
import { EventParameterKey } from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { isDesktop } from '../../utils/BooleanUtility';

class MediaDetailListsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCartVisible: false,
    };
  }

  onListButtonTap = (index) => () => {
    if (index >= 0) {
      this.pagesRef.scrollToPage(index, true);
    }
  };

  onScrollEnd = () => {
    this.headerRef.onScrollEnd(this.pagesRef.progress);
  };

  renderPager = () => null;

  savePagesRef = (ref) => {
    this.pagesRef = ref;
  };

  MediaList = (props) => {
    const { item, navigation, onPress } = props;
    const { videoMetadata, showToast } = this.props;
    if (item.content !== 'product') {
      return null;
    }
    item.size = 'horizontal';
    const listKey = item.content + videoMetadata.video_id;
    return (
      <List
        navigation={navigation}
        itemData={item}
        id={item.id}
        listName={item.name}
        listContent={item.content}
        type={item.type}
        size={item.size}
        layout={LAYOUT.LIST}
        display={LAYOUT.RAIL}
        theme={THEME.DARK}
        toggleCartVisibility={this.toggleCartVisibility}
        ignoreMinCount
        onPress={item.content === 'product' ? null : onPress}
        listKey={listKey}
        previousScreen={SCREEN_CONSTANTS.CONTENT_PAGE}
        showToast={showToast}
      />
    );
  };

  saveHeaderRef = (ref) => {
    this.headerRef = ref;
  };

  renderPager = () => null;

  toggleCartVisibility = () => {
    this.props.navigation.push('Cart');
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  renderPages = () => {
    const { objects, navigation, onPress } = this.props;
    const { isCartVisible } = this.state;
    if (Utility.isBlank(objects)) {
      return null;
    }
    // FIXME: The View inside the scrollview may be entirely unnecessary
    let item = objects[0];
    return (
      <>
        {/* <Pages
          ref={this.savePagesRef}
          style={styles.pagesStyle}
          renderPager={this.renderPager}
          onScrollEnd={this.onScrollEnd}
        > */}

        {/* {objects.map((item) => ( */}
        <ScrollView
          bounces={false}
          horizontal
          style={styles.scrollView}
          dataSet={{ media: ids.scrollView }}
          key={item.id}
          showsHorizontalScrollIndicator={!isDesktop()}
        >
          <View style={styles.scrollViewItem} dataSet={{ media: ids.scrollViewItem }}>
            <this.MediaList
              item={item}
              navigation={navigation}
              onPress={onPress}
            />
          </View>
        </ScrollView>
        {/* ))} */}
        {/* </Pages> */}
        {/* <CartModal
          navigation={navigation}
          isVisible={isCartVisible}
          onCartDismiss={this.onCartDismiss}
          showCart={this.toggleCartVisibility}
        /> */}
      </>
    );
  };

  render() {
    const {
      // onNextButtonTapped,
      // objects,
      videoMetadata,
      // onNextButtonClick,
      navigation,
    } = this.props;
    // const listNames = objects.map((item) => item.name);

    return (
      <View style={styles.listContainerStyle}>
        {/* <MediaDetailListHeader
          ref={this.saveHeaderRef}
          listNames={listNames}
          videoMetadata={videoMetadata}
          onListButtonTap={this.onListButtonTap}
          onNextButtonTapped={onNextButtonTapped}
          onNextButtonClick={onNextButtonClick}
          navigation={navigation}
        /> */}
        <this.renderPages />
      </View>
    );
  }
}

MediaDetailListsContainer.propTypes = {
  objects: PropTypes.array,
  navigation: PropTypes.any,
  videoMetadata: PropTypes.shape({
    [EventParameterKey.VIDEO_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_TITLE]: PropTypes.string,
    [EventParameterKey.ARTIST_NAME]: PropTypes.string,
    [EventParameterKey.ARTIST_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_DURATION]: PropTypes.number,
    [EventParameterKey.VIDEO_TYPE]: PropTypes.string,
  }).isRequired,
  onNextButtonTapped: PropTypes.func.isRequired,
  onPress: PropTypes.func,
};

MediaDetailListsContainer.defaultProps = {
  onPress: null,
};

export default MediaDetailListsContainer;
