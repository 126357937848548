import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../theme/Colors';
import Utility from './Utility';

class GradientDivider extends PureComponent {
  render() {
    const { reverse = false, componentStyle, marginTop, marginBottom } = this.props;
    const gradientArray = reverse
      ? ['rgba(0, 0, 0, 0.0)', 'rgba(0, 0, 0, 0.1)']
      : ['rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.0)'];
    return (
      <LinearGradient
        colors={gradientArray}
        style={[styles.gradientDivider, componentStyle, { marginTop, marginBottom }]}
      />
    );
  }
}

const styles = StyleSheet.create({
  divider: {
    height: 1,
    backgroundColor: colors.border,
  },
  gradientDivider: {
    height: 1,
    width: Utility.getScreenWidth(),
  },
});
export default GradientDivider;
