import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';

import ProductDescription from './ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AddToCart from './AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import ProductColorVariants from '../variants/ProductColorVariants';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import ProductSizeVariants from '../variants/ProductSizeVariants';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import MoreOptions from './MoreOptions';
import ReviewProduct from './ReviewProduct';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { Grayscale } from '../../libraries/ReactNativeColorMatrixImageFilters';
import {
  applyFlashDeal,
  setSearchResultClicks,
} from '../../actions/ActionTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import { navigateToScreen } from '../../utils/NavigationUtility';

class DefaultSizeProductList extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 2000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug, stocked_status, replacement_sku },
      toggleCartVisibility,
      addMyBrowsing,
      applyFlashDeal,
      previousScreen,
      type = '',
      searchQuery = '',
      setSearchResultClicks,
    } = this.props;

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      const data = {
        query: searchQuery,
        type,
        id,
      };
      setSearchResultClicks(data);
    }

    applyFlashDeal({
      type: 'product',
      id,
    });

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        id,
        slug:
          stocked_status === 'discontinued' && Utility.isPresent(replacement_sku)
            ? `/api/v1/products/${replacement_sku}`
            : slug,
        isReplacement:
          stocked_status === 'discontinued' && Utility.isPresent(replacement_sku),
        original_slug: slug,
        name: itemData.name,
        display: LAYOUT.SCREEN,
        itemData,
        toggleCartVisibility,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData,
      listId,
      listData = {},
      layout,
      previousScreen,
      listName,
      listContent,
      toggleUnavailableProductsModal,
      cancelpressed,
      fromModal = false,
      extraEventParameters = {},
    } = this.props;
    if (fromModal) {
      cancelpressed();
    }
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      itemData.id,
      itemData.slug,
      listData?.slug,
      extraEventParameters,
      itemData,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      layout,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
      prompts,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      disableAddToBag,
      listData,
      listData: { additional_data = {} } = {},
      fromModal,
    } = this.props;

    if (fromModal) {
      return null;
    }
    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.GRID}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        singleStockedVariantSku={singleStockedVariantSku}
        prompts={prompts}
        additional_data={additional_data}
        listData={listData}
      />
    );
  };

  productShadesCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Shade';

  productSizeCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Size';

  conditionalVariants = compose(
    withEither(this.productShadesCondition, ProductColorVariants),
    withEither(this.productSizeCondition, ProductSizeVariants),
  )(ProductOtherVariants);

  productVariants = (props) => {
    const {
      itemData: { image_url: imageUrl, variants_details: variantDetails = {} },
    } = this.props;
    const { containerStyle } = props;

    const {
      variants_count: variantsCount = 0,
      variants_shade_images: variantShadeImages = [],
      variants_property_name: variantPropertyName = '',
    } = variantDetails;

    return (
      <this.conditionalVariants
        variantImage={imageUrl}
        variantsCount={variantsCount}
        variantShadesImages={variantShadeImages}
        variantsPropertyName={variantPropertyName}
        containerStyle={containerStyle}
      />
    );
  };

  getCardStyle = (show_borders_for_list_items) => {
    return {
      flexDirection: 'column',
      marginBottom: 4,
      backgroundColor: colors.white,
      borderWidth: show_borders_for_list_items ? 1 : 0,
      borderColor: colors.borderColor,
      marginHorizontal: Utility.spacingBetweenItems,
      borderRadius: 4,
      width: '100%',
      alignItems: 'center',
      alignSelf: 'center',
    };
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      itemData: {
        offer_value,
        final_sp,
        additional_discount,
        type,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        brand: { name: brandName = '' } = {},
        sku_id: skuId,
        prompts,
        has_variants: hasVariants,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
      } = {},
      listData: { options: listOptions = {} } = {},
      renderIngredients,
    } = this.props;

    if (Utility.isBlank(itemData)) {
      return null;
    }

    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const options = Utility.isPresent(listOptions) ? listOptions : {};

    const { show_borders_for_list_items = 0 } = options;
    const cardStyle = this.getCardStyle(show_borders_for_list_items);

    const display_price = final_sp;

    return (
      <View>
        <ScaleAnimate
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
        >
          <Card style={cardStyle}>
            <View style={styles.cardContainer}>
              <View style={styles.imageContainer}>
                <ProductImageAndRating
                  layout={LAYOUT.LIST}
                  rating={rating}
                  images={images}
                  webp_images={webp_images}
                  image_webp_url={image_webp_url}
                  imageUrl={imageUrl}
                  id={id}
                  name={name}
                  previousScreen={previousScreen}
                  itemData={itemData}
                />
              </View>
              {/* <View style={styles.fullCardDivider} /> */}
              <View style={{ flex: 1, marginRight: 60 }}>
                <ProductDescription
                  itemData={itemData}
                  itemData={itemData}
                  name={itemData?.name}
                  mrp={mrp}
                  offer_value={offer_value}
                  sp={sp}
                  display_price={display_price}
                  discount={discount}
                  brandName={brandName}
                  variantBrandName={variantBrandName}
                  layout={LAYOUT.LIST}
                  id={id}
                  previousScreen={previousScreen}
                  itemData={itemData}
                  prompts={prompts}
                  renderProp={() => (
                    <this.productVariants
                      containerStyle={styles.variantContainerStyle}
                    />
                  )}
                  hideBrandName={AppConfig.getBooleanValue(
                    Config.HIDE_BRAND_ON_PRODUCT_CARD,
                  )}
                />
              </View>
              <View style={styles.cartImageContainer}>
                <this.addToCart
                  skuId={skuId}
                  orientation={orientation}
                  toggleCartState={this.toggleCartState}
                  layout={LAYOUT.LIST}
                  addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
                  hideAddToCart={hideAddToCart}
                  id={id}
                  itemData={itemData}
                  hasVariants={hasVariants}
                  showCart={this.showCart}
                  priority={priority}
                  outOfStock={outOfStock}
                  previousScreen={previousScreen}
                  singleStockedVariantSku={singleStockedVariantSku}
                  prompts={prompts}
                />
              </View>
            </View>
            {renderIngredients && renderIngredients()}
          </Card>

          {/* <this.productVariants /> */}
        </ScaleAnimate>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    marginHorizontal: Utility.spacingBetweenItems,
    flexDirection: 'row',
    borderRadius: 4,
    paddingHorizontal: 24,
    height: 122,
    alignSelf: 'center',
    width: '100%',
    marginBottom: 2,
  },
  imageContainer: {
    alignSelf: 'center',
    // paddingLeft: 8,
    height: 116,
    width: 116,
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 12,
    right: 12,
  },
  variantContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 4,
    marginBottom: 4,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      applyFlashDeal,
      setSearchResultClicks,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(DefaultSizeProductList),
);
