import Banner from '../../../containers/banner/Banner';
import Feature from '../../../containers/feature/Feature';
import Media from '../../../containers/media/Media';
import List from '../../../containers/List/List';
import FavouriteList from '../../../containers/List/FavouriteList';
import { ShortBanner } from '../../banner';
import GridScreenPlaceHolder from '../../shared/GridScreenPlaceHolder';
import FilterResultsEmptyState from '../../sort-filter-options/FilterResultsEmptyState';
import EmptyHeader from '../EmptyHeader';
import ListsFilter from '../ListsFilter';
import OfferPair from '../../feature/OfferPair';

const Components = {
  list: List,
  video: Media,
  image: Media,
  feature: Feature,
  offer: Feature,
  short_banner: ShortBanner,
  favouriteList: FavouriteList,
  quick_filters: ListsFilter,
  quick_filters_header: EmptyHeader,
  shimmer: GridScreenPlaceHolder,
  filters_empty_state: FilterResultsEmptyState,
  banner: Banner,
  offer_pair: OfferPair,
};

export const getVerticalComponent = (type) => {
  return Components[type];
};

export const getVerticalListOffsets = (listData, getListComponentHeight) => {
  const offsetAndHeightList = {};
  let offset = 0;
  listData.forEach((item = {}, index) => {
    let height = 0;

    try {
      height = Components[item.type].getComponentHeight(item);
    } catch (error) {
      if (item.type !== 'list') height = 0;
      else {
        height = getListComponentHeight(item);
      }
    }
    offsetAndHeightList[index] = { length: height, offset };
    offset += height;
  });
  return offsetAndHeightList;
};
