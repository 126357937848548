export const GET_ORDERS_HISTORY = 'GET_ORDERS_HISTORY';
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const GET_SHIPMENT_DETAILS = 'GET_SHIPMENT_DETAILS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const GET_SHIPMENT_OFFERS = 'GET_SHIPMENT_OFFERS';
export const CLAIM_SHIPMENT_OFFER = 'CLAIM_SHIPMENT_OFFER';
export const UPDATE_CALLBACK_REQUESTED_AT = 'UPDATE_CALLBACK_REQUESTED_AT';
export const UPDATE_CUSTOMER_STATUS = 'UPDATE_CUSTOMER_STATUS';
export const ADD_DELIVERY_INSTRUCTION = 'ADD_DELIVERY_INSTRUCTION';
export const HELD_ORDER = 'HELD_ORDER';
export const CANCEL_SHIPMENT = 'CANCEL_SHIPMENT';
export const CLAIM_MISSED_DELIGHT_OFFER = 'CLAIM_MISSED_DELIGHT_OFFER';
export const GET_ORDER_OFFERS = 'GET_ORDER_OFFERS';

export function getOrdersHistory(page, callback) {
  return {
    type: GET_ORDERS_HISTORY,
    page,
    callback,
  };
}

export function getShipmentOffers(id, callback) {
  return {
    type: GET_SHIPMENT_OFFERS,
    id,
    callback,
  };
}

export function getOrderOffers(id, callback) {
  return {
    type: GET_ORDER_OFFERS,
    id,
    callback,
  };
}

export function claimMissedDelightOffer(data, callback) {
  return {
    type: CLAIM_MISSED_DELIGHT_OFFER,
    data,
    callback,
  };
}

export function heldOrder(data, callback) {
  return {
    type: HELD_ORDER,
    data,
    callback,
  };
}

export function cancelShipment(data, callback) {
  return {
    type: CANCEL_SHIPMENT,
    data,
    callback,
  };
}

export function getOrderDetails(id, callback) {
  return {
    type: GET_ORDER_DETAILS,
    id,
    callback,
  };
}

export function updateCallbackRequestedAt(id, data, callback) {
  return {
    type: UPDATE_CALLBACK_REQUESTED_AT,
    id,
    data,
    callback,
  };
}

export function updateCustomerStatus(id, data, callback) {
  return {
    type: UPDATE_CUSTOMER_STATUS,
    id,
    data,
    callback,
  };
}

export function addDeliveryInstruction(id, data, callback) {
  return {
    type: ADD_DELIVERY_INSTRUCTION,
    id,
    data,
    callback,
  };
}

export function updateOrder(id, data, callback) {
  return {
    type: UPDATE_ORDER,
    id,
    data,
    callback,
  };
}

export function getShipmentDetails(id, callback) {
  return {
    type: GET_SHIPMENT_DETAILS,
    id,
    callback,
  };
}

export function claimShipmentOffer(data, callback) {
  return {
    type: CLAIM_SHIPMENT_OFFER,
    data,
    callback,
  };
}
