import { StyleSheet, Platform } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import { getRealScreenHeight } from '../../../utils/LayoutUtility';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
    overflow: 'visible',
  },
  sectionListContainer: {
    flex: 1,
    backgroundColor: colors.background,
    overflow: 'visible',
    width: Utility.getScreenWidth() * 0.75,
    ...Platform.select({
      web: { height: getRealScreenHeight() - 120 },
      default: {},
    }),
  },
  pageWithSideBarContainer: {
    width: Utility.getScreenWidth() * 0.75,
    overflow: 'visible',
  },
  contentContainerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
    overflow: 'visible',
    paddingBottom: 16,
  },
  sub_heading: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
  },

  morebuttonstyle: {
    fontSize: 14,
    color: '#fd5c63',
    fontFamily: 'Roboto-Bold',
  },
  searchHeaderContainer: {
    height: 35,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
    marginTop: 12,
  },
  searchHeaderSwitchText: {
    color: colors.silver,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 16,
  },
  searchHeaderSwitch: {
    position: 'absolute',
    right: 8,
    bottom: 10,
    height: 24,
    transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
  },
});

export default styles;
