import DefaultSizeBrandList from './DefaultSizeBrandList';
import DefaultSizeBrandGrid from './DefaultSizeBrandGrid';
import DefaultSizeBrandRail from './DefaultSizeBrandRail';

const SmallBrandGrid = DefaultSizeBrandGrid;
const LargeBrandGrid = DefaultSizeBrandGrid;
const SmallBrandList = DefaultSizeBrandList;
const LargeBrandList = DefaultSizeBrandList;
const SmallBrandRail = DefaultSizeBrandRail;
const LargeBrandRail = DefaultSizeBrandRail;

export {
  SmallBrandGrid,
  LargeBrandGrid,
  SmallBrandList,
  LargeBrandList,
  SmallBrandRail,
  LargeBrandRail,
};
