import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { compose } from 'recompose';
import { videoSchema, imageSchema } from '../../config/Schema';
import { MediaCard, MediaReviewCard } from '../../components/media';
import { withMaybe, withEither } from '../../lib/Monads';
import Utility from '../../utils/Utility';
import * as ActionTypes from '../../actions/ActionTypes';
import { LAYOUT } from '../../config/Constants';
import { UserPostCard } from '../../components/profile';

class UserPost extends Component {
  forNullConditionFn = props => !props.itemData;

  mediaReviewCondition = props => props.layout === LAYOUT.REVIEWGRID;

  MediaCardWithCondition = compose(
    withMaybe(this.forNullConditionFn),
    withEither(this.mediaReviewCondition, MediaReviewCard),
  )(MediaCard);

  render = () => {
    let { itemData, layout, size } = this.props;
    if (Utility.isBlank(itemData)) {
      itemData = this.props.data;
    }
    return <UserPostCard {...this.props} itemData={itemData} layout={layout} size={size} />;
  };
}

const mapStateToProps = (store, ownProps) => {
  const schemaType = {
    video: videoSchema,
    image: imageSchema,
  };
  const itemData = denormalize(
    store.data.entities[`${ownProps.type}s`][ownProps.id],
    schemaType[ownProps.type],
    store.data.entities,
  );
  if (Utility.isBlank(itemData)) {
    return {
      itemData: ownProps.itemData,
    };
  }
  return { itemData };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionTypes, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPost);
