import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Toast from 'react-native-easy-toast';
import PropTypes from 'prop-types';
import {
  ScrollView,
  View,
  ActivityIndicator,
  BackHandler,
  Animated,
  RefreshControl,
  Text,
  Image,
} from 'react-native';
import _, { delay } from 'lodash';
import StaticNavigationHeader from '../../components/shared/StaticNavigationHeader';
import CartAddresses from './CartAddresses';
import CartOffers from './CartOffers';
import CartCheckout from './CartCheckout';
import CartAdditionalItems from './CartAdditionalItems';
import CartEmptyView from './CartEmptyView';
import CartFoxyPromise from './CartFoxyPromise';
import { CartStyles, cartIds } from '../../components/cart/styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import {
  fetchCart,
  setContactLess,
  applyCartCoupon,
  fetchData,
} from '../../actions/ActionTypes';
import { setCurrentPaymentFlow } from '../../actions/PaymentActions';
import CartPricing from '../../components/cart/CartPricing';
import CartItems from '../../components/cart/CartItems';
import {
  AnalyticsManager,
  CartAndPaymentAnalytics,
  EventType,
} from '../../analytics';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import RemoteConfig from '../../utils/RemoteConfig';
import { FOXY_URLS, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { availableApps } from '../../actions/LoginActions';
import BtlPriceTimer from './BtlPriceTimer';
import AddFromWishlist from './AddFromWishlist';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import Config from '../../libraries/ReactNativeConfig';
import { List } from '../List';
import RecommendedList from './RecommendedList';
import AppConfig from '../../config/AppConfig';
import TimeUtility from '../../utils/TimeUtility';
import EdgeExtensionCard from '../FoxyEdge/EdgeExtensionCard';
import {
  isCartInBottomTab,
  isDesktop,
  isNative,
  isWeb,
} from '../../utils/BooleanUtility';
import { jsonParser } from '../../utils/JsonUtility';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { getFirebasePerformanceTrace } from '../../utils/PerformanceUtility';
import AppInstallPrompts from '../../components/shared/AppInstallPrompts';

class Cart extends Component {
  static navigationOptions = ({ navigation }) => {
    if (!AppConfig.getBooleanValue(Config.DISABLE_CART_STATIC_HEADER)) {
      return null;
    }
    let { params = {} } = navigation.state;
    let titleStyle = Utility.isAndroid()
      ? {
          textAlign: 'center',
          alignSelf: 'center',
          flex: 1, // must to centrally align title on android, react navigation bug
        }
      : {};
    params = {
      title: 'Cart',
      headerTitleStyle: titleStyle,
    };

    return params;
  };

  constructor(props) {
    super(props);
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.CATEGORY);
    this.trace.start();
    this.state = {
      isLoaderVisible: false,
      isModalVisible: false,
      refreshing: false,
      errorMessage: '',
      scrollY: new Animated.Value(0),
      errorMessage: '',
      successfullyAppliedCoupon: {},
      recommendedCartItems: {},
      showRecommendedCartItemsShimmer: true,
    };
    this.stickyIndex = [0];
    this.modalMessage = null;
    const { route } = props;
    this.source = route.params?.source;
    this.allowWithoutAcceptingContactless = null;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }

    this.cartBuilder = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.cart_builder),
    );

    this.edgeExtensionCardData =
      jsonParser(
        RemoteConfig.getValue(
          REMOTE_CONFIG_KEYS.edge_extension_card_position_in_cart,
        ),
      ) || {};

    const { selected_position = '', show_edge_extension_card = false } =
      this.edgeExtensionCardData;
    this.edgeExtensionCardPosition = selected_position;
    this.showEdgeExtensionCard = show_edge_extension_card;

    this.debounceOnPressUnlockFreeDelivery = _.debounce(
      this.onPressUnlockFreeDelivery,
      1000,
      {
        leading: true,
        trailing: false,
      },
    );
    const routes = props.navigation.getState()?.routes;
    this.prevRoute = routes[routes.length - 2];
  }

  componentDidMount() {
    const { inStoreMode, navigation } = this.props;
    if (Utility.isPresent(inStoreMode)) {
      navigation.replace('RetailCart');
      return;
    }
    this.unsubscribeFocus = navigation.addListener(
      'focus',
      AppConfig.getBooleanValue(Config.GET_CART_ITEMS_ON_FOCUS)
        ? this.onRefresh
        : () => {},
    );
    // this.unsubscribeBlur = navigation.addListener('blur', this.onDidBlur);
    this.modalMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.switch_contact_less_modal_message,
    );

    this.allowWithoutAcceptingContactless = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.allow_without_contactless,
    );
    const { setCurrentPaymentFlow } = this.props;
    setCurrentPaymentFlow(true);
    this.getCart();
    Utility.furtherAction = {
      action: null,
      params: [],
      phone_number: '',
      isActionAsync: false,
    };
    this.trace.stop();
  }

  componentWillUnmount() {
    if (Utility.isPresent(this.unsubscribeFocus)) {
      this.unsubscribeFocus();
    }
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  componentDidUpdate(prevProps) {
    const { cartItems } = this.props;
    if (prevProps.cartItems.length !== cartItems.length) {
      this.scrollToTop();
    }
    let currentCartQuantity = 0;
    _.forEach(cartItems, (cartItem) => {
      if (cartItem.is_free === false) {
        currentCartQuantity += cartItem.quantity;
      }
    });
    if (this.cartBuilder.min_cart_items >= currentCartQuantity) {
      const prevCartItems = prevProps.cartItems;
      let prevCartQuantity = 0;
      _.forEach(prevCartItems, (cartItem) => {
        if (cartItem.is_free === false) {
          prevCartQuantity += cartItem.quantity;
        }
      });
      if (prevCartQuantity !== currentCartQuantity) {
        this.fetchRecommendedList();
      }
    }
  }

  fetchRecommendedList = () => {
    const { fetchData } = this.props;
    const data = {
      method: 'GET',
      slug: this.cartBuilder.recommendedListSlug,
    };

    this.setState({
      showRecommendedCartItemsShimmer: true,
    });
    fetchData(data, (success, response = {}) => {
      if (!success || Utility.isBlank(response)) {
        this.setState({
          recommendedCartItems: {},
          showRecommendedCartItemsShimmer: false,
        });
        return;
      }
      this.setState({
        recommendedCartItems: response,
        showRecommendedCartItemsShimmer: false,
      });
    });
  };

  setSuccessfullyAppliedCoupon = (data) => {
    this.setState({
      successfullyAppliedCoupon: data,
    });
  };

  removeSuccessfullyAppliedCoupon = () => {
    this.setState({
      successfullyAppliedCoupon: {},
    });
  };

  applyFreeDeliveryCoupon = (code) => {
    const { applyCartCoupon } = this.props;
    this.renderLoader(true);
    applyCartCoupon(
      code,
      (success, callbackData) => {
        this.renderLoader(false);
        if (Utility.isBlank(callbackData)) return;
        if (success) {
          const appliedCoupon = callbackData?.prompts?.find(
            (coupon) => coupon.coupon_code === code,
          );

          this.setSuccessfullyAppliedCoupon(appliedCoupon);
          AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
            modal_name: 'success_offer',
            offer_id: appliedCoupon.offer_id,
          });
        } else {
          this.setState({
            errorMessage: `${callbackData?.errors[0] || ''} `,
          });
        }

        // this.refreshOfferStrip();
      },
      false,
      false,
    );
  };

  modalVisibility = (isVisible) => {
    this.setState({
      isModalVisible: isVisible,
    });
  };

  getCart = () => {
    const { fetchCart } = this.props;

    fetchCart((success, response) => {
      this.setState({
        refreshing: false,
        showRecommendedCartItemsShimmer: false,
      });
      if (!success) {
        this.showToast('Something went wrong', 500);
      } else {
        this.setState({
          recommendedCartItems: response?.recommended_cart_items_list ?? {},
        });
        const {
          cartItems = [],
          cartPricing = {},
          address = [],
          authToken,
          cartPrompts,
        } = this.props;
        let totalSkuQuantity = 0;
        let freeSkuQuantity = 0;
        _.forEach(cartItems, (cartItem) => {
          if (cartItem.is_free === true) {
            freeSkuQuantity += cartItem.quantity;
          }
          totalSkuQuantity += cartItem.quantity;
        });
        const stockStatus = this.getOrderCampaignStockStatus(cartItems);
        const meta = {
          cartItems,
          cartPricing,
          address,
          authToken,
          source: this.source,
          totalSkuQuantity,
          freeSkuQuantity,
          stockStatus,
        };
        CartAndPaymentAnalytics.fireCartViewEvent(meta);
        CartAndPaymentAnalytics.firePageLoadEvent(SCREEN_CONSTANTS.CART);
        CartAndPaymentAnalytics.fireCartPromptVisible(cartPrompts);
      }
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    if (Utility.isBlank(this.prevRoute)) {
      navigation.navigate('Feed');
    } else {
      navigation.goBack();
    }
    return true;
  };

  closeErrorModal = () => {
    this.setState({
      errorMessage: '',
    });
  };

  showToast = (message, duration = 1000) => {
    // this.toast.show(message, duration);
    this.setState({
      errorMessage: message,
    });
  };

  renderLoader = (isLoaderVisible) => {
    this.setState({
      isLoaderVisible,
    });
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  closeModal = () => {
    const { setContactLess } = this.props;
    setContactLess({ isContactLess: true });
    this.setState({
      isModalVisible: false,
    });
  };

  onRefresh = () => {
    this.setState({
      refreshing: true,
    });
    this.getCart();
  };

  checkIfFlashDealExist = (item) => {
    const { todayDeals: { skus = [] } = {} } = this.props;
    return (
      (skus?.[`${item?.sku_id}`] &&
        item.participating_discount_offer_type === 'flash_deal') ||
      item.participating_discount_offer_type === 'club_deal'
    );
  };

  scrollToTop = () => {
    const { scrollY } = this.state;
    if (scrollY !== 0) {
      this.scrollRef?.scrollTo({ x: 0, y: 0, animating: true });
    }
  };

  onPressUnlockFreeDelivery = () => {
    const { deliveryFeeDiscountCouponCode } = this.props;
    if (Utility.isBlank(deliveryFeeDiscountCouponCode)) {
      return;
    }
    this.applyFreeDeliveryCoupon(deliveryFeeDiscountCouponCode);
  };

  getOrderCampaignStockStatus = (cartItems) => {
    const campaigns = cartItems.map((item) => {
      const {
        product: { order_campaign: { order_campaings_type = '' } = {} } = {},
        variant: {
          order_campaign: {
            order_campaings_type: order_campaign_type_variant = '',
          } = {},
        } = {},
      } = item;
      return Utility.isPresent(order_campaings_type)
        ? order_campaings_type
        : order_campaign_type_variant;
    });
    const isPreOrderCampaignPresent = campaigns.includes('preorder');
    const isBackOrderCampaignPresent = campaigns.includes('backorder');
    if (isPreOrderCampaignPresent && isBackOrderCampaignPresent) {
      return 'preorder_backorder';
    }
    if (isPreOrderCampaignPresent) {
      return 'preorder';
    }
    if (isBackOrderCampaignPresent) {
      return 'backorder';
    }
    return '';
  };

  getLastCampaignOrderDate = (cartItems) => {
    const campaigns = cartItems.map((item) => {
      const {
        product: { order_campaign: orderCampaignProduct = ({} = {}) } = {},
        variant: { order_campaign: orderCampaignVariant = ({} = {}) } = {},
      } = item;
      return Utility.isPresent(orderCampaignProduct?.expected_instock_date)
        ? orderCampaignProduct
        : orderCampaignVariant;
    });
    const filteredCampaigns = campaigns.filter((campaign) =>
      Utility.isPresent(campaign),
    );
    if (Utility.isBlank(filteredCampaigns) || filteredCampaigns.length === 0) {
      return '';
    }
    let lastCampaignDate = filteredCampaigns[0].expected_instock_date;
    filteredCampaigns.forEach((campaign) => {
      if (Date(lastCampaignDate) < Date(campaign.expected_instock_date)) {
        lastCampaignDate = campaign.expected_instock_date;
      }
    });
    return lastCampaignDate;
  };

  getShipmentText = (cartItems) => {
    if (Utility.isBlank(cartItems)) return '';
    const campaigns = cartItems.map((item) => {
      const {
        product: { order_campaign: orderCampaignProduct = ({} = {}) } = {},
        variant: { order_campaign: orderCampaignVariant = ({} = {}) } = {},
      } = item;
      return Utility.isPresent(orderCampaignProduct?.expected_instock_date)
        ? orderCampaignProduct
        : orderCampaignVariant;
    });
    const filteredCampaigns =
      campaigns.filter((campaign) => Utility.isPresent(campaign)) ?? [];
    let isOtherShipmentPresent = false;
    filteredCampaigns.forEach((campaign) => {
      if (!isOtherShipmentPresent) {
        isOtherShipmentPresent = campaign.other_shipment ?? false;
      }
    });
    if (Utility.isBlank(filteredCampaigns)) return '';
    if (!isOtherShipmentPresent && cartItems.length > 1) {
      return `All items expected to dispatch by ${this.getLastCampaignOrderDate(
        cartItems,
      )}`;
    }
    if (cartItems.length - filteredCampaigns.length === 1) {
      return '1 item expected to dispatch within 24-48hrs';
    }
    if (
      filteredCampaigns.length === cartItems.length &&
      cartItems.length === 1
    ) {
      return `Expected dispatch by ${filteredCampaigns[0].expected_instock_date}`;
    }
    if (cartItems.length > 2 && filteredCampaigns.length === 1) {
      return 'All items expected to dispatch within 24-48hrs except 1 item';
    }
    if (cartItems.length > 3 && filteredCampaigns.length > 1) {
      return `All items expected to dispatch within 24-48hrs except ${filteredCampaigns.length} items`;
    }

    return '';
  };

  getComboItems = () => {
    const {
      cartItems,
      cartPrompts,
      cartPricing: { coupon_codes: cartPromptsFromCouponCodes = [] },
      coupon_codes,
    } = this.props;

    let cartItemsToRender = [];

    cartPromptsFromCouponCodes.forEach((cartPrompt) => {
      if (Utility.isPresent(cartPrompt.combo_offer_details)) {
        let currentComboQuantity = 0;
        let comboCartItems = [];
        let totalFinalMrp = 0;
        let totalFinalSp = 0;
        let totalDiscount = 0;
        let coupon_discount = {};

        let url = '';
        cartPrompts.forEach((e) => {
          if (cartPrompt.offer_id === e.offer_id) {
            url = e.url;
          }
        });

        cartItems.forEach((cartItem) => {
          if (
            cartItem.is_participating_offer_combo === true &&
            (cartItem.participating_discount_offer_id || cartItem.offer_id) ===
              cartPrompt.offer_id
          ) {
            const { combo_offer_details: { combo_color = '' } = {} } =
              cartPrompt;
            comboCartItems.push({
              ...cartItem,
              bgColor: combo_color.concat('20'),
            });

            coupon_discount = _.find(coupon_codes, (e) => {
              return (
                e.offer_id ===
                (cartItem.participating_discount_offer_id || cartItem.offer_id)
              );
            });
            if (Utility.isBlank(coupon_discount)) {
              coupon_discount = {};
            }

            const {
              combo_offer_details: { mrp_sum, sp_sum, percentage } = {},
            } = coupon_discount;

            totalFinalMrp = mrp_sum;
            totalDiscount = percentage;
            totalFinalSp = sp_sum;

            currentComboQuantity += cartItem.quantity;
          }
        });

        if (comboCartItems.length > 0) {
          //Push header details
          if (Utility.isBlank(Utility.usedComboColors[cartPrompt.offer_id])) {
            Utility.usedComboColors[cartPrompt.offer_id] =
              Utility.comboColors.length > 0
                ? Utility.comboColors.shift()
                : colors.white;
          }
          cartItemsToRender.push({
            ...cartPrompt,
            currentComboQuantity: currentComboQuantity,
            totalFinalMrp,
            totalFinalSp,
            totalDiscount,
            url,
          });
          //push cart items
          cartItemsToRender.push(...comboCartItems);
        }
      }
    });

    let remainingCartItems = [];
    remainingCartItems = cartItems.filter((cartItem) => {
      return !!cartItem.is_participating_offer_combo === false;
    });

    remainingCartItems.sort((first, second) => {
      const {
        product: { brand: { id: firstBrandId = '' } = {} } = {},
        sku_id: firstSkuId = '',
      } = first;
      const {
        product: { brand: { id: secondBrandId = '' } = {} } = {},
        sku_id: secondSkuId = '',
      } = second;
      if (firstBrandId !== secondBrandId) {
        return firstBrandId - secondBrandId;
      } else {
        return firstSkuId - secondSkuId;
      }
    });
    return cartItemsToRender.concat(remainingCartItems);
  };

  getUpdatedCartItems = () => {
    const {
      cartItems = [],
      cartPrompts,
      cartPricing: { coupon_codes: cartPromptsFromCouponCodes = [] },
      coupon_codes,
    } = this.props;

    const cart = [...cartItems];
    const comboOffers = {};
    const updatedCart = []; // Initialize a new array to hold updated cart items
    const participatingDiscountOfferIds = [];

    _.forEach(cart, (cartItem) => {
      if (cartItem.is_participating_offer_combo) {
        // Check if combo offer already added
        if (!comboOffers[cartItem.participating_discount_offer_id]) {
          const comboOfferDetails = _.find(
            coupon_codes,
            (couponCode) =>
              couponCode.offer_id === cartItem.participating_discount_offer_id,
          ).combo_offer_details;
          const comboOfferCartPrompt = _.find(
            cartPrompts,
            (cp) => cp.offer_id === cartItem.participating_discount_offer_id,
          );

          comboOffers[cartItem.participating_discount_offer_id] = {
            ...comboOfferCartPrompt,
            participating_discount_offer_id:
              cartItem.participating_discount_offer_id,
            combo_offer_details: comboOfferDetails,
          };
        }
      }
    });

    cart.forEach((cartItem) => {
      if (
        comboOffers[cartItem.participating_discount_offer_id] &&
        !participatingDiscountOfferIds.includes(
          cartItem.participating_discount_offer_id,
        )
      ) {
        updatedCart.push(comboOffers[cartItem.participating_discount_offer_id]); // Add combo offer once after its respective cart item
        participatingDiscountOfferIds.push(
          cartItem.participating_discount_offer_id,
        );
        if (
          isBlank(
            Utility.usedComboColors[cartItem.participating_discount_offer_id],
          )
        ) {
          Utility.usedComboColors[cartItem.participating_discount_offer_id] =
            Utility.comboColors.length > 0
              ? Utility.comboColors.shift()
              : colors.white;
        }
      }

      updatedCart.push(cartItem); // Add original cart items to the updatedCart array
    });

    return updatedCart;
  };

  render() {
    const {
      cartItems,
      cartPricing = {},
      cartPricing: { items_total: itemsTotal, discount = 0 } = {},
      cartPrompts,
      navigation,
      optForPlasticFreePackaging,
      isContactLess,
      address,
      isOfferShimmerVisible,
      remoteConfigData,
      todayDeals,
      deliveryFeeDiscountCouponCode,
      todayDeals: { skus = [], membership_cohort } = {},
    } = this.props;

    const {
      isLoaderVisible,
      isModalVisible,
      refreshing,
      errorMessage,
      successfullyAppliedCoupon,
      recommendedCartItems,
      recommendedCartItems: { objects: recommendedCartItemObjects = {} },
      showRecommendedCartItemsShimmer,
    } = this.state;
    if (cartItems.length === 0) {
      return (
        <>
          {!isCartInBottomTab() && <StaticNavigationHeader title='Bag' />}
          <CartEmptyView />
          <AppInstallPrompts link={FOXY_URLS.appInstallPage.cart} />
        </>
      );
    }

    const { digital_cart, delivery_fee: deliveryFee = '' } = cartPricing;

    let totalPayableQuantity = 0;
    _.forEach(cartItems, (cartItem) => {
      if (cartItem.is_free === false) {
        totalPayableQuantity += cartItem.quantity;
      }
    });

    const isFreeDelivery =
      deliveryFee === '0.0' || deliveryFee === 0.0 || deliveryFee === 0;
    const minRequiredCartItemsCondnFulfilled =
      this.cartBuilder.min_cart_items < totalPayableQuantity;

    const showUnlockFreeDeliveryCoupon =
      !isFreeDelivery &&
      !minRequiredCartItemsCondnFulfilled &&
      Utility.isPresent(deliveryFeeDiscountCouponCode) &&
      this.cartBuilder.show_unlock_free_delivery_coupon;

    const showRecommendedList =
      this.cartBuilder.min_cart_value > itemsTotal - discount &&
      this.cartBuilder.show_recommended_products &&
      !minRequiredCartItemsCondnFulfilled &&
      Utility.isPresent(recommendedCartItems) &&
      Utility.isPresent(recommendedCartItemObjects);

    const showRecommendedListShimmer =
      this.cartBuilder.show_recommended_products &&
      this.cartBuilder.min_cart_value > itemsTotal - discount &&
      showRecommendedCartItemsShimmer;

    const cartItemsToRender = isWeb()
      ? this.getComboItems()
      : this.getUpdatedCartItems();
    const hideCartAdditionalItems = AppConfig.getBooleanValue(
      Config.HIDE_CART_ADDITIONAL_ITEMS,
    );

    const canShowExtenstionCard =
      this.showEdgeExtensionCard &&
      (membership_cohort === 'expired' || membership_cohort === 'non_member');

    if (isDesktop()) {
      return (
        <>
          {!isCartInBottomTab() && (
            <StaticNavigationHeader
              title='Bag'
              skipDivider={!!_.find(cartItems, this.checkIfFlashDealExist)}
            />
          )}
          <BtlPriceTimer cartItems={cartItems} todayDeals={todayDeals} />
          <ScrollView
            style={CartStyles.scrollView}
            dataSet={{ media: cartIds.scrollView }}
            showsVerticalScrollIndicator={false}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={this.onRefresh}
              />
            }
            onScroll={Animated.event([
              { nativeEvent: { contentOffset: { y: this.state.scrollY } } },
            ])}
            ref={(ref) => {
              this.scrollRef = ref;
            }}
            stickyHeaderIndices={this.stickyIndex}
          >
            <>
              <View style={CartStyles.desktopEmptySpace} />
              <View style={CartStyles.cartContainer}>
                <View style={CartStyles.cartLeftContainer}>
                  <CartItems
                    cartItems={cartItemsToRender}
                    navigation={navigation}
                    showToast={this.showToast}
                  />
                  <CartOffers
                    showToast={this.showToast}
                    updatePricing={this.updatePricing}
                    cartPrompts={cartPrompts}
                    todayDeals={todayDeals}
                  />
                </View>
                <View style={CartStyles.cartRightContainer}>
                  {!digital_cart && (
                    <CartAddresses
                      address={address}
                      showToast={this.showToast}
                      onRefresh={this.onRefresh}
                      getShipmentText={this.getShipmentText(cartItems)}
                    />
                  )}
                  {!digital_cart && !hideCartAdditionalItems && (
                    <CartAdditionalItems showToast={this.showToast} />
                  )}
                  <CartPricing
                    pricingData={cartPricing}
                    showToast={this.showToast}
                    optForPlasticFreePackaging={optForPlasticFreePackaging}
                    isOfferShimmerVisible={isOfferShimmerVisible}
                    remoteConfigData={remoteConfigData}
                    showDeliveryFeeDiscountButton={showUnlockFreeDeliveryCoupon}
                    onPressUnlockFreeDelivery={
                      this.debounceOnPressUnlockFreeDelivery
                    }
                  />
                  <CartCheckout
                    showToast={this.showToast}
                    renderLoader={this.renderLoader}
                    pricingData={cartPricing}
                    cartItems={cartItems}
                    optForPlasticFreePackaging={optForPlasticFreePackaging}
                    allowWithOutContactLessDelivery={
                      this.allowWithoutAcceptingContactless
                    }
                    modalVisibility={this.modalVisibility}
                    isContactLess={isContactLess}
                    isOfferShimmerVisible={isOfferShimmerVisible}
                    onRefresh={this.onRefresh}
                    digital_cart={digital_cart}
                    source={this.source}
                  />
                  <CartFoxyPromise showToast={this.showToast} />
                </View>
              </View>
            </>
          </ScrollView>

          {isLoaderVisible && (
            <>
              <View style={CartStyles.cartLoadingOverlay}>
                <ActivityIndicator animating color='red' size='large' />
              </View>
            </>
          )}
          <Toast style={CartStyles.toast} ref={this.toastRef} />
          <FoxyAlert
            isVisible={isModalVisible}
            onTapOutside={this.closeModal}
            alertBoxTitle='Important Info'
            alertMessage={this.modalMessage}
            firstButtonTitle='Continue'
            hideSecondButton
            firstButtonOnPress={this.closeModal}
            height={240}
            numOfLines={7}
          />
          <FoxyAlert
            isVisible={Utility.isPresent(errorMessage)}
            hideSecondButton
            alertBoxTitle='Oops'
            alertMessage={errorMessage}
            firstButtonTitle='Okay'
            firstButtonOnPress={this.closeErrorModal}
            onTapOutside={this.closeErrorModal}
            height={180}
            autoWrapContent
            firstButtonTextColor={colors.cta.lightBlue}
            image_url={images.alert_message.uri}
            showImage
            isAbsolute
          />
          <FoxyAlert
            isVisible={Utility.isPresent(successfullyAppliedCoupon)}
            hideSecondButton
            alertBoxTitle={successfullyAppliedCoupon?.coupon_code}
            alertMessage={successfullyAppliedCoupon?.message}
            firstButtonTitle={
              Utility.isPresent(successfullyAppliedCoupon?.cta_text)
                ? successfullyAppliedCoupon?.cta_text
                : 'YAY !'
            }
            firstButtonOnPress={this.removeSuccessfullyAppliedCoupon}
            onTapOutside={this.removeSuccessfullyAppliedCoupon}
            autoWrapContent
            firstButtonTextColor={colors.cta.lightBlue}
            image_url={images.alert_message.uri}
            showJson
          />
          <AppInstallPrompts link={FOXY_URLS.appInstallPage.cart} />
        </>
      );
    }

    return (
      <>
        {!isCartInBottomTab() && (
          <StaticNavigationHeader
            title='Bag'
            skipDivider={!!_.find(cartItems, this.checkIfFlashDealExist)}
          />
        )}
        <ScrollView
          style={CartStyles.scrollView}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={this.onRefresh}
            />
          }
          onScroll={Animated.event([
            { nativeEvent: { contentOffset: { y: this.state.scrollY } } },
          ])}
          ref={(ref) => {
            this.scrollRef = ref;
          }}
          stickyHeaderIndices={this.stickyIndex}
        >
          <BtlPriceTimer cartItems={cartItems} todayDeals={todayDeals} />
          <CartItems
            cartItems={cartItemsToRender}
            navigation={navigation}
            showToast={this.showToast}
          />

          <RecommendedList
            itemsTotal={itemsTotal}
            discount={discount}
            isFreeDelivery={isFreeDelivery}
            recommendedCartItems={recommendedCartItems}
            navigation={navigation}
            showRecommendedList={showRecommendedList}
            showRecommendedListShimmer={showRecommendedListShimmer}
            isShowHeader
          />

          {isNative() && canShowExtenstionCard &&
            this.edgeExtensionCardPosition === 'above_offers' && (
              <EdgeExtensionCard
                todayDeals={todayDeals}
                navigation={navigation}
                position={this.edgeExtensionCardPosition}
              />
            )}
          <CartOffers
            showToast={this.showToast}
            updatePricing={this.updatePricing}
            cartPrompts={cartPrompts}
            todayDeals={todayDeals}
          />

          {isNative() && canShowExtenstionCard &&
            this.edgeExtensionCardPosition === 'above_delivery_info' && (
              <EdgeExtensionCard
                todayDeals={todayDeals}
                navigation={navigation}
                position={this.edgeExtensionCardPosition}
              />
            )}
          {!digital_cart && (
            <CartAddresses
              address={address}
              showToast={this.showToast}
              onRefresh={this.onRefresh}
              getShipmentText={this.getShipmentText(cartItems)}
            />
          )}

          {!digital_cart && !hideCartAdditionalItems && (
            <CartAdditionalItems showToast={this.showToast} />
          )}

          {canShowExtenstionCard &&
            this.edgeExtensionCardPosition === 'above_bill_details' && (
              <EdgeExtensionCard
                todayDeals={todayDeals}
                navigation={navigation}
                position={this.edgeExtensionCardPosition}
              />
            )}
          <CartPricing
            pricingData={cartPricing}
            showToast={this.showToast}
            optForPlasticFreePackaging={optForPlasticFreePackaging}
            isOfferShimmerVisible={isOfferShimmerVisible}
            remoteConfigData={remoteConfigData}
            showDeliveryFeeDiscountButton={showUnlockFreeDeliveryCoupon}
            onPressUnlockFreeDelivery={this.debounceOnPressUnlockFreeDelivery}
          />

          <AddFromWishlist />

          <CartFoxyPromise showToast={this.showToast} />
          <View style={CartStyles.verticalSpacingCart} />
        </ScrollView>
        <CartCheckout
          showToast={this.showToast}
          renderLoader={this.renderLoader}
          pricingData={cartPricing}
          cartItems={cartItems}
          optForPlasticFreePackaging={optForPlasticFreePackaging}
          allowWithOutContactLessDelivery={
            this.allowWithoutAcceptingContactless
          }
          modalVisibility={this.modalVisibility}
          isContactLess={isContactLess}
          isOfferShimmerVisible={isOfferShimmerVisible}
          onRefresh={this.onRefresh}
          digital_cart={digital_cart}
          source={this.source}
        />
        {isLoaderVisible && (
          <>
            <View style={CartStyles.cartLoadingOverlay}>
              <ActivityIndicator animating color='red' size='large' />
            </View>
          </>
        )}
        <Toast style={CartStyles.toast} ref={this.toastRef} />
        <FoxyAlert
          isVisible={isModalVisible}
          onTapOutside={this.closeModal}
          alertBoxTitle='Important Info'
          alertMessage={this.modalMessage}
          firstButtonTitle='Continue'
          hideSecondButton
          firstButtonOnPress={this.closeModal}
          height={240}
          numOfLines={7}
        />
        <FoxyAlert
          isVisible={Utility.isPresent(errorMessage)}
          hideSecondButton
          alertBoxTitle='Oops'
          alertMessage={errorMessage}
          firstButtonTitle='Okay'
          firstButtonOnPress={this.closeErrorModal}
          onTapOutside={this.closeErrorModal}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showImage
          isAbsolute
        />
        <FoxyAlert
          isVisible={Utility.isPresent(successfullyAppliedCoupon)}
          hideSecondButton
          alertBoxTitle={successfullyAppliedCoupon?.coupon_code}
          alertMessage={successfullyAppliedCoupon?.message}
          firstButtonTitle={
            Utility.isPresent(successfullyAppliedCoupon?.cta_text)
              ? successfullyAppliedCoupon?.cta_text
              : 'YAY !'
          }
          firstButtonOnPress={this.removeSuccessfullyAppliedCoupon}
          onTapOutside={this.removeSuccessfullyAppliedCoupon}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showJson
        />
        <AppInstallPrompts link={FOXY_URLS.appInstallPage.cart} />
      </>
    );
  }
}

Cart.propTypes = {
  fetchCart: PropTypes.func,
  cartItems: PropTypes.array,
  cartPricing: PropTypes.object,
  address: PropTypes.array,
  authToken: PropTypes.string,
  cartPrompts: PropTypes.object,
  setContactLess: PropTypes.func,
  optForPlasticFreePackaging: PropTypes.bool,
  isContactLess: PropTypes.bool,
};

Cart.defaultProps = {
  fetchCart: () => {},
  cartItems: [],
  cartPricing: {},
  address: [],
  authToken: '',
  cartPrompts: {},
  setContactLess: () => {},
  optForPlasticFreePackaging: true,
  isContactLess: false,
};

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
  cartPricing: state.bag.cartPricing,
  cartPrompts: state.bag.cartPrompts,
  deliveryFeeDiscountCouponCode: state.bag.deliveryFeeDiscountCouponCode,
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  isContactLess: state.bag.isContactLess,
  isOfferShimmerVisible: state.bag.isOfferShimmerVisible,
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
  todayDeals: state.todayDeals,
  inStoreMode: state.RetailStore,
  date_of_birth: state.UserAccountInfo.profile.date_of_birth,
  coupon_codes: state.bag.cartPricing.coupon_codes,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchCart,
      setContactLess,
      setCurrentPaymentFlow,
      applyCartCoupon,
      fetchData,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Cart),
);

export const CartHeaderOptions = {
  title: 'Cart',
  headerBackVisible: false,
  headerShadowVisible: false,
  headerTitleAlign: 'center',
  headerStyle: Utility.isAndroid()
    ? {
        textAlign: 'center',
        alignSelf: 'center',
        flex: 1, // must to centrally align title on android, react navigation bug
        justifyContent: 'center',
        alignItems: 'center',
      }
    : {},
  style: {
    textAlign: 'center',
    alignSelf: 'center',
    flex: 1,
  },
};

export const CartHideHeaderOptions = {};

export const CartStackNavigationOptions = {
  tabBarLabel: 'Bag',
  tabBarActiveTintColor: Utility.isPresent(Config.CART_TAB_ACTIVE_COLOR)
    ? Config.CART_TAB_ACTIVE_COLOR
    : '#F97700',
  tabBarInactiveTintColor: Config.CART_TAB_INACTIVE_COLOR || '#979BAA',
  tabBarStyle: { borderTopWidth: 0, elevation: 0 },
  animation: 'fade',
  tabBarIcon: ({ focused }) => {
    let image = images.cartEmptyBag;
    if (Utility.isPresent(Config.CART_TAB_ICON_URL)) {
      image = { uri: Config.CART_TAB_ICON_URL };
    }
    if (focused && Utility.isPresent(Config.CART_ACTIVE_TAB_ICON_URL)) {
      image = { uri: Config.CART_ACTIVE_TAB_ICON_URL };
    }
    const activeColor = Utility.isPresent(Config.CART_TAB_ACTIVE_COLOR)
      ? Config.CART_TAB_ACTIVE_COLOR
      : '#F97700';
    const tintColor = focused ? activeColor : Config.CART_TAB_INACTIVE_COLOR;
    const tabImageStyle = getTabImageStyle(tintColor);
    return <Image source={image} style={tabImageStyle} />;
  },
};

const memoizedTabImageStyles = {};

const getTabImageStyle = (tintColor) => {
  if (!memoizedTabImageStyles[tintColor]) {
    memoizedTabImageStyles[tintColor] = [
      CartStyles.cartTabImageStyle,
      { tintColor },
    ];
  }
  return memoizedTabImageStyles[tintColor];
};
