import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';

const productHeaderHeight = 44.0;
const paddingLeft = 10.0;
const buttonWidth = 160;

export const { ids, styles } = StyleSheet.create({
  contentListHeaderContainer: {
    flexDirection: 'row',
    height: productHeaderHeight,
    paddingLeft,
  },
  contentListHeaderButtons: {
    width: buttonWidth,
    height: productHeaderHeight,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonTextStyle: {
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: 'Roboto-Medium',
  },
  showMoreButton: {
    width: 44,
    height: productHeaderHeight,
    right: paddingLeft,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  separatorView: {
    height: 2,
    backgroundColor: colors.contentPageSeparatorColor,
    bottom: 0,
    left: 10,
    right: 10,
    position: 'absolute',
  },
  selectedIndicator: {
    height: 2,
    width: buttonWidth,
    backgroundColor: colors.background,
    bottom: 0,
    left: paddingLeft,
    position: 'absolute',
  },
  pagesStyle: { position: 'absolute' },
  listContainerStyle: { flex: 1 },
  scrollView: {
    flexDirection: 'row',
    height: 84,
    width: '100%',
    '@media (min-width: 768px)': {
      width: getScreenWidth() / 2.5,
    },
  },
  scrollViewItem: {
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    '@media (min-width: 768px)': {
      width: getScreenWidth() / 2.5,
    },
  },
});
