import { memoize } from 'lodash';
import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { styles } from './styles';

const LinearTabCard = (props) => {
  const {
    selectedIndex = '',
    index,
    tabStyle,
    indicatorStyle,
    item,
    onTabPress,
  } = props;
  const showIndicator = selectedIndex === index;
  return (
    <TouchableOpacity
      onPress={memoize(
        () => onTabPress(item, index),
        () => [index],
      )}
      style={tabStyle}
    >
      <Text style={styles.tabTextStyle}>{item.name}</Text>
      {showIndicator && <View style={indicatorStyle} />}
    </TouchableOpacity>
  );
};

export default LinearTabCard;
