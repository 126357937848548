import React from 'react';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import FastImageView from '../FastImageView';

export default function ShopifyOrderHistoryItem({ item = {} }) {
  const navigation = useNavigation();
  const { orderName, status, id, orderItemsImages = [], createdAt } = item;

  const onActionCardClick = () => {
    navigation.push('ShopifyOrderDetails', { id });
  };

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <Text style={styles.orderId}>{`ORDER ${orderName}`}</Text>
      </View>
      <View style={styles.orderItem}>
        <DebouncedTouchableOpacity
          {...this.props}
          style={styles.actionItemContainer}
          onPress={onActionCardClick}
        >
          <View style={styles.actionItemInternalContainer}>
            <Text style={styles.actionItemHeading}>{status}</Text>
          </View>
          <Text style={styles.actionItemSubheading}>
            {`Placed on ${createdAt}`}
          </Text>
          <ScrollView
            style={styles.imagesContainer}
            horizontal
            showsHorizontalScrollIndicator={false}
          >
            {orderItemsImages.map((imageUrl) => (
              <FastImageView
                key={imageUrl}
                source={`${imageUrl}&width=36`}
                style={styles.orderItemImage}
                resizeMode='contain'
              />
            ))}
          </ScrollView>
          <Image source={images.chevronRight} style={styles.chevronRight} />
        </DebouncedTouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  orderItem: {
    backgroundColor: colors.white,
  },
  container: {
    paddingLeft: 12,
    marginTop: 12,
    backgroundColor: colors.white,
  },
  innerContainer: {
    height: 40,
    justifyContent: 'center',
  },
  orderId: {
    color: colors.subtitle,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
  },
  actionItemContainer: {
    paddingTop: 8,
    borderTopWidth: 1,
    borderTopColor: colors.background,
  },
  actionItemInternalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionItemHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  actionItemSubheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.darkBorder,
    marginRight: 52,
  },
  imagesContainer: {
    flexDirection: 'row',
    marginVertical: 8,
    zIndex: 1,
    height: 50,
  },
  orderItemImage: {
    height: 36,
    width: 36,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: colors.ultraLightGray,
    marginRight: 4,
  },
  chevronRight: {
    height: 12,
    width: 7,
    position: 'absolute',
    tintColor: colors.foxyBlack,
    right: 12,
    top: 12,
  },
  storeTagContainer: {
    flexDirection: 'row',
    marginLeft: 8,
    alignItems: 'center',
    backgroundColor: '#FFF6DD',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 16,
  },
});
