import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchLatestVideos } from '../../actions/ActionTypes';
import styles from '../../components/onboarding/ImageMarkers/MarkerStyle';

class UsersAttribute extends Component {
  state = {
    isProcessing: false,
  };
  _text = (props) => {
    return (
      <Text numberOfLines={1} ellipsizeMode='tail' style={buttonStyles.text}>
        {props.text}
      </Text>
    );
  };

  _titleOnly = ({ title }) => (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <this._text text={title} />
    </View>
  );

  _titleWithIcon = ({ icon, title }) => (
    <View style={buttonStyles.textWithIconContainer}>
      <Image
        style={styles.icon}
        source={{ uri: Utility.getMinifiedImage(icon) }}
      />
      <this._text text={title} />
    </View>
  );

  onPressMarker = () => {
    const { itemData, fetchLatestVideos, navigation } = this.props;
    this.setState({
      isProcessing: true,
    });
    fetchLatestVideos(itemData.id, (success, response) => {
      if (success) {
        navigation.push('ContentModal', {
          itemData: response.objects[0],
          listId: response.id,
          index: 0,
          listData: response,
          id: itemData.id,
          fetchLatestVideos,
          homepageAttributes: true,
        });
        this.setState({
          isProcessing: false,
        });
      } else {
        this.setState({
          isProcessing: false,
        });
      }
    });
  };

  activityIndicator = () => (
    <ActivityIndicator
      animating={true}
      color={colors.foxyBlack}
      size='small'
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    />
  );

  render() {
    const { itemData } = this.props;
    const { isProcessing } = this.state;
    const valuesPresent = Utility.isPresent(itemData);
    const iconPresent = !!(valuesPresent && Utility.isPresent(itemData.icon));
    const isTitleOnly = !iconPresent && itemData;
    let buttonContent;
    if (iconPresent) {
      buttonContent = isProcessing ? (
        <this.activityIndicator />
      ) : (
        <this._titleWithIcon title={itemData.name} icon={itemData.icon} />
      );
    } else if (isTitleOnly) {
      buttonContent = isProcessing ? (
        <this.activityIndicator />
      ) : (
        <this._titleOnly title={itemData.name} />
      );
    }
    return (
      <View style={buttonStyles.container}>
        <TouchableOpacity onPress={this.onPressMarker}>
          <View style={styles.mediaButtonsContainer}>{buttonContent}</View>
        </TouchableOpacity>
      </View>
    );
  }
}

const buttonStyles = StyleSheet.create({
  text: {
    fontFamily: 'Roboto-Medium',
    fontSize: 10,
    width: 50,
    color: colors.foxyBlack,
    textAlign: 'center',
  },
  textWithIconContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  container: {
    marginRight: 8,
    marginTop: 5,
    marginBottom: 5,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchLatestVideos }, dispatch),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(UsersAttribute),
);
