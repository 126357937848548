import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import FastImageView from '../FastImageView';

class OverlappingHorizontalImageList extends PureComponent {
  render() {
    const { images, size = 18 } = this.props;
    const imageStyle = [
      styles.image,
      {
        height: size,
        width: size,
        borderRadius: size / 2,
        marginRight: -(size / 3),
      },
    ];
    return (
      <View style={styles.container}>
        {images.map((image) => {
          return (
            <FastImageView
              source={image}
              style={imageStyle}
            />
          );
        })}
      </View>
    );
  }
}

export default OverlappingHorizontalImageList;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  image: {
    height: 18,
    marginRight: -6,
    width: 18,
    borderWidth: 1,
    borderRadius: 9,
    borderColor: colors.white,
    resizeMode: 'cover',
    backgroundColor: colors.white,
  },
});
