import React, { Component } from 'react';
import { debounce } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withNavigation from '../../utils/WithNavigation';
import { getUsersAllAddress } from '../../actions/ActionTypes';
import Addresses from '../../components/cart/CartAddresses';

export class CartAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = { cartItems: [] };
    this.debounceNavigateToAddress = debounce(this.navigateToAddress, 1000, {
      leading: true,
      trailing: false,
    });
  }

  componentDidMount() {
    setTimeout(this.getAddresses, 0);
  }

  navigateToAddress = () => {
    const { navigation, onRefresh = () => {} } = this.props;
    navigation.navigate('Address', {
      onRefresh,
      previousScreen: 'Cart',
    });
  };

  getAddresses = () => {
    const { getUsersAllAddress, showToast } = this.props;
    getUsersAllAddress((success) => {
      if (!success) {
        showToast('Something went wrong');
      }
    });
  };

  render() {
    const { address, getShipmentText = '' } = this.props;
    return (
      <Addresses
        address={address}
        debounceNavigateToAddress={this.debounceNavigateToAddress}
        getShipmentText={getShipmentText}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getUsersAllAddress,
    },
    dispatch,
  ),
});

export default withNavigation(connect(null, mapDispatchToProps)(CartAddresses));
