import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import ProductDetailStyles from '../Product/styles/ProductDetailStyles';
import ProductCardStyles from '../Product/styles/styles';
import { LAYOUT, MAX_LINES } from '../../config/Constants';
import Utility from '../../utils/Utility';
import size from '../../theme/Fonts';
import colors, { colorCode } from '../../theme/Colors';
import images from '../../theme/Images';
import Config from '../../libraries/ReactNativeConfig';
import FastImageView from '../FastImageView';
import AppConfig from '../../config/AppConfig';

function getBackgroundColor(rating) {
  let background;
  if (rating === '?') {
    return colorCode.ratings.grey;
  }
  if (rating > 4) {
    background = colorCode.ratings.five;
  } else if (rating > 3) {
    background = colorCode.ratings.four;
  } else if (rating > 2) {
    background = colorCode.ratings.three;
  } else if (rating > 1) {
    background = colorCode.ratings.two;
  } else {
    background = colorCode.ratings.one;
  }

  return background;
}

const RatingContainer = ({
  rating,
  styles,
  textStyle,
  rightAlignRating = false,
}) => {
  if (Utility.isBlank(rating)) {
    return null;
  }
  let containerStyle = styles;
  let backgroundColor = getBackgroundColor(rating);
  let ratingLabelStyle = ratingStyle.ratingText;

  if (
    !AppConfig.getBooleanValue(Config.SHOW_FOXY_DEFAULT_RATING_VIEW_ON_PRODUCT)
  ) {
    backgroundColor = '#f0f0f0';
    containerStyle = rightAlignRating
      ? ProductCardStyles.ratingOvalLeftAligned
      : ProductCardStyles.ratingOval;
    ratingLabelStyle = ratingStyle.ratingTextOval;
  }

  return (
    <View
      style={[
        ratingStyle.container,
        containerStyle,
        { backgroundColor },
      ]}
    >
      <Text
        numberOfLines={MAX_LINES.single}
        style={[ratingLabelStyle, textStyle]}
        allowFontScaling={false}
      >
        {rating}
      </Text>
      {AppConfig.getBooleanValue(
        Config.SHOW_FOXY_DEFAULT_RATING_VIEW_ON_PRODUCT,
      ) && (
        <Image source={images.ratingStarWhite} style={ratingStyle.ratingStar} />
      )}
      {AppConfig.getBooleanValue(Config.SHOW_YELLOW_RATING_STAR) && (
        <FastImageView
          source={images.rating_star_yellow}
          style={ratingStyle.ratingStar}
          resizeMode='contain'
        />
      )}
    </View>
  );
};

const ratingStyle = StyleSheet.create({
  container: {
    height: 20,
    backgroundColor: '#01b460',
    borderRadius: 4,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 6,
    paddingVertical: 2,
    flexDirection: 'row',
    width: 34,
  },
  ratingText: {
    fontSize: size.h5,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 11,
    marginTop: 2,
    overflow: 'visible',
  },
  ratingTextOval: {
    fontSize: 11,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontStyle: 'normal',
    letterSpacing: 0,
    justifyContent: 'center',
    lineHeight: 11,

    marginTop: 2,
  },

  ratingStar: {
    width: 8,
    height: 8,
    marginLeft: 3,
  },
});

export default RatingContainer;
