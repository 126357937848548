// Dependencies
import React, { Component } from 'react';
import {
  View,
  FlatList,
  Image,
  Text,
  TouchableOpacity,
  ImageBackground,
  Animated,
} from 'react-native';
import CardView from '../../libraries/ReactNativeCardView';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ProductDetailStyles } from './styles';
import Images from '../../theme/Images';
import ScaleAnimate from '../shared/ScaleAnimate';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import OfferTermsModal from './OfferTermsModal';
import { applyCartCoupon } from '../../actions/ActionTypes';
import FoxyAlert from '../camera/shared/FoxyAlert';
import OfferFeatureCard from '../../components/feature/OfferFeatureCard';

class ProductOffer extends Component {
  productOfferConstants = {
    bannerHeight: 96,
    bannerWidth: 280,
    aspectRatio: 0.3,
    maxHorizontalPadding: 24,
  };
  state = {
    offerId: '',
    offerTnc: '',
    offerImage: '',
    offerName: '',
    isOfferModalVisible: false,
    couponCode: '',
    isAlertVisible: false,
    alertMessage: '',
    item: {},
  };

  UNSAFE_componentWillMount() {
    this.animatedValue = new Animated.Value(1);
  }


  applyOffer = () => {
    const {
      offerId,
      offerTnc,
      offerImage,
      offerName,
      couponCode: promoCode,
      item: { coupon_code, offer_type },
    } = this.state;

    if (offer_type === 'offer_discount') {
      this.setState({ isOfferModalVisible: false });
      return;
    }

    const { applyCartCoupon } = this.props;
    this.setState((prevState) => {
      const newId = prevState.offerApplied !== this.offerId ? this.offerId : '';
      return {
        isOfferModalVisible: false,
      };
    });

    applyCartCoupon(coupon_code, (success, callbackData) => {
      if (success) {
        this.props.updateSelectedOffer(
          offerId,
          offerName,
          offerTnc,
          offerImage,
        );
      } else {
        if (
          !Utility.isBlank(callbackData.errors) &&
          callbackData.errors.length > 0
        ) {
          console.tron.log('code error is ', callbackData);
          setTimeout(() => {
            this.setState({
              alertMessage: callbackData.errors[0],
              isAlertVisible: true,
            });
          }, 500);
        }
      }
    });
  };

  showModal = (item) => {
    const { offerApplied, updateSelectedOffer } = this.props;
    const { id, name, tnc, image, couponCode } = item;

    if (offerApplied == id) {
      updateSelectedOffer('', '', '', '');
      return;
    }

    if (item.offer_type && item.offer_type === 'offer_surprise') {
      this.toggleSurpriseModal();
      return;
    }
    this.setState({
      offerId: id,
      offerTnc: tnc,
      offerImage: image,
      offerName: name,
      isOfferModalVisible: true,
      couponCode: couponCode,
      item,
    });
  };

  cancelOffer = () => {
    this.setState({
      offerId: '',
      offerTnc: '',
      offerImage: '',
      offerName: '',
      isOfferModalVisible: '',
      couponCode: '',
    });
  };

  actionButtonInAlertTapped = () => {
    this.setState({
      isAlertVisible: false,
      alertMessage: '',
    });
  };

  offerCard = (item, updateSelectedOffer, offerApplied) => {
    const { offers } = this.props;
    const offer = item;
    const styles = ProductDetailStyles;
    let appliedStyle = styles.offerUnSelectedBackgroundStyle;
    let borderStyle = styles.offerUnSelectedBorderStyle;
    let buttonText = 'APPLY';
    let innerShadowImageSource = '';
    let cardElevation = 2;
    if (offerApplied === item.id) {
      appliedStyle = styles.offerSelectedBackgroundStyle;
      borderStyle = styles.offerSelectedBorderStyle;
      buttonText = 'APPLIED';
      innerShadowImageSource = images.innerShadow;
      cardElevation = 0;
    }
    let height = this.productOfferConstants.bannerHeight;
    let width = this.productOfferConstants.bannerWidth;
    if (offers.length === 1) {
      width =
        Utility.getScreenWidth() -
        this.productOfferConstants.maxHorizontalPadding;
      height = this.productOfferConstants.aspectRatio * width;
    }
    if (offer.type === 'feature') {
      return (
        <OfferFeatureCard
          itemData={offer}
          isCardDesign
          showCart={this.props.showCart}
          showFreeProductScreen={this.props.showFreeProductScreen}
        />
      );
    }
    return (
      <ScaleAnimate {...this.props} onPress={() => this.showModal(item)}>
        <CardView
          cardElevation={cardElevation}
          cardMaxElevation={cardElevation}
          cornerRadius={4}
          style={{
            margin: 5,
            height,
            width,
          }}
        >
          <ImageBackground
            source={{
              uri: Utility.getMinifiedImage(
                offer.image_url,
                Utility.getScreenWidth(),
                Utility.getScreenWidth(),
              ),
            }}
            resizeMode='cover'
            style={[ProductDetailStyles.offerCardStyle, { height, width }]}
          >
            <Image
              source={innerShadowImageSource}
              style={ProductDetailStyles.innerShadow}
            />
          </ImageBackground>
          {/* TODO: uncomment following lines to enable apply button */}
          {/* <TouchableOpacity
            onPress={() =>
              this.showModal(item.id, item.name, item.tnc, item.image_url)
            }
            style={[borderStyle, ProductDetailStyles.offerApplyButtonStyle]}
          >
            <View style={ProductDetailStyles.offerApplyInnerButtonStyle}>
              <View
                style={[
                  ProductDetailStyles.applyButtonCircularIndicatorStyle,
                  appliedStyle,
                ]}
              >
                <Image
                  style={ProductDetailStyles.whiteTickImageStyle}
                  source={Images.whiteTick}
                />
              </View>
              <Text
                allowFontScaling={false}
                style={ProductDetailStyles.applyTextStyle}
              >
                {buttonText}
              </Text>
            </View>
          </TouchableOpacity> */}
        </CardView>
      </ScaleAnimate>
    );
  };

  render() {
    const { offers, updateSelectedOffer, offerApplied, fromPersonalizedPage } =
      this.props;
    const {
      offerTnc,
      offerImage,
      offerName,
      isAlertVisible,
      alertMessage,
      item,
    } = this.state;
    const { isOfferModalVisible } = this.state;
    if (
      Utility.isBlank(offers) ||
      offers.length === 0 ||
      fromPersonalizedPage
    ) {
      return null;
    }
    return (
      <>
        <View style={{ backgroundColor: colors.background }}>
          <FlatList
            data={offers}
            horizontal
            renderItem={({ item }) =>
              this.offerCard(item, updateSelectedOffer, offerApplied)
            }
            keyExtractor={(item) => `${item.id}`}
            showsHorizontalScrollIndicator={false}
            style={ProductDetailStyles.offerCardFlatlistStyle}
            extraData={offerApplied}
          />
        </View>
        <OfferTermsModal
          isOfferModalVisible={isOfferModalVisible}
          applyOffer={this.applyOffer}
          continueOffer={this.continueOffer}
          cancelOffer={this.cancelOffer}
          offerImage={offerImage}
          offerName={offerName}
          offerTnc={offerTnc}
          item={item}
        />
        <FoxyAlert
          isVisible={isAlertVisible}
          hideSecondButton
          alertBoxTitle="Error"
          alertMessage={alertMessage}
          firstButtonTitle="Ok"
          firstButtonOnPress={this.actionButtonInAlertTapped}
          height={150}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ applyCartCoupon }, dispatch),
});

export default connect(null, mapDispatchToProps)(ProductOffer);
