import React, { PureComponent } from 'react';
import {
  Text,
  View,
  StyleSheet,
  ImageBackgroundComponent,
  Button,
  ImageBackground,
  Image,
  SafeAreaView,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import images from '../../theme/Images';
import QRCode from '../../libraries/ReactNativeQrcodeSvg';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FastImageView from '../../components/FastImageView';
import StaticNavigationHeader from '../../components/shared/StaticNavigationHeader';

import OrderSummary from './container/payment/OrderSummary';
import CartAndOffers from './container/cart/CartAndOffers';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { CURRENCY_SYMBOL } from '../../config/Constants';
// import { Button } from 'react-native-share';

class FoxyPass extends PureComponent {
  constructor(props) {
    super(props);
    const {
      publicId,
      route,
      paymentCurrentStatus: { order_id = '' } = {},
    } = this.props;
    this.showIndependentPass = route.params?.showIndependentPass;

    if (this.showIndependentPass === undefined) {
      this.showIndependentPass = true;
    }

    this.userSlug = this.showIndependentPass
      ? `https://foxy.in/orders/${order_id}`
      : `https://foxy.in/users/${publicId}`;
  }

  header = () => {
    if (this.showIndependentPass) return null;
    return (
      <StaticNavigationHeader
        title=""
        bgColor="transparent"
        backButtonColorBg="transparent"
        overrideBackButtonBgColor
        skipDivider
      />
    );
  };

  qrCodeContainer = () => {
    const { name, mobileNumber, discount } = this.props;

    return (
      <View style={styles.scanContainer}>
        <View style={styles.qrContainer}>
          {Utility.isPresent(name) && <Text style={styles.name}>{name}</Text>}
          <QRCode
            value={this.userSlug}
            size={200}
            logo={
              this.props.selfieImageUrl ? this.props.selfieImageUrl : undefined
            }
            logoSize={60}
            logoBorderRadius={40}
            enableLinearGradient={true}
            linearGradient={['#942121', '#0972B2']}
          />
          <Image source={images.foxy_barcode} style={styles.barcode} />
          <Text style={styles.mobileNumber}>{mobileNumber}</Text>
        </View>
      </View>
    );
  };

  render() {
    const { name, mobileNumber, discount } = this.props;

    const foxyPassSubtitle = this.showIndependentPass
      ? 'This QR code is specific to your profile'
      : `Show this QR code to our ${Config.APP_NAME} staff`;

    const RootContainer = !this.showIndependentPass ? View : ImageBackground;
    return (
      <SafeAreaView style={styles.wrapper}>
        <this.header />
        <View style={styles.main}>
          <Text style={styles.title}>Your {Config.APP_NAME} Pass</Text>
          <Text style={styles.subtitle}>{foxyPassSubtitle}</Text>
          <RootContainer
            source={images.retailStore.foxyPassBackground}
            style={styles.backgroundImage}
            resizeMode="cover"
          >
            <this.qrCodeContainer />
          </RootContainer>
          {this.showIndependentPass && parseInt(discount) > 0 && (
            <>
              <Text style={styles.secondaryTitle}>
                Your total savings on this order!
              </Text>
              <View style={styles.orderAmountContainer}>
                <Text
                  style={styles.orderAmount}
                >{`${CURRENCY_SYMBOL}${discount}`}</Text>
                <FastImageView
                  source={images.retailStore.confetti}
                  style={styles.confettiImage}
                />
              </View>
            </>
          )}
        </View>

        {this.showIndependentPass && <CartAndOffers />}
        {!this.showIndependentPass && (
          <FastImageView
            source={images.retailStore.foxyPassBackground.uri}
            style={styles.bottomBanner}
          />
        )}
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => ({
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
  publicId: state.UserAccountInfo.publicId,
  mobileNumber: state.UserAccountInfo.mobileNumber,
  name: state.UserAccountInfo.profile?.name,
  discount: state.bag.cartPricing.discount,
});

export default withNavigation(connect(mapStateToProps, null)(FoxyPass));

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: colors.background,
  },
  title: {
    fontSize: 24,
    color: '#000',
    lineHeight: 28,
    fontWeight: '700',
  },
  subtitle: {
    color: '#818181',
    fontSize: 14,
    lineHeight: 16.5,
  },
  secondaryTitle: {
    fontSize: 18,
    fontWeight: '500',
    lineHeight: 21,
    color: '#000',
  },
  orderAmount: {
    fontWeight: '700',
    fontSize: 32,
    lineHeight: 38,
    color: '#173143',
  },
  main: { marginVertical: 20, alignItems: 'center' },
  container: {},
  backgroundImage: {
    alignSelf: 'stretch',
    alignItems: 'center',
    marginVertical: 23,
  },
  orderAmountContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  confettiImage: {
    width: 38,
    height: 38,
  },

  scanContainer: {
    width: Utility.getScreenWidth(),
    height: 320,
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrContainer: {
    height: 293,
    width: 270,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
  },
  name: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: '#979BAA',
    marginBottom: 12,
  },
  barcode: { width: '100%', height: 10, position: 'absolute', bottom: 0 },
  mobileNumber: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: '#979BAA',
    marginTop: 12,
  },
  bottomBanner: {
    position: 'absolute',
    width: Utility.getScreenWidth(),
    height: 300,
    bottom: 50,
  },
});
