import { memoize } from 'lodash';
import React from 'react';
import { View, Text } from 'react-native';
import { ids, styles } from './styles';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import FastImageView from '../../components/FastImageView';
import { isDesktop } from '../../utils/BooleanUtility';

const CircularTabCard = (props) => {
  const { selectedIndex = '', index, item, onTabPress } = props;
  const isSelectedTab = selectedIndex === index;
  return (
    <View style={styles.contentContainer}>
      <DebouncedTouchableOpacity
        {...props}
        onPress={memoize(
          () => onTabPress(item, index),
          () => [index],
        )}
        delayPressOut={0}
      >
        <FastImageView
          source={item.picture}
          style={isDesktop() ? styles.desktopImage : styles.image}
        />
        {isSelectedTab && (
          <View
            style={styles.selectedCircularTabStyle}
            dataSet={{ media: ids.selectedCircularTabStyle }}
          />
        )}
      </DebouncedTouchableOpacity>
      <Text style={styles.circularTabTextStyle}>{item.name}</Text>
    </View>
  );
};

export default CircularTabCard;
