import React, { PureComponent } from 'react';
import { View, Text, Linking } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ids, ArtistCardStyles } from './styles';
import Card from '../../lib/Card';
import { ARTIST } from '../../config/LayoutConstants/ArtistConfig';
import colors from '../../theme/Colors';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  MAX_LINES,
  PERMISSION_STORE,
  PERMISSIONS,
} from '../../config/Constants';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import ScaleAnimate from '../shared/ScaleAnimate';
// import {
//   viewPublisherStory,
//   unfollowStories,
//   getArtistStoryRecommendation,
//   renderOnboarding,
// } from '../../actions/ActionTypes';
// import { phoneNumberModalTitle } from '../../actions/LoginActions';
// import { hideStoriesIntro } from '../../actions/StoriesAction';
import {
  AnalyticsEvent,
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { debounce } from 'lodash';
import LinearGradient from 'react-native-linear-gradient';
import images from '../../theme/Images';
import FastImageView from '../FastImageView';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { isDesktop } from '../../utils/BooleanUtility';

class PublisherStoryCard extends PureComponent {
  constructor(props) {
    super(props);
    this.currentPermissionState = '';
    this.debouncedNavigateToMedia = debounce(this.navigateToMedia, 2000, {
      leading: true,
      trailing: false,
    });
    const {
      cardStyle,
      imageStyle,
      imagewidth,
      imageContainerStyle,
      imageBlankContainerStyle,
    } = this.getStyles(this.props);

    this.cardStyle = cardStyle;
    this.imageStyle = imageStyle;
    this.imagewidth = imagewidth;
    this.imageContainerStyle = imageContainerStyle;
    this.imageBlankContainerStyle = imageBlankContainerStyle;
  }

  getStyles = ({ layout = 'rail', size = 'defaultSize' }) => {
    // const { imagewidth } = ARTIST?.CARD[layout][size];
    const imagewidth = isDesktop() ? 86 * 2 : 86;
    const cardDimensions = {
      // height: viewheight,
      width: imagewidth + 4,
      imagewidth,
    };
    const cardStyle = [cardDimensions, ArtistCardStyles.updateCardStyle];
    const imageStyle = {
      width: imagewidth,
      height: imagewidth,
      borderRadius: imagewidth / 2,
      borderWidth: 2,
      borderColor: colors.white,
    };

    const imageContainerStyle = {
      width: imagewidth + 4,
      height: imagewidth + 4,
      borderRadius: (imagewidth + 4) / 2,
      justifyContent: 'center',
      alignItems: 'center',
    };
    const imageBlankContainerStyle = {
      width: imagewidth,
      height: imagewidth,
      borderRadius: imagewidth / 2,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.background,
    };

    return {
      cardStyle,
      imageStyle,
      imagewidth,
      imageContainerStyle,
      imageBlankContainerStyle,
    };
  };

  navigateToSelfieScreen = () => {
    const { navigation } = this.props;
    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          navigation.navigate('TakeSelfie', {
            previousScreen: 'stories',
          });
          this.currentPermissionState = '';
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionStatus;
          } else {
            Linking.openSettings();
          }
        }
      },
      Utility.createPermissionMetaObject(
        SCREEN_CONSTANTS.FEED,
        PERMISSIONS.CAMERA,
      ),
    );
  };

  navigateToMedia = () => {
    const {
      index,
      navigation,
      currentId,
      item,
      itemData,
      imageUrl,
      showToast,
      videosData,
      startIndex,
      videoViewed,
      listIndex,
      listData: {
        id: listId = '',
        name: listName = '',
        slug: listSlug = '',
        display: listDisplay = '',
        content: listContent = '',
      } = {},
      previousScreen,
    } = this.props;
    const publisherType = item?.type;
    const publisherSlug = item?.slug;
    const publisherId = item?.id;
    let videoToTopicMap = this.createVideoToTopicMap(itemData);
    let indexToPlay = startIndex;
    let rotatedVideosData = { ...videosData };
    if (startIndex !== 0) {
      const rotatedVideos = Utility.rotateArray(
        rotatedVideosData.objects,
        startIndex,
      );
      rotatedVideosData.objects = rotatedVideos;
      videoToTopicMap = Utility.rotateArray(videoToTopicMap, startIndex);
      indexToPlay = 0;
    }

    AnalyticsManager.logEvent(EventType.storyEvents.STORY_OPEN, {
      [EventParameterKey.PUBLISHER_ID]: item?.id,
      [EventParameterKey.PUBLISHER_NAME]: item?.name,
      [EventParameterKey.PUBLISHER_TYPE]: item?.type,
      [EventParameterKey.SCREEN_NAME]: previousScreen,
      [EventParameterKey.POSITION]: index,
    });

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      item?.id,
      item?.type,
      item?.name,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      item?.slug,
      listSlug,
    );

    navigation.push('ContentModal', {
      itemData,
      listId: 'stories',
      index: indexToPlay,
      listData: rotatedVideosData,
      videoToTopicMap,
      videoViewed,
      publisherType,
      publisherSlug,
      publisherId,
    });
  };

  createVideoToTopicMap(topics) {
    let videoToTopicMap = [];
    topics.forEach((topic) => {
      const id = topic?.id;
      const name = topic?.name;
      topic?.media_indices.forEach((index) => {
        videoToTopicMap.push({ id, name });
      });
    });
    return videoToTopicMap;
  }

  PublisherAvatar = (props) => {
    const styles = ArtistCardStyles;
    const { imageUrl, imageStyle, imagewidth, item, isSelfie } = props;
    let imgSource = null;

    imgSource = Utility.getMinifiedImage(imageUrl);

    let image = {
      uri: imgSource,
    };
    const borderColor = colors.border;
    if (item?.type === 'my_kit') {
      if (Utility.isPresent(imageUrl)) {
        let myKitImageSource = null;

        myKitImageSource = Utility.getMinifiedImage(imageUrl);

        image = {
          uri: myKitImageSource,
        };
      } else {
        image = images.for_you_placeholder;
      }
    }

    return (
      <View
        style={[
          styles.storyContainer,
          {
            borderRadius: (imagewidth + 4) / 2,
            borderColor,
          },
        ]}
      >
        <FastImageView source={image} style={imageStyle} />
      </View>
    );
  };

  PublisherAvatarWithGradient = (props) => {
    const styles = ArtistCardStyles;
    const {
      imageUrl,
      imageStyle,
      imagewidth,
      imageContainerStyle,
      imageBlankContainerStyle,
      item,
    } = props;

    const gradientArray = ['#F45A44', '#F77F6E', '#F4C44A', '#C13A49'];
    let imageSource = null;

    imageSource = Utility.getMinifiedImage(imageUrl);

    let image = {
      uri: imageSource,
    };
    if (item?.type === 'my_kit') {
      if (Utility.getMinifiedImage(imageUrl)) {
        let myKitImageSource = null;

        myKitImageSource = Utility.getMinifiedImage(imageUrl);

        image = {
          uri: myKitImageSource,
        };
      } else {
        image = images.for_you_placeholder;
      }
    }

    return (
      <View
        style={[
          styles.storyContainerWithGradient,
          { borderRadius: (imagewidth + 4) / 2 },
        ]}
      >
        <LinearGradient
          colors={gradientArray}
          style={styles.storyGradientStyle}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 0 }}
        />
        <View style={imageContainerStyle}>
          <View style={imageBlankContainerStyle}>
            <FastImageView source={image} style={imageStyle} />
          </View>
        </View>
      </View>
    );
  };

  render() {
    const { image_url, name, item, displayName, imageUrl, isTopicViewed } =
      this.props;

    const forYouImageUrl =
      Utility.isPresent(imageUrl) && item.personalised_story
        ? imageUrl
        : image_url;

    // cardStyle[0].height += 12;
    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigateToMedia}>
        <Card style={this.cardStyle}>
          {isTopicViewed ? (
            <this.PublisherAvatar
              imageUrl={forYouImageUrl}
              imageStyle={this.imageStyle}
              imagewidth={this.imagewidth}
              item={item}
              isSelfie={Utility.isPresent(imageUrl) && item.personalised_story}
            />
          ) : (
            <this.PublisherAvatarWithGradient
              imageUrl={forYouImageUrl}
              imageStyle={this.imageStyle}
              imagewidth={this.imagewidth}
              imageContainerStyle={this.imageContainerStyle}
              imageBlankContainerStyle={this.imageBlankContainerStyle}
              item={item}
              isSelfie={Utility.isPresent(imageUrl) && item.personalised_story}
            />
          )}
          <Text numberOfLines={2} style={ArtistCardStyles.artistname} dataSet={{ media: ids.artistname }}>
            {Utility.isBlank(displayName) ? name : displayName}
          </Text>
        </Card>
      </ScaleAnimate>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    // shouldHideStoriesIntro: state.stories.hideStoriesIntro,
    // viewedStories: state.stories.viewedStories,
    imageUrl: state.UserAccountInfo.profile.selfie_image_url,
    // authToken: state.UserAccountInfo.authToken,
  };
};

export default withNavigation(
  connect(mapStateToProps, null)(PublisherStoryCard),
);
