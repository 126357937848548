import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';

const listingStyles = StyleSheet.create({
  flatlistContainer: {
    marginVertical: 8,
  },

  flatlistContainerWithBG: {
    marginVertical: 8,
    backgroundColor: colors.background,
  },
  margin: { height: 8 },
});

export default listingStyles;
