import { put, takeLatest, select, call } from 'redux-saga/effects';

import {
  ORDER_URL,
  ADDRESS_URL,
  ORDER_ITEM_URL,
  SHIPMENT_URL,
  URL,
} from '../config/Constants';
import {
  GET_ORDERS,
  GET_ORDER_DETAIL,
  GET_ADDRESS_DETAIL,
  GET_CARD_DETAILS,
  CANCEL_ORDER_ITEM,
  GET_ONGOING_SHIPMENTS,
  RATE_DELIVERY,
  CANCEL_ORDER,
} from '../actions/ActionTypes';
import {
  ADD_DELIVERY_INSTRUCTION,
  CLAIM_MISSED_DELIGHT_OFFER,
  CLAIM_SHIPMENT_OFFER,
  CANCEL_SHIPMENT,
  GET_ORDERS_HISTORY,
  GET_ORDER_DETAILS,
  GET_ORDER_OFFERS,
  GET_SHIPMENT_DETAILS,
  GET_SHIPMENT_OFFERS,
  HELD_ORDER,
  UPDATE_CALLBACK_REQUESTED_AT,
  UPDATE_CUSTOMER_STATUS,
  UPDATE_ORDER,
} from '../actions/OrderActions';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import Utility from '../utils/Utility';

function* getOrdersHistoryAsync(action) {
  const { page = 0, callback } = action;
  const url = `${ORDER_URL}?page=${page}`;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield response.json();
      callback(true, json);
    } else if (response.status === 401) {
      const json = yield response.json();
      callback(true, json);
    } else {
      response = yield response.json();
      callback(false, {});
    }
  } catch (error) {}
}

function* getOrderDetailAsync(action) {
  const { id, callback } = action;
  const url = `${ORDER_URL}/${id}`;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    let response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield response.json();
      callback(true, json);
    } else {
      response = yield response.json();
      callback(false, response.errors[0]);
    }
  } catch (error) {
    console.tron.log('----1');
  }
}

function* getShipmentDetailAsync(action) {
  const { id, callback, page } = action;
  const url = `${SHIPMENT_URL}/${id}`;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield response.json();
      callback(true, json);
    } else {
      response = yield response.json();
      callback(false, response.errors[0]);
    }
  } catch (error) {
    console.tron.log('----1');
  }
}

function* getAddressByIdAsync(action) {
  const { addressId, callback } = action;
  const url = `${ADDRESS_URL}/${addressId}`;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (response.status >= 200 && response.status < 300) {
      if (Utility.isPresent(json)) {
        callback(json);
      }
    } else if (Utility.isPresent(json)) {
      callback(json.errors[0]);
    }
  } catch (error) {
    console.tron.log('----2');
  }
}

function* createOrder(action) {
  const { addressId, callback } = action;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);

  try {
    const response = yield fetch(URL.CARD_DETAILS, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (response.status >= 200 && response.status < 300) {
      callback(json);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log('Working');
  }
}

function* cancelOrderItemByIdAsync(action) {
  const { orderItemId, callback } = action;
  const url = `${ORDER_ITEM_URL}/${orderItemId}/cancel`;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      callback('success');
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      callback(json.errors[0]);
    }
  } catch (error) {
    console.tron.log(error);
  }
}

function* rateDeliveryByShipmentIdAsync(action) {
  const { id, callback, rating } = action;
  const url = `${SHIPMENT_URL}/${id}/rate`;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        shipment: {
          rating,
        },
      }),
    });

    if (response.status >= 200 && response.status < 300) {
      callback('success');
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      callback(json.errors[0]);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

function* updateCustomerStatusAsync(action) {
  const { data, id, callback } = action;
  const url = `${SHIPMENT_URL}/${id}/update_customer_status`;

  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false);
    clearTimeout(timer);
  }, parseInt(Utility.getTimeOutValue()));

  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(data),
    });

    clearTimeout(timer);

    if (response.status >= 200 && response.status < 300 && sendCallbackData) {
      callback(true);
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(false, json.errors[0]);
    }
  } catch (error) {
    callback(false);
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

function* updateCallbackRequestedAtAsync(action) {
  const { data, id, callback } = action;
  const url = `${ORDER_URL}/${id}/request_callback`;
  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false);
    clearTimeout(timer);
  }, parseInt(Utility.getTimeOutValue()));

  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(data),
    });

    clearTimeout(timer);

    if (response.status >= 200 && response.status < 300 && sendCallbackData) {
      callback(true);
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(false, json.errors[0]);
    }
  } catch (error) {
    callback(false);
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

function* addDeliveryInstructions(action) {
  const { data, id, callback } = action;
  const url = `${URL.ADD_DELIVERY_INSTRUCTION}`;
  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false);
    clearTimeout(timer);
  }, parseInt(Utility.getTimeOutValue()));

  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(data),
    });

    clearTimeout(timer);

    if (response.status >= 200 && response.status < 300 && sendCallbackData) {
      callback(true);
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(false, json.errors[0]);
    }
  } catch (error) {
    callback(false);
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

function* updateOrderAsync(action) {
  const { data, id, callback } = action;
  const url = `${ORDER_URL}/${id}`;
  try {
    const response = yield fetch(url, {
      method: 'PATCH',
      headers: yield getApiHeaders(),
      body: JSON.stringify(data),
    });

    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      callback(false, json.errors[0]);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

function* getOngoingShipments(action) {
  const { callback } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(URL.SHIPMENTS, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (response.status >= 200 && response.status < 300) {
      callback(json);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log('AGaina Error');
  }
}

function* getShipmentOffersAsync(action) {
  const { callback, id } = action;
  const url = `${SHIPMENT_URL}/${id}/offers`;
  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false);
    clearTimeout(timer);
  }, parseInt(Utility.getTimeOutValue()));

  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    clearTimeout(timer);

    const json = yield convertJsonFromResponse(response);
    if (response.status >= 200 && response.status < 300 && sendCallbackData) {
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* getOrderOffersAsync(action) {
  const { callback, id } = action;
  const url = `${ORDER_URL}/${id}/offers`;
  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false);
    clearTimeout(timer);
  }, parseInt(Utility.getTimeOutValue()));

  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    clearTimeout(timer);

    const json = yield convertJsonFromResponse(response);
    if (response.status >= 200 && response.status < 300 && sendCallbackData) {
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* cancelOrder(action) {
  const { id, callback } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(URL.CANCEL_ORDER, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        id,
      }),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, 'Something went wrong while cancelling order');
        return;
      }
      callback(true, json.message);
    } else {
      callback(false, 'Something went wrong while cancelling order');
    }
  } catch (error) {
    callback(false, 'Something went wrong while cancelling order');
  }
}

function* claimShipmentOfferAsync(action) {
  const { callback, data } = action;
  const { shipment_id, delay } = data;
  const url = `${SHIPMENT_URL}/${shipment_id}/claim_offers`;

  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false);
    clearTimeout(timer);
  }, parseInt(Utility.getTimeOutValue()));

  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        shipment: { delay },
      }),
    });

    clearTimeout(timer);
    const json = yield convertJsonFromResponse(response);
    if (response.status >= 200 && response.status < 300 && sendCallbackData) {
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* claimMissedDelightOfferAsync(action) {
  const { callback, data } = action;
  const { orderId, delay } = data;
  const url = `${ORDER_URL}/${orderId}/claim_missed_delight_offer`;

  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false);
    clearTimeout(timer);
  }, parseInt(Utility.getTimeOutValue()));

  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        shipment: { delay },
      }),
    });

    clearTimeout(timer);
    const json = yield convertJsonFromResponse(response);
    if (response.status >= 200 && response.status < 300 && sendCallbackData) {
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* heldOrder(action) {
  const { callback, data } = action;
  const url = `${URL.HELD_ORDER}`;
  const body = {
    id: data.entity_id,
    cancellation_reason: data.cancellation_reason,
  };
  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false);
    clearTimeout(timer);
  }, parseInt(Utility.getTimeOutValue()));
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(body),
    });

    const json = yield convertJsonFromResponse(response);
    clearTimeout(timer);
    if (response.status >= 200 && response.status < 300 && sendCallbackData) {
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* cancelShipment(action) {
  const { callback, data } = action;
  const url = `${SHIPMENT_URL}/${data?.entity_id}/cancel`;
  const body = {
    cancellation_reason_id: data.cancellation_reason,
  };
  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false);
  }, parseInt(Utility.getTimeOutValue()));
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(body),
    });

    const json = yield convertJsonFromResponse(response);
    clearTimeout(timer);
    if (response.status >= 200 && response.status < 300 && sendCallbackData) {
      if (json?.status === 'success') {
        callback(true, json);
        return;
      }
      callback(false);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export default function* watchFetchOrderSaga() {
  yield takeLatest(GET_ORDERS_HISTORY, getOrdersHistoryAsync);
  yield takeLatest(GET_ORDER_DETAILS, getOrderDetailAsync);
  yield takeLatest(GET_SHIPMENT_DETAILS, getShipmentDetailAsync);
  yield takeLatest(GET_ADDRESS_DETAIL, getAddressByIdAsync);
  yield takeLatest(GET_CARD_DETAILS, createOrder);
  yield takeLatest(CANCEL_ORDER_ITEM, cancelOrderItemByIdAsync);
  yield takeLatest(RATE_DELIVERY, rateDeliveryByShipmentIdAsync);
  yield takeLatest(GET_ONGOING_SHIPMENTS, getOngoingShipments);
  yield takeLatest(CANCEL_ORDER, cancelOrder);
  yield takeLatest(UPDATE_ORDER, updateOrderAsync);
  yield takeLatest(GET_SHIPMENT_OFFERS, getShipmentOffersAsync);
  yield takeLatest(GET_ORDER_OFFERS, getOrderOffersAsync);
  yield takeLatest(CLAIM_SHIPMENT_OFFER, claimShipmentOfferAsync);
  yield takeLatest(
    UPDATE_CALLBACK_REQUESTED_AT,
    updateCallbackRequestedAtAsync,
  );
  yield takeLatest(UPDATE_CUSTOMER_STATUS, updateCustomerStatusAsync);
  yield takeLatest(ADD_DELIVERY_INSTRUCTION, addDeliveryInstructions);
  yield takeLatest(HELD_ORDER, heldOrder);
  yield takeLatest(CLAIM_MISSED_DELIGHT_OFFER, claimMissedDelightOfferAsync);
  yield takeLatest(CANCEL_SHIPMENT, cancelShipment);
}
