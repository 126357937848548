import StyleSheet from 'react-native-media-query';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';

export const { ids, styles } = StyleSheet.create({
  adContainer: {
    flexDirection: 'row',
    margin: 8,
  },
  adText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#9d9d9d',
  },
  shortBannerStyle: {
    height: 117,
    margin: 4,
    backgroundColor: colors.background,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: Utility.getDynamicWidth(2, 8),
  },
  shortBannerCardText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 8,
  },
  bannerTextContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  largeBannerText: {
    marginLeft: 14,
  },
  smallBannerText: {
    marginLeft: 34,
  },
  largeImagePosition: {
    position: 'absolute',
    bottom: 0,
    marginLeft: 13,
  },
  smallImagePosition: {
    position: 'absolute',
    left: 0,
    bottom: 16,
  },
  bannerImage: {
    height: (1 / 3) * (Utility.getScreenWidth() - 24),
    overflow: 'hidden',
    // borderWidth: 1,
    borderColor: colors.border,
    backgroundColor: 'transparent',
  },
  bannerWithTimer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%',
    width: '100%',
    paddingHorizontal: 12,
  },
  bannerWithTimerText: {
    color: '#ffffff',
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    alignSelf: 'center',
  },
  bannerTimerDigitStyle: {
    backgroundColor: '#000',
    borderRadius: 2,
  },
  bannerTimerLabelStyle: {
    color: '#fff',
    fontSize: 8,
    fontFamily: 'Roboto-Bold',
    marginLeft: 2,
    paddingLeft: 0,
  },
  bannerTimerSeparatorStyle: {
    color: '#000000',
    marginVertical: 2,
  },
  bannerContainer: {
    marginLeft: Utility.padding,
    marginRight: Utility.padding,
    width: Utility.getScreenWidth() - 2 * Utility.padding,
    height: 42,
    alignSelf: 'center',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    overflow: 'hidden',
  },
  bannerImageStyle: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
    overflow: 'hidden',
    transform: [{ rotateX: '180deg' }],
  },
  bannerTimerContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    alignContent: 'center',
  },
  container: {
    flex: 1,
    '@media (min-width: 800px)': {
      width: Utility.getScreenWidth(),
      paddingHorizontal: '25%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});
