import React, { PureComponent } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither, withMaybe } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';

import ProductDescription from './ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AddToCart from './AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT, MAX_LINES, THEME } from '../../config/Constants';
import colors from '../../theme/Colors';
import ProductColorVariants from '../variants/ProductColorVariants';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import ProductSizeVariants from '../variants/ProductSizeVariants';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import MoreOptions from './MoreOptions';
import ReviewProduct from './ReviewProduct';
import { ProductDetailStyles } from './styles';
import size from '../../theme/Fonts';
import PropTypes from 'prop-types';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import AnimatedLikeButton from '../shared/AnimatedLikeButton';
import FastImageView from '../FastImageView';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class HorizontalProductRail extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceProductCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData,
      itemData: { id, slug },
      toggleCartVisibility,
    } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        id,
        slug,
        display: LAYOUT.SCREEN,
        itemData,
        toggleCartVisibility,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      itemData,
      listId,
      listData = {},
      layout,
      previousScreen,
      listName,
      listContent,
      toggleUnavailableProductsModal,
    } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.debounceProductCardClick(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      itemData.id,
      itemData.slug,
      listData?.slug,
      {},
      itemData,
    );

    this.debouncedNavigate();
    if (onPress !== undefined && onPress !== null) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      layout,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData = {},
    } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.HORIZONTALRAIL}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        singleStockedVariantSku={singleStockedVariantSku}
        theme={THEME.DARK}
        listData={listData}
      />
    );
  };

  productShadesCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Shade';

  productSizeCondition = ({ variantsPropertyName }) =>
    variantsPropertyName === 'Size';

  conditionalVariants = compose(
    withEither(this.productShadesCondition, ProductColorVariants),
    withEither(this.productSizeCondition, ProductSizeVariants),
  )(ProductOtherVariants);

  productVariants = (props) => {
    const {
      itemData: { image_url: imageUrl, variants_details: variantDetails = {} },
    } = this.props;
    const { containerStyle } = props;

    const {
      variants_count: variantsCount = 0,
      variants_shade_images: variantShadeImages = [],
      variants_property_name: variantPropertyName = '',
    } = variantDetails;

    return (
      <this.conditionalVariants
        variantImage={imageUrl}
        variantsCount={variantsCount}
        variantShadesImages={variantShadeImages}
        variantsPropertyName={variantPropertyName}
        containerStyle={containerStyle}
      />
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      itemData: {
        offer_applicable,
        offer_value,
        final_sp,
        additional_discount,
        type,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name,
        mrp,
        sp,
        discount,
        brand: { name: brandName = '' },
        sku_id: skuId,
        prompts,
        has_variants: hasVariants,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
      },
      isSingleItem,
      showToast,
    } = this.props;

    const disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const display_price = final_sp;

    const containerStyle = isSingleItem
      ? styles.singleCardContainer
      : styles.cardContainer;

    const containerDataSet = isSingleItem
      ? { media: ids.singleCardContainer }
      : { media: ids.cardContainer };

    return (
      <View>
        <ScaleAnimate
          {...this.props}
          disabled={disabled}
          onPress={this.onPress}
        >
          <Card style={containerStyle} dataSet={containerDataSet}>
            <View style={styles.imageContainer}>
              <FastImageView
                source={{
                  uri: Utility.getMinifiedImage(images?.[0], 100, 100),
                }}
                style={styles.image}
                resizeMode="contain"
              />
            </View>
            <View style={styles.descriptionContainer}>
              <ProductDescription
                itemData={itemData}
                name={name}
                mrp={mrp}
                offer_applicable={offer_applicable}
                additional_discount={additional_discount}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={brandName}
                variantBrandName={itemData.brand_name}
                layout={LAYOUT.HORIZONTALRAIL}
                id={id}
                previousScreen={previousScreen}
                prompts={prompts}
                theme={THEME.DARK}
              />
            </View>
            <View style={styles.likeButtonContainer}>
              <AnimatedLikeButton
                ref={this.likeButtonRef}
                id={itemData.id}
                type={itemData.type}
                slug={itemData.slug}
                mrp={itemData.mrp}
                skuId={Utility.getSkuId(itemData)}
                layout={LAYOUT.CARD}
                content={itemData.content}
                onLike={this.onLike}
                itemName={itemData.name}
                screenName={SCREEN_CONSTANTS.PRODUCT_CARD}
                addToCartLikeButton
                showToast={showToast}
              />
            </View>
          </Card>
        </ScaleAnimate>
      </View>
    );
  }
}

HorizontalProductRail.propTypes = {
  isSingleItem: PropTypes.bool,
};

HorizontalProductRail.defaultProps = {
  isSingleItem: false,
};

const { ids, styles } = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.translucentgray,
    marginRight: 6,
    marginLeft: 6,
    flexDirection: 'row',
    borderRadius: 8,
    width: 305,
    height: 96,
    alignSelf: 'center',
    alignItems: 'center',
  },
  singleCardContainer: {
    backgroundColor: colors.translucentgray,
    marginRight: 16,
    marginLeft: 16,
    flexDirection: 'row',
    borderRadius: 8,
    width: Utility.getScreenWidth() - 2 * Utility.largePadding,
    height: 96,
    alignSelf: 'center',
    alignItems: 'center',
    '@media (min-width: 768px)': {
      width: getScreenWidth() / 2.5 - 2 * Utility.largePadding,
    },
  },
  imageContainer: {
    height: 78,
    // marginRight: 9,
    marginLeft: 9,
    borderRadius: 8,
    // padding: 8,
    flexDirection: 'row',
  },
  image: {
    height: 76,
    width: 76,
    borderRadius: 8,
    alignSelf: 'center',
    backgroundColor: 'white',
  },
  descriptionContainer: {
    marginLeft: 8,
    marginRight: 0,
    flex: 1,
    justifyContent: 'center',
  },
  likeButtonContainer: {
    position: 'absolute',
    right: 6,
    bottom: 6,
  },
  variantContainerStyle: {
    position: 'absolute',
    right: 12,
    top: 16,
    maxWidth: 80,
    backgroundColor: '#ffffff',
  },
  brandName: {
    fontFamily: 'Roboto',
    fontSize: size.h4,
    letterSpacing: 0,
    color: colors.subtitle,
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  name: {
    fontFamily: 'Roboto',
    fontSize: size.h4,
    letterSpacing: 0,
    color: colors.white,
    fontWeight: 'normal',
    fontStyle: 'normal',
    marginBottom: 2,
  },
  spStyle: {
    color: colors.white,
    fontSize: size.h4,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    marginRight: 4,
  },
  mrpStyle: {
    color: colors.silver,
    fontSize: size.h4,
    textDecorationLine: 'line-through',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  discountRateStyle: {
    color: colors.foxyPink,
    fontSize: size.h4,
    marginLeft: 3,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
  },
});
export default withNavigation(HorizontalProductRail);
