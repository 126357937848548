import React, { Component } from 'react';
import { View } from 'react-native';

export default class EmptyHeader extends Component {
  render() {
    return (
      <View />
    );
  }
}
