import { NativeModules } from 'react-native';
import Config from '../libraries/ReactNativeConfig';
import { findLast } from 'lodash';
import { savePersonalizationAttributes } from '../actions/ActionTypes';
import RemoteConfig from './RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../config/Constants';
import { isAndroid, isBlank, isWeb } from './BooleanUtility';
import { jsonParser } from './JsonUtility';
import { getStoreRef } from '../store/StoreUtility';

export const checkLastOrderStatus = (lastOrderDate) => {
  if (isBlank(Config.LAST_ORDER_DATE_PERSONALIZED_ATTRIBUTE_ID)) return;
  const lastOrderDateTimeFrame = jsonParser(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.last_order_date_time_frame),
  );
  if (isBlank(lastOrderDateTimeFrame)) return;
  const currentDate = new Date();
  const orderDate = new Date(lastOrderDate);
  const timeDifference = currentDate - orderDate;
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  const lastOrderTimeFrameId = findLast(
    lastOrderDateTimeFrame,
    (x) => x.minDaysDifference < daysDifference,
  ).timeFrame;
  getStoreRef().dispatch(
    savePersonalizationAttributes({
      [Config.LAST_ORDER_DATE_PERSONALIZED_ATTRIBUTE_ID]: [
        lastOrderTimeFrameId,
      ],
    }),
  );
};

export const checkInstallDateTimeFrame = (installDate) => {
  if (isBlank(Config.INSTALL_DATE_PERSONALIZED_ATTRIBUTE_ID)) return;
  const installDateTimeFrame = jsonParser(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.install_date_time_frame),
  );
  if (isBlank(installDateTimeFrame)) return;
  const currentDate = new Date();
  const installDateObj = new Date(installDate);
  const timeDifference = currentDate - installDateObj;
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  const installDateTimeFrameId = findLast(
    installDateTimeFrame,
    (x) => x.minDaysDifference < daysDifference,
  )?.timeFrame;
  if (isBlank(installDateTimeFrameId)) return;
  getStoreRef().dispatch(
    savePersonalizationAttributes({
      [Config.INSTALL_DATE_PERSONALIZED_ATTRIBUTE_ID]: [installDateTimeFrameId],
    }),
  );
};

export const checkIsDevicePremium = async () => {
  if (isWeb()) return;
  const state = getStoreRef();
  const {
    UserAccountInfo: {
      device_information: { model = '', manufacturer = '' } = {},
    },
  } = state;
  if (isBlank(Config.DEVICE_PERSONALIZED_ATTRIBUTE_ID)) return;

  const premiumDeviceBrandNames =
    jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.premium_device_brand_name),
    ) || [];
  const premiumDeviceModelNames =
    jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.premium_device_model_name),
    ) || [];
  const premiumDevicesRamThresholdLimit = RemoteConfig.getIntValue(
    REMOTE_CONFIG_KEYS.premium_device_ram_threshold_limit,
  );

  let deviceRam = '';

  if (isAndroid()) {
    try {
      const response = await new Promise((resolve, reject) => {
        NativeModules.DeviceDetails.getDeviceMemoryInformation(
          (error, result) => {
            if (error) {
              reject(error);
            } else {
              resolve(result);
            }
          },
        );
      });

      deviceRam = response?.total_ram || '';
    } catch (error) {
      console.error('Error fetching device memory information:', error);
    }
  }
  const isPremium =
    deviceRam >= premiumDevicesRamThresholdLimit ||
    premiumDeviceBrandNames.includes(manufacturer) ||
    premiumDeviceModelNames.includes(model);

  const personalizedAttribute = isPremium
    ? [Config.PREMIUM_DEVICE_PERSONALIZED_ATTRIBUTE_ID]
    : [Config.NON_PREMIUM_DEVICE_PERSONALIZED_ATTRIBUTE_ID];

  getStoreRef().dispatch(
    savePersonalizationAttributes({
      [Config.DEVICE_PERSONALIZED_ATTRIBUTE_ID]: personalizedAttribute,
    }),
  );
};

export const checkAppPlatform = () => {
  let attributeValues = [];
  if (isBlank(Config.PLATFORM_PERSONALIZED_ATTRIBUTE_ID)) return;
  if (isAndroid()) {
    attributeValues = [Config.PLATFORM_ANDROID_PERSONALIZED_ATTRIBUTE_ID];
  } else {
    attributeValues = [Config.PLATFORM_IOS_PERSONALIZED_ATTRIBUTE_ID];
  }
  getStoreRef().dispatch(
    savePersonalizationAttributes({
      [Config.PLATFORM_PERSONALIZED_ATTRIBUTE_ID]: attributeValues,
    }),
  );
};
