import AnalyticsEvent from './AnalyticsEvent';

class FacebookAnalytics {
  static trackEvent = (event) => {
    if (event instanceof AnalyticsEvent) {
      const { meta, eventType } = event;
      fbq('trackCustom', eventType, meta);
    }
  };

  static logStandardEvent = (eventName, valueToSum, parameters) => {
  };

  static logPurchaseEvent = (purchaseAmount, currencyCode, parameters) => {
  };

  static logPixelStandardEvent = (eventName, params) => {
    fbq('track', eventName, params);
  }
}

export default FacebookAnalytics;
