import _ from 'lodash';
import React, { Component } from 'react';
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import colors from '../../../../theme/Colors';
import size from '../../../../theme/Fonts';
import Utility from '../../../../utils/Utility';

class PollActionCardContent extends Component {
  getTotalPollCount = (pollQuestionOptions) => {
    let totalCount = 0;
    pollQuestionOptions.map((poll) => {
      if (Utility.isPresent(poll?.pollAnswersCount)) {
        totalCount = totalCount + poll?.pollAnswersCount;
      }
    });
    return totalCount;
  };

  pollQuestion = ({ item = {} }) => {
    const {
      title: pollQuestionTitle = '',
      pollQuestionOptions = [],
      selectedPollQuestionOptions = [],
    } = item;
    const totalPollAnswersCount = this.getTotalPollCount(pollQuestionOptions);
    const totalVoteCount =
      selectedPollQuestionOptions.length !== 0 && totalPollAnswersCount !== 0;
    return (
      <View style={{ marginBottom: 16 }}>
        <Text style={styles.pollDescription}>{pollQuestionTitle}</Text>
        <FlatList
          data={pollQuestionOptions}
          renderItem={_.memoize(
            ({ item, index }) =>
              this.pollQuestionOption(
                item,
                index,
                totalPollAnswersCount,
                selectedPollQuestionOptions,
              ),
            () => [selectedPollQuestionOptions],
          )}
          keyExtractor={(item) => `${item?.id}`}
        />
        {totalVoteCount && (
          <Text style={styles.totalVotes}>{totalPollAnswersCount} votes</Text>
        )}
      </View>
    );
  };

  onPressPoll = (item) => {
    const {
      buttonVisibility = '',
      onPollOptionPress = () => {},
      inactivePollTapped = () => {},
    } = this.props;
    if (buttonVisibility === 'disabled') {
      inactivePollTapped();
      return;
    }
    onPollOptionPress(item);
  };

  pollQuestionOption = (
    item = {},
    index,
    totalPollAnswersCount,
    selectedPollQuestionOptions,
  ) => {
    const letter = String.fromCharCode(index + 65).toLowerCase();
    const { pollAnswersCount = 0, id: pollQuestionOptionId = '' } = item;
    const { title = '' } = item;
    const pollResult =
      totalPollAnswersCount !== 0
        ? ((pollAnswersCount * 100) / totalPollAnswersCount).toFixed(0)
        : 0;
    const pollResultIndicator = [
      styles.pollResultIndicator,
      { width: `${pollResult}%` },
    ];
    const isSelected =
      selectedPollQuestionOptions.filter(
        (element) => parseInt(element) === parseInt(pollQuestionOptionId),
      ).length !== 0;
    const pollItemContainerStyle = isSelected
      ? styles.pollItemContainerSelected
      : styles.pollItemContainerUnselected;

    const hasUserVoted = selectedPollQuestionOptions.length !== 0;
    return (
      <TouchableOpacity
        onPress={() => this.onPressPoll(item)}
        style={pollItemContainerStyle}
        // disabled={hasUserVoted}
      >
        <View style={styles.pollOptionContentContainer}>
          <View style={styles.pollPointContainer}>
            <Text style={styles.pollOptionPointText}>{letter}</Text>
          </View>
          <Text
            style={styles.pollOptionText}
            numberOfLines={1}
            ellipsizeMode='tail'
          >
            {title}
          </Text>
        </View>
        {hasUserVoted && pollAnswersCount !== 0 && (
          <View style={pollResultIndicator} />
        )}
        {hasUserVoted && (
          <Text style={styles.pollResultText}>{pollResult}%</Text>
        )}
      </TouchableOpacity>
    );
  };

  render() {
    const { pollsData = [] } = this.props;
    return (
      <FlatList
        data={pollsData}
        renderItem={this.pollQuestion}
        keyExtractor={(item) => `${item?.id}`}
      />
    );
  }
}

export default PollActionCardContent;

const styles = StyleSheet.create({
  pollDescription: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
  },
  pollItemContainerUnselected: {
    backgroundColor: colors.white,
    borderRadius: 16,
    marginTop: 12,
    height: 40,
    width: '100%',
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: colors.transparent,
    justifyContent: 'center',
  },
  pollItemContainerSelected: {
    backgroundColor: colors.white,
    borderRadius: 16,
    marginTop: 12,
    height: 40,
    borderWidth: 1,
    overflow: 'hidden',
    borderColor: colors.light_green,
    width: '100%',
    justifyContent: 'center',
  },
  pollOptionContentContainer: {
    flexDirection: 'row',
    zIndex: 2,
    margin: 12,
    overflow: 'visible',
    position: 'absolute',
    alignItems: 'center',
  },
  pollResultIndicator: {
    zIndex: 1,
    backgroundColor: colors.light_green,
    opacity: 0.2,
    borderRadius: 16,
    top: 0,
    height: '100%',
    margin: -1,
    borderWidth: 1,
    borderColor: colors.light_green,
  },
  pollPointContainer: {
    borderRadius: 9,
    borderWidth: 1,
    borderColor: colors.dark_grey,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
    width: 18,
  },
  pollOptionPointText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    lineHeight: 14,
  },
  pollOptionText: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    // flex: 1,
    // marginTop: 4,
  },
  pollResultText: {
    zIndex: 2,
    position: 'absolute',
    right: 12,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h3,
    color: colors.black,
  },
  totalVotes: {
    color: colors.foxyBlack,
    fontSize: size.h3_5,
    fontFamily: 'Roboto-Bold',
    marginTop: 12,
  },
});
