import React from 'react';
import { Text, View } from 'react-native';
import FastImageView from '../../components/FastImageView';
import Utility from '../../utils/Utility';
import { styles } from './styles';

export default function SmallTag(props) {
  const {
    itemData = {},
    itemData: { image_url = '', name = '' } = {},
    options: { show_item_labels = '0' } = {},
  } = props;

  if (Utility.isBlank(itemData) || Utility.isBlank(image_url)) {
    return null;
  }

  const newImageUrl = Utility.getMinifiedImage(image_url, 200);

  return (
    <View style={styles.smallTagContainer}>
      <FastImageView
        source={newImageUrl}
        style={styles.smallTagImage}
        resizeMode='contain'
      />
      {show_item_labels === '1' && <Text style={styles.tagsText}>{name}</Text>}
    </View>
  );
}
