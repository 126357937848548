import React, { PureComponent } from 'react';
import {
  Text,
  View,
  StyleSheet,
  ImageBackground,
  Image,
  ScrollView,
  Button,
  BackHandler,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import FastImageView from '../../components/FastImageView';
import StaticNavigationHeader from '../../components/shared/StaticNavigationHeader';

import RetailBagSummary from './RetailBagSummary';
import CartAndOffers from './container/cart/CartAndOffers';
import QRCode from '../../libraries/ReactNativeQrcodeSvg';
import { connect } from 'react-redux';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { getStoreCartStatus } from '../../actions/RetailStoreActions';
import { bindActionCreators } from 'redux';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { CURRENCY_SYMBOL } from '../../config/Constants';

class UpdateCart extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.userSlug = `https://foxy.in/users/${props.publicId}`;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.showIndependentPass = route.params?.showIndependentPass;
    console.tron.log('showIndependentPass', this.showIndependentPass);
  }

  componentDidMount() {
    this.startCartStatusWatcher();
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  startCartStatusWatcher = () => {
    this.getCart();
    this.status = setInterval(this.getCart, 5000);
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  getCart = () => {
    const { getStoreCartStatus } = this.props;

    getStoreCartStatus((success, value) => {
      const { store_cart_status } = value;
      console.tron.log(value, 'value');
      if (!success) {
        this.showToast('Something went wrong', 500);
      } else {
        if (store_cart_status === 'started') {
          this.props.navigation.replace('RetailCart', {
            showUpdatingCart: true,
          });
        }
      }
    });
  };

  componentWillUnmount() {
    clearInterval(this.status);
  }

  render() {
    const { name, mobileNumber, navigation, selfieImageUrl, discount } =
      this.props;
    const RootContainer = this.showIndependentPass ? View : ImageBackground;
    return (
      <>
        <ScrollView>
          <StaticNavigationHeader
            bgColor="transparent"
            skipDivider
            overrideBackButtonBgColor
          />
          <View style={styles.main}>
            <Text style={styles.title}>Your {Config.APP_NAME} Pass</Text>
            <Text style={styles.subtitle}>
              Show this QR code to our {Config.APP_NAME} staff
            </Text>
            <RootContainer
              source={images.retailStore.foxyPassBackground}
              style={styles.backgroundImage}
              resizeMode="cover"
            >
              <View style={styles.scanContainer}>
                <View style={styles.qrContainer}>
                  {!!name && <Text style={styles.name}>{name}</Text>}
                  <QRCode
                    value={this.userSlug}
                    size={200}
                    logo={selfieImageUrl ? selfieImageUrl : undefined}
                    logoSize={60}
                    logoBorderRadius={40}
                    enableLinearGradient={true}
                    linearGradient={['#942121', '#0972B2']}
                  />
                  <Image source={images.foxy_barcode} style={styles.barcode} />
                  <Text style={styles.mobileNumber}>{mobileNumber}</Text>
                </View>
              </View>
            </RootContainer>
            {!this.showIndependentPass && parseInt(discount) > 0 && (
              <>
                <Text style={styles.secondaryTitle}>
                  Your total savings on this order!
                </Text>
                <View style={styles.orderAmountContainer}>
                  <Text
                    style={styles.orderAmount}
                  >{`${CURRENCY_SYMBOL}${discount}`}</Text>
                  <FastImageView
                    source={images.retailStore.confetti}
                    style={styles.confettiImage}
                  />
                </View>
              </>
            )}
          </View>
          {!this.showIndependentPass && <CartAndOffers showWarning />}
        </ScrollView>
        {this.showIndependentPass && (
          <FastImageView
            source={images.retailStore.foxyPassBackground.uri}
            style={styles.bottomBanner}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
  publicId: state.UserAccountInfo.publicId,
  mobileNumber: state.UserAccountInfo.mobileNumber,
  name: state.UserAccountInfo.profile?.name,
  discount: state.bag.cartPricing.discount,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getStoreCartStatus,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(UpdateCart),
);

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    color: '#000',
    lineHeight: 28,
    fontWeight: '700',
  },
  subtitle: {
    color: '#818181',
    fontSize: 14,
    lineHeight: 16.5,
  },
  secondaryTitle: {
    fontSize: 18,
    fontWeight: '500',
    lineHeight: 21,
    color: '#000',
  },
  orderAmount: {
    fontWeight: '700',
    fontSize: 32,
    lineHeight: 38,
    color: '#173143',
  },
  main: { marginVertical: 20, alignItems: 'center' },
  container: {},
  backgroundImage: {
    alignSelf: 'stretch',
    alignItems: 'center',
    marginVertical: 23,
    overflow: 'visible',
  },
  orderAmountContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  confettiImage: {
    width: 38,
    height: 38,
  },

  scanContainer: {
    width: Utility.getScreenWidth(),
    height: 320,
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrContainer: {
    height: 293,
    width: 270,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
  },
  name: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: '#979BAA',
    marginBottom: 12,
  },
  barcode: { width: '100%', height: 10, position: 'absolute', bottom: 0 },
  mobileNumber: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: '#979BAA',
    marginTop: 12,
  },
  bottomBanner: {
    position: 'absolute',
    width: Utility.getScreenWidth(),
    height: 290,

    bottom: 80,
  },
});
