import React from 'react';
import { View, Text, FlatList, TouchableOpacity, Image } from 'react-native';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { PaymentStyles } from '../cart/styles';
import { CURRENCY_SYMBOL } from '../../config/Constants';
import Utility from '../../utils/Utility';
import Accordion from '../layout/Accordion';
import SavedCardLayout from './SavedCardLayout';
import PayWithSavedCardLayout from './PayWithSavedCardLayout';
import FoxyShadowButton from '../../lib/FoxyShadowButton';

const CardLayout = (props) => {
  const styles = PaymentStyles;
  const {
    cardNumber,
    updateExpandableComponent,
    card,
    expandedStatus = '',
    createOrder = () => {},
    setCvvForSavedCard,
    savedCardCvv,
    setRef,
    index,
  } = props;
  const borderColor =
    expandedStatus.includes(cardNumber) && expandedStatus.includes('expanded')
      ? colors.green
      : '#86949E';
  return (
    <>
      <Accordion
        title={() => (
          <SavedCardLayout
            cardNumber={cardNumber}
            card={card}
            borderColor={borderColor}
            setCvvForSavedCard={setCvvForSavedCard}
            setRef={setRef}
            index={index}
            expandedStatus={expandedStatus}
            savedCardCvv={savedCardCvv}
            updateExpandableComponent={updateExpandableComponent}
          />
        )}
        expandableComponent={() => (
          <PayWithSavedCardLayout
            createOrder={createOrder}
            card={card}
            savedCardCvv={savedCardCvv}
            setCvvForSavedCard={setCvvForSavedCard}
          />
        )}
        item={{ title: cardNumber }}
        expandedStatus={expandedStatus}
        updateExpandableComponent={updateExpandableComponent}
        hideArrow
      />

      <View style={styles.divider} />
    </>
  );
};

const createOrderAndPay = (item, createOrder) => {
  const upiParams = {
    virtual_bank_account_id: item.detail,
    bankcode: 'UPI',
  };

  const payuPayload = {
    vpa: item.detail,
  };

  const paymentPayload = {
    paymentGateway: 'payu',
    paymentMethod: 'upi',
    isSavedPaymentMethod: true,
    paymentMethodPayload: upiParams,
    payuPayload,
    extraData: {
      bankcode: 'UPI',
      method: 'upi',
      details: item?.details,
    },
  };
  createOrder(paymentPayload);
};

const createOrderAndPayWithNb = (item, createOrder) => {
  const nbParams = {
    bankCode: item.bankcode,
    bankTitle: item.name,
  };

  const paymentPayload = {
    paymentGateway: 'payu',
    paymentMethod: 'netbanking',
    isSavedPaymentMethod: true,
    paymentMethodPayload: item,
    payuPayload: nbParams,
    extraData: {
      method: 'netbanking',
    },
  };
  createOrder(paymentPayload);
};

const SavedPaytm = ({
  paytmDetails,
  updateExpandableComponent,
  expandedStatus,
  createOrderAndAddMoney,
  createOrderAndPayWithPaytm,
}) => {
  if (Utility.isBlank(paytmDetails)) return null;

  const styles = PaymentStyles;
  let payButton = 'Pay';

  if (Utility.isPresent(paytmDetails)) {
    if (paytmDetails.sufficient_balance) {
      payButton = 'Pay';
    } else {
      payButton = `Add ${CURRENCY_SYMBOL}${paytmDetails.amount_needed} and Pay`;
    }
  }

  return (
    <>
      <Accordion
        title={() => (
          <View style={styles.paymentOption}>
            <Image source={images.paytm} style={styles.paymentTypeImage} />
            <Text style={styles.paymentTypeText}>Paytm Wallet</Text>

            <View
              style={{
                flexDirection: 'row',
                position: 'absolute',
                right: 20,
                bottom: 20,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto-Regular',
                  color: colors.foxyBlack,
                  marginRight: 8,
                }}
              >{`${CURRENCY_SYMBOL}${paytmDetails.paytm_wallet_amount}`}</Text>

              <Image
                source={
                  expandedStatus.includes('expanded') &&
                  expandedStatus.includes('paytm')
                    ? images.chevronUp
                    : images.chevronDown
                }
                style={{
                  tintColor: colors.foxyBlack,
                }}
              />
            </View>
          </View>
        )}
        expandableComponent={() => (
          <View
            style={{
              backgroundColor: '#ffffff',
              paddingBottom: 20,
            }}
          >
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 34}
              title={payButton}
              onPress={() => {
                if (
                  Utility.isPresent(paytmDetails) &&
                  paytmDetails.sufficient_balance
                ) {
                  createOrderAndPayWithPaytm();
                } else {
                  createOrderAndAddMoney();
                }
              }}
              backgroundColor={colors.primaryActionBackgroundColor}
              style={styles.payButton}
              firstColor={colors.linearGradientGreenFirst}
              secondColor={colors.linearGradientGreenSecond}
            />
          </View>
        )}
        item={{ title: 'paytm' }}
        expandedStatus={expandedStatus}
        updateExpandableComponent={updateExpandableComponent}
        hideArrow
      />
      <View style={styles.divider} />
    </>
  );
};

const SavedUpis = ({ savedUpis, createOrder }) => {
  if (Utility.isBlank(savedUpis) || savedUpis.length === 0) {
    return null;
  }

  return (
    <View>
      {savedUpis.map((item) => {
        return (
          <>
            <TouchableOpacity
              onPress={() => createOrderAndPay(item, createOrder)}
              style={PaymentStyles.paymentOption}
            >
              <Image
                source={images.bhim}
                style={{
                  height: 24,
                  width: 24,
                }}
              />
              <Text style={PaymentStyles.paymentTypeText}> {item.detail}</Text>
              <Image
                source={images.chevronRight}
                style={PaymentStyles.rightArrow}
              />
            </TouchableOpacity>
            <View style={PaymentStyles.divider} />
          </>
        );
      })}
    </View>
  );
};

const SavedNbs = ({ savedNbs, createOrder }) => {
  if (Utility.isBlank(savedNbs) || savedNbs.length === 0) {
    return null;
  }
  return (
    <View>
      {savedNbs.map((item) => (
        <>
          <TouchableOpacity
            onPress={() => createOrderAndPayWithNb(item, createOrder)}
            style={PaymentStyles.paymentOption}
          >
            <Image
              source={
                Utility.isPresent(images.bankImage[`BANK_${item.bankcode}`])
                  ? images.bankImage[`BANK_${item.bankcode}`]
                  : images.payment.bank
              }
              style={{
                height: 24,
                width: 24,
                borderRadius: 2,
                resizeMode: 'contain',
              }}
            />
            <Text style={PaymentStyles.paymentTypeText}>{item.name}</Text>
            <Image
              source={images.chevronRight}
              style={PaymentStyles.rightArrow}
            />
          </TouchableOpacity>
          <View style={PaymentStyles.divider} />
        </>
      ))}
    </View>
  );
};

const ConditionalSavedCards = (props) => {
  const {
    savedCards,
    updateExpandableComponent,
    expandedStatus,
    createOrder,
    setCvvForSavedCard,
    savedCardCvv,
    setRef,
  } = props;
  if (Utility.isBlank(savedCards) || savedCards.length === 0) {
    return null;
  }
  return (
    <View>
      <FlatList
        data={savedCards}
        keyboardShouldPersistTaps
        extraData={props}
        renderItem={({ item, index }) => (
          <CardLayout
            card={item}
            cardNumber={item.cardNumber}
            bankName={item.bankName}
            selectedPaymentOption='Saved Card'
            index={index}
            updateExpandableComponent={(paymentOption) => {
              updateExpandableComponent(paymentOption, index);
            }}
            expandedStatus={expandedStatus}
            createOrder={createOrder}
            setCvvForSavedCard={setCvvForSavedCard}
            savedCardCvv={savedCardCvv}
            setRef={setRef}
          />
        )}
      />
    </View>
  );
};

const PreferredPayments = ({
  savedUpis = [],
  savedNbs = [],
  savedCards = [],
  navigation,
  updateExpandableComponent,
  expandedStatus,
  createOrder,
  paytmDetails,
  createOrderAndPayWithPaytm,
  createOrderAndAddMoney,
  setCvvForSavedCard,
  savedCardCvv,
  setRef,
}) => {
  return (
    <>
      <SavedPaytm
        updateExpandableComponent={updateExpandableComponent}
        expandedStatus={expandedStatus}
        paytmDetails={paytmDetails}
        createOrder={createOrder}
        createOrderAndPayWithPaytm={createOrderAndPayWithPaytm}
        createOrderAndAddMoney={createOrderAndAddMoney}
      />
      <SavedUpis savedUpis={savedUpis} createOrder={createOrder} />
      <SavedNbs savedNbs={savedNbs} createOrder={createOrder} />
      <ConditionalSavedCards
        savedCards={savedCards}
        updateExpandableComponent={updateExpandableComponent}
        expandedStatus={expandedStatus}
        createOrder={createOrder}
        setCvvForSavedCard={setCvvForSavedCard}
        savedCardCvv={savedCardCvv}
        setRef={setRef}
      />
    </>
  );
};

export default PreferredPayments;
