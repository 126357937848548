import React from 'react';
import { Dimensions, Image, View } from 'react-native';
import PropTypes from 'prop-types';

// Components, Styles ,...
import images from '../../theme/Images';
import colors from '../../theme/Colors';

const { width } = Dimensions.get('window');

const FoxyDefaultCheckBox = ({
  containerStyle = {},
  imageStyle = {},
  checked = false,
}) => (
  <View
    style={[
      {
        height: 14,
        borderColor: colors.silver,
        borderWidth: 0.4,
        borderRadius: 3,
        width: 14,
      },
      containerStyle,
    ]}
  >
    <Image source={checked ? images.greenCheck : ''} style={imageStyle} />
  </View>
);

FoxyDefaultCheckBox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  checkboxStyle: PropTypes.object,
};

FoxyDefaultCheckBox.defaultProps = {
  label: 'Foxy CheckBox',
  checked: true,
  onChange: () => {},
  checkboxStyle: { width: 18, height: 18 },
};

export default FoxyDefaultCheckBox;
