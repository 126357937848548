import React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  View,
  TouchableWithoutFeedback,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';

const ModalContainerWithoutSafeArea = ({ children }) => {
  const navigation = useNavigation();
  const goBack = () => {
    navigation.goBack();
  };

  return (
    <>
      <TouchableWithoutFeedback onPress={goBack}>
        <View style={modalStyles.touchableContainer} />
      </TouchableWithoutFeedback>
      <View style={modalStyles.modalContainer} pointerEvents='box-none' dataSet={{ media: ids.modalContainer }}>
        {children}
      </View>
    </>
  );
};

export default ModalContainerWithoutSafeArea;

const { ids, styles: modalStyles } = StyleSheet.create({
  touchableContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.translucent,
  },
  modalContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    '@media (min-width: 768px)': {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});
