import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { compose } from 'recompose';
import { videoSchema, imageSchema } from '../../config/Schema';
import { MediaCard, MediaReviewCard } from '../../components/media';
import { withMaybe, withEither } from '../../lib/Monads';
import Utility from '../../utils/Utility';
import * as ActionTypes from '../../actions/ActionTypes';
import { LAYOUT } from '../../config/Constants';

class Media extends Component {
  forNullConditionFn = (props) => !props.itemData;

  mediaReviewCondition = (props) => props.layout === LAYOUT.REVIEWGRID;

  MediaCardWithCondition = compose(
    withMaybe(this.forNullConditionFn),
    withEither(this.mediaReviewCondition, MediaReviewCard),
  )(MediaCard);

  render = () => {
    let {
      itemData,
      layout,
      listIndex,
      index,
      searchQuery,
      search,
      elementItemCounts,
      toggleCartVisibility,
      listData,
      onPress,
    } = this.props;
    if (Utility.isBlank(itemData)) {
      itemData = this.props.data;
    }
    return (
      <this.MediaCardWithCondition
        {...this.props}
        itemData={itemData}
        layout={layout}
        listIndex={listIndex}
        index={index}
        searchQuery={searchQuery}
        search={search}
        elementItemCounts={elementItemCounts}
        listData={listData}
        onPress={onPress}
      />
    );
  };
}

const mapStateToProps = (store, ownProps) => {
  const schemaType = {
    video: videoSchema,
    image: imageSchema,
  };
  const itemData = denormalize(
    store.data.entities[`${ownProps.type}s`][ownProps.id],
    schemaType[ownProps.type],
    store.data.entities,
  );
  if (Utility.isBlank(itemData) || ownProps.preventReduxFetch) {
    return {
      itemData: ownProps.itemData,
    };
  }
  return { itemData };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionTypes, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Media);
