import * as types from '../actions/ActionTypes';
import initialState from './initialState';

export default function brandDetails(
  state = initialState.brandDetails,
  action,
) {
  switch (action.type) {
    case types.BRAND_DATA_RECEIVED:
      return {
        id: action.data.id,
        type: action.data.type,
        slug: action.data.slug,
        isLoading: false,
      };

    default:
      return state;
  }
}
