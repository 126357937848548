import React, { Component } from 'react';
import {
  View,
  Image,
  ActivityIndicator,
  BackHandler,
  NativeModules,
  NativeEventEmitter,
  RefreshControl,
  Linking,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import Config from '../../libraries/ReactNativeConfig';
import Toast from 'react-native-easy-toast';
import { KeyboardAwareScrollView } from '../../libraries/KeyboardAwareScrollView';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce, reduce } from 'lodash';
import { RNPayuPayment } from '../../lib/RNPayuPayment';
import {
  createNewOrder,
  getCardDetails,
  setUpgradeToPrepaid,
  createNewOrderV2,
  saveCardsInRedux,
  getSavedUpiFromServer,
  saveLocalNotificationData,
  setCartNotification,
} from '../../actions/ActionTypes';
import {
  CART_NOTIFICATION_STATES,
  NOTIFICATION_ACTIONS,
  NOTIFICATION_IDENTIFIERS,
  NOTIFICATION_TYPE,
  ORDER_PAGE_URL,
  PAYMENT_METHODS_URL,
  REMOTE_CONFIG_KEYS,
  TRACKING_PARAMS,
} from '../../config/Constants';
import { checkPaytmBalance } from '../../actions/PaytmActions';
import { setCurrentPaymentFlow } from '../../actions/PaymentActions';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import Payment from '../../components/payment/Payments';
import PreferredPayments from './PreferredPayments';
import {
  StaticNavigationHeader,
  PaymentPagePlaceHolder,
} from '../../components/shared';
import { PaymentStyles, CartStyles } from '../../components/cart/styles';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import UpgradeToPrepaid from '../../components/payment/UpgradeToPrepaid';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import {
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  CartAndPaymentAnalytics,
  EventType,
} from '../../analytics';
import NotificationManager from '../../utils/NotificationsManager';
import {
  CartProducts,
  CartPricing,
  Address,
} from '../../components/payment/paymentStatus/PaymentStatusElements';
import RemoteConfig from '../../utils/RemoteConfig';
import NetworkStats from '../../utils/NetworkStat';
import { availableApps } from '../../actions/LoginActions';
import OrderSummary from './OrderSummary';
import { Text } from 'react-native';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { setCongratsModalForEdge } from '../../actions/FoxyEdgeAction';
import Razorpay from 'react-native-customui';
import { getPriceText } from '../../utils/NumberUtility';
import { isDesktop, isIOS, isNative, isWeb } from '../../utils/BooleanUtility';
import PayuForm from './PayuForm';
import { pushInitiateCheckoutToGtm } from '../../analytics/GTMAnalytics';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getFirebasePerformanceTrace } from '../../utils/PerformanceUtility';

export class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedStatus: '',
      isLoaderVisible: false,
      refreshing: false,
      savedCards: [],
      supportedIOSIntents: [],
      isShimmerVisible: false,
      errorMessage: '',
    };
    this.trace_initiate_checkout_to_confirm = getFirebasePerformanceTrace('initiate_checkout_to_confirm');
    this.trace_payment_initiate_to_confirm = getFirebasePerformanceTrace('payment_initiate_to_confirm');
    this.debounceCreateOrder = debounce(this.createOrder, 1000, {
      leading: true,
      trailing: false,
    });
    this.showOrderSummary = true; //this.props.navigation.getParam('showOrderSummary') || false;
    this.UtoPOrderAmount = props.route.params?.UtoPOrderAmount;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
      this.appDetectEventListener = new NativeEventEmitter(
        NativeModules.AppDetect,
      );
      this.payuSuccess = new NativeEventEmitter(NativeModules.payment);
    }
    this.source = this.props.route.params?.source || '';
    this.razorpayEnabled = Config.PAYMENT_GATEWAY === 'RazorPay';
    this.payuEnabled = Config.PAYMENT_GATEWAY === 'PayU';
    this.getRazorPaySupportedUpiAppsForIOS();
  }

  componentDidMount() {
    const { getCardDetails, setCurrentPaymentFlow, navigation } = this.props;
    const enabledPayment = RemoteConfig.getValue(
      isWeb()
        ? REMOTE_CONFIG_KEYS.PAYMENT_ENABLED_WEB
        : REMOTE_CONFIG_KEYS.PAYMENT_ENABLED,
    );
    this.trace_initiate_checkout_to_confirm.start();

    setCurrentPaymentFlow(true);

    this.unsubscribe = navigation.addListener('focus', () => {
      this.onFocus();
    });

    if (this.razorpayEnabled) {
      this.firePaymentMethodView();
    }

    if (isWeb()) {
      this.firePaymentMethodView();
    }

    if (Utility.isAndroid()) {
      this.payuSuccess.addListener('payu_payment_success', (response) => {
        let parsedResponse = {};
        try {
          parsedResponse = Utility.jsonParser(response);
        } catch {}
        const { result: { txnid = '' } = {} } = parsedResponse;
        AnalyticsManager.logEvent('order_confirm_cross_check', {
          [EventParameterKey.TXN_ID]: Utility.isPresent(txnid) ? txnid : '',
        });
      });
      this.payuSuccess.addListener('payu_upi_error', (response) => {
        try {
          this.toast.show('UPI is not enabled on this application');
        } catch (e) {}
      });
      try {
        this.subscribeAppDetectEventListener();
      } catch (e) {}
    }

    this.renderShimmers(true);

    const hidePaymentOptions = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.hide_payment_options),
    );

    const isCashOnly =
      !hidePaymentOptions.Cash &&
      Object.values(hidePaymentOptions).filter((value) => !value).length === 1;

    if (!isCashOnly) {
      setTimeout(() => {
        this.getPreferredPayments();
        if (this.payuEnabled) {
          getCardDetails((response) => {
            this.getSavedCardDetails(response.payu_params);
            if (Utility.isIOS()) {
              this.getIOSIntentDetails(response.payu_params);
            }
          });
        }
      }, 10);
    }

    setTimeout(() => {
      this.renderShimmers(false);
    }, 2000);

    this.setState({
      expandedStatus: `${enabledPayment}_expanded`,
    });
    pushInitiateCheckoutToGtm(5, 'Payment Page');
  }

  componentWillUnmount() {
    const { setUpgradeToPrepaid } = this.props;

    if (Utility.isAndroid()) {
      this.backhandler.remove();
      this.payuSuccess.removeListener('payu_payment_success');
      this.payuSuccess.removeListener('payu_upi_error');
      this.appDetectEventListener.removeListener('installedApps');
    }
    this.stopTraces('abandon');
    setUpgradeToPrepaid({
      isUpgradeToPrepaid: false,
      prepaidUpgradeOptionExpiresAt: '',
      orderId: '',
    });

    this.unsubscribe();
  }

  getRazorPaySupportedUpiAppsForIOS = () => {
    if (!isIOS() || !this.razorpayEnabled) {
      return;
    }
    Razorpay.getAppsWhichSupportUPI((res = {}) => {
      const { data: supportedIOSApps = [] } = res;
      const supportedIOSIntents = reduce(
        supportedIOSApps,
        (supportedAppsArray = [], supportedApp = {}) => {
          let { appName: { appPackageName = '' } = {} } = supportedApp;
          if (appPackageName === 'google_pay') {
            appPackageName = 'gpay'; // razorpay returns package name as google_pay whearas in our implementation we have used it as gpay.
          }
          supportedAppsArray.push(appPackageName);
          return supportedAppsArray;
        },
        [],
      );
      this.setState({
        supportedIOSIntents,
      });
    });
  };

  renderShimmers = (isShimmerVisible) => {
    this.setState({
      isShimmerVisible,
    });
  };

  getPreferredPayments = () => {
    const { getSavedUpiFromServer } = this.props;
    getSavedUpiFromServer();
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  renderLoader = (isLoaderVisible) => {
    this.setState({
      isLoaderVisible,
    });
  };

  checkIfPaytmAccountExist = () => {
    const { checkPaytmBalance, cartItems, navigation } = this.props;

    if (Utility.isBlank(cartItems)) {
      navigation.replace('TabNavigator');
    }
    checkPaytmBalance();
    this.setState({
      refreshing: false,
    });
  };

  closeErrorModal = () => {
    this.setState({
      errorMessage: '',
    });
  };

  getSavedCardDetails = (payuParams) => {
    const {
      key,
      txnId,
      amount,
      firstName,
      email,
      environment,
      productInfo,
      surl,
      furl,
      userCredentials,
      phoneNumber,
      hashes: {
        paymentHash,
        saveUserCardHash,
        deleteUserCardHash,
        paymentRelatedDetailsHash,
        VASForMobileSDKHash,
      },
    } = payuParams;

    const payParams = {
      key,
      txnId,
      amount,
      productInfo,
      firstName,
      email,
      environment,
      surl,
      furl,
      userCredentials,
      phoneNumber,
      paymentHash,
      saveUserCardHash,
      deleteUserCardHash,
      paymentRelatedDetailsHash,
      VASForMobileSDKHash,
    };

    const { saveCardsInRedux } = this.props;

    RNPayuPayment.setPaymentParams(payParams)
      .then((res) => {
        const { availableSavedCards } = res;

        if (Utility.isPresent(availableSavedCards)) {
          this.setState({
            savedCards: availableSavedCards,
          });
        }

        this.renderShimmers(false);

        saveCardsInRedux(availableSavedCards);
        this.firePaymentMethodView(availableSavedCards);
      })
      .catch((e) => {
        this.renderShimmers(false);
      });
  };

  getIOSIntentDetails = (payuParams) => {
    const {
      key,
      txnId,
      amount,
      firstName,
      email,
      environment,
      productInfo,
      surl,
      furl,
      userCredentials,
      phoneNumber,
      hashes: {
        paymentHash,
        saveUserCardHash,
        deleteUserCardHash,
        paymentRelatedDetailsHash,
        VASForMobileSDKHash,
      },
    } = payuParams;

    const payParams = {
      key,
      txnId,
      amount,
      productInfo,
      firstName,
      email,
      environment,
      surl,
      furl,
      userCredentials,
      phoneNumber,
      paymentHash,
      saveUserCardHash,
      deleteUserCardHash,
      paymentRelatedDetailsHash,
      VASForMobileSDKHash,
    };

    this.setPaymentParamsForIOSIntent(payParams);
  };

  setPaymentParamsForIOSIntent = (payParams, callback) => {
    RNPayuPayment.setPaymentParamsForIOSIntent(payParams)
      .then((res) => {
        const { supportedIntents } = res;

        if (Utility.isPresent(supportedIntents)) {
          this.setState({
            supportedIOSIntents: supportedIntents,
          });
        }

        callback(true);
      })
      .catch((e) => {
        // callback(false);
      });
  };

  subscribeAppDetectEventListener = () => {
    const { availableApps } = this.props;

    let apps = [];
    this.appDetectEventListener.addListener('installedApps', (response) => {
      if (!this.app_detected) {
        if (!response) {
          AnalyticsManager.logEvent(
            EventType.appLifeCycleEvents.APP_DETECT_FAILURE,
          );
        } else {
          const upiApps = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.UPI_APPS);
          apps = Utility.getInstalledUpiApps(response, upiApps);
        }
        this.app_detected = true;
        this.appDetectEventListener.removeListener();
        availableApps(apps);
      }
    });

    setTimeout(NativeModules.AppDetect.getUpiReadyApps, 100);
  };

  openOrderSuccessPage = (orderResponse, orderPayload, payuParams) => {
    const {
      navigation,
      cartPricing,
      isContactLess,
      optForPlasticFreePackaging,
      cartItems = [],
      UtoP,
      authToken,
      setCongratsModalForEdge,
      membership_cohort = '',
      setCartNotification,
    } = this.props;
    const meta = {
      orderResponse,
      cartPricing,
      isContactLess,
      optForPlasticFreePackaging,
      cartItems,
      orderPayload,
      payuParams,
      UtoP,
      networkInfo: NetworkStats.networkDetails,
      authToken,
      membershipCohort: membership_cohort,
    };
    const { saveLocalNotificationData } = this.props;
    const numberOfCartItems = cartItems.length;
    const txn_amount = cartPricing?.total || '';

    try {
      AnalyticsUtilty.firePaymentStatus(meta);
      if (orderResponse && orderResponse.status === 'success') {
        const loyaltyPlan = cartItems.filter(
          (cartItem) => cartItem?.loyalty_plan?.type === 'loyalty_plan',
        );
        if (Utility.isPresent(loyaltyPlan)) {
          setCongratsModalForEdge(true);
        }
        AnalyticsUtilty.fireOrderConfirm(meta);
        if (this.source === SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE) {
          orderResponse?.skus?.map((routineDetails) => {
            const { name = '', price = '', id = '' } = routineDetails;
            const routineMeta = {
              [EventParameterKey.ROUTINES.ROUTINE_NAME]: name,
              [EventParameterKey.ROUTINES.ROUTINE_PRICE]: price,
              [EventParameterKey.ROUTINES.ROUTINE_ID]: id,
            };
            AnalyticsManager.logEvent(
              EventType.routine.ROUTINE_SUBSCRIBE,
              routineMeta,
            );
          });
        }

        this.stopTraces('success');
        if (orderResponse.order_status === 'confirmed') {
          const total = Utility.isPresent(orderResponse?.total)
            ? orderResponse?.total
            : cartPricing?.total;

          const link = Utility.appendUtmParamsWithUrl(
            ORDER_PAGE_URL,
            TRACKING_PARAMS.NOTIFICATION.PAYMENT_SUCCESS,
          );

          const payload = {
            title: 'Yay! Order confirmed',
            description: `Your order for ${numberOfCartItems} item(s) worth ₹ ${total} is confirmed. Happy shopping on ${Config.APP_NAME} 😊`,
            deepLink: link,
            image: images.cart_reminder.confirm_order,
            primary_cta: NOTIFICATION_ACTIONS.VIEW_ORDER_DETAILS.cta,
            primary_destination: link,
            secondary_cta: NOTIFICATION_ACTIONS.DISMISS.cta,
            secondary_destination: NOTIFICATION_ACTIONS.DISMISS.action,
            type: NOTIFICATION_TYPE.PAYMENT_SUCCESS,
            isLocal: true,
            receivedTime: new Date(),
            isClicked: false,
          };
          saveLocalNotificationData(payload);
          setCartNotification(CART_NOTIFICATION_STATES.paymentDone);
          /**
           * FIXME: As of now we dont want to show this in android.
           * Remove this if block once IOS live activities are live
           */
          if (Utility.isIOS()) {
            NotificationManager.createNotification(
              payload,
              NOTIFICATION_IDENTIFIERS.PAYMENT_SUCCESS,
            );
          }
        }
      } else {
        this.stopTraces('fail');

        const link = Utility.appendUtmParamsWithUrl(
          PAYMENT_METHODS_URL,
          TRACKING_PARAMS.NOTIFICATION.PAYMENT_FAILURE,
        );
        const payload = {
          title: 'Oh no! your payment failed :(',
          description: `We are usually bearing good news. But sadly your order for ${numberOfCartItems} item(s) worth INR ${cartPricing?.total} was not processed. Don't worry we have your cart saved `,
          deepLink: link,
          primary_cta: NOTIFICATION_ACTIONS.TRY_AGAIN.cta,
          primary_destination: link,
          secondary_cta: NOTIFICATION_ACTIONS.DISMISS.cta,
          secondary_destination: NOTIFICATION_ACTIONS.DISMISS.action,
          type: NOTIFICATION_TYPE.PAYMENT_FAILED,
          isLocal: true,
          receivedTime: new Date(),
          isClicked: false,
        };
        saveLocalNotificationData(payload);

        /**
         * FIXME: As of now we dont want to show this in android.
         * Remove this if block once IOS live activities are live
         */
        if (Utility.isIOS()) {
          NotificationManager.createNotification(
            payload,
            NOTIFICATION_IDENTIFIERS.PAYMENT_FAILED,
          );
        }
      }
    } catch (e) {
      AnalyticsManager.logEvent('exception_captured', {
        [EventParameterKey.SOURCE]: 'Payments.js: openOrderSuccessPage',
      });
      this.stopTraces('payment_exception');
    }

    navigation.navigate('OrderStatus', {
      orderResponse,
      retryPayment: () => this.debounceCreateOrder(orderPayload),
      source: this.source,
    });
  };

  setupPayuCallback = (payuPayload, extraData, orderPayload, payuParams) => {
    const { method, save = true } = extraData;
    const { cartPricing, UtoP, cartItems } = this.props;
    const meta = {
      payuPayload,
      orderPayload,
      extraData,
      cartItems,
      UtoP,
      payuParams,
      cartPricing,
      networkInfo: NetworkStats.networkDetails,
    };

    AnalyticsUtility.firePaymentInitiate(meta);
    if (method === 'upi') {
      RNPayuPayment.payWithUPI(payuPayload)
        .then((res) => {
          this.openOrderSuccessPage(res, orderPayload, payuParams);
        })
        .catch((e) => {
          this.stopTraces('cancel');
          //     this.openOrderSuccessPage(e, orderPayload, payuParams);
        });
    } else if (method === 'intent') {
      if (Utility.isAndroid()) {
        RNPayuPayment.payWithIntent(payuPayload.package_name);
      } else {
        RNPayuPayment.payWithIntentIOS(payuPayload.package_name);
      }
    } else if (method === 'card') {
      RNPayuPayment.payWithCard(payuPayload, save)
        .then((res) => {
          this.openOrderSuccessPage(res, orderPayload, payuParams);
        })
        .catch((e) => {
          this.stopTraces('cancel');
          // this.openOrderSuccessPage(e, orderPayload, payuParams);
        });
    } else if (method === 'netbanking') {
      RNPayuPayment.payWithNetBanking(payuPayload)
        .then((res) => {
          this.openOrderSuccessPage(res, orderPayload, payuParams);
        })
        .catch((e) => {
          this.stopTraces('cancel');
          //     this.openOrderSuccessPage(e, orderPayload, payuParams);
        });
    } else if (method === 'saved_card') {
      RNPayuPayment.payWithSavedCard(payuPayload)
        .then((res) => {
          this.openOrderSuccessPage(res, orderPayload, payuParams);
        })
        .catch((e) => {
          this.stopTraces('cancel');
          // this.openOrderSuccessPage(e, orderPayload, payuParams);
        });
    }
  };

  stopTraces = (source) => {
    if (this.trace_initiate_checkout_to_confirm) {
      this.trace_initiate_checkout_to_confirm.putAttribute('source', source);
      this.trace_initiate_checkout_to_confirm.stop();
    }
    if (this.trace_payment_initiate_to_confirm && this.tracePaymentInitiateToConfirmStarted) {
      this.trace_payment_initiate_to_confirm.putAttribute('source', source);
      this.trace_payment_initiate_to_confirm.stop();
    }
  };

  showErrorMessage = (message) => {
    this.setState({
      errorMessage: message,
    });
  };

  createOrderCallback = (
    success,
    payuParams,
    codData,
    status,
    payuPayload = {},
    extraData,
    retryPayload,
    paymentMethod,
    orderPayload,
  ) => {
    const { method } = extraData;
    const {
      navigation,
      cartPricing,
      isContactLess,
      optForPlasticFreePackaging,
      cartItems,
      UtoP,
    } = this.props;

    const meta = {
      payuPayload,
      orderPayload,
      extraData,
      cartItems,
      UtoP,
      payuParams,
      cartPricing,
      networkInfo: NetworkStats.networkDetails,
    };

    AnalyticsUtility.firePaymentInitiate(meta);

    this.renderLoader(false);

    if (!this.tracePaymentInitiateToConfirmStarted) {
      this.trace_payment_initiate_to_confirm.start();
      this.tracePaymentInitiateToConfirmStarted = true;
    }
    if (success) {
      const meta = {
        payuParams,
        codData,
        extraData,
        cartPricing,
        isContactLess,
        optForPlasticFreePackaging,
        cartItems,
      };
      const metaCod = {
        codData,
        cartItems,
        cartPricing,
      };
      if (Utility.isAndroid()) {
        const total = Utility.isPresent(codData?.total)
          ? codData?.total
          : cartPricing?.total;

        if (!Utility.isPresent(codData)) {
          const payload = {
            title: `${Config.APP_NAME} Order Update`,
            description: `Your payment for order ${
              cartItems?.length
            } item(s) worth INR ${total || ''} is in progress`,
            primary_cta: NOTIFICATION_ACTIONS.CHECK_STATUS.cta,
            primary_destination: NOTIFICATION_ACTIONS.CHECK_STATUS.action,
            secondary_cta: NOTIFICATION_ACTIONS.DISMISS.cta,
            secondary_destination: NOTIFICATION_ACTIONS.DISMISS.action,
            type: NOTIFICATION_TYPE.PAYMENT_IN_PROGESS,
          };

          /**
           * FIXME: As of now we dont want to show this in android.
           * Remove this if block once IOS live activities are live
           */
          if (Utility.isIOS()) {
            NotificationManager.createNotification(
              payload,
              NOTIFICATION_IDENTIFIERS.PAYMENT_IN_PROGESS,
            );
          }
        }
      }
      // TODO: AMAN
      // CartAndPaymentAnalytics.fireOrderCreateEvent(meta);
      AnalyticsUtilty.fireOrderCreateEvent(meta);
      if (status === 'success') {
        this.openOrderSuccessPage(codData, retryPayload);
        AnalyticsUtility.firePaymentInitiateCod(metaCod);
      } else if (method === 'TEZOMNI') {
        navigation.navigate('GooglePayProcessing', {
          payuPayload,
          payuParams,
          createOrder: this.debounceCreateOrder,
          retryPayment: () => this.debounceCreateOrder(retryPayload),
          openOrderSuccessPage: this.openOrderSuccessPage,
          method,
          retryPayload,
        });
      } else {
        this.setupPayu(payuParams, extraData, payuPayload, () =>
          this.setupPayuCallback(
            payuPayload,
            extraData,
            retryPayload,
            payuParams,
          ),
        );
      }
    } else if (
      Utility.isPresent(payuParams) &&
      Utility.isPresent(payuParams.errors)
    ) {
      const errorMessage = Utility.isPresent(payuParams.errors)
        ? payuParams.errors[0]
        : 'Something went wrong';
      AnalyticsManager.logEvent(EventType.order.ORDER_CREATE_ERROR, {
        [EventParameterKey.PAYMENT_METHOD]: paymentMethod,
        [EventParameterKey.ERROR]: errorMessage || 'Something went wrong',
      });
      this.showErrorMessage(errorMessage);
    }
  };

  setupPayu = (payuParams, extraData, payuPayload = {}, callback) => {
    const {
      key,
      txnId,
      amount,
      firstName,
      email,
      environment,
      productInfo,
      surl,
      furl,
      curl,
      userCredentials,
      phoneNumber,
      udf1,
      hashes: {
        paymentHash,
        saveUserCardHash,
        deleteUserCardHash,
        paymentRelatedDetailsHash,
        VASForMobileSDKHash,
      },
    } = payuParams;
    const payParams = {
      key,
      txnId,
      amount,
      productInfo,
      firstName,
      email,
      environment,
      surl,
      furl,
      userCredentials,
      phoneNumber,
      paymentHash,
      saveUserCardHash,
      deleteUserCardHash,
      paymentRelatedDetailsHash,
      VASForMobileSDKHash,
    };

    const { method } = extraData;

    if (Utility.isAndroid()) {
      RNPayuPayment.setPayuParamsForIntent(payParams)
        .then((res) => {})
        .catch((e) => {});

      RNPayuPayment.setPaymentParams(payParams)
        .then((res) => {
          callback(true);
        })
        .catch((e) => {
          callback(false);
        });
    } else if (method === 'intent') {
      this.setPaymentParamsForIOSIntent(payParams, callback);
    } else if (isWeb() && method === 'card') {
      const {
        cardNumber = '',
        cvv = '',
        expiryMonth = '',
        expiryYear = '',
      } = payuPayload;
      document.getElementById('key').value = key;
      document.getElementById('firstname').value = firstName;
      document.getElementById('email').value = email;
      document.getElementById('phone').value = phoneNumber;
      document.getElementById('hash').value = paymentHash;
      document.getElementById('surl').value = surl;
      document.getElementById('curl').value = curl;
      document.getElementById('furl').value = furl;
      document.getElementById('txnid').value = txnId;
      document.getElementById('udf1').value = udf1;
      document.getElementById('productinfo').value = productInfo;
      document.getElementById('amount').value = amount;
      document.getElementById('ccnum').value = cardNumber;
      document.getElementById('ccvv').value = cvv;
      document.getElementById('ccexpmon').value = expiryMonth;
      document.getElementById('ccexpyr').value = expiryYear;
      document.getElementById('payu-form').submit();
    } else {
      RNPayuPayment.setPaymentParams(payParams)
        .then((res) => {
          callback(true);
        })
        .catch((e) => {
          callback(false);
        });
    }
  };

  createOrderV2Callback = (
    response,
    orderPayload,
    paymentMethodDetails,
    extraData,
    retryPayload,
  ) => {
    const {
      navigation,
      cartPricing,
      isContactLess,
      cartItems,
      optForPlasticFreePackaging,
      UtoP,
    } = this.props;
    const meta = {
      upiParams: response,
      extraData,
      cartPricing,
      isContactLess,
      optForPlasticFreePackaging,
      cartItems,
      payload: paymentMethodDetails,
    };
    AnalyticsUtilty.fireOrderCreateEventUpi(meta);
    this.renderLoader(false);
    if (!this.tracePaymentInitiateToConfirmStarted) {
      this.trace_payment_initiate_to_confirm.start();
      this.tracePaymentInitiateToConfirmStarted = true;
    }

    if (this.payuEnabled) {
      setTimeout(() => {
        if (orderPayload.paymentMethod === 'intent') {
          const payuParams = response['payu_params'];
          this.setupPayu(payuParams, extraData, {}, () =>
            this.setupPayuCallback(
              paymentMethodDetails,
              extraData,
              retryPayload,
              payuParams,
            ),
          );
        } else {
          const payuParams = response['payu_params'];
          if (isWeb()) {
            const meta = {
              payuPayload: paymentMethodDetails,
              orderPayload,
              extraData,
              cartItems,
              UtoP,
              payuParams,
              cartPricing,
              networkInfo: NetworkStats.networkDetails,
            };
            AnalyticsUtility.firePaymentInitiate(meta);
          }
          if (
            isNative() &&
            Utility.isPresent(extraData?.details?.intent_url) &&
            Linking.canOpenURL(extraData?.details?.intent_url)
          ) {
            Linking.openURL(extraData?.details?.intent_url);
          }
        }
      }, 0);
    }

    navigateToScreen({
      navigation,
      type: isWeb() ? 'push' : 'navigate',
      screen: 'PaymentsWaiting',
      params: {
        paymentStatus: response,
        orderPayload,
        extraData,
        paymentMethodDetails,
      },
    });
  };

  createOrder = ({
    paymentGateway,
    paymentMethod,
    isSavedPaymentMethod,
    paymentMethodPayload,
    payuPayload,
    extraData,
    retry = false,
  }) => {
    this.renderLoader(true);

    const {
      address,
      createNewOrder,
      cartPricing: { total = '' } = {},
      createNewOrderV2,
      isNewPaymentJourney,
    } = this.props;
    let addressArray = [];
    if (!Utility.isBlank(address)) {
      addressArray = Object.keys(address).map((key) => ({
        id: key,
        name: address[key].name,
        pincode: address[key].pincode,
        line1: address[key].line1,
        line2: address[key].line2,
        contact_name: address[key].contact_name,
        default: address[key].default,
      }));
    }
    let defaultAddress = addressArray.filter((item) => item.default);
    if (Utility.isBlank(defaultAddress)) {
      defaultAddress = addressArray.splice(0, 1);
    }
    const addressId = defaultAddress.length > 0 ? defaultAddress[0].id : '';
    const orderPayload = {
      addressId,
      paymentGateway,
      paymentMethod,
      isSavedPaymentMethod,
      paymentMethodPayload,
      orderId: '',
      upgradableToPrepaid: false,
      isContactLessDelivery: false,
    };
    const retryPayload = {
      paymentGateway,
      paymentMethod,
      isSavedPaymentMethod,
      paymentMethodPayload,
      payuPayload,
      extraData,
      retry: true,
    };

    if (retry) {
      AnalyticsUtility.fireRetryPayment({
        paymentMethodPayload,
      });
    }
    try {
      AnalyticsUtility.firePaymentMethodClickEvent({
        orderPayload,
        extraData,
        total,
      });
    } catch (e) {}
    if (
      isNewPaymentJourney &&
      (paymentMethod === 'upi' ||
        paymentMethod === 'blinkbyemtel' ||
        paymentMethod === 'intent' ||
        (this.razorpayEnabled && paymentMethod === 'credit_card'))
    ) {
      createNewOrderV2(orderPayload, (success, response) => {
        if (!success) {
          const { errors = [] } = response;
          this.renderLoader(false);
          this.showErrorMessage(errors[0] || 'Something went wrong');
          AnalyticsManager.logEvent(EventType.order.ORDER_CREATE_ERROR, {
            [EventParameterKey.PAYMENT_METHOD]: paymentMethod,
            [EventParameterKey.ERROR]: errors[0] || 'Something went wrong',
          });
          return;
        }
        this.createOrderV2Callback(
          response,
          orderPayload,
          payuPayload,
          extraData,
          retryPayload,
        );
      });
      return;
    }
    createNewOrder(orderPayload, (success, payuParams, codData, status) => {
      this.createOrderCallback(
        success,
        payuParams,
        codData,
        status,
        payuPayload,
        extraData,
        retryPayload,
        paymentMethod,
        orderPayload,
      );
    });
  };

  updateExpandableComponent = (paymentOption) => {
    const { expandedStatus = '' } = this.state;
    const {
      UtoP: { isUpgradeToPrepaid = false } = {},
      cartPricing: { total = '' } = {},
    } = this.props;
    if (
      expandedStatus.includes('expanded') &&
      expandedStatus.includes(paymentOption)
    ) {
      this.setState({
        expandedStatus: `${paymentOption}_collapsed`,
      });
      AnalyticsUtility.firePaymentMethodCollapseEvent({
        paymentOption,
      });
    } else {
      AnalyticsUtility.firePaymentMethodExpandEvent({
        paymentOption,
        isUpgradeToPrepaid,
        total,
      });
      this.setState({
        expandedStatus: `${paymentOption}_expanded`,
      });
      pushInitiateCheckoutToGtm(6, 'Payment Method Expand');
    }
  };

  BottomComponent = () => (
    <>
      <View
        style={styles.bottomContainer}
        dataSet={{ media: ids.bottomContainer }}
      />
      <Image
        source={images.safePayments}
        style={PaymentStyles.safePaymentImage}
        dataSet={{ media: ids.safePaymentImage }}
      />
    </>
  );

  firePaymentMethodView = (props = {}) => {
    const { cartPricing, UtoP, authToken } = this.props;
    const { savedCards = {} } = props;
    const paymentMeta = {
      savedCards,
      cartPricing,
      UtoP,
      networkInfo: NetworkStats.networkDetails,
      authToken,
    };
    AnalyticsUtilty.firePaymentMethodView(paymentMeta);
  };

  createRef = (ref) => {
    this.toast = ref;
  };

  dismissPayment = () => {
    const { navigation } = this.props;
    navigation.popToTop();
  };

  onRefresh = () => {
    this.setState({
      refreshing: true,
    });
    this.checkIfPaytmAccountExist();
  };

  onFocus = () => {
    this.checkIfPaytmAccountExist();
  };

  render() {
    const {
      paytmDetails,
      cartPricing: { total = '', total_for_cod = '' } = {},
      UtoP,
      isCod,
      savedCards,
      navigation,
      addedToCartProduct,
      UtoP: { isUpgradeToPrepaid = false },
      digital_cart = false,
      semi_digital_cart,
    } = this.props;
    const {
      expandedStatus,
      isLoaderVisible,
      refreshing,
      isShimmerVisible = false,
      supportedIOSIntents,
      errorMessage,
    } = this.state;

    return (
      <View style={PaymentStyles.bottomComponent}>
        <StaticNavigationHeader
          title={
            !isCod
              ? isUpgradeToPrepaid
                ? `Pay online`
                : `Total Amount: ${getPriceText(total)}`
              : `Total Amount: ${getPriceText(total_for_cod)}`
          }
          addedToCartProduct={addedToCartProduct}
        />
        <KeyboardAwareScrollView
          innerRef={(c) => {
            this.scroll = c;
          }}
          showsVerticalScrollIndicator={false}
          enableAutomaticScroll
          enableOnAndroid
          extraScrollHeight={190}
          keyboardShouldPersistTaps='handled'
          style={[
            PaymentStyles.container,
            { paddingBottom: Utility.bottomInset },
          ]}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={this.onRefresh}
            />
          }
        >
          <View
            style={styles.innerContainer}
            dataSet={{ media: ids.innerContainer }}
          >
            <View
              style={styles.leftContainer}
              dataSet={{ media: ids.leftContainer }}
            >
              {this.showOrderSummary && !isUpgradeToPrepaid && <OrderSummary />}
              <PreferredPayments
                updateExpandableComponent={this.updateExpandableComponent}
                expandedStatus={expandedStatus}
                createOrder={this.debounceCreateOrder}
                paytmDetails={paytmDetails}
                renderLoader={this.renderLoader}
                isShimmerVisible={isShimmerVisible}
                savedCards={savedCards}
              />
              <Payment
                updateExpandableComponent={this.updateExpandableComponent}
                expandedStatus={expandedStatus}
                createOrder={this.debounceCreateOrder}
                paytmDetails={paytmDetails}
                UtoP={UtoP}
                supportedIOSIntents={supportedIOSIntents}
                renderLoader={this.renderLoader}
                hideCashOnDelivery={digital_cart}
                semi_digital_cart={semi_digital_cart}
              />
            </View>
            <View
              style={styles.rightContainer}
              dataSet={{ media: ids.rightContainer }}
            >
              <View
                style={styles.addressContainer}
                dataSet={{ media: ids.addressContainer }}
              >
                {isDesktop() && (
                  <Text style={styles.addressTitle}>Shipping Address</Text>
                )}
                <View
                  style={styles.addressInnerContainer}
                  dataSet={{ media: ids.addressInnerContainer }}
                >
                  <Address />
                  <DebouncedTouchableOpacity
                    {...this.props}
                    onPress={() => {
                      navigation.push('Address');
                    }}
                    style={{ position: 'absolute', top: 16, right: 12 }}
                    hitSlop={Utility.getHitSlop()}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: 'Roboto-Medium',
                        color: colors.cta.lightBlue,
                      }}
                    >
                      Change
                    </Text>
                  </DebouncedTouchableOpacity>
                </View>

                {!isUpgradeToPrepaid && <CartPricing />}
              </View>
              <this.BottomComponent />
            </View>
          </View>
        </KeyboardAwareScrollView>
        {isLoaderVisible && (
          <>
            <View style={CartStyles.cartLoadingOverlay}>
              <ActivityIndicator animating color='red' size='large' />
            </View>
          </>
        )}
        <Toast style={CartStyles.toast} ref={this.createRef} />
        <FoxyAlert
          isVisible={Utility.isPresent(errorMessage)}
          hideSecondButton
          alertBoxTitle='Oops'
          alertMessage={errorMessage}
          firstButtonTitle='Okay'
          firstButtonOnPress={this.closeErrorModal}
          onTapOutside={this.closeErrorModal}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showImage
          isAbsolute
        />
        <PayuForm />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
  paytmDetails: state.UserAccountInfo.paytmDetails,
  savedCards: state.UserAccountInfo.checkoutDetails.savedCards,
  cartPricing: state.bag.cartPricing,
  isCod: state.bag.cod,
  cartItems: state.bag.cartItems,
  isNewPaymentJourney: state.bag.isNewPaymentJourney,
  UtoP: state.bag.UtoP,
  isContactLess: state.bag.isContactLess,
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  addedToCartProduct: state.bag.addedToCartProduct,
  digital_cart: state.bag.cartPricing.digital_cart,
  semi_digital_cart: state.bag.cartPricing.semi_digital_cart,
  membership_cohort: state.todayDeals.membership_cohort,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      createNewOrder,
      checkPaytmBalance,
      getCardDetails,
      setUpgradeToPrepaid,
      setCurrentPaymentFlow,
      createNewOrderV2,
      saveCardsInRedux,
      getSavedUpiFromServer,
      availableApps,
      setCongratsModalForEdge,
      saveLocalNotificationData,
      setCartNotification,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Payments),
);

const { ids, styles } = StyleSheet.create({
  innerContainer: {
    flex: 1,
    '@media (min-width: 800px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 30,
    },
  },
  leftContainer: {
    '@media (min-width: 800px)': {
      width: 636,
    },
  },
  rightContainer: {
    '@media (min-width: 800px)': {
      width: 432,
    },
  },
  bottomContainer: {
    backgroundColor: colors.background,
    height: 30,
    width: Utility.getScreenWidth(),
    '@media (min-width: 800px)': {
      width: '100%',
      paddingHorizontal: 40,
    },
  },
  safePaymentImage: {
    '@media (min-width: 800px)': {
      width: '100%',
    },
  },
  addressContainer: {
    marginTop: 16,
    width: '100%',
    '@media (min-width: 800px)': {
      marginTop: 0,
    },
  },
  addressTitle: {
    paddingVertical: 12,
    marginTop: 4,
    fontSize: 16,
    color: '#979BAA',
    fontFamily: 'Roboto-Medium',
    fontWeight: '800',
  },
  addressInnerContainer: {
    paddingLeft: 16,
    backgroundColor: colors.white,
    '@media (min-width: 768px)': {
      borderRadius: 8,
    },
  },
  emptyView: {
    height: 100,
  },
});
