// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { adSchema } from '../../config/Schema';

// Components
import { AdCard } from '../../components/ad';
import { withMaybe } from '../../lib/Monads';

class Advertisement extends Component {
  itemDataNullFn = props => !props.itemData;

  AdCardWithCondition = withMaybe(this.itemDataNullFn)(AdCard);

  render() {
    const { itemData } = this.props;
    return <this.AdCardWithCondition {...this.props} itemData={itemData} />;
  }
}

const mapStateToProps = (store, ownProps) => ({
  itemData: denormalize(store.data.entities.ads[ownProps.id], adSchema, store.data.entities),
});
export default connect(mapStateToProps)(Advertisement);
