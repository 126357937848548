import React, { useEffect, useRef, useState } from 'react';
import { View, Text, ScrollView, Linking } from 'react-native';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInfluencerDetails,
  getProAccountLinks,
} from '../../actions/InfluencerActions';
import { ids, styles } from './styles';
import DashboardInfoCard from './DashboardInfoCard';
import { useNavigation } from '@react-navigation/native';
import DashboardOneLinkCard from './DashboardOneLinkCard';
import DashboardSocialMediaHandlesCard from './DashboardSocialMediaHandlesCard';
import Toast from 'react-native-easy-toast';
import DashboardInviteLinkCard from './DashboardInviteLinkCard';
import DashboardContactUsCard from './DashboardContactUsCard';
import { getProAccountObjectForId } from './OneLinkConfig';
import DashboardContentLinksCard from './DashboardContentLinksCard';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import DashboardProductLinksCard from './DashboardProductLinksCard';
import { AnalyticsManager, EventType } from '../../analytics';

export default function Dashboard() {
  const navigation = useNavigation();
  const toastRef = useRef(null);
  const handle =
    useSelector((state) => state.UserAccountInfo.profile.handle) || '';
  const referralUrl =
    useSelector((state) => state.UserAccountInfo.profile.pro_referral_url) ||
    '';
  const [influencerData, setInfluencerData] = useState({});
  const [proAccountLinks, setProAccountLinks] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.INFLUENCER_DASHBOARD_VIEW, {
      foxy_handle: handle,
    });
    fetchInfluencerDetails();
    fetchProAccountLinks();
  }, []);

  const showCopiedToClipboard = () => {
    toastRef.current.show('Copied to clipboard.', 2000);
  };

  const fetchInfluencerDetails = () => {
    if (isPresent(handle)) {
      dispatch(
        getInfluencerDetails(handle, (success, response) => {
          setInfluencerData(response);
        }),
      );
    }
  };

  const fetchProAccountLinks = () => {
    dispatch(
      getProAccountLinks((success, response) => {
        if (success) {
          setProAccountLinks(response);
        }
      }),
    );
  };

  const navigateToHandleSelection = () => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.DASHBOARD_EDIT_PROFILE_CLICK, {
      handle,
    });
    navigation.navigate('HandleSelection', { source: 'dashboard' });
  };

  const navigateToAddSocialProfiles = () => {
    navigation.navigate('AddSocialProfiles', { source: 'dashboard' });
  };

  const handleRouteFromLink = (route, slug, path, extra) => {
    navigation.navigate(route, { slug, extra });
  };

  const handleDeeplink = (url) => {
    DynamicLinkManager.handleDynamicLink(url, handleRouteFromLink);
  };

  if (isBlank(influencerData)) return null;

  const {
    name = '',
    bio = '',
    influencer_tags = [],
    influencer_tag_colors = [],
    pro_accounts = [],
    image_url = '',
  } = influencerData;
  const {
    content_links_list: { objects: contentLinks } = {},
    product_links_list: { objects: productLinks } = {},
  } = proAccountLinks;

  const instagramHandle =
    getProAccountObjectForId(pro_accounts, 1)?.user_input || '';
  return (
    <>
      <ScrollView>
        <View
          style={styles.dashboardContainer}
          dataSet={{ media: ids.dashboardContainer }}
        >
          <Text
            style={[
              styles.registrationCompletedHeading,
              { marginBottom: 24, paddingHorizontal: 0 },
            ]}
          >
            Hi {name}
          </Text>
          <DashboardInfoCard
            name={name}
            bio={bio}
            influencer_tags={influencer_tags}
            influencer_tag_colors={influencer_tag_colors}
            onEditPress={navigateToHandleSelection}
            image_url={image_url}
          />
          <View style={styles.dashboardSeparator} />
          <DashboardOneLinkCard
            instagramHandle={instagramHandle}
            onEditPress={navigateToHandleSelection}
          />
          <View style={styles.dashboardSeparator} />
          <DashboardSocialMediaHandlesCard
            pro_accounts={pro_accounts}
            onEditPress={navigateToAddSocialProfiles}
            refresh={fetchInfluencerDetails}
          />
          <View style={styles.dashboardSeparator} />
          {/* <DashboardContentLinksCard
            contentLinks={contentLinks}
            handleDeeplink={handleDeeplink}
          />
          <View style={styles.dashboardSeparator} />
          <DashboardProductLinksCard
            productLinks={productLinks}
            handleDeeplink={handleDeeplink}
            refresh={fetchProAccountLinks}
          />
          <View style={styles.dashboardSeparator} /> */}
          <DashboardInviteLinkCard
            referralUrl={referralUrl}
            showCopiedToClipboard={showCopiedToClipboard}
            refresh={fetchProAccountLinks}
            handle={handle}
          />
          <View style={styles.dashboardSeparator} />
          <DashboardContactUsCard />
          <View style={styles.dashboardSeparator} />
        </View>
      </ScrollView>
      <Toast style={{ position: 'absolute', bottom: 20 }} ref={toastRef} />
    </>
  );
}
