// Dependencies
import React from 'react';
import { Image, TouchableOpacity } from 'react-native';

// Components, Styles ,...
import PropTypes from 'prop-types';
import Utility from '../utils/Utility';
import FastImageView from '../components/FastImageView';

const FoxyIcon = (props) => {
  const {
    height,
    width,
    marginRight,
    borderRadius,
    borderColor,
    backgroundColor,
    onPress,
    icon,
    disableIcon,
    borderWidth,
    tintColor,
    justifyContent,
    alignItems,
    iconHeight = 16,
    iconWidth = 16,
    padding = 0,
  } = props;

  return (
    <TouchableOpacity
      style={{
        height,
        width,
        marginRight,
        backgroundColor,
        borderRadius,
        borderColor,
        borderWidth,
        justifyContent,
        alignItems,
      }}
      disabled={disableIcon}
      onPress={onPress}
    >
      <FastImageView
        source={icon}
        style={{ tintColor, height: iconHeight, width: iconWidth }}
      />
    </TouchableOpacity>
  );
};

FoxyIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  marginRight: PropTypes.number,
  borderRadius: PropTypes.number,
  borderColor: PropTypes.number,
  backgroundColor: PropTypes.string,
  borderWidth: PropTypes.number,
  disableIcon: PropTypes.bool,
  onPress: PropTypes.func,
  tintColor: PropTypes.string,
  icon: PropTypes.any, // TODO: Suitable proptype for icon
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
};

FoxyIcon.defaultProps = {
  height: 30,
  width: 30,
  marginRight: 0,
  borderRadius: 20,
  borderColor: 'transparent',
  borderWidth: 0.5,
  backgroundColor: '#404040',
  disableIcon: false,
  onPress: () => {},
  justifyContent: 'center',
  alignItems: 'center',
  imageHeight: 30,
  imageWidth: 30,
};

export default FoxyIcon;
