import React, { PureComponent } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Image,
} from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import FastImageView from '../../FastImageView';
import _ from 'lodash';
import Utility from '../../../utils/Utility';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { isWeb } from '../../../utils/BooleanUtility';

class RoutineCalenderCard extends PureComponent {
  dashedLine = () => {
    return (
      <View style={styles.dashedBorder}>
        <View style={styles.borderMaskContainer}>
          <View style={styles.borderMask} />
        </View>
      </View>
    );
  };

  headerContainer = () => {
    const { selectedDay, numberOfDays } = this.props;
    const dayText =
      selectedDay !== 0 ? `Day ${selectedDay}/${numberOfDays}` : 'Your results';
    return (
      <View style={styles.headerContainer}>
        <this.dashedLine />
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{dayText}</Text>
        </View>
        {/* <TouchableOpacity style={styles.calenderIconContainer}>
          <FastImageView
            source={images.routines.calender_icon}
            style={styles.calenderIcon}
          />
        </TouchableOpacity> */}
      </View>
    );
  };

  renderDay = ({ index, selectedDay }) => {
    const { isRoutineActive } = this.props;
    const item = isRoutineActive ? index + 1 : index;
    if (!isRoutineActive && item === 0) {
      return (
        <FastImageView source={images.routines.trophy} style={styles.image} />
      );
    }

    const dayTitle =
      selectedDay === item
        ? styles.selectedDayTitle
        : styles.unselectedDayTitle;
    const daySubtitle =
      selectedDay === item
        ? styles.selectedDaySubtitle
        : styles.unselectedDaySubtitle;
    return (
      <>
        <Text style={dayTitle}>Day</Text>
        <Text style={daySubtitle}>{item}</Text>
      </>
    );
  };

  renderDays = (index, selectedDay, dayContainerWidth) => {
    const { isRoutineActive } = this.props;
    const item = isRoutineActive ? index + 1 : index;
    const { onChangeDay } = this.props;
    const dayContainer =
      selectedDay === item
        ? styles.selectedDayContainer
        : styles.unselectedDayContainer;

    return (
      <>
        <TouchableWithoutFeedback onPress={() => onChangeDay(item)}>
          <View
            style={{
              ...dayContainer,
              width: dayContainerWidth,
            }}
          >
            <this.renderDay index={index} selectedDay={selectedDay} />
          </View>
        </TouchableWithoutFeedback>
      </>
    );
  };

  render() {
    const { selectedDay, numberOfDays, isRoutineActive } = this.props;
    const totalDays = isRoutineActive ? numberOfDays : numberOfDays + 1;
    const actualTotalWidthOfCards =
      Utility.getScreenWidth() - 32 - (totalDays - 1) * 16;
    const calculatedWidth = actualTotalWidthOfCards / totalDays;
    const isMoreThanMinWidth = calculatedWidth > 37;
    const dayContainerWidth = isMoreThanMinWidth ? calculatedWidth : 37;
    return (
      <View style={styles.container}>
        <this.headerContainer />
        <FlatList
          data={new Array(totalDays)}
          renderItem={_.memoize(
            ({ item, index }) =>
              this.renderDays(index, selectedDay, dayContainerWidth),
            () => [selectedDay],
          )}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.daysList}
          keyExtractor={(item, index) => `${index}`}
        />
      </View>
    );
  }
}

export default RoutineCalenderCard;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingVertical: 8,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginTop: 12,
    marginBottom: 16,
  },
  title: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    flex: 1,
    paddingHorizontal: 8,
    marginTop: -8,
    backgroundColor: colors.background,
  },
  titleContainer: {
    position: 'absolute',
    zIndex: 1,
  },
  dashedBorder: {
    height: 1,
    flex: 1,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.foxyBlack,
    borderStyle: 'dashed',
  },
  borderMaskContainer: {
    top: 0,
    width: '100%',
    height: 1,
    backgroundColor: colors.white,
    zIndex: 2,
  },
  borderMask: {
    flex: 1,
    backgroundColor: colors.routine_green_bg,
  },
  calenderIcon: {
    width: 22,
    height: 24,
    resizeMode: 'contain',
  },
  calenderIconContainer: {
    width: 42,
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 21,
    backgroundColor: colors.white,
    marginLeft: 12,
    shadowColor: colors.black,
    shadowOffset: { width: 4, height: 4 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
  },
  selectedDayContainer: {
    backgroundColor: colors.ultraDarkGreen,
    paddingVertical: 8,
    marginHorizontal: 8,
    width: 37,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 24,
    width: 24,
  },
  unselectedDayContainer: {
    paddingVertical: 8,
    width: 37,
    borderRadius: 20,
    marginHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedDaySubtitle: {
    color: isWeb() ? colors.ultraDarkGreen : colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
    marginTop: 12,
  },
  unselectedDaySubtitle: {
    color: colors.black,
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
    marginTop: 12,
  },
  unselectedDayTitle: {
    color: colors.subtitle,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
  },
  selectedDayTitle: {
    color: isWeb() ? colors.ultraDarkGreen : colors.white,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
  },
  daysList: {
    marginBottom: 12,
    marginHorizontal: 8,
  },
});
