import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import { Platform, NativeModules } from 'react-native';
import {
  GET_FAQ_DATA,
  STORE_FAQ_LIKE_AND_DISLIKE_DATA,
  ADD_ORDER_REQUEST_DETAIL,
  GET_CONTACT_US_REASON,
  GET_RETURN_REPLACE_REASON,
  ADD_RETURN_REPLACE_REASON_ID,
  GET_CANCEL_REASON,
  GET_SHIPMENT_TRACKING_DETAILS,
  UPDATE_ORDER_REQUEST_DETAIL,
  GET_ACTIVE_CALLBACK,
} from '../actions/ActionTypes';

import Utility from '../utils/Utility';
import _ from 'lodash';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import {
  FAQ_SECTION_URL,
  FAQ_LIKE_AND_DISLIKE_URL,
  SHIPMENT_TRACKING_DETAILS,
  POST_ORDER_REQUEST_DETAIL,
  API_DOMAIN,
  CONTACT_US_REASON,
  RETURN_REPLACE_REASON,
  ORDER_URL,
  CANCEL_REASON,
  GET_ACTIVE_CALLBACK_DETAILS,
  URL,
} from '../config/Constants';

function* fetchFaqSectionData(action) {
  const { callback = () => {}, slug } = action;
  let url = `${FAQ_SECTION_URL}`;
  if (slug) {
    url = `${API_DOMAIN}/${slug}`;
  }
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json, response);
    } else {
      callback(false, {}, response.status);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* faqLikeData(action) {
  const { slug, actionType, callback } = action;
  let url = `${FAQ_LIKE_AND_DISLIKE_URL}`;
  if (actionType === 'like') {
    url = `${url}/${slug}/like`;
  } else if (actionType === 'dislike') {
    url = `${url}/${slug}/dislike`;
  }
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        id: slug,
      }),
    });
    const json = yield convertJsonFromResponse(response);
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      callback(true, {});
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

function* addOrderRequestDetail(action) {
  const {
    data: {
      orderId = '',
      callRequestTime = '',
      callBackRequestReasonId,
      reason,
      email,
      return_request_id,
    },
    callback = () => {},
  } = action;
  const url = `${POST_ORDER_REQUEST_DETAIL}`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        order_id: orderId,
        requested_at: callRequestTime,
        callback_request_reason_id: callBackRequestReasonId,
        remarks: reason,
        email,
        return_request_id,
      }),
    });

    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        callback(true, json);
      }
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

function* fetchContactUsReasons(action) {
  const { entityType = '', entityId = '', callback = () => {} } = action;
  const url = `${CONTACT_US_REASON}?${entityType}_id=${entityId}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json, response);
    } else {
      callback(false, {}, response.status);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* fetchReturnReplaceReasons(action) {
  const { callback = () => {} } = action;
  const url = `${RETURN_REPLACE_REASON}`;

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json, response);
    } else {
      callback(false, {}, response.status);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* addReturnReplaceReasonId(action) {
  const { reasonId = '', entityId, entityType, callback = () => {} } = action;
  const url = `${URL.RETURN_REQUEST}`;
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        return_request: { return_reason_id: reasonId },
        entity_id: entityId,
        entity_type: entityType,
      }),
    });
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        callback(true, json);
      }
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

function* fetchCancelReasons(action) {
  const { callback = () => {} } = action;
  const url = `${CANCEL_REASON}`;

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json, response);
    } else {
      callback(false, {}, response.status);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* fetchCustomerOrderStatus(action) {
  const { callback = {}, id } = action;
  let url = `${SHIPMENT_TRACKING_DETAILS}`;
  if (id) {
    url = `${url}/${id}/shipment_tracking_details`;
  }
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json, response);
    } else {
      callback(false, {}, response.status);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* updateOrderRequestDetail(action) {
  const {
    data: { callRequestTime = '', email, callBackRequestId },
    callback = () => {},
  } = action;
  let url = `${POST_ORDER_REQUEST_DETAIL}`;
  let body = {};
  if (Utility.isPresent(callBackRequestId)) {
    url = `${url}/${callBackRequestId}`;
    body = { requested_at: callRequestTime, email };
  } else {
    body = {
      order_id: '',
      requested_at: callRequestTime,
      remarks: 'I need a callback from customer support.',
      email,
      return_request_id: '',
    };
  }
  const method = Utility.isPresent(callBackRequestId) ? 'PATCH' : 'POST';
  try {
    const response = yield fetch(url, {
      method: method,
      headers: yield getApiHeaders(),
      body: JSON.stringify(body),
    });

    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        callback(true, json);
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

function* fetchActiveCallBack(action) {
  const { callback = {}, orderId = '', requestReasonId = '' } = action;
  const url = `${GET_ACTIVE_CALLBACK_DETAILS}?order_id=${orderId}&callback_request_reason_id=${requestReasonId}&active=true`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json, response);
    } else {
      callback(false, {}, response.status);
    }
  } catch (error) {
    callback(false, {});
  }
}

export default function* watchFetchFaqDetails() {
  yield takeLatest(GET_FAQ_DATA, fetchFaqSectionData);
  yield takeLatest(STORE_FAQ_LIKE_AND_DISLIKE_DATA, faqLikeData);
  yield takeLatest(ADD_ORDER_REQUEST_DETAIL, addOrderRequestDetail);
  yield takeLatest(GET_CONTACT_US_REASON, fetchContactUsReasons);
  yield takeLatest(GET_RETURN_REPLACE_REASON, fetchReturnReplaceReasons);
  yield takeLatest(ADD_RETURN_REPLACE_REASON_ID, addReturnReplaceReasonId);
  yield takeLatest(GET_CANCEL_REASON, fetchCancelReasons);
  yield takeLatest(GET_SHIPMENT_TRACKING_DETAILS, fetchCustomerOrderStatus);
  yield takeLatest(UPDATE_ORDER_REQUEST_DETAIL, updateOrderRequestDetail);
  yield takeLatest(GET_ACTIVE_CALLBACK, fetchActiveCallBack);
}
