import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { renderOnboarding } from '../../actions/ActionTypes';
import Utility from '../../utils/Utility';
import FastImageView from '../../components/FastImageView';
import colors from '../../theme/Colors';
import ScaleAnimate from '../../components/shared/ScaleAnimate';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import NavigationService from '../../navigator/NavigationService';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

class VerticalBannerCard extends PureComponent {
  static getComponentHeight({ display = '' }) {
    return (1 / 3) * (Utility.getScreenWidth() - 24);
  }

  itemDataNullFn = (props) => !props.itemData;

  navigateToScreen = (path, destination) => {
    const { navigation } = this.props;
    const newDestination = destination;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: path,
      params: {
        newDestination, // webview reads data from destination
        slug: newDestination,
      },
    });
  };

  onActionPressed = () => {
    const {
      navigation,
      itemData,
      previousScreen,
      listIndex = 0,
      itemData: {
        destination,
        dynamic_destination,
        slug,
        id,
        external_link = '',
      } = {},
      allVideosId = '',
      navigateToOfferDetail,
      authToken,
      listData: {
        id: listId = '',
        display: listDisplay = '',
        slug: listSlug = '',
      } = {},
      listContent,
      listName,
      index,
      extraEventParameters = {},
    } = this.props;
    let url = Utility.isBlank(destination) ? listData?.slug : destination;
    url = Utility.isPresent(external_link) ? external_link : url;

    const listNames = url.split('/')[url.split('/').length - 1];
    if (Utility.isPresent(itemData)) {
      const { type } = itemData;
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        listNames,
        index,
        listId,
        listDisplay,
        listName,
        listIndex,
        '',
        '',
        listContent,
        id,
        slug,
        listSlug,
        extraEventParameters,
      );
    }

    DynamicLinkManager.handleDynamicLink(
      url,
      this.navigateToScreen,
      destination,
    );
  };

  render() {
    const { itemData = {} } = this.props;
    const { image_webp_url = '' } = itemData;

    return (
      <ScaleAnimate {...this.props} onPress={this.onActionPressed}>
        <View>
          <FastImageView
            resizeMode='stretch'
            source={image_webp_url}
            style={styles.image}
          />
          <View style={styles.textContainer}>
            <Text style={styles.text}>
              EXPLORE
            </Text>
          </View>
        </View>
      </ScaleAnimate>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      renderOnboarding,
    },
    dispatch,
  ),
});

const mapStateToProps = (store) => ({
  authToken: store.UserAccountInfo.authToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(VerticalBannerCard);

const dividingFactor = isDesktop() ? 1.8 * 2.6 : 1.8;

const styles = StyleSheet.create({
  image: {
    height: (Utility.getScreenWidth() / dividingFactor) * 1.2,
    width: Utility.getScreenWidth() / dividingFactor,
    overflow: 'hidden',
    borderColor: colors.border,
    backgroundColor: 'transparent',
    marginRight: 12,
  },
  textContainer: {
    height: 44,
    backgroundColor: colors.foxyBlack,
    width: Utility.getScreenWidth() / dividingFactor,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  text: {
    color: colors.white,
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
  }
});
