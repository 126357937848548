import _ from 'lodash';
import * as types from '../actions/UserInteractionsActions';
import {
  UPDATE_WISHLIST_TOOLTIP,
  ADD_LAST_LIKE,
  WISHLIST_CROSS_CLICKED,
  CLEAR_WISHLIST_TOOLTIP,
} from '../actions/ActionTypes';
import initialState from './initialState';
import { isBlank } from '../utils/BooleanUtility';

export default function userInteractions(
  state = initialState.userInteractions,
  action,
) {
  if (isBlank(action.data)) {
    if (action.type === types.LOGOUT_USER) {
      return {
        ...state,
        userInteractions: {
          productViews: [],
          myBrowsing: [],
        },
      };
    }
    if (action.type === ADD_LAST_LIKE) {
      return {
        ...state,
        lastLike: new Date(),
      };
    }

    if (action.type === WISHLIST_CROSS_CLICKED) {
      return {
        ...state,
        isWishlistCrossClicked: true,
      };
    }

    if (action.type === CLEAR_WISHLIST_TOOLTIP) {
      return {
        ...state,
        wishlistTooltip: [],
        wishlistTooltipResetAt: new Date(),
      };
    }

    return state;
  }

  switch (action.type) {
    case types.ADD_PRODUCT_ID_IN_PRODUCT_VIEWS:
      if (isBlank(action?.data?.id)) {
        return state;
      }
      return {
        ...state,
        productViews: state.productViews.includes(action?.data?.id)
          ? state.productViews
          : [action?.data?.id, ...state.productViews],
      };

    case types.ADD_MY_BROWSING:
      const { data = [] } = action;
      const isPresentInMyBrowsing = _.find(
        state.myBrowsing,
        (e) => e.id === data.id,
      );

      return {
        ...state,
        myBrowsing: isPresentInMyBrowsing
          ? state.myBrowsing
          : [...state.myBrowsing, data],
      };

    case UPDATE_WISHLIST_TOOLTIP:
      if (isBlank(action?.data?.id)) {
        return state;
      }
      return {
        ...state,
        wishlistTooltip: state.wishlistTooltip.includes(action?.data?.id)
          ? state.wishlistTooltip
          : [action?.data?.id, ...state.wishlistTooltip],
      };

    default:
      return state;
  }
}
