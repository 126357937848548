import { Text, StyleSheet, View, TouchableOpacity } from 'react-native';
import React, { PureComponent } from 'react';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import FastImageView from '../../components/FastImageView';
import images from '../../theme/Images';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { StaticNavigationHeader } from '../../components/shared';
import { Linking, Platform } from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { StackActions } from '@react-navigation/native';
import { isWeb } from '../../utils/BooleanUtility';

class ConfirmRequestPage extends PureComponent {
  constructor(props) {
    super(props);
    const { navigation, route } = this.props;
    this.headerTitle = route.params?.headerTitle;
    this.heading = route.params?.heading;
    this.subHeading = route.params?.subHeading;
  }

  componentDidMount() {
    AnalyticsManager.logEvent('page_load_2', {
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.CONFIRM_REQUEST_PAGE,
      [EventParameterKey.SOURCE]: this.headerTitle,
    });
  }

  onPressContactUs = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.SOURCE]: this.headerTitle,
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.CONFIRM_REQUEST_PAGE,
      [EventParameterKey.CTA]: 'contact_us',
    });
    Linking.openURL('mailto:hello@foxy.in');
  };

  contactUsSection = () => {
    return (
      <View style={styles.contactUsContainer}>
        <View style={styles.subContainerStyle}>
          <Text style={styles.boldTextStyle}>
            Here's what else you can do -
          </Text>
          <Text style={styles.subHeadingStyle}>
            You can email us at hello@foxy.in and we’ll get our customer care
            team to deep dive into your issue
          </Text>
          <TouchableOpacity onPress={this.onPressContactUs}>
            <Text style={styles.blueTextButton}> CONTACT US</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  onPressActionButton = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.SOURCE]: this.headerTitle,
      [EventParameterKey.PAGE]: SCREEN_CONSTANTS.CONFIRM_REQUEST_PAGE,
      [EventParameterKey.CTA]: 'Submit',
    });
    if (isWeb()) {
      navigation.replace('TabNavigator');
      return;
    }
    navigation.navigate('Feed');
  };

  onBackPress = () => {
    const { navigation } = this.props;
    navigation.navigate('OrdersHistory');
  };

  render() {
    return (
      <View>
        <StaticNavigationHeader
          onBackPress={this.onBackPress}
          title={this.headerTitle}
        />
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <FastImageView
              source={images.confirm_request_image}
              style={styles.imageStyle}
              resizeMode='contain'
            />
            <Text style={styles.boldText}>{this.heading}</Text>
            <Text style={styles.subHeading}>{this.subHeading}</Text>
          </View>
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 24}
              title={'Continue Shopping'}
              textStyle={{ color: colors.foxyBlack }}
              onPress={this.onPressActionButton}
              style={styles.button}
              firstColor={colors.white}
              secondColor={colors.white}
              underlayColor={colors.lightGrey}
            />
          </View>
        </View>
        <this.contactUsSection />
      </View>
    );
  }
}

export default ConfirmRequestPage;

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth(),
    height: 326,
    backgroundColor: colors.white,
    alignSelf: 'center',
  },
  subContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  imageStyle: {
    width: Utility.getScreenWidth() - 48,
    height: 120,
    marginTop: 24,
  },
  boldText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginTop: 30,
  },
  subHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    paddingTop: 8,
    textAlign: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingTop: 12,
    bottom: 0,
    paddingBottom: 20,
  },
  button: {
    height: 48,
    borderWidth: 1,
    borderColor: colors.black,
    borderStyle: 'solid',
  },

  contactUsContainer: {
    height: 130,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    marginTop: 12,
    alignSelf: 'center',
  },
  subContainerStyle: {
    marginHorizontal: 20,
    marginTop: 24,
    marginBottom: 14,
  },
  subHeadingStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 4,
  },
  blueTextButton: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.cta.lightBlue,
    marginTop: 8,
    right: 3,
  },
  boldTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
});
